(function(exports) {
"use strict";

exports.environment = {
  "RAVEN_CONFIG": null
};

})(window);

(function () {
  'use strict';

  // https://stackoverflow.com/a/40686169/61552
  // https://github.com/angular-ui/ui-mask/issues/198
  // https://github.com/angular/angular.js/commit/274e93537ed4e95aefeacea48909eb334894f0ac

  function patternModelOverwrite() {
    return {
      restrict: 'A',
      require: '?ngModel',
      priority: 1,
      compile: function() {
        var regexp, patternExp;

        return {
          pre: function(scope, elm, attr, ctrl) {
            if (!ctrl) {
              return
            }

            attr.$observe('pattern', function(regex) {
              /**
               * The built-in directive will call our overwritten validator
               * (see below). We just need to update the regex.
               * The preLink fn guarantees our observer is called first.
               */
              if (angular.isString(regex) && regex.length > 0) {
                regex = new RegExp('^' + regex + '$');
              }

              if (regex && !regex.test) {
                //The built-in validator will throw at this point
                return;
              }

              regexp = regex || undefined;
            });

          },
          post: function(scope, elm, attr, ctrl) {
            if (!ctrl) {
              return
            }

            regexp, patternExp = attr.ngPattern || attr.pattern; // jshint ignore:line

            //The postLink fn guarantees we overwrite the built-in pattern validator
            ctrl.$validators.pattern = function(value) {
              return ctrl.$isEmpty(value) ||
                angular.isUndefined(regexp) ||
                regexp.test(value);
            };
          }
        };
      }
    };
  }

angular.module('common.directives.pattern-model-overwrite', [])
.directive('patternModelOverwrite', patternModelOverwrite);
})();

(function () {
  "use strict";

  angular.module("common.directives.uiSelect", [])
    .directive("uiSelectNoAnimate", function() {
      // https://github.com/angular-ui/ui-select/issues/389#issuecomment-416378819
      return {
        restrict: "A",
        require: "uiSelect",
        link: function(scope, element, attrs, $select) {
          $select.$animate = null;
        }
      }
    })
})();

(function() {
  "use strict";

  angular.module("common.components", [])

})();

(function() {
  "use strict"

  angular.module("common.components")

  .controller("broadcastNavbarDropdown",Controller )

  .component("broadcastNavbarDropdown", {
    controller: Controller,
    templateUrl: "src/common/components/broadcast-navbar-dropdown.tpl.html",
    bindings: {
      disabled: "<"
    }
  })

  function Controller(
    $filter,
    $log,
    $rootScope,
    $scope,
    $uibModal,
    CommunicationsService,
    NotificationService,
    PrincipalService,
    WebsocketService
  ) {

    var $ctrl = this

    $ctrl.$onInit = function() {
      $ctrl.muted = CommunicationsService.isMuted()
      $ctrl.practiceId = PrincipalService.getPracticeId()
      $ctrl.userId = PrincipalService.getAccountId()
      $ctrl.broadcasts = []
      $ctrl.receivedAndUnseen = []
      $ctrl.broadcastHistoryModalInstance = null
      $ctrl.broadcastSendModalInstance = null
      $ctrl.disabled = null

      $ctrl.broadcastObservableId = WebsocketService.observe("communication", ["inform"], function(stream, cmd, params) {
        var broadcast = {cmd:cmd, stream:stream, params:params}
        if ($ctrl.userId === broadcast.params.user_id) {
          broadcast.type = "sent"
          broadcast.seen = true
        }
        else {
          broadcast.type = "received"
          broadcast.seen = false
        }
        $scope.$applyAsync(function() {
          $ctrl.broadcasts.push(broadcast)
          $ctrl.updateReceivedAndUnseen()
          if (broadcast.type === "received" && !$ctrl.muted && !$ctrl.broadcastHistoryModalInstance) {
            $ctrl.showMessages($ctrl.receivedAndUnseen)
          }
        })
      })

      $ctrl.cancelCommServiceMuteListener = $rootScope.$on("communicationsService.mute", function(event, muted) {
        $ctrl.muted = muted
      })

      // TODO: only for devel
      // window.broadcasts = $ctrl.broadcasts
      // CommunicationsService.broadcastToPractice($ctrl.practiceId, 0, "foo")
      // CommunicationsService.broadcastToPractice($ctrl.practiceId, 1, "bar")
      // CommunicationsService.broadcastToPractice($ctrl.practiceId, 2, "baz")
      // $ctrl.openBroadcastHistoryModal()
    }

    $ctrl.$onChanges = function(changes) {
      if (changes.disabled) {
        $ctrl.disabled = changes.disabled.currentValue
      }
    }

    $ctrl.$onDestroy = function() {
      $ctrl.cancelCommServiceMuteListener()
      WebsocketService.unobserve($ctrl.broadcastObservableId)
      if ($ctrl.broadcastHistoryModalInstance) {
        $ctrl.broadcastHistoryModalInstance.close()
      }
      if ($ctrl.broadcastSendModalInstance) {
        $ctrl.broadcastSendModalInstance.close()
      }
    }

    $ctrl.updateReceivedAndUnseen = function() {
      $ctrl.receivedAndUnseen = $ctrl.broadcasts.filter(function(broadcast) {
        return broadcast.type === "received" && broadcast.seen === false
      })
    }

    $ctrl.toggleMuted = function() {
      CommunicationsService.mute(!$ctrl.muted)
    }

    $ctrl.openBroadcastSendModal = function() {
      $ctrl.broadcastSendModalInstance = $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        templateUrl: "src/common/components/broadcast-navbar-dropdown.tpl.html",
        resolve: {
          practiceId: function() { return $ctrl.practiceId }
        },
        controller: function($scope, practiceId) {
          var $ctrl = this
          $ctrl.message = null
          $ctrl.send = function(level) {
            var text = angular.element("<pre/>").text($ctrl.message).html()
            CommunicationsService.broadcastToPractice(practiceId, level, text).then(
              function() {
                $scope.form.$setPristine()
                $ctrl.message = null
              },
              function(err) {
                NotificationService.error("Error broadcasting message", err)
              }
            )
          }
        },
      })
      $ctrl.broadcastSendModalInstance.result.finally(
        function() {
          $ctrl.broadcastSendModalInstance = null
        }
      )
    }

    $ctrl.openBroadcastHistoryModal = function() {
      $ctrl.broadcastHistoryModalInstance = $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        size: "lg",
        templateUrl: "src/common/components/broadcast-navbar-history-model.tpl.html",
        resolve: {
          broadcasts: function() {
            return $ctrl.broadcasts
          },
          userId: function() {
            return $ctrl.userId
          },
        },
        controller: function($scope, broadcasts, userId) {
          var $ctrl = this
          $ctrl.broadcasts = broadcasts
          $ctrl.userId = userId
        },
      })
      $ctrl.broadcastHistoryModalInstance.result.finally(
        function() {
          $ctrl.broadcastHistoryModalInstance = null
        }
      )
    }

    $ctrl.showMessages = function(messages) {
      swal(
        {
          confirmButtonText: "OK",
          customClass:"sweet-broadcast",
          html: messages.map(function(message) {
            return [
              '<div class="alert alert-', {0:"success", 1:"warning", 2:"danger"}[message.params.level] || "info", '">',
                '<small class="dis-b">',
                  $filter("date")(message.params.datetime, "MM/dd/yyyy hh:mm:ss a", null),
                '</small>',
                '<small>',
                  '<b>',
                    message.params.user_first_name, ' ', message.params.user_last_name,
                  '</b>',
                '</small>',
                '<p class="preserve-newlines" style="padding-top: 10px">',
                  message.params.message.trim(),
                '</p>',
              '</div>'
            ].join("")
          }).join(""),
          title: "Broadcast Received",
        },
        function() {
          $scope.$applyAsync(function() {
            messages.forEach(function(message) {
              message.seen = true
            })
            $ctrl.updateReceivedAndUnseen()
          })
        }
      )
    }
    $scope.broadcastCtrl = $ctrl;
  }

})();

(function() {
  "use strict";

  angular.module("common.components")

  .component("unsupportedBrowser", {
    controller: Controller,
    templateUrl: "src/common/components/browser-unsupported.html",
  })

  function Controller(
    $localStorage,
    $rootScope,
    $scope
  ) {

    var $ctrl = this

    $ctrl.$onInit = function() {
      $ctrl.family = elvisEx($rootScope.resolved, "apiMeta.ua.family", null)
      $ctrl.unsupportedBrowserUnderstood = $localStorage.unsupportedBrowserUnderstood
      $ctrl.isSupported = $ctrl.supportedBrowsersFamily.indexOf($ctrl.family) !== -1
      $ctrl.$unsupportedBrowserUnderstoodUnwatch = $scope.$watch("$ctrl.unsupportedBrowserUnderstood", function(value) {
        $localStorage.unsupportedBrowserUnderstood = value
      })
    }

    $ctrl.$onDestroy = function() {
      $ctrl.$unsupportedBrowserUnderstoodUnwatch()
    }

    $ctrl.supportedBrowsersFamily = [
      "Chrome",
      "Edge",
      "Firefox",
    ]

  }

})();

(function() {
  "use strict";

  angular.module("common.components")

  .controller("idleNavbarCtrl",Controller )

  .component("idleNavbarDropdown", {
    controller: Controller,
    templateUrl: "src/common/components/idle-navbar-dropdown.tpl.html",
    bindings: {
      disabled: "<"
    }
  })

  .filter("idleDuration", function() {
    return function(value) {
      value = moment.duration(value, "seconds").toISOString()
      return value.substring(value.indexOf("T") + 1).toLowerCase()
    }
  })

  function Controller(
    $localStorage,
    $rootScope,
    $scope
  ) {

    var $ctrl = this

    $ctrl.$onInit = function() {
      $ctrl.defaultIdle = $rootScope.getdefaultIdle()
      $ctrl.idle = $rootScope.getIdle()
      $ctrl.multiplierWasStored = true
      $ctrl.storeMultiplier = false
      $ctrl.disabled = null
    }

    $ctrl.$onChanges = function(changes) {
      if (changes.disabled) {
        $ctrl.disabled = changes.disabled.currentValue
      }
    }

    $ctrl.$onDestroy = function() {
    }

    $ctrl.resetToDefault = function() {
      $ctrl.setMultiplier(1, true)
      $ctrl.storeMultiplier = false
      $ctrl.multiplierWasStored = true
    }

    $ctrl.setMultiplier = function(multiplier, store) {
      $ctrl.multiplierWasStored = !!(store || $ctrl.storeMultiplier)
      $ctrl.idle = $rootScope.multiplyIdle(multiplier, $ctrl.multiplierWasStored)
    }

    $ctrl.storeMultiplierToggle = function() {
      $ctrl.storeMultiplier = !$ctrl.storeMultiplier
      if ($ctrl.storeMultiplier) {
        $localStorage.idleMultiplier = $ctrl.idle / $ctrl.defaultIdle
        $ctrl.multiplierWasStored = true
      }
      else {
        $localStorage.idleMultiplier = 1
        $ctrl.multiplierWasStored = $ctrl.idle / $ctrl.defaultIdle === 1
      }
    }

    $scope.idleCtrl = $ctrl;
  }

})();

(function(Konva) {
  "use strict";
  /* jshint unused:false */
})(window.Konva);

(function(angular) {
  'use strict';

  angular.module('common.decorators.$q', [])
    .config(config);


    function config($provide) {
      $provide.decorator('$q', function ($delegate) {
        var $q = $delegate;
        var originalDefer = $delegate.defer;
        var isPromiseLike = function (obj) {
          return obj && angular.isFunction(obj.then);
        }

        $q.allSettled = function(promises) {
          return $q.all(mapValues(promises, function(promiseOrValue) {
            if (!promiseOrValue.then) {
              return {
                state: 'fulfilled',
                value: promiseOrValue
              };
            }
            return promiseOrValue.then(
              function(value) {
                return {
                  state: 'fulfilled',
                  value: value
                };
              },
              function(reason) {
                return {
                  state: 'rejected',
                  reason: reason
                };
              }
            );
          }));
        };

        $q.map = function(values, callback, ctx) {
          return $q.all(mapValues(values, callback, ctx));
        };

        $q.mapSettled = function(values, callback, ctx) {
          return $q.allSettled(mapValues(values, callback, ctx));
        };

        $q.defer = function () {
          // Get the prototype of the promise
          var promiseProto = originalDefer().promise.constructor.prototype;
          // Add the spread method
          Object.defineProperty(promiseProto, 'spread', {
            value: function (resolve, reject) {
              function spread (data) {
                return resolve.apply(void 0, data);
              }
              return this.then(spread, reject);
            },
            writable: true,
            enumerable: false
          });
          return originalDefer();
        };

        $q.serial = function(tasks) {

          //Fake a "previous task" for our initial iteration
          var prevPromise;
          var error = new Error();
          angular.forEach(tasks, function (task, key) {
            var success = task.success || task;
            var fail = task.fail;
            var notify = task.notify;
            var nextPromise;
            //First task
            if (!prevPromise) {
              nextPromise = success();
              if (!isPromiseLike(nextPromise)) {
                error.message = "Task " + key + " did not return a promise.";
                throw error;
              }
            }
            else {
              //Wait until the previous promise has resolved or rejected to execute the next task
              nextPromise = prevPromise.then(
                function (data) {
                  if (!success) {
                    return data;
                  }
                  var ret = success(data);
                  if (!isPromiseLike(ret)) {
                    error.message = "Task " + key + " did not return a promise.";
                    throw error;
                  }
                  return ret;
                },
                function (reason) {
                  if (!fail) {
                    return $delegate.reject(reason);
                  }
                  var ret = fail(reason);
                  if (!isPromiseLike(ret)) {
                    error.message = "Fail for task " + key + " did not return a promise.";
                    throw error;
                  }
                  return ret;
                },
                notify
              );
            }
            prevPromise = nextPromise;
          });
          return prevPromise || $delegate.when();
        }

        return $q;
      });

      function mapValues(obj, callback, ctx) {
        // Called with an array this acts like map, otherwise it acts like _.mapValues in lodash.
        // @return {Array|Object} The same type as the input argument.
        if (angular.isArray(obj)) {
          return obj.map(callback, ctx);
        }
        var ret = {};
        Object.keys(obj).forEach(function(key) {
          // TODO: ctx not testet yet
          ret[key] = callback.call(ctx, obj[key], key);
        });
        return ret;
      }
    }

})(window.angular);

(function() {
  "use strict"

  /**!
   * AngularJS treeviewer directive.
   * @author Russell Morley <russell@compass-point.net>
   * @version 0.1.0
   */

  /* global angular */

  angular.module("common.directives.angularTreeviewer", []).directive("angularTreeviewer", [
    "$window",
    "$timeout",
    "$log",
    "$parse",
    function($window, $timeout, $log, $parse) { /*jshint unused: false*/
      return {
        restrict: "A",
        scope: {
          fullTree: "=fullTree",
          openIconClass: "=?openIconClass",
          closeIconClass: "=?closeIconClass",
          emptyIconClass: "=?emptyIconClass",
          groupIconClass: "=?groupIconClass",
          searchIconClass: "=?searchIconClass",
          removeEmptyValues: "=?removeEmptyValues",
          normalizeWords: "=?normalizeWords",
          startContracted: "=?startContracted",
        },
        link: function(scope, element, attrs, ngModel) { /*jshint unused: false*/
          scope.isString = function(thing) {
            return angular.isString(thing)
          }

          scope.isPrimitive = function(thing) {
            return angular.isString(thing) || angular.isNumber(thing) || typeof thing === "boolean"
          }

          scope.isObject = function(thing) {
            return angular.isObject(thing)
          }

          scope.toggleControls = function(thing) {
            if (
              !thing || //null, undefined, false, 0, '', NaN
              typeof thing !== "object" || //boolean, number, string, symbol, function
              (thing instanceof Array && thing.length === 0) //zero length array
            ) {
              return
            }
            // if: non-null object or array with a size greater than zero.
            thing._childrenVisible = !thing._childrenVisible
            thing._iconClass = thing._childrenVisible ? scope.openIconClass : scope.closeIconClass
          }

          scope.setControls = function(thing) {
            if (typeof thing === "object") {
              if (thing && typeof thing._iconClass === "undefined") {
                if (thing instanceof Array && thing.length === 0) {
                  thing._iconClass = scope.emptyIconClass
                } else {
                  thing._iconClass = scope.closeIconClass
                }
              }
              if (thing instanceof Array) {
                for (var i = 0; i < thing.length; i++) {
                  if (typeof thing[i] === "object") {
                    thing[i]._childrenVisible = true
                    thing[i]._iconClass = scope.groupIconClass
                  }
                }
              }
            }
            return true
          }
          scope.showLabel = function(thing) {
            return thing !== "_childrenVisible" && thing !== "_iconClass"
          }

          scope.getRepeatClass = function(thing, isRoot) {
            if (!isRoot) {
              if (typeof thing === "object") {
                return "expanded-item-with-icon"
              } else {
                return "expanded-item-without-icon"
              }
            } else {
              if (typeof thing !== "object") {
                return "root-item-without-icon"
              }
            }
          }

          scope.search = ""

          scope.$watch("search", function(val) {
            if (!scope.flattenedTree || scope.flattenedTree.length === 0) {
              scope.flattenedTree = []
              flatten(scope.heirarchicalTree, scope.flattenedTree)
            }
            scope.displayTree = val.length > 0 ? scope.flattenedTree : scope.heirarchicalTree
          })

          scope.$watch("fullTree", function(val) {
            scope.search = "" //reset search
            scope.flattenedTree = null //clear flattened tree
            scope.heirarchicalTree = preProcessTree(
              val,
              scope.removeEmptyValues,
              scope.normalizeWords,
              scope.startContracted,
              scope.openIconClass
            )
            scope.displayTree = scope.heirarchicalTree
          })

          function flatten(node, arr, val, parent) {
            if (node && node instanceof Array) {
              node.forEach(function(val, idx, array) {
                /*jshint unused: false*/

                flatten(val, arr)
              })
              return
            } else if (node && typeof node === "object") {
              Object.getOwnPropertyNames(node).forEach(function(val, idx, array) {
                /*jshint unused: false*/

                flatten(node[val], arr, val, node)
              })
              return
            } else if (
              node &&
              (typeof node === "string" || typeof node === "boolean" || typeof node === "number") &&
              val
            ) {
              var par = ""
              /*jshint unused: false*/
              Object.getOwnPropertyNames(parent).forEach(function(key, idx, array) {
                if (parent[key] && typeof parent[key] === "string") {
                  if (key !== "_childrenVisible" && key !== "_iconClass" && key !== "$$hashKey") {
                    par += key + ": " + parent[key] + "; "
                  }
                }
              })
              var str = par
              if (arr.indexOf(str) === -1) {
                arr.push(str)
              }
              return
            } else {
              return
            }
          }

          function preProcessTree(node, removeEmptyValues, normalizeWords, startContracted, openIconClass) {
            if (node && node instanceof Array) {
              var newArray = []
              node.forEach(function(val, idx, array) {
                /*jshint unused: false*/

                var newVal = preProcessTree(val, removeEmptyValues, normalizeWords, startContracted, openIconClass)
                if (!removeEmptyValues || newVal) {
                  newArray.push(newVal)
                }
              })
              if (!removeEmptyValues || newArray.length > 0) {
                if (!startContracted) {
                  newArray._childrenVisible = true
                  newArray._iconClass = openIconClass
                }
                return newArray
              } else {
                return null
              }
            } else if (node && typeof node === "object") {
              var nodeProperties = Object.getOwnPropertyNames(node)
              /*
                                    XML to JSON converts XML arrays specified as:
                                        <e> <a>text</a> <a>text</a> </e>	
                                    to:
                                        "e": { "a": ["text", "text"] }
                                    When "a" is the only property of its object we would rather have the
                                    following for display (because "a" doesn't contain any meaningful information
                                    for the user):
                                        "e": ["text", "text"]
                                    This conditional converts the former to the latter for 
                                    cases where "a" is the only property of its object.
                                 */
              if (nodeProperties.length === 1 && node[nodeProperties[0]] instanceof Array) {
                return preProcessTree(
                  node[nodeProperties[0]],
                  removeEmptyValues,
                  normalizeWords,
                  startContracted,
                  openIconClass
                )
              }
              var newObj = {}
              nodeProperties.forEach(function(key, idx, array) {
                /*jshint unused: false*/

                var newKey = key
                if (normalizeWords) {
                  //first replace all underscores in string with a blank
                  newKey = key.replace(/_/gi, function(txt) {
                    return " "
                  })
                  //normalize to first letter cap, rest lower for all words in the string.
                  newKey = newKey.replace(/\w\S*/g, function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                  })
                }
                var newVal = preProcessTree(
                  node[key],
                  removeEmptyValues,
                  normalizeWords,
                  startContracted,
                  openIconClass
                )
                if (!removeEmptyValues || newVal) {
                  newObj[newKey] = newVal
                }
              })
              if (!startContracted) {
                newObj._childrenVisible = true
                newObj._iconClass = openIconClass
              }
              return newObj
            } else {
              if (
                node &&
                ((typeof node === "string" && node.length > 0) || typeof node === "boolean" || typeof node === "number")
              ) {
                if (normalizeWords && typeof node === "string") {
                  return node.replace(/\w\S*/g, function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                  })
                }
                return node
              } else {
                return null
              }
            }
          }
        },
        templateUrl: 'src/common/directives/angular-treeviewer.tpl.html'
      }
    },
  ])
})();

/**
 * bootstrap date-picker with mask input
 *
 */
(function () {
    'use strict';
    function DatePickerPopup() {
        return {
            restrict: 'E',
            replace: true,
            require: 'ngModel',
            scope:{
              ngModel: '=',
              ngReadonly: '=?',
              inputName: '=',
              ngShow: '=?',
              ngRequired: '=?',
              ngDisabled: '=?',
              minDate: '=?',
              maxDate: '=?',
              showPlaceholder: '=?',
              ivalue: '=?',
              placeholder: '=?'
            },
            templateUrl: 'src/common/directives/datepicker.tpl.html',
            link: function (scope, element, attrs, ngModel) {

              var ngModelFormat = 'YYYY-MM-DD';
              var uiMaskFormat = 'MM/DD/YYYY';
              scope.uiDatePickerFormat = 'MM/dd/yyyy';
              scope.isInvalid = false;

              var modelController = element.find('input').controller('ngModel');

              scope.dpOpened = false;
              scope.open = function() {
                scope.dpOpened = !scope.dpOpened;
              };

              if(scope.showPlaceholder === false){
                scope.placeholder = ' ';
              }

              if(!scope.ngShow && scope.ngShow !== false ){
                scope.ngShow = true;
              }

              //Sets the ngModel date (string) into the datepicker component
              ngModel.$render = function modelToDatePicker() {
                if(!ngModel.$viewValue){

                  if(scope.ngRequired){
                    scope.isInvalid = true;
                    modelController.$setValidity('date',false);
                  }

                  return;
                }
                var date = moment(ngModel.$viewValue, ngModelFormat,true);
                if(date.isValid()){
                  scope.datePickerModel = date._d;
                  modelController.$setValidity('date',true);
                  scope.isInvalid = false;
                  return;
                }

                console.warn('ngModel date does not successfully validate with hardcoded date format ' + ngModelFormat + '. Please repair if this is a problem. Current value ' + ngModel.$viewValue);
              };


              //On user date interaction marks it invaild or valid and sets the value to the ngModel
              var onUserInputChange = function(){
                if(!modelController.$viewValue){

                  if(scope.ngRequired){
                    scope.isInvalid = true;
                    modelController.$setValidity('date',false);
                  }

                  return;
                }

                var date = moment(modelController.$viewValue, uiMaskFormat,true);

                if(date.isValid()){
                  ngModel.$setViewValue(moment(date).format(ngModelFormat));
                  modelController.$setValidity('date',true);
                  scope.isInvalid = false;
                  return;
                }
                scope.isInvalid = true;
                if (scope.ivalue !== undefined) {
                  ngModel.$setViewValue(scope.ivalue);
                  scope.isInvalid = false;
                }
                modelController.$setValidity('date',false);
              };

              scope.$watch(function (){ return scope.dpOpened; }, function() {
                onUserInputChange();
              });

              scope.$watch(
                function () {
                  return ngModel.$modelValue
                },
                function(newValue) {
                  if (newValue === undefined) {
                    delete scope.datePickerModel
                  }
                }
              )

              scope.onBlur = onUserInputChange;
              scope.onKeyUp = onUserInputChange;
              scope.onChange = onUserInputChange;

            }
        };
    }

    angular.module('common.directives.datepicker', ['ui.bootstrap'])
        .directive('customDatepickerPopup', DatePickerPopup);
})();

(function () {
  'use strict';
  function DateInputCtrl() {
  }

  function DateInput() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        ngModel: '=ngModel',
        ngRequired: '=ngRequired',
        name: '=name',
        placeholder: '=placeholder',
      },
      controller: DateInputCtrl,
      controllerAs: 'dateInputCtrl',
      templateUrl: 'src/common/directives/date-input.tpl.html'
    };
  }

angular.module('common.directives.date-input', [])
.directive('dateInput', DateInput);
})();

(function () {
  'use strict';
  function YesNoButtonCtrl() {
  }

  function YesNoButton() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        ngModel: '=',
        label: '=label',
        yesLabel: '=yesLabel',
        noLabel: '=noLabel'
      },
      controller: YesNoButtonCtrl,
      controllerAs: 'yesNoButtonCtrl',
      templateUrl: 'src/common/directives/yes-no-button.tpl.html'
    };
  }

angular.module('common.directives.yes-no-button', [])
.directive('yesNoButton', YesNoButton);
})();

(function () {
  'use strict';
  function SignatureInputCtrl() {
  }

  function SignatureInput() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        ngModel: '=ngModel',
        ngRequired: '=ngRequired',
        name: '=name',
        placeholder: '=placeholder'
      },
      controller: SignatureInputCtrl,
      controllerAs: 'signatureInputCtrl',
      templateUrl: 'src/common/directives/signature-input.tpl.html'
    };
  }

angular.module('common.directives.signature-input', [])
.directive('signatureInput', SignatureInput);
})();

(function () {
  'use strict';
  function SsnInputCtrl() {
  }

  function SsnInput() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        ngModel: '=ngModel',
        ngRequired: '=ngRequired',
        name: '=name',
        placeholder: '=placeholder',
      },
      controller: SsnInputCtrl,
      controllerAs: 'ssnInputCtrl',
      templateUrl: 'src/common/directives/ssn-input.tpl.html'
    };
  }

angular.module('common.directives.ssn-input', [])
  .directive('ssnInput', SsnInput);
})();

(function () {
  'use strict';
  function PhoneInputCtrl() {
  }

  function PhoneInput() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        ngModel: '=ngModel',
        ngRequired: '=ngRequired',
        ngReadonly: '=ngReadonly',
        validateOn: '@validateOn',
        name: '@name',
        placeholder: '@placeholder',
      },
      controller: PhoneInputCtrl,
      controllerAs: 'phoneInputCtrl',
      templateUrl: 'src/common/directives/phone-input.tpl.html'
    };
  }

angular.module('common.directives.phone-input', [])
.directive('phoneInput', PhoneInput);
})();

(function () {
  'use strict';
  function PhoneInputCtrl() {
  }

  function PhoneInput() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        ngModel: '=ngModel',
        ngRequired: '=ngRequired',
        validateOn: '@validateOn',
        name: '@name',
        placeholder: '@placeholder',
      },
      controller: PhoneInputCtrl,
      controllerAs: 'phoneInputCtrl',
      templateUrl: 'src/common/directives/phone-input-new.tpl.html'
    };
  }

angular.module('common.directives.phone-input-new', [])
.directive('phoneInputNew', PhoneInput);
})();

(function () {
  'use strict';
  function StateInputCtrl() {
  }

  function StateInput() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        ngModel: '=ngModel',
        ngRequired: '=ngRequired',
        name: '@name',
        ngReadonly: '=ngReadonly',
        placeholder: '=placeholder',
      },
      controller: StateInputCtrl,
      controllerAs: 'stateInputCtrl',
      templateUrl: 'src/common/directives/state-input.tpl.html'
    };
  }

angular.module('common.directives.state-input', [])
.directive('stateInput', StateInput);
})();

(function () {
  'use strict';
  function ZipInputCtrl() {
  }

  function ZipInput() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        ngModel: '=ngModel',
        ngRequired: '=ngRequired',
        ngReadonly: '=ngReadonly',
        name: '@name',
      },
      controller: ZipInputCtrl,
      controllerAs: 'zipInputCtrl',
      templateUrl: 'src/common/directives/zip-input.tpl.html'
    };
  }

angular.module('common.directives.zip-input', [])
.directive('zipInput', ZipInput);
})();

(function() {
  'use strict';

  function backButton($window) {
      return {
          restrict: 'A',
          link: function(scope, elem) {
              elem.bind('click', function() {
                  $window.history.back();
              });
          }
      };
  }

  angular.module('common.directives.backbutton', [])
    .directive('backButton', backButton);
})();

(function () {
  'use strict';

  // TODO: Move this to a service?
  // Inheritance implementation
  function inheritsFrom(childClass, ParentClassOrObject) {
    if (typeof(ParentClassOrObject.constructor) === 'function') {
      // Normal Inheritance
      childClass.prototype = new ParentClassOrObject();
      childClass.prototype.parent = ParentClassOrObject.prototype;
    } else {
      // Pure Virtual Inheritance
      childClass.prototype = ParentClassOrObject;
      childClass.prototype.parent = ParentClassOrObject;
    }

    childClass.prototype.constructor = this; // jshint ignore:line

    return childClass;
  }

  function ActionsHandler() {
    var that = this;
    this.default = 'default';
    this.current = that.default;
    this.actions = {};

    function changeAction(newAction) {
      that.actions[that.current || that.default].disable();
      that.current = newAction || that.default;
      that.actions[that.current].enable();
    }

    function add(actionName, action) {
      that.actions[actionName] = action;
    }

    return {
      add: add,
      changeAction: changeAction
    };
  }

  function Action(elem, eventHandlers) {
    this.elem = elem;
    this.eventHandlers = eventHandlers;
    this.enabled = false;
  }

  Action.prototype.enable = function () {
    var that = this;

    if (this.enabled) {
      return;
    }

    this.enabled = true;
    this.eventHandlers.forEach(function (eventHandlerTuple) {
      that.elem.addEventListener(eventHandlerTuple[0], eventHandlerTuple[1]);
    });
  };

  Action.prototype.disable = function () {
    var that = this;

    if (!this.enabled) {
      return;
    }

    var eventHandler;
    this.eventHandlers.forEach(function (eventHandlerTuple) {
      eventHandler = eventHandlerTuple[1];
      that.elem.removeEventListener(eventHandlerTuple[0], eventHandler);
      if (typeof(eventHandler) === 'object' && eventHandler.restore) {
        eventHandler.restore();
      }
    });

    this.enabled = false;
  };

  function DefaultAction(elem, eventHandlers) {
    Action.call(this, elem, eventHandlers);
  }

  inheritsFrom(DefaultAction, Action);

  function Measurement(elem, eventHandlers) {
    Action.call(this, elem, eventHandlers);
  }

  inheritsFrom(Measurement, Action);

  /**
   * Image editing directive.
   *
   * Handles zoom, pan, taking measures.
   *
   * Based on: https://github.com/petalvlad/angular-canvas-ext <petalvlad@gmail.com>
   */
  function imageEditor(ImageService, CanvasService, $window) {
    return {
      restrict: 'A',
      scope: {
        zoomable: '=?',
        fullscreen: '=?',
        imageData: '=?',
        action: '=?'
      },
      link: function ($scope, element) {
        function changeOffset() {
          drawImage();
        }

        function getMousePosition(e) {
          var rect = canvas.getBoundingClientRect();

          return {
            x: (e.clientX - rect.left) / imageData.scale,
            y: (e.clientY - rect.top) / imageData.scale
          };
        }

        function changeImageSource() {
          if (imageData.src) {
            loadImage();
          } else {
            imageData.image = null;
          }
        }

        function changeImage(newImage, oldImage) {
          if (newImage) {
            updateDefaultScale();
            if (oldImage || !imageData.scale) {
              updateScale();
            }
            drawImage();
          }
        }

        function changeScale() {
          // Avoid zooming out the image below the width of its container
          if (imageData.scale && imageData.scale < defaultScale) {
            setScale(defaultScale);
          }
          drawImage();
        }

        function CheckPoint() {
          this.measure = {};

          this.restore = function () {
            // Check if the measure was interrupted
            if (this.measure.p1 && !this.measure.p2) {
              drawImage();
            }

            this.measure = {};
          };

          this.handleEvent = function (e) {
            var imageOffsetLimits = canvasHandler.getImageOffsetLimits(),
              mousePosition = getMousePosition(e),
              // Get the point after scaling and moving the image. This takes into account the
              // ratio between the image size and the container
              newPoint = {
                x: imageOffsetLimits.right + mousePosition.x - imageData.offset.x,
                y: imageOffsetLimits.bottom + mousePosition.y - imageData.offset.y
              };

            if (!this.measure.p1) {
              canvasHandler.drawPoint(newPoint);
              this.measure.p1 = newPoint;
            } else {
              var distance = ImageService.getDistance(this.measure.p1, newPoint);

              // Reset measure if clicking the same point
              if (distance === 0) {
                return this.restore();
              }

              this.measure.p2 = newPoint;
              imageData.addMeasure(this.measure);

              // Let listeners of measures know about the new measure
              $scope.$apply();

              // Draw the new measure
              canvasHandler.drawPoint(newPoint);
              canvasHandler.drawLine(this.measure.p1, newPoint);
              canvasHandler.drawDistance(this.measure.p1, newPoint);

              this.restore();
            }
          };

          return this;
        }

        var imageData = $scope.imageData,
          canvas = element[0],
          defaultScale = 0,
          isUpdateOffset = false,
          isUpdateScale = false,
          isMoving = false,
          previousMousePosition = null,
          lastZoomDist = null,
          actionsHandler = new ActionsHandler(),
          canvasHandler = null;

        actionsHandler.add(
          'measure',
          new Measurement(canvas, [['mousedown', new CheckPoint()]])
        );

        // Initialization
        if (!imageData.offset) {
          imageData.offset = {
            x: 0,
            y: 0
          };
        }

        $scope.$watch(function () {
          return imageData.src;
        }, function () {
          changeImageSource();
        });

        function loadImage() {
          var image = new Image();

          image.onload = function () {
            var oldImage = imageData.image;
            imageData.image = image;

            var canvasContainer = angular.element('#canvas-container');

            // Set canvas size relative to the viewport
            var canvasSize = CanvasService.getSizeFromContainer(canvasContainer, image, $scope.fullscreen);
            canvas.width = canvasSize.width;
            canvas.height = canvasSize.height;

            // Scale should be redefined based on the width of the canvas when the scale was taken
            if (imageData.canvasWidth) {
              imageData.scale *= canvasSize.width / imageData.canvasWidth;
            }

            // Initialize a canvas handler
            canvasHandler = CanvasService.getHandler(canvas, imageData);

            changeImage(imageData.image, oldImage);

            // Handle window resizing
            var windowElement = angular.element($window);
            windowElement.on('resize.editorResize', function (){
              $scope.$apply(function() {
                var newCanvasSize = CanvasService.getSizeFromContainer(canvasContainer, image, $scope.fullscreen);

                // If the container width hasn't changed, do nothing
                if (newCanvasSize.width === canvasSize.width) {
                  return;
                }

                var scaleChange = newCanvasSize.width / canvasSize.width;

                // Save the new canvas width and scale
                canvas.width = imageData.canvasWidth = canvasSize.width = newCanvasSize.width;
                canvas.height = canvasSize.height = newCanvasSize.height;
                imageData.scale *= scaleChange;
                updateDefaultScale();
              });
            });
            $scope.$on('$destroy', function() {
              windowElement.off('resize.editorResize');
            });
          };

          // TODO: Handle CORS correctly for every scenario. Enable CORS
          image.crossOrigin = 'anonymous';
          image.src = imageData.src;
        }

        // React to filter changes
        $scope.$watch(function () {
          return imageData.filters;
        }, function (newValue, oldValue) {
          if (canvasHandler && newValue !== oldValue) {
            canvasHandler.drawImage();
          }
        }, true);

        // Redraw image when measures change
        $scope.$watch(function () {
          return imageData.getMeasures();
        }, function(newValue, oldValue) {
          if (newValue !== oldValue) {
            // Redraw only if there are measures
            if (canvasHandler) {
              canvasHandler.drawImage();
            }
          }
        }, true);

        // Redraw measures when the calibration used changes
        $scope.$watch(function () {
          return imageData.calibration;
        }, function(newValue, oldValue) {
          if (newValue !== oldValue) {
            // Redraw only if there are measures
            if (canvasHandler && imageData.hasMeasures()) {
              canvasHandler.drawImage();
            }
          }
        });

        $scope.$watch(function () {
          return imageData.image;
        }, function (newImage, oldImage) {
          changeImage(newImage, oldImage);
        });

        function setScale(scale) {
          isUpdateScale = true;
          imageData.scale = scale;
          isUpdateScale = false;
        }

        function updateDefaultScale() {
          defaultScale = canvas.width / imageData.image.width;
        }

        function updateScale() {
          setScale(defaultScale);
        }

        function drawImage() {
          if (!imageData.image || isUpdateScale || isUpdateOffset) {
            return;
          }
          clipToBounds();
          canvasHandler.drawImage();
        }

        function clipToBounds() {
          isUpdateOffset = true;
          canvasHandler.clipToBounds();
          isUpdateOffset = false;
        }

        if ($scope.zoomable) {
          var setIsMoving = function (moving, event, position) {
            event.preventDefault();
            isMoving = moving;
            if (moving) {
              previousMousePosition = getMousePosition(position);
            }
          };

          var moveTo = function (e, position) {
            if (isMoving) {
              e.preventDefault();
              var mousePosition = getMousePosition(position);
              imageData.offset = {
                x: imageData.offset.x + (mousePosition.x - previousMousePosition.x),
                y: imageData.offset.y + (mousePosition.y - previousMousePosition.y)
              };
              previousMousePosition = mousePosition;
              changeOffset();
            }
          };

          var zoom = function (e, touch1, touch2) {
            e.preventDefault();
            var dist = Math.sqrt(Math.pow(touch2.pageX - touch1.pageX, 2) + Math.pow(touch2.pageY - touch1.pageY, 2));
            if (lastZoomDist) {
              imageData.scale *= dist / lastZoomDist;
              changeScale();
            }
            lastZoomDist = dist;
          };

          var handleMouseDown = function (e) {
            setIsMoving(true, e, e);
          };

          var handleTouchStart = function (e) {
            if (e.targetTouches.length === 1) {
              setIsMoving(true, e, e.changedTouches[0]);
            }
          };

          var handleMouseUp = function (e) {
            setIsMoving(false, e);
          };

          var handleTouchEnd = function (e) {
            lastZoomDist = null;
            setIsMoving(false, e);
          };

          var handleMouseMove = function (e) {
            moveTo(e, e);
          };

          var handleTouchMove = function (e) {
            if (e.targetTouches.length >= 2) {
              var touch1 = e.targetTouches[0], touch2 = e.targetTouches[1];
              if (touch1 && touch2) {
                zoom(e, touch1, touch2);
              }
            } else {
              moveTo(e, e.changedTouches[0]);
            }
          };

          // Remove mouse wheel zoom for now
          //var handleMouseWheel = function (e) {
          //  if (e.deltaY > 0) {
          //    imageData.scale /= 1.05;
          //  } else {
          //    imageData.scale *= 1.05;
          //  }
          //  changeScale();
          //  return e.preventDefault() && false;
          //};

          var eventsHandlers = [
            ['mousedown', handleMouseDown],
            ['mouseup', handleMouseUp],
            ['mouseleave', handleMouseUp],
            ['mousemove', handleMouseMove],
            //['wheel', handleMouseWheel],
            ['touchstart', handleTouchStart],
            ['touchend', handleTouchEnd],
            ['touchcancel', handleTouchEnd],
            ['touchleave', handleTouchEnd],
            ['touchmove', handleTouchMove]
          ];
          actionsHandler.add('default', new DefaultAction(canvas, eventsHandlers));
          $scope.$watch(function () {
            return imageData.scale;
          }, function (newScale, oldScale) {
            if (newScale !== oldScale) {
              changeScale();
            }
          });
          $scope.$watch(function () {
            return imageData.offset;
          }, function () {
            changeOffset();
          });
        }
        $scope.$watch(function () {
          return $scope.action;
        }, function (newAction) {
          actionsHandler.changeAction(newAction);
        });
      }
    };
  }

  angular.module('common.directives.imageEditing', [])
    .directive('imageEditor', imageEditor);
})();

(function () {
  'use strict';
  function PasswordStrengthInputCtrl() {

  }

  function PasswordStrengthInput() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        ngModel: '=ngModel',
        name: '=name',
        placeholder: '=placeholder'
      },
      controller: PasswordStrengthInputCtrl,
      controllerAs: 'PasswordStrengthInputCtrl',
      templateUrl: 'src/common/directives/password-strength-input.tpl.html',
      /*jshint unused:false*/
      link: function(scope, elm, attrs) {

        // Password strength indicator
        var $inputLabelAbsolute = $('.label-indicator-password-strength input');
        var $outputLabelAbsolute = $('.label-indicator-password-strength > span');

        // Strength meter
        var feedback = [
            {color: '#D55757', text: 'Weak', textColor: '#fff'},
            {color: '#EB7F5E', text: 'Normal', textColor: '#fff'},
            {color: '#3BA4CE', text: 'Good', textColor: '#fff'},
            {color: '#40B381', text: 'Strong', textColor: '#fff'}
        ];

        $.passy.requirements = {
        	// Character types a password should contain
        	characters: [
        		$.passy.character.DIGIT,
        		$.passy.character.LOWERCASE,
        		$.passy.character.UPPERCASE,
        		$.passy.character.PUNCTUATION
        	],

        	// A minimum and maximum length
        	length: {
        		min: 8,
        		max: 255
        	}
        };

        $inputLabelAbsolute.passy(function(strength, valid) {
            $outputLabelAbsolute.text(feedback[strength].text + ((valid) ? '' : ' and not valid'));
            $outputLabelAbsolute.css('background-color', feedback[strength].color).css('color', feedback[strength].textColor);
        });
      }
    };
  }

angular.module('common.directives.password-strength-input', [])
.directive('passwordStrengthInput', PasswordStrengthInput);
})();

(function () {
  'use strict';

  function calibrationTool($locale, $rootScope, $window, ImageService, CanvasService, KonvaService) {
    return {
      restrict: 'E',
      templateUrl: 'src/common/directives/calibration/calibration.tpl.html',
      // Expects the image URL in the scope, as imageSrc
      link: function (scope) {
        var points = [],
          currentStep = 0,
          calibration = {},
          stage = null,
          konvaImage = null,
          imageLayer = KonvaService.getImageLayer(), //new Konva.Layer({}),
          calibrationLayer = KonvaService.getCalibrationLayer(), //new Konva.Layer({}),
          scale = null;

        function restartCalibration() {
          points = [];
          currentStep = 0;
          calibration = {};

          calibrationLayer.clear();
          calibrationLayer.destroyChildren();

          scope.showMeasure = false;
          scope.measure = null;
          scope.name = null;
        }

        function init() {
          var image = new Image();

          image.onload = function () {
            var canvasContainer = angular.element('#canvas-container');
            var canvasSize = CanvasService.getSizeFromContainer(canvasContainer, image, scope.fullscreen);
            scale = image.width / canvasSize.width;

            stage = new Konva.Stage({
              width: canvasSize.width,
              height: canvasSize.height,
              container: 'canvas-calibration-container'
            });

            konvaImage = new Konva.Image({
              x: 0,
              y: 0,
              width: canvasSize.width,
              height: canvasSize.height,
              image: image,
              name: 'calibration'
            });

            imageLayer.add(konvaImage);
            stage.add(imageLayer);
            stage.add(calibrationLayer);
            stage.on('contentClick', checkPoint);

            scope.calibrate = function () {
              if (!(scope.isValidMeasure() && scope.name)) {
                return;
              }

              var distPixels = ImageService.getDistance(points[0], points[1]);
              calibration.calibration = scope.measure / (distPixels * scale);
              calibration.name = scope.name;
              $rootScope.$broadcast('calibrationEnded', calibration);
              scope.cancel();
            };

            // Handle window resizing
            var windowElement = angular.element($window);
            windowElement.on('resize.calibrationResize', function (){
              scope.$apply(function() {
                var newCanvasSize = CanvasService.getSizeFromContainer(canvasContainer, image, scope.fullscreen);

                // If the container width hasn't changed, do nothing
                if (newCanvasSize.width === canvasSize.width) {
                  return;
                }

                // Save the new canvas width and scale
                canvasSize.width = newCanvasSize.width;
                canvasSize.height = newCanvasSize.height;
                scale = image.width / newCanvasSize.width;

                // Redraw image
                stage.size({
                  width: canvasSize.width,
                  height: canvasSize.height
                });
                konvaImage.size({
                  width: canvasSize.width,
                  height: canvasSize.height
                });
                imageLayer.draw();
              });
            });
            scope.$on('$destroy', function() {
              windowElement.off('resize.calibrationResize');
              restartCalibration();
              imageLayer.clear();
              imageLayer.destroyChildren();
              stage.clear();
              stage.clearCache();
              stage.destroyChildren();
              stage.destroy();
              stage = null;
              konvaImage.clearCache();
              konvaImage.destroy();
              konvaImage = null;
            });
          };

          image.src = scope.transforms.src;
        }

        var checkPoint = function () {
          if (currentStep <= 1) {
            nextStep();

            var circle = new Konva.Circle({
              x: (stage.getPointerPosition().x - 1 ) / stage.getScaleX(),
              y: (stage.getPointerPosition().y - 1) / stage.getScaleY(),
              radius: 2,
              fill: 'red',
              stroke: 'black',
              strokeWidth: 1,
              draggable: true
            });

            // add the shape to the layer
            calibrationLayer.add(circle);
            calibrationLayer.draw();

            if (currentStep === 2) {
              // Join points
              drawLine();
              scope.showMeasure = true;
            }
            scope.$apply();
          }
        };

        scope.$watch(function() {
          return scope.transforms.src;
        }, function(newValue) {
          if (newValue) {
            init();
          }
        });

        var nextStep = function () {
          currentStep++;
        };

        var drawLine = function () {
          var x, y, auxPoints = [];

          for (var i = 0; i < calibrationLayer.children.length; i++) {
            x = calibrationLayer.children[i].x();
            y = calibrationLayer.children[i].y();
            auxPoints.push(x);
            auxPoints.push(y);
            points.push({
              x: x,
              y: y
            });
          }

          var redLine = new Konva.Line({
            points: auxPoints,
            stroke: 'red',
            strokeWidth: 1.2,
            lineCap: 'round',
            lineJoin: 'round',
            draggable: true
          });
          calibrationLayer.add(redLine);
          calibrationLayer.draw();
        };

        scope.isValidMeasure = function () {
          return angular.isNumber(scope.measure) && scope.measure > 0;
        };

        scope.cancel = function () {
          scope.calibrating = false;
          restartCalibration();
        };

        scope.clear = restartCalibration;

        scope.onlyNumber = function (event) {
          // Get the Unicode value
          var keyCode = event.charCode || event.keyCode;
          // Convert the value into a character
          var keyCodeString = String.fromCharCode(keyCode);
          var regEx = new RegExp(/^\d+$/);
          if (!regEx.test(keyCodeString) && keyCodeString !== $locale.NUMBER_FORMATS.DECIMAL_SEP) {
            event.preventDefault();
          }
        };
      }
    };
  }

function konvaService() {
    var imageLayer = new Konva.Layer({});
    var calibrationLayer = new Konva.Layer({});
 
    return {
        getImageLayer: function() {
            return imageLayer;
        },
        getCalibrationLayer: function() {
            return calibrationLayer;
        }
    };
}

  angular.module('common.directives.calibrationTool', [])
    .directive('calibrationTool', calibrationTool)
    .factory('KonvaService', konvaService);
})();

(function() {
  'use strict';

  function stringToNumber() {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function(value) {
		if (value === null) {
			return null;
		} else {
      		return '' + value;
		}
      });
      ngModel.$formatters.push(function(value) {
        return parseFloat(value);
      });
    }
  };
}

  angular.module('common.directives.stringtonumber', [])
    .directive('stringToNumber', stringToNumber);
})();

(function () {
  'use strict';

  function EnterInput() {

    return function (scope, element, attrs) {
      element.bind('keydown keypress', function (event) {
        if(event.which === 13) {

          scope.$apply(function (){
            scope.$eval(attrs.enterInput);
          });

          event.preventDefault();
        }
      });
    };

  }

  angular.module('common.directives.enter-input', [])
    .directive('enterInput', EnterInput);
})();

(function () {
  'use strict';

  function GooglePlacesAutoComplete() {
    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
        city: '=',
        state: '=',
        zip: '=',
      },
      link: function($scope , element) {
        var options = {
          types: [],
        };

        element.bind('keydown',function(event){
          if(event.which === 13){
            if (event.stopPropagation) {
              event.stopPropagation();
              event.preventDefault();
            }
          }
        });

        $scope.gPlace = new window.google.maps.places.Autocomplete(element[0], options);

        window.google.maps.event.addListener($scope.gPlace, 'place_changed', function() {
          var placeInfo =  $scope.gPlace.getPlace();
          if(placeInfo.address_components) {
            var addressComponents = placeInfo.address_components
              .filter(function(component){
                switch (component.types[0]) {
                  case 'locality': // city
                    return true;
                  case 'administrative_area_level_1': // state
                    return true;
                  case 'postal_code': //postal code
                    return true;
                  default:
                    return false;
                }
              })
              .map(function(obj) {
                return obj.short_name;
              });
            $scope.$apply(function(){
              $scope.ngModel = placeInfo.name;
              $scope.city = addressComponents[0];
              $scope.state = addressComponents[1];
              $scope.zip = addressComponents[2];
            });
          }
        });

      }


    };
  }

  angular.module('common.directives.google-places-autocomplete', [])
    .directive('googlePlacesAutoComplete', GooglePlacesAutoComplete);
})();

(function () {
  'use strict';

  function NGCurrency($filter, $locale) {
    return {
      require: 'ngModel',
      scope: {
        min: '=?min',
        max: '=?max',
        currencySymbol: '@',
        ngRequired: '=?ngRequired',
        fraction: '=?fraction'
      },
      link: function (scope, element, attrs, ngModel) {

        if (attrs.ngCurrency === 'false'){ return;}

        scope.fraction = (typeof scope.fraction !== 'undefined') ? scope.fraction : 2;

        function decimalRex(dChar) {
          return RegExp('\\d|\\-|\\' + dChar, 'g');
        }

        function clearRex(dChar) {
          return RegExp('\\-{0,1}((\\' + dChar + ')|([0-9]{1,}\\' + dChar + '?))&?[0-9]{0,' + scope.fraction + '}', 'g');
        }

        function clearValue(value) {
          value = String(value);
          var dSeparator = $locale.NUMBER_FORMATS.DECIMAL_SEP;
          var cleared = null;

          if (value.indexOf($locale.NUMBER_FORMATS.DECIMAL_SEP) === -1 &&
            value.indexOf('.') !== -1 &&
            scope.fraction) {
            dSeparator = '.';
          }

          // Replace negative pattern to minus sign (-)
          var neg_dummy = $filter('currency')('-1', getCurrencySymbol(), scope.fraction);
          var neg_regexp = RegExp('[0-9.' + $locale.NUMBER_FORMATS.DECIMAL_SEP + $locale.NUMBER_FORMATS.GROUP_SEP + ']+');
          var neg_dummy_txt = neg_dummy.replace(neg_regexp.exec(neg_dummy), '');
          var value_dummy_txt = value.replace(neg_regexp.exec(value), '');

          // If is negative
          if (neg_dummy_txt === value_dummy_txt) {
            value = '-' + neg_regexp.exec(value);
          }

          if (RegExp('^-[\\s]*$', 'g').test(value)) {
            value = '-0';
          }

          if (decimalRex(dSeparator).test(value)) {
            cleared = value.match(decimalRex(dSeparator))
              .join('').match(clearRex(dSeparator));
            cleared = cleared ? cleared[0].replace(dSeparator, '.') : null;
          }

          return cleared;
        }

        function getCurrencySymbol() {
          if (angular.isDefined(scope.currencySymbol)) {
            return scope.currencySymbol;
          } else {
            return $locale.NUMBER_FORMATS.CURRENCY_SYM;
          }
        }

        function reformatViewValue() {
          var formatters = ngModel.$formatters,
            idx = formatters.length;

          var viewValue = ngModel.$$rawModelValue;
          while (idx--) {
            viewValue = formatters[idx](viewValue);
          }

          ngModel.$setViewValue(viewValue);
          ngModel.$render();
        }

        ngModel.$parsers.push(function (viewValue) {
          var cVal = clearValue(viewValue);
          //return parseFloat(cVal);
          // Check for fast digitation (-. or .)
          if (cVal === '.' || cVal === '-.') {
            cVal = '.0';
          }
          return parseFloat(cVal);
        });

        element.on('blur', function () {
          ngModel.$commitViewValue();
          reformatViewValue();
        });

        ngModel.$formatters.unshift(function (value) {
          return $filter('currency')(value, getCurrencySymbol(), scope.fraction);
        });

        ngModel.$validators.min = function (cVal) {
          if (!scope.ngRequired && isNaN(cVal)) {
            return true;
          }
          if (typeof scope.min !== 'undefined') {
            return cVal >= parseFloat(scope.min);
          }
          return true;
        };

        scope.$watch('min', function () {
          ngModel.$validate();
        });

        ngModel.$validators.max = function (cVal) {
          if (!scope.ngRequired && isNaN(cVal)) {
            return true;
          }
          if (typeof scope.max !== 'undefined') {
            return cVal <= parseFloat(scope.max);
          }
          return true;
        };

        scope.$watch('max', function () {
          ngModel.$validate();
        });


        ngModel.$validators.fraction = function (cVal) {
          if (!!cVal && isNaN(cVal)) {
            return false;
          }

          return true;
        };

        scope.$on('currencyRedraw', function () {
          ngModel.$commitViewValue();
          reformatViewValue();
        });

        element.on('focus', function () {
          var viewValue = ngModel.$$rawModelValue;

          if (isNaN(viewValue) || viewValue === '' || viewValue === null) {
            viewValue = '';
          }
          else {
            viewValue = parseFloat(viewValue).toFixed(scope.fraction);
          }
          ngModel.$setViewValue(viewValue);
          ngModel.$render();
        });
      }

    };
  }

  angular.module('common.directives.ng-currency', [])
    .directive('ngCurrency', NGCurrency);
})();

(function() {
  'use strict';

  function convertToNumber() {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        ngModel.$parsers.push(function(val) {
          return parseInt(val, 10);
        });
        ngModel.$formatters.push(function(val) {
          return '' + val;
        });
      }
    };
  }

  angular.module('common.directives.convert-to-number', [])
    .directive('convertToNumber', convertToNumber);
})();

(function () {
  'use strict';

  function NumberRangeLimit() {
    return {
      require: 'ngModel',
      scope: {
        ngModel: '='
      },
      link: function(scope, element, attributes,ngModelCtrl) {
        element.on('keydown keyup', function(e) {
          if (Number(ngModelCtrl.$modelValue) > Number(attributes.rangeMax) &&
            e.keyCode !== 46 && // delete
            e.keyCode !== 8 // backspace
          ) {
            e.preventDefault();
            scope.$apply(function(){
              scope.ngModel = Number(attributes.rangeMax);
            });
          }
          if (Number(ngModelCtrl.$modelValue) < Number(attributes.rangeMin) &&
            e.keyCode !== 46 && // delete
            e.keyCode !== 8 // backspace
          ) {
            e.preventDefault();
            scope.$apply(function(){
              scope.ngModel = Number(attributes.rangeMin);
            });
          }

        });
      }
    };
  }

  angular.module('common.directives.number-range-limit', [])
    .directive('numberRangeLimit', NumberRangeLimit);
})();

(function () {
  "use strict";

  angular.module("common.directives.stop-click-propagation", [])

  .directive('stopClickPropagation', function () {
    return function(scope, el) {
      el.on("click", function(event) {
        event.preventDefault()
        event.stopPropagation()
      })
    }
  })

})();

(function() {
  "use strict";

  angular.module("common.filters.height", [])

  .filter("toFeetAndInch", toFeetAndInch)

  function toFeetAndInch() {
    return function(value, metric) {
      if (metric) {
        value = parseFloat(value)
        if (value) {
          var unit
          if (metric === 1) {
            unit = value % 12
            return [
              Math.floor(value / 12),
              "ft",
              unit % 1 === 0 ? unit : unit.toFixed(1),
              "in"
            ].join(" ")
          }
          if (metric === 2) {
            unit = value % 100
            return [
              Math.floor(value / 100),
              "m",
              unit % 1 === 0 ? unit : unit.toFixed(1),
              "cm"
            ].join(" ")
          }
        }
      }
    }
  }

})();

(function () {
  'use strict';


  function HidePhone() {
    return function (phoneNumber) {
      if (!phoneNumber) {
        return '';
      }

      var value = phoneNumber.toString();
      var hidden = '';
      for (var i = 0; i < value.length - 5; i++) {
        hidden += '*';
      }

      // Display only the first 3 and the last 2 characters
      return (
        value.slice(0, 3) +
        hidden +
        value.slice(hidden.length + 3, value.length)
      );
    };
  }

  angular.module('common.filters.hide-phone', [])
    .filter('hidePhone', HidePhone);
})();
(function () {
  "use strict"

  angular.module("common.filters.props-filter", [])

  .filter("propsFilter", propsFilter)

  /**
   * AngularJS default filter with the following expression:
   * ng-repeat="person in people | filter: {name: $select.search, age: $select.search}"
   * performs an AND between 'name: $select.search' and 'age: $select.search'.
   * We want
   * - to perform an OR.
   * - a simpler syntax since the search is the same for every property
   * - to transforms the data to match its representation
   *
   * Usage
   * ng-repeat="person in people | propsFilter: {filter:text, props:['name', 'birthday|date|MM/dd/yyyy']}"
   */
  function propsFilter($filter) {
    return function(items, opts) {
      if (angular.isArray(items) && opts.filter) {
        var prop
        var propPipe
        var propNested
        var propPipeArgs
        var propParts
        var propValue
        var filter = opts.filter.toLowerCase()
        return items.filter(function(item) {
          for (var i = 0; i < opts.props.length; i++) {
            prop = opts.props[i]
            propPipe = null
            propValue = null
            if (prop.indexOf("|") !== -1) {
              propParts = prop.split("|")
              prop = propParts[0]
              propPipe = propParts[1]
              propPipeArgs = propParts[2]
            }
            if (prop.indexOf(".") !== -1) {
              propNested = true
            }
            if (item.hasOwnProperty(prop) || propNested) {
              propValue = elvisEx(item, prop)
              if (propPipe) {
                if (propPipe === "date") {
                  propValue = $filter(propPipe)(propValue, propPipeArgs)
                }
                else if (propPipe === "tel") {
                  propValue = $filter(propPipe)(propValue)
                }
                else {
                  console.error(["[propsFilter] unknown pipe:", propPipe, " with args:", propPipeArgs].join(""))
                  propValue = null
                }
              }
            }
            if (propValue && propValue.toString().toLowerCase().indexOf(filter) !== -1) {
              return true
            }
          }
          return false
        })
      }
      return items
    }
  }

})();

(function () {
  'use strict';

  function Sanitize($sce) {
    return function(htmlCode){
      return $sce.trustAsHtml(htmlCode);
    };
  }

  angular.module('common.filters.sanitize', [])
    .filter('sanitize',Sanitize);
})();

(function() {
  'use strict';

  function signedDate() {
    return function(date) {
      return moment(date).format('[Signed on] MM/DD/YYYY [at] hh:mma ') + moment.tz(moment.tz.guess()).zoneAbbr()
    };
  }

  angular.module('common.filters.signed-date', [])
    .filter('signedDate', signedDate);
})();

(function () {
  'use strict';

  angular.module('common.filters.trustAsResourceUrl', [])
    .filter('trustAsResourceUrl', TrustAsResourceUrl);

  function TrustAsResourceUrl($sce) {
    return function(resourceUrl){
      return $sce.trustAsResourceUrl(resourceUrl);
    };
  }

})();

(function() {
  "use strict";

  angular.module("common.filters.yearsOld", [])

  .filter("yearsOld", function(PatientsService){
    return function(iso_date_string) {
      return PatientsService.getYearsOld(iso_date_string)
    }
  })
})();

(function() {
  'use strict';

  function httpInterceptor($q, $log) {
    return {
      request: function(config) {
        return config;
      },
      requestError: function(rejection) {
        $log.debug(rejection);
        return $q.reject(rejection);
      },
      response: function(response) {
        // $log.debug('response: ', response);
        return response;
      },
      responseError: function(rejection) {
        $log.debug(rejection);
        return $q.reject(rejection);
      }
    };
  }

  angular.module('common.interceptors.http', [])
    .factory('httpInterceptor', httpInterceptor);
})();

(function () {
  "use strict"

  angular.module("common.services.accounts", [])

    .service("AccountsService", function ($http) {
      this.sendEmail = function (practice, id, kind) {
        return $http.post("/api/v3/practice/" + practice + "/~authentication/account/" + id + "/send-email/" + kind)
      }
    })

})();

(function() {
  'use strict';

function analyticsService($http) {
    /*
    function setDigits(intgr, length) {
  	    var strIntgr = '' + intgr;
        while (strIntgr.length < length) {
    	    strIntgr = '0' + strIntgr;
        }
        return strIntgr;
    }
    */
    return {
        toAPIDateTime: function(date) {
          return date.toISOString();
        },
        toAPIDate: function(date) {
          var month = '' + (date.getMonth() + 1);
          var day = '' + date.getDate();
          var year = date.getFullYear();

          if (month.length < 2) {
            month = '0' + month;
          }
          if (day.length < 2) {
            day = '0' + day;
          }

          return [year, month, day].join('-');
        },
        fromAPIDate: function(apiDate) {  //'yyyy-mm-dd'
          apiDate = new Date(apiDate);
          return new Date(apiDate.setHours(apiDate.getHours() + apiDate.getTimezoneOffset()/60));
        },
        fromAPIDateTime: function(apiDateTime) { //'yyyy-mm-ddThh:mm:ss.mmmmmmZ'
            return new Date(Date.parse(apiDateTime));
        },
        //getTreatmentRevenueSumsByProviderBeginningThrough2: function (beginningDate, throughDate) {
        //    return $http.get('/api/v1/treatmentrevenuesumsbyprovider2/from/' + setDigits(beginningDate.getUTCFullYear(),4) + '/' + setDigits(beginningDate.getUTCMonth() + 1,2) + '/' + setDigits(beginningDate.getUTCDate(),2) + '/through/' + setDigits(throughDate.getUTCFullYear(),4) + '/' + setDigits(throughDate.getUTCMonth() + 1,2) + '/' + setDigits(throughDate.getUTCDate(),2) + '/');
        //},
        getTreatmentRevenueSumsByProviderBeginningThrough: function (practiceId, fromDate, throughDate) {
            return $http.get('/api/v1/treatmentrevenuesumsbyprovider/practices/' + practiceId + '/', {
                params: {
                    from_datetime: this.toAPIDateTime(fromDate),
                    through_datetime: this.toAPIDateTime(throughDate)
                }
            });
        },
        getTreatmentRevenueSumsByReferringPracticeBeginningThrough: function (practiceId, fromDate, throughDate) {
            return $http.get('/api/v1/treatmentrevenuesumsbyreferringpractice/practices/' + practiceId + '/', {
                params: {
                    from_datetime: this.toAPIDateTime(fromDate),
                    through_datetime: this.toAPIDateTime(throughDate)
                }
            });
        },
        getPatientsArAccountsTotals: function (practiceId) {
            return $http.get('/api/v1/patientsaraccountstotals/practices/' + practiceId + '/');
        },
        //getTreatmentRevenueSumsByProcedureBeginningThrough: function (beginningDate, throughDate) {
        //    return $http.get('/api/v1/treatmentrevenuesumsbyprocedure/from/' + setDigits(beginningDate.getUTCFullYear(),4) + '/' + setDigits(beginningDate.getUTCMonth() + 1,2) + '/' + setDigits(beginningDate.getUTCDate(),2) + '/through/' + setDigits(throughDate.getUTCFullYear(),4) + '/' + setDigits(throughDate.getUTCMonth() + 1,2) + '/' + setDigits(throughDate.getUTCDate(),2) + '/');
        //},
        getTreatmentRevenueSumsByProcedureBeginningThrough: function (practiceId, fromDate, throughDate) {
            return $http.get('/api/v1/treatmentrevenuesumsbyprocedure/practices/' + practiceId + '/', {
              params: {
                  from_datetime: this.toAPIDateTime(fromDate),
                  through_datetime: this.toAPIDateTime(throughDate)
              }
          });
        },
        getTreatmentsForDateRange: function (practiceId, fromDate, throughDate) {
            return $http.get('/api/v1/treatmentsfordaterange/practices/' + practiceId + '/', {
                params: {
                    from_date: fromDate,
                    through_date: throughDate
                }
            });
        },
        getPaymentsForDateRange: function (practiceId, fromDateTime, throughDateTime) {
            return $http.get('/api/v1/paymentsfordaterange/practices/' + practiceId + '/', {
                params: {
                    from_datetime: fromDateTime,
                    through_datetime: throughDateTime
                }
            });
        },
        getActiveClaims: function (practiceId) {
            return $http.get('/api/v1/activeclaimslist/practices/' + practiceId + '/');
        },
        getInsuranceUnclaimedPatientTotals: function (practiceId) {
            return $http.get('/api/v1/insuranceunclaimedpatienttotals/practices/' + practiceId + '/');
        }
    };
}

    angular.module('common.services.analytics', [])

        .factory("AnalyticsServiceNext", function Service(
            $http,
            PrincipalService
          ) {
          return {
            getClaimCompleted: function() {
              var practiceId = PrincipalService.getPracticeId()
              return $http.get("/api/v3/practice/" + practiceId + "/~analytics/claim/completed")
            },
            getClaimUnclaimed: function() {
              var practiceId = PrincipalService.getPracticeId()
              return $http.get("/api/v3/practice/" + practiceId + "/~analytics/claim/unclaimed")
            },
            getGLAccountARTotals: function() {
              var practiceId = PrincipalService.getPracticeId()
              return $http.get("/api/v3/practice/" + practiceId + "/~analytics/glaccount/ar-totals")
            },
          }
        })

        .factory('AnalyticsService', analyticsService);
})();

(function() {
  'use strict';

// authorization service's purpose is to wrap up authorize functionality
// it basically just checks to see if the principal is authenticated and checks the root state
// to see if there is a state that needs to be authorized. if so, it does a role check.
// this is used by the state resolver to make sure when you refresh, hard navigate, or drop onto a
// route, the app resolves your identity before it does an authorize check. after that,
// authorize is called from $stateChangeStart to make sure the principal is allowed to change to
// the desired state

  angular
    .module('common.services.authorization', [])
    .factory('AuthorizationService', authorizationService);

  function authorizationService($rootScope, $state, $sessionStorage, $http, $window, PrincipalService) {

    return {
      authorize: function() {
        return PrincipalService.identity().then(function() {
          if ($rootScope.toState.data.roles && $rootScope.toState.data.roles.length > 0 && !PrincipalService.isInAnyRole($rootScope.toState.data.roles)) {
            if (PrincipalService.isAuthenticated()) {
              // user is signed in but not authorized for desired state
              $state.go('accessdenied');
            }
            else {
              // send them to the signin state, so you can return them when you're done
              $rootScope.returnToState = $rootScope.toState;
              $rootScope.returnToStateParams = $rootScope.toState.params;
              // now, send them to the signin state so they can log in
              if ($rootScope.toState && $rootScope.toState.params) {
                $sessionStorage.redirectOnLogin = {"name": $rootScope.toState.name, "params": $rootScope.toStateParams}
              }
              $state.go('signin');
            }
          }
        });
      },
      completeWizard: function () {
        return $http.post('/api/v1/auth/wizard_complete');
      },
      hasFrontendPermission: function(frontendPermission) {
        var perms = $window.permissionsMap[frontendPermission];
        if (!perms) {
          console.log('INVALID PERMISSION', frontendPermission);
          return false;
        }
        if (!PrincipalService.isAuthenticated()) {
          return false
        }
        for (var i=0; i < perms.length; i++) {
          if (!PrincipalService.hasPermission(perms[i])) {
            // console.log('MISSING', frontendPermission, perms[i]);
            return false;
          }
        }
        return true;
      },
      hasAnyFrontendPermissions: function(frontendPermissions) {
        for (var i=0; i < frontendPermissions.length; i++) {
          if (this.hasFrontendPermission(frontendPermissions[i])) {
            return true;
          }
        }
        return false;
      },
    };
  }

})();

(function() {
  'use strict';

function authenticationService($http, $q) {
  return {

    formatNumber: function(format, posInteger, withDelimiters) {
      var firstDelimiterIndex = format.indexOf('$');
      var lastDelimiterIndex = format.lastIndexOf('$');

      var num = '';
      for (var i = 0; i < lastDelimiterIndex-firstDelimiterIndex-1; i++) {
        num += '0';
      }
      num += posInteger;
      num = num.slice(-(lastDelimiterIndex-firstDelimiterIndex-1));
      if (withDelimiters) {
        return format.slice(0,firstDelimiterIndex+1) + num + format.slice(lastDelimiterIndex );

      } else {
        return format.slice(0,firstDelimiterIndex) + num + format.slice(lastDelimiterIndex + 1);
      }
    },
    extractPosInteger: function(format) {
      var firstDelimiterIndex = format.indexOf('$');
      var lastDelimiterIndex = format.lastIndexOf('$');

      var posInteger = format.slice(firstDelimiterIndex+1, lastDelimiterIndex);
      return parseInt(posInteger);
    },
    getPractices: function () {
      return $http.get('/api/v1/practices/');
    },
    getPractice: function (practiceId) {
      return $http.get('/api/v1/practices/' + practiceId + '/');
    },
    getPracticeEx: function (practiceId) {
      return $http.get('/api/v1/practices/' + practiceId + '/').then(
        function(response) {
          var deferred = $q.defer()
          if (response.data.picture) {
            try {
              var img = new Image()
              img.crossOrigin = "anonymous"
              img.addEventListener("load", function() {
                try {
                  var canvas = document.createElement("canvas")
                  canvas.width = img.naturalWidth
                  canvas.height = img.naturalHeight
                  canvas.getContext("2d").drawImage(img, 0, 0)
                  response.data.pictureDataURL = canvas.toDataURL("image/png")
                  deferred.resolve(response)
                }
                catch (e) {
                  deferred.resolve(response)
                }
              })
              img.addEventListener("error", function() {
                deferred.resolve(response)
              })
              img.src = response.data.picture
            }
            catch (e) {
              deferred.resolve(response)
            }
          }
          else {
            deferred.resolve(response)
          }
          return deferred.promise
        }
      )
    },
    getPracticeSchema: function (practiceId) {
      return $http({method: 'OPTIONS', url: '/api/v1/practices/' + practiceId + '/'});
    },
    updatePractice: function (practiceId, practice) {
        return $http.put('/api/v1/practices/' + practiceId + '/', practice);
    },
    getUserAccounts: function (practiceId) {
      return $http.get('/api/v1/account/practices/' + practiceId + '/');
    },
    getUserAccount: function (accountId) {
      return $http.get('/api/v1/account/' + accountId + '/');
    },
    updateUserAccount: function (accountId, data) {
      return $http.put('/api/v1/account/' + accountId + '/', data);
    },
    createUserAccount: function (practiceId, data) {
      return $http.post('/api/v1/account/practices/' + practiceId + '/', data);
    },
    getStaffRoles: function () {
      return $http.get('/api/v1/roles/staff/');
    },
    getProviderRoles: function () {
      return $http.get('/api/v1/roles/providers/');
    },
    getProviderAccounts: function (practiceId) {
      return $http.get('/api/v1/account/practices/' + practiceId + '/provider/');
    },
    getProviderAndAssociatesAccounts: function (practiceId) {
      return $http.get('/api/v1/account/practices/' + practiceId + '/providers-and-associates/');
    },
    getPracticeAdminAndProviderAccounts: function (practiceId, filterByActive) {
      var httpGetReq =  $http.get('/api/v1/account/practices/' + practiceId + '/provider_and_admin/')
      if(filterByActive) {
        httpGetReq
        .then(function(data) {
          data.data = data.data.filter(
            function (dItem) { return dItem.is_active }
          )
          return data;
        })
      } 
      return httpGetReq
    }
  };
}

  angular.module('common.services.authentication', [])
    .factory('AuthenticationService', authenticationService);
})();

(function() {
  'use strict';

function billingService($http) {
  function setDigits(intgr, length) {
  	var strIntgr = '' + intgr;
    while (strIntgr.length < length) {
    	strIntgr = '0' + strIntgr;
    }
    return strIntgr;
  }

  return {
    AR_TYPE: {
          REVENUEINVOICELINEITEM:   'revenueinvoicelineitem',
          INSDISCOUNTREVENUEINVOICELINEITEM:'insurancediscountrevenueinvoicelineitem',
          INVOICELINEITEM:          'invoicelineitem', // all invoice line item types should incllude this string
          MISCINVOICELINEITEM:      'miscinvoicelineitem',
          CLAIMERINVOICELINEITEM:   'claimerinvoicelineitem',
          CLAIMEE:                  'claimee',
          //the following can be applied to the preceeding
          PAYMENT:                  'payment',
          WRITEOFF:                 'writeoff',
          CHARGEBACK:               'chargeback'
    },
    getArTypeDisplayString: function(arType) {
      if (arType === this.AR_TYPE.REVENUEINVOICELINEITEM) {
        return 'Line Item';
      } else if (arType === this.AR_TYPE.INSDISCOUNTREVENUEINVOICELINEITEM) {
        return 'Line Item';
      } else if (arType === this.AR_TYPE.INVOICELINEITEM) {
        return 'Line Item';
      } else if (arType === this.AR_TYPE.MISCINVOICELINEITEM) {
        return 'Line Item - Misc.';
      } else if (arType === this.AR_TYPE.PAYMENT) {
        return 'Payment (-) / Refund (+)';
      } else if (arType === this.AR_TYPE.WRITEOFF) {
        return 'Writeoff (-) / Writeoff Reversal (+)';
      } else if (arType === this.AR_TYPE.CLAIMERINVOICELINEITEM) {
        return 'Line Item - Claim Credit';
      } else if (arType === this.AR_TYPE.CHARGEBACK) {
        return 'Chargeback to patient (- in insurance AR, + in patient AR) / Chargeback to insurance (+ in insuranceAR, - in patient AR)';
      } else if (arType === this.AR_TYPE.CLAIMEE) {
        return 'Line Item';
      }
    },
    /**
     *
     * @param {String | Number} amount - a string decimal number or float
     * @param {integer} roundToDecimalPlaces - round the result to this number of decimal places. Default: 2
     * @return {String} a string representation of amount rounded to roundToDecimalPlaces
     */
    getAmountToFixed: function(amount, roundToDecimalPlaces) {
      roundToDecimalPlaces = (typeof roundToDecimalPlaces !== 'undefined') ?  roundToDecimalPlaces : 2;

      var amountToFixed = null;

      if (typeof amount === 'number') {
        amountToFixed = amount.toFixed(roundToDecimalPlaces);
      } else {
        amountToFixed = parseFloat(amount).toFixed(roundToDecimalPlaces);
      }
      return (amountToFixed === '-0.00') ? '0.00' : amountToFixed; //deal with stupud JS negative zero, e.g. -0.00
    },
    createJe: function (description, memo, arjeds, jeds) {
		  var je = {};
		  je.description = description;
      je.memo = memo;
      if (jeds) {
        je.journal_entry_details = jeds;
      } else {
        je.journal_entry_details = [];
      }
      if (arjeds) {
        je.ar_journal_entry_details = arjeds;
      } else {
        je.ar_journal_entry_details = [];
      }
      return je;
    },
    /**
     * @param {String}    description
     * @param {String}    memo
     * @param {Number}    glAccount - an integer key to the glAccount to which to apply jed.
     * @param {String | Number}    amount - a decimal number up to two places.
     * @return the created jed or null if amount is not a decimal number up to two places.
     */
    createJed: function(description, memo, glAccount, amount, currency) {
      var amountToFixed = this.getAmountToFixed(amount);
      if (!amountToFixed) { return null; } //not a valid amount

      var jed = {};
      jed.description = description;
      jed.memo = memo;
      jed.gl_account = glAccount;
 		  jed.amount = amountToFixed;
  	  jed.currency = currency;
      return jed;
    },
    createArjed: function(jed, arType, treatment, claim, invoice, paymentFor, writeoffFor, chargebackFor) {
	    var arjed = {};
	    arjed.journal_entry_detail = jed;
	    arjed.type = arType;
      if (treatment) {
        if (typeof treatment === 'object') {
	        arjed.treatment = treatment.id;
          arjed.treatmentInfo = treatment;
        } else {
	        arjed.treatment = treatment;
        }
      }
      if (claim) {
        if (typeof claim === 'object') {
	        arjed.claim = claim.id;
          arjed.claimInfo = claim;
        } else {
	        arjed.claim = claim;
        }
      }
      if (invoice) {
        if (typeof invoice === 'object') {
	        arjed.invoice = invoice.id;
          arjed.invoiceInfo = invoice;
        } else {
	        arjed.invoice = invoice;
        }
      }
      if (paymentFor) {
        if (typeof paymentFor === 'object') {
	        arjed.payment_for = paymentFor.id;
        } else {
	        arjed.payment_for = paymentFor;
        }
      }
      if (writeoffFor) {
        if (typeof writeoffFor === 'object') {
	        arjed.writeoff_for = writeoffFor.id;
        } else {
	        arjed.writeoff_for = writeoffFor;
        }
      }
      if (chargebackFor) {
        if (typeof chargebackFor === 'object') {
	        arjed.chargeback_for = chargebackFor.id;
        } else {
	        arjed.chargeback_for = chargebackFor;
        }
      }
	    return arjed;
    },
    /**
     * creates an arjed that contains either a corresponding partnerArjed or partnerJed:
     *      arjed                  arjed
     *        |              or      |
     *     partnerArJed           partnerJed   (<- balancing jed)
     *
     * that can be transformed into a proper double entry bookkeeping journal entry containing
     * the two jed (journal entry details) whose amounts sum to zero:
     *        je                     je
     *      |     |        or      |      |
     *     jed    jed              jed    jed
     *      |     |                |
     *    arjed   arjed           arjed
     *
     * @param {Array}     arjeds - the array of arjeds into which to push the new arjed
     * @param {String}    description
     * @param {String}    memo
     * @param {Number}    amount - A float. Negative amount represents a debit from the added arjed's gl account and credited to the partner account, positive amount represents a credit to the added arjed's gl account and debited from the partner account.
     * @param {String}    currency
     * @param {Number}    arGlAccount -  the arjed's gl account number.
     * @param {String}    arType - 'revenueinvoicelineitem' | 'insurancediscountinvoicelineitem' | 'miscinvoicelineitem' | 'claimerinvoicelineitem' | 'payment' | 'writeoff' | 'claimer' | 'claimee'
     * @param {Number}    partnerGlAccount - the account number of the balancing jed.
     * @param {Boolean}   isPartnerGlAccountAr - indicates whether or not the balancing jed is ar and thus a partnerArjed or partnerJed.
     * @param {String}    partnerArType -
     * @param {Object}    treatment -  a treatment to attach to the arjed and partnerArjed (if applicable)
     * @param {Object}    claim - a claim to attach to the arjed and partnerArjed (if applicable).
     * @return Object     arjed - the ar that was created and added to arjeds.
     */
    addArToArjeds: function(
      arJeds,
      description,
      memo,
      amount,
      currency,
      arGlAccount,
      arType,
      partnerGlAccount,
      isPartnerGlAccountAr,
      partnerArType,
      treatment,
      claim
    ) {
      var jed = this.createJed(description, memo, arGlAccount, - amount, currency);
      var arjed = this.createArjed(jed, arType, treatment, claim);

		  arjed.description = description;

      var partnerJed = this.createJed(description, memo, partnerGlAccount, amount, currency);

      if (partnerArType) {
        var partnerArjed = this.createArjed(partnerJed, partnerArType, treatment, claim);
		    arjed.partnerArJed = partnerArjed;
      } else {
		    arjed.partnerJed = partnerJed;
      }

		  arJeds.push(arjed);
      return arjed;
    },
    /*
     * transforms arjeds that contains either a corresponding partnerArjed or partnerJed:
     *      arjed                  arjed
     *        |              or      |
     *     partnerArJed           partnerJed   (<- balancing jed)
     *
     * into a proper double entry bookkeeping journal entry containing
     * the two jed (journal entry details) whose amounts sum to zero:
     *        je                     je
     *      |     |        or      |      |
     *     jed    jed              jed    jed
     *      |     |                |
     *    arjed   arjed           arjed
     *
     * @param {Array}     arjeds objects
     * @return {Array}    jes objects
     */
    getJesFromArjeds: function(
      arjeds
    ) {

      var jes = [];

		  for (var i = 0; i < arjeds.length; i++) {
			  var arjed = arjeds[i];

        var je = this.createJe(arjed.description, arjed.memo);

        //copy arjed's description, in case it was changed, to the jed.description
			  arjed.journal_entry_detail.description = arjed.description;
        //delete description from arjed since it is  not a property on the back end's arjed model
			  delete arjed.description;

			  if ('partnerJed' in arjed) {
				  var partnerJed = arjed.partnerJed;
				  delete arjed.partnerJed;
          //refresh partnerJed values in case they were changed
				  partnerJed.description = je.description;
				  partnerJed.memo = je.memo;
				  partnerJed.amount = -arjed.journal_entry_detail.amount; //converting values where debits were temporarily stored as positives for display back to negatives.
				  partnerJed.currency = arjed.journal_entry_detail.currency;
				  je.journal_entry_details.push(partnerJed);
			  }

			  if ('partnerArJed' in arjed) {
				  var partnerArJed = arjed.partnerArJed;
				  delete arjed.partnerArJed;
          //refresh partnerJed values in case they were changed
				  partnerArJed.journal_entry_detail.description = je.description;
				  partnerArJed.journal_entry_detail.memo = je.memo;
				  partnerArJed.journal_entry_detail.amount = -arjed.journal_entry_detail.amount; //converting values where debits were temporarily stored as positives for display back to negatives.
				  partnerArJed.journal_entry_detail.currency = arjed.journal_entry_detail.currency;
				  if (arjed.treatment) {
					  partnerArJed.treatment = arjed.treatment;
				  }
				  if (arjed.claim) {
					  partnerArJed.claim = arjed.claim;
				  }
				  if (arjed.invoice) {
					  partnerArJed.invoice = arjed.invoice;
				  }
				  je.ar_journal_entry_details.push(partnerArJed);
			  }

			  je.ar_journal_entry_details.push(arjed);
			  jes.push(je);
  		}
      return jes;
    },

    getAccountsForPractice: function (practiceId) {
      return $http.get('/api/v1/glaccounts/practices/' + practiceId + '/');
    },
    createAccountForPractice: function (practiceId, account) {
      return $http.post('/api/v1/glaccounts/practices/' + practiceId + '/', account);
    },
    getAccount: function (accountId) {
      return $http.get('/api/v1/glaccounts/' + accountId + '/');
    },
    updateAccount: function (accountId, account) {
        return $http.put('/api/v1/glaccounts/' + accountId + '/', account);
    },
    //for obtaining the balance of a GL account through a day. Most relevant to balance sheet accounts.
    getAccountBalanceThrough: function (accountId, throughDate) {
      return $http.get('/api/v1/glaccounts/' + accountId + '/balancethrough/' + setDigits(throughDate.getFullYear(),4) + '/' + setDigits(throughDate.getMonth() + 1,2) + '/' + setDigits(throughDate.getDate(),2) + '/');
    },
    getAccountSumBeginningThrough: function (accountId, beginningDate, throughDate) {
      return $http.get('/api/v1/glaccounts/(?P<gl_account_id>[0-9]+)/sumbeginning/' + setDigits(beginningDate.getFullYear(),4) + '/' + setDigits(beginningDate.getMonth() + 1,2) + '/' + setDigits(beginningDate.getDate(),2) + '/through/' + setDigits(throughDate.getFullYear(),4) + '/' + setDigits(throughDate.getMonth() + 1,2) + '/' + setDigits(throughDate.getDate(),2) + '/');
    },
    getJournalEntriesForPractice: function (practiceId) {
      return $http.get('/api/v1/journalentries/practices/' + practiceId + '/');
    },
    addReversingJournalEntry: function (journalEntryId) {
      return $http.get('/api/v1/journalentries/' + journalEntryId + '/addreversingjournalentry/');
    },
    getJournalEntriesForAccount: function (accountId) {
      return $http.get('/api/v1/journalentries/glaccounts/' + accountId + '/');
    },
    getAssociatedArJournalEntryDetails: function (journalEntryDetailId) {
      return $http.get('/api/v1/arjournalentrydetails/associatedwith/' + journalEntryDetailId + '/');
    },
    createJournalEntryForPractice: function (practiceId, journalEntry) {
      return $http.post('/api/v1/journalentries/practices/' + practiceId + '/', journalEntry);
    },
    getArJournalEntryDetailsForAccount: function (accountId) {
      return $http.get('/api/v1/arjournalentrydetails/glaccounts/' + accountId + '/');
    },
    getClaimeeArJournalEntryDetailsForPatient: function (accountId, patientId, onlyWithoutClaim) {
      return $http.get('/api/v1/arjournalentrydetails/glaccounts/' + accountId + '/patients/' + patientId + '/claimee/onlywithoutclaim/' + onlyWithoutClaim + '/');
    },
/*
    extendIntoArJournalEntryDetail: function (journalEntryDetailId, arJournalEntryDetail) {
      return $http.post('/api/v1/arjournalentrydetails/extendjournalentrydetail/' + journalEntryDetailId + '/', arJournalEntryDetail);
    },
*/
    getArJournalEntryDetail: function (arJournalEntryDetailId) {
      return $http.get('/api/v1/arjournalentrydetails/' + arJournalEntryDetailId + '/');
    },
    updateArJournalEntryDetail: function (arJournalEntryDetailId, arJournalEntryDetail) {
        return $http.put('/api/v1/arjournalentrydetails/' + arJournalEntryDetailId + '/', arJournalEntryDetail);
    },
    getInvoicesForAccount: function (accountId) {
      return $http.get('/api/v1/invoices/glaccounts/' + accountId + '/');
    },
    createInvoiceForAccount: function (accountId, invoice) {
      return $http.post('/api/v1/invoices/glaccounts/' + accountId + '/', invoice);
    },
    getInvoice: function (invoiceId) {
      return $http.get('/api/v1/invoices/' + invoiceId + '/');
    },
    updateInvoice: function (invoiceId, invoice) {
        return $http.put('/api/v1/invoices/' + invoiceId + '/', invoice);
    },
    printInvoice: function (invoiceId) {
        return $http.get('/api/v1/invoices/' + invoiceId + '/print/');
    },
    getClaimsForAccount: function (accountId, includeCompleted) {
      includeCompleted = (typeof includeCompleted !== 'undefined') ? includeCompleted : true;
      return $http.get('/api/v1/claims/glaccounts/' + accountId + '/includecompleted/' + includeCompleted + '/');
    },
    createClaimForAccount: function (accountId, claim) {
      return $http.post('/api/v1/claims/glaccounts/' + accountId + '/', claim);
    },
    submitClaim: function(claimId, operation) {
      return $http.get('/api/v1/claims/' + claimId + '/operation/' + operation + '/');
    },
    getClaimStatus: function(claimId) {
      return $http.get('/api/v1/claims/' + claimId + '/submit/status/');
    },
    getClaim: function (claimId) {
      return $http.get('/api/v1/claims/' + claimId + '/');
    },
    updateClaim: function (claimId, claim) {
        return $http.put('/api/v1/claims/' + claimId + '/', claim);
    },
	  getEligibility: function(patientId, isForOtherCoverage) {
        //return $http.get('/api/v1/eligibilities/patients/' + patientId + '/providers/' + providerId + '/locations/' + locationId + '/forothercoverage/' + isForOtherCoverage.toString() + '/');
        return $http.get('/api/v1/eligibilities/patients/' + patientId + '/forothercoverage/' + isForOtherCoverage.toString() + '/');
	  },
    /**
     * Returns the data in a object heirarchy required for the claim submit api.
     * submitClaim
     *      patient
     *          insurance
     *              company
     *              subscriber
     *          other_coverage
     *              company
     *              subscriber
     *      services
     *          treatments []
     *          provider
     *          location
     *      billing
     *          provider
     *          location
     *
     * @return an object heirarchy of the data required for the claim submit api.
     */
    CLAIM_OPERATIONS: {
        MODEL__CREATE:                          'create',
        MODEL__UPDATE:                          'update',
        SUBMIT__PREAUTHORIZE_INSURANCE:         'preauthorize_insurance',
        SUBMIT__PREAUTHORIZE_OTHER_COVERAGE:    'preauthorize_other_coverage',
        SUBMIT__CLAIM_INSURANCE:                'claim_insurance',
        SUBMIT__CLAIM_INSURANCE_MANUAL:         'claim_insurance_manual',
        SUBMIT__VOID_INSURANCE_CLAIM:           'void_insurance_claim',
        SUBMIT__VOID_INSURANCE_CLAIM_MANUAL:    'void_insurance_claim_manual',
        SUBMIT__CORRECT_INSURANCE_CLAIM:        'correct_insurance_claim',
        SUBMIT__CLAIM_OTHER_COVERAGE:           'claim_other_coverage',
        SUBMIT__CLAIM_OTHER_COVERAGE_MANUAL:    'claim_other_coverage_manual',
        SUBMIT__VOID_OTHER_COVERAGE_CLAIM:      'void_other_coverage_claim',
        SUBMIT__VOID_OTHER_COVERAGE_CLAIM_MANUAL:      'void_other_coverage_claim_manual',
        SUBMIT__CORRECT_OTHER_COVERAGE_CLAIM:   'correct_other_coverage_claim',
        //REMITTANCE__ACCEPT_PAYMENT:             'accept_remittance_payment',
        //REMITTANCE__WRITEOFF:                   'writeoff',
        RECEIVE__INSURANCE_EOB:                 'receive_insurance_eob',
        RECEIVE__OTHER_COVERAGE_EOB:            'receive_other_coverage_eob',
        MARK__COMPLETED:                        'mark_completed',

        CLAIM_STATE_MACHINE: {
            'new': {
                'create': 'unclaimed'
            },
            'unclaimed': {
                'update': 'unclaimed',
                'claim_insurance': 'insurance_claiming',
                'claim_insurance_manual': 'insurance_claiming_manual',
                'mark_completed': 'claim_completed'
            },
            'insurance_claiming': {
                'update': 'insurance_claiming',
                'correct_insurance_claim': 'insurance_claiming',
                'receive_insurance_eob': 'insurance_claim_completed'
                //'void_insurance_claim': 'unclaimed',
            },
            'insurance_claiming_manual': {
                'update': 'insurance_claiming',
                //'void_insurance_claim_manual': 'unclaimed',
                'receive_insurance_eob': 'insurance_claim_completed'
            },
            'insurance_paid': {
                'receive_insurance_eob': 'insurance_claim_completed'
            },
            'insurance_denied': {
                'receive_insurance_eob': 'insurance_claim_completed'
            },
            'insurance_claim_completed': {
                'update': 'insurance_claim_completed',
                //'accept_remittance_payment': 'insurance_claim_completed',
                //'writeoff': 'insurance_claim_completed',
                'claim_other_coverage': 'other_coverage_claiming',
                'claim_other_coverage_manual': 'other_coverage_claiming_manual',
                'mark_completed': 'claim_completed'
            },
            'other_coverage_claiming': {
                'update': 'other_coverage_claiming',
                'correct_other_coverage_claim': 'other_coverage_claiming',
                'receive_other_coverage_eob': 'other_coverage_claim_completed'
                //'void_other_coverage_claim': 'insurance_claim_completed',
            },
            'other_coverage_claiming_manual': {
                'update': 'other_coverage_claiming',
                //'void_other_coverage_claim_manual': 'insurance_claim_completed',
                'receive_other_coverage_eob': 'other_coverage_claim_completed'
            },
            'other_coverage_paid': {
                'receive_other_coverage_eob': 'other_coverage_claim_completed'
            },
            'other_coverage_denied': {
                'receive_other_coverage_eob': 'other_coverage_claim_completed'
            },
            'other_coverage_claim_completed': {
                //'accept_remittance_payment': 'other_coverage_claim_completed',
                //'writeoff': 'other_coverage_claim_completed',
                'mark_completed': 'claim_completed'
            },
            'claim_completed': {
            }

        },
        /**
        * Attempts to transition to a new state based on an operation occuring
        * @param {string} attemptedOperation: a attempted operation
        * @param {string} initialState: the state when the operation occurs
        * @param {object} stateMachineMap: a state machine map object with states as keys and an objects as values
        * that themselves have valid commands as keys each mapped to a new state
        * (e.g. {'initialState': {'command1': 'newState'}}
        * @param {function} a callback function that takes one parameter of newState that will be invoked
        * prior to returning if a newState will be returned
        * @return {string | false} a newState string or false if the operation
        * doesn't trigger a transition from initialState in the stateMachineMap.
        */
        transition: function(initialState, attemptedOperation, fnResultingAction) {
            var initialStateTransitions = this.CLAIM_STATE_MACHINE[initialState];
            if (initialStateTransitions) {
                var newState = initialStateTransitions[attemptedOperation];
                if (newState) {
                    if (fnResultingAction) {
                        fnResultingAction(newState, attemptedOperation);
                    }
                    return newState;
                }
            }
            return false;
        }
    },
    CLAIM_STATES: {
        NEW:            'new',
        UNCLAIMED:      'unclaimed',
        INS_CLAIMING:   'insurance_claiming',
        INS_CLAIMING_manual:   'insurance_claiming_manual',
        INS_PAID:       'insurance_paid',
        INS_DENIED:     'insurance_denied',
        INS_CLAIM_COMPLETED:    'insurance_claim_completed',
        OC_CLAIMING:    'other_coverage_claiming',
        OC_CLAIMING_manual:    'other_coverage_claiming_manual',
        OC_PAID:        'other_coverage_paid',
        OC_DENIED:      'other_coverage_denied',
        OC_CLAIM_COMPLETED:     'other_coverage_claim_completed'
    },
    mapToSubmitClaimApi: function(
            patient,
            practice,
            billingLocation,
            treatingProvider,
            treatmentsLocation,
            treatments) {
        var submittedClaim = {};
        submittedClaim.preauth_id = '';
        submittedClaim.insurance_paid_amount = null;
        submittedClaim.insurance_paid_date = null;
        submittedClaim.has_enclosures = false;
        submittedClaim.nea_number = '';
        submittedClaim.place_of_treatment = '11'; //['11': 'office'(default) | '12': 'home' | '21': 'in patient hospital' | '22': 'out patient hospital' | '31': 'skilled nursing facility' | '32': 'nursing facility']
        /* valid values:
                11 = Office;
                12 = Home;
                21 = Inpatient Hospital;
                22 = Outpatient Hospital;
                31 = Skilled Nursing Facility;
                32 = Nursing Facility
        */
        submittedClaim.is_orthodontic = false; //[true | false]
        submittedClaim.appliance_placed_date = null;  //valid date, or '' or null
        submittedClaim.months_remaining = null; //valid positive integer, or null or blank ''
        submittedClaim.prothesis_replacement = false; //[true | false]
        submittedClaim.prior_applicance_placed_date = null; //valid date, or '' or null
        submittedClaim.treatment_resulting_from = ''; //ADD //valid values: '' | 'Occ Injury' | 'Auto Accident' | 'Other'
        submittedClaim.accident_date = null; //valid date, or '' or null
        submittedClaim.accident_state = ''; //valid two letter state code, or '' or null

        submittedClaim.patient = {};
        submittedClaim.patient.title = patient.title;
        submittedClaim.patient.first_name = patient.first_name;
        submittedClaim.patient.middle_initial = patient.middle_initial;
        submittedClaim.patient.last_name = patient.last_name;
        submittedClaim.patient.street1 = patient.patient_address_street_1;
        submittedClaim.patient.street2 = patient.patient_address_street_2;
        submittedClaim.patient.city = patient.patient_address_city;
        submittedClaim.patient.state = patient.patient_address_state;
        submittedClaim.patient.zip_code = patient.patient_address_zip_code;
        submittedClaim.patient.birth_date = patient.birth_date; // valid date required
        submittedClaim.patient.gender = patient.gender; //['m' | 'f']
        submittedClaim.patient.id = patient.id;
        submittedClaim.patient.student_status = patient.student_status; //ADD //values: 'Not a Student', 'Full Time','Part Time'
        submittedClaim.patient.missing_teeth = ''; //comma delimited list of numbers, or ''


        submittedClaim.patient.insurance = {};

        submittedClaim.patient.insurance.type = patient.insurance_plan_info ? (
          patient.insurance_plan_info.payer_type
        ) : ('none'); //['medical' | 'dental' | 'none']

        submittedClaim.patient.insurance.company = {};
        submittedClaim.patient.insurance.company.payer_id = patient.insurance_plan_info.payer_id;
        submittedClaim.patient.insurance.company.name = patient.insurance_plan_info.payer_name;
        submittedClaim.patient.insurance.company.street1 = patient.insurance_plan_info.address_street_1;
        submittedClaim.patient.insurance.company.street2 = patient.insurance_plan_info.address_street_2;
        submittedClaim.patient.insurance.company.city = patient.insurance_plan_info.address_city;
        submittedClaim.patient.insurance.company.state = patient.insurance_plan_info.address_state;
        submittedClaim.patient.insurance.company.zip_code = patient.insurance_plan_info.address_zip_code;
        submittedClaim.patient.insurance.company.group_number = patient.insurance_plan_info.group_id;
        //submittedClaim.patient.insurance.company.plan_name = patient.insurance_plan_info.name;

        submittedClaim.patient.insurance.subscriber = {};
        submittedClaim.patient.insurance.subscriber.first_name = patient.insurance_subscriber_first_name;
        submittedClaim.patient.insurance.subscriber.last_name = patient.insurance_subscriber_last_name;
        submittedClaim.patient.insurance.subscriber.birth_date = patient.insurance_subscriber_birth_date; //Valid date required
        submittedClaim.patient.insurance.subscriber.gender = patient.insurance_subscriber_gender; //['m' | 'f']
        submittedClaim.patient.insurance.subscriber.id = patient.insurance_subscriber_id;
        submittedClaim.patient.insurance.subscriber.employer = patient.insurance_plan_info.name;//patient.insurance_subscriber_employer;
        submittedClaim.patient.insurance.subscriber.patient_relationship_to_subscriber = patient.insurance_subscriber_patient_relationship_to_subscriber ? (
          patient.insurance_subscriber_patient_relationship_to_subscriber
        ) : ('self'); //['self' | 'spouse' | 'dependent' | 'other']
        submittedClaim.patient.insurance.subscriber.street1 = patient.insurance_subscriber_address_street_1;
        submittedClaim.patient.insurance.subscriber.street2 = patient.insurance_subscriber_address_street_2;
        submittedClaim.patient.insurance.subscriber.city = patient.insurance_subscriber_address_city;
        submittedClaim.patient.insurance.subscriber.state = patient.insurance_subscriber_address_state;
        submittedClaim.patient.insurance.subscriber.zip_code = patient.insurance_subscriber_address_zip_code;


        /* server will allow assignment of benefits only:
          if
            1) submittedClaim.patient.insurance.subscriber.billing_provider_assigned_benefits == true AND
            2) submittedClaim.patient.insurance.subscriber.signature_on_file == true AND
            3) submittedClaim.patient.insurance.subscriber.signature_on_file set

            in which case signature and date will be 'placed' on the claim which results in the claim
            remittance going to the practice.

          else
            neither signature or date will be 'placed' on the claim

            in which case the claim remittance will go to the subscriber.
        */
        submittedClaim.patient.insurance.subscriber.billing_provider_assigned_benefits  = patient.insurance_billing_provider_assigned_benefits;
        submittedClaim.patient.insurance.subscriber.signature_on_file  =
          (
            patient.insurance_billing_provider_assigned_benefits &&
            patient.insurance_subscriber_signature && patient.insurance_subscriber_signature.length > 0 &&
            patient.insurance_subscriber_signature_date && patient.insurance_subscriber_signature_date.length > 0
          ) ?
          true : false;
        submittedClaim.patient.insurance.subscriber.signature_on_file_date  = (
            patient.insurance_billing_provider_assigned_benefits &&
            patient.insurance_subscriber_signature && patient.insurance_subscriber_signature.length > 0 &&
            patient.insurance_subscriber_signature_date && patient.insurance_subscriber_signature_date.length > 0
          ) ? moment().format('YYYY-MM-DD') : null;

        submittedClaim.patient.other_coverage = {};
        submittedClaim.patient.other_coverage.type = patient.other_coverage_plan_info ? (
          patient.other_coverage_plan_info.payer_type
        ) : ('none'); //['medical' | 'dental' | 'none']
        submittedClaim.patient.other_coverage.company = {};
        submittedClaim.patient.other_coverage.company.payer_id = patient.other_coverage_plan_info ? (
          patient.other_coverage_plan_info.payer_id
        ) : ('');
        submittedClaim.patient.other_coverage.company.name = patient.other_coverage_plan_info ? (
          patient.other_coverage_plan_info.payer_name
        ) : ('');
        submittedClaim.patient.other_coverage.company.street1 = patient.other_coverage_plan_info ? (
          patient.other_coverage_plan_info.address_street_1
        ) : ('');
        submittedClaim.patient.other_coverage.company.street2 = patient.other_coverage_plan_info ? (
          patient.other_coverage_plan_info.address_street_2
        ) : ('');
        submittedClaim.patient.other_coverage.company.city = patient.other_coverage_plan_info ? (
          patient.other_coverage_plan_info.address_city
        ) : ('');
        submittedClaim.patient.other_coverage.company.state = patient.other_coverage_plan_info ? (
          patient.other_coverage_plan_info.address_state
        ) : ('');
        submittedClaim.patient.other_coverage.company.zip_code = patient.other_coverage_plan_info ? (
          patient.other_coverage_plan_info.address_zip_code
        ) : ('');
        submittedClaim.patient.other_coverage.company.group_number = patient.other_coverage_plan_info ? (
          patient.other_coverage_plan_info.group_id
        ) : ('');
        //submittedClaim.patient.other_coverage.company.plan_name = patient.other_coverage_plan_info ? (
        //  patient.other_coverage_plan_info.name
        //) : ('');


        submittedClaim.patient.other_coverage.subscriber = {};
        submittedClaim.patient.other_coverage.subscriber.first_name = patient.other_coverage_subscriber_first_name;
        submittedClaim.patient.other_coverage.subscriber.last_name = patient.other_coverage_subscriber_last_name;
        submittedClaim.patient.other_coverage.subscriber.birth_date = patient.other_coverage_subscriber_birth_date; //Valid date, or '' or null
        submittedClaim.patient.other_coverage.subscriber.gender = patient.other_coverage_subscriber_gender; //['m' | 'f' | '']
        submittedClaim.patient.other_coverage.subscriber.id = patient.other_coverage_subscriber_id;
        submittedClaim.patient.other_coverage.subscriber.employer = patient.other_coverage_plan_info ? (
          patient.other_coverage_plan_info.name
        ) : ('');
        //submittedClaim.patient.other_coverage.subscriber.employer = patient.other_coverage_subscriber_employer;
        submittedClaim.patient.other_coverage.subscriber.patient_relationship_to_subscriber = patient.other_coverage_subscriber_patient_relationship_to_subscriber ? (
          patient.other_coverage_subscriber_patient_relationship_to_subscriber
        ) : ('self'); //['self' | 'spouse' | 'dependent' | 'other']
        submittedClaim.patient.other_coverage.subscriber.street1 = patient.other_coverage_subscriber_address_street_1;
        submittedClaim.patient.other_coverage.subscriber.street2 = patient.other_coverage_subscriber_address_street_2;
        submittedClaim.patient.other_coverage.subscriber.city = patient.other_coverage_subscriber_address_city;
        submittedClaim.patient.other_coverage.subscriber.state = patient.other_coverage_subscriber_address_state;
        submittedClaim.patient.other_coverage.subscriber.zip_code = patient.other_coverage_subscriber_address_zip_code;

        submittedClaim.patient.other_coverage.subscriber.billing_provider_assigned_benefits  = patient.other_coverage_billing_provider_assigned_benefits;
        submittedClaim.patient.other_coverage.subscriber.signature_on_file  =
          (
            patient.other_coverage_billing_provider_assigned_benefits &&
            patient.other_coverage_subscriber_signature && patient.other_coverage_subscriber_signature.length > 0 &&
            patient.other_coverage_subscriber_signature_date && patient.other_coverage_subscriber_signature_date.length > 0
          ) ?
          true : false;
        submittedClaim.patient.other_coverage.subscriber.signature_on_file_date  = (
            patient.other_coverage_billing_provider_assigned_benefits &&
            patient.other_coverage_subscriber_signature && patient.other_coverage_subscriber_signature.length > 0 &&
            patient.other_coverage_subscriber_signature_date && patient.other_coverage_subscriber_signature_date.length > 0
          ) ? moment().format('YYYY-MM-DD') : null;

        submittedClaim.services = {};
        submittedClaim.services.other_fees = 0.0; //ADD
        submittedClaim.services.remarks = '';      //ADD
        submittedClaim.services.diagnosis_code_qualifier = ''; //['' (default) | 'B': 'ICD-9' | 'AB': 'ICD-10']
        submittedClaim.services.diagnosis_codes = ''; //comma delimited list, or ''

        submittedClaim.services.treatments = [];
        for (var i = 0; i < treatments.length; i++) {
            var treatment = {};
            treatment.id = treatments[i].id;
            treatment.treatment_date = treatments[i].treatment_date; //valid date required
            treatment.area_of_oral_cavity = treatments[i].area_of_oral_cavity;
            treatment.tooth_system = treatments[i].tooth_system;
            treatment.tooth_numbers = treatments[i].tooth_numbers;
            treatment.tooth_surface = treatments[i].tooth_surface;
            treatment.procedure_code = treatments[i].procedure_code;
            treatment.diagnostic_pointer = treatments[i].diagnostic_pointer;
            treatment.quantity = 1;
            treatment.procedure_description =  treatments[i].procedure_name;
            treatment.fee =  treatments[i].amount_revenue_to_post;
            treatment.arAmount = treatments[i].arAmount; //for display only.
            submittedClaim.services.treatments.push(treatment);
        }

        submittedClaim.services.provider = {};

        submittedClaim.services.provider.signature_on_file  =
          (
            treatingProvider.signature && treatingProvider.signature.length > 0 &&
            treatingProvider.signature_date && treatingProvider.signature_date.length > 0
          ) ?
          true : false;
        submittedClaim.services.provider.signature_on_file_date  = (
            treatingProvider.signature && treatingProvider.signature.length > 0 &&
            treatingProvider.signature_date && treatingProvider.signature_date.length > 0
          ) ? moment().format('YYYY-MM-DD') : null;

        submittedClaim.services.provider.npi = treatingProvider.npi;
        submittedClaim.services.provider.first_name = treatingProvider.first_name;
        submittedClaim.services.provider.last_name = treatingProvider.last_name;

        submittedClaim.services.provider.license_number = '';
        for (var a=0; a<treatingProvider.account_locations.length; a++) {
          if (treatingProvider.account_locations[a].location === treatmentsLocation.id) {
            submittedClaim.services.provider.license_number = treatingProvider.account_locations[a].license_number;
          }
        }
        submittedClaim.services.provider.specialty = treatingProvider.specialty;
            /* valid specialty values with descriptions:
                        ('122300000X', 'Dentist'),
                        ('1223G0001X', 'General Practice'),
                        ('1223D0001X', 'Dental Public Health'),
                        ('1223E0200X', 'Endodontics'),
                        ('1223X0400X', 'Orthodontics'),
                        ('1223P0221X', 'Pediatric Dentistry'),
                        ('1223P0300X', 'Periodontics'),
                        ('1223P0700X', 'Prosthodontics'),
                        ('1223P0106X', 'Oral & Maxillofacial Pathology'),
                        ('1223D0008X', 'Oral & Maxillofacial Radiology'),
                        ('1223S0112X', 'Oral & Maxillofacial Surgery')
            */
        submittedClaim.services.provider.phone =  treatingProvider.phone;
        submittedClaim.services.provider.additional_id = treatingProvider.additional_id;

        submittedClaim.services.location = {};
        submittedClaim.services.location.name = treatmentsLocation.name;
        submittedClaim.services.location.street1 = treatmentsLocation.street1;
        submittedClaim.services.location.street2 = treatmentsLocation.street2;
        submittedClaim.services.location.city = treatmentsLocation.city;
        submittedClaim.services.location.state = treatmentsLocation.state;
        submittedClaim.services.location.zip_code = treatmentsLocation.zip_code;

        submittedClaim.services.patient = {};

        submittedClaim.services.patient.signature_on_file  =
          (
            patient.insurance_signature && patient.insurance_signature.length > 0 &&
            patient.insurance_signature_date && patient.insurance_signature_date.length > 0
          ) ?
          true : false;
        submittedClaim.services.patient.signature_on_file_date  = (
            patient.insurance_signature && patient.insurance_signature.length > 0 &&
            patient.insurance_signature_date && patient.insurance_signature_date.length > 0
          ) ? moment().format('YYYY-MM-DD') : null;

        submittedClaim.billing = {};
        submittedClaim.billing.provider = {};
        submittedClaim.billing.provider.phone = practice.phone;
        if (practice.npi && practice.npi.trim().length > 0 && practice.tax_id && practice.tax_id.trim().length > 0) {
          submittedClaim.billing.provider.name = practice.name;
          submittedClaim.billing.provider.npi =  practice.npi;
          submittedClaim.billing.provider.license_number = '';
          submittedClaim.billing.provider.tax_id_or_ssn = practice.tax_id;
          submittedClaim.billing.provider.additional_id = '';
        } else {
          submittedClaim.billing.provider.name = treatingProvider.first_name + ' ' + treatingProvider.last_name;
          submittedClaim.billing.provider.npi =  treatingProvider.npi;
          submittedClaim.billing.provider.license_number = '';
          for (var b=0; b<treatingProvider.account_locations.length; b++) {
            if (!practice.use_claim_treating_location_for_billing_location && treatingProvider.account_locations[b].location === billingLocation.id) {
              submittedClaim.billing.provider.license_number = treatingProvider.account_locations[b].license_number;
              break;
            } else if (practice.use_claim_treating_location_for_billing_location && treatingProvider.account_locations[b].location === treatmentsLocation.id) {
              submittedClaim.billing.provider.license_number = treatingProvider.account_locations[b].license_number;
              break;
            }
          }
          submittedClaim.billing.provider.tax_id_or_ssn = treatingProvider.tax_id_or_ssn;
          submittedClaim.billing.provider.additional_id = treatingProvider.additional_id;
        }

        submittedClaim.billing.location = {};
        if (practice.use_claim_treating_location_for_billing_location) {
          submittedClaim.billing.location.street1 = treatmentsLocation.street1;
          submittedClaim.billing.location.street2 = treatmentsLocation.street2;
          submittedClaim.billing.location.city = treatmentsLocation.city;
          submittedClaim.billing.location.state = treatmentsLocation.state;
          submittedClaim.billing.location.zip_code = treatmentsLocation.zip_code;
        } else {
          submittedClaim.billing.location.street1 = billingLocation.street1;
          submittedClaim.billing.location.street2 = billingLocation.street2;
          submittedClaim.billing.location.city = billingLocation.city;
          submittedClaim.billing.location.state = billingLocation.state;
          submittedClaim.billing.location.zip_code = billingLocation.zip_code;
        }

        return submittedClaim;
    }
  };
}

  angular.module('common.services.billing', [])
    .factory('BillingService', billingService);
})();

(function() {
  'use strict';

  // TODO: take into account calibration for calculations
  function imageService() {
    // TODO: Move to constants file
    var mmPerInch = 25.4;

    /**
     * Convert pixels to millimeters, based on a particular DPI.
     * @param pixels: value in pixels.
     * @param dpi (defaults to 96).
     * @returns {number}
     */
    function pixels2mmFromDpi(pixels, dpi) {
      dpi = angular.isNumber(dpi) ? dpi : 96;

      return (pixels * mmPerInch) / dpi;
    }

    /**
     * Convert pixels to millimeters, based on a particular calibration.
     *
     * If no calibration is given, converts to mm in the usual way.
     * @param pixels: value in pixels.
     * @param calibration: calibration data.
     * @param imageWidth: original image width.
     * @returns {number}
     */
    function pixels2mm(pixels, calibration, imageWidth) {
      if (!pixels) {
        return 0;
      }

      if (calibration) {
        var calibrationValue = calibration.calibration;
        // Ratio from original image
        var ratio = calibration.width / imageWidth;

        return pixels * calibrationValue * ratio;
      } else {
        return pixels2mmFromDpi(pixels);
      }
    }

    /**
     * Convert line pairs per millimeter to dpi.
     * @param lpmm
     * @returns {number}
     *
     * Reference: http://leica-users.org/v17/msg11544.html
     * Example:
     *   1lp/mm * ~25mm/in * 2lines/lp * 2dots/line = 100dpi
     */
    function lpmm2dpi(lpmm) {
      if (!lpmm) {
        return 0;
      }

      return lpmm * mmPerInch * 2.0 * 2.0;
    }

    /**
     * Returns the distance in pixels from point A to point B.
     * @param pointA
     * @param pointB
     * @returns {number}
     */
    function getDistance(pointA, pointB) {
      var b = pointA.x - pointB.x,
          c = pointA.y - pointB.y;

      // TODO: should we take into account scale? what about resolution?
      return Math.sqrt(Math.pow(b, 2) + Math.pow(c, 2));
    }

    return {
      pixels2mm: pixels2mm,
      lpmm2dpi: lpmm2dpi,
      getDistance: getDistance
    };
  }

  angular.module('common.services.image', [])
    .factory('ImageService', imageService);
})();
(function() {
  "use strict"

  angular.module("common.services.reminders", [])

  .service("ReminderService", function($http) {

    var apiUrl = "/api/v2/~encounters/reminders/"

    this.list = function(params) {
      return $http.get(apiUrl, {params:params})
    }

    this.create = function(params) {
      params.date = moment(params.date).format("YYYY-MM-DD")
      return $http.post(apiUrl, params).then()
    }

    this.update = function(params) {
      if (params.date) {
        params.date = moment(params.date).startOf("day").format("YYYY-MM-DD")
      }
      return $http.put(apiUrl + params.id + "/", params)
    }

    this.destroy = function(id) {
      return $http.delete(apiUrl + id + "/")
    }

    this.expiredAndNotCompleted = function(params) {
      return $http.get(apiUrl + "expired-and-not-completed/", {params:params})
    }

  })
})();

(function() {
  'use strict';

  angular.module('common.services.consentforms', [])
    .service('ConsentFormService', ConsentFormService);


  function ConsentFormService($q, $http, Upload) {

    var apiUrl = '/api/v2/~form-template/consent-forms/';

    this.list = function(params) {
      return $http.get(apiUrl, {params:params});
    };

    this.create = function(params) {
      if (params.form_archive) {
        var deferred = $q.defer();
        $http.post(apiUrl + 'upload/', {form_archive:params.form_archive.name}).then(
          function(response) {
            if (response.data.form_archive) {
              response.data.form_archive.data.file = params.form_archive;
              Upload.upload(response.data.form_archive).then(
                function() {
                  params.form_archive = response.data.form_archive.name;
                  $http.post(apiUrl, params).then(
                    function(response) {
                      deferred.resolve(response);
                    },
                    function(response) {
                      deferred.reject(response);
                    }
                  );
                },
                function(response) {
                  deferred.reject(response);
                },
                function(event) {
                  deferred.notify(event);
                }
              );
            }
            else {
              deferred.resolve(Upload.upload({url: apiUrl, data: params, arrayKey: ''}));
            }
          },
          function(response) {
            deferred.reject(response);
          }
        );
        return deferred.promise;
      }
      else {
        return $http.post(apiUrl, params);
      }
    };

    this.retrieve = function(id) {
      return $http.get(apiUrl + id + '/');
    };

    this.update = function(params) {
      return $http.patch(apiUrl + params.id + '/', params);
    };

    this.destroy = function(id) {
      return $http.delete(apiUrl + id + '/');
    };

    this.download = function(id) {
      return $http.get(apiUrl + id + '/download/');
    };

    this.consented_treatments = function(patientId) {
      return $http.get('/api/v2/~patients/patients/' + patientId + '/consented-treatments/');
    };

  }

})();

(function() {
  'use strict';

  angular.module('common.services.consentforms-template', [])
    .service('ConsentFormTemplateService', ConsentFormTemplateService);


  function ConsentFormTemplateService($q, $http, Upload) {

    var apiUrl = '/api/v2/~form-template/consent-form-templates/';

    this.list = function(params) {
      return $http.get(apiUrl, params);
    };

    this.create = function(params) {
      return Upload.upload({url: apiUrl, arrayKey: '', data: params});
    };

    this.retrieve = function(id) {
      return $http.get(apiUrl + id + '/');
    };

    this.update = function(params) {
      return $http.patch(apiUrl + params.id + '/', params);
    };

    this.destroy = function(id) {
      return $http.delete(apiUrl + id + '/');
    };

    this.download = function(id) {
      return $http.get(apiUrl + id + '/download/');
    };

  }

})();

(function() {
  "use strict"

  angular.module("common.services.multifactorauthentication", [])

  .service("MultiFactorAuthenticationTrustedDeviceService", TrustedDeviceService)

  function TrustedDeviceService($http) {
    var $svc = this

    $svc.destroy = function(id) {
      return $http.delete("/api/v2/~mfa/trusted-devices/" + id + "/")
    }

    $svc.list = function(params) {
      return $http.get("/api/v2/~mfa/trusted-devices/", {params:params})
    }

    $svc.revoke = function(which, params) {
      return $http.delete("/api/v2/~mfa/trusted-devices/revoke/" + which + "/", {params:params})
    }

  }

})();

(function() {
  "use strict"

  angular.module("common.services.patient", [])
    .service("PatientService", PatientService)

  function PatientService(
    $http,
    $q,
    Upload,
    PrincipalService
  ) {

    this.list = function(params) {
      return $http.get("/api/v2/~patients/patients/", {params:params})
    }

    this.retrieve = function(id) {
      return $http.get("/api/v2/~patients/patients/" + id + "/")
    }

    this.sendForm = function(id, params) {
      return $http.post("/api/v2/~patients/patients/" + id + "/send-form/", params)
    }

    this.sendPatientPortalEmail = function(id) {
      return $http.post("/api/v2/~patients/patients/" + id + "/send-patient-portal-email/")
    }

    this.update = function(params) {
      return $http.patch("/api/v2/~patients/patients/" + params.id + "/?saverid=" + PrincipalService.getSaverid(), params)
    }

    this.upload = function(patient, data) {
      var self = this
      return $http.post("/api/v2/~patients/patients/" + patient.id + "/upload/", {mugshot: data.mugshot.name}).then(
        function(response) {
          var presigned_post = response.data
          if (angular.isObject(presigned_post.mugshot)) {
            presigned_post.mugshot.data.file = data.mugshot
            return Upload.upload(presigned_post.mugshot).then(
              function() {
                return self.update({
                  id: patient.id,
                  mugshot: presigned_post.mugshot.name
                })
              },
              function(response) {
                patient.$mugshot_status = response
              },
              function(event) {
                patient.$mugshot_progress = Math.min(
                  100, parseInt(100.0 * event.loaded / event.total)
                )
              }
            )
          }
          else {
          }
        }
      )
    }

    this.v3 = {
      list: function(cacheReload) {
        var headers = {}
        if(cacheReload) {
          headers = {
            "Reload-Cache": "true"
          }
        }
        return $http.get("/api/v3/practice/" + PrincipalService.getPracticeId() + "/~patients/patient",{headers: headers})
      },
      update: function(id, data) {
        return $http.put("/api/v3/practice/" + PrincipalService.getPracticeId() + "/~patients/patient/" + id, data )
      },
    }

  }

})();

(function() {
  "use strict"

  angular.module("common.services.patientfiles", [])

  .service("PatientFileService", function($http, $log, $q, ArchivesService, NotificationService, Upload) {

    var $svc = this

    $svc.create = function(patient_files, patientId, apiStorage) {
      if (apiStorage.upload) {
        return getPresignedPosts(patient_files, patientId).then(
          function(presignedPosts) {
            return $q.map(patient_files, function(patient_file, idx) {
              return $q.map(presignedPosts[idx], function(presignedPost, prop) {
                presignedPost.data.file = patient_file["$file_" + prop]
                return Upload.upload(presignedPost).then(
                  function() {
                    patient_file["$uploadProgress_" + prop] = 100
                    return presignedPost.name
                  },
                  function(response) {
                    ArchivesService.setError(patient_file, "$upload_" + prop, response)
                  },
                  function(evt) {
                    patient_file["$uploadProgress_" + prop] = Math.min(100, parseInt(100.0 * evt.loaded / evt.total))
                  }
                )
              })
              .then(
                function(uploaded_patient_file) {
                  uploaded_patient_file.patient = patientId
                  return $http.post("/api/v2/~patients/patient-files/", uploaded_patient_file).then(
                    function(response) {
                      return angular.extend(patient_file, response.data)
                    },
                    function(response) {
                      ArchivesService.setError(patient_file, "$create", response)
                    }
                  )
                }
              )
            })
          },
          function(errors) {
            if (errors.InvalidPresignedPosts) {
              patient_files.forEach(function(patient_file) {
                ArchivesService.setError(patient_file, "InvalidPresignedPost")
              })
            }
          }
        )
      }
      else {
        console.error("[non-S3-mode] Not Implemented")
      }
    }

    $svc.destroy = function(patient_file) {
      return $http.delete("/api/v2/~patients/patient-files/" + patient_file.id + "/")
    }

    $svc.download = function(patient_file) {
      return $http.get("/api/v2/~patients/patient-files/" + patient_file.id + "/download/").then(
        function(response) {
          return Upload.urlToBlob(response.data.archive).then(
            function(blob) {
              blob.name = response.data.archive_name
              return blob
            }
          )
        }
      )
    }

    $svc.list = function(params) {
      return $http.get("/api/v2/~patients/patient-files/", {params:params})
    }

    $svc.retrieve = function(patient_file) {
      return $http.get("/api/v2/~patients/patient-files/" + patient_file.id + "/")
    }

    $svc.update = function(patient_file) {
      return $http.patch("/api/v2/~patients/patient-files/" + patient_file.id + "/", patient_file)
    }

    function getPresignedPosts(patient_files, patientId) {
      var url = "/api/v2/~patients/patient-files/upload/"
      var data = patient_files.map(function(patient_file) { return { archive: patient_file.$file_archive.name } })
      var config = { params: { patient_id: patientId } }
      return $http.post(url, data, config).then(
        function(response) {
          return response.data
        },
        function(response) {
          response.data.forEach(function(resp) {
            if (resp.errors) {
              NotificationService.error("Getting Presigned Post", angular.extend({}, response, {data: resp.errors}))
            }
          })
          throw {InvalidPresignedPosts: true}
        }
      )
    }

  })

})();

(function() {
  "use strict"

  angular.module("common.services.patientimages", [])

  .service("PatientImageService", function($http, $log, $q, ArchivesService, NotificationService, Upload) {

    var $svc = this

    $svc.create = function(patient_images, encounter, apiStorage) {
      if (apiStorage.upload) {
        return $q.all([getPresignedPosts(patient_images, encounter), getThumbFiles(patient_images)]).spread(
          function(presignedPosts) {
            return $q.map(patient_images, function(patient_image, idx) {
              return $q.map(
                presignedPosts[idx],
                function(presignedPost, prop) {
                  presignedPost.data.file = patient_image["$file_" + prop]
                  return Upload.upload(presignedPost).then(
                    function() {
                      patient_image["$uploadProgress_" + prop] = 100
                      return presignedPost.name
                    },
                    function(response) {
                      ArchivesService.setError(patient_image, "$upload_" + prop, response)
                    },
                    function(evt) {
                      patient_image["$uploadProgress_" + prop] = Math.min(100, parseInt(100.0 * evt.loaded / evt.total))
                    }
                  )
                }
              ).then(
                function(uploaded_patient_image) {
                  uploaded_patient_image.encounter = encounter
                  return $http.post("/api/v2/~patients/patient-images/", uploaded_patient_image).then(
                    function(response) {
                      return angular.extend(patient_image, response.data)
                    },
                    function(response) {
                      ArchivesService.setError(patient_image, "$create", response)
                    }
                  )
                }
              )
            })
          },
          function(errors) {
            if (errors.InvalidPresignedPosts) {
              patient_images.forEach(function(patient_image) {
                ArchivesService.setError(patient_image, "InvalidPresignedPost")
              })
            }
          }
        )
      }
      else {
        console.error("[non-S3-mode] Not Implemented")
      }
    }

    $svc.destroy = function(id) {
      return $http.delete("/api/v2/~patients/patient-images/" + id + "/")
    }

    $svc.download = function(id) {
      return $http.get("/api/v2/~patients/patient-images/" + id + "/download/").then(
        function(response) {
          return Upload.urlToBlob(response.data.image_archive)
        }
      )
    }

    $svc.list = function(params) {
      return $http.get("/api/v2/~patients/patient-images/", {params:params})
    }

    $svc.retrieve = function(id) {
      return $http.get("/api/v2/~patients/patient-images/" + id + "/")
    }

    $svc.update = function(patient_image) {
      return $http.patch("/api/v2/~patients/patient-images/" + patient_image.id + "/", patient_image)
    }

    $svc.uploads = function(patient_images, queryParams) {
      var data = patient_images.map( function(pi) { return {image_archive: pi.image_archive.name} } )
      return $http.post("/api/v2/~patients/patient-images/upload/", data, {params:queryParams}).then(
        function(resp) { return resp.data }
      )
    }

    function getPresignedPosts(patient_images, encounter_id) {
      var url = "/api/v2/~patients/patient-images/upload/"
      var data = patient_images.map(function(patient_image) {
        return { image_archive: patient_image.$file_image_archive.name }
      })
      var config = {
        params: { encounter_id: encounter_id }
      }
      return $http.post(url, data, config).then(
        function(response) {
          return response.data
        },
        function(response) {
          response.data.forEach(function(resp) {
            if (resp.errors) {
              NotificationService.error("Getting Presigned Post", angular.extend({}, response, {data: resp.errors}))
            }
          })
          throw {InvalidPresignedPosts: true}
        }
      )
    }

    function getThumbFiles(patient_images) {
      return $q.map(patient_images, function(patient_image) {
        if (patient_image.image_archive_thumb && patient_image.$file_image_archive_thumb) {
          return patient_image.$file_image_archive_thumb
        }
        return ArchivesService.getThumb(patient_image.$file_image_archive).then(
          function(file) {
            patient_image.image_archive_thumb = URL.createObjectURL(file)
            patient_image.$file_image_archive_thumb = file
            return file
          }
        )
      })
    }

  })

})();

(function () {
  'use strict';

  function canvasService(ImageService) {

    function getSizeFromContainer(canvasContainer, image, fullscreen) {
      var viewportWidth = canvasContainer.width() || image.width;
      var canvasWidth = fullscreen ? viewportWidth : Math.min(image.width, viewportWidth);

      return {
        width: canvasWidth,
        height: image.height / (image.width / canvasWidth)
      };
    }

    function CanvasHandler(canvas, data) {
      var canvasData = data,
        ctx = canvas.getContext('2d'),
        // TODO: Move to constants file?
        // Default drawing Styles
        defaults = {
          fillColor: '#FA4C30',
          strokeColor: '#FA4C30',
          font: 'bold 12px Helvetica',
          alignment: 'center',
          lineWidth: 1.2
        };

      function save() {
        // Save context
        ctx.save();
        // Reset scale
        ctx.scale(1, 1);
      }

      function restore() {
        // Restore scale
        ctx.scale(canvasData.scale, canvasData.scale);
        // Restore context
        ctx.restore();
      }

      function getImageHalfSize() {
        return {
          width: canvasData.image.width / 2,
          height: canvasData.image.height / 2
        };
      }

      function getScaledOffset() {
        return {
          x: (ctx.canvas.width / 2) / canvasData.scale,
          y: (ctx.canvas.height / 2) / canvasData.scale
        };
      }

      function getImageOffsetLimits() {
        var imageHalfSize = getImageHalfSize(),
          scaledOffset = getScaledOffset(),
          right = imageHalfSize.width - scaledOffset.x,
          bottom = imageHalfSize.height - scaledOffset.y;

        return {
          left: -right,
          right: right,
          top: -bottom,
          bottom: bottom
        };
      }

      function clipToBounds() {
        var offsetLimits = getImageOffsetLimits();

        if (canvasData.offset.y < offsetLimits.top) {
          canvasData.offset.y = offsetLimits.top;
        }
        if (canvasData.offset.y > offsetLimits.bottom) {
          canvasData.offset.y = offsetLimits.bottom;
        }
        if (canvasData.offset.x < offsetLimits.left) {
          canvasData.offset.x = offsetLimits.left;
        }
        if (canvasData.offset.x > offsetLimits.right) {
          canvasData.offset.x = offsetLimits.right;
        }
      }

      function clearCanvas() {
        ctx.canvas.width = ctx.canvas.width;
      }

      function drawLabel(text, p1, p2, options) {
        options = options || {useEllipsis: false};
        if (!options.alignment) {
          options.alignment = defaults.alignment;
        }
        if (!options.padding) {
          options.padding = 0;
        }
        if (!options.font) {
          options.font = defaults.font;
        }
        if (!options.fillStyle) {
          options.fillStyle = defaults.fillColor;
        }

        var dx = p2.x - p1.x,
          dy = p2.y - p1.y,
          len = Math.sqrt(dx * dx + dy * dy),
          avail = len - 2 * options.padding,
          textToDraw = text;

        if (options.useEllipsis) {
          // Replace text with ellipsis if there is not enough space
          if (ctx.measureText && ctx.measureText(textToDraw).width > avail) {
            while (textToDraw && ctx.measureText(textToDraw + '…').width > avail) {
              textToDraw = textToDraw.slice(0, -1);
            }
            textToDraw += '…';
          }
        }

        var point, pad, angle = Math.atan2(dy, dx);

        // Keep text upright
        if (angle < -Math.PI / 2 || angle > Math.PI / 2) {
          point = p1;
          p1 = p2;
          p2 = point;
          dx *= -1;
          dy *= -1;
          angle -= Math.PI;
        }

        // Set alignment
        if (options.alignment === 'center') {
          point = p1;
          pad = 1 / 2;
        } else {
          var left = options.alignment === 'left';
          point = left ? p1 : p2;
          pad = options.padding / len * (left ? 1 : -1);
        }

        // Draw label
        save();
        ctx.textAlign = options.alignment;
        ctx.fillStyle = options.fillStyle;
        ctx.font = options.font;
        ctx.translate(point.x + dx * pad, point.y + dy * pad);
        ctx.rotate(angle);
        ctx.fillText(textToDraw, 0, -5);
        restore();
      }

      function drawDistance(p1, p2) {
        var distance = ImageService.getDistance(p1, p2);
        var distanceInMm = ImageService.pixels2mm(
          distance,
          canvasData.calibration,
          canvasData.image.width
        );
        var label = distanceInMm.toFixed(2) + ' mm';

        drawLabel(label, p1, p2);
      }

      function drawPoint(point, fillStyle, strokeStyle) {
        fillStyle = fillStyle ? fillStyle : defaults.fillColor;
        strokeStyle = strokeStyle ? strokeStyle : defaults.strokeColor;
        save();
        ctx.strokeStyle = strokeStyle;
        ctx.fillStyle = fillStyle;
        ctx.beginPath();
        // Draw a 360º arc with 2px diameter
        ctx.arc(point.x, point.y, 2, 0, Math.PI * 2, false);
        ctx.fill();
        ctx.stroke();
        restore();
      }

      function drawLine(p1, p2, options) {
        options = options || {};
        if (!options.fillStyle) {
          options.fillStyle = defaults.fillColor;
        }

        // Draw line
        save();
        ctx.beginPath();
        ctx.moveTo(p1.x, p1.y);
        ctx.lineTo(p2.x, p2.y);
        ctx.lineWidth = options.width ? options.width : defaults.lineWidth;
        ctx.strokeStyle = options.fillStyle;
        ctx.stroke();
        // Draw ends
        if (options.drawEnds) {
          drawPoint(p1, options.fillStyle);
          drawPoint(p2, options.fillStyle);
        }
        restore();
      }

      function drawMeasures() {
        if (canvasData.hasMeasures()) {
          var measure;
          for (var i = 0; i < canvasData.measuresLength(); i++) {
            measure = canvasData.getMeasure(i);
            drawLine(measure.p1, measure.p2, {drawEnds: true});
            drawDistance(measure.p1, measure.p2);
          }
        }
      }

      function invertFilter(data) {
        if (canvasData.filters.invert) {
          for (var i = 0; i < data.length; i += 4) {
            data[i] = 255 - data[i];
            data[i + 1] = 255 - data[i + 1];
            data[i + 2] = 255 - data[i + 2];
          }
        }
      }

      function contrastFilter(data) {
        var contrast = canvasData.filters.contrast;

        if (angular.isNumber(contrast)) {
          var factor = (259 * (contrast + 255)) / (255 * (259 - contrast));

          for (var i = 0; i < data.length; i += 4) {
            data[i] = factor * (data[i] - 128) + 128;
            data[i + 1] = factor * (data[i + 1] - 128) + 128;
            data[i + 2] = factor * (data[i + 2] - 128) + 128;
          }
        }
      }

      function brightnessFilter(data) {
        var brightness = canvasData.filters.brightness;

        if (angular.isNumber(brightness)) {
          for (var i = 0, len = data.length; i < len; i += 4) {
            data[i] += brightness;
            data[i + 1] += brightness;
            data[i + 2] += brightness;
          }
        }
      }

      function applyFilters() {
        var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        var data = imageData.data;

        brightnessFilter(data);
        contrastFilter(data);
        invertFilter(data);

        ctx.putImageData(imageData, 0, 0);
      }

      function drawImage() {
        var image = canvasData.image,
          imageHalfSize = getImageHalfSize(),
          beforeScaleOffset = {
            x: (-imageHalfSize.width + canvasData.offset.x) * canvasData.scale,
            y: (-imageHalfSize.height + canvasData.offset.y) * canvasData.scale
          }, afterScaleOffset = getScaledOffset();

        // Clear canvas
        clearCanvas();
        // Move center to the left and top corner
        ctx.translate(beforeScaleOffset.x, beforeScaleOffset.y);
        // Scale
        ctx.scale(canvasData.scale, canvasData.scale);
        // Move center back to the center
        ctx.translate(afterScaleOffset.x, afterScaleOffset.y);
        // Draw image in original size
        ctx.drawImage(image, 0, 0, image.width, image.height);

        applyFilters();

        drawMeasures();

        // Save current canvas width
        canvasData.canvasWidth = canvas.width;
      }

      return {
        getImageOffsetLimits: getImageOffsetLimits,
        clipToBounds: clipToBounds,

        drawLabel: drawLabel,
        drawDistance: drawDistance,
        drawPoint: drawPoint,
        drawLine: drawLine,
        drawImage: drawImage,
        drawMeasures: drawMeasures
      };
    }

    return {
      getHandler: function(canvas, data) {
        return new CanvasHandler(canvas, data);
      },
      getSizeFromContainer: getSizeFromContainer
    };
  }

  angular.module('common.services.canvas', [])
    .factory('CanvasService', canvasService);
})();
(function() {
  'use strict';

function calibrationsService($http) {
  return {
    get: function(id) {
      return $http.get('/api/v1/calibrations/' + id);
    },
    all: function () {
      return $http.get('/api/v1/calibrations/');
    },
    create: function (calibration) {
      return $http.post('/api/v1/calibrations/', calibration);
    },
    update: function (id, calibration) {
      return $http.put('/api/v1/calibrations/' + id + '/', calibration);
    },
    delete: function (id) {
      return $http.delete('/api/v1/calibrations/' + id);
    }
  };
}

  angular.module('common.services.calibrations', [])
    .factory('CalibrationsService', calibrationsService);
})();

/*jshint -W080 */
/*jshint -W041 */
/*jslint eqeq: true*/
(function() {
  'use strict';

// principal is a service that tracks the user's identity.
// calling identity() returns a promise while it does what you need it to do
// to look up the signed-in user's identity info. for example, it could make an
// HTTP request to a rest endpoint which returns the user's name, roles, etc.
// after validating an auth token in a cookie. it will only do this identity lookup
// once, when the application first runs. you can force re-request it by calling identity(true)
  function principalService(
    $localStorage,
    $document,
    $q,
    $rootScope,
    $state,
    $sessionStorage,
    NotificationService,
    WebsocketService,
    ReactPrincipalService,
    $uibModal,
    $timeout
  ) {

    return {

      isIdentityResolved: function() {
        const identityResolved = ReactPrincipalService.isIdentityResolved()
        return identityResolved;
      },
      isAnonymous: function() {
        const anonymous = ReactPrincipalService.isAnonymous();
        return anonymous;
      },
      isAuthenticated: function() {
        const authenticated = ReactPrincipalService.isAuthenticatedFunc();
        return authenticated;
      },
      getUsername: function() {
        const userName = ReactPrincipalService.getUsername();
        return userName;
      },
      getPermissions: function() {
        const permission = ReactPrincipalService.getPermissions();
        return permission;
      },
      isProvider: function() {
        const provider = ReactPrincipalService.isProvider();
        return provider;
      },
      isEncounterAssistant: function() {
        const encounterAssistant = ReactPrincipalService.isEncounterAssistant()
        return encounterAssistant;
      },
      isPracticeAdmin: function() {
        const practiceAdmin = ReactPrincipalService.isPracticeAdmin()
        return practiceAdmin;
      },
      hasPermission: function(permissionName) {
        const permissions = ReactPrincipalService.hasPermission(permissionName)
        return permissions;
      },
      getAccountId: function() {
        const accountId = ReactPrincipalService.getAccountId();
        return accountId;
      },
      getPracticeId: function() {
        const practiceId = ReactPrincipalService.getPracticeId()
        return practiceId;
      },
      getTwoFactorStatus: function () {
        const twoFactorStatus = ReactPrincipalService.getTwoFactorStatus()
        return twoFactorStatus;
      },
      setTwoFactorStatus: function (status) {
        ReactPrincipalService.setTwoFactorStatus(status)
      },
      getIdentity: function() {
        const identity = ReactPrincipalService.getIdentity()
        return identity;
      },
      getWizardCompleted: function() {
        const isWizardCompleted = ReactPrincipalService.getWizardCompleted()
        return isWizardCompleted;
      },
      setWizardCompleted: function() {
        ReactPrincipalService.setWizardCompleted()
      },
      updateCookie: function(){
        ReactPrincipalService.updateCookie()
      },
      isEulaAccepted: function() {
        const eulaAccepted = ReactPrincipalService.isEulaAccepted()
        return eulaAccepted;
      },
      setEulaAccepted: function() {
        ReactPrincipalService.setEulaAccepted()
      },
      setTutorialCompleted: function() {
        ReactPrincipalService.setTutorialCompleted()
      },
      setUser: function(user) {
        ReactPrincipalService.setUser(user)
      },
      getTutorialCompleted: function() {
        const tutorialCompleted = ReactPrincipalService.getTutorialCompleted();
        return tutorialCompleted;
      },
      isInRole: function(role) {
        const inRole = ReactPrincipalService.isInRole(role)
        return inRole;
      },
      isInAnyRole: function(roles) {
        const inAnyRole = ReactPrincipalService.isInAnyRole(roles)
        return inAnyRole;
      },
      getSaverid: function() {
        const saverID = ReactPrincipalService.getSaverid()
        return saverID;
      },
      authenticate: function(identity) {
        ReactPrincipalService.authenticate(identity)
      },
      identity: function(force) {
        const promise = ReactPrincipalService.identityFunc(force);
        return promise;
      },
      login: function (data) {
        ReactPrincipalService.login(data, $timeout, $uibModal)
      }
    };
  }

  angular.module('common.services.principal', [])
    .factory('PrincipalService', principalService);
})();

  /*jshint forin: false */
/*jshint camelcase: false */
(function() {
  'use strict';

function patientsService($http, $q, PrincipalService) {

  function addSignedTokenHeader(token, config) {
    if (!config) {
      config = {
        headers: {}
      };
    } else if (!config.headers) {
      config.headers = {};
    }

    config.headers['Signed-Token'] = token;
    return config;
  }

  function allCamelcaseToSnakecase(patient) {
    // This function translates camelCase JS names to
    // python underscore names.
    String.prototype.toUnderscore = function() {
        return this.replace(/([A-Z|0-9])/g, function($1) {
          return '_' + $1.toLowerCase();
        });
    };

    var underscorePatient = {};
    for (var property in patient) {
      var underscoreProp = property.toUnderscore();
      underscorePatient[underscoreProp] = patient[property];
    }

    return underscorePatient;
  }

  /* Converts from the patient's insurance already transformed to camelcase to
      insurance data in snake case (!@#$!)

    I don't understand what this mess is for but I've changed the
      names so it is at least clear(er) what it's doing (hopefully!)
  */
  function insuranceCamelcaseToInsurancedataSnakecase(patient) {
    patient.insuranceData = {};
    patient.insuranceData.insurance_allowance = patient.insuranceAllowance;
    patient.insuranceData.insurance_allowance_remaining = patient.insuranceAllowanceRemaining;
    patient.insuranceData.insurance_allowance_remaining_reset_date = patient.insuranceAllowanceRemainingResetDate;

    patient.insuranceData.insurance_type = patient.insuranceType;
    patient.insuranceData.insurance_plan_name = patient.insurancePlanName;
    patient.insuranceData.insurance_group_id = patient.insuranceGroupId;
    patient.insuranceData.insurance_company_address_street_1 = patient.insuranceCompanyAddressStreet1;
    patient.insuranceData.insurance_company_address_street_2 = patient.insuranceCompanyAddressStreet2;
    patient.insuranceData.insurance_company_address_city = patient.insuranceCompanyAddressCity;
    patient.insuranceData.insurance_company_address_state = patient.insuranceCompanyAddressState;
    patient.insuranceData.insurance_company_address_zipcode = patient.insuranceCompanyAddressZipCode;
    patient.insuranceData.insurance_company_name = patient.insuranceCompanyName;
    patient.insuranceData.insurance_company_phone = patient.insuranceCompanyPhone;

    patient.insuranceData.insurance_subscriber_address_city = patient.insuranceSubscriberAddressCity;
    patient.insuranceData.insurance_subscriber_address_state = patient.insuranceSubscriberAddressState;
    patient.insuranceData.insurance_subscriber_address_street_1 = patient.insuranceSubscriberAddressStreet1;
    patient.insuranceData.insurance_subscriber_address_street_2 = patient.insuranceSubscriberAddressStreet2;
    patient.insuranceData.insurance_subscriber_address_zipcode = patient.insuranceSubscriberAddressZipCode;
    patient.insuranceData.insurance_subscriber_birth_date = patient.insuranceSubscriberBirthDate;
    patient.insuranceData.insurance_subscriber_employer_name = patient.insuranceSubscriberEmployer;
    patient.insuranceData.insurance_subscriber_first_name = patient.insuranceSubscriberFirstName;
    patient.insuranceData.insurance_subscriber_gender = patient.insuranceSubscriberGender;
    patient.insuranceData.insurance_subscriber_id = patient.insuranceSubscriberId;
    patient.insuranceData.insurance_subscriber_last_name = patient.insuranceSubscriberLastName;
    patient.insuranceData.insurance_subscriber_party_relationship = patient.insuranceSubscriberPatientRelationshipToSubscriber;
    patient.insuranceData.insurance_subscriber_phone = patient.insuranceSubscriberPhone;
    patient.insuranceData.insurance_subscriber_ssn = patient.insuranceSubscriberPolicyId;
    patient.insuranceData.insurance_plan = patient.insurancePlan;

    patient.otherInsuranceData = {};
    patient.otherInsuranceData.insurance_type = patient.otherCoverageType;
    patient.otherInsuranceData.insurance_plan_name = patient.otherCoveragePlanName;
    patient.otherInsuranceData.insurance_group_id = patient.otherCoverageGroupId;
    patient.otherInsuranceData.insurance_company_address_street_1 = patient.otherCoverageCompanyAddressStreet1;
    patient.otherInsuranceData.insurance_company_address_street_2 = patient.otherCoverageCompanyAddressStreet2;
    patient.otherInsuranceData.insurance_company_address_city = patient.otherCoverageCompanyAddressCity;
    patient.otherInsuranceData.insurance_company_address_state = patient.otherCoverageCompanyAddressState;
    patient.otherInsuranceData.insurance_company_address_zipcode = patient.otherCoverageCompanyAddressZipCode;
    patient.otherInsuranceData.insurance_company_name = patient.otherCoverageCompanyName;
    patient.otherInsuranceData.insurance_company_phone = patient.otherCoverageCompanyPhone;

    patient.otherInsuranceData.insurance_subscriber_address_city = patient.otherCoverageSubscriberAddressCity;
    patient.otherInsuranceData.insurance_subscriber_address_state = patient.otherCoverageSubscriberAddressState;
    patient.otherInsuranceData.insurance_subscriber_address_street_1 = patient.otherCoverageSubscriberAddressStreet1;
    patient.otherInsuranceData.insurance_subscriber_address_street_2 = patient.otherCoverageSubscriberAddressStreet2;
    patient.otherInsuranceData.insurance_subscriber_address_zipcode = patient.otherCoverageSubscriberAddressZipCode;
    patient.otherInsuranceData.insurance_subscriber_birth_date = patient.otherCoverageSubscriberBirthDate;
    patient.otherInsuranceData.insurance_subscriber_employer_name = patient.otherCoverageSubscriberEmployer;
    patient.otherInsuranceData.insurance_subscriber_first_name = patient.otherCoverageSubscriberFirstName;
    patient.otherInsuranceData.insurance_subscriber_gender = patient.otherCoverageSubscriberGender;
    patient.otherInsuranceData.insurance_subscriber_id = patient.otherCoverageSubscriberId;
    patient.otherInsuranceData.insurance_subscriber_last_name = patient.otherCoverageSubscriberLastName;
    patient.otherInsuranceData.insurance_subscriber_party_relationship = patient.otherCoverageSubscriberPatientRelationshipToSubscriber;
    patient.otherInsuranceData.insurance_subscriber_phone = patient.otherCoverageSubscriberPhone;
    patient.otherInsuranceData.insurance_subscriber_ssn = patient.otherCoverageSubscriberPolicyId;
    patient.otherInsuranceData.insurance_plan = patient.otherCoveragePlan;





    return patient;

  }

  function insuranceDataSnakecaseToSnakecase(patient) {
    if(patient.insuranceData){

      patient.insurance_allowance = patient.insuranceData.insurance_allowance;
      patient.insurance_allowance_remaining = patient.insuranceData.insurance_allowance_remaining;
      patient.insurance_allowance_remaining_reset_date = patient.insuranceData.insurance_allowance_remaining_reset_date

      patient.insurance_type = patient.insuranceData.insurance_type;
      patient.insurance_plan_name = patient.insuranceData.insurance_plan_name;
      patient.insurance_group_id = patient.insuranceData.insurance_group_id;
      patient.insurance_company_address_street1 = patient.insuranceData.insurance_company_address_street_1;
      patient.insurance_company_address_street2 = patient.insuranceData.insurance_company_address_street_2;
      patient.insurance_company_address_city = patient.insuranceData.insurance_company_address_city;
      patient.insurance_company_address_state = patient.insuranceData.insurance_company_address_state;
      patient.insurance_company_address_zip_code = patient.insuranceData.insurance_company_address_zipcode;
      patient.insurance_company_name = patient.insuranceData.insurance_company_name;
      patient.insurance_company_phone = patient.insuranceData.insurance_company_phone;

      patient.insurance_subscriber_address_city = patient.insuranceData.insurance_subscriber_address_city;
      patient.insurance_subscriber_address_state = patient.insuranceData.insurance_subscriber_address_state;
      //the allCamelcaseToSnakecase() method does not work correctly for case where there is an underscore
      // between a letter and a number: if there is an underscore in this case it adds another one.
      patient.insurance_subscriber_address_street1 = patient.insuranceData.insurance_subscriber_address_street_1;
      patient.insurance_subscriber_address_street2 = patient.insuranceData.insurance_subscriber_address_street_2;
      patient.insurance_subscriber_address_zip_code = patient.insuranceData.insurance_subscriber_address_zipcode;
      patient.insurance_subscriber_birth_date = patient.insuranceData.insurance_subscriber_birth_date
      patient.insurance_subscriber_employer = patient.insuranceData.insurance_subscriber_employer_name;
      patient.insurance_subscriber_first_name = patient.insuranceData.insurance_subscriber_first_name;
      patient.insurance_subscriber_gender = patient.insuranceData.insurance_subscriber_gender;
      patient.insurance_subscriber_id = patient.insuranceData.insurance_subscriber_id;
      patient.insurance_subscriber_last_name = patient.insuranceData.insurance_subscriber_last_name;
      patient.insurance_subscriber_patient_relationship_to_subscriber = patient.insuranceData.insurance_subscriber_party_relationship;
      patient.insurance_subscriber_phone = patient.insuranceData.insurance_subscriber_phone;
      patient.insurance_subscriber_policy_id = patient.insuranceData.insurance_subscriber_ssn;
      patient.insurance_plan = patient.insuranceData.insurance_plan;


    }

    if(patient.otherInsuranceData){

      patient.other_coverage_type = patient.otherInsuranceData.insurance_type;
      patient.other_coverage_plan_name = patient.otherInsuranceData.insurance_plan_name;
      patient.other_coverage_group_id = patient.otherInsuranceData.insurance_group_id;
      //the allCamelcaseToSnakecase() method does not work correctly for case where there is an underscore
      // between a letter and a number: if there is an underscore in this case it adds another one.
      patient.other_coverage_company_address_street1 = patient.otherInsuranceData.insurance_company_address_street_1;
      patient.other_coverage_company_address_street2 = patient.otherInsuranceData.insurance_company_address_street_2;
      patient.other_coverage_company_address_city = patient.otherInsuranceData.insurance_company_address_city;
      patient.other_coverage_company_address_state = patient.otherInsuranceData.insurance_company_address_state;
      patient.other_coverage_company_address_zip_code = patient.otherInsuranceData.insurance_company_address_zipcode;
      patient.other_coverage_company_name = patient.otherInsuranceData.insurance_company_name;
      patient.other_coverage_company_phone = patient.otherInsuranceData.insurance_company_phone;

      patient.other_coverage_subscriber_address_city = patient.otherInsuranceData.insurance_subscriber_address_city;
      patient.other_coverage_subscriber_address_state = patient.otherInsuranceData.insurance_subscriber_address_state;
      patient.other_coverage_subscriber_address_street1 = patient.otherInsuranceData.insurance_subscriber_address_street_1;
      patient.other_coverage_subscriber_address_street2 = patient.otherInsuranceData.insurance_subscriber_address_street_2;
      patient.other_coverage_subscriber_address_zip_code = patient.otherInsuranceData.insurance_subscriber_address_zipcode;
      patient.other_coverage_subscriber_birth_date = patient.otherInsuranceData.insurance_subscriber_birth_date;
      patient.other_coverage_subscriber_employer = patient.otherInsuranceData.insurance_subscriber_employer_name;
      patient.other_coverage_subscriber_first_name = patient.otherInsuranceData.insurance_subscriber_first_name;
      patient.other_coverage_subscriber_gender = patient.otherInsuranceData.insurance_subscriber_gender;
      patient.other_coverage_subscriber_id = patient.otherInsuranceData.insurance_subscriber_id;
      patient.other_coverage_subscriber_last_name = patient.otherInsuranceData.insurance_subscriber_last_name;
      patient.other_coverage_subscriber_patient_relationship_to_subscriber = patient.otherInsuranceData.insurance_subscriber_party_relationship;
      patient.other_coverage_subscriber_phone = patient.otherInsuranceData.insurance_subscriber_phone;
      patient.other_coverage_subscriber_policy_id = patient.otherInsuranceData.insurance_subscriber_ssn;
      patient.other_coverage_plan = patient.otherInsuranceData.insurance_plan;

    }

  }

  return {
    insuranceDataSnakecaseToSnakecase: insuranceDataSnakecaseToSnakecase,
    insuranceCamelcaseToInsurancedataSnakecase: insuranceCamelcaseToInsurancedataSnakecase,
    getYearsOld: function (iso_date_string) {
      var year = Number(iso_date_string.slice(0, 4));
      var month = Number(iso_date_string.slice(5, 7));
      var date = Number(iso_date_string.slice(8, 10));
      
      if (!year || !month || !date) {
        return null;
      }

      var currentTime = new Date();
      var currentYear = currentTime.getFullYear();
      var currentMonth = currentTime.getMonth() + 1;
      var currentDate = currentTime.getDate();

      var ageYear = currentYear - year;
      if (
        (currentMonth < month) || 
        ((currentMonth === month) && (currentDate < date))
      ) {
        ageYear = ageYear - 1;
      }
      return ageYear.toString();
    },
    all: function () {
      //PATIENTCACHE: off begin
      //return $http.get('/api/v1/patients/');
      //PATIENTCACHE: off end

      //PATIENTCACHE: on begin
      return $q(function(resolve, reject){
        $http.get('/api/v1/patients/').then(function(data) {
          var nonArchivedPatients = data.data.filter(function(patient) {
            return !patient.is_archived;
          });
          data.data = nonArchivedPatients;
          resolve(data);
        }, function(err) {
          reject(err);
        });
      });
      //PATIENTCACHE: on end
    },
    allIncludingArchived: function () {
      //PATIENTCACHE: off begin
      //return $http.get('/api/v1/patients/include_archived/');
      //PATIENTCACHE: off end

      //PATIENTCACHE: on begin
      return $http.get('/api/v1/patients/');
      //PATIENTCACHE: on end
    },
    create: function (patient) {
      return $http.post('/api/v1/patients/', allCamelcaseToSnakecase(patient));
    },
    update: function (patientId, patient) {
      return $http.put('/api/v1/patients/' + patientId + '/?saverid=' + PrincipalService.getSaverid(), allCamelcaseToSnakecase(patient));
    },
    get: function(patientId) {
      return $http.get('/api/v1/patients/' + patientId + '/');
    },
    delete: function(patientId) {
      return $http.delete('/api/v1/patients/' + patientId + '/');
    },
    anonymous: {
      get: function(token, pin) {
        return $http.get('/api/v1/patients/form/fill/', addSignedTokenHeader(token, {params: pin ? {pin: pin} : {}}));
      },
      update: function (token, patient, pin) {
        return $http.put('/api/v1/patients/form/fill/', allCamelcaseToSnakecase(patient), addSignedTokenHeader(token, {params: pin ? {pin: pin} : {}}));
      }
    },
    allCamelcaseToSnakecase: allCamelcaseToSnakecase,
    allSnakecaseToCamelcase: function(patient) {
      String.prototype.toCamelCase = function() {
        return this.replace(/(\_[a-z|0-9])/g, function($1){
          return $1.toUpperCase().replace('_','');
        });
      };

      var camelCasePatient = {};
      for (var property in patient) {
        var underscoreProp = property.toCamelCase();
        camelCasePatient[underscoreProp] = patient[property];
      }

      return camelCasePatient;
    },
    addSignedTokenHeader: function(token, config) {
      return addSignedTokenHeader(token, config);
    },
    mergeExtensions: function(ext, extTempl) { //merges the extTempl from the practice into the ext in the patient.
      if (!ext) {
        ext = {};
      } else {
        ext = angular.fromJson(ext);
      }
      for (var section in extTempl) {
        if (!ext[section]) {
          ext[section] = extTempl[section]; //if the section doesn't exist in ext, create it along with all its contents.
        } else {
          for (var id in extTempl[section]) { 
            if (!ext[section][id]) {
              ext[section][id] = extTempl[section][id]; //if the id doesn't exist in the section in ext, create it along with all its contents
            } else {
              for (var attr in extTempl[section][id]) {
                if (!ext[section][id][attr]) {
                  ext[section][id][attr] = extTempl[section][id][attr]; //if the attr doesn't exist in id, create it with its default
                }
              }
            }
          }
        }
      }
      return ext;
    },
    setExtensionDates: function(extensions, doBackup) {
      for (var section in extensions) {
        for (var id in extensions[section]) {
          for (var attr in extensions[section][id]) {
            if (attr.indexOf('Signature') !== -1 && attr.indexOf('Date') === -1 && attr.indexOf('_') === -1) { // a signature attribute
              if (doBackup) {
                extensions[section][id]["_" + attr] = extensions[section][id][attr];  //back up signature attribute
              } else {
                if (extensions[section][id]["_" + attr] !== 'undefined' &&  (extensions[section][id]["_" + attr] !== extensions[section][id][attr])) {
                  if (extensions[section][id][attr + 'Date'] !== 'undefined') {
                    if (extensions[section][id][attr].trim().length === 0) {
                      extensions[section][id][attr + 'Date'] = "";
                    } else {
                      extensions[section][id][attr + 'Date'] = new Date();
                    }
                  }
                }
                if (extensions[section][id]["_" + attr] !== 'undefined') {
                  delete extensions[section][id]["_" + attr];
                }
              }
            }
          }
        }
      }
    },
    getMedicalHistory : function(patient) {
      return { // sections and items will display in order listed
        dentalInformation:{
          label: 'Dental Information (marked YES)', 
          items: {
            111111111: {
              labelQuestion: "Discomfort, clicking, or popping in jaw?",
              labelStatement: "Discomfort, clicking, or popping in jaw",
              value: patient.is_in_discomfort
            },
            111111112: {
              labelQuestion: "Red, swollen, or bleeding gums?",
              labelStatement: "Swollen gums",
              value: patient.is_swollen_gums
            },
            111111113: {
              labelQuestion: "A removable dental appliance?",
              labelStatement: "Removable appliance",
              value: patient.is_removable_appliance
            },
            111111114: {
              labelQuestion: "Blisters / sores in or around the mouth?",
              labelStatement: "Blisters, Sores",
              value: patient.is_blisters_sores
            },
            111111115: {
              labelQuestion: "Prolonged bleeding from an injury / extraction?",
              labelStatement: "Prolonged bleeding",
              value: patient.is_prolonged_bleeding
            },
            111111116: {
              labelQuestion: "Recent infections or sore throat?",
              labelStatement: "Recent infections",
              value: patient.is_recent_infections
            },
            111111117: {
              labelQuestion: "My teeth are sensitive to hot?",
              labelStatement: "Sensitive to hot",
              value: patient.is_sensitive_to_hot
            },
            111111118: {
              labelQuestion: "My teeth are sensitive to cold?",
              labelStatement: "Sensitive to cold",
              value: patient.is_sensitive_to_cold
            },
            111111119: {
              labelQuestion: "My teeth are sensitive to sweets?",
              labelStatement: "Sensitive to sweets",
              value: patient.is_sensitive_to_sweets
            },
            111111120: {
              labelQuestion: "My teeth are sensitive to chewing/biting?",
              labelStatement: "Sensitive to chewing/biting",
              value: patient.is_sensitive_to_biting
            },
            111111121: {
              labelQuestion: "Stained teeth?",
              labelStatement: "Stained teeth",
              value: patient.is_stained_teeth
            },
            111111122: {
              labelQuestion: "Locking jaw?",
              labelStatement: "Locking jaw",
              value: patient.is_locking_jaw
            },
            111111123: {
              labelQuestion: "Bad Breath?",
              labelStatement: "Bad breath",
              value: patient.is_bad_breath
            },
            111111124: {
              labelQuestion: "Toothache?",
              labelStatement: "Toothache",
              value: patient.is_toothache
            },
            111111125: {
              labelQuestion: "Burning tongue / lips?",
              labelStatement: "Burning tongue / lips",
              value: patient.is_burning_tongue
            },
            111111126: {
              labelQuestion: "Broken fillings?",
              labelStatement: "Broken fillings",
              value: patient.is_lost_broken_fillings
            },
            111111127: {
              labelQuestion: "Teeth grinding / clenching?",
              labelStatement: "Teeth grinding/clenching",
              value: patient.is_teeth_grinding_clenching
            },
            111111128: {
              labelQuestion: "Broken / chipped tooth?",
              labelStatement: "Broken/chipped tooth",
              value: patient.is_broken_chipped_tooth
            },
            111111129: {
              labelQuestion: "Gum Disease?",
              labelStatement: "Gum Disease",
              value: patient.is_gum_disease
            },
            111111130: {
              labelQuestion: "Difficulty closing jaw?",
              labelStatement: "Difficulty closing jaw",
              value: patient.is_difficulty_closing_jaw
            },
            111111131: {
              labelQuestion: "Difficulty opening jaw?",
              labelStatement: "Difficulty opening jaw",
              value: patient.is_difficulty_opening_jaw
            },
            111111132: {
              labelQuestion: "Loose / shifting teeth?",
              labelStatement: "Loose / shifting teeth",
              value: patient.is_loose_shifting_teeth
            },
            111111133: {
              labelQuestion: "Food caught between teeth?",
              labelStatement: "Food caught between teeth",
              value: patient.is_food_caught_between_teeth
            },
            111111134: {
              labelQuestion: "Swelling in mouth?",
              labelStatement: "Swelling in mouth",
              value: patient.is_swelling_lump_in_mouth
            },
            111111135: {
              labelQuestion: "Please Describe?",
              labelStatement: "Other Conditions",
              value: patient.other_condition
            }
          }
        },
        medicalHistory: {
          label: 'Medical History (marked YES)',
          items: {
            111112111: {
              labelQuestion: "Are you in good health?",
              labelStatement: "In good health",
              value: patient.is_in_good_health
            },
            111112112: {
              labelQuestion: "Are you under the care of a physician?",
              labelStatement: "Under care of physician",
              value: patient.is_under_physician_care
            },
            111112113: {
              labelQuestion: "Has a physician or previous dentist recommended that you take antibiotics prior to your dental treatment?",
              labelStatement: "Recommended pre-medication antibiotics",
              value: patient.is_recommended_antibiotics
            },
            111112114: {
              labelQuestion: "Have you had any illness, operation or been hospitalized in the past five years?",
              labelStatement: "Hospitalized in the past five years",
              value: patient.is_hospitalized_past_five_years
            },
            111112115: {
              labelQuestion: "Have you ever had general anesthesia?",
              labelStatement: "Had general anesthesia",
              value: patient.is_had_general_anesthesia
            },
            111112116: {
              labelQuestion: "Have you had any unusual or serious reaction to general anesthesia?",
              labelStatement: "Had reaction to anesthesia",
              value: patient.is_had_reaction_anesthesia
            }
          }
        },
        medicalConditions: {
          label: 'Medical Conditions (marked YES)',
          items: {
            111113111: {
              labelQuestion: "Rheumatic fever?",
              labelStatement: "Rheumatic fever",
              value: patient.is_rheumtic_fever
            },
            111113112: {
              labelQuestion: "High blood pressure?",
              labelStatement: "High blood pressure",
              value: patient.is_high_blood_pressure
            },
            111113113: {
              labelQuestion: "Low blood pressure?",
              labelStatement: "Low blood pressure",
              value: patient.is_low_blood_pressure
            },
            111113114: {
              labelQuestion: "Mitral valve prolapse?",
              labelStatement: "Mitral valve prolapse",
              value: patient.is_mitral_valve_prolapse
            },
            111113115: {
              labelQuestion: "Heart murmur?",
              labelStatement: "Heart murmur",
              value: patient.is_heart_murmur
            },
            111113116: {
              labelQuestion: "Chest pain/angina?",
              labelStatement: "Chest pain / angina",
              value: patient.is_chest_pain_angina
            },
            111113117: {
              labelQuestion: "Heart attack(s)?",
              labelStatement: "Heart attack(s)",
              value: patient.is_heart_attacks
            },
            111113118: {
              labelQuestion: "Irregular heart beat?",
              labelStatement: "Irregular heart beat",
              value: patient.is_irregular_heart_beat
            },
            111113119: {
              labelQuestion: "Cardiac pacemaker?",
              labelStatement: "Cardiac pacemaker",
              value: patient.is_cardiac_pacemaker
            },
            111113120: {
              labelQuestion: "Heart surgery?",
              labelStatement: "Heart surgery",
              value: patient.is_heart_surgery
            },
            111113121: {
              labelQuestion: "Damaged heart valves?",
              labelStatement: "Damaged heart valves",
              value: patient.is_damaged_heart_valves
            },
            111113122: {
              labelQuestion: "Pneumonia, Bronchitis or Chronic Cough?",
              labelStatement: "Pneumonia, Bronchitis or Chronic cough",
              value: patient.is_pneumonia
            },
            111113123: {
              labelQuestion: "Chronic fatigue / night sweats?",
              labelStatement: "Chronic fatigue / night sweats",
              value: patient.is_chronic_fatigue
            },
            111113124: {
              labelQuestion: "Trouble climbing 1-2 flights of stairs?",
              labelStatement: "Trouble climbing stairs",
              value: patient.is_trouble_climbing_stairs
            },
            111113125: {
              labelQuestion: "Anemia?",
              labelStatement: "Anemia",
              value: patient.is_anemia
            },
            111113126: {
              labelQuestion: "Asthma?",
              labelStatement: "Asthma",
              value: patient.is_asthma
            },
            111113127: {
              labelQuestion: "Bleeding Tendency?",
              labelStatement: "Bleeding tendency",
              value: patient.is_bleeding_tendency
            },
            111113128: {
              labelQuestion: "Blood Transfusion?",
              labelStatement: "Blood transfusion",
              value: patient.is_blood_transfusion
            },
            111113129: {
              labelQuestion: "Blood Disorder?",
              labelStatement: "Blood disorder",
              value: patient.is_blood_disorder
            },
            111113130: {
              labelQuestion: "Bruise easily?",
              labelStatement: "Bruise easily",
              value: patient.is_bruise_easily
            },
            111113131: {
              labelQuestion: "Eye disease / glaucoma?",
              labelStatement: "Eye disease / Glaucoma",
              value: patient.is_eye_disease
            },
            111113132: {
              labelQuestion: "Jaundice / Liver disease?",
              labelStatement: "Jaundice / Liver disease",
              value: patient.is_liver_disease
            },
            111113133: {
              labelQuestion: "Hepatitis?",
              labelStatement: "Hepatitis",
              value: patient.is_hepatitis
            },
            111113134: {
              labelQuestion: "Gallbladder trouble?",
              labelStatement: "Gallbladder trouble",
              value: patient.is_gallbladder_trouble
            },
            111113135: {
              labelQuestion: "Fainting spells?",
              labelStatement: "Fainting spells",
              value: patient.is_fainting_spells
            },
            111113136: {
              labelQuestion: "Epilepsy or Seizures?",
              labelStatement: "Epilepsy or Seizures",
              value: patient.is_convulsions_epilepsy
            },
            111113137: {
              labelQuestion: "Stroke?",
              labelStatement: "Stroke",
              value: patient.is_stroke
            },
            111113138: {
              labelQuestion: "Thyroid trouble?",
              labelStatement: "Thyroid trouble",
              value: patient.is_thyroid_trouble
            },
            111113139: {
              labelQuestion: "Diabetes?",
              labelStatement: "Diabetes",
              value: patient.is_diabetes
            },
            111113140: {
              labelQuestion: "Low blood sugar?",
              labelStatement: "Low blood sugar",
              value: patient.is_low_blood_sugar
            },
            111113141: {
              labelQuestion: "Are you on dialysis?",
              labelStatement: "On dialysis",
              value: patient.is_on_dialysis
            },
            111113142: {
              labelQuestion: "HIV?",
              labelStatement: "HIV",
              value: patient.is_hiv
            },
            111113143: {
              labelQuestion: "AIDS?",
              labelStatement: "AIDS",
              value: patient.is_aids
            },
            111113144: {
              labelQuestion: "Kidney trouble?",
              labelStatement: "Kidney Trouble",
              value: patient.is_kidney_trouble
            },
            111113145: {
              labelQuestion: "Mental health problems?",
              labelStatement: "Mental health problems",
              value: patient.is_mental_health_problems
            },
            111113146: {
              labelQuestion: "Problems with immune system (possibly from med. / surg.)?",
              labelStatement: "Immune system problems",
              value: patient.is_immune_system_problems
            },
            111113147: {
              labelQuestion: "Delay in healing?",
              labelStatement: "Delay in healing",
              value: patient.is_delay_in_healing
            },
            111113148: {
              labelQuestion: "Hay fever / Sinus problems?",
              labelStatement: "Hay fever / Sinus problems",
              value: patient.is_sinus_problems
            },
            111113149: {
              labelQuestion: "Snoring?",
              labelStatement: "Snoring",
              value: patient.is_snoring
            },
            111113150: {
              labelQuestion: "Sleep Apnea / CPAP?",
              labelStatement: "Sleep Apnea / CPAP",
              value: patient.is_sleep_apnea
            },
            111113151: {
              labelQuestion: "Respiratory problems?",
              labelStatement: "Respiratory problems",
              value: patient.is_respiratory_problems
            },
            111113152: {
              labelQuestion: "Tuberculosis?",
              labelStatement: "Tuberculosis",
              value: patient.is_tuberculosis
            },
            111113153: {
              labelQuestion: "Emphysema?",
              labelStatement: "Emphysema",
              value: patient.is_emphysema
            },
            111113154: {
              labelQuestion: "Do you smoke?",
              labelStatement: "Smokes",
              value: patient.is_smoker
            },
            111113155: {
              labelQuestion: "Do you use chewing tobacco?",
              labelStatement: "Uses chewing tobacco",
              value: patient.is_using_chewing_tobacco
            },
            111113156: {
              labelQuestion: "A history of drug abuse?",
              labelStatement: "Has drug abuse history",
              value: patient.is_has_drug_abuse_history
            },
            111113157: {
              labelQuestion: "A history of alcohol abuse?",
              labelStatement: "Has alcohol abuse history",
              value: patient.is_has_alcohol_abuse_history
            },
            111113158: {
              labelQuestion: "Abnormal bleeding?",
              labelStatement: "Abnormal bleeding",
              value: patient.is_abnormal_bleeding
            },
            111113159: {
              labelQuestion: "Sexually transmitted disease?",
              labelStatement: "Sexually transmitted disease",
              value: patient.is_sexually_transmitted_disease
            },
            111113160: {
              labelQuestion: "Contagious diseases?",
              labelStatement: "Contagious diseases",
              value: patient.is_contagious_diseases
            },
            111113161: {
              labelQuestion: "Infectious mononucleosis?",
              labelStatement: "Infectious mononucleosis",
              value: patient.is_infectious_mononucleosis
            },
            111113162: {
              labelQuestion: "Swollen Ankles?",
              labelStatement: "Swollen ankles",
              value: patient.is_swollen_ankles
            },
            111113163: {
              labelQuestion: "Arthritis / Joint disease?",
              labelStatement: "Arthritis / Joint disease",
              value: patient.is_arthritis
            },
            111113164: {
              labelQuestion: "Prosthetic implant?",
              labelStatement: "Prosthetic implant",
              value: patient.is_prosthetic_implant
            },
            111113165: {
              labelQuestion: "Joint replacement?",
              labelStatement: "Joint replacement",
              value: patient.is_joint_replacement
            },
            111113166: {
              labelQuestion: "Osteoporosis / osteopenia?",
              labelStatement: "Osteoporosis / osteopenia",
              value: patient.is_osteoporosis
            },
            111113167: {
              labelQuestion: "Osteonecrosis?",
              labelStatement: "Osteonecrosis",
              value: patient.is_osteonecrosis
            },
            111113168: {
              labelQuestion: "Stomach ulcers?",
              labelStatement: "Stomach ulcers",
              value: patient.is_stomach_ulcers
            },
            111113169: {
              labelQuestion: "Tumor or growth?",
              labelStatement: "Tumor growth",
              value: patient.is_tumor_growth
            },
            111113170: {
              labelQuestion: "Cancer / Radiation / Chemotherapy?",
              labelStatement: "Cancer / Radiation / Chemotherapy",
              value: patient.is_cancer
            },
            111113171: {
              labelQuestion: "Are you on a diet?",
              labelStatement: "Is on a diet",
              value: patient.is_on_diet
            },
            111113172: {
              labelQuestion: "Are you Pregnant?",
              labelStatement: "Is Pregnant",
              value: patient.is_pregnant
            }
          }
        },
        medications: {
          label: 'Medications (marked IS TAKING)',
          items: {
            111114111: {
              labelQuestion: "Medication / Antibiotic name",
              labelStatement: "",
              value: patient.taking_medication_0
            },
            111114112: {
              labelQuestion: "Medication / Antibiotic name",
              labelStatement: "",
              value: patient.taking_medication_1
            },
            111114113: {
              labelQuestion: "Medication / Antibiotic name",
              labelStatement: "",
              value: patient.taking_medication_2
            },
            111114114: {
              labelQuestion: "Medication / Antibiotic name",
              labelStatement: "",
              value: patient.taking_medication_3
            },
            111114115: {
              labelQuestion: "Medication / Antibiotic name",
              labelStatement: "",
              value: patient.taking_medication_4
            },
            111114116: {
              labelQuestion: "Medication / Antibiotic name",
              labelStatement: "",
              value: patient.taking_medication_5
            },
            111114117: {
              labelQuestion: "Medication / Antibiotic name",
              labelStatement: "",
              value: patient.taking_medication_6
            },
            111114118: {
              labelQuestion: "Medication / Antibiotic name",
              labelStatement: "",
              value: patient.taking_medication_7
            },
            111114119: {
              labelQuestion: "Medication / Antibiotic name",
              labelStatement: "",
              value: patient.taking_medication_8
            },
            111114120: {
              labelQuestion: "Medication / Antibiotic name",
              labelStatement: "",
              value: patient.taking_medication_9
            },
            111114121: {
              labelQuestion: "Nerve pills?",
              labelStatement: "Nerve pills",
              value: patient.is_taking_nerve_pills
            },
            111114122: {
              labelQuestion: "Diet Pills?",
              labelStatement: "Diet pills",
              value: patient.is_taking_diet_pills
            },
            111114123: {
              labelQuestion: "Pain Medications?",
              labelStatement: "Pain medications",
              value: patient.is_taking_pain_killers
            },
            111114124: {
              labelQuestion: "Tranquilizers?",
              labelStatement: "Tranquilizers",
              value: patient.is_taking_tranquilizers
            },
            111114125: {
              labelQuestion: "Muscle relaxers?",
              labelStatement: "Muscle relaxers",
              value: patient.is_taking_muscle_relaxers
            },
            111114126: {
              labelQuestion: "Insulin?",
              labelStatement: "Insulin",
              value: patient.is_taking_insulin
            },
            111114127: {
              labelQuestion: "Stimulants?",
              labelStatement: "Stimulants",
              value: patient.is_taking_stimulants
            },
            111114128: {
              labelQuestion: "Antidepressants?",
              labelStatement: "Antidepressants",
              value: patient.is_taking_antidepressants
            },
            111114129: {
              labelQuestion: "Blood thinners (Coumadin, Aspirin)?",
              labelStatement: "Blood Thinners",
              value: patient.is_taking_blood_thinners
            },
            111114130: {
              labelQuestion: "Are you taking, or have you ever taken, any bone density meds. or bisphosphonates, such as Fosamax, Boniva, Actonel, IV Zometa, Reclast, Xgeva, Prolia, or Aredia within the past 12 years?",
              labelStatement: "Bone density meds / Bisphosphonates",
              value: patient.is_taking_bone_density_meds
            },
            111114131: {
              labelQuestion: "Antibiotic?",
              labelStatement: "Antibiotic",
              value: patient.is_taking_antibiotic
            },
            111114132: {
              labelQuestion: "Sodium pentothal, Valium, or other tranquilizers?",
              labelStatement: "Valium",
              value: patient.is_taking_valium
            }
          }
        },
        allergies: {
          label: 'Allergies (marked YES)',
          items: {
            111115111: {
              labelQuestion: "Soy?",
              labelStatement: "Soy",
              value: patient.is_allergic_to_soy
            },
            111115112: {
              labelQuestion: "Sulfa Drugs?",
              labelStatement: "Sulfa drugs",
              value: patient.is_allergic_to_sulfa_drugs
            },
            111115113: {
              labelQuestion: "Aspirin?",
              labelStatement: "Aspirin",
              value: patient.is_allergic_to_aspirin
            },
            111115114: {
              labelQuestion: "Eggs/Yolk?",
              labelStatement: "Eggs / Yolks",
              value: patient.is_allergic_to_eggs
            },
            111115115: {
              labelQuestion: "Local anesthetic (numbing medication)?",
              labelStatement: "Local Anesthetic",
              value: patient.is_allergic_to_local_anesthetic
            },
            111115116: {
              labelQuestion: "Codeine or other narcotics?",
              labelStatement: "Codeine or other narcotics",
              value: patient.is_allergic_to_codeine
            },
            111115117: {
              labelQuestion: "Sulfites?",
              labelStatement: "Sulfites",
              value: patient.is_allergic_to_sulfites
            },
            111115118: {
              labelQuestion: "Amoxicillin?",
              labelStatement: "Amoxicillin",
              value: patient.is_allergic_to_amoxicillin
            },
            111115119: {
              labelQuestion: "Latex?",
              labelStatement: "Latex",
              value: patient.is_allergic_to_latex
            },
            111115120: {
              labelQuestion: "Please list any allergies other than drug allergies?",
              labelStatement: "",
              value: patient.non_drug_allergies
            },
          }
        }
      }
    }
  };
}

  angular.module('common.services.patients', [])
    .factory('PatientsService', patientsService);
})();

(function() {
  'use strict';

/* jshint undef:false*/
function encountersService($http, PrincipalService) {
  return {
    all: function (patientId) {
      return $http.get('/api/v1/encounters/patients/' + patientId + '/');
    },
    create: function (patientId, providerId, encounter) {
      return $http.post('/api/v1/encounters/patients/' + patientId + '/providers/' + providerId + '/', encounter);
    },
    get: function (encounterId) {
      return $http.get('/api/v1/encounters/' + encounterId + '/');
    },
    update: function (encounterId, encounter) {
        return $http.put('api/v1/encounters/' + encounterId + '/?saverid=' + PrincipalService.getSaverid(), encounter);
    },
    delete: function (encounterId) {
        return $http.delete('api/v1/encounters/' + encounterId + '/');
    },
    getPrintPatientViewUrl: function (encounterId) {
        return '/api/v1/encounters/' + encounterId + '/patientprintview/';
    }
  };
}

  angular.module('common.services.encounters', [])
    .factory('EncountersService', encountersService);
})();

/*global moment */
/*global _ */
/*jshint camelcase: false */
(function () {
  'use strict';

  function scheduleService($http, PatientsService) {
    var getAppointmentTypeName = function (id) {
      var strings = {
        'npv': 'New Patient Visit',
        'ev': 'Evaluation',
        'rc': 'Root Canal',
        'ob': 'Obturation',
        're': 'Remedicate',
        'rt': 'Re-Treatment',
        'a': 'Apicoetomy',
        'po': 'Post-Op'
      };
      return strings[id];
    };

    function formatPhoneNumber(number) {
      return number.length !== 10 ? '' :
        number.substring(0, 3) + '-' +
        number.substring(3, 6) + '-' +
        number.substring(6, 10);
    }

    function addSignedTokenHeader(token, config) {
      if (!config) {
        config = {
          headers: {}
        };
      } else if (!config.headers) {
        config.headers = {};
      }

      config.headers['Signed-Token'] = token;
      return config;
    }


    var transformAppointments = function (data, multicolumnByProvider, filterId, colorBy, isBackgroundEditMode) {
      function getColor(field_value, field_name_gb) {
        var opacity = '1.0';
        var field_name = field_name_gb ? field_name_gb : 'appointment_type';

        if (field_name === 'appointment_type') {
          var typeColorMap = {
            'npv': 'rgba(162, 194, 101, ' + opacity + ')',
            'ev': 'rgba(153, 189, 255, ' + opacity + ')',
            'rc': 'rgba(144, 213, 213, ' + opacity + ')',
            'ob': 'rgba(255, 187, 77, ' + opacity + ')',
            're': 'rgba(198, 168, 244, ' + opacity + ')',
            'rt': 'rgba(255, 165, 125, ' + opacity + ')',
            'a': 'rgba(103, 152, 130, ' + opacity + ')',
            'po': 'rgba(100, 103, 140, ' + opacity + ')'
          };
          if (typeColorMap.hasOwnProperty(field_value)) {
            return typeColorMap[field_value];
          }
        } else if (field_name === 'appointment_status') {
          var statusColorMap = {
            'unconfirmed': 'rgba(144, 213, 213,  ' + opacity + ')',
            'contact_attempted': 'rgba(153, 189, 255,  ' + opacity + ')',
            'confirmed': 'rgba(198, 168, 244,  ' + opacity + ')',
            'late': 'rgba(255, 187, 77,   ' + opacity + ')',
            'paperwork': 'rgba(255, 165, 125, ' + opacity + ')',
            'checked_in': 'rgba(162, 194, 101,  ' + opacity + ')',
            'seated': 'rgba(103, 152, 130,  ' + opacity + ')',
            'checked_out': 'rgba(100, 103, 140,  ' + opacity + ')',
            'completed': 'rgba(181, 181, 181,  ' + opacity + ')',
            'no_show': 'rgba(255, 105, 102,  ' + opacity + ')',
            'canceled': 'rgba(213, 214, 212,  ' + opacity + ')'
          };
          if (statusColorMap.hasOwnProperty(field_value)) {
            return statusColorMap[field_value];
          }
        }

        if (typeof field_value === 'string' || field_value instanceof String) {
          var stringFieldValue = field_value;
          field_value = 0;
          for (var i = 0; i < stringFieldValue.length; i++) {
            field_value += stringFieldValue.charCodeAt(i);
          }
        }

        var colors = [
          'rgba(144, 213, 213, ' + opacity + ')',
          'rgba(255, 105, 102, ' + opacity + ')',
          'rgba(162, 194, 101, ' + opacity + ')',
          'rgba(153, 189, 255, ' + opacity + ')',
          'rgba(255, 187, 77, ' + opacity + ')',
          'rgba(198, 168, 244, ' + opacity + ')',
          'rgba(255, 165, 125, ' + opacity + ')',
          'rgba(126, 146, 206, ' + opacity + ')',
          'rgba(213, 144, 144, ' + opacity + ')',
          'rgba(103, 152, 130, ' + opacity + ')',
          'rgba(213, 196, 144, ' + opacity + ')',
          'rgba(152, 103, 140, ' + opacity + ')'
        ];

        field_value = field_value % colors.length;
        return colors[field_value];
      }

      var filterType = (multicolumnByProvider) ? 'provider' : 'operatory';

      filterId = '' + filterId;

      var rawAppointments = angular.fromJson(data);
      var appointments = [];

      var groups = _.groupBy(rawAppointments, filterType);

      _.each(groups, function (group) {
        var filterValue = '' + group[0][filterType];
        if (isNaN(filterId) || filterValue === filterId) {
          _.each(group, function (appointment) {
            var start = moment(appointment.start_datetime).toDate();
            var end = moment(appointment.end_datetime).toDate();
            var title = ((appointment.patient_event_alerts.length > 0) ? '! ' : '') +
              appointment.patient_first_name + ' ' +
              appointment.patient_last_name + ' ' +
              ((appointment.patient_birth_date) ? ('(' + PatientsService.getYearsOld(appointment.patient_birth_date) + 'y) ') : '') +
              '- ' +
              appointment.complaint;
            var homePhone = formatPhoneNumber(appointment.patient_home_phone);
            var mobilePhone = formatPhoneNumber(appointment.patient_mobile_phone);
            var appointmentItem = {
              id: appointment.id,
              start: start,
              end: end,
              title: title,
              extendedProps: {
                complaint: appointment.complaint,
                operatory: appointment.operatory,
                operatoryName: appointment.operatory_name,
                provider: appointment.provider,
                appointmentType: appointment.appointment_type,
                appointmentStatus: appointment.appointment_status,
                providerFirstName: appointment.provider_first_name,
                providerLastName: appointment.provider_last_name,
                reminderStatus: appointment.reminder_status,
                reminderSentDate: appointment.reminder_sent_date,
                patient: appointment.patient,
                patientFirstName: appointment.patient_first_name,
                patientLastName: appointment.patient_last_name,
                patientYearsOld: (appointment.patient_birth_date) ? PatientsService.getYearsOld(appointment.patient_birth_date) : null,
                patientMobilePhone: mobilePhone,
                type: 'appointment',
                patient_event_alerts: appointment.patient_event_alerts,
                patientHomePhone: homePhone
              },
              allDay: false,
              display: isBackgroundEditMode ? 'none' : 'auto',
              backgroundColor: getColor(appointment[colorBy], colorBy),
              textColor: 'black',
            };

            if (multicolumnByProvider) {
              appointmentItem.resourceId = appointment.provider;
            } else {
              appointmentItem.resourceId = appointment.operatory;
            }
            appointments.push(appointmentItem);
          });
        }
      });

      return appointments;
    };

    return {
      transformAppointments: transformAppointments,
      getAppointmentTypeName: getAppointmentTypeName,
      all: function () {
        var fromDate = new Date('2015-06-05T13:00:00');
        var toDate = new Date('2020-07-01T15:24:00');
        return this.allBetween(fromDate, toDate);
      },
      allBetween: function (fromDate, toDate) {
        var fromSecondsSinceEpoch = fromDate.getTime() / 1000; //convert from millis to seconds
        var toSecondsSinceEpoch = toDate.getTime() / 1000;

        //Service doesn't accept decimals so lets round it

        fromSecondsSinceEpoch = Math.round(fromSecondsSinceEpoch);
        toSecondsSinceEpoch = Math.round(toSecondsSinceEpoch);

        return $http.get('/api/v1/appointments/frommsecondssinceepoch/' + fromSecondsSinceEpoch + '/tosecondsfromepoch/' + toSecondsSinceEpoch + '/');
      },
      create: function (appointment) {
        /*jshint camelcase: false */
        return $http.post('/api/v1/appointments/', appointment);
      },
      update: function (id, appointment) {
        /*jshint camelcase: false */
        return $http.put('/api/v1/appointments/' + id + '/', appointment);
      },

      getAppointment: function (id) {
        /*jshint camelcase: false */
        return $http.get('/api/v1/appointments/' + id + '/');
      },

      sendReminder: function (appointmentId) {
        return $http.post('/api/v1/appointment/reminder/' + appointmentId + '/');
      },

      getAppointmentReminder: function (token) {
        return $http.get('/api/v1/appointment/reminder/', addSignedTokenHeader(token));
      },

      confirmAppointmentReminder: function (token) {
        var req = {
          method: 'PUT',
          url: '/api/v1/appointment/reminder/confirm/',
          headers: {
            'Signed-Token': token
          }
        };

        return $http(req);
      },

      delete: function (id, appointment) {
        /*jshint camelcase: false */
        return $http.delete('/api/v1/appointments/' + id + '/', appointment);
      },
      formatPhoneNumber: function (number) {
        return formatPhoneNumber(number);
      },
    };
  }

  angular.module('common.services.schedule', [])
    .factory('ScheduleService', scheduleService);
})();

/*global moment */
(function () {
  'use strict';

  function scheduleCommonService() {
    var service = {}

    /**
     * Helper function to calculate endTime from startTime + duration
     * @param {Number} startTime 
     * @param {Number} duration 
     * @returns 
     */
    function calculateEndTime(startTime, duration) {
      return moment(startTime).clone().add(duration, 'minutes').toDate();
    }

    /**
     * @param {String} startDate 
     * @param {Object} startTime 
     * @param {String} currentOperatoryTZ 
     * @param {String} momentDateFormat 
     * @param {Object} repeatType 
     * @param {Number} providerId 
     * @param {Number} operatoryId 
     * @param {Number} duration 
     * @param {String} reason 
     * @param {Boolean} is_background 
     * @returns 
     */
    function prepareTimeblock(startDate, startTime, currentOperatoryTZ, momentDateFormat, repeatType,
      providerId, operatoryId, duration, reason, is_background) {
      // Take two datetime objects and sets the time
      // only elements of the first to the second, and
      // returns a string to send to the server.
      var start = prepDatetime(startDate, startTime, currentOperatoryTZ, momentDateFormat);
      var timeblock = {
        provider: (providerId) ? parseInt(providerId) : null,
        operatory: (operatoryId) ? parseInt(operatoryId) : null,
        start_datetime: start.toISOString(),
        duration: duration,
        reason: reason,
        is_background: is_background
      };

      if(repeatType.value === 0) {
        timeblock.recur = null;
      } 

      if (typeof repeatType.value === 'string') {
        timeblock.recur = window.rrule.rrulestr("DTSTART:" + start.utc().format("YYYYMMDDTHHmmss") + "Z\n" + repeatType.value);
      }
      if (timeblock.recur) {
        timeblock.rrule = timeblock.recur.toString();
      }
      return timeblock;
    }

    /**
     * @param {String} startTime 
     * @param {Number} duration 
     * @param {String} currentOperatoryTZ 
     * @param {String} momentTimeFormat 
     * @param {String} timeblockString 
     * @returns 
     */
    function timeChange(startTime, duration, currentOperatoryTZ, momentTimeFormat, timeblockString) {
      var endTime = calculateEndTime(startTime, duration);

      if (currentOperatoryTZ) {
        timeblockString = (
          moment(startTime).format(momentTimeFormat) + ' - ' +
          moment(endTime).format(momentTimeFormat)
        );
      }
      else {
        timeblockString = (
          moment.tz(startTime, currentOperatoryTZ).format(momentTimeFormat) + ' - ' +
          moment.tz(endTime, currentOperatoryTZ).format(momentTimeFormat)
        );
      }
      return timeblockString
    }

    /**
     * @param {Object} operatories 
     * @param {Number} operatoryId 
     * @returns 
     */
    function onOperatoryChange(operatories, operatoryId) {
      var currentOperatory = operatories.find(function (operatory) {
        return operatory.id === operatoryId
      })
      var currentOperatoryTZ;
      if (currentOperatory) {
        currentOperatoryTZ = currentOperatory.time_zone
      } else {
        currentOperatoryTZ = moment.tz.guess();
      }
      return currentOperatoryTZ
    } 

    /**
     * Helper function to prepare datetime
     * @param {String} date 
     * @param {Object} time 
     * @param {String} currentOperatoryTZ 
     * @param {String} momentDateFormat 
     * @returns 
     */
    function prepDatetime(date, time, currentOperatoryTZ, momentDateFormat) {
      var returnDate;
      if (typeof date === "string") {
        if (currentOperatoryTZ) {
          returnDate = moment.tz(date, momentDateFormat, currentOperatoryTZ);
        } else {
          returnDate = moment(date, momentDateFormat);
        }
      } else {
        returnDate = moment(date);
      }
      returnDate.hours(time.getHours());
      returnDate.minutes(time.getMinutes());
      return returnDate;
    }

    /**
     * @param {object} TimeblockService 
     * @param {object} NotificationService 
     * @param {object} $uibModalInstance 
     * @param {object} event 
     * @param {String} eventName 
     */
    function create(TimeblockService, NotificationService, $uibModalInstance, event, eventName) {
      TimeblockService.create(event).then(
        function success() {
          NotificationService.success(eventName + ' added successfully');
          $uibModalInstance.close();
        },
        function fail(err) {
          NotificationService.error('Error adding ' + eventName,err);
        });
    }

    /**
     * @param {Object} TimeblockService 
     * @param {Object} NotificationService 
     * @param {Object} $uibModalInstance 
     * @param {String} eventId 
     * @param {Object} event 
     * @param {String} eventName 
     */
    function update(TimeblockService, NotificationService, $uibModalInstance, eventId,  event, eventName) {
      TimeblockService.update(eventId, event).then(
        function success() {
          NotificationService.success(eventName + ' updated successfully');
          $uibModalInstance.close();
        },
        function fail(err) {
          NotificationService.error('Error updating ' + eventName,err);
        });
    }
    
    /**
     * @param {Object} TimeblockService 
     * @param {Object} NotificationService 
     * @param {Object} $uibModalInstance 
     * @param {Object} eventId 
     * @param {String} eventName 
     */
    function deleteNotification(TimeblockService, NotificationService, $uibModalInstance, eventId, eventName) {
      TimeblockService.delete(eventId).then(
        function success() {
          NotificationService.success(eventName +  ' deleted');
          $uibModalInstance.close();
        },
        function fail(err) {
          NotificationService.error('Error deleting ' + eventName,err);
        });
    }

    /**
     * @param {Object} $uibModalInstance 
     */
    function cancel($uibModalInstance) {
      $uibModalInstance.dismiss('cancel');
    }

    /**
     * @param {Object} $uibModal 
     * @param {Object} LocalStorageService 
     * @param {Object} repeatType 
     * @param {Boolean} animationsEnabled 
     * @returns 
     */
    function openCustomRepeatModal(
      $uibModal, 
      LocalStorageService, 
      repeatType, 
      animationsEnabled,
      $scope
      ) {
      if (repeatType.value === 1) {
        // is custom should only return new repeatType
        var modalInstance = $uibModal.open({
          animation: animationsEnabled,
          templateUrl: "src/common/schedule-recurring-add.tpl.html",
          controller: "ScheduleRecurringAddCtrl",
          size: 'md'
        });

        modalInstance.result.then(function (result) {
          var repeatOptions = LocalStorageService.get('repeatOptions');
          var option = repeatOptions.find((option) => option.value === result.value)
          if(!option) {
            repeatOptions.push(result);
            LocalStorageService.set('repeatOptions', repeatOptions);
            $scope.repeatType = result;
          } else {
            $scope.repeatType = option;
          }
         
        });
      } else {
        $scope.repeatType = repeatType;
      }

    }
    
    /**
     * @param {Object} AuthenticationService 
     * @param {Object} PrincipalService 
     * @param {Object} NotificationService 
     * @param {Object} providerId 
     * @returns 
     */
    function AuthServiceHelper(AuthenticationService, PrincipalService, NotificationService, providerId) {
      return new Promise(function(resolve) {
        AuthenticationService.getPracticeAdminAndProviderAccounts(PrincipalService.getPracticeId())
          .then(function(data) {
            var providers = angular.fromJson(data.data);
            if (providers.length !== 0 && providerId === 0) {
              providerId = providers[0].id;
            }
            resolve({
              providerId: providerId,
              providers: providers,
            });
          }, function(err) {
            NotificationService.error('Error loading users list', err);
          })
      });
    }

    /**
     * @param {Object} LocationsService 
     * @param {Boolean} MultiLocationEnable 
     * @param {Number} operatoryId 
     * @param {Object} currentOperatoryTZ 
     * @param {Object} timeblock 
     * @param {String} startDate 
     * @param {Object} startTime 
     * @param {String} momentDateFormat 
     * @param {Number} duration 
     * @param {String} momentTimeFormat 
     * @param {String} timeblockString 
     * @param {Object} NotificationService 
     * @returns 
     */
    function LocServiceHelper(LocationsService, MultiLocationEnable, operatoryId,
      currentOperatoryTZ, timeblock, startDate, startTime, momentDateFormat,
      duration, momentTimeFormat, timeblockString, NotificationService) {
     return new Promise(function(resolve) {
       LocationsService.all()
         .then(function(data) {
           var locations = angular.fromJson(data.data);
           var operatories = []

           if (MultiLocationEnable) {
             operatories = locations.reduce(
               function(memo, location) {
                 location.operatories.forEach(function(operatory) {
                   memo.push(angular.extend({}, operatory, { time_zone: location.time_zone }));
                 });
                 return memo;
               },
               []
             );
           } else {
             operatories = locations[0].operatories.map(function(operatory) {
               return angular.extend({}, operatory, { time_zone: locations[0].time_zone });
             });
           }
   
           if (operatories.length) {
             if (operatoryId === 0) {
               operatoryId = operatories[0].id;
               currentOperatoryTZ = operatories[0].time_zone;
             } else {
               var currentOperatory = operatories.find(function(operatory) {
                 return operatory.id === +operatoryId;
               });
               if (currentOperatory) {
                 currentOperatoryTZ = currentOperatory.time_zone;
               } else {
                 currentOperatoryTZ = moment.tz.guess();
               }
   
               var time = timeblock.start;
               if (timeblock.rrule !== '') {
                 time = timeblock.originalStart;
               }
               var dateInOperatoryTZ = moment(time).tz(currentOperatoryTZ);
               startDate = dateInOperatoryTZ.format(momentDateFormat);
               startTime = new Date(startTime.setHours(dateInOperatoryTZ.hours(), dateInOperatoryTZ.minutes()));
             }
             timeblockString = timeChange(startTime, duration, currentOperatoryTZ, momentTimeFormat, timeblockString);
           }
   
           resolve({
             currentOperatoryTZ: currentOperatoryTZ,
             operatories: operatories,
             operatoryId: operatoryId,
             timeblockString: timeblockString,
             startDate: startDate,
             startTime: startTime,
             timeblock: timeblock,
           });
         }, function(err) {
          NotificationService.error('Error loading Operatories list', err);
         })
     });
   }

   /**
    * 
    * @param {Object} repeatOptions 
    * @param {Object} timeblock 
    * @param {Object} repeatType 
    * @param {Object} LocalStorageService 
    * @param {String} momentDateFormat 
    * @returns 
    */
   function initVariables(repeatOptions, timeblock, repeatType, LocalStorageService, momentDateFormat) {
    var startDate;
    var startTime;
    var duration;
    
    if(repeatOptions && timeblock.rrule && timeblock.rrule !== '') {
      var rruleObj = window.rrule.rrulestr(timeblock.rrule)
      if(rruleObj && rruleObj.toText() === 'every day') {
        repeatType = repeatOptions[1]
      } else {
        repeatType = repeatOptions.find(
          function(repeatOption) { 
            return repeatOption.label.toLowerCase() === rruleObj.toText().toLowerCase()
          })
      }
    
      if(!repeatType) {
        // remove all date info so remove dtstart or until if exists. Which it should
        var rruleOptions = rruleObj.origOptions;
        delete rruleOptions.dtstart
        delete rruleOptions.until

        var newRepeatOptions = {
          label: window.titleCase(rruleObj.toText()),
          value: new window.rrule.RRule(rruleOptions).toString()
        }
        repeatOptions.push(newRepeatOptions)
        LocalStorageService.set('repeatOptions', repeatOptions);
        repeatOptions = LocalStorageService.get('repeatOptions');
        repeatType = newRepeatOptions
      }
    }
    
    // If a start/end time is provided, override the default values with those
    // from this existing timeblock.
    if (timeblock.start !== '') {
      if(timeblock.rrule !== '') {
        startDate = moment(timeblock.originalStart).startOf('day').format(momentDateFormat);
      } else {
        startDate = moment(timeblock.start).startOf('day').format(momentDateFormat);
      }
      startTime = moment(timeblock.start).toDate();
      duration = timeblock.duration;
    }

    return {
      repeatOptions: repeatOptions,
      repeatType: repeatType,
      startDate: startDate,
      startTime: startTime,
      duration: duration
    }
   }

   function getAndAddDuration(duration, defaultDurations) {
    if(duration === '') {
      return 30
    }
    var durationValue = defaultDurations.find((durationItem) => durationItem.value === duration);
    if(durationValue === undefined) {
      var dur =  moment.duration(duration, 'minutes')
      var hours = duration < 60 ? "" : dur.hours() + " hours";
      var minutes = dur.minutes() + " minutes";
      var label = hours + " " + minutes;
      defaultDurations.push({
        value: duration,
        label: label
      })
    }
    return duration;
  }

    function getAppointmentDefaults(serverValue) {
      var mappedValues = serverValue.reduce((result, item) => {
        result[item.appointment_type] = item.appointment_type_length;
        return result;
      }, {});

      return [
        {
          label: "New Patient Visit",
          value: "npv",
          defaultDuration: mappedValues.npv,
        },
        {
          label: "Evaluation",
          value: "ev",
          defaultDuration: mappedValues.ev
        },
        {
          label: "Root Canal",
          value: "rc",
          defaultDuration: mappedValues.rc
        },
        {
          label: "Obturation",
          value: "ob",
          defaultDuration: mappedValues.ob
        },
        {
          label: "Remedicate",
          value: "re",
          defaultDuration: mappedValues.re
        },
        {
          label: "Re-Treatment",
          value: "rt",
          defaultDuration: mappedValues.rt
        },
        {
          label: "Apicoetomy",
          value: "a",
          defaultDuration: mappedValues.a
        },
        {
          label: "Post-Op",
          value: "po",
          defaultDuration: mappedValues.po
        },
      ]
    }

    function getDurationDefaults() {
      return [
        {
          value: "15", 
          label: "15 minutes"
        },
        {
          value: "30", 
          label: "30 minutes",
        },
        { value: "45", label: "45 minutes" },
        {
          value: "60",
          label: "1 hour"
        },
        {
          value: "90",
          label: "1 hour 30 minutes"
        },
        {
          value: "120",
          label: "2 hours"
        }, 
        {
          value: "150",
          label: "2 hours 30 minutes"
        },
        { value: "180", label: "3 hours" },
        {
          value: "210",
          label: "3 hours 30 minutes"
        }, 
        {
          value: "240",
          label: "4 hours"
        }, 
        {
          value: "270",
          label: "4 hours 30 minutes"
        },
        {
          value: "300",
          label: "5 hours"
        },
        {
          value: "600",
          label: "Entire day"
        }
      ];
    }

    service.prepareTimeblock = prepareTimeblock
    service.calculateEndTime = calculateEndTime;
    service.onOperatoryChange = onOperatoryChange;
    service.timeChange = timeChange;
    service.create = create;
    service.update = update;
    service.deleteNotification = deleteNotification;
    service.cancel = cancel;
    service.openCustomRepeatModal = openCustomRepeatModal;
    service.AuthServiceHelper = AuthServiceHelper;
    service.LocServiceHelper = LocServiceHelper;
    service.initVariables = initVariables;
    service.getAndAddDuration = getAndAddDuration;
    service.getAppointmentDefaults = getAppointmentDefaults;
    service.getDurationDefaults = getDurationDefaults;
    return service
  }

  
  
  angular.module('common.services.schedulecommon', [])
    .factory('ScheduleCommonService', scheduleCommonService);
})();

/*global moment */
/*global _ */
(function() {
  'use strict';

function timeblockService($http) {

  // Return a list of timeblock rules that match a provider.
  var filterRawTimeblocksToProvider = function(rawTimeblocks, providerId) {
    providerId = parseInt(providerId);
    return _.filter(rawTimeblocks, function(timeblock){ return timeblock.provider === providerId; });
  };

  // Return a list of timeblock rules that match an operatory.
  var filterRawTimeblocksToOperatory = function(rawTimeblocks, operatoryId) {
    operatoryId = parseInt(operatoryId);
    return _.filter(rawTimeblocks, function(timeblock){ return timeblock.operatory === operatoryId; });
  };

  var getDisplayInfo = function(isBackgroundEditMode, isBackgroundEvent) {
    if(isBackgroundEditMode && isBackgroundEvent) {
      return 'auto'
    } else if(isBackgroundEvent) {
      return 'background'
    } else if(isBackgroundEditMode) {
      return 'none'
    }
  }

  var transformTimeblocks = function(data, colorBy, multicolumnByProvider, isBackgroundEditMode, currentStartTime) {
    function getColor(index, isBackgroundEvent, isBackgroundEditMode) {
      if(isBackgroundEditMode) {
        return 'rgba(183, 183, 183, 0.85)';
      }

      if(isBackgroundEvent) {
        return 'rgba(0, 0, 0, 0.8)';
      }

      if (!index || isNaN(index)) {
        return 'rgba(183, 183, 183, 0.85)';
      }

      /*
      var colors = [
        'rgba(255,0,0, 0.5)',
        'rgba(0, 0, 255, 0.5)',
        'rgba(0, 255,0, 0.5)',
        'rgba(255,164,0, 0.5)',
        'rgba(0, 255, 255, 0.5)',
        'rgba(255,0,255 0.5)'
      ];
      */
      var opacity = '0.4';

      var colors = [
        'rgba(144, 213, 213, ' + opacity + ')',
        'rgba(255, 105, 102, ' + opacity + ')',
        'rgba(162, 194, 101, ' + opacity + ')',
        'rgba(153, 189, 255, ' + opacity + ')',
        'rgba(255, 187, 77, ' + opacity + ')',
        'rgba(198, 168, 244, ' + opacity + ')',
        'rgba(255, 165, 125, ' + opacity + ')',
        'rgba(126, 146, 206, ' + opacity + ')',
        'rgba(213, 144, 144, ' + opacity + ')',
        'rgba(103, 152, 130, ' + opacity + ')',
        'rgba(213, 196, 144, ' + opacity + ')',
        'rgba(152, 103, 140, ' + opacity + ')'
      ];

      index = index % colors.length;
      return colors[index];
    }

    var rawTimeblocks = angular.fromJson(data);
    var timeblocks = [];
    var grouped= _.groupBy(rawTimeblocks, 'provider');

    _.each(grouped, function(group) {
      _.each(group, function(timeblock) {
        var isBackgroundEvent = timeblock.is_background;      
        var start = moment(timeblock.start_datetime).toDate();
        var end = moment(timeblock.start_datetime).clone().add(timeblock.duration, 'minutes').toDate();
        var timeBlockItem = { 
          id: timeblock.id,
          start: start,
          end: end,
          title: timeblock.reason,
          duration: moment.utc(moment.duration(timeblock.duration, 'minutes').as('milliseconds')).format('HH:mm'),
          extendedProps: {
            reason: timeblock.reason,
            duration: timeblock.duration,
            provider: timeblock.provider,
            providerFirstName: timeblock.provider_first_name,
            providerLastName: timeblock.provider_last_name,
            operatory: timeblock.operatory,
            operatoryName: timeblock.operatory_name,
            locationName: timeblock.location_name,
            originalStartDate: start,
            type: isBackgroundEvent ? 'background' : 'timeblock',
          },
          allDay: false,
          display: getDisplayInfo(isBackgroundEditMode, isBackgroundEvent),
          backgroundColor: getColor(timeblock[colorBy], isBackgroundEvent, isBackgroundEditMode),
          textColor: 'rgba(0, 0, 0, 0.8)',
        };
        if(multicolumnByProvider) {
          timeBlockItem.resourceId = timeblock.provider;
        } else {
          timeBlockItem.resourceId = timeblock.operatory;
        }

        if(timeblock.rrule) {
          if(moment(currentStartTime).add(6, 'hours').isDST() !== moment(start).isDST()) {
            var isDST = moment(start).isDST();
            var originalOptions = window.rrule.rrulestr(timeblock.rrule).origOptions
            var newStartTime = isDST ? moment(originalOptions.dtstart).add(1, 'hours').toDate() : moment(originalOptions.dtstart).subtract(1, 'hours').toDate()
            var rruleUpdated = Object.assign(
              {}, 
              originalOptions, 
              {
                dtstart: newStartTime
              })
            timeblock.rrule = new window.rrule.RRule(rruleUpdated).toString();
          }
          timeBlockItem.rrule = timeblock.rrule
          timeBlockItem.extendedProps.rrule = timeblock.rrule
        }

        timeblocks.push(timeBlockItem);
      });
    });
    return timeblocks;
  };

  return {
    filterRawTimeblocksToProvider: filterRawTimeblocksToProvider,
    filterRawTimeblocksToOperatory: filterRawTimeblocksToOperatory,
    transformTimeblocks: transformTimeblocks,
    all: function () {
      var fromDate = new Date('2015-06-05T13:00:00');
      var toDate = new Date('2020-07-01T15:24:00');
      return this.allBetween(fromDate, toDate);
    },
    allBetween: function (fromDate, toDate) {
      var fromSecondsSinceEpoch = fromDate.getTime() / 1000; //convert from millis to seconds
      var toSecondsSinceEpoch = toDate.getTime() / 1000;

      //Service doesn't accept decimals so lets round it

      fromSecondsSinceEpoch = Math.round(fromSecondsSinceEpoch);
      toSecondsSinceEpoch = Math.round(toSecondsSinceEpoch);


      return $http.get('/api/v1/timeblocks/frommsecondssinceepoch/' + fromSecondsSinceEpoch + '/tosecondsfromepoch/' + toSecondsSinceEpoch + '/');
    },
    create: function (timeblock) {
      return $http.post('/api/v1/timeblocks/', timeblock);
    },
    update: function (id, timeblock) {
      return $http.put('/api/v1/timeblocks/' + id + '/', timeblock);
    },
    delete: function (id, timeblock) {
      return $http.delete('/api/v1/timeblocks/' + id + '/', timeblock);
    },
  };
}

  angular.module('common.services.timeblocks', [])
    .factory('TimeblockService', timeblockService);
})();

(function() {
  'use strict';

function locationsService($http) {
  return {
    all: function () {
      return $http.get('/api/v1/locations/');
    },
    get: function (id) {
      return $http.get('/api/v1/locations/' + id + '/');
    },
    create: function (location) {
      return $http.post('/api/v1/locations/', location);
    },
    update: function (id, location) {
      return $http.put('/api/v1/locations/' + id + '/', location);
    },
    delete: function (id, location) {
      return $http.delete('/api/v1/locations/' + id + '/', location);
    },
  };
}

  angular.module('common.services.locations', [])
    .factory('LocationsService', locationsService);
})();

(function() {
  'use strict';

function insuranceService($http) {
  return {
    getPayers: function () {
      return $http.get('/api/v1/claims/payers/');
    },
    getPlansForPractice: function (practiceId) {
      return $http.get('/api/v1/plans/practices/' + practiceId + '/');
    },
    getPlan: function (id) {
      return $http.get('/api/v1/plans/' + id + '/');
    },
    createPlanForPractice: function (practiceId, plan) {
      return $http.post('/api/v1/plans/practices/' + practiceId + '/', plan);
    },
    updatePlan: function (id, plan) {
      return $http.put('/api/v1/plans/' + id + '/', plan);
    },
    deletePlan: function (id, plan) {
      return $http.delete('/api/v1/plans/' + id + '/', plan);
    },
    getMaximumsForPlan: function (planId) {
      return $http.get('/api/v1/maximums/plans/' + planId + '/');
    },
    getMaximum: function (id) {
      return $http.get('/api/v1/maximums/' + id + '/');
    },
    /* Not used
    createMaximumForPlan: function (planId, maximum) {
      return $http.post('/api/v1/maximums/plans/' + planId + '/', maximum);
    },
    */
    updateMaximum: function (id, maximum) {
      return $http.put('/api/v1/maximums/' + id + '/', maximum);
    },
/* not turned on on endpoint view
    deleteMaximum: function (id, maximum) {
      return $http.delete('/api/v1/maximums/' + id + '/', maximum);
    },
*/
    getDeductiblesForPlan: function (planId) {
      return $http.get('/api/v1/deductibles/plans/' + planId + '/');
    },
    getDeductible: function (id) {
      return $http.get('/api/v1/deductibles/' + id + '/');
    },
    /* Not used
    createDeductibleForPlan: function (planId, deductible) {
      return $http.post('/api/v1/deductibles/plans/' + planId + '/', deductible);
    },
    */
    updateDeductible: function (id, deductible) {
      return $http.put('/api/v1/deductibles/' + id + '/', deductible);
    },
/* Not turned on on endpoint view
    deleteDeductible: function (id, deductible) {
      return $http.delete('/api/v1/deductibles/' + id + '/', deductible);
    },
*/
    getPatientMaximums: function (patientId, planId) {
      return $http.get('/api/v1/patientmaximums/patients/' + patientId + '/plans/' + planId + '/');
    },
    getPatientMaximum: function (id) {
      return $http.get('/api/v1/patientmaximums/' + id + '/');
    },
    updatePatientMaximum: function (id, patientMaximum) {
      return $http.put('/api/v1/patientmaximums/' + id + '/', patientMaximum);
    },

    getPatientDeductibles: function (patientId, planId) {
      return $http.get('/api/v1/patientdeductibles/patients/' + patientId + '/plans/' + planId + '/');
    },
    getPatientDeductible: function (id) {
      return $http.get('/api/v1/patientdeductibles/' + id + '/');
    },
    updatePatientDeductible: function (id, patientDeductibles) {
      return $http.put('/api/v1/patientdeductibles/' + id + '/', patientDeductibles);
    }
  };
}

  angular.module('common.services.insurance', [])
    .factory('InsuranceService', insuranceService);
})();

(function() {
  'use strict';

/* jshint undef:false */
function uploadsService($http) {

  return {
/*
    // Retrieve AWS Bucket info
    getStorageData: function(patientId, filetype) {
        return $http.get('/api/v1/patientimages/' + patientId + '/storage/?type=' + filetype);
    },
*/
    //IMAGES
    getCreatePatientImageUrl: function(patientId, encounterId) {
        if (encounterId !== undefined) {
            return '/api/v1/patientimages/patients/' + patientId + '/encounters/' + encounterId + '/';
        } else {
            return '/api/v1/patientimages/patients/' + patientId + '/';
        }
    },

    createPatientImage: function(patientId, encounterId, data) {
        var url = this.getCreatePatientImageUrl(patientId, encounterId);
        return $http.post(url, data);
    },

    allPatientImages: function (patientId, encounterId) {
      if (!encounterId) {
        return $http.get('api/v1/patientimages/patients/' + patientId + '/');
      } else {
        return $http.get('api/v1/patientimages/patients/' + patientId + '/encounters/' + encounterId + '/');
      }
    },

    deletePatientImage: function(patientImageId) {
        return $http.delete('api/v1/patientimages/' + patientImageId + '/');
    },
    getPatientImage: function (patientImageId) {
        return $http.get('api/v1/patientimages/' + patientImageId + '/');
    },
    updatePatientImage: function (patientImageId, data) {
        return $http.put('api/v1/patientimages/' + patientImageId + '/', data);
    },
    
    //FILES

    createPatientFile: function(patientId, data) {
        return $http.post('/api/v1/patientfiles/patients/' + patientId + '/?format=json', data);
    },

    allPatientFiles: function (patientId, type) {
        if (type) {
            return $http.get('api/v1/patientfiles/patients/' + patientId + '/filetype/' + type + '/');
        } else {
            return $http.get('api/v1/patientfiles/patients/' + patientId + '/');
        }
    },
    deletePatientFile: function(fileId) {
        return $http.delete('api/v1/patientfiles/' + fileId + '/');
    },
    getPatientFile: function (fileId) {
        return $http.get('api/v1/patientfiles/' + fileId + '/');
    },
    updatePatientFile: function (fileId, data) {
        return $http.put('api/v1/patientfiles/' + fileId + '/', data);
    }
  };
}

  angular.module('common.services.uploads', [])
    .factory('UploadsService', uploadsService);
})();

(function() {
  'use strict';


function encounterPrescriptionsService($http) {
  var prescriptions = [];

  return {
    // TODO(bradleybossard) : Added logic to clear prescription (once they have been submitted).
    all: function() {
      return prescriptions;
    },
    add: function (prescription) {
      prescriptions.push(prescription);
    },
    getDoseSpotPatientUrl: function(encounterId) {
        return $http.get('/api/v1/prescriptions/encounters/' + encounterId + '/dosespot/patient/');
    },
    getDoseSpotPatientRefillCounts: function(encounterId) {
        return $http.get('/api/v1/prescriptions/encounters/' + encounterId + '/dosespot/patient/refill/');
    },
    getIsDoseSpotRegistered: function(encounterId) {
        return $http.get('/api/v1/prescriptions/encounters/' + encounterId + '/dosespot/registered/');
    }
  };
}

angular.module('common.services.encounter-prescriptions', [])
  .factory('EncounterPrescriptionsService', encounterPrescriptionsService);
})();

(function() {
  'use strict';

function encounterTreatmentsService() {
  // TODO(bradleybossard) : Added logic to clear treatments (once they have been submitted).
  var treatments = [];

  return {
    all: function() {
      return treatments;
    },
    add: function (treatment) {
      treatments.push(treatment);
    }
  };
}

angular.module('common.services.encounter-treatments', [])
    .factory('EncounterTreatmentsService', encounterTreatmentsService);
})();

(function() {
  'use strict';

function treatmentsService($http) {
  return {
    allForPatient: function (patientId) {
      return $http.get('/api/v1/treatments/patients/' + patientId + '/');
    },
    allForStatus: function (patientId, status) { //notstarted, doing, done, wontdo
      return $http.get('/api/v1/treatments/patients/' + patientId + '/status/' + status + '/');
    },
    /*
        if false: returns treatments for patient that haven't been assigned to any
            ar_journal_entry_details and are status doing or done.
        if true: returns treatments for patient that have been assigned to at least
            one ar_journal_entry_details
    */
    allIsAr: function (patientId, isAr, treatmentsToOnlyInclude) { //true, false
      if (treatmentsToOnlyInclude) {
        return $http.get('/api/v1/treatments/patients/' + patientId + '/isar/' + isAr + '/', {
            params: {
                treatmentid: treatmentsToOnlyInclude
            }
        });
      } else {
        return $http.get('/api/v1/treatments/patients/' + patientId + '/isar/' + isAr + '/');
      }
    },
    allForEncounter: function (encounterId) {
      return $http.get('/api/v1/treatments/encounters/' + encounterId + '/');
    },
    create: function (encounterId, treatment) {
      return $http.post('/api/v1/treatments/encounters/' + encounterId + '/', treatment);
    },
    get: function (treatmentId) {
      return $http.get('/api/v1/treatments/' + treatmentId + '/');
    },
    delete: function(treatmentId) {
        return $http.delete('api/v1/treatments/' + treatmentId + '/');
    },
    update: function (treatmentId, treatment) {
        return $http.put('api/v1/treatments/' + treatmentId + '/', treatment);
    }
  };
}

  angular.module('common.services.treatments', [])
    .factory('TreatmentsService', treatmentsService);
})();

(function() {
  'use strict';

function proceduresService($http) {
  return {

    /**anonymous: {
      getFeeprofile: function (id,token) {
        return $http.get('/api/v1/feeprofiles/' + id + '/', addSignedTokenHeader(token));
      },
    },*/

    getProcedures: function (practiceId) {
      return $http.get('/api/v1/procedures/practices/' + practiceId + '/');
    },
    getActiveProcedures: function(practiceId) {
      return $http.get('/api/v1/procedures/practices/' + practiceId + '/active/');
    },
    getProcedure: function (procedureId) {
      return $http.get('/api/v1/procedures/' + procedureId + '/');
    },
    updateProcedure: function (procedureId, procedure) {
        return $http.put('/api/v1/procedures/' + procedureId + '/', procedure);
    },
    getAllFeeprofiles: function (practiceId) {
      return $http.get('/api/v1/feeprofiles/practices/' + practiceId + '/');
    },
    createFeeprofile: function (practiceId, feeprofile, fpIdToCopy) {
      if (fpIdToCopy) {
        return $http.post('/api/v1/feeprofiles/practices/' + practiceId + '/feeprofilefeestocopy/' + fpIdToCopy + '/', feeprofile);
      } else {
        return $http.post('/api/v1/feeprofiles/practices/' + practiceId + '/', feeprofile);
      }
    },
    getFeeprofile: function (feeprofileId) {
      return $http.get('/api/v1/feeprofiles/' + feeprofileId + '/');
    },
    updateFeeprofile: function (feeprofileId, feeprofile) {
        return $http.put('/api/v1/feeprofiles/' + feeprofileId + '/', feeprofile);
    },
    deleteFeeprofile: function (feeprofileId) {
        return $http.delete('/api/v1/feeprofiles/' + feeprofileId + '/');
	  },
    getFeesForFeeprofile: function (feeprofileId) {
      return $http.get('/api/v1/fees/feeprofiles/' + feeprofileId + '/');
    },
    getFeesForPatient: function (patientId) {
      return $http.get('/api/v1/fees/patients/' + patientId + '/');
    },
    createFee: function (fee) {
      return $http.post('/api/v1/fees/feeprofiles/', fee);
    },
    getFee: function (feeId) {
      return $http.get('/api/v1/fees/' + feeId + '/');
    },
    updateFee: function (feeId, fee) {
        return $http.put('/api/v1/fees/' + feeId + '/', fee);
    }
  };
}

  angular.module('common.services.procedures', [])
    .factory('ProceduresService', proceduresService);
})();

(function() {
  'use strict';

function templatesService($http) {
  return {
    all: function () {
      return $http.get('/api/v1/templates/practices/');
    },
    allForPractice: function (practiceId) {
      return $http.get('/api/v1/templates/practices/' + practiceId + '/');
    },
    allForProvider: function (providerId) {
      return $http.get('/api/v1/templates/providers/' + providerId + '/');
    },
    createForProvider: function (providerId, template) {
      return $http.post('/api/v1/templates/providers/' + providerId + '/', template);
    },
    get: function (templateId) {
      return $http.get('/api/v1/templates/' + templateId + '/');
    },
    update: function (templateId, template) {
        return $http.put('api/v1/templates/' + templateId + '/', template);
    },
    delete: function (templateId) {
        return $http.delete('api/v1/templates/' + templateId + '/');
    },
    //addTemplateToCaller: function (templateId) { // adds to invoking provider
    //  return $http.get('api/v1/templates/' + templateId + '/add/');
    //},
    //removeTemplateFromCaller: function (templateId) { // removes from invoking provider
    //  return $http.get('api/v1/templates/' + templateId + '/remove/');
    //},
    //voteForTemplate: function (templateId, votes) { // adds to invoking provider
    //  return $http.get('api/v1/templates/' + templateId + '/votes/' + votes + '/');
    //}
  };
}

  angular.module('common.services.templates', [])
    .factory('TemplatesService', templatesService);
})();
(function() {
  'use strict';

/* jshint undef:false*/
function prescriptionsService($http) {
  return {
    allForPatient: function (patientId) {
      return $http.get('/api/v1/prescriptions/patients/' + patientId + '/');
    },
    allForEncounter: function (encounterId) {
      return $http.get('/api/v1/prescriptions/encounters/' + encounterId + '/');
    },
    create: function (encounterId, prescription) {
      /*jshint camelcase: false */
      return $http.post('/api/v1/prescriptions/encounters/' + encounterId + '/create/', prescription);
    },
    get: function (prescriptionId) {
      return $http.get('/api/v1/prescriptions/' + prescriptionId + '/');
    },
    update: function (prescriptionId, prescription) {
      return $http.put('/api/v1/prescriptions/' + prescriptionId + '/', prescription);
    },
    delete: function(prescriptionId) {
        return $http.delete('/api/v1/prescriptions/' + prescriptionId + '/');
    },
    getPrintPrescriptionUrl: function (prescriptionId) {
        return '/api/v1/prescriptions/' + prescriptionId + '/print/';
    },
    getAllDrugTemplates: function(){
      return $http.get('/api/v1/prescriptions/templates/');
    },
    createTemplate: function(template){
      return $http.post('/api/v1/prescriptions/templates/',template);
    },
    updateTemplate: function(templateId,template){
      return $http.put('/api/v1/prescriptions/template/'+templateId+ '/',template);
    },
    deleteTemplate: function(templateId,template){
      return $http.delete('/api/v1/prescriptions/template/'+templateId+ '/',template);
    }
  };
}

  angular.module('common.services.prescriptions', [])
    .factory('PrescriptionsService', prescriptionsService);
})();

(function() {
  'use strict';

function passwordService($http) {
  var restUrl = '/api/v1/auth/password';

  return {
    reset: function (email) {
      return $http.post(restUrl + '/reset/', {
		  email: email
	  });
    },
    invite: function (email) {
      return $http.post(restUrl + '/invite/', {
		  email: email
	  });
    },
    confirm: function (data) {
      return $http.post(restUrl + '/reset/confirm/', {
        uid: data.uid,
        token: data.token,
        new_password: data.password,
        re_new_password: data.confirmPassword
      });
    },
    change: function (data) {
      return $http.post(restUrl + '/change/', {
        new_password: data.password,
        re_new_password: data.confirmPassword,
        current_password: data.currentPassword
      });
    }
  };
}

  angular.module('common.services.password', [])
    .factory('PasswordService', passwordService);
})();

(function () {
  'use strict';

  function twoFactorService($http, $q, $filter) {
    // FIXME: remove unnecessary code in this service
    var apiUrl = '/api/v1/two-factor/';
    var appsApiUrl = apiUrl + 'apps/';
    var phonesApiUrl = apiUrl + 'phones/';
    var staticsApiUrl = apiUrl + 'statics/';

    function getVerificationTokenHeader(verificationToken) {
      return {
        headers: {
          'Verification-Token': verificationToken
        }
      };
    }

    function getAllApps(verificationToken) {
      var options = getVerificationTokenHeader(verificationToken);
      return $http.get(appsApiUrl, options);
    }

    function getAllPhones(confirmed, verificationToken) {
      var options = getVerificationTokenHeader(verificationToken);
      if (confirmed !== undefined) {
        options.params = {
          confirmed: confirmed ? 'True' : 'False'
        };
      }

      return $http.get(phonesApiUrl, options);
    }

    return {
      devices: {
        all: function (options) {
          options = options || {};
          var appsPromise = getAllApps(options.verificationToken);
          var phonesPromise = getAllPhones(options.confirmed, options.verificationToken);
          var deferred = $q.defer();

          $q.all([appsPromise, phonesPromise]).then(function (responses) {
            var devices = {
              apps: [],
              phones: [],
              primary: {
                isApp: false,
                isPhone: false
              }
            };

            if (responses[0].data.length) {
              devices.apps = responses[0].data;
            }
            if (responses[1].data.length) {
              devices.phones = responses[1].data;
            }

            var index, device;
            for (index = 0; index < devices.apps.length; index++) {
              device = devices.apps[index];

              if (device.name === 'default') {
                devices.primary.device = device;
                devices.primary.text = 'Token generator application';
                devices.primary.isApp = true;
                break;
              }
            }

            if (!devices.primary.device) {
              for (index = 0; index < devices.phones.length; index++) {
                device = devices.phones[index];

                if (device.name === 'default') {
                  devices.primary.device = device;
                  devices.phones.splice(index, 1);
                  devices.primary.text = device.method_display + ' ' +
                    $filter('hidePhone')(devices.primary.device.number);
                  devices.primary.isPhone = true;
                  break;
                }
              }
            }

            deferred.resolve(devices);
          }, function (errors) {
            deferred.reject(errors);
          });

          return deferred.promise;
        }
      },
      statics: {
        all: function (verificationToken) {
          var options = getVerificationTokenHeader(verificationToken);
          return $http.get(staticsApiUrl, options);
        },
        create: function () {
          return $http.post(staticsApiUrl, {});
        }
      },
      apps: {
        get: function (id) {
          return $http.get(appsApiUrl + id);
        },
        all: getAllApps,
        create: function (device) {
          return $http.post(appsApiUrl, device);
        },
        update: function (id, device) {
          return $http.put(appsApiUrl + id + '/', device);
        },
        delete: function (id) {
          return $http.delete(appsApiUrl + id + '/');
        }
      },
      phones: {
        get: function (id) {
          return $http.get(phonesApiUrl + id);
        },
        all: getAllPhones,
        create: function (device) {
          return $http.post(phonesApiUrl, device);
        },
        update: function (id, device) {
          return $http.put(phonesApiUrl + id + '/', device);
        },
        delete: function (id) {
          return $http.delete(phonesApiUrl + id);
        },
        verify: function (id, device) {
          return $http.post(phonesApiUrl + id + '/verify/', device);
        },
        challenge: function (id, verificationToken) {
          var options = getVerificationTokenHeader(verificationToken);
          return $http.get(phonesApiUrl + id + '/challenge/', options);
        }
      },
      disable: function () {
        return $http.post(apiUrl + 'disable/', {});
      },
      verify: function (data, verificationToken) {
        var options = getVerificationTokenHeader(verificationToken);
        return $http.post(apiUrl + 'verify/', data, options);
      }
    };
  }

  angular.module('common.services.two-factor', [])
    .factory('TwoFactorService', twoFactorService);
})();

(function() {
  'use strict';

  //save temporal data in the app
  //We can change to use localStorage or sessionStorage if needed in the future
  function sessionStorageService() {
    return {
      multiColumnProviderLastDropdownSelection: [],
      multiColumnLocationLastDropdownSelection: [],
      calendarViewType: "multicolumn",
      calendarIntervalUnit: "agendaDay",
      calendarCurrentDate: moment(),
      colorBy: "appointment_type",
      multicolumnByProvider: false,
      lastURLState: "/home",
      toURLState: undefined
    };
  }

  angular.module('common.services.storage', [])
    .factory('SessionStorageService', sessionStorageService);
})();

(function () {
  'use strict';

  function LocalStorageService() {
    var defaults = {
      repeatOptions: [
        { label: 'Does not repeat', value: 0 },
        { label: 'Every day', value: 'RRULE:FREQ=DAILY;INTERVAL=1' },
        { label: 'Custom', value: 1 }
      ],
      repeatEvents: [],
      appointmentDefaults: [
        {
          label: "New Patient Visit",
          value: "npv",
          defaultDuration: 30,
        },
        {
          label: "Evaluation",
          value: "ev",
          defaultDuration: 30,
        },
        {
          label: "Root Canal",
          value: "rc",
          defaultDuration: 30,
        },
        {
          label: "Obturation",
          value: "ob",
          defaultDuration: 30,
        },
        {
          label: "Remedicate",
          value: "re",
          defaultDuration: 30,
        },
        {
          label: "Re-Treatment",
          value: "rt",
          defaultDuration: 30,
        },
        {
          label: "Apicoetomy",
          value: "a",
          defaultDuration: 30,
        },
        {
          label: "Post-Op",
          value: "po",
          defaultDuration: 30,
        },
      ],
      orderMap: {}
    };
    var storageKey = 'myLocalStorageService';
    if(!localStorage.getItem('hasReset')) {
      localStorage.removeItem(storageKey);
      localStorage.setItem('hasReset', true)
    }

    // Get the storage object, or initialize with defaults if it does not exist
    var storageObj = JSON.parse(localStorage.getItem(storageKey)) || Object.assign({}, defaults);

    // Save the storage object to localStorage if it was just initialized with defaults
    if (localStorage.getItem(storageKey) === null) {
      localStorage.setItem(storageKey, JSON.stringify(storageObj));
    }

    // Get a value for a key
    function get(key) {
      return storageObj[key];
    }

    // Set a value for a key
    function set(key, value) {
      storageObj[key] = value;
      localStorage.setItem(storageKey, JSON.stringify(storageObj));
    }

    // Remove a key from storage
    function remove(key) {
      delete storageObj[key];
      localStorage.setItem(storageKey, JSON.stringify(storageObj));
    }

    return {
      get: get,
      set: set,
      remove: remove,
    };
  }

  angular.module('common.services.local-storage', [])
    .factory('LocalStorageService', LocalStorageService);
})();

(function() {
  'use strict';

  angular.module('common.services.notification', [])
    .service('NotificationService', NotificationService);

  function NotificationService(
    $compile,
    $log,
    $q,
    $rootScope,
    $timeout,
    toastr,
    toastrConfig
  ) {

    var ORDERED_KEYS = {
      type: 0,
      detail: 10,
      protected_objects: 50,
    };

    var INVALID_KEYS = {
      text: true,
    };

    this.clear = function() {
      toastr.clear();
    };

    this.success = function(title, message, options) {
      angular.extend(toastrConfig, {
        positionClass: options && options.positionClass || 'toast-top-right',
        target: options && options.target || 'body',
        preventDuplicates: options && options.preventDuplicates || false
      });
      toastr.success(getMessage(message), getTitle(title), options);
    };

    this.warning = function(title, message, options) {
      angular.extend(toastrConfig, {
        positionClass: options && options.positionClass || 'toast-top-right',
        target: options && options.target || 'body',
        preventDuplicates: options && options.preventDuplicates || false
      });
      toastr.warning(getMessage(message), getTitle(title), options);
    };

    this.error = function(title, message, options) {
      angular.extend(toastrConfig, {
        positionClass: options && options.positionClass || 'toast-top-right',
        target: options && options.target || 'body',
        preventDuplicates: options && options.preventDuplicates || false
      });
      toastr.error(getMessage(message), getTitle(title), options);
    };

    this.parse = function(message) {
      return getMessage(message)
    }

    function getTitle(title) {
      if (title && !title.startsWith('<')) {
        return '<h5>' + title + '</h5>';
      }
    }

    function getMessage(message) {
      if (!angular.isObject(message)) {
        return message;
      }
      else if (message.status === 403) {
        if (message.data && message.data.message) {
          return message.data.message;
        }
        return 'Insufficient permissions';
      }
      else if (message.status === 500) {
        return message.statusText;
      }
      else if (message.status === 502) {
        return 'Bad gateway. Please try again at a later time';
      }
      else if (message.status === 504) {
        return 'Gateway timeout. Please try again at a later time';
      }
      else {
        var data = message.data || message.detail || message.message;
        var html = [];
        if (angular.isArray(data)) {
          html.push('<ul class="list-unstyled">');
          angular.forEach(data, function(item){
            html.push('<li>' + item + '</li>');
          });
          html.push('</ul>');
          return html.join('');
        }
        else if (angular.isObject(data)) {
          html.push('<dl style="font-size: 14px;">');
          // if (data.hasOwnProperty("errors")) {
          //   data = data.errors
          // }
          Object.keys(data)
            .filter(function(key) { return !INVALID_KEYS.hasOwnProperty(key); })
            .sort(function(key1, key2) {
              return (
                (ORDERED_KEYS.hasOwnProperty(key1) ? ORDERED_KEYS[key1] : 90) -
                (ORDERED_KEYS.hasOwnProperty(key2) ? ORDERED_KEYS[key2] : 90)
              );
            })
            .forEach(function(key) {
              //object as an unknown format let's print its contents
              if (angular.isArray(data[key])) {
                html.push('<dt>' + key + '</dt><dd>'+ data[key].join(",") +'</dd>');
              }
              else if (angular.isObject(data[key])) {
                html.push('<dt>' + key + '</dt><dd>'+ JSON.stringify(data[key]) +'</dd>');
              }
              else {
                html.push('<dt>' + key + '</dt><dd>'+ data[key] +'</dd>');
              }
            });
          html.push('</dl>');
          return html.join('');
        }
        return data;
      }
    }

  }

})();

/*global _ */
(function() {
  'use strict';

function medicalAlertsService($http) {
  return {

    get: function () {
      return $http.get('/api/v1/medicalalerts/');
    },

    update: function (data) {
      return $http.put('/api/v1/medicalalerts/',data);
    },

    getAlertsByPatient: function (patientId) {
      return $http.get('/api/v1/medicalalerts/patient/' + patientId + '/');
    },

    translateMedicalAlerts: function (alertKeys) {
      var allergies = [], taking = [], medical = [],
        medicalConditionsKeys = {
          is_rheumtic_fever:'Rheumatic fever',
          is_high_blood_pressure:'High blood pressure',
          is_low_blood_pressure:'Low blood pressure',
          is_mitral_valve_prolapse:'Mitral valve prolapse',
          is_heart_murmur:'Heart mumur',
          is_chest_pain_angina:'Chest pain/angina',
          is_heart_attacks:'Heart attack(s)',
          is_irregular_heart_beat:'Irregular heart beat',
          is_cardiac_pacemaker:'Cardiac pacemaker',
          is_heart_surgery:'Heart surgery',
          is_damaged_heart_valves:'Damaged heart valves',
          is_pneumonia:'"Pneumonia, Bronchitis or Chronic Cough"',
          is_chronic_fatigue:'Chronic fatigue / night sweats',
          is_trouble_climbing_stairs:'Trouble climbing 1-2 flights of stairs',
          is_anemia:'Anemia',
          is_asthma:'Asthma',
          is_bleeding_tendency:'Bleeding Tendency',
          is_blood_transfusion:'Blood Transfusion',
          is_blood_disorder:'Blood Disorder',
          is_bruise_easily:'Bruise easily',
          is_eye_disease:'Eye disease / glaucoma',
          is_liver_disease:'Jaundice / Liver disease',
          is_hepatitis:'Hepatitis',
          is_gallbladder_trouble:'Gallbladder trouble',
          is_fainting_spells:'Fainting spells',
          is_convulsions_epilepsy:'Epilepsy or Seizures',
          is_stroke:'Stroke',
          is_thyroid_trouble:'Thyroid trouble',
          is_diabetes:'Diabetes',
          is_low_blood_sugar:'Low blood sugar',
          is_on_dialysis:'Dialysis',
          is_kidney_trouble:'Kidney trouble',
          is_mental_health_problems:'Mental health problems',
          is_immune_system_problems:'Problems with immune system (possibly from med. / surg.)',
          is_delay_in_healing:'Delay in healing',
          is_sinus_problems:'Hay fever / Sinus problems',
          is_snoring:'Snoring',
          is_sleep_apnea:'Sleep Apnea / CPAP',
          is_respiratory_problems:'Respiratory problems',
          is_tuberculosis:'Tuberculosis',
          is_emphysema:'Emphysema',
          is_smoker:'Smoker',
          is_using_chewing_tobacco:'Chewing tobacco',
          is_has_drug_abuse_history:'History of drug abuse',
          is_has_alcohol_abuse_history:'history of alcohol abuse',
          is_abnormal_bleeding:'Abnormal bleeding',
          is_sexually_transmitted_disease:'Sexually transmitted disease',
          is_contagious_diseases:'Contagious diseases',
          is_infectious_mononucleosis:'Infectious mononucleosis',
          is_swollen_ankles:'Swollen Ankles',
          is_arthritis:'Arthritis / Joint disease',
          is_prosthetic_implant:'Prosthetic implant',
          is_joint_replacement:'Joint replacement',
          is_osteoporosis:'Osteoporosis / osteopenia',
          is_osteonecrosis:'Osteonecrosis',
          is_stomach_ulcers:'Stomach ulcers',
          is_tumor_growth:'Tumor or growth',
          is_cancer:'Cancer / Radiation / Chemotherapy',
          is_on_diet:'On diet',
          is_pregnant:'Pregnancy',
          is_hiv: 'HIV',
          is_aids: 'AIDS'
        },takingConditionsKeys = {
          is_taking_nerve_pills:'Nerve pills',
          is_taking_diet_pills:'Diet Pills',
          is_taking_pain_killers:'Pain killers',
          is_taking_tranquilizers:'Tranquilizers',
          is_taking_muscle_relaxers:'Muscle relaxers',
          is_taking_insulin:'Insulin',
          is_taking_stimulants:'Stimulants',
          is_taking_antidepressants:'Antidepressants',
          is_taking_blood_thinners:'"Blood thinners (Coumadin, Aspirin)"',
          is_taking_bone_density_meds:'Density meds. or bisphosphonates',
          is_taking_antibiotic:'Antibiotic',
          is_taking_valium:'"Sodium pentothal, Valium, or other tranquilizers"'
        },allergiesContidionsKeys = {
          is_allergic_to_soy:'Soy',
          is_allergic_to_sulfa_drugs:'Sulfa Drugs',
          is_allergic_to_aspirin:'Aspirin',
          is_allergic_to_eggs:'Eggs/Yolk',
          is_allergic_to_local_anesthetic:'Local anesthetic (numbing medication)',
          is_allergic_to_codeine:'Codeine or other narcotics',
          is_allergic_to_sulfites:'Sulfites',
          is_allergic_to_amoxicillin:'Amoxicillin',
          is_allergic_to_latex:'Latex'
        };

      _.forEach(alertKeys,function(alertKey){
        if(medicalConditionsKeys[alertKey]){
          medical.push(medicalConditionsKeys[alertKey]);
        }
        if(takingConditionsKeys[alertKey]){
          taking.push(takingConditionsKeys[alertKey]);
        }
        if(allergiesContidionsKeys[alertKey]){
          allergies.push(allergiesContidionsKeys[alertKey]);
        }
      });

      var alertsString = '';
      if(medical.length > 0){
        alertsString = alertsString + '<h3>Medical Conditions</h3>' + medical.join(', ');
      }
      if(taking.length > 0){
        alertsString = alertsString + '<h3>Is Taking</h3>' + taking.join(', ');
      }
      if(allergies.length > 0){
        alertsString = alertsString + '<h3>Allergies</h3>' + allergies.join(', ');
      }

      return alertsString;

    }
  };
}

  angular.module('common.services.medical-alerts', [])
    .factory('MedicalAlertsService', medicalAlertsService);
})();

(function() {
  'use strict';

  function textTemplateService($http) {
    return {

      getDefaultTextTemplates: function () {
        return $http.get('/api/v1/texttemplates/');
      },

      getTextTemplateForPractice: function (defaultTemplateType) {
        return $http.get('/api/v1/texttemplates/'+defaultTemplateType+'/');
      },

      updateTextTemplate: function (template) {
        return $http.put('/api/v1/texttemplate/' + template.id +'/',template);
      }

    };
  }

  angular.module('common.services.text-templates', [])
    .factory('TextTemplateService', textTemplateService);
})();

(function() {
  'use strict';


  var API_VERSION = '/api/v1/';

  function patientInteractionsService($http) {
    return {
      all: function (patientId) {
        return $http.get(API_VERSION+'patientevents/patients/'+patientId+'/');
      },
      allAlerts: function (patientId) {
        return $http.get(API_VERSION+'patientevents/patients/'+patientId+'/alerts/');
      },
      create: function(patientId,data) {
        return $http.post(API_VERSION+'patientevents/patients/'+patientId+'/',data);
      },
      update: function(patientEventId,data){
        return $http.put(API_VERSION+'patientevents/'+patientEventId+'/',data);
      },
      delete: function(patientEventId){
        return $http.delete(API_VERSION+'patientevents/'+patientEventId+'/');
      }
    };
  }

  angular.module('common.services.patient-interaction-service', [])
    .factory('PatientInteractionsService', patientInteractionsService);
})();

(function() {
  'use strict';

  function textFormattersService() {
    return {
      phoneFormat: function(phoneText) {
        var phoneFormatter = /^(\d{3})?(\d{3})(\d{4})$/;
        var phoneFormatted = phoneFormatter.exec(phoneText);
        if (phoneFormatted) {
          phoneFormatted.shift();
          return phoneFormatted.join('-');
        } else {
          return (phoneText !== null) ? phoneText.trim() : '';
        }
      }
    };
  }

  angular.module('common.services.text-formatters', [])
    .factory('TextFormattersService', textFormattersService);
})();

(function() {
  'use strict';

function domainService($location) {
  return {
    getSubdomain: function () {
      var host = $location.host();
      if (host.indexOf('.') < 0){ 
          return host;
      }else{
          return host.split('.')[0];
    }
  }
  };
}

  angular.module('common.services.domain', [])
    .factory('DomainService', domainService);
})();
(function() {
  'use strict';

  function PracticeService(
    $http,
    PrincipalService,
    Upload
  ) {

    var apiUrl = '/api/v2/~authentication/practices/';


    this.system_events = function(params) {
      var practiceId = PrincipalService.getPracticeId();
      return $http.get(apiUrl + practiceId + '/system-events/', {
        params: params || {}
      });
    };

    this.system_events_meta = function(params) {
      var practiceId = PrincipalService.getPracticeId();
      return $http.get(apiUrl + practiceId + '/system-events-meta/', {
        params: params || {}
      });
    };

    this.update = function(params) {
      return $http.patch(apiUrl + params.id + '/', params);
    };

    this.upload = function(practice, data) {
      var self = this;
      return $http.post(apiUrl + practice.id + '/upload/', {picture: data.picture.name}).then(
        function(response) {
          var presigned_post = response.data;
          if (angular.isObject(presigned_post.picture)) {
            presigned_post.picture.data.file = data.picture;
            return Upload.upload(presigned_post.picture).then(
              function() {
                return self.update({
                  id: practice.id,
                  picture: presigned_post.picture.name
                });
              },
              function(response) {
                practice.$picture_status = response;
              },
              function(event) {
                practice.$picture_progress = Math.min(
                  100, parseInt(100.0 * event.loaded / event.total)
                );
              }
            );
          }
          else {
          }
        }
      );
    };


  }

  angular.module('common.services.practice-service', [])
    .service('PracticeService', PracticeService);

})();

(function() {
  'use strict';

  function thirdPartyLicensesService($http) {
    return {
      get: function () {
        return $http.get('/static/core/licenses/thirdparty.json');
      }
    };
  }

  angular.module('common.services.thirdpartylicenses', [])
    .factory('ThirdPartyLicensesService', thirdPartyLicensesService);
})();

(function() {
  "use strict"

  angular.module("common.services.sensorcalibrations", [])

  .service("SensorCalibrationService", function($http) {

    this.list = function(params) {
      return $http.get("/api/v2/~authentication/sensor-calibrations/", {params:params})
    }

    this.create = function(params) {
      return $http.post("/api/v2/~authentication/sensor-calibrations/", params)
    }

    this.destroy = function(id) {
      return $http.delete("/api/v2/~authentication/sensor-calibrations/" + id + "/")
    }

  })

})();

(function() {
  'use strict';

/* jshint undef:false*/
function reviewsService($http, PatientsService) {
  return {
    allForPractice: function (practiceId) {
      return $http.get('/api/v1/reviews/practices/' + practiceId + '/');
    },
    get: function (reviewId) {
      return $http.get('/api/v1/reviews/' + reviewId + '/');
    },
    update: function (review) {
      return $http.put('/api/v1/reviews/' + review.id + '/', review);
    },

    requestReview: function (patientId) {
      return $http.post('/api/v1/reviews/patient/requestreview/' + patientId + '/');
    },
    reviewer: {
      get: function (token) {
        return $http.get('/api/v1/reviews/patient/getandcreatereview/', PatientsService.addSignedTokenHeader(token));
      },
      submit: function (token, review) {
        return $http.post('/api/v1/reviews/patient/getandcreatereview/', review, PatientsService.addSignedTokenHeader(token));
      }
    }
  };
}

angular.module('common.services.reviews', [])
  .factory('ReviewsService', reviewsService);
})();

(function() {
  'use strict';

/* jshint undef:false*/
function supportService($http) {
  return {
    ticketUnderConstruction: {
      status: 'new',
      description: ''
    },
    allTicketsForPractice: function (practiceId, dontIncludeResolved) {
      if (dontIncludeResolved) {
        return $http.get('/api/v1/support/tickets/practices/' + practiceId + '/dontincluderesolved/');
      } else {
        return $http.get('/api/v1/support/tickets/practices/' + practiceId + '/');
      }
    },
    createMessageForPracticeTicket: function (ticketId, message) {
      return $http.post('/api/v1/support/messages/practice/tickets/' + ticketId + '/', message);
    },
    getPracticeMessage: function (messageId) {
      return $http.get('/api/v1/support/practice/messages/' + messageId + '/');
    },
    allTicketsForUser: function (dontIncludeResolved) {
      if (dontIncludeResolved) {
        return $http.get('/api/v1/support/tickets/users/self/dontincluderesolved/');
      } else {
        return $http.get('/api/v1/support/tickets/users/self/');
      }
    },
    createTicketForUser: function (ticket) {
      return $http.post('/api/v1/support/tickets/users/self/', ticket);
    },
    createMessageForTicket: function (ticketId, message) {
      return $http.post('/api/v1/support/messages/tickets/' + ticketId + '/', message);
    },
    getMessage: function (messageId) {
      return $http.get('/api/v1/support/messages/' + messageId + '/');
    },
    updateMessage: function (messageId, message) {
        return $http.put('/api/v1/support/messages/' + messageId + '/', message);
    },
    allUnreadMessagesForUser: function () {
        return $http.get('/api/v1/support/messages/users/self/unread/');
    },
  };
}

angular.module('common.services.support', [])
  .factory('SupportService', supportService);
})();

(function() {
  "use strict";

  angular.module("common.services.pdfmake", [])
    .service("PDFMakeService", PDFMakeService);


  function PDFMakeService($filter, $q, $window) {

    this.getInstanceForReport = function() {
      return new ReportPDF()
    }

    function ReportPDF() {
      this.docDefinition = {
        content: [],
        defaultStyle: {
          fontSize: 10,
        },
        info: {
          title: "",
          author: "DentalEMR Inc",
          subject: "",
          keywords: "referral report",
          creator: "DentalEMR Inc",
          producer: "DentalEMR Inc",
        },
        pageOrientation: "portrait",
        pageSize: "A4",
        styles: {
          a_center: { alignment: "center" },
          a_just: { alignment: "justify" },
          a_right: { alignment: "right" },
          bold: { bold: true },
        }
      }
      this.pdfGenerator = null
      this.placeholderImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
    }

    Object.defineProperties(ReportPDF.prototype, {
      author: {
        configurable: true,
        enumerable: true,
        get: function() { return this.docDefinition.info.author },
        set: function (value) { this.docDefinition.info.author = value }
      },
      pageOrientation: {
        configurable: true,
        enumerable: true,
        get: function() { return this.docDefinition.pageOrientation },
        set: function (value) { this.docDefinition.pageOrientation = value }
      },
      pageSize: {
        configurable: true,
        enumerable: true,
        get: function() { return this.docDefinition.pageSize },
        set: function (value) { this.docDefinition.pageSize = value }
      },
      subject: {
        configurable: true,
        enumerable: true,
        get: function() { return this.docDefinition.info.subject },
        set: function (value) { this.docDefinition.info.subject = value }
      },
      styles: {
        configurable: true,
        enumerable: true,
        get: function() { return this.docDefinition.styles },
        set: function (value) { this.docDefinition.styles = value }
      },
      title: {
        configurable: true,
        enumerable: true,
        get: function() { return this.docDefinition.info.title },
        set: function (value) { this.docDefinition.info.title = value }
      },
    })

    ReportPDF.prototype.build = function(report) {
      var deferred = $q.defer()
      var ext_patient = report.snapshot.referral.ext_patient
      this.title = ["ReferralReport for patient", ext_patient.first_name, ext_patient.last_name].join(" ")
      this.report = report
      this.docDefinition.content.length = 0
      var _this = this
      $q.all([
        this.getHeaderContent(),
        // this.getTreatmentsContent(),
        this.getSummaryAndDescriptionContent(),
        this.getImagesContent(),
        this.getCloseMessageContent(),
        this.getSigningProviderContent(),
      ]).then(
        function(contents) {
          for (var i = 0; i < contents.length; i++) {
            if (contents[i]) {
              if (Array.isArray(contents[i])) {
                Array.prototype.push.apply(_this.docDefinition.content, contents[i])
              }
              else {
                _this.docDefinition.content.push(contents[i])
              }
            }
          }
          _this.pdfGenerator = $window.pdfMake.createPdf(_this.docDefinition)
          deferred.resolve()
        }
      )
      return deferred.promise
    }

    ReportPDF.prototype.download = function() {
      this.pdfGenerator.download((this.title + ".pdf").replace(/ /g, "-"))
    }

    ReportPDF.prototype.getCloseMessageContent = function() {
      var close_message = elvis(this.report, "close_message", "")
      if (close_message) {
        return {
          margin: [0, 0, 0, 10],
          text: close_message,
          preserveLeadingSpaces: true,
          preserveTrailingSpaces: true,
        }
      }
    }

    ReportPDF.prototype.getHeaderContent = function() {
      var picture = elvis(this.report, "snapshot.referral.practice.picture", "")
      var mugshot = elvis(this.report, "snapshot.referral.patient.mugshot", "")
      var skel = {
        layout: "noBorders",
        style: {
          fontSize: 11
        },
        table: {
          body: [
            [
              picture ? { image: picture, width: 80 } : { text: "", width: 80 },
              [
                [{text: elvis(this.report, "snapshot.referral.practice.name", ""), style: ["bold"]}],
                [elvis(this.report, "snapshot.referral.practice.location.street1", "")],
                [elvis(this.report, "snapshot.referral.practice.location.street2", "")],
                [
                  elvis(this.report, "snapshot.referral.practice.location.city", "", function (v) { return v + "," }) + " " +
                  elvis(this.report, "snapshot.referral.practice.location.state", "") + " " +
                  elvis(this.report, "snapshot.referral.practice.location.zip_code", "")
                ],
                [elvis(this.report, "snapshot.referral.practice.phone", "", this.toTel)],
              ],
              [
                [{text: "Patient Name", style: ["bold"]}],
                [
                  elvis(this.report, "snapshot.referral.patient.first_name", "") + " " +
                  elvis(this.report, "snapshot.referral.patient.last_name", "")
                ],
                ["DOB: " + elvis(this.report, "snapshot.referral.patient.birth_date", "", this.toDate)],
                mugshot ? { image: mugshot, width: 80 } : { text: "", width: 80 },
              ]
            ],
          ],
          widths: [80, "*", "auto"],
        }
      }
      return skel
    }

    ReportPDF.prototype.getImagesContent = function() {
      var _this = this
      if (this.report.report_images) {
        var deferred = $q.defer()
        $q.mapSettled(this.report.report_images, function(ri) {
          return _this.toBase64(ri.patient_image.image_archive_thumb_url).then(
            function(url) {
              return {
                image: url,
                text: ri.name,
              }
            }
          )
        }).then(function(mapSettled) {
          var images = mapSettled.reduce(function(memo, settled) {
            if (settled.state === "fulfilled") {
              memo.push(settled.value)
            }
            else {
              if (window.Raven.isSetup()) {
                window.Raven.captureException(settled.reason)
              }
              else{
                console.error(settled.reason)
              }
            }
            return memo
          }, [])
          deferred.resolve(
            chunk(images, 3).map(function(row) {
              var skel = {
                layout: "noBorders",
                margin: [0, 0, 0, 10],
                unbreakable: true,
                style: {
                  fontSize: 8
                },
                table: {
                  body: [[], []],
                  widths: ["*", "*", "*"],
                }
              }
              for (var i = 0; i < row.length; i++) {
                skel.table.body[0].push({
                  alignment: ["left", "center", "right"][i],
                  fit: [160, 160],
                  image: row[i].image,
                })
                skel.table.body[1].push({
                  style: "a_center",
                  text: row[i].text,
                })
              }
              return skel
            })
          )
        })
        return deferred.promise
      }
    }

    ReportPDF.prototype.getSigningProviderContent = function() {
      var skel = {
        unbreakable: true,
        style: {
          fontSize: 9
        },
        table: {
          body: [],
          widths: [150],
        },
      }
      var first_name = elvis(this.report, "signing_provider.first_name", "")
      var last_name = elvis(this.report, "signing_provider.last_name", "")
      var signature = elvis(this.report, "signing_provider.signature", "")
      skel.table.body.push([{
        image: signature || this.placeholderImage,
        alignment: "left",
        border: [false, false, false, false],
        fit: signature ? [150, 75] : [100, 50],
      }])
      if (!signature) {
        skel.table.body.push([{
          text: "Signature",
          alignment: "left",
          border: [false, true, false, false],
        }])
      }
      if (first_name || last_name) {
        skel.table.body.push([{
          text: first_name + " " + last_name,
          alignment: "left",
          border: [false, false, false, false],
        }])
      }
      else {
        skel.table.body.push([{
          text: "\n",
          alignment: "left",
          border: [false, false, false, false],
        }])
        skel.table.body.push([{
          text: "Name",
          alignment: "left",
          border: [false, true, false, false],
        }])
      }
      return skel
    }

    ReportPDF.prototype.getSummaryAndDescriptionContent = function() {
      var skel = []
      var sent_at = elvis(this.report, "sent_at", "", this.toDate)
      var summary = elvis(this.report, "summary", "")
      var description = elvis(this.report, "description", "")
      if (sent_at) {
        skel.push({
          text: "Published: " + sent_at,
          margin: [0, 0, 0, 10],
        })
      }
      if (summary) {
        skel.push({
          text: "Re: " + summary,
          bold: true,
          margin: [0, 0, 0, 10],
        })
      }
      if (description) {
        skel.push({
          text: description,
          margin: [0, 0, 0, 10],
          preserveLeadingSpaces: true,
          preserveTrailingSpaces: true,
        })
      }
      return skel
    }

    // ReportPDF.prototype.getTreatmentsContent = function() {
    //   if (this.report.report_treatments && this.report.report_treatments.length) {
    //     var skel = {
    //       layout: {
    //         fillColor: function(i) {
    //           return (i%2 === 0) ? ((i === 0)? '#f0f0f0' : '#ccffff') : null;
    //         }
    //       },
    //       margin: [0, 0, 0, 10],
    //       style: {
    //         fontSize: 9
    //       },
    //       table: {
    //         body: [
    //           [
    //             { style: "bold", text: "Date" },
    //             { style: "bold", text: "Visit" },
    //             { style: "bold", text: "Tooth" },
    //             { style: "bold", text: "Code" },
    //             { style: "bold", text: "Procedure" },
    //           ],
    //         ],
    //         headerRows: 1,
    //         widths: ["auto", "auto", "auto", "auto", "*"],
    //       },
    //     }
    //     var content = this.report.report_treatments
    //     for (var _i = 0, content_1 = content; _i < content_1.length; _i++) {
    //       var rt = content_1[_i]
    //       skel.table.body.push([
    //         elvis(rt, "treatment.treatment_date", "", this.toDate),
    //         elvis(rt, "treatment.recommendation_level", ""),
    //         elvis(rt, "treatment.tooth_numbers", ""),
    //         elvis(rt, "treatment.procedure_code", ""),
    //         elvis(rt, "treatment.procedure_name", ""),
    //       ])
    //     }
    //     return skel
    //   }
    // }

    ReportPDF.prototype.getUrl = function() {
      var deferred = $q.defer()
      this.pdfGenerator.getBlob(function(blob) {
        deferred.resolve(URL.createObjectURL(blob))
      })
      return deferred.promise
    }

    ReportPDF.prototype.toBase64 = function(url) {
      var deferred = $q.defer()
      try {
        var img = new Image()
        img.crossOrigin = "anonymous"
        img.addEventListener("load", function() {
          try {
            var canvas = document.createElement("canvas")
            canvas.width = img.naturalWidth
            canvas.height = img.naturalHeight
            canvas.getContext("2d").drawImage(img, 0, 0)
            deferred.resolve(canvas.toDataURL("image/png"))
          }
          catch (e) {
            deferred.reject(e)
          }
        })
        img.addEventListener("error", function() {
          deferred.reject(new Error(img.src))
        })
        img.src = url
      }
      catch (e) {
        deferred.reject(e)
      }
      return deferred.promise
    }

    ReportPDF.prototype.toDate = function(value) {
      return $filter("date")(value, "MM/dd/yyyy")
    }

    ReportPDF.prototype.toTel = function (value) {
      return value ? $filter("tel")(value) : ""
    }

  }

  function chunk(array, size) {
    return array.reduce(function (memo, item, index) {
      if (index % size === 0) {
        memo.push([])
      }
      memo[memo.length - 1].push(item)
      return memo
    }, [])
  }

  function elvis(obj, path, fallback, transform) {
    var nested = obj
    for (var _i = 0, _a = path.split("."); _i < _a.length; _i++) {
      var prop = _a[_i]
      if (nested && nested.hasOwnProperty(prop)) {
        nested = nested[prop]
      }
      else {
        return fallback
      }
    }
    if (transform) {
      nested = transform(nested)
    }
    return nested !== null ? nested : fallback
  }

})();

/* jshint ignore:start */
/*!
 * Do not edit!. This file is autogenerated by running `npm run browserify`.
 */
(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.channels = f()}})(function(){var define,module,exports;return (function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";
;
;
;
var isWebSocket = function (constructor) {
    return constructor && constructor.CLOSING === 2;
};
var isGlobalWebSocket = function () {
    return typeof WebSocket !== 'undefined' && isWebSocket(WebSocket);
};
var getDefaultOptions = function () { return ({
    constructor: isGlobalWebSocket() ? WebSocket : null,
    maxReconnectionDelay: 10000,
    minReconnectionDelay: 1500,
    reconnectionDelayGrowFactor: 1.3,
    connectionTimeout: 4000,
    maxRetries: Infinity,
    debug: false,
}); };
var bypassProperty = function (src, dst, name) {
    Object.defineProperty(dst, name, {
        get: function () { return src[name]; },
        set: function (value) { src[name] = value; },
        enumerable: true,
        configurable: true,
    });
};
var initReconnectionDelay = function (config) {
    return (config.minReconnectionDelay + Math.random() * config.minReconnectionDelay);
};
var updateReconnectionDelay = function (config, previousDelay) {
    var newDelay = previousDelay * config.reconnectionDelayGrowFactor;
    return (newDelay > config.maxReconnectionDelay)
        ? config.maxReconnectionDelay
        : newDelay;
};
var LEVEL_0_EVENTS = ['onopen', 'onclose', 'onmessage', 'onerror'];
var reassignEventListeners = function (ws, oldWs, listeners) {
    Object.keys(listeners).forEach(function (type) {
        listeners[type].forEach(function (_a) {
            var listener = _a[0], options = _a[1];
            ws.addEventListener(type, listener, options);
        });
    });
    if (oldWs) {
        LEVEL_0_EVENTS.forEach(function (name) {
            ws[name] = oldWs[name];
        });
    }
};
var ReconnectingWebsocket = function (url, protocols, options) {
    var _this = this;
    if (options === void 0) { options = {}; }
    var ws;
    var connectingTimeout;
    var reconnectDelay = 0;
    var retriesCount = 0;
    var shouldRetry = true;
    var savedOnClose = null;
    var listeners = {};
    // require new to construct
    if (!(this instanceof ReconnectingWebsocket)) {
        throw new TypeError("Failed to construct 'ReconnectingWebSocket': Please use the 'new' operator");
    }
    // Set config. Not using `Object.assign` because of IE11
    var config = getDefaultOptions();
    Object.keys(config)
        .filter(function (key) { return options.hasOwnProperty(key); })
        .forEach(function (key) { return config[key] = options[key]; });
    if (!isWebSocket(config.constructor)) {
        throw new TypeError('Invalid WebSocket constructor. Set `options.constructor`');
    }
    var log = config.debug ? function () {
        var params = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
        }
        return console.log.apply(console, ['RWS:'].concat(params));
    } : function () { };
    /**
     * Not using dispatchEvent, otherwise we must use a DOM Event object
     * Deferred because we want to handle the close event before this
     */
    var emitError = function (code, msg) { return setTimeout(function () {
        var err = new Error(msg);
        err.code = code;
        if (Array.isArray(listeners.error)) {
            listeners.error.forEach(function (_a) {
                var fn = _a[0];
                return fn(err);
            });
        }
        if (ws.onerror) {
            ws.onerror(err);
        }
    }, 0); };
    var handleClose = function () {
        log('handleClose', { shouldRetry: shouldRetry });
        retriesCount++;
        log('retries count:', retriesCount);
        if (retriesCount > config.maxRetries) {
            emitError('EHOSTDOWN', 'Too many failed connection attempts');
            return;
        }
        if (!reconnectDelay) {
            reconnectDelay = initReconnectionDelay(config);
        }
        else {
            reconnectDelay = updateReconnectionDelay(config, reconnectDelay);
        }
        log('handleClose - reconnectDelay:', reconnectDelay);
        if (shouldRetry) {
            setTimeout(connect, reconnectDelay);
        }
    };
    var connect = function () {
        if (!shouldRetry) {
            return;
        }
        log('connect');
        var oldWs = ws;
        var wsUrl = (typeof url === 'function') ? url() : url;
        ws = new config.constructor(wsUrl, protocols);
        connectingTimeout = setTimeout(function () {
            log('timeout');
            ws.close();
            emitError('ETIMEDOUT', 'Connection timeout');
        }, config.connectionTimeout);
        log('bypass properties');
        for (var key in ws) {
            // @todo move to constant
            if (['addEventListener', 'removeEventListener', 'close', 'send'].indexOf(key) < 0) {
                bypassProperty(ws, _this, key);
            }
        }
        ws.addEventListener('open', function () {
            clearTimeout(connectingTimeout);
            log('open');
            reconnectDelay = initReconnectionDelay(config);
            log('reconnectDelay:', reconnectDelay);
            retriesCount = 0;
        });
        ws.addEventListener('close', handleClose);
        reassignEventListeners(ws, oldWs, listeners);
        // because when closing with fastClose=true, it is saved and set to null to avoid double calls
        ws.onclose = ws.onclose || savedOnClose;
        savedOnClose = null;
    };
    log('init');
    connect();
    this.close = function (code, reason, _a) {
        if (code === void 0) { code = 1000; }
        if (reason === void 0) { reason = ''; }
        var _b = _a === void 0 ? {} : _a, _c = _b.keepClosed, keepClosed = _c === void 0 ? false : _c, _d = _b.fastClose, fastClose = _d === void 0 ? true : _d, _e = _b.delay, delay = _e === void 0 ? 0 : _e;
        log('close - params:', { reason: reason, keepClosed: keepClosed, fastClose: fastClose, delay: delay, retriesCount: retriesCount, maxRetries: config.maxRetries });
        shouldRetry = !keepClosed && retriesCount <= config.maxRetries;
        if (delay) {
            reconnectDelay = delay;
        }
        ws.close(code, reason);
        if (fastClose) {
            var fakeCloseEvent_1 = {
                code: code,
                reason: reason,
                wasClean: true,
            };
            // execute close listeners soon with a fake closeEvent
            // and remove them from the WS instance so they
            // don't get fired on the real close.
            handleClose();
            ws.removeEventListener('close', handleClose);
            // run and remove level2
            if (Array.isArray(listeners.close)) {
                listeners.close.forEach(function (_a) {
                    var listener = _a[0], options = _a[1];
                    listener(fakeCloseEvent_1);
                    ws.removeEventListener('close', listener, options);
                });
            }
            // run and remove level0
            if (ws.onclose) {
                savedOnClose = ws.onclose;
                ws.onclose(fakeCloseEvent_1);
                ws.onclose = null;
            }
        }
    };
    this.send = function (data) {
        ws.send(data);
    };
    this.addEventListener = function (type, listener, options) {
        if (Array.isArray(listeners[type])) {
            if (!listeners[type].some(function (_a) {
                var l = _a[0];
                return l === listener;
            })) {
                listeners[type].push([listener, options]);
            }
        }
        else {
            listeners[type] = [[listener, options]];
        }
        ws.addEventListener(type, listener, options);
    };
    this.removeEventListener = function (type, listener, options) {
        if (Array.isArray(listeners[type])) {
            listeners[type] = listeners[type].filter(function (_a) {
                var l = _a[0];
                return l !== listener;
            });
        }
        ws.removeEventListener(type, listener, options);
    };
};
module.exports = ReconnectingWebsocket;

},{}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WebSocketBridge = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _reconnectingWebsocket = require('reconnecting-websocket');

var _reconnectingWebsocket2 = _interopRequireDefault(_reconnectingWebsocket);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Bridge between Channels and plain javascript.
 *
 * @example
 * const webSocketBridge = new WebSocketBridge();
 * webSocketBridge.connect();
 * webSocketBridge.listen(function(payload, stream) {
 *   console.log(payload, stream);
 * });
 */
var WebSocketBridge = function () {
  function WebSocketBridge(options) {
    _classCallCheck(this, WebSocketBridge);

    /**
     * The underlaying `ReconnectingWebSocket` instance.
     *
     * @type {ReconnectingWebSocket}
     */
    this.socket = null;
    this.streams = {};
    this.default_cb = null;
    this.options = _extends({}, options);
  }

  /**
   * Connect to the websocket server
   *
   * @param      {String}  [url]     The url of the websocket. Defaults to
   * `window.location.host`
   * @param      {String[]|String}  [protocols] Optional string or array of protocols.
   * @param      {Object} options Object of options for [`reconnecting-websocket`](https://github.com/joewalnes/reconnecting-websocket#options-1).
   * @example
   * const webSocketBridge = new WebSocketBridge();
   * webSocketBridge.connect();
   */


  _createClass(WebSocketBridge, [{
    key: 'connect',
    value: function connect(url, protocols, options) {
      var _url = void 0;
      // Use wss:// if running on https://
      var scheme = window.location.protocol === 'https:' ? 'wss' : 'ws';
      var base_url = scheme + '://' + window.location.host;
      if (url === undefined) {
        _url = base_url;
      } else {
        // Support relative URLs
        if (url[0] == '/') {
          _url = '' + base_url + url;
        } else {
          _url = url;
        }
      }
      this.socket = new _reconnectingWebsocket2.default(_url, protocols, options);
    }

    /**
     * Starts listening for messages on the websocket, demultiplexing if necessary.
     *
     * @param      {Function}  [cb]         Callback to be execute when a message
     * arrives. The callback will receive `payload` and `stream` parameters
     *
     * @example
     * const webSocketBridge = new WebSocketBridge();
     * webSocketBridge.connect();
     * webSocketBridge.listen(function(payload, stream) {
     *   console.log(payload, stream);
     * });
     */

  }, {
    key: 'listen',
    value: function listen(cb) {
      var _this = this;

      this.default_cb = cb;
      this.socket.onmessage = function (event) {
        var msg = JSON.parse(event.data);
        var payload = void 0;
        var stream = void 0;

        if (msg.stream !== undefined) {
          payload = msg.payload;
          stream = msg.stream;
          var stream_cb = _this.streams[stream];
          stream_cb ? stream_cb(payload, stream) : null;
        } else {
          payload = msg;
          stream = null;
          _this.default_cb ? _this.default_cb(payload, stream) : null;
        }
      };
    }

    /**
     * Adds a 'stream handler' callback. Messages coming from the specified stream
     * will call the specified callback.
     *
     * @param      {String}    stream  The stream name
     * @param      {Function}  cb      Callback to be execute when a message
     * arrives. The callback will receive `payload` and `stream` parameters.
       * @example
     * const webSocketBridge = new WebSocketBridge();
     * webSocketBridge.connect();
     * webSocketBridge.listen();
     * webSocketBridge.demultiplex('mystream', function(payload, stream) {
     *   console.log(payload, stream);
     * });
     * webSocketBridge.demultiplex('myotherstream', function(payload, stream) {
     *   console.info(payload, stream);
     * });
     */

  }, {
    key: 'demultiplex',
    value: function demultiplex(stream, cb) {
      this.streams[stream] = cb;
    }

    /**
     * Sends a message to the reply channel.
     *
     * @param      {Object}  msg     The message
     *
     * @example
     * webSocketBridge.send({prop1: 'value1', prop2: 'value1'});
     */

  }, {
    key: 'send',
    value: function send(msg) {
      this.socket.send(JSON.stringify(msg));
    }

    /**
     * Returns an object to send messages to a specific stream
     *
     * @param      {String}  stream  The stream name
     * @return     {Object}  convenience object to send messages to `stream`.
     * @example
     * webSocketBridge.stream('mystream').send({prop1: 'value1', prop2: 'value1'})
     */

  }, {
    key: 'stream',
    value: function stream(_stream) {
      var _this2 = this;

      return {
        send: function send(action) {
          var msg = {
            stream: _stream,
            payload: action
          };
          _this2.socket.send(JSON.stringify(msg));
        }
      };
    }
  }]);

  return WebSocketBridge;
}();

exports.WebSocketBridge = WebSocketBridge;

},{"reconnecting-websocket":1}]},{},[2])(2)
});

(function() {
  'use strict';

/* jshint undef:false*/
function websocketService() {

  var socketBridge = null;
  var streamToObserversMap = {};
  var observerIdToObserver = {};
  var nextObserverId = 1;
  var demultiplexCallback = function(payload, stream) {
    if (streamToObserversMap.hasOwnProperty(stream) && streamToObserversMap[stream].hasOwnProperty(payload.cmd)) {
      for (var i=0; i<streamToObserversMap[stream][payload.cmd].length; i++) {
        streamToObserversMap[stream][payload.cmd][i](stream, payload.cmd, payload.params);
      }
    }
  };

  return {
    /**
    sets up a socket for the client. Typically there should only be one
    socket per client given multiple streams per socket is supported.
    */
    connect: function (url) {
      socketBridge = new window.channels.WebSocketBridge();
      socketBridge.connect(url);
      socketBridge.listen();
    },
    disconnect: function() {
      if (socketBridge) {
        socketBridge.socket.close(1000,'',{keepClosed:true});
      }
      socketBridge = null;
      streamToObserversMap = {};
      observerIdToObserver = {};
      nextObserverId = 1;
    },
    /**
    Registers a client observer to be called when a cmd in cmds (an array of command strings)
    is received on stream, for streams with same payload interface as core.channels.clientconsumers.ClientTenantMultiplexer
    where payload:
          {
            target: target,
            cmd: cmd,
            params: params
          }

    observer function callback with signature function(stream, cmd, params).

    returns a unique observerId the client observer should use to unobserve upon destroy.
    */
    observe: function(stream, cmds, observer) { //observer: function(stream, command, params)
      var observerId = nextObserverId++;
      if (!streamToObserversMap.hasOwnProperty(stream)) {
        streamToObserversMap[stream] = {};
        socketBridge.demultiplex(stream, demultiplexCallback);
      }
      for (var i=0; i<cmds.length; i++) {
        if (!streamToObserversMap[stream].hasOwnProperty(cmds[i])) {
          streamToObserversMap[stream][cmds[i]] = [];
        }
        if (!observerIdToObserver.hasOwnProperty('ID_' + observerId)) {
          observerIdToObserver['ID_' + observerId] = [];
        }
        streamToObserversMap[stream][cmds[i]].push(observer);
        observerIdToObserver['ID_' + observerId].push({stream: stream, command: cmds[i], observer: observer});
      }
      return observerId;
    },
    /**
    removes the observer from all stream cmds it was observing.
    */
    unobserve: function(observerId) {
      if (observerIdToObserver.hasOwnProperty('ID_' + observerId)) {
        for (var i=0; i<observerIdToObserver['ID_' + observerId].length; i++) {
          streamToObserversMap[observerIdToObserver['ID_' + observerId][i].stream][observerIdToObserver['ID_' + observerId][i].command] =
          streamToObserversMap[observerIdToObserver['ID_' + observerId][i].stream][observerIdToObserver['ID_' + observerId][i].command]
          .filter(function(element) {
            return element !== observerIdToObserver['ID_' + observerId][i].observer;
          });
        }
        delete observerIdToObserver['ID_' + observerId];
      }
    },
    send: function (stream, payload) {
      socketBridge.stream(stream).send(payload)
    },
    newArrayItemsCount: function(oldArray, array) {
      return array.filter(function(arrayObj) {
        return !oldArray.some(function(oldArrayObj) {
          return oldArrayObj.id === arrayObj.id && oldArrayObj.type === arrayObj.type;
        });
      }).length;
    }
  };
}

angular.module('common.services.websocket', [])
  .factory('WebsocketService', websocketService);
})();

(function() {
  'use strict';

/* jshint undef:false*/
function communicationsService($http, $rootScope) {
  var muted = false;
  return {
    broadcastToPractice: function (practiceId, level, message) {
      return $http.post(
        '/api/v1/communications/broadcasts/practices/' + practiceId + '/', 
        {level: level, message: message}
      );
    },
    mute: function(mute) {
      muted = mute;
      $rootScope.$broadcast('communicationsService.mute', mute);
    },
    isMuted: function() {
      return muted;
    }
  };
}

  angular.module('common.services.communications', [])
    .factory('CommunicationsService', communicationsService);
})();

(function() {
  "use strict";

  angular.module("common.archives", [])

  .component("archives", {
    bindings: {
      // optionals, can works with defaults
      "ngfDropText": "@",
      "ngfSelectText": "@",
      "archivePropsArchive": "@",
      "archivePropsArchiveThumb": "@",
      "archivePropsName": "@",
      "archivePropsMetadata": "@",
      // required
      "apiSvc": "=",
      "archiveParamsCreate": "=",
      "archives": "=",
      // perms
      // "canDelete": "=",
      // "canList": "=",
      // "canRetrieve": "=",
      // "canUpdate": "=",
      // "canCreate": "=",
      // "encounterId": "=",
    },
    templateUrl: "src/common/archives.tpl.html",
    controller: function(
      // $location,
      // $log,
      $q,
      // $resolves,
      // $rootScope,
      // $scope,
      // $state,
      // $timeout,
      $uibModal,
      // PatientImageEditorService,
      // PatientImageService
      // ArchivesService
      NotificationService
    ) {

      var $ctrl = this

      $ctrl.$onInit = function() {
        $ctrl.ngfDropText = $ctrl.ngfDropText || "Drop Files"
        $ctrl.ngfSelectText = $ctrl.ngfSelectText || "Select Files"
        $ctrl.archivePropsArchive = $ctrl.archivePropsArchive || "archive"
        $ctrl.archivePropsArchiveThumb = $ctrl.archivePropsArchiveThumb || "archive_thumb"
        $ctrl.archivePropsMetadata = $ctrl.archivePropsMetadata || "archive_metadata"
        $ctrl.archivePropsName = $ctrl.archivePropsName || "name"
        $ctrl.uniqId = Date.now().toString(36)
        // console.log($ctrl.archivePropsName);
        // $ctrl.loading = false
        // $ctrl.apiStorage = $resolves.apiMeta.storage
        // $ctrl.load()
      }

      $ctrl.create = function(files) {
        return $ctrl.apiSvc.create($ctrl.archiveParamsCreate, files).then(
          function(archives) {
            Array.prototype.push.apply($ctrl.archives, archives)
          }
        )
        // return $q.map(archives, function(archive) {
        //   return $ctrl.apiSvc.create(obj.id).then(
        //     function(blob) {
        //       // var link = angular.element("#archive-" + $ctrl.uniqId + "-dwl")
        //       // link.attr("href", URL.createObjectURL(blob))
        //       // link.attr("download", obj.name + "." + mime.getExtension(blob.type))
        //       // link[0].click()
        //       // delete obj.$loading
        //     },
        //     function(response) {
        //       // NotificationService.error("Error Downloading Archive", response)
        //       // delete obj.$loading
        //     }
        //   )
        // })

        // transformations
        // 1. {$archive: File}

        // return ArchivesService.upload(uploadUrl, archives).then(
        //   function(uploaded_archives) {
        //     if ($ctrl.archiveParamsCreate) {
        //       return ArchivesService.create(createUrl, uploaded_archives).then(
        //         function(created_archives) {
        //           return created_archives
        //         }
        //       )
        //     }
        //     // cleanup
        //   }
        // )

        // var patient_images = files.map(function(file) { return { $file_image_archive: file } })
        // Array.prototype.push.apply($ctrl.patient_images, patient_images)
        // return PatientImageService.create(patient_images, +$ctrl.encounterId, $ctrl.apiStorage).then(
        //   function(created_patient_images) {
        //     created_patient_images.forEach(function(patient_image) {
        //       angular.forEach(patient_image, function(value, key) {
        //         if (key.startsWith("$") && key !== "$errors") {
        //           delete patient_image[key]
        //         }
        //       })
        //     })
        //   }
        // )
      }

      $ctrl.destroy = function(obj) {
        $uibModal.open({
          bindToController: true,
          controllerAs: "$ctrl",
          templateUrl: "#archive-destroy",
          controller: function($scope) {
            var $mctrl = this
            $mctrl.loading = false
            $mctrl.submit = function() {
              $mctrl.loading = true
              return $ctrl.apiSvc.destroy(obj.id).then(
                function() {
                  $mctrl.loading = false
                  $scope.$close()
                },
                function(response) {
                  NotificationService.error("Error Removing Archive", response)
                  $mctrl.loading = false
                }
              )
            }
          },
        }).result.then(
          function() {
            var idx = $ctrl.archives.findIndex(function(item) { return item.id === obj.id })
            if (idx !== -1) {
              $ctrl.archives.splice(idx, 1)
            }
          }
        )
      }

      $ctrl.download = function(obj) {
        obj.$loading = true
        return $ctrl.apiSvc.download(obj.id).then(
          function(blob) {
            console.log(blob)
            var link = angular.element("#archive-" + $ctrl.uniqId + "-dwl")
            link.attr("href", URL.createObjectURL(blob))
            link.attr("download", obj.name + "." + mime.getExtension(blob.type))
            link[0].click()
            delete obj.$loading
          },
          function(response) {
            NotificationService.error("Error Downloading Archive", response)
            delete obj.$loading
          }
        )
      }

      $ctrl.open = function(obj) {
        console.log("open", obj.id)
          $uibModal.open({
            bindToController: true,
            controllerAs: "$ctrl",
            templateUrl: "#archive-open",
            resolve: {
              obj: function() {
                return $ctrl.apiSvc.retrieve(obj.id).then(
                  function(response) {
                    return response.data
                  },
                  function(response) {
                    NotificationService.error("Error Retrieving Archive", response)
                  }
                )
              }
            },
            controller: function($scope, obj) {
              var $mctrl = this
              $mctrl.obj = obj
              $mctrl.download = function() {
                $mctrl.loading = true
                $ctrl.apiSvc.download(obj.id).then(
                  function(blob) {
                    var link = angular.element("#archive-" + $ctrl.uniqId + "-dwl")
                    link.attr("href", URL.createObjectURL(blob))
                    link.attr("download", obj.name + "." + mime.getExtension(blob.type))
                    link[0].click()
                    $mctrl.loading = false
                  },
                  function(response) {
                    NotificationService.error("Error Downloading Archive", response)
                    $mctrl.loading = false
                  }
                )
              }
              $mctrl.update = function() {
                $mctrl.loading = true
                $ctrl.apiSvc.partial_update(obj).then(
                  function(response) {
                    $mctrl.loading = false
                    $scope.$close(response)
                  },
                  function(response) {
                    NotificationService.error("Error Updating Archive", response)
                    $mctrl.loading = false
                  }
                )
              }
            }
          }).result.then(
            function(response) {
              angular.extend(obj, response.data)
            }
          )
      }

      // $ctrl.$onChanges = function(changes) {
      //   console.log("changes", changes)
      //   // if (changes.disabled) {
      //   //   $ctrl.disabled = changes.disabled.currentValue
      //   // }
      // }
      // $ctrl.download = function(patient_image) {
      //   patient_image.$loading = true
      //   return PatientImageService.download(patient_image.id).then(
      //     function(blob) {
      //       var link = angular.element("#patient-image-dwl")
      //       link.attr("href", URL.createObjectURL(blob))
      //       link.attr("download", patient_image.name + "." + mime.getExtension(blob.type))
      //       link[0].click()
      //       delete patient_image.$loading
      //     },
      //     function(response) {
      //       NotificationService.error("Error downloading Patient Image", response)
      //       delete patient_image.$loading
      //     }
      //   )
      // }
      // $ctrl.load = function() {
      //   if ($ctrl.encounterId) {
      //     $ctrl.params = {encounter_id: $ctrl.encounterId}
      //   }
      //   else if ($ctrl.patientId) {
      //     $ctrl.params = {encounter__patient_id: $ctrl.patientId}
      //   }
      //   else {
      //     $ctrl.params = null
      //   }
      //   if ($ctrl.canList && $ctrl.params) {
      //     $ctrl.loading = true
      //     return PatientImageService.list($ctrl.params).then(
      //       function(response) {
      //         $ctrl.patient_images = response.data
      //         $ctrl.loading = false
      //         // only for dev
      //         // $ctrl.edit(response.data[3].id)
      //         // $ctrl.scan(0)
      //       },
      //       function(response) {
      //         $ctrl.patient_images = []
      //         $ctrl.loading = false
      //         NotificationService.error("Error Loading Patient Images", response)
      //       }
      //     )
      //   }
      //   else {
      //     $ctrl.patient_images = []
      //     // NotificationService.error("Error $ctrl.canList && $ctrl.params")
      //   }
      // }
      // $ctrl.remove = function(patient_image) {
      //   $uibModal.open({
      //     bindToController: true,
      //     controllerAs: "$ctrl",
      //     templateUrl: "src/common/patient-image-delete-modal.html",
      //     controller: function($scope) {
      //       var $mctrl = this
      //       $mctrl.loading = false
      //       $mctrl.remove = function() {
      //         $mctrl.loading = true
      //         return PatientImageService.destroy(patient_image.id).then(
      //           function() {
      //             $mctrl.loading = false
      //             $scope.$close()
      //           },
      //           function(response) {
      //             $mctrl.loading = false
      //             NotificationService.error("Error Removing Image", response)
      //           }
      //         )
      //       }
      //     },
      //   }).result.then(
      //     function() {
      //       var idx = $ctrl.patient_images.findIndex(function(item) { return item.id === patient_image.id })
      //       if (idx !== -1) {
      //         $ctrl.patient_images.splice(idx, 1)
      //       }
      //     }
      //   )
      // }
      // $ctrl.scan = function() {
      //   PatientImageEditorService.scan().then(
      //     function(patient_images) {
      //       if (patient_images && patient_images.length) {
      //         patient_images.forEach(function(patient_image) {
      //           patient_image.$file_image_archive.name = patient_image.name + ".png"
      //         })
      //         Array.prototype.push.apply($ctrl.patient_images, patient_images)
      //         return PatientImageService.create(patient_images, +$ctrl.encounterId, $ctrl.apiStorage).then(
      //           function(created_patient_images) {
      //             created_patient_images.forEach(function(patient_image) {
      //               angular.forEach(patient_image, function(value, key) {
      //                 if (key.startsWith("$") && key !== "$errors") {
      //                   delete patient_image[key]
      //                 }
      //               })
      //             })
      //           }
      //         )
      //       }
      //     }
      //   )
      // }
    },
  })

  .directive("archiveItem", function($timeout) {
    return {
      restrict: "A",
      require: ["^archives"],
      scope: {
        archive: "=",
        // url: "=",
        // backgroundSize: "=?archiveThumbBackgroundSize",
        // backgroundColor: "=?archiveThumbBackgroundColor",
        // contentType: "=archiveContentType",
      },
      link: function ($scope, $element, $attrs, controllers) {
        var $ctrl = controllers[0]
        var archive = $scope.archive
        // var archive = $scope.$parent.archive

        var $progress = $element.find(".progress")
        if (archive.id) {
          $progress.hide()
        }
        else {
          var progressBar = $progress.children()
          var $unwatch
          if (archive["$uploadProgress_" + $ctrl.archivePropsArchiveThumb]) {
            $unwatch = $scope.$watch(
              function() {
                return [
                  archive["$uploadProgress_" + $ctrl.archivePropsArchiveThumb],
                  archive["$uploadProgress_" + $ctrl.archivePropsArchive],
                  archive.id,
                ]
              },
              function(value) {
                if (value[2]) {
                  $unwatch()
                  progressBar.css("width", "100%")
                  $timeout(function() { $progress.fadeOut(500) }, 500)
                }
                else {
                  progressBar.css("width", value[0]/2 + value[1]/2 - 5 + "%")
                }
              },
              true
            )
          }
          else {
            $unwatch = $scope.$watch(
              function() {
                return [
                  archive["$uploadProgress_" + $ctrl.archivePropsArchive],
                  archive.id,
                ]
              },
              function(value) {
                if (value[1]) {
                  $unwatch()
                  progressBar.css("width", "100%")
                  $timeout(function() { $progress.fadeOut(500) }, 500)
                }
                else {
                  progressBar.css("width", value[0] - 5 + "%")
                }
              },
              true
            )
          }
        }

        var $main = $element.find(".main")
        if (archive[$ctrl.archivePropsArchiveThumb]) {
          // the archive contains thumb, use that
          // var content_type = $scope.fileMetadata ? $scope.fileMetadata.content_type : $scope.file.type
          // var $unwatch = $scope.$watch("archive", function(value) {
          //   console.log(value);
          //   // if (value) {
          //   //   $element.css({
          //   //     "background-color": $scope.backgroundColor || "#cbcbcb",
          //   //     "background-image": "url(" + value + ")",
          //   //     "background-size": $scope.backgroundSize || "cover",
          //   //     "background-repeat": "no-repeat",
          //   //     "background-position": "center",
          //   //   })
          //   //   $unwatch()
          //   // }
          //   // else if (value === null) {
          //   //   $element.css({
          //   //     "background-color": "#cbcbcb",
          //   //     "background-image": "url(assets/images/no-thumb.jpg)",
          //   //     "background-size": "contain",
          //   //   })
          //   //   $unwatch()
          //   // }
          //   // else {
          //   //   $element.css({
          //   //     "background-color": "#cbcbcb",
          //   //     "background-image": "",
          //   //     "background-size": "cover",
          //   //   })
          //   // }
          // })
        }
        else {
          var content_type = null
          try {
            content_type = archive[$ctrl.archivePropsMetadata].content_type
          }
          catch (e) {
            try {
              content_type = archive["$" + $ctrl.archivePropsArchive].type
            }
            catch (e) {
              content_type = null
            }
          }
          $main.addClass("icomoon")
          var ext = mime.getExtension(content_type)
          if (["7z", "bz2", "tar", "zip",].includes(ext)) {
            $main.addClass("icon-icomoon-file-zip")
          }
          else if (["csv", "xls", "xlsx"].includes(ext)) {
            $main.addClass("icon-icomoon-file-excel")
          }
          else if (["doc", "docx", "rtf"].includes(ext)) {
            $main.addClass("icon-icomoon-file-word")
          }
          else if (["pdf"].includes(ext)) {
            $main.addClass("icon-icomoon-file-pdf")
          }
          else if (["gif", "jpeg", "png", "tiff"].includes(ext)) {
            $main.addClass("icon-icomoon-file-picture")
          }
          else if (["avi", "mpeg", "qt", "webm"].includes(ext)) {
            $main.addClass("icon-icomoon-file-video")
          }
          else if (content_type) {
            if (content_type.startsWith("audio")) {
              $main.addClass("icon-icomoon-file-music")
            }
            else {
              $main.addClass("icon-icomoon-file-text2")
            }
          }
          else {
            $main.addClass("icon-icomoon-file-text2")
          }
        }

        // console.log(JSON.stringify(archive, null, 2));
        // console.log(archive[$ctrl.archivePropsArchiveThumb]);
        // console.log($ctrl.archivePropsArchive);
        // console.log($ctrl.archivePropsArchiveThumb);
        // console.log($ctrl.archivePropsMetadata);
      },
      // controller: function($timeout) {
      //   this.$timeout = $timeout
      //   // console.log($scope.contentType)
      //   // var $unwatch = $scope.$watch("url", function(value) {
      //   //   if (value) {
      //   //     $element.css({
      //   //       "background-color": $scope.backgroundColor || "#cbcbcb",
      //   //       "background-image": "url(" + value + ")",
      //   //       "background-size": $scope.backgroundSize || "cover",
      //   //       "background-repeat": "no-repeat",
      //   //       "background-position": "center",
      //   //     })
      //   //     $unwatch()
      //   //   }
      //   //   else if (value === null) {
      //   //     $element.css({
      //   //       "background-color": "#cbcbcb",
      //   //       "background-image": "url(assets/images/no-thumb.jpg)",
      //   //       "background-size": "contain",
      //   //     })
      //   //     $unwatch()
      //   //   }
      //   //   else {
      //   //     $element.css({
      //   //       "background-color": "#cbcbcb",
      //   //       "background-image": "",
      //   //       "background-size": "cover",
      //   //     })
      //   //   }
      //   // })
      // }
    }
  })

  .directive("archiveCreationProgress", function() {
    return {
      restrict: "A",
      scope: {
        oid: "=",
        uploadProgress: "=",
        thumb: "=",
      },
      controller: function($scope, $element, $timeout) {
        if ($scope.oid) {
          $element.hide()
        }
        else {
          var progressBar = $element.children()
          var $unwatch
          if ($scope.thumb) {
            $unwatch = $scope.$watch(
              function(scope) { return [scope.thumb, scope.archive, scope.oid] },
              function(value) {
                if (value[2]) {
                  $unwatch()
                  progressBar.css("width", "100%")
                  $timeout(function() { $element.fadeOut(500) }, 500)
                }
                else {
                  progressBar.css("width", value[0]/2 + value[1]/2 - 5 + "%")
                }
              },
              true
            )
          }
          else {
            $unwatch = $scope.$watch(
              function(scope) { return [scope.archive, scope.oid] },
              function(value) {
                if (value[1]) {
                  $unwatch()
                  progressBar.css("width", "100%")
                  $timeout(function() { $element.fadeOut(500) }, 500)
                }
                else {
                  progressBar.css("width", value[0] - 5 + "%")
                }
              },
              true
            )
          }
        }
      }
    }
  })

  .directive("archiveThumbPreview", function() {
    return {
      restrict: "A",
      scope: {
        url: "=",
        backgroundSize: "=?archiveThumbBackgroundSize",
        backgroundColor: "=?archiveThumbBackgroundColor",
      },
      controller: function($scope, $element) {
        var $unwatch = $scope.$watch("url", function(value) {
          if (value) {
            $element.css({
              "background-color": $scope.backgroundColor || "#cbcbcb",
              "background-image": "url(" + value + ")",
              "background-size": $scope.backgroundSize || "cover",
              "background-repeat": "no-repeat",
              "background-position": "center",
            })
            $unwatch()
          }
          else if (value === null) {
            $element.css({
              "background-color": "#cbcbcb",
              "background-image": "url(assets/images/no-thumb.jpg)",
              "background-size": "contain",
            })
            $unwatch()
          }
          else {
            $element.css({
              "background-color": "#cbcbcb",
              "background-image": "",
              "background-size": "cover",
            })
          }
        })
      }
    }
  })

  .directive("archiveTypePreview", function() {
    return {
      restrict: "A",
      scope: {
        file: "=",
        fileMetadata: "=",
      },
      controller: function($scope, $element) {
        $element.addClass("icomoon")
        var content_type = $scope.fileMetadata ? $scope.fileMetadata.content_type : $scope.file.type
        var ext = mime.getExtension(content_type)
        if (["7z", "bz2", "tar", "zip",].includes(ext)) {
          $element.addClass("icon-icomoon-file-zip")
        }
        else if (["csv", "xls", "xlsx"].includes(ext)) {
          $element.addClass("icon-icomoon-file-excel")
        }
        else if (["doc", "docx", "rtf"].includes(ext)) {
          $element.addClass("icon-icomoon-file-word")
        }
        else if (["pdf"].includes(ext)) {
          $element.addClass("icon-icomoon-file-pdf")
        }
        else if (["gif", "jpeg", "png", "tiff"].includes(ext)) {
          $element.addClass("icon-icomoon-file-picture")
        }
        else if (["avi", "mpeg", "qt", "webm"].includes(ext)) {
          $element.addClass("icon-icomoon-file-video")
        }
        else {
          if (content_type) {
            if (content_type.startsWith("audio")) {
              $element.addClass("icon-icomoon-file-music")
            }
            else {
              $element.addClass("icon-icomoon-file-text2")
            }
          }
          else {
            $element.addClass("icon-icomoon-file-text2")
          }
        }
      }
    }
  })

  .service("ArchivesService", function($http, $log, $q) {

    var $svc = this

    $svc.getThumb = function(file, opts) {
      opts = opts !== undefined ? opts : {}
      opts.width = opts.width !== undefined ? opts.width : 640
      opts.strict = opts.strict !== undefined ? opts.strict : true
      var deferred = $q.defer()
      var img = angular.element("<img/>")
      img.on("load", function() {
        opts.height = parseInt(opts.width / (this.width / this.height))
        if (opts.width >= this.width || opts.height >= this.height) {
          deferred.resolve(file)
        }
        else {
          var canvas = angular.element("<canvas/>")
          canvas.attr("width", opts.width)
          canvas.attr("height", opts.height)
          canvas.get(0).getContext("2d").drawImage(this, 0, 0, opts.width, opts.height)
          canvas.get(0).toBlob(
            function(blob) {
              deferred.resolve(blob)
              URL.revokeObjectURL(img.attr("src"))
            },
            file.type
          )
        }
      })
      img.on("error", function() {
        console.group("ArchivesService.getThumb() -> img.error")
        console.log(JSON.stringify({size: file.size, type: file.type}))
        console.groupEnd()
        deferred.resolve(opts.strict ? file : null)
      })
      try {
        img.attr("src", URL.createObjectURL(file))
      }
      catch (e) {
        console.group("ArchivesService.getThumb() -> img.src")
        console.log(JSON.stringify({size: file.size, type: file.type}))
        console.groupEnd()
        deferred.resolve(opts.strict ? file : null)
      }
      return deferred.promise
    }

    $svc.setError = function(obj, prop, value) {
      if (!angular.isObject(obj.$errors)) { obj.$errors = {} }
      obj.$errors[prop] = value || true
    }

    // $svc.upload = function(url, archives) {
    //   // url: is from api that returns the info for further posting content and upload
    // }

  })

})();

(function() {
  "use strict";

  angular.module("common.patient-image-detail", [])

  .component("patientImageDetailEncounter", {
    bindings: {
      "canDelete": "=",
      "canList": "=",
      "canRetrieve": "=",
      "canUpdate": "=",
      "canCreate": "=",
      "encounterId": "=",
    },
    controller: PatientImageDetailController,
    templateUrl: "src/common/patient-image-detail-encounter.tpl.html",
  })

  .component("patientImageDetailPatient", {
    bindings: {
      "canDelete": "=",
      "canList": "=",
      "canRetrieve": "=",
      "canUpdate": "=",
      "canRetrieveEncounter": "=",
      "patientId": "="
    },
    controller: PatientImageDetailController,
    templateUrl: "src/common/patient-image-detail-patient.tpl.html",
  })

  function PatientImageDetailController(
    $location,
    $log,
    $rootScope,
    $scope,
    $state,
    $timeout,
    $uibModal,
    NotificationService,
    PatientImageEditorService,
    PatientImageService,
    PrincipalService,
    AuthenticationService,
    PatientsService
  ) {

    var $ctrl = this

    $ctrl.$onInit = function() {
      $ctrl.loading = false
      $ctrl.apiStorage = $rootScope.resolved.apiMeta.storage
      $ctrl.load()

      AuthenticationService.getPractice(PrincipalService.getPracticeId()).then(
        function(response) {
          $log.debug('Retrieved practice and setting demrservice_config to: ' + response.data.demrservice_config);
          $ctrl.demrservice_config = response.data.demrservice_config;
          if ($ctrl.demrservice_config && $ctrl.demrservice_config.trim().length > 0) {
            PatientsService.get($scope.$parent.patientId).then(
              function(response) {
                $log.debug('Retrieved patient.');
                $ctrl.patient = response.data;
              },
              function(response) {
                $log.error('Error retrieving patient. Error: ' + response);
                $ctrl.patient = null;
              }
            );
          }
        },
        function(response) {
          $log.error('Error retrieving practice. Setting demrservice_config to blank. Error: ' + response);
          $ctrl.demrservice_config = '';
          $ctrl.patient = null;
        }
      );
    }

    $ctrl.create = function(files) {
      var patient_images = files.map(function(file) { return { $file_image_archive: file } })
      Array.prototype.push.apply($ctrl.patient_images, patient_images)
      return PatientImageService.create(patient_images, +$ctrl.encounterId, $ctrl.apiStorage).then(
        function(created_patient_images) {
          created_patient_images.forEach(function(patient_image) {
            angular.forEach(patient_image, function(value, key) {
              if (key.startsWith("$") && key !== "$errors") {
                delete patient_image[key]
              }
            })
          })
        }
      )
    }

    $ctrl.download = function(patient_image) {
      patient_image.$loading = true
      return PatientImageService.download(patient_image.id).then(
        function(blob) {
          var link = angular.element("#patient-image-dwl")
          link.attr("href", URL.createObjectURL(blob))
          link.attr("download", patient_image.name + "." + mime.getExtension(blob.type))
          link[0].click()
          delete patient_image.$loading
        },
        function(response) {
          NotificationService.error("Error downloading Patient Image", response)
          delete patient_image.$loading
        }
      )
    }

    $ctrl.edit = function(id) {
      PatientImageEditorService.edit(id).then(
        function(patient_image) {
          if (patient_image && patient_image.id) {
            var found = $ctrl.patient_images.find(function(elem) {return patient_image.id === elem.id})
            if (found) {
              found.name = patient_image.name
            }
            else {
              $ctrl.patient_images.push(patient_image)
            }
          }
        }
      )
    }

    $ctrl.load = function() {
      if ($ctrl.encounterId) {
        $ctrl.params = {encounter_id: $ctrl.encounterId}
      }
      else if ($ctrl.patientId) {
        $ctrl.params = {encounter__patient_id: $ctrl.patientId}
      }
      else {
        $ctrl.params = null
      }

      if ($ctrl.canList && $ctrl.params) {
        $ctrl.loading = true
        return PatientImageService.list($ctrl.params).then(
          function(response) {
            $ctrl.patient_images = response.data
            $ctrl.loading = false
            // only for dev
            // if (response.data.length) {
            //   // $ctrl.edit(response.data[0].id)
            //   // $ctrl.scan()
            // }
          },
          function(response) {
            $ctrl.patient_images = []
            $ctrl.loading = false
            NotificationService.error("Error Loading Patient Images", response)
          }
        )
      }
      else {
        $ctrl.patient_images = []
        // NotificationService.error("Error $ctrl.canList && $ctrl.params")
      }
    }

    $ctrl.remove = function(patient_image) {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        templateUrl: "src/common/patient-image-delete-modal.html",
        controller: function($scope) {
          var $mctrl = this
          $mctrl.loading = false
          $mctrl.remove = function() {
            $mctrl.loading = true
            return PatientImageService.destroy(patient_image.id).then(
              function() {
                $mctrl.loading = false
                $scope.$close()
              },
              function(response) {
                $mctrl.loading = false
                NotificationService.error("Error Removing Image", response)
              }
            )
          }
        },
      }).result.then(
        function() {
          var idx = $ctrl.patient_images.findIndex(function(item) { return item.id === patient_image.id })
          if (idx !== -1) {
            $ctrl.patient_images.splice(idx, 1)
          }
        }
      )
    }

    $ctrl.scan = function() {
      PatientImageEditorService.scan().then(
        function(patient_images) {
          if (patient_images && patient_images.length) {
            patient_images.forEach(function(patient_image) {
              patient_image.$file_image_archive.name = patient_image.name + ".png"
            })
            Array.prototype.push.apply($ctrl.patient_images, patient_images)
            return PatientImageService.create(patient_images, +$ctrl.encounterId, $ctrl.apiStorage).then(
              function(created_patient_images) {
                created_patient_images.forEach(function(patient_image) {
                  angular.forEach(patient_image, function(value, key) {
                    if (key.startsWith("$") && key !== "$errors") {
                      delete patient_image[key]
                    }
                  })
                })
              }
            )
          }
        },
        function() {}
      )
    }

    /*
    To generate JSON to store in practice.demrservice_config DB field,
    write a JS object with the following schema in https://www.json-generator.com/# and generate JSON.

    {
      "a_planmeca_cbct_invoker": {
        "type": "invoker",
        "buttonText": "Send patient to TestConsoleApp",
        "buttonTip": "Send patient info to Planmeca CBCT and trigger scan",
        "binaryPath": "C:\\Users\\rm\\source\\repos\\DemrService\\publish\\TestConsoleApp.exe",
        "binaryArgs": "\"{id}\" \"{last_name}\" \"{first_name}\" \"{birth_date}\""
      },
      "b_planmeca_cbct_invoker": {
        "type": "invoker",
        "buttonText": "Send patient to Planmeca CBCT",
        "buttonTip": "Send patient info to Planmeca CBCT and trigger scan",
        "binaryPath": "C:\\Users\\rm\\planmeca\\DxStart.exe",
        "binaryArgs": "\"{id}\" \"{last_name}\" \"{first_name}\" \"{birth_date}\""
      },
      "c_planmeca_retriever": {
        "type": "retriever",
        "buttonText": "Retrieve Planmeca CBCT Volume:",
        "buttonTip": "Retrieve exported Planmeca CBCT and upload to patient files",
        "rootPath": "C:\\Users\\rm\\Desktop\\",
        "name": "{id}_{last_name}_{first_name}",
        "nameIsDir": true,
        "del": false
      },
      "d_jmorita_invoker": {
        "type": "invoker",
        "buttonText": "Send patient to JMorita CBCT",
        "buttonTip": "Send patient info to JMorita CBCT",
        "binaryPath": "C:\\Users\\rm\\jmorita\\TolView.exe",
        "binaryArgs": "C,{id},,,{first_name_underscores} {last_name_underscores},{gender_number},{birth_date_year}{birth_date_twodigit_month}{birth_date_twodigit_day},"
      },
      "e_jmorita_retriever": {
        "type": "retriever",
        "buttonText": "Retrieve JMorita CBCT Volume:",
        "buttonTip": "Retrieve exported JMorita CBCT and upload to patient files",
        "rootPath": "C:\\Users\\rm\\Desktop\\",
        "name": "{id}_{last_name}_{first_name}",
        "nameIsDir": true,
        "del": false
      },
      "f_carestream_cbct_tw" : {
        "type": "invoker",
        "buttonText": "Send patient to Carestream CBCT",
        "buttonTip": "Send patient info to Carestream CBCT",
        "binaryPath": "C:\\Users\\rm\\tw\\tw.exe",
        "binaryArgs": "-P  {fullPath} -N {id} {last_name}, {first_name} {birth_date}",
        "rootPathAndNameLink": "g_carestream_retriever"
      },
      "g_carestream_retriever" : {
        "type": "retriever",
        "buttonText": "Retrieve Carestream CBCT Volume:",
        "buttonTip": "Retrieve Carestream CBCT volume, zip, and upload zip to patient files",
        "rootPath": "C:\\Users\\rm\\Desktop\\CarestreamCBCT\\",
        "name": "{id}_{last_name}_{first_name}",
        "nameIsDir": true,
        "del": false
      },
      "e_jmorita_watcher": {
        "type": "watcher",
        "watchInDir": "C:\\Users\\rm\\Desktop\\watchFiles\\",
        "del": true,
      },
      "f_image_watcher": {
        "type": "watcher",
        "watchInDir": "C:\\Users\\rm\\Desktop\\watchImages\\",
        "del": true,
        "watchFor": "images"
      }
    }

    For testing, code JSON literals must be escaped https://www.freeformatter.com/json-escape.html#ad-output
    */
    $ctrl.sendToDentalemrService = function(demrservice_config, patient, encounterId) {
      
      $log.info(demrservice_config);
      if (!demrservice_config || demrservice_config.trim().length === 0) {
        return;
      }
      demrservice_config = angular.fromJson(demrservice_config);

      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        templateUrl: "src/common/patient-image-send-to-dentalemrservice.html",
        controller: function($scope, $log, $timeout, $window, NotificationService, $http) {
          var $mctrl = this;
          var timeoutId = null;
          var userClosed = false;
          var numPending = 0;

          $mctrl.loading = false;
          $mctrl.started = false;
          $mctrl.statusMessage = "";
          $scope.demrservice_config = demrservice_config;

          var scope = $scope;
          function addToStatusMessageAndApplyAsync(message) {
            scope.$applyAsync(function() {
              if (message) {
                $mctrl.statusMessage = '[' + new Date().toISOString() + ']: ' + message + '\n\n' + $mctrl.statusMessage;
              }
            });
          }

          function buildString(s, objs, replaceFalsyWithBlank) {
            return s.replace(/{([^{}]*)}/g, 
              function(token,key) {
                for (var i=0; i<objs.length; i++) {
                  if (objs[i][key]) {
                    var replace = objs[i][key];
                    return typeof replace ==='string' || typeof replace ==='number' ? replace : ((replaceFalsyWithBlank) ? '' : token);
                  }
                }
              }
            );
          }

          function buildPatientInfo(patient) {
            var birthDate = ((patient.birth_date)) ? moment(patient.birth_date).toDate() : null;
            return {
              id: patient.id,
              last_name: patient.last_name,
              first_name: patient.first_name,
              birth_date: patient.birth_date,

              //JMorita
              gender_number: (patient.gender === 'm') ?  '1' : ((patient.gender ==='f') ? '2' : ''),
              birth_date_year: (birthDate) ? birthDate.getFullYear() : '',
              birth_date_twodigit_month: (birthDate) ? ('0' + (birthDate.getMonth() + 1)).slice(-2) : '',
              birth_date_twodigit_day: (birthDate) ? ('0' + birthDate.getDate()).slice(-2) : '',
              first_name_underscores: patient.first_name.trim().replace(/ /g, "_"),
              last_name_underscores: patient.last_name.trim().replace(/ /g, "_"),
            };
          }

          function initializeConfigs(configs, patientInfo) {
            for (var config in configs) {
              if (!configs.hasOwnProperty(config)) {
                continue;
              }
              if (configs[config].type === 'retriever') {
                var today = new Date();
                var dateObj = {
                  month:  (today.getMonth() + 1).toString(),
                  day:    today.getDate().toString(),
                  year:   today.getFullYear().toString(),
                  iso:    today.toISOString()
                }
                var builtName = buildString(configs[config].name, [patientInfo, dateObj], true);
                configs[config].fullPath = configs[config].rootPath + builtName;
              }
            }
          }

          function updateConfigEntry(configEntry, patientInfo) {
            if (configEntry.type === 'invoker') {
              configEntry.interpolatedBinaryArgs = buildString(configEntry.binaryArgs, [patientInfo, configEntry], true);
            }
          }

          /*
            set watches that set paths on configs with rootPathAndNameLink to their linked config's paths
          */
          function buildAndLinkConfigs(configs, configWatchName, patientInfo) {
            for (var config in configs) {
              if (!configs.hasOwnProperty(config)) {
                continue;
              }
  
              if (
                configs[config].rootPathAndNameLink && 
                configs[configs[config].rootPathAndNameLink] &&
                configs[configs[config].rootPathAndNameLink].fullPath
              )
              {
                $scope.$watch(
                  configWatchName + '["' + configs[config].rootPathAndNameLink + '"].fullPath',
                  (function(config) {
                    return function(newVal, oldVal)
                    {
                      if (newVal !== oldVal)
                      {
                        configs[config].fullPath = newVal;
                        updateConfigEntry(configs[config], patientInfo);
                      }
                    }
                  })(config)
                );
                // set fullPath to fullPath value in config referenced by rootPathAndNameLink
                configs[config].fullPath = configs[demrservice_config[config].rootPathAndNameLink].fullPath;
              }
              updateConfigEntry(configs[config], patientInfo);    //update all config entries
            }
          }

          var patientInfo = buildPatientInfo(patient);
          initializeConfigs($scope.demrservice_config, patientInfo);
          buildAndLinkConfigs($scope.demrservice_config, 'demrservice_config', patientInfo);

          $mctrl.dismiss = function() {
            $log.debug('dismiss');
            $mctrl.$dismiss();
          };

          var connection = new $window.signalR.HubConnectionBuilder()
              //.withUrl('http://localhost:5000/demr')
              .withUrl('https://localhost:5001/demr')
              .configureLogging($window.signalR.LogLevel.Information)
              .withAutomaticReconnect()
              .build();
        
          connection.onreconnecting(function(error) {
            console.assert(connection.state === $window.signalR.HubConnectionState.Reconnecting);
            console.debug('signalr onreconnecting: Connection lost due to error: ' + ((error) ? error : '[no info]'));

            if (!userClosed) {
              $mctrl.started = false;
              addToStatusMessageAndApplyAsync('Lost connection to DemrService.exe. Please ensure DemrService.exe is running on this computer. Attempting to reconnect...');
            }
          });
              
          connection.onreconnected(function(connectionId) {
            console.assert(connection.state === $window.signalR.HubConnectionState.Connected);
            $log.debug('signalr onreconnected: ConnectionId: ' + connectionId);

            watchBegin();

            if (!userClosed) {
              $mctrl.started = true;
              addToStatusMessageAndApplyAsync('Connection to DemrService.exe re-established. Ready.');
            }
          });
                
          connection.onclose(function(error) {
            console.assert(connection.state === $window.signalR.HubConnectionState.Disconnected);
            $log.error('signalr onclose: ' + ((error) ? error : '[no info]'));

            if (!userClosed) {
              $mctrl.started = false;
              addToStatusMessageAndApplyAsync('Connection closed to DemrService.exe. Please ensure DemrService.exe is running on this computer. Attempting to reopen connection...');
              timeoutId = $timeout(function() {
                start()
              }, 10000);
            }
          });
                
          connection.on("InvokeBinarySucceeded", function(exitCode, stdOut, stdErr, transactionId) {
            $log.debug('InvokeBinarySucceeded: exitCode: ' + exitCode + '; stdout: ' + stdOut + '; stdErr: ' + stdErr + '; transactionId: ' + transactionId);
            numPending--;

            if (exitCode === 0) {
              NotificationService.success('Invocation succeeded.')
            } else {
              NotificationService.error('Invocation error',(stdErr) ? stdErr : 'Please try again.');
            }

            if (!userClosed) {
              if (exitCode === 0) {
                addToStatusMessageAndApplyAsync('Invocation ' + transactionId + ' completed successfully. ' + ((stdOut) ? ('External program output: ' + stdOut) : ''));
              } else {
                addToStatusMessageAndApplyAsync('Invocation ' + transactionId + ' resulted in an error from the external program. ' + ((stdErr) ? ('External program error: ' + stdErr) : ''));
              }
            }
            
            if (userClosed && (numPending < 1)) {
              connection.stop().then(function() {
                $log.debug('InvokeBinarySucceeded: signalr stop(): succeeded.');
                dispose();
              }, function(response) {
                $log.error('InvokeBinarySucceeded: signalr stop(): error: ' + ((response) ? response : '[no info]'));
                dispose();
              });
            }
          });
          
          connection.on("InvokeBinaryExceptioned", function(exceptionMessage, transactionId) {
            $log.error('InvokeBinaryExceptioned: ' + ((exceptionMessage) ? exceptionMessage : '[no info]')  + '; transactionId: ' + transactionId);
            numPending--;

            NotificationService.error('Error retrieving file',(exceptionMessage) ? exceptionMessage : 'Please try again.');

            if (!userClosed) {
              addToStatusMessageAndApplyAsync(((exceptionMessage) ? exceptionMessage : ' ') + ' Transaction id ' + transactionId + ' received an error from the invoked program and ended. ');
            }

            if (userClosed && (numPending < 1)) {
              connection.stop().then(function() {
                $log.debug('InvokeBinaryExceptioned: signalr stop(): succeeded.');
                dispose();
              }, function(response) {
                $log.error('InvokeBinaryExceptioned: signalr stop(): error: ' + ((response) ? response : '[no info]'));
                dispose();
              });
            }
          });

          connection.on("RetrieveFileAndPostSucceeded", function(response, postId, path, deletePath, transactionId) {
            $log.debug('RetrieveFileAndPostSucceeded: ' + ((response) ? response : '[no info]')  + '; transactionId: ' + transactionId);

            //NotificationService.success('Volume successfully retrieved and posted.')

            $http.post("/api/v2/~patients/patient-files/", {archive: postId, patient: patient.id}).then(
              function() {
                $log.debug('signalr retrieveFileAndPost(): File set in database. Completed successfully.');
                NotificationService.success("Successfully retrieved and uploaded file. Access file in patient's file tab");

                if (deletePath) {
                  connection.invoke("DeletePath", path).then(
                    function() {
                      $log.info('signalr deletePath(): succeeded');
                      numPending--;
                      if (userClosed && (numPending < 1)) {
                        connection.stop().then(function() {
                          $log.debug('RetrieveFileAndPostSucceeded: signalr stop(): succeeded.');
                          dispose();
                        }, function(response) {
                          $log.error('RetrieveFileAndPostSucceeded: signalr stop(): error: ' + ((response) ? response : '[no info]'));
                          dispose();
                        });
                      }
                    }, function(response) {
                      $log.error('signalr deletePath(): DemrService.exe failed to delete the path. Completed with errors: ' + response.data || '[no error info]');
                      numPending--;
                      if (userClosed && (numPending < 1)) {
                        connection.stop().then(function() {
                          $log.debug('RetrieveFileAndPostSucceeded: signalr stop(): succeeded.');
                          dispose();
                        }, function(response) {
                          $log.error('RetrieveFileAndPostSucceeded: signalr stop(): error: ' + ((response) ? response : '[no info]'));
                          dispose();
                        });
                      }
                    }
                  );
                } else {
                  numPending--;
                  if (userClosed && (numPending < 1)) {
                    connection.stop().then(function() {
                      $log.debug('RetrieveFileAndPostSucceeded: signalr stop(): succeeded.');
                      dispose();
                    }, function(response) {
                      $log.error('RetrieveFileAndPostSucceeded: signalr stop(): error: ' + ((response) ? response : '[no info]'));
                      dispose();
                    });
                  }
                }

                if (!userClosed) {
                  addToStatusMessageAndApplyAsync('Retrieved volume ' + path + ' and saved it in the patient/files tab. Transaction id ' + transactionId + ' completed successfully. ');
                }
              },
              function(response) {
                $log.error('signalr retrieveFileAndPost(): error: Failed to set file in database. completed with errors: ' + response.data || '[no error info]');
                NotificationService.error("Error retrieving and uploading file", response)
                numPending--;
                if (!userClosed) {
                  //$mctrl.loading = false
                  addToStatusMessageAndApplyAsync(((response && response.data) ? response.data : ' ') + 'Transaction id ' + transactionId + '  ended in an error. ');
                } else if (userClosed && (numPending < 1)) {
                  connection.stop().then(function() {
                    $log.debug('RetrieveFileAndPostSucceeded: signalr stop(): succeeded.');
                    dispose();
                  }, function(response) {
                    $log.error('RetrieveFileAndPostSucceeded: signalr stop(): error: ' + ((response) ? response : '[no info]'));
                    dispose();
                  });
                }
              }
            );
          });
    
          connection.on("RetrieveFileAndPostFailed", function(statusCode, reason, transactionId) {
            $log.error('RetrieveFileAndPostFailed: ' + ((statusCode) ? statusCode : '[no info]')  + '; ' + ((reason) ? reason : '[no info]') + '; transactionId: ' + transactionId);
            numPending--;

            NotificationService.error('Error retrieving file', (reason) ? reason : 'Please try again.');

            if (!userClosed) {
              addToStatusMessageAndApplyAsync(((statusCode) ? statusCode : '') + ' ' + ((reason) ? reason : '') + ' Transaction id ' + transactionId + ' ended in an error.');
            }

            if (userClosed && (numPending < 1)) {
              connection.stop().then(function() {
                $log.debug('RetrieveFileAndPostFailed: signalr stop(): succeeded.');
                dispose();
              }, function(response) {
                $log.error('RetrieveFileAndPostFailed: signalr stop(): error: ' + ((response) ? response : '[no info]'));
                dispose();
              });
            }
          });
    
          connection.on("RetrieveFileAndPostExceptioned", function(exceptionMessage, transactionId) {
            $log.error('RetrieveFileAndPostExceptioned: ' + ((exceptionMessage) ? exceptionMessage : '[no info]')  + '; transactionId: ' + transactionId);
            numPending--;

            NotificationService.error('Error retrieving file',(exceptionMessage) ? exceptionMessage : 'Please try again.');

            if (!userClosed) {
              addToStatusMessageAndApplyAsync(((exceptionMessage) ? exceptionMessage : '') + ' Transaction id ' + transactionId + ' ended in an error.');
            }
            if (userClosed && (numPending < 1)) {
              connection.stop().then(function() {
                $log.debug('RetrieveFileAndPostExceptioned: signalr stop(): succeeded.');
                dispose();
              }, function(response) {
                $log.error('RetrieveFileAndPostExceptioned: signalr stop(): error: ' + ((response) ? response : '[no info]'));
                dispose();
              });
            }
          });

          connection.on("RetrieveImageAndPostSucceeded", function(imageResponse, imagePostId, thumbResponse, thumbPostId, path, deletePath, transactionId) {
            $log.debug('RetrieveFileAndPostSucceeded: ' + ((imageResponse) ? imageResponse : '[no image response]') + '; ' + ((thumbResponse) ? thumbResponse : '[no thumb response]') + '; transactionId: ' + transactionId);

            //NotificationService.success('Volume successfully retrieved and posted.')

            $http.post("/api/v2/~patients/patient-images/", {image_archive: imagePostId, image_archive_thumb: thumbPostId, encounter: encounterId}).then(
              function() {
                $log.debug('signalr retrieveImageAndPost(): Image set in database. Completed successfully.');
                NotificationService.success("Successfully retrieved and uploaded image");

                if (deletePath) {
                  connection.invoke("DeletePath", path).then(
                    function() {
                      $log.info('signalr deletePath(): succeeded');
                      numPending--;
                      if (userClosed && (numPending < 1)) {
                        connection.stop().then(function() {
                          $log.debug('RetrieveImageAndPostSucceeded: signalr stop(): succeeded.');
                          dispose();
                        }, function(response) {
                          $log.error('RetrieveImageAndPostSucceeded: signalr stop(): error: ' + ((response) ? response : '[no info]'));
                          dispose();
                        });
                      }
                    }, function(response) {
                      $log.error('signalr deletePath(): DemrService.exe failed to delete the path. Completed with errors: ' + response.data || '[no error info]');
                      numPending--;
                      if (userClosed && (numPending < 1)) {
                        connection.stop().then(function() {
                          $log.debug('RetrieveImageAndPostSucceeded: signalr stop(): succeeded.');
                          dispose();
                        }, function(response) {
                          $log.error('RetrieveImageAndPostSucceeded: signalr stop(): error: ' + ((response) ? response : '[no info]'));
                          dispose();
                        });
                      }
                    }
                  );
                } else {
                  numPending--;
                  if (userClosed && (numPending < 1)) {
                    connection.stop().then(function() {
                      $log.debug('RetrieveImageAndPostSucceeded: signalr stop(): succeeded.');
                      dispose();
                    }, function(response) {
                      $log.error('RetrieveImageAndPostSucceeded: signalr stop(): error: ' + ((response) ? response : '[no info]'));
                      dispose();
                    });
                  }
                }

                if (!userClosed) {
                  addToStatusMessageAndApplyAsync('Retrieved image ' + path + '. Transaction id ' + transactionId + ' completed successfully. After closing this dialog press the reload button on the right of the images section show.');
                }
              },
              function(response) {
                $log.error('signalr retrieveImageAndPost(): error: Failed to set image in database. completed with errors: ' + response.data || '[no error info]');
                NotificationService.error("Error retrieving and uploading image", response)
                numPending--;
                if (!userClosed) {
                  //$mctrl.loading = false
                  addToStatusMessageAndApplyAsync(((response && response.data) ? response.data : ' ') + 'Transaction id ' + transactionId + '  ended in an error. ');
                } else if (userClosed && (numPending < 1)) {
                  connection.stop().then(function() {
                    $log.debug('RetrieveImageAndPostSucceeded: signalr stop(): succeeded.');
                    dispose();
                  }, function(response) {
                    $log.error('RetrieveImageAndPostSucceeded: signalr stop(): error: ' + ((response) ? response : '[no info]'));
                    dispose();
                  });
                }
              }
            );
          });
    
          connection.on("RetrieveImageAndPostFailed", function(imageStatusCode, imageReason, thumbStatusCode, thumbReason, transactionId) {
            $log.error('RetrieveImageAndPostFailed: ' + ((imageStatusCode) ? imageStatusCode : '[no image status code]')  + '; ' + ((imageReason) ? imageReason : '[no image reason]') + ';' + ((thumbStatusCode) ? thumbStatusCode : '[no thumb status code]')  + '; ' + ((thumbReason) ? thumbReason : '[no thumb reason]') + '; transactionId: ' + transactionId);
            numPending--;

            NotificationService.error('Error retrieving image', ((imageReason) ? imageReason : '') + ' ' +  ((thumbReason) ? thumbReason : '') + ' Please try again.');

            if (!userClosed) {
              addToStatusMessageAndApplyAsync(((imageStatusCode) ? imageStatusCode : '') + ' ' + ((imageReason) ? imageReason : '') + ' ' + ((thumbStatusCode) ? thumbStatusCode : '') + ' ' + ((thumbReason) ? thumbReason : '') + ' Transaction id ' + transactionId + ' ended in an error.');
            }

            if (userClosed && (numPending < 1)) {
              connection.stop().then(function() {
                $log.debug('RetrieveImageAndPostFailed: signalr stop(): succeeded.');
                dispose();
              }, function(response) {
                $log.error('RetrieveImageAndPostFailed: signalr stop(): error: ' + ((response) ? response : '[no info]'));
                dispose();
              });
            }
          });
    
          connection.on("RetrieveImageAndPostExceptioned", function(exceptionMessage, transactionId) {
            $log.error('RetrieveImageAndPostExceptioned: ' + ((exceptionMessage) ? exceptionMessage : '[no exception message]')  + '; transactionId: ' + transactionId);
            numPending--;

            NotificationService.error('Error retrieving image',(exceptionMessage) ? exceptionMessage : 'Please try again.');

            if (!userClosed) {
              addToStatusMessageAndApplyAsync(((exceptionMessage) ? exceptionMessage : '') + ' Transaction id ' + transactionId + ' ended in an error.');
            }
            if (userClosed && (numPending < 1)) {
              connection.stop().then(function() {
                $log.debug('RetrieveImageAndPostExceptioned: signalr stop(): succeeded.');
                dispose();
              }, function(response) {
                $log.error('RetrieveImageAndPostExceptioned: signalr stop(): error: ' + ((response) ? response : '[no info]'));
                dispose();
              });
            }
          });

          connection.on("CreatedInWatchDir", function(config, fullPath, isDir, del) {
            if (!$scope.demrservice_config.hasOwnProperty(config)) {
              $log.error('CreatedInWatchDir(' + config + '): ' + fullPath + ': Could not find demrservice_config[' + config + ']');
              return;
            }
            $log.debug('CreatedInWatchDir(' + config + '): ' + fullPath);

            if (!userClosed) {
              addToStatusMessageAndApplyAsync('Detected new file at ' + fullPath + ' while watching directory.');

              if (!demrservice_config[config].watchFor || demrservice_config[config].watchFor === 'files') {
                addToStatusMessageAndApplyAsync('Detected new file at ' + fullPath + ' while watching directory.');
                $mctrl.retrieveFileAndPost(fullPath, isDir, del);

              } else if (demrservice_config[config].watchFor && demrservice_config[config].watchFor === 'images') {
                if (isDir === true) {
                  $log.error('CreatedInWatchDir(' + config + '): ' + fullPath + ': watchFor === "images" but isDir === True. Ignoring.');
                  return;
                }
                addToStatusMessageAndApplyAsync('Detected new image at ' + fullPath + ' while watching directory.');
                $mctrl.retrieveImageAndPost(fullPath, del);

              } else {
                $log.error('CreatedInWatchDir(' + config + '): ' + fullPath + ': Could not find demrservice_config[' + config + '].watchFor or value not "files" or "images"');
              }
            }
          });

          function watchBegin() {
            for (var config in $scope.demrservice_config) {
              if (!$scope.demrservice_config.hasOwnProperty(config)) {
                continue;
              }
              if ($scope.demrservice_config[config].type === 'watcher') {
                $mctrl.watchForCreateInDirBegin(
                  config,
                  $scope.demrservice_config[config].watchInDir,
                  $scope.demrservice_config[config].del,
                  $scope.demrservice_config[config].disableCreatedEvent,
                  $scope.demrservice_config[config].enableRenamedEvent
                );
              }
            }
          }

          function start() {
            $log.debug('signalr start(): begin...');

            $mctrl.loading = true;
            connection.start().then(function() {
              $log.debug('signalr start(): succeeded.');
              $mctrl.started = true;

              watchBegin();
              $mctrl.loading = false;
              addToStatusMessageAndApplyAsync('Ready.');
            }, function (error) {
              $log.error('signalr start(): error: ' + ((error) ? error : '[no info]'));

              $mctrl.loading = false;
              $mctrl.started = false;
              addToStatusMessageAndApplyAsync('Not able to connect to DemrService.exe. Please run DemrService.exe on this computer. Will attempt to connect again in 10 seconds...');
              timeoutId = $timeout(function() {
                start()
              }, 10000);
            });
          }

          function dispose() {
            connection.off("onreconnected");
            connection.off("onreconnecting");
            connection.off("onclose");
            
            connection.off("InvokeBinarySucceeded");
            connection.off("InvokeBinaryExceptioned");
            connection.off("RetrieveFileAndPostSucceeded");
            connection.off("RetrieveFileAndPostFailed");
            connection.off("RetrieveFileAndPostExceptioned");
            connection.off("CreatedInWatchDir");
            
            connection = null;
          }

          $scope.$on('modal.closing', function() {
            if (timeoutId) {
              $timeout.cancel(timeoutId);
            }
            userClosed = true;
            $log.debug('sendToDentalemrService: user closing dialog');

            for (var config in $scope.demrservice_config) {
              if (!$scope.demrservice_config.hasOwnProperty(config)) {
                continue;
              }
              if ($scope.demrservice_config[config].type === 'watcher') {
                $mctrl.watchForCreateInDirEnd(config, $scope.demrservice_config[config].watchInDir);
              }
            }

            if (numPending < 1) {
              $log.debug('none pending. signalr stop(): begin...');

              $mctrl.loading = true;
              connection.stop().then(function() {
                $log.debug('signalr stop(): succeeded.');
  
                $mctrl.loading = false;
                $mctrl.started = false;
                dispose();
              }, function(response) {
                $log.error('signalr stop(): error: ' + ((response) ? response : '[no info]'));
  
                $mctrl.loading = false;
                $mctrl.started = false;
                dispose();
              });
            }
          });


          $mctrl.invokeBinary = function(binaryPath, binaryArgs) {
            if ($mctrl.started) {
              $log.debug('signalr invokeBinary(): begin...');
              numPending++;

              $mctrl.loading = true

              var transactionId = (Math.floor(Math.random() * 10000)).toString();
              addToStatusMessageAndApplyAsync('Invoking external program ' + binaryPath + ' with arguments ' + binaryArgs + '. Transaction id: ' + transactionId + '...');
              
              connection.invoke("InvokeBinary", binaryPath, binaryArgs, transactionId).then(
                function() {
                  $log.debug('signalr invokeBinary(): succeeded.');

                  if (!userClosed) {
                    $mctrl.loading = false;
                    //addToStatusMessage('Executed external program, asking it to send patient info. Awaiting response...');
                  }
                }, function(response) {
                  $log.debug('signalr invokeBinary(): error: ' + ((response) ? response : '[no info]'));

                  if (!userClosed) {
                    $mctrl.loading = false
                    addToStatusMessageAndApplyAsync(((response) ? response : ' ') + 'Transaction id ' + transactionId + ' ended in an error.');
                  }
                }
              );
            }
          };

          $mctrl.retrieveFileAndPost = function(fullPath, isDir, del) {
            if ($mctrl.started) {
              $log.debug('signalr retrieveFileAndPost(): beginning...');

              var transactionId = (Math.floor(Math.random() * 10000)).toString();
              addToStatusMessageAndApplyAsync('Retrieving  ' + fullPath + '. Transaction id ' + transactionId + '...');

              numPending++;

              $mctrl.loading = true

              var fileName = fullPath.replace(/^.*[\\\/]/,'');
              if (isDir) {
                fileName += '.zip';
              }
              var url = "/api/v2/~patients/patient-files/upload/";
              var data = [{ archive: fileName }];
              var config = { params: { patient_id: patient.id } };

              $log.debug('signalr retrieveFileAndPost(): retrieving url...');

              $http.post(url, data, config).then(
                function(response) {
                  $log.debug('signalr retrieveFileAndPost(): retrieved url. Demrservice.exe retrieving file and post to url...');

                  if (response.data.length === 0) {
                    $log.error('signalr retrieveFileAndPost(): Failed to retrieve url. Completed with errors: returned data is length 0');
                    NotificationService.error("Error retrieving and uploading file", "Could not obtain authentication information necessary to upload.");
                    if (!userClosed) {
                      $mctrl.loading = false;
                      addToStatusMessageAndApplyAsync(' Could not obtain authentication information necessary to upload. '+ 'Transaction id ' + transactionId + '  ended in an error. ');
                    }
                    return;
                  } 
                  var archive = response.data[0].archive;
                  var arrayHeaderNameValuePairs = Object.keys(archive.data).map(function(key) {
                    return {Name: key, Value: archive.data[key]};
                  });
                  //arrayHeaderNameValuePairs.push({Name: 'name', Value: archive.name});

                  connection.invoke("RetrieveFileAndPost", fullPath, del, archive.url, archive.name, arrayHeaderNameValuePairs, transactionId).then(
                    function() {
                      if (!userClosed) {
                        $mctrl.loading = false;
                        addToStatusMessageAndApplyAsync();
                      }
                    }, function(response) {
                      $log.error('signalr retrieveFileAndPost(): DemrService.exe failed to retrieve and post file. Completed with errors: ' + response.data || '[no error info]');
   
                      if (!userClosed) {
                        $mctrl.loading = false;
                        NotificationService.error("Error retrieving and uploading file", response)
                        addToStatusMessageAndApplyAsync(((response) ? response : ' ') + 'Transaction id ' + transactionId + '  ended in an error. ');
                      }
                    }
                  );
                },
                function(response) {
                  $log.error('signalr retrieveFileAndPost(): Failed to retrieve url. Completed with errors: ' + response.data || '[no error info]');
                  NotificationService.error("Error retrieving and uploading file", response)

                  if (!userClosed) {
                    $mctrl.loading = false;
                    addToStatusMessageAndApplyAsync(((response && response.data) ? response.data : ' ') + 'Transaction id ' + transactionId + '  ended in an error. ');
                  }
                }
              );
            }
          };

          $mctrl.retrieveImageAndPost = function(fullPath, del) {
            if ($mctrl.started) {
              $log.debug('signalr retrieveImageAndPost(): beginning...');

              var transactionId = (Math.floor(Math.random() * 10000)).toString();
              addToStatusMessageAndApplyAsync('Retrieving  ' + fullPath + '. Transaction id ' + transactionId + '...');

              numPending++;

              $mctrl.loading = true

              var fileName = fullPath.replace(/^.*[\\\/]/,'');
              var url = "/api/v2/~patients/patient-images/upload/";
              var data = [{ image_archive: fileName }];
              var config = { params: { encounter_id: encounterId } };

              $log.debug('signalr retrieveImageAndPost(): retrieving url...');

              $http.post(url, data, config).then(
                function(response) {
                  $log.debug('signalr retrieveImageAndPost(): retrieved url. Demrservice.exe retrieving file and post to url...');

                  if (response.data.length === 0) {
                    $log.error('signalr retrieveFileAndPost(): Failed to retrieve url. Completed with errors: returned data is length 0');
                    NotificationService.error("Error retrieving and uploading image", "Could not obtain authentication information necessary to upload.");
                    if (!userClosed) {
                      $mctrl.loading = false;
                      addToStatusMessageAndApplyAsync(' Could not obtain authentication information necessary to upload. '+ 'Transaction id ' + transactionId + '  ended in an error. ');
                    }
                    return;
                  } 
                  var imageArchive = response.data[0].image_archive;
                  var imageArrayHeaderNameValuePairs = Object.keys(imageArchive.data).map(function(key) {
                    return {Name: key, Value: imageArchive.data[key]};
                  });
                  var thumbArchive = response.data[0].image_archive_thumb;
                  var thumbArrayHeaderNameValuePairs = Object.keys(thumbArchive.data).map(function(key) {
                    return {Name: key, Value: thumbArchive.data[key]};
                  });

                  connection.invoke("RetrieveImageAndPost", 
                    fullPath,
                    del,
                    imageArchive.url, imageArchive.name, imageArrayHeaderNameValuePairs, 
                    thumbArchive.url, thumbArchive.name, thumbArrayHeaderNameValuePairs, 
                    transactionId
                  ).then(
                    function() {
                      if (!userClosed) {
                        $mctrl.loading = false;
                        addToStatusMessageAndApplyAsync();
                      }
                    }, function(response) {
                      $log.error('signalr retrieveImageAndPost(): DemrService.exe failed to retrieve and post image. Completed with errors: ' + response.data || '[no error info]');
   
                      if (!userClosed) {
                        $mctrl.loading = false;
                        NotificationService.error("Error retrieving and uploading image", response)
                        addToStatusMessageAndApplyAsync(((response) ? response : ' ') + 'Transaction id ' + transactionId + '  ended in an error. ');
                      }
                    }
                  );
                },
                function(response) {
                  $log.error('signalr retrieveImageAndPost(): Failed to retrieve url. Completed with errors: ' + response.data || '[no error info]');
                  NotificationService.error("Error retrieving and uploading image", response)

                  if (!userClosed) {
                    $mctrl.loading = false;
                    addToStatusMessageAndApplyAsync(((response && response.data) ? response.data : ' ') + 'Transaction id ' + transactionId + '  ended in an error. ');
                  }
                }
              );
            }
          };

          $mctrl.watchForCreateInDirBegin = function(config, watchInDir, del, disableCreatedEvent, enableRenamedEvent) {
            if ($mctrl.started) {
              numPending++;

              if (!disableCreatedEvent) {
                disableCreatedEvent = false;
              }
              if (!enableRenamedEvent) {
                enableRenamedEvent = false;
              }
              $log.debug('signalr watchForCreateInDirBegin(' + config + '): begin...');

              connection.invoke("watchForCreateInDirBegin", config, watchInDir, del, disableCreatedEvent, enableRenamedEvent).then(
                function(watchInDirFullPath) {
                  $log.debug('signalr watchForCreateInDirBegin(' + config + '): succeeded. Listening in ' + watchInDirFullPath);

                  if (!userClosed) {
                    addToStatusMessageAndApplyAsync('Listening for files in directory ' + watchInDirFullPath);
                  }
                }, function(response) {
                  $log.debug('signalr watchForCreateInDirBegin(' + config + '): error: ' + ((response) ? response : '[no info]'));

                  if (!userClosed) {
                    addToStatusMessageAndApplyAsync('Cannot listen for files in parent directory ' + watchInDir + ' ' + ((response) ? response : ' '));
                  }
                }
              );
            }
          };
          
          $mctrl.watchForCreateInDirEnd = function(config, watchInDir) {
            if ($mctrl.started) {

              $log.debug('signalr watchForCreateInDirEnd(' + config + '): begin...');

              connection.invoke("watchForCreateInDirEnd", config, watchInDir).then(
                function() {
                  numPending--;

                  $log.debug('signalr watchForCreateInDirEnd(' + config + '): succeeded.');

                  if (!userClosed) {
                    addToStatusMessageAndApplyAsync('Stopped listening for files in directory ' + watchInDir + '.');
                  } else if (userClosed && (numPending < 1)) {
                    connection.stop().then(function() {
                      $log.debug('watchForCreateInDirEnd(' + config + '): signalr stop(): succeeded.');
                      dispose();
                    }, function(response) {
                      $log.error('watchForCreateInDirEnd(' + config + '): signalr stop(): error: ' + ((response) ? response : '[no info]'));
                      dispose();
                    });
                  }
                }, function(response) {
                  numPending--;

                  $log.debug('signalr watchForCreateInDirEnd(' + config + '): error: ' + ((response) ? response : '[no info]'));

                  if (!userClosed) {
                    addToStatusMessageAndApplyAsync('Cannot stop listening for files in directory ' + watchInDir + ' ' + ((response) ? response : ' '));
                  } else if (userClosed && (numPending < 1)) {
                    connection.stop().then(function() {
                      $log.debug('watchForCreateInDirEnd(' + config + '): signalr stop(): succeeded.');
                      dispose();
                    }, function(response) {
                      $log.error('watchForCreateInDirEnd(' + config + '): signalr stop(): error: ' + ((response) ? response : '[no info]'));
                      dispose();
                    });
                  }
                }
              );
            }
          };

          $mctrl.$onInit = function() {
            start();
          }
        }
      }).result.then(
        function() {
        }
      )
    }
  }
})();

(function() {
  "use strict";

  angular.module("common.patient-image-editor", [])

  .directive("patientImageEditorViewport", PatientImageEditorViewport)

  .directive("patientImageEditorGrabbablePopover", PatientImageEditorGrabbablePopover)

  .service("PatientImageEditorService", PatientImageEditorService)

  function PatientImageEditorGrabbablePopover() {
    return {
      restrict: "A",
      controller: function($document, $element) {
        var startX
        var startY
        var initialMouseX
        var initialMouseY
        var popover = $element.parents(".modal-popover")
        $element.addClass("grabbable")
        $element.bind("mousedown", function(event) {
          $element.addClass("grabbing")
          startX = popover.prop("offsetLeft")
          startY = popover.prop("offsetTop")
          initialMouseX = event.clientX
          initialMouseY = event.clientY
          $document.bind("mousemove", mousemove)
          $document.bind("mouseup", mouseup)
          return false
        })
        function mousemove(event) {
          var dx = event.clientX - initialMouseX
          var dy = event.clientY - initialMouseY
          popover.css({
            top: (startY + dy) + "px",
            left: (startX + dx) + "px"
          })
          return false
        }
        function mouseup() {
          $element.removeClass("grabbing")
          $document.unbind("mousemove", mousemove)
          $document.unbind("mouseup", mouseup)
        }
      }
    }
  }

  function PatientImageEditorEditModalController(
    $document,
    $uibModalInstance,
    $scope,
    $rootScope,
    // $timeout, // only for dev
    calibrations,
    image,
    NotificationService,
    PatientImageService,
    PrincipalService,
    SensorCalibrationService
    ) {
    var $mctrl = this
    var noop = angular.noop
    var filters = image.image_archive_metadata.filters
    delete filters.fitScale
    delete filters.scale
    if (calibrations.length) {
      $mctrl.caliper = calibrations[0]
      var caliper = store.get("caliper")
      if (caliper) {
        for (var i = 0 ; i < calibrations.length ; i++) {
          if (calibrations[i].id === caliper.id) {
            $mctrl.caliper = calibrations[i]
            break
          }
        }
      }
    }
    $mctrl.calibrations = calibrations
    $mctrl.canUpdate = true
    // $ctrl.canUpdate
    $mctrl.image = image
    $mctrl.image_archive_thumb = {
      name: "thumb-" + image.file_name,
      type: image.image_archive_metadata.content_type
    }
    $mctrl.loading = true
    $mctrl.mode = {}
    $mctrl.keymode = {
      name: null,
      less: noop,
      more: noop,
      reset: noop,
    }

    $mctrl.apiPatientImageEditorViewport = null

    // $mctrl.$onInit = function() {
    //   // for now: use $mctrl.registerPatientImageEditorViewport instead
    // }

    $mctrl.onSelectCaliper = function(value) {
      store.set("caliper", value)
    }

    $mctrl.changeFilter = function(prop, value, min, def, max) {
      filters[prop] = + Math.min(Math.max(min, (filters[prop] || def) + value), max).toFixed(2)
    }

    $mctrl.containerHeight = function(loading) {
      if (loading !== null) {
        return "calc(100vh - " + (30 + 64 + 30 + ($mctrl.canUpdate ? 57 : 0)) + "px)"
      }
      return "initial"
    }

    $mctrl.createCalibration = function(calibration) {
      angular.extend(calibration, {
        practice: PrincipalService.getPracticeId()
      })
      SensorCalibrationService.create(calibration).then(
        function(response) {
          $mctrl.calibrations.push(response.data)
          $mctrl.caliper = response.data
          $mctrl.clearModeProps()
          $scope.$applyAsync(function() {
            $mctrl.toggleMode("calibration")
          })
        }
      )
    }

    $mctrl.deleteCalibration = function(id) {
      SensorCalibrationService.destroy(id).then(
        function() {
          for (var i = 0 ; i < $mctrl.calibrations.length ; i++) {
            if ($mctrl.calibrations[i].id === id) {
              $mctrl.calibrations.splice(i, 1)
              break
            }
          }
          if ($mctrl.calibrations.length) {
            $mctrl.caliper = $mctrl.calibrations[0]
          }
          else {
            $mctrl.caliper = null
          }
          store.set("caliper", $mctrl.caliper)
        }
      )
    }

    $mctrl.clearModeProps = function(ignoreProps) {
      var ignore
      if (!angular.isArray(ignoreProps)) {
        ignore = {
          name:true
        }
      }
      else {
        ignore = ignoreProps.reduce(
          function(memo, value) {
            memo[value] = true
            return memo
          },
          {
            name:true
          }
        )
      }
      angular.forEach($mctrl.mode, function(prop) {
        if (!ignore[prop]) {
          delete $mctrl.mode[prop]
        }
      })
      // TODO jjlorenzo this is a quick fix, improve later
      if ($mctrl.mode.name === "calibration") {
        $mctrl.mode.calibration = {
          points:[],
        }
      }
    }

    $mctrl.download = function() {
      $mctrl.loading = true
      return PatientImageService.download($mctrl.image.id).then(
        function(blob) {
          var link = angular.element("#patient-image-dwl")
          link.attr("href", URL.createObjectURL(blob))
          link.attr("download", $mctrl.image.name + "." + mime.getExtension(blob.type))
          link[0].click()
          $mctrl.loading = false
        },
        function(response) {
          NotificationService.error("Error downloading Patient Image", response)
          $mctrl.loading = false
        }
      )
    }

    $mctrl.filterHasDefaultValue = function(prop, value) {
      return filters[prop] === value || filters[prop] === undefined
    }

    $mctrl.filterHasValue = function(prop, value) {
      return filters[prop] === value
    }

    $mctrl.setFilterValue = function(prop, value) {
      filters[prop] = value
    }

    $mctrl.toggleFilter = function(prop, def) {
      filters[prop] = !(filters[prop] || def)
    }

    $mctrl.toggleMode = function(name) {
      $mctrl.setMode($mctrl.mode.name !== name ? name : null)
    }

    $mctrl.toggleKeyMode = function(name, less, more, reset) {
      if ($mctrl.keymode.name !== name) {
        $mctrl.setKeyMode(name, less, more, reset)
      }
      else {
        $mctrl.setKeyMode(null)
      }
    }

    $mctrl.setMode = function(name) {
      $mctrl.mode = {name:name}
      if (name === "calibration") {
        $mctrl.mode.calibration = {
          points: []
        }
      }
      if (name === "saveas") {
        $mctrl.mode.image = {
          name: $mctrl.image.name + "- copy",
          preview : $mctrl.apiPatientImageEditorViewport.image.toDataURL(),
        }
      }
    }

    $mctrl.registerPatientImageEditorViewport = function(api) {
      $mctrl.apiPatientImageEditorViewport = api
      // only for dev
      // $timeout(function() {
      //   $mctrl.toggleMode("saveas")
      //   // $timeout(function() { $mctrl.saveas($mctrl.mode.image.name) }, 100)
      // }, 100)
    }

    $mctrl.saveas = function(name) {
      $mctrl.mode.loading = true
      $mctrl.apiPatientImageEditorViewport.image.toCanvas().toBlob(
        function(blob) {
          blob.name = name
          var extension = mime.getExtension(blob.type)
          if (extension) {
            blob.name = name + "." + extension
          }
          blob.lastModifiedDate = new Date()
          PatientImageService.create([{$file_image_archive: blob}], $mctrl.image.encounter, $rootScope.resolved.apiMeta.storage).then(
            function(response) {
              $mctrl.$close({
                id: response[0].id,
                encounter: $mctrl.image.encounter,
                created_at: response[0].created_at,
                created_by: response[0].created_by,
                image_archive_thumb: response[0].image_archive_thumb,
                name: response[0].name,
              })
            }
          )
        }
      )
    }

    $mctrl.setKeyMode = function(name, less, more, reset) {
      $mctrl.keymode = {
        name: name,
        less: less || noop,
        more: more || noop,
        reset: reset || noop
      }
    }

    $mctrl.update = function() {
      $mctrl.loading = true
      PatientImageService.update($mctrl.image).then(
        function(response) {
          $mctrl.loading = false
          $mctrl.$close(response.data)
        },
        function(response) {
          $mctrl.loading = false
          NotificationService.error("Error Updating", response)
        }
      )
    }

    // TODO: refator to improve function names

    $mctrl.aLess = function() {
      $mctrl.changeFilter("angle", -90, -360, 0, 360)
    }

    $mctrl.aMore = function() {
      $mctrl.changeFilter("angle", 90, -360, 0, 360)
    }

    $mctrl.aReset = function() {
      $mctrl.setFilterValue("angle", 0)
    }

    $mctrl.bLess = function() {
      $mctrl.changeFilter("brightness", -0.05, -1, 0, 1)
    }

    $mctrl.bMore = function() {
      $mctrl.changeFilter("brightness", 0.05, -1, 0, 1)
    }

    $mctrl.bReset = function() {
      $mctrl.setFilterValue("brightness", 0)
    }

    $mctrl.cLess = function() {
      $mctrl.changeFilter("contrast", -10, -100, 0, 100)
    }

    $mctrl.cMore = function() {
      $mctrl.changeFilter("contrast", 10, -100, 0, 100)
    }

    $mctrl.cReset = function() {
      $mctrl.setFilterValue("contrast", 0)
    }

    $mctrl.center = function() {
      $mctrl.setFilterValue("dx", 0)
      $mctrl.setFilterValue("dy", 0)
    }

    $mctrl.invert = function() {
      $mctrl.toggleFilter("invert", false)
    }

    $mctrl.sLess = function() {
      $mctrl.changeFilter("scale", -0.05, 0.05, 1, 2)
    }

    $mctrl.sMore = function() {
      $mctrl.changeFilter("scale", 0.05, 0.05, 1, 2)
    }

    $mctrl.sFit = function() {
      $mctrl.setFilterValue("scale", null)
    }

    $mctrl.sReset = function() {
      $mctrl.setFilterValue("scale", 1)
    }

    $mctrl.sharpnessReset = function() {
      $mctrl.setFilterValue("sharpness", {
        strength: 0,
        mix: 0
      })
    }

    $mctrl.clearFilters = function() {
      $mctrl.setFilterValue("angle", 0)
      $mctrl.setFilterValue("brightness", 0)
      $mctrl.setFilterValue("contrast", 0)
      $mctrl.setFilterValue("dx", 0)
      $mctrl.setFilterValue("dy", 0)
      $mctrl.setFilterValue("invert", false)
      $mctrl.setFilterValue("scale", 1)
    }

    $mctrl.clearMeasurements = function() {
      $mctrl.image.image_archive_metadata.measurements = []
    }

    $document.on("keypress.image-viewer", function(event) {
      // for now any modifier key
      if (event.ctrlKey || event.altKey || event.metaKey) {
        $scope.$applyAsync(function() {
          switch (event.key) {
            case "q":
              $mctrl.setMode(null)
              $mctrl.toggleKeyMode("scale", $mctrl.sLess, $mctrl.sMore, $mctrl.sReset)
              break
            case "w":
              $mctrl.setMode(null)
              $mctrl.toggleKeyMode("angle", $mctrl.aLess, $mctrl.aMore, $mctrl.aReset)
              break
            case "e":
              $mctrl.setMode(null)
              $mctrl.toggleKeyMode("brightness", $mctrl.bLess, $mctrl.bMore, $mctrl.bReset)
              break
            case "r":
              $mctrl.setMode(null)
              $mctrl.toggleKeyMode("contrast", $mctrl.cLess, $mctrl.cMore, $mctrl.cReset)
              break
            case "t":
              $mctrl.setMode(null)
              $mctrl.setKeyMode(null)
              $mctrl.invert()
              break
            case "y":
              $mctrl.setKeyMode(null)
              $mctrl.toggleMode("drag")
              break
            case "u":
              $mctrl.setKeyMode(null)
              $mctrl.center()
              break
            case "i":
              $mctrl.setKeyMode(null)
              $mctrl.setMode(null)
              $mctrl.clearFilters()
              break
            case "o":
              $mctrl.setKeyMode(null)
              $mctrl.toggleMode("filters")
              break
            case "p":
              $mctrl.setKeyMode(null)
              $mctrl.toggleMode("filters")
              break
            case "a":
              $mctrl.setKeyMode(null)
              $mctrl.toggleMode("measure")
              break
            case "s":
              $mctrl.setKeyMode(null)
              $mctrl.clearMeasurements()
              break
            case "d":
              $mctrl.setKeyMode(null)
              $mctrl.toggleMode("form")
              break
            case "f":
              $mctrl.setKeyMode(null)
              $mctrl.toggleMode("help")
              break
            case "-":
              $mctrl.keymode.less()
              break
            case "+":
              $mctrl.keymode.more()
              break
            case "=": case "*":
              $mctrl.keymode.reset()
              break
          }
        })
      }
    })

  }

  function PatientImageEditorScanModalController(
    $cookies,
    $http,
    $localStorage,
    $log,
    $q,
    $scope,
    $uibModalInstance,
    ArchivesService,
    dwtContainerId,
    NotificationService
    ) {

    var $ctrl = this

    var dwtContainerIdStorageDefaults = {}

    dwtContainerIdStorageDefaults[dwtContainerId] = {
      sources: {
        all: [],
        latest: null,
        ip: Dynamsoft.Lib.product.host,
      },
      showUI: true,
    }

    $ctrl.$onInit = function() {
      $ctrl.localStorage = $localStorage.$default(dwtContainerIdStorageDefaults)
      $ctrl.settings = $ctrl.localStorage[dwtContainerId]
      $ctrl.settings.showUI = true
      $ctrl.dwt = null
      $ctrl.loading = "init"
      $ctrl.images = []
      $ctrl.currentImageIdx = null
      // Dynamsoft.DWT.OnWebTwainPreExecute = noop
      // Dynamsoft.DWT.OnWebTwainPostExecute = noop
      $uibModalInstance.rendered.then(function() {
        Dynamsoft.DWT.CreateDWTObject(
          dwtContainerId,
          null,
          null,
          null,
          function(dwt) {
            dwt.BackgroundFillColor = 0x000000
            dwt.Viewer.fitWindow()
            dwt.Viewer.singlePageMode = true
            $scope.$applyAsync(function() {
              $ctrl.dwt = dwt
              $ctrl.loadSources()
              // if (autoScan) {
              //   $ctrl.scan()
              // }
              $ctrl.loading = false
            })
          },
          function(errorString) {
            $scope.$applyAsync(function() {
              $ctrl.loading = false
              NotificationService.error("Error Creating DWTObject", errorString)
            })
          }
        )
      })
      $scope.$on("$destroy", function() {
        Dynamsoft.DWT.DeleteDWTObject(dwtContainerId)
      })
    }

    $ctrl.load = function(idx) {
      $ctrl.images[idx].$loading = true
      return getImageInBufferAtIndex(idx).then(
        function(image) {
          delete $ctrl.images[idx].$error
          angular.extend($ctrl.images[idx], image)
          $ctrl.images[idx].$loading = false
        },
        function(error) {
          $ctrl.images[idx].$loading = false
          NotificationService.error("getImageInBufferAtIndex Error", {data: error})
        }
      )
    }

    $ctrl.loadSources = function(reload) {
      if (!reload || $ctrl.dwt.CloseSourceManager()) {
        if (!reload || $ctrl.dwt.OpenSourceManager()) {
          var sources = Array.apply(null, Array($ctrl.dwt.SourceCount)).map(function (_, idx) {
            return {idx:idx, name:$ctrl.dwt.GetSourceNameItems(idx)}
          })
          var cmp_fn = function(s) {
            return [s.idx, s.name]
          }
          if (JSON.stringify(sources.map(cmp_fn)) !== JSON.stringify($ctrl.settings.sources.all.map(cmp_fn))) {
            $ctrl.settings.sources.all = sources
            $ctrl.settings.sources.latest = sources[0]
            $ctrl.saveSettings()
          }
          if (reload) {
            NotificationService.success("Updated Devices")
          }
        }
        else {
          NotificationService.error("OpenSourceManager Error", {data: getLastError()})
        }
      }
      else {
        NotificationService.error("CloseSourceManager Error", {data: getLastError()})
      }
    }

    $ctrl.saveSettings = function() {
      $localStorage[dwtContainerId] = $ctrl.settings
    }

    $ctrl.scan = function() {
      if ($ctrl.dwt.SelectSourceByIndex($ctrl.settings.sources.latest.idx)) {
        if ($ctrl.dwt.OpenSource()) {
          $ctrl.dwt.IfAppendImage = true
          $ctrl.dwt.IfShowIndicator = $ctrl.settings.showUI
          $ctrl.dwt.IfShowProgressBar = $ctrl.settings.showUI
          $ctrl.dwt.IfShowUI = $ctrl.settings.showUI
          $ctrl.loading = true
          $ctrl.dwt.AcquireImage(
            function() {
              var indexes = Array.apply(null, Array($ctrl.dwt.HowManyImagesInBuffer - $ctrl.images.length)).map(
                function (_, idx0) {
                  return $ctrl.images.length + idx0
                }
              )
              var tasks = indexes.map(function(idx) {
                return function() {
                  return getImageInBufferAtIndex(idx).then(function(image) {
                    angular.extend(image, {name: "IMG_" + moment().format("YYYYMMDDHH_mmssSSS")})
                    $ctrl.images.push(image)
                  })
                }
              })
              $q.serial(tasks).then(function() {
                $ctrl.dwt.CloseSource()
                $scope.$applyAsync(function() {
                  $ctrl.currentImageIdx = $ctrl.images.length - 1
                  $ctrl.loading = false
                })
              })
            },
            function(errorCode, errorString) {
              $ctrl.loading = false
              if ([-1032].indexOf(errorCode) !== -1) {
                NotificationService.warning(errorString)
              }
              else {
                NotificationService.error("AcquireImage Error", {data:{code:errorCode, msg:errorString}})
              }
            }
          )
        }
        else {
          NotificationService.error("OpenSource Error", {data: getLastError()})
        }
      }
      else {
        NotificationService.error("SelectSourceByIndex Error", {data: getLastError()})
      }
    }

    $ctrl.remove = function(idx) {
      $ctrl.images[idx].$loading = true
      if ($ctrl.dwt.RemoveImage(idx)) {
        $ctrl.images.splice(idx, 1)
        $ctrl.currentImageIdx = $ctrl.images.length ? Math.min(idx, $ctrl.images.length - 1) : null
        $ctrl.images[idx].$loading = false
      }
      else {
        $ctrl.images[idx].$loading = false
        NotificationService.error("RemoveImage Error", {data: getLastError()})
      }
    }

    $ctrl.rotate = function(idx, direction) {
      $ctrl.loading = true
      if ($ctrl.dwt["Rotate" + direction](idx)) {
        return getImageInBufferAtIndex(idx).then(
          function(image) {
            delete $ctrl.images[idx].$error
            angular.extend($ctrl.images[idx], image)
            $ctrl.loading = false
          },
          function(error) {
            $ctrl.loading = false
            NotificationService.error("getImageInBufferAtIndex Error", {data: error})
          }
        )
      }
      else {
        $ctrl.images[idx].$loading = false
        NotificationService.error("Rotate Error", {data: getLastError()})
      }
    }

    function getImageInBufferAtIndex(idx) {
      return $q(function(resolve) {
        return $ctrl.dwt.ConvertToBlob([idx], Dynamsoft.DWT.EnumDWT_ImageType.IT_PNG,
          function(file) {
            return ArchivesService.getThumb(file).then(function(thumb) {
              return resolve({
                $file_image_archive: file,
                $file_image_archive_thumb: thumb,
                image_archive: URL.createObjectURL(file),
                image_archive_thumb: URL.createObjectURL(thumb),
              })
            })
          },
          function(errorCode, errorString) {
            NotificationService.error("ConvertToBlob Error", {data:{code:errorCode, msg:errorString}})
            return resolve({
              image_archive_thumb: "/assets/images/no-thumb.jpg",
              $error: {code:errorCode, msg:errorString},
            })
          }
        )
      })
    }

    function getLastError() {
      return {
        code: $ctrl.dwt.ErrorCode,
        msg: $ctrl.dwt.ErrorString
      }
    }

    // function noop() {}

  }

  function PatientImageEditorService(
    $uibModal,
    NotificationService
    ) {

    var $svc = this

    $svc.edit = function(id) {
      return $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        keyboard: false,
        size: "fullscreen",
        templateUrl: "src/common/patient-image-editor-edit-modal.html",
        windowClass: "fullscreen",
        controller: PatientImageEditorEditModalController,
        resolve: {
          calibrations: function(SensorCalibrationService, PrincipalService) {
            return SensorCalibrationService.list({practice:PrincipalService.getPracticeId()}).then(
              function(response) {
                return response.data
              },
              function(response) {
                NotificationService.error("Error", response)
              }
            )
          },
          image: function(PatientImageService) {
            return PatientImageService.retrieve(id).then(
              function(response) {
                return response.data
              },
              function(response) {
                NotificationService.error("Error Retrieving Patient Image", response)
              }
            )
          }
        },

      }).result
    }

    $svc.scan = function() {
      return $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        keyboard: true,
        size: "lg",
        templateUrl: "src/common/patient-image-editor-scan-modal.html",
        controller: PatientImageEditorScanModalController,
        resolve: {
          dwtContainerId: function() { return "dwt-container-encounter-patient-image" },
        },
      }).result
    }

  }

  function PatientImageEditorViewport() {
    return {
      restrict: "A",
      scope: {
        archive: "=",
        caliper: "=",
        filters: "=",
        loading: "=",
        measurements: "=",
        mode: "=",
        thumb: "=?",
        register: "&",
      },
      controller: function($document, $element, $injector, $q, $scope, $window) {
        if (!angular.isArray($scope.measurements)) {
          $scope.measurements = []
        }
        // hack to wait for the container to gets its final dimmensions, e.g. if inside a modal
        $scope.$applyAsync(function() {
          var $_window = angular.element($window)
          var angle
          var dx
          var dy
          var scale
          // half - container - height|width
          var ch = $element.height()
          var cw = $element.width()
          var hch = ch / 2
          var hcw = cw / 2
          // half - image - height|width
          var ih
          var iw
          var hih
          var hiw

          var img = null
          var stage = new Konva.Stage({name:"stage", container:$element[0], height:ch, width:cw})
          var layer = new Konva.Layer({name:"layer"})
          var image = new Konva.Image({name:"image"})
          var measurements = new Konva.Group({name:"measurements"})

          var selectedMeasurement = null
          var resizeMeasurement = {}
          var newMeasurement = null
          var newCalibration = null

          layer.add(image)
          layer.add(measurements)
          stage.add(layer)

          layer.on("click", function(event) {
            var name = event.target.getName()
            var pos
            var points
            if ($scope.mode.name === "measure") {
              if (name === "start" || name === "end") {
                if (resizeMeasurement.handler === event.target) {
                  resizeMeasurement = {}
                }
                else {
                  resizeMeasurement = {
                    handler: event.target,
                    kind: event.target.getName(),
                    line: event.target.getParent().findOne(".line"),
                    text: event.target.getParent().findOne(".text"),
                  }
                }
              }
              else if (name === "image") {
                if (resizeMeasurement.handler) {
                  resizeMeasurement = {}
                }
                pos = getPointerPosition()
                if (!newMeasurement) {
                  newMeasurement = buildMeasurement([pos.x, pos.y])
                  measurements.add(newMeasurement)
                }
                else {
                  points = [
                    newMeasurement.attrs.points[0],
                    newMeasurement.attrs.points[1],
                    pos.x,
                    pos.y
                  ]
                  newMeasurement.setAttr("points", points)
                  newMeasurement.findOne(".line").setAttrs({
                    points: points,
                    visible: true,
                  })
                  newMeasurement.findOne(".end").setAttrs({
                    x:points[2],
                    y:points[3],
                    visible: true,
                  })
                  newMeasurement.findOne(".text").setAttrs({
                    text: calcDistance(points).toString(),
                    data: getPathData(points),
                    visible: true,
                  })
                  newMeasurement = null
                  $scope.$applyAsync(function() {
                    $scope.measurements.push(points)
                  })
                }
              }
              else if (name === "text") {
                var measurement = event.target.getParent()
                if (selectedMeasurement === measurement) {
                  event.target.setAttr("fill", "yellow")
                  selectedMeasurement = null
                }
                else {
                  event.target.setAttr("fill", "white")
                  if (selectedMeasurement) {
                    selectedMeasurement.findOne(".text").setAttr("fill", "yellow")
                  }
                  selectedMeasurement = measurement
                }
              }
            }
            else if ($scope.mode.name === "calibration") {
              if ($scope.mode.calibration.points.length <= 4) {
                pos = getPointerPosition()
                // draw the special measurement to visualize the calibration
                if (!newCalibration) {
                  newCalibration = buildMeasurement([pos.x, pos.y])
                  measurements.add(newCalibration)
                }
                else {
                  points = [
                    newCalibration.attrs.points[0],
                    newCalibration.attrs.points[1],
                    pos.x,
                    pos.y
                  ]
                  newCalibration.findOne(".line").setAttrs({
                    points: points,
                    visible: true,
                  })
                  newCalibration.findOne(".end").setAttrs({
                    x:points[2],
                    y:points[3],
                    visible: true,
                  })
                  newCalibration.findOne(".text").setAttrs({
                    text: $scope.mode.calibration.mm ? ($scope.mode.calibration.mm + "mm") : "",
                    data: getPathData(points),
                    visible: true,
                  })
                  // newCalibration = null
                }

                $scope.$applyAsync(function() {
                  $scope.mode.calibration.points.push(pos.x)
                  $scope.mode.calibration.points.push(pos.y)
                })
              }
            }
            stage.batchDraw()
          })

          layer.on("mousemove", function() {
            if ($scope.mode.name === "measure" && resizeMeasurement.handler) {
              var pos = getPointerPosition()
              var points = resizeMeasurement.line.points()
              resizeMeasurement.handler.setAttrs({
                x:pos.x,
                y:pos.y
              })
              resizeMeasurement.text.setAttrs({
                data:getPathData(points),
                text: calcDistance(points).toString()
              })
              if (resizeMeasurement.kind === "start") {
                points[0] = pos.x
                points[1] = pos.y
              }
              else {
                points[2] = pos.x
                points[3] = pos.y
              }
              resizeMeasurement.line.points(points)
              stage.batchDraw()
            }
          })

          $scope.$watch("caliper", function(value, oldValue) {
            if (value !== oldValue) {
              angular.forEach(measurements.find(".text"), function(text){
                text.setText(calcDistance(text.getParent().findOne(".line").points()).toString())
                stage.batchDraw()
              })
            }

          })

          $scope.$watch("mode.calibration", function(value) {
            if (value && !value.points.length && newCalibration) {
              newCalibration.destroy()
              newCalibration = null
              layer.batchDraw()
            }
          })

          $scope.$watch("mode.calibration.mm", function(value) {
            if (newCalibration && value) {
              newCalibration.findOne(".text").setAttrs({
                text: value.toFixed(2) + "mm",
              })
              stage.batchDraw()
            }
          })

          $scope.$watch("mode", function(nmode, omode) {
            if (omode.name === "drag") {
              layer.setDraggable(false)
              layer.off("dragend")
            }
            if (omode.name === "measure") {
            }
            if (omode.name === "calibration") {
              // measurements.hide()
              // layer.batchDraw()
            }

            if (nmode.name === "drag") {
              layer.setDraggable(true)
              layer.on("dragend", function(event) {
                dx = event.target.x() - hcw
                dy = event.target.y() - hch
                $scope.$applyAsync(function() {
                  $scope.filters.dx = dx
                  $scope.filters.dy = dy
                })
              })
            }
            if (nmode.name === "measure") {
            }
            if (nmode.name === "calibration") {
              // measurements.show()
              // layer.batchDraw()
            }
          })

          $scope.$watch("measurements", function(value, oldValue) {
            if (value !== oldValue) {
              measurements.removeChildren()
              angular.forEach($scope.measurements, function(measure) {
                measurements.add(buildMeasurement(measure))
              })
              stage.batchDraw()
            }
          })

          $scope.$watch("filters", function(value) {
            if (img) {
              transform(value)
              if ($scope.thumb && $scope.thumb.name) {
                thumbnail($scope.thumb.name, $scope.thumb.type).then(
                  function(thumb) {
                    if ($scope.thumb.url && $scope.thumb.url.startsWith("blob")) {
                      URL.revokeObjectURL($scope.thumb.url)
                    }
                    angular.extend($scope.thumb, thumb)
                  }
                )
              }
            }
          }, true)

          $scope.$on("$destroy", function() {
            $document.off("keydown.image-viewer")
            $_window.off("resize.image-viewer")
            stage.destroy()
          })

          $document.on("keydown.image-viewer", function(event) {
            if (event.key === "Delete") {
              if (selectedMeasurement) {
                var points = selectedMeasurement.getAttr("points")
                $scope.$applyAsync(function() {
                  $scope.measurements.splice(
                    $scope.measurements.findIndex(function(_points){
                      return _points === points
                    }),
                    1
                  )
                })
                selectedMeasurement.destroy()
                selectedMeasurement = null
                stage.batchDraw()
              }
            }
          })

          $_window.on("resize.image-viewer", function() {
            ch = $element.height()
            cw = $element.width()
            hch = ch / 2
            hcw = cw / 2
            delete $scope.filters.fitScale
            transform($scope.filters)
          })

          function loadImage(src) {
            var deferred = $q.defer()
            var img = new Image()
            img.crossOrigin = "Anonymous"
            img.onerror = function() {
              deferred.reject(null)
            }
            img.onload = function() {
              ih = this.height
              iw = this.width
              hih = ih / 2
              hiw = iw / 2
              image.setAttrs({
                height:ih,
                image:this,
                width:iw
              })
              deferred.resolve(this)
            }
            img.src = src
            return deferred.promise
          }

          function thumbnail(name, type) {
            var deferred = $q.defer()
            try {
              stage.toCanvas().toBlob(
                function(blob) {
                  blob.name = name
                  blob.lastModifiedDate = new Date()
                  deferred.resolve({
                    file: blob,
                    url: URL.createObjectURL(blob)
                  })
                },
                type
              )
            }
            catch (e) {
              deferred.reject()
            }
            return deferred.promise
          }

          function transform(params, draw) {
            if (img) {
              var filters = [Konva.Filters.Brighten, Konva.Filters.Contrast, Konva.Filters.Sharpness]
              scale = params.scale || Math.min(hcw/hiw, hch/hih, 1)
              if (!params.scale) {
                // since this value can only be calculated inside the directive, we must propagate the value to the
                // parent component
                $scope.filters.scale = scale
                $scope.filters.fitScale = scale
              }
              dx = params.dx || 0
              dy = params.dy || 0
              angle = params.angle || 0

              image.clearCache()
              image.cache()
              layer.rotation(angle)
              image.brightness(params.brightness || 0)
              image.contrast(params.contrast || 0)
              image.sharpness(params.sharpness ? [params.sharpness.strength || 0, params.sharpness.mix || 0] : [0, 0])
              if (params.invert) {
                filters.push(Konva.Filters.Invert)
              }
              image.filters(filters)
              image.x(hcw - hiw)
              image.y(hch - hih)
              measurements.x(hcw - hiw)
              measurements.y(hch - hih)
              layer.offsetX(hcw)
              layer.offsetY(hch)
              layer.x(hcw + dx)
              layer.y(hch + dy)
              layer.scale({x:scale, y:scale})
              stage.height(ch)
              stage.width(cw)
              if (draw !== false) {
                stage.batchDraw()
              }
            }
          }

          function getPointerPosition() {
            return image.getAbsoluteTransform().copy().invert().point(stage.getPointerPosition())
          }

          function MeasurementHandlerHitFunc(ctx) {
            ctx.beginPath()
            ctx.arc(0, 0, this.getRadius() + 5, 0, Math.PI * 2, true)
            ctx.closePath()
            ctx.fillStrokeShape(this)
          }

          function buildMeasurement(points) {
            if (points.length !== 2 && points.length !== 4) {
              Konva.Util.error(
                "Measurement require and array with the coordinates of one or two points: [x0, y0] or [x0, y0, y0, y1]"
              )
            }
            var measurement = new Konva.Group({
              name:"measurement",
              points: points,
            })
            var start = new Konva.Circle({
              name:"start",
              radius:1,
              stroke: "yellow",
              shadowBlur: 3,
              shadowColor: "black",
              x:points[0],
              y:points[1],
              hitFunc: MeasurementHandlerHitFunc,
            })
            var line = new Konva.Line({
              name:"line",
              strokeWidth: 1.2,
              stroke: "yellow",
              shadowBlur: 3,
              shadowColor: "black",
              visible: false,
            })
            var end = new Konva.Circle({
              name:"end",
              radius:1,
              stroke: "yellow",
              shadowBlur: 3,
              shadowColor: "black",
              hitFunc: MeasurementHandlerHitFunc,
              visible: false,
            })
            // TODO jjlorenzo: ensure that text fit the line width
            var text = new Konva.TextPath({
              name: "text",
              fontSize: "14",
              fontFamily: "Helvetica",
              fontStyle: "bold",
              align: "center",
              fill: "yellow",
              shadowBlur: 3,
              shadowColor: "black",
            })
            if (points.length === 4) {
              line.setAttrs({
                points: points,
                visible: true,
              })
              end.setAttrs({
                x:points[2],
                y:points[3],
                visible: true,
              })
              text.setAttrs({
                text: calcDistance(points).toString(),
                data: getPathData(points),
                visible: true,
              })
            }
            measurement.add(line)
            measurement.add(text)
            measurement.add(start)
            measurement.add(end)
            return measurement
          }

          function getPixelsDistance(points) {
            return Math.sqrt(Math.pow((points[2] - points[0]), 2) + Math.pow((points[3] - points[1]), 2))
          }

          function calcDistance(points) {
            var pixels = getPixelsDistance(points)
            var unit
            var value
            if ($scope.caliper && $scope.caliper.points && $scope.caliper.mm) {
              unit = "mm"
              value = pixels * $scope.caliper.mm / getPixelsDistance($scope.caliper.points)
            }
            else {
              unit = "px"
              value = pixels
            }
            return {
              unit: unit,
              value: value,
              toString: function() {
                return value.toFixed(2) + unit
              }
            }
          }

          function getPathData(points) {
            return "M" + points[0] + "," + points[1] + " " + points[2] + "," + points[3]
          }

          Konva.Factory.addGetterSetter(
            Konva.Node,
            "sharpness",
            [0, 0],
            Konva.Validators.getNumberArrayValidator(),
            Konva.Factory.afterSetFilter
          )

          Konva.Filters.Sharpness = function(imageData) {
            var sharpness = this.sharpness() // [amount, mix]

            var source = [0, 0, 0, 0, 1, 0, 0, 0, 0]
            var edge = [0, -1, 0, -1, 4, -1, 0, -1, 0]

            var amount = sharpness[0]
            var mix = sharpness[1]

            if (amount > 0 && mix > 0) {
              var kernel = []
              for (var i = 0; i < source.length; i++) {
                kernel.push(source[i] + edge[i] * amount)
              }
              Konva.Filters.Sharpness.convolution(imageData, kernel, sharpness[1])
            }
          }

          Konva.Filters.Sharpness.convolution = function(imageData, kernel, mix) {
            //put image data into destination and set up variables
            //creates a new imageData with same dimensions but does NOT copy contents
            // see https://taylorpetrick.com/blog/post/convolution-part3
            var
              dstData = document.createElement('canvas').getContext('2d').createImageData(imageData).data,
              dstW = imageData.width,
              // dstH = imageData.height,
              srcData = imageData.data,
              srcH = imageData.height,
              srcW = imageData.width;

            //srcData = new Uint8ClampedArray(dstData);

            var
              side = Math.round(Math.sqrt(kernel.length)),
              halfSide = (side * 0.5) | 0,
              //set up iterators
              x,
              y = srcH;

            //walk back height of destination
            while (y--) {
              x = srcW;
                //for each height level in destination, walk back dwidth of destination
              while (x--) {
                var srcY = y,
                    srcX = x,
                    dstOff = (y * dstW + x) * 4,
                    r = 0,
                    g = 0,
                    b = 0,
                    a = 0;
                //iterate throught the convolutional kernel centered on (srcX, srcY)
                for (var convY = 0; convY < side; convY++) {
                  for (var convX = 0; convX < side; convX++) {
                    //locate pixel in source image that convolutioanl weight overlays.
                    var sConvY = srcY + convY - halfSide;
                    var sConvX = srcX + convX - halfSide;

                    //make sure convolutional matrix entirely overlays image source
                    if (sConvY >= 0 && sConvY < srcH && sConvX >= 0 && sConvX < srcW) {

                      var srcOff = (sConvY * srcW + sConvX) * 4;
                      var weight = kernel[convY * side + convX];

                      r += srcData[srcOff] * weight;
                      g += srcData[srcOff + 1] * weight;
                      b += srcData[srcOff + 2] * weight;
                      a += srcData[srcOff + 3] * weight;
                    }
                  }
                }

                dstData[dstOff] = r * mix + srcData[dstOff] * (1 - mix);
                dstData[dstOff + 1] = g * mix + srcData[dstOff + 1] * (1 - mix);
                dstData[dstOff + 2] = b * mix + srcData[dstOff + 2] * (1 - mix)
                dstData[dstOff + 3] = srcData[dstOff + 3];


              }
            }

            imageData.data.set(dstData);
          }

          loadImage($scope.archive).then(
            function(value) {
              img = value
              transform($scope.filters, false)
              angular.forEach($scope.measurements, function(measure) {
                measurements.add(buildMeasurement(measure))
              })
              stage.batchDraw()
              $scope.loading = false
              $scope.register({ api: {
                image: image,
              }})
            },
            function() {
              img = null
              $scope.loading = null
            }
          )
        })
      }
    }
  }

})();

/*jshint unused: false */
(function() {
  'use strict';

  function billingInvoicesDetailCtrl($scope, $transition$, AuthenticationService, TreatmentsService,
                                     BillingService, $uibModal, PatientsService, $q, PrincipalService, NotificationService, $window, $state,CanCreateInvoice,CanRetrieveEncounter) {

		$scope.patientId = $transition$.params().patientid;
		$scope.invoiceId = $transition$.params().invoiceid;
    $scope.isNewInvoice = true;
    $scope.CanCreateInvoice = CanCreateInvoice;
    $scope.CanRetrieveEncounter = CanRetrieveEncounter;

		$scope.open = function($event) {
		  $event.preventDefault();
		  $event.stopPropagation();
		  $scope.opened = true;
		};

    var unbindEventWatcher = $scope.$watch('arJournalEntryDetails', function(newVal, oldVal) {
			var val;
			if (newVal) {
				var invoiceTotal = 0;
				for (var i = 0; i < newVal.length; i++) {
          if (
            newVal[i].type.indexOf(BillingService.AR_TYPE.INVOICELINEITEM) !== -1
          ) {
  					invoiceTotal = invoiceTotal + parseFloat(newVal[i].journal_entry_detail.amount);
          }
				}
				$scope.invoiceTotal = BillingService.getAmountToFixed(invoiceTotal);
			}
        }, true);

		$q.all([
			PatientsService.get($scope.patientId),
			AuthenticationService.getPractice(PrincipalService.getPracticeId())
		]).then(function(responses) {
			$scope.patient = angular.fromJson(responses[0].data);
			$scope.practice = angular.fromJson(responses[1].data);
      return  BillingService.getAccount($scope.patient.ar_gl_account);
		}, function(err) {
      NotificationService.error('Error loading  patient or practice information',err);
		}).then(function(response) {
			$scope.arAccount = angular.fromJson(response.data);
			reset();
		}, function(err) {
      NotificationService.error('Error loading patient AR account information',err);
		});

    function reset() {
      if (!$scope.invoiceId) {
        TreatmentsService.allIsAr($scope.patientId, 'false', $transition$.params().treatmentsToInvoice).then(function(data){
          //returns treatments for patient that haven't been assigned to any ar_journal_entry_details
          // and are status doing or done.
          $scope.arJournalEntryDetails = [];
          var treatmentsNotAr = angular.fromJson(data.data);

          //create array of unique provider ids along with separate array of corresponding service promises
          var providerIdArray = [];
          var providerAccountRequestPromiseArray = [];
          for (var d = 0; d < treatmentsNotAr.length; d++) {
            if (providerIdArray.indexOf(treatmentsNotAr[d].provider) === -1) {
              providerIdArray.push(treatmentsNotAr[d].provider);
              providerAccountRequestPromiseArray.push(AuthenticationService.getUserAccount(treatmentsNotAr[d].provider));
            }
          }
          $q.all(providerAccountRequestPromiseArray)
          .then(function(responses) {

            //create associative array of providerIds to treatment revenue accounts
            var providerIdToTreatmentRevenueGlAccountMap = {};
            for (var m = 0; m < responses.length; m++) {
              var userAccountInfo = angular.fromJson(responses[m].data);
					  	if (userAccountInfo.treatment_revenue_gl_account) {
                providerIdToTreatmentRevenueGlAccountMap[providerIdArray[m]] = userAccountInfo.treatment_revenue_gl_account;
					  	} else {
                NotificationService.error('Error determining treatment revenue account for username ' + userAccountInfo.username + ' because user account is not a provider and does not have a treatment revenue account');
                return;
					  	}
            }

            //for each treatment not ar, create and add a arjed that contains a partnerJed
				    for (var i = 0; i < treatmentsNotAr.length; i++) {
              if (!treatmentsNotAr[i].updated_in_update_noninvoiced_treatments) {
                continue; //ignore treatments not included in fees calculation. UI should already limit selection to exclude these.
              }
              var fee = parseFloat(treatmentsNotAr[i].amount_fee);
              var amountIns = null; //null denotes not insurance
              if (treatmentsNotAr[i].insurance_applied) {
                amountIns = parseFloat(treatmentsNotAr[i].amount_ins); //this value should always be non-null for insurance_applied. Leave parseFloat to exception if backend has bug and has insurance_applied but amount_ins null.
                if (treatmentsNotAr[i].other_applied) {
                  amountIns += parseFloat(treatmentsNotAr[i].amount_other);
                }
              }
              var amountRevenue = parseFloat(treatmentsNotAr[i].amount_revenue_to_post);
              var amountInsDiscount = amountRevenue - fee;
              /*
              if (treatmentsNotAr[i].insurance_applied && treatmentsNotAr[i].is_insurance_innetwork) {
                fee = parseFloat(treatmentsNotAr[i].amount_innetwork_fee);
                amountIns = fee - parseFloat(treatmentsNotAr[i].amount_patient);

              } else if (treatmentsNotAr[i].insurance_applied) {
                fee = parseFloat(treatmentsNotAr[i].amount_outnetwork_fee);
                amountIns = fee - parseFloat(treatmentsNotAr[i].amount_patient);
              } else { // insurance not applied
                fee = parseFloat(treatmentsNotAr[i].amount_patient);
              }
              */
              var d = new Date(treatmentsNotAr[i].treatment_date);
              d.setHours(d.getHours() + d.getTimezoneOffset()/60);
              var description = treatmentsNotAr[i].procedure_code + ' - ' +
                treatmentsNotAr[i].procedure_name + ' - ' +
                ((treatmentsNotAr[i].tooth_numbers.trim().length > 0) ? 'Th #' + treatmentsNotAr[i].tooth_numbers + ' - ' : '') +
                d.toLocaleDateString();

              var descriptionCondensed = treatmentsNotAr[i].procedure_code + ' - ' +
                ((treatmentsNotAr[i].tooth_numbers.trim().length > 0) ? 'Th #' + treatmentsNotAr[i].tooth_numbers + ' - ' : '') +
                d.toLocaleDateString();

              BillingService.addArToArjeds(
                $scope.arJournalEntryDetails,
                description,
                '',         //memo
                -amountRevenue,
                $scope.practice.currency,
                $scope.patient.ar_gl_account,
                BillingService.AR_TYPE.REVENUEINVOICELINEITEM,
                providerIdToTreatmentRevenueGlAccountMap[treatmentsNotAr[i].provider], //partnerArGlAccount
                false,      //isPartnerGlAccountAr
                null,       // partnerArType
                treatmentsNotAr[i],
                null        //claim
              );

              if ((!treatmentsNotAr[i].insurance_applied || !$scope.patient.insurance_plan_info) &&  amountInsDiscount > 0.00) {
                NotificationService.error('Error creating invoice: treatment amount of revenue to post is different from fee yet insurance has not been applied');
              } else if (amountInsDiscount > 0.00) {
                var arjedAdded = BillingService.addArToArjeds(
                  $scope.arJournalEntryDetails,
                  'Insurance Discount for  ' + descriptionCondensed,
                  '',         //memo
                  amountInsDiscount,
                  $scope.practice.currency,
                  $scope.patient.ar_gl_account,
                  BillingService.AR_TYPE.INSDISCOUNTREVENUEINVOICELINEITEM,
                  $scope.practice.insurance_discount_gl_account, //partnerArGlAccount
                  false,      //isPartnerGlAccountAr
                  null,       // partnerArType
                  treatmentsNotAr[i],
                  null        //claim
                );
                //add the insurance payer to the discount writeoff jed
                arjedAdded.partnerJed.data = angular.toJson({payer_id: $scope.patient.insurance_plan_info.payer_id});
              }

              //for each treatment that has an amount covered by insurance,
              // create and add a arjed that contains a partnerArjed
						  if (amountIns || amountIns === 0) {//null denotes insurance_applied is false for this treatment. Otherwise always create journal entry even if it is zero
                /*
                  When submitted to back end as a part of a new invoice, a claim line item results in:
                                  je
                      |                           |
                    jed                           jed
                      gl_account=patientAR              gl_account=insuranceAR account
                      amount = -amountIns  amount = amountIns
                      |                           |
                    arjed                         ajed
                      type= claiminvoicelineitem    type=claimee
                      treatment=treatment           treatment=treatment
                      invoice=created invoice
                */
                BillingService.addArToArjeds(
                  $scope.arJournalEntryDetails,
                  'Estimated Insurance Payment for ' + descriptionCondensed, //description
                  '',         //memo
                  parseFloat(amountIns),
                  $scope.practice.currency,
                  $scope.patient.ar_gl_account,
                  BillingService.AR_TYPE.CLAIMERINVOICELINEITEM,    //arType
                  $scope.practice.insurance_ar_gl_account,          //partnerArGlAccount
                  true,                                             //isPartnerGlAccountAr
                  BillingService.AR_TYPE.CLAIMEE,                   // partnerArType
                  treatmentsNotAr[i],
                  null                                              //claim
                );
						  }
				    } //end for
				    $scope.invoice = {};
				    $scope.invoice.invoice_date = moment().format('YYYY-MM-DD');
				    $scope.isNewInvoice = true;
          }, function(err) {
            NotificationService.error('Error loading provider account information',err);
          });
			  }, function(err) {
          NotificationService.error('Error loading treatments not AR',err);
			  });
      } else {
		    BillingService.getInvoice($scope.invoiceId).then(function(data){
		      $scope.invoice = angular.fromJson(data.data);
		      $scope.isNewInvoice = false;
		      $scope.arJournalEntryDetails = $scope.invoice.ar_journal_entry_details;
		    }, function(err) {
          NotificationService.error('Error loading invoice',err);
	      });
      }
    } // end reset()

    $scope.createInvoice = function() {
      $scope.invoice.journal_entries = BillingService.getJesFromArjeds($scope.arJournalEntryDetails);
   	  BillingService.createInvoiceForAccount($scope.patient.ar_gl_account, $scope.invoice).then(function(data){
			  //$scope.isNewInvoice = false;
			  var invoice = angular.fromJson(data.data);
			  //$scope.invoiceId = invoice.id;
			  //reset();
        $state.go('invoicedetail',{invoiceid:invoice.id}, {location:'replace'});
		  }, function(err) {
          NotificationService.error('Error creating invoice',err, 'Re-initializing invoice line items.');
          reset();
		  });
    }; //end create()

    $scope.createInvoiceClick = function() {
      swal({
        title: 'Everything correct?',
        text: [
          "Creating an invoice from these line items will create journal entries and set data on each treatment that",
          "cannot be subsequently edited or deleted. Corrections after invoicing will require correcting entries, and some analytics",
          "reports may not reflect such corrections. Please verify that the invoice's line items and amounts are correct before continuing."
        ].join(" "),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Create invoice',
        closeOnConfirm: true,
        reverseButtons: true,
        allowOutsideClick: false,
        allowEnterKey: false,
      }, function () {
        $scope.createInvoice();
      });
    };


    $scope.removeFromArJournalEntryDetails = function(arjed) {
        $scope.arJournalEntryDetails.splice($scope.arJournalEntryDetails.indexOf(arjed), 1);
    };

	  $scope.printInvoice = function() {
		  $window.open('/api/v1/invoices/' + $scope.invoiceId + '/print/', '_blank');
	  };


    /* ADD TO INVOICE
      When submitted to back end as a part of a new invoice, taxes, deposits, and discounts result in:
                      je
          |                           |
        jed                           jed
          gl_account=patient AR         gl_account= taxes, deposit, or discount contra revenue account
          amount = amt                  amount = -amt
          |
        arjed
          type = misinvoicelineitem
          invoice=created invoice
    */
    $scope.openAddToInvoiceModal = function (title, description, patientArGlAccountId,
      toGlAccountId, currency, arJournalEntryDetails, addAmountToPatientArGlAccount)
    {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'addToInvoice.html',
        controller: 'addToInvoiceModalInstanceCtrl',
        size: 'sm',
        resolve: {
          currency: function () {
            return currency;
          },
          description: function () {
            return description;
          },
		      title: function () {
  			    return title;
		      }
        }
      });

      modalInstance.result.then(function (newJournalEntryInfo) {
		    var amountToSelf = 0;
		    if (addAmountToPatientArGlAccount) {
			    amountToSelf = parseFloat(newJournalEntryInfo.amount);
		    } else {
			    amountToSelf = - parseFloat(newJournalEntryInfo.amount)	;
		    }

        BillingService.addArToArjeds(
          $scope.arJournalEntryDetails,
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo,
          - amountToSelf,
          currency,
          patientArGlAccountId,
          BillingService.AR_TYPE.MISCINVOICELINEITEM,       //arType
          toGlAccountId,                                //partnerArGlAccount
          false,                                        //isPartnerGlAccountAr
          null,                                         // partnerArType
          null,
          null                                              //claim
        );
      });
    }; // end openAddToInvoiceModal()

    // ADD CLAIM TO INVOICE
    $scope.openAddClaimToInvoiceModal = function (title, description,
      patientArGlAccountId, toGlAccountId, currency, treatment, arJournalEntryDetails)
    {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'addClaimToInvoice.html',
        controller: 'addClaimToInvoiceModalInstanceCtrl',
        size: 'sm',
        resolve: {
          currency: function () {
            return currency;
          },
          description: function () {
            return description;
          },
		      title: function () {
			      return title;
		      }
        }
      });

      modalInstance.result.then(function (newJournalEntryInfo) {
        /*
          When submitted to back end as a part of a new invoice, a claim line item results in:
                          je
              |                           |
            jed                           jed
              gl_account=patientAR          gl_account=insuranceAR account
              amount = -amt                 amount = amt
              |                           |
            arjed                         ajed
              type= claiminvoicelineitem    type=claimee
              treatment=treatment           treatment=treatment
              invoice=created invoice
        */
        BillingService.addArToArjeds(
          arJournalEntryDetails,
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo,
          parseFloat(newJournalEntryInfo.amount),
          currency,
          patientArGlAccountId,
          BillingService.AR_TYPE.CLAIMERINVOICELINEITEM,    //arType
          toGlAccountId,                                    //partnerArGlAccount
          true,                                             //isPartnerGlAccountAr
          BillingService.AR_TYPE.CLAIMEE,                   // partnerArType
          treatment,
          null                                              //claim
        );
      });
    }; // end openAddClaimToInvoiceModal()


  }

  function addToInvoiceModalInstanceCtrl($scope, $uibModalInstance, currency, title, description) {
	  $scope.currency = currency;
	  $scope.title = title;
	  $scope.newJournalEntryInfo = {};
	  $scope.newJournalEntryInfo.description = description;
    $scope.ok = function () {
      $uibModalInstance.close($scope.newJournalEntryInfo);
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }

  function addClaimToInvoiceModalInstanceCtrl($scope, $uibModalInstance, currency, title, description) {
	  $scope.currency = currency;
	  $scope.title = title;
	  $scope.newJournalEntryInfo = {};
	  $scope.newJournalEntryInfo.description = description;
    $scope.ok = function () {
      $uibModalInstance.close($scope.newJournalEntryInfo);
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }

  angular.module('common.billing-invoices-detail', [])
    .controller('BillingInvoicesDetailCtrl', billingInvoicesDetailCtrl)
    .controller('addClaimToInvoiceModalInstanceCtrl', addClaimToInvoiceModalInstanceCtrl)
    .controller('addToInvoiceModalInstanceCtrl', addToInvoiceModalInstanceCtrl);
})();

/*jshint unused: false */
(function() {
  'use strict';

  function billingClaimsDetailCtrl($scope, $log, $transition$, BillingService,
    $uibModal, AuthenticationService, PatientsService, $q, PrincipalService, LocationsService, $state, NotificationService,
                                   CanCreateClaim,CanUpdateClaim)
  {
    $scope.CLAIM_OPERATIONS = BillingService.CLAIM_OPERATIONS;
    $scope.CLAIM_STATES = BillingService.CLAIM_STATES;

    $scope.claimOtherCoverage = false;     //true directs to claim other-coverage instead of primary insurance
    $scope.actionActive = false;

		$scope.claimId = $transition$.params().claimid;
		$scope.patientId = $transition$.params().patientid;
		$scope.treatingProviderId = $transition$.params().treatingproviderid;
    $scope.locationId = $transition$.params().locationid;

    $scope.CanCreateClaim = CanCreateClaim;
    $scope.CanUpdateClaim = CanUpdateClaim;

    $scope.claim = {};

		$scope.open = function($event) {
		  $event.preventDefault();
		  $event.stopPropagation();
		  $scope.opened = true;
		};

    var unbindEventWatcherTreatments = $scope.$watch(
      'claim.submitted_claim.services.treatments',
      function(newVal, oldVal)
      {
			  var val;
			  if (newVal) {
          var feesTotal = 0.0;
          if (oldVal === '' || oldVal) { // let the other_fees watcher add other_fees in to feesTotal the first time (where oldVal == null)
            feesTotal = parseInt($scope.claim.submitted_claim.services.other_fees);
          }
          var arAmountTotal = 0.0;
				  for (var i = 0; i < newVal.length; i++) {
					  feesTotal = feesTotal + parseFloat(newVal[i].fee);
					  arAmountTotal = arAmountTotal + parseFloat(newVal[i].arAmount);
				  }
				  $scope.feesTotal = feesTotal;
				  $scope.arAmountTotal = arAmountTotal;
			  }
      },
      true
    );

    var unbindEventWatcherOtherFees = $scope.$watch(
      'claim.submitted_claim.services.other_fees',
      function(newVal, oldVal) {
			  var val;
			  if (newVal === '' && oldVal) {
				  $scope.feesTotal = $scope.feesTotal - parseFloat(oldVal);
        } else if (newVal && oldVal) {
				  $scope.feesTotal = $scope.feesTotal - parseFloat(oldVal) + parseFloat(newVal);
        } else if (newVal && !oldVal) {
				  $scope.feesTotal = $scope.feesTotal + parseFloat(newVal);
        }
      },
      true
    );

	  function reset() {
		  if (!$scope.claimId) {
        $q.all([
	        PatientsService.get($scope.patientId),
	        LocationsService.all(),//get($scope.locationId),
          AuthenticationService.getUserAccount($scope.treatingProviderId),
          AuthenticationService.getPractice(PrincipalService.getPracticeId())
   	    ]).then(function(responses) {
	        $scope.patient = angular.fromJson(responses[0].data);
	        var locations = angular.fromJson(responses[1].data);
          $scope.treatingProvider = angular.fromJson(responses[2].data);
          $scope.practice = angular.fromJson(responses[3].data);
          for (var d=0; d<locations.length; d++) {
            if (locations[d].id === parseInt($scope.locationId)) {
              $scope.treatmentsLocation = locations[d];
            }
            if (locations[d].is_billing_location) {
              $scope.billingLocation = locations[d];
            }
          }
          return $q.all([
            BillingService.getClaimeeArJournalEntryDetailsForPatient($scope.practice.insurance_ar_gl_account, $scope.patientId, true),
          ]);
        }, function(err) {
          NotificationService.error('Error Loading initialization information',err);
        }).then(function(responses) {
          var arJournalEntryDetails = angular.fromJson(responses[0].data);
			    $scope.claim = {};
			    $scope.claim.claim_date = moment().format('YYYY-MM-DD');
          $scope.claim.patient = $scope.patientId;
			    $scope.isNewClaim = true;
          $scope.claim.state = 'new';
          $scope.claim.ar_journal_entry_details = [];
          var treatments = [];
          for (var l = 0; l < arJournalEntryDetails.length; l++) {
            //claim can only include treatments for one patient, one treating provider, and one location combination.
            if (
              arJournalEntryDetails[l].treatment.provider.id === parseInt($scope.treatingProviderId) &&
              arJournalEntryDetails[l].treatment.location === parseInt($scope.treatmentsLocation.id)
            ) {
              var treatment = arJournalEntryDetails[l].treatment;
              //treatment.ar_journal_entry_detail_id = arJournalEntryDetails[l].journal_entry_detail.id;
              $scope.claim.ar_journal_entry_details.push(arJournalEntryDetails[l].journal_entry_detail.id);
              treatment.arAmount = arJournalEntryDetails[l].journal_entry_detail.amount;
              treatments.push(treatment);
            }
          }
          if (!$scope.patient.insurance_plan_info) {
            NotificationService.warning('Cannot construct claim: patient profile insurance plan is not set');
            return;
          }
          $scope.claim.submitted_claim =  BillingService.mapToSubmitClaimApi(
            $scope.patient,
            $scope.practice,
            $scope.billingLocation,
            $scope.treatingProvider,
            $scope.treatmentsLocation,
            treatments
          );
          $scope.isNewClaim = true;
		    }, function(err) {
          NotificationService.error('Error Loading journal entries pertaining to claim.',err);
		    });
		  } else {

        $q.all([
          BillingService.getClaim($scope.claimId),
          AuthenticationService.getPractice(PrincipalService.getPracticeId())
        ]).then(function(responses) {
          $scope.claim = responses[0].data;
          $scope.practice = responses[1].data;
				  $scope.isNewClaim = false;
          $scope.claim.submitted_claim = angular.fromJson($scope.claim.submitted_claim);
        }, function(err) {
          NotificationService.error('Error Loading claim or practice information',err);
        });
      }
	  }
    reset();


    $scope.save = function(isCreate) {
      /* Luis fixme
      if($scope.myOuterForm.$invalid){
        return NotificationService.warning('Please fill all required fields');
      }
      */
      $scope.actionActive = true;
     
      $scope.claim.submitted_claim = angular.toJson($scope.claim.submitted_claim);

      if (isCreate) {
      	BillingService.createClaimForAccount($scope.practice.insurance_ar_gl_account, $scope.claim).then(function(data){
          var claim = angular.fromJson(data.data);
          $state.go('claimdetail',{claimid:claim.id}, {location:'replace'});
        }, function(err) {
          $scope.claim.submitted_claim = angular.fromJson($scope.claim.submitted_claim);
        	$log.debug('Error creating claim. Error reports: ' + err);
          NotificationService.error('Error Creating Claim.',err);
          $scope.actionActive = false;
        });
      } else {
      	BillingService.updateClaim($scope.claim.id, $scope.claim).then(function(data){
          $scope.claim = angular.fromJson(data.data);
          $scope.claim.submitted_claim = angular.fromJson($scope.claim.submitted_claim);
          NotificationService.success('Successfully updated claim.');
          $scope.actionActive = false;
        }, function(err) {
          $scope.claim.submitted_claim = angular.fromJson($scope.claim.submitted_claim);
      	  $log.debug('Error updating claim. Error reports: ' + err);
          NotificationService.error('Error Updating claim.',err);
          $scope.actionActive = false;
        });
      }
    };

    var resultingAction = function(newState, operation) {
      switch (operation) {
        case BillingService.CLAIM_OPERATIONS.MODEL__CREATE:
          $scope.save(true);
          break;
        case BillingService.CLAIM_OPERATIONS.MODEL__UPDATE:
          $scope.save(false);
          break;
        case BillingService.CLAIM_OPERATIONS.SUBMIT__CLAIM_INSURANCE:
        case BillingService.CLAIM_OPERATIONS.SUBMIT__CLAIM_INSURANCE_MANUAL:
        case BillingService.CLAIM_OPERATIONS.SUBMIT__VOID_INSURANCE_CLAIM:
        case BillingService.CLAIM_OPERATIONS.SUBMIT__VOID_INSURANCE_CLAIM_MANUAL:
        case BillingService.CLAIM_OPERATIONS.SUBMIT__CORRECT_INSURANCE_CLAIM:
        case BillingService.CLAIM_OPERATIONS.SUBMIT__CLAIM_OTHER_COVERAGE:
        case BillingService.CLAIM_OPERATIONS.SUBMIT__CLAIM_OTHER_COVERAGE_MANUAL:
        case BillingService.CLAIM_OPERATIONS.SUBMIT__VOID_OTHER_COVERAGE_CLAIM:
        case BillingService.CLAIM_OPERATIONS.SUBMIT__VOID_OTHER_COVERAGE_CLAIM_MANUAL:
        case BillingService.CLAIM_OPERATIONS.SUBMIT__CORRECT_OTHER_COVERAGE_CLAIM:

        case BillingService.CLAIM_OPERATIONS.RECEIVE__INSURANCE_EOB:
        case BillingService.CLAIM_OPERATIONS.RECEIVE__OTHER_COVERAGE_EOB:

        case BillingService.CLAIM_OPERATIONS.MARK__COMPLETED:

          $scope.actionActive = true;
          BillingService.submitClaim($scope.claim.id, operation).then(function(data){
            $scope.claim = angular.fromJson(data.data);
            $scope.claim.submitted_claim = angular.fromJson($scope.claim.submitted_claim);
            NotificationService.success('Action successful on claim. See events for more information.');
            $scope.patientDetailInsuranceReset();
            $scope.actionActive = false;
          }, function(err) {
            var data = angular.fromJson(err.data);
            $scope.claim = data.claim;
            $scope.claim.submitted_claim = angular.fromJson($scope.claim.submitted_claim);
            $log.debug('Error processing claim. Error reports: ' + err.detail);
            NotificationService.error('Action resulted in errors. See events for more information');
            $scope.actionActive = false;
          });
          $log.log(operation);
          break;
        //case BillingService.REMITTANCE__ACCEPT_PAYMENT:
        //    break;
        //case BillingService.REMITTANCE__WRITEOFF:
        //    break;
        default:
            console.log('DON\'T RECOGNIZE COMMAND');
      }
    };

    $scope.operationValidForCurrentState = function(objWithStateProperty, operation) {
      return BillingService.CLAIM_OPERATIONS.transition(objWithStateProperty.state, operation);
    };

    $scope.executeOperationOnCurrentState = function(objWithStateProperty, operation) {
      function perform() {
        BillingService.CLAIM_OPERATIONS.transition(objWithStateProperty.state, operation, resultingAction)
      }
      if (operation === BillingService.CLAIM_OPERATIONS.MARK__COMPLETED) {
        return $uibModal.open({templateUrl: "#billing-claims-detail-confirm-complete"}).result.then(perform)
      }
      if (operation === BillingService.CLAIM_OPERATIONS.SUBMIT__CLAIM_INSURANCE_MANUAL) {
        return $uibModal.open({templateUrl: "#billing-claims-detail-confirm-submit-manually"}).result.then(perform)
      }
      if (operation === BillingService.CLAIM_OPERATIONS.SUBMIT__CLAIM_OTHER_COVERAGE_MANUAL) {
        return $uibModal.open({templateUrl: "#billing-claims-detail-confirm-submit-manually"}).result.then(perform)
      }
      perform()
    };

	  $scope.removeFromTreatments = function(treatment) {
      var treatmentIndex = $scope.claim.submitted_claim.services.treatments.map(function(o) {return o.id;}).indexOf(treatment.id);
			$scope.claim.submitted_claim.services.treatments.splice(treatmentIndex, 1);
      $scope.claim.ar_journal_entry_details.splice(treatmentIndex, 1);
	  };

    $scope.openAddPaymentModal = function (jed) {

      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'addClaimPayment.html',
        controller: 'addClaimPaymentModalInstanceCtrl',
        size: 'sm',
        resolve: {
          currency: function () {
            return $scope.arAccount.currency;
          },
          jed: function () {
            return jed;
          }
        }
      });
    };

  }

  angular.module('common.billing-claims-detail', [])
  .controller('BillingClaimsDetailCtrl', billingClaimsDetailCtrl);
})();

/*jshint camelcase: false */
(function () {
  'use strict';

  function navbarCtrl($scope,
    $http,
    $location,
    $sessionStorage,
    $rootScope,
    $log,
    $state,
    $uibModal,
    $uibModalStack,
    $window,
    AuthorizationService,
    NotificationService,
    PrincipalService,
    PatientService,
    SupportService,
    WebsocketService
  ) {

    $scope.isSmallWidth = $window.innerWidth <= 1254;
    angular.element($window).bind('resize', function () {
      $scope.isSmallWidth = $window.innerWidth <= 1336;
      // manuall $digest required as resize event
      // is outside of angular
      $scope.$digest();
    });

    $scope.signout = function () {
      PrincipalService.authenticate(null);
      $http.post('/api/v1/auth/logout/', {})
        .then(function () {
          $state.go('signout');
          NotificationService.success('You have been successfully logged out');
        }, function () {
          //silent continue to the login screen if an error occurs
          $state.go('signout');
        });
    };
    $scope.isAuthenticated = PrincipalService.isAuthenticated();
    $scope.username = PrincipalService.getUsername();
    $scope.avatarHash = "b58996c504c5638798eb6b511e6f49af"; /*CryptoJS.MD5(PrincipalService.getUsername().trim().toLowerCase()).toString();*/
    $scope.isLoginDropdownLock = false;
    $scope.CanCreatePatient = AuthorizationService.hasFrontendPermission("CanCreatePatient");
    $scope.isHardReloadButtonClicked = false;
    $scope.hasNewSupportMessages = false;
    $scope.$state = $state;
    function resetHasNewSupportMessages() {
      SupportService.allUnreadMessagesForUser().then(function (data) {
        $scope.hasNewSupportMessages = data.data.length > 0;
      }, function (err) {
        NotificationService.error('Error finding if unread support messages exist', err);
      });
    }
    resetHasNewSupportMessages();
    // setup socket observer to listen for new messages
    //begin websocket observer setup
    var scope = $scope;
    var strm = 'notification';
    var cmds_observed = [
      'model_create_support_models_message',
      'model_update_support_models_message',
      //'model_delete_support_models_message'
    ];
    scope.notificationObservableId = WebsocketService.observe(
      strm,
      cmds_observed,
      function (stream, cmd, params) {
        resetHasNewSupportMessages();
        $log.debug('Websocket message received in NAVBAR with ID: ' + scope.notificationObservableId + '; STREAM: ' + stream + '; CMD: ' + cmd + '; PARAMS: ' + JSON.stringify(params));
      }
    );
    $log.debug('Websocket observer registered in NAVBAR with ID: ' + scope.notificationObservableId + '; STREAM: ' + strm + '; CMDS_OBSERVED: ' + JSON.stringify(cmds_observed));
    $scope.$on('$destroy', function () {
      WebsocketService.unobserve(scope.notificationObservableId);
      $log.debug('Websocket observer unregistered in NAVBAR with ID: ' + scope.notificationObservableId);
    });
    //end websocket observer setup


    //workaround to get the login dropdown locked when a modal is open
    //Cause: top header has a greater z-index than modals and will be more time expensive to change this
    //at this moment
    $scope.$watch($uibModalStack.getTop, function (top) {
      if (top === undefined) {
        $scope.isLoginDropdownLock = false;
        return;
      }
      $scope.isLoginDropdownLock = true;
    });

    $scope.$on('lockAllMenus', function () {
      $scope.isScreenLock = true;
    });

    $scope.$on('unlockAllMenus', function () {
      $scope.isScreenLock = false;
    });

    $scope.$on('updatedUserAccount', function (event, user) {
      $scope.username = user.email;
    });

    $scope.logoclick = function () {
      if ($scope.isAuthenticated) {
        $state.go('schedule');
      } else {
        $state.go('splash');
      }
    };

    $scope.openForums = function () {
      $window.open('https://groups.google.com/forum/#!forum/dental-emr-endo', '_blank');
    };
    /*
    Variable for controlling the modal state
    */
    $state.isModalOpened = false;

    $state.openPatientCreateModal = function (handleModalCloseCallback) {
      $state.isModalOpened = true;

      $uibModal
        .open({
          controller: "PatientAddCtrl",
          resolve: {
            openPatientDetail: true,
            patientInfo: function () {
              return null;
            },
            showGoToPatientDetail: function () {
              return true;
            },
          },
          size: "lg",
          templateUrl: "src/common/patient-add.tpl.html",
        })
        .result.then(
          function () {
            $state.isModalOpened = false; // Set isModalOpened to false when submit button is clicked
            handleModalCloseCallback();
          },
          function () {
            $state.isModalOpened = false; // Set isModalOpened to false when modal is cancelled or dismissed
            handleModalCloseCallback();
          }
        );
    };

    /**
     * Show a lockScreen
     */
    $scope.lockScreen = function () {

      //Do a silent logout
      PrincipalService.authenticate(null);
      $scope.isScreenLock = true;

      $http.post('/api/v1/auth/logout/', {}).then(
        null,
        function (err) {
          NotificationService.error('Login out', err);
        }
      )

      //on login go to the same page
      $sessionStorage.redirectOnLogin = { "name": $rootScope.toState.name, "params": $rootScope.toStateParams }

      //flag used to not redirect to login and to not idle
      localStorage.setItem('inLockScreen', true);

      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'src/common/lock-screen.tpl.html',
        controller: 'SignInCtrl',
        backdrop: 'static',
        backdropClass: 'opaque',
        openedClass: 'modal-open modal-opaque',
        keyboard: false,
      });

      //close the modal on sign again
      $scope.$on('signinchange', function () {
        localStorage.setItem('inLockScreen', false);
        $scope.isScreenLock = false;
        $scope.isAuthenticated = PrincipalService.isAuthenticated();
        $scope.username = PrincipalService.getUsername();
        $scope.avatarHash = "b58996c504c5638798eb6b511e6f49af"; /*CryptoJS.MD5(PrincipalService.getUsername().trim().toLowerCase()).toString();*/
        modalInstance.close();
      });

      //close the modal on location change if the user didn't login it will redirect to the main login page
      $scope.$on('$locationChangeSuccess', function () {
        localStorage.setItem('inLockScreen', false);
        $scope.isScreenLock = false;
        modalInstance.close();
      }); 
    }

      $scope.logoclick = function () {
        if ($scope.isAuthenticated) {
          $state.go('schedule');
        } else {
          $state.go('splash');
        }
      };

      $scope.addSupportTicket = function () {
        var addSupportTicketModal = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'addSupportTicket.html',
          controller: 'addSupportTicketModalInstanceCtrl',
          size: 'medium',
        });

        addSupportTicketModal.result.then(function () {
        }, function () {
        });

      };
    }

  angular.module('common.navbar', [])
    .controller('NavbarCtrl', navbarCtrl)
})();

(function() {
  'use strict';

  angular.module('common.task-select', [])
    .controller('TaskSelectCtrl', TaskSelectCtrl);

  function TaskSelectCtrl(
    $scope,
    $state,
    PrincipalService,
    $transitions
  ) {

    $state.practice_id = PrincipalService.getPracticeId();
    $scope.$state = $state;
    $scope.$transitions = $transitions;

    // $scope.navigateIfMenuUnlocked = function(event) {
    //   if($scope.menuLocked) {
    //     event.preventDefault();
    //     swal({ß
    //       title:'',
    //       text:'Please complete the onboarding wizard first',
    //       type:'info'
    //     });
    //   }
    // };

    // $scope.$on('unlockAllMenus',function(){
    //   $scope.menuLocked = false;
    // });

    // $scope.$on('lockAllMenus',function(){
    //   $scope.menuLocked = true;
    // });
    
    $(document).on('click', '.popover-jumper-container  a[href^="#"]', function (event) {    
      var topMenuHeight = $(".jump-top").height();
      $('html, body').animate({

          scrollTop: $($.attr(this, 'href')).offset().top - topMenuHeight
      }, 1000);
      event.preventDefault();
    });


  }

})();

/*global _ */
/*jshint unused:false*/
(function () {
  'use strict';

  function scheduleCtrl(
    $compile,
    $log,
    $uibModal,
    $rootScope,
    $scope,
    $state,
    $window,
    $transition$,
    NotificationService,
    PracticeSettings,
    PrincipalService,
    ScheduleService,
    TimeblockService,
    SessionStorageService,
    CanRetrieveAppointment,
    CanCreateAppointment,
    CanRetrieveTimeBlock,
    CanCreateTimeBlock,
    CanUpdateAppointment,
    MultiLocationEnable,
    LocalStorageService
  ) {

    $log.debug('Schedule loaded')
    $scope.CanRetrieveAppointment = CanRetrieveAppointment;
    $scope.CanCreateAppointment = CanCreateAppointment;
    $scope.CanRetrieveTimeBlock = CanRetrieveTimeBlock;
    $scope.CanCreateTimeBlock = CanCreateTimeBlock;
    $scope.MultiLocationEnable = MultiLocationEnable;

    $scope.animationsEnabled = true;
    $scope.isBackgroundEditMode = $rootScope.isBackgroundEditMode;

    $scope.multiColumnView = (SessionStorageService.calendarViewType === 'multicolumn');

    $scope.multicolumnByProvider = SessionStorageService.multicolumnByProvider;

    $scope.colorBy = SessionStorageService.colorBy;

    $scope.currentDate = SessionStorageService.calendarCurrentDate;

    $scope.currentSystemTZ = moment.tz.guess();

    $scope.isFullScreen = false;

    $scope.setFullScreen = function () {
      $scope.isFullScreen = !$scope.isFullScreen;
    }
  
    // Tracks state the fullcalendar view is in.
    // if the mode is week or month, set it, otherwise mode is day, multicolumn or unset, so default to day.
    $scope.intervalUnit = ($transition$.params().mode === 'agendaWeek' || $transition$.params().mode === 'month') ? $transition$.params().mode : SessionStorageService.calendarIntervalUnit;

    $scope.toggleMultiColumn = function (isMulticolumn) {
      $scope.multiColumnView = isMulticolumn;
      //$scope.currentDate = SessionStorageService.calendarCurrentDate;
    };

    $scope.setMulticolumnByProvider = function (multicolumnByProvider) {

      $scope.$broadcast('updateMulticolumnByProvider', multicolumnByProvider);

    };

    $scope.editBckMode = function () {
      $scope.isBackgroundEditMode = !$scope.isBackgroundEditMode;
      $rootScope.isBackgroundEditMode =  $scope.isBackgroundEditMode;
      $scope.$broadcast('editBackgroundMode', $scope.isBackgroundEditMode);
    }

    $scope.setColorsBy = function (colorBy) {
      // colorBy valid values: provider, operatory, appointment_status
      $scope.colorBy = colorBy;

      $scope.$broadcast('colorEventBy', $scope.colorBy);
      $scope.$broadcast('timeblockUpdate');

      SessionStorageService.colorBy = $scope.colorBy;
    };

    // Used to update an appointment on the server (after drag and drop).
    $scope.updateAppointment = function (event, eventProps, callback) {
      var appointment = {
        complaint: eventProps.complaint,
        start_datetime: event.start,
        end_datetime: event.end,
        patient: eventProps.patient,
        provider: eventProps.provider,
        operatory: eventProps.operatory,
      };

      if (!PrincipalService.hasPermission('appointment_modify')) {
        return callback();
      }

      ScheduleService.update(event.id, appointment).then(
        function success() {
          if (callback) {
            callback();
          }
          NotificationService.success('Appointment updated');
        },
        function fail(err) {
          NotificationService.error('Error Updating appointment', err);
        });
    };

    $scope.combineDate = function (originalDate, startTime) {
      originalDate.setHours(0, 0, 0, 0);
      startTime.setFullYear(1970, 0, 1);
      
      var combinedDateTime = new Date(originalDate);
      combinedDateTime.setHours(startTime.getHours(), startTime.getMinutes(), startTime.getSeconds());

      return combinedDateTime
    }

    $scope.getUpdatedDuration = function (start, end) {
      var start_moment = moment(start)
      var end_moment = moment(end)

      return end_moment.diff(start_moment, 'minutes');
    }
    
    $scope.updateTimeblock = function (event, eventProps, callback) {
      var combinedDateTime = $scope.combineDate(eventProps.originalStartDate, event.start)  
      event.setExtendedProp('start', combinedDateTime)

      var timeblock = {
        start_datetime: combinedDateTime,
        end_datetime: event.end,
        provider: eventProps.provider,
        operatory: eventProps.operatory,
        duration: $scope.getUpdatedDuration(combinedDateTime, event.end)
      };

      if(eventProps.rrule) {
        var originalOptions = window.rrule.rrulestr(eventProps.rrule).origOptions
        var updatedTime = originalOptions.dtstart
        updatedTime.setHours( event.start.getHours())
        updatedTime.setMinutes( event.start.getMinutes())
        updatedTime.setSeconds( event.start.getSeconds())

        var rruleUpdated = Object.assign(
          {}, 
          originalOptions, 
          {
            dtstart: combinedDateTime
          })
        timeblock.rrule = new window.rrule.RRule(rruleUpdated).toString();
      }

      TimeblockService.update(event.id, timeblock).then(
        function success() {
          if (callback) {
            callback();
          }
          NotificationService.success('Timeblock updated');
        },
        function fail(err) {
          NotificationService.error('Error Updating timeblock', err);
        });
    };

    $scope.updateBackground = function (event, eventProps, callback) {
      var combinedDateTime = $scope.combineDate(eventProps.originalStartDate, event.start)  
      event.setExtendedProp('start', combinedDateTime)
      event.setExtendedProp('originalStartDate', combinedDateTime)

      var backgroundEvent = {
        start_datetime: combinedDateTime,
        end_datetime: event.end,
        provider: eventProps.provider,
        operatory: eventProps.operatory,
        duration: $scope.getUpdatedDuration(combinedDateTime, event.end),
        is_background: true,
      };

      if(eventProps.rrule) {
        var rruleUpdated = Object.assign(
          {}, 
          window.rrule.rrulestr(eventProps.rrule).origOptions, 
          {
            dtstart: combinedDateTime
          })
          backgroundEvent.rrule = new window.rrule.RRule(rruleUpdated).toString();
      }
      
      TimeblockService.update(event.id, backgroundEvent).then(
        function success() {
          if(callback) {
            callback();
          }
          NotificationService.success('Background updated successfully');
        },
        function fail(err) {
          NotificationService.error('Error Updating Background', err);
        });
  }


    $scope.openAddAppointmentModal = function (args) {
      $rootScope.$broadcast("closeIntro")
      var start = args.start ? args.start : "";
      var end = args.end ? args.end : "";
      var operatoryId = "";

      if(args.resource && args.resource.id) {
        if(typeof args.resource.id === 'string') {
          operatoryId = +args.resource.id;
        } else {
          operatoryId = args.resource.id;
        }
      }


      $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: "src/common/schedule-appointment-add.tpl.html",
        controller: "ScheduleAppointmentAddCtrl",
        // size: "lg",
        resolve: {
          appointment: function () {
            return {
              appointmentId: 0,
              providerId: null,
              patientId: null,
              appointmentType: end ? "0" : "npv",
              operatoryId: operatoryId,
              complaint: '',
              start: start,
              end: end
            }
          },
          CanUpdateAppointment: $rootScope.resolved.CanUpdateAppointment,
          CanCreateAppointment: $rootScope.resolved.CanCreateAppointment,
          CanDeleteAppointment: $rootScope.resolved.CanDeleteAppointment,
          MultiLocationEnable: $rootScope.resolved.MultiLocationEnable,
        }
      }).result.then(
        function () {
          // TODO(bradleybossard): Can/should these events be emitted from the dialog?  That would
          // clean up some redundant code here and below.
          $scope.$broadcast("appointmentUpdate")
        },
        function () { }
      )
    }

    $scope.openEditAppointmentModal = function (event, eventProps) {
      $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: "src/common/schedule-appointment-add.tpl.html",
        controller: "ScheduleAppointmentAddCtrl",
        resolve: {
          appointment: function () {
            // Convert start/end moment() objects to native JS Dates b/c everything is
            // handled as native Dates with in the controller.
            return {
              appointmentId: event.id,
              providerId: eventProps.provider,
              patientId: eventProps.patient,
              appointmentType: eventProps.appointmentType,
              operatoryId: eventProps.operatory,
              complaint: eventProps.complaint,
              reminderStatus: eventProps.reminderStatus,
              reminderSentDate: eventProps.reminderSentDate,
              appointmentStatus: eventProps.appointmentStatus,
              start: event.start,
              end: event.end,
              patient_event_alerts: eventProps.patient_event_alerts
            }
          },
          CanUpdateAppointment: $rootScope.resolved.CanUpdateAppointment,
          CanCreateAppointment: $rootScope.resolved.CanCreateAppointment,
          CanDeleteAppointment: $rootScope.resolved.CanDeleteAppointment,
          MultiLocationEnable: $rootScope.resolved.MultiLocationEnable,
        }
      }).result.then(
        function () {
          $scope.$broadcast("appointmentUpdate")
        },
        function () { }
      )
    }

    $scope.openAddBackGroundModal = function (args) {

      $rootScope.$broadcast("closeIntro")
      var start = args.start ? args.start : "";
      var operatoryId = "";

      if(args.resource && args.resource.id) {
        if(typeof args.resource.id === 'string') {
          operatoryId = +args.resource.id;
        } else {
          operatoryId = args.resource.id;
        }
      }

      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'src/common/schedule-background-add.tpl.html',
        controller: 'ScheduleBackGroundAddCtrl',
        resolve: {
          timeblock: function () {
            return {
              timeblockId: 0,
              providerId: null,
              operatoryId: operatoryId,
              reason: '',
              start: start,
              rrule: '',
              duration: 30
            };
          },
          CanUpdateTimeBlock: $rootScope.resolved.CanUpdateTimeBlock,
          CanCreateTimeBlock: $rootScope.resolved.CanCreateTimeBlock,
          CanDeleteTimeBlock: $rootScope.resolved.CanDeleteTimeBlock,
          MultiLocationEnable: $rootScope.resolved.MultiLocationEnable,
        }
      });

      modalInstance.result.then(function () {
        $scope.$broadcast('timeblockUpdate');
      }, function () {
      });

    };

    $scope.openEditBackGroundModal = function (event, eventProps) {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'src/common/schedule-background-add.tpl.html',
        controller: 'ScheduleBackGroundAddCtrl',
        resolve: {
          timeblock: function () {
            return {
              timeblockId: event.id,
              providerId: eventProps.provider,
              operatoryId: eventProps.operatory,
              reason: eventProps.reason,
              start: event.start,
              rrule: eventProps.rrule,
              duration: eventProps.duration,
              originalStart: eventProps.originalStartDate,
            };
          },
          CanUpdateTimeBlock: $rootScope.resolved.CanUpdateTimeBlock,
          CanCreateTimeBlock: $rootScope.resolved.CanCreateTimeBlock,
          CanDeleteTimeBlock: $rootScope.resolved.CanDeleteTimeBlock,
          MultiLocationEnable: $rootScope.resolved.MultiLocationEnable,
        }
      });

      modalInstance.result.then(function () {
        $scope.$broadcast('timeblockUpdate');
      }, function () {
      });
    };
    
    $scope.openAddTimeblockModal = function ($event, day) {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'src/common/schedule-timeblock-add.tpl.html',
        controller: 'ScheduleTimeblockAddCtrl',
        resolve: {
          timeblock: function () {
            return {
              timeblockId: 0,
              providerId: null,
              operatoryId: null,
              reason: '',
              start: day,
              rrule: '',
              duration: 30
            };
          },
          CanUpdateTimeBlock: $rootScope.resolved.CanUpdateTimeBlock,
          CanCreateTimeBlock: $rootScope.resolved.CanCreateTimeBlock,
          CanDeleteTimeBlock: $rootScope.resolved.CanDeleteTimeBlock,
          MultiLocationEnable: $rootScope.resolved.MultiLocationEnable,
        }
      });

      $scope.blurBootstrapButton($event);

      modalInstance.result.then(function () {
        $scope.$broadcast('timeblockUpdate');
      }, function () {
      });

    };

    $scope.blurBootstrapButton = function ($event) {
      if ($event && $event.target) {
        $event.target.blur();
      }
    };

    $scope.openEditTimeblockModal = function (event, eventProps) {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'src/common/schedule-timeblock-add.tpl.html',
        controller: 'ScheduleTimeblockAddCtrl',
        resolve: {
          timeblock: function () {
            return {
              timeblockId: event.id,
              providerId: eventProps.provider,
              operatoryId: eventProps.operatory,
              reason: eventProps.reason,
              start: event.start,
              rrule: eventProps.rrule,
              duration: eventProps.duration,
              originalStart: eventProps.originalStartDate,
            };
          },
          CanUpdateTimeBlock: $rootScope.resolved.CanUpdateTimeBlock,
          CanCreateTimeBlock: $rootScope.resolved.CanCreateTimeBlock,
          CanDeleteTimeBlock: $rootScope.resolved.CanDeleteTimeBlock,
          MultiLocationEnable: $rootScope.resolved.MultiLocationEnable,
        }
      });

      modalInstance.result.then(function () {
        $scope.$broadcast('timeblockUpdate');
      }, function () {
      });
    };

    $scope.businessHoursStart = PracticeSettings.business_hours_start;
    var endHour = PracticeSettings.business_hours_end;
    $scope.businessMinutesStart = PracticeSettings.business_minutes_start;
    var endMinutes = PracticeSettings.business_minutes_end;

    var calendarHeaderAndSeparatorsHeight = 42 + 19 + 4;
    var calendar15MinutesHeight = 22;
    var calendar1MinuteHeight = 1.46;

    var businessHoursHeight = ((endHour - $scope.businessHoursStart) * 4) * calendar15MinutesHeight +
      ($scope.businessMinutesStart + endMinutes) * calendar1MinuteHeight + calendarHeaderAndSeparatorsHeight;


    $scope.$watch('currentDate', function (newVal, oldVal) {
      if ((newVal !== oldVal) && (newVal !== null)) {
        //update calendar date
        if ($scope.uiConfig.calendar.defaultDate !== newVal) {
          $scope.uiConfig.calendar.defaultDate = newVal;
        }
        //remember current date for subsequent calendar access
        if (newVal !== SessionStorageService.calendarCurrentDate) {
          SessionStorageService.calendarCurrentDate = newVal;
        }
      }
    });



    $scope.uiConfig = {
      calendar: {
        //Apply on both contentHeight and height in order the header don't shrink on other screen resolutions
        contentHeight: businessHoursHeight,
        height: businessHoursHeight,
        dayMinWidth: 150,
        stickyFooterScrollbar : true,
        scrollTime: moment().hour(PracticeSettings.business_hours_start).minute(PracticeSettings.business_minutes_start).format("HH:mm"),
        editable: CanUpdateAppointment,  // Used on for the appointment at this moment
        // Always render the calendar in locale of the client.  May want to revisit this?
        timeZone: 'local',
        businessHours: {
          startTime: moment().hour(PracticeSettings.business_hours_start).minute(PracticeSettings.business_minutes_start).format("HH:mm"),
          endTime: moment().hour(PracticeSettings.business_hours_end).minute(PracticeSettings.business_minutes_end).format("HH:mm"),
          daysOfWeek: PracticeSettings.dow // days of week (0=Sunday)
        },
        initialDate: $scope.currentDate.format('YYYY-MM-DD'),
        slotDuration: '00:15:00',
        initialView: 'timeGridDay',
        headerToolbar: false,
      }
    };

  }

  angular.module('common.schedule', [])
    .controller('ScheduleCtrl', scheduleCtrl);
})();

(function() {
  'use strict';

  function scheduleFullCalendarCtrl($scope, $log, uiCalendarConfig, ScheduleService, TimeblockService , PrincipalService, NotificationService, WebsocketService, $rootScope) {
    $log.debug('ScheduleFullCalendar loaded');

    // Array containing actually FullCalendar appointment events.
    $scope.appointments = [];

    // Array containing rules for generating timeblocks.  These rules are
    // used to compute events each time there is a FullCalendar view change.
    $scope.timeblocks = [];

    // The array of sources FullCalendar usings for events (appointments + timeblocks)
    $scope.eventSources = [];

    //begin websocket observer setup
    var scope = $scope;
    var strm = 'notification';
    var cmds_observed = [
      'model_create_dmr_schedule_models_appointment',
      'model_update_dmr_schedule_models_appointment',
      'model_delete_dmr_schedule_models_appointment',
      'model_create_dmr_schedule_models_timeblock',
      'model_update_dmr_schedule_models_timeblock',
      'model_delete_dmr_schedule_models_timeblock'
    ];
    scope.notificationObservableId = WebsocketService.observe(
      strm,
      cmds_observed,
      function(stream, cmd, params) {
        scope.$applyAsync(function() {
          $log.debug('Websocket message received in DMR_SCHEDULE with ID: ' + scope.notificationObservableId + '; STREAM: ' + stream + '; CMD: ' + cmd + '; PARAMS: ' + JSON.stringify(params));
          if (cmd.endsWith('appointment')) {
            $rootScope.$broadcast('appointmentUpdate');
          } else if (cmd.endsWith('timeblock')) {
            $rootScope.$broadcast('timeblockUpdate');
          }
        });
      }
    );
    $log.debug('Websocket observer registered in DMR_SCHEDULE with ID: ' + scope.notificationObservableId + '; STREAM: ' + strm + '; CMDS_OBSERVED: ' + JSON.stringify(cmds_observed));
    $scope.$on('$destroy', function() {
      WebsocketService.unobserve(scope.notificationObservableId);
      $log.debug('Websocket observer unregistered in DMR_SCHEDULE with ID: ' + scope.notificationObservableId);
    });
    //end websocket observer setup


    // when the calendar view change or enter the first time load the data according
    // the type of view of the calendar
    $scope.$on('calendarViewRenderChange',function(event,data){
      var startDate,endDate;
      // if($stateParams.mode === 'multicolumn'){
      //   return;
      // }

      if(data.type === 'agendaWeek'){
        startDate = data.calendar.getDate().clone().startOf('week');
        endDate = data.calendar.getDate().clone().endOf('week');
      }
      if(data.type === 'month'){
        startDate = data.calendar.getDate().clone().startOf('month');
        endDate = data.calendar.getDate().clone().endOf('month');
      }
      if(data.type === 'agendaDay'){
        startDate = data.calendar.getDate().clone().startOf('day');
        endDate = data.calendar.getDate().clone().endOf('day');
      }


      $scope.calcStartDate = startDate.toDate();
      $scope.calcEndDate = endDate.toDate();


      $scope.fetchAppointments(startDate.toDate(),endDate.toDate());
      $scope.fetchTimeblocks(startDate.toDate(),endDate.toDate());

    });

    // Fetch appointments from the server, then refresh the eventSource.
    $scope.fetchAppointments = function(startDate,endDate) {

      if(!PrincipalService.hasPermission('appointments_view')){
        return;
      }


      ScheduleService.allBetween(startDate,endDate).then(
        function(data) {
          uiCalendarConfig.calendars.calendar.fullCalendar('removeEventSource', "appt");
          $scope.appointments = ScheduleService.transformAppointments(data.data, $scope.multicolumnByProvider, null, $scope.colorBy);
          $scope.appointments.id = "appt"
          uiCalendarConfig.calendars.calendar.fullCalendar('addEventSource', $scope.appointments);
        },
        function(err) {
          NotificationService.error('Error fetching appointments',err);
        });
    };

    // Fetch timeblocks from the server, then refresh the eventSource.
    $scope.fetchTimeblocks = function(startDate,endDate) {
      TimeblockService.allBetween(startDate,endDate).then(
        function(data) {
          uiCalendarConfig.calendars.calendar.fullCalendar('removeEventSource', "tblk")
          $scope.timeblocks = {
            id: "tblk",
            events: TimeblockService.transformTimeblocks(data.data, null, null, $scope.colorBy, null,startDate)
          };
          uiCalendarConfig.calendars.calendar.fullCalendar('addEventSource', $scope.timeblocks);
        },
        function(err) {
          NotificationService.error('Error fetching timeblocks',err);
        });
    };

    // Listen for events from parent scope about event changes
    $scope.$on('appointmentUpdate', function() {
      $scope.fetchAppointments($scope.calcStartDate,$scope.calcEndDate);
    });

    $scope.$on('timeblockUpdate', function() {
      $scope.fetchTimeblocks($scope.calcStartDate,$scope.calcEndDate);
    });
  }

  angular.module('common.schedule-fullcalendar', [])
    .controller('ScheduleFullCalendarCtrl', scheduleFullCalendarCtrl);
})();

/**
 * A controller for the scheduler full-calendar
 */
/* global tippy */
class ScheduleMultiColumnController {
  constructor(
    $log,
    $uibModal,
    $q,
    $rootScope,
    $scope,
    $timeout,
    NotificationService,
    PrincipalService,
    ScheduleService,
    SessionStorageService,
    TimeblockService,
    AuthenticationService,
    LocationsService,
    LocalStorageService,
    WebsocketService
  ) {
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$timeout =$timeout
    this.NotificationService = NotificationService;
    this.PrincipalService = PrincipalService;
    this.ScheduleService = ScheduleService;
    this.SessionStorageService = SessionStorageService;
    this.TimeblockService = TimeblockService;
    this.AuthenticationService = AuthenticationService;
    this.LocationsService = LocationsService;
    this.LocalStorageService = LocalStorageService;
    this.WebsocketService = WebsocketService;

    this.events = undefined;
    this.colorBy = 'Appt. Type';
    this.selectedColumn = 'Operator';
    this.dt = moment().format('YYYY-MM-DD');
    this.loading = false;
    this.operatoryNameMap = new Map();
    this.providerNameMap = new Map();
  }
  /**
   *
   */
  $onInit() {
    this.$log.debug('scheduleMultiColumnController initialized');
    this.$scope.$watch('ctrl.dt', function (newDate, oldVal) {
      if (newDate === oldVal) { return; }
      var newCurrentDate = moment(newDate,'YYYY-MM-DD', true); 
      if (this.$scope.$parent.$parent.$parent.currentDate !== newCurrentDate) {
        this.$scope.$parent.$parent.$parent.currentDate =  newCurrentDate
      }
      this.$scope.$broadcast('updateCurrentTime', newDate)
      this.$scope.$broadcast('goToDateClick', newDate);
    }.bind(this))

    this.$scope.$on('timeblockUpdate', function () {
      if(this.$scope.multiColumnView) {
        this.$scope.$broadcast('goToDateClick', this.dt);
      }
    }.bind(this))


    this.$scope.$on('appointmentUpdate', function () {
      this.$scope.$broadcast('goToDateClick', this.dt);
    }.bind(this))

    this.$q.all([
      this.AuthenticationService.getPracticeAdminAndProviderAccounts(
        this.PrincipalService.getPracticeId()
      ),
      this.LocationsService.all()]
    ).then((events) => {
      this.providers = angular.fromJson(events[0].data);
      this.locations = angular.fromJson(events[1].data);

      var locations = [];
      if (this.$scope.MultiLocationEnable) {
        //order locations so the user's location is first
        var currentAccountId = this.PrincipalService.getAccountId();
        var provider = _.find(this.providers, function (provider) {
          return provider.id === currentAccountId;
        });
        if (provider) {
          this.locations = _.sortBy(this.locations, function (location) {
            if (location.id === provider.location_default) {
              return 0;
            }
            if (location.id !== provider.location_default) {
              return 1;
            }
          });
        }
        locations = this.locations;
      } else {
        locations = this.locations[0];
      }

      this.allOperatories = _.map(locations, function (location) {
        return _.sortBy(location.operatories, 'name');
      });

      this.resources = _.map(_.flatten(this.allOperatories), function (operatory) {
        var orderMap = this.LocalStorageService.get('orderMap') ? this.LocalStorageService.get('orderMap')  : {};
        this.operatoryNameMap.set(operatory.id, operatory.name);
        var orderKey = operatory.id + "_" + operatory.name
        var val=  { id: operatory.id, title: operatory.name, isOperatory: true, 
          order: orderMap[orderKey]
        }
        return val
      }.bind(this));
    })

    this.$scope.$watch('multiColumnView', function() {
        this.$scope.$broadcast('fullView', !this.$scope.multiColumnView);
    }.bind(this));


    this.$scope.$on('updateMulticolumnByProvider', function (_event, value) {
      this.$scope.multicolumnByProvider = value
      var orderMap = this.LocalStorageService.get('orderMap') ? this.LocalStorageService.get('orderMap')  : {};
      if(value) {
        this.resources = _.map(_.flatten(_.filter(this.providers, function (prov) { return prov.is_active } )), function (provider) {
          var orderKey = provider.id + "_" + provider.last_name + ", " + provider.first_name
          return { 
            id: provider.id,
            title: provider.last_name + ", " + provider.first_name,
            isOperatory: false,
            order: orderMap[orderKey]
          }
        });
      } else {
        this.resources = _.map(_.flatten(this.allOperatories), function (operatory) {
          var orderKey = operatory.id + "_" + operatory.name
          return { 
            id: operatory.id,
            title: operatory.name,
            isOperatory: true,
            order: orderMap[orderKey]
          }
        });
      }
    }.bind(this))

    //begin websocket observer setup
    var strm = 'notification';
    var cmds_observed = [
      'model_create_dmr_schedule_models_appointment',
      'model_update_dmr_schedule_models_appointment',
      'model_delete_dmr_schedule_models_appointment',
      'model_create_dmr_schedule_models_timeblock',
      'model_update_dmr_schedule_models_timeblock',
      'model_delete_dmr_schedule_models_timeblock'
    ];
    this.$scope.notificationObservableId = this.WebsocketService.observe(
      strm,
      cmds_observed,
      function(stream, cmd, params) {
        this.$scope.$applyAsync(function() {
          this.$log.debug('Websocket message received in DMR_SCHEDULE with ID: ' + this.$scope.notificationObservableId + '; STREAM: ' + stream + '; CMD: ' + cmd + '; PARAMS: ' + JSON.stringify(params));
          if (cmd.endsWith('appointment')) {
            this.$rootScope.$broadcast('appointmentUpdate');
          } else if (cmd.endsWith('timeblock')) {
            this.$rootScope.$broadcast('timeblockUpdate');
          }
        }.bind(this));
      }.bind(this)
    );
    this.$log.debug('Websocket observer registered in DMR_SCHEDULE with ID: ' + this.$scope.notificationObservableId + '; STREAM: ' + strm + '; CMDS_OBSERVED: ' + JSON.stringify(cmds_observed));
    this.$scope.$on('$destroy', function() {
      this.WebsocketService.unobserve(this.$scope.notificationObservableId);
      this.$log.debug('Websocket observer unregistered in DMR_SCHEDULE with ID: ' + this.$scope.notificationObservableId);
    }.bind(this));
    //end websocket observer setup
  }

  dateSet() {
    var counter = 0;
    return function (event) {
      if(this.$scope.multiColumnView && event.startStr.split('T')[0] !== this.dt && counter < 2) {
        counter++;
        this.$scope.$broadcast('goToDateClick', this.dt);
        console.info(`The two dates in event aren't equal`);
        return;
      }

      this.loading = true;
      this.$q.all([
        this.ScheduleService.allBetween(event.start, event.end),
        this.TimeblockService.allBetween(event.start, event.end)
      ])
        .then(([app, tms]) => {

          var timeBlock = this.TimeblockService.transformTimeblocks(
            tms.data,
            this.$scope.colorBy,
            this.$scope.multicolumnByProvider,
            this.$rootScope.isBackgroundEditMode,
            event.start
          );

          var appointment = this.ScheduleService.transformAppointments(
            app.data,
            this.$scope.multicolumnByProvider,
            undefined,
            this.$scope.colorBy,
            this.$rootScope.isBackgroundEditMode
          );
          this.events = _.flatten([timeBlock, appointment]);
          this.loading = false;
        })
    }.bind(this);
  }

  hasPermission(eventType) {
    var typePermissionMap = {
      'appointment': 'CanRetrieveAppointment',
      'timeblock': 'CanRetrieveTimeBlock',
      'background': 'CanRetrieveTimeBlock'
    }
    return this.$rootScope.resolved[typePermissionMap[eventType]];
  }

  getModal(eventType) {
    var typeModalMap = {
      'appointment': 'openEditAppointmentModal',
      'timeblock': 'openEditTimeblockModal',
      'background': 'openEditBackGroundModal'
    }

    return this.$scope[typeModalMap[eventType]];
  }

  eventClick() {
    return function (args) {
      var event = args.event;
      var eventProps = event.extendedProps;
      var eventType = eventProps.type;

      if (!this.hasPermission(eventType)) {
        return;
      }

      this.getModal(eventType)(event, eventProps);
    }.bind(this);
  }

  eventMouseLeave() {
    return function (args) {
      if(args._tippy) {
        args._tippy.dispose();
      }
    }.bind(this)
  }
  eventMouseEnter() {
    return function (args) {
      var event = args.event;
      var eventProps = args.event.extendedProps;
      var appointmentString = this.ScheduleService.getAppointmentTypeName(eventProps.appointmentType);
      var start = moment(event.start).format('h:mm a');
      var end = moment(event.end).format('h:mm a');
      var patient_event_alerts_string = '';
      var appointmentContent = '<div align="left">' +
                  '<div style="word-break:break-all"><h5>' + eventProps.complaint + '<h5></div>' +
                  '<b>Type</b> - ' + appointmentString + '<br>' +
                  '<b>Provider</b> - ' + eventProps.providerFirstName + ' ' + eventProps.providerLastName + '<br>' +
                  '<b>Patient</b> - ' + eventProps.patientFirstName + ' ' + eventProps.patientLastName + ((eventProps.patientYearsOld) ? (' (' + eventProps.patientYearsOld + 'y)') : '') + '<br>' +
                  '<b>Operatory</b> - ' + eventProps.operatoryName + '<br>' +
                  '<b>Time</b> - ' + start + ' - ' + end + '<br>' +
                  '<b>Appt Status</b> - ' + eventProps.appointmentStatus + '<br>' +
                  '<b>Reminder Status</b> - ' + eventProps.reminderStatus +
                  '<br>' +
                  '<b>Patient Mobile</b> - ' + eventProps.patientMobilePhone + '<br>' +
                  '<b>Patient Home</b> - ' + eventProps.patientHomePhone + '<br>' +
                  patient_event_alerts_string +
                  '</div>';
      var timeBlockContent = '<div align="left">' +
        '<div style="word-break:break-all"><h5>' + eventProps.reason + '</h5></div>' +
        '<b>Provider</b> - ' + ((eventProps.provider) ? eventProps.providerFirstName + ' ' + eventProps.providerLastName : "") + '<br>' +
        '<b>Operatory</b> - ' + ((eventProps.operatory) ? eventProps.operatoryName : '') + '<br>' +
        '<b>Start Time</b> - ' + start + '<br>' +
        '<b>Duration</b> - ' + eventProps.duration + '<br>' +
        '</div>';

      var content;
      if (eventProps.type === 'timeblock') {
        content = timeBlockContent
      } else if(eventProps.type === 'appointment') {
        content = appointmentContent;
      }

      if(content && !args.el._tippy) {
        tippy(args.el, { content: content, allowHTML: true});
      } else if(content && args.el._tippy) {
        args.el._tippy.setContent(content)
      }
    }.bind(this);
  }
  
  eventDrop() {
    return function (args) {
      if(args.event && args.event.startEditable === false && args.event.durationEditable === false) { 
        return;
      }
      
      if(args.event.extendedProps.rrule) {
        this.confirmationDialog(args);
        return;
      } 
      this.saveEvent(args);
    }.bind(this);
  }

  saveEvent(args) {
    if(args.newResource) {
      if(args.newResource.extendedProps.isOperatory) {
        args.event.setExtendedProp('operatory', args.newResource.id);
        args.event.setExtendedProp('operatoryName', this.operatoryNameMap.get(Number(args.newResource.id)))
      } else {
        args.event.setExtendedProp('provider', args.newResource.id);
      }
    }
    if(args.event.extendedProps.type === 'appointment') {
      this.$scope.updateAppointment(args.event, args.event.extendedProps, () => {
        this.$scope.$broadcast('appointmentUpdate');
      })
     
    } else if(args.event.extendedProps.type === 'timeblock') {
      this.$scope.updateTimeblock(args.event, args.event.extendedProps,  () => {
        this.$scope.$broadcast('timeblockUpdate');
      })

    } else if(args.event.extendedProps.type === 'background') {
      this.$scope.updateBackground(args.event, args.event.extendedProps, () => {
        this.$scope.$broadcast('timeblockUpdate');
      })
    }
  }

  eventResize() {
    return function (args) {
      if(args.event && args.event.startEditable === false && args.event.durationEditable === false) { 
        return;
      }
      
      if(args.event.extendedProps.rrule) {
        this.confirmationDialog(args);
        return;
      } 
      this.saveEvent(args);
    }.bind(this);
  }

  dateClick() {
    return function (args) {
      if(this.$rootScope.isBackgroundEditMode) {
        this.$scope.openAddBackGroundModal(args)
      } else {
        this.$scope.openAddAppointmentModal(args)
      }
    }.bind(this);
  }

  openDatepicker() {
    this.datePickerOpened = true;
  }

  incrementDate() {
    this.$scope.ctrl.dt = moment(this.$scope.ctrl.dt).add(1, 'days'). toISOString().split('T')[0];
  }

  decrementDate() {
    this.$scope.ctrl.dt = moment(this.$scope.ctrl.dt).subtract(1, 'days').toISOString().split('T')[0];
  }

  today() {
    this.$scope.ctrl.dt = moment().format('YYYY-MM-DD');
  }

  settingsDialog() {
    this.modalInstance = this.$uibModal.open({
      animation: this.$scope.animationsEnabled,
      templateUrl: "src/common/schedule-settings.partial.tpl.html",
      controller: "scheduleSettingsCtrl",
      resolve: { 
        message: () => "Changing this event will change all recurring events",
        resources: () => _.clone(this.resources)
      }
    })
  
    this.modalInstance.result.then(
      function (result) {
        if(result) {
          this.resources = result;
        }
      }.bind(this)
  )
  }

  confirmationDialog(args) {
    this.modalInstance = this.$uibModal.open({
      animation: this.$scope.animationsEnabled,
      templateUrl: "src/common/schedule-confirm.partial.tpl.html",
      controller: "scheduleConfirmationCtrl",
      resolve: { message: () => "Changing this event will change all recurring events" }

    })
  
    this.modalInstance.result.then(
      function (result) {
        if(result === 'cancel') {
          args.revert();
        } 

        if(result === 'save') {
          this.saveEvent(args);
        }
      }.bind(this)
  )
  
  }

}

(function () {
  'use strict';
  angular.module('common.schedule-multicolumn', [])
      .controller('ScheduleMultiColumnController', ScheduleMultiColumnController)
})();

/*global rrule */
(function() {
  'use strict';

  function scheduleRecurringAddCtrl(
    $uibModalInstance,
    $rootScope,
    $scope
    ) {

      $scope.repeatInterval = null;
      $scope.repeatIntervalUnits = [
        {label: 'Days', value: 3},
        {label: 'Weeks', value: 2},
        {label: 'Months', value: 1},
        {label: 'Years', value: 0}
      ];
      $scope.selectedRepeatIntervalUnit = $scope.repeatIntervalUnits[0];
      $scope.repeatOnDaysOfWeek = [
        {label: 'Sunday', value: 6},
        {label: 'Monday', value: 0},
        {label: 'Tuesday', value: 1},
        {label: 'Wednesday', value: 2},
        {label: 'Thursday', value: 3},
        {label: 'Friday', value: 4},
        {label: 'Saturday', value: 5}
      ];

      $scope.getSelectedDaysOfWeek = function() {
        var selectedDays = [];
        angular.forEach($scope.repeatOnDaysOfWeek, function(dayOfWeek) {
          if (dayOfWeek.checked) {
            selectedDays.push(dayOfWeek.value);
          }
        });
        return selectedDays;
      }

      $scope.save = function() {
        var ruleOptions = {}
        ruleOptions.freq = $scope.selectedRepeatIntervalUnit.value
        ruleOptions.interval = $scope.repeatInterval
        if(ruleOptions.freq === 2) {
          var selectedDays = $scope.getSelectedDaysOfWeek()
          ruleOptions.byweekday = selectedDays
        }

        var rule =  new rrule.RRule(ruleOptions)
        $uibModalInstance.close({label: window.titleCase(rule.toText()), value: rule.toString()});
      };

      $scope.cancel = function() {
        $uibModalInstance.dismiss();
      };


  }

  angular.module('common.schedule-recurring-add', [])
    .controller('ScheduleRecurringAddCtrl', scheduleRecurringAddCtrl);
})();

(function() {
  'use strict';

  function scheduleAppointmentAddCtrl(
    $filter,
    $log,
    $uibModal,
    $uibModalInstance,
    $rootScope,
    $scope,
    $state,
    $window,
    $http,
    appointment,
    AuthenticationService,
    CanCreateAppointment,
    CanDeleteAppointment,
    CanUpdateAppointment,
    LocationsService,
    MultiLocationEnable,
    NotificationService,
    PatientService,
    PrincipalService,
    ScheduleService,
    LocalStorageService,
    ScheduleCommonService
    ) {

    $scope.CanCreateAppointment = CanCreateAppointment;
    $scope.CanUpdateAppointment = CanUpdateAppointment;
    $scope.CanDeleteAppointment = CanDeleteAppointment;

    $scope.appointmentStatuses = [
      {value:'unconfirmed',title:'Unconfirmed'},
      {value:'confirmed',title:'Confirmed'},
      {value:'checked_in',title:'Checked in'},
      {value:'paperwork',title:'Paperwork'},
      {value:'seated',title:'Seated'},
      {value:'checked_out',title:'Checked out'},
      {value:'completed',title:'Completed'},
      {value:'contact_attempted',title:'Contact Attempted'},
      {value:'late',title:'Late'},
      {value:'no_show',title:'No Show'},
      {value:'canceled',title:'Canceled'}
    ];

    $scope.durationOptions = [
      { label: '15 minutes', value: 15 },
      { label: '30 minutes', value: 30 },
      { label: '45 minutes', value: 45 },
      { label: '1 hour', value: 60 },
      { label: '1 hour 15 minutes', value: 75 },
      { label: '1 hour 30 minutes', value: 90 },
      { label: '1 hour 45 minutes', value: 105 },
      { label: '2 hours', value: 120 },
      { label: '2 hours 15 minutes', value: 135 },
      { label: '2 hours 30 minutes', value: 150 },
      { label: '2 hours 45 minutes', value: 165 },
      { label: '3 hours', value: 180 },
      { label: '3 hours 30 minutes', value: 210 },
      { label: '4 hours', value: 240 },
      { label: '4 hours 30 minutes', value: 270 },
      { label: '5 hours', value: 300 },
    ];
    $scope.appointmentDefaults = LocalStorageService.get('appointmentDefaults');
    $http.get('/api/v1/appointment/default/list').then((response) => 
    {
      $scope.appointmentDefaults = response.data.length > 0 ? ScheduleCommonService.getAppointmentDefaults(response.data) :  $scope.appointmentDefaults
    })

    // Helper function to calculate endTime from startTime + duration
    function calculateEndTime() {
      return moment($scope.startTime).clone().add($scope.duration, 'minutes').toDate();
    }

    $scope.appointmentId = appointment.appointmentId;
    $scope.isNewAppointment = (appointment.appointmentId === 0);
    $scope.appointmentType = appointment.appointmentType;
    $scope.providerId = appointment.providerId;
    $scope.patient = {id:appointment.patientId};
    $scope.operatoryId = appointment.operatoryId;
    $scope.complaint = appointment.complaint;
    $scope.reminderStatus = appointment.reminderStatus;
    $scope.reminderSentDate = appointment.reminderSentDate;
    $scope.appointmentStatus = appointment.appointmentStatus;
    $scope.sendReminderOnNewApp = false;
    $scope.sendReminderEnabled = false;
    $scope.doesApptDefaultExist = true;

    $scope.repeatOptions = LocalStorageService.get('repeatOptions');
    $scope.repeatType = appointment.rrule ? appointment.rrule : $scope.repeatOptions[0];
    if($scope.repeatOptions && appointment.rrule) {
      var rruleObj = window.rrule.rrulestr(appointment.rrule)
      $scope.repeatType = $scope.repeatOptions.find(
        (repeatOptions) => repeatOptions.label.toLowerCase() === rruleObj.toText())

      if(!$scope.repeatType) {
        var newRepeatOptions = {
          label: window.titleCase(rruleObj.toText()),
          value: rruleObj.toString()
        }
        $scope.repeatOptions.push(newRepeatOptions)
        LocalStorageService.set('repeatOptions', $scope.repeatOptions);
        $scope.repeatOptions = LocalStorageService.get('repeatOptions');
        $scope.repeatType = newRepeatOptions
      }
    }

    $scope.patient_event_alerts = appointment.patient_event_alerts;
    $scope.$watch('patient.id', function(newVal, oldVal) {
      if (newVal !== oldVal) {
        $scope.patient_event_alerts = null;
        if ($scope.patientSelectedAndCanReceiveReminder()) {
          $scope.sendReminderEnabled = true;
          if ($scope.isNewAppointment) {
            $scope.sendReminderOnNewApp = true;
          }
        } else {
          $scope.sendReminderEnabled = false;
          if ($scope.isNewAppointment) {
            $scope.sendReminderOnNewApp = false;
          }
        }
      }
    });
    $scope.getPatientEventAlertText = function (patientEventAlerts) {
      var text = '';
      if (patientEventAlerts && patientEventAlerts.length > 0) {
        for (var i=0; i<patientEventAlerts.length; i++) {
          text += patientEventAlerts[i].data.note.substring(0, 200) + ((patientEventAlerts[i].data.note.length > 200) ? "..." : "") + '\n';
        }
      }
      return text;
    }
    // AngularUi and momentJs have two different syntaxes for date formatting, so both must
    // be defined.  Otherwise, this led to a bug where the form was not being validated
    // properly for an appointment update, b/c the string momentJs was inserted was validating.


    // Equivalent format for momentJs
    $scope.momentDateFormat = 'YYYY-MM-DD';
    // Time format
    $scope.momentTimeFormat = 'h:mm A';
    $scope.minDate = moment().subtract(1, 'week').format($scope.momentDateFormat);
    $scope.startDate = '';
    $scope.startTime = moment().hour(8).minute(0).toDate();

    $scope.duration = null;
    $scope.appointmentString = '';
    $scope.endTime = null;

    $scope.updateDurationOptions = function (appointmentTime) {
      // Check if the value already exists in durationOptions
      const valueExists = $scope.durationOptions.some((option) => option.value === appointmentTime);
    
      if (!valueExists) {
        // Find the index where the new option should be inserted
        let insertIndex = 0;
        while (
          insertIndex < $scope.durationOptions.length &&
          $scope.durationOptions[insertIndex].value < appointmentTime
        ) {
          insertIndex++;
        }
    
        // Create the new option with the corresponding label
        const newOption = {
          label: $scope.formatDurationLabel(appointmentTime),
          value: appointmentTime,
        };
    
        // Insert the new option at the appropriate location
        $scope.durationOptions.splice(insertIndex, 0, newOption);
      }
    };

    $scope.setDefaultDurationByAppointmentType = function (appointmentType) {
      $scope.appointmentDefaults.forEach(function (appt) {
        if (appt.value === appointmentType) {
          if (appt.defaultDuration) {
            $scope.duration = appt.defaultDuration;
            $scope.timeChange();
            $scope.updateDurationOptions(appt.defaultDuration)
          }
        }
      });
    };
    
    // TODO: update with moment
    // Function to format the label based on the value
    $scope.formatDurationLabel = function(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
    
      if (hours === 0) {
        return `${remainingMinutes} minutes`;
      } else if (hours === 1) {
        return `${hours} hour ${remainingMinutes} minutes`;
      } else {
        return `${hours} hours ${remainingMinutes} minutes`;
      }
    }


    // If a start/end time is provided, override the default values with those
    // from this existing appointment.
    if (appointment.start !== '') {
      $scope.startDate = moment(appointment.start).startOf('day').format($scope.momentDateFormat);
      $scope.startTime = moment(appointment.start).toDate();
    }

    if(appointment.start !== '' && appointment.end !== '' ){
      var diff = moment(appointment.end).diff(moment(appointment.start));
      var duration = moment.duration(diff).asMinutes();

      if($scope.durationOptions.filter(function(d){return d.value === duration;}).length === 0 &&
        $scope.appointmentDefaults.filter(function(d){return d.defaultDuration === duration;}).length === 0
      ){
        $scope.updateDurationOptions(duration)
      }

      $scope.duration = duration;
    }

    $scope.patients = [];
    $scope.providers = [];
    $scope.operatoriies = [];

    $scope.getYearsOld = function(date_string) {
      var month = Number(date_string.slice(0, 2))
      var date = Number(date_string.slice(3, 5))
      var year = Number(date_string.slice(6, 10))
      if (!year || !month || !date) {
        return null;
      }
      var currentTime = new Date();
      var currentYear = currentTime.getFullYear();
      var currentMonth = currentTime.getMonth() + 1;
      var currentDate = currentTime.getDate();
      var ageYear = currentYear - year;
      if ((currentMonth < month) || ((currentMonth === month) && (currentDate < date))) {
        ageYear = ageYear - 1;
      }
      return ageYear.toString();
    }

    // gets the absolute URL for the patient encounters list  to a new tab redirect
    $scope.goToPatientEncounters = function() {
      if(!$scope.patient && !$scope.patient.id){
        return;
      }

      var patientEncounterUrl = $state.href('chartnotes', {patientid:$scope.patient.id}, {absolute: true});

      $window.open(patientEncounterUrl, '_blank');

    };


    $scope.openCustomRepeatModal = function () {
      if ($scope.repeatType.value === 1) {
        var modalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: "src/common/schedule-recurring-add.tpl.html",
          controller: "ScheduleRecurringAddCtrl",
          size: 'md'
        });

        modalInstance.result.then(function (result) {
          var repeatOptions = LocalStorageService.get('repeatOptions');
          repeatOptions.push(result);
          LocalStorageService.set('repeatOptions', repeatOptions);
          $scope.repeatType = result;
        });
      }
    };


    /**
     * Used just to update the sent reminder status
     * TODO add a websocket to listen the event when the user accepts the reminder
     */
    $scope.getAppointmentDetail = function() {
      if(!$scope.appointmentId){
        return;
      }
      ScheduleService.getAppointment($scope.appointmentId).then(
        function(event){
          $scope.reminderStatus = event.data.reminder_status;
        },
        function(error){
          NotificationService.error('Error Loading Appointment Detail',error);

        });

    };

    $scope.getAppointmentDetail();


    $scope.sendReminder = function() {
      ScheduleService.sendReminder($scope.appointmentId).then(
        function(response){
          $scope.reminderStatus = 'sent';
          $scope.reminderSentDate = new Date();
          $rootScope.$broadcast('appointmentUpdate');
          if (response.data.method) {
            NotificationService.success("Reminder Sent by " + response.data.method + " to " + response.data.to);
          }
          else {
            NotificationService.success('Reminder Sent');
          }
        },
        function(err){
          NotificationService.error('Error Sending Reminder',err);
        }
      );
    };

    $scope.getAllPatients = function() {
      PatientService.v3.list().then(
        function (response) {
          $scope.patients = $filter("orderBy")(response.data, ["1", "2", "3"])
          if ($scope.patient.id) {
            var idx = $scope.patients.findIndex(function(p) { return p[0] === $scope.patient.id })
            if (idx !== -1) {
              $scope.patients.unshift($scope.patients.splice(idx, 1)[0])
            }
          }
          $scope.sendReminderEnabled = $scope.patientSelectedAndCanReceiveReminder();
        },
        function (err) {
          NotificationService.error("Error loading patients list", err)
        }
      )
    };

    // Initial patient select from the database.
    $scope.getAllPatients();

    AuthenticationService.getPracticeAdminAndProviderAccounts(PrincipalService.getPracticeId(), true).then(
      function(data) {
        // Check that there providers in the database and providerId was not initialized to 0,
        // i.e. this is a new, not existing, appointment.
        $scope.providers = angular.fromJson(data.data);
        if ($scope.providers.length !== 0 && $scope.providerId === 0) {
          $scope.providerId = $scope.providers[0].id;
        }
        if (!$scope.providerId) {
          if ($scope.providers.length === 1) {
            $scope.providerId = $scope.providers[0].id;
          }
          else {
            var accountId = PrincipalService.getAccountId()
            var provider = $scope.providers.find(function(provider){
              return provider.id === accountId
            })
            if (provider) {
              $scope.providerId = provider.id
            }
          }
        }
      },
      function(err) {
        NotificationService.error('Error loading users list',err);
      });

    LocationsService.all().then(
      function(data) {
        // Check that there providers in the database and providerId was not initialized to 0,
        // i.e. this is a new, not existing, appointment.

        var locations = angular.fromJson(data.data);
        if (MultiLocationEnable) {
          $scope.operatories = locations.reduce(
            function(memo, location) {
              location.operatories.forEach(function(operatory) {
                memo.push(angular.extend({}, operatory, {time_zone: location.time_zone}))
              })
              return memo
            },
            []
          )
        }
        else {
          $scope.operatories = locations[0].operatories.map(function(operatory) {
            return angular.extend({}, operatory, {time_zone: locations[0].time_zone})
          })
        }

        if ( $scope.operatories.length) {
          if ($scope.operatoryId === 0) {
            $scope.operatoryId = $scope.operatories[0].id;
            $scope.currentOperatoryTZ = $scope.operatories[0].time_zone
          }
          else {
            var currentOperatory = $scope.operatories.find(function(operatory) {
              return operatory.id === +$scope.operatoryId
            })
            if (currentOperatory) {
              $scope.currentOperatoryTZ = currentOperatory.time_zone
            } else {
              $scope.currentOperatoryTZ = moment.tz.guess();
            }
            // HACK+HACK+HACK!! to honor currentOperatoryTZ TZ
            var dateInOperatoryTZ = moment(appointment.start).tz($scope.currentOperatoryTZ);
            if (dateInOperatoryTZ) {
              $scope.startDate = dateInOperatoryTZ.format($scope.momentDateFormat);
              $scope.startTime = new Date($scope.startTime.setHours(dateInOperatoryTZ.hours(), dateInOperatoryTZ.minutes()))
            }
            // HACK+HACK+HACK!! to honor currentOperatoryTZ TZ
          }
          $scope.timeChange();
        }
      },
      function(err) {
        NotificationService.error('Error loading Operatories list',err);
      });


    $scope.openAddPatientModal = function() {
      var patientCreatedFromPopupDeregisterFunc = $rootScope.$on('patientCreatedFromPopup',function(event,data){
        $scope.patient.id = data.id;
        var gender = data.gender ? data.gender.toUpperCase() : data.gender
        $scope.patients.unshift([
          data.id,
          data.last_name + ", " + data.first_name + (gender ? " (" + gender + ")" : ""),
          $filter("tel")(data.home_phone),
          $filter("date")(data.birth_date),
          data.is_archived,
          true,
          $filter("tel")(data.mobile_phone),
          data.prefer_sms_for_notifications,
          data.email,
        ])
      });

      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        size:'lg',
        templateUrl: 'src/common/patient-add.tpl.html',
        controller: 'PatientAddCtrl',
        resolve: {
          openPatientDetail: null,
          showGoToPatientDetail: function () {
            return false;
          },
          patientInfo: function () {
            return null;
          }
        }
      });

      modalInstance.result.then(function () {
        patientCreatedFromPopupDeregisterFunc();
      }, function () {
      });

    };

    $scope.onOperatoryChange = function() {
      var currentOperatory = $scope.operatories.find(function(operatory) {
        return operatory.id === +$scope.operatoryId
      })
      if (currentOperatory) {
        $scope.currentOperatoryTZ = currentOperatory.time_zone
      }
    }

    $scope.openStartDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.startDateOpened = true;
    };

    $scope.patientSelectedAndCanReceiveReminder = function() {
      if ($scope.patient && $scope.patient.id && $scope.patients) {
        var patientModel = $scope.patients.find(function(patient) {
          return $scope.patient.id === patient[0];
        });
        if (patientModel && patientModel[7] && patientModel[6].trim().length > 0) {
          return true;
        }
        if (patientModel && patientModel[8] && patientModel[8].trim().length > 0) {
          return true;
        }
      }
      return false;
    };

    // Calculates the start/end times whenever something is changed in the modal.
    $scope.timeChange = function() {
      $scope.endTime = calculateEndTime();
      if ($scope.currentOperatoryTZ) {
        $scope.appointmentString = (
          moment($scope.startTime).format($scope.momentTimeFormat) +  ' - ' +
          moment($scope.endTime).format($scope.momentTimeFormat)
        );
      }
      else {
        $scope.appointmentString = (
          moment.tz($scope.startTime, $scope.currentOperatoryTZ).format($scope.momentTimeFormat) +  ' - ' +
          moment.tz($scope.endTime, $scope.currentOperatoryTZ).format($scope.momentTimeFormat)
        );
      }
    };

    $scope.prepareAppointment = function () {
      // Take two datetime objects and sets the time
      // only elements of the first to the second, and
      // returns a string to send to the server.
      function prepDatetimeForJson(date, time) {
        var returnDate;
        if (typeof date === "string") {
          if ($scope.currentOperatoryTZ) {
            returnDate = moment.tz(date, $scope.momentDateFormat, $scope.currentOperatoryTZ)
          }
          else {
            returnDate = moment(date, $scope.momentDateFormat)
          }
        }
        else {
          returnDate = moment(date);
        }
        returnDate.hours(time.getHours());
        returnDate.minutes(time.getMinutes());
        return returnDate.toISOString();
      }

      // Use startDate in both instances, assume appoint is on the same day.
      var start = prepDatetimeForJson($scope.startDate, $scope.startTime);
      var end = prepDatetimeForJson($scope.startDate, $scope.endTime);
      /*jshint camelcase: false */
      return {
        provider: parseInt($scope.providerId),
        patient: parseInt($scope.patient.id),
        operatory: parseInt($scope.operatoryId),
        appointment_type: $scope.appointmentType,
        start_datetime: start,
        end_datetime: end,
        complaint: $scope.complaint,
        appointment_status: $scope.appointmentStatus,
        reminder_status: $scope.reminderStatus,
        reminder_sent_date: $scope.reminderSentDate
      };
    };

    $scope.createOrUpdate = function () {
      if($scope.isNewAppointment){
        $scope.create();
        return;
      }
      $scope.update();
    };

    // Creates a new appointment (for create new appointment modal).
    $scope.create = function () {
      var appointment = $scope.prepareAppointment();

      ScheduleService.create(appointment).then(
        function success(event) {
          $scope.appointmentId = event.data.id;
          NotificationService.success('Appointment added sucessfully');


          if($scope.sendReminderOnNewApp){
            $scope.sendReminder();
          }

          //don' wait to close the modal if a reminder need to be send
          $uibModalInstance.close();
        },
        function fail(err) {
          NotificationService.error('Error adding appointment',err);
        });
    };

    // Updates an existing appointment (for update existing appointment modal).
    $scope.update = function () {
      var appointment = $scope.prepareAppointment();
      ScheduleService.update($scope.appointmentId, appointment).then(
        function success() {
          NotificationService.success('Appointment updated sucessfully');
          $uibModalInstance.close();
        },
        function fail(err) {
          NotificationService.error('Error updating appointment',err);
        });
    };

    // Deletes an existing appointment (for update existing appointment modal).
    $scope.delete = function () {
      ScheduleService.delete($scope.appointmentId).then(
        function success() {
          NotificationService.success('Appointment deleted.');
          $uibModalInstance.close();
        },
        function fail(err) {
          NotificationService.error('Error deleting appointment',err);
        });
    };

    // Cancels the modal.
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    // Initialize endTime and appointmentString
    $scope.timeChange();
    
    $scope.$watch('appointmentType', function(newVal, oldVal) {
      if(newVal === "0") {
        $scope.doesApptDefaultExist = false;
      } else {
        $scope.doesApptDefaultExist = true;
      }

      if (newVal !== oldVal) {
        // set default duration based on the appointment type
        $scope.setDefaultDurationByAppointmentType(newVal);
      }
    });


    if(!$scope.duration && $scope.appointmentType){
      $scope.setDefaultDurationByAppointmentType($scope.appointmentType);
    }

  }

  angular.module('common.schedule-appointment-add', [])
    .controller('ScheduleAppointmentAddCtrl', scheduleAppointmentAddCtrl);
})();

(function() {
  'use strict';

  function scheduleTimeblockAddCtrl($scope, $uibModalInstance, $log, AuthenticationService, PrincipalService, LocationsService, NotificationService,
      ScheduleCommonService, TimeblockService, timeblock, CanCreateTimeBlock, CanUpdateTimeBlock, CanDeleteTimeBlock, MultiLocationEnable,$uibModal,LocalStorageService) {
    $log.debug('schedleTimeblockAddCtrl loaded');

    $scope.durationDefaults = ScheduleCommonService.getDurationDefaults();

    $scope.CanCreateTimeBlock = CanCreateTimeBlock;
    $scope.CanUpdateTimeBlock = CanUpdateTimeBlock;
    $scope.CanDeleteTimeBlock = CanDeleteTimeBlock;

    $scope.timeblockId = timeblock.timeblockId;
    $scope.isNewTimeblock = ($scope.timeblockId === 0);
    $scope.providerId = timeblock.providerId;
    $scope.operatoryId = timeblock.operatoryId;
    $scope.reason = timeblock.reason;
    $scope.duration = ScheduleCommonService.getAndAddDuration(timeblock.duration, $scope.durationDefaults);
    $scope.hasDiffStart = !!timeblock.rrule

    // Equivalent format for momentJs
    $scope.momentDateFormat = 'YYYY-MM-DD';
    // Time format
    $scope.momentTimeFormat = 'h:mm A';

    $scope.timeblockId = timeblock.timeblockId;
    $scope.providerId = timeblock.providerId;
    $scope.reason = timeblock.reason;

    $scope.startDate = '';
    $scope.startTime = moment().hour(8).minute(0).toDate();

    $scope.repeatOptions = LocalStorageService.get('repeatOptions');
    $scope.repeatType = timeblock.repeat ? timeblock.repeat : $scope.repeatOptions[0];
    
    var res = ScheduleCommonService.initVariables($scope.repeatOptions, timeblock, 
      $scope.repeatType, LocalStorageService, $scope.momentDateFormat)
    $scope.repeatOptions = res.repeatOptions
    $scope.repeatType = res.repeatType
    $scope.startDate = res.startDate
    $scope.startTime = res.startTime
    $scope.duration = res.duration

    $scope.providers = [];

    ScheduleCommonService.AuthServiceHelper(AuthenticationService, PrincipalService, NotificationService, $scope.providerId)
    .then(function(temp) {
      $scope.providerId = temp.providerId;
      $scope.providers = temp.providers;
    })

    $scope.onOperatoryChange = function () {
      $scope.currentOperatoryTZ = ScheduleCommonService.onOperatoryChange($scope.operatories, $scope.operatoryId)
    }
    
    ScheduleCommonService.LocServiceHelper(LocationsService, MultiLocationEnable,$scope.operatoryId, 
      $scope.currentOperatoryTZ, timeblock, $scope.startDate, $scope.startTime, $scope.momentDateFormat, 
      $scope.duration, $scope.momentTimeFormat, $scope.timeblockString, NotificationService)
      .then(function(res) {
        $scope.currentOperatoryTZ = res.currentOperatoryTZ;
        $scope.operatories = res.operatories;
        $scope.operatoryId = res.operatoryId;
        $scope.timeblockString = res.timeblockString;
        $scope.startDate = res.startDate;
        $scope.startTime = res.startTime;
      })

    // Calculates the start/end times whenever something is changed in the modal.
    $scope.timeChange = function() {
      $scope.timeblockString = ScheduleCommonService.timeChange(
        $scope.startTime, $scope.duration, $scope.currentOperatoryTZ, $scope.momentTimeFormat, $scope.timeblockString
      )
    };

    $scope.openStartDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.startDateOpened = true;
    };

    $scope.prepareTimeblock = function () {
      return ScheduleCommonService.prepareTimeblock($scope.startDate, $scope.startTime, 
        $scope.currentOperatoryTZ, $scope.momentDateFormat, $scope.repeatType, 
        $scope.providerId, $scope.operatoryId, $scope.duration, $scope.reason, false)
    };

    $scope.canEditTimeBlock = function () {
      return PrincipalService.hasPermission('timeblock_modify');
    };

    $scope.create = function () {
      ScheduleCommonService.create(TimeblockService, NotificationService, 
        $uibModalInstance, $scope.prepareTimeblock(), 'Timeblock')
    };

    $scope.update = function () {
      ScheduleCommonService.update(TimeblockService, NotificationService, $uibModalInstance,
        $scope.timeblockId, $scope.prepareTimeblock(), 'Timeblock')
    };

    $scope.delete = function () {
      ScheduleCommonService.deleteNotification(TimeblockService, NotificationService, $uibModalInstance, 
        $scope.timeblockId, 'Timeblock', $uibModal, $scope.animationsEnabled)
    };

    // Cancels the modal.
    $scope.cancel = function () {
      ScheduleCommonService.cancel($uibModalInstance)
    };

    // Initialize endTime and timeblockString
    $scope.timeChange();

    $scope.openCustomRepeatModal = function () {
      ScheduleCommonService.openCustomRepeatModal(
        $uibModal, LocalStorageService, $scope.repeatType, $scope.animationsEnabled, $scope
      )
    };
  }

  angular.module('common.schedule-timeblock-add', [])
    .controller('ScheduleTimeblockAddCtrl', scheduleTimeblockAddCtrl);
})();

(function () {
  'use strict';

  function scheduleTimeblockAddCtrl($scope, $uibModalInstance, $log, ScheduleCommonService, AuthenticationService, PrincipalService, LocationsService, NotificationService,
    TimeblockService, timeblock, CanCreateTimeBlock, CanUpdateTimeBlock, CanDeleteTimeBlock, MultiLocationEnable, $uibModal, LocalStorageService) {
    $log.debug('schedleTimeblockAddCtrl loaded');

    $scope.durationDefaults = ScheduleCommonService.getDurationDefaults();
    
    $scope.CanCreateTimeBlock = CanCreateTimeBlock;
    $scope.CanUpdateTimeBlock = CanUpdateTimeBlock;
    $scope.CanDeleteTimeBlock = CanDeleteTimeBlock;

    $scope.backgroundEventId = timeblock.timeblockId;
    $scope.providerId = timeblock.providerId;
    $scope.operatoryId = timeblock.operatoryId;
    $scope.reason = timeblock.reason;

    $scope.duration = ScheduleCommonService.getAndAddDuration(timeblock.duration, $scope.durationDefaults);
    $scope.hasDiffStart = !!timeblock.rrule
    
    $scope.isNewTimeblock = ($scope.backgroundEventId === 0 || $scope.backgroundEventId === undefined)
    $scope.recur = undefined;

    // Equivalent format for momentJs
    $scope.momentDateFormat = 'YYYY-MM-DD';
    // Time format
    $scope.momentTimeFormat = 'h:mm A';

    $scope.startDate = '';
    $scope.startTime = moment().hour(8).minute(0).toDate();

    $scope.repeatOptions = LocalStorageService.get('repeatOptions');
    $scope.repeatType = timeblock.repeat ? timeblock.repeat : $scope.repeatOptions[0];

    var res = ScheduleCommonService.initVariables($scope.repeatOptions, timeblock, 
      $scope.repeatType, LocalStorageService, $scope.momentDateFormat)
    $scope.repeatOptions = res.repeatOptions
    $scope.repeatType = res.repeatType
    $scope.startDate = res.startDate
    $scope.startTime = res.startTime
    $scope.duration = res.duration

    $scope.providers = [];

    ScheduleCommonService.AuthServiceHelper(AuthenticationService, PrincipalService, NotificationService, $scope.providerId)
    .then(function(res) {
      $scope.providerId = res.providerId;
      $scope.providers = res.providers;
    })

    $scope.onOperatoryChange = function () {
      $scope.currentOperatoryTZ = ScheduleCommonService.onOperatoryChange($scope.operatories, $scope.operatoryId)
    }

    ScheduleCommonService.LocServiceHelper(LocationsService, MultiLocationEnable,
      $scope.operatoryId, $scope.currentOperatoryTZ, timeblock, $scope.startDate, $scope.startTime, $scope.momentDateFormat, 
      $scope.duration, $scope.momentTimeFormat, $scope.timeblockString, NotificationService)
      .then(function(res) {
        $scope.currentOperatoryTZ = res.currentOperatoryTZ;
        $scope.operatories = res.operatories;
        $scope.operatoryId = res.operatoryId;
        $scope.timeblockString = res.timeblockString;
        $scope.startDate = res.startDate;
        $scope.startTime = res.startTime;
      })

    // Calculates the start/end times whenever something is changed in the modal.
    $scope.timeChange = function() {
      $scope.timeblockString = ScheduleCommonService.timeChange(
        $scope.startTime, $scope.duration, $scope.currentOperatoryTZ, $scope.momentTimeFormat, $scope.timeblockString
      )
    };

    $scope.openStartDate = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.startDateOpened = true;
    };

    $scope.prepareBackgroundEvent = function () {
      return ScheduleCommonService.prepareTimeblock($scope.startDate, $scope.startTime, 
        $scope.currentOperatoryTZ, $scope.momentDateFormat, $scope.repeatType, 
        $scope.providerId, $scope.operatoryId, $scope.duration, $scope.reason, true)
    };

    $scope.canEditTimeBlock = function () {
      return PrincipalService.hasPermission('timeblock_modify');
    };

    $scope.create = function () {
      ScheduleCommonService.create(TimeblockService,NotificationService, 
        $uibModalInstance, $scope.prepareBackgroundEvent(), 'Background event')
    };

    $scope.update = function () {
      ScheduleCommonService.update(TimeblockService, NotificationService, $uibModalInstance, 
        $scope.backgroundEventId, $scope.prepareBackgroundEvent(), 'Background event')
    };

    $scope.delete = function (args) {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: "src/common/schedule-confirm.partial.tpl.html",
        controller: "scheduleConfirmationCtrl",
        resolve: { message: () => "Deleting this event will delete all recurring events" }
      })
    
      modalInstance.result.then(
        function (result) {
          if(result === 'cancel') {
            args.revert();
          } 
  
          if(result === 'save') {
            ScheduleCommonService.deleteNotification(TimeblockService, NotificationService, $uibModalInstance, 
              $scope.backgroundEventId, 'Background event')
          }
        }
      )
    };

    // Cancels the modal.
    $scope.cancel = function () {
      ScheduleCommonService.cancel($uibModalInstance)
    };

    // Initialize endTime and timeblockString
    $scope.timeChange();

    $scope.openCustomRepeatModal = function () {
      ScheduleCommonService.openCustomRepeatModal(
        $uibModal, LocalStorageService, $scope.repeatType, $scope.animationsEnabled, $scope
      )
    };
  }

  angular.module('common.schedule-background-add', [])
    .controller('ScheduleBackGroundAddCtrl', scheduleTimeblockAddCtrl);
})();

(function () {
  'use strict';

  function scheduleConfirmationCtrl($scope, $uibModalInstance, message) {
    $scope.message = message ? message: "Changing this event will change all recurring events"
    $scope.result = function (status) {
      $uibModalInstance.close(status)
    }
  }

  angular.module('common.schedule-confirm-ctrl', [])
    .controller('scheduleConfirmationCtrl', scheduleConfirmationCtrl);
})();

(function () {
  'use strict';

  function scheduleSettingsCtrl($scope, $uibModalInstance, message, resources, LocalStorageService) {
    $scope.message = message ? message: "Changing this event will change all recurring events"
    $scope.items = _.sortBy(resources, 'order');
    $scope.result = function (args) {
      if(args === 'save') {
        var orderMap = LocalStorageService.get('orderMap') ? LocalStorageService.get('orderMap') : {};
        var result = _.map($scope.items, function(item, index) {
          var itemKey = item.id + "_" + item.title
          orderMap[itemKey] = index
          return Object.assign({}, item, { order: index });
        })
        LocalStorageService.set('orderMap', orderMap)
        $uibModalInstance.close(result)
      }
      $uibModalInstance.close()

    }
  
  }

  angular.module('common.schedule-settings-ctrl', [angularDragula(angular)])
    .controller('scheduleSettingsCtrl', scheduleSettingsCtrl);
})();

(function() {
  'use strict';

  function messagesCtrl($log) {

    $log.debug('Messages loaded');
  }

  angular.module('common.messages', [])
    .controller('MessagesCtrl', messagesCtrl);
})();

(function() {
  "use strict"

  angular.module("common.reminders", [])

  .component("remindersList", {
    templateUrl: "src/common/reminders-list.tpl.html",
    controller: function($rootScope, NotificationService, ReminderService) {
      var $ctrl = this;
      $ctrl.CanListReminder = $rootScope.resolved.CanListReminder
      $ctrl.showPatientName = $rootScope.resolved.remindersList.showPatientName

      $ctrl.expired = true
      $ctrl.uncompleted = true
      $ctrl.loading = false
      $ctrl.reminders = []
      $ctrl.today = moment.utc().format("YYYY-MM-DD")

      Object.defineProperties($ctrl, {
        filters: {
          get: function() {
            return angular.extend(
              {},
              $rootScope.resolved.remindersList.filters,
              {
                date__lte: $ctrl.expired ? $ctrl.today : undefined,
                is_completed: $ctrl.uncompleted ? false : undefined,
              }
            )
          }
        },
      })

      $ctrl.$onInit = function() {
        $ctrl.loadReminders()
      }

      $ctrl.loadReminders = function() {
        $ctrl.loading = true
        ReminderService.list($ctrl.filters).then(
          function(response) {
            $ctrl.today = moment.utc().format("YYYY-MM-DD")
            $ctrl.loading = false
            $ctrl.reminders = response.data
          },
          function(response) {
            $ctrl.loading = false
            NotificationService.error("Error Loading Reminders", response)
          }
        )
      }
    },
  })

  .component("remindersManage", {
    templateUrl: "src/common/reminders-manage.tpl.html",
    controller: function($rootScope, $uibModal, NotificationService, PrincipalService, ReminderService) {
      var $ctrl = this
      $ctrl.CanListReminder = $rootScope.resolved.CanListReminder
      $ctrl.CanCreateReminder = $rootScope.resolved.CanCreateReminder
      $ctrl.CanUpdateReminder = $rootScope.resolved.CanUpdateReminder
      $ctrl.CanRetrieveReminder = $rootScope.resolved.CanRetrieveReminder
      $ctrl.CanDeleteReminder = $rootScope.resolved.CanDeleteReminder

      $ctrl.expired = false
      $ctrl.uncompleted = false
      $ctrl.loading = false
      $ctrl.reminders = []
      $ctrl.today = moment.utc().format("YYYY-MM-DD")

      Object.defineProperties($ctrl, {
        filters: {
          get: function() {
            return angular.extend(
              {},
              $rootScope.resolved.remindersManage.filters,
              {
                date__lte: $ctrl.expired ? $ctrl.today : undefined,
                is_completed: $ctrl.uncompleted ? false : undefined,
              }
            )
          }
        },
      })

      $ctrl.$onInit = function() {
        if ($rootScope.resolved.remindersManage) {
          $ctrl.loadReminders()
        }
      }

      $ctrl.loadReminders = function() {
        $ctrl.loading = true
        ReminderService.list($ctrl.filters).then(
          function(response) {
            $ctrl.today = moment.utc().format("YYYY-MM-DD")
            $ctrl.loading = false
            $ctrl.reminders = response.data
          },
          function(response) {
            $ctrl.loading = false
            NotificationService.error("Error Loading Reminders", response)
          }
        )
      }

      $ctrl.reminderCreateModal = function() {
        $uibModal.open({
          bindToController: true,
          controllerAs: "$mctrl",
          keyboard: true,
          size: "md",
          templateUrl: "#reminder-create-modal",
          controller: function($scope, ReminderService) {
            var $mctrl = this
            $mctrl.reminder = {
              encounter: $rootScope.resolved.remindersManage.filters.encounter_id,
            }
            $mctrl.save = function() {
              $mctrl.loading = true
              ReminderService.create($mctrl.reminder).then(
                function(response) {
                  $mctrl.loading = false
                  $scope.$close(response.data)
                },
                function(response) {
                  $mctrl.loading = false
                  NotificationService.error("Error Saving Reminder", response)
                }
              )
            }
          },
        })
        .result.then(
          function(reminder) {
            $ctrl.reminders.push(reminder)
          }
        )
      }

      $ctrl.reminderDeleteModal = function(reminder) {
        $uibModal.open({
          bindToController: true,
          controllerAs: "$mctrl",
          keyboard: true,
          size: "md",
          templateUrl: "#reminder-delete-modal",
          controller: function($scope, ReminderService) {
            var $mctrl = this
            $mctrl.save = function() {
              $mctrl.loading = true
              ReminderService.destroy(reminder.id).then(
                function() {
                  $mctrl.loading = false
                  $scope.$close(reminder.id)
                },
                function(response) {
                  $mctrl.loading = false
                  NotificationService.error("Error Deleting Reminder", response)
                }
              )
            }
          },
        }).result.then(
          function(id) {
            var idx = $ctrl.reminders.findIndex(function(reminder) { return reminder.id === id })
            if (!!~idx) {
              $ctrl.reminders.splice(idx, 1)
            }
          }
        )
      }

      $ctrl.reminderDetailModal = function(reminder) {
        $uibModal.open({
          bindToController: true,
          controllerAs: "$mctrl",
          keyboard: true,
          templateUrl: "#reminder-detail-modal",
          controller: function($scope, ReminderService) {
            var $mctrl = this
            $mctrl.CanUpdateReminder = $ctrl.CanUpdateReminder
            $mctrl.reminder = angular.extend({}, reminder)
            $mctrl.insertResolutionHeader = function() {
              insertResolutionHeader($mctrl.reminder, "resolution")
            }
            $mctrl.save = function() {
              $mctrl.loading = true
              ReminderService.update($mctrl.reminder).then(
                function(response) {
                  $mctrl.loading = false
                  $scope.$close(response.data)
                },
                function(response) {
                  $mctrl.loading = false
                  NotificationService.error("Error Updating Reminder", response)
                }
              )
            }
          },
        }).result.then(
          function(new_reminder) {
            angular.extend(reminder, new_reminder)
          }
        )
      }

      $ctrl.reminderResolveModal = function(reminder) {
        $uibModal.open({
          bindToController: true,
          controllerAs: "$mctrl",
          keyboard: true,
          templateUrl: "#reminder-resolve-modal",
          controller: function($scope, ReminderService) {
            var $mctrl = this
            $mctrl.reminder = angular.extend({}, {
              id: reminder.id,
              is_completed: true,
              resolution: reminder.resolution,
            })
            insertResolutionHeader($mctrl.reminder, "resolution")
            $mctrl.save = function() {
              $mctrl.loading = true
              ReminderService.update($mctrl.reminder).then(
                function(response) {
                  $mctrl.loading = false
                  $scope.$close(response.data)
                },
                function(response) {
                  $mctrl.loading = false
                  NotificationService.error("Error Completing Reminder", response)
                }
              )
            }
          },
        }).result.then(
          function(new_reminder) {
            angular.extend(reminder, new_reminder)
          }
        )
      }

      function insertResolutionHeader(obj, prop) {
        var user = PrincipalService.getIdentity()
        if (obj[prop] === undefined) {
          obj[prop] = ""
        }
        var value = obj[prop].trim()
        if (value) {
          obj[prop] += "\n"
        }
        obj[prop] += ["[", user.last_name, ", ", user.first_name, " ", moment().local().format("L LTS ZZ"), "]: "].join("")
      }
    },
  })

})();

(function() {
  "use strict"

  angular.module("common.patient-files", [])

  .component("patientFiles", {
    controller: PatientFilesController,
    templateUrl: "src/common/patient-files.tpl.html",
    bindings: { $transition$: '<' },
  })

  function PatientFilesController(
    $q,
    $rootScope,
    $scope,
    $uibModal,
    NotificationService,
    PatientFileService
    ) {

    var $ctrl = this

    $ctrl.$onInit = function() {
      $ctrl.canCreate = $rootScope.resolved.CanCreatePatientFile
      $ctrl.canDelete = $rootScope.resolved.CanDeletePatientFile
      $ctrl.canList = $rootScope.resolved.CanListPatientFile
      $ctrl.canRetrieve = $rootScope.resolved.CanRetrievePatientFile

      $ctrl.loading = false
      $ctrl.apiStorage = $rootScope.resolved.apiMeta.storage
      $ctrl.patientId = +this.$transition$.params().patientid
      $ctrl.list()
    }

    $ctrl.create = function(files) {
      // for feature debug
      // localforage.setItem("patient-files", files)
      var patient_files = files.map(function(file) { return { $file_archive: file } })
      Array.prototype.push.apply($ctrl.patient_files, patient_files)
      return PatientFileService.create(patient_files, $ctrl.patientId, $ctrl.apiStorage).then(
        function(created_patient_files) {
          created_patient_files.forEach(function(patient_file) {
            angular.forEach(patient_file, function(value, key) {
              if (key.startsWith("$") && key !== "$errors") {
                delete patient_file[key]
              }
            })
          })
        }
      )
    }

    $ctrl.destroy = function(patient_file) {
      $uibModal
        .open({
          bindToController: true,
          controllerAs: "$ctrl",
          templateUrl: "#patient-file-destroy",
          controller: function($scope) {
            var $mctrl = this
            $mctrl.destroy = function() {
              $mctrl.loading = true
              $mctrl.protected_objects = null
              PatientFileService.destroy(patient_file).then(
                function() {
                  $mctrl.loading = false
                  $mctrl.protected_objects = null
                  $scope.$close()
                },
                function(response) {
                  $mctrl.loading = false
                  if (response.data.protected_objects && response.data.protected_objects.length) {
                    NotificationService.error("Error Deleting File", {"data": "Because is being used"})
                    $mctrl.protected_objects = response.data.protected_objects
                  }
                  else {
                    NotificationService.error("Error Deleting File", response)
                  }
                }
              )
            }
          },
        })
        .result.then(
          function() {
            var idx = $ctrl.patient_files.findIndex(function(item) { return item.id === patient_file.id })
            if (idx !== -1) {
              $ctrl.patient_files.splice(idx, 1)
            }
          }
        )
    }

    $ctrl.download = function(patient_file) {
      patient_file.$loading = true
      return PatientFileService.download(patient_file).then(
        function(blob) {
          var link = angular.element("#patient-file-dwl")
          link.attr("href", URL.createObjectURL(blob))
          link.attr("download", blob.name)
          link[0].click()
          delete patient_file.$loading
        },
        function(response) {
          NotificationService.error('Error Downloading File', response)
        }
      )
    }

    $ctrl.list = function() {
      $ctrl.loading = true
      if ($ctrl.patientId) {
        $ctrl.params = {patient_id: $ctrl.patientId}
      }
      else {
        $ctrl.params = null
      }
      if ($ctrl.canList && $ctrl.params) {
        $ctrl.loading = true
        return PatientFileService.list($ctrl.params).then(
          function(response) {
            $ctrl.patient_files = response.data
            $ctrl.loading = false
          },
          function(response) {
            $ctrl.patient_files = []
            $ctrl.loading = false
            NotificationService.error('Error Loading Patient Files', response)
          }
        )
      }
      else {
        $ctrl.patient_files = []
        // NotificationService.error("Error $ctrl.canList && $ctrl.params")
      }
    }

    $ctrl.retrieve = function(patient_file) {
      $uibModal
        .open({
          bindToController: true,
          controllerAs: "$ctrl",
          templateUrl: "#patient-file-retrieve",
          resolve: {
            retrieved_patient_file: function() {
              return PatientFileService.retrieve(patient_file).then(
                function(response) {
                  return response.data
                },
                function(response) {
                  NotificationService.error("Error Retrieving File", response)
                }
              )
            }
          },
          controller: function($scope, retrieved_patient_file) {
            var $mctrl = this
            $mctrl.canUpdate = $rootScope.resolved.CanUpdatePatientFile
            $mctrl.patient_file = retrieved_patient_file
            $mctrl.download = function() {
              $mctrl.loading = true
              $ctrl.download(patient_file).then(
                function() {
                  $mctrl.loading = false
                }
              )
            }
            $mctrl.update = function() {
              $mctrl.loading = true
              PatientFileService.update(retrieved_patient_file).then(
                function(response) {
                  $mctrl.loading = false
                  angular.extend(patient_file, response.data)
                  $scope.$close()
                },
                function(response) {
                  $mctrl.loading = false
                  NotificationService.error("Error Updating File", response)
                }
              )
            }
          }
        })
    }

    $ctrl.scan = function() {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        keyboard: true,
        size: "lg",
        templateUrl: "src/common/patient-files-scan-modal.html",
        resolve: { dwtContainerId: function() { return "dwt-container-patient-files" } },
        controller: function PatientImageEditorScanModalController(
          $cookies,
          $http,
          $localStorage,
          $log,
          $q,
          $scope,
          $uibModalInstance,
          ArchivesService,
          dwtContainerId,
          NotificationService
          ) {
      
          var $ctrl = this
      
          var dwtContainerIdStorageDefaults = {}
      
          dwtContainerIdStorageDefaults[dwtContainerId] = {
            sources: {
              all: [],
              latest: null,
              ip: Dynamsoft.Lib.product.ip,
            },
            showUI: true,
          }
      
          $ctrl.$onInit = function() {
            $ctrl.localStorage = $localStorage.$default(dwtContainerIdStorageDefaults)
            $ctrl.settings = $ctrl.localStorage[dwtContainerId]
            $ctrl.settings.showUI = true
            $ctrl.dwt = null
            $ctrl.loading = "init"
            $ctrl.images = []
            $ctrl.currentImageIdx = null
            // Dynamsoft.DWT.OnWebTwainPreExecute = noop
            // Dynamsoft.DWT.OnWebTwainPostExecute = noop
            $uibModalInstance.rendered.then(function() {
              Dynamsoft.DWT.CreateDWTObject(
                dwtContainerId,
                null,
                null,
                null,
                function(dwt) {
                  dwt.BackgroundFillColor = 0x000000
                  dwt.Viewer.fitWindow()
                  dwt.Viewer.singlePageMode = true
                  $scope.$applyAsync(function() {
                    $ctrl.dwt = dwt
                    $ctrl.loadSources()
                    // if (autoScan) {
                    //   $ctrl.scan()
                    // }
                    $ctrl.loading = false
                  })
                },
                function(errorString) {
                  $scope.$applyAsync(function() {
                    $ctrl.loading = false
                    NotificationService.error("Error Creating DWTObject", errorString)
                  })
                }
              )
            })
            $scope.$on("$destroy", function() {
              Dynamsoft.DWT.DeleteDWTObject(dwtContainerId)
            })
          }
      
          $ctrl.load = function(idx) {
            $ctrl.images[idx].$loading = true
            return getImageInBufferAtIndex(idx).then(
              function(image) {
                delete $ctrl.images[idx].$error
                angular.extend($ctrl.images[idx], image)
                $ctrl.images[idx].$loading = false
              },
              function(error) {
                $ctrl.images[idx].$loading = false
                NotificationService.error("getImageInBufferAtIndex Error", {data: error})
              }
            )
          }
      
          $ctrl.loadSources = function(reload) {
            if (!reload || $ctrl.dwt.CloseSourceManager()) {
              if (!reload || $ctrl.dwt.OpenSourceManager()) {
                var sources = Array.apply(null, Array($ctrl.dwt.SourceCount)).map(function (_, idx) {
                  return {idx:idx, name:$ctrl.dwt.GetSourceNameItems(idx)}
                })
                var cmp_fn = function(s) {
                  return [s.idx, s.name]
                }
                if (JSON.stringify(sources.map(cmp_fn)) !== JSON.stringify($ctrl.settings.sources.all.map(cmp_fn))) {
                  $ctrl.settings.sources.all = sources
                  $ctrl.settings.sources.latest = sources[0]
                  $ctrl.saveSettings()
                }
                if (reload) {
                  NotificationService.success("Updated Devices")
                }
              }
              else {
                NotificationService.error("OpenSourceManager Error", {data: getLastError()})
              }
            }
            else {
              NotificationService.error("CloseSourceManager Error", {data: getLastError()})
            }
          }
      
          $ctrl.saveSettings = function() {
            $localStorage[dwtContainerId] = $ctrl.settings
          }
      
          $ctrl.scan = function() {
            if ($ctrl.dwt.SelectSourceByIndex($ctrl.settings.sources.latest.idx)) {
              if ($ctrl.dwt.OpenSource()) {
                $ctrl.dwt.IfAppendImage = true
                $ctrl.dwt.IfShowIndicator = $ctrl.settings.showUI
                $ctrl.dwt.IfShowProgressBar = $ctrl.settings.showUI
                $ctrl.dwt.IfShowUI = $ctrl.settings.showUI
                $ctrl.loading = true
                $ctrl.dwt.AcquireImage(
                  function() {
                    var indexes = Array.apply(null, Array($ctrl.dwt.HowManyImagesInBuffer - $ctrl.images.length)).map(
                      function (_, idx0) {
                        return $ctrl.images.length + idx0
                      }
                    )
                    var tasks = indexes.map(function(idx) {
                      return function() {
                        return getImageInBufferAtIndex(idx).then(function(image) {
                          angular.extend(image, {name: "DOC_" + moment().format("YYYYMMDDHH_mmssSSS")})
                          $ctrl.images.push(image)
                        })
                      }
                    })
                    $q.serial(tasks).then(function() {
                      $ctrl.dwt.CloseSource()
                      $scope.$applyAsync(function() {
                        $ctrl.currentImageIdx = $ctrl.images.length - 1
                        $ctrl.loading = false
                      })
                    })
                  },
                  function(errorCode, errorString) {
                    $ctrl.loading = false
                    if ([-1032].indexOf(errorCode) !== -1) {
                      NotificationService.warning(errorString)
                    }
                    else {
                      NotificationService.error("AcquireImage Error", {data:{code:errorCode, msg:errorString}})
                    }
                  }
                )
              }
              else {
                NotificationService.error("OpenSource Error", {data: getLastError()})
              }
            }
            else {
              NotificationService.error("SelectSourceByIndex Error", {data: getLastError()})
            }
          }
      
          $ctrl.remove = function(idx) {
            $ctrl.images[idx].$loading = true
            if ($ctrl.dwt.RemoveImage(idx)) {
              $ctrl.images.splice(idx, 1)
              $ctrl.currentImageIdx = $ctrl.images.length ? Math.min(idx, $ctrl.images.length - 1) : null
              $ctrl.images[idx].$loading = false
            }
            else {
              $ctrl.images[idx].$loading = false
              NotificationService.error("RemoveImage Error", {data: getLastError()})
            }
          }
      
          $ctrl.rotate = function(idx, direction) {
            $ctrl.loading = true
            if ($ctrl.dwt["Rotate" + direction](idx)) {
              return getImageInBufferAtIndex(idx).then(
                function(image) {
                  delete $ctrl.images[idx].$error
                  angular.extend($ctrl.images[idx], image)
                  $ctrl.loading = false
                },
                function(error) {
                  $ctrl.loading = false
                  NotificationService.error("ConvertToBlob Error", {data: error})
                }
              )
            }
            else {
              $ctrl.images[idx].$loading = false
              NotificationService.error("Rotate Error", {data: getLastError()})
            }
          }
      
          function getImageInBufferAtIndex(idx) {
            return $q.when()
              .then(function() { 
                return $q(function(resolve) {
                  $ctrl.dwt.ConvertToBlob(
                    [idx],
                    Dynamsoft.DWT.EnumDWT_ImageType.IT_PNG,
                    function(file) {
                      resolve({
                        img: URL.createObjectURL(file),
                      })
                    },
                    function(errorCode, errorString) {
                      NotificationService.error("ConvertToBlob Error", {data:{code:errorCode, msg:errorString}})
                      resolve({
                        img: "/assets/images/no-thumb.jpg",
                        $error: {code:errorCode, msg:errorString},
                      })
                    }
                  )
                })
              })
              .then(function(acc) {
                return $q(function(resolve) {
                  $ctrl.dwt.ConvertToBlob(
                    [idx],
                    Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF,
                    function(file) {
                      acc.pdf = file
                      resolve(acc)
                    },
                    function(errorCode, errorString) {
                      NotificationService.error("ConvertToBlob Error", {data:{code:errorCode, msg:errorString}})
                      acc.pdf = null
                      acc.$error = {code:errorCode, msg:errorString}
                      resolve(acc)
                    }
                  )
                })
              });
          }
      
          function getLastError() {
            return {
              code: $ctrl.dwt.ErrorCode,
              msg: $ctrl.dwt.ErrorString
            }
          }
      
          // function noop() {}
      
        },
      }).result.then(
        function (objs) { 
          $ctrl.create(objs.map(function(obj) {
            obj.pdf.name = obj.name + ".pdf"
            return obj.pdf
          }))
        },
        function () {}
      )
    }

    // for feature debug
    $ctrl.uploadStored = function() {
      localforage.getItem("patient-files").then(function(files) {
        $scope.$applyAsync(function() { if (files) { $ctrl.create(files) } })
      })
    }

  }

})();

(function() {
  'use strict';

  function reportsCtrl($log) {
    $log.debug('Reports loaded');
  }

  angular.module('common.reports', [])
    .controller('ReportsCtrl', reportsCtrl);
})();

(function() {
  'use strict';

  function analyticsCtrl($log, $q, $scope, $state, AuthenticationService, PrincipalService, NotificationService) {
    $log.debug('Analytics loaded');
    $scope.$state = $state;

	$q.all([
   		AuthenticationService.getPractice(PrincipalService.getPracticeId())
	])
	.then(function(responses) {
        $scope.practice = angular.fromJson(responses[0].data);
	}, function(err) {
      NotificationService.error('Error loading practice information',err);
	});


  }

  angular.module('common.analytics', [])
    .controller('AnalyticsCtrl', analyticsCtrl);
})();

(function () {
  "use strict"

  angular
    .module("common.analytics")

    .component("analyticsActiveClaims", {
      bindings: { $transition$: "<" },
      controller: Controller,
      templateUrl: "src/common/analytics-active-claims-next.tpl.html",
    })

  function Controller(
    $filter,
    $q,
    AnalyticsService,
    AnalyticsServiceNext,
    NotificationService,
    PrincipalService,
    uiGridConstants
  ) {
    var $ctrl = this

    $ctrl.$onInit = function () {
      $ctrl.loading = false
      $ctrl.uiGrid = {
        columnDefs: [
          {
            cellTemplate: [
              '<div class="ui-grid-cell-contents">',
              '  <a class="dis-ib" ui-sref="insurance({ patientid:row.entity.patient.id })" target="_blank">',
              '    {{ row.entity.patient.name }}',
              '  </a>',
              '  <div class="pull-right p-r-xs" ng-if="!row.entity.claim">',
              '    Unclaimed',
              '  </div>',
              '</div>',
            ].join(""),
            displayName: "Patient Name",
            field: "patient.name",
            name: "patient-name",
          },
          {
            displayName: "Payer Name",
            field: "plan.payer.name",
            maxWidth: 300,
            name: "plan-payer-name",
          },
          {
            cellClass: "text-center",
            cellTemplate: [
              '<div class="ui-grid-cell-contents">',
              '  <a ng-if="row.entity.claim" ui-sref="claimdetail({ patientid:row.entity.patient.id, claimid:row.entity.claim.id })" target="_blank">',
              '    {{ row.entity.claim.number }}',
              '  </a>',
              '</div>',
            ].join(""),
            displayName: "Claim Number",
            field: "claim.number",
            maxWidth: 150,
            name: "claim-number",
          },
          {
            cellClass: "text-center",
            displayName: "Claim Date",
            field: "claim.date",
            maxWidth: 150,
            name: "claim-date",
            type: "date",
          },
          {
            aggregationHideLabel: true,
            aggregationType: uiGridConstants.aggregationTypes.sum,
            cellClass: "text-right",
            cellFilter: "currency:'$':2",
            displayName: "Total (USD)",
            field: "claim.total",
            footerCellClass: "text-right",
            footerCellFilter: "currency",
            maxWidth: 150,
            name: "claim-total",
            type: "number",
          },
          {
            aggregationHideLabel: true,
            aggregationType: uiGridConstants.aggregationTypes.sum,
            cellClass: "text-right",
            cellFilter: "currency:'$':2",
            displayName: "Balance (USD)",
            field: "balance()",
            footerCellClass: "text-right",
            footerCellFilter: "currency",
            maxWidth: 150,
            name: "balance",
            type: "number",
          },
          {
            cellClass: "text-center",
            displayName: "Payer Phone",
            field: "plan.phone",
            maxWidth: 150,
            name: "plan-phone",
          },
          {
            displayName: "Provider Name",
            field: "treatments.provider.name",
            maxWidth: 200,
            name: "treatments-provider-name",
            visible: false,
          },
          {
            displayName: "Location Name",
            field: "treatments.location.name",
            maxWidth: 200,
            name: "treatments-location-name",
            visible: false,
          },
        ],
        columnFunc: {
          compare: new Intl.Collator().compare,
          balance: function() {
            if (this.claim) {
              return this.claim.amount_remaining
            }
            if (this.unclaim) {
              return this.unclaim.total
            }
          }
        },
        enableFiltering: true,
        enableGridMenu: true,
        exporterCsvFilename: "ActiveClaims.csv",
        exporterExcelFilename: "ActiveClaims.xlsx",
        exporterPdfFilename: "ActiveClaims.pdf",
        exporterPdfFooter: function(currentPage, pageCount) { return currentPage.toString() + " of " + pageCount },
        exporterPdfHeader: "Active Claims",
        showColumnFooter: true,
        showGridFooter: true,
      }
      $ctrl.load()
    }

    $ctrl.load = function() {
      $ctrl.loading = true
      $q.allSettled([
        AnalyticsServiceNext.getClaimCompleted(),
        AnalyticsServiceNext.getClaimUnclaimed(),
      ]).then(
        function(responses) {
          var data = []
          responses.forEach(function(response) {
            if (response.state === "fulfilled") {
              data.push.apply(data, angular.copy(response.value.data))
            }
          })
          data.sort(function(datum1, datum2) {
            return $ctrl.uiGrid.columnFunc.compare(datum1.patient.name, datum2.patient.name)
          })
          var todate = $filter("date")
          var totel = $filter("tel")
          data.forEach(function(datum) {
            if (datum.claim && datum.claim.date) {
              datum.claim.date = todate(datum.claim.date, "MM/dd/yyyy")
            }
            if (datum.plan && datum.plan.phone) {
              datum.plan.phone = totel(datum.plan.phone)
            }
            datum.balance = $ctrl.uiGrid.columnFunc.balance
          })
          $ctrl.uiGrid.minRowsToShow = Math.min(30, data.length)
          $ctrl.uiGrid.data = data
          $ctrl.loading = false
          $ctrl.validateResponse(responses)
        }
      )
    }

    $ctrl.validateResponse = function(currResponses) {
      $q.allSettled([
        AnalyticsService.getActiveClaims(PrincipalService.getPracticeId()),
        AnalyticsService.getInsuranceUnclaimedPatientTotals(PrincipalService.getPracticeId()),
      ]).then(
        function(prevResponses) {
          var completed = null
          var unclaimed = null
          var idx = null
          var idxMax = null
          var diff = null
          var diffFn = null
          var invalid = false
          console.log("validateResponse", "[start]")
          if (currResponses[0].state === "fulfilled" && prevResponses[0].state === "fulfilled") {
            console.group("validateResponse", "(completed)")
            completed = {
              curr: currResponses[0].value.data,
              prev: prevResponses[0].value.data,
            }
            completed.curr.sort(function(a, b) { return $ctrl.uiGrid.columnFunc.compare(a.claim.id, b.claim.id) })
            completed.prev.sort(function(a, b) { return $ctrl.uiGrid.columnFunc.compare(a.id, b.id) })
            diffFn = function(curr, prev) {
              var curr_a = curr ? [
                curr.claim.date,
                curr.claim.total.toFixed(2),
                curr.claim.id,
                curr.claim.amount_remaining.toFixed(2),
                curr.claim.number,
                curr.patient.id,
                curr.patient.name,
                curr.plan.phone,
                curr.plan.payer.name,
                curr.treatments.location ? curr.treatments.location.id : "",
                curr.treatments.provider ? curr.treatments.provider.id : "",
              ] : []
              var prev_a = prev ? [
                prev.claim_date,
                prev.claim_total,
                prev.id,
                prev.claim_amount_remaining,
                prev.claim_number,
                prev.patient_id,
                [prev.patient_last_name, prev.patient_first_name].join(", "),
                prev.plan_phone,
                prev.payer_name,
                prev.location_of_treatments_id,
                prev.provider_of_treatments_id,
              ] : []
              if (!angular.equals(curr_a, prev_a)) {
                return [curr_a, prev_a]
              }
              return null
            }
            for (idx = 0, idxMax = Math.max(completed.curr.length, completed.prev.length); idx < idxMax; idx++) {
              diff = diffFn(completed.curr[idx], completed.prev[idx])
              if (diff) {
                invalid = true
                console.log(JSON.stringify({
                  diff: diff,
                  curr: completed.curr[idx],
                  prev: completed.prev[idx],
                }, null, 2))
              }
            }
            console.groupEnd()
          }
          else {
            invalid = true
          }
          if (currResponses[1].state === "fulfilled" && prevResponses[1].state === "fulfilled") {
            console.group("validateResponse", "(unclaimed)")
            unclaimed = {
              curr: currResponses[1].value.data,
              prev: prevResponses[1].value.data,
            }
            unclaimed.curr.sort(function(a, b) { return $ctrl.uiGrid.columnFunc.compare(a.patient.id, b.patient.id) })
            unclaimed.prev.sort(function(a, b) { return $ctrl.uiGrid.columnFunc.compare(a.patient_id, b.patient_id) })
            diffFn = function (curr, prev) {
              var curr_a = curr ? [
                curr.patient.id,
                curr.patient.name,
                curr.unclaim.total.toFixed(2),
              ] : []
              var prev_a = prev ? [
                prev.patient_id,
                [prev.patient_last_name, prev.patient_first_name].join(", "),
                prev.total,
              ] : []
              if (!angular.equals(curr_a, prev_a)) {
                return [curr_a, prev_a]
              }
              return null
            }
            for (idx = 0, idxMax = Math.max(unclaimed.curr.length, unclaimed.prev.length); idx < idxMax; idx++) {
              diff = diffFn(unclaimed.curr[idx], unclaimed.prev[idx])
              if (diff) {
                invalid = true
                console.log(JSON.stringify({
                  diff: diff,
                  curr: unclaimed.curr[idx],
                  prev: unclaimed.prev[idx],
                }, null, 2))
              }
            }
            console.groupEnd()
          }
          else {
            invalid = true
          }
          if (invalid) {
            NotificationService.error("Error validating Response")
          }
          console.log("validateResponse", "[end]")
        }
      )
    }
  }
})();

/* jshint undef: false, unused: false */
(function() {
    'use strict';

    function analyticsOverviewCtrl($scope, PrincipalService, NotificationService, AnalyticsService, $q, $state) {

        var AR__DISPLAY_COUNT = 5;
        var AR__OTHERS_DISPLAY_LABEL = 'others';
        var PRODUCT__DISPLAY_COUNT = 5;
        var PRODUCT__OTHERS_DISPLAY_LABEL = 'others';

// PRODUCTIVITY

        $scope.productivityOptions = {
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        var x = data.labels[tooltipItem.index] + ': $' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return x;
                    }
                }
            },
            legend: {
                display: true,
                position: 'bottom',
                onClick: function(event, legendItem) {
                    console.log(legendItem.index);
                }
            },
            donut: {
                title: 'Providers'
            }
        };
        $scope.productivityItemClicked = function(points, evt) {
            if (points.length) {
                console.log(points[0]._index);
            } else {
                return false;
            }
        };

// PATIENT AR

        //$scope.arLabels = [''];
        //$scope.arSeries = ["Jane Patient", "Cranky Crankenstein", "Bill John", "Ace Ventura", "Cher"];
        //$scope.arData = [[1000], [500], [100], [33], [25]];
        $scope.arOptions = {
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        var x = data.datasets[tooltipItem.datasetIndex].label + ': $' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return x;
                    }
                }
            },
            legend: {
                display: true,
                position: 'bottom',
                onClick: function(event, legendItem) {
                    console.log(legendItem.datasetIndex);
                }
            },
            scales: {
                yAxes: [{
                    stacked: true
                }],
                xAxes: [{
                    stacked: true
                }]
            }
        };

        $scope.arItemClicked = function(points, evt) {
/*            if (points.length) {

                var elementAtEvent = points[0]._chart.controller.getElementAtEvent(evt);
                if (elementAtEvent && elementAtEvent.length) {
                    console.log(elementAtEvent[0]._datasetIndex);
                    return true;
                }
            }
            return false;
*/
            $state.go('analytics-patients-aging');
            return true;
        };

// PRODUCT
//        $scope.productLabels = ["D0150", "D0120", "D0171"];
//        $scope.productData = [1000, 500, 400];
        $scope.productOptions = {
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        var x = data.labels[tooltipItem.index] + ': $' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return x;
                    }
                }
            },
            legend: {
                display: true,
                position: 'bottom',
                onClick: function(event, legendItem) {
                    console.log(legendItem.index);
                }
            },
            scale: {
                ticks: {
                    beginAtZero: true
                }
            }
        };

        $scope.productItemClicked = function(points, evt) {
            if (points.length) {
                var elementAtEvent = points[0]._chart.controller.getElementAtEvent(evt);
                if (elementAtEvent && elementAtEvent.length) {
                    console.log(elementAtEvent[0]._index);
                    return true;
                }
            }
            return false;
        };


        var reset = function() {
            var beginningDate = new Date();
            beginningDate.setHours(0,0,0,0); //sets to 00:00:00:000 *local* time which for PDT is 07:00:00:000 UTC
            beginningDate.setUTCDate(1); //sets to first day of month UTC
            var now = new Date();
	        $q.all([
           		AnalyticsService.getTreatmentRevenueSumsByProviderBeginningThrough(PrincipalService.getPracticeId(), beginningDate, now),
              AnalyticsService.getPatientsArAccountsTotals(PrincipalService.getPracticeId()),
           		AnalyticsService.getTreatmentRevenueSumsByProcedureBeginningThrough(PrincipalService.getPracticeId(), beginningDate, now)
	        ])
	        .then(function(responses) {
                var treatmentRevenueGlAccountTotalAmountInfos = angular.fromJson(responses[0].data);
                var arGlAccountTotalAmountInfos = angular.fromJson(responses[1].data);
                var productGlAccountTotalAmountInfos = angular.fromJson(responses[2].data);

                //data for productivity chart
                $scope.productivityData = [];
                $scope.productivityLabels = [];
                $scope.revenueAmountTotals = 0.0;
                treatmentRevenueGlAccountTotalAmountInfos.forEach(function (glAccountTotalAmountInfo) {
                    var revenueAmount = -parseFloat(glAccountTotalAmountInfo.amount);
                    $scope.revenueAmountTotals = $scope.revenueAmountTotals + revenueAmount;
                    $scope.productivityData.push(revenueAmount);
                    $scope.productivityLabels.push(glAccountTotalAmountInfo.first_name + ' ' + glAccountTotalAmountInfo.last_name);
	            });

                //data for patient ar chart
                $scope.arData = [];
                $scope.arLabels = [''];
                $scope.arSeries = [];
                $scope.arAmountTotals = 0.0;
                var arOthersDataItem = 0.0;
                var arAmount = 0.0;
                if (arGlAccountTotalAmountInfos.length < AR__DISPLAY_COUNT) {
                    for (var i = 0; i < arGlAccountTotalAmountInfos.length; i++) {
                        arAmount = parseFloat(arGlAccountTotalAmountInfos[i].amount);
                        $scope.arAmountTotals = $scope.arAmountTotals + arAmount;
                        $scope.arData.push([arAmount]);
                        $scope.arSeries.push(arGlAccountTotalAmountInfos[i].first_name + ' ' + arGlAccountTotalAmountInfos[i].last_name);
                    }
                } else {
                    for (var j = 0; j < AR__DISPLAY_COUNT-1; j++) {
                        arAmount = parseFloat(arGlAccountTotalAmountInfos[j].amount);
                        $scope.arAmountTotals = $scope.arAmountTotals + arAmount;
                        $scope.arData.push([arAmount]);
                        $scope.arSeries.push(arGlAccountTotalAmountInfos[j].first_name + ' ' + arGlAccountTotalAmountInfos[j].last_name);
                    }
                    for (var k = AR__DISPLAY_COUNT-1; k < arGlAccountTotalAmountInfos.length; k++) {
                        arAmount = parseFloat(arGlAccountTotalAmountInfos[k].amount);
                        $scope.arAmountTotals = $scope.arAmountTotals + arAmount;
                        arOthersDataItem = arOthersDataItem + arAmount;
                    }
                    $scope.arData.push([arOthersDataItem]);
                    $scope.arSeries.push(AR__OTHERS_DISPLAY_LABEL);
                }

                //data for product chart
                $scope.productData = [];
                $scope.productLabels = [];
                $scope.productAmountTotals = 0.0;
                var productOthersDataItem = 0.0;
                var productAmount = 0.0;
                if (productGlAccountTotalAmountInfos.length < PRODUCT__DISPLAY_COUNT) {
                    for (var l = 0; l < productGlAccountTotalAmountInfos.length; l++) {
                        productAmount = -parseFloat(productGlAccountTotalAmountInfos[l].amount);
                        $scope.productAmountTotals = $scope.productAmountTotals + productAmount;
                        $scope.productData.push(productAmount);
                        $scope.productLabels.push(productGlAccountTotalAmountInfos[l].procedure_code + ' ' + productGlAccountTotalAmountInfos[l].procedure_name.slice(0,10));
                    }
                } else {
                    for (var m = 0; m < PRODUCT__DISPLAY_COUNT-1; m++) {
                        productAmount = -parseFloat(productGlAccountTotalAmountInfos[m].amount);
                        $scope.productAmountTotals = $scope.productAmountTotals + productAmount;
                        $scope.productData.push(productAmount);
                        $scope.productLabels.push(productGlAccountTotalAmountInfos[m].procedure_code + ' ' + productGlAccountTotalAmountInfos[m].procedure_name.slice(0,10));
                    }
                    for (var n = PRODUCT__DISPLAY_COUNT-1; n < productGlAccountTotalAmountInfos.length; n++) {
                        productAmount = -parseFloat(productGlAccountTotalAmountInfos[n].amount);
                        $scope.productAmountTotals = $scope.productAmountTotals + productAmount;
                        productOthersDataItem = productOthersDataItem + productAmount;
                    }
                    $scope.productData.push(productOthersDataItem);
                    $scope.productLabels.push(PRODUCT__OTHERS_DISPLAY_LABEL);
                }
	        }, function(err) {
                NotificationService.error('Error Obtaining treatment revenue sums',err);
	        });
        };

        reset();
    }

    angular.module('common.analytics-overview', [])
        .controller('AnalyticsOverviewCtrl', analyticsOverviewCtrl);

})();

/* jshint undef: false, unused: false */
(function() {
    'use strict';

    function analyticsProductivityCtrl($log) {
        $log.log('analytics-productivity');
    }

    angular.module('common.analytics-productivity', [])
        .controller('AnalyticsProductivityCtrl', analyticsProductivityCtrl);

})();

/* jshint undef: false, unused: false */
(function() {
    'use strict';

    function analyticsProductivityTreatmentsDetailCtrl($scope, $window, $log, PrincipalService, NotificationService, AnalyticsService, $q, uiGridConstants,$state) {

      $scope.loadingTreatmentsForDateRange = false
      $scope.filterModels = {};

      var dataForTimeRange = [];
      var dataForGridDisplay = [];

      $scope.showAbandoned = false;
      $scope.onShowAbandonedChanged = function() {
        updateUI(dataForTimeRange, $scope.showAbandoned, true);
      };

      $scope.maxThroughDate = new Date();
      $scope.duration = 'today';

      $scope.BACKEND_FORMAT_DATE = 'YYYY-MM-DD';


      $scope.$watch('duration', function(newVal, oldVal) {
        if (newVal !== null) {
          var now = new Date();
          var firstDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
          switch(newVal) {
            case 'today':
              $scope.from = moment(new Date()).format($scope.BACKEND_FORMAT_DATE);
              $scope.through = moment(new Date()).format($scope.BACKEND_FORMAT_DATE);
              $scope.getDataForDatetimeRange($scope.from, $scope.through);
              break;
            case 'mtd':
              $scope.from = moment(firstDayOfCurrentMonth).format($scope.BACKEND_FORMAT_DATE);
              $scope.through = moment(new Date()).format($scope.BACKEND_FORMAT_DATE);
              $scope.getDataForDatetimeRange($scope.from, $scope.through);
              break;
            case 'priormonth':
              $scope.from = moment(new Date(now.getFullYear(), now.getMonth()-1, 1)).format($scope.BACKEND_FORMAT_DATE);
              $scope.through = moment((new Date()).setDate(0)).format($scope.BACKEND_FORMAT_DATE); //sets date to last day of previous month
              $scope.getDataForDatetimeRange($scope.from, $scope.through);
              break;
            case 'custom':
              break;
          }
        }
      });

      $scope.getFromDateFormat = function(from){
        return moment(from,$scope.BACKEND_FORMAT_DATE).format('MM/DD/YYYY');
      };

      $scope.getThroughDateFormat = function(through){
        return moment(through,$scope.BACKEND_FORMAT_DATE).format('MM/DD/YYYY');
      };

      $scope.onNameClick = function(data){
        var url = $state.href('treatmentplans',{patientid:data.patient});
        $window.open(url, '_blank');
        //$state.go('treatmentplans',{patientid:data.patient});
      };

      $scope.onReferralClick = function(data){
        var url = $state.href('practice-referrals.received.detail',{id:data.referral_id});
        $window.open(url, '_blank');
        //$state.go('practice-referrals.received.detail',{id:data.referral_id});
      };

      $scope.onInvoiceClick = function(data){
        var url = $state.href('invoicedetail',{patientid:data.patient, invoiceid:data.invoice_id});
        $window.open(url, '_blank');
      };

      $scope.$watchCollection('filterModels', function(newNames, oldNames) {
        for (var dropdown in newNames) {
          if (newNames.hasOwnProperty(dropdown) && typeof(newNames[dropdown]) !== "function" && newNames[dropdown].id !== 'all') {
            console.log(dropdown + ': ' + JSON.stringify(newNames[dropdown]));
            /* attempt to refresh selected display but doesn't work
            var dropdownValue = $scope.filterModels[dropdown];
            $scope.filterModels[dropdown] = {
              all: {
                id: 'all',
                name: '---all---',
                totalRevenueRank: 0
              }
            };
            $scope.filterModels[dropdown] = dropdownValue;
            */
          }
        }
        updateUI(dataForTimeRange, $scope.showAbandoned, false);
      });
      
      function setUpDropdowns(dataForGridDisplay, resetDropdownSelection) {
        var providers = {
          all: {
            id: 'all',
            name: '---all---',
            totalRevenueRank: 0
          }
        };

        var patients = {
          all: {
            id: 'all',
            name: '---all---',
            totalRevenueRank: 0
          }
        };

        var procedures = {
          all: {
            id: 'all',
            name: '---all---',
            totalRevenueRank: 0
          }
        };

        var locations = {
          all: {
            id: 'all',
            name: '---all---',
            totalRevenueRank: 0
          }
        };

        var referralPractices = {
          all: {
            id: 'all',
            name: '---all---',
            totalRevenueRank: 0
          }
        };

        var referralUsers = {
          all: {
            id: 'all',
            name: '---all---',
            totalRevenueRank: 0
          }
        };

        var filteredDataForGridDisplay = [];

        if (!resetDropdownSelection) {
          for (var j=0; j<dataForGridDisplay.length; j++) {
            if ($scope.filterModels.provider.id === dataForGridDisplay[j].provider || $scope.filterModels.provider.id === 'all') {
              if ($scope.filterModels.patient.id === dataForGridDisplay[j].patient || $scope.filterModels.patient.id === 'all') {
                if ($scope.filterModels.procedure.id === dataForGridDisplay[j].procedure_code || $scope.filterModels.procedure.id === 'all') {
                  if ($scope.filterModels.location.id === dataForGridDisplay[j].location || $scope.filterModels.location.id === 'all') {
                    if ($scope.filterModels.referralPractice.id === dataForGridDisplay[j].referral_practice_id || $scope.filterModels.referralPractice.id === 'all') {
                      if ($scope.filterModels.referralUser.id === dataForGridDisplay[j].referral_user_id || $scope.filterModels.referralUser.id === 'all') {
                        filteredDataForGridDisplay.push(dataForGridDisplay[j]);
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          filteredDataForGridDisplay = dataForGridDisplay;
        }


        for (var i=0; i<filteredDataForGridDisplay.length; i++) {
          filteredDataForGridDisplay[i].treatmentDate =  AnalyticsService.fromAPIDate(filteredDataForGridDisplay[i].treatment_date);
          filteredDataForGridDisplay[i].patientName = filteredDataForGridDisplay[i].patient_last_name + ', ' + filteredDataForGridDisplay[i].patient_first_name;
          filteredDataForGridDisplay[i].providerName = filteredDataForGridDisplay[i].provider_last_name + ', ' + filteredDataForGridDisplay[i].provider_first_name;
          filteredDataForGridDisplay[i].procedureName = filteredDataForGridDisplay[i].procedure_code + ' - ' + filteredDataForGridDisplay[i].procedure_name;
          filteredDataForGridDisplay[i].referralUserName = (filteredDataForGridDisplay[i].referral_user_last_name && filteredDataForGridDisplay[i].referral_user_first_name) ? filteredDataForGridDisplay[i].referral_user_last_name + ', ' + filteredDataForGridDisplay[i].referral_user_first_name : '';

          filteredDataForGridDisplay[i].insuranceFeeNetwork = (
            filteredDataForGridDisplay[i].insurance_applied ?
            (
              filteredDataForGridDisplay[i].is_insurance_innetwork ?
              'In-Network' :
              'Out-Network'
            ) :
            ''
          );

          if (!providers[filteredDataForGridDisplay[i].provider]) {
            providers[filteredDataForGridDisplay[i].provider] = {
              id: filteredDataForGridDisplay[i].provider,
              name: filteredDataForGridDisplay[i].providerName,
              totalRevenue: parseFloat(filteredDataForGridDisplay[i].amount_revenue_to_post).toFixed(2),
              totalRevenueRank:0
            };
          } else {
            providers[filteredDataForGridDisplay[i].provider].totalRevenue = (parseFloat(providers[filteredDataForGridDisplay[i].provider].totalRevenue) + parseFloat(filteredDataForGridDisplay[i].amount_revenue_to_post)).toFixed(2);
          }
    
          if (!locations[filteredDataForGridDisplay[i].location]) {
            locations[filteredDataForGridDisplay[i].location] = {
              id: filteredDataForGridDisplay[i].location,
              name: filteredDataForGridDisplay[i].location_name,
              totalRevenue: parseFloat(filteredDataForGridDisplay[i].amount_revenue_to_post).toFixed(2),
              totalRevenueRank:0
            };
          } else {
            locations[filteredDataForGridDisplay[i].location].totalRevenue = (parseFloat(locations[filteredDataForGridDisplay[i].location].totalRevenue) + parseFloat(filteredDataForGridDisplay[i].amount_revenue_to_post)).toFixed(2);
          }
  
          if (!patients[filteredDataForGridDisplay[i].patient]) {
            patients[filteredDataForGridDisplay[i].patient] = {
              id: filteredDataForGridDisplay[i].patient,
              name: filteredDataForGridDisplay[i].patientName
            };
          }
  
          if (!procedures[filteredDataForGridDisplay[i].procedure_code]) {
            procedures[filteredDataForGridDisplay[i].procedure_code] = {
              id: filteredDataForGridDisplay[i].procedure_code,
              name: filteredDataForGridDisplay[i].procedureName
            };
          }
  
          if (filteredDataForGridDisplay[i].referral_practice_id && !referralPractices[filteredDataForGridDisplay[i].referral_practice_id]) {
            referralPractices[filteredDataForGridDisplay[i].referral_practice_id] = {
              id: filteredDataForGridDisplay[i].referral_practice_id,
              name: filteredDataForGridDisplay[i].referral_practice_name,
              totalRevenue:parseFloat(filteredDataForGridDisplay[i].amount_revenue_to_post).toFixed(2),
              totalRevenueRank:0
            };
          } else if (filteredDataForGridDisplay[i].referral_practice_id) {
            referralPractices[filteredDataForGridDisplay[i].referral_practice_id].totalRevenue = (parseFloat(referralPractices[filteredDataForGridDisplay[i].referral_practice_id].totalRevenue) + parseFloat(filteredDataForGridDisplay[i].amount_revenue_to_post)).toFixed(2);
          }
  
          if (filteredDataForGridDisplay[i].referral_user_id && !referralUsers[filteredDataForGridDisplay[i].referral_user_id]) {
            referralUsers[filteredDataForGridDisplay[i].referral_user_id] = {
              id: filteredDataForGridDisplay[i].referral_user_id,
              name: filteredDataForGridDisplay[i].referralUserName,
              totalRevenue:parseFloat(filteredDataForGridDisplay[i].amount_revenue_to_post).toFixed(2),
              totalRevenueRank:0
            };
          } else if (filteredDataForGridDisplay[i].referral_user_id) {
            referralUsers[filteredDataForGridDisplay[i].referral_user_id].totalRevenue = (parseFloat(referralUsers[filteredDataForGridDisplay[i].referral_user_id].totalRevenue) + parseFloat(filteredDataForGridDisplay[i].amount_revenue_to_post)).toFixed(2);
          }
        }

        var rank = 1;
        var sortFunc = function(a,b) {
          return parseFloat(b[1].totalRevenue) - parseFloat(a[1].totalRevenue);
        }

        var entries = Object.entries(providers);
        entries.sort(sortFunc);
        providers = [];
        rank = 1;
        for (var k in entries) { 
          if (entries.hasOwnProperty(k)) {
            if (entries[k][1].id !=='all') {
              entries[k][1].totalRevenueRank = rank++;
            }
            providers.push(entries[k][1]); 
          }
        }
        $scope.providers = providers;
        if (resetDropdownSelection) {
          $scope.filterModels.provider = providers.find(function (element) {
            return element.id === 'all';
          });
        }

        entries = Object.entries(locations);
        entries.sort(sortFunc);
        locations = [];
        rank = 1;
        for (k in entries) { 
          if (entries.hasOwnProperty(k)) {
            if (entries[k][1].id !=='all') {
              entries[k][1].totalRevenueRank = rank++;
            }
            locations.push(entries[k][1]); 
          }
        }
        if (resetDropdownSelection) {
            $scope.filterModels.location = locations.find(function (element) {
            return element.id === 'all';
          });
        }
        $scope.locations = locations;

        $scope.patients = Object.values(patients);
        if (resetDropdownSelection) {
            $scope.filterModels.patient = $scope.patients.find(function (element) {
            return element.id === 'all';
          });
        }

        $scope.procedures = Object.values(procedures);
        if (resetDropdownSelection) {
          $scope.filterModels.procedure = $scope.procedures.find(function (element) {
            return element.id === 'all';
          });
        }

        entries = Object.entries(referralPractices);
        entries.sort(sortFunc);
        referralPractices = [];
        rank = 1;
        for (k in entries) { 
          if (entries.hasOwnProperty(k)) {
            if (entries[k][1].id !=='all') {
              entries[k][1].totalRevenueRank = rank++;
            }
            referralPractices.push(entries[k][1]); 
          }
        }
        if (resetDropdownSelection) {
          $scope.filterModels.referralPractice = referralPractices.find(function (element) {
            return element.id === 'all';
          });
        }
        $scope.referralPractices = referralPractices;

        entries = Object.entries(referralUsers);
        entries.sort(sortFunc);
        referralUsers = [];
        rank = 1;
        for (k in entries) { 
          if (entries.hasOwnProperty(k)) {
            if (entries[k][1].id !=='all') {
              entries[k][1].totalRevenueRank = rank++;
            }
            referralUsers.push(entries[k][1]); 
          }
        }
        if (resetDropdownSelection) {
          $scope.filterModels.referralUser = referralUsers.find(function (element) {
            return element.id === 'all';
          })
        }
        $scope.referralUsers = referralUsers;

        return filteredDataForGridDisplay;
      }


      function displayGrid(gridOptions, gridData) {

        gridOptions.enableSorting = true;
        gridOptions.columnDefs = [
          {name: 'treatmentDate', displayName: 'Treatment Date', field: 'treatmentDate', type: 'date', cellFilter: 'date:\'MM/dd/yyyy\''},
          {name: 'procedureCode', displayName: 'Procedure', field: 'procedureName'},
          {visible: false, name: 'toothNumbers', displayName: 'Tooth Number(s)', field: 'tooth_numbers'},
          {visible: false, name: 'practiceFee', displayName: 'Practice Fee', field: 'fee_practice_primary_feeprofile', type: 'number', cellFilter: 'currency:"":2', cellClass: 'currency', aggregationType: uiGridConstants.aggregationTypes.sum, footerCellTemplate: '<div class="ui-grid-cell-contents currency">Total: {{col.getAggregationValue() | currency }}</div>'},
          {name: 'amountRevenue', displayName: 'Gross Revenue', field: 'amount_revenue_to_post', type: 'number', cellFilter: 'currency:"":2', cellClass: 'currency', aggregationType: uiGridConstants.aggregationTypes.sum, footerCellTemplate: '<div class="ui-grid-cell-contents currency">Total: {{col.getAggregationValue() | currency }}</div>'},
          {name: 'insuranceFee', displayName: 'Ins. Adj. Fee', field: 'amount_fee', type: 'number', cellFilter: 'currency:"":2', cellClass: 'currency', aggregationType: uiGridConstants.aggregationTypes.sum, footerCellTemplate: '<div class="ui-grid-cell-contents currency">Total: {{col.getAggregationValue() | currency }}</div>'},
          {visible: false, name: 'isInsurance', displayName: 'Insurance Applied?', field: 'insurance_applied'},
          {name: 'amountWriteoff', displayName: 'Total Writeoffs', field: 'writeoff_amount', type: 'number', cellFilter: 'currency:"":2', cellClass: 'currency', aggregationType: uiGridConstants.aggregationTypes.sum, footerCellTemplate: '<div class="ui-grid-cell-contents currency">Total: {{col.getAggregationValue() | currency }}</div>'},
          {visible: false, name: 'insuranceInNetwork', displayName: 'In / Out of Network?', field: 'insuranceFeeNetwork'},
          {name: 'patient', displayName: 'Patient', field: 'patientName', cellTemplate:'<a  ng-click="grid.appScope.onNameClick(row.entity)" target="_blank">({{row.entity.patient}}) {{row.entity.patientName}}</a>'},
          {name: 'provider', displayName: 'Provider', field: 'providerName'},
          {name: 'location', displayName: 'Location', field: 'location_name'},
          {name: 'referralPractice', displayName: 'Referring Practice', field: 'referral_practice_name', cellTemplate:'<a  ng-click="grid.appScope.onReferralClick(row.entity)" target="_blank">{{row.entity.referral_practice_name}}</a>'},
          {name: 'referralUser', displayName: 'Referring User', field: 'referralUserName', cellTemplate:'<a  ng-click="grid.appScope.onReferralClick(row.entity)" target="_blank">{{row.entity.referralUserName}}</a>'},
          {visible: false, name: 'treatmentIsAbandoned', displayName: 'Abandoned?', field: 'is_abandoned'},
          {visible: false, name: 'patientPhoneNumber', displayName: 'Mobile Number', field:'patient_mobile_phone'},
          {visible: false, name: 'patientEmail', displayName: 'Email', field: 'patient_email'},
          {visible: false, name: 'invoiceNumber', displayName: 'Invoice Number', field: 'invoice_number', cellTemplate:'<a  ng-click="grid.appScope.onInvoiceClick(row.entity)" target="_blank">{{row.entity.invoice_number}}</a>'}
        ];
        gridOptions.data = gridData;
        /*
        gridOptions.onRegisterApi = function(gridApi) {
          $scope.gridApi = gridApi;
          gridApi.selection.on.rowSelectionChanged($scope, function(row) {
            //$state.go('billing', {patientid: row.entity.patient_id});
          });
        };
        */
      }

      function getDataExceptAbandoned(data) {
        return data.filter(function(record) {
          return record.is_abandoned === false;
        });
      }

      function updateUI(data, showAbandoned, resetDropdownSelection) {
        if ($scope.showAbandoned) {
          displayGrid($scope.gridOptions, setUpDropdowns(data, resetDropdownSelection));
        } else {
          displayGrid($scope.gridOptions, setUpDropdowns(getDataExceptAbandoned(data), resetDropdownSelection));
        } 

      }

      $scope.getDataForDatetimeRange = function(fromDate, throughDate) {
        fromDate = moment(fromDate).format($scope.BACKEND_FORMAT_DATE);
        throughDate =  moment(throughDate).format($scope.BACKEND_FORMAT_DATE);
        $log.debug(fromDate);
        $log.debug(throughDate);

        $scope.loadingTreatmentsForDateRange = true
        $q.all([
          AnalyticsService.getTreatmentsForDateRange(PrincipalService.getPracticeId(), fromDate, throughDate)
        ])
        .then(function(responses) {
          $scope.loadingTreatmentsForDateRange = false
          dataForTimeRange = responses[0].data;
 
          updateUI(dataForTimeRange, $scope.showAbandoned, true);
        }, function(err) {
          $scope.loadingTreatmentsForDateRange = false
          NotificationService.error('Error obtaining treatments info',err);
        });
      };

      $scope.gridOptions = {
        columnDefs: [
        ],
        data: [
        ],
        enableGridMenu: true,
        exporterPdfHeader: function(currentPage, pageCount) {
          const providerName = $scope.filterModels && $scope.filterModels.provider ? $scope.filterModels.provider.name : '';
          const patientName = $scope.patient ? $scope.patient.name : '';
          const procedureName = $scope.procedure ? $scope.procedure.name : '';

          return `Treatments from ${$scope.from} through ${$scope.through} for provider: ${providerName}, patient: ${patientName}, and procedure: ${procedureName}`;

        },
        exporterPdfFooter: function(currentPage, pageCount) {
          return currentPage.toString() + ' of ' + pageCount.toString();
        },
        showGridFooter: true,
        showColumnFooter: true,

        enableSelectAll: true,
        enableRowSelection: true,
        enableRowHeaderSelection: true,

        multiSelect: true,
        modifierKeysToMultiSelect: false,
        noUnselect: false,
        enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
        minRowsToShow: 15,

        onRegisterApi: function(gridApi) {
          /*
          gridApi.grid.registerDataChangeCallback(
            function(grid) {
              grid.options.minRowsToShow = grid.options.data.length || 1;
              // snippet extracted from client/node_modules/angular-ui-grid/ui-grid.js#autoAdjustHeight
              // simplified since it does not include scrollbarHeight nor filterHeight
              var headerHeight = grid.options.showHeader ? grid.options.headerRowHeight : 0;
              var contentHeight = grid.options.minRowsToShow * grid.options.rowHeight;
              var footerHeight = grid.calcFooterHeight();
              var scrollbarHeight = 17;
              var filterHeight = 0
              var newHeight = headerHeight + contentHeight + footerHeight + scrollbarHeight + filterHeight;
              grid.element.css("height", newHeight + "px")
            },
            [
              uiGridConstants.dataChange.ROW
            ]
          );
          */
        }

      };
    }

    angular.module('common.analytics-productivity-treatments-detail', [])
        .controller('AnalyticsProductivityTreatmentsDetailCtrl', analyticsProductivityTreatmentsDetailCtrl);

})();

/* jshint undef: false, unused: false */
(function() {
    'use strict';

    function analyticsPatientsCtrl($log) {

        $log.log('analytics-patients');
    }

    angular.module('common.analytics-patients', [])
        .controller('AnalyticsPatientsCtrl', analyticsPatientsCtrl);

})();

(function () {
  "use strict"

  angular
    .module("common.analytics")

    .component("analyticsPatientsAging", {
      bindings: { $transition$: "<" },
      controller: Controller,
      templateUrl: "src/common/analytics-patients-aging.tpl.html",
    })

  function Controller(
    $filter,
    $q,
    AnalyticsService,
    AnalyticsServiceNext,
    NotificationService,
    PrincipalService,
    uiGridConstants
  ) {
    var $ctrl = this

    $ctrl.$onInit = function () {
      $ctrl.loading = false
      $ctrl.uiGrid = {
        columnDefs: [
          {
            cellTemplate: [
              '<div class="ui-grid-cell-contents">',
              '  <a class="dis-ib" ui-sref="billing({ patientid:row.entity.patient.id })" target="_blank">',
              '    {{ row.entity.patient.name }}',
              '  </a>',
              '  <div class="pull-right p-r-xs">',
              '    <i ng-if="row.entity.claim.has_active"',
              '      class="icomoon icon-icomoon-warning2"',
              '      uib-tooltip="Has Active Claims"',
              '      tooltip-append-to-body="true">',
              '    </i>',
              '  </div>',
              '</div>',
            ].join(""),
            displayName: "Patient Name",
            field: "patient.name",
            name: "patient-name",
          },
          {
            aggregationType: uiGridConstants.aggregationTypes.sum,
            cellClass: "text-right",
            cellFilter: "currency:'$':2",
            displayName: "Amount (USD)",
            field: "jed.amount",
            footerCellClass: "text-right",
            footerCellFilter: "currency",
            maxWidth: 150,
            name: "jed-amount",
            type: "number",
          },
          {
            aggregationType: uiGridConstants.aggregationTypes.max,
            cellClass: "text-right",
            cellTemplate: [
              '<div class="ui-grid-cell-contents">',
              '  {{ row.entity.invoice.past_due !== null ? row.entity.invoice.past_due : (row.entity.jed.amount < 0 ? "[CREDIT]" : "-") }}',
              '</div>',
            ].join(""),
            displayName: "Past Due (days)",
            field: "invoice.past_due",
            footerCellClass: "text-right",
            maxWidth: 155,
            name: "invoice-past-due",
            type: "number",
          },
          {
            cellClass: "text-center",
            displayName: "Home Phone",
            field: "patient.home_phone",
            maxWidth: 150,
            name: "patient-home-phone",
          },
          {
            cellClass: "text-center",
            displayName: "Mobile Phone",
            field: "patient.mobile_phone",
            maxWidth: 155,
            name: "patient-mobile-phone",
          },
        ],
        enableFiltering: true,
        enableGridMenu: true,
        exporterCsvFilename: "PatientsAging.csv",
        exporterExcelFilename: "PatientsAging.xlsx",
        exporterPdfFilename: "PatientsAging.pdf",
        exporterPdfFooter: function (currentPage, pageCount) { return currentPage.toString() + " of " + pageCount },
        exporterPdfHeader: "Patients Aging",
        onRegisterApi: function (gridApi) {
          $ctrl.uiGridEx.api = gridApi
          $ctrl.uiGridEx.api.grid.registerRowsProcessor(
            function (rows) {
              var visible_rows_length = rows.length
              rows.forEach(function (row) {
                if (gridApi.grid.appScope.$parent.$ctrl.uiGridEx.filters.showNegativeAmounts) {
                  row.visible = true
                }
                else {
                  row.visible = row.entity.jed.amount >= 0
                  visible_rows_length -= 1
                }
              })
              return rows
            },
            99
          )
        },
        showColumnFooter: true,
        showGridFooter: true,
      }
      $ctrl.uiGridEx = {
        compare: new Intl.Collator().compare,
        filters: {
          showNegativeAmounts: false,
        },
        refresh: function () {
          if ($ctrl.uiGridEx.api) {
            $ctrl.uiGridEx.api.grid.queueGridRefresh()
          }
        },
      }
      $ctrl.load()
    }

    $ctrl.load = function () {
      $ctrl.loading = true
      $q.allSettled([
        AnalyticsServiceNext.getGLAccountARTotals()
      ]).then(
        function (responses) {
          var data = []
          responses.forEach(function (response) {
            if (response.state === "fulfilled") {
              data.push.apply(data, angular.copy(response.value.data))
            }
          })
          data.sort(function (datum1, datum2) {
            return $ctrl.uiGridEx.compare(datum1.patient.name, datum2.patient.name)
          })
          var totel = $filter("tel")
          data.forEach(function(datum) {
            if (datum.patient.home_phone) {
              datum.patient.home_phone = totel(datum.patient.home_phone)
            }
            if (datum.patient.mobile_phone) {
              datum.patient.mobile_phone = totel(datum.patient.mobile_phone)
            }
          })
          $ctrl.uiGrid.minRowsToShow = Math.min(30, data.length)
          $ctrl.uiGrid.data = data
          $ctrl.loading = false
          $ctrl.validateResponse(responses)
        }
      )
    }

    $ctrl.validateResponse = function (currResponses) {
      $q.allSettled([
        AnalyticsService.getPatientsArAccountsTotals(PrincipalService.getPracticeId())
      ]).then(
        function (prevResponses) {
          var totals = null
          var idx = null
          var idxMax = null
          var diff = null
          var diffFn = null
          var invalid = false
          console.log("validateResponse", "[start]")
          if (currResponses[0].state === "fulfilled" && prevResponses[0].state === "fulfilled") {
            console.group("validateResponse", "(totals)")
            totals = {
              curr: currResponses[0].value.data,
              prev: prevResponses[0].value.data,
            }
            totals.curr.sort(function (a, b) { return $ctrl.uiGridEx.compare(a.patient.id, b.patient.id) })
            totals.prev.sort(function (a, b) { return $ctrl.uiGridEx.compare(a.patient_id, b.patient_id) })
            diffFn = function (curr, prev) {
              var curr_a = curr ? [
                curr.claim.has_active,
                curr.invoice.past_due !== null ? curr.invoice.past_due : (curr.jed.amount < 0 ? "[CREDIT]" : "-"),
                curr.jed.amount.toFixed(2),
                curr.patient.mobile_phone,
                curr.patient.id,
                curr.patient.home_phone,
                curr.patient.name,

              ] : []
              var prev_a = prev ? [
                prev.has_active_claim,
                prev.pastdue_days,
                prev.amount,
                prev.patient_mobile_phone,
                prev.patient_id,
                prev.patient_home_phone,
                [prev.last_name, prev.first_name].join(", "),
              ] : []
              if (!angular.equals(curr_a, prev_a)) {
                return [curr_a, prev_a]
              }
              return null
            }
            for (idx = 0, idxMax = Math.max(totals.curr.length, totals.prev.length); idx < idxMax; idx++) {
              diff = diffFn(totals.curr[idx], totals.prev[idx])
              if (diff) {
                invalid = true
                console.groupCollapsed(idx)
                console.log(JSON.stringify({
                  diff: diff,
                  curr: totals.curr[idx],
                  prev: totals.prev[idx],
                }, null, 2))
                console.groupEnd()
              }
            }
            console.groupEnd()
          }
          else {
            invalid = true
          }
          if (invalid) {
            NotificationService.error("Error validating Response")
          }
          console.log("validateResponse", "[end]")
        }
      )
    }
  }
})();

/* jshint undef: false, unused: false */
(function() {
    'use strict';

    function analyticsPatientsArCurrentCtrl($scope, $window, NotificationService, AnalyticsService, $q, $state, PrincipalService, uiGridConstants) {
      $scope.loadingPatientsArAccountsTotals = false

      $scope.showPatientsWithCreditBalances = false;
      $scope.onShowPatientsWithCreditBalances = function() {
        if ($scope.showPatientsWithCreditBalances) {
          setDataThatsSetup($scope.allDataSetup);
        } else {
          setDataThatsSetup($scope.allDataExceptPatientsWithCreditBalancesSetup);
        } 
    };

      $scope.$watch('data', function(newVal, oldVal) {
        if (newVal) { //call even on initialization
          setGridOptions($scope.gridOptions, newVal);
        }
      });

      function setupData(data) {
        for (var i=0; i<data.length; i++) {
          data[i].patientName = data[i].last_name + ', ' + data[i].first_name;
        }
        return {data: data};
      }

      $scope.onNameClick = function(data){
        var url = $state.href('billing',{patientid:data.patient_id});
        $window.open(url, '_blank');
        //$state.go('billing',{patientid:data.patient_id});
      };

      function setGridOptions(gridOptions, data) {
        var gridData = data;

        gridOptions.enableSorting = true;
        gridOptions.columnDefs = [
          {
            name: "name", 
            displayName: "Patient Name", 
            field: "patientName", 
            cellTemplate: '' +
              '<div class="ui-grid-cell-contents">' +
                '<a class="dis-ib" ng-click="grid.appScope.onNameClick(row.entity)" target="_blank">{{ row.entity.patientName }}</a>' +
                '<div class="pull-right p-r-xs">' +
                  '<i ng-if="row.entity.has_active_claim" class="icomoon icon-icomoon-warning2" uib-tooltip="Has Active Claims" tooltip-append-to-body="true"></i>' +
                '</div>' +
              '</div>',
          },
          {name: 'amount', displayName: 'Amount (USD)', field: 'amount', aggregationType: uiGridConstants.aggregationTypes.sum,  footerCellTemplate: '<div class="ui-grid-cell-contents">Total: {{col.getAggregationValue() | number: 2 }}</div>'},
          {name: 'pastdue_days', displayName: 'Past Due (days)', field: 'pastdue_days',aggregationType: uiGridConstants.aggregationTypes.max},
          {
            name: 'patient_home_phone', 
            displayName: 'Patient Home Phone', 
            field: 'patient_home_phone', 
            cellTemplate: '' +
              '<div class="ui-grid-cell-contents">' +
                '{{ row.entity.patient_home_phone | tel }}' +
              '</div>',
          },
          {
            name: 'patient_mobile_phone', 
            displayName: 'Patient Mobile Phone', 
            field: 'patient_mobile_phone', 
            cellTemplate: '' +
              '<div class="ui-grid-cell-contents">' +
                '{{ row.entity.patient_mobile_phone | tel }}' +
              '</div>',
          }
        ];
        gridOptions.data = gridData;
        /*
        gridOptions.onRegisterApi = function(gridApi) {
          $scope.gridApi = gridApi;
          gridApi.selection.on.rowSelectionChanged($scope, function(row) {
            //$state.go('billing', {patientid: row.entity.patient_id});
          });
        }
        */
      }

      function setDataThatsSetup(dataThatsSetup) {
        $scope.data = dataThatsSetup.data;
      }

      var query = function() {
        $scope.loadingPatientsArAccountsTotals = true
        $q.all([
          AnalyticsService.getPatientsArAccountsTotals(PrincipalService.getPracticeId())
        ])
        .then(function(responses) {
          $scope.loadingPatientsArAccountsTotals = false
          $scope.allDataSetup = setupData(responses[0].data);
          $scope.allDataExceptPatientsWithCreditBalancesSetup = setupData(responses[0].data.filter(function(record) {
            return parseFloat(record.amount) >= 0.0;
          }));

          if ($scope.showPatientsWithCreditBalances) {
            setDataThatsSetup($scope.allDataSetup);
          } else {
            setDataThatsSetup($scope.allDataExceptPatientsWithCreditBalancesSetup);
          } 

        }, function(err) {
          $scope.loadingPatientsArAccountsTotals = false
          NotificationService.error('Error obtaining patient AR totals',err);
        });
      };
      query();

      $scope.gridOptions = {
        columnDefs: [
        ],
        data: [
        ],
        enableGridMenu: true,
        exporterPdfHeader: function(currentPage, pageCount) {
          return 'Patient Aging';
        },
        exporterPdfFooter: function(currentPage, pageCount) {
          return currentPage.toString() + ' of ' + pageCount.toString();
        },
        showGridFooter: true,
        showColumnFooter: true,

        enableSelectAll: true,
        enableRowSelection: true,
        enableRowHeaderSelection: true,

        multiSelect: true,
        modifierKeysToMultiSelect: false,
        noUnselect: false,
      };
    }

    angular.module('common.analytics-patients-ar-current', [])
        .controller('AnalyticsPatientsArCurrentCtrl', analyticsPatientsArCurrentCtrl);

})();

/* jshint undef: false, unused: false */
(function() {
    'use strict';

    function analyticsPatientsPaymentsReconciliation($scope, $window, $filter, $log, $timeout, NotificationService, AnalyticsService, $q, $state, PrincipalService, uiGridConstants) {
      $scope.loadingPaymentsForDateRange = false
      $scope.maxThroughDate = new Date();
      $scope.duration = 'today';

      $scope.BACKEND_FORMAT_DATETIME = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

      $scope.$watch('duration', function(newVal, oldVal) {
        if (newVal !== null) {
          var now = new Date();
          var firstDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
          switch(newVal) {
            case 'today':
              $scope.from = moment((new Date()).setHours(0,0,0,0)).format($scope.BACKEND_FORMAT_DATETIME);
              $scope.through = moment(new Date()).format($scope.BACKEND_FORMAT_DATETIME);
              $scope.queryForDatetimeRange($scope.from, $scope.through);
              break;
            case 'mtd':
              $scope.from = moment(firstDayOfCurrentMonth.setHours(0,0,0,0)).format($scope.BACKEND_FORMAT_DATETIME);
              $scope.through = moment(new Date()).format($scope.BACKEND_FORMAT_DATETIME);
              $scope.queryForDatetimeRange($scope.from, $scope.through);
              break;
            case 'priormonth':
              $scope.from = moment((new Date(now.getFullYear(), now.getMonth()-1, 1)).setHours(0,0,0,0)).format($scope.BACKEND_FORMAT_DATETIME);
              $scope.through = moment( (new Date( (new Date()).setDate(0))).setHours(23,59,59,999)).format($scope.BACKEND_FORMAT_DATETIME); //sets date to last day of previous month
              $scope.queryForDatetimeRange($scope.from, $scope.through);
              break;
            case 'custom':
              break;
          }
        }
      });

      $scope.$watch('patient', function(newVal, oldVal) {
        if (newVal !== oldVal && newVal) {
          $log.debug('watch called: patient');
          setGridOptions($scope.gridOptions, $scope.data, $scope.provider, newVal, $scope.procedure, $scope.from, $scope.through);
        }
      });

      $scope.$watch('provider', function(newVal, oldVal) {
        if (newVal !== oldVal && newVal) {
          $log.debug('watch called: provider');
          setGridOptions($scope.gridOptions, $scope.data, newVal, $scope.patient, $scope.procedure, $scope.from, $scope.through);
        }
      });

      function setupData(data) {
        var patients = {
          all: {
            id: 'all',
            name: '---all---'
          }
        };

        var providers = {
          all: {
            id: 'all',
            name: '---all---'
          }
        };

        for (var i=0; i<data.length; i++) {
          if (data[i].treatment_date) {
            data[i].treatmentDate =  AnalyticsService.fromAPIDate(data[i].treatment_date);
          } else {
            data[i].treatmentDate = '';
          }
          data[i].createdDate =  AnalyticsService.fromAPIDateTime(data[i].created);
          data[i].patientName = data[i].patient_last_name + ', ' + data[i].patient_first_name;
          if (data[i].treatment_procedure_code && data[i].treatment_procedure_name) {
            data[i].procedureName = data[i].treatment_procedure_code + ' - ' + data[i].treatment_procedure_name;
          } else {
            // '';
          }
          data[i].paymentAmount = data[i].journal_entry_detail_amount + ' ' + data[i].journal_entry_detail_currency;
          data[i].method = (data[i].journal_entry_detail_description.indexOf(':') > -1) ? data[i].journal_entry_detail_description.substring(0, data[i].journal_entry_detail_description.indexOf(':')) : data[i].journal_entry_detail_description;
          data[i].description = (data[i].journal_entry_detail_description.indexOf(':') > -1) ? data[i].journal_entry_detail_description.substring(data[i].journal_entry_detail_description.indexOf(':')+1) : '';
          if (!patients[data[i].patient]) {
            patients[data[i].patient] = {
              id: data[i].patient,
              name: data[i].patientName
            };
          }
          if (data[i].treatment_provider && !providers[data[i].treatment_provider.id]) {
            providers[data[i].treatment_provider.id] = {
              id: data[i].treatment_provider.id,
              name: data[i].treatment_provider.name,
              email: data[i].treatment_provider.email,
            };
          }

        }
        return {
          data: data,
          patients: patients,
          providers: providers,
        };
      }


      $scope.onNameClick = function(data){
        var url = $state.href((data.is_remittance) ? 'insurance' : 'billing' ,{patientid:data.patient});
        $window.open(url, '_blank');
        //$state.go(data.is_remittance ? 'insurance' : 'billing' ,{patientid:data.patient});
      };

      function setGridOptions(gridOptions, data, provider, patient, procedure, from, through) {
        var gridData = [];

        for (var j=0; j<data.length; j++) {
          if (
            (patient.id === data[j].patient || patient.id === 'all') &&
            (provider.id === 'all' || (data[j].treatment_provider && provider.id === data[j].treatment_provider.id))
          ) {
            gridData.push(data[j]);
          }
        }
        gridOptions.data = gridData;
        $timeout(function() {
          $scope.gridApi.treeBase.collapseAllRows()
        }, 500)
       }

      $scope.queryForDatetimeRange = function(fromDateTime, throughDateTime) {
//RM 6/12/2018: Datepicker.js is a mess. Format is hardcoded so validation will fail for datetimes.
// I cannot refactor it at this time because of it may cause many regressions in other datepicker uses. Frustrated with sloppy coding!
// Implication: datetimes will show for most on UI except when picking custom dates where it will show as date.
// Queries, however, work correctly.
        fromDateTime = moment(fromDateTime).toISOString();
        throughDateTime = moment(throughDateTime);
        if (throughDateTime.hour() === 0 && throughDateTime.minute() === 0) {
              throughDateTime.hour(23).minute(59).seconds(59);
        }
        throughDateTime = throughDateTime.toISOString();
        
        $log.debug(fromDateTime);
        $log.debug(throughDateTime);

        $scope.loadingPaymentsForDateRange = true
        $q.all([
          AnalyticsService.getPaymentsForDateRange(PrincipalService.getPracticeId(), fromDateTime, throughDateTime)
        ])
        .then(function(responses) {
          $scope.loadingPaymentsForDateRange = false
          var dataThatsSetup = setupData(responses[0].data);

          $scope.patients = Object.values(dataThatsSetup.patients);
          $scope.patient = dataThatsSetup.patients.all;
          $scope.providers = Object.values(dataThatsSetup.providers);
          $scope.provider = dataThatsSetup.providers.all;

          $scope.data = dataThatsSetup.data;

        }, function(err) {
          $scope.loadingPaymentsForDateRange = false
          NotificationService.error('Error Obtaining payments info',err);
        });
      };

      var setGroupValues = function( columns, rows ) {
        columns.forEach( function( column ) {
          if ( column.grouping && column.grouping.groupPriority === 0 ){
            // Put the total amount next to all group labels.
            column.treeAggregationFn = function( aggregation, fieldValue, numValue, row ) {
              if ( typeof(aggregation.value) === 'undefined') {
                aggregation.value = 0.00;
              }
              aggregation.value = aggregation.value + parseFloat(row.entity.journal_entry_detail_amount);
            };
            column.customTreeAggregationFinalizerFn = function( aggregation ) {
              if ( typeof(aggregation.groupVal) !== 'undefined') {
                aggregation.rendered = aggregation.groupVal + ' (' + $filter('currency')(aggregation.value) + ')';
              } else {
                aggregation.rendered = null;
              }
            };
          }
          else if ( column.grouping && column.grouping.groupPriority === 1 ){
            // Put the total amount next to all group labels.
            column.treeAggregationFn = function( aggregation, fieldValue, numValue, row ) {
              if ( typeof(aggregation.value) === 'undefined') {
                aggregation.value = 0.00;
              }
              aggregation.value = aggregation.value + parseFloat(row.entity.journal_entry_detail_amount);
              aggregation.name = row.entity.patientName;
            };
            column.customTreeAggregationFinalizerFn = function( aggregation ) {
              if ( typeof(aggregation.groupVal) !== 'undefined') {
                aggregation.rendered = aggregation.groupVal + ' (' + $filter('currency')(aggregation.value) + '): ' + aggregation.name;
              } else {
                aggregation.rendered = null;
              }
            };
          }

        });
        return columns;
      };
   
      $scope.gridOptions = {
       
        data: [],
        enableGridMenu: true,
        exporterPdfHeader: function(currentPage, pageCount) {
          return 'Payments from ' + $scope.from + ' through ' + $scope.through + ' for patient: ' + $scope.patient.name;
        },
        exporterPdfFooter: function(currentPage, pageCount) {
          return currentPage.toString() + ' of ' + pageCount.toString();
        },
        showGridFooter: true,
        showColumnFooter: true,

        enableSelectAll: true,
        enableRowSelection: true,
        enableRowHeaderSelection: true,

        multiSelect: true,
        modifierKeysToMultiSelect: false,
        noUnselect: false,

        enableSorting: true,
        treeRowHeaderAlwaysVisible: false,
        
        onRegisterApi: function( gridApi ) {
          $scope.gridApi = gridApi;
          $scope.gridApi.grid.registerColumnsProcessor( setGroupValues, 410 );
        },
 
        columnDefs: [
          {name: 'referenceNum', width: '350', grouping: {groupPriority:1}, sort: {priority:1, direction:'asc'}, displayName: 'Pmt #', field: 'reference_number', cellTemplate: '<div><div ng-if="!col.grouping || col.grouping.groupPriority === undefined || col.grouping.groupPriority === null || ( row.groupHeader && col.grouping.groupPriority === row.treeLevel )" class="ui-grid-cell-contents" title="TOOLTIP">{{COL_FIELD CUSTOM_FILTERS}}</div></div>', groupingShowAggregationMenu: false},
          {name: 'treatmentDate', displayName: 'Treatment Date', field: 'treatmentDate', type: 'date', cellFilter: 'date:\'MM/dd/yyyy\'', groupingShowAggregationMenu: false},
          {name: 'procedureCode', displayName: 'Procedure', field: 'procedureName', groupingShowAggregationMenu: false},
          {name: 'toothNumbers', displayName: 'Tooth Num(s)', field: 'tooth_numbers', groupingShowAggregationMenu: false, visible: false},
          {name: 'paymentAmount', displayName: 'Payment Amount', field: 'journal_entry_detail_amount', groupingShowAggregationMenu: false,
            cellFilter: 'currency', 
            aggregationType: uiGridConstants.aggregationTypes.sum, 
            footerCellTemplate: '<div class="ui-grid-cell-contents">Total: {{col.getAggregationValue() | currency }}</div>',
          },
          {name: 'method', width: '350', grouping: {groupPriority:0}, sort: {priority:0, direction:'asc'}, displayName: 'Payment type', field: 'method', cellTemplate: '<div><div ng-if="!col.grouping || col.grouping.groupPriority === undefined || col.grouping.groupPriority === null || ( row.groupHeader && col.grouping.groupPriority === row.treeLevel )" class="ui-grid-cell-contents" title="TOOLTIP">{{COL_FIELD CUSTOM_FILTERS}}</div></div>', groupingShowAggregationMenu: false },
          {name: 'description', displayName: 'Description', field: 'description', cellTemplate:'<div class="ui-grid-cell-contents" title="{{row.entity.description}} {{row.entity.journal_entry_detail_memo}}">{{row.entity.description}}</div>', groupingShowAggregationMenu: false,},
          {
            name: 'provider',
            displayName: 'Provider',
            field: 'treatment_provider.name',
            groupingShowAggregationMenu: false
          },
          {name: 'patient', displayName: 'Patient', field: 'patientName', cellTemplate:'<a ng-click="grid.appScope.onNameClick(row.entity)">{{row.entity.patientName}}</a>', groupingShowAggregationMenu: false},
          {name: 'enteredBy', displayName: 'Entered By', field: 'by_username', groupingShowAggregationMenu: false, visible: false},
          {name: 'enteredDate', displayName: 'Entered On', field: 'createdDate', type: 'date', cellFilter: 'date:\'short\'', groupingShowAggregationMenu: false},
          {name: 'isRemittance', displayName: 'Is Remittance?', field: 'is_remittance', groupingShowAggregationMenu: false, visible: false}
        ]


      };
    }

    angular.module('common.analytics-patients-payments-reconciliation', [])
        .controller('AnalyticsPatientsPaymentsReconciliation', analyticsPatientsPaymentsReconciliation);

})();

/* jshint undef: false, unused: false */
(function() {
    'use strict';

    function analyticsActiveClaimsCtrl(
      $scope, 
      $window, 
      ScheduleService,
      NotificationService, 
      AnalyticsService, 
      $q, 
      $state, 
      PrincipalService, 
      uiGridConstants,
      AuthenticationService,
      LocationsService
    ) {

      $scope.loadingActiveClaimData = false
      $scope.$watch('data', function(newVal, oldVal) {
        if (newVal) { //call even on initialization
          setGridOptions($scope.gridOptions, newVal);
        }
      });

      function setupActiveClaimData(data) {
        for (var i=0; i<data.length; i++) {
          data[i].patientName = data[i].patient_last_name + ', ' + data[i].patient_first_name;
          data[i].claimDate =  AnalyticsService.fromAPIDate(data[i].claim_date);
          data[i].planPhone =  (data[i].plan_phone) ? ScheduleService.formatPhoneNumber(data[i].plan_phone) : '';
        }
        return data;
      }

      function setupInsuranceUnclaimedPatientTotalsData(data) {
        for (var i=0; i<data.length; i++) {
          data[i].patientName = data[i].patient_last_name + ', ' + data[i].patient_first_name + ' (Unclaimed)';
          data[i].claim_amount_remaining =  data[i].total;
        }
        return data;
      }

      $scope.onNameClick = function(data){
        var url = $state.href('insurance',{patientid:data.patient_id});
        $window.open(url, '_blank');
      };

      $scope.onClaimClick = function(data){
        var url = $state.href('claimdetail',{patientid:data.patient_id, claimid:data.id});
        $window.open(url, '_blank');
      };

      function setGridOptions(gridOptions, data) {
        var gridData = data;

        gridOptions.enableSorting = true;
        gridOptions.columnDefs = [
          {name: 'patientName', displayName: 'Patient Name', field: 'patientName', cellTemplate:'<a ng-click="grid.appScope.onNameClick(row.entity)"  target="_blank">{{row.entity.patientName}}</a>'},
          {name: 'payerName', displayName: 'Payer Name', field: 'payer_name'},
          {name: 'claimNumber', displayName: 'Claim Number', field: 'claim_number', cellTemplate:'<a ng-click="grid.appScope.onClaimClick(row.entity)"  target="_blank">{{row.entity.claim_number}}</a>'},
          {name: 'claimDate', displayName: 'Claim Date', field: 'claimDate', type: 'date', cellFilter: 'date:\'MM/dd/yyyy\''},
          {name: 'claimTotal', displayName: 'Total (USD)', field: 'claim_total', type: 'number', cellFilter: 'currency:""', cellClass: 'currency', aggregationType: uiGridConstants.aggregationTypes.sum, footerCellTemplate: '<div class="ui-grid-cell-contents currency">Total: {{col.getAggregationValue() | currency }}</div>'},
          {name: 'claimAmountRemaining', displayName: 'Balance (USD)', field: 'claim_amount_remaining', type: 'number', cellFilter: 'currency:""', cellClass: 'currency', aggregationType: uiGridConstants.aggregationTypes.sum, footerCellTemplate: '<div class="ui-grid-cell-contents currency">Total: {{col.getAggregationValue() | currency }}</div>'},
          {name: 'planPhone', displayName: 'Payer Phone', field: 'planPhone'},
          {visible:false, name: 'provider', displayName: 'Provider Name', field: 'provider_name'},
          {visible:false, name: 'location', displayName: 'Location Name', field: 'location_name'},
        ];
        gridOptions.data = gridData;
      }

      var query = function() {
        $scope.loadingActiveClaimData = true
        $q.all([
          AnalyticsService.getActiveClaims(PrincipalService.getPracticeId()),
          AnalyticsService.getInsuranceUnclaimedPatientTotals(PrincipalService.getPracticeId()),
          AuthenticationService.getProviderAccounts(PrincipalService.getPracticeId()),
          LocationsService.all()
        ])
        .then(function(responses) {
          $scope.loadingActiveClaimData = false
          var activeClaimData = setupActiveClaimData(responses[0].data);
          var insuranceUnclaimedPatientTotals = setupInsuranceUnclaimedPatientTotalsData(responses[1].data);
          var providers = responses[2].data;
          var locations = responses[3].data;
          _.each(activeClaimData, function(activeClaim){
            var provider = providers.find(function(provider) {
              return activeClaim.provider_of_treatments_id === provider.id;
            });
            if (provider) {
              activeClaim.provider_name = provider.last_name + ', ' + provider.first_name;
            } else {
              activeClaim.provider_name = '';
            }

            var location = locations.find(function(location) {
              return activeClaim.location_of_treatments_id === location.id;
            });
            if (location) {
              activeClaim.location_name = location.name;
            } else {
              activeClaim.location_name = '';
            }
          });
          $scope.data = activeClaimData.concat(insuranceUnclaimedPatientTotals);
          $scope.data.sort(function(a,b) {
            var nameA = a.patient_last_name.concat(a.patient_first_name).toUpperCase();
            var nameB = b.patient_last_name.concat(b.patient_first_name).toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        }, function(err) {
          $scope.loadingActiveClaimData = false
          NotificationService.error('Error obtaining active claims or unclaimed patient totals',err);
        });
      };
      query();

      $scope.gridOptions = {
        columnDefs: [
        ],
        data: [
        ],
        enableGridMenu: true,
        exporterPdfHeader: function(currentPage, pageCount) {
          return 'Active Claims';
        },
        exporterPdfFooter: function(currentPage, pageCount) {
          return currentPage.toString() + ' of ' + pageCount.toString();
        },
        showGridFooter: true,
        showColumnFooter: true,

        enableSelectAll: true,
        enableRowSelection: true,
        enableRowHeaderSelection: true,

        multiSelect: true,
        modifierKeysToMultiSelect: false,
        noUnselect: false,

        enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,

        onRegisterApi: function(gridApi) {
          gridApi.grid.registerDataChangeCallback(
            function(grid) {
              grid.options.minRowsToShow = grid.options.data.length || 1;
              // snippet extracted from client/node_modules/angular-ui-grid/ui-grid.js#autoAdjustHeight
              // simplified since it does not include scrollbarHeight nor filterHeight
              var headerHeight = grid.options.showHeader ? grid.options.headerRowHeight : 0;
              var contentHeight = grid.options.minRowsToShow * grid.options.rowHeight;
              var footerHeight = grid.calcFooterHeight();
              var scrollbarHeight = 17;
              var filterHeight = 0
              var newHeight = headerHeight + contentHeight + footerHeight + scrollbarHeight + filterHeight;
              grid.element.css("height", newHeight + "px")
            },
            [
              uiGridConstants.dataChange.ROW
            ]
          );
        }
      };
    }

    angular.module('common.analytics-active-claims', [])
        .controller('AnalyticsActiveClaimsCtrl', analyticsActiveClaimsCtrl);

})();

/* jshint undef: false, unused: false */
(function() {
    'use strict';

    function analyticsServicesCtrl($log) {
        $log.log('analytics-services');
    }

    angular.module('common.analytics-services', [])
        .controller('AnalyticsServicesCtrl', analyticsServicesCtrl);

})();

(function() {
'use strict';

  function appointmentReminderCtrl($scope, $transition$, ScheduleService,NotificationService) {

    //Remove the default background that contains the left menu color
    $('html,body').css('background','#eef1f4');


    var token = $transition$.params().token;


    $scope.init = function () {
      if(!$transition$.params().token){
        swal({
          title: '',
          html: 'Invalid URL , please try again',
          type: 'warning',
          allowOutsideClick: false
        });
        return;
      }

      ScheduleService.getAppointmentReminder(token).then(
      function(event){
        $scope.appointment = event.data;
        $scope.appointment.phone = ScheduleService.formatPhoneNumber($scope.appointment.phone);
        if($scope.appointment.reminder_status === 'confirmed'){
          $scope.appointmentConfirmed = true;
        }

      },function(){
          swal({
            title: '',
            html: 'Invalid URL , please try again',
            type: 'warning',
            allowOutsideClick: false
          });
      });
    };


    $scope.confirmAppointmentReminder = function () {
      ScheduleService.confirmAppointmentReminder(token).then(
      function(){
        $scope.appointmentConfirmed = true;
        NotificationService.success('Thank you for confirming');
      },function(err){
        NotificationService.error('Error Confirming appointment',err);
      });
    };

    //$scope.confirmAppointmentReminder();

}

angular.module('common.appointment-reminder', [])
.controller('AppointmentReminderCtrl', appointmentReminderCtrl);
})();

(function() {
  'use strict';

  function ProfileCtrl($rootScope, $scope, $log, $state, $http, PrincipalService, AuthenticationService, NotificationService,$timeout) {
    $log.debug('Profile loaded');

    PrincipalService.identity().then(function (response) {
      AuthenticationService.getUserAccount(response.id).then(function (response) {

        $scope.me = response.data;
      });
    });

    $scope.clearSignature = function(account,field){
      account[field] = null;
      //We need to set it to '' so allow_blank=True works fine as is the default on django and not null
      //and null is the only way to clear out the signature on the component
      $timeout(function(){
        account[field] = '';
      },1);

    };


    $scope.save = function (data) {
        // Update user's security questions
        const formData = data ? data : $scope.me;
        AuthenticationService.updateUserAccount(formData.id, formData).then(function () {
          PrincipalService.authenticate(null);
          $http.post('/api/v1/auth/logout/', {})
          .then(function() {
            $state.go('signout');
            NotificationService.success('Your profile information has been updated. Please log in again');
          }, function() {
            //silent continue to the login screen if an error occurs
            $state.go('signout');
            NotificationService.success('Your profile information has been updated. Please log in again');
          });
          //$scope.me = response.data;
          //$rootScope.$broadcast('updatedUserAccount', response.data);
          //PrincipalService.setUser(response.data);
        }, function (err) {
          NotificationService.error('Error update your profile information',err);
        });
    };

  }

  angular.module('common.profile', [])
    .controller('ProfileCtrl', ProfileCtrl);
})();

(function() {
  "use strict";

  angular.module("common.settings", [])
    .controller("SettingsCtrl", settingsCtrl)

  function settingsCtrl(
    $http,
    $scope,
    $state,
    NotificationService,
    PrincipalService
    ) {
    $scope.twoFactorEnabled = PrincipalService.getTwoFactorStatus()
    $scope.loading = false
    $scope.navigationCallback = function cb(link) {
      console.debug("Navigating to " + link);
      $state.go(link);
      return;
    }
    $scope.downloadDesignatedRecordSet = function() {
      $scope.loading = true
      var url = "/api/v2/~authentication/practices/" + PrincipalService.getPracticeId() + "/clininal-data.zip"
      $http.get(url, {responseType:"blob"}).then(
        function(response) {
          $scope.loading = false
          var link = angular.element("#download-designated-recordset")
          link.attr("href", URL.createObjectURL(response.data))
          link.attr("download", response.headers("content-name"))
          link[0].click()
        },
        function(errors) {
          $scope.loading = false
          NotificationService.error("Error Downloading Clinical Information", errors)
        }
      )
    }
  }
})();

(function() {
  'use strict';

  function accountSettingsCtrl(
		$log,
		$scope,
		BillingService,
		PrincipalService,
		AuthenticationService,
		$uibModal,
		$q,
		NotificationService,
		CanCreateGLAccount,
		CanUpdateGLAccount
	) {


    $scope.CanCreateGLAccount = CanCreateGLAccount;
    $scope.CanUpdateGLAccount = CanUpdateGLAccount;

	$q.all([
		AuthenticationService.getPractice(PrincipalService.getPracticeId())
	]).then(function(responses) {
		$scope.practice = angular.fromJson(responses[0].data);
	}, function(err) {
      NotificationService.error('Error Loading practice information',err);
	});


	function resetAccounts() {
	 BillingService.getAccountsForPractice(PrincipalService.getPracticeId()).then(function(data) {
		  $scope.accounts = angular.fromJson(data.data);
	  }, function(err) {
      NotificationService.error('Error Loading accounts',err);
	  });
	  $scope.journalEntryDetails = [];
    }

	resetAccounts();

	function resetJournalEntryDetails(accountId) {
		$scope.accountTotal = BillingService.getAmountToFixed('0.00');
		$scope.accountTotalCurrency = '';
		$log.debug(accountId);
		if (accountId === null) {
			$scope.journalEntryDetails = [];
		} else {
			/*
				Builds journalEntryDetails:
				
				journalEntryDetails [
					journalEntryDetail {
						journalEntry: {
							
						},
						otherJournalEntryDetailInfos: [
							{
								...
								gl_account_number
							}

						]
					}
				]
			*/
			BillingService.getJournalEntriesForAccount(accountId).then(function(data) {
				var journalEntries = angular.fromJson(data.data);
				$scope.journalEntryDetails = [];

				for (var q=0; q<journalEntries.length; q++) {

					//add gl_account_number to all of this je's jeds
					for (var m=0; m<journalEntries[q].journal_entry_details.length; m++) {
						var accountIndex = $scope.accounts.map(function(account) { return account.id; }).indexOf(journalEntries[q].journal_entry_details[m].gl_account);
						if (accountIndex > -1) {
							journalEntries[q].journal_entry_details[m].gl_account_number = $scope.accounts[accountIndex].gl_account_number;
						}
					}

					for (m=0; m<journalEntries[q].journal_entry_details.length; m++) {
						if (journalEntries[q].journal_entry_details[m].gl_account === accountId) {
							var otherJournalEntryDetailInfos = [];

							//set je on the jed
							journalEntries[q].journal_entry_details[m].journalEntry = journalEntries[q];

							//put the jed into the list to display
							$scope.journalEntryDetails.push(journalEntries[q].journal_entry_details[m]);

							//go back through the je's jeds and for all the other jeds add their info to an array otherJournalEntryDetailInfos
							for (var z=0; z<journalEntries[q].journal_entry_details.length; z++) { 
								if (journalEntries[q].journal_entry_details[z].id !== journalEntries[q].journal_entry_details[m].id) {
									otherJournalEntryDetailInfos.push({
										id: journalEntries[q].journal_entry_details[z].id,
										gl_account_number: journalEntries[q].journal_entry_details[z].gl_account_number,
										amount: journalEntries[q].journal_entry_details[z].amount
									});
								}
							}
							//then set that array to a otherJournalEntryDetailInfos property on the jed
							journalEntries[q].journal_entry_details[m].otherJournalEntryDetailInfos = otherJournalEntryDetailInfos;
						}
					}
					delete journalEntries[q].journal_entry_details; // don't need this anymore since done with je
				}


				if ($scope.journalEntryDetails.length > 0) {
					$scope.accountTotalCurrency = $scope.journalEntryDetails[0].currency;
					var now = new Date();
					now.setTime(now.getTime() + (now.getTimezoneOffset() * 60000));
					$log.debug(now);
					$log.debug(now.getMonth());
					$log.debug(now.getDay());
					BillingService.getAccountBalanceThrough(accountId, now).then(function(data) {
						var totalObj = angular.fromJson(data.data);
						$scope.accountTotal = BillingService.getAmountToFixed(totalObj.totalAmount);
					 }, function(err) {
						$log.debug('Error getting total for account. Error reports: ' + err);
						//toastr.error('Error getting total for account.');
					});
				}
			 }, function(err) {
        NotificationService.error('Error Getting journal entries for account',err);
			});
		}
	}

	$scope.selectAccount = function(accountId) {
		resetJournalEntryDetails(accountId);
	};


	$scope.updateAccount = function(account) {
		if (account) {
			BillingService.updateAccount(account.id, account).then(function(data) {
				account = angular.fromJson(data.data);
				NotificationService.success('Successfully updated account.');
			 }, function(err) {
        NotificationService.error('Error Updating account',err);
			});
		}
		resetAccounts();
	};


// ADD ACCOUNT
    $scope.openAddAccountModal = function (currency) {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'addAccount.html',
        controller: 'addAccountModalInstanceCtrl',
        size: 'sm',
        resolve: {
          currency: function () {
            return currency;
          }
        }
      });

      modalInstance.result.then(function (newAccount) {
          $scope.doAddAccount(newAccount);
      }, function () {
      });
    };

    $scope.doAddAccount = function(newAccount) {
			$log.debug(newAccount);
			BillingService.createAccountForPractice(PrincipalService.getPracticeId(), newAccount).then(function() {
					NotificationService.success('Successfully created new account.');
					resetAccounts();
			}, function(err) {
					NotificationService.error('Error Creating new account',err);
			});
    };


// ADD JOURNAL ENTRY
    $scope.openAddJournalEntryModal = function (account, accounts) {
	  var currency;
	  var accountsWithoutAccountId = [];
	  for (var k = 0; k < accounts.length; k++) {
		if (accounts[k].id !== account.id) {
			accountsWithoutAccountId.push(accounts[k]);
		} else {
			currency = accounts[k].currency;
		}
	  }
      var accountsWithoutAccountIdAndSameCurrency = [];
	  for (var l = 0; l < accountsWithoutAccountId.length; l++) {
		if (accountsWithoutAccountId[l].currency === currency) {
			accountsWithoutAccountIdAndSameCurrency.push(accountsWithoutAccountId[l]);
		}
	  }

      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'addJournalEntry.html',
        controller: 'addJournalEntryModalInstanceCtrl',
        size: 'sm',
        resolve: {
          account: function () {
            return account;
          },
          currency: function () {
            return currency;
          },
          accountsWithoutAccountIdAndSameCurrency: function () {
            return accountsWithoutAccountIdAndSameCurrency;
          }
        }

      });

      modalInstance.result.then(function (newJournalEntry) {
		  var journalEntry = {};
          journalEntry.description = newJournalEntry.description;
          journalEntry.memo = newJournalEntry.memo;
          journalEntry.journal_entry_details = [];

		  var journalEntryDetail1 = {};
          journalEntryDetail1.gl_account = newJournalEntry.gl_account1;
          journalEntryDetail1.description = newJournalEntry.description;
          journalEntryDetail1.memo = newJournalEntry.memo;
	  	  journalEntryDetail1.amount = -newJournalEntry.amount;
	  	  journalEntryDetail1.currency = currency; //newJournalEntry.currency;

		  var journalEntryDetail2 = {};
          journalEntryDetail2.gl_account = newJournalEntry.gl_account2;
          journalEntryDetail2.description = newJournalEntry.description;
          journalEntryDetail2.memo = newJournalEntry.memo;
 		  journalEntryDetail2.amount = newJournalEntry.amount;
	  	  journalEntryDetail2.currency = currency; //newJournalEntry.currency;

          journalEntry.journal_entry_details.push(journalEntryDetail1);
		  journalEntry.journal_entry_details.push(journalEntryDetail2);

          doAddJournalEntry(journalEntry, newJournalEntry.gl_account1); //gl_account1 is the account context in which the addition is being done and is equal to openAddJournalEntryModal()'s accountId parameter (see template)
      }, function () {
      });
    };

    function doAddJournalEntry(journalEntry, accountId) {
			$log.debug(journalEntry);
			BillingService.createJournalEntryForPractice(PrincipalService.getPracticeId(), journalEntry).then(function() {
					NotificationService.success('Successfully created new journal entry.');
					resetJournalEntryDetails(accountId);
			}, function(err) {
					NotificationService.error('Error Creating new journal entry',err);
			});
    }
  }

  function addAccountModalInstanceCtrl($scope, $uibModalInstance, currency) {
	  $scope.newAccount = {};
	  $scope.newAccount.currency = currency;
      $scope.ok = function () {
        $uibModalInstance.close($scope.newAccount);
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

  }

  function addJournalEntryModalInstanceCtrl($scope, $uibModalInstance, account, currency, accountsWithoutAccountIdAndSameCurrency) {
	  $scope.title = 'Add journal entry';
	  $scope.account = account;
	  $scope.currency = currency;
	  $scope.newJournalEntry = {};
	  $scope.newJournalEntry.gl_account1 = account.id;
	  $scope.accountsWithoutAccountIdAndSameCurrency = accountsWithoutAccountIdAndSameCurrency;
      $scope.ok = function () {
        $uibModalInstance.close($scope.newJournalEntry);
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

  }

  angular.module('common.settings-account', [])
    .controller('AccountSettingsCtrl', accountSettingsCtrl)
    .controller('addAccountModalInstanceCtrl', addAccountModalInstanceCtrl)
    .controller('addJournalEntryModalInstanceCtrl', addJournalEntryModalInstanceCtrl);
})();

(function() {
  'use strict';

  var CLIENT_FORMAT_REGEX = /^([# \w]*)\$(0?)(\d{1,99})\$$/;
  var SERVER_FORMAT_REGEX = /^([# \w]*){:(0?)(\d{1,2})}$/;
  var MERCHANT_ID_REGEX = /^[0-9]{6}$/;

  function padLeft(number, width, fill) {
    return Array(width-String(number).length+1).join(fill||'0') + number;
  }

  function numberingFormatToPython(value) {
    var parts = value.split('$',2);
    var fill = parts[1].startsWith('0') ? '0' : '';
    var width = parts[1].length;
    return {
      format: parts[0] + '{:' + fill + width + '}',
      last: parseInt(parts[1]),
    };
  }

  function clientFormatFromServerFormat(server_format, server_last_value, separator) {
    if (typeof separator === 'undefined') {
      separator = '$';
    }
    var match = server_format.match(SERVER_FORMAT_REGEX);
    if (match) {
      return match[1] + separator + padLeft(server_last_value, match[3], match[2]) + separator;
    }
  }

  function clientNextValueFromClientFormat(value) {
    if (CLIENT_FORMAT_REGEX.test(value)) {
      return value.split('$',2).join('');
    }
  }

  function billingSettingsCtrl($log, $q, PrincipalService, AuthenticationService, $scope, NotificationService,CanUpdateBillingSetting) {
    $log.debug('Billing Settings loaded');

    $scope.CanUpdateBillingSetting = CanUpdateBillingSetting;
    $scope.CLIENT_FORMAT_REGEX = CLIENT_FORMAT_REGEX;
    $scope.SERVER_FORMAT_REGEX = SERVER_FORMAT_REGEX;
    $scope.MERCHANT_ID_REGEX = MERCHANT_ID_REGEX;

    $scope.update = function() {
      $scope.practice.invoice_number_last -= 1;
      $scope.practice.claim_number_last -= 1;
      $scope.practice.reference_number_last -= 1;
      AuthenticationService.updatePractice(PrincipalService.getPracticeId(), $scope.practice).then(
        function(data) {
          $scope.practice = angular.fromJson(data.data);
          $scope.practice.invoice_number_last += 1;
          $scope.practice.claim_number_last += 1;
          $scope.practice.reference_number_last += 1;
          NotificationService.success('Successfully updated practice information.');
        },
        function(err) {
          $scope.practice.invoice_number_last += 1;
          $scope.practice.claim_number_last += 1;
          $scope.practice.reference_number_last += 1;
          NotificationService.error('Error updating practice',err);
        }
      );
    };

    $q.all([
      AuthenticationService.getPractice(PrincipalService.getPracticeId()),
      AuthenticationService.getPracticeAdminAndProviderAccounts(PrincipalService.getPracticeId())
    ]).then(
      function(responses) {
        $scope.practice = angular.fromJson(responses[0].data);
        $scope.providers = angular.fromJson(responses[1].data);

        $scope.practice_values = {
          invoice_number_format: clientFormatFromServerFormat(
            $scope.practice.invoice_number_format, $scope.practice.invoice_number_last += 1
          ),
          claim_number_format: clientFormatFromServerFormat(
            $scope.practice.claim_number_format, $scope.practice.claim_number_last += 1
          ),
          reference_number_format: clientFormatFromServerFormat(
            $scope.practice.reference_number_format, $scope.practice.reference_number_last += 1
          ),
          invoice_number_last: '',
          claim_number_last: '',
          reference_number_last: ''
        };

        $scope.$watch('practice_values.invoice_number_format', function(newVal) {
          if (newVal) {
            var to_python = numberingFormatToPython($scope.practice_values.invoice_number_format);
            $scope.practice.invoice_number_format = to_python.format;
            $scope.practice.invoice_number_last = to_python.last;
            $scope.practice_values.invoice_number_last = clientNextValueFromClientFormat(newVal);

          }
        });
        $scope.$watch('practice_values.claim_number_format', function(newVal) {
          if (newVal) {
            var to_python =  numberingFormatToPython($scope.practice_values.claim_number_format);
            $scope.practice.claim_number_format = to_python.format;
            $scope.practice.claim_number_last = to_python.last;
            $scope.practice_values.claim_number_last = clientNextValueFromClientFormat(newVal);
          }
        });
        $scope.$watch('practice_values.reference_number_format', function(newVal) {
          if (newVal) {
            var to_python = numberingFormatToPython($scope.practice_values.reference_number_format);
            $scope.practice.reference_number_format = to_python.format;
            $scope.practice.reference_number_last = to_python.last;
            $scope.practice_values.reference_number_last = clientNextValueFromClientFormat(newVal);
          }
        });
      },
      function(err) {
        NotificationService.error('Error loading practice information', err);
      }
    );
  }

  angular.module('common.settings-billing', [])
    .controller('BillingSettingsCtrl', billingSettingsCtrl);
})();

(function() {
  'use strict';

  function chartTemplatesSettingsCtrl($log, PrincipalService, TemplatesService, $scope, $uibModal, NotificationService,CanDeleteEncounterTemplate) {
    $log.debug('ChartTemplatesSettingsCtrl loaded');

    $scope.CanDeleteEncounterTemplate = CanDeleteEncounterTemplate;
	function reset() {
	 TemplatesService.allForProvider(PrincipalService.getAccountId()).then(function(data) {
		  $scope.templates = angular.fromJson(data.data);
	  }, function(err) {
      NotificationService.error('Error loading templates',err);
	  });
    }

	reset();

     //delete template modal
    $scope.openDelTemplateModal = function (templateId, title) {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'delTemplate.html',
        controller: 'delTemplateModalInstanceCtrl',
        size: 'sm',
        resolve: {
          title: function () {
            return title;
          }
        }
      });

      modalInstance.result.then(function () {
          $scope.doDel(templateId);
      }, function () {
      });
    };

    $scope.doDel = function(templateId) {
        TemplatesService.delete(templateId).then(function() {
           NotificationService.success('Successfully deleted template.');
           reset();
        }, function(err) {
            NotificationService.error('Error deleting template',err);
        });
    };

  }

  function delTemplateModalInstanceCtrl($scope, $uibModalInstance, title) {
      $scope.title = title;
      $scope.ok = function () {
        $uibModalInstance.close();
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
  }
  angular.module('common.settings-chart-templates', [])
    .controller('ChartTemplatesSettingsCtrl', chartTemplatesSettingsCtrl)
    .controller('delTemplateModalInstanceCtrl', delTemplateModalInstanceCtrl);
})();

(function() {
  'use strict';

  angular.module('common.settings-consent-forms-templates', [])
    .controller('ConsentFormsSettingsCtrl', ConsentFormsSettingsCtrl);

  function ConsentFormsSettingsCtrl(
    $scope,
    $uibModal,
    CanCreateConsentFormTemplate,
    CanDeleteConsentFormTemplate,
    CanListConsentFormTemplate,
    CanRetrieveConsentFormTemplate,
    CanUpdateConsentFormTemplate,
    ConsentFormTemplateService,
    NotificationService
  ) {

    $scope.CanCreateConsentFormTemplate = CanCreateConsentFormTemplate;
    $scope.CanDeleteConsentFormTemplate = CanDeleteConsentFormTemplate;
    $scope.CanListConsentFormTemplate = CanListConsentFormTemplate;
    $scope.CanRetrieveConsentFormTemplate = CanRetrieveConsentFormTemplate;
    $scope.CanUpdateConsentFormTemplate = CanUpdateConsentFormTemplate;

    $scope.consent_form_templates = [];
    $scope.upload_queue = [];

    $scope.load = function(notify) {
      if ($scope.CanListConsentFormTemplate) {
        ConsentFormTemplateService.list().then(
          function(response) {
            $scope.consent_form_templates = response.data;
            // TODO: jjlorenzo clear errored files from $scope.upload_queue
            if (notify) {
              NotificationService.success('Loaded Consent Form Templates');
            }
          },
          function(response) {
            $scope.consent_form_templates = [];
            NotificationService.error('Error Loading Consent Form Templates', response);
          }
        );
      }
      else {
        $scope.consent_form_templates = [];
      }
    };

    $scope.upload = function(files) {
      angular.forEach(files, function(file) {
        $scope.upload_queue.push(file);
        ConsentFormTemplateService.create({form_archive:file}).then(
          function(response) {
            if (response.data && response.data.id) {
              $scope.consent_form_templates.splice(0, 0, response.data);
              $scope.upload_queue.splice($scope.upload_queue.indexOf(file), 1);
              file.status = 'completed';
              NotificationService.success('Consent Form Template added');
            }
            else {
              file.status = 'error';
              NotificationService.error('Error Adding Consent Form Template', response);
            }
          },
          function(response) {
            file.status = 'error';
            NotificationService.error('Error Adding Consent Form', response);
          },
          function(event) {
            file.status = 'progress';
            file.progress = Math.min(100, parseInt(100.0 * event.loaded / event.total));
          }
        );
      });
    };

    $scope.previewModal = function(index) {
      $uibModal.open({
        controller: function($sce, $scope, consent_form_template) {
          $scope.CanUpdateConsentFormTemplate = CanUpdateConsentFormTemplate;
          $scope.loading = false;
          $scope.consent_form_template = consent_form_template;
          $scope.form_archive_url = $sce.trustAsResourceUrl($scope.consent_form_template.form_archive);
          $scope.update = function() {
            $scope.loading = true;
            return ConsentFormTemplateService.update(consent_form_template).then(
              function(response) {
                $scope.loading = false;
                $scope.consent_form_templates[index].name = response.data.name;
                NotificationService.success('Successfully updated Consent Form Template');
                $scope.$close();
              },
              function(response) {
                $scope.loading = false;
                NotificationService.error('Error Updating Consent Form Template', response);
              }
            );
          };
          $scope.download = function() {
            return ConsentFormTemplateService.download(consent_form_template.id).then(
              function(response) {
                var link = angular.element('<a/>');
                link.attr('href', response.data.form_archive);
                link.attr('download', '');
                link.attr('target', '_blank');
                link[0].click();
              },
              function(response) {
                NotificationService.error('Error Downloading Consent Form Template', response);
              }
            );
          };
        },
        scope: $scope,
        templateUrl: '#ConsentFormTemplatePreviewModal',
        resolve:{
          consent_form_template: function() {
            return ConsentFormTemplateService.retrieve($scope.consent_form_templates[index].id).then(
              function(response) {
                return response.data;
              },
              function() {
                return {};
              }
            );
          }
        },
      });
    };

    $scope.removeModal = function(index) {
      $uibModal.open({
        controller: function($scope) {
          $scope.loading = false;
          $scope.remove = function() {
            $scope.loading = true;
            return ConsentFormTemplateService.destroy($scope.consent_form_templates[index].id).then(
              function() {
                $scope.loading = false;
                $scope.consent_form_templates.splice(index, 1);
                NotificationService.success('Successfully removed Consent Form Template');
                $scope.$close();
              },
              function(response) {
                $scope.loading = false;
                NotificationService.error('Error Error removing Consent Form Template', response);
              }
            );
          };
        },
        scope: $scope,
        templateUrl: '#ConsentFormTemplateRemoveModal',
      });
    };

    $scope.load();

  }

})();

(function() {
  'use strict';

  function chartNotesSettingsCtrl($log, $scope, $rootScope, ProceduresService, PrincipalService, $uibModal, $q,
                                  AuthenticationService, NotificationService,InsuranceService,CanCreateFeeProfile,CanDeleteFeeProfile,CanUpdateFeeProfile) {


    $scope.CanCreateFeeProfile = CanCreateFeeProfile;
    $scope.CanDeleteFeeProfile = CanDeleteFeeProfile;
    $scope.CanUpdateFeeProfile = CanUpdateFeeProfile;

    $q.all([
      AuthenticationService.getPractice(PrincipalService.getPracticeId())
    ])
    .then(function(responses) {
      $scope.practice = angular.fromJson(responses[0].data);
      reset();
    }, function(err) {
        NotificationService.error('Error loading practice information',err);
    });

      $rootScope.$on('selectFirstFeeProfile',function(){
        if($scope.feeprofiles && $scope.feeprofiles.length === 1){
          $scope.selectFeeprofile($scope.feeprofiles[0].id,$scope.feeprofiles[0]);
        }
      });

	function reset() {


    $q.all([InsuranceService.getPayers(),ProceduresService.getAllFeeprofiles(PrincipalService.getPracticeId())]).then(function(events){
      $scope.selectFeeprofile(null,null);

      var payers = events[0].data;
      var feeProfiles = events[1].data;
      var payersKeyValues = {};

      for (var j = 0; j < payers.length; j++) {
        payersKeyValues[payers[j].id] = payers[j];
      }

      for (var i = 0; i < feeProfiles.length; i++) {
        var feeProfile = feeProfiles[i];
        if(feeProfile.payer && payersKeyValues[feeProfile.payer]){
          feeProfile.payer =  payersKeyValues[feeProfile.payer];
        }
      }

      $scope.feeprofiles = feeProfiles;
      $scope.fees = [];

    },function(err){
      NotificationService.error('Error loading Fee Profiles data',err);
    });
  }

	$scope.selectFeeprofile = function(feeprofileId,feeprofile) {
		$log.debug(feeprofileId);
		$scope.fpselected = feeprofileId;
    $scope.feeprofileselected = feeprofile;
		if (feeprofileId === null) {
			$scope.fees = [];
		} else {
			ProceduresService.getFeesForFeeprofile(feeprofileId).then(function(data) {
        $scope.fees = data.data;
			}, function(err) {
        NotificationService.error('Error getting fees for fee profile',err);
			});
		}
	};


    $scope.sortType = 'name';

    /**
     * Keep Fee for Service on top
     * @param fee
     * @returns {number}
     */
    $scope.orderByFn = function(feeProfile){
      if(feeProfile.name === 'Fee for Service'){
        return -1;
      } else {
        return feeProfile[$scope.sortType];
      }
    };


    $scope.updateFeeProfile = function(fp){
      var feeProfile = angular.copy(fp);
      if(feeProfile.payer){
        feeProfile.payer = feeProfile.payer.id;
      }
      ProceduresService.updateFeeprofile(feeProfile.id,feeProfile).then(
      function(){
        NotificationService.success('Successfully updated fee profile.');
      },
      function(err){
        NotificationService.error('Error updating fee profile',err);
      });
    };

	$scope.updateFee = function(fee) {
		//$log.debug('fee: ' + fee.amount + '- copay: ' + fee.copay);
        ProceduresService.updateFee(fee.id, fee).then(function(data) {
		  	fee = angular.fromJson(data.data);
			  NotificationService.success('Successfully updated fee.');
         }, function(err) {
            NotificationService.error('Error updating fee',err);
        });
	};

//add feeprofile modal
    $scope.openAddFeeprofileModal = function (fp) {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'src/common/settings-fee-profile-add.tpl.html',
        controller: 'addFeeprofileModalInstanceCtrl',
        size: 'lg',
        resolve: {
          modalData: function () {
            return {fp: fp};
          }
        }
      });

      modalInstance.result.then(function () {
          reset();
      }, function () {
      });
    };

//delete feeprofile
    $scope.openDelFeeprofileModal = function (feeprofileId, name) {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'delFeeprofile.html',
        controller: 'delFeeprofileModalInstanceCtrl',
        size: 'sm',
        resolve: {
          name: function () {
            return name;
          }
        }
      });

      modalInstance.result.then(function () {
          $scope.doDeleteFeeProfile(feeprofileId);
      }, function () {
      });
    };

    $scope.doDeleteFeeProfile = function(feeprofileId) {
        ProceduresService.deleteFeeprofile(feeprofileId).then(function() {
           NotificationService.success('Successfully deleted fee profile.');
           reset();
        }, function(err) {
            NotificationService.error('Error deleting fee profile',err);
        });
    };




  }

  function addFeeprofileModalInstanceCtrl($scope, $uibModalInstance, InsuranceService, NotificationService,ProceduresService,PrincipalService,modalData) {

      if (modalData.fp) {
        $scope.hasInsuranceFees = modalData.fp.has_insurance_fees ? true: false;
        $scope.disablePayer = true;
      } else {
        $scope.hasInsuranceFees = modalData.hasInsuranceFees ? true: false;
        $scope.disablePayer = modalData.hasInsuranceFees ? true: false;
      }

      $scope.doAdd = function(data, fpToCopy) {
        var promise = (fpToCopy) ? ProceduresService.createFeeprofile(PrincipalService.getPracticeId(), data, fpToCopy.id) : ProceduresService.createFeeprofile(PrincipalService.getPracticeId(), data);
        promise.then(function(event) {
          NotificationService.success('Successfully created new fee profile.');
          $uibModalInstance.close(event.data);
        }, function(err) {
          NotificationService.error('Error creating new fee profile',err);
        });
      };

      $scope.ok = function () {
        var data = {};
        data.name = $scope.newFeeprofileName;
        data.has_insurance_fees = $scope.hasInsuranceFees ? true : false;
        if ($scope.hasInsuranceFees) {
          data.payer = $scope.payer.id;
        }

        $scope.doAdd(data, modalData.fp);

      };

      InsuranceService.getPayers().then(
        function(event){
          $scope.payers = event.data;
          $scope.payer = {id:modalData.payer ? modalData.payer : null};
        },
        function(err){
          NotificationService.error('Error loading payers',err);
        });

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

  }

  function delFeeprofileModalInstanceCtrl($scope, $uibModalInstance, name) {
      $scope.name = name;
      $scope.ok = function () {
        $uibModalInstance.close();
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

  }

  function delFeeModalInstanceCtrl($scope, $uibModalInstance, name) {
      $scope.name = name;
      $scope.ok = function () {
        $uibModalInstance.close();
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

  }

  angular.module('common.settings-chart-notes', [])
    .controller('ChartNotesSettingsCtrl', chartNotesSettingsCtrl)
    .controller('delFeeprofileModalInstanceCtrl', delFeeprofileModalInstanceCtrl)
    .controller('delFeepModalInstanceCtrl', delFeeModalInstanceCtrl)
    .controller('addFeeprofileModalInstanceCtrl', addFeeprofileModalInstanceCtrl);
})();

(function() {
  'use strict';

  function imagingSettingsCtrl($log) {
    $log.debug('Imaging Settings loaded');
  }

  angular.module('common.settings-imaging', [])
    .controller('ImagingSettingsCtrl', imagingSettingsCtrl);
})();

(function() {
  'use strict';

  function practiceSettings($log, PrincipalService, AuthenticationService, $q, $scope, NotificationService,CanUpdatePracticeSetting,PracticeService) {

    $scope.CanUpdatePracticeSetting = CanUpdatePracticeSetting;

    $q.all([
      AuthenticationService.getPractice(PrincipalService.getPracticeId()),
      AuthenticationService.getPracticeSchema(PrincipalService.getPracticeId()),
    ]).then(
      function(responses) {
        $scope.practice = angular.fromJson(responses[0].data);
        $scope.practice_schema = angular.fromJson(responses[1].data);

        for(var i=0;i<$scope.practice.dow.length;i++){
          var day = 'day'+$scope.practice.dow[i];
          $scope[day] = true;
        }

        $scope.startTime = moment().hour($scope.practice.business_hours_start).minute($scope.practice.business_minutes_start).toDate();
        $scope.endTime = moment().hour($scope.practice.business_hours_end).minute($scope.practice.business_minutes_end).toDate();

      },
      function(err) {
        NotificationService.error('Error loading practice information', err);
      }
    );

    $scope.save = function(data) {

      var dow = [];
      for(var i=0;i<=6;i++){
        if($scope["day"+i] === true){
          dow.push(i);
        }
      }

      $scope.practice.dow = dow;

      if(!data){
        if(moment($scope.endTime).isBefore(moment($scope.startTime)) || moment($scope.startTime).isSame(moment($scope.endTime))){
          return NotificationService.error("End Time can't be before or same than Start Time");
        }
      }

      var startHour = moment($scope.startTime).get('hour');
      var startMinutes = moment($scope.startTime).get('minute');
      var endHour = moment($scope.endTime).get('hour');
      var endMinutes = moment($scope.endTime).get('minute');

      $scope.practice.business_hours_start = startHour;
      $scope.practice.business_minutes_start = startMinutes;
      $scope.practice.business_hours_end = endHour;
      $scope.practice.business_minutes_end = endMinutes;


      AuthenticationService.updatePractice(PrincipalService.getPracticeId(), data ? data : $scope.practice).then(
        function(data) {
          $scope.practice = angular.fromJson(data.data);
          NotificationService.success('Successfully updated practice information.');
        },
        function(err) {
          NotificationService.error('Error updating practice',err);
        }
      );
    };


    $scope.updatePracticePicture = function(file) {
      if (file) {
        var origPracticePicture = $scope.practice.picture;
        var newPracticePicture = URL.createObjectURL(file);
        $scope.uploading = true;
        $scope.practice.picture = newPracticePicture;

        PracticeService.upload($scope.practice, {picture: file}).then(
          function() {
            delete $scope.practice.$picture_progress;
          },
          function() {
            delete $scope.practice.$picture_status;
            $scope.practice.picture = origPracticePicture;
          }
        );
      }
      else {
        NotificationService.error("Provided image is not in a supported format")
      }
    };

  }

  angular.module('common.settings-practice', [])
    .controller('PracticeSettings', practiceSettings);
})();

(function() {
  'use strict';

  function practiceDetailsSettingsCtrl($log, $uibModal, $scope, LocationsService, NotificationService,
    PrincipalService,CanUpdateLocationOperatory,Locations,MultiLocationEnable) {

    $scope.MULTILOCATION_ENABLE = MultiLocationEnable;

    $log.debug('Practice Detail Settings loaded');

    $scope.CanUpdateLocationOperatory = CanUpdateLocationOperatory;

    $scope.locations = Locations;

    $scope.showLocation = false;
    $scope.savedLocation = false;

    $scope.cancelSaveLocation = function(){
      $scope.hideLocation();
    };

    $scope.viewLocation = function(index){
      $scope.location = $scope.locations[index];
      $scope.operatories = $scope.location.operatories;
      $scope.showLocation = $scope.location.id;
      $scope.savedLocation = true;
    };

    $scope.hideLocation = function(){
      $scope.location = {};
      $scope.operatories = [];
      $scope.showLocation = false;
      $scope.savedLocation = false;

    };

    $scope.addNewLocation = function(){
      $scope.location = {};
      $scope.location.operatories = [];
      $scope.location.time_zone = moment.tz.guess();
      $scope.operatories = [];
      $scope.showLocation = true;
      $scope.savedLocation = false;
    };

    $scope.updateMainLocation = function(newMainlocation) {
      //Unset last main location
      LocationsService.update($scope.mainLocation.id, $scope.mainLocation).then(
        function() {
          for (var i = 0; i < $scope.locations.length; i++) {
            $scope.locations[i].is_billing_location = false;
          }
          newMainlocation.is_billing_location = true;

          //set main location
          LocationsService.update(newMainlocation.id, newMainlocation).then(
            function() {
              NotificationService.success('Main Location Updated');
            },
            function() {
              NotificationService.error('Error Updating Main Location');
            }
          );

        },
        function() {
          NotificationService.error('Error Updating Main Location');
        }
      );

    };


    $scope.timeZones = function () {
      return moment.tz.names();
    };

    $scope.updateLocations = function() {


      LocationsService.all().then(
        function(data) {




          if(!$scope.MULTILOCATION_ENABLE){
            $scope.location = angular.fromJson(data.data)[0];
            $scope.operatories = $scope.location.operatories;

            if(!$scope.location.time_zone){
              $scope.location.time_zone = moment.tz.guess();
            }
          }else{
            $scope.locations = data.data;

            if($scope.location){
              var currentLocationIdx = $scope.locations.findIndex(function(location){
                return location.id === $scope.location.id;
              });

              var edit = false;

              if($scope.location.edit){
                edit = true;
              }

              if(currentLocationIdx !== -1){
                $scope.location = $scope.locations[currentLocationIdx];
                $scope.operatories = $scope.location.operatories;
                $scope.location.edit = edit;
              }




            }



          }




        },
        function() {
          NotificationService.error('Error loading operatories list');
        });


      var mainLocation =  $scope.locations.filter(function(location){
        return location.is_billing_location;
      });


      $scope.mainLocation = mainLocation[0];


    };

    $scope.saveLocationAddress = function() {

      if(!$scope.location.id){
        LocationsService.create($scope.location).then(
          function(data) {
            $scope.location = angular.fromJson(data.data);
            $scope.updateLocations();
            $scope.savedLocation = true;
            $scope.showLocation = $scope.location.id;
            NotificationService.success('Location name and address saved.');
          },
          function() {
            NotificationService.error('Error saving location name and address');
          }
        );

        return;
      }


      LocationsService.update($scope.location.id, $scope.location).then(
          function(data) {
              $scope.location = angular.fromJson(data.data);
              NotificationService.success('Location name and address saved.');
          },
          function() {
            NotificationService.error('Error saving location name and address');
          }
      );


    };

    $scope.openAddModal = function() {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'src/common/settings-locations-add.tpl.html',
        controller: 'SettingsLocationsAddCtrl',
        resolve: {
          operatory: function () {
            return { location: $scope.location,
                     name: '',
                     index: -1
                   };
          },
          MultiLocationEnable:MultiLocationEnable,
          Locations:function(){
            return $scope.locations;
          }
        }
      });

      modalInstance.result.then(function () {
        $scope.updateLocations();
      }, function () {
      });
    };

    $scope.openEditModal = function(index) {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'src/common/settings-locations-add.tpl.html',
        controller: 'SettingsLocationsAddCtrl',
        resolve: {
          operatory: function(){
            return {
              location: $scope.location,
              name: $scope.operatories[index].name,
              id: $scope.operatories[index].id,
              index: index
            }
          },
          MultiLocationEnable: MultiLocationEnable,
          Locations: function(){
            return $scope.locations;
          }
        }
      });

      modalInstance.result.then(function () {
        $scope.updateLocations();
      }, function () {
      });
    };

    $scope.openRemoveModal = function(index) {
      var operatory = $scope.operatories[index];
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: '#removeOperatoryModal',
        controller: function($sce, $scope) {
          $scope.operatory = operatory;
        },
        size: 'md',
        scope: $scope,
      });
      modalInstance.result.then(
        function() {
          $scope.location.operatories.splice(index, 1);
          LocationsService.update(PrincipalService.getPracticeId(), $scope.location).then(
            function(data) {
              $scope.location = angular.fromJson(data.data);
              $scope.operatories = $scope.location.operatories;
              NotificationService.success('Operatory removed.');
            },
            function(err) {
              NotificationService.error('Error Removing operatory',err);
            }
          );
        }
      );
    };

    $scope.openRemoveLocationModal = function(index) {
      var location = $scope.locations[index];
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: '#removeLocationModal',
        controller: function($sce, $scope) {
          $scope.location = location;
        },
        size: 'md',
        scope: $scope,
      });
      modalInstance.result.then(
        function() {
          LocationsService.delete(location.id, location).then(
            function() {
              $scope.locations.splice(index, 1);
              $scope.hideLocation();
              NotificationService.success('Location removed.');
            },
            function(err) {
              NotificationService.error('Error Removing location',err);
            }
          );
        }


      );
    };

    $scope.updateLocations();
  }

  angular.module('common.settings-practice-details', [])
    .controller('PracticeDetailsSettingsCtrl', practiceDetailsSettingsCtrl);
})();

(function() {
    'use strict';

    function practiceAppointmentDefaultsCtrl($scope,$log,LocalStorageService,NotificationService, ScheduleCommonService, $http) {
      $log.debug('Locations Add Controller loaded');
      $scope.appointmentDefaults = LocalStorageService.get('appointmentDefaults');
      $http.get('/api/v1/appointment/default/list').then((response) => 
      {
        $scope.appointmentDefaults = response.data.length > 0 ? ScheduleCommonService.getAppointmentDefaults(response.data) :  $scope.appointmentDefaults
      })

      $scope.save = function() {
        $http.post('/api/v1/appointment/defaults/', $scope.appointmentDefaults);
        LocalStorageService.set('appointmentDefaults', $scope.appointmentDefaults)
        NotificationService.success('Appointment defaults updated.');

      }

    }

    angular.module('common.settings-practice-appointment-defaults', [])
      .controller('PracticeAppointmentDefaultsCtrl', practiceAppointmentDefaultsCtrl);
  })();

(function() {
  'use strict';

  function scheduleSettingsCtrl($log) {
    $log.debug('schedule Settings loaded');
  }

  angular.module('common.settings-schedule', [])
    .controller('ScheduleSettingsCtrl', scheduleSettingsCtrl);
})();

(function() {
  'use strict';

  function importPatientsSettingsCtrl($log) {
    $log.debug('Import Patients Settings loaded');
  }

  angular.module('common.settings-import-patients', [])
    .controller('ImportPatientsSettingsCtrl', importPatientsSettingsCtrl);
})();

(function() {
  'use strict';

  function settingsLocationsAddCtrl($scope, $log, $uibModalInstance, LocationsService, operatory, NotificationService,MultiLocationEnable,Locations) {
    $log.debug('Locations Add Controller loaded');

    $scope.location = operatory.location;
    $scope.index = operatory.index;
    $scope.isNewOperatory = ($scope.index === -1);
    $scope.name = operatory.name;
    $scope.id = operatory.id;

    $scope.create = function () {
      var operatory = {
        name: $scope.name
      };
      if (!$scope.location.operatories) {
        $scope.location.operatories = [];
      }

      //temporal - remove when we display better the operatories names on multicolumn calendar view
      if(MultiLocationEnable){
        for (var i = 0; i < Locations.length; i++) {
          var location = Locations[i];
          for (var j = 0; j < location.operatories.length; j++) {
            var ope = location.operatories[j];
              if(operatory.name === ope.name){
                return NotificationService.error('duplicate name, the name must be unique among all operatories');
              }
          }
        }
      }

      //We don't want to modify the original operatories array
      //until the service successfully finish
      var locationToUpdate = _.clone($scope.location);
      var operatoriesToUpdate = _.clone($scope.location.operatories);

      operatoriesToUpdate.push(operatory);

      locationToUpdate.operatories = operatoriesToUpdate;

      LocationsService.update($scope.location.id, locationToUpdate).then(
        function success() {
          NotificationService.success('Operatory added sucessfully');
          $uibModalInstance.close();
        },
        function fail(err) {
          NotificationService.error('Error adding operatory',err);
        });
    };

    $scope.createOrUpdate = function() {
      if($scope.isNewOperatory){
        $scope.create();
        return;
      }
      $scope.update();
    };

    // Updates an existing appointment (for update existing appointment modal).
    $scope.update = function () {

      //object with name updated
      var op =  { 'id':$scope.id,'name': $scope.name };

      //We don't want to modify the original operatories array
      //until the service successfully finish
      var operatoriesToUpdate = _.clone($scope.location.operatories);
      var locationToUpdate = _.clone($scope.location);
      // Find operatory to update index
      var index = _.indexOf(operatoriesToUpdate, _.find(operatoriesToUpdate, {id: $scope.id}));

      // Replace operatory with the new name at index using native splice
      operatoriesToUpdate.splice(index, 1, op);

      locationToUpdate.operatories = operatoriesToUpdate;

      LocationsService.update($scope.location.id, locationToUpdate).then(
        function success() {
          NotificationService.success('Operatory updated sucessfully');
          $uibModalInstance.close();
        },
        function fail(err) {
          NotificationService.error('Error updating operatory',err);
        });
    };

    // Deletes an existing appointment (for update existing appointment modal).
    $scope.delete = function () {
      $scope.location.operatories.splice($scope.index, 1);
      LocationsService.update($scope.location.id, $scope.location).then(
        function success() {
          NotificationService.success('Operatory deleted');
          $uibModalInstance.close();
        },
        function fail(err) {
          NotificationService.error('Error deleting operatory',err);
        });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }

  angular.module('common.settings-locations-add', [])
    .controller('SettingsLocationsAddCtrl', settingsLocationsAddCtrl);
})();

(function() {
  'use strict';

  function usersSettingsCtrl($rootScope, $q, $scope, $uibModal, $log, $state,
    AuthenticationService, PrincipalService, NotificationService, PasswordService,
    AccountsService,
    CanRetrieveUser, CanUpdateUser, CanCreateUser, CanListUserActivity,LocationsService,MultiLocationEnable
    ) {
    $log.debug('Users Settings loaded');

    $scope.viewAllUsers = false;
    $scope.CanRetrieveUser = CanRetrieveUser;
    $scope.CanUpdateUser = CanUpdateUser;
    $scope.CanCreateUser = CanCreateUser;
    $scope.CanListUserActivity = CanListUserActivity;

    $scope.animationsEnabled = true;

    $scope.sendAccountInvitationEmail = function(account) {
      AccountsService.sendEmail(PrincipalService.getPracticeId(), account.id, "invite").then(
        function() {
          NotificationService.success("Successfully sent invitation to user.")
        },
        function(err) {
          NotificationService.error("Error sending invite", err)
        }
      )
    }

    $scope.sendAccountResetPasswordEmail = function(account) {
      AccountsService.sendEmail(PrincipalService.getPracticeId(), account.id, "reset-password").then(
        function() {
          NotificationService.success("Successfully sent password reset to user.");
        },
        function(err) {
          NotificationService.error("Error sending password reset", err);
        }
      )
    }

    $q.all([
      AuthenticationService.getPractice(PrincipalService.getPracticeId()),
      AuthenticationService.getProviderRoles(),
      AuthenticationService.getStaffRoles()
    ])
    .then(function(responses) {
      $scope.practice = angular.fromJson(responses[0].data);
      $scope.providerRoles = angular.fromJson(responses[1].data);
      $scope.staffRoleToNameMap = angular.fromJson(responses[2].data);
      $scope.resetUsers();
    }, function(err) {
      $log.debug('Error loading practice information. Error reports: ' + err);
    });

    $scope.resetUsers = function() {
     AuthenticationService.getUserAccounts(PrincipalService.getPracticeId()).then(
       function(data) {
         $scope.users = data.data;
       },
       function(err) {
         NotificationService.error('Error loading users list',err);
       });
     };

    $rootScope.$on('openAddEditUserModal',function(event,userId,title){
      $scope.openAddEditModal(userId,title);
    });

    $scope.openAddEditModal = function($user_id,customUserEditTitle) {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'src/common/settings-users-add.tpl.html',
        controller: 'SettingsUsersAddCtrl',
        resolve:{
          user:function(){
            if(!$user_id){
              return null;
            }
            return AuthenticationService.getUserAccount($user_id).then(function(event){
              return event.data;
            });
          },
          edit:$user_id ? true : false,
          CanUpdateUser:CanUpdateUser,
          CanCreateUser:CanCreateUser,
          CustomUserEditTitle:function(){return customUserEditTitle},
          staffRoleToNameMap: function(){ return $scope.staffRoleToNameMap},
          providerRoles: function(){ return $scope.providerRoles},
          MultiLocationEnable:MultiLocationEnable,
          locations: function() {
            return LocationsService.all().then(function(response){return response.data},function(err){
              NotificationService.error('Error loading locations',err);
            });
          },
          mainLocation: function() {
            return LocationsService.all().then(function(response){
              return response.data.filter(function(location){
                return location.is_billing_location;
              })
            },function(err){
              NotificationService.error('Error loading locations',err);
            });
          }
        }
      });

      modalInstance.result.then(function () {
        $scope.resetUsers();
      }, function () {
        $log.debug('updated users');
      });
    };
  }

  angular.module('common.settings-users', [])
    .controller('UsersSettingsCtrl', usersSettingsCtrl);
})();

(function() {
  'use strict';

  function settingsUsersAddCtrl($scope, $log, $uibModalInstance, PrincipalService, AuthenticationService, $timeout, NotificationService,
                                CanUpdateUser,CanCreateUser,CustomUserEditTitle,user,staffRoleToNameMap,providerRoles, edit,locations,MultiLocationEnable,mainLocation) {
    $log.debug('Users Add Controller loaded');

    $scope.edit = edit;
    $scope.staffRoleToNameMap = staffRoleToNameMap;
    $scope.providerRoles = providerRoles;
    $scope.CanUpdateUser = CanUpdateUser;
    $scope.CanCreateUser = CanCreateUser;
    $scope.customUserEditTitle = CustomUserEditTitle;
    $scope.MultiLocationEnable = MultiLocationEnable;
    $scope.locations = {};
    $scope.locations.selected_license_location = {};

    if(mainLocation && mainLocation.length > 0 ){
      $scope.mainLocationId = mainLocation[0].id;
    }

    $scope.locations = locations;

    if(locations){
      var account_locations = [];

      if(user && user.account_locations){
        account_locations = user.account_locations;
      }

      for(var i=0; locations.length > i; i++){
        var accountLocation = account_locations.find(function(alocation){
          return alocation.location === locations[i].id;
        });



        if(accountLocation === undefined){
          accountLocation = {location:locations[i].id,license_number:"",name:locations[i].name};
          account_locations.push(accountLocation);
        }else{
          accountLocation.name = locations[i].name;
        }

        if($scope.mainLocationId === locations[i].id ){
          $scope.locations.selected_license_location = accountLocation;
        }
      }

      $scope.account_locations = account_locations;
    }


    $scope.userIsProvider = function(user){
      if (user && user.roles) {
        return user.roles.filter(function(role){
          return providerRoles.indexOf(role) > -1;
        }).length > 0;
    } else {
      return false;
    }
    };

    $scope.$applyAsync(function(){
      $scope.user = user || {role: 'provider', specialty: '122300000X',location_default:$scope.mainLocationId};
    });

    $scope.prepareData = function(user){
      user.username = user.email;

      user.account_locations = $scope.account_locations;


    };

    $scope.clearSignature = function(user,field){
      user[field] = null;
      //We need to set it to '' so allow_blank=True works fine as is the default on django and not null
      //and null is the only way to clear out the signature on the component
      $timeout(function(){
        user[field] = '';
      },1);

    };

    $scope.updateUser = function () {
      $scope.prepareData($scope.user);
      AuthenticationService.updateUserAccount($scope.user.id, $scope.user).then(
        function success() {
          $uibModalInstance.close();
          NotificationService.success('User updated sucessfully');
        },
        function fail(err) {
          $scope.user = $scope.user;
          NotificationService.error('Error Updating user',err);
        }
      );
    };

    $scope.createUser = function () {
      $scope.prepareData($scope.user);
      AuthenticationService.createUserAccount(PrincipalService.getPracticeId(), $scope.user).then(
        function success() {
          $uibModalInstance.close();
          NotificationService.success('User created sucessfully');
        },
        function fail(err) {
          NotificationService.error('Error Adding user',err);
        });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }

  angular.module('common.settings-users-add', [])
    .controller('SettingsUsersAddCtrl', settingsUsersAddCtrl);
})();

(function() {
  'use strict';

  function reportsTemplatesSettingsCtrl($log) {
    $log.debug('Reports Templates Settings loaded');
  }

  angular.module('common.settings-reports-templates', [])
    .controller('ReportsTemplatesSettingsCtrl', reportsTemplatesSettingsCtrl);
})();

(function () {
  "use strict";

  angular.module("common.settings-two-factor", [])

  .controller("TwoFactorCtrl", TwoFactorCtrl)

  function TwoFactorCtrl(
    $scope,
    $state,
    $q,
    $uibModal,
    MultiFactorAuthenticationTrustedDeviceService,
    NotificationService,
    PrincipalService,
    TwoFactorService
    ) {

    $scope.twoFactorEnabled = PrincipalService.getTwoFactorStatus()

    if (!$scope.twoFactorEnabled) {
      return
    }

    var cancelColor = "#d33"
    var confirmColor = "#3085d6"
    var mfaTrustedDeviceParams = {account: PrincipalService.getAccountId()}

    TwoFactorService.devices.all().then(
      function (devices) {
        angular.extend($scope, devices)
      },
      function (err) {
        NotificationService.error("Error loading your devices", err)
      }
    )

    $scope.backupTokens = []

    TwoFactorService.statics.all().then(
      function (response) {
        $scope.backupTokens = response.data
      }
    )

    $scope.disableTwoFactor = function () {
      swal(
        {
          title: "Are you sure?",
          text: "This will make your account less secure.",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: confirmColor,
          cancelButtonColor: cancelColor,
          confirmButtonText: "Yes, disable it!",
          closeOnConfirm: false
        },
        function () {
          MultiFactorAuthenticationTrustedDeviceService.revoke("all", mfaTrustedDeviceParams).then(
            function() {
              $scope.trustedDevices = []
            }
          )
          TwoFactorService.disable().then(
            function () {
              PrincipalService.setTwoFactorStatus(false)
              swal(
                {
                  title: "Disabled!",
                  text: "Two factor authentication has been disabled.",
                  type: "success"
                },
                function () {
                  // Reload the current state
                  $state.go($state.current, {}, {reload: true})
                }
              )
            },
            function () {
              swal("Error!", "There was an error disabling two factor authentication, please try again later.", "error")
            }
          )
        }
      )
    }

    $scope.removeApp = function (deviceId) {
      removeDevice(deviceId, "app", 0)
    }

    $scope.removePhone = function (deviceId, index) {
      removeDevice(deviceId, "phone", index)
    }

    $scope.verifyPhone = function (device) {
      TwoFactorService.phones.challenge(device.id).then(
        function () {
          $state.go($state.go("settings-two-factor-setup-backup-phone-verify", {device: device}))
        }
      )
    }

    function removeDevice (deviceId, method, index) {
      var serviceObj
      var items

      if (method === "phone") {
        serviceObj = TwoFactorService.phones
        items = $scope.phones
      }
      else if (method === "app") {
        serviceObj = TwoFactorService.apps
        items = $scope.apps
      }

      if (serviceObj) {
        swal(
          {
            title: "Are you sure?",
            text: "You will no longer be able to use this method to authenticate.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: confirmColor,
            cancelButtonColor: cancelColor,
            confirmButtonText: "Yes, remove it!",
            closeOnConfirm: false
          },
          function () {
            serviceObj.delete(deviceId).then(
              function () {
                swal("Removed!", "Your device has been removed.", "success")
                items.splice(index, 1)
              },
              function() {
                swal("Error!", "There was an error removing your device, please try again later.", "error")
              }
            )
          }
        )
      }
    }

    // mfa-trusted-devices

    $scope.destroyTrustedDevice = function(trustedDevice) {
      var confirm = $q.when()
      if (trustedDevice.is_valid) {
        confirm = $uibModal.open({
          bindToController: true,
          controllerAs: "$ctrl",
          templateUrl: "#trusted-device-destroy",
          controller: function() {
            this.many = false
          }
        }).result
      }
      confirm.then(
        function() {
          MultiFactorAuthenticationTrustedDeviceService.destroy(trustedDevice.id).then(
            function() {
              var idx = $scope.trustedDevices.findIndex(function(item) { return item.id === trustedDevice.id })
              if (idx !== -1) {
                $scope.trustedDevices.splice(idx, 1)
              }
              NotificationService.success("Successfully revoked device")
            },
            function(response) {
              NotificationService.error("Error Revoke trust from this device", response)
            }
          )
        }
      )
    }

    $scope.listTrustedDevices = function() {
      return MultiFactorAuthenticationTrustedDeviceService.list(mfaTrustedDeviceParams).then(
        function(response) {
          $scope.trustedDevices = response.data
        },
        function(err) {
          $scope.trustedDevices = []
          NotificationService.error("Error loading your trusted devices", err)
        }
      )
    }

    $scope.revokeAllTrustedDevices = function() {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        templateUrl: "#trusted-device-destroy",
        controller: function() {
          this.many = true
        }
      }).result.then(
        function() {
          MultiFactorAuthenticationTrustedDeviceService.revoke("all", mfaTrustedDeviceParams).then(
            function(response) {
              if (response.data && response.data.length) {
                response.data.forEach(function(trustedDevice) {
                  var idx = $scope.trustedDevices.findIndex(function(item) { return item.id === trustedDevice.id })
                  $scope.trustedDevices.splice(idx, 1)
                })
              }
              NotificationService.success("Successfully removed all devices")
            },
            function(response) {
              NotificationService.error("Error Revoke trust from this device", response)
            }
          )
        }
      )
    }

    $scope.revokeExpiredTrustedDevices = function() {
      MultiFactorAuthenticationTrustedDeviceService.revoke("expired", mfaTrustedDeviceParams).then(
        function(response) {
          if (response.data && response.data.length) {
            response.data.forEach(function(trustedDevice) {
              var idx = $scope.trustedDevices.findIndex(function(item) { return item.id === trustedDevice.id })
              $scope.trustedDevices.splice(idx, 1)
            })
          }
          NotificationService.success("Successfully removed expired devices")
        },
        function(response) {
          NotificationService.error("Error Revoke trust from expired devices", response)
        }
      )
    }

    $scope.listTrustedDevices()

  }

})();

(function () {
  'use strict';

  function twoFactorSetupCtrl($scope, $state, $log, PrincipalService) {
    $log.debug('TwoFactor Setup loaded');

    $scope.twoFactorEnabled = PrincipalService.getTwoFactorStatus();
    $scope.state = $state
    
    if ($scope.twoFactorEnabled) {
      $state.go('settings-two-factor');
    }
  }

  function twoFactorSetupMethodCtrl($scope, $state) {
    $scope.methods = [
      {
        text: 'Token Generator App',
        state: 'settings-two-factor-setup-app',
        method: 'app'
      },
      // {
      //   text: 'Text Message',
      //   state: 'settings-two-factor-setup-phone',
      //   method: 'sms'
      // },
      // {
      //   text: 'Phone Call',
      //   state: 'settings-two-factor-setup-phone',
      //   method: 'call'
      // }
    ];

    $scope.nextState = {
      method: $scope.methods[0]
    };

    $scope.goToForm = function () {
      $state.go($scope.nextState.method.state, {method: $scope.nextState.method.method});
    };
  }

  function twoFactorSetupAppCtrl($scope, $state, TwoFactorService, PrincipalService , NotificationService) {
    $scope.nocache = new Date().getTime().toString(36)

    $scope.device = {
      token: null
    };

    $scope.twoFactorEnabled = PrincipalService.getTwoFactorStatus();
    if ($scope.twoFactorEnabled) {
      $scope.pageTitle = 'Add a backup application';
    } else {
      $scope.pageTitle = 'Enable Two-Factor Authentication';
    }
    $scope.submit = function (tokenForm) {
      if (tokenForm.$valid) {
        TwoFactorService.apps.create($scope.device).then(function () {
          NotificationService.success('Your have successfully added a token device');

          if (!$scope.twoFactorEnabled) {
            PrincipalService.setTwoFactorStatus(true);
          }

          $state.go('settings-two-factor');
        }, function (response) {
          if (response.data) {
            var data = response.data;

            if (data.error) {
              NotificationService.error(data.error);
            } else if (data.token) {
              $scope.serverError = true;
            }
          } else {
            NotificationService.error('Error enabling your token device',response);
          }
        });
      }
    };
  }

  function twoFactorSetupPhoneCtrl($scope, $state, $transition$, PrincipalService, TwoFactorService) {
    if (!$transition$.params().method) {
      $scope.methods = [
        {
          text: 'Text Message',
          state: 'settings-two-factor-setup-phone',
          method: 'sms'
        },
        {
          text: 'Phone Call',
          state: 'settings-two-factor-setup-phone',
          method: 'call'
        }
      ];
    }

    $scope.phone = {
      number: null,
      method: $transition$.params().method || $scope.methods[0].method
    };


    $scope.twoFactorEnabled = PrincipalService.getTwoFactorStatus();
    if (!$scope.twoFactorEnabled) {
      $scope.pageTitle = 'Enable Two-Factor Authentication';
    } else {
      $scope.pageTitle = 'Add a backup phone';
    }

    $scope.clearServerError = function() {
      $scope.serverError = null;
    };

    $scope.submit = function (phoneForm) {
      if (phoneForm.$valid) {
        TwoFactorService.phones.create($scope.phone).then(function (response) {
          $scope.phone.id = response.data.id;
          var stateData = {device: $scope.phone};
          if (!$scope.twoFactorEnabled) {
            $state.go('settings-two-factor-setup-phone-verify', stateData);
          } else {
            $state.go('settings-two-factor-setup-backup-phone-verify', stateData);
          }
        }, function (response) {
          var data = response.data;

          if (data) {
            if (data.number) {
              $scope.serverError = data.number;
            } else if (data.error) {
              $scope.serverError = data.error;
            }
          }

          if (response.status === 304) {
            $scope.serverError = 'That phone number has been already verified';
          }
        });
      }
    };
  }

  function twoFactorSetupPhoneVerifyCtrl($scope, $state, $transition$, TwoFactorService, PrincipalService, NotificationService) {
    if (!$transition$.params().device) {
      $state.go('^');
      return;
    }

    $scope.device = $transition$.params().device;
    $scope.device.token = null;

    $scope.twoFactorEnabled = PrincipalService.getTwoFactorStatus();
    if ($scope.twoFactorEnabled) {
      $scope.pageTitle = 'Add a backup phone';
    } else {
      $scope.pageTitle = 'Enable Two-Factor Authentication';
    }

    if ($scope.device.method === 'call') {
      $scope.action = 'We will call you';
    } else {
      $scope.action = 'We sent you a text message';
    }

    $scope.submit = function (tokenForm) {
      if (tokenForm.$valid) {
        TwoFactorService.phones.verify($scope.device.id, $scope.device).then(function () {
          NotificationService.success('Your have successfully verified your phone');

          if (!$scope.twoFactorEnabled) {
            PrincipalService.setTwoFactorStatus(true);
          }

          $state.go('settings-two-factor');
        }, function (response) {
          if (response.data) {
            var data = response.data;

            if (data.error) {
              NotificationService.error(data.error);
            } else if (data.token) {
              $scope.serverError = true;
            }
          } else {
            NotificationService.error('Error verifying your phone',response);
          }
        });
      }
    };
  }

  function twoFactorSetupBackupTokensCtrl($scope, $transition$, TwoFactorService, NotificationService) {
    $scope.backupTokens = null;

    // Avoid a request if backup tokens are present (coming from another state)
    if ($transition$.params().backupTokens) {
      $scope.backupTokens = $transition$.params().backupTokens;
    } else {
      TwoFactorService.statics.all().then(function (response) {
        $scope.backupTokens = response.data;
      }, function (err) {
        NotificationService.error('Error loading your backup tokens',err);
      });
    }

    $scope.generateTokens = function () {
      TwoFactorService.statics.create().then(function (response) {
        $scope.backupTokens = response.data;
      }, function (err) {
        $scope.backupTokens = null;
        NotificationService.error('Error generating your backup tokens',err);
      });
    };
  }

  angular.module('common.settings-two-factor-setup', [])
    .controller('TwoFactorSetupCtrl', twoFactorSetupCtrl)
    .controller('TwoFactorSetupMethodCtrl', twoFactorSetupMethodCtrl)
    .controller('TwoFactorSetupAppCtrl', twoFactorSetupAppCtrl)
    .controller('TwoFactorSetupPhoneCtrl', twoFactorSetupPhoneCtrl)
    .controller('TwoFactorSetupPhoneVerifyCtrl', twoFactorSetupPhoneVerifyCtrl)
    .controller('TwoFactorSetupBackupTokensCtrl', twoFactorSetupBackupTokensCtrl);
})();

(function() {
  'use strict';

  function securityQuestionsCtrl($scope, PrincipalService, AuthenticationService, NotificationService) {
    var accountId = PrincipalService.getAccountId();
    $scope.securityQuestions = [
      'What is the name of your first boyfriend or girlfriend?',
      'What was your favorite place to visit as a child?',
      'Who is your favorite actor, musician, or artist?',
      'What is the name of your first pet?',
      'In what city were you born?',
      'What is the name of your first school?',
      'What high school did you attend?',
      'What is your favorite movie?',
      'What is your mother\'s maiden name?',
      'What is your father\'s middle name?',
      'What street did you grow up on?',
      'What is the name of your first grade teacher?'
    ];
    $scope.keys = ['one', 'two', 'three'];
    $scope.account = {};

    $scope.getSecurityQuestions = function (key) {
      var questions = $scope.securityQuestions;

      // Filter questions already selected
      $scope.keys.forEach(function (k) {
        if (k !== key) {
          questions = questions.filter(function (q) {
            if (q !== $scope.account['security_question_' + k]) {
              return q;
            }
          });
        }
      });

      return questions;
    };

    // Get user's security questions
    AuthenticationService.getUserAccount(accountId).then(function (response) {
      $scope.account = response.data;
    }, function (err) {
      NotificationService.error('Error Loading your security questions',err);
    });

    $scope.submit = function (form) {
      if (form.$valid) {
        // Update user's security questions
        AuthenticationService.updateUserAccount(accountId, $scope.account).then(function () {
          NotificationService.success('Your security questions have been updated');
        }, function (err) {
          NotificationService.error('Error updating your security questions',err);
        });
      }
    };
  }

  angular.module('common.settings.security-questions', [])
    .controller('SecurityQuestionsCtrl', securityQuestionsCtrl);
})();

(function() {
  'use strict';

  function medicalAlertsSettingsCtrl($log, $q, $rootScope, $scope, $state, $uibModal, AuthenticationService,
    MedicalAlertsService, NotificationService, PasswordService, PatientsService, PrincipalService,CanUpdateMedicalAlert ) {

    $scope.settings = true;
    $scope.CanUpdateMedicalAlert = CanUpdateMedicalAlert;

    MedicalAlertsService.get().then(function(event){
      //Here we use the same format as the patient form , in order to reuse the html form
      var patientData = PatientsService.allSnakecaseToCamelcase(event.data);
      $scope.patient = patientData;
    },function(err){
      NotificationService.error('Error Loading Medical Alerts',err);
    });

    $scope.save = function(){
      var data = PatientsService.allCamelcaseToSnakecase($scope.patient);
      MedicalAlertsService.update(data).then(function(){
        NotificationService.success('Medical Alerts Updated Successfully');
      },function(err){
        NotificationService.error('Error Updating Medical Alerts',err);
      });
    };

  }

  angular.module('common.settings-medical-alerts', [])
    .controller('MedicalAlertsSettingsCtrl', medicalAlertsSettingsCtrl);
})();

/*global _ */
(function() {
  'use strict';

  function prescriptionsTemplateSettingsCtrl($scope, $uibModal,
                                             NotificationService,
                                             PrescriptionsService,CanDeletePrescriptionTemplate,CanUpdatePrescriptionTemplate,CanCreatePrescriptionTemplate,CanRetrievePrescriptionTemplate) {

    $scope.settings = true;
    $scope.CanDeletePrescriptionTemplate = CanDeletePrescriptionTemplate;
    $scope.CanCreatePrescriptionTemplate = CanCreatePrescriptionTemplate;
    $scope.CanRetrievePrescriptionTemplate = CanRetrievePrescriptionTemplate;

    $scope.getAllDrugTemplates = function(){
      PrescriptionsService.getAllDrugTemplates().then(function(event){
        $scope.prescriptionTemplates  = event.data;
      },function(err){
        NotificationService.error('Error Loading Prescriptions Templates',err);
      });
    };

    $scope.delete = function(template){
      PrescriptionsService.deleteTemplate(template.id,template).then(
        function(){
          $scope.getAllDrugTemplates();
          NotificationService.success('Prescription Template Updated Successfully');
        },function(error){
          NotificationService.error('Error Updating Prescription Template',error);
        });
    };


    $scope.getAllDrugTemplates();

    $scope.openAddPrescriptionTemplate = function(template){
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'src/common/settings-prescriptions-templates-add.tpl.html',
        controller: 'PrescriptionsTemplateAddSettingsCtrl',
        resolve: {
          template: function () {
            return _.clone(template);
          },
          CanUpdatePrescriptionTemplate:CanUpdatePrescriptionTemplate,
          CanCreatePrescriptionTemplate:CanCreatePrescriptionTemplate
        }

      });


      modalInstance.result.then(function (response) {
        if(response){
          $scope.getAllDrugTemplates();
        }
      }, function () {
      });

    };




  }

  function prescriptionsTemplateAddSettingsCtrl(template,$uibModalInstance,$scope,PrescriptionsService,NotificationService,CanUpdatePrescriptionTemplate,CanCreatePrescriptionTemplate) {

    $scope.template = template;
    $scope.CanUpdatePrescriptionTemplate = CanUpdatePrescriptionTemplate;
    $scope.CanCreatePrescriptionTemplate = CanCreatePrescriptionTemplate;

    $scope.save = function(template){
      if(template.id){
        PrescriptionsService.updateTemplate(template.id,template).then(
          function(){
            $uibModalInstance.close(true);
            NotificationService.success('Prescription Template Updated Successfully');
          },function(error){
            $uibModalInstance.close();
            NotificationService.error('Error Updating Prescription Template',error);
          });

      }else{
        PrescriptionsService.createTemplate(template).then(
          function(){
            $uibModalInstance.close(true);
            NotificationService.success('Prescription Template Created Successfully');
          },function(error){
            $uibModalInstance.close();
            NotificationService.error('Error Creating Prescription Template',error);
          });

      }

    };

    $scope.cancel = function(){
      $uibModalInstance.close();
    };


  }


    angular.module('common.settings-prescriptions-templates', [])
    .controller('PrescriptionsTemplatesSettingsCtrl', prescriptionsTemplateSettingsCtrl)
    .controller('PrescriptionsTemplateAddSettingsCtrl', prescriptionsTemplateAddSettingsCtrl);

})();

/*global _ */
(function() {
  'use strict';

  function textTemplateSettingsCtrl($scope, $uibModal, NotificationService,TextTemplateService,CanRetrieveMessageTemplate,CanUpdateMessageTemplate) {

    $scope.CanRetrieveMessageTemplate = CanRetrieveMessageTemplate;

    $scope.getAllTextTemplates = function(){
      TextTemplateService.getDefaultTextTemplates().then(
      function(event){
        $scope.templates = event.data;
      },function(error){
          NotificationService.error('Error Loading Templates',error);
      });
    };


    $scope.getAllTextTemplates();

    $scope.openEditTemplateModal = function(template) {

      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'src/common/settings-text-templates-edit.tpl.html',
        controller: 'TextTemplateEditSettingsCtrl',
        resolve: {
          templateType: function () {
            return template.type;
          },
          CanUpdateMessageTemplate:CanUpdateMessageTemplate
        }

      });

      modalInstance.result.then(function (response) {
        if(response){
          $scope.getAllTextTemplates();
        }
      }, function () {
      });

    };
  }

  function textTemplateAddSettingsCtrl($scope, NotificationService,TextTemplateService,templateType, $uibModalInstance,CanUpdateMessageTemplate) {

    $scope.template = {};
    $scope.CanUpdateMessageTemplate = CanUpdateMessageTemplate;

    TextTemplateService.getTextTemplateForPractice(templateType).then(
    function(event){
      if(event.data.valid_params){
        event.data.valid_params = event.data.valid_params.split(',');
        event.data.valid_params = _.map(event.data.valid_params,function(param){ return '{{'+param+'}}';});

      }

      $scope.template = event.data;
    },
    function(error){
      NotificationService.error('Error Loading Template Detail',error);
    });


    $scope.cancel = function(){
      $uibModalInstance.close();
    };


    $scope.reset = function(){
      $scope.template.content = $scope.template.default_content;
      $scope.template.title = $scope.template.default_title;
    };

    $scope.update = function(template){
      TextTemplateService.updateTextTemplate(template).then(
      function(){
        $uibModalInstance.close(true);
        NotificationService.success('Successfully Updated Template');
      },
      function(error){
        NotificationService.error('Error Updating Template',error);
      });
    };
  }


    angular.module('common.settings-text-templates', [])
    .controller('TextTemplatesSettingsCtrl', textTemplateSettingsCtrl)
    .controller('TextTemplateEditSettingsCtrl', textTemplateAddSettingsCtrl);
})();

/*global _ */
(function() {
  'use strict';

  function InsurancePlansSettingsCtrl(InsuranceService,PrincipalService,NotificationService,$uibModal,$scope,$q,ProceduresService,CanRetrieveInsurancePlan,
                                      AppServicesApi,
                                      CanCreateInsurancePlan,CanDeleteInsurancePlan,CanUpdateInsurancePlan, CanListInsurancePlan) {
    var self = this;

    this.CanRetrieveInsurancePlan = CanRetrieveInsurancePlan;
    this.CanCreateInsurancePlan = CanCreateInsurancePlan;
    this.CanDeleteInsurancePlan = CanDeleteInsurancePlan;
    this.CanListInsurancePlan = CanListInsurancePlan;
    this.CanUpdateInsurancePlan = CanUpdateInsurancePlan;

    this.getAllPlans = function(){

      $q.all([
        InsuranceService.getPayers(),
        InsuranceService.getPlansForPractice(PrincipalService.getPracticeId())
      ]).then(function(events) {
        var payers = events[0].data;
        var plans = events[1].data;

        //convert each plan.payer from an id to the corresponding payer object
        var payersKeyValues = {};
        for (var j = 0; j < payers.length; j++) {
          payersKeyValues[payers[j].id] = payers[j];
        }

        for (var i = 0; i < plans.length; i++) {
          var plan = plans[i];
          if(plan.payer && payersKeyValues[plan.payer]){
            plan.payer =  payersKeyValues[plan.payer];
          }
        }
        self.plans = plans;

      },function(err){
        NotificationService.error('Error loading plans info',err);
      });


    };

    this.getAllPlans();


    this.delete = function(plan){
      swal({
        title: 'Are you sure?',
        text: '',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true
      }, function () {

        InsuranceService.deletePlan(plan.id,plan).then(function(){
          self.getAllPlans();
        },function(error){
          NotificationService.error('Error deleting plan',error);

        });

      });
    };



    this.edit = function(plan){
      $q.all([
        ProceduresService.getAllFeeprofiles(PrincipalService.getPracticeId()),
        InsuranceService.getPayers()
      ]).then(function(events)
      {
        var feeprofiles = events[0].data;
        var payers = events[1].data;

        var modalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'src/common/settings-insurance-plans-add.tpl.html',
          controller: 'InsurancePlansAddCtrl as insurancePlansAdd',
          size: 'lg',
          resolve: {
            listPlan: function () {
              return plan;
            },
            feeprofiles: function () {
              return feeprofiles;
            },
            payers: function () {
              return payers;
            },
            CanCreateInsurancePlan:CanCreateInsurancePlan,
            CanUpdateInsurancePlan:CanUpdateInsurancePlan
          }
        });

        modalInstance.result.then(function () {
          self.getAllPlans();
        }, function () {
        });

      },function(err){
        NotificationService.error('Error loading payers and/or feeprofile data',err);
      });
    };


    this.add = function(){
      $q.all([
        ProceduresService.getAllFeeprofiles(PrincipalService.getPracticeId()),
        InsuranceService.getPayers()
      ]).then(function(events)
      {
        var feeprofiles = events[0].data;
        var payers = events[1].data;

        var modalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'src/common/settings-insurance-plans-add.tpl.html',
          controller: 'InsurancePlansAddCtrl as insurancePlansAdd',
          size: 'lg',
          resolve: {
            listPlan: function () {
              return undefined;
            },
            feeprofiles: function () {
              return feeprofiles;
            },
            payers: function () {
              return payers;
            },
            CanCreateInsurancePlan:CanCreateInsurancePlan,
            CanUpdateInsurancePlan:CanUpdateInsurancePlan
          }
        });

        modalInstance.result.then(function () {
          self.getAllPlans();
        }, function () {
        });

      },function(err){
        NotificationService.error('Error loading payers and/or feeprofile data',err);
        return;
      });

    };

    this.orderByClick = function(prop) {
      if (angular.equals(prop, this.orderByProp)) {
        this.orderByReverse = !this.orderByReverse
      }
      else {
        this.orderByProp = prop
      }
    }
    this.orderByProp = "name"
    this.orderByReverse = false
    this.searchTerm = ""

    this.showArchived = false

    this.isVisible = function(plan) {
      var byArchived = this.showArchived ? true : !plan.is_archived
      return byArchived
    }

    this.update = function (insuranceplan, props) {
      var data = props.reduce(function (data, prop) {
        data[prop] = insuranceplan[prop]
        return data
      }, {})
      AppServicesApi.insuranceplan_update(insuranceplan.id, data).then(
        function (response) {
          angular.extend(insuranceplan, response.data)
        },
        function (response) {
          NotificationService.error("Error updating Insurance Plan", response)
        }
      )
    }
  }


  function InsurancePlansAddCtrl(ProceduresService,PrincipalService,NotificationService,InsuranceService,$uibModalInstance,$filter,listPlan, feeprofiles, payers, $q,$timeout,$uibModal,$scope,CanCreateInsurancePlan,CanUpdateInsurancePlan){
    var self = this;
    this.AMOUNT_REGEX = /^\ ?[0-9]+\.?[0-9]?[0-9]?$/;
    this.editPlan = false;
    this.CanUpdateInsurancePlan = CanUpdateInsurancePlan;
    this.CanCreateInsurancePlan = CanCreateInsurancePlan;

    this.feeprofiles = feeprofiles;
    this.payers = payers;

    //define methods

    $scope.procedureCodeGreaterThan = function(code) {
      return function(item) {
        if (item.code > code) {
          return true;
        } else {
          return false;
        }
      }
    };

    $scope.copyProcedureSettingThrough = function(procedureFrom) {
      if (procedureFrom.copyProcedureSettingThroughProcedureCode) {
        var planProceduresOrdered = $filter('filter')($scope.insurancePlansAdd.plan.planProcedures, $scope.procedureCodeGreaterThan(procedureFrom.code), false);
        planProceduresOrdered = $filter('orderBy')(planProceduresOrdered,'code', false);
        for (var i=0; i<planProceduresOrdered.length; i++) {
          if (planProceduresOrdered[i].code <= procedureFrom.copyProcedureSettingThroughProcedureCode) {
            planProceduresOrdered[i].planProcedureAssoc.proportion_innetwork_covered = procedureFrom.planProcedureAssoc.proportion_innetwork_covered;
            planProceduresOrdered[i].planProcedureAssoc.proportion_outnetwork_covered = procedureFrom.planProcedureAssoc.proportion_outnetwork_covered;
            planProceduresOrdered[i].deductibleId = procedureFrom.deductibleId;
          } else {
            break;
          }
        }
      }
    };

    this.getMaximumsForPlan = function(){
      InsuranceService.getMaximumsForPlan(this.plan.id).then(function(event){
        var data_annual = _.find(event.data,{resets:true});
        var data_lifetime = _.find(event.data,{resets:false});

        data_annual.amount_innetwork = data_annual.amount_innetwork;
        data_lifetime.amount_innetwork = data_lifetime.amount_innetwork;

        self.plan.annual_maximum = data_annual;
        self.plan.lifetime_maximum = data_lifetime;
      },function(err){
        NotificationService.error('Error loading maximums for plan',err);
      });
    };

    this.isZero = function(strNum) {
      if (!strNum) {
        return true;
      } else {
        var fixedNum = this.getFixed(strNum);
        if (fixedNum === '0.00') {
          return true;
        } else {
          return false;
        }
      }
    };

    this.getFixed = function(strNum) {
      if (typeof strNum === 'undefined') {
        return '0.00';
      } else {
        return parseFloat(strNum).toFixed(2);
      }
    };

    this.getDeductiblesForPlanAndProcedures = function(plan){
      $q.all([
        InsuranceService.getDeductiblesForPlan(plan.id),
        ProceduresService.getProcedures(PrincipalService.getPracticeId())
      ]).then(function(events)
      {
        plan.deductibles = events[0].data;
        plan.deductiblesNamed = {};
        for (var t=0; t<plan.deductibles.length; t++) {
          plan.deductiblesNamed[plan.deductibles[t].name] = plan.deductibles[t];
        }
        //plan.deductibles.splice(0,0,{id: null, name: ''});
        plan.planProcedures = events[1].data;
        for (var i=0; i<plan.planProcedures.length; i++) {
          var planProcedureAssoc = _.find(plan.planprocedureassoc_set, {procedure: plan.planProcedures[i].id});
          if (planProcedureAssoc) {
            plan.planProcedures[i].planProcedureAssoc = planProcedureAssoc;

            if (
              !plan.planProcedures[i].planProcedureAssoc.hasOwnProperty('proportion_outnetwork_covered') ||
              !plan.planProcedures[i].planProcedureAssoc.proportion_outnetwork_covered
            ) {
              plan.planProcedures[i].planProcedureAssoc.proportion_outnetwork_covered = self.getFixed();
            }
            if (
              !plan.planProcedures[i].planProcedureAssoc.hasOwnProperty('proportion_innetwork_covered') ||
              !plan.planProcedures[i].planProcedureAssoc.proportion_innetwork_covered
            ) {
              plan.planProcedures[i].planProcedureAssoc.proportion_innetwork_covered = self.getFixed();
            }
          } else {
            plan.planProcedures[i].planProcedureAssoc = {};
            plan.planProcedures[i].planProcedureAssoc.procedure = plan.planProcedures[i].id;
            plan.planProcedures[i].planProcedureAssoc.proportion_outnetwork_covered = self.getFixed();
            plan.planProcedures[i].planProcedureAssoc.proportion_innetwork_covered = self.getFixed();
          }

          var deductible = _.find(plan.deductibles, function(deductible){
            return typeof (
              _.find(deductible.procedures, function(procedureId){
                return procedureId === plan.planProcedures[i].id;
              })
            ) !== 'undefined';
          });
          if (deductible) {
            plan.planProcedures[i].deductibleId = deductible.id;
          }
        }
      },function(err){
        NotificationService.error('Error loading deductibles or procedures',err);
      });
    };

    this.openAddFeeprofileModal = function () {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'src/common/settings-fee-profile-add.tpl.html',
        controller: 'addFeeprofileModalInstanceCtrl',
        size: 'md',
        resolve: {
          modalData: function () {
            return {payer:Number(self.plan.payer),hasInsuranceFees: true};
          }
        }
      });

      modalInstance.result.then(function (data) {
        self.feeprofiles.push(data);
        self.plan.feeprofile = data.id;
      }, function () {
      });
    };


    this.save = function(plan){
      plan.feeprofiles = [plan.feeprofile];

      var savePromises = [];

      //UPDATE
      if(this.editPlan){
        $scope.saving = true;
        //clear all procedure associations from all deductibles.
        for (var p=0; p<plan.deductibles.length; p++) {
          plan.deductibles[p].procedures = [];
        }

        plan.planprocedureassoc_set = [];
        for (var k=0; k<plan.planProcedures.length; k++) {
          //only add to planprocedureassoc_set if either covered is not zero or null or undefined.
          //back end interprets nulls as 0.
          if (
            !this.isZero(plan.planProcedures[k].planProcedureAssoc.proportion_outnetwork_covered) ||
            !this.isZero(plan.planProcedures[k].planProcedureAssoc.proportion_innetwork_covered)
          ) {

            if (this.isZero(plan.planProcedures[k].planProcedureAssoc.proportion_outnetwork_covered)) {
              plan.planProcedures[k].planProcedureAssoc.proportion_outnetwork_covered = this.getFixed();
            } else {
              plan.planProcedures[k].planProcedureAssoc.proportion_outnetwork_covered = this.getFixed(plan.planProcedures[k].planProcedureAssoc.proportion_outnetwork_covered);
            }
            if (this.isZero(plan.planProcedures[k].planProcedureAssoc.proportion_innetwork_covered)) {
              plan.planProcedures[k].planProcedureAssoc.proportion_innetwork_covered = this.getFixed();
            } else {
              plan.planProcedures[k].planProcedureAssoc.proportion_innetwork_covered = this.getFixed(plan.planProcedures[k].planProcedureAssoc.proportion_innetwork_covered);
            }

            plan.planprocedureassoc_set.push(plan.planProcedures[k].planProcedureAssoc);
          } else {
            plan.planProcedures[k].planProcedureAssoc.proportion_outnetwork_covered = this.getFixed();
            plan.planProcedures[k].planProcedureAssoc.proportion_innetwork_covered = this.getFixed();
          }


          if (plan.planProcedures[k].deductibleId) {
            var deductible_ = _.find(plan.deductibles, function(deductible){
              if (deductible.id) {
                return deductible.id === plan.planProcedures[k].deductibleId;
              } else {
                return false;
              }
            });
            deductible_.procedures.push(plan.planProcedures[k].id);
          }

        }

        savePromises.push(InsuranceService.updatePlan(plan.id,plan));
        savePromises.push(InsuranceService.updateMaximum(plan.annual_maximum.id,plan.annual_maximum));
        savePromises.push(InsuranceService.updateMaximum(plan.lifetime_maximum.id,plan.lifetime_maximum));
        for (var q=-0; q<plan.deductibles.length; q++) {
          if (plan.deductibles[q].id) {
            savePromises.push(InsuranceService.updateDeductible(plan.deductibles[q].id,plan.deductibles[q]));
          }
        }
        $q.all(savePromises).then(function() {
          self.showBenefitInformation = true;
          $scope.saving = false;
          NotificationService.success('Plan successfully updated');
        },function(err){
          $scope.saving = false;
          NotificationService.error('Error updating plan',err);
        });

        return;
      }

      //CREATE
      savePromises.push(InsuranceService.createPlanForPractice(PrincipalService.getPracticeId(),plan));
      $q.all(savePromises).then(function(events)
      {
        self.reset(events[0].data);
        NotificationService.success('Plan successfully created');
      },function(err){
        NotificationService.error('Error saving plan',err);
      });
    };

    this.done = function(plan){
      if (plan) {
        //change from plan.payer containing the id back to it containing the payer object for the list
        plan.payer = _.find(this.payers, {id:Number(plan.payer)});
      }
      $uibModalInstance.close(plan);
    };

    this.resetWithListPlan = function(listPlan) {
      if (listPlan) {
        $q.all([
          InsuranceService.getPlan(listPlan.id)
        ]).then(function(events) {
          var plan = events[0].data;
          self.reset(plan);
        },function(err){
          NotificationService.error('Error loading plan detailed info',err);
        });
      }
    };

    this.reset = function(plan) {
      //editing a plan
      //change from plan.payer containing a payer object back to it containing just the payer id.
      if (plan.payer.hasOwnProperty('id')) {
        plan.payer = plan.payer.id;
      }
      plan.feeprofile = plan.feeprofiles[0];
      self.plan = plan;
      self.editPlan = true;
      self.getMaximumsForPlan();
      self.getDeductiblesForPlanAndProcedures(self.plan);
    };
    this.resetWithListPlan(listPlan);
  }

  angular.module('common.settings-insurance-plans', [])
    .controller('InsurancePlansSettingsCtrl', InsurancePlansSettingsCtrl)
    .controller('InsurancePlansAddCtrl', InsurancePlansAddCtrl);
})();

(function() {
  'use strict';

  function ProceduresSettingsCtrl(
    PrincipalService,
    NotificationService,
    $scope,
    $q,
    ProceduresService,
    CanListProcedure,
    CanUpdateProcedure
  ) {

    $scope.CanListProcedure = CanListProcedure;
    $scope.CanUpdateProcedure = CanUpdateProcedure;

    $scope.getAllProcedures = function(){
      $q.all([
        ProceduresService.getProcedures(PrincipalService.getPracticeId())
      ]).then(function(responses) {
        $scope.procedures = responses[0].data;
      },function(err){
        NotificationService.error('Error loading procedures',err);
      });
    };

    $scope.getAllProcedures();


    $scope.edit = function(procedure){
      $q.all([
        ProceduresService.updateProcedure(procedure.id, procedure),
      ]).then(function()
      {
        NotificationService.success('Successfully updated procedure ' + procedure.code);
      },function(err){
        $scope.getAllProcedures();
        NotificationService.error('Error updating procedure ' + procedure.code,err);
      });
    };

  }


  angular.module('common.settings-procedures', [])
    .controller('ProceduresSettingsCtrl', ProceduresSettingsCtrl);
})();

(function() {
  'use strict';

  function InsurancePayersInNetworkProvidersSettingsCtrl(AuthenticationService,NotificationService,PrincipalService,InsuranceService,$q,CanUpdateInsurancePayersInNetworkProvider) {
    var self = this;
    this.selectedProvider = false;
    this.CanUpdateInsurancePayersInNetworkProvider = CanUpdateInsurancePayersInNetworkProvider;

    $q.all([AuthenticationService.getPracticeAdminAndProviderAccounts(PrincipalService.getPracticeId()),InsuranceService.getPayers()]).then(function(events){
      self.providers = events[0].data;
      self._payers = events[1].data;
    },function(err){
      NotificationService.error('Error Loading providers',err);
    });


    this.providerSelect = function(provider){
      this.selectedProvider = provider;
      for (var i = 0; i < this.providers.length; i++) {
        this.providers[i].selected = false;
      }
      provider.selected = true;

      var payers = this._payers;

      for (var p = 0; p < payers.length; p++) {
        var payer = payers[p];
        payer.selected = false;
        for (var j = 0; j < provider.payers_in_contract_with.length; j++) {
          var payerId = provider.payers_in_contract_with[j];
          if(payer.id === payerId){
            payer.selected = true;
          }
        }
      }
      this.payers = payers;
    };

    this.payerSelect = function(payer){
      var index = this.selectedProvider.payers_in_contract_with.indexOf(payer.id);
      if(this.selectedProvider.payers_in_contract_with.indexOf(payer.id) > -1){
        this.selectedProvider.payers_in_contract_with.splice(index,1);
        payer.selected = false;
      }else{
          this.selectedProvider.payers_in_contract_with.push(payer.id);
          payer.selected = true;
      }
      AuthenticationService.updateUserAccount(this.selectedProvider.id,this.selectedProvider).then(function(event){
        self.selectedProvider.payers_in_contract_with = event.data.payers_in_contract_with;
      },function(err){
        NotificationService.error('Error Updating provider',err);
      });
    };
  }

  angular.module('common.settings-insurance-payers-in-network-providers', [])
    .controller('InsurancePayersInNetworkProvidersSettingsCtrl', InsurancePayersInNetworkProvidersSettingsCtrl);
})();

(function() {
  'use strict';

  function howtoCtrl($log, $scope) {
    $log.debug('How-to loaded');

    $scope.videos = [
      {
        subject: 'Users',
        type: 'Setup',
        description: 'Learn how to create and manage users and their roles.',
        videoURLMP4: 'https://s3-us-west-2.amazonaws.com/demr-howto/Users.mp4'
      },
      {
        subject: 'Templates',
        type: 'Setup',
        description: 'Learn how to create and manage encounter, prescription, consent form and referral report templates.',
        videoURLMP4: 'https://s3-us-west-2.amazonaws.com/demr-howto/Templates.mp4'
      },
      {
        subject: 'Templates',
        type: 'Setup',
        description: 'Learn how to create and use Quick Fields in the encounter templates. Learn about the encounter note audit log.',
        videoURLMP4: 'https://s3-us-west-2.amazonaws.com/demr-howto/Quick+Fields+How-To+Video.mp4'
      },
      {
        subject: 'Patient-new',
        type: 'Workflows',
        description: 'Learn how to add a new patient and send new patient paperwork from the schedule, charts and referrals menus.',
        videoURLMP4: 'https://s3-us-west-2.amazonaws.com/demr-howto/Adding+a+New+Patient.mp4'
      },
      {
        subject: 'Billing',
        type: 'Workflows',
        description: 'Learn how to invoice treatment, accept patient payments, bill statements and manage patient ledger.',
        videoURLMP4: 'https://s3-us-west-2.amazonaws.com/demr-howto/Billing+Training+Video.mp4'
      },
      {
        subject: 'Images',
        type: 'Setup',
        description: 'Learn how to set up and use images.',
        videoURLMP4: 'https://s3-us-west-2.amazonaws.com/demr-howto/Imaging+Training+Video.mp4'
      },
      {
        subject: 'Insurance',
        type: 'Setup',
        description: 'Learn how to set up fee schedules, add payers, add plans, associate doctors to payers, and assign plans to patients.',
        videoURLMP4: 'https://s3-us-west-2.amazonaws.com/demr-howto/Insurance+Set-up+Training+Video.mp4'
      },
      {
        subject: 'Insurance',
        type: 'Workflows',
        description: 'Learn how to file insurance claims, accept insurance payments and manage insurance ledger.',
        videoURLMP4: 'https://s3-us-west-2.amazonaws.com/demr-howto/Insurance+Training+Video.mp4'
      },
      {
        subject: 'Kiosk',
        type: 'Setup',
        description: 'Learn how to set up and use kiosks.',
        videoURLMP4: 'https://s3-us-west-2.amazonaws.com/demr-howto/Kiosk+Video.mp4'
      },
      {
        subject: 'Patient-profile',
        type: 'Overview',
        description: 'Become familiar with the patient profile and it\'s functions.',
        videoURLMP4: 'https://s3-us-west-2.amazonaws.com/demr-howto/Patient+Profile+Overview.mp4'
      },
      {
        subject: 'Patient-signatures',
        type: 'Overview',
        description: 'Learn the purpose and functions of the patient profile signatures.',
        videoURLMP4: 'https://s3-us-west-2.amazonaws.com/demr-howto/Patient+Profile+Signatures+.mp4'
      },
      {
        subject: 'Referrals',
        type: 'Workflows',
        description: 'Learn how to manage external practices and users, create new referrals, and create reports for the referring dentist.',
        videoURLMP4: 'https://s3-us-west-2.amazonaws.com/demr-howto/Referrals+Training+Video.mp4'
      },
      {
        subject: 'Treatment Planning',
        type: 'Workflows',
        description: 'Learn how to add completed and planned treatment and obtain financial agreements.',
        videoURLMP4: 'https://s3-us-west-2.amazonaws.com/demr-howto/Treatment+Planning+Video.mp4'
      },
      {
        subject: 'Two factor authentication',
        type: 'Setup',
        description: 'Learn how to set up two factor authentication.',
        videoURLMP4: 'https://s3-us-west-2.amazonaws.com/demr-howto/2FA+Setup.mp4'
      },

    ];

    $scope.searchVideos = function(value){
      if(!$scope.searchTerm){
        return true;
      }

      //include subject matches
      if(value.subject.toLowerCase().indexOf($scope.searchTerm.toLowerCase()) !== -1){
        return true;
      }

      //include type matches
      if(value.type.toLowerCase().indexOf($scope.searchTerm.toLowerCase()) !== -1){
        return true;
      }

      //include type matches
      if(value.description.toLowerCase().indexOf($scope.searchTerm.toLowerCase()) !== -1){
        return true;
      }

      return false;
    };

  }

  angular.module('common.howto', [])
    .controller('HowtoCtrl', howtoCtrl);
})();

(function() {
  'use strict';

  function faqCtrl($log) {
    $log.debug('FAQ loaded');

  }

  angular.module('common.faq', [])
    .controller('FaqCtrl', faqCtrl);
})();

(function() {
  'use strict';

  function releasesCtrl($log) {
    $log.debug('Releases loaded');

  }

  angular.module('common.releases', [])
    .controller('ReleasesCtrl', releasesCtrl);
})();

(function() {
  'use strict';

  function patientAddCtrl(
    $scope, 
    $rootScope,
    $state,
    PatientsService,
    PatientService,
    NotificationService,
    $uibModalInstance,
    showGoToPatientDetail,
    openPatientDetail,
    patientInfo
    ) {
    $scope.patient = (patientInfo) ? PatientsService.allSnakecaseToCamelcase(patientInfo) : {};

    $scope.showGoToPatientDetail = showGoToPatientDetail;
    $scope.sendForm = false;
    $scope.usePin = false;
    $scope.creating = false;
    $scope.created = false;
    $scope.openPatientDetail = openPatientDetail ? openPatientDetail : false;

    function finishAdd(patient) {
      if ($scope.openPatientDetail) {
        $state.go("profile", {patientid: patient.id});
      }
      $uibModalInstance.close(PatientsService.allCamelcaseToSnakecase(patient));
    }

    $scope.emailChanged = function() {
      console.log("patient.email changed to: " + $scope.patient.email);
      $scope.sendForm = false;
      $scope.usePin = false;
    };

    $scope.sendFormChanged = function() {
      console.log("sendForm changed to: " + $scope.sendForm);
      $scope.usePin = false;
    };

    $scope.sendFormToPatient = function() {
      sendForm($scope.patient, {use_pin: $scope.usePin});
    };

    function sendForm(patient, info) {
      PatientService.sendForm(patient.id, info).then(
        function(response) {
          // eml: user@email.com         -> email was sent
          // pin: 789756                 -> pin was used
          // sms: +11234567890           -> sms was sent (with pin)
          // err: {email: "X", sms: "Y"} -> errors related to emsg and smsg send action
          if (response.data.eml) {
            if (response.data.pin) {
              swal({
                allowOutsideClick: false,
                confirmButtonText: "Understood",
                html: [
                  '<h4 class="text-info">',
                  (response.data.sms
                    ? [
                      "PIN <b>" + response.data.pin + "</b>",
                      "texted to patient's mobile phone <b>" + response.data.sms + "</b>",
                    ]
                    : [
                      "Could not successfully sent text to patient mobile phone.",
                      "Please provide PIN <b>" + response.data.pin + "</b> to the patient through means other than email.",
                    ]
                  ).join("<br/><br/>"),
                  '</h4>',
                ].join(""),
                title: "Form sent to patient",
                type: "success",
              })
            }
            else {
              NotificationService.success("Email successfully sent!")
            }
          }
          else {
            NotificationService.error("Error sending form to patient.", {detail: response.data.err})
          }
          finishAdd(patient); // Always finish and close since patient's already been saved.
        },
        function(error) {
          finishAdd(patient); // Always finish and close since patient's already been saved.
          NotificationService.error('Error sending form to patient. Open patient to edit and try again',error);
        }
      );  
    }

    $scope.submit = function() {
      $scope.creating = true;
      PatientsService.create($scope.patient).then(
        function(event) {
          $scope.creating = false;
          $scope.created = true;

          $scope.patient = PatientsService.allSnakecaseToCamelcase(event.data);
          $rootScope.$broadcast('patientCreatedFromPopup',event.data);
          NotificationService.success('Patient ' + $scope.patient.lastName + ' added sucessfully');

          if ($scope.sendForm) {
            sendForm($scope.patient, {use_pin: $scope.usePin});
          } else {
            finishAdd($scope.patient);
          }
        },
        function(err) {
          $scope.creating = false;
          $scope.created = false;
          NotificationService.error("Error Adding patient", err);
        }
      );
    };

    $scope.close = function() {
      $uibModalInstance.dismiss();
    };

  }

  angular.module('common.patient-add', [])
    .controller('PatientAddCtrl', patientAddCtrl);
})();

(function() {
  'use strict';

  function patientAddPaymentInformationCtrl($scope, ProceduresService, PrincipalService, $log, NotificationService) {

	 ProceduresService.getAllFeeprofiles(PrincipalService.getPracticeId()).then(function(data) {
		  $scope.feeprofiles = angular.fromJson(data.data);
		  if ($scope.feeprofiles.length === 0) {
        NotificationService.error('No fee profiles to load. Please go to settings and set up your practice\'s procedure fee profiles.');
		  } else if(!$scope.patient.feeprofile){
        $scope.patient.feeprofile = $scope.feeprofiles[0].id;
      }
	  }, function(err) {
      NotificationService.error('Error loading feeprofiles',err);
	  });
  }

  angular.module('common.patient-add-payment-information', ['common.directives.datepicker'])
    .controller('PatientAddPaymentInformationCtrl', patientAddPaymentInformationCtrl);
})();

(function () {
  "use strict"

  angular
    .module("common.patient-list", [])

    .component("patientList", {
      bindings: {
        canCreatePatient: "<",
        canDeletePatient: "<",
        canListBilling: "<",
        canListEncounter: "<",
        canListFile: "<",
        canListImage: "<",
        canListInsurance: "<",
        canListPatient: "<",
        canListPrescription: "<",
        canListTreatment: "<",
        canRetrievePatient: "<",
      },
      controller: Controller,
      templateUrl: "src/common/patient-list.tpl.html",
    })

  function Controller($filter, $uibModal, NotificationService, PatientService, PatientsService, uiGridConstants) {
    var $ctrl = this

    $ctrl.$onInit = function () {
      $ctrl.loading = false
      $ctrl.patientDetailView = $ctrl.getPatientDetailView()
      $ctrl.uiGrid = {
        api: null,
        refresh: function () {
          if ($ctrl.uiGrid.api) {
            var isArchived = $ctrl.uiGrid.api.grid.getColumn("is-archived")
            if ($ctrl.uiGrid.showArchivedPatients) {
              isArchived.showColumn()
            } else {
              isArchived.hideColumn()
            }
            $ctrl.uiGrid.api.grid.queueGridRefresh()
          }
        },
        showArchivedPatients: false,
      }
      $ctrl.uiGridSettings = {
        columnDefs: [
          {
            cellClass: "text-right",
            displayName: "ID",
            field: "0",
            name: "id",
            width: 75,
          },
          {
            displayName: "Patient Name (Gender)",
            field: "1",
            name: "name-and-gender",
            minWidth: 75,
            sort: {
              direction: uiGridConstants.ASC,
              priority: 0,
            },
          },
          {
            cellClass: "text-center",
            displayName: "Mobile Phone",
            field: "6",
            name: "mobile-phone",
            maxWidth: 155,
          },
          {
            cellClass: "text-center",
            displayName: "Date of Birth",
            field: "3",
            name: "birth-date",
            maxWidth: 155,
          },
          {
            cellClass: "text-center",
            cellTemplate: [
              '<div class="ui-grid-cell-contents">',
              '  <div class="checkbox-inline custom-control custom-checkbox">',
              "    <label>",
              '      <input type="checkbox" style="bottom:0;"',
              '        ng-change="grid.appScope.$ctrl.setIsArchived(row.entity)"',
              '        ng-model="row.entity[4]">',
              '      <span class="custom-control-indicator"></span>',
              "    </label>",
              "  </div>",
              "</div>",
            ].join(""),
            displayName: "Is Archived",
            enableColumnMenu: false,
            enableFiltering: false,
            field: "4",
            name: "is-archived",
            visible: $ctrl.uiGrid.showArchivedPatients,
            width: 135,
          },
          {
            cellTemplate: [
              '<div class="ui-grid-cell-contents">',
              '  <div class="btn-group">',
              '    <a class="btn btn-icomoon-pixel-perfect-1x font-blue m-r-sm"',
              '      ui-sref="{{ grid.appScope.$ctrl.patientDetailView }}({patientid: row.entity[0]})"',
              '      ng-disabled="!grid.appScope.$ctrl.patientDetailView">',
              '      <i class="icomoon icon-icomoon-pencil5"></i>',
              "    </a>",
              '    <button type="button" class="btn btn-icomoon-pixel-perfect-1x font-red"',
              '      ng-click="grid.appScope.$ctrl.openPatientDeleteModal(row.entity);"',
              '      ng-if="grid.appScope.$ctrl.canDeletePatient && row.entity[5]">',
              '      <i class="icomoon icon-icomoon-bin"></i>',
              "    </button>",
              "  </div>",
              "</div>",
            ].join(""),
            displayName: "",
            enableColumnMenu: false,
            enableColumnResizing: false,
            enableFiltering: false,
            name: "actions",
            width: 80,
          },
        ],
        enableFiltering: true,
        onRegisterApi: function (api) {
          api.grid.registerRowsProcessor(function (rows) {
            var showArchivedPatients = $ctrl.uiGrid.showArchivedPatients
            rows.forEach(function (row) {
              if (showArchivedPatients === false && row.entity[4] === true) {
                row.visible = false
              } else {
                row.visible = true
              }
            })
            return rows
          }, 99)
          $ctrl.uiGrid.api = api
        },
        showGridFooter: true,
      }
      $ctrl.load()
    }

    $ctrl.getPatientDetailView = function () {
      if ($ctrl.canRetrievePatient) {
        return "profile"
      }
      if ($ctrl.canListEncounter) {
        return "chartnotes"
      }
      if ($ctrl.canListTreatment) {
        return "treatmentplans"
      }
      if ($ctrl.canListImage) {
        return "photos"
      }
      if ($ctrl.canListPrescription) {
        return "prescriptions"
      }
      if ($ctrl.canListFile) {
        return "files"
      }
      if ($ctrl.canListBilling) {
        return "billing"
      }
      if ($ctrl.canListInsurance) {
        return "insurance"
      }
      return null
    }

    $ctrl.load = function (reloadCache) {
      $ctrl.loading = true
      PatientService.v3.list(reloadCache).then(
        function (response) {
          $ctrl.uiGridSettings.minRowsToShow = Math.min(20, response.data.length)
          $ctrl.uiGridSettings.data = response.data
          $ctrl.loading = false
        },
        function () {
          $ctrl.uiGridSettings.data = []
          $ctrl.loading = false
        }
      )
    }

    $ctrl.openPatientCreateModal = function () {
      $uibModal
        .open({
          controller: "PatientAddCtrl",
          resolve: {
            openPatientDetail: null,
            patientInfo: function () {
              return null
            },
            showGoToPatientDetail: function () {
              return true
            },
          },
          size: "lg",
          templateUrl: "src/common/patient-add.tpl.html",
        })
        .result.then(
          function (patient) {
            $ctrl.uiGridSettings.data.push($ctrl.toDataItem(patient))
          },
          function () {}
        )
    }

    $ctrl.openPatientDeleteModal = function (patient) {
      $uibModal
        .open({
          bindToController: true,
          controller: function ($scope) {
            var $ctrl = this
            $ctrl.patient = {
              name: patient[1],
            }
            $ctrl.submit = function () {
              PatientsService.delete(patient[0]).then(
                function () {
                  $scope.$close(patient[0])
                },
                function (err) {
                  $scope.$dismiss(err)
                }
              )
            }
          },
          controllerAs: "$ctrl",
          size: "sm",
          templateUrl: "patient-list.tpl.html#patient-delete-modal",
        })
        .result.then(
          function (id) {
            var idx = $ctrl.uiGridSettings.data.findIndex(function (item) {
              return item[0] === id
            })
            if (idx !== -1) {
              $ctrl.uiGridSettings.data.splice(idx, 1)
            }
          },
          function (reason) {
            if (reason) {
              NotificationService.error("Error Deleting patient", reason)
            }
          }
        )
    }

    $ctrl.setIsArchived = function (row) {
      PatientService.v3.update(row[0], { is_archived: row[4] }).then(
        function () {
          NotificationService.clear()
          NotificationService.success("Saved")
        },
        function (error) {
          NotificationService.error("Error saving patient", error)
        }
      )
    }

    $ctrl.toDataItem = function (patient) {
      var gender = patient.gender ? patient.gender.toUpperCase() : patient.gender
      return [
        patient.id,
        patient.last_name + ", " + patient.first_name + (gender ? " (" + gender + ")" : ""),
        $filter("tel")(patient.home_phone),
        $filter("date")(patient.birth_date, "MM/dd/yyyy"),
        patient.is_archived,
        true,
        $filter("tel")(patient.mobile_phone),
      ]
    }
  }
})();

(function() {
  'use strict';

  function chartsCtrl(
    $log,
    $uibModal,
    $scope,
    $state,
    $rootScope ,
    CanCreatePatient,
    CanDeletePatient,
    CanListBilling,
    CanListEncounter,
    CanListFile,
    CanListImage,
    CanListInsurance,
    CanListPrescription,
    CanListTreatment,
    CanRetrievePatient,
    NotificationService,
    PatientsService,
    WebsocketService
    ) {

    $log.debug('Charts loaded');
    $scope.isResetting = false;
    $scope.angular = angular;

    $scope.CanDeletePatient = CanDeletePatient;
    $scope.CanCreatePatient = CanCreatePatient;
    $scope.CanRetrievePatient = CanRetrievePatient;
    $scope.patients = [];
    $scope.dateFormat = 'MM/dd/yyyy';

    $scope.searchTerm = "";

    $scope.orderByProp = ['last_name', 'first_name', 'gender'];

    $scope.orderByReverse = false;

    $scope.orderByClick = function(prop) {
      if (angular.equals(prop, $scope.orderByProp)) {
        $scope.orderByReverse = !$scope.orderByReverse;
      }
      else {
        $scope.orderByProp = prop;
      }
    }

    // setup socket observer to listen for updates or deletion of this encounter
    //begin websocket observer setup
    var scope = $scope;
    var strm = 'notification';
    var cmds_observed = [
      'model_update_authentication_models_cachestorage',
    ];
    scope.notificationObservableId = WebsocketService.observe(
      strm,
      cmds_observed,
      function(stream, cmd, params) {
        $log.debug('Websocket message received in CACHESTORAGE with ID: ' + scope.notificationObservableId + '; STREAM: ' + stream + '; CMD: ' + cmd + '; PARAMS: ' + JSON.stringify(params));
        reset();
      }
    );
    $log.debug('Websocket observer registered in CACHESTORAGE with ID: ' + scope.notificationObservableId + '; STREAM: ' + strm + '; CMDS_OBSERVED: ' + JSON.stringify(cmds_observed));
    $scope.$on('$destroy', function() {
      WebsocketService.unobserve(scope.notificationObservableId);
      $log.debug('Websocket observer unregistered in CACHESTORAGE with ID: ' + scope.notificationObservableId);
    });
    //end websocket observer setup



    function reset() {
      $scope.isResetting = true;
      if ($scope.include_archived) {
        PatientsService.allIncludingArchived().then(
          function(data) {
            var patientData = angular.fromJson(data.data);
            $scope.patients = patientData;
            $scope.isResetting = false;
          },
          function(err) {
            $scope.isResetting = false;
            NotificationService.error('Error loading patients',err);
          }
        );
      } else {
        PatientsService.all().then(
          function(data) {
            var patientData = angular.fromJson(data.data);
            $scope.patients = patientData;
            $scope.isResetting = false;
          },
          function(err) {
            $scope.isResetting = false;
            NotificationService.error('Error loading patients',err);
          }
        );
      }
    }

    $scope.get = function() {
      reset();
    };

    $scope.set = function(patient) {
      PatientsService.update(patient.id, patient).then(
        function(data) {
          patient = angular.fromJson(data.data);
          NotificationService.success('Saved');
        },
        function(err) {
          NotificationService.error('Error saving patient',err);
        }
      );
    };

    $scope.hasAvailablePatientView = function() {
      if(CanRetrievePatient){
        return true;
      }
      if(CanListEncounter){
        return true;
      }
      if(CanListTreatment){
        return true;
      }
      if(CanListImage){
        return true;
      }
      if(CanListPrescription){
        return true;
      }
      if(CanListFile){
        return true;
      }
      if(CanListBilling){
        return true;
      }
      if(CanListInsurance){
        return true;
      }
      return false;
    };

    $scope.goToFirstAvailablePatientView = function(patient) {
      if(CanRetrievePatient){
        return $state.go('profile',{patientid:patient.id});
      }
      if(CanListEncounter){
        return $state.go('chartnotes',{patientid:patient.id});
      }
      if(CanListTreatment){
        return $state.go('treatmentplans',{patientid:patient.id});
      }
      if(CanListImage){
        return $state.go('photos',{patientid:patient.id});
      }
      if(CanListPrescription){
        return $state.go('prescriptions',{patientid:patient.id});
      }
      if(CanListFile){
        return $state.go('files',{patientid:patient.id});
      }
      if(CanListBilling){
        return $state.go('billing',{patientid:patient.id});
      }
      if(CanListInsurance){
        return $state.go('insurance',{patientid:patient.id});
      }
    };

    reset();

    $scope.openAddPatientModal = function() {
      var patientCreatedFromPopupDeregisterFunc = $rootScope.$on('patientCreatedFromPopup',function(event,data){
        $scope.patients.push(data);
      });

      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        size:'lg',
        templateUrl: 'src/common/patient-add.tpl.html',
        controller: 'PatientAddCtrl',
        resolve: {
          openPatientDetail: null,
          showGoToPatientDetail: function () {
            return true;
          },
          patientInfo: function () {
            return null;
          }
        }
      });

      modalInstance.result.then(function () {
        //reset(); //FIXME CACHE: comment when cache is completed, not needed
        patientCreatedFromPopupDeregisterFunc();
      }, function () {
      });
    };


    //delete patient (can only delete patients that have no associated treatments)
    $scope.openDelPatient = function (patient) {

      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'delPatient.html',
        controller: 'deletePatientModalInstanceCtrl',
        size: 'sm',
        resolve: {
          patient: function () {
            return patient;
          }
        }
      });

      modalInstance.result.then(function (patient) {
        $scope.doDel(patient);
      }, function () {
      });
    };

    $scope.doDel = function(patient) {
      //Remove it from patient list first...
      var patientIndex = $scope.patients.map(function(patient) { return patient.id; }).indexOf(patient.id);
      if (patientIndex > -1) {
        $scope.patients.splice(patientIndex, 1);
      }
      PatientsService.delete(patient.id).then(function() {
         //reset();
         NotificationService.success('Successfully deleted patient.');
      }, function(err) {
        //...and if there's an error, reset() to add it back in
        reset();
        NotificationService.error('Error Deleting patient',err);
      });
    };
  }

  function deletePatientModalInstanceCtrl(
    $scope, $uibModalInstance, patient)
  {
    $scope.patient = patient;

    $scope.ok = function () {
      $uibModalInstance.close($scope.patient);
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }


  angular.module('common.charts', [])
    .controller('ChartsCtrl', chartsCtrl)
    .controller('deletePatientModalInstanceCtrl', deletePatientModalInstanceCtrl);
})();

/* jshint undef: false, unused: false */
(function() {
  'use strict';

  function encountersGridCtrl($scope, $uibModal, $stateParams, $log, EncountersService, PrincipalService, NotificationService,
                              CanCreateEncounter, CanRetrieveEncounter, CanDeleteEncounter,$state, AuthenticationService) {
    $log.debug('EncountersGrid loaded');

    $scope.CanCreateEncounter = CanCreateEncounter;
    $scope.CanRetrieveEncounter = CanRetrieveEncounter;
    $scope.CanDeleteEncounter = CanDeleteEncounter;

    $scope.patientId = $stateParams.patientid;

    $scope.encounters = [];

    $scope.searchTerm = "";

    $scope.orderByProp = "encounter_date";

    $scope.orderByReverse = true;

    $scope.orderByClick = function(prop) {
      if (prop ===  $scope.orderByProp) {
        $scope.orderByReverse = !$scope.orderByReverse;
      }
      else {
        $scope.orderByProp = prop;
      }
    }

    $scope.goToEncounterType = function(type){
      if(PrincipalService.isEncounterAssistant()){
        return AuthenticationService.getPracticeAdminAndProviderAccounts(PrincipalService.getPracticeId(), true).then(
          function(data) {
            var providers = angular.fromJson(data.data);
            if (providers.length === 1) {
              return $state.go('chart-detail-new', {type:type, providerId: providers[0].id}, {location:false});
            }
            $scope.showProviderSelection(type, providers);
          },
          function(err) {
            NotificationService.error('Error Loading users list',err);
          }
        );
      }
      $state.go('chart-detail-new',{type:type},{location:false});
    };


    $scope.showProviderSelection = function(type, providers){
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'src/common/provider-selection.tpl.html',
        controller: 'ProviderSelectionCtrl as ctrl',
        resolve: {
          providers: function() {
            return providers
          }
        },
        size: 'sm'
      });


      modalInstance.result.then(function (providerId) {
        if(providerId){
          $state.go('chart-detail-new',{type:type,providerId:providerId},{location:false});
        }
      });

    };

    $scope.displayType = function(type) {
       if (type === 'co') {
          return 'Consultation';
       } else if (type === 'cp') {
          return 'Consultation & Procedure';
       } else if (type === 'pr') {
          return 'Procedure';
       } else if (type === 'fu') {
          return 'FollowUp';
       }
    };

    $scope.reset = function() {
        EncountersService.all($scope.patientId).then(
          function(data) {
            $scope.encounters = angular.fromJson(data.data);
          },
          function(err) {
            NotificationService.error('Error retrieving encounters',err);
          });
    };

    $scope.reset();
/* PRIOR GRID CODE
    // Gets a list of all the patients and puts them in the grid.
    var linkCellTemplate = '<a ui-sref="chart-detail({encounterid:{{row.entity.id}}})">{{row.entity.title}}</a>';
    var linkCellDateTemplate = '<a ui-sref="chart-detail({encounterid:{{row.entity.id}}})">{{row.entity.encounter_date | date:"MM/dd/yyyy"}}</a>';

    $scope.gridOptions = { data: 'encounters',
            columnDefs:
             [{ field: 'title', displayName: 'Title', cellTemplate: linkCellTemplate },
              { field: 'encounter_date', displayName: 'Date', cellTemplate: linkCellDateTemplate},
            ]};




    console.log('patientId  = ',$scope.patientId);
*/

    //delete treatment modal
    $scope.openDelEncounterModal = function (encounterId, encounterDate, encounterTitle) {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'delEncounter.html',
        controller: 'delEncounterModalInstanceCtrl',
        size: 'sm',
        resolve: {
          encounterDate: function () {
            return encounterDate;
          },
          encounterTitle: function () {
            return encounterTitle;
          }
        }
      });

      modalInstance.result.then(function (selectedItem) {
          $scope.doDel(encounterId);
      }, function () {
      });
    };

    $scope.doDel = function(encounterId) {
    $log.debug('delete called for encounter ' + encounterId);
        EncountersService.delete(encounterId).then(function(data) {
           NotificationService.success('Successfully deleted encounter.');
           $scope.reset();
        }, function(err) {
          NotificationService.error('Error deleting encounter',err);
        });

    };

  }

  function delEncounterModalInstanceCtrl($scope, $uibModalInstance, encounterDate, encounterTitle) {
      $scope.encounterDate = encounterDate;
      $scope.encounterTitle = encounterTitle;
      $scope.ok = function () {
        $uibModalInstance.close();
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
  }

  angular.module('common.patient-detail-encounters', [])
    .controller('PatientDetailEncounters', encountersGridCtrl)
    .controller('delEncounterModalInstanceCtrl', delEncounterModalInstanceCtrl);

})();

(function() {
  'use strict';

  angular.module("common.signin", [])

  .controller("SignInCtrl", SignInCtrl)

  .controller("SignInVerifyCtrl", SignInVerifyCtrl)


  function SignInCtrl(
    $http,
    $scope,
    $state,
    NotificationService,
    PrincipalService
    ) {

    $scope.email = ""
    $scope.password = ""

    $scope.signin = function(data) {
      const formData = data ? data : $scope;
      $http.post("/api/v1/auth/login/", {email: formData.email, password: formData.password}).then(
        function (response) {
          if (response.data.verification_token) {
            // User has two factor authentication enabled and needs to verify his credentials
            $state.go("signin-verify", {verificationToken: response.data.verification_token})
            return
          }
          PrincipalService.login(response.data)
        },
        function (err) {
          if (typeof err === "string" && (err.indexOf("408") || err.indexOf("504"))) {
            NotificationService.error("Signing in: Request timed out. Please try again.")
          }
          else {
            NotificationService.error("Signing in", err)
          }
          // When the screen is locked we don't need to go to the signin
          if (localStorage.getItem("inLockScreen") === "true") {
            return
          }
          // $state.go("signin")
        }
      )
    }

  }

  function SignInVerifyCtrl(
    $scope,
    $state,
    $transition$,
    NotificationService,
    PrincipalService,
    TwoFactorService
    ) {

    if (!$transition$.params().verificationToken) {
      $state.go("signin")
      return
    }

    var verificationToken = $transition$.params().verificationToken
    var reqOptions = {confirmed: true, verificationToken: verificationToken}

    $scope.device = {token: null}
    $scope.backupTokens = []

    TwoFactorService.statics.all(verificationToken).then(
      function (response) {
        $scope.backupTokens = response.data
      }
    )

    TwoFactorService.devices.all(reqOptions).then(
      function (devices) {
        angular.extend($scope, devices)
        // If the primary device is a phone, generate the challenge
        if ($scope.primary.isPhone) {
          if ($scope.device.method === "call") {
            $scope.indicationText = "We will call you"
          }
          else {
            $scope.indicationText = "We sent you a text message"
          }
          $scope.indicationText += " , please enter the provided token."
          $scope.generateChallenge($scope.primary.device)
        }
        else {
          $scope.indicationText = "Use your token generator app to get a token"
        }
      },
      function (err) {
        NotificationService.error("Error loading your devices", err)
        $state.go("signin")
      }
    )

    $scope.generateChallenge = function (device) {
      TwoFactorService.phones.challenge(device.id, verificationToken).then(null,
        function (response) {
          if (response.data && response.data.error) {
            NotificationService.error(response.data.error)
          }
          check403(response)
        }
      )
    }

    $scope.submit = function () {
      if ($scope.tokenForm.$valid) {
        TwoFactorService.verify($scope.device, verificationToken).then(
          function (response) {
            PrincipalService.login(response.data)
          },
          function (response) {
            if (response.data && response.data.token) {
              $scope.serverError = true
            }
            check403(response)
          }
        )
      }
    }

    function check403(response) {
      if (response.status === 403) {
        NotificationService.error("Ran out of time to provide a token, please start over")
        $state.go("signin")
      }
    }

  }

})();

(function() {
  'use strict';

  function signoutCtrl($log) {
    $log.debug('SignOut Loaded');
    }

  angular.module('common.signout', [])
    .controller('SignoutCtrl', signoutCtrl);
})();

(function () {
    'use strict';

    function passwordResetCtrl($scope, PasswordService, $location, NotificationService) {
        $scope.model = {
            email: ''
        };
        $scope.emailSent = false;


        $scope.return = function () {
            $location.path('/signin');
        };

        $scope.resetPassword = function (email) {
            const formEmail = email ? email : $scope.model.email
            if (formEmail) {
                PasswordService.reset(formEmail).then(function () {
                    $scope.emailSent = true;
                }, function (response) {
                    if (response.status === 400 || response.status === 404) {
                        NotificationService.error('Email address is either invalid or non-existent');
                    } else {
                        NotificationService.error('Error Sending the email');
                    }
                });
            }
        };
    }

    angular.module('common.password-reset', [])
        .controller('PasswordResetCtrl', passwordResetCtrl);
})();

(function () {
    'use strict';

    function passwordResetConfirmCtrl($scope, $state, $transition$, NotificationService, PasswordService) {
        if (!$transition$.params().uid || !$transition$.params().token) {
            $scope.invalidUrl = true;
            return;
        }

        $scope.formModel = {
            password: '',
            confirmPassword: '',
            uid: $transition$.params().uid,
            token: $transition$.params().token
        };

        $scope.submit = function (resetConfirmForm) {
            if (resetConfirmForm.$valid) {
                PasswordService.confirm($scope.formModel).then(function () {
                    $state.go('signin');
                    NotificationService.success('Your password has been reset! You may now Sign in!');
                }, function () {
                    $scope.invalidUrl = true;
                });
            }
        };
    }

    function passwordChangeCtrl($scope, $state, PasswordService,NotificationService) {
        $scope.changePassword = true;
        $scope.$state = $state;
        $scope.formModel = {
            password: '',
            confirmPassword: '',
            currentPassword: ''
        };

        $scope.submit = function (passwordForm) {
            const formData = passwordForm ? passwordForm : $scope.formModel
            if (formData) {
                PasswordService.change(formData).then(function () {
                    $state.go('signin');
                    NotificationService.success('Your password has been changed! Please sign in again with your new password.');
                }, function (response) {
                    if (response.data && response.data.current_password) {
                        NotificationService.error('Invalid Password');
                    }
                });
            }
        };
    }

    angular.module('common.password-change', [])
        .controller('PasswordResetConfirmCtrl', passwordResetConfirmCtrl)
        .controller('PasswordChangeCtrl', passwordChangeCtrl);
})();

(function() {
  'use strict';

  function flowCompleteCtrl($scope, $state, $transition$) {
    if (!$transition$.params().message) {
      return $state.go('schedule');
    }
    console.log('sd');
    //Reset to default background
    $('body').css('background', 'none');

    $scope.page = {
      title: $transition$.params().title || 'Thank you!',
      message: $transition$.params().message
    };
  }

  angular.module('common.flow-complete', [])
    .controller('FlowCompleteCtrl', flowCompleteCtrl);
})();

/*global _ */
(function() {
  'use strict';

  function anonymousPatientDetailCtrl(
    $scope,
    $state,
    $transition$,
    $timeout,
    $uibModal,
    Idle,
    Keepalive,
    NotificationService,
    PatientsService,
    TextTemplateService
  ) {
    if (!$transition$.params().token) {
      $scope.invalidUrl = true;
      return;
    }

    //Remove the default background that contains the left menu color
    $('body').css('background','#eef1f4');

    if (window.top !== window.self) {
      $("html")
        .css("-webkit-overflow-scrolling", "touch")
        .css("height", "100%")
        .css("overflow", "auto")
        .css("width", "100%")
      $("body")
        .css("-webkit-overflow-scrolling", "touch")
        .css("height", "100%")
        .css("overflow", "auto")
        .css("width", "100%")
    }

    if (window.top !== window.self) {
      Idle.unwatch();
      Keepalive.stop();
    }

    $scope.patient = [];
    $scope.completed = false;
    $scope.disablePrimaryInsurance = false;
    $scope.disableOtherInsurance = false;
    $scope.pin = "";

    $scope.clearSignature = function(patient,field){
      patient[field] = null;
      patient[field+'Date'] = null;
      //We need to set it to '' so allow_blank=True works fine as is the default on django and not null
      //and null is the only way to clear out the signature on the component
      $timeout(function(){
        patient[field] = '';

      },1);

    };


    $scope.assignBenefitsChange = function(newValue,signatureToClear){
      if(!newValue){
        $scope.clearSignature($scope.patient,signatureToClear)
      }
    };

    // This is a helper function used for creating a ng-repeat of num times.
    $scope.getNumber = function(num) {
      return new Array(num);
    };

    //run when some main field of the insurance plan change
    $scope.planDataChange = function() {};

    $scope.canEditPatientInfo = function() {
      //Ignore if is  anonymous
      if($scope.isAnonymous()){
        return true;
      }
    };

    $scope.canEditMedicalHistoryInfo = function() {
      //Ignore if is anonymous
      if($scope.isAnonymous()){
        return true;
      }
    };

    $scope.canEditPatientInsurance = function() {
      //Ignore if is anonymous
      if($scope.isAnonymous()){
        return true;
      }
    };

    $scope.load = function(pin) {
      $scope.pin = pin
      PatientsService.anonymous.get($transition$.params().token, pin).then(
        function(data) {
          var patient = angular.fromJson(data.data)
          patient.extensions = PatientsService.mergeExtensions(patient.extensions, patient.extensions_template);
          PatientsService.setExtensionDates(patient.extensions, true);

          if (patient.insurance_billing_provider_assigned_benefits === false) {
            $scope.disablePrimaryInsurance = true
            patient.insurance_subscriber_signature = ''
            patient.insurance_subscriber_signature_date = null
          }
          if (patient.other_coverage_billing_provider_assigned_benefits === false) {
            $scope.disableOtherInsurance = true
            patient.other_coverage_subscriber_signature = ''
            patient.other_coverage_subscriber_signature_date = null
          }
          var patientDataCamelCase = PatientsService.allSnakecaseToCamelcase(patient)
          var patientDataCamelCaseWithInsuranceSnakeCase = PatientsService.insuranceCamelcaseToInsurancedataSnakecase(patientDataCamelCase)
          //set the data to display from insurance_data_provided_by_patient json ,
          // if no insurance plan os selected for patient
          if (patientDataCamelCaseWithInsuranceSnakeCase.insuranceDataProvidedByPatient && !patientDataCamelCaseWithInsuranceSnakeCase.insurancePlan) {
            $scope.patient.insuranceData = patientDataCamelCaseWithInsuranceSnakeCase.insuranceDataProvidedByPatient.insuranceData
            $scope.patient.otherInsuranceData = patientDataCamelCaseWithInsuranceSnakeCase.insuranceDataProvidedByPatient.otherInsuranceData
          }
          $scope.originalData = angular.copy(patientDataCamelCaseWithInsuranceSnakeCase)
          $scope.patient = patientDataCamelCaseWithInsuranceSnakeCase
          $scope.invalidUrl = false
          $scope.signatureText =  patient.text_signature
          if (patientDataCamelCaseWithInsuranceSnakeCase.insurancePlan || !_.isEmpty(patientDataCamelCaseWithInsuranceSnakeCase.insuranceDataProvidedByPatient)) {
            $scope.patient.hasHealthInsurance = true
          }
        },
        function(response) {
          if (response.status === 403) {
            var error = elvisEx(response.data, "detail", null)
            if (error === "PIN invalid") {
              NotificationService.error("Invalid PIN")
            }
            if (error === "PIN invalid" || error === "PIN required") {
              $uibModal.open({
                bindToController: true,
                controllerAs: "$ctrl",
                backdrop: "static",
                keyboard: false,
                size: "md",
                templateUrl: "#send-form-pin-modal",
                controller: function() {
                  this.pin = pin || ""
                },
              }).result.then(
                function(_pin) {
                  $scope.load(_pin)
                }
              )
            }
            else {
              $scope.invalidUrl = true;
              NotificationService.error('Your token is expired or does not exist anymore');
            }
          }
          else {
            NotificationService.error('Error loading patient data',response);
          }
        }
      )

      TextTemplateService.getTextTemplateForPractice('Fees and Payments Text').then(function(value) {
        $scope.feeAndPaymentTitle = value.data.title;
        $scope.feeAndPaymentText = value.data.content;
      })
    }

    $scope.load()

    //for triggering submit from outside of form so button can fit skin.
    $scope.triggerSubmit = function() {
      $scope.submit();
    };

    $scope.submit = function() {
      PatientsService.insuranceDataSnakecaseToSnakecase($scope.patient);
      var newPatient = angular.copy($scope.patient);

      if(
        angular.toJson($scope.originalData.insuranceData)  !== angular.toJson(newPatient.insuranceData) ||
        angular.toJson($scope.originalData.otherInsuranceData)  !== angular.toJson(newPatient.otherInsuranceData)
      ) {
        newPatient.insurance_data_provided_by_patient = {
          insuranceData:$scope.patient.insuranceData,
          otherInsuranceData:$scope.patient.otherInsuranceData
        };
        newPatient.insurance_data_provided_by_patient = angular.toJson(newPatient.insurance_data_provided_by_patient);
      }

      PatientsService.setExtensionDates(newPatient.extensions, false);
      newPatient.extensions = angular.toJson(newPatient.extensions);
      swal({
        title: 'Are you sure?',
        text: [
          "Once updated you will not be able to access this form again.",
          "If you have more information to add, click cancel and complete before updating."
        ].join(" "),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, update!',
        closeOnConfirm: true
      }, function () {
        PatientsService.anonymous.update($transition$.params().token, newPatient, $scope.pin).then(
          function success() {
            if (window.top === window.self) {
              $state.go('flow-complete', {
                message: 'Your information has been updated.'
              });
            }
            else {
              window.top.postMessage({
                stream: "kiosk",
                payload: {
                  cmd: "patient:done",
                  request: {
                    target: "client:kiosk",
                    "patient:done": {
                      patient: {
                        form: {
                          token: $transition$.params().token
                        }
                      }
                    }
                  }
                }
              }, '*');
            }
          },
          function fail(err) {
            NotificationService.error('Error updating your information',err);
          }
        );
      });
    };


    $scope.medicalHistoryToogleFields = ['isInGoodHealth','isUnderPhysicianCare','isRecommendedAntibiotics','isHospitalizedPastFiveYears',
      'isHadGeneralAnesthesia','isHadReactionAnesthesia'];

    $scope.$watch(function(){ return $scope.patient.markAllMedicalHistory; }, function(newValue){
      $scope.medicalHistoryToogleFields.forEach(function (field) {
        $scope.patient[field] = newValue;
      });
    },true);


    $scope.medicalHistoryConditions1ToogleFields = ['isRheumticFever','isHighBloodPressure','isLowBloodPressure','isMitralValveProlapse',
      'isHeartMurmur','isChestPainAngina','isHeartAttacks','isIrregularHeartBeat','isCardiacPacemaker','isHeartSurgery','isDamagedHeartValves','isPneumonia','isChronicFatigue',
      'isTroubleClimbingStairs','isAnemia','isAsthma','isBleedingTendency','isBloodTransfusion','isBloodDisorder','isBruiseEasily','isEyeDisease','isLiverDisease','isHepatitis',
      'isGallbladderTrouble','isFaintingSpells','isConvulsionsEpilepsy','isStroke','isThyroidTrouble','isDiabetes','isLowBloodSugar','isOnDialysis'];

    $scope.$watch(function(){ return $scope.patient.markAllMedicalHistoryConditions1; }, function(newValue){
      $scope.medicalHistoryConditions1ToogleFields.forEach(function (field) {
        $scope.patient[field] = newValue;
      });
    },true);


    $scope.medicalHistoryConditions2ToogleFields = ['isAids','isHiv','isKidneyTrouble','isMentalHealthProblems','isImmuneSystemProblems','isDelayInHealing','isSinusProblems','isSnoring','isSleepApnea',
      'isRespiratoryProblems','isTuberculosis','isEmphysema','isSmoker','isUsingChewingTobacco','isHasDrugAbuseHistory','isHasAlcoholAbuseHistory','isAbnormalBleeding','isSexuallyTransmittedDisease',
      'isContagiousDiseases','isInfectiousMononucleosis','isSwollenAnkles','isArthritis','isProstheticImplant','isJointReplacement','isOsteoporosis','isOsteonecrosis','isStomachUlcers','isTumorGrowth',
      'isCancer','isOnDiet','isPregnant'];

    $scope.$watch(function(){ return $scope.patient.markAllMedicalHistoryConditions2; }, function(newValue){
      if(newValue === undefined){
        return;
      }
      $scope.medicalHistoryConditions2ToogleFields.forEach(function (field) {
        $scope.patient[field] = newValue;
      });
    },true);


    $scope.dentalInformationToogleFields = ['isInDiscomfort','isSwollenGums','isRemovableAppliance','isBlistersSores','isProlongedBleeding','isRecentInfections','isSensitiveToHot','isSensitiveToCold',
      'isSensitiveToSweets','isSensitiveToBiting','isStainedTeeth','isLockingJaw','isBadBreath','isToothache','isBurningTongue','isLostBrokenFillings','isTeethGrindingClenching','isBrokenChippedTooth',
      'isDifficultyClosingJaw','isDifficultyOpeningJaw','isLooseShiftingTeeth','isFoodCaughtBetweenTeeth','isSwellingLumpInMouth','isOther'];

    $scope.$watch(function(){ return $scope.patient.markAllDentalInformationPart2; }, function(newValue){
      $scope.dentalInformationToogleFields.forEach(function (field) {
        $scope.patient[field] = newValue;
      });
    },true);


    $scope.medicationTakingToogleFields = ['isTakingNervePills','isTakingDietPills','isTakingTranquilizers','isTakingMuscleRelaxers','isTakingInsulin','isTakingStimulants','isTakingAntidepressants',
      'isTakingBloodThinners','isTakingBoneDensityMeds','isTakingAntibiotic','isTakingValium'];

    $scope.$watch(function(){ return $scope.patient.markAllMedicationTaking; }, function(newValue){
      $scope.medicationTakingToogleFields.forEach(function (field) {
        $scope.patient[field] = newValue;
      });
    },true);


    $scope.allergicToogleFields = ['isHasKnownAllergies','isAllergicToSoy','isAllergicToSulfaDrugs','isAllergicToAspirin','isAllergicToEggs','isAllergicToLocalAnesthetic','isAllergicToCodeine',
      'isAllergicToSulfites','isAllergicToAmoxicillin','isAllergicToLatex'];

    $scope.$watch(function(){ return $scope.patient.markAllAllergic; }, function(newValue){
      $scope.allergicToogleFields.forEach(function (field) {
        $scope.patient[field] = newValue;
      });
    },true);

  }

  angular.module('common.anonymous.patient-detail', [])
    .controller('AnonymousPatientDetailCtrl', anonymousPatientDetailCtrl);
})();

(function() {
  'use strict';

  angular.module('common.patient-detail', [])
    .controller('PatientDetailCtrl', PatientDetailCtrl);

  function PatientDetailCtrl(
    $rootScope,
    $scope,
    $state,
    $transition$,
    CanListBilling,
    CanListEncounter,
    CanListInsurance,
    CanListPatientFile,
    CanListPatientImage,
    CanListPrescription,
    CanListReferral,
    CanListTreatment,
    CanRetrievePatient,
    NotificationService,
    PatientsService,
    PatientInteractionsService
    ) {

    $scope.CanListBilling = CanListBilling;
    $scope.CanListEncounter = CanListEncounter;
    $scope.CanListInsurance = CanListInsurance;
    $scope.CanListPatientFile = CanListPatientFile;
    $scope.CanListPatientImage = CanListPatientImage;
    $scope.CanListPrescription = CanListPrescription;
    $scope.CanListReferral = CanListReferral;
    $scope.CanListTreatment = CanListTreatment;
    $scope.CanRetrievePatient = CanRetrievePatient;

    $scope.$state = $state;
    $scope.patientId = $transition$.params().patientid;
    $scope.patientAlerts = null;

    function showPatientInteractionsAlerts(patient) {
      PatientInteractionsService.allAlerts(patient.id).then(
        function(response) {
          $scope.patientAlerts = response.data
        },
        function(err) {
          NotificationService.error('Error retrieving patient interactions alerts',err);
        }
      );
    }

    $scope.getYearsOld = function(iso_date_string) {
      return PatientsService.getYearsOld(iso_date_string);
    }

    PatientsService.get($scope.patientId).then(
      function(data) {
        var patient = angular.fromJson(data.data);
        $scope.patient = PatientsService.allSnakecaseToCamelcase(patient);
        showPatientInteractionsAlerts(patient);
      },
      function(err) {
        NotificationService.error('Error loading patient data',err);
      }
    );

    $rootScope.$on('mugshot-changed', function(event, mugshot) {
      if ($scope.patient) {
        $scope.patient.mugshot = mugshot;
      }
    });

    $rootScope.$on("patient-changed", function(event, patient) {
      $scope.patient = angular.copy(patient);
    });

    $scope.discardAlerts = function() {
      $scope.patientAlerts = null;
    }

  }

})();

(function() {
  'use strict';

  angular.module('common.patient-detail-consentforms', [])
    .controller('PatientDetailConsentformsCtrl', PatientDetailConsentformsCtrl);

  function PatientDetailConsentformsCtrl(
    $filter,
    $q,
    $rootScope,
    $scope,
    $uibModal,
    CanAccessConsentForm,
    CanCreateConsentForm,
    CanDeleteConsentForm,
    CanListConsentForm,
    CanListConsentFormTemplate,
    CanRetrieveConsentForm,
    CanRetrieveConsentFormTemplate,
    CanUpdateConsentForm,
    ConsentFormService,
    ConsentFormTemplateService,
    NotificationService
  ) {

    $scope.CanAccessConsentForm = CanAccessConsentForm;
    $scope.CanCreateConsentForm = CanCreateConsentForm;
    $scope.CanDeleteConsentForm = CanDeleteConsentForm;
    $scope.CanListConsentForm = CanListConsentForm;
    $scope.CanRetrieveConsentForm = CanRetrieveConsentForm;
    $scope.CanUpdateConsentForm = CanUpdateConsentForm;
    $scope.CanListConsentFormTemplate = CanListConsentFormTemplate;
    $scope.CanRetrieveConsentFormTemplate = CanRetrieveConsentFormTemplate;

    $scope.consent_form_templates = [];
    $scope.consent_forms = [];
    $scope.needsConsent = false;
    $scope.upload_queue = [];

    // this event is broadcasted from PatientDetailTreatmentPlansCtrl
    $rootScope.$on(
      'encounter-treatments-changed',
      function(event, treatments, current_consented_treatments) {
        $scope.needsConsent = _.difference(treatments, current_consented_treatments).length > 0;
      }
    );

    $scope.load = function(notify) {
      if ($scope.CanListConsentFormTemplate) {
        ConsentFormTemplateService.list({params:{"is-active":"yes"}}).then(
          function(response) {
            $scope.consent_form_templates = response.data;
            if (notify) {
              NotificationService.success('Loaded Consent Form Templates');
            }
            // $scope.renderModal(0);
          },
          function(response) {
            $scope.consent_form_templates = [];
            NotificationService.error('Error Loading Consent Form Templates', response);
          }
        );
      }
      if ($scope.CanListConsentForm) {
        ConsentFormService.list({patient_id: $scope.patientId}).then(
          function(response) {
            $scope.consent_forms = response.data;
            // TODO: jjlorenzo clear errored files from $scope.upload_queue
            if (notify) {
              NotificationService.success('Loaded Consent Forms');
            }
            // $scope.previewModal(0);
          },
          function(error) {
            $scope.consent_forms = [];
            NotificationService.error('Error Loading Consent Forms', error);
          }
        );
      }
    };

    $scope.upload = function(file) {
      if (file) {
        $scope.upload_queue.splice(0, 0, file);
        ConsentFormService.create({
          patient: $scope.patientId,
          form_archive: file,
          treatments: $scope.encounter.treatments,
        }).then(
          function(response) {
            $scope.consent_forms.splice(0, 0, response.data);
            file.status = 'completed';
            NotificationService.success('Consent Form uploaded');
            $scope.upload_queue.splice($scope.upload_queue.indexOf(file), 1);
            $scope.needsConsent = false;
            $rootScope.$emit('consent-form-changed');
          },
          function(response) {
            file.status = 'error';
            NotificationService.error('Error Uploading Consent Form', response);
          },
          function(event) {
            file.status = 'progress';
            file.progress = Math.min(100, parseInt(100.0 * event.loaded / event.total));
            file.loaded = event.loaded;
          }
        );
      }
    };

    $scope.previewTemplateModal = function(index) {
      $uibModal.open({
        controller: function($sce, $scope, consent_form_template) {
          $scope.consent_form_template = consent_form_template;
          $scope.loading = false;
          $scope.form_archive_url = $sce.trustAsResourceUrl($scope.consent_form_template.form_archive);
          $scope.download = function() {
            return ConsentFormTemplateService.download(consent_form_template.id).then(
              function(response) {
                var link = angular.element('<a/>');
                link.attr('href', response.data.form_archive);
                link.attr('download', '');
                link.attr('target', '_blank');
                link[0].click();
              },
              function(response) {
                NotificationService.error('Error Downloading Consent Form Template', response);
              }
            );
          };
        },
        scope: $scope,
        templateUrl: '#ConsentFormTemplatePreviewModal',
        resolve:{
          consent_form_template: function() {
            return ConsentFormTemplateService.retrieve($scope.consent_form_templates[index].id).then(
              function(response) {
                return response.data;
              },
              function(response) {
                NotificationService.error('Error Retrieving Consent Form Template', response);
                return {};
              }
            );
          },
        },
      });
    };

    $scope.previewModal = function(index) {
      $uibModal.open({
        controller: function($sce, $scope, CanUpdateConsentForm, consent_form) {
          $scope.CanUpdateConsentForm = CanUpdateConsentForm;
          $scope.consent_form = consent_form;
          $scope.loading = false;
          $scope.has_context = !angular.equals(consent_form.form_context, {});
          $scope.form_archive_url = $sce.trustAsResourceUrl($scope.consent_form.form_archive);
          $scope.template = function(id) {
            $scope.previewTemplateModal(
              _.findIndex($scope.consent_form_templates, function(obj) { return obj.id === id; })
            );
          };
          $scope.download = function() {
            return ConsentFormService.download(consent_form.id).then(
              function(response) {
                var link = angular.element('<a/>');
                link.attr('href', response.data.form_archive);
                link.attr('download', '');
                link.attr('target', '_blank');
                link[0].click();
              },
              function(response) {
                NotificationService.error('Error Downloading Consent Form', response);
              }
            );
          };
          $scope.update = function() {
            $scope.loading = true;
            return ConsentFormService.update(consent_form).then(
              function(response) {
                $scope.loading = false;
                $scope.consent_forms[index].name = response.data.name;
                NotificationService.success('Successfully updated Consent Form');
                $scope.$close();
              },
              function(response) {
                $scope.loading = false;
                NotificationService.error('Error Updating Consent Form', response);
              }
            );
          };
        },
        scope: $scope,
        templateUrl: '#ConsentFormPreviewModal',
        resolve:{
          CanUpdateConsentForm: CanUpdateConsentForm,
          consent_form: function() {
            return ConsentFormService.retrieve($scope.consent_forms[index].id).then(
              function(response) {
                return response.data;
              },
              function(response) {
                NotificationService.error('Error Retrieving Consent Form', response);
                return {};
              }
            );
          },
        },
      });
    };

    $scope.removeModal = function(index) {
      $uibModal.open({
        controller: function($scope) {
          $scope.loading = false;
          $scope.remove = function() {
            $scope.loading = true;
            return ConsentFormService.destroy($scope.consent_forms[index].id).then(
              function() {
                $scope.loading = false;
                $scope.consent_forms.splice(index, 1);
                $rootScope.$emit('consent-form-changed');
                NotificationService.success('Successfully removed Consent Form');
                $scope.$close();
              },
              function(response) {
                $scope.loading = false;
                NotificationService.error('Error Removing Consent Form', response);
              }
            );
          };
        },
        scope: $scope,
        templateUrl: '#ConsentFormRemoveModal',
      });
    };

    $scope.renderModal = function(index) {
      $uibModal.open({
        controller: function($sce, $scope, consent_form_template) {
          $scope.consent_form_template = consent_form_template;
          $scope.loading = false;
          $scope.consent_form = {
            name: consent_form_template.name,
            form_template: consent_form_template.id,
            form_context: {},
          };
          $scope.render = function() {
            $scope.loading = true;
            var data = angular.extend({}, $scope.consent_form, {
              patient: $scope.patientId,
              treatments: $scope.encounter.treatments,
            });
            return ConsentFormService.create(data).then(
              function(response) {
                $scope.loading = false;
                $scope.consent_forms.splice(0, 0, response.data);
                $rootScope.$emit('consent-form-changed');
                NotificationService.success('Successfully created Consent Form');
                $scope.$close();
              },
              function(response) {
                $scope.loading = false;
                NotificationService.error('Error Creating Consent Form', response);
              }
            );
          };
        },
        scope: $scope,
        templateUrl: '#ConsentFormRenderModal',
        resolve:{
          consent_form_template: function() {
            return ConsentFormTemplateService.retrieve($scope.consent_form_templates[index].id).then(
              function(response) {
                return response.data;
              },
              function(response) {
                NotificationService.error('Error Retrieving Consent Form Template', response);
                return {};
              }
            );
          },
        },
      });
    };

    $scope.load();

  }

})();

/*global _ */
/*global pdfMake */
(function() {
  'use strict';

  function patientDetailBillingCtrl($scope, BillingService, AuthenticationService,
    PrincipalService, $window, $uibModal, $filter, $transition$, $q, PatientsService, $state, NotificationService,
                                    TextTemplateService, LocationsService, CanUpdateBilling,CanRetrieveInvoice,
                                    CanCreateInvoice,CanRetrieveEncounter/*,TextFormattersService, $timeout*/)
  {
    /*

      CONTAINMENT:
      1. if $scope.invoiceId then this controller is hosted in an BillingInvoicesDetailCtrl and should only show ar related to the invoice.
      2. $scope.mode = 'BILLING' tells hosted PatientDetailTreatmentPlansCtrl to display in billing mode.

    */

    $scope.loadingJeds = false
    $scope.animationsEnabled = false;

    $scope.patientId = $transition$.params().patientid;

	  $scope.mode = 'BILLING';
    $scope.enablePaymentButton = false;
    $scope.enablePrintStatementButton = false;
    $scope.CanUpdateBilling = CanUpdateBilling;
    $scope.CanRetrieveInvoice = CanRetrieveInvoice;
    $scope.CanCreateInvoice = CanCreateInvoice;
    $scope.CanRetrieveEncounter = CanRetrieveEncounter;

    var payments = {};

    $scope.triggerBuildStatement = function() {
      if ($scope.totalPaymentLineitems) {
        $scope.displayJeds = $scope.jeds;
        $scope.totalPaymentLineitems = false;
      }
      $scope.buildStatement = true; 
    };

    $scope.$watch('totalPaymentLineitems', function(newVal, oldVal) {
      if (newVal !==oldVal && !$scope.buildStatement) {
        if (newVal) {
          $scope.displayJeds = $scope.jedsTotalPaymentLineitems;
        } else {
          $scope.displayJeds = $scope.jeds;
        }
      }
    });

    if ($scope.mode === 'BILLING') {

       //function for child patient-detail-treatments to call to make its treatments
      // available to this controller so newInvoice can determine which treatments
      // have been selected for invoicing.
      $scope.setTreatmentsForInvoicing = function(treatmentsForInvoicing, treatmentsForNewInvoiceToInvoiceProperty) {
        $scope.newInvoiceClicked = function() {
          var treatmentsToInvoice = [];
          for (var q=0; q<treatmentsForInvoicing.length; q++ ) {
            if (treatmentsForInvoicing[q][treatmentsForNewInvoiceToInvoiceProperty]) {
              treatmentsToInvoice.push(treatmentsForInvoicing[q].id);
            }
          }
          $state.go('invoicedetail', {invoiceid:null, treatmentsToInvoice: treatmentsToInvoice});
        };

        $scope.treatmentsForInvoicing = treatmentsForInvoicing;
        var watchList = [];
        for (var r=0; r<treatmentsForInvoicing.length; r++) {
          watchList.push('treatmentsForInvoicing[' + r +'].includeInInvoice');
        }
        $scope.$watchGroup(watchList, function(newVal) {
          if (newVal) {
            $scope.enableNewInvoiceButton = false;
            for (var s=0; s<$scope.treatmentsForInvoicing.length; s++) {
              if ($scope.treatmentsForInvoicing[s].includeInInvoice) {
                 $scope.enableNewInvoiceButton = true;
              }
            }
          }
        });
      };
      $scope.enableNewInvoiceButton = false;
    }

    $scope.lineItemCheckChanged = function() {
      for (var i = 0; i < $scope.jeds.length; i++) {
        if ($scope.jeds[i].includeInPayment) {
          $scope.enablePaymentButton = true;
          return;
        }
      }
      $scope.enablePaymentButton = false;
    };

    $scope.lineItemStatementCheckChanged = function() {
      for (var i = 0; i < $scope.jeds.length; i++) {
        if ($scope.jeds[i].includeInStatement) {
          $scope.enablePrintStatementButton = true;
          return;
        }
      }
      $scope.enablePrintStatementButton = false;
    };

    $scope.isToday = function(date) {
      date = new Date(date);
      var today = new Date();
      return(
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate()
      );
    };

	  $q.all([
		  PatientsService.get($scope.patientId),
		  AuthenticationService.getPracticeEx(PrincipalService.getPracticeId()),
      TextTemplateService.getTextTemplateForPractice('Billing Statement Text'),
      LocationsService.all()
	  ])
	  .then(function(responses) {
		  $scope.patient = responses[0].data;
		  $scope.practice = responses[1].data;
      $scope.billingStatementText = responses[2].data;
      $scope.locations = responses[3].data;
		  reset();
	  }, function(err) {
        NotificationService.error('Error loading  patient or practice information',err);
	  });

    var includeInStatementWatchDeregistrationFunc = null;

  	function reset() {
		  var now = new Date();
		  now.setTime(now.getTime() + (now.getTimezoneOffset() * 60000));

      $scope.loadingJeds = true
		  $q.all([
			  BillingService.getJournalEntriesForAccount($scope.patient.ar_gl_account),
			  BillingService.getArJournalEntryDetailsForAccount($scope.patient.ar_gl_account)
		  ])
		  .then(function(responses) {
        $scope.loadingJeds = false
			  var jes = angular.fromJson(responses[0].data);
			  var arjeds = angular.fromJson(responses[1].data);

        /*
			    Saves a list of journal entry details in $scope.jeds associated with the account with jeds
            that are arjeds 'inverted' to contain an additional property 'arjed' containing AR information:
			        (1) retrieves all jes and arjeds,
			        (2) pulls out the jed from each je that is associated with the account
              (3) removes jeds that are arjeds,
              (4) inverts the arjeds so that they are jeds containing the AR part,
              (5) combines jeds with the inverted arjeds
              to produce a list of jeds, some which contain AR information, for display.
        */

        //find the child jeds that corresponds to the patient's account for each je
			  var jeds = [];
			  for (var i = 0; i < jes.length; i++) {
				  var jeds_ = jes[i].journal_entry_details;
				  for (var j = 0; j < jeds_.length; j++) {
					  if (jeds_[j].gl_account === $scope.patient.ar_gl_account) {
						  jeds.push(jeds_[j]);
					  }
				  }
			  }

        //put each arjed with its corresponding jed.
        var jedsToList = [];
        var arjedsDict = {};
        payments = {};
			  for (var l = 0; l < arjeds.length; l++) {
          if (arjeds[l].type === 'payment' && arjeds[l].reference_number) {
            if (arjeds[l].reference_number in payments) {
              payments[arjeds[l].reference_number].total = BillingService.getAmountToFixed(parseFloat(payments[arjeds[l].reference_number].total) + parseFloat(arjeds[l].journal_entry_detail.amount));
            } else {
              payments[arjeds[l].reference_number] = {
                total: arjeds[l].journal_entry_detail.amount
              };
            }
          }
				  arjedsDict[arjeds[l].journal_entry_detail.id] = arjeds[l];
			  }
			  for (var k = 0; k < jeds.length; k++) {
          if (jeds[k].id in arjedsDict && arjedsDict[jeds[k].id].type === 'payment' && arjedsDict[jeds[k].id].reference_number && arjedsDict[jeds[k].id].reference_number in payments) {
            arjedsDict[jeds[k].id].payment = payments[arjedsDict[jeds[k].id].reference_number];
          }
          if (!$scope.invoiceId) {
            jedsToList.push(jeds[k]);
				    if (jeds[k].id in arjedsDict) {
					    jeds[k].arjed = arjedsDict[jeds[k].id];
				    }
          } else {
				    if (
              (jeds[k].id in arjedsDict) &&
              (arjedsDict[jeds[k].id].invoice) &&
              (arjedsDict[jeds[k].id].invoice.id.toString() === $scope.invoiceId)
            ) {
              jedsToList.push(jeds[k]);
					    jeds[k].arjed = arjedsDict[jeds[k].id];
            }
          }
			  }
			  $scope.jeds = jedsToList;

        var watchList = [];
        for (var r=0; r<$scope.jeds.length; r++) {
          watchList.push('jeds[' + r +'].includeInStatement');
        }
        if (includeInStatementWatchDeregistrationFunc) {
          includeInStatementWatchDeregistrationFunc();
        }        
        includeInStatementWatchDeregistrationFunc = $scope.$watchGroup(watchList, function(newVal) {
          if (newVal) {
            $scope.statementTotal = 0.00;
            $scope.enablePrintStatementButton = false;
            for (var s=0; s<$scope.jeds.length; s++) {
              if ($scope.jeds[s].includeInStatement) {
                $scope.statementTotal += parseFloat($scope.jeds[s].amount);
                $scope.enablePrintStatementButton = true;
              }
            }
            $scope.statementTotal = BillingService.getAmountToFixed($scope.statementTotal);
          }
        });

        //group jeds into a dictionary of invoiceid to object with jeds and total amount of jeds as invoice balance.
		    var invoices = {};
		    for (var p = 0; p < $scope.jeds.length; p++) {
			    if ($scope.jeds[p].arjed && $scope.jeds[p].arjed.invoice) {
				    if (!invoices[$scope.jeds[p].arjed.invoice.id]) {
					    invoices[$scope.jeds[p].arjed.invoice.id] = {};
					    invoices[$scope.jeds[p].arjed.invoice.id].jeds = [];
  				    invoices[$scope.jeds[p].arjed.invoice.id].balance = 0.00;
              invoices[$scope.jeds[p].arjed.invoice.id].total = 0.00;
              invoices[$scope.jeds[p].arjed.invoice.id].invoice = $scope.jeds[p].arjed.invoice;
				    }
				    invoices[$scope.jeds[p].arjed.invoice.id].jeds.push($scope.jeds[p]);
				    invoices[$scope.jeds[p].arjed.invoice.id].balance += parseFloat($scope.jeds[p].amount);
            if ( //invoices only include types of 'invoicelineitem' so only include these in invoice total.
              $scope.jeds[p].arjed.type.indexOf(BillingService.AR_TYPE.INVOICELINEITEM) !== -1
            ) {
              invoices[$scope.jeds[p].arjed.invoice.id].total += parseFloat($scope.jeds[p].amount);
            }
			    }

          //set the lineitem type display
          if (!$scope.jeds[p].arjed) {
            $scope.jeds[p].type = 'Journal Entry';
          } else {
            $scope.jeds[p].type = BillingService.getArTypeDisplayString($scope.jeds[p].arjed.type);
          }
		    }

			  var nowMillis  = new Date().getTime();
        var millisPerDay = 24*60*60*1000;

        //put summary properties into each jeds so each line item can show info on the invoice, including 'invoicePastDueDays', 'invoiceTotal', and 'paymentsTotal' and 'remaining' for those of type 'invoicelineitem'
		    for (var key in invoices) {
			    if (invoices.hasOwnProperty(key)) {
            invoices[key].balance = BillingService.getAmountToFixed(invoices[key].balance);
				    for (var q = 0; q < invoices[key].jeds.length; q++) {
              var invoiceDateMillis = new Date(invoices[key].invoice.invoice_date).getTime();
              invoices[key].jeds[q].arjed.invoicePastDueDays =
                Math.floor((nowMillis - invoiceDateMillis) / millisPerDay);
					    invoices[key].jeds[q].arjed.invoiceTotal = BillingService.getAmountToFixed(invoices[key].total);
					    invoices[key].jeds[q].arjed.invoiceBalance =invoices[key].balance;
              if (
                invoices[key].jeds[q].arjed.type.indexOf(BillingService.AR_TYPE.INVOICELINEITEM) !== -1
              ) {
                invoices[key].jeds[q].arjed.paymentsTotal = 0.00;
                for (var w=0; w<invoices[key].jeds[q].arjed.payments.length; w++) {
                  invoices[key].jeds[q].arjed.paymentsTotal += parseFloat(_.find(invoices[key].jeds, function(jed) {
                    return jed.id === invoices[key].jeds[q].arjed.payments[w];
                  }).amount);
                }
                invoices[key].jeds[q].arjed.paymentsTotal = BillingService.getAmountToFixed(-invoices[key].jeds[q].arjed.paymentsTotal);

                invoices[key].jeds[q].arjed.writeoffsTotal = 0.00;
                for (var x=0; x<invoices[key].jeds[q].arjed.writeoffs.length; x++) {
                  invoices[key].jeds[q].arjed.writeoffsTotal += parseFloat(_.find(invoices[key].jeds, function(jed) {
                    return jed.id === invoices[key].jeds[q].arjed.writeoffs[x];
                  }).amount);
                }
                invoices[key].jeds[q].arjed.writeoffsTotal = BillingService.getAmountToFixed(-invoices[key].jeds[q].arjed.writeoffsTotal);

                invoices[key].jeds[q].arjed.chargebacksTotal = 0.00;
                for (var t=0; t<invoices[key].jeds[q].arjed.chargebacks.length; t++) {
                  invoices[key].jeds[q].arjed.chargebacksTotal += parseFloat(_.find(invoices[key].jeds, function(jed) {
                    return jed.id === invoices[key].jeds[q].arjed.chargebacks[t];
                  }).amount);
                }
                invoices[key].jeds[q].arjed.chargebacksTotal = BillingService.getAmountToFixed(-invoices[key].jeds[q].arjed.chargebacksTotal);

                invoices[key].jeds[q].arjed.remaining = -parseFloat(invoices[key].jeds[q].arjed.paymentsTotal) - parseFloat(invoices[key].jeds[q].arjed.writeoffsTotal) - parseFloat(invoices[key].jeds[q].arjed.chargebacksTotal) + parseFloat(invoices[key].jeds[q].amount);

                if (
                  invoices[key].jeds[q].arjed.remaining !== 0.00 &&
                  invoices[key].jeds[q].arjed.invoiceBalance !== '0.00'
                ) {
                  invoices[key].jeds[q].includeInPayment = true;
                } else {
                  invoices[key].jeds[q].includeInPayment = false;
                }
              }
				    }
			    }
		    }
        $scope.lineItemCheckChanged();
        if (!$scope.invoiceId) {
          BillingService.getAccountBalanceThrough($scope.patient.ar_gl_account, now).then(function(response)
          {
			      $scope.accountBalance = response.data;
            if (!$scope.accountBalance.totalAmount) {
                $scope.accountBalance.totalAmount = 0.00;
            }
            $scope.accountBalance.totalAmount = BillingService.getAmountToFixed($scope.accountBalance.totalAmount);
			      $scope.jeds = $filter('orderBy')($scope.jeds, 'created', true);
		      }, function(err) {
              NotificationService.error('Error obtaining account balance',err);
		      });
        } else {
          $scope.accountBalance = {};
          $scope.accountBalance.totalAmount = invoices[$scope.invoiceId].balance;
			    $scope.jeds = $filter('orderBy')($scope.jeds, 'created', true);
        }
        // $scope.buildStatement = true
        // $timeout(
        //   function() {
        //     $scope.printStatement(
        //       $scope.totalPaymentLineitems,
        //       $scope.accountBalance.totalAmount,
        //       $scope.invoiceId ? $scope.invoice.invoice_number : null,
        //       // null,
        //     )
        //   },
        //   1
        // )
        var paymentReferenceNumberSeen = {};
        $scope.jedsTotalPaymentLineitems = $scope.jeds.filter(function(jed) {
          if (!jed.arjed || (jed.arjed.type !== 'payment')) {
            return true;
          }
          var key = jed.arjed.reference_number;
          return paymentReferenceNumberSeen.hasOwnProperty(key) ? false : (paymentReferenceNumberSeen[key] = true);
        });
        $scope.displayJeds = $scope.jeds;
		  }, function(err) {
          $scope.loadingJeds = false
          NotificationService.error('Error loading account journal entries',err);
		  });
  	}

	  $scope.printInvoice = function(invoiceId) {
		  $window.open('/api/v1/invoices/' + invoiceId + '/print/', '_blank');
	  };

    $scope.printStatement = function(totalPaymentLineitems, balance, invoiceNumber) {
      var total = 0.00;
      var totalPastDue30_60 = 0.00;
      var totalPastDue60_90 = 0.00;
      var totalPastDue90_120 = 0.00;
      var totalPastDue120_ = 0.00;
      var lineItems = [];
      if (totalPaymentLineitems) {
        for (var key in payments) {
          if (payments.hasOwnProperty(key)) {
            payments[key].statementTotal = "0.00";
          }
        }
        for (var x=0; x<$scope.jeds.length; x++) {
          if (
            $scope.jeds[x].includeInStatement &&
            $scope.jeds[x].arjed.type === 'payment' &&
            $scope.jeds[x].arjed &&
            $scope.jeds[x].arjed.reference_number &&
            $scope.jeds[x].arjed.payment
          ) {
            payments[$scope.jeds[x].arjed.reference_number].statementTotal = (payments[$scope.jeds[x].arjed.reference_number].statementTotal) ? BillingService.getAmountToFixed(parseFloat(payments[$scope.jeds[x].arjed.reference_number].statementTotal) + parseFloat($scope.jeds[x].amount)) : $scope.jeds[x].amount;
          }
        }
      }
      var paymentReferenceNumbers = [];
      for (var t=0; t<$scope.jeds.length; t++) {
        if ($scope.jeds[t].includeInStatement) {
          total += parseFloat($scope.jeds[t].amount);

          if (
            $scope.jeds[t].arjed &&
            $scope.jeds[t].arjed.invoice
          ) {
            if ($scope.jeds[t].arjed.invoicePastDueDays && $scope.jeds[t].arjed.invoicePastDueDays >= 30 && $scope.jeds[t].arjed.invoicePastDueDays <60 && $scope.jeds[t].arjed.invoiceBalance > 0.00) {
              totalPastDue30_60 += parseFloat($scope.jeds[t].amount);
            } else if ($scope.jeds[t].arjed.invoicePastDueDays && $scope.jeds[t].arjed.invoicePastDueDays >= 60 && $scope.jeds[t].arjed.invoicePastDueDays < 90 && $scope.jeds[t].arjed.invoiceBalance > 0.00) {
              totalPastDue60_90 += parseFloat($scope.jeds[t].amount);
            } else if ($scope.jeds[t].arjed.invoicePastDueDays && $scope.jeds[t].arjed.invoicePastDueDays >= 90 && $scope.jeds[t].arjed.invoicePastDueDays < 120 && $scope.jeds[t].arjed.invoiceBalance > 0.00) {
              totalPastDue90_120 += parseFloat($scope.jeds[t].amount);
            } else if ($scope.jeds[t].arjed.invoicePastDueDays && $scope.jeds[t].arjed.invoicePastDueDays >= 120 && $scope.jeds[t].arjed.invoiceBalance > 0.00) {
              totalPastDue120_ += parseFloat($scope.jeds[t].amount);
            }
          }

          var desc = '';
          var id = '';
          var amount = '0.00';
          if (
            $scope.jeds[t].arjed &&
            $scope.jeds[t].arjed.type === 'payment' &&
            $scope.jeds[t].arjed.reference_number &&
            $scope.jeds[t].arjed.payment &&
            !totalPaymentLineitems
          ) {
            desc = 'Portion of ' + $scope.jeds[t].description + ' ' + $scope.jeds[t].arjed.reference_number + ' totaling ' +  $scope.jeds[t].arjed.payment.total + ' ' + $scope.practice.currency + ' applied to #' + $scope.jeds[t].arjed.payment_for;
            id = $scope.jeds[t].id;
            amount = $scope.jeds[t].amount;
          } else if (
            $scope.jeds[t].arjed &&
            $scope.jeds[t].arjed.type === 'payment' &&
            $scope.jeds[t].arjed.reference_number &&
            paymentReferenceNumbers.indexOf($scope.jeds[t].arjed.reference_number) === -1 &&
            $scope.jeds[t].arjed.payment &&
            totalPaymentLineitems
          ) {
            desc = $scope.jeds[t].description + ' ' + $scope.jeds[t].arjed.reference_number + ' totaling ' +  $scope.jeds[t].arjed.payment.total + ' ' + $scope.practice.currency;
            id = '';
            amount = $scope.jeds[t].arjed.payment.statementTotal;
            paymentReferenceNumbers.push($scope.jeds[t].arjed.reference_number);
          } else if (
            $scope.jeds[t].arjed &&
            $scope.jeds[t].arjed.type === 'payment' &&
            $scope.jeds[t].arjed.reference_number &&
            paymentReferenceNumbers.indexOf($scope.jeds[t].arjed.reference_number) > -1 &&
            $scope.jeds[t].arjed.payment &&
            totalPaymentLineitems
          ) {
            continue;
          } else {
            desc = $scope.jeds[t].description;
            id = $scope.jeds[t].id;
            amount = $scope.jeds[t].amount;
          }

          var lineItem = [
            {
              style: { alignment: "center" },
              text: $filter("date")($scope.jeds[t].created, "shortDate"),
            },
            {
              style: { },
              text: id,
            },
            {
              style: { },
              text: desc,
            },
            {
              style: { alignment: "right" },
              text: amount,
            },
          ];
          lineItems.unshift(lineItem);
        }
      }
      lineItems.unshift([
        {
          style: { alignment: "center", bold: true },
          text: "Recorded",
        },
        {
          style: { bold: true },
          text: "#",
        },
        {
          style: { bold: true },
          text: "Description",
        },
        {
          style: { alignment: "right", bold: true },
          text: "Amount",
        },
      ]);
      total = BillingService.getAmountToFixed(total);
      lineItems.push([
        {
          colSpan: 3,
          style: { alignment: "right", bold: true, fontSize: 10 },
          text: total === "0.00" ? "Items total:" : "Items total. Please pay now:",
          fillColor: "gray",
          color: "white",
        },
        {},
        {},
        {
          style: { alignment: "right", bold: true, fontSize: 10 },
          text: total,
          fillColor: "gray",
          color: "white",
        },
      ]);
      totalPastDue30_60 = BillingService.getAmountToFixed(totalPastDue30_60);
      totalPastDue60_90 = BillingService.getAmountToFixed(totalPastDue60_90);
      totalPastDue90_120 = BillingService.getAmountToFixed(totalPastDue90_120);
      totalPastDue120_ = BillingService.getAmountToFixed(totalPastDue120_);

      var practice = {
        name: $scope.practice.name,
        phone: $scope.practice.phone,
        picture: $scope.practice.pictureDataURL || [
          "data:image/png;base64",
          "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
        ].join(","),
        street1: "",
        street2: "",
        city: "",
        state: "",
        zip_code: "",
        currency: $scope.practice.currency,
      }
      var location = $scope.locations.find(function(elem) { return elem.is_billing_location === true })
      if (location) {
        angular.extend(practice, {
          street1: location.street1,
          street2: location.street2,
          city: location.city,
          state: location.state,
          zip_code: location.zip_code,
        })
      }
      var patient = {
        id: $scope.patient.id,
        name: [$scope.patient.first_name, $scope.patient.last_name].join(" "),
        street1: $scope.patient.patient_address_street_1,
        street2: $scope.patient.patient_address_street_2,
        city: $scope.patient.patient_address_city,
        state: $scope.patient.patient_address_state,
        zip_code: $scope.patient.patient_address_zip_code,
      }
      var responsible = $scope.patient.responsible_party === "self" ? patient : {
        name: [$scope.patient.responsible_first_name, $scope.patient.responsible_last_name].join(" "),
        street1: $scope.patient.responsible_address_street_1,
        street2: $scope.patient.responsible_address_street_2,
        city: $scope.patient.responsible_address_city,
        state: $scope.patient.responsible_address_state,
        zip_code: $scope.patient.responsible_address_zip_code,
      }

      var docDefinition = {
        content: [
          {
            layout: {
              hLineWidth: function() { return 0 },
              vLineWidth: function() { return 0 },
              paddingLeft: function() { return 0 },
              paddingTop: function() { return 0 },
              paddingRight: function() { return 0 },
              paddingBottom: function() { return 0 },
            },
            table: {
              body: [
                [
                  {
                    margin: [ 6, practice.street2 ? 7 : 14, 0, 0 ],
                    stack: [
                      {
                        maxHeight: 12,
                        style: { bold: true },
                        text: practice.name,
                      },
                      {
                        maxHeight: 12,
                        text: practice.street1,
                      },
                      {
                        maxHeight: 12,
                        text: practice.street2,
                      },
                      {
                        maxHeight: 12,
                        text: [
                          practice.city,
                          ", ",
                          practice.state,
                          ", ",
                          practice.zip_code,
                        ],
                      },
                    ],
                  },
                  "",
                  {
                    alignment: "right",
                    fit: [
                      3/1 * 72,
                      8/8 * 72
                    ],
                    image: practice.picture,
                    rowSpan: 2,
                  },
                ],
                [
                  "",
                  "",
                  {},
                ],
                [
                  {
                    colSpan: 2,
                    margin: [ 6, responsible.street2 ? 12 : 18, 0, 0 ],
                    stack: [
                      {
                        maxHeight: 12,
                        style: { bold: true },
                        text: responsible.name,
                      },
                      {
                        maxHeight: 12,
                        text: responsible.street1,
                      },
                      {
                        maxHeight: 12,
                        text: responsible.street2,
                      },
                      {
                        maxHeight: 12,
                        text: [
                          responsible.city,
                          ", ",
                          responsible.state,
                          ", ",
                          responsible.zip_code,
                        ],
                      },
                    ],
                  },
                  {},
                  {
                    stack: [
                      {
                        columns: [
                          {
                            alignment: "left",
                            margin: [4, 0, 0, 0],
                            stack: invoiceNumber ? [
                              {
                                text: "Invoice:",
                                style: { bold: true },
                              },
                              invoiceNumber,
                            ] : [],
                            width: "*",
                          },
                          {
                            alignment: "right",
                            margin: [4, 0, 0, 0],
                            stack: [
                              {
                                text: invoiceNumber ? "Balance:" : "Patient Balance:",
                                style: { bold: true },
                              },
                              {
                                text: [
                                  balance,
                                  " ",
                                  practice.currency,
                                ]
                              },
                              {
                                text: "As of:",
                                margin: [0, 4, 0, 0],
                                style: { bold: true },
                              },
                              {
                                text: $filter("date")(new Date(), "medium")
                              },
                            ],
                            style: { noWrap: true },
                            width: "auto",
                          },
                        ]
                      },
                      {
                        alignment: "right",
                        fontSize: 7,
                        margin: [0, 4, 0, 0],
                        style: { italics: true },
                        text: [
                          "This balance may differ from the items total if it includes a pending balance not yet ",
                          "due or insurance claims that have not been fully processed."
                        ],
                      },
                    ],
                  },
                ],
              ],
              heights: [
                7/8 * 72,
                3/8 * 72,
                1/1 * 72,
              ],
              widths: [
                3 * 72 + 1/4 * 72,
                3/4 * 72,
                "*",
              ],
            }
          },
          {
            margin: [0, 3/4 * 72, 0, 0],
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 7 * 72 + 1/4 * 72,
                y2: 0,
                lineColor: "#666",
                dash: {
                  length: 5,
                  space: 10,
                }
              },
            ],
          },
          {
            margin: [0, 10, 0, 0],
            text: [
              {
                style: { bold: true },
                text: "For Patient: ",
              },
              {
                text: patient.name,
              }
            ],
          },
          {
            layout: {
              fillColor: function(i, node) {
                if (i === 0 || i === node.table.body.length - 1) {
                  return null
                }
                return (i % 2 === 0) ? "#f2f2f2" : null
              },
              hLineWidth: function(i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return 0
                }
                return (i === node.table.headerRows || i === node.table.body.length - 1) ? 1 : 0
              },
              vLineWidth: function() {
                return 0;
              }
            },
            margin: [0, 5, 0, 0],
            table: {
              body: lineItems,
              headerRows: 1,
              widths: [
                "auto",
                "auto",
                "*",
                "auto",
              ],
            }
          },
          {
            layout: {
              hLineWidth: function(i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return 0;
                }
                return 1;
              },
              vLineWidth: function() {
                return 0;
              }
            },
            margin: [0, 20, 0, 0],
            table: {
              headerRows: 1,
              body: [
                [
                  {
                    style: { alignment: "center", bold: true },
                    text: ">30 Days",
                  },
                  {
                    style: { alignment: "center", bold: true },
                    text: ">60 Days",
                  },
                  {
                    style: { alignment: "center", bold: true },
                    text: ">90 Days",
                  },
                  {
                    style: { alignment: "center", bold: true },
                    text: "120+ Days",
                  }
                ],
                [
                  {
                    style: { alignment: "center" },
                    text: totalPastDue30_60,
                  },
                  {
                    style: { alignment: "center" },
                    text: totalPastDue60_90,
                  },
                  {
                    style: { alignment: "center" },
                    text: totalPastDue90_120,
                  },
                  {
                    style: { alignment: "center" },
                    text: totalPastDue120_,
                  }
                ],
              ],
              widths: [
                "*",
                "*",
                "*",
                "*",
              ],
            },
          },
          {
            margin: [0, 20, 0, 0],
            text: $scope.billingStatementText.content,
            unbreakable: true,
          }
        ],
        defaultStyle: {
          fontSize: 10,
        },
        footer: function (currentPage, pageCount) {
          return {
            alignment: "center",
            text: [
              practice.name,
              "  —  ",
              $filter("tel")(practice.phone),
              "  —  ",
              patient.name,
              "  —  ",
              "Patient ID: ",
              patient.id,
              "  —  ",
              {
                text: ["Page ", currentPage.toString(), " of ", pageCount],
              }
            ],
          }
        },
        pageMargins: [
          5/8 * 72,
          7/8 * 72,
        ],
        pageSize: "LETTER",
      }
      pdfMake.createPdf(docDefinition).open();
      // pdfMake.createPdf(docDefinition).getBlob(function(blob){
      //   $scope.$applyAsync(function() {
      //     $scope.pdfurl = URL.createObjectURL(blob)
      //   })
      // })
    }

/* Replaced by client side pdfmake approach above. Keeping this code in case it needs
to be moved to server

	  $scope.printStatement = function() {
      var queryString = '';
      for (var t=0; t<$scope.jeds.length; t++) {
        if ($scope.jeds[t].includeInStatement) {
          if (queryString.length !== 0) {
            queryString += '&';
          }
          queryString = queryString + 'jed=' + $scope.jeds[t].id;
        }
      }

		  $window.open('/api/v1/statement/practices/' + PrincipalService.getPracticeId() + '/print/?' + queryString, '_blank');
	  };
*/
    function createJournalEntry(journalEntry) {
      BillingService.createJournalEntryForPractice(PrincipalService.getPracticeId(), journalEntry).then(function() {
        NotificationService.success('Successfully applied new entry.');
        reset();
      }, function(err) {
        NotificationService.error('Error creating new entry',err);
      });
    }

    /* ADD PAYMENT
      When submitted to back end it results in:

                      je
          |                           |
        jed                           jed
          amount= -pmtamount            amount=paymentamount
          gl_account=patientAR          gl_account=cash
          |
        arjed
          type= payment
    */
    $scope.openAddPaymentModal = function (title, description, currency, merchantId, fromArGlAccountId,
      cashGlAccountId, jeds, showUnassociatedAmount, animationsEnabled)
    {
      showUnassociatedAmount = typeof showUnassociatedAmount !== 'undefined' ? showUnassociatedAmount : true;

      //find the jeds to pay from all jeds
      var jedsWithArjedToPay = [];
      for (var j=0; j<jeds.length; j++) {
        if (
          jeds[j].includeInPayment
        ) {
          if (jeds[j].arjed.remaining) {
            jeds[j].arjed.paymentAmount = BillingService.getAmountToFixed(jeds[j].arjed.remaining);
          } else {
            jeds[j].arjed.paymentAmount = '0.00';
          }
          jedsWithArjedToPay.push(jeds[j]);
        }
      }
      jedsWithArjedToPay = JSON.parse(JSON.stringify(jedsWithArjedToPay)); //deepcopy

      var modalInstance = $uibModal.open({
        animation: animationsEnabled,
        templateUrl: 'addPayment.html',
        controller: 'addPaymentModalInstanceCtrl',
        size: 'lg',
        resolve: {
          currency: function () {
            return currency;
          },
          description: function () {
            return description;
          },
		      title: function () {
			      return title;
		      },
          merchantId: function () {
            return merchantId;
          },
          jedsWithArjedToPay: function () {
            return jedsWithArjedToPay;
          },
          showUnassociatedAmount: function () {
            return showUnassociatedAmount;
          }
        }
      });

      modalInstance.result.then(function (closeObj) {
        var newJournalEntryInfo = closeObj.newJournalEntryInfo;
        var jedsWithArjedToPay = closeObj.jedsWithArjedToPay;
        var unassociatedAmount = closeObj.unassociatedAmount;

        var journalEntry = BillingService.createJe(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo
        );

        var partnerJed = BillingService.createJed(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo,
          cashGlAccountId,
          newJournalEntryInfo.amount,
          currency
        );

        journalEntry.journal_entry_details.push(partnerJed);

        for (var z=0; z<jedsWithArjedToPay.length; z++) {

          //if (parseFloat(jedsWithArjedToPay[z].arjed.paymentAmount) !== 0.00) {

		        var jed = BillingService.createJed(
              newJournalEntryInfo.description,
              newJournalEntryInfo.memo,
              fromArGlAccountId,
              -jedsWithArjedToPay[z].arjed.paymentAmount,
              currency
            );

		        var arjed = BillingService.createArjed(
              jed,
              BillingService.AR_TYPE.PAYMENT,
              jedsWithArjedToPay[z].arjed.treatment,
              jedsWithArjedToPay[z].arjed.claim,
              jedsWithArjedToPay[z].arjed.invoice,
              jedsWithArjedToPay[z]
            );

            journalEntry.ar_journal_entry_details.push(arjed);
          //}
        }

        if (parseFloat(unassociatedAmount) !== 0.00) {
	        var jedUnassociated = BillingService.createJed(
            newJournalEntryInfo.description,
            newJournalEntryInfo.memo,
            fromArGlAccountId,
            -unassociatedAmount,
            currency
          );

	        var arjedUnassociated = BillingService.createArjed(
            jedUnassociated,
            BillingService.AR_TYPE.PAYMENT
          );

          journalEntry.ar_journal_entry_details.push(arjedUnassociated);
        }

        createJournalEntry(journalEntry);
      }, function () {
      });
    };



    /* ADD JE
      When submitted to back end it results in:

                      je
          |                           |
        jed                           jed
          amount= -amount                amount=amount
          gl_account=fromArGlAccountId   gl_account=toGlAccountId
          |
        arjed
          type= fromArType
    */
    $scope.openAddJEModal = function (
      title,
      description,
      currency,
      fromArGlAccountId,
      fromArType,
      fromArTreatment,
      fromArClaim,
      fromArInvoice,
      toGlAccountId,
      amount,
      paymentFor,
      writeoffFor,
      animationsEnabled,
      helpText)
    {

      var modalInstance = $uibModal.open({
        animation: animationsEnabled,
        templateUrl: 'addJE.html',
        controller: 'addJEModalInstanceCtrl',
        size: 'lg',
        resolve: {
          currency: function () {
            return currency;
          },
          description: function () {
            return description;
          },
		      title: function () {
			      return title;
		      },
		      fromArType: function () {
			      return fromArType;
		      },
		      amount: function () {
			      return amount;
		      },
		      helpText: function () {
			      return helpText;
		      },
        }
      });

      modalInstance.result.then(function (newJournalEntryInfo) {

        var journalEntry = BillingService.createJe(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo
        );

        var toJed = BillingService.createJed(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo,
          toGlAccountId,
          -newJournalEntryInfo.amount,
          currency
        );

        journalEntry.journal_entry_details.push(toJed);

        var fromJed = BillingService.createJed(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo,
          fromArGlAccountId,
          newJournalEntryInfo.amount,
          currency
        );

        var fromArjed = BillingService.createArjed(
          fromJed,
          fromArType,
          fromArTreatment,
          fromArClaim,
          fromArInvoice,
          paymentFor,
          writeoffFor,
          null
        );

        journalEntry.ar_journal_entry_details.push(fromArjed);

        createJournalEntry(journalEntry);
      }, function () {
      });
    };

    /* ADD AR
    */
    $scope.openAddARModal = function (
      title,
      description,
      currency,
      fromArGlAccountId,
      fromArType,
      fromArTreatment,
      fromArClaim,
      fromArInvoice,
      toGlAccountId,
      toArType,
      toArClaim,
      toArInvoice,
      amount,
      animationsEnabled,
      helpText)
    {

      var modalInstance = $uibModal.open({
        animation: animationsEnabled,
        templateUrl: 'addJE.html',
        controller: 'addJEModalInstanceCtrl',
        size: 'lg',
        resolve: {
          currency: function () {
            return currency;
          },
          description: function () {
            return description;
          },
          title: function () {
            return title;
          },
          fromArType: function () {
            return fromArType;
          },
          amount: function () {
            return amount;
          },
          helpText: function () {
            return helpText;
          },
        }
      });

      modalInstance.result.then(function (newJournalEntryInfo) {

        var journalEntry = BillingService.createJe(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo
        );

        var toJed = BillingService.createJed(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo,
          toGlAccountId,
          newJournalEntryInfo.amount,
          currency
        );

        var toArjed = BillingService.createArjed(
          toJed,
          toArType,
          fromArTreatment,
          toArClaim,
          toArInvoice,
          null,
          null,
          null
        );

        journalEntry.ar_journal_entry_details.push(toArjed);

        var fromJed = BillingService.createJed(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo,
          fromArGlAccountId,
          -newJournalEntryInfo.amount,
          currency
        );

        var fromArjed = BillingService.createArjed(
          fromJed,
          fromArType,
          fromArTreatment,
          fromArClaim,
          fromArInvoice,
          null,
          null,
          null
        );

        journalEntry.ar_journal_entry_details.push(fromArjed);

        createJournalEntry(journalEntry);
      }, function () {
      });
    };

  }

/* in patient-detail-insurance.js
  function addPaymentModalInstanceCtrl($scope, $uibModalInstance, currency, description, title, merchantId, jedsWithArjedToPay, showUnassociatedAmount) {
    $scope.showUnassociatedAmount = showUnassociatedAmount;

    var ENUM__SALE   = 'SALE';
    var ENUM__CREDIT = 'CREDIT';
    //var ENUM__CREDITCARD_VOID   = 'CREDITCARD_VOID';
    var BUTTONDISPLAY__SALE   = 'Charge / EBT';
    var BUTTONDISPLAY__CREDIT = 'Refund';
    //var BUTTONDISPLAY__CREDITCARD_VOID   = 'Void Current Transaction';
    $scope.AMOUNT_REGEX = /^((-?)(\d+)(\.\d{2}))$/;

    $scope.jedsWithArjedToPay = jedsWithArjedToPay;
    var watchList = [];
    for (var q=0; q<$scope.jedsWithArjedToPay.length; q++) {
      $scope.jedsWithArjedToPay.index = q;
      watchList.push('jedsWithArjedToPay[' + q +'].arjed.paymentAmount');
    }
    watchList.push('unassociatedAmount');

    $scope.newJournalEntryInfo = {};
	  $scope.newJournalEntryInfo.description = description;
    $scope.unassociatedAmount = '0.00';

    $scope.currency = currency;
    $scope.title = title;
    $scope.merchantId = merchantId;
    $scope.control = {};

    $scope.transactionType = ENUM__SALE;
    $scope.transactionTypeDisplay = BUTTONDISPLAY__SALE;

    $scope.$watchGroup(watchList, function(newVal) {
      if (newVal) {
        var amountJedsWithArjedToPay = 0.00;
        for (var j=0; j<$scope.jedsWithArjedToPay.length; j++) {
          amountJedsWithArjedToPay += parseFloat($scope.jedsWithArjedToPay[j].arjed.paymentAmount);
        }
        $scope.newJournalEntryInfo.amount = (parseFloat(amountJedsWithArjedToPay) + parseFloat($scope.unassociatedAmount)).toFixed(2);

        if ($scope.newJournalEntryInfo.amount > 0) {
          $scope.transactionTypeDisplay = BUTTONDISPLAY__SALE + ' ' + $scope.newJournalEntryInfo.amount  + ' ' + currency;
          $scope.transactionType = ENUM__SALE;
        } else if ($scope.newJournalEntryInfo.amount < 0) {
          $scope.transactionTypeDisplay = BUTTONDISPLAY__CREDIT + ' ' + -$scope.newJournalEntryInfo.amount  + ' ' + currency;
          $scope.transactionType = ENUM__CREDIT;
        } else if (parseFloat($scope.newJournalEntryInfo.amount) === 0) {
          $scope.transactionTypeDisplay = '0.00 ' + currency;
        } else {
          $scope.transactionTypeDisplay = 'INVALID AMOUNT';
        }
      }
    });

    $scope.ok = function () {
      $uibModalInstance.close(
        {
          newJournalEntryInfo: $scope.newJournalEntryInfo,
          jedsWithArjedToPay: $scope.jedsWithArjedToPay,
          unassociatedAmount: $scope.unassociatedAmount
        }
      );
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }

  function addJEModalInstanceCtrl($scope, $uibModalInstance, currency, description, title, fromArType, amount) {

    $scope.AMOUNT_REGEX = /^((-?)(\d+)(\.\d{2}))$/;

    $scope.newJournalEntryInfo = {};
	  $scope.newJournalEntryInfo.description = description;
    $scope.newJournalEntryInfo.amount = (-parseFloat(amount)).toFixed(2);
    $scope.currency = currency;
    $scope.title = title;
    $scope.fromArType = fromArType;

    $scope.ok = function () {
      $uibModalInstance.close($scope.newJournalEntryInfo);
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
*/
  angular.module('common.patient-detail-billing', [])
  .controller('PatientDetailBillingCtrl', patientDetailBillingCtrl)
//  .controller('addPaymentModalInstanceCtrl', addPaymentModalInstanceCtrl)
//  .controller('addJEModalInstanceCtrl', addJEModalInstanceCtrl)
;
})();

/*global _ */
(function() {
  'use strict';

  function patientDetailInsuranceCtrl($scope, BillingService, AuthenticationService,
    PrincipalService, $uibModal, $transition$, $q, LocationsService, NotificationService,CanUpdateInsurance,CanCreateClaim,CanRetrieveClaim,CanRetrieveEncounter)
  {
    $scope.loadingJeds = false
    $scope.includeCompleted = false;

    $scope.animationsEnabled = false;
    $scope.patientId = $transition$.params().patientid;
    $scope.CanUpdateInsurance = CanUpdateInsurance;
    $scope.CanCreateClaim = CanCreateClaim;
    $scope.CanRetrieveClaim = CanRetrieveClaim;
    $scope.CanRetrieveEncounter = CanRetrieveEncounter;
    /*
      this controller can be used:
      1) standalone within a patient's chart to show patient insurance ar
      2) contained in a claim to show only claim related ar
      3) stanalone outside of a patient chart to show all active insurance
    */
    if ($scope.claimId) {
      $scope.claimContext = true;
    } else if ($scope.patientId) {
      $scope.patientInsuranceContext = true;
    } else {
      $scope.insuranceContext = true;
    }

    $scope.lineItemCheckChanged = function() {
      for (var i = 0; i < $scope.jeds.length; i++) {
        if ($scope.jeds[i].includeInPayment) {
          $scope.enablePaymentButton = true;
          return;
        }
      }
      $scope.enablePaymentButton = false;
    };

    $q.all([
      AuthenticationService.getPractice(PrincipalService.getPracticeId()),
      LocationsService.all()
    ])
    .then(function(responses) {
      $scope.practice = angular.fromJson(responses[0].data);
      $scope.locations = angular.fromJson(responses[1].data);
      reset();
    }, function(err) {
      NotificationService.error('Error loading patient or practice information',err);
    });

  	function reset() {

      $scope.loadingJeds = true
      if ($scope.claimContext) {
		    $q.all([
        	BillingService.getClaim($scope.claimId),
		    ])
	      .then(function(responses) {
		      var claim = angular.fromJson(responses[0].data);
          //remove associated ar that aren't in the same account as the claim, e.g. claimerinvoicelineitems
          claim.ar_journal_entry_details = claim.ar_journal_entry_details.filter(function(arjed) {
            return arjed.journal_entry_detail.gl_account === this.gl_account;  //'this' refers to claim
          }, claim);
          $scope.loadingJeds = false
          prepareScope(claim.ar_journal_entry_details);
	      }, function(err) {
          $scope.loadingJeds = false
          NotificationService.error('Error loading claim to obtain claim journal entry details',err);
	      });
      } else if ($scope.patientInsuranceContext) {
		    $q.all([
        	BillingService.getClaimeeArJournalEntryDetailsForPatient($scope.practice.insurance_ar_gl_account, $scope.patientId, false),
		    ])
	      .then(function(responses) {
		      var arjeds = angular.fromJson(responses[0].data);
          $scope.loadingJeds = false
          prepareScope(arjeds);
	      }, function(err) {
          $scope.loadingJeds = false
          NotificationService.error('Error loading patient ar journal entry details',err);
	      });
      } else { //insuranceContext
		    $q.all([
        	BillingService.getClaimsForAccount($scope.practice.insurance_ar_gl_account, $scope.includeCompleted)
		    ])
	      .then(function(responses) {
		      var claims = angular.fromJson(responses[0].data);
          var arjeds = [];
          for (var o=0; o<claims.length; o++) {
            claims[o].ar_journal_entry_details = claims[o].ar_journal_entry_details.filter(function(arjed) {
              return arjed.journal_entry_detail.gl_account === this.gl_account;  //'this' refers to claim[o]
            }, claims[o]);
            arjeds = arjeds.concat(claims[o].ar_journal_entry_details);
          }
          prepareScope(arjeds);
	      }, function(err) {
          NotificationService.error('Error loading claim to obtain claim journal entry details',err);
	      });
      }
    }

    //for making reset() available to billing-claims-detail.js, identified by the scope property 'CLAIM_OPERATIONS'.
    if ($scope.CLAIM_OPERATIONS) {
      var scope = $scope;
      while (true) {
        var childScope = scope;
        scope = scope.$parent;
        if (!scope) {
          break;
        } else if (!scope.CLAIM_OPERATIONS && childScope.$id !== $scope.$id) {
          childScope.patientDetailInsuranceReset = reset;
          break;
        }
      }
    }

    function prepareScope(arjeds) {
      /*
	      A list of ar journal entry details for the account for the patient that are
        'inverted' so each ar journal entry detail's journal entry detail contains
        the arjed. Whether an arjed.claim has as value or is null
        determines if it is in the top list of claims not assigned to an
        actual claim or in the bottom list of claims already assigned to a claim.
      */
	    var noclaimjeds = [];
	    var noclaimjedstotal = 0.00;
	    var jeds = [];
	    var jedstotal = 0.00;
      var forgoneLineItems = {};
	    for (var j = 0; j < arjeds.length; j++) {
		    var jed = arjeds[j].journal_entry_detail;
		    delete arjeds[j].journal_entry_detail;
		    jed.arjed = arjeds[j];
		    if (
          !jed.arjed.claim &&
          !jed.arjed.chargeback_for &&
          !jed.arjed.writeoff_for &&
          !jed.arjed.payment_for_for &&
          !jed.arjed.type_claimee_forgone
        ) {
			    noclaimjeds.push(jed);
			    noclaimjedstotal += parseFloat(jed.amount);
		    } else {
		      jeds.push(jed);
		      jedstotal += parseFloat(jed.amount);
          if (!jed.arjed.claim && jed.arjed.type_claimee_forgone) {
            jed.arjed.remaining = parseFloat(jed.amount);
            jed.arjed.paymentsTotal = 0.00;
            jed.arjed.writeoffsTotal = 0.00;
            jed.arjed.chargebacksTotal = 0.00;
            forgoneLineItems[jed.id] = jed;
          }
		    }
	    }
	    $scope.noclaimjeds = noclaimjeds;
	    $scope.noclaimjedstotal = BillingService.getAmountToFixed(noclaimjedstotal);
	    $scope.jeds = jeds;
	    $scope.jedstotal = BillingService.getAmountToFixed(jedstotal);

      //group jeds into a dictionary of claimid to object with jeds and total amount of jeds.
	    var claims = {};
	    for (var p = 0; p < $scope.jeds.length; p++) {
		    if ($scope.jeds[p].arjed && $scope.jeds[p].arjed.claim) {
			    if (!claims[$scope.jeds[p].arjed.claim.id]) {
				    claims[$scope.jeds[p].arjed.claim.id] = {};
				    claims[$scope.jeds[p].arjed.claim.id].jeds = [];
				    claims[$scope.jeds[p].arjed.claim.id].balance = 0.00;
            claims[$scope.jeds[p].arjed.claim.id].total = 0.00;
            claims[$scope.jeds[p].arjed.claim.id].claim = $scope.jeds[p].arjed.claim;
			    }
			    claims[$scope.jeds[p].arjed.claim.id].jeds.push($scope.jeds[p]);
			    claims[$scope.jeds[p].arjed.claim.id].balance += parseFloat($scope.jeds[p].amount);
          if ( //claims only include type claimee in claim total.
            $scope.jeds[p].arjed.type === BillingService.AR_TYPE.CLAIMEE
          ) {
            claims[$scope.jeds[p].arjed.claim.id].total += parseFloat($scope.jeds[p].amount);
          }
		    } else if (
          !$scope.jeds[p].arjed.type_claimee_forgone &&
          !$scope.jeds[p].arjed.claim
        ) {
          if ($scope.jeds[p].arjed.writeoff_for) {
            if (forgoneLineItems[$scope.jeds[p].arjed.writeoff_for]) {
              forgoneLineItems[$scope.jeds[p].arjed.writeoff_for].arjed.writeoffsTotal -= parseFloat($scope.jeds[p].amount);
              forgoneLineItems[$scope.jeds[p].arjed.writeoff_for].arjed.remaining += parseFloat($scope.jeds[p].amount);
            }
          }
          if ($scope.jeds[p].arjed.chargeback_for) {
            if (forgoneLineItems[$scope.jeds[p].arjed.chargeback_for]) {
              forgoneLineItems[$scope.jeds[p].arjed.chargeback_for].arjed.chargebacksTotal -= parseFloat($scope.jeds[p].amount);
              forgoneLineItems[$scope.jeds[p].arjed.chargeback_for].arjed.remaining += parseFloat($scope.jeds[p].amount);
            }
          }
          if ($scope.jeds[p].arjed.payment_for) {
            if (forgoneLineItems[$scope.jeds[p].arjed.payment_for]) {
              forgoneLineItems[$scope.jeds[p].arjed.payment_for].arjed.paymentsTotal -= parseFloat($scope.jeds[p].amount);
              forgoneLineItems[$scope.jeds[p].arjed.payment_for].arjed.remaining += parseFloat($scope.jeds[p].amount);
            }
          }
		    }

        //set the lineitem type display
        if (!$scope.jeds[p].arjed) {
          $scope.jeds[p].type = 'Journal Entry';
        } else {
          $scope.jeds[p].type = BillingService.getArTypeDisplayString($scope.jeds[p].arjed.type);
        }
	    }

		  var nowMillis  = new Date().getTime();
      var millisPerDay = 24*60*60*1000;

      //put summary properties into each jeds so each line item can show info on the claim, including 'claimPastDueDays', 'claimTotal', and 'paymentsTotal' and 'remaining' for those of type 'claimee'
	    for (var key in claims) {
		    if (claims.hasOwnProperty(key)) {
          claims[key].balance = BillingService.getAmountToFixed(claims[key].balance);
			    for (var q = 0; q < claims[key].jeds.length; q++) {
            var claimDateMillis = new Date(claims[key].claim.claim_date).getTime();
            claims[key].jeds[q].arjed.claimPastDueDays =
              Math.round((nowMillis - claimDateMillis) / millisPerDay);
				    claims[key].jeds[q].arjed.claimTotal = BillingService.getAmountToFixed(claims[key].total);
				    claims[key].jeds[q].arjed.claimBalance =claims[key].balance;

            if (
              claims[key].jeds[q].arjed.type === 'claimee'
            ) {

              //calculate payments total
              claims[key].jeds[q].arjed.paymentsTotal = 0.00;
              for (var w=0; w<claims[key].jeds[q].arjed.payments.length; w++) {
                claims[key].jeds[q].arjed.paymentsTotal += parseFloat(_.find(claims[key].jeds, function(jed) {
                  return jed.id === claims[key].jeds[q].arjed.payments[w];
                }).amount);
              }
              claims[key].jeds[q].arjed.paymentsTotal = BillingService.getAmountToFixed(-claims[key].jeds[q].arjed.paymentsTotal);

              //calculate writeoffs total
              claims[key].jeds[q].arjed.writeoffsTotal = 0.00;
              for (var x=0; x<claims[key].jeds[q].arjed.writeoffs.length; x++) {
                claims[key].jeds[q].arjed.writeoffsTotal += parseFloat(_.find(claims[key].jeds, function(jed) {
                  return jed.id === claims[key].jeds[q].arjed.writeoffs[x];
                }).amount);
              }
              claims[key].jeds[q].arjed.writeoffsTotal = BillingService.getAmountToFixed(-claims[key].jeds[q].arjed.writeoffsTotal);

              //calculate chargebacks total
              claims[key].jeds[q].arjed.chargebacksTotal = 0.00;
              for (var t=0; t<claims[key].jeds[q].arjed.chargebacks.length; t++) {
                claims[key].jeds[q].arjed.chargebacksTotal += parseFloat(_.find(claims[key].jeds, function(jed) {
                  return jed.id === claims[key].jeds[q].arjed.chargebacks[t];
                }).amount);
              }
              claims[key].jeds[q].arjed.chargebacksTotal = BillingService.getAmountToFixed(-claims[key].jeds[q].arjed.chargebacksTotal);

              claims[key].jeds[q].arjed.remaining = -parseFloat(claims[key].jeds[q].arjed.paymentsTotal) - parseFloat(claims[key].jeds[q].arjed.writeoffsTotal) - parseFloat(claims[key].jeds[q].arjed.chargebacksTotal) + parseFloat(claims[key].jeds[q].amount);

            }
			    }
		    }
	    }
      // from all the unclaimed jeds find all the unique providers. This is required
      // because insurance claims are by treating provider.
      $scope.uniqueProviderLocations = [];
      for (var s = 0; s < $scope.noclaimjeds.length; s++) {
        var found = false;
        for (var k = 0; k < $scope.uniqueProviderLocations.length; k++) {
          if (($scope.uniqueProviderLocations[k].treatingProviderId === $scope.noclaimjeds[s].arjed.treatment.provider.id) && ($scope.uniqueProviderLocations[k].locationId === $scope.noclaimjeds[s].arjed.treatment.location)) {
            found = true;
            break;
          }
        }
        if (!found) {
          var upl = {};
          upl.treatingProviderId = $scope.noclaimjeds[s].arjed.treatment.provider.id;
          upl.providerUsername = $scope.noclaimjeds[s].arjed.treatment.provider.username;
          upl.locationId = $scope.noclaimjeds[s].arjed.treatment.location;
          for (var z = 0; z < $scope.locations.length; z++) {
            if (upl.locationId === $scope.locations[z].id) {
              upl.locationName = $scope.locations[z].name;
              break;
            }
          }
          $scope.uniqueProviderLocations.push(upl);
        }
      }
    }

    function createJournalEntry(journalEntry) {
      BillingService.createJournalEntryForPractice(PrincipalService.getPracticeId(), journalEntry).then(function() {
        NotificationService.success('Successfully applied new entry.');
        reset();
      }, function(err) {
        NotificationService.error('Error creating new entry',err);
      });
    }

    $scope.jedActionToggled = function(open, jed) {
      if (open && !jed.associatedArjed) {
        BillingService.getAssociatedArJournalEntryDetails(jed.id).then(function(data) {
          var associatedArjeds = data.data;
          if (associatedArjeds.length === 1) { //only support chargebacks when there is a single associated arjed to chargeback to.
            jed.associatedArjed = associatedArjeds[0];
          }
        }, function(err) {
          NotificationService.error('Error getting associated ar journal entry detail information',err);
        });
      }
    };
    /* ADD PAYMENT
      When submitted to back end it results in:

                      je
          |                           |
        jed                           jed
          amount= -pmtamount            amount=paymentamount
          gl_account=patientAR          gl_account=cash
          |
        arjed
          type= payment
    */
    $scope.openAddPaymentModal = function (title, description, currency, merchantId, fromArGlAccountId,
      cashGlAccountId, jeds, showUnassociatedAmount, animationsEnabled)
    {
      showUnassociatedAmount = typeof showUnassociatedAmount !== 'undefined' ? showUnassociatedAmount : true;

      //find the jeds to pay from all jeds
      var jedsWithArjedToPay = [];
      for (var j=0; j<jeds.length; j++) {
        if (
          jeds[j].includeInPayment
        ) {
          jeds[j].arjed.paymentAmount = BillingService.getAmountToFixed(jeds[j].arjed.remaining);
          jedsWithArjedToPay.push(jeds[j]);
        }
      }
      jedsWithArjedToPay = JSON.parse(JSON.stringify(jedsWithArjedToPay)); //deepcopy

      var modalInstance = $uibModal.open({
        animation: animationsEnabled,
        templateUrl: 'addPayment.html',
        controller: 'addPaymentModalInstanceCtrl',
        size: 'lg',
        resolve: {
          currency: function () {
            return currency;
          },
          description: function () {
            return description;
          },
		      title: function () {
			      return title;
		      },
          merchantId: function () {
            return merchantId;
          },
          jedsWithArjedToPay: function () {
            return jedsWithArjedToPay;
          },
          showUnassociatedAmount: function () {
            return showUnassociatedAmount;
          }
        }
      });

      modalInstance.result.then(function (closeObj) {
        var newJournalEntryInfo = closeObj.newJournalEntryInfo;
        var jedsWithArjedToPay = closeObj.jedsWithArjedToPay;
        var unassociatedAmount = closeObj.unassociatedAmount;

        var journalEntry = BillingService.createJe(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo
        );

        var partnerJed = BillingService.createJed(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo,
          cashGlAccountId,
          newJournalEntryInfo.amount,
          currency
        );

        journalEntry.journal_entry_details.push(partnerJed);

        for (var z=0; z<jedsWithArjedToPay.length; z++) {

          //if (parseFloat(jedsWithArjedToPay[z].arjed.paymentAmount) !== 0.00) {

		        var jed = BillingService.createJed(
              newJournalEntryInfo.description,
              newJournalEntryInfo.memo,
              fromArGlAccountId,
              -jedsWithArjedToPay[z].arjed.paymentAmount,
              currency
            );

		        var arjed = BillingService.createArjed(
              jed,
              BillingService.AR_TYPE.PAYMENT,
              jedsWithArjedToPay[z].arjed.treatment,
              jedsWithArjedToPay[z].arjed.claim,
              jedsWithArjedToPay[z].arjed.invoice,
              jedsWithArjedToPay[z]
            );

            journalEntry.ar_journal_entry_details.push(arjed);
          //}
        }

        if (parseFloat(unassociatedAmount) !== 0.00) {
	        var jedUnassociated = BillingService.createJed(
            newJournalEntryInfo.description,
            newJournalEntryInfo.memo,
            fromArGlAccountId,
            -unassociatedAmount,
            currency
          );

	        var arjedUnassociated = BillingService.createArjed(
            jedUnassociated,
            BillingService.AR_TYPE.PAYMENT
          );

          journalEntry.ar_journal_entry_details.push(arjedUnassociated);
        }

        createJournalEntry(journalEntry);
      }, function () {
      });
    };


    /* ADD chargeback
      When submitted to back end it results in:

                      je
          |                           |
        jed                           jed
          amount= -amount                amount=amount
          gl_account=fromArGlAccountId   gl_account=toGlAccountId
          |                               |
        arjed                            arjed
          type= chargeback                 type=chargeback
          chargeback_for=                  chargeback_for= the item in toGlAccount the chargeback is for
            the item in fromArGlAccount
            that was the entry balancing
            the item in toGlAccount the
            chargeback is for
     */

    $scope.forgoUnclaimedLineItem = function(jed)
    {
      $q.all([
        BillingService.getArJournalEntryDetail(jed.id)
      ])
      .then(function(responses) {
        var arjed = responses[0].data;
        arjed.type_claimee_forgone = true;
        return BillingService.updateArJournalEntryDetail(arjed.journal_entry_detail.id, arjed);
      }, function(err) {
        NotificationService.error('Error getting ar journal entry detail',err);
      }).then(function() {
  			reset();
  		}, function(err) {
        NotificationService.error('Error updating ar journal entry detail',err);
  		});
    };

    $scope.openAddChargebackModal = function(
      title,
      description,
      currency,
      fromArGlAccountId,
      fromArTreatment,
      fromArClaim,
      fromArInvoice,
      amount,
      chargebackFor,
      animationsEnabled,
      helpText)
    {
      var arType = BillingService.AR_TYPE.CHARGEBACK;
      if (!amount) {
        amount = '0.00';
      }
      var modalInstance = $uibModal.open({
        animation: animationsEnabled,
        templateUrl: 'addJE.html',
        controller: 'addJEModalInstanceCtrl',
        size: 'lg',
        resolve: {
          currency: function () {
            return currency;
          },
          description: function () {
            return description;
          },
		      title: function () {
			      return title;
		      },
		      amount: function () {
			      return amount;
          },
          helpText: function () {
			      return helpText;
		      },
        }
      });

      modalInstance.result.then(function (newJournalEntryInfo) {

        var journalEntry = BillingService.createJe(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo
        );

        var toJed = BillingService.createJed(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo,
          chargebackFor.associatedArjed.journal_entry_detail.gl_account,
          -newJournalEntryInfo.amount,
          currency
        );

        var toArjed = BillingService.createArjed(
          toJed,
          arType,
          chargebackFor.associatedArjed.treatment,
          chargebackFor.associatedArjed.claim,
          chargebackFor.associatedArjed.invoice,
          null,
          null,
          chargebackFor.associatedArjed.journal_entry_detail.id
        );

        journalEntry.ar_journal_entry_details.push(toArjed);

        var fromJed = BillingService.createJed(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo,
          fromArGlAccountId,
          newJournalEntryInfo.amount,
          currency
        );

        var fromArjed = BillingService.createArjed(
          fromJed,
          arType,
          fromArTreatment,
          fromArClaim,
          fromArInvoice,
          null,
          null,
          chargebackFor
        );

        journalEntry.ar_journal_entry_details.push(fromArjed);

        createJournalEntry(journalEntry);
      }, function () {
      });
    };

    /* ADD JE
      When submitted to back end it results in:

                      je
          |                           |
        jed                           jed
          amount= -amount                amount=amount
          gl_account=fromArGlAccountId   gl_account=toGlAccountId
          |
        arjed
          type= fromArType
    */
    $scope.openAddJEModal = function (
      title,
      description,
      currency,
      fromArGlAccountId,
      fromArType,
      fromArTreatment,
      fromArClaim,
      fromArInvoice,
      toGlAccountId,
      amount,
      paymentFor,
      writeoffFor,
      animationsEnabled,
      helpText)
    {

      var modalInstance = $uibModal.open({
        animation: animationsEnabled,
        templateUrl: 'addJE.html',
        controller: 'addJEModalInstanceCtrl',
        size: 'lg',
        resolve: {
          currency: function () {
            return currency;
          },
          description: function () {
            return description;
          },
		      title: function () {
			      return title;
		      },
		      amount: function () {
			      return amount;
          },
          helpText: function () {
			      return helpText;
		      },
        }
      });

      modalInstance.result.then(function (newJournalEntryInfo) {

        var journalEntry = BillingService.createJe(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo
        );

        var toJed = BillingService.createJed(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo,
          toGlAccountId,
          -newJournalEntryInfo.amount,
          currency
        );

        journalEntry.journal_entry_details.push(toJed);

        var fromJed = BillingService.createJed(
          newJournalEntryInfo.description,
          newJournalEntryInfo.memo,
          fromArGlAccountId,
          newJournalEntryInfo.amount,
          currency
        );

        var fromArjed = BillingService.createArjed(
          fromJed,
          fromArType,
          fromArTreatment,
          fromArClaim,
          fromArInvoice,
          paymentFor,
          writeoffFor,
          null
        );

        journalEntry.ar_journal_entry_details.push(fromArjed);

        createJournalEntry(journalEntry);
      }, function () {
      });
    };
  }

  function addPaymentModalInstanceCtrl($scope, $uibModalInstance, currency, description, title, merchantId, jedsWithArjedToPay, showUnassociatedAmount) {

    $scope.showUnassociatedAmount = showUnassociatedAmount;

    var ENUM__SALE   = 'SALE';
    var ENUM__CREDIT = 'CREDIT';
    //var ENUM__CREDITCARD_VOID   = 'CREDITCARD_VOID';
    var BUTTONDISPLAY__SALE   = 'Charge / EBT';
    var BUTTONDISPLAY__CREDIT = 'Refund';
    //var BUTTONDISPLAY__CREDITCARD_VOID   = 'Void Current Transaction';
    $scope.AMOUNT_REGEX = /^((-?)(\d+)(\.\d{2}))$/;

    $scope.jedsWithArjedToPay = jedsWithArjedToPay;
    var watchList = [];
    for (var q=0; q<$scope.jedsWithArjedToPay.length; q++) {
      $scope.jedsWithArjedToPay.index = q;
      watchList.push('jedsWithArjedToPay[' + q +'].arjed.paymentAmount');
    }
    watchList.push('unassociatedAmount');
    $scope.newJournalEntryInfo = {};
    
    //$scope.newJournalEntryInfo.description = description;
    //$scope.method = 'Insurance Payment - Check';
    $scope.payer = '';
    $scope.unassociatedAmount = '0.00';

    $scope.currency = currency;
    $scope.title = title;
    $scope.merchantId = merchantId;
    $scope.control = {};

    $scope.transactionType = ENUM__SALE;
    $scope.transactionTypeDisplay = BUTTONDISPLAY__SALE;

    $scope.changeTotalAmount = false;

    var origTotal = -1;
    $scope.origJedsWithArjedToPay = JSON.parse(JSON.stringify(jedsWithArjedToPay)); //deepcopy

    var lastPositiveLineIndex = -1;
    for (var j=$scope.origJedsWithArjedToPay.length-1; j>=0; j--) {
      if (parseFloat($scope.origJedsWithArjedToPay[j].arjed.paymentAmount) > 0.00) {
        lastPositiveLineIndex = j;
        break;
      }
    }

    $scope.$watch('newJournalEntryInfo.amount', function(newVal) {
      if ($scope.changeTotalAmount) {
        if (parseFloat($scope.newJournalEntryInfo.amount) > 0) {
          $scope.transactionTypeDisplay = BUTTONDISPLAY__SALE + ' ' + $scope.newJournalEntryInfo.amount  + ' ' + currency;
          $scope.transactionType = ENUM__SALE;
        } else if (parseFloat($scope.newJournalEntryInfo.amount) < 0) {
          $scope.transactionTypeDisplay = BUTTONDISPLAY__CREDIT + ' ' + (-parseFloat($scope.newJournalEntryInfo.amount)).toFixed(2)  + ' ' + currency;
          $scope.transactionType = ENUM__CREDIT;
        } else if (parseFloat($scope.newJournalEntryInfo.amount) === 0) {
          $scope.transactionTypeDisplay = '0.00 ' + currency;
        } else {
          $scope.transactionTypeDisplay = 'INVALID AMOUNT';
          return;
        }
        if (!isNaN(parseFloat(newVal))) {
          var newTotal = parseFloat(newVal);
          var diff = origTotal - newTotal - $scope.unassociatedAmount;
          var lineAmt = 0.00; 
          
          if (lastPositiveLineIndex !== -1) {// there is a positive line
            for (j=0; j<$scope.origJedsWithArjedToPay.length; j++) {
              lineAmt = parseFloat($scope.origJedsWithArjedToPay[j].arjed.paymentAmount);
              if (lineAmt > 0.00) {
                if ( diff > lineAmt && lastPositiveLineIndex !== j) {
                  diff = diff - lineAmt;
                  lineAmt = 0.00;
                } else {
                  lineAmt = lineAmt - diff;
                  diff = 0.00;
                }
              }
              $scope.jedsWithArjedToPay[j].arjed.paymentAmount = lineAmt.toFixed(2);
            }
          } else { // all lines are negative or zero
            if (newTotal > 0) {
              lineAmt = parseFloat($scope.origJedsWithArjedToPay[0].arjed.paymentAmount);
              lineAmt = lineAmt - diff;
              $scope.jedsWithArjedToPay[0].arjed.paymentAmount = lineAmt.toFixed(2);
            } else {
              for (j=0; j<$scope.origJedsWithArjedToPay.length; j++) {
                lineAmt = parseFloat($scope.origJedsWithArjedToPay[j].arjed.paymentAmount);
                if ( diff < lineAmt ) {
                  diff = diff - lineAmt;
                  lineAmt = 0.00;
                } else {
                  lineAmt = lineAmt - diff;
                  diff = 0.00;
                }
                $scope.jedsWithArjedToPay[j].arjed.paymentAmount = lineAmt.toFixed(2);
              }
            }   
          }
        }
      }
    });

    $scope.$watchGroup(watchList, function(newVal) {
      if (!$scope.changeTotalAmount) {
        if (newVal) {
          var amountJedsWithArjedToPay = 0.00;
          for (var j=0; j<$scope.jedsWithArjedToPay.length; j++) {
            amountJedsWithArjedToPay += parseFloat($scope.jedsWithArjedToPay[j].arjed.paymentAmount);
          }

          var lastTotal = parseFloat(amountJedsWithArjedToPay) + parseFloat($scope.unassociatedAmount);
          $scope.newJournalEntryInfo.amount = lastTotal.toFixed(2);
          if (origTotal === -1) {
            origTotal = lastTotal;
          } 

          if (parseFloat($scope.newJournalEntryInfo.amount) > 0) {
            $scope.transactionTypeDisplay = BUTTONDISPLAY__SALE + ' ' + $scope.newJournalEntryInfo.amount  + ' ' + currency;
            $scope.transactionType = ENUM__SALE;
          } else if (parseFloat($scope.newJournalEntryInfo.amount) < 0) {
            $scope.transactionTypeDisplay = BUTTONDISPLAY__CREDIT + ' ' + (-parseFloat($scope.newJournalEntryInfo.amount)).toFixed(2)  + ' ' + currency;
            $scope.transactionType = ENUM__CREDIT;
          } else if (parseFloat($scope.newJournalEntryInfo.amount) === 0) {
            $scope.transactionTypeDisplay = '0.00 ' + currency;
          } else {
            $scope.transactionTypeDisplay = 'INVALID AMOUNT';
          }
        }
      }
    });

    $scope.ok = function () {
      swal(
        {
          title: "Have you processed payment?",
          html: [
            '<p class="m-t">Click "yes" only if you have completed processing Charge/EBT or received cash.</p>',
          ].join(""),
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        },
        function () {
          $scope.newJournalEntryInfo.description = $scope.method + (($scope.payer.length > 0) ? ': ' + $scope.payer : '');
          $uibModalInstance.close(
            {
              newJournalEntryInfo: $scope.newJournalEntryInfo,
              jedsWithArjedToPay: $scope.jedsWithArjedToPay,
              unassociatedAmount: $scope.unassociatedAmount
            }
          )
        }
      )
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }

  function addJEModalInstanceCtrl($scope, $uibModalInstance, currency, description, title, amount, helpText) {

    $scope.AMOUNT_REGEX = /^((-?)(\d+)(\.\d{2}))$/;

    $scope.newJournalEntryInfo = {};
	  $scope.newJournalEntryInfo.description = description;
    $scope.newJournalEntryInfo.amount = (-parseFloat(amount)).toFixed(2);
    $scope.currency = currency;
    $scope.title = title;
    $scope.helpText = helpText;

    $scope.ok = function () {
      $uibModalInstance.close($scope.newJournalEntryInfo);
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }

  angular.module('common.patient-detail-insurance', [])
  .controller('PatientDetailInsuranceCtrl', patientDetailInsuranceCtrl)
  .controller('addPaymentModalInstanceCtrl', addPaymentModalInstanceCtrl)
  .controller('addJEModalInstanceCtrl', addJEModalInstanceCtrl);
})();

(function() {
  'use strict';

  angular.module('common.patient-detail-photos', [])
    .controller('PatientDetailPhotosCtrl', PatientDetailPhotosCtrl);

  function PatientDetailPhotosCtrl($scope, $transition$, AuthorizationService, PatientImageService) {
    $scope.PatientImageService = PatientImageService;
    $scope.CanDeletePatientImage = AuthorizationService.hasFrontendPermission('CanDeletePatientImage');
    $scope.CanListPatientImage = AuthorizationService.hasFrontendPermission('CanListPatientImage');
    $scope.CanRetrievePatientImage = AuthorizationService.hasFrontendPermission('CanRetrievePatientImage');
    $scope.CanRetrieveEncounter = AuthorizationService.hasFrontendPermission('CanRetrieveEncounter');
    $scope.CanUpdatePatientImage = AuthorizationService.hasFrontendPermission('CanUpdatePatientImage');
    $scope.patientId = $transition$.params().patientid;
  }

})();

(function () {
  'use strict';

  angular.module('common.patient-detail-photo-detail', [])
    .controller('PatientDetailPhotoDetailCtrl', patientDetailPhotoDetailCtrl);

  function patientDetailPhotoDetailCtrl($scope, $log, $transition$, UploadsService, CalibrationsService, NotificationService,CanUpdateImage) {
    $log.debug('Patient detail image detail loaded with patientid ' + $transition$.params().patientid);

    $scope.CanUpdateImage = CanUpdateImage;
    // TODO: improve handling of transformations
    // TODO: move this into a factory?
    function Transformations() {
      var that = this;
      var keys = [
        'scale',
        'canvasWidth',
        'offset',
        'filters'
      ];

      that.init = function (options) {
        options = options || {};
        var scale = options.scale || null;

        // Set default values
        var obj = {
          scale: scale,
          offset: {
            x: 0,
            y: 0
          },
          measures: [],
          filters: {
            brightness: null,
            contrast: null,
            invert: null
          },
          calibration: null,
          undos: []
        };

        // If clearing, do not change image, source and canvas width
        if (!options.clear) {
          obj.canvasWidth = 0;
          obj.image = null;
          obj.src = null;
        }

        angular.extend(that, obj);
      };

      that.clear = function () {
        that.init({
          clear: true,
          // Set scale to 100% of the current viewport
          scale: that.canvasWidth / that.image.width
        });
      };

      that.get = function() {
        return {
          scale: that.scale,
          canvasWidth: that.canvasWidth,
          offset: that.offset,
          measures: that.getMeasures(),
          filters: that.filters
        };
      };

      function hasValue(data, key) {
        return key in data && data[key] !== null;
      }

      function safeSet(data, key, targetKey) {
        targetKey = targetKey ? targetKey: key;

        // Set values only if available
        if (hasValue(data, key)) {
          that[targetKey] = data[key];
        }
      }

      that.update = function(data) {
        keys.forEach(function(key) {
          return safeSet(data.transformations || {}, key);
        });

        safeSet(data, 'image', 'src');

        if (hasValue(data.transformations, 'measures')) {
          that.setMeasures(data.transformations.measures);
        }
      };

      /**
       * MEASURES
       */
      that.clearMeasures = function () {
        that.measures = [];
      };

      that.hasMeasures = function () {
        return that.measures.length !== 0;
      };

      that.setMeasures = function (value) {
        that.measures = value;
      };

      that.getMeasures = function () {
        return that.measures;
      };

      that.measuresLength = function () {
        return that.measures.length;
      };

      that.removeLastMeasure = function () {
        return that.measures.pop();
      };

      that.addMeasure = function (measure) {
        that.measures.push(measure);
        that.addAction(that.removeLastMeasure);
      };

      that.getMeasure = function (index) {
        if (index < that.measuresLength()) {
          return that.measures[index];
        }

        return {};
      };
      /** END MEASURES **/

      /**
       * ACTIONS
       */
      that.hasActions = function () {
        return that.undos.length !== 0;
      };

      that.undoAction = function () {
        var actionObj = that.undos.pop();
        actionObj.action();
      };

      that.addAction = function (actionFunc, data) {
        that.undos.push({
          action: actionFunc,
          data: data
        });
      };
      /** END ACTIONS **/

      that.init();

      return that;
    }

    $scope.transforms = new Transformations();

    // TODO: Improve blocking actions logic
    // Handle blocking actions
    $scope.action = null;
    $scope.toggleMeasurement = function () {
      $scope.action = $scope.action !== 'measure' ? 'measure' : null;
    };

    $scope.clearTransforms = function () {
      $scope.transforms.clear();
    };

    // Toggle measures display
    $scope.measuresBackup = null;
    $scope.toggleMeasures = function () {
      $scope.action = null;

      if ($scope.measuresBackup) {
        $scope.transforms.setMeasures($scope.measuresBackup);
        $scope.measuresBackup = null;
      } else {
        $scope.measuresBackup = $scope.transforms.getMeasures();
        $scope.transforms.clearMeasures();
      }
    };

    // Handle zooming
    $scope.zoomIn = function () {
      $scope.transforms.scale = $scope.transforms.scale * 1.2;
    };
    $scope.zoomOut = function () {
      $scope.transforms.scale = $scope.transforms.scale / 1.2;
    };

    UploadsService.getPatientImage($transition$.params().photoid).then(function(data) {
      $scope.photo = angular.fromJson(data.data);
      $scope.photo.image = data.data.image;
      $scope.transforms.update($scope.photo);
    }, function (err) {
      NotificationService.error('Error Loading photo',err);
    });

    $scope.update = function () {
      // Set the new transformations
      $scope.photo.transformations = $scope.transforms.get();

      UploadsService.updatePatientImage($transition$.params().photoid, $scope.photo).then(function (data) {
        $scope.photo = angular.fromJson(data.data);
        $scope.transforms.update($scope.photo);
        NotificationService.success('Successfully updated photo information.');
      }, function (err) {
        NotificationService.error('Error Updating photo',err);
      });
    };

    // Update only the calibration value, when user selects a new one
    $scope.updateCalibration = function () {
      if (!$scope.transforms.calibration) {
        return;
      }

      $scope.transforms.calibration.default = true;

      CalibrationsService.update($scope.transforms.calibration.id, $scope.transforms.calibration).then(null, function (err) {
        NotificationService.error('Error Updating calibration profile',err);
      });
    };

    // Undo action
    $scope.undoAction = function () {
      $scope.action = null;
      $scope.transforms.undoAction();
    };

    // Calibration
    $scope.calibrating = false;
    $scope.calibrations = [];

    // Get existing calibrations for this practice
    CalibrationsService.all().then(function(data) {
      var calibrations = data.data;
      $scope.calibrations = calibrations;
      for (var i = 0; i < calibrations.length; i++) {
        if (calibrations[i].default) {
          $scope.transforms.calibration = calibrations[i];
          break;
        }
      }
    }, function (err) {
      NotificationService.error('Error Loading calibrations',err);
    });

    // Create a new calibration
    $scope.$on('calibrationEnded', function(event, calibration) {
      calibration.width = $scope.transforms.image.width;
      calibration.height = $scope.transforms.image.height;

      CalibrationsService.create(calibration).then(function(data) {
        $scope.calibrations.push(angular.fromJson(data.data));
      }, function (err) {
        NotificationService.error('Error Saving calibration',err);
      });
    });

    $scope.removeCalibration = function (calibration) {
      CalibrationsService.delete(calibration.id).then(function () {
        // Find the calibration to remove (current calibration may have less properties than the one in the array)
        for(var i = 0; $scope.calibrations.length ; i++) {
          if ($scope.calibrations[i].id === calibration.id) {
            $scope.calibrations.splice(i, 1);
            break;
          }
        }
      }, function (err) {
        NotificationService.error('Error Deleting calibration',err);
      });
    };

  }

})();

/* jshint undef: false, unused: false */
(function() {
  'use strict';

  function patientDetailPrescriptionsCtrl(
    $log,
    $scope,
    $transition$,
    $uibModal,
    $window,
    CanRetrieveEncounter,
    CanRetrievePrescription,
    CanUpdateEncounter,
    NotificationService,
    PrescriptionsService
  ) {

    $scope.CanRetrievePrescription = CanRetrievePrescription;
    $scope.CanRetrieveEncounter = CanRetrieveEncounter;
    $scope.CanDeletePrescription = CanUpdateEncounter;
    $scope.patientId = $transition$.params().patientid;

    function reset() {
      if ($scope.mode === 'ENCOUNTER') {
        if ($scope.encounterId) {
          PrescriptionsService.allForEncounter($scope.encounterId).then(
            function(data) {
              $scope.prescriptions = angular.fromJson(data.data);
            },
            function(err) {
              NotificationService.error('Error Loading prescriptions', err);
            }
          );
        }
      }
      else {
        // prescriptions tab
        PrescriptionsService.allForPatient($scope.patientId).then(
          function(data) {
            $scope.prescriptions = angular.fromJson(data.data);
          },
          function(err) {
            NotificationService.error('Error Loading prescriptions', err);
          }
        );
      }
    }

    reset();

    $scope.printPrescription = function(prescriptionId) {
      $window.open(PrescriptionsService.getPrintPrescriptionUrl(prescriptionId), '_blank');
    };

    //delete prescription modal
    $scope.openDelPrescriptionModal = function(prescriptionId) {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'delPrescription.html',
        controller: 'delPrescriptionModalInstanceCtrl',
        size: 'md'
      });
      modalInstance.result.then(
        function(selectedItem) {
          $scope.doDel(prescriptionId);
        },
        function() {}
      );
    };

    $scope.doDel = function(prescriptionId) {
      PrescriptionsService.delete(prescriptionId).then(
        function(data) {
          NotificationService.success('Successfully deleted prescription.');
          reset();
        },
        function(err) {
          NotificationService.error('Error Deleting prescription', err);
        }
      );
    };

    // ADD/EDIT PRESCRIPTION

    var unbindEventWatcher = $scope.$watch('isPrescriptionsAddNotCollapsed', function(newVal, oldVal) {
      if (newVal !== oldVal) {
        if ((newVal === true) && $scope.encounterId) {
          if ($scope.isDoseSpotRegistered) {
            $scope.openEprescriptionPickerModal($scope.encounterId);
          }
          else {
            $scope.openMaintainPrescriptionModal($scope.encounterId);
          }
        }
      }
    });

    // this is only called from the isPrescriptionsAddNotCollapsed.watcher
    $scope.openEprescriptionPickerModal = function(encounterId) {
      $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'ePrescriptionPicker.html',
        resolve: {
          encounterProvider: function(/*AuthenticationService*/) {
            return {
              "id": $scope.encounter.provider,
              "name": $scope.encounter.provider_fullname,
            }
          },
        },
        controller: 'ePrescriptionPickerModalInstanceCtrl',
        size: 'md'
      }).result.then(
        function(choice) {
          if (choice.name === 'manual') {
            $scope.openMaintainPrescriptionModal(encounterId);
          }
          else {
            $scope.requestDoseSpotPatientRedirect();
          }
          $scope.$parent.isPrescriptionsAddNotCollapsed = false;
        }
      )
      .catch(
        function(e) {
          if (e !== 'cancel_button_clicked') {
            $log.error('prescription picker modal threw error: ', e);
          }
          $scope.$parent.isPrescriptionsAddNotCollapsed = false;
        }
      );
    };

    $scope.openMaintainPrescriptionModal = function(encounterId, prescription, viewOnly) {
      prescription = typeof prescription !== 'undefined' ? angular.copy(prescription) : {};
      viewOnly = typeof viewOnly !== 'undefined' ? viewOnly : false;
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'maintainPrescription.html',
        controller: 'maintainPrescriptionModalInstanceCtrl',
        resolve: {
          encounterId: function() { return encounterId; },
          prescription: function() { return prescription; },
          viewOnly: function() { return viewOnly; },
          location: function() { return $scope.locations && $scope.locations.length > 0 ? $scope.locations[0] : {}; },
          CanUpdatePrescription: CanUpdateEncounter,
          CanCreatePrescription: CanUpdateEncounter
        }
      });
      modalInstance.result.then(
        function(prescription) {
          if (prescription.hasOwnProperty('id')) {
            $scope.doEditPrescription(prescription);
          }
          else {
            $scope.doAddPrescription(prescription);
          }
          $scope.$parent.isPrescriptionsAddNotCollapsed = false;
        },
        function() {
          $scope.$parent.isPrescriptionsAddNotCollapsed = false;
        }
      );
    };

    $scope.doAddPrescription = function(prescription) {
      $log.debug(prescription);
      PrescriptionsService.create(prescription.encounter.id, prescription).then(
        function() {
          NotificationService.success('Successfully created new prescription.');
          reset();
        },
        function(err) {
          NotificationService.error('Error Creating new prescription', err);
        }
      );
    };

    $scope.doEditPrescription = function(prescription) {
      $log.debug(prescription);
      PrescriptionsService.update(prescription.id, prescription).then(
        function() {
          NotificationService.success('Successfully updated prescription.');
          reset();
        },
        function(err) {
          NotificationService.error('Error Updating prescription', err);
        }
      );
    };

    var prescriptionStatusMap = {
      1 : "Entered",
      2 : "Printed",
      3 : "Sending",
      4 : "eRxSent",
      5 : "FaxSent",
      6 : "Error",
      7 : "Deleted",
      8 : "Requested",
      9 : "Edited",
      10: "EpcsError",
      11: "EpcsSigned",
      12: "EpcsToSign",
      13: "PharmacyVerified",
    }

    $scope.prescriptionStatusDisplay = function(status) {
      if (status) {
        return prescriptionStatusMap[status]
      }
    }

  }

  function delPrescriptionModalInstanceCtrl($scope, $uibModalInstance) {

    $scope.ok = function() {
      $uibModalInstance.close();
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }

  function maintainPrescriptionModalInstanceCtrl(
    $log,
    $uibModalInstance,
    $q,
    $scope,
    AuthenticationService,
    CanCreatePrescription,
    CanUpdatePrescription,
    encounterId,
    EncountersService,
    location,
    NotificationService,
    PatientsService,
    prescription,
    PrescriptionsService,
    PrincipalService,
    viewOnly
  ) {

    $scope.encounterId = encounterId;
    $scope.prescription = typeof prescription !== 'undefined' ? prescription : {};
    $scope.location = location;
    $scope.isEdit = prescription.hasOwnProperty('id') ? true : false;
    $scope.viewOnly = viewOnly;
    $scope.CanUpdatePrescription = CanUpdatePrescription;
    $scope.CanCreatePrescription = CanCreatePrescription;
    $scope.BACKEND_FORMAT_DATE = 'YYYY-MM-DD';

    PrescriptionsService.getAllDrugTemplates().then(
      function(event) {
        $scope.drugTemplates = event.data;
      },
      function(error) {
        NotificationService.error('Error Loading Drug Templates', error);
      }
    );

    $scope.drugTemplateChange = function(drugTemplate) {
      if (!drugTemplate) {
        return;
      }
      $scope.prescription.drug = drugTemplate.drug;
      $scope.prescription.dosage = drugTemplate.dosage;
      $scope.prescription.quantity = drugTemplate.quantity;
      $scope.prescription.number_of_refills = drugTemplate.number_of_refills;
      $scope.prescription.instructions = drugTemplate.instructions;
    };

    $scope.canModifyPrescription = function() {
      if (!$scope.isEdit) {
        return true;
      }
      return PrincipalService.hasPermission('prescription_modify');
    };

    if (!$scope.isEdit) {
      EncountersService.get(encounterId).then(
        function(data) {
          var encounter = angular.fromJson(data.data);
          $q.all([
            PatientsService.get(encounter.patient),
            AuthenticationService.getUserAccount(encounter.provider)
          ])
          .then(function(responses) {
            $scope.prescription.date = moment().format($scope.BACKEND_FORMAT_DATE);
            $scope.prescription.encounter = {};
            $scope.prescription.encounter.id = $scope.encounterId;
            $scope.prescription.encounter.patient = angular.fromJson(responses[0].data);
            $scope.prescription.encounter.provider = angular.fromJson(responses[1].data);
            return AuthenticationService.getPractice($scope.prescription.encounter.patient.practice);
          })
          .then(function(resp) {
            $scope.prescription.encounter.patient.practice = angular.fromJson(resp.data);
            $log.debug('[ PRESCRIPTION BUILT ]: ', $scope.prescription);
          })
          .catch(function(err) {
            NotificationService.error('Error Loading patient or provider information', err);
          });
        },
        function(err) {
          NotificationService.error('Error Getting encounter information', err);
        }
      );
    }
    else {
      prescription.date = prescription.date;
    }

    $scope.ok = function() {
      $uibModalInstance.close($scope.prescription);
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    //datepicker
    $scope.openPrescriptionDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.dateOpen = true;
    };
  }

  function ePrescriptionPickerModalInstanceCtrl($scope, $uibModalInstance, PrincipalService, encounterProvider) {

    $scope.ePrescriptionsDisabled = PrincipalService.getAccountId() !== encounterProvider.id
    $scope.encounterProvider = encounterProvider

    $scope.choice = {
      name: $scope.ePrescriptionsDisabled ? "manual" : "eprescription"
    };

    $scope.ok = function() {
      $uibModalInstance.close($scope.choice);
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel_button_clicked');
    };
  }

  angular.module('common.patient-detail-prescriptions', [])
    .controller('PatientDetailPrescriptionsCtrl', patientDetailPrescriptionsCtrl)
    .controller('delPrescriptionModalInstanceCtrl', delPrescriptionModalInstanceCtrl)
    .controller('maintainPrescriptionModalInstanceCtrl', maintainPrescriptionModalInstanceCtrl)
    .controller('ePrescriptionPickerModalInstanceCtrl', ePrescriptionPickerModalInstanceCtrl);
})();

(function() {
  'use strict';

  function patientDetailFileDetailCtrl($scope, $log, $transition$, UploadsService, NotificationService,CanUpdateFile) {
      $log.debug('Patient detail file detail loaded with patientid ' + $transition$.params().fileid);

      $scope.CanUpdateFile = CanUpdateFile;
      $scope.metadataReadOnly = false;

      $scope.changedFileType = function() {
          //$log.debug("type changed to: " + $scope.file.file_type);
          $scope.file.file_type_metadata = {};
      };

      $scope.openpatientsigdate = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.patientsigdateopened = true;
      };

      $scope.opendoctorsigdate = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.doctorsigdateopened = true;
      };

      UploadsService.getPatientFile($transition$.params().fileid).then(function(data){
          $scope.file = angular.fromJson(data.data);
          try {
              $scope.file.file_type_metadata = angular.fromJson($scope.file.file_type_metadata);
              if ($scope.file.file_type === 'informedconsent') {
                  $scope.patientsig = $scope.file.file_type_metadata.patientsig;
                  $scope.doctorsig = $scope.file.file_type_metadata.doctorsig;
/*
                  if ($scope.patientsig) {
                      $scope.patientsig.fromDataURL($scope.file.file_type_metadata.patientsig);
                      //$scope.patientsig.off();
                  }
                  if ($scope.doctorsig) {
                      $scope.doctorsig.fromDataURL($scope.file.file_type_metadata.doctorsig);
                      //$scope.doctorsig.off();
                  }
*/
                  //$scope.metadataReadOnly = true;
              }
          } catch (e) {
              $scope.file.file_type_metadata = {};
          }
      }, function(err) {
          NotificationService.error('Error loading file',err);
      });

      $scope.update = function() {
          try {
              if ($scope.file.file_type === 'informedconsent') {
                  $scope.file.file_type_metadata.patientsig = $scope.patientsig;
                  $scope.file.file_type_metadata.doctorsig = $scope.doctorsig;

/*
                  if ($scope.patientsig) {
                      $scope.file.file_type_metadata.patientsig = $scope.patientsig.toDataURL();
                  }
                  if ($scope.doctorsig) {
                      $scope.file.file_type_metadata.doctorsig = $scope.doctorsig.toDataURL();
                  }
                  //$scope.metadataReadOnly = true;
*/
              }
              $scope.file.file_type_metadata = angular.toJson($scope.file.file_type_metadata);
          } catch (e) {
          }
          UploadsService.updatePatientFile($transition$.params().fileid, $scope.file).then(function(data){
              $scope.file = angular.fromJson(data.data);
              try {
                  $scope.file.file_type_metadata = angular.fromJson($scope.file.file_type_metadata);
/*
                  if ($scope.file.file_type === 'informedconsent') {
                      if ($scope.patientsig) {
                          $scope.patientsig.clear();
                          $scope.patientsig.fromDataURL($scope.file.file_type_metadata.patientsig);
                      }
                      if ($scope.doctorsig) {
                          $scope.doctorsig.clear();
                          $scope.doctorsig.fromDataURL($scope.file.file_type_metadata.doctorsig);
                      }
                      //$scope.metadataReadOnly = true;
                  }
*/
              } catch (e) {
                  $scope.file.file_type_metadata = {};
              }
              NotificationService.success('Successfully updated file information.');
          }, function(err) {
              NotificationService.error('Error updating file',err);
          });
      };
  }

  angular.module('common.patient-detail-file-detail', [])
    .controller('PatientDetailFileDetailCtrl', patientDetailFileDetailCtrl);
})();

/* jshint undef: false, unused: false */
(function() {
  'use strict';

  function patientDetailTreatmentsCtrl($rootScope, $scope, $transition$, $log, $uibModal, $q,
    AuthenticationService, LocationsService, NotificationService, PatientsService, PrincipalService, ProceduresService,
    TreatmentsService, ConsentFormService,CanRetrieveEncounter,CanUpdateEncounter, AppServicesApi)
  {
    console.debug('patient detail treaments plans');
    $scope.loading = true;
    $scope.edit=false;

    $scope.sortParam = 'treatment_date';

    $scope.patientId = $transition$.params().patientid;

    $scope.CanUpdateEncounter = CanUpdateEncounter;
    $scope.CanRetrieveEncounter = CanRetrieveEncounter;

    $scope.BACKEND_FORMAT_DATE = 'YYYY-MM-DD';

    //obtain info from session
    $scope.providerId = PrincipalService.getAccountId();

    //initialize scope variables
    $scope.treatment = {};
    $scope.treatments = [];
    $scope.procedures = [];
    $scope.providers = [];
    $scope.locations = null;
    $scope.datepickers = { //obj used as an associative array
        newTreatment: false
    };

    //for debugging - add to a temp button's onclick()
    $scope.dumpTreatments = function() {
      var out = '';
      $scope.treatments.forEach(function(treatment) {
        out += treatment.id + ': ' + treatment.procedure_code + ': ' + treatment.procedure_name + '\n';
      })
      $log.debug(out);
    };

    function initializeTreatment() {
      $scope.treatment = {};

      $scope.treatment.calc_amounts = true;
      $scope.treatment.amount_patient = 0.00;
      $scope.treatment.amount_revenue_to_post = 0.00;
      $scope.treatment.amount_fee = 0.00;
      $scope.treatment.insurance_applied = $scope.patient.insurance_plan ? true : false;
      $scope.treatment.other_applied = $scope.patient.other_coverage_plan ? true : false;
      $scope.treatment.insurance_billing_provider_assigned_benefits = $scope.patient.insurance_billing_provider_assigned_benefits;
      $scope.treatment.other_coverage_billing_provider_assigned_benefits = $scope.patient.other_coverage_billing_provider_assigned_benefits;
      $scope.treatment.amount_ins = 0.00;
      $scope.treatment.amount_other = 0.00;
      $scope.treatment.provider = $scope.providerId;
      $scope.treatment.treatment_date = moment().format($scope.BACKEND_FORMAT_DATE); //new Date();

      var provider = $scope.providers.find(function(provider){
        return provider.id === $scope.providerId;
      });
      if (provider === undefined && $scope.encounter.provider) {
        $scope.treatment.provider = $scope.encounter.provider
        provider = $scope.providers.find(function(provider){
          return provider.id === $scope.encounter.provider
        })
      }
      $scope.treatment.location = provider ? provider.location_default : null;//($scope.locations && $scope.locations.length > 0) ? $scope.locations[0].id : null;
      $scope.treatment.status = 'notstarted';
      $scope.treatment.recommendation_level = 'Primary';
      $scope.treatment.procedure_code = '';
      $scope.treatment.procedure_name = '';
      $scope.treatment.type = '';
    }

    $scope.reloadTreatmentsForEncounter = function() {
      $scope.loading = true;
      TreatmentsService.allForEncounter($scope.encounterId).then(function(response) {
        $scope.treatments = syncTreatmentsEditing(angular.fromJson(response.data), $scope.procedures);

        // we $emit a changed event, since the ConsentForm component needs the actual treatments,
        // both scopes are siblings and this info is not exposed on the parent scope, only the encounter object,
        // but we need the treatments data
        // we use $emit instead $broadcast so event remains within the set of $rootScope.$$listeners
        $rootScope.$emit('encounter-treatments-changed',
          _.pluck($scope.treatments, 'id'),
          $scope.consented_treatments.now
        );

        $scope.loading = false;
      }, function(error) {
        $scope.loading = false;
        NotificationService.error('Error reloading treatments',err);
      });
    }


    function reset() {
      if ($scope.mode === 'ENCOUNTER') {
        if ($scope.encounterId) {
          $scope.sortParam = null;
          //retrieve all needed info from endpoints
          $scope.loading = true;
          $q.all([
       		  ProceduresService.getFeesForPatient($scope.patientId),
            AuthenticationService.getPracticeAdminAndProviderAccounts(PrincipalService.getPracticeId(), true),
            LocationsService.all(),
            TreatmentsService.allForEncounter($scope.encounterId),
            PatientsService.get($scope.patientId),
            ConsentFormService.consented_treatments($scope.patientId),
            AuthenticationService.getPractice(PrincipalService.getPracticeId()),
            AppServicesApi.supercode_list().then(function(response){
              // console.log(JSON.stringify(response.data, null, 2))
              return {
                data: response.data.filter(
                  function(supercode){
                    return supercode.is_active
                  }
                ).map(
                  function(supercode){
                    return {
                      id: supercode.id,
                      code: supercode.code,
                      name: supercode.name,
                    }
                  }
                )
              }
            }),
          ])
          .then(function(responses) {
            $scope.procedures = angular.fromJson(responses[0].data).concat(angular.fromJson(responses[7].data));
            $scope.providers = angular.fromJson(responses[1].data);
            $scope.locations = angular.fromJson(responses[2].data);
            $scope.treatments = syncTreatmentsEditing(angular.fromJson(responses[3].data), $scope.procedures);
            $scope.patient = angular.fromJson(responses[4].data);
            $scope.consented_treatments = responses[5].data;
            $scope.practice = responses[6].data;

            // we $emit a changed event, since the ConsentForm component needs the actual treatments,
            // both scopes are siblings and this info is not exposed on the parent scope, only the encounter object,
            // but we need the treatments data
            // we use $emit instead $broadcast so event remains within the set of $rootScope.$$listeners
            $rootScope.$emit('encounter-treatments-changed',
              _.pluck($scope.treatments, 'id'),
              $scope.consented_treatments.now
            );
            return ProceduresService.getFeeprofile($scope.patient.feeprofile);
          }, function(err) {
            $scope.loading = false;
            NotificationService.error('Error loading treatment information',err);
          }).then(function(data){
            $scope.feeprofile = data.data;
            for (var i = 0; i < $scope.treatments.length; i++) {
                $scope.datepickers[$scope.treatments[i].id] = false;
            }
            /* Turn this off so add always shows per Nima's request.
            if ($scope.isTreatmentsAddNotCollapsed) {
                $scope.isTreatmentsAddNotCollapsed = !$scope.isTreatmentsAddNotCollapsed;
            }
            */
            initializeTreatment();

            $scope.loading = false;
          },function(err){
            $scope.loading = false;
            NotificationService.error('Error loading fee profile',err);
          });
        }
      } else if ($scope.mode === 'BILLING') {
        $scope.loading = true;
        $q.all([
          AuthenticationService.getPracticeAdminAndProviderAccounts(PrincipalService.getPracticeId()),
          TreatmentsService.allIsAr($scope.patientId, 'false'),
          PatientsService.get($scope.patientId),
          ConsentFormService.consented_treatments($scope.patientId),
          LocationsService.all()
        ])
        .then(function(responses) {
          $scope.providers = angular.fromJson(responses[0].data);
          $scope.treatments = angular.fromJson(responses[1].data);
          $scope.consented_treatments = responses[3].data;
          $scope.locations = angular.fromJson(responses[4].data);
          $scope.setTreatmentsForInvoicing($scope.treatments, 'includeInInvoice');
          $scope.loading = false;
          return ProceduresService.getFeeprofile(responses[2].data.feeprofile);
        }, function(err) {
          $scope.loading = false;
          NotificationService.error('Error loading billable treatments information',err);
        }).then(function(data){
          $scope.feeprofile = data.data;
        });
      } else {//treatments tab
        $scope.loading = true;

        $q.all([
          AuthenticationService.getPracticeAdminAndProviderAccounts(PrincipalService.getPracticeId()),
          TreatmentsService.allForPatient($scope.patientId),
          PatientsService.get($scope.patientId),
          ConsentFormService.consented_treatments($scope.patientId),
          LocationsService.all()
        ])
        .then(function(responses) {
          $scope.providers = angular.fromJson(responses[0].data);
          $scope.treatments = angular.fromJson(responses[1].data);
          $scope.patient = angular.fromJson(responses[2].data);
          $scope.consented_treatments = responses[3].data;
          $scope.locations = angular.fromJson(responses[4].data);
          $scope.loading = false;
          return ProceduresService.getFeeprofile($scope.patient.feeprofile);
        }, function(err) {
          $scope.loading = false;
          NotificationService.error('Error loading patient\'s treatments information',err);
        }).then(function(data){
          $scope.feeprofile = data.data;
        },function(err){
          NotificationService.error('Error loading fee profile',err);
        });
      }
    }

    if ($scope.mode === 'ENCOUNTER') {
      var EventEncounterLoaded = 'encounterLoaded';

      $scope.$watch('event', function(newVal, oldVal) {
        console.debug('watch');
        if (newVal !== oldVal) {
          if (newVal === EventEncounterLoaded) {
            reset();
          }
        }
      });
    } else {
      reset();
    }

    $rootScope.$on('consent-form-changed', function(event) {
      reset();
    });

    var treatmentsEditingDict = {};

    $scope.isInTreatmentsEditingDict = function(treatmentId) {
      return typeof(treatmentsEditingDict[treatmentId]) !== "undefined";
    }

    $scope.treatmentsEditingDictCount = function() {
      return Object.keys(treatmentsEditingDict).length;
    };

    $scope.addToTreatmentsEditing = function(treatment) {
      treatmentsEditingDict[treatment.id] = {};
      treatmentsEditingDict[treatment.id].copy = angular.copy(treatment);
      treatmentsEditingDict[treatment.id].ref = treatment;
    };

    function removeFromTreatmentsEditing(treatmentId) {
      var ted = treatmentsEditingDict[treatmentId];
      if (ted) {
        delete treatmentsEditingDict[treatmentId];
        return ted;
      } else {
        return null;
      }
    }

    $scope.removeFromTreatmentsEditingReturnCopy = function(treatments, treatment) {
      var ted = removeFromTreatmentsEditing(treatment.id);
      if (ted) {
        var treatmentIndex = treatments.map(function(treatment) { return treatment.id; }).indexOf(treatment.id);
        treatments[treatmentIndex] = ted.copy;
      }
    };

    function syncTreatmentsEditing(treatments, procedures) {
      deactivateTreatmentWatches();
      var tes = {};
      for (var teKey in treatmentsEditingDict) {
        if (treatmentsEditingDict.hasOwnProperty(teKey)) {
          var te = treatmentsEditingDict[teKey];
          var treatmentIndex = treatments.map(function(treatment) { return treatment.id; }).indexOf(te.ref.id);
          if (treatmentIndex > -1) {
            te.copy = treatments[treatmentIndex];
            treatments[treatmentIndex] = te.ref;
            tes[te.ref.id] = te;
          }
        }
      }
      treatmentsEditingDict = tes;
      activateTreatmentWatches(treatments, procedures)
      return treatments;
    }

    $scope.updateTreatmentsEditing = function() {
      if ($scope.treatmentsEditingDictCount() === 0) {
        return;
      }
      $scope.loading = true;
      var errorCount = 0;
      for (var treatmentId in treatmentsEditingDict) {
        if (treatmentsEditingDict.hasOwnProperty(treatmentId)) {
          (function(treatmentId) {
            TreatmentsService.update(treatmentId, treatmentsEditingDict[treatmentId].ref).then(function(data) {
              var te = removeFromTreatmentsEditing(treatmentId);
              if ($scope.treatmentsEditingDictCount() === errorCount) {
                $scope.reloadTreatmentsForEncounter();  //reload will set $scope.loading = false;
              }
              NotificationService.success('Successfully saved treatment ' + te.ref.procedure_code);
            }, function(err) {
              errorCount = errorCount + 1;
              if ($scope.treatmentsEditingDictCount() === errorCount) {
                $scope.reloadTreatmentsForEncounter(); //reload will set $scope.loading = false;
              }
              NotificationService.error('Error updating treatment ' +treatmentsEditingDict[treatmentId].ref.procedure_code,err);
            });
          })(treatmentId);
        }
      }
    };

    $scope.onProviderChange = function(providerId){
      var provider = _.find($scope.providers,function(provider){
        return provider.id === providerId;
      });

      $scope.treatment.location = provider.location_default;
    };

    $scope.update = function(treatment) {
      $scope.loading = true;
      TreatmentsService.update(treatment.id, treatment).then(function(data) {
        //$scope.treatment = angular.fromJson(data.data); //must always refresh to do fee calculations
        if(!isTreatmentFormValid(treatment, "update")) {
          return;
        }
        removeFromTreatmentsEditing(treatment.id);
        $scope.reloadTreatmentsForEncounter(); //reload will set $scope.loading = false;
        NotificationService.success('Successfully saved treatment ' + treatment.procedure_code);
      }, function(err) {
        $scope.loading = false;
        NotificationService.error('Error updating treatment ' + treatment.procedure_code,err);
      });
    };

    $scope.treatmentConsentedStatus = function(treatment_id) {
      if ($scope.consented_treatments.once.includes(treatment_id)) {
        if ($scope.consented_treatments.now.includes(treatment_id)) {
          return {
            class:'icon-icomoon-checkmark5 font-green-jungle',
            text: 'The treatment is included in the latest Consent Form'
          };
        }
        return {
          class:'icon-icomoon-warning2 font-yellow-gold',
          text: 'The treatment was not included in the latest Consent Form. But once was included on a previous.'
        };
      }
      return {
        class:'icon-icomoon-warning2 font-red-thunderbird',
        text: 'The treatment was not included in any Consent Form.'
      };
    };

    let isTreatmentFormValid = (treatment, eventType) => {

      // Can add additional validation as required
      if(treatment.tooth_numbers < 1 || treatment.tooth_numbers > 32) {
        var errorMessage;
        switch(eventType) {
          case "create":
            $scope.addTreatmentForm.toothNumber.$invalid = true;
            errorMessage = "Error creating treatment";
            break;
          case "update":
            $scope.editTreatmentsForm.toothNumber.$invalid = true;
            errorMessage = "Error updating treatment";
            break;
          
          default:
            break;
        }

        NotificationService.error(errorMessage,"Tooth Number should be between 1 and 32");
        $scope.loading = false;

        return false
      }

      return true
    }

    $scope.create = function() {
      $scope.loading = true;
      
      // Form validation
      if(!isTreatmentFormValid($scope.treatment, "create")) {
        return;
      }

      TreatmentsService.create($scope.encounterId, $scope.treatment).then(function(data) {
        initializeTreatment();
        $scope.reloadTreatmentsForEncounter(); //reload will set $scope.loading = false;
        NotificationService.success('Successfully saved treatment.');
      }, function(err) {
        $scope.loading = false;
        NotificationService.error('Error creating treatment',err);
      });
    };

    $scope.onCodeChange = function(code) {
      if (code.procedure) {
        $scope.treatment.type = 'procedure'
      } else {
        $scope.treatment.type = 'supercode'
      }
    }

    var treatmentWatchesDeactivationFunctions = [];

    function deactivateTreatmentWatches() {
      treatmentWatchesDeactivationFunctions.forEach(function(f) { f()});
      treatmentWatchesDeactivationFunctions = [];
    }

    function activateTreatmentWatches(treatments, procedures) {
      for (var i = 0; i < treatments.length; i++) {
        (function(i) {
          treatmentWatchesDeactivationFunctions.push($scope.$watchCollection('treatments[' + i + ']',
            function(newTreatment, oldTreatment){
              if (newTreatment.procedure_code !== oldTreatment.procedure_code) {
                var procedureIndex = procedures.map(function(procedure) { return procedure.code; }).indexOf(newTreatment.procedure_code);
                newTreatment.procedure_name = procedures[procedureIndex].name;
                $log.debug('WATCH FIRED: ' + newTreatment.procedure_code + '-->' + newTreatment.procedure_name);
              }
            }
          ));
        })(i)
      }
    }

    $scope.$watchGroup(['treatment.procedure_code', 'treatment.provider'], function(newVal){
      if (newVal) {
        if (newVal[0]) {
          for (var i = 0; i < $scope.procedures.length; i++) {
            if ($scope.procedures[i].code === newVal[0]) {
              $scope.treatment.procedure_name = $scope.procedures[i].name;
              break;
            }
          }
        }
      }
    });


    //methods for obtaining display information
    $scope.getDisplayForStatus = function(status) {
      if (status === 'notstarted') {
        return 'Not started';
      } else if (status === 'doing') {
        return 'Doing';
      } else if (status === 'done') {
        return 'Done';
      } else if (status === 'wontdo') {
        return 'Won\'t do';
      }
    };

    $scope.getDisplayForInsurancePlanTooltip = function(priPlan, secPlan) {
      var ret = "";
      if (priPlan) {
        ret += "- PRIMARY PLAN: " + priPlan.name + "\n- PRIMARY GROUP NUMBER: " + priPlan.group_id;
        if (priPlan.notes.trim().length > 0) {
          ret += "\n- PRIMARY NOTES:\n" + priPlan.notes;
        }
      }
      if (secPlan) {
        ret += "\n\n- SECONDARY PLAN: " + secPlan.name + "\n- SECONDARY GROUP NUMBER: " + secPlan.group_id;
        if (secPlan.notes.trim().length > 0) {
          ret += "\n- SECONDARY NOTES:\n" + secPlan.notes;
        }
      }
      return ret;
    };

    $scope.getProviderDisplay = function(providerId) {
      for (var i = 0; i < $scope.providers.length; i++)  {
        if ($scope.providers[i].id === providerId) {
          return $scope.providers[i].first_name + ' ' + $scope.providers[i].last_name;
        }
      }
    };

    $scope.getLocationDisplay = function(locationId) {
      for (var i = 0; i < $scope.locations.length; i++)  {
        if ($scope.locations[i].id === locationId) {
          return $scope.locations[i].name;
        }
      }
    };

    // date picker
    $scope.today = function() {
      return moment().format($scope.BACKEND_FORMAT_DATE);
    };

    $scope.statusSelectChanged = function(treatment) {
      if (!treatment.invoiced && (treatment.status === 'doing' || treatment.status === 'done')) {
        treatment.treatment_date = $scope.today();
      }
    };

    $scope.openTreatmentDate = function($event, which) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.datepickers[which] = true;
    };



    //delete treatment modal
    $scope.openDelTreatmentModal = function (treatment) {
      var treatmentId = treatment.id;
      var procedureCode = treatment.procedure_code;

      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'delTreatment.html',
        controller: 'delTreatmentModalInstanceCtrl',
        size: 'sm',
        resolve: {
          procedureCode: function () {
            return procedureCode;
          }
        }
      });

      modalInstance.result.then(function () {
        $scope.doDel(treatmentId);
      }, function () {
      });
    };

    $scope.doDel = function(treatmentId) {
      $scope.loading = true;
      TreatmentsService.delete(treatmentId).then(function() {
         NotificationService.success('Successfully deleted treatment.');
         $scope.reloadTreatmentsForEncounter(); //will call $scope.loading = false;
      }, function(err) {
          NotificationService.error('Error deleting treatment',err);
      });
    };

    // Check eligibility
    $scope.checkEligibility = function (patientId, isForOtherCoverage) {
	    var eligibilityData = null;

      var modalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'checkEligibilityInTreatments.html',
          controller: 'checkEligibilityInTreatmentsModalInstanceCtrl',
          size: 'lg',
          resolve: {
              patientId: function () {
                  return patientId;
              },
              isForOtherCoverage: function () {
                  return isForOtherCoverage;
              }
          }
      });

      modalInstance.result.then(function () {
      }, function () {
      });
    };
  }

    function delTreatmentModalInstanceCtrl($scope, $uibModalInstance, procedureCode) {
      $scope.procedureCode = procedureCode;
      $scope.ok = function () {
        $uibModalInstance.close();
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    }

    function checkEligibilityInTreatmentsModalInstanceCtrl(
      $scope, $uibModalInstance, BillingService, patientId, isForOtherCoverage,
      NotificationService, PatientsService, $q)
    {
      $scope.isForOtherCoverage = isForOtherCoverage;

      $scope.error = null;
      $scope.working = true;

	    $q.all([
     		BillingService.getEligibility(patientId, isForOtherCoverage),
          PatientsService.get(patientId)
	    ])
	    .then(function(responses) {
        $scope.working = false;
        $scope.error = null;
        $scope.eligibilityData = angular.fromJson(responses[0].data);
        $scope.html_div = $scope.eligibilityData.html_div;
        delete $scope.eligibilityData.html_div;
        $scope.patient = angular.fromJson(responses[1].data);
	    }, function(err) {
        $scope.working = false;
        NotificationService.error('Error retrieving eligibility information', err);
	    });

      $scope.ok = function () {
        $uibModalInstance.close();
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    }

  angular.module('common.patient-detail-treatments', [])
    .controller('PatientDetailTreatmentPlansCtrl', patientDetailTreatmentsCtrl)
    .controller('delTreatmentModalInstanceCtrl', delTreatmentModalInstanceCtrl)
    .controller('checkEligibilityInTreatmentsModalInstanceCtrl', checkEligibilityInTreatmentsModalInstanceCtrl);
})();

/*global _ */
(function() {
  'use strict';

  function patientDetailCtrl(
    $q,
    $rootScope,
    $scope,
    $state,
    $transition$,
    $timeout,
    $uibModal,
    $window,
    $log,
    AppServicesApi,
    AuthenticationService,
    CanCreateInsurancePlan,
    CanUpdateInsurancePlan,
    CanUpdatePatient,
    InsuranceService,
    NotificationService,
    PatientsService,
    PatientService,
    PrincipalService,
    ProceduresService,
    TextTemplateService, 
    WebsocketService
  ) {
    $scope.$state = $state;
    $scope.patientId = $transition$.params().patientid;

    $scope.patient = [];
    $scope.CanUpdatePatient = CanUpdatePatient;
    $scope.loadingCount = 0;

    $scope.clearSignature = function(patient,field){
      patient[field] = null;
      //We need to set it to '' so allow_blank=True works fine as is the default on django and not null
      //and null is the only way to clear out the signature on the component
      $timeout(function(){
        patient[field] = '';
      },1);

    };

    // Date format for AngularUi datepicker
    $scope.ngUiFormat = 'MM-dd-yyyy';

    // This is a helper function used for creating a ng-repeat of num times.
    $scope.getNumber = function(num) {
      return new Array(num);
    };

    if(!$scope.isAnonymous()){
      // setup socket observer to listen for updates or deletion of this encounter
      //begin websocket observer setup
      var scope = $scope;
      var strm = 'notification';
      var cmds_observed = [
        'model_update_patients_models_patient_patient',
        'model_delete_patients_models_patient_patient',
      ];
      scope.notificationObservableId = WebsocketService.observe(
        strm,
        cmds_observed,
        function(stream, cmd, params) {
          $log.debug('Websocket message received in PATIENT with ID: ' + scope.notificationObservableId + '; STREAM: ' + stream + '; CMD: ' + cmd + '; PARAMS: ' + JSON.stringify(params));
          if ($scope.patient.id && params.id.toString() === $scope.patient.id.toString() && params.saverid && PrincipalService.getSaverid() !== params.saverid) {
            reloadQuestion();
          }
        }
      );
      $log.debug('Websocket observer registered in PATIENT with ID: ' + scope.notificationObservableId + '; STREAM: ' + strm + '; CMDS_OBSERVED: ' + JSON.stringify(cmds_observed));
      $scope.$on('$destroy', function() {
        WebsocketService.unobserve(scope.notificationObservableId);
        $log.debug('Websocket observer unregistered in PATIENT with ID: ' + scope.notificationObservableId);
      });
      //end websocket observer setup
    }

    function reloadQuestion() {
      swal({
        title: 'Record Saved By Another User',
        text: [
          "This record was just updated by another user session.",
          "Changes saved by the other user session since you loaded this record will be overwritten if you update.",
          "Also, if you reload now you will lose any changes you made to this record since you last loaded it.",
          "If you have made changes, press cancel, note your changes, reload this page, then apply your changes"
        ].join(" "),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Reload (CAUTION)',
        closeOnConfirm: true,
        allowOutsideClick: true
      }, function () {
        scope.reset();
      });
    }

    $scope.updateMugshot = function(file) {
      if (file) {
        var origMugshot = $scope.patient.mugshot;
        var newMugshot = URL.createObjectURL(file);
        $scope.uploading = true;
        $scope.patient.mugshot = newMugshot;
        PatientService.upload($scope.patient, {mugshot: file}).then(
          function() {
            delete $scope.patient.$mugshot_progress;
          },
          function() {
            delete $scope.patient.$mugshot_status;
            $scope.patient.mugshot = origMugshot;
          }
        );
      }
    };

    $scope.planDataChange = function(data) {
      data.insurance_plan = '';
      data.max_annual = {};
      data.max_lifetime = {};
      data.ded_preventative = {};
      data.ded_major = {};
      data.ded_basic = {};
      data.ded_orthodontic = {};
    };

    $scope.onInsurancePlanChange = function(patientId, insuranceData,updateMaximum, updateDeductibles) {
      if(!insuranceData.insurance_plan){
        insuranceData.insurance_type = 'none';
        insuranceData.insurance_company_name = '';
        insuranceData.insurance_plan_name = '';
        insuranceData.insurance_group_id = '';
        insuranceData.insurance_company_address_street_1 = '';
        insuranceData.insurance_company_address_street_2 = '';
        insuranceData.insurance_company_address_city = '';
        insuranceData.insurance_company_address_state = '';
        insuranceData.insurance_company_address_zipcode = '';
        insuranceData.insurance_company_phone = '';
        insuranceData.max_annual = {};
        insuranceData.max_lifetime = {};
        insuranceData.ded_preventative = {};
        insuranceData.ded_major = {};
        insuranceData.ded_basic = {};
        insuranceData.ded_orthodontic = {};
        insuranceData.amount_remaining_reset_date = null;
        $scope.planSelectionChanged = true;
        return;
      }

      var plan = _.find($scope.plans,function(plan){return plan.id === parseInt(insuranceData.insurance_plan);});

      insuranceData.insurance_type = plan.payer_type;
      insuranceData.insurance_company_name = plan.payer_name;
      insuranceData.insurance_plan_name = plan.name;
      insuranceData.insurance_group_id = plan.group_id;
      insuranceData.insurance_company_address_street_1 = plan.address_street_1;
      insuranceData.insurance_company_address_street_2 = plan.address_street_2;
      insuranceData.insurance_company_address_city = plan.address_city;
      insuranceData.insurance_company_address_state = plan.address_state;
      insuranceData.insurance_company_address_zipcode = plan.address_zip_code;
      insuranceData.insurance_company_phone = plan.phone;

      if(updateMaximum){
        $scope.getPatientMaximums(patientId, insuranceData);
      }
      if(updateDeductibles){
        $scope.getPatientDeductibles(patientId, insuranceData);
      }
      $scope.planSelectionChanged = true;
    };

    /**
     * 
     * @param {*} toPatientInsuranceData The data structure to put the deductible information in.
     * @param {*} fromPatientInsuranceData if this is defined, pull maximum information from this, else get it from the back end.
     * @returns 
     */
    $scope.getPatientMaximums = function(patientId, toPatientInsuranceData, fromPatientInsuranceData){
      toPatientInsuranceData.max_annual = {};
      toPatientInsuranceData.max_lifetime = {};

      if(!toPatientInsuranceData.insurance_plan){
        return;
      }

      if (fromPatientInsuranceData) {
        toPatientInsuranceData.max_annual = fromPatientInsuranceData.max_annual;
        toPatientInsuranceData.max_lifetime = fromPatientInsuranceData.max_lifetime;
        toPatientInsuranceData.amount_remaining_reset_date = fromPatientInsuranceData.amount_remaining_reset_date;
        return;
      }

      $scope.loadingCount++;
      InsuranceService.getPatientMaximums(patientId,toPatientInsuranceData.insurance_plan).then(function(event){
        if(event.data instanceof Array && event.data.length === 0){
          $scope.loadingCount--;
          return;
        }
        var data_annual = _.find(event.data,function(data){return data.maximum.resets;});
        var data_lifetime = _.find(event.data,function(data){return data.maximum.resets === false;});
        var data_amount_remaining_reset_date = data_annual.amount_remaining_reset_date;

        toPatientInsuranceData.max_annual = data_annual;
        toPatientInsuranceData.max_lifetime = data_lifetime;

        toPatientInsuranceData.amount_remaining_reset_date = data_amount_remaining_reset_date;
        $scope.loadingCount--;

      },function(err){
        $scope.loadingCount--;
        NotificationService.error('Error loading patient maximums',err);
      });

    };

    /**
     * 
     * @param {*} toPatientInsuranceData The data structure to put the deductible information in.
     * @param {*} fromPatientInsuranceData if this is defined, pull deductible information from this, else get it from the back end.
     * @returns 
     */
    $scope.getPatientDeductibles = function(patientId, toPatientInsuranceData, fromPatientInsuranceData){
      toPatientInsuranceData.ded_preventative = {};
      toPatientInsuranceData.ded_basic = {};
      toPatientInsuranceData.ded_major = {};
      toPatientInsuranceData.ded_orthodontic = {};

      if(!toPatientInsuranceData.insurance_plan){
        return;
      }

      if (fromPatientInsuranceData) {
        toPatientInsuranceData.ded_preventative = fromPatientInsuranceData.ded_preventative;
        toPatientInsuranceData.ded_basic = fromPatientInsuranceData.ded_basic;
        toPatientInsuranceData.ded_major = fromPatientInsuranceData.ded_major;
        toPatientInsuranceData.ded_orthodontic = fromPatientInsuranceData.ded_orthodontic;
        return;
      }

      $scope.loadingCount++;
      InsuranceService.getPatientDeductibles(patientId,toPatientInsuranceData.insurance_plan).then(function(event){
        var data_preventative = _.find(event.data,function(data){return data.deductible.name === 'Preventative';});
        var data_basic = _.find(event.data,function(data){return data.deductible.name === 'Basic';});
        var data_major = _.find(event.data,function(data){return data.deductible.name === 'Major';});
        var data_orthodontic = _.find(event.data,function(data){return data.deductible.name === 'Orthodontic';});

        toPatientInsuranceData.ded_preventative = data_preventative;
        toPatientInsuranceData.ded_basic = data_basic;
        toPatientInsuranceData.ded_major = data_major;
        toPatientInsuranceData.ded_orthodontic = data_orthodontic;
        $scope.loadingCount--;

      },function(err){
        $scope.loadingCount--;
        NotificationService.error('Error loading patient deductibles',err);
      });

    };


    //Only needed for anonymous form
    $scope.assignBenefitsChange = function(){

    };

    $scope.medicalHistoryToogleFields = ['isInGoodHealth','isUnderPhysicianCare','isRecommendedAntibiotics','isHospitalizedPastFiveYears',
      'isHadGeneralAnesthesia','isHadReactionAnesthesia'];

    $scope.$watch('patient.mugshot', function(value, oldValue) {
      if (value !== oldValue) {
        $scope.$emit('mugshot-changed', value);
      }
    });


    $scope.$watch(function(){ return $scope.patient.markAllMedicalHistory; }, function(newValue){
      if(newValue === undefined){
        return;
      }

      $scope.medicalHistoryToogleFields.forEach(function (field) {
        $scope.patient[field] = newValue;
      });
    },true);


    $scope.medicalHistoryConditions1ToogleFields = ['isRheumticFever','isHighBloodPressure','isLowBloodPressure','isMitralValveProlapse',
      'isHeartMurmur','isChestPainAngina','isHeartAttacks','isIrregularHeartBeat','isCardiacPacemaker','isHeartSurgery','isDamagedHeartValves','isPneumonia','isChronicFatigue',
      'isTroubleClimbingStairs','isAnemia','isAsthma','isBleedingTendency','isBloodTransfusion','isBloodDisorder','isBruiseEasily','isEyeDisease','isLiverDisease','isHepatitis',
    'isGallbladderTrouble','isFaintingSpells','isConvulsionsEpilepsy','isStroke','isThyroidTrouble','isDiabetes','isLowBloodSugar','isOnDialysis'];

    $scope.$watch(function(){ return $scope.patient.markAllMedicalHistoryConditions1; }, function(newValue){
      if(newValue === undefined){
        return;
      }
      $scope.medicalHistoryConditions1ToogleFields.forEach(function (field) {
        $scope.patient[field] = newValue;
      });
    },true);


    $scope.medicalHistoryConditions2ToogleFields = ['isAids','isHiv','isKidneyTrouble','isMentalHealthProblems','isImmuneSystemProblems','isDelayInHealing','isSinusProblems','isSnoring','isSleepApnea',
    'isRespiratoryProblems','isTuberculosis','isEmphysema','isSmoker','isUsingChewingTobacco','isHasDrugAbuseHistory','isHasAlcoholAbuseHistory','isAbnormalBleeding','isSexuallyTransmittedDisease',
    'isContagiousDiseases','isInfectiousMononucleosis','isSwollenAnkles','isArthritis','isProstheticImplant','isJointReplacement','isOsteoporosis','isOsteonecrosis','isStomachUlcers','isTumorGrowth',
      'isCancer','isOnDiet','isPregnant'];

    $scope.$watch(function(){ return $scope.patient.markAllMedicalHistoryConditions2; }, function(newValue){
      if(newValue === undefined){
        return;
      }
      $scope.medicalHistoryConditions2ToogleFields.forEach(function (field) {
        $scope.patient[field] = newValue;
      });
    },true);


    $scope.dentalInformationToogleFields = ['isInDiscomfort','isSwollenGums','isRemovableAppliance','isBlistersSores','isProlongedBleeding','isRecentInfections','isSensitiveToHot','isSensitiveToCold',
    'isSensitiveToSweets','isSensitiveToBiting','isStainedTeeth','isLockingJaw','isBadBreath','isToothache','isBurningTongue','isLostBrokenFillings','isTeethGrindingClenching','isBrokenChippedTooth',
      'isDifficultyClosingJaw','isDifficultyOpeningJaw','isLooseShiftingTeeth','isFoodCaughtBetweenTeeth','isSwellingLumpInMouth','isOther'];

    $scope.$watch(function(){ return $scope.patient.markAllDentalInformationPart2; }, function(newValue){
      if(newValue === undefined){
        return;
      }
      $scope.dentalInformationToogleFields.forEach(function (field) {
        $scope.patient[field] = newValue;
      });
    },true);


    $scope.medicationTakingToogleFields = ['isTakingNervePills','isTakingDietPills','isTakingTranquilizers','isTakingMuscleRelaxers','isTakingInsulin','isTakingStimulants','isTakingAntidepressants',
      'isTakingBloodThinners','isTakingBoneDensityMeds','isTakingAntibiotic','isTakingValium'];

    $scope.$watch(function(){ return $scope.patient.markAllMedicationTaking; }, function(newValue){
      if(newValue === undefined){
        return;
      }
      $scope.medicationTakingToogleFields.forEach(function (field) {
        $scope.patient[field] = newValue;
      });
    },true);


    $scope.allergicToogleFields = ['isHasKnownAllergies','isAllergicToSoy','isAllergicToSulfaDrugs','isAllergicToAspirin','isAllergicToEggs','isAllergicToLocalAnesthetic','isAllergicToCodeine',
      'isAllergicToSulfites','isAllergicToAmoxicillin','isAllergicToLatex'];

    $scope.$watch(function(){ return $scope.patient.markAllAllergic; }, function(newValue){
      if(newValue === undefined){
        return;
      }
      $scope.allergicToogleFields.forEach(function (field) {
        $scope.patient[field] = newValue;
      });
    },true);

    $scope.getPatientData = function(){
      if($scope.patientId){
        return PatientsService.get($scope.patientId);
      }
      return $q.resolve(false);
    };


    //Sets and updates the patient form with the raw patient Data
    $scope.updatePatientForm = function(rawPatientData, omitGetPatientMaximumsAndDeductiblesFromServer){
      rawPatientData.extensions = PatientsService.mergeExtensions(rawPatientData.extensions, $scope.practice.extensions_template);
      PatientsService.setExtensionDates(rawPatientData.extensions, true);
      
      var patientData = PatientsService.allSnakecaseToCamelcase(rawPatientData);
      var updatedPatient = PatientsService.insuranceCamelcaseToInsurancedataSnakecase(patientData);
      $scope.checkHealthInsurance(updatedPatient);
      if (!omitGetPatientMaximumsAndDeductiblesFromServer || !$scope.patient) {
        $scope.getPatientMaximums(updatedPatient.id, updatedPatient.insuranceData);
        $scope.getPatientDeductibles(updatedPatient.id, updatedPatient.insuranceData);
        $scope.getPatientMaximums(updatedPatient.id, updatedPatient.otherInsuranceData);
        $scope.getPatientDeductibles(updatedPatient.id, updatedPatient.otherInsuranceData);
      } else {
        $scope.getPatientMaximums(updatedPatient.id, updatedPatient.insuranceData, $scope.patient.insuranceData);
        $scope.getPatientDeductibles(updatedPatient.id, updatedPatient.insuranceData, $scope.patient.insuranceData);
        $scope.getPatientMaximums(updatedPatient.id, updatedPatient.otherInsuranceData, $scope.patient.otherInsuranceData);
        $scope.getPatientDeductibles(updatedPatient.id, updatedPatient.otherInsuranceData, $scope.patient.otherInsuranceData);
      }
      $scope.patient = updatedPatient;
      $rootScope.$emit("patient-changed", $scope.patient);
    };

    $scope.reset = function() {
      $scope.loadingCount++;
      $q.all([
        InsuranceService.getPlansForPractice(PrincipalService.getPracticeId()),
        $scope.getPatientData(),
        AuthenticationService.getPractice(PrincipalService.getPracticeId()),
        AppServicesApi.feeprofile_for_patient($scope.patientId),
        InsuranceService.getPayers(),
        TextTemplateService.getTextTemplateForPractice('Patient Signature Text'),
        TextTemplateService.getTextTemplateForPractice('Fees and Payments Text')
      ]).then(
      function(values){
        $scope.plans = values[0].data;
        $scope.practice = values[2].data;
        $scope.updatePatientForm(values[1].data, false); //false: get patient maximums and deductibles
        $scope.feeprofiles = values[3].data;
        $scope.payers = values[4].data;
        $scope.signatureText = values[5].data.content;
        $scope.feeAndPaymentTitle = values[6].data.title;
        $scope.feeAndPaymentText = values[6].data.content;
  /*
        $timeout(function(){
          $scope.plans = values[0].data;
          $scope.updatePatientForm(values[1].data);
        },1);
  */
        if(!$scope.patient.feeprofile){
          $scope.patient.feeprofile = $scope.feeprofiles[0].id;
        }
        $scope.planSelectionChanged = false;
        if (elvisEx($scope.patient, "hmac.valid", null)) {
          NotificationService.success('Completed loading patient data.');
        }
        else {
          NotificationService.warning("Completed loading patient data with warnings.")
        }
        $scope.loadingCount--;
      },
      function(err){
        $scope.loadingCount--;
        NotificationService.error('Error loading patient form',err);
      });
    };
    $scope.reset();

    $scope.checkHealthInsurance = function(patient){
      patient.hasHealthInsurance = false;

      if( patient.insuranceData.insurance_plan || patient.otherInsuranceData.insurance_plan ) {
         patient.hasHealthInsurance = true;
         return;
      }

      if(!_.isEmpty(patient.insuranceDataProvidedByPatient)){
        patient.hasHealthInsurance = true;
      }
    };

    $scope.canEditPatientInfo = function() {
      //Ignore if is  anonymous
      if($scope.isAnonymous()){
        return false;
      }
     return CanUpdatePatient;
    };

    $scope.showDataProvidedByPatient = function() {
      $window.open('insurance-data-provided-by-patient/'+$scope.patient.id, '_blank');
    };

    //for triggering submit from outside of form so button can fit skin.
    $scope.triggerSubmit = function() {
      $scope.submit();
    };

    $scope.updatePatientMaximum = function (max, amount_remaining_reset_date) {
      $scope.loadingCount++;

      if (
        amount_remaining_reset_date &&
        typeof amount_remaining_reset_date !== 'undefined' &&
        moment(amount_remaining_reset_date, "YYYY-MM-DD").isValid()
      ){
        max.amount_remaining_reset_date = moment(amount_remaining_reset_date).format('YYYY-MM-DD');
      }

      InsuranceService.updatePatientMaximum(max.id,max).then(function(){
        $scope.loadingCount--;
      },function(err){
        $scope.loadingCount--;
        NotificationService.error('Error updating patient maximum',err);
      });

    };

    $scope.updatePatientDeductible = function (ded, amount_remaining_reset_date) {
      $scope.loadingCount++;

      if(
        amount_remaining_reset_date &&
        typeof amount_remaining_reset_date !== 'undefined' &&
        _.isDate(amount_remaining_reset_date)
      ){
        ded.amount_remaining_reset_date = moment(amount_remaining_reset_date).format('YYYY-MM-DD');
      }

      InsuranceService.updatePatientDeductible(ded.id,ded).then(function(){
        $scope.loadingCount--;
      },function(err){
        $scope.loadingCount--;
        NotificationService.error('Error updating patient deductible',err);
      });

    };

    $scope.submit = function() {
      $scope.loadingCount++; //update
      PatientsService.insuranceDataSnakecaseToSnakecase($scope.patient);
      var newPatient = angular.copy($scope.patient);

      if (newPatient.title === null) {
        newPatient.title = "no"
      }

      PatientsService.setExtensionDates(newPatient.extensions, false);
      newPatient.extensions = angular.toJson(newPatient.extensions);
      PatientsService.update($scope.patientId, newPatient).then(
        function success(event) {
          if (!$scope.planSelectionChanged) {
            if(!_.isEmpty($scope.patient.insuranceData.max_annual)){
              $scope.updatePatientMaximum($scope.patient.insuranceData.max_annual, $scope.patient.insuranceData.amount_remaining_reset_date);
            }

            if(!_.isEmpty($scope.patient.insuranceData.max_lifetime)){
              $scope.updatePatientMaximum($scope.patient.insuranceData.max_lifetime);
            }

            if(!_.isEmpty($scope.patient.insuranceData.ded_preventative)){
              $scope.updatePatientDeductible($scope.patient.insuranceData.ded_preventative, $scope.patient.insuranceData.amount_remaining_reset_date);
            }

            if(!_.isEmpty($scope.patient.insuranceData.ded_basic)){
              $scope.updatePatientDeductible($scope.patient.insuranceData.ded_basic, $scope.patient.insuranceData.amount_remaining_reset_date);
            }
            if(!_.isEmpty($scope.patient.insuranceData.ded_major)){
              $scope.updatePatientDeductible($scope.patient.insuranceData.ded_major, $scope.patient.insuranceData.amount_remaining_reset_date);
            }

            if(!_.isEmpty($scope.patient.insuranceData.ded_orthodontic)){
              $scope.updatePatientDeductible($scope.patient.insuranceData.ded_orthodontic, $scope.patient.insuranceData.amount_remaining_reset_date);
            }

            if(!_.isEmpty($scope.patient.otherInsuranceData.max_annual)){
              $scope.updatePatientMaximum($scope.patient.otherInsuranceData.max_annual, $scope.patient.otherInsuranceData.amount_remaining_reset_date);
            }

            if(!_.isEmpty($scope.patient.otherInsuranceData.max_lifetime)){
              $scope.updatePatientMaximum($scope.patient.otherInsuranceData.max_lifetime);
            }

            if(!_.isEmpty($scope.patient.otherInsuranceData.ded_preventative)){
              $scope.updatePatientDeductible($scope.patient.otherInsuranceData.ded_preventative, $scope.patient.otherInsuranceData.amount_remaining_reset_date);
            }

            if(!_.isEmpty($scope.patient.otherInsuranceData.ded_basic)){
              $scope.updatePatientDeductible($scope.patient.otherInsuranceData.ded_basic, $scope.patient.otherInsuranceData.amount_remaining_reset_date);
            }
            if(!_.isEmpty($scope.patient.otherInsuranceData.ded_major)){
              $scope.updatePatientDeductible($scope.patient.otherInsuranceData.ded_major, $scope.patient.otherInsuranceData.amount_remaining_reset_date);
            }

            if(!_.isEmpty($scope.patient.otherInsuranceData.ded_orthodontic)){
              $scope.updatePatientDeductible($scope.patient.otherInsuranceData.ded_orthodontic, $scope.patient.otherInsuranceData.amount_remaining_reset_date);
            }
          }
          if (!$scope.planSelectionChanged) {
            $scope.updatePatientForm(event.data, true); //true: omit getting patient deductibles and maximums so there isn't a race condition with the updates
                                                        // since the records have already been retrieved and planselection hasn't changed.
          } else {
            $scope.updatePatientForm(event.data, false); //false: get patient deductibles and maximums since plan selection has changed.
          }
          $scope.planSelectionChanged = false;
          $scope.loadingCount--;
          NotificationService.success('Patient ' + $scope.patient.lastName + ' updated sucessfully');
        },
        function fail(err) {
          $scope.loadingCount--;
          NotificationService.error('Error adding patient',err);
        });
    };

    $scope.openAddPlan = function(patientId, insuranceData, feeprofiles, payers) {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'src/common/settings-insurance-plans-add.tpl.html',
        controller: 'InsurancePlansAddCtrl as insurancePlansAdd',
        size: 'lg',
        resolve: {
          listPlan: function () {
            return undefined;
          },
          feeprofiles: function () {
            return feeprofiles;
          },
          payers: function () {
            return payers;
          },
          CanCreateInsurancePlan:CanCreateInsurancePlan,
          CanUpdateInsurancePlan:CanUpdateInsurancePlan
        }
      });

      modalInstance.result.then(function (data) {
        if(!data){
          return;
        }
        $scope.plans.push(data);
        $timeout(function(){
          insuranceData.insurance_plan = data.id;
          $scope.onInsurancePlanChange(patientId, insuranceData,true, true);
        },1);
      }, function () {
      });


    };

    $scope.sendPatientPortalEmail = function(id) {
      PatientService.sendPatientPortalEmail(id).then(
        function() {
          NotificationService.success("Email successfully sent!")
        },
        function(error) {
          NotificationService.error("Error sending email.", error)
        }
      )
    }

    $scope.openSendEmailModal = function(patient) {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        size: "md",
        templateUrl: "#send-form-to-patient-modal",
        controller: function($scope, PatientService, NotificationService) {
          var $mctrl = this
          $mctrl.data = {
            use_pin: patient.mobilePhone ? true : false,
          }
          $mctrl.patient = patient
          $mctrl.send = function() {
            PatientService.sendForm(patient.id, $mctrl.data).then(
              function(response) {
                $scope.$close(response)
              },
              function(error) {
                NotificationService.error("Error sending this form to patient.", error)
              }
            )
          }
        },
      }).result.then(
        function(response) {
          // eml: user@email.com         -> email was sent
          // pin: 789756                 -> pin was used
          // sms: +11234567890           -> sms was sent (with pin)
          // err: {email: "X", sms: "Y"} -> errors related to emsg and smsg send action
          if (response.data.eml) {
            if (response.data.pin) {
              swal({
                allowOutsideClick: false,
                confirmButtonText: "Understood",
                html: [
                  '<h4 class="text-info">',
                  (response.data.sms
                    ? [
                      "PIN <b>" + response.data.pin + "</b>",
                      "texted to patient's mobile phone <b>" + response.data.sms + "</b>",
                    ]
                    : [
                      "Could not successfully sent text to patient mobile phone.",
                      "Please provide PIN <b>" + response.data.pin + "</b> to the patient through means other than email.",
                    ]
                  ).join("<br/><br/>"),
                  '</h4>',
                ].join(""),
                title: "Form sent to patient",
                type: "success",
              })
            }
            else {
              NotificationService.success("Email successfully sent!")
            }
          }
          else {
            NotificationService.error("Error sending form to patient.", {detail: response.data.err})
          }
        }
      )
    }

    $scope.getDisplayForInsurancePlanTooltip = function(priPlan, secPlan) {
      var ret = "";
      if (priPlan) {
        ret += "- PRIMARY PLAN: " + priPlan.name + "\n- PRIMARY GROUP NUMBER: " + priPlan.group_id;
        if (priPlan.notes.trim().length > 0) {
          ret += "\n- PRIMARY NOTES:\n" + priPlan.notes;
        }
      }
      if (secPlan) {
        ret += "\n\n- SECONDARY PLAN: " + secPlan.name + "\n- SECONDARY GROUP NUMBER: " + secPlan.group_id;
        if (secPlan.notes.trim().length > 0) {
          ret += "\n- SECONDARY NOTES:\n" + secPlan.notes;
        }
      }
      return ret;
    };
    
    // Check eligibility
    $scope.checkEligibility = function (patientId, isForOtherCoverage) {
      // var eligibilityData = null;

      var modalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'checkEligibilityInTreatments.html',
          controller: 'checkEligibilityInTreatmentsModalInstanceCtrl',
          size: 'lg',
          resolve: {
              patientId: function () {
                  return patientId;
              },
              isForOtherCoverage: function () {
                  return isForOtherCoverage;
              }
          }
      });

      modalInstance.result.then(function () {
      }, function () {
      });
    };

    $scope.fillInsuranceDataFromPatient = function(patient, destAttr) {
      var attrsMap = [
        ["insurance_subscriber_first_name", "firstName"],
        ["insurance_subscriber_last_name", "lastName"],
        ["insurance_subscriber_address_street_1", "patientAddressStreet1"],
        ["insurance_subscriber_address_street_2", "patientAddressStreet2"],
        ["insurance_subscriber_address_city", "patientAddressCity"],
        ["insurance_subscriber_address_state", "patientAddressState"],
        ["insurance_subscriber_address_zipcode", "patientAddressZipCode"],
        ["insurance_subscriber_birth_date", "birthDate"],
        ["insurance_subscriber_gender", "gender"],
        ["insurance_subscriber_phone", "homePhone"],
        ["insurance_subscriber_ssn", "ssn"],
      ]
      var i;
      if (patient[destAttr].insurance_subscriber_party_relationship === "self") {
        for (i = 0; i < attrsMap.length; i++) {
          patient[destAttr][attrsMap[i][0]] = patient[attrsMap[i][1]]
        }
      }
      else {
        for (i = 0; i < attrsMap.length; i++) {
          delete patient[destAttr][attrsMap[i][0]]
        }
      }
    }
  }

  angular.module('common.patient-detail-profile', [])
    .controller('PatientDetailProfileCtrl', patientDetailCtrl);
})();

(function() {
  'use strict';

  function patientInteractionsCtrl($scope, $transition$,CanUpdatePatient,CanCreateReviewRequest, PatientInteractionsService,PrincipalService,NotificationService,$uibModal, ReviewsService) {

    $scope.patientId = $transition$.params().patientid;
    $scope.CanUpdatePatient = CanUpdatePatient;
    $scope.CanCreateReviewRequest = CanCreateReviewRequest;

    $scope.practiceId = PrincipalService.getPracticeId();
    $scope.userName  = PrincipalService.getUsername();

    var EVENT_TYPE_APPOINTMENT_STATUS_CHANGE = 100;
    var EVENT_TYPE_APPOINTMENT_CHANGE = 110;
    var EVENT_TYPE_APPOINTMENT_CREATE = 120;
    var EVENT_TYPE_APPOINTMENT_DELETE = 130;
    var EVENT_TYPE_STAFF_NOTE = 400;
    var EVENT_TYPE_REMINDER_SENT = 200;
    var EVENT_TYPE_INSURANCE_DATA_CHANGE_BY_PATIENT = 500;
    var EVENT_TYPE_REQUEST_FOR_REVIEW_SENT = 600;
    var EVENT_TYPE_REVIEW_RECEIVED = 700;
    var EVENT_TYPE_REVIEW_UPDATED = 800;
    var EVENT_TYPE_FORM_SENT = 900;
    var EVENT_TYPE_FORM_SENT_END = 901;
    var EVENT_TYPE_KIOSK_FORM_LOAD = 902;
    var EVENT_TYPE_KIOSK_FORM_SUBMIT = 903;


    $scope.EVENT_TYPES = {
      100 : 'Appointment Status Changed',
      110 : 'Appointment Changed',
      120:  'Appointment Created',
      130:  'Appointment Deleted',
      400 : 'Staff Note',
      200 : 'Reminder Sent',
      500 : 'Insurance Data Provided by Patient has been Changed',
      600 : 'Request for review sent to patient',
      700 : 'Review received from patient',
      800 : 'Review from patient updated',
      900 : 'Patient form sent',
      901 : 'Patient form sent end',
      902 : 'Kiosk loaded Patient',
      903 : 'Kiosk submit Patient',
    };

    $scope.EVENT_TYPE_FORM_SENT = EVENT_TYPE_FORM_SENT;

    $scope.requestReview = function(){
      ReviewsService.requestReview($scope.patientId).then(function () {
        $scope.getAllInteractions();
        NotificationService.success('Successfully sent a request for a review to the patient.');
      }, function (error) {
        NotificationService.error("Error sending request to patient.",error);
      });
    };

    $scope.getAllInteractions = function(){
      PatientInteractionsService.all($scope.patientId).then(function(event){
        $scope.patientEvents = event.data;
      },function(error){
        NotificationService.error('Error Loading Patient Interactions',error);
      });
    };


    $scope.getAllInteractions();

    $scope.typeTitle = function(code){
      return $scope.EVENT_TYPES[code];
    };

    $scope.formattedInteraction = function(event){
      var data = '';
      if(event.type === EVENT_TYPE_APPOINTMENT_STATUS_CHANGE){
        data = '<span><b>Appointment Date:</b> ' + moment(event.data.appointment_startdate).format('MM/DD/YYYY h:mma')+ '</span><br>';

        if(event.data.old_status){
          data += '<span><b>Old Status:</b> '+ event.data.old_status +'</span> ';
        }

        data += '<span><b>New Status:</b> '+ event.data.new_status +'</span>';

        return data;
      }

      if(event.type === EVENT_TYPE_APPOINTMENT_CHANGE){
        data = '<span><b>Appointment Date:</b> ' + moment(event.data.appointment_startdate).format('MM/DD/YYYY h:mma')+ '</span><br>';

        data += '<span><b>Appointment changes:</b> '+ event.data.appointment_changes +'</span>';

        return data;
      }

      if(event.type === EVENT_TYPE_APPOINTMENT_CREATE){
        data = '<span><b>Appointment Date:</b> ' + moment(event.data.appointment_startdate).format('MM/DD/YYYY h:mma')+ '</span><br>';

        data += '<span><b>New appointment information:</b> '+ event.data.appointment_info +'</span>';

        return data;
      }

      if(event.type === EVENT_TYPE_APPOINTMENT_DELETE){
        data = '<span><b>Appointment Date:</b> ' + moment(event.data.appointment_startdate).format('MM/DD/YYYY h:mma')+ '</span><br>';

        data += '<span><b>Deleted appointment information:</b> '+ event.data.appointment_info +'</span>';

        return data;
      }

      if(event.type === EVENT_TYPE_STAFF_NOTE) {
        data = '<span>' + event.data.note   + '</span>';
        return data;
      }

      if(event.type === EVENT_TYPE_REMINDER_SENT){
        data = '<span><b>Appointment Date:</b> ' + moment(event.data.appointment_startdate).format('MM/DD/YYYY h:mma')+ '</span><br>';
        return data;
      }

      if(event.type === EVENT_TYPE_INSURANCE_DATA_CHANGE_BY_PATIENT){
        data = '';
        return data;
      }

      if(event.type === EVENT_TYPE_REQUEST_FOR_REVIEW_SENT) {
        data = '';
        return data;
      }

      if(event.type === EVENT_TYPE_REVIEW_RECEIVED) {
        data = ('<div><b>Id:</b> ' + event.data.id  + '</div><div><b>Patient gave rating:</b> ' + event.data.patient_rating + '</div><div><b>Patient review: </b>' + event.data.patient_text   + '</div><div><b>Displaying:</b> ' + event.data.approved   + '</div>');
        return data;
      }

      if(event.type === EVENT_TYPE_REVIEW_UPDATED) {
        data = ('<div><b>Id:</b> ' + event.data.id  + '</div><div><b>Patient gave rating:</b> ' + event.data.patient_rating + '</div><div><b>Patient review: </b>' + event.data.patient_text   + '</div><div><b>Displaying:</b> ' + event.data.approved   + '</div><div><b>Response from practice:</b> ' + event.data.response_text   + '</div><div><b>Review updated by:</b> ' + event.data.updated_by   + '</div>');
        return data;
      }

      if (event.type === EVENT_TYPE_FORM_SENT) {
        data = [
          '<div><b>Email:</b> ' + event.data.email + '</div>',
          '<div><b>Transaction Id:</b> ' + event.data.token_id + '</div>',
          '<div><b>Form sent to patient</b></div>',
        ]
        if (event.data.pin) {
          if (event.data.mobile_phone) {
            data.push('<div>PIN <b>' + event.data.pin + '</b> texted to patient\'s mobile phone <b>' + event.data.mobile_phone + '</b>.</div>')
          }
          else {
            data.push('<div>Please provide PIN <b>' + event.data.pin + '</b> to the patient through means other than email.</div>')
          }
        }
        return data.join("");
      }

      if (event.type === EVENT_TYPE_FORM_SENT_END) {
        data = []
        if (event.data.token_id) {
          data.push('<div>')
          data.push('<b>Transaction Id:</b> ' + event.data.token_id)
          if (event.data.status === "cancelled_by_user") {
            data.push(', <span>Patient form sent cancelled by user.</span>')
          }
          else if (event.data.status === "exceeded_maximum_pin_retries") {
            data.push(', <span>Patient form sent cancelled because patient exceeded maximum PIN retries.</span>')
          }
          else if (event.data.status === "submitted") {
            data.push(', <span>Patient successfully submitted form.</span>')
          }
          data.push('</div>')
        }
        return data.join("");
      }

      if (event.type === EVENT_TYPE_KIOSK_FORM_LOAD) {
        data = [
          '<div><b>Transaction Id:</b> ' + event.data.token_id + '</div>',
          '<div><b>Kiosk:</b> ' + event.data.kiosk.name + ' (' + event.data.kiosk.id + ')' + '</div>',
        ]
        return data.join("");
      }

      if (event.type === EVENT_TYPE_KIOSK_FORM_SUBMIT) {
        data = [
          '<div><b>Transaction Id:</b> ' + event.data.token_id + '</div>',
          '<div><b>Kiosk:</b> ' + event.data.kiosk.name + ' (' + event.data.kiosk.id + ')' + '</div>',
        ]
        return data.join("");
      }

      return JSON.stringify(event.data);
    };

    $scope.saveNote = function(event){
      if(event.id){
        PatientInteractionsService.update(event.id,{
          type:EVENT_TYPE_STAFF_NOTE,
          data:event.data
        }).then(function(){
          NotificationService.success('Note updated');
          $scope.getAllInteractions();
        },function(error){
          NotificationService.error('Error Updating note ',error);
        });
        return;
      }

      PatientInteractionsService.create($scope.patientId,{
        type:EVENT_TYPE_STAFF_NOTE,
        data:event.data
      }).then(function(){
        NotificationService.success('Note created');
        $scope.getAllInteractions();
      },function(error){
        NotificationService.error('Error Saving note ',error);
      });
    };

    $scope.deleteNote = function(){
      PatientInteractionsService.delete($scope.noteEventIdToDelete).then(function(){
        $scope.getAllInteractions();
      },function(error){
        NotificationService.error('Error Deleting note ',error);
      });
    };

    $scope.canEdit = function(event){
      if(!CanUpdatePatient){
        return false;
      }

      if(event.type === EVENT_TYPE_STAFF_NOTE &&
        event.created_by === $scope.userName ){
        return true;
      }

      return false;
    };

    $scope.canDelete = function(event){
      if(!CanUpdatePatient){
        return false;
      }

      if(event.type === EVENT_TYPE_STAFF_NOTE &&
        event.created_by === $scope.userName ){
        return true;
      }

      return false;
    };

    $scope.openNoteModal = function (event){
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'addNote.html',
        controller: 'AddNoteEventCtrl',
        size: 'lg',
        resolve: {
          event: function () {
            return event;
          }
        }
      });
      modalInstance.result.then(function(event){
        $scope.saveNote(event);
      });
    };

    $scope.openDeleteNoteModal = function(id){
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'delNote.html',
        controller: 'DeleteNoteEventCtrl',
        size: 'sm'
      });
      $scope.noteEventIdToDelete = id;
      modalInstance.result.then(function(){
        $scope.deleteNote();
      });
    };

    $scope.openCancelPatientFormModal = function(event) {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        size: "sm",
        templateUrl: "#cancel-patientform-modal",
        controller: function($scope) {
          this.submit = function() {
            $scope.$close()
          }
        },
      }).result.then(function() {
        PatientInteractionsService.delete(event.id).then(
          function() {
            $scope.getAllInteractions();
          },
          function(error) {
            $scope.getAllInteractions();
            NotificationService.error('Error Canceling Patient Form', error);
          }
        );
      })
    }
  }

  function addNoteEventCtrl($scope,$uibModalInstance, event) {
    $scope.event = angular.copy(event);

    $scope.save = function(){
      $uibModalInstance.close($scope.event);
    };

    $scope.cancel = function(){
      $uibModalInstance.dismiss();
    };
  }

  function deleteNoteEventCtrl($scope,$uibModalInstance) {
    $scope.ok = function(){
      $uibModalInstance.close();
    };

    $scope.cancel = function(){
      $uibModalInstance.dismiss();
    };
  }

  angular.module('common.patient-interactions', [])
    .controller('PatientInteractionsCtrl', patientInteractionsCtrl)
    .controller('AddNoteEventCtrl',addNoteEventCtrl)
    .controller('DeleteNoteEventCtrl',deleteNoteEventCtrl);
})();

(function() {
  'use strict';

  function insuranceDataProvidedByPatientCtrl($scope, $transition$, PatientsService, NotificationService) {
    //Remove the default background that contains the left menu color
    $('body').css('background','#eef1f4');
    if($transition$.params().patientId){
      PatientsService.get($transition$.params().patientId).then(function(event){
        var patientData = event.data;
        var patient = {};
        if(!patientData.insurance_data_provided_by_patient){
          return;
        }
        patientData.insurance_data_provided_by_patient = angular.fromJson(patientData.insurance_data_provided_by_patient);
        patient.otherInsuranceData = patientData.insurance_data_provided_by_patient.otherInsuranceData;
        patient.insuranceData = patientData.insurance_data_provided_by_patient.insuranceData;
        $scope.patient = patient;
      },function(err){
          NotificationService.error('Error loading patient data',err);
      });
    }
  }

  angular.module('common.insurance-data-provided-by-patient', [])
    .controller('InsuranceDataProvidedByPatientCtrl', insuranceDataProvidedByPatientCtrl);
})();

(function() {
  'use strict';

  function encounterAddCtrl($scope, $log, $state, $transition$, EncountersService, EncounterPrescriptionsService, EncounterTreatmentsService, NotificationService) {
    $log.debug('EncounterAdd loaded');

    $scope.patientId = $transition$.params().patientid;

    $scope.prescriptions = EncounterPrescriptionsService.all();
    $scope.treatments = EncounterTreatmentsService.all();
    $scope.report = '';

    $scope.submit = function() {
      var newEncounter = {};
      newEncounter.patient = $scope.patientId;
      newEncounter.prescriptions = $scope.prescriptions;
      newEncounter.treatments = $scope.treatments;
      newEncounter.report = $scope.report;

      $log.debug('submit called');

      EncountersService.create($scope.patientId, newEncounter).then(
        function success() {
          NotificationService.success('Encounter added sucessfully');

          console.log('Encounter added successfully');
          $state.go('^');
        },
        function fail(err) {
          NotificationService.error('Error adding encounter',err);
        });
    };
  }

  angular.module('common.encounter-add', [])
    .controller('EncounterAddCtrl', encounterAddCtrl);
})();

/*jshint undef: false, unused: false */
(function() {
  'use strict';

  angular.module('common.encounter-detail', [])
    .controller('EncounterDetailCtrl', EncounterDetailCtrl)
    .controller('ReportFormModalInstanceCtrl', reportFormModalInstanceCtrl)
    .directive('elemOnChange', elemOnChangeDirective  );

  function EncounterDetailCtrl(
    $log,
    $q,
    $rootScope,
    $scope,
    $state,
    $transition$,
    $timeout,
    $uibModal,
    $window,
    CanCreatePatientImage,
    CanDeletePatientImage,
    CanListPatientImage,
    CanRetrievePatientImage,
    CanRetrieveTreatmentAgreement,
    CanUpdateEncounter,
    CanUpdatePatientImage,
    EncounterPrescriptionsService,
    EncountersService,
    LocationsService,
    MedicalAlertsService,
    NotificationService,
    PatientImageService,
    PrincipalService,
    TemplatesService,
    AuthenticationService,
    MultiLocationEnable,
    WebsocketService,
    PatientsService
  ) {

    $scope.providerId = $transition$.params().providerId || PrincipalService.getAccountId();

    $scope.MultiLocationEnable = MultiLocationEnable;

    //retrieve state (url) params
    $scope.encounterId = $transition$.params().encounterid;
    $scope.isNewEncounter = $transition$.params().isnewencounter;
    $scope.newEncounterType = $transition$.params().type;
    $scope.patientId = $transition$.params().patientid;
    $scope.CanUpdateEncounter = CanUpdateEncounter;
    $scope.CanRetrieveTreatmentAgreement = CanRetrieveTreatmentAgreement;

    $scope.PatientImageService = PatientImageService;
    $scope.CanCreatePatientImage = CanCreatePatientImage;
    $scope.CanDeletePatientImage = CanDeletePatientImage;
    $scope.CanListPatientImage = CanListPatientImage;
    $scope.CanRetrievePatientImage = CanRetrievePatientImage;
    $scope.CanUpdatePatientImage = CanUpdatePatientImage;

    $scope.BACKEND_FORMAT_DATE = 'YYYY-MM-DD';

    //initialize scope variables
    $scope.isFilesAddHidden = false;
    $scope.isPhotosAddNotCollapsed= true;
    $scope.locations = null;
    //tells image controller that encounterId is required.
    $scope.mode = 'ENCOUNTER';
    $scope.report = '';

    // used to signal provider must go to DoseSpot UI
    $scope.isDoseSpotRegistered = false;
    $scope.patientDoseSpotRedirectUrl = '';
    $scope.refillDoseSpotRedirectUrl = '';
    $scope.refillRequestCount = 0;
    $scope.refillTransmissionErrorCount = 0;

    $scope.event = null;

    $scope.encounter = {};
    $scope.encounter.provider = $scope.providerId;

    $scope.patientMedicalCondition = null;

    $scope.patient_medical_history = '';

    $scope.medicalHistoryConditions = ['is_aids','is_allergic_to_amoxicillin','is_allergic_to_aspirin','is_allergic_to_codeine','is_allergic_to_eggs',
    'is_allergic_to_latex','is_allergic_to_local_anesthetic','is_allergic_to_soy','is_allergic_to_sulfa_drugs','is_allergic_to_sulfites','is_anemia',
    'is_archived','is_arthritis','is_asthma','is_bad_breath','is_bleeding_tendency','is_blisters_sores','is_blood_disorder','is_blood_transfusion',
    'is_broken_chipped_tooth','is_bruise_easily','is_burning_tongue','is_cancer','is_cardiac_pacemaker','is_chest_pain_angina','is_chronic_fatigue',
    'is_contagious_diseases','is_convulsions_epilepsy','is_damaged_heart_valves','is_delay_in_healing','is_diabetes','is_difficulty_closing_jaw',
    'is_difficulty_opening_jaw','is_emphysema','is_eye_disease','is_fainting_spells','is_family_patient_of_practice','is_food_caught_between_teeth',
    'is_gallbladder_trouble','is_gum_disease','is_had_general_anesthesia','is_had_reaction_anesthesia','is_has_alcohol_abuse_history',
    'is_has_drug_abuse_history','is_has_known_allergies','is_heart_attacks','is_heart_murmur','is_heart_surgery','is_hepatitis',
    'is_high_blood_pressure','is_hiv','is_hmo_ppo','is_hospitalized_past_five_years','is_immune_system_problems','is_in_discomfort','is_in_good_health',
    'is_in_pain','is_infectious_mononucleosis','is_irregular_heart_beat','is_joint_replacement','is_kidney_trouble','is_liver_disease','is_locking_jaw',
    'is_loose_shifting_teeth','is_lost_broken_fillings','is_low_blood_pressure','is_low_blood_sugar','is_mental_health_problems','is_mitral_valve_prolapse',
    'is_on_dialysis','is_on_diet','is_osteonecrosis','is_osteoporosis','is_other','is_patient_of_practice','is_pneumonia','is_pregnant',
    'is_prolonged_bleeding','is_prosthetic_implant','is_recent_infections','is_recommended_antibiotics','is_removable_appliance','is_respiratory_problems',
    'is_rheumtic_fever','is_ringing_in_ears','is_sensitive_to_biting','is_sensitive_to_cold','is_sensitive_to_hot','is_sensitive_to_sweets',
    'is_sexually_transmitted_disease','is_sinus_problems','is_sleep_apnea','is_smoker','is_snoring','is_stained_teeth','is_stomach_ulcers',
    'is_stroke','is_swelling_lump_in_mouth','is_swollen_ankles','is_swollen_gums','is_taking_antibiotic','is_taking_antidepressants',
    'is_taking_blood_thinners','is_taking_bone_density_meds','is_taking_diet_pills','is_taking_insulin','is_taking_medication','is_taking_muscle_relaxers',
    'is_taking_nerve_pills','is_taking_pain_killers','is_taking_stimulants','is_taking_tranquilizers','is_taking_valium','is_teeth_grinding_clenching',
    'is_thyroid_trouble','is_toothache','is_trouble_climbing_stairs','is_tuberculosis','is_tumor_growth','is_under_physician_care','is_using_chewing_tobacco'];

    $scope.takingMedication = ['taking_medication_0','taking_medication_1','taking_medication_2','taking_medication_3','taking_medication_4',
    'taking_medication_5','taking_medication_6','taking_medication_7','taking_medication_8'];


    $scope.medicationAllergies = ['is_taking_nerve_pills','is_taking_diet_pills','is_taking_pain_killers','is_taking_tranquilizers','is_taking_muscle_relaxers',
    'is_taking_insulin','is_taking_stimulants','is_taking_antidepressants','is_taking_blood_thinners','is_taking_bone_density_meds',
    'is_taking_antibiotic','is_taking_valium'];

    $scope.hasAllergies = ['is_has_known_allergies'];

	function uppercaseWords(str) {
      return str.replace(/^(.)|\s+(.)/g, function(c) { return c.toUpperCase() })
	}


    var formFieldRegexInput = /\[+([^\][]+)]+/g; //selects inner-most text delimited by []
    var formFieldRegexSelect = /\{+([^}{]+)}+/g; //selects inner-most text delimited by {}
    function hasFormFields(report) {
      if ( (report.match(formFieldRegexInput) || []).length === 0) {
        return (report.match(formFieldRegexSelect) || []).length > 0;
      } else {
        return true;
      }
    }

    function reloadQuestion() {
      swal({
        title: 'Record Saved By Another User',
        text: [
          "This record was just updated by another user session.",
          "Changes saved by the other user session since you loaded this record will be overwritten if you update.",
          "Also, if you reload now you will lose any changes you made to this record since you last loaded it.",
          "If you have made changes, press cancel, note your changes, reload this page, then apply your changes"
        ].join(" "),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Reload (CAUTION)',
        closeOnConfirm: true,
        allowOutsideClick: true
      }, function () {
        scope.reset();
      });
    }

    // setup socket observer to listen for updates or deletion of this encounter
    //begin websocket observer setup
    var scope = $scope;
    var strm = 'notification';
    var cmds_observed = [
      'model_update_encounters_models_encounter_encounter',
      'model_delete_encounters_models_encounter_encounter',
    ];
    scope.notificationObservableId = WebsocketService.observe(
      strm,
      cmds_observed,
      function(stream, cmd, params) {
        $log.debug('Websocket message received in ENCOUNTER with ID: ' + scope.notificationObservableId + '; STREAM: ' + stream + '; CMD: ' + cmd + '; PARAMS: ' + JSON.stringify(params));
        if (params.id.toString() === $scope.encounterId.toString() && params.saverid && PrincipalService.getSaverid() !== params.saverid) {
          reloadQuestion();
        }
      }
    );
    $log.debug('Websocket observer registered in ENCOUNTER with ID: ' + scope.notificationObservableId + '; STREAM: ' + strm + '; CMDS_OBSERVED: ' + JSON.stringify(cmds_observed));
    $scope.$on('$destroy', function() {
      WebsocketService.unobserve(scope.notificationObservableId);
      $log.debug('Websocket observer unregistered in ENCOUNTER with ID: ' + scope.notificationObservableId);
    });
    //end websocket observer setup

    $scope.clearSignature = function(encounter,field){
      encounter[field] = null;
      //We need to set it to '' so allow_blank=True works fine as is the default on django and not null
      //and null is the only way to clear out the signature on the component
      $timeout(function(){
        encounter[field] = '';
      },1);

    };

    $scope.canSignChartNotes = function() {
      if (!$scope.canEditEncounters()) {
        return false;
      }
      return PrincipalService.hasPermission('sign_chart_notes');
    };

    $scope.canEditEncounters = function() {
      // Can create but not edit
      if ($scope.isNewEncounter === true) {
        return true;
      }
      return PrincipalService.hasPermission('encounter_modify');
    };

    $scope.saveAsTemplate = function() {
      var template_ = {};
      template_.title = $scope.encounter.title;
      template_.tooth_numbers = $scope.encounter.tooth_numbers;
      template_.report = $scope.encounter.report;

      TemplatesService.createForProvider(PrincipalService.getAccountId(), template_)
      .then(function() {
        NotificationService.success('Successfully saved template.');
        getTemplates();
      })
      .catch(function(err) {
        NotificationService.error('Error Creating a new template', err);
      });
    };

    function getJsonFromObj(obj) {
      if (typeof obj === 'object' && obj !== null) {
        return angular.toJson(obj);
      } else {
        $log.error('getJsonFromObj: obj is not a non-null object type: ' + typeof obj + '. Returning "{}".');
        return angular.toJson({});
      }
    }

    function getObjFromJson(json) {
      var obj = {};
      if (json.length > 0) {
        try {
          var parseResult = angular.fromJson(json);
          if (typeof parseResult === 'string' || parseResult instanceof String) {
            $log.error('getObjFromJson: parsed json is still a string: ' + parseResult + ' of length: ' + parseResult.length + '. Returning {}.');
          } else if (typeof parseResult === 'object' && parseResult === null) {
            $log.error('getObjFromJson: parsed json is null' + '. Returning {}.');
          } else if (typeof parseResult !== 'object') {
            $log.error('getObjFromJson: parsed json is of type: ' + typeof parseResult + '. Returning {}.');
          } else {
            obj = parseResult;
          }
        } catch (e) {
          $log.error('getObjFromJson: error parsing json: ' + json + '. Returning {}.');
        }
      }
      return obj;
    }

    $scope.reportBlurred = function() {
      $scope.showReportFormButton = hasFormFields($scope.encounter.report);
    };

    $scope.openReportFormDialog = function(report_template) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'reportForm.html',
        controller: 'ReportFormModalInstanceCtrl',
        windowClass: 'modal-full',
        resolve: {
          report_template: function () {
            return report_template;
          },
          formFieldRegexInput: function () {
            return formFieldRegexInput;
          },
          formFieldRegexSelect: function () {
            return formFieldRegexSelect;
          },
        }
      });

      modalInstance.result.then(function (closeObj) {
        $scope.encounter.report = closeObj.report;
        $scope.showReportFormButton = hasFormFields(closeObj.report);
      }, function () {
      });
    };

    $scope.save = function(encounter) {
      encounter.diagnostic_tests = getJsonFromObj(encounter.diagnostic_tests);
      EncountersService.update($scope.encounterId, encounter)
      .then(function(response) {
        var encounter = angular.fromJson(response.data);
        encounter.diagnostic_tests = getObjFromJson(encounter.diagnostic_tests);
        $scope.encounter = encounter;
        $scope.showReportFormButton = hasFormFields($scope.encounter.report);
        NotificationService.success('Encounter successfully saved');
      })
      .catch(function(err) {
        NotificationService.error('Error Saving encounter',err);
      });
    };

    $scope.submit = function() {
      if($scope.addEncounter.encounterDateInput.$invalid) {
        NotificationService.error('Invalid Encounter Date');
      }
      else {
        $scope.save($scope.encounter);
      }
    };

    //Dates open event handlers
    $scope.openEncounterDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.opened = true;
    };

    $scope.openPatientSigDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.patientSigDateOpened = true;
    };

    $scope.openProviderSigDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.providerSigDateOpened = true;
    };

    $scope.doseSpotRedirect = function(path) {
      var doseSpotWindow = $window.open(path, "_blank")
      // var timer = setInterval(function() {
      //   if (doseSpotWindow.closed) {
      //     // doseSpotWindow closed, refresh prescriptions
      //     clearInterval(timer);
      //   }
      // }, 1000);
    };

    $scope.requestDoseSpotPatientRedirect = function() {
      if (!$scope.encounterId) {
        return;
      }
      EncounterPrescriptionsService.getDoseSpotPatientUrl($scope.encounterId)
        .then(
          function(response) {
            if (response.data.url) {
              $scope.doseSpotRedirect(response.data.url);
            }
            else {
              NotificationService.error("Not fully set up with e-prescriptions provider", "Click on adjacent icon to complete setup then try again.");
            }
          }
        )
        .catch(
          function(err) {
            NotificationService.error("Error received from e-prescriptions provider", err);
          }
        );
    };

    $scope.reset = function() {
      //initialize. Save encounter immediately if no encounterid so one is available for child entities (e.g. images)
      if ($scope.encounterId) {
        EncountersService.get($scope.encounterId)
        .then(function (data) {
          $scope.encounter = angular.fromJson(data.data);
          $scope.encounter.diagnostic_tests = getObjFromJson($scope.encounter.diagnostic_tests);
          $scope.showReportFormButton = hasFormFields($scope.encounter.report);
          $scope.event = 'encounterLoaded';
        })
        .catch(function (err) {
          NotificationService.error('Error Getting encounter', err);
        });

        // DoseSpot requirement is for this to load everytime the page is shown
        EncounterPrescriptionsService.getIsDoseSpotRegistered($scope.encounterId)
        .then(function (resp) {
          if (resp.data && resp.data.is_dosespot_registered) {
            return EncounterPrescriptionsService.getDoseSpotPatientRefillCounts($scope.encounterId);
          }
          return $q.reject({breakChain: true});
        })
        .then(function (resp) {
          $scope.refillRequestCount = resp.data.refill_request_count;
          $scope.refillTransmissionErrorCount = resp.data.transmission_error_count;
          $scope.refillDoseSpotRedirectUrl = resp.data.url;
          $scope.isDoseSpotRegistered = true;
        })
        .catch(function (err) {
          // if provider is not DoseSpot registered then we purposely broke the chain
          if (err.breakChain) {
            return null;
          }
          NotificationService.error('Error Querying DoseSpot', err);
        });

        MedicalAlertsService.getAlertsByPatient($scope.patientId).then(
          function(response) {
            if (response.data && response.data.length > 0) {
              $scope.patientMedicalCondition = MedicalAlertsService.translateMedicalAlerts(response.data);
            }
          },
          function (err) {
            NotificationService.error('Error Loading alerts', err);
          }
        );
      }
      else if ($scope.newEncounterType) {
        $scope.encounter.encounter_date = moment().format($scope.BACKEND_FORMAT_DATE);
        $scope.encounter.encounter_type = $scope.newEncounterType;


        if($scope.provider.location_default){
          $scope.encounter.location = $scope.provider.location_default;
        }else{
          $scope.encounter.location = null;
        }

        //clear out signatures on creating a new encounter
        $scope.encounter.patient_signature = '';
        $scope.encounter.provider_signature = '';
        $scope.encounter.diagnostic_tests = getJsonFromObj({});
        EncountersService.create($scope.patientId, $scope.providerId, $scope.encounter)
        .then(function (response) {
          var encounter = angular.fromJson(response.data);
          $state.go('chart-detail', {encounterid: encounter.id, isnewencounter: true});
          NotificationService.success('New encounter successfully created');
        })
        .catch(function (err) {
          NotificationService.error('Error Creating new encounter', err);
        });
      }
      getPatientMedicalHistory();
    };

    populateFielsWhenTemplateChange();
    getLocations();
    getTemplates();

    function populateFielsWhenTemplateChange() {
      return $scope.$watch('template', function(newVal, oldVal) {
        if ((newVal !== oldVal) && (newVal !== null)) {
          $scope.encounter.title = newVal.title;
          $scope.encounter.tooth_numbers = newVal.tooth_numbers;
          $scope.encounter.report = newVal.report;
          $scope.showReportFormButton = hasFormFields($scope.encounter.report);
          $timeout(function() {
            $scope.template = null;
          }, 2000);
        }
      });
    }

    function getLocations() {
      //retrieve info from endpoints
      $q.all([LocationsService.all(),AuthenticationService.getUserAccount($scope.providerId)])
      .then(function(responses) {
        $scope.locations = angular.fromJson(responses[0].data);
        $scope.provider = angular.fromJson(responses[1].data);
        $scope.reset();
      })
      .catch(function(err) {
        NotificationService.error('Error Loading locations',err);
      });
    }

    function getTemplates() {
      TemplatesService.allForPractice(PrincipalService.getPracticeId())
      .then(function(data) {
        $scope.templates = angular.fromJson(data.data);
      })
      .catch(function(err) {
        NotificationService.error('Error Loading templates',err);
      });
    }

    function getPatientMedicalHistory() {
      //retrieve info from endpoints
      $q.all([PatientsService.get($scope.patientId)])
      .then(function(responses) {
        var patient = angular.fromJson(responses[0].data);
        var medicalHistory = PatientsService.getMedicalHistory(patient);
        var medicalHistoryString = '';
        for (var groupKey in medicalHistory) {
          if (medicalHistory.hasOwnProperty(groupKey)) {
            if (!medicalHistoryString) {
              medicalHistoryString = 'Last Updated (local time): ' + moment(responses[0].data.updated).format('MMMM Do YYYY, h:mm:ss a') + '\n\n';
            } else {
              medicalHistoryString = medicalHistoryString + '\n\n';
            }
            medicalHistoryString += medicalHistory[groupKey].label.toUpperCase();
            var count = 0;
            for (var itemKey in medicalHistory[groupKey].items) {
              if (medicalHistory[groupKey].items.hasOwnProperty(itemKey)) {
                var item = medicalHistory[groupKey].items[itemKey];
                if (item.value && (typeof item.value === "boolean")) {
                  medicalHistoryString = medicalHistoryString + '\n' + item.labelStatement;
                } else if (item.value && (typeof item.value === 'string' || item.value instanceof String)) {
                  medicalHistoryString = medicalHistoryString + '\n' + item.labelStatement + ((item.labelStatement) ? ' ' : '') + item.value;
                }
                count++;
              }
            }
            if (!count) {
              medicalHistoryString = medicalHistoryString + '\n' + '(none)';
            }
          }
        }
        $scope.medicalHistory = medicalHistoryString;
        $scope.medicalHistoryLoaded = true;
      })
      .catch(function() {
        $scope.medicalHistoryLoaded = false;
      });
    }

    function replaceChar(input, from, to){
      if(input === undefined) {
        return;
      }
      var regex = new RegExp(from, 'g');
      return input.replace(regex, to);
    }

    // this event is broadcasted from PatientDetailTreatmentPlansCtrl
    $rootScope.$on('encounter-treatments-changed', function(event, treatments) {
      // keep synced the encounter.treatments
      $scope.encounter.treatments = treatments;
    });

  }

  function elemOnChangeDirective() {
      return {
        scope: {
          onchg: '&'
        },
        link: function(scope, elem) {
          elem.on('change', function() {
              scope.onchg();
          });
        }
      };
  }

  function reportFormModalInstanceCtrl($scope, $uibModalInstance, $sce, report_template, formFieldRegexInput, formFieldRegexSelect) {

    //remove html elements in template
    report_template = $("<div>").html(report_template).text();

    var form = report_template.replace(formFieldRegexInput, "<input type='text' class='custom-control-input mb-2 mr-sm-2' placeholder='$1' />");

    function toSelect(match, p1, offset, str) {
      var optionsArray = p1.split('|');
      var options = '';
      for (var i = 0; i < optionsArray.length; i++) {
          options = options + '<option value="' + optionsArray[i] + '">' + optionsArray[i] + '</option>';
      }
      return '<select class="custom-select mr-sm-2">' + options + '</select>';
    }
    form = form.replace(formFieldRegexSelect, toSelect);

    form = form.replace(/\n/g, "<br>");

    $scope.form_html = $sce.trustAsHtml(form);

    $scope.ok = function () {
      var div = $("#form_html");

      div.children("input").each(function () {
        if ($("<div>").html($(this).val()).text().trim().length !== 0) {
          $(this).replaceWith($("<div>").html($(this).val()).text()); //replace inputs with text entered in inputs
        } else {
          $(this).replaceWith("[" + $(this).attr("placeholder") + "]"); //reset inputs back into square bracket markup notation
        }
      });

      div.children("select").each(function () {
        if ($("<div>").html($(this).val()).text().trim().length !== 0) {
          $(this).replaceWith($("<div>").html($(this).val()).text()); //replace select with value selected
        } else {
          var list = "";
          var first = true;
          $(this).children("option").each(function(i, option) {
            if (first) {
              list = option.value;
              first = false;
            } else {
              list = list + "|" + option.value;
            }
          });
          $(this).replaceWith("{" + list + "}"); // reset select back into curly braces markup notation
        }
      });

      var report = div.html().replace(/<br>/g, "\n");

      $uibModalInstance.close({
        report: report,
      });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }

})();

/*jshint unused: false */
/*global _ */

(function() {
  'use strict';

  function encounterDetailPatientViewCtrl($scope, $timeout, $transition$, EncountersService,
                                          TreatmentsService, PrincipalService, AuthenticationService, ProceduresService,
                                          PatientsService, $q, $window, NotificationService, CanUpdateTreatmentAgreement,
                                          BillingService) {


    //retrieve state (url) params
    $scope.patientId = $transition$.params().patientid;
    $scope.encounterId = $transition$.params().encounterid;

    $scope.CanUpdateTreatmentAgreement = CanUpdateTreatmentAgreement;


    $scope.clearSignature = function(encounter,field){
      encounter[field] = null;
      //We need to set it to '' so allow_blank=True works fine as is the default on django and not null
      //and null is the only way to clear out the signature on the component
      $timeout(function(){
        encounter[field] = '';
      },1);

    };


	$q.all([
		EncountersService.get($scope.encounterId),
        PatientsService.get($scope.patientId),
        TreatmentsService.allForEncounter($scope.encounterId),
        AuthenticationService.getPractice(PrincipalService.getPracticeId()),
        AuthenticationService.getPracticeAdminAndProviderAccounts(PrincipalService.getPracticeId())

  ]).then(function(responses) {
        $scope.encounter = angular.fromJson(responses[0].data);
        $scope.patient = angular.fromJson(responses[1].data);
        var treatments = angular.fromJson(responses[2].data);
        $scope.practice = angular.fromJson(responses[3].data);
        $scope.providers = angular.fromJson(responses[4].data);

        //sort and group treatment
        var treatmentsWithoutWontDo = _.filter(treatments, function(treatment) {return treatment.status !== 'wontdo';});
        var treatmentsWithoutWontDoSortedByRecommend = _.sortBy(treatmentsWithoutWontDo,'recommendation_level');
        var treatmentsGroupedByRecommend = _.groupBy(treatmentsWithoutWontDoSortedByRecommend, 'recommendation_level');
        var treatmentsGroupedFlatten;
        var treatmentsGroupedByRecommendSortByTooth = [];
        _.forEach(treatmentsGroupedByRecommend,function(treatments){
          var amountPatientSubtotal = 0.00;
          var amountFeeSubtotal = 0.00;
          var amountInsSubtotal = 0.00;
          var amountOtherSubtotal = 0.00;
          var treatmentsSorted = _.sortBy(treatments,'tooth_numbers');
          _.forEach(treatmentsSorted,function(treatment,idx){
            if(idx !== 0){
              treatment.hideRecommend = true;
            }

            amountFeeSubtotal +=  parseFloat(treatment.amount_fee);
            if (treatment.insurance_applied && treatment.amount_ins) {
              amountInsSubtotal +=  parseFloat(treatment.amount_ins);
            }
            if (treatment.other_applied && treatment.amount_other) {
              amountOtherSubtotal +=  parseFloat(treatment.amount_other);
            }
            amountPatientSubtotal += parseFloat(treatment.amount_patient);
          });
          treatmentsSorted.push(
            {
              subtotal:true,
              amountFeeSubtotal: BillingService.getAmountToFixed(amountFeeSubtotal),
              amountPatientSubtotal: BillingService.getAmountToFixed(amountPatientSubtotal),
              amountInsSubtotal: BillingService.getAmountToFixed(amountInsSubtotal),
              amountOtherSubtotal: BillingService.getAmountToFixed(amountOtherSubtotal)
            }
          );
          treatmentsGroupedByRecommendSortByTooth.push(treatmentsSorted);

        });
        $scope.groupedTreatments = _.flatten(_.values(treatmentsGroupedByRecommendSortByTooth));

        //calculate totals
        $scope.amount_fee_total = 0.00;
        $scope.amount_patient_total = 0.00;
        $scope.amount_ins_total = 0.00;
        $scope.amount_other_total = 0.00;
        //$scope.amount_other_total = 0.00;

        for (var i = 0; i < treatmentsWithoutWontDo.length; i++) {
          $scope.amount_fee_total += parseFloat(treatmentsWithoutWontDo[i].amount_fee);
          if (treatmentsWithoutWontDo[i].insurance_applied && treatmentsWithoutWontDo[i].amount_ins) {
            $scope.amount_ins_total += parseFloat(treatmentsWithoutWontDo[i].amount_ins);
          }
          if (treatmentsWithoutWontDo[i].other_applied && treatmentsWithoutWontDo[i].amount_other) {
            $scope.amount_other_total += parseFloat(treatmentsWithoutWontDo[i].amount_other);
          }
          //$scope.amount_other_total += parseFloat(treatmentsWithoutWontDo[i].amount_other);
          $scope.amount_patient_total += parseFloat(treatmentsWithoutWontDo[i].amount_patient);
        }

        $scope.amount_fee_total = BillingService.getAmountToFixed($scope.amount_fee_total);
        $scope.amount_ins_total = BillingService.getAmountToFixed($scope.amount_ins_total);
        $scope.amount_other_total = BillingService.getAmountToFixed($scope.amount_other_total);
        $scope.amount_patient_total = BillingService.getAmountToFixed($scope.amount_patient_total);

        return ProceduresService.getFeeprofile($scope.patient.feeprofile);
	}, function(err) {
      NotificationService.error('Error loading encounter, patient, or treatments information',err);
	}).then(function(data) {
      $scope.feeprofile = data.data;
  }, function(err) {
      NotificationService.error('Error loading fee profile',err);
  });

    $scope.save = function(encounter) {
        EncountersService.update($scope.encounterId, encounter).then(function(data) {
            $scope.encounter = angular.fromJson(data.data);
            NotificationService.success('Encounter successfully saved');
          },
          function(err) {
            NotificationService.error('Error saving encounter',err);
          }
        );
    };

    $scope.getProviderName = function(providerId) {
      if(!$scope.providers || !providerId){
        return '';
      }

      var provider = _.find($scope.providers,function(provider){return provider.id === providerId;});

      return provider.first_name + ' ' + provider.last_name;

    };

    $scope.printEncounterDetailPatientView = function(encounterId) {
		  $window.open(EncountersService.getPrintPatientViewUrl(encounterId), '_blank');
    };

    $scope.canSignChartNotes = function() {
      return PrincipalService.hasPermission('sign_chart_notes');
    };

    $scope.openPatientSigDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.patientSigDateOpened = true;
    };

    $scope.openProviderSigDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.providerSigDateOpened = true;
    };


  }


  angular.module('common.encounter-detail-patient-view', [])
    .controller('EncounterDetailPatientViewCtrl', encounterDetailPatientViewCtrl);
})();

(function() {
  'use strict';

  function prescriptionAddCtrl($scope, $state, $log, EncounterPrescriptionsService) {
    $log.debug('PrescriptionAdd loaded');

    $scope.drugList = [
      {id: 0, name: 'Select a Drug Type' },
      {id: 1, name: 'Cindomycin' },
      {id: 2, name: 'Pen VK' },
      {id: 3, name: 'Motrin' }
    ];
    $scope.selectedDrug = $scope.drugList[0];

    $scope.dosageList = [
      {id: 0, name: 'Select Dosage' },
      {id: 1, name: '150 mg' },
      {id: 2, name: '300 mg' },
      {id: 3, name: '500 mg' },
    ];
    $scope.selectedDosage = $scope.dosageList[0];

    $scope.dispenseList = [
      {id: 0, name: 'Select Dispense Amount' },
      {id: 1, name: '1' },
      {id: 2, name: '2' },
      {id: 3, name: '3' },
    ];
    $scope.selectedDispense = $scope.dispenseList[0];

    $scope.setDrug = function(drug) {
      $scope.selectedDrug  = drug;
    };

    $scope.setDosage = function(dosage) {
      $scope.selectedDosage  = dosage;
    };

    $scope.setDispense = function(dispense) {
      $scope.selectedDispense = dispense;
    };

    $scope.addPrescription = function() {
      var prescription = { drug: $scope.selectedDrug.name,
                           dosage: $scope.selectedDosage.name,
                           dispense:$scope.selectedDosage.name
                         };
      EncounterPrescriptionsService.add(prescription);
      $state.go('^');
    };
  }

  angular.module('common.prescription-add', [])
    .controller('PrescriptionAddCtrl', prescriptionAddCtrl);
})();

(function() {
  'use strict';

  function treatmentAddCtrl($scope, $state, $log, EncounterTreatmentsService) {
    $log.debug('TreatmentAdd loaded');

    $scope.procedureList = [
      {id: 0, name: 'Select a Procedure' },
      {id: 1, name: 'Root Canal' },
      {id: 2, name: 'Crown' }
    ];
    $scope.selectedProcedure = $scope.procedureList[0];
    $scope.setProcedure = function(procedure) {
      $scope.selectedProcedure = procedure;
    };

    $scope.addTreatment = function() {
      var treatment = { procedure : $scope.selectedProcedure.name };
      EncounterTreatmentsService.add(treatment);
      $state.go('^');
    };
  }

  angular.module('common.treatment-add', [])
    .controller('TreatmentAddCtrl', treatmentAddCtrl);
})();

(function() {
  'use strict';

  function uploaderCtrl($timeout, $scope, $log, Upload, UploadsService, NotificationService) {
    $scope.preparingScan = false;

    // On drop files
    $scope.$watch('images', function(){
        if ($scope.images) {
            $scope.saveFile($scope.patientId, $scope.images, $scope.$invalidFiles, 'images', $scope.photos);
        }
    });

    $scope.$watch('documents', function(){
        if ($scope.documents) {
            $scope.saveFile($scope.patientId, $scope.documents, $scope.$invalidFiles, 'files', $scope.patientfiles);
        }
    });


    $scope.saveFile = function(patientId, files, errFiles, fileType, fileInfoArray){
        fileType = fileType || 'images';
        $scope.errFiles = errFiles;
        $scope.queue = files;

        angular.forEach(files, function(file) {
            $scope.saveFileInfo(patientId, file, fileType, fileInfoArray);
        });
    };

    $scope.saveFileInfo = function(patientId, file, fileType, fileInfoArray) {
        var data = null;
        if (fileType === 'images'){
            data = {
                file_name: $scope.sanitize(file.name),
                //storage_key:  file.storageKey,
                content_type: file.type
            };

            UploadsService.createPatientImage($scope.patientId, $scope.encounterId, data).then(function(response){
                // returns PatientImage
                var fileInfo = angular.fromJson(response.data);
                var storageInfo = angular.fromJson(fileInfo.storage_info_json);
                $scope.saveFileToStorage(patientId, file, fileType, fileInfo, fileInfoArray, storageInfo);
            }, function(error){
                NotificationService.error('Error uploading info for image',error);
            });
        } else {
            data = {
                file_name: $scope.sanitize(file.name),
                //storage_relative_key:  file.storageKey,
                file_type: ($scope.mode === 'ENCOUNTER') ? 'informedconsent' : 'file'
            };

            UploadsService.createPatientFile($scope.patientId, data).then(function(response){
                // returns PatientFile
                var fileInfo = angular.fromJson(response.data);
                var storageInfo = angular.fromJson(fileInfo.storage_info_json);
                $scope.saveFileToStorage(patientId, file, fileType, fileInfo, fileInfoArray, storageInfo);
            }, function(error){
                NotificationService.error('Error uploading info for file',error);
            });
        }
    };

    $scope.saveFileToStorage = function(patient, file, fileType, originalFileInfo, fileInfoArray, storageInfo) {
        var params;
        if ($scope.isAwsMode(storageInfo)) {
            params = {
                url: storageInfo.url,
                method: 'POST',
                data: {
                    key: storageInfo.key,
                    AWSAccessKeyId: storageInfo.access_key,
                    acl: 'private',
                    policy: storageInfo.policy,
                    signature: storageInfo.signature,
                    // filename: filename, // this is needed for Flash polyfill IE8-9
                    'x-amz-server-side-encryption': 'AES256',
                    file: file
                },
                headers: {
                    'Content-Type': file.type,
                    'x-amz-server-side-encryption': 'AES256'
                }
            };
        } else {
            params = {
                url: storageInfo.url,
                method: 'PUT',
                data: {
                    file: file
                },
                headers: {
                    'Content-Type': file.type,
                }
            };
        }
        file.upload = Upload.upload(params);

        file.upload.then(function(response){
            for (var i=0; i< $scope.queue.length; i++) {
                if ($scope.queue[i] === file) {
                    $scope.queue.splice(i, 1);
                    break;
                }
            }
            var fileInfo = null;
            if (!$scope.isAwsMode(storageInfo)) {
                fileInfo = angular.fromJson(response.data); // if storing on filesystem we need the latest fileInfo because it includes the image, thumnail, and file_asset urls.
            } else {
                fileInfo = originalFileInfo; //otherwise, the orignal file info will suffice because t will already contain the image, thumbnail, and file_assets urls.
            }
            //var fileInfo = angular.fromJson(response.data);
            fileInfoArray.push(fileInfo);
            //file.isSuccess = true;
            $scope.acquiringImage = false;
            $scope.preparingScan = false;
            $scope.$applyAsync();
        }, function(error){
            $log.error('File ' + originalFileInfo.file_name + 'failed to upload. Error reports: ' +  error.statusText);
            if (fileType === 'images') {
                UploadsService.deletePatientImage(originalFileInfo.id).then(function(){
                    $log.error('Delete of file info file succeeded');
                }, function(error){
                    $log.error('Delete of file info failed. Error reports: ' + error.statusText);
                });
            } else {
                UploadsService.deletePatientFile(originalFileInfo.id).then(function(){
                    $log.error('Delete of file info file succeeded');
                }, function(error){
                    $log.error('Delete of file info failed. Error reports: ' +error.statusText);
                });
            }
            NotificationService.error('Error uploading file',error);
            if (error.status > 0){
                file.isError = true;
                $log.error(error.status, error.statusText);
            }
            for (var i=0; i< $scope.queue.length; i++) {
                if ($scope.queue[i] === file) {
                    $scope.queue.splice(i, 1);
                    break;
                }
            }
            $scope.acquiringImage = false;
            $scope.preparingScan = false;
            $scope.$applyAsync();
        }, function(event){
            var progressPercentage = Math.min(100, parseInt(100.0 * event.loaded / event.total));
            file.progress = progressPercentage;
        });
    };

    // Rename file name
    $scope.sanitize = function(str){
        var trimmed = $.trim(str),
            extension = trimmed.split('.').pop(),
            sanitize = trimmed.replace(/[^a-z0-9-]/gi, '-').replace(/-+/g, '-').replace(/^-|-$/g, '').replace(extension, ''),
            param = sanitize + Date.now().toString(),
            file_name = [param, extension].join('.');
        return file_name.toLowerCase();
    };

    $scope.uploadScan = function (dwObject) {
        $scope.preparingScan = true;

        dwObject.SelectedImagesCount = dwObject.HowManyImagesInBuffer;
        for (var i = 0; i < dwObject.HowManyImagesInBuffer; i++) {
            dwObject.SetSelectedImageIndex(i, i);
        }

        dwObject.GetSelectedImagesSize($scope.imgType.dwType);
        // hidden method obtained from Dynamsoft support.
        // They don't (?!!) have one that just gets binary -- this is it...
        var imagesBase64 = dwObject.SaveSelectedImagesToBase64Binary();
        var binaryImageString = atob(imagesBase64);
        var length = binaryImageString.length;
        var arrayBuffer = new ArrayBuffer(length);
        var binaryImages = new Uint8Array(arrayBuffer);
        for (var j = 0; j < length; j++) {
            binaryImages[j] = binaryImageString.charCodeAt(j);
        }

        var imagesBinaryBlob = new Blob([binaryImages.buffer], { type: $scope.imgType.type });

        // make into a FileLikeObject in angular file-upload https://github.com/nervgh/angular-file-upload/blob/master/src/services/FileLikeObject.js
        imagesBinaryBlob.name = 'scan' + $scope.imgType.suffix;
        imagesBinaryBlob.lastModifiedDate = new Date();

        $scope.images = [imagesBinaryBlob];
        NotificationService.warning('Uploading... Please, wait.');
        dwObject.RemoveAllImages();
    };

    $scope.isAwsMode = function(storageInfo) {
        if (storageInfo.access_key) {
            return true;
        } else {
            return false;
        }
    };
  }

  angular.module('common.uploader', []).controller('UploaderCtrl', uploaderCtrl);

})();

(function() {
  'use strict';

  function wizardCtrl($rootScope, $scope, $state, $q, AuthenticationService, PrincipalService, NotificationService) {
    // This variable is used to turn on/off various elements based
    // on whether or not we are in the wizard.
    $scope.isInWizard = true;

    $scope.numFeeProfiles = 1;

    $rootScope.$broadcast('lockAllMenus',true);

    $scope.selectFirstFeeProfile = function () {
      $rootScope.$broadcast('selectFirstFeeProfile',true);
    };

    $scope.finishedWizard = function() {
      $rootScope.$broadcast('unlockAllMenus',true);
      $state.go('charts');
    };

    $scope.canExitFeeProfile = function() {
      var d = $q.defer();
      $scope.numFeeProfiles = $('.fee-profile').length;
      if ($scope.numFeeProfiles > 0) {
        d.resolve(true);
      } else {
        NotificationService.error('You must have at least one fee profile.');
        d.resolve(false);
      }
      return d.promise;
    };

    $scope.userFirstCompleteYourInfo = function(){
      $rootScope.$broadcast('openAddEditUserModal',PrincipalService.getAccountId(),'First Complete Your Info');
    };

    $scope.canExitAddUsers = function() {
      var d = $q.defer();
      $scope.numUsers = $('.settings-users').length;
      if ($scope.numFeeProfiles > 0) {
        d.resolve(true);
      } else {
        NotificationService.error('You must have at least one users in your practice.');
        d.resolve(false);
      }
      return d.promise;
    };

    $scope.canExitAddLocations = function() {
      var d = $q.defer();
      $scope.numLocations = $('.settings-locations').length;
      if ($scope.numLocations > 0) {
        d.resolve(true);
      } else {
        NotificationService.error('You must have at least one operatory');
        d.resolve(false);
      }
      return d.promise;
    };

    $scope.finishWizard = function() {

      var failureFn =  function() {
        NotificationService.error('Error Updating the wizard complete');
      };

      var practiceUpdatePromise = AuthenticationService.getPractice(PrincipalService.getPracticeId()).then(function(practice){
        practice.data.is_wizard_completed = true;
        return AuthenticationService.updatePractice(practice.data.id,practice.data);
      },failureFn);

      practiceUpdatePromise.then(function(){
        PrincipalService.setWizardCompleted();
        NotificationService.success('Wizard completed successfully');
        $rootScope.$broadcast('unlockAllMenus',true);
        $state.go('charts');
      },failureFn);


    };
  }

  angular.module('common.wizard', [])
    .controller('WizardCtrl', wizardCtrl);
})();

(function() {
  'use strict';

  angular.module('common.provider-selection', [])
    .controller('ProviderSelectionCtrl', ProviderSelectionCtrl);

  function ProviderSelectionCtrl($log, $scope, providers) {
    $log.info('Provider selection loaded');
    var self = this;
    self.providers = providers
    self.onProviderSelect = function(provider){
      $scope.$close(provider.id)
    };

  }

})();

(function() {
  "use strict"

  angular.module("common.settings-users-activity", [])

  .component("settingsUsersActivity", {
    controller: Controller,
    templateUrl: "src/common/settings-users-activity.tpl.html",
  })

  function Controller(
    $filter,
    $q,
    $rootScope,
    $state,
    NotificationService,
    PracticeService
  ) {

    var $ctrl = this

    $ctrl.CanListUserActivity = $rootScope.resolved.CanListUserActivity
    $ctrl.days = []
    $ctrl.events = $rootScope.resolved.events
    $ctrl.events_meta = $rootScope.resolved.events_meta
    $ctrl.params = $rootScope.resolved.params
    $ctrl.selectedId = null
    $ctrl.user = $rootScope.resolved.user

    $ctrl.$onInit = function() {
      $ctrl.days = $ctrl.events_meta.days.map(function(day) {
        return {
          formatted: $filter("date")(day, "MM/dd/yyyy"),
          value: day,
        }
      })
    }

    $ctrl.load = function(params) {
      if (params && params.page) {
        $ctrl.selectedId = null
        if (params) {
          angular.extend($ctrl.params, params)
        }
        PracticeService.system_events($ctrl.params).then(
          function(response) {
            $ctrl.events = response.data
          },
          function(error) {
            NotificationService.error('Error loading events', error)
            $ctrl.events = []
          }
        )
      }
    }
  }

})();

(function() {
  'use strict';

  function lockScreenCtrl($log ) {
    $log.log('lockScreenCtrl loaded');




  }

  angular.module('common.lockscreen', [])
    .controller('LockScreenCtrl', lockScreenCtrl);
})();

(function() {
  'use strict';

  function eulaCtrl($rootScope, $scope, $state, $http , PrincipalService, AuthenticationService , NotificationService) {

    $rootScope.$broadcast('lockAllMenus',true);


    $scope.decline = function(){
      //TODO move the $http to a service
      PrincipalService.authenticate(null);
      $http.post('/api/v1/auth/logout/', {})
        .then(function() {
          $state.go('signout');
          NotificationService.success('You have been successfully logged out');
        }, function (err) {
          NotificationService.error('Login out',err);
        });
    };

    $scope.accept = function(){

      var failureFn =  function() {
        NotificationService.error('Error Updating the eula acceptance');
      };

      var accountPromise = AuthenticationService.getUserAccount(PrincipalService.getAccountId()).then(function(account){
        return account.data;
      },failureFn);

      var accountUpdatedPromise = accountPromise.then(function(account){
        account.is_eula_accepted = true;
        return AuthenticationService.updateUserAccount(account.id,account);
      });

      accountUpdatedPromise.then(function(){
        $rootScope.$broadcast('unlockAllMenus',true);

        PrincipalService.setEulaAccepted();

        if (PrincipalService.getIdentity().is_wizard_completed) {
          $state.go('charts');
          return;
        }

        $state.go('wizard');

      },failureFn);

    };

  }

  angular.module('common.eula', [])
    .controller('EulaCtrl', eulaCtrl);
})();

(function() {
  'use strict';

  function ThirdPartyLicencesCtrl(ThirdPartyLicensesService) {
   var self = this;

    ThirdPartyLicensesService.get().then(function(event) {
      self.licenses = event.data;
    });

  }

  angular.module('common.thirdparty', [])
    .controller('ThirdPartyLicencesCtrl', ThirdPartyLicencesCtrl);
})();

(function() {
  'use strict';

  function reviewSubmitCtrl($scope, $state, $transition$, $location, $timeout, ReviewsService, NotificationService) {
    if (!$transition$.params().token) {
      $scope.isValid = false;
      return;
    }
    $scope.completed = false;

    ReviewsService.reviewer.get($transition$.params().token).then(
      function(data) {
        $scope.review = data.data;
        $scope.isValid = true;
      },
      function(response) {
        if (response.status === 403) {
          $scope.isValid = false;
          NotificationService.error('Not valid', 'Your token is expired or does not exist anymore');
          $timeout(function(){
            $location.path('/login');
          }, 10000);
        } else {
          $scope.isValid = false;
          NotificationService.error('Error loading reviewer data',response);
          $timeout(function(){
            $location.path('/login');
          }, 10000);
        }
      });

    $scope.submit = function() {
      ReviewsService.reviewer.submit($transition$.params().token, $scope.review).then(
        function success() {
          $state.go('flow-complete', {
            message: 'Thank you, we appreciate you taking the time to review our practice. Your review has been submitted.'
          });
        },
        function fail(err) {
          NotificationService.error('Error submitting your review',err);
        }
      );
    };
  }

  angular.module('common.review-submit', [])
    .controller('ReviewSubmitCtrl', reviewSubmitCtrl);
})();

(function() {
  'use strict';

  function reviewsCtrl(
    $scope, 
    $uibModal, 
    PrincipalService, 
    ReviewsService, 
    NotificationService,
    CanListReview,
    CanRetrieveReview,
    CanUpdateReview
  ) {
    $scope.practiceId = PrincipalService.getPracticeId()
    $scope.CanListReview = CanListReview;
    $scope.CanRetrieveReview = CanRetrieveReview;
    $scope.CanUpdateReview = CanUpdateReview;
    function reset() {
      ReviewsService.allForPractice(PrincipalService.getPracticeId()).then(function(data) {
          $scope.reviews = data.data;
      }, function(err) {
          NotificationService.error('Error Loading reviews',err);
      });
    }
    reset();

    $scope.maintainReview = function (review) {

      var reviewModal = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'maintainReview.html',
          controller: 'maintainReviewModalInstanceCtrl',
          resolve: {
            review: function () {
              return review;
            },
          },
          size: 'medium'
      });

      reviewModal.result.then(function (review) {
        ReviewsService.update(review).then(function() {
           NotificationService.success('Successfully updated review.');
           reset();
        }, function(err) {
            NotificationService.error('Error updating review',err);
        });
      }, function () {
      });
    };
  }

  function maintainReviewModalInstanceCtrl($scope, $uibModalInstance, review) {
    $scope.review = JSON.parse(JSON.stringify(review));

    $scope.ok = function () {
        $uibModalInstance.close($scope.review);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
  }

  angular.module('common.reviews', [])
    .controller('ReviewsCtrl', reviewsCtrl)
    .controller('maintainReviewModalInstanceCtrl', maintainReviewModalInstanceCtrl);
})();

(function() {
  'use strict';

  function supportCtrl(
    $scope, 
    $window, 
    SupportService, 
    $uibModal, 
    $log,
    NotificationService, 
    PrincipalService,
    CanCreateSupportPracticeTickets,
    CanCreateSupportUserTickets,
    CanListSupportPracticeTickets,
    CanListSupportUserTickets,
    CanRetrieveSupportPracticeTickets,
    CanRetrieveSupportUserTickets,
    CanUpdateSupportUserTickets,
    WebsocketService
  ) {

    $scope.CanCreateSupportPracticeTickets = CanCreateSupportPracticeTickets;
    $scope.CanCreateSupportUserTickets = CanCreateSupportUserTickets;
    $scope.CanListSupportPracticeTickets = CanListSupportPracticeTickets;
    $scope.CanListSupportUserTickets = CanListSupportUserTickets;
    $scope.CanRetrieveSupportPracticeTickets = CanRetrieveSupportPracticeTickets;
    $scope.CanRetrieveSupportUserTickets = CanRetrieveSupportUserTickets;
    $scope.CanUpdateSupportUserTickets = CanUpdateSupportUserTickets;
    $scope.maintainSupportTicketModal = null;
    
    $scope.openForums = function() {
      $window.open('https://groups.google.com/forum/#!forum/dental-emr-endo', '_blank');
    };

    $scope.ticketHasUnreadMessage = function(ticket) {
      return (ticket.ticket_messages.filter(function(message) {return !message.read;})).length > 0;
    };

    function reset() {
      /*
      if ($scope.maintainSupportTicketModal) {
        $scope.maintainSupportTicketModal.close();
      }
      */
      if ($scope.CanListSupportUserTickets) {
        SupportService.allTicketsForUser(false).then(function(data) {
          $scope.tickets = data.data;
        }, function(err) {
          NotificationService.error('Error loading your tickets',err);
        });
      }
      if ($scope.CanListSupportPracticeTickets) {
        SupportService.allTicketsForPractice(PrincipalService.getPracticeId(), false).then(function(data) {
          $scope.practicetickets = data.data;
        }, function(err) {
          NotificationService.error('Error loading tickets for your practice',err);
        });
      }
    }
    reset();
    // setup socket observer to listen for new messages
    //begin websocket observer setup
    var scope = $scope;
    var strm = 'notification';
    var cmds_observed = [
      'model_create_support_models_message',
      'model_update_support_models_message',
      //'model_delete_support_models_message'
    ];
    scope.notificationObservableId = WebsocketService.observe(
      strm,
      cmds_observed,
      function(stream, cmd, params) {
        reset();
        $log.debug('Websocket message received in SUPPORT with ID: ' + scope.notificationObservableId + '; STREAM: ' + stream + '; CMD: ' + cmd + '; PARAMS: ' + JSON.stringify(params));
      }
    );
    $log.debug('Websocket observer registered in SUPPORT with ID: ' + scope.notificationObservableId + '; STREAM: ' + strm + '; CMDS_OBSERVED: ' + JSON.stringify(cmds_observed));
    $scope.$on('$destroy', function() {
      WebsocketService.unobserve(scope.notificationObservableId);
      $log.debug('Websocket observer unregistered in SUPPORT with ID: ' + scope.notificationObservableId);
    });
    //end websocket observer setup

    $scope.addSupportTicket = function() {
      var addSupportTicketModal = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'addSupportTicket.html',
          controller: 'addSupportTicketModalInstanceCtrl',
          size: 'medium',
      });

      addSupportTicketModal.result.then(function () {
        reset();
      }, function () {
      });

    };

    $scope.maintainSupportTicket = function(ticket, forPractice) {
      $scope.maintainSupportTicketModal = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'maintainSupportTicket.html',
          controller: 'maintainSupportTicketModalInstanceCtrl',
          size: 'lg',
          resolve: {
            ticket: function () {
              return ticket;
            },
            forPractice: function () {
              return forPractice;
            },
          },

      });

      $scope.maintainSupportTicketModal.result.then(function () {
      }, function () {
        $scope.maintainSupportTicketModal = null;
        reset();
      });

    };
  }

  function addSupportTicketModalInstanceCtrl($scope, $uibModalInstance, SupportService, NotificationService) {
    $scope.ticket = SupportService.ticketUnderConstruction;

    $scope.ok = function () {
      SupportService.createTicketForUser($scope.ticket).then(function() {
         NotificationService.success('Your ticket has been sent to us.');
         $scope.clear();
         $uibModalInstance.close($scope.ticket);
      }, function(err) {
          NotificationService.error('Error submitting ticket',err);
      });

    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.clear = function () {
      $scope.ticket.description = "";
    }
  }

  function maintainSupportTicketModalInstanceCtrl($scope, $uibModalInstance, SupportService, NotificationService, PrincipalService, ticket, forPractice) {
    $scope.ticket = ticket;
    $scope.forPractice = forPractice;
    $scope.text = '';

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.createMessage = function() {
      var serviceMethod = SupportService.createMessageForTicket;
      if (forPractice) {
        serviceMethod = SupportService.createMessageForPracticeTicket;
      }
      serviceMethod($scope.ticket.id, {text: $scope.text}).then(function(data) {
         NotificationService.success('Your message has been sent to us.');
         var message = data.data;
         message.user = { //add in last and first so it displays correctly
           id: message.user,
           last_name: (PrincipalService.getIdentity()) ? PrincipalService.getIdentity().last_name : '',
           first_name: (PrincipalService.getIdentity()) ? PrincipalService.getIdentity().first_name : ''
         }
         $scope.ticket.ticket_messages.push(data.data)
         $scope.clear();
      }, function(err) {
          NotificationService.error('Error submitting your message',err);
      });
    };

    $scope.setMessageRead = function(message) {
      SupportService.updateMessage(message.id, message).then(function() {
        NotificationService.success('Set message read');
      }, function(err) {
        message.read = !message.read;
        NotificationService.error('Error setting message to read',err);
      });
    };

    $scope.clear = function () {
      $scope.text = "";
    };
  }

  angular.module('common.support', [])
    .controller('SupportCtrl', supportCtrl)
    .controller('addSupportTicketModalInstanceCtrl', addSupportTicketModalInstanceCtrl)
    .controller('maintainSupportTicketModalInstanceCtrl', maintainSupportTicketModalInstanceCtrl);
})();

//HEAD 
(function(app) {
try { app = angular.module("templates"); }
catch(err) { app = angular.module("templates", []); }
app.run(["$templateCache", function($templateCache) {
"use strict";

$templateCache.put("src/common/analytics-active-claims-next.tpl.html","<div class=\"page-bar\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <a ui-sref=\"analytics-overview\">Overview</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <a ui-sref=\"analytics-patients\">Patients and Receivables</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <span>Accounts Receivables</span>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>Active Claims</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"portlet light porlet-fit\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption\">\n" +
    "      <i class=\"icomoon icon-icomoon-key font-red-sunglo\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold uppercase\">Active Claims</span>\n" +
    "    </div>\n" +
    "    <div class=\"actions\">\n" +
    "      <button class=\"btn btn-wide default\" type=\"button\" ng-click=\"$ctrl.load()\">\n" +
    "        Reload\n" +
    "      </button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body pos-r\">\n" +
    "    <wave-spinner ng-if=\"$ctrl.loading\" ng-class=\"{'global-loader': $ctrl.uiGrid.data}\"></wave-spinner>\n" +
    "    <div class=form-group row\">\n" +
    "      <div ng-if=\"$ctrl.uiGrid.data\"\n" +
    "        ui-grid=\"$ctrl.uiGrid\"  \n" +
    "        ui-grid-exporter\n" +
    "        ui-grid-resize-columns\n" +
    "        ui-grid-selection>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/analytics-active-claims.tpl.html","<style>\n" +
    "  .currency {\n" +
    "    text-align: right;\n" +
    "  }\n" +
    "</style>\n" +
    "<!-- BEGIN PAGE HEADER-->\n" +
    "<!--\n" +
    "<h3 class=\"page-title\"> Patients Analytcs\n" +
    "    <small>analyze information about your customers</small>\n" +
    "</h3>\n" +
    "-->\n" +
    "<div class=\"page-bar\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <!-- <i class=\"icomoon icon-icomoon-home6\"></i> -->\n" +
    "      <a ui-sref=\"analytics-overview\">Overview</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <a ui-sref=\"analytics-patients\">Patients and Receivables</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <span>Accounts Receivables</span>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>Active Claims</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit pos-r\">\n" +
    "      <wave-spinner class=\"modal-loader\" ng-if=\"loadingActiveClaimData\"></wave-spinner>\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "            <i class=\"icomoon icon-icomoon-key font-red-sunglo\"></i>\n" +
    "            <span class=\"caption-subject font-red-sunglo bold uppercase\">Active Claims</span>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <div class=form-group row\">\n" +
    "          <div id=\"claims-grid\" ui-grid=\"gridOptions\" ui-grid-selection ui-grid-resize-columns ui-grid-exporter ui-grid-auto-resize class=\"grid\">\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/analytics-overview.tpl.html","<overview></overview>\n" +
    "\n" +
    "")

$templateCache.put("src/common/analytics-patients-aging.tpl.html","<div class=\"page-bar\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <a ui-sref=\"analytics-overview\">Overview</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <a ui-sref=\"analytics-patients\">Patients and Receivables</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <span>Accounts Receivables</span>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>Patient Aging</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"portlet light porlet-fit\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption\">\n" +
    "      <i class=\"icomoon icon-icomoon-key font-red-sunglo\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold uppercase\">Patient Aging</span>\n" +
    "    </div>\n" +
    "    <div class=\"actions\">\n" +
    "      <div class=\"checkbox-inline custom-control custom-checkbox m-r\">\n" +
    "        <label>\n" +
    "          <input type=\"checkbox\" \n" +
    "            ng-change=\"$ctrl.uiGridEx.refresh()\"\n" +
    "            ng-model=\"$ctrl.uiGridEx.filters.showNegativeAmounts\">\n" +
    "          <span class=\"custom-control-indicator\"></span>\n" +
    "          Show patients with credit balances?\n" +
    "        </label>\n" +
    "      </div>\n" +
    "      \n" +
    "      <button class=\"btn btn-wide default\" type=\"button\" ng-click=\"$ctrl.load()\">\n" +
    "        Reload\n" +
    "      </button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body pos-r\">\n" +
    "    <wave-spinner ng-if=\"$ctrl.loading\" ng-class=\"{'global-loader': $ctrl.uiGrid.data}\"></wave-spinner>\n" +
    "    <div class=form-group row\">\n" +
    "      <div ng-if=\"$ctrl.uiGrid.data\"\n" +
    "        ui-grid=\"$ctrl.uiGrid\"  \n" +
    "        ui-grid-exporter\n" +
    "        ui-grid-resize-columns\n" +
    "        ui-grid-selection>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/analytics-patients-ar-current.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<!--\n" +
    "<h3 class=\"page-title\"> Patients Analytcs\n" +
    "    <small>analyze information about your customers</small>\n" +
    "</h3>\n" +
    "-->\n" +
    "  <div class=\"page-bar\">\n" +
    "    <ul class=\"page-breadcrumb\">\n" +
    "        <li>\n" +
    "            <!-- <i class=\"icomoon icon-icomoon-home6\"></i> -->\n" +
    "            <a ui-sref=\"analytics-overview\">Overview</a>\n" +
    "            <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "            <a ui-sref=\"analytics-patients\">Patients and Receivables</a>\n" +
    "            <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "            <span>Accounts Receivables</span>\n" +
    "            <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "        </li>\n" +
    "        <li>\n" +
    "            <span>Patient Aging</span>\n" +
    "        </li>\n" +
    "    </ul>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "<div class=\"row\">\n" +
    "    <div class=\"col-md-12\">\n" +
    "        <div class=\"portlet light porlet-fit pos-r\">\n" +
    "        <wave-spinner class=\"modal-loader\" ng-if=\"loadingPatientsArAccountsTotals\"></wave-spinner>\n" +
    "        <div class=\"portlet-title\">\n" +
    "            <div class=\"caption\">\n" +
    "                <i class=\"icomoon icon-icomoon-key font-red-sunglo\"></i>\n" +
    "                <span class=\"caption-subject font-red-sunglo bold uppercase\">Patient Aging</span>\n" +
    "            </div>\n" +
    "            <div class=\"checkbox-inline custom-control custom-checkbox pull-right\">\n" +
    "                <label>\n" +
    "                  <input type=\"checkbox\" ng-model=\"showPatientsWithCreditBalances\" ng-change=\"onShowPatientsWithCreditBalances();\">\n" +
    "                  <span class=\"custom-control-indicator\"></span>\n" +
    "                  Show patients with credit balances?\n" +
    "                </label>\n" +
    "              </div>\n" +
    "        </div>\n" +
    "        <div class=\"portlet-body\">\n" +
    "            <div id=\"patients-grid\" ui-grid=\"gridOptions\" ui-grid-selection ui-grid-resize-columns ui-grid-exporter class=\"grid\">\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/analytics-patients-payments-reconciliation.tpl.html","  <div class=\"page-bar\">\n" +
    "    <ul class=\"page-breadcrumb\">\n" +
    "      <li>\n" +
    "        <!-- <i class=\"icomoon icon-icomoon-home6\"></i> -->\n" +
    "        <a ui-sref=\"analytics-overview\">Overview</a>\n" +
    "        <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "        <a ui-sref=\"analytics-patients\">Patients and Receivables</a>\n" +
    "        <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "        <span>Accounts Receivables</span>\n" +
    "        <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      </li>\n" +
    "      <li>\n" +
    "        <span>Payments</span>\n" +
    "      </li>\n" +
    "    </ul>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit pos-r\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-if=\"loadingPaymentsForDateRange\"></wave-spinner>\n" +
    "    <div class=\"portlet-title\">\n" +
    "      <div class=\"caption\">\n" +
    "        <i class=\"icomoon icon-icomoon-key font-red-sunglo\"></i>\n" +
    "        <span class=\"caption-subject font-red-sunglo bold uppercase\">Payments</span>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"portlet-body\">\n" +
    "      <div class=\"form-group row\">\n" +
    "        <div class=\"col-md-2\">\n" +
    "          <label>Date Range:</label>\n" +
    "        </div>\n" +
    "        <div class=\"col-md-4 btn-group\">\n" +
    "          <label class=\"btn btn-primary\" ng-model=\"duration\" uib-btn-radio=\"'today'\">Today</label>\n" +
    "          <label class=\"btn btn-primary\" ng-model=\"duration\" uib-btn-radio=\"'mtd'\">Month To Date</label>\n" +
    "          <label class=\"btn btn-primary\" ng-model=\"duration\" uib-btn-radio=\"'priormonth'\">Prior Month</label>\n" +
    "          <label class=\"btn btn-primary\" ng-model=\"duration\" uib-btn-radio=\"'custom'\">Custom</label>\n" +
    "        </div>\n" +
    "          <label>{{from && through ? from + ' - ' + through : ''}}</label>\n" +
    "      </div>\n" +
    "      <div class=\"form-group row\">\n" +
    "        <form name=\"treatmentquery\" role=\"form\">\n" +
    "        <div class=\"col-md-2\" ng-hide=\"duration !== 'custom'\">\n" +
    "          <label></label>\n" +
    "        </div>\n" +
    "        <div class=\"col-md-2\" ng-hide=\"duration !== 'custom'\">\n" +
    "          <label>From:</label>\n" +
    "          <custom-datepicker-popup\n" +
    "            form-name=\"'treatmentquery'\"\n" +
    "            input-name=\"'fromName'\"\n" +
    "            format=\"'MM/dd/yyyy'\"\n" +
    "            max-date=\"through\"\n" +
    "            show-placeholder=\"false\"\n" +
    "            ng-model=\"from\"\n" +
    "            ng-required=\"true\"/>\n" +
    "        </div>\n" +
    "        <div class=\"form-group col-md-2\"  ng-hide=\"duration !== 'custom'\">\n" +
    "          <label>Through:</label>\n" +
    "          <custom-datepicker-popup\n" +
    "            form-name=\"'treatmentquery'\"\n" +
    "            input-name=\"'throughName'\"\n" +
    "            format=\"'MM/dd/yyyy'\"\n" +
    "            max-date=\"maxThroughDate\"\n" +
    "            min-date='from'\n" +
    "            show-placeholder=\"false\"\n" +
    "            ng-model=\"through\"\n" +
    "            ng-required=\"true\"/>\n" +
    "        </div>\n" +
    "        <div class=\"form-group col-md-2\"  ng-hide=\"duration !== 'custom'\">\n" +
    "          <div class=\"row\">\n" +
    "          <label>&nbsp;</label>\n" +
    "          </div>\n" +
    "          <div class=\"row\">\n" +
    "          <button type=\"button\" class=\"btn btn-primary btn-circle\" ng-click=\"queryForDatetimeRange(from, through);\">Query</button>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        </form>\n" +
    "      </div>\n" +
    "      <div class=\"form-group row\">\n" +
    "        <div class=\"col-md-2\">\n" +
    "          <label>Patient: </label>\n" +
    "        </div>\n" +
    "        <div class=\"col-md-10\">\n" +
    "          <ui-select ng-model=\"$parent.patient\">\n" +
    "            <ui-select-match>\n" +
    "              {{ $select.selected.name }}\n" +
    "            </ui-select-match>\n" +
    "            <ui-select-choices repeat=\"patient in patients | propsFilter: {filter:$select.search, props:['name'] } | orderBy: ['name']\">\n" +
    "              <span ng-bind-html=\"patient.name | highlight: $select.search\"></span>\n" +
    "            </ui-select-choices>\n" +
    "          </ui-select>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"form-group row\">\n" +
    "        <div class=\"col-md-2\">\n" +
    "          <label>Provider:</label>\n" +
    "        </div>\n" +
    "        <div class=\"col-md-10\">\n" +
    "          <ui-select ng-model=\"$parent.provider\">\n" +
    "            <ui-select-match>\n" +
    "              {{ $select.selected.name }}\n" +
    "              -\n" +
    "              {{ $select.selected.email }}\n" +
    "            </ui-select-match>\n" +
    "            <ui-select-choices repeat=\"provider in providers | propsFilter: {filter:$select.search, props:['name'] } | orderBy: ['name']\">\n" +
    "              <span ng-bind-html=\"provider.name | highlight: $select.search\"></span>\n" +
    "              <small class=\"m-l-sm\">{{ provider.email }}</small>\n" +
    "            </ui-select-choices>\n" +
    "          </ui-select>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=form-group row\">\n" +
    "        <div id=\"treatments-grid\" ui-grid=\"gridOptions\" ui-grid-selection ui-grid-resize-columns ui-grid-exporter ui-grid-grouping class=\"grid\">\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/analytics-patients.tpl.html","  <div class=\"page-bar\">\n" +
    "    <ul class=\"page-breadcrumb\">\n" +
    "        <li>\n" +
    "            <!-- <i class=\"icomoon icon-icomoon-home6\"></i> -->\n" +
    "            <a ui-sref=\"analytics-overview\">Overview</a>\n" +
    "            <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "            <span>Patients and Receivables</span>\n" +
    "        </li>\n" +
    "    </ul>\n" +
    "</div>\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-6\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <!--<i class=\"icomoon icon-icomoon-key font-red-sunglo\"></i>-->\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Accounts Receivables</span>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <div><a ui-sref=\"analytics-patients-aging\">Patient Aging</a></div>\n" +
    "        <div><a ui-sref=\"analytics-patients-payments-reconciliation\">Payments</a></div>\n" +
    "        <div><a ui-sref=\"analytics-active-claims-next\">Active Claims</a></div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/analytics-productivity-treatments-detail.tpl.html","<style>\n" +
    "  .currency {\n" +
    "    text-align: right;\n" +
    "  }\n" +
    "</style>\n" +
    "<div class=\"page-bar\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <!-- <i class=\"icomoon icon-icomoon-home6\"></i> -->\n" +
    "      <a ui-sref=\"analytics-overview\">Overview</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <a ui-sref=\"analytics-productivity\">Productivity</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <span>Treatments</span>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>Completed</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit pos-r\">\n" +
    "      <wave-spinner class=\"modal-loader\" ng-if=\"loadingTreatmentsForDateRange\"></wave-spinner>\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-key font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Treatments Completed</span>\n" +
    "          <small>(may or may not have been billed yet)</small>\n" +
    "        </div>\n" +
    "        <div class=\"checkbox-inline custom-control custom-checkbox pull-right\">\n" +
    "          <label>\n" +
    "            <input type=\"checkbox\" ng-model=\"showAbandoned\" ng-change=\"onShowAbandonedChanged();\">\n" +
    "            <span class=\"custom-control-indicator\"></span>\n" +
    "            Show abandoned?\n" +
    "          </label>\n" +
    "        </div>\n" +
    "		    <div class=\"actions\">\n" +
    "		    </div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-md-2\">\n" +
    "            <label>Date Range:</label>\n" +
    "          </div>\n" +
    "		      <div class=\"col-md-4 btn-group\">\n" +
    "            <label class=\"btn btn-primary\" ng-model=\"duration\" uib-btn-radio=\"'today'\">Today</label>\n" +
    "            <label class=\"btn btn-primary\" ng-model=\"duration\" uib-btn-radio=\"'mtd'\">Month To Date</label>\n" +
    "            <label class=\"btn btn-primary\" ng-model=\"duration\" uib-btn-radio=\"'priormonth'\">Prior Month</label>\n" +
    "            <label class=\"btn btn-primary\" ng-model=\"duration\" uib-btn-radio=\"'custom'\">Custom</label>\n" +
    "		      </div>\n" +
    "            <label>{{from && through ? getFromDateFormat(from)  + ' - ' + getThroughDateFormat(through) : ''}}</label>\n" +
    "        </div>\n" +
    "        <div class=\"form-group row\">\n" +
    "          <form name=\"treatmentquery\" role=\"form\">\n" +
    "          <div class=\"col-md-2\" ng-hide=\"duration !== 'custom'\">\n" +
    "            <label></label>\n" +
    "          </div>\n" +
    "          <div class=\"col-md-2\" ng-hide=\"duration !== 'custom'\">\n" +
    "            <label>From:</label>\n" +
    "            <custom-datepicker-popup\n" +
    "              form-name=\"'treatmentquery'\"\n" +
    "              input-name=\"'fromName'\"\n" +
    "              format=\"'MM/dd/yyyy'\"\n" +
    "              max-date=\"through\"\n" +
    "              show-placeholder=\"false\"\n" +
    "              ng-model=\"from\"\n" +
    "              ng-required=\"true\"/>\n" +
    "          </div>\n" +
    "          <div class=\"form-group col-md-2\"  ng-hide=\"duration !== 'custom'\">\n" +
    "            <label>Through:</label>\n" +
    "            <custom-datepicker-popup\n" +
    "              form-name=\"'treatmentquery'\"\n" +
    "              input-name=\"'throughName'\"\n" +
    "              format=\"'MM/dd/yyyy'\"\n" +
    "              max-date=\"maxThroughDate\"\n" +
    "              min-date='from'\n" +
    "              show-placeholder=\"false\"\n" +
    "              ng-model=\"through\"\n" +
    "              ng-required=\"true\"/>\n" +
    "          </div>\n" +
    "          <div class=\"form-group col-md-2\"  ng-hide=\"duration !== 'custom'\">\n" +
    "            <div class=\"row\">\n" +
    "            <label>&nbsp;</label>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "            <button type=\"button\" class=\"btn btn-primary btn-circle\" ng-click=\"getDataForDatetimeRange(from, through);\">Query</button>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          </form>\n" +
    "        </div>\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"form-group\">\n" +
    "            <div class=\"col-md-2\">\n" +
    "              <label>Provider: </label>\n" +
    "            </div>\n" +
    "            <div class=\"col-md-10\">\n" +
    "              <ui-select ng-model=\"filterModels.provider\">\n" +
    "                <ui-select-match placeholder=\"Select a Provider\" >\n" +
    "                  <span style=\"text-overflow:ellipsis\"  title=\"{{ ($select.selected.id !== 'all') ? ($select.selected.name) : ''}}\" >\n" +
    "                    <!--{{($select.selected.id !== 'all') ? '#' : ''}}\n" +
    "                    {{($select.selected.id !== 'all') ? $select.selected.totalRevenueRank : '' }}\n" +
    "                    {{($select.selected.id !== 'all') ? ' - ' : ''}} -->\n" +
    "                    {{ $select.selected.name }}\n" +
    "                    <!--<small>{{ ($select.selected.id !=='all') ? ('($' + $select.selected.totalRevenue + ')') : '' }}</small>-->\n" +
    "                  </span>\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                  repeat=\"item as item in providers | filter: $select.search | orderBy: 'totalRevenueRank'\"\n" +
    "                >\n" +
    "                  <span style=\"text-overflow: ellipsis;\" title=\"{{ (item.id !=='all') ? (item.name + ' $'+ item.totalRevenue) : ''}}\">\n" +
    "                    {{(item.id !== 'all') ? '#' : ''}}\n" +
    "                    {{(item.id !== 'all') ? item.totalRevenueRank : ''}}\n" +
    "                    {{(item.id !== 'all') ? ' - ' : ''}} \n" +
    "                    {{ item.name }}\n" +
    "                    <small >{{ (item.id !== 'all') ? ('($' + item.totalRevenue + ')') : '' }}</small>\n" +
    "                  </span>\n" +
    "                </ui-select-choices>\n" +
    "              </ui-select>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>        \n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"form-group\">\n" +
    "            <div class=\"col-md-2\">\n" +
    "              <label>Patient: </label>\n" +
    "            </div>\n" +
    "            <div class=\"col-md-10\">\n" +
    "              <ui-select ng-model=\"filterModels.patient\">\n" +
    "                <ui-select-match placeholder=\"Select a Patient\" >\n" +
    "                  <span style=\"text-overflow:ellipsis\"  title=\"{{ ($select.selected.id !== 'all') ? ($select.selected.name) : ''}}\" >\n" +
    "                    {{ $select.selected.name }}\n" +
    "                  </span>\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                  repeat=\"item as item in patients | filter: $select.search | orderBy: 'name'\"\n" +
    "                >\n" +
    "                  <span style=\"text-overflow: ellipsis;\" title=\"{{ (item.id !=='all') ? (item.name) : ''}}\">\n" +
    "                    {{ item.name }}\n" +
    "                  </span>\n" +
    "                </ui-select-choices>\n" +
    "              </ui-select>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>  \n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"form-group\">\n" +
    "            <div class=\"col-md-2\">\n" +
    "              <label>Procedure: </label>\n" +
    "            </div>\n" +
    "            <div class=\"col-md-10\">\n" +
    "              <ui-select ng-model=\"filterModels.procedure\">\n" +
    "                <ui-select-match placeholder=\"Select a Procedure\" >\n" +
    "                  <span style=\"text-overflow:ellipsis\"  title=\"{{ ($select.selected.id !== 'all') ? ($select.selected.name) : ''}}\" >\n" +
    "                    {{ $select.selected.name }}\n" +
    "                  </span>\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                  repeat=\"item as item in procedures | filter: $select.search | orderBy: 'name'\"\n" +
    "                >\n" +
    "                  <span style=\"text-overflow: ellipsis;\" title=\"{{ (item.id !=='all') ? (item.name) : ''}}\">\n" +
    "                    {{ item.name }}\n" +
    "                  </span>\n" +
    "                </ui-select-choices>\n" +
    "              </ui-select>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>          \n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"form-group\">\n" +
    "            <div class=\"col-md-2\">\n" +
    "              <label>Location: </label>\n" +
    "            </div>\n" +
    "            <div class=\"col-md-10\">\n" +
    "              <ui-select ng-model=\"filterModels.location\">\n" +
    "                <ui-select-match placeholder=\"Select a Location\" >\n" +
    "                  <span style=\"text-overflow:ellipsis\"  title=\"{{ ($select.selected.id !== 'all') ? ($select.selected.name) : ''}}\" >\n" +
    "                    <!--{{($select.selected.id !== 'all') ? '#' : ''}}\n" +
    "                    {{($select.selected.id !== 'all') ? $select.selected.totalRevenueRank : '' }}\n" +
    "                    {{($select.selected.id !== 'all') ? ' - ' : ''}} -->\n" +
    "                    {{ $select.selected.name }}\n" +
    "                    <!--<small>{{ ($select.selected.id !=='all') ? ('($' + $select.selected.totalRevenue + ')') : '' }}</small>-->\n" +
    "                  </span>\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                  repeat=\"item as item in locations | filter: $select.search | orderBy: 'totalRevenueRank'\"\n" +
    "                >\n" +
    "                  <span style=\"text-overflow: ellipsis;\" title=\"{{ (item.id !=='all') ? (item.name + ' $'+ item.totalRevenue) : ''}}\">\n" +
    "                    {{(item.id !== 'all') ? '#' : ''}}\n" +
    "                    {{(item.id !== 'all') ? item.totalRevenueRank : ''}}\n" +
    "                    {{(item.id !== 'all') ? ' - ' : ''}} \n" +
    "                    {{ item.name }}\n" +
    "                    <small >{{ (item.id !== 'all') ? ('($' + item.totalRevenue + ')') : '' }}</small>\n" +
    "                  </span>\n" +
    "                </ui-select-choices>\n" +
    "              </ui-select>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>        \n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"form-group\">\n" +
    "            <div class=\"col-md-2\">\n" +
    "              <label>Referring Practice: </label>\n" +
    "            </div>\n" +
    "            <div class=\"col-md-10\">\n" +
    "              <ui-select ng-model=\"filterModels.referralPractice\">\n" +
    "                <ui-select-match placeholder=\"Select a Referral Practice\" >\n" +
    "                  <span style=\"text-overflow:ellipsis\"  title=\"{{ ($select.selected.id !== 'all') ? ($select.selected.name) : ''}}\" >\n" +
    "                    <!--{{($select.selected.id !== 'all') ? '#' : ''}}\n" +
    "                    {{($select.selected.id !== 'all') ? $select.selected.totalRevenueRank : '' }}\n" +
    "                    {{($select.selected.id !== 'all') ? ' - ' : ''}} -->\n" +
    "                    {{ $select.selected.name }}\n" +
    "                    <!--<small>{{ ($select.selected.id !=='all') ? ('($' + $select.selected.totalRevenue + ')') : '' }}</small>-->\n" +
    "                  </span>\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                  repeat=\"item as item in referralPractices | filter: $select.search | orderBy: 'totalRevenueRank'\"\n" +
    "                >\n" +
    "                  <span style=\"text-overflow: ellipsis;\" title=\"{{ (item.id !=='all') ? (item.name + ' $'+ item.totalRevenue) : ''}}\">\n" +
    "                    {{(item.id !== 'all') ? '#' : ''}}\n" +
    "                    {{(item.id !== 'all') ? item.totalRevenueRank : ''}}\n" +
    "                    {{(item.id !== 'all') ? ' - ' : ''}} \n" +
    "                    {{ item.name }}\n" +
    "                    <small >{{ (item.id !== 'all') ? ('($' + item.totalRevenue + ')') : '' }}</small>\n" +
    "                  </span>\n" +
    "                </ui-select-choices>\n" +
    "              </ui-select>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>        \n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"form-group\">\n" +
    "            <div class=\"col-md-2\">\n" +
    "              <label>Referring User: </label>\n" +
    "            </div>\n" +
    "            <div class=\"col-md-10\">\n" +
    "              <ui-select ng-model=\"filterModels.referralUser\">\n" +
    "                <ui-select-match placeholder=\"Select a Referral User\" >\n" +
    "                  <span style=\"text-overflow:ellipsis\"  title=\"{{ ($select.selected.id !== 'all') ? ($select.selected.name) : ''}}\" >\n" +
    "                    <!--{{($select.selected.id !== 'all') ? '#' : ''}}\n" +
    "                    {{($select.selected.id !== 'all') ? $select.selected.totalRevenueRank : '' }}\n" +
    "                    {{($select.selected.id !== 'all') ? ' - ' : ''}} -->\n" +
    "                    {{ $select.selected.name }}\n" +
    "                    <!--<small>{{ ($select.selected.id !=='all') ? ('($' + $select.selected.totalRevenue + ')') : '' }}</small>-->\n" +
    "                  </span>\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                  repeat=\"item as item in referralUsers | filter: $select.search | orderBy: 'totalRevenueRank'\"\n" +
    "                >\n" +
    "                  <span style=\"text-overflow: ellipsis;\" title=\"{{ (item.id !=='all') ? (item.name + ' $'+ item.totalRevenue) : ''}}\">\n" +
    "                    {{(item.id !== 'all') ? '#' : ''}}\n" +
    "                    {{(item.id !== 'all') ? item.totalRevenueRank : ''}}\n" +
    "                    {{(item.id !== 'all') ? ' - ' : ''}} \n" +
    "                    {{ item.name }}\n" +
    "                    <small >{{ (item.id !== 'all') ? ('($' + item.totalRevenue + ')') : '' }}</small>\n" +
    "                  </span>\n" +
    "                </ui-select-choices>\n" +
    "              </ui-select>\n" +
    "\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=form-group row\">\n" +
    "          <div id=\"treatments-grid\" ui-grid=\"gridOptions\" ui-grid-selection ui-grid-resize-columns ui-grid-exporter ui-grid-auto-resize class=\"grid\">\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/analytics-productivity.tpl.html","  <div class=\"page-bar\">\n" +
    "    <ul class=\"page-breadcrumb\">\n" +
    "        <li>\n" +
    "            <!-- <i class=\"icomoon icon-icomoon-home6\"></i> -->\n" +
    "            <a ui-sref=\"analytics-overview\">Overview</a>\n" +
    "            <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "            <span>Productivity</span>\n" +
    "        </li>\n" +
    "    </ul>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-6\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <!--<i class=\"icomoon icon-icomoon-key font-red-sunglo\"></i>-->\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Treatments</span>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <div><a ui-sref=\"analytics-productivity-treatments-detail\">Treatments Completed</a></div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/analytics-services.tpl.html","analytics-services\n" +
    "")

$templateCache.put("src/common/analytics.tpl.html","<analytics-tabs></analytics-tabs>\n" +
    "")

$templateCache.put("src/common/appointment-reminder.tpl.html","<div class=\"row\" style=\"padding-top: 30px;\" ng-init=\"init()\">\n" +
    "  <div class=\"col-md-6 col-md-offset-3\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase text-center\">Appointment Reminder</span>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"portlet-body\">\n" +
    "\n" +
    "        <p>Patient: \n" +
    "          <strong>{{appointment.patient_first_name}} {{appointment.patient_last_name}}</strong>\n" +
    "        </p>\n" +
    "        <p>Appointment Date and Time:\n" +
    "        <strong>{{ appointment.start_datetime | date:\"MM/dd/yyyy 'at' h:mma\"}}</strong></p>\n" +
    "        <p>Appointment Location:</p>\n" +
    "        <address>\n" +
    "          <strong>\n" +
    "            {{appointment.name}}<br>\n" +
    "            {{appointment.street1}}<br>\n" +
    "            {{appointment.street2}}<br ng-show=\"appointment.street2\">\n" +
    "            {{appointment.city}}, {{appointment.state}}, {{appointment.zip_code}}<br>\n" +
    "          </strong>\n" +
    "        </address>\n" +
    "        <p>Phone:\n" +
    "          <phone>\n" +
    "            <strong>\n" +
    "              {{appointment.phone}}\n" +
    "            </strong>\n" +
    "          </phone>\n" +
    "        </p>\n" +
    "\n" +
    "        <p>Please arrive 15 minutes early and bring a piece of photo ID.</p>\n" +
    "\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"col-md-6 col-md-offset-3\">\n" +
    "            <button ng-if='appointmentConfirmed' ng-disabled=\"true\" class=\"btn btn-primary btn-lg btn-block\" type=\"button\">Confirmed</button>\n" +
    "            <button ng-if='!appointmentConfirmed' class=\"btn btn-primary btn-lg btn-block\" type=\"button\" ng-click=\"confirmAppointmentReminder();\">Confirm</button>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/archives.tpl.html","\n" +
    "<div class=\"row\" ng-if=\"$ctrl.archiveParamsCreate || ($ctrl.archives && $ctrl.archives.length)\">\n" +
    "\n" +
    "  <div ng-if=\"$ctrl.archiveParamsCreate\" class=\"col-xs-12 col-sm-4 col-md-3 m-b\">\n" +
    "    <div class=\"thumbnail\">\n" +
    "      <div class=\"drop-box text-center\" style=\"line-height:200px; font-size:14px;\"\n" +
    "        ngf-allow-dir=\"true\"\n" +
    "        ngf-drag-over-class=\"'dragover'\"\n" +
    "        ngf-drop-available=\"dropAvailable\"\n" +
    "        ngf-hide-on-drop-not-available=\"true\"\n" +
    "        ngf-max-size=\"'700MB'\"\n" +
    "        ngf-multiple=\"true\"\n" +
    "        ngf-drop=\"$ctrl.create($files)\">\n" +
    "        {{ $ctrl.ngfDropText }}\n" +
    "      </div>\n" +
    "      <div class=\"caption text-center p-x-0 p-y-0\">\n" +
    "        <label class=\"btn btn-file btn-info btn-block\" style=\"margin-top:4px\">\n" +
    "          {{ $ctrl.ngfSelectText }}\n" +
    "          <input type=\"file\"\n" +
    "            ngf-allow-dir=\"true\"\n" +
    "            ngf-max-size=\"'700MB'\"\n" +
    "            ngf-multiple=\"true\"\n" +
    "            ngf-select=\"$ctrl.create($files)\">\n" +
    "        </label>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"col-xs-12 col-sm-4 col-md-3 m-b\" archive=\"archive\" ng-repeat=\"archive in $ctrl.archives\">\n" +
    "    <div class=\"thumbnail\" ng-click=\"archive.id && $ctrl.open(archive)\">\n" +
    "      <wave-spinner class=\"modal-loader\" ng-show=\"archive.$loading\"></wave-spinner>\n" +
    "      <div class=\"main\" style=\"height:200px;\">\n" +
    "        <div class=\"btn-group top-right\" stop-click-propagation ng-if=\"archive.id\">\n" +
    "          <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.open(archive)\">\n" +
    "            <i class=\"icomoon icon-icomoon-eye2\"></i>\n" +
    "          </div>\n" +
    "          <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\"\n" +
    "            ng-click=\"$ctrl.download(archive)\">\n" +
    "            <i class=\"icomoon icon-icomoon-download\"></i>\n" +
    "          </div>\n" +
    "          <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\"\n" +
    "            ng-click=\"$ctrl.destroy(archive)\">\n" +
    "            <i class=\"icomoon icon-icomoon-bin\"></i>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <h6 class=\"size\">{{ archive.archive_metadata.size | filesize }}</h6>\n" +
    "        <div class=\"progress\">\n" +
    "          <div class=\"progress-bar\"></div>\n" +
    "        </div>\n" +
    "        <!-- <pre class=\"text-left\">{{ archive | json }}</pre> -->\n" +
    "      </div>\n" +
    "      <div class=\"caption\">\n" +
    "        <label>{{ archive.name || \"&nbsp;\" }}</label>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <a class=\"hide\" ng-attr-id=\"archive-{{ $ctrl.uniqId }}-dwl\"></a>\n" +
    "\n" +
    "  <script type=\"text/ng-template\" id=\"#archive-destroy\">\n" +
    "    <div class=\"modal-header\">\n" +
    "      <h3 class=\"modal-title\">Are you sure?</h3>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body\">\n" +
    "      <wave-spinner class=\"modal-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "      This action can not be undone\n" +
    "    </div>\n" +
    "    <div class=\"modal-footer\">\n" +
    "      <button class=\"btn p-x-md btn-default\" ng-click=\"$dismiss()\">Cancel</button>\n" +
    "      <button class=\"btn p-x-md btn-danger\" type=\"submit\" ng-click=\"$ctrl.submit()\" ng-disabled=\"$ctrl.loading\">Delete</button>\n" +
    "    </div>\n" +
    "  </script>\n" +
    "\n" +
    "  <script type=\"text/ng-template\" id=\"#archive-open\">\n" +
    "    <div class=\"modal-header\">\n" +
    "      <h3 class=\"modal-title\">Archive <small>{{ $ctrl.obj.name }}</small></h3>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body\">\n" +
    "      <wave-spinner class=\"modal-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "      <form>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label>Name</label>\n" +
    "          <input type=\"text\" class=\"form-control\" name=\"name\" ng-model=\"$ctrl.obj.name\">\n" +
    "          <span class=\"help-block\" ng-if=\"$ctrl.canUpdate\">If left blank, it will be inferred from the filename</span>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label>Description</label>\n" +
    "          <textarea class=\"form-control\" name=\"description\" rows=\"4\" ng-model=\"$ctrl.obj.description\"></textarea>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\" ng-if=\"$ctrl.obj.archive_metadata.content_type\">\n" +
    "          <label>File</label>\n" +
    "          <embed class=\"w-full img-thumbnail\" ng-attr-src=\"{{ $ctrl.obj.archive | trustAsResourceUrl }}\" ng-attr-type=\"{{ $ctrl.obj.archive_metadata.content_type }}\" />\n" +
    "        </div>\n" +
    "      </form>\n" +
    "    </div>\n" +
    "    <div class=\"modal-footer\">\n" +
    "      <button class=\"btn p-x btn-success pull-left\" ng-click=\"$ctrl.download()\" ng-disabled=\"$ctrl.loading\">Download</button>\n" +
    "      <button class=\"btn p-x-md btn-default\" ng-click=\"$dismiss()\">Close</button>\n" +
    "      <button class=\"btn p-x-md btn-primary\" ng-click=\"$ctrl.update()\" ng-disabled=\"$ctrl.loading\">Save</button>\n" +
    "    </div>\n" +
    "  </script>\n" +
    "\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/billing-claims-detail.tpl.html","<style>\n" +
    "  .overlay {\n" +
    "    filter: blur(0px);\n" +
    "    -webkit-filter: blur(0px);\n" +
    "    margin-right:0px\n" +
    "  }\n" +
    "</style>\n" +
    "<form name=\"myOuterForm\" novalidate class=\"css-form\" role=\"form\">\n" +
    "	<div id=\"dwtcontrolContainer\">\n" +
    "\n" +
    "\n" +
    "		<div class=\"portlet light porlet-fit\" ng-class=\"{'overlay':actionActive}\">\n" +
    "      <wave-spinner class=\"global-loader\" ng-show=\"actionActive\"></wave-spinner>\n" +
    "			<div class=\"portlet-title\">\n" +
    "				<div class=\"caption\">\n" +
    "					<i class=\"icomoon icon-icomoon-cash3 font-red-sunglo\"></i>\n" +
    "					<span ng-if=\"claim.state==='new'\" class=\"caption-subject font-red-sunglo bold uppercase\">Create Claim {{claim.claim_number}} and account receivables </span>\n" +
    "					<span ng-if=\"claim.state!=='new'\" class=\"caption-subject font-red-sunglo bold uppercase\">Process Claim {{claim.claim_number}}</span>\n" +
    "				</div>\n" +
    "				<div class=\"actions\">\n" +
    "		            <div class=\"btn-group\">\n" +
    "			            <button type=\"button\" class=\"btn p-x btn-default\" back-button ng-disabled=\"actionActive\">\n" +
    "				            <i class=\"icomoon icon-icomoon-arrow-left\"></i> Back\n" +
    "			            </button>\n" +
    "                    </div>\n" +
    "			        <div class=\"btn-group\" uib-dropdown>\n" +
    "			            <button ng-disabled=\"claim.state === 'claim_completed' || !(CanCreateClaim || CanUpdateClaim) || (claim.state === 'new' && !patient.insurance_plan_info)\" type=\"button\" class=\"btn dropdown-toggle p-x btn-primary\" uib-dropdown-toggle>Actions<span class=\"caret\"></span>\n" +
    "			            </button>\n" +
    "			            <ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "				            <li ng-show=\"operationValidForCurrentState(claim, CLAIM_OPERATIONS.MODEL__CREATE) && CanCreateClaim\" >\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.MODEL__CREATE)\"\n" +
    "                                >Create new saved claim and claim A/R</a>\n" +
    "                            </li>\n" +
    "				            <li ng-show=\"operationValidForCurrentState(claim, CLAIM_OPERATIONS.MODEL__UPDATE) && CanUpdateClaim\">\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.MODEL__UPDATE)\"\n" +
    "                                >Save claim form fields</a>\n" +
    "                            </li>\n" +
    "				            <li ng-show=\"operationValidForCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__CLAIM_INSURANCE) && CanUpdateClaim\" >\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__CLAIM_INSURANCE)\"\n" +
    "                                >Send saved claim to primary insurance</a>\n" +
    "                            </li>\n" +
    "				            <li ng-show=\"operationValidForCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__CLAIM_INSURANCE_MANUAL) && CanUpdateClaim\" >\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__CLAIM_INSURANCE_MANUAL)\"\n" +
    "                                >Submit claim to primary insurance MANUALLY</a>\n" +
    "                            </li>\n" +
    "				            <li ng-show=\"operationValidForCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__VOID_INSURANCE_CLAIM) && CanUpdateClaim\" >\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__VOID_INSURANCE_CLAIM)\"\n" +
    "                                >Send request to void claim to primary insurance</a>\n" +
    "                            </li>\n" +
    "				            <li ng-show=\"operationValidForCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__VOID_INSURANCE_CLAIM_MANUAL) && CanUpdateClaim\" >\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__VOID_INSURANCE_CLAIM_MANUAL)\"\n" +
    "                                >Cancel primary insurance claim submitted MANUALLY</a>\n" +
    "                            </li>\n" +
    "				            <li ng-show=\"operationValidForCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__CORRECT_INSURANCE_CLAIM)   && CanUpdateClaim\" >\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__CORRECT_INSURANCE_CLAIM)\"\n" +
    "                                >Send saved claim as correction to primary insurance</a>\n" +
    "                            </li>\n" +
    "<!--\n" +
    "				            <li ng-show=\"operationValidForCurrentState(claim, CLAIM_OPERATIONS.ACCEPT__INSURANCE_CLAIMED)\">\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.ACCEPT__INSURANCE_CLAIMED)\"\n" +
    "                                >Accept claim action by insurance company</a>\n" +
    "                            </li>\n" +
    "-->\n" +
    "				            <li ng-show=\"claim.submitted_claim.patient.other_coverage.type==='dental' && operationValidForCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__CLAIM_OTHER_COVERAGE) && CanUpdateClaim\">\n" +
    "                                <a\n" +
    "\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__CLAIM_OTHER_COVERAGE)\"\n" +
    "                                >Send saved claim to secondary insurance</a>\n" +
    "                            </li>\n" +
    "				            <li ng-show=\"claim.submitted_claim.patient.other_coverage.type!=='none'  && operationValidForCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__CLAIM_OTHER_COVERAGE_MANUAL) && CanUpdateClaim\">\n" +
    "                                <a\n" +
    "\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__CLAIM_OTHER_COVERAGE_MANUAL)\"\n" +
    "                                >Submit claim to secondary insurance MANUALLY</a>\n" +
    "                            </li>\n" +
    "				            <li ng-show=\"claim.submitted_claim.patient.other_coverage.type==='dental'  && operationValidForCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__VOID_OTHER_COVERAGE_CLAIM) && CanUpdateClaim\">\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__VOID_OTHER_COVERAGE_CLAIM)\"\n" +
    "                                >Send request to void claim to secondary insurance </a>\n" +
    "                            </li>\n" +
    "				            <li ng-show=\"claim.submitted_claim.patient.other_coverage.type==='dental'  && operationValidForCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__VOID_OTHER_COVERAGE_CLAIM_MANUAL) && CanUpdateClaim\">\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__VOID_OTHER_COVERAGE_CLAIM_MANUAL)\"\n" +
    "                                >Cancel secondary insurance claim submitted MANUALLY</a>\n" +
    "                            </li>\n" +
    "				            <li ng-show=\"claim.submitted_claim.patient.other_coverage.type==='dental'  && operationValidForCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__CORRECT_OTHER_COVERAGE_CLAIM) && CanUpdateClaim\">\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.SUBMIT__CORRECT_OTHER_COVERAGE_CLAIM)\"\n" +
    "                                >Send saved claim as correction to secondary insurance </a>\n" +
    "                            </li>\n" +
    "				            <li ng-show=\"operationValidForCurrentState(claim, CLAIM_OPERATIONS.RECEIVE__INSURANCE_EOB) && CanUpdateClaim\">\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.RECEIVE__INSURANCE_EOB)\"\n" +
    "                                >Primary insurance EOB received</a>\n" +
    "                            </li>\n" +
    "				            <li ng-show=\"claim.submitted_claim.patient.other_coverage.type!=='none'  && operationValidForCurrentState(claim, CLAIM_OPERATIONS.RECEIVE__OTHER_COVERAGE_EOB) && CanUpdateClaim\">\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.RECEIVE__OTHER_COVERAGE_EOB)\"\n" +
    "                                >Secondary insurance EOB received</a>\n" +
    "                            </li>\n" +
    "				            <li ng-show=\"operationValidForCurrentState(claim, CLAIM_OPERATIONS.MARK__COMPLETED) && CanUpdateClaim\">\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.MARK__COMPLETED)\"\n" +
    "                                >Mark claims processing as complete.</a>\n" +
    "                            </li>\n" +
    "<!--\n" +
    "				            <li ng-show=\"operationValidForCurrentState(claim, CLAIM_OPERATIONS.REMITTANCE__ACCEPT_PAYMENT)\">\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.REMITTANCE__ACCEPT_PAYMENT)\"\n" +
    "                                >Accept remittance payment</a>\n" +
    "                            </li>\n" +
    "				            <li ng-show=\"operationValidForCurrentState(claim, CLAIM_OPERATIONS.REMITTANCE__WRITEOFF)\">\n" +
    "                                <a\n" +
    "                                    ng-click=\"executeOperationOnCurrentState(claim, CLAIM_OPERATIONS.REMITTANCE__WRITEOFF)\"\n" +
    "                                >Make writeoff</a>\n" +
    "                            </li>\n" +
    "-->\n" +
    "			            </ul>\n" +
    "                    </div>\n" +
    "				</div>\n" +
    "			</div>\n" +
    "			<div class=\"portlet-body\">\n" +
    "\n" +
    "          <div class=\"row\">\n" +
    "            <div class=\"col-md-12\">\n" +
    "              <div class=\"portlet light porlet-fit\">\n" +
    "                <div class=\"portlet-title\">\n" +
    "                  <div class=\"caption\">\n" +
    "                    <i class=\"icomoon icon-icomoon-pencil font-red-sunglo\"></i>\n" +
    "                    <span  class=\"caption-subject\">Practice Private Notes</span>\n" +
    "                  </div>\n" +
    "                  <div class=\"actions\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"portlet-body\">\n" +
    "                  <textarea ng-model=\"claim.memo\" id=\"claim-memo\" class=\"form-control \" rows=\"3\" placeholder=\"Clinic private notes...\"></textarea>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "        <div class=\"row\" ng-hide=\"claim.state === 'new'\">\n" +
    "          <div class=\"col-md-12\">\n" +
    "            <div class=\"portlet light porlet-fit\">\n" +
    "              <div class=\"portlet-title\">\n" +
    "                <div class=\"caption\">\n" +
    "                  <i class=\"icomoon icon-icomoon-file-text font-red-sunglo\"></i>\n" +
    "	                <span  class=\"caption-subject\">Events</span>\n" +
    "                </div>\n" +
    "                <div class=\"actions\">\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"portlet-body\">\n" +
    "                <div class=\"pre-scrollable\" style=\"min-height:100px\">\n" +
    "	                <table class=\"table table-condensed\">\n" +
    "		                <tr>\n" +
    "			                <td class=\"col-md-2\">Date</td>\n" +
    "			                <td class=\"col-md-9\">Description</td>\n" +
    "			                <td class=\"col-md-1\">By</td>\n" +
    "		                </tr>\n" +
    "		                </tr>\n" +
    "		                <tr ng-repeat=\"ce in claim.claim_events | orderBy: 'created': true\">\n" +
    "			                <td >{{ce.created | date:\"short\"}}</td>\n" +
    "			                <td >{{ce.description}}</td>\n" +
    "			                <td>{{ce.by_username}}</td>\n" +
    "		                </tr>\n" +
    "	                </table>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ui-view=\"insurance\" id=\"ar\" ng-if=\"claim.state !== 'new'\">\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <fieldset ng-disabled=\" (claim.state !== 'new' && !CanUpdateClaim)\">\n" +
    "		<div class=\"portlet light porlet-fit\">\n" +
    "			<div class=\"portlet-title\">\n" +
    "				<div class=\"caption\">\n" +
    "					<i class=\"icomoon icon-icomoon-cash3 font-red-sunglo\"></i>\n" +
    "					<span class=\"caption-subject font-red-sunglo bold uppercase\">Claim Form Fields {{claim.claim_number}}</span>\n" +
    "				</div>\n" +
    "				<div class=\"actions\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        	<div class=\"portlet-body\">\n" +
    "\n" +
    "            <div class=\"portlet light porlet-fit\" ng-if=\"claim.state==='insurance_claim_completed' || claim.state==='other_coverage_claiming' || claim.state==='other_coverage_claiming_manual'\">\n" +
    "              <div class=\"portlet-title\">\n" +
    "                <div class=\"caption\">\n" +
    "                  <i class=\"icomoon\"></i>\n" +
    "                  <span class=\"caption-subject bold uppercase\"> Information About Primary Claim</span>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"portlet-body\">\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Amount Paid by Primary Insurer</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" ng-currency  class=\"form-control\" ng-model=\"claim.submitted_claim.insurance_paid_amount\" placeholder=\"Amount Paid by Primary\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Date Primary Insurance Paid</label>\n" +
    "\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <custom-datepicker-popup show-placeholder=\"true\" ng-model=\"claim.submitted_claim.insurance_paid_date\" placeholder=\"'Paid date'\"\n" +
    "                                             input-name=\"'primaryInsurancePaidDateInput'\" form-name=\"'myOuterForm'\" format=\"'MM/dd/yyyy'\"\n" +
    "                                             ng-required=\"false\"/>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"portlet light porlet-fit\">\n" +
    "              <div class=\"portlet-title\">\n" +
    "                <div class=\"caption\">\n" +
    "                  <i class=\"icomoon\"></i>\n" +
    "                  <span class=\"caption-subject bold uppercase\"> Primary Insurance Information</span>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"portlet-body\">\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Type</label>\n" +
    "                  <div class=\"col-sm-4\">\n" +
    "                    <select ng-model=\"claim.submitted_claim.patient.insurance.type\" style=\"margin-top: 8px;\">\n" +
    "                      <option value=\"none\">None</option>\n" +
    "                      <option value=\"medical\">Medical</option>\n" +
    "                      <option value=\"dental\">Dental</option>\n" +
    "                    </select>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber Last Name</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"  ng-model=\"claim.submitted_claim.patient.insurance.subscriber.last_name\" placeholder=\"Last Name\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber First Name</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.insurance.subscriber.first_name\" placeholder=\"First Name\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber Street Address</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"\n" +
    "\n" +
    "                           google-places-auto-complete\n" +
    "                           zip=\"claim.submitted_claim.patient.insurance.subscriber.zip_code\"\n" +
    "                           state=\"claim.submitted_claim.patient.insurance.subscriber.state\"\n" +
    "                           city=\"claim.submitted_claim.patient.insurance.subscriber.city\"\n" +
    "\n" +
    "                           ng-model=\"claim.submitted_claim.patient.insurance.subscriber.street1\"\n" +
    "                           placeholder=\"Street Address Line 1\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber Street Address</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.insurance.subscriber.street2\" placeholder=\"Street Address Line 2\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber City</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.insurance.subscriber.city\" placeholder=\"City\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber State</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.insurance.subscriber.state\" placeholder=\"State\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber Zip</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.insurance.subscriber.zip_code\" placeholder=\"Zip\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber Date of Birth</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <custom-datepicker-popup show-placeholder=\"true\" ng-model=\"claim.submitted_claim.patient.insurance.subscriber.birth_date\" placeholder=\"'Birth date'\"\n" +
    "                                             input-name=\"'insuranceSubscriberBirthDateInput'\" form-name=\"'myOuterForm'\" format=\"'MM/dd/yyyy'\"\n" +
    "                                             ng-required=\"false\"/>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber Gender</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <div class=\"btn-group pull-left\">\n" +
    "                      <label class=\"btn btn-default\"\n" +
    "                             ng-model=\"claim.submitted_claim.patient.insurance.subscriber.gender\"\n" +
    "                             uib-btn-radio=\"'m'\">Male</label>\n" +
    "                      <label class=\"btn btn-default\"\n" +
    "                             ng-model=\"claim.submitted_claim.patient.insurance.subscriber.gender\"\n" +
    "                             uib-btn-radio=\"'f'\">Female</label>\n" +
    "                    </div>\n" +
    "\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber ID (SSN or ID#)</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"  ng-model=\"claim.submitted_claim.patient.insurance.subscriber.id\" placeholder=\"Subscriber id\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Plan/Group Number</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"  ng-model=\"claim.submitted_claim.patient.insurance.company.group_number\" placeholder=\"Group number\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                 <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2 control-label\">Patient's Relationship to Subscriber</label>\n" +
    "                    <div class=\"col-sm-10\">\n" +
    "                      <select name=\"patientRelationshipToSubscriberSelect\"\n" +
    "                              ng-model=\"claim.submitted_claim.patient.insurance.subscriber.patient_relationship_to_subscriber\"\n" +
    "                              style=\"margin-top: 8px;\" ng-required=\"false\">\n" +
    "                        <option value=\"self\">Self</option>\n" +
    "                        <option value=\"spouse\">Spouse</option>\n" +
    "                        <option value=\"dependent\">Dependent</option>\n" +
    "                        <option value=\"other\">Other</option>\n" +
    "                      </select>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber Employer Name</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.insurance.subscriber.employer\" placeholder=\"Name of employer\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Company Name</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.insurance.company.name\" placeholder=\"Company name\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Company Address</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"\n" +
    "                           ng-model=\"claim.submitted_claim.patient.insurance.company.street1\"\n" +
    "                           placeholder=\"Company Address Line 1\"\n" +
    "                           google-places-auto-complete\n" +
    "                           zip=\"claim.submitted_claim.patient.insurance.company.zip_code\"\n" +
    "                           state=\"claim.submitted_claim.patient.insurance.company.state\"\n" +
    "                           city=\"claim.submitted_claim.patient.insurance.company.city\"\n" +
    "                    >\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Company Address</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.insurance.company.street2\" placeholder=\"Company Address Line 2\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Company City</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.insurance.company.city\" placeholder=\"City\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Company State</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.insurance.company.state\" placeholder=\"State\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Company Zip</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.insurance.company.zip_code\" placeholder=\"Zip\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "               </div>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "            <div class=\"portlet light porlet-fit\">\n" +
    "              <div class=\"portlet-title\">\n" +
    "                <div class=\"caption\">\n" +
    "                  <i class=\"icomoon\"></i>\n" +
    "                  <span class=\"caption-subject bold uppercase\">Secondary Insurance Information</span>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"portlet-body\" ng-if=\"claim.submitted_claim.patient.other_coverage.type === 'medical' || claim.submitted_claim.patient.other_coverage.type === 'dental'  \">\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Type</label>\n" +
    "                  <div class=\"col-sm-4\">\n" +
    "                    <select ng-model=\"claim.submitted_claim.patient.other_coverage.type\" style=\"margin-top: 8px;\">\n" +
    "                      <option value=\"none\">None</option>\n" +
    "                      <option value=\"medical\">Medical</option>\n" +
    "                      <option value=\"dental\">Dental</option>\n" +
    "                    </select>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber Last Name</label>\n" +
    "                  <div class=\"col-sm-8\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.last_name\" placeholder=\"Last Name\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber First Name</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"  ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.first_name\" placeholder=\"First Name\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "               <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber Street Address</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"\n" +
    "\n" +
    "                           google-places-auto-complete\n" +
    "                           zip=\"claim.submitted_claim.patient.other_coverage.subscriber.zip_code\"\n" +
    "                           state=\"claim.submitted_claim.patient.other_coverage.subscriber.state\"\n" +
    "                           city=\"claim.submitted_claim.patient.other_coverage.subscriber.city\"\n" +
    "\n" +
    "                           ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.street1\"\n" +
    "                           placeholder=\"Street Address Line 1\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber Street Address</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.street2\" placeholder=\"Street Address Line 2\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber City</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.city\" placeholder=\"City\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber State</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.state\" placeholder=\"State\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber Zip</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.zip_code\" placeholder=\"Zip\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber Date of Birth</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <custom-datepicker-popup show-placeholder=\"true\" ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.birth_date\"\n" +
    "                                             input-name=\"'patientBirthDateInput'\" form-name=\"'myOuterForm'\" format=\"'MM/dd/yyyy'\" ng-required=\"false\"/>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber Gender</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "\n" +
    "                    <div class=\"btn-group pull-left\">\n" +
    "                      <label class=\"btn btn-default\"\n" +
    "                             ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.gender\"\n" +
    "                             uib-btn-radio=\"'m'\">Male</label>\n" +
    "                      <label class=\"btn btn-default\"\n" +
    "                             ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.gender\"\n" +
    "                             uib-btn-radio=\"'f'\">Female</label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber ID (SSN or ID#)</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.id\" placeholder=\"Subscriber Id\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Plan/Group Number</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.other_coverage.company.group_number\" placeholder=\"Group number\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Patient’s Relationship to Subscriber</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <select ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.patient_relationship_to_subscriber\" style=\"margin-top: 8px;\">\n" +
    "                      <option value=\"self\">Self</option>\n" +
    "                      <option value=\"spouse\">Spouse</option>\n" +
    "                      <option value=\"dependent\">Dependent</option>\n" +
    "                      <option value=\"other\">Other</option>\n" +
    "                    </select>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Subscriber Employer Name</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.employer\" placeholder=\"Name of employer\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Company Name</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.other_coverage.company.name\" placeholder=\"Name\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Company Address</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"\n" +
    "\n" +
    "                           google-places-auto-complete\n" +
    "                           zip=\"claim.submitted_claim.patient.other_coverage.company.zip_code\"\n" +
    "                           state=\"claim.submitted_claim.patient.other_coverage.company.state\"\n" +
    "                           city=\"claim.submitted_claim.patient.other_coverage.company.city\"\n" +
    "\n" +
    "                           ng-model=\"claim.submitted_claim.patient.other_coverage.company.street1\"\n" +
    "                           placeholder=\"Street Address Line 1\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Company Address</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"  ng-model=\"claim.submitted_claim.patient.other_coverage.company.street2\" placeholder=\"Street Address Line 2\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Company City</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.other_coverage.company.city\" placeholder=\"City\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Company State</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.other_coverage.company.state\" placeholder=\"State\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Company Zip</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.other_coverage.company.zip_code\" placeholder=\"Zip code\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"portlet light porlet-fit\">\n" +
    "			        <div class=\"portlet-title\">\n" +
    "				        <div class=\"caption\">\n" +
    "					        <i class=\"icomoon\"></i>\n" +
    "					        <span class=\"caption-subject bold uppercase\">Patient Information</span>\n" +
    "				        </div>\n" +
    "              </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2 control-label\">Patient First Name</label>\n" +
    "                    <div class=\"col-sm-10\">\n" +
    "                      <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.first_name\" placeholder=\"First Name\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2 control-label\">Patient Middle Initial</label>\n" +
    "                    <div class=\"col-sm-10\">\n" +
    "                      <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.middle_initial\" placeholder=\"Middle Initial\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2 control-label\">Patient Last Name</label>\n" +
    "                    <div class=\"col-sm-10\">\n" +
    "                      <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.last_name\" placeholder=\"Last Name\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2 control-label\">Patient Street Address</label>\n" +
    "                    <div class=\"col-sm-10\">\n" +
    "                      <input type=\"text\" class=\"form-control\"\n" +
    "\n" +
    "                             google-places-auto-complete\n" +
    "                             zip=\"claim.submitted_claim.patient.zip_code\"\n" +
    "                             state=\"claim.submitted_claim.patient.state\"\n" +
    "                             city=\"claim.submitted_claim.patient.city\"\n" +
    "\n" +
    "                             ng-model=\"claim.submitted_claim.patient.street1\"\n" +
    "                             placeholder=\"Street Address Line 1\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2 control-label\">Patient Street Address</label>\n" +
    "                    <div class=\"col-sm-10\">\n" +
    "                      <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.street2\" placeholder=\"Street Address Line 2\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2 control-label\">Patient City</label>\n" +
    "                    <div class=\"col-sm-10\">\n" +
    "                      <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.city\" placeholder=\"City\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2 control-label\">Patient State</label>\n" +
    "                    <div class=\"col-sm-10\">\n" +
    "                      <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.state\" placeholder=\"State\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2 control-label\">Patient Zip</label>\n" +
    "                    <div class=\"col-sm-10\">\n" +
    "                      <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.zip_code\" placeholder=\"Zip\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2 \">Patient Date of Birth</label>\n" +
    "                    <div class=\"col-sm-10\">\n" +
    "                      <custom-datepicker-popup show-placeholder=\"true\" ng-model=\"claim.submitted_claim.patient.birth_date\" placeholder=\"'Birth date'\"\n" +
    "                                               input-name=\"'submittedClaimPatientBirthDateInput'\" form-name=\"'myOuterForm'\" format=\"'MM/dd/yyyy'\"\n" +
    "                                               ng-required=\"false\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2 control-label\">Patient Gender</label>\n" +
    "                    <div class=\"col-sm-10\">\n" +
    "                      <div class=\"btn-group pull-left\">\n" +
    "                        <label class=\"btn btn-default\"\n" +
    "                               ng-model=\"claim.submitted_claim.patient.gender\"\n" +
    "                               uib-btn-radio=\"'m'\">Male</label>\n" +
    "                        <label class=\"btn btn-default\"\n" +
    "                               ng-model=\"claim.submitted_claim.patient.gender\"\n" +
    "                               uib-btn-radio=\"'f'\">Female</label>\n" +
    "                      </div>\n" +
    "\n" +
    "\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2 control-label\">Patient ID/Account # (Assigned by Dentist)</label>\n" +
    "                    <div class=\"col-sm-10\">\n" +
    "                      <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.id\" placeholder=\"Patient id\" ng-disabled=\"true\"></input>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "		        <div class=\"portlet light porlet-fit\">\n" +
    "			        <div class=\"portlet-title\">\n" +
    "				        <div class=\"caption\">\n" +
    "					        <i class=\"icomoon\"></i>\n" +
    "					        <span class=\"caption-subject bold uppercase\">Record of Services Provided</span>\n" +
    "				        </div>\n" +
    "				        <div class=\"actions\">\n" +
    "                            <!--\n" +
    "			                <button type=\"button\" class=\"btn btn-circle default\">\n" +
    "				                <i class=\"icomoon\"></i>Add\n" +
    "			                </button>\n" +
    "                            -->\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                	<div class=\"portlet-body\">\n" +
    "				        <div style=\"overflow-x: auto;\">\n" +
    "					        <table class=\"table table-striped table-condensed\">\n" +
    "						        <tr>\n" +
    "							        <td class=\"col-md-1\">Procedure Date</td>\n" +
    "							        <td class=\"col-md-1\">Area of Oral Cavity</td>\n" +
    "							        <td class=\"col-md-1\">Tooth System</td>\n" +
    "							        <td class=\"col-md-1\">Tooth Number(s) or Letter(s)</td>\n" +
    "							        <td class=\"col-md-1\">Tooth Surface</td>\n" +
    "							        <td class=\"col-md-1\">Procedure Code</td>\n" +
    "                      <td class=\"col-md-1\">Diagnostic Pointer</td>\n" +
    "							        <td class=\"col-md-1\">Description</td>\n" +
    "							        <td class=\"col-md-1\">Qty. 1 X 31.Fee</td>\n" +
    "							        <td class=\"col-md-1\">Associated A/R amount (covered estimate)</td>\n" +
    "                                    <td class=\"col-md-1\"></td>\n" +
    "                                    <td class=\"col-md-1\"></td>\n" +
    "						        </tr>\n" +
    "						        <tr ng-repeat=\"treatment in claim.submitted_claim.services.treatments\">\n" +
    "\n" +
    "							        <td style=\"min-width: 155px\">\n" +
    "                        <custom-datepicker-popup show-placeholder=\"true\" ng-model=\"treatment.treatment_date\" placeholder=\"'Service date'\"\n" +
    "                                                 input-name=\"'treatmentDateInput' + $index \" form-name=\"'myOuterForm'\" format=\"'MM/dd/yyyy'\" ng-required=\"false\"/>\n" +
    "                      </td>\n" +
    "\n" +
    "							        <td><input type=\"text\" ng-model=\"treatment.area_of_oral_cavity\" placeholder=\"Area of oral cavity...\"></input></td>\n" +
    "							        <td><input type=\"text\" ng-model=\"treatment.tooth_system\" placeholder=\"Tooth system\"></input></td>\n" +
    "							        <td><input type=\"text\" ng-model=\"treatment.tooth_numbers\" placeholder=\"Tooth number(s)s\"></input></td>\n" +
    "							        <td><input type=\"text\" ng-model=\"treatment.tooth_surface\" placeholder=\"Tooth surface\"></input></td>\n" +
    "							        <td><input type=\"text\" ng-disabled=\"true\" ng-model=\"treatment.procedure_code\" placeholder=\"Procedure code\"></input></td>\n" +
    "							        <td><input type=\"text\" ng-model=\"treatment.diagnostic_pointer\" placeholder=\"Diagnostic pointer\"></input></td>\n" +
    "							        <td><input type=\"text\" ng-disabled=\"true\" ng-model=\"treatment.procedure_description\" placeholder=\"Description\"></input></td>\n" +
    "							        <td><span><input type=\"text\" ng-currency ng-model=\"treatment.fee\" placeholder=\"Fee\"></input></span></td>\n" +
    "							        <td><input type=\"text\" ng-disabled='true' title=\"A/R was created during invoicing. Make adjustments to A/R amounts in the Accounting left menu option\" ng-model=\"treatment.arAmount\" placeholder=\"Fee\"></input></td>\n" +
    "							        <td>\n" +
    "								        <div class=\"btn-group\" uib-dropdown ng-show=\"service.encounters.length > 0\">\n" +
    "									        <button type=\"button\" class=\"btn btn-default btn-sm dropdown-toggle\" uib-dropdown-toggle>\n" +
    "										        Encounters\n" +
    "										        <span class=\"caret\"></span>\n" +
    "									        </button>\n" +
    "									        <ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "										        <li ng-repeat=\"e in treatment.encounters | orderBy: 'encounter_date'\"><a ui-sref=\"chart-detail({encounterid: e.id})\">{{(e.encounter_date | date:'MM-dd-yyyy') + \" - \" + e.title}}</a></li>\n" +
    "									        </ul>\n" +
    "								        </div>\n" +
    "							        </td>\n" +
    "							        <td>\n" +
    "								        <div class=\"btn-group\" ng-show=\"claim.state==='new'\">\n" +
    "									        <button type=\"button\" class=\"btn btn-sm btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"removeFromTreatments(treatment)\">\n" +
    "										        <span class=\"icomoon icon-icomoon-bin\" aria-hidden=\"true\"></span>\n" +
    "									        </button>\n" +
    "								        </div>\n" +
    "							        </td>\n" +
    "						        </tr>\n" +
    "                  </table>\n" +
    "                </div>\n" +
    "\n" +
    "                <br></br>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Other Fees</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" ng-currency  class=\"form-control\" ng-model=\"claim.submitted_claim.services.other_fees\" placeholder=\"Other fee(s)\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "				        <div class=\"row\" >\n" +
    "					        <div class=\"form-group col-md-6\">\n" +
    "                    <div>\n" +
    "                      <label class=\"\">Total Fees:   <span style=\"font-weight: 600\"> {{feesTotal | currency : practice.currency + ' ' : 2}}  </span>     </label>\n" +
    "                    </div>\n" +
    "                    <div>\n" +
    "                      <label ng-show=\"claim.state === 'new'\" class=\"\">Associated A/R amount (covered estimate) total: {{arAmountTotal | currency : practice.currency + ' ' : 2}}</label>\n" +
    "                    </div>\n" +
    "					        </div>\n" +
    "				        </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "		        <div class=\"portlet light porlet-fit\">\n" +
    "			        <div class=\"portlet-title\">\n" +
    "				        <div class=\"caption\">\n" +
    "					        <i class=\"icomoon\"></i>\n" +
    "					        <span class=\"caption-subject bold uppercase\"></span>\n" +
    "				        </div>\n" +
    "\n" +
    "              </div>\n" +
    "              <div class=\"portlet-body\">\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Missing Teeth Information</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.patient.missing_teeth\" placeholder=\"Missing teeth\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Diagnosis Code List Qualifier</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <select ng-model=\"claim.submitted_claim.services.diagnosis_code_qualifier\" style=\"margin-top: 8px;\">\n" +
    "                      <option value=\"\"></option>\n" +
    "                      <option value=\"B\">ICD-9</option>\n" +
    "                      <option value=\"AB\">ICD-10</option>\n" +
    "                    </select>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Diagnosis Code(s) (Comma Delimited List, Primary Diagnosis First)</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"  ng-model=\"claim.submitted_claim.services.diagnosis_codes\" >\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Remarks</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <textarea ng-model=\"claim.submitted_claim.services.remarks\" class=\"form-control \" rows=\"3\" placeholder=\"Remarks\"></textarea>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "		        <div class=\"portlet light porlet-fit\">\n" +
    "			        <div class=\"portlet-title\">\n" +
    "				        <div class=\"caption\">\n" +
    "					        <i class=\"icomoon\"></i>\n" +
    "					        <span class=\"caption-subject bold uppercase\">Authorizations</span>\n" +
    "				        </div>\n" +
    "              </div>\n" +
    "              <div class=\"portlet-body\">\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <div class=\"col-sm-6\">\n" +
    "                    <label >Patient / guardian signature and date on file?</label><br>\n" +
    "                    <small>(Defaults to yes if both the 'patient / guardian signature' and associated date located in patient's profile tab are filled in)</small>\n" +
    "                  </div>\n" +
    "                  <div class=\"col-sm-4\">\n" +
    "                    <yes-no-button\n" +
    "                      ng-model=\"claim.submitted_claim.services.patient.signature_on_file\"\n" +
    "                    >\n" +
    "                    </yes-no-button>\n" +
    "                  </div>\n" +
    "                  <div class=\"col-sm-2\">\n" +
    "                    <custom-datepicker-popup\n" +
    "                      show-placeholder=\"true\"\n" +
    "                      ng-model=\"claim.submitted_claim.services.patient.signature_on_file_date\"\n" +
    "                      placeholder=\"'Signature Date'\"\n" +
    "                      input-name=\"'submittedClaimPatientSignatureInput'\"\n" +
    "                      format=\"'MM/dd/yyyy'\"\n" +
    "                      ng-required=\"false\"\n" +
    "                    />\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <div class=\"col-sm-6\">\n" +
    "                    <label >Primary Insurance Subscriber signature and date on file?</label><br>\n" +
    "                    <small>(Defaults to yes if assignment of benefits is set to yes and both the 'Primary Insurance Subscriber Signature' and associated date in the patient's profile tab are filled in)</small>\n" +
    "                  </div>\n" +
    "                  <div class=\"col-sm-4\">\n" +
    "                    <yes-no-button\n" +
    "                      ng-model=\"claim.submitted_claim.patient.insurance.subscriber.signature_on_file\"\n" +
    "                    >\n" +
    "                    </yes-no-button>\n" +
    "                  </div>\n" +
    "                  <div class=\"col-sm-2\">\n" +
    "                    <custom-datepicker-popup\n" +
    "                      show-placeholder=\"true\"\n" +
    "                      ng-model=\"claim.submitted_claim.patient.insurance.subscriber.signature_on_file_date\"\n" +
    "                      placeholder=\"'Signature Date'\"\n" +
    "                      input-name=\"'submittedClaimSubscriberSignatureInput'\"\n" +
    "                      format=\"'MM/dd/yyyy'\"\n" +
    "                      ng-required=\"false\"\n" +
    "                    />\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <div class=\"col-sm-6\">\n" +
    "                    <label >Secondary Insurance Subscriber signature and date on file?</label><br>\n" +
    "                    <small>(Defaults to yes if assignment of benefits is set to yes and both the 'Secondary Insurance Subscriber Signature' and associated date in the patient's profile tab are filled in)</small>\n" +
    "                  </div>\n" +
    "                  <div class=\"col-sm-4\">\n" +
    "                    <yes-no-button\n" +
    "                      ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.signature_on_file\"\n" +
    "                    >\n" +
    "                    </yes-no-button>\n" +
    "                  </div>\n" +
    "                  <div class=\"col-sm-2\">\n" +
    "                    <custom-datepicker-popup\n" +
    "                      show-placeholder=\"true\"\n" +
    "                      ng-model=\"claim.submitted_claim.patient.other_coverage.subscriber.signature_on_file_date\"\n" +
    "                      placeholder=\"'Signature Date'\"\n" +
    "                      input-name=\"'submittedClaimSecondarySubscriberSignatureInput'\"\n" +
    "                      format=\"'MM/dd/yyyy'\"\n" +
    "                      ng-required=\"false\"\n" +
    "                    />\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "		        <div class=\"portlet light porlet-fit\">\n" +
    "			        <div class=\"portlet-title\">\n" +
    "				        <div class=\"caption\">\n" +
    "					        <i class=\"icomoon\"></i>\n" +
    "					        <span class=\"caption-subject bold uppercase\">Ancillary Claim / Treatment Information</span>\n" +
    "				        </div>\n" +
    "              </div>\n" +
    "              <div class=\"portlet-body\">\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Place of Treatment</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <select ng-model=\"claim.submitted_claim.place_of_treatment\" style=\"margin-top: 8px;\">\n" +
    "                      <option value=\"11\">office</option>\n" +
    "                      <option value=\"12\">home</option>\n" +
    "                      <option value=\"21\">in patient hospital</option>\n" +
    "                      <option value=\"22\">out patient hospital</option>\n" +
    "                      <option value=\"31\">skilled nursing facility</option>\n" +
    "                      <option value=\"32\">nursing facility</option>\n" +
    "                    </select>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Has Enclosures</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <yes-no-button\n" +
    "                      ng-model=\"claim.submitted_claim.has_enclosures\">\n" +
    "                    </yes-no-button>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">NEA Number</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" min=\"0\" ng-model=\"claim.submitted_claim.nea_number\" placeholder=\"NEA Number\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Is Treatment for Orthodontics?</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <yes-no-button\n" +
    "                      ng-model=\"claim.submitted_claim.is_orthodontic\">\n" +
    "                    </yes-no-button>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div ng-if=\"claim.submitted_claim.is_orthodontic\">\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2 control-label\">Date Appliance Placed</label>\n" +
    "                    <div class=\"col-sm-10\">\n" +
    "                      <custom-datepicker-popup show-placeholder=\"true\" ng-model=\"claim.submitted_claim.appliance_placed_date\"\n" +
    "                                               input-name=\"'appliancePlacedDateInput'\" form-name=\"'myOuterForm'\" format=\"'MM/dd/yyyy'\" ng-required=\"false\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "\n" +
    "                  <div class=\"form-group row\" ng-class=\"{ 'has-error' : myOuterForm.monthsRemainingInput.$invalid}\">\n" +
    "                    <label class=\"col-sm-2 control-label\">Months of Treatment</label>\n" +
    "                    <div class=\"col-sm-10\">\n" +
    "                      <input type=\"number\" min=\"0\" class=\"form-control\" name=\"monthsRemainingInput\" ng-model=\"claim.submitted_claim.months_remaining\" placeholder=\"Month remaining\">\n" +
    "                      <span class=\"help-block\" ng-show=\"myOuterForm.monthsRemainingInput.$invalid\">Needs to be a positive number</span>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Replacement of Prosthesis</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <yes-no-button\n" +
    "                      ng-model=\"claim.submitted_claim.prothesis_replacement\">\n" +
    "                    </yes-no-button>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\" ng-if=\"claim.submitted_claim.prothesis_replacement\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Date of Prior Placement</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <custom-datepicker-popup show-placeholder=\"true\" ng-model=\"claim.submitted_claim.prior_applicance_placed_date\" placeholder=\"'Prior appliance placed date'\"\n" +
    "                                             input-name=\"'dateOfPriorPlacementDateInput'\" form-name=\"'myOuterForm'\" format=\"'MM/dd/yyyy'\" ng-required=\"false\"/>\n" +
    "\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Treatment Resulting from Occupational Illness/Injury</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <select ng-model=\"claim.submitted_claim.treatment_resulting_from\" style=\"margin-top: 8px;\">\n" +
    "                      <option value=\"Occ Injury\">Occ Injury</option>\n" +
    "                      <option value=\"Auto Accident\">Auto Accident</option>\n" +
    "                      <option value=\"Other\">Other</option>\n" +
    "                    </select>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Date of Accident</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <custom-datepicker-popup show-placeholder=\"true\" ng-model=\"claim.submitted_claim.accident_date\" placeholder=\"'Date of accident'\"\n" +
    "                                             input-name=\"'dateOfAccidentDateInput'\" form-name=\"'myOuterForm'\" format=\"'MM/dd/yyyy'\" ng-required=\"false\"/>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Auto Accident State</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <state-input ng-model=\"claim.submitted_claim.accident_state\"\n" +
    "                                 name=\"submittedClaimAccidentState\"\n" +
    "                                 ng-required=\"false\" />\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "		        <div class=\"portlet light porlet-fit\">\n" +
    "			        <div class=\"portlet-title\">\n" +
    "				        <div class=\"caption\">\n" +
    "					        <i class=\"icomoon\"></i>\n" +
    "					        <span class=\"caption-subject bold uppercase\">Billing Dentist or Dental Entity (Leave blank if dentist or dental entity is notsubmitting claim on behalf of the patient or insured/subscriber.)</span>\n" +
    "				        </div>\n" +
    "              </div>\n" +
    "              <div class=\"portlet-body\">\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Name</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.billing.provider.name\" placeholder=\"Name\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Street Address</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"\n" +
    "\n" +
    "\n" +
    "                           google-places-auto-complete\n" +
    "                           zip=\"claim.submitted_claim.billing.location.zip_code\"\n" +
    "                           state=\"claim.submitted_claim.billing.location.state\"\n" +
    "                           city=\"claim.submitted_claim.billing.location.city\"\n" +
    "\n" +
    "\n" +
    "                           ng-model=\"claim.submitted_claim.billing.location.street1\"\n" +
    "                           placeholder=\"Billing Street Address Line 1\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Street Address</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.billing.location.street2\" placeholder=\"Billing Street Address Line 2\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">City</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.billing.location.city\" placeholder=\"Billing City\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">State</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.billing.location.state\" placeholder=\"Billing State\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Zip</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.billing.location.zip_code\" placeholder=\"Billing Zip\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Billing Provider NPI</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"  ng-model=\"claim.submitted_claim.billing.provider.npi\" placeholder=\"Billing Provider NPI\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Billing Provider License Number</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.billing.provider.license_number\" placeholder=\"Billing provider license number\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">SSN or TIN</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.billing.provider.tax_id_or_ssn\" placeholder=\"Billing provider ssn or TIN\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Billing Provider Phone Number</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.billing.provider.phone\" placeholder=\"Billing provider phone number\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Additional Provider ID</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.billing.provider.additional_id\" placeholder=\"Billing provider additional ID\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "		        <div class=\"portlet light porlet-fit\">\n" +
    "			        <div class=\"portlet-title\">\n" +
    "				        <div class=\"caption\">\n" +
    "					        <i class=\"icomoon\"></i>\n" +
    "					        <span class=\"caption-subject bold uppercase\">Treating Dentist and Treatment Location Information</span>\n" +
    "				        </div>\n" +
    "              </div>\n" +
    "              <div class=\"portlet-body\">\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <div class=\"col-sm-6\">\n" +
    "                    <label >Treating dentist signature on file?</label><br>\n" +
    "                    <small>(Defaults to yes if both the user signature and associated date located in Settings/Practice/Users for the treating provider are filled in)</small>\n" +
    "                  </div>\n" +
    "                  <div class=\"col-sm-4\">\n" +
    "                    <yes-no-button\n" +
    "                      ng-model=\"claim.submitted_claim.services.provider.signature_on_file\"\n" +
    "                    >\n" +
    "                    </yes-no-button>\n" +
    "                  </div>\n" +
    "                  <div class=\"col-sm-2\">\n" +
    "                    <custom-datepicker-popup\n" +
    "                      show-placeholder=\"true\"\n" +
    "                      ng-model=\"claim.submitted_claim.services.provider.signature_on_file_date\"\n" +
    "                      placeholder=\"'Signature Date'\"\n" +
    "                      input-name=\"'submittedClaimTreatingProviderSignatureInput'\"\n" +
    "                      format=\"'MM/dd/yyyy'\"\n" +
    "                      ng-required=\"false\"\n" +
    "                    />\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                \n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Treating Provider Name</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <p class=\"form-control\">{{claim.submitted_claim.services.provider.first_name}} {{claim.submitted_claim.services.provider.last_name}}</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Treating Provider NPI</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.services.provider.npi\" placeholder=\"Treating provider NPI\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Treating Provider License Number</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.services.provider.license_number\" placeholder=\"Treating provider license number\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <label>Address, City, State, Zip Code where Treatment Occured</label>  <br></br>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Street Address</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"\n" +
    "\n" +
    "                           google-places-auto-complete\n" +
    "                           zip=\"claim.submitted_claim.services.location.zip\"\n" +
    "                           state=\"claim.submitted_claim.services.location.state\"\n" +
    "                           city=\"claim.submitted_claim.services.location.city\"\n" +
    "\n" +
    "                           ng-model=\"claim.submitted_claim.services.location.street1\"\n" +
    "                           placeholder=\"Treatment Street Address Line 1\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Street Address</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.services.location.street2\" placeholder=\"Treatment Street Address Line 2\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">City</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"  ng-model=\"claim.submitted_claim.services.location.city\" placeholder=\"Treatment City\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">State</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"  ng-model=\"claim.submitted_claim.services.location.state\" placeholder=\"Treatment State\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Zip</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"  ng-model=\"claim.submitted_claim.services.location.zip_code\" placeholder=\"Treatment Zip\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Treating Provider's Specialty Code</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "\n" +
    "                    <select ng-model=\"claim.submitted_claim.services.provider.specialty\" style=\"margin-top: 8px;\">\n" +
    "                      <option value=\"122300000X\">Dentist</option>\n" +
    "                      <option value=\"1223G0001X\">General Practice</option>\n" +
    "                      <option value=\"1223D0001X\">Dental Public Health</option>\n" +
    "                      <option value=\"1223E0200X\">Endodontics</option>\n" +
    "                      <option value=\"1223X0400X\">Orthodontics</option>\n" +
    "                      <option value=\"1223P0221X\">Pediatric Dentistry</option>\n" +
    "                      <option value=\"1223P0300X\">Periodontics</option>\n" +
    "                      <option value=\"1223P0700X\">Prosthodontics</option>\n" +
    "                      <option value=\"1223P0106X\">Oral & Maxillofacial Pathology</option>\n" +
    "                      <option value=\"1223D0008X\">Oral & Maxillofacila Radiology</option>\n" +
    "                      <option value=\"1223S0112X\">Oral & Maxillofacila Surgery</option>\n" +
    "                    </select>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Treating Provider's Phone Number</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"claim.submitted_claim.services.provider.phone\" placeholder=\"Treating provider's phone number\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <label class=\"col-sm-2 control-label\">Additional Treating Provider ID</label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\"  ng-model=\"claim.submitted_claim.services.provider.additional_id\" placeholder=\"Treating provider additional id\">\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "\n" +
    "              </div>\n" +
    "            </div>\n" +
    "			    </div>\n" +
    "        </div>\n" +
    "    </fieldset>\n" +
    "  </div>\n" +
    "</form>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#billing-claims-detail-confirm-complete\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title text-center\">Are you sure?</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    Marking claims processing as complete is final. No other payment or other actions can be performed on this claim\n" +
    "    once marked complete.\n" +
    "    <p class=\"m-b-0\" style=\"margin-top:10px\">\n" +
    "      Are you sure you'd like to mark claims processing as complete?\n" +
    "    </p>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-default btn-wide pull-left\" ng-click=\"$dismiss()\">No</button>\n" +
    "    <button class=\"btn btn-primary btn-wide\" ng-click=\"$close()\">Yes</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#billing-claims-detail-confirm-submit-manually\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title text-center\">Are you sure?</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    Sending a claim manually means you intend to file the claim through means other than the electronic clearinghouse.\n" +
    "    You will not be able to file the claim electronically through the clearinghouse after you select <b>Yes</b>.\n" +
    "    <p class=\"m-b-0\" style=\"margin-top:10px\">\n" +
    "      Are you sure you'd like to mark the claim as manual send?\n" +
    "    </p>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-default btn-wide pull-left\" ng-click=\"$dismiss()\">No</button>\n" +
    "    <button class=\"btn btn-primary btn-wide\" ng-click=\"$close()\">Yes</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/common/billing-invoices-detail.tpl.html","<form name=\"myOuterForm\" novalidate class=\"css-form\" role=\"form\">\n" +
    "	<div id=\"dwtcontrolContainer\">\n" +
    "		<div class=\"portlet light porlet-fit\">\n" +
    "			<div class=\"portlet-title\">\n" +
    "				<div class=\"caption\">\n" +
    "					<i ng-if=\"isNewInvoice\" class=\"icomoon icon-icomoon-file-plus2 font-red-sunglo\"></i>\n" +
    "					<i ng-if=\"!isNewInvoice\" class=\"icomoon icon-icomoon-file-eye2 font-red-sunglo\"></i>\n" +
    "					<span class=\"caption-subject font-red-sunglo bold uppercase\">\n" +
    "						<span ng-if=\"isNewInvoice\">Create Invoice</span>\n" +
    "						<span ng-if=\"!isNewInvoice\">View Invoice</span>\n" +
    "					</span>\n" +
    "				</div>\n" +
    "				<div class=\"actions\">\n" +
    "			    <div class=\"btn-group\">\n" +
    "						<a class=\"btn btn-default p-x\" ui-sref=\"^\" ui-sref-opts=\"{reload: true}\">\n" +
    "							<i class=\"icomoon icon-icomoon-arrow-left\"></i> Back\n" +
    "						</a>\n" +
    "				    <button ng-show=\"isNewInvoice\" type=\"button\" ng-disabled=\"myOuterForm.$invalid || !CanCreateInvoice\" class=\"btn p-x btn-primary\" ng-click=\"createInvoiceClick()\" style=\"margin-left: 6px;\">\n" +
    "              Create\n" +
    "				    </button>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "			<div class=\"portlet-body\">\n" +
    "				<!--\n" +
    "				    <button type=\"button\" class=\"btn p-x btn-primary pull-right\"  ng-show=\"!isNewInvoice\"  ng-click=\"printInvoice()\" style=\"margin-left: 6px;\">\n" +
    "					    <i class=\"icomoon icon-icomoon-printer2\"></i> Print Invoice\n" +
    "				    </button>\n" +
    "				-->\n" +
    "				<div class=\"row\" ng-if=\"!isNewInvoice\">\n" +
    "					<div class=\"form-group col-md-4\" >\n" +
    "						<label for=\"invoice-number\">Invoice #:</label>\n" +
    "						<strong><p class=\"form-control-static\" id=\"invoice-number\">{{invoice.invoice_number}}</p></strong>\n" +
    "					</div>\n" +
    "        </div>\n" +
    "				<div class=\"row\">\n" +
    "					<div class=\"form-group col-md-6\">\n" +
    "						<label for=\"patient-name\">Patient:</label>\n" +
    "						<strong><p class=\"form-control-static\" id=\"patient-name\">{{patient.first_name}} {{patient.middle_name}} {{patient.last_name}}</p></strong>\n" +
    "					</div>\n" +
    "        </div>\n" +
    "				<div class=\"row\">\n" +
    "					<div class=\"form-group\" ng-if=\"isNewInvoice\" >\n" +
    "						<label for=\"invoice-date\" control-label class=\"col-md-2\">Invoice date:</label>\n" +
    "            <custom-datepicker-popup show-placeholder=\"false\" id=\"invoice-date\" ng-model=\"invoice.invoice_date\" input-name=\"'invoiceDateInputDate'\" form-name=\"'myOuterForm'\" format=\"'MM/dd/yyyy'\" ng-required=\"true\" class=\"col-md-2\"/>\n" +
    "          </div>\n" +
    "					<div class=\"form-group  col-md-6\" ng-if=\"!isNewInvoice\" >\n" +
    "						<label for=\"invoice-date\" control-label >Invoice date:</label>\n" +
    "						<strong><p class=\"form-control-static\">{{invoice.invoice_date | date:\"MMMM dd, yyyy\"}}</p></strong>\n" +
    "					</div>\n" +
    "				</div>\n" +
    "				<div class=\"row\">\n" +
    "					<div class=\"form-group  col-md-12\">\n" +
    "						<label for=\"invoice-memo\">Notes:</label>\n" +
    "						<textarea ng-show=\"isNewInvoice\" ng-model=\"invoice.memo\" id=\"invoice-memo\" class=\"form-control \" rows=\"1\" placeholder=\"Memo...\"></textarea>\n" +
    "						<p class=\"form-control-static\" ng-hide=\"isNewInvoice\" id=\"invoice-memo\">{{invoice.memo}}</p>\n" +
    "					</div>\n" +
    "				</div>\n" +
    "\n" +
    "			</div>\n" +
    "			<div class=\"portlet-title\">\n" +
    "				<div class=\"caption\"></div>\n" +
    "				<!-- <div class=\"actions\">\n" +
    "					<div class=\"btn-group\" ng-show=\"isNewInvoice\">\n" +
    "						<div class=\"btn-group\" uib-dropdown>\n" +
    "							<button ng-disabled=\"!CanCreateInvoice\" type=\"button\" class=\"btn p-x btn-primary\" uib-dropdown-toggle>\n" +
    "								Add\n" +
    "								<span class=\"caret\"></span>\n" +
    "							</button>\n" +
    "							<ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "								<li><a href=\"\" ng-click=\"openAddToInvoiceModal('Add deposit', '', patient.ar_gl_account, practice.cash_gl_account, practice.currency, arJournalEntryDetails, false)\">Deposit</a></li>\n" +
    "								<li><a href=\"\" ng-click=\"openAddToInvoiceModal('Add tax', '', patient.ar_gl_account, practice.taxes_gl_account, practice.currency, arJournalEntryDetails, true)\">Tax</a></li>\n" +
    "								<li><a href=\"\" ng-click=\"openAddToInvoiceModal('Add discount', '', patient.ar_gl_account, practice.discount_gl_account, practice.currency, arJournalEntryDetails, false)\">Discount</a></li>\n" +
    "							</ul>\n" +
    "						</div>\n" +
    "					</div>\n" +
    "				</div> -->\n" +
    "			</div>\n" +
    "			<div class=\"portlet-body\">\n" +
    "\n" +
    "				<!-- INVOICE DETAIL: AR JOURNAL ENTRY DETAILS associated with Invoice that have a positive amount -->\n" +
    "				<div style=\"overflow-x: auto;\">\n" +
    "					<table class=\"table table-striped table-bordered table-advance\">\n" +
    "						<thead>\n" +
    "              <tr>\n" +
    "                <th class=\"col-md-8\">Description</th>\n" +
    "                <th class=\"col-md-2\">Amount</th>\n" +
    "                <th class=\"col-md-1\">\n" +
    "                </th>\n" +
    "                <!--\n" +
    "                <td class=\"col-md-1\">\n" +
    "                </td>\n" +
    "                -->\n" +
    "                <th class=\"col-md-1\">\n" +
    "                </th>\n" +
    "              </tr>\n" +
    "            </thead>\n" +
    "						<tr ng-hide=\"isNewInvoice\" ng-repeat=\"ajet in arJournalEntryDetails | filter: {type: 'invoicelineitem' }\">\n" +
    "							<td>{{ajet.journal_entry_detail.description}}</td>\n" +
    "							<td align=\"right\">{{ajet.journal_entry_detail.amount}} </td>\n" +
    "							<td>\n" +
    "								<div class=\"btn-group\" uib-dropdown ng-show=\"ajet.treatmentInfo.encounters.length > 0\">\n" +
    "									<button ng-disabled=\"!CanRetrieveEncounter\" type=\"button\" class=\"btn btn-default btn-sm dropdown-toggle\" uib-dropdown-toggle>\n" +
    "										Encounters\n" +
    "										<span class=\"caret\"></span>\n" +
    "									</button>\n" +
    "									<ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "										<li ng-repeat=\"e in ajet.treatmentInfo.encounters | orderBy: 'encounter_date'\"><a ui-sref=\"chart-detail({encounterid: e.id})\">{{(e.encounter_date | date:'MM-dd-yyyy') + \" - \" + e.title}}</a></li>\n" +
    "									</ul>\n" +
    "								</div>\n" +
    "							</td>\n" +
    "							<td>\n" +
    "							</td>\n" +
    "							<td>\n" +
    "							</td>\n" +
    "						</tr>\n" +
    "						<tr ng-form=\"myForm\" ng-show=\"isNewInvoice\" ng-repeat=\"ajet in arJournalEntryDetails\">\n" +
    "							<td>\n" +
    "								<div ng-class=\"{ 'has-success': myForm.description.$valid, 'has-error': myForm.description.$invalid}\" required>\n" +
    "									<input ng-disabled=\"!CanCreateInvoice\" name=\"description\" type=\"text\" class=\"form-control\" ng-model=\"ajet.description\" required></input>\n" +
    "								</div>\n" +
    "							</td>\n" +
    "							<td>{{ajet.journal_entry_detail.amount}} {{practice.currency}}</td>\n" +
    "							<td>\n" +
    "								<div class=\"btn-group\">\n" +
    "									<button  ng-disabled=\"!CanCreateInvoice\" ng-hide=\"(ajet.type.indexOf('revenueinvoicelineitem') !== -1) || ajet.type === 'claimerinvoicelineitem'\" type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"removeFromArJournalEntryDetails(ajet)\">\n" +
    "                    <i class=\"icomoon icon-icomoon-bin\" aria-hidden=\"true\"></i>\n" +
    "									</button>\n" +
    "								</div>\n" +
    "							</td>\n" +
    "              <!--\n" +
    "							<td>\n" +
    "								<div ng-show=\"ajet.treatment && ajet.type ==='invoicelineitem'\" class=\"btn-group\">\n" +
    "									<button ng-disabled=\"!CanCreateInvoice\" type=\"button\" class=\"btn btn-default btn-sm\" ng-click=\"openAddClaimToInvoiceModal('Add claim', 'Insurance claim for treatment ' + ajet.journal_entry_detail.description, patient.ar_gl_account, practice.insurance_ar_gl_account, practice.currency, ajet.treatment, arJournalEntryDetails)\">\n" +
    "										Add claim\n" +
    "									</button>\n" +
    "								</div>\n" +
    "							</td>\n" +
    "              -->\n" +
    "							<td>\n" +
    "								<div class=\"btn-group\" uib-dropdown ng-show=\"ajet.treatmentInfo.encounters.length > 0\">\n" +
    "									<button ng-disabled=\"!CanRetrieveEncounter\" type=\"button\" class=\"btn btn-default btn-sm dropdown-toggle\" uib-dropdown-toggle>\n" +
    "										Encounters\n" +
    "										<span class=\"caret\"></span>\n" +
    "									</button>\n" +
    "									<ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "										<li ng-repeat=\"e in ajet.treatmentInfo.encounters | orderBy: 'encounter_date'\"><a ui-sref=\"chart-detail({encounterid: e.id})\">{{(e.encounter_date | date:'MM-dd-yyyy') + \" - \" + e.title}}</a></li>\n" +
    "									</ul>\n" +
    "								</div>\n" +
    "							</td>\n" +
    "						</tr>\n" +
    "					</table>\n" +
    "				</div>\n" +
    "				<label class=\"pull-right\">Total: {{invoiceTotal}} {{practice.currency}}</label>\n" +
    "				<div class=\"clearfix\"></div>\n" +
    "			</div>\n" +
    "	        <div class=\"portlet-title\">\n" +
    "                <div class=\"actions\">\n" +
    "		            <div class=\"btn-group\">\n" +
    "									<a class=\"btn btn-default p-x\" ui-sref=\"^\" ui-sref-opts=\"{reload: true}\">\n" +
    "										<i class=\"icomoon icon-icomoon-arrow-left\"></i> Back\n" +
    "									</a>\n" +
    "			            <button ng-show=\"isNewInvoice\" type=\"button\" ng-disabled=\"myOuterForm.$invalid || !CanCreateInvoice\" class=\"btn p-x btn-primary\" ng-click=\"createInvoiceClick()\" style=\"margin-left: 6px;\">\n" +
    "				            Create\n" +
    "			            </button>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"panel-body\" id=\"ar\" ng-if=\"!isNewInvoice\">\n" +
    "          <div ui-view=\"billing\"></div>\n" +
    "        </div>\n" +
    "	</div>\n" +
    "</form>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"addJournalEntryToInvoice.html\">\n" +
    "	<div class=\"modal-header\">\n" +
    "		<h3 class=\"modal-title\">Add Journal Entry</h3>\n" +
    "	</div>\n" +
    "	<div class=\"modal-body\">\n" +
    "		<form name=\"myForm\">\n" +
    "			<div class=\"form-group\" ng-class=\"{ 'has-success': myForm.description.$valid, 'has-error': myForm.description.$invalid}\">\n" +
    "				<label for=\"jedesc\">Description:</label>\n" +
    "				<textarea id=\"jedesc\" name=\"description\" class=\"form-control\" type=\"text\" ng-model=\"newJournalEntryInfo.description\" required></textarea>\n" +
    "			</div>\n" +
    "			<div class=\"form-group\">\n" +
    "				<label for=\"jememo\">Memo:</label>\n" +
    "				<textarea id=\"jememo\" class=\"form-control\" type=\"text\" ng-model=\"newJournalEntryInfo.memo\"></textarea>\n" +
    "			</div>\n" +
    "			<div class=\"form-group\">\n" +
    "				<label for=\"jetoacct\">Other Account</label>\n" +
    "				<select class=\"form-control\" ng-model=\"newJournalEntryInfo.gl_account_other\" ng-options=\"account.id as account.description for account in accountsWithoutAccountIdAndSameCurrency  | orderBy:'id'\">\n" +
    "				</select>\n" +
    "			</div>\n" +
    "			<div class=\"form-group\" ng-class=\"{ 'has-success': myForm.amount.$valid, 'has-error': myForm.amount.$invalid}\">\n" +
    "				<label for \"jeamt\">Amount to other account:</label>\n" +
    "				<input id=\"jeamt\" name=\"amount\" class=\"form-control\" type=\"number\" ng-model=\"newJournalEntryInfo.amount\" required></input>\n" +
    "			</div>\n" +
    "			<div class=\"form-group\">\n" +
    "				<label>Currency: {{currency}}</label>\n" +
    "			</div>\n" +
    "		</form>\n" +
    "	</div>\n" +
    "	<div class=\"modal-footer\">\n" +
    "		<button class=\"btn btn-primary\" ng-disabled=\"myForm.$invalid\" ng-click=\"ok()\">OK</button>\n" +
    "		<button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "	</div>\n" +
    "</script>\n" +
    "<script type=\"text/ng-template\" id=\"addToInvoice.html\">\n" +
    "	<div class=\"modal-header\">\n" +
    "		<h3 class=\"modal-title\">{{title}}</h3>\n" +
    "	</div>\n" +
    "	<div class=\"modal-body\">\n" +
    "		<form name=\"myForm\">\n" +
    "			<div class=\"form-group\" ng-class=\"{ 'has-success': myForm.description.$valid, 'has-error': myForm.description.$invalid}\">\n" +
    "				<label for=\"jedesc\">Description:</label>\n" +
    "				<textarea id=\"jedesc\" name=\"description\" class=\"form-control\" type=\"text\" ng-model=\"newJournalEntryInfo.description\" required></textarea>\n" +
    "			</div>\n" +
    "			<div class=\"form-group\">\n" +
    "				<label for=\"jememo\">Memo:</label>\n" +
    "				<textarea id=\"jememo\" class=\"form-control\" type=\"text\" ng-model=\"newJournalEntryInfo.memo\"></textarea>\n" +
    "			</div>\n" +
    "			<div class=\"form-group\" ng-class=\"{ 'has-success': myForm.amount.$valid, 'has-error': myForm.amount.$invalid}\">\n" +
    "				<label for \"jeamt\">Amount:</label>\n" +
    "				<input id=\"jeamt\" name=\"amount\" class=\"form-control\" type=\"number\" ng-model=\"newJournalEntryInfo.amount\" required></input>\n" +
    "			</div>\n" +
    "			<div class=\"form-group\">\n" +
    "				<label>Currency: {{currency}}</label>\n" +
    "			</div>\n" +
    "		</form>\n" +
    "	</div>\n" +
    "	<div class=\"modal-footer\">\n" +
    "		<button class=\"btn btn-primary\" ng-disabled=\"myForm.$invalid\" ng-click=\"ok()\">OK</button>\n" +
    "		<button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "	</div>\n" +
    "</script>\n" +
    "<script type=\"text/ng-template\" id=\"addClaimToInvoice.html\">\n" +
    "	<div class=\"modal-header\">\n" +
    "		<h3 class=\"modal-title\">{{title}}</h3>\n" +
    "	</div>\n" +
    "	<div class=\"modal-body\">\n" +
    "		<form name='myForm'>\n" +
    "			<div class=\"form-group\" ng-class=\"{ 'has-success': myForm.description.$valid, 'has-error': myForm.description.$invalid}\">\n" +
    "				<label for=\"jedesc\">Description:</label>\n" +
    "				<textarea id=\"jedesc\" name='description' class=\"form-control\" ng-model=\"newJournalEntryInfo.description\" required></textarea>\n" +
    "			</div>\n" +
    "			<div class=\"form-group\">\n" +
    "				<label for=\"jememo\">Memo:</label>\n" +
    "				<textarea id=\"jememo\" class=\"form-control\" type=\"text\" ng-model=\"newJournalEntryInfo.memo\"></textarea>\n" +
    "			</div>\n" +
    "			<div class=\"form-group\" ng-class=\"{ 'has-success': myForm.amount.$valid, 'has-error': myForm.amount.$invalid}\">\n" +
    "				<label for \"jeamt\">Amount:</label>\n" +
    "				<input id=\"jeamt\" name='amount' class=\"form-control\" type=\"number\" ng-model=\"newJournalEntryInfo.amount\" required></input>\n" +
    "			</div>\n" +
    "			<div class=\"form-group\">\n" +
    "				<label>Currency: {{currency}}</label>\n" +
    "			</div>\n" +
    "		</form>\n" +
    "	</div>\n" +
    "	<div class=\"modal-footer\">\n" +
    "		<button ng-disabled=\"myForm.$invalid\" class=\"btn btn-primary\" ng-click=\"ok()\">OK</button>\n" +
    "		<button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "	</div>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/common/charts.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<h3 class=\"page-title\"> Charts\n" +
    "  <small></small>\n" +
    "</h3>\n" +
    "<div class=\"page-bar\" ng-hide=\"true\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "      <a ui-sref=\"home\">Home</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>Charts</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-aid-kit font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Patients</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\">\n" +
    "            <a href=\"/api/v1/patients/?format=csv\" target=\"_blank\" class=\"btn btn-default\" title=\"Download patient list\" ng-disabled=\"!CanRetrievePatient\">\n" +
    "              <i class=\"icomoon icon-icomoon-download\" aria-hidden=\"true\"></i>\n" +
    "            </a>\n" +
    "            <div class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "              <label style=\"margin-right:4px;\">Include archived patients in list?</label>\n" +
    "            </div>\n" +
    "            <div class=\"checkbox-inline custom-control custom-checkbox m-x-0\">\n" +
    "              <label>\n" +
    "                <input type=\"checkbox\" ng-model=\"include_archived\" ng-change=\"get(include_archived)\">\n" +
    "                <span class=\"custom-control-indicator\"></span>\n" +
    "              </label>\n" +
    "            </div>\n" +
    "            <button ng-disabled=\"!CanCreatePatient\" ng-click=\"openAddPatientModal()\" class=\"btn p-x btn-primary\" id=\"addPatient\">\n" +
    "              Add New Patient\n" +
    "            </button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <input type=\"text\" class=\"form-control\" placeholder=\"Search\" ng-model=\"searchTerm\">\n" +
    "        <button type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\"\n" +
    "          ng-click=\"get()\"\n" +
    "          ng-disabled=\"isResetting\">\n" +
    "          <i class=\"icomoon icon-icomoon-loop2\" aria-hidden=\"true\"></i>\n" +
    "        </button>\n" +
    "        <br>\n" +
    "\n" +
    "        <table class=\"table table-striped table-bordered table-advance\">\n" +
    "          <thead>\n" +
    "            <tr>\n" +
    "              <th ng-click=\"orderByClick(['id'])\" style=\"cursor:pointer\">\n" +
    "                ID\n" +
    "                <span class=\"pull-right\" ng-show=\"angular.equals(orderByProp, ['id'])\">\n" +
    "                  <i ng-hide=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                  <i ng-show=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "                </span>\n" +
    "              </th>\n" +
    "              <th ng-click=\"orderByClick(['last_name', 'first_name', 'gender'])\" style=\"cursor:pointer; width:50%\">\n" +
    "                Patient Name (Gender)\n" +
    "                <span class=\"pull-right\" ng-show=\"angular.equals(orderByProp, ['last_name', 'first_name', 'gender'])\">\n" +
    "                  <i ng-hide=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                  <i ng-show=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "                </span>\n" +
    "              </th>\n" +
    "              <th ng-click=\"orderByClick('home_phone')\" style=\"cursor:pointer; width:20%\">\n" +
    "                Home Phone\n" +
    "                <span class=\"pull-right\" ng-show=\"angular.equals(orderByProp, 'home_phone')\">\n" +
    "                  <i ng-hide=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                  <i ng-show=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "                </span>\n" +
    "              </th>\n" +
    "              <th ng-click=\"orderByClick('birth_date')\" style=\"cursor:pointer\">\n" +
    "                Date of Birth\n" +
    "                <span class=\"pull-right\" ng-show=\"angular.equals(orderByProp, 'birth_date')\">\n" +
    "                  <i ng-hide=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                  <i ng-show=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "                </span>\n" +
    "              </th>\n" +
    "              <th ng-click=\"orderByClick('is_archived')\" ng-if=\"include_archived\" style=\"cursor:pointer; min-width:95px\">\n" +
    "                Is Archived\n" +
    "                <span class=\"pull-right\" ng-show=\"angular.equals(orderByProp, 'is_archived')\">\n" +
    "                  <i ng-hide=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                  <i ng-show=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "                </span>\n" +
    "              </th>\n" +
    "              <th class=\"col-narrow\" style=\"min-width:81px\"></th>\n" +
    "            </tr>\n" +
    "          </thead>\n" +
    "          <tr ng-repeat=\"patient in patients | propsFilter: {filter:searchTerm, props:['id','last_name', 'first_name', 'gender', 'home_phone|tel', 'birth_date|date|MM/dd/yyyy']} | orderBy:orderByProp:orderByReverse\">\n" +
    "            <td ng-bind-html=\"patient.id | highlight:searchTerm\"></td>\n" +
    "            <td>\n" +
    "              <span ng-bind-html=\"patient.last_name | highlight:searchTerm\"></span>,\n" +
    "              <span ng-bind-html=\"patient.first_name | highlight:searchTerm\"></span>\n" +
    "              (<span ng-bind-html=\"patient.gender | uppercase | highlight:searchTerm\"></span>)\n" +
    "            </td>\n" +
    "            <td ng-bind-html=\"patient.home_phone | tel | highlight:searchTerm\"></td>\n" +
    "            <td ng-bind-html=\"patient.birth_date | date:'MM/dd/yyyy' | highlight:searchTerm\"></td>\n" +
    "            <td class=\"col-narrow text-center btn-container\" ng-if=\"include_archived\">\n" +
    "              <div class=\"checkbox-inline custom-control custom-checkbox m-x-0\">\n" +
    "                <label>\n" +
    "                  <input type=\"checkbox\" ng-model=\"patient.is_archived\" ng-change=\"set(patient)\">\n" +
    "                  <span class=\"custom-control-indicator\"></span>\n" +
    "                </label>\n" +
    "              </div>\n" +
    "            </td>\n" +
    "            <td class=\"btn-container\">\n" +
    "              <div class=\"btn-group\">\n" +
    "                <button type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\"\n" +
    "                  ng-click=\"goToFirstAvailablePatientView(patient)\"\n" +
    "                  ng-disabled=\"!hasAvailablePatientView()\">\n" +
    "                  <i class=\"icomoon icon-icomoon-pencil5\" aria-hidden=\"true\"></i>\n" +
    "                </button>\n" +
    "                <button type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\"\n" +
    "                  ng-click=\"openDelPatient(patient);\"\n" +
    "                  ng-if=\"CanDeletePatient && patient.can_be_deleted\">\n" +
    "                  <i class=\"icomoon icon-icomoon-bin\" aria-hidden=\"true\"></i>\n" +
    "                </button>\n" +
    "              </div>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </table>\n" +
    "\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "<script type=\"text/ng-template\" id=\"delPatient.html\">\n" +
    "  <div class=\"modal-header\">\n" +
    "	  <h3 class=\"modal-title\">Delete this patient?</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">Are you sure you want to delete the patient record for {{patient.first_name}} {{patient.last_name}}? NOTE that deleting this patient will delete any associated appointments.</div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn p-x btn-primary\" ng-click=\"ok()\">OK</button>\n" +
    "    <button class=\"btn p-x btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/common/denied.tpl.html","<alert type=\"'danger'\">\n" +
    "  <strong>Access Denied</strong>\n" +
    "  <p>You don't have permission to access this. <a ui-sref=\"home\">Return home.</a></p>\n" +
    "</alert>\n" +
    "")

$templateCache.put("src/common/encounter-add.tpl.html","<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-key font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">New Encounter</span>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <form name=\"addEncounter\" role=\"form\" novalidate ng-submit=\"submit()\">\n" +
    "          <div class=\"form-group\">\n" +
    "            <textarea ng-model=\"report\" class=\"form-control\" rows=\"8\"></textarea>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"panel panel-default\">\n" +
    "            <div class=\"panel-heading\">\n" +
    "              <span class=\"panel-title\">Associate Images</span>\n" +
    "              <div class=\"btn-group\">\n" +
    "                <button type=\"submit\" class=\"btn purple-sharp btn-circle\"><span class=\"glyphicon glyphicon-plus\" aria-hidden=\"true\"></span></button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"panel-body\">\n" +
    "              <!-- TODO(bradleybossard) - Fill in imags associated with this encounter -->\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"panel panel-default\">\n" +
    "            <div class=\"panel-heading\">\n" +
    "              <span class=\"panel-title\">Prescriptions</span>\n" +
    "              <div class=\"btn-group\">\n" +
    "                <button ui-sref=\"prescription-add\" class=\"btn purple-sharp btn-circle\"><span class=\"glyphicon glyphicon-plus\" aria-hidden=\"true\"></span></button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"panel-body\">\n" +
    "              <table class=\"table\" ng-hide=\"prescriptions.length == 0\">\n" +
    "                <thead>\n" +
    "                  <tr>\n" +
    "                    <td>Drug</td><td>Dosage</td><td>Dispense</td>\n" +
    "                  </tr>\n" +
    "                </thead>\n" +
    "                <tbody>\n" +
    "                  <tr ng-repeat=\"prescription in prescriptions\">\n" +
    "                    <td>{{prescription.drug}}</td>\n" +
    "                    <td>{{prescription.dosage}}</td>\n" +
    "                    <td>{{prescription.dispense}}</td>\n" +
    "                  </tr>\n" +
    "                </tbody>\n" +
    "              </table>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"panel panel-default\">\n" +
    "            <div class=\"panel-heading\">\n" +
    "              <span class=\"panel-title\">Treatment Plans</span>\n" +
    "              <div class=\"btn-group\">\n" +
    "                <button ui-sref=\"treatment-add\" class=\"btn btn-circle purple-sharp\"><span class=\"glyphicon glyphicon-plus\" aria-hidden=\"true\"></span></button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"panel-body\">\n" +
    "              <table class=\"table\" ng-hide=\"treatments.length == 0\">\n" +
    "                <thead>\n" +
    "                  <tr>\n" +
    "                    <td>Procedure</td>\n" +
    "                  </tr>\n" +
    "                </thead>\n" +
    "                <tbody>\n" +
    "                  <tr ng-repeat=\"treatment in treatments\">\n" +
    "                    <td>{{treatment.procedure}}</td>\n" +
    "                  </tr>\n" +
    "                </tbody>\n" +
    "              </table>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group pull-right\">\n" +
    "            <button type=\"submit\" class=\"btn btn-primary\">Submit</button>\n" +
    "            <button ui-sref=\"^\" class=\"btn btn-danger\">Cancel</button>\n" +
    "          </div>\n" +
    "        </form>\n" +
    "\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/encounter-detail-patient-view.tpl.html","<form name=\"addEncounter\" role=\"form\" novalidate  ng-submit=\"submit()\">\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-md-12\">\n" +
    "      <div class=\"portlet light porlet-fit\">\n" +
    "        <div class=\"portlet-title\">\n" +
    "          <div class=\"caption\">\n" +
    "            <i class=\"icomoon icon-icomoon-key font-red-sunglo\"></i>\n" +
    "            <span class=\"caption-subject font-red-sunglo bold uppercase\">Obtain Treatments Agreement</span>\n" +
    "          </div>\n" +
    "          <div class=\"actions\">\n" +
    "            <div class=\"btn-group\">\n" +
    "              <button type=\"button\" class=\"btn p-x btn-default\" back-button>\n" +
    "                <i class=\"icomoon icon-icomoon-arrow-left\"></i> Back\n" +
    "              </button>\n" +
    "              <button type=\"button\" ng-disabled=\"!CanUpdateTreatmentAgreement\" ng-click=\"save(encounter)\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\">\n" +
    "                <i class=\"icomoon icon-icomoon-circle-up6\"></i> Update\n" +
    "              </button>\n" +
    "              <button type=\"button\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\" ng-click=\"printEncounterDetailPatientView(encounterId)\">\n" +
    "                <i class=\"icomoon icon-icomoon-printer2\"></i> Print\n" +
    "              </button>\n" +
    "           </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"portlet-body\">\n" +
    "          <div class=\"row\">\n" +
    "            <div class=\"form-group col-md-6\">\n" +
    "              <label for=\"name\">Patient: </label>\n" +
    "              {{patient.first_name}} {{patient.last_name}}\n" +
    "            </div>\n" +
    "          </div>\n" +
    "		<div>\n" +
    "\n" +
    "\n" +
    "      <table class=\"table table-striped table-bordered table-advance\">\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <th width=\"4%\">Visit</th>\n" +
    "            <th width=\"9%\">Tooth</th>\n" +
    "            <th width=\"10%\">Provider</th>\n" +
    "            <th width=\"15%\">Description</th>\n" +
    "            <th width=\"5%\"> &nbsp;</th>\n" +
    "            <th width=\"13%\" style=\"text-align: right\">Fee</th>\n" +
    "            <th width=\"13%\" style=\"text-align: right\">Primary Insurance Covered (estimate)</th>\n" +
    "            <th width=\"13%\" style=\"text-align: right\">Secondary Insurance Covered (estimate)</th>\n" +
    "            <th width=\"15%\" style=\"text-align: right\">Patient (estimate)</th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "          <tr ng-repeat=\" t in groupedTreatments\" ng-class=\"{'treatments-table-striped-row' : t.subtotal}\"> <!-- rows -->\n" +
    "\n" +
    "\n" +
    "            <td width=\"5%\" ng-hide=\"t.hideRecommend\" style=\" color:firebrick\">{{t.recommendation_level}}</td>\n" +
    "            <td width=\"5%\" ng-show=\"t.hideRecommend\"></td>\n" +
    "\n" +
    "            <td width=\"10%\">{{t.tooth_numbers}}</td>\n" +
    "\n" +
    "            <td width=\"10%\" ng-hide=\"t.subtotal\">{{ getProviderName(t.provider) }}</td>\n" +
    "            <td width=\"10%\" ng-show=\"t.subtotal\"></td>\n" +
    "\n" +
    "\n" +
    "            <td width=\"15%\">{{t.procedure_name}}</td>\n" +
    "\n" +
    "\n" +
    "            <td width=\"5%\" ng-show=\"t.subtotal\" style=\"font-weight: 600; color:firebrick; text-align: right;\">Subtotal</td>\n" +
    "            <td width=\"5%\" ng-hide=\"t.subtotal\"></td>\n" +
    "\n" +
    "            <td width=\"13%\" ng-hide=\"t.subtotal\" style=\" text-align: right;\">{{t.amount_fee}}</td>\n" +
    "            <td width=\"13%\" ng-show=\"t.subtotal\" style=\"font-weight: 600; color:firebrick;  text-align: right;\">{{t.amountFeeSubtotal}}</td>\n" +
    "\n" +
    "            <td width=\"13%\" ng-hide=\"t.subtotal\" style=\" text-align: right;\">{{t.insurance_applied ? t.amount_ins : ''}}</td>\n" +
    "            <td width=\"13%\" ng-show=\"t.subtotal\" style=\"font-weight: 600; color:firebrick;  text-align: right;\">{{t.amountInsSubtotal}}</td>\n" +
    "\n" +
    "            <td width=\"13%\" ng-hide=\"t.subtotal\" style=\" text-align: right;\">{{t.other_applied ? t.amount_other : ''}}</td>\n" +
    "            <td width=\"13%\" ng-show=\"t.subtotal\" style=\"font-weight: 600; color:firebrick;  text-align: right;\">{{t.amountOtherSubtotal}}</td>\n" +
    "\n" +
    "            <td width=\"15%\" ng-hide=\"t.subtotal\" style=\" text-align: right;\">{{t.amount_patient }}</td>\n" +
    "            <td width=\"13%\" ng-show=\"t.subtotal\" style=\"font-weight: 600; color:firebrick;  text-align: right;\">{{t.amountPatientSubtotal}}</td>\n" +
    "\n" +
    "\n" +
    "          </tr>\n" +
    "        </tbody>\n" +
    "      </table>\n" +
    "\n" +
    "\n" +
    "\n" +
    "		</div>\n" +
    "        <div class=\"row\" style=\"color:red\">\n" +
    "            <div class=\"col-md-4\" style=\"font-size:6;\">\n" +
    "                <label style=\"font-weight: 600;\">Total Fee:</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-md-4\" style=\" ont-size:6;\">\n" +
    "                <label>{{amount_fee_total}} {{practice.currency}}</label>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"row\" style=\"color:red;\">\n" +
    "            <div class=\"col-md-4\" style=\"font-size:6;\">\n" +
    "                <label style=\"font-weight: 600;\">Total Primary Insurance Covered (estimate):</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-md-4\" style=\"font-size:6;\">\n" +
    "                <label>{{amount_ins_total}} {{practice.currency}}</label>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"row\" style=\"color:red;\">\n" +
    "            <div class=\"col-md-4\" style=\"font-size:6;\">\n" +
    "                <label style=\"font-weight: 600;\">Total Secondary Insurance Covered (estimate):</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-md-4\" style=\"font-size:6;\">\n" +
    "                <label>{{amount_other_total}} {{practice.currency}}</label>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"row\" style=\"color:red;\">\n" +
    "            <div class=\"col-md-4\" style=\"font-size:6;\">\n" +
    "                <label style=\"font-weight: 600;\">Total Patient Responsibility (estimate):</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-md-4\" style=\"font-size:6;\">\n" +
    "                <label>{{amount_patient_total}} {{practice.currency}}</label>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"panel panel-default\">\n" +
    "            <div class=\"panel-heading\">\n" +
    "                <span class=\"panel-title\">Signatures</span>\n" +
    "            </div>\n" +
    "            <div class=\"panel-body\">\n" +
    "                <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-12\">\n" +
    "                        <p>I agree that I am financially responsible for all treatment started.  I agree that my Insurance Plan coverage has been explained to me and I understand that all amounts listed above, including the estimated amount and my estimated out-of-pocket/patient expense, are only estimates. I agree to be fully responsible if my Insurance Plan does not pay these estimates for any reason and understand that this may increase my out-of-pocket expense.  Any changes in any of the amounts due to Insurance Plan coverage will be my responsibility.  I understand the billing descriptions/codes required for claims submittal to my Insurance Plan may not adequately describe the treatment I have chosen.  My Insurance Plan may catalog the treatment I have chosen based solely on the descriptions provided for use rather than the treatment actually provided.  I understand that the office’s contracted fee with my Insurance Plan(s) may not be the same as In-Network Fee set forth above.  In the event my Insurance Plan(s) pays more than its estimated amount, I understand that this may not reduce my patient share.   Once my Insurance Plan(s) adjudicate(s) my claim, if my patient share was overestimated, I will be refunded any excess by the office.  Any change in my treatment plan either by my choice or by necessity will change the fees quoted.  I understand that whenever possible, I will be informed of any changes in advance.  I have read, fully understand and authorize the treatment as presented to me today. </p>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"row\" ng-class=\"{ 'link-disabled':  !CanUpdateTreatmentAgreement}\" >\n" +
    "\n" +
    "                    <div class=\"form-group col-sm-6\">\n" +
    "                      <label for=\"patientsig\">Patient Signature</label>\n" +
    "                      <div class=\"relative-signature-box panel-default\">\n" +
    "                        <!--<canvas ng-signature-pad=\"patientsig\"></canvas>-->\n" +
    "                        <canvas angular-sig-pad ng-model=\"encounter.patient_signature\"></canvas>\n" +
    "                        <button ng-disabled=\"metadataReadOnly\" type=\"button\" class=\"absolute-top-button-transparent-right-box btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"clearSignature(encounter,'patient_signature')\"><i class=\"icomoon icon-icomoon-reset\"></i></button>\n" +
    "                      </div>\n" +
    "                      <!--label ng-show=\"encounter.patient_signature_date\">{{encounter.patient_signature_date | signedDate}}</label-->\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"form-group col-sm-6\">\n" +
    "                      <label for=\"doctorsig\">Provider Signature</label>\n" +
    "                      <div class=\"relative-signature-box panel-default\">\n" +
    "                        <!--<canvas ng-signature-pad=\"doctorsig\"></canvas>-->\n" +
    "                        <canvas angular-sig-pad ng-model=\"encounter.provider_signature\"></canvas>\n" +
    "                        <button   ng-disabled=\"metadataReadOnly\" type=\"button\" class=\"absolute-top-button-transparent-right-box btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"clearSignature(encounter,'provider_signature')\"><i class=\"icomoon icon-icomoon-reset\"></i></button>\n" +
    "                      </div>\n" +
    "                      <!--label ng-show=\"encounter.provider_signature_date\">{{encounter.provider_signature_date | signedDate}}</label-->\n" +
    "                    </div>\n" +
    "\n" +
    "               </div>\n" +
    "\n" +
    "             </div>\n" +
    "        </div>\n" +
    "\n" +
    "        </div>\n" +
    "        <div class=\"portlet-title\">\n" +
    "          <div class=\"actions\">\n" +
    "            <div class=\"btn-group\">\n" +
    "              <button type=\"button\" class=\"btn p-x btn-default\" back-button>\n" +
    "                <i class=\"icomoon icon-icomoon-arrow-left\"></i> Back\n" +
    "              </button>\n" +
    "              <button type=\"button\" ng-disabled=\"!CanUpdateTreatmentAgreement\" ng-click=\"save(encounter)\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\">\n" +
    "                <i class=\"icomoon icon-icomoon-circle-up6\"></i> Update\n" +
    "              </button>\n" +
    "              <button type=\"button\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\" ng-click=\"printEncounterDetailPatientView(encounterId)\">\n" +
    "                <i class=\"icomoon icon-icomoon-printer2\"></i> Print\n" +
    "              </button>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</form>\n" +
    "")

$templateCache.put("src/common/encounter-detail.tpl.html","<form name=\"addEncounter\" role=\"form\" novalidate ng-submit=\"submit()\">\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-md-12\">\n" +
    "      <div class=\"portlet light porlet-fit m-b-0\">\n" +
    "        <div class=\"portlet-title\">\n" +
    "          <div class=\"caption\">\n" +
    "            <i class=\"icomoon icon-icomoon-key font-red-sunglo\"></i>\n" +
    "            <span class=\"caption-subject font-red-sunglo bold uppercase\">Encounter Details</span>\n" +
    "          </div>\n" +
    "          <div class=\"actions\">\n" +
    "            <div class=\"btn-group\">\n" +
    "              <a class=\"btn btn-default p-x\" ui-sref=\"^\" ui-sref-opts=\"{reload: true}\">\n" +
    "                <i class=\"icomoon icon-icomoon-arrow-left\"></i>\n" +
    "                Back\n" +
    "              </a>\n" +
    "              <button type=\"submit\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\" ng-disabled=\"!CanUpdateEncounter\">\n" +
    "                <i class=\"icomoon icon-icomoon-circle-up6\"></i>\n" +
    "                Update\n" +
    "              </button>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"portlet-title\">\n" +
    "        </div>\n" +
    "        <div class=\"portlet-body\">\n" +
    "          <div ng-if=\"patientMedicalCondition\" class=\"alert alert-warning medical-alert p-t-0 pos-r\">\n" +
    "            <i class=\"icomoon icon-icomoon-warning2 pos-a\" style=\"font-size:32px; bottom:50%\"></i>\n" +
    "            <div ng-bind-html=\"patientMedicalCondition\" style=\"margin-left:45px\"></div>\n" +
    "          </div>\n" +
    "          <fieldset ng-disabled=\"!CanUpdateEncounter\">\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"form-group col-md-3\">\n" +
    "                <custom-datepicker-popup ng-model=\"encounter.encounter_date\" ng-required=\"true\" form-name=\"'addEncounter'\" input-name=\"'encounterDateInput'\" show-placeholder=\"false\"></custom-datepicker-popup>\n" +
    "              </div>\n" +
    "              <div class=\"form-group col-md-2\">\n" +
    "                <input ng-model=\"encounter.tooth_numbers\" type=\"text\" class=\"form-control\" id=\"tooth_numbers\" placeholder=\"Tooth number(s)...\"></input>\n" +
    "              </div>\n" +
    "              <div ng-if=\"MultiLocationEnable\" class=\"form-group col-md-3\">\n" +
    "                <ui-select ng-model=\"encounter.location\" ng-required=\"false\" theme=\"selectize\">\n" +
    "                  <ui-select-match placeholder=\"Location\">{{ $select.selected.name }}</ui-select-match>\n" +
    "                  <ui-select-choices repeat=\"p.id as p in locations | filter:$select.search\">\n" +
    "                    <span ng-bind-html=\"p.name | highlight: $select.search\"></span>\n" +
    "                  </ui-select-choices>\n" +
    "                </ui-select>\n" +
    "              </div>\n" +
    "              <div ng-if=\"MultiLocationEnable\" class=\"form-group col-md-12\">\n" +
    "                <input ng-model=\"encounter.title\" type=\"text\" class=\"form-control\" id=\"title\" placeholder=\"Short description (root canal, etc.)...\"></input>\n" +
    "              </div>\n" +
    "              <div ng-if=\"!MultiLocationEnable\" class=\"form-group col-md-7\">\n" +
    "                <input ng-model=\"encounter.title\" type=\"text\" class=\"form-control\" id=\"title\" placeholder=\"Short description (root canal, etc.)...\"></input>\n" +
    "              </div>\n" +
    "              <div class=\"form-group col-md-12\">\n" +
    "                <div class=\"input-group\">\n" +
    "                  <ui-select ng-model=\"$parent.template\" theme=\"selectize\" style=\"margin-right:2px; margin-top:3px\">\n" +
    "                    <ui-select-match placeholder=\"Select an Encounter Template by Title (ID) - Provider...\">\n" +
    "                      <span style=\"text-overflow:ellipsis\">\n" +
    "                        {{ $select.selected.title }}\n" +
    "                        ({{ $select.selected.id }})\n" +
    "                        -\n" +
    "                        <small class=\"text-muted\">{{ $select.selected.provider_first_name }} {{ $select.selected.provider_last_name }}</small>\n" +
    "                      </span>\n" +
    "                    </ui-select-match>\n" +
    "                    <ui-select-choices\n" +
    "                      repeat=\"template in templates | filter:{title: $select.search} | orderBy: ['title']\"\n" +
    "                    >\n" +
    "                      <span style=\"text-overflow:ellipsis\">\n" +
    "                        {{template.title}}\n" +
    "                        ({{template.id}})\n" +
    "                        -\n" +
    "                        <small class=\"text-muted\">{{template.provider_first_name}} {{template.provider_last_name}}</small>\n" +
    "                      </span>\n" +
    "                    </ui-select-choices>\n" +
    "                  </ui-select>\n" +
    "                  <div class=\"input-group-btn\">\n" +
    "                    <button type=\"button\" class=\"btn default btn-icomoon-pixel-perfect-1x p-x\" ng-click=\"saveAsTemplate()\">\n" +
    "                      <i class=\"icomoon icon-icomoon-file-plus2\"></i>\n" +
    "                    </button>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-show=\"encounter.encounter_type == 'co' || encounter.encounter_type=='cp'\">\n" +
    "              <table class=\"table table-striped table-condensed\">\n" +
    "                <tr>\n" +
    "                  <td class=\"col-md-2\"><p class=\"form-control-static\">Diagnostic test</p></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.t1\" tabindex=\"1\"  placeholder=\"Tooth #\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.t2\" tabindex=\"10\"  placeholder=\"Tooth #\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.t3\" tabindex=\"19\" placeholder=\"Tooth #\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.t4\" tabindex=\"28\" placeholder=\"Tooth #\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.t5\" tabindex=\"37\" placeholder=\"Tooth #\"></input></td>\n" +
    "                </tr>\n" +
    "                <tr>\n" +
    "                  <td class=\"col-md-2\"><p class=\"form-control-static\">Cold test</p></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ct1\" tabindex=\"2\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ct2\" tabindex=\"11\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ct3\" tabindex=\"20\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ct4\" tabindex=\"29\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ct5\" tabindex=\"38\"></input></td>\n" +
    "                </tr>\n" +
    "                <tr>\n" +
    "                  <td class=\"col-md-2\"><p class=\"form-control-static\">Heat test</p></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ht1\" tabindex=\"3\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ht2\" tabindex=\"12\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ht3\" tabindex=\"21\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ht4\" tabindex=\"30\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ht5\" tabindex=\"39\"></input></td>\n" +
    "                </tr>\n" +
    "                <tr>\n" +
    "                  <td class=\"col-md-2\"><p class=\"form-control-static\">Percussion</p></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pe1\" tabindex=\"4\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pe2\" tabindex=\"13\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pe3\" tabindex=\"22\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pe4\" tabindex=\"31\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pe5\" tabindex=\"40\"></input></td>\n" +
    "                </tr>\n" +
    "                <tr>\n" +
    "                  <td class=\"col-md-2\"><p class=\"form-control-static\">Palpation</p></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pa1\" tabindex=\"5\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pa2\" tabindex=\"14\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pa3\" tabindex=\"23\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pa4\" tabindex=\"32\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pa5\" tabindex=\"41\"></input></td>\n" +
    "                </tr>\n" +
    "                <tr>\n" +
    "                  <td class=\"col-md-2\"><p class=\"form-control-static\">EPT</p></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ept1\" tabindex=\"6\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ept2\" tabindex=\"15\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ept3\" tabindex=\"24\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ept4\" tabindex=\"33\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.ept5\" tabindex=\"42\"></input></td>\n" +
    "                </tr>\n" +
    "                <tr>\n" +
    "                  <td class=\"col-md-2\"><p class=\"form-control-static\">Mobility</p></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.mo1\" tabindex=\"7\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.mo2\" tabindex=\"16\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.mo3\" tabindex=\"25\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.mo4\" tabindex=\"34\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.mo5\" tabindex=\"43\"></input></td>\n" +
    "                </tr>\n" +
    "                <tr>\n" +
    "                  <td class=\"col-md-2\"><p class=\"form-control-static\">Probing</p></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pr1\" tabindex=\"8\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pr2\" tabindex=\"17\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pr3\" tabindex=\"26\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pr4\" tabindex=\"35\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.pr5\" tabindex=\"44\"></input></td>\n" +
    "                </tr>\n" +
    "                <tr>\n" +
    "                  <td class=\"col-md-2\"><p class=\"form-control-static\">Bite Test</p></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.bt1\" tabindex=\"9\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.bt2\" tabindex=\"18\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.bt3\" tabindex=\"27\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.bt4\" tabindex=\"36\"></input></td>\n" +
    "                  <td class=\"col-md-2\"><input type=\"text\" class=\"form-control\" ng-model=\"encounter.diagnostic_tests.bt5\" tabindex=\"45\"></input></td>\n" +
    "                </tr>\n" +
    "              </table>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "              <textarea ng-model=\"encounter.report\" class=\"form-control\" rows=\"8\" placeholder=\"Full description...\" ng-blur=\"reportBlurred()\"></textarea>\n" +
    "              <div class=\"btn-group\">\n" +
    "                  <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" title=\"Show description history\"\n" +
    "                    ng-click=\"isReportHistoryNotCollapsed = !isReportHistoryNotCollapsed\"\n" +
    "                  >\n" +
    "                    <span class=\"icomoon\" aria-hidden=\"true\" ng-class=\"isReportHistoryNotCollapsed ? ' icon-icomoon-minus2' : 'icon-icomoon-plus2'\"></span>\n" +
    "                  </button>\n" +
    "                  <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" title=\"Fill out description form\" ng-show=\"showReportFormButton\"\n" +
    "                    ng-click=\"openReportFormDialog(encounter.report)\"\n" +
    "                  >\n" +
    "                    <span aria-hidden=\"true\" class=\"icomoon icon-icomoon-insert-template font-red-sunglo bold\"></span>\n" +
    "                  </button>\n" +
    "                </div>\n" +
    "                <div class=\"btn-group\" >\n" +
    "                  <button type=\"button\"  class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" ng-disabled=\"!medicalHistoryLoaded\" title=\"Show medical history\"\n" +
    "                    ng-click=\"isMedicalHistoryNotCollapsed = !isMedicalHistoryNotCollapsed\"\n" +
    "                  >\n" +
    "                    <span class=\"icomoon font-red-sunglo\" aria-hidden=\"true\" ng-class=\"isMedicalHistoryNotCollapsed ? ' icon-icomoon-aid-kit' : 'icon-icomoon-aid-kit'\"></span>\n" +
    "                  </button>\n" +
    "\n" +
    "                </div>\n" +
    "                <div uib-collapse=\"!isReportHistoryNotCollapsed\">\n" +
    "                  <label for=\"report-history\">Description History:</label>\n" +
    "                  <textarea id=\"report-history\" ng-model=\"encounter.report_history\" class=\"form-control\" rows=\"12\" ng-disabled=\"true\"></textarea>\n" +
    "              </div>\n" +
    "              <div uib-collapse=\"!isMedicalHistoryNotCollapsed\" class=\"medical-history-panel\">\n" +
    "                <label for=\"medical-history\">Medical History:</label>\n" +
    "                <textarea id=\"medical-history\" ng-model=\"medicalHistory\" class=\"form-control\" rows=\"12\" ng-disabled=\"true\"></textarea>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          </fieldset>\n" +
    "          <patient-image-detail-encounter\n" +
    "            can-create=\"CanCreatePatientImage\"\n" +
    "            can-delete=\"CanDeletePatientImage\"\n" +
    "            can-list=\"CanListPatientImage\"\n" +
    "            can-retrieve=\"CanRetrievePatientImage\"\n" +
    "            can-update=\"CanUpdatePatientImage\"\n" +
    "            encounter-id=\"encounterId\">\n" +
    "          </patient-image-detail-encounter>\n" +
    "          <div class=\"panel panel-default\" id=\"encounter-detail-prescription\">\n" +
    "            <div class=\"panel-heading\">\n" +
    "              <span class=\"panel-title\">Prescriptions</span>\n" +
    "              <div class=\"btn-group\">\n" +
    "                <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "                  ng-click=\"isPrescriptionsAddNotCollapsed = !isPrescriptionsAddNotCollapsed\"\n" +
    "                  ng-disabled=\"!encounterId || !CanUpdateEncounter\">\n" +
    "                  <span class=\"icomoon icon-icomoon-plus2\" aria-hidden=\"true\" ng-hide=\"isPrescriptionsAddNotCollapsed\"></span>\n" +
    "                  <span ng-hide=\"!isPrescriptionsAddNotCollapsed\" class=\"icomoon icon-icomoon-minus2\" aria-hidden=\"true\"></span>\n" +
    "                </button>\n" +
    "              </div>\n" +
    "              <div class=\"btn-group\">\n" +
    "                <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "                  ng-click=\"isDoseSpotRegistered && doseSpotRedirect(refillDoseSpotRedirectUrl)\"\n" +
    "                  ng-disabled=\"!encounterId || !CanUpdateEncounter\"\n" +
    "                  ng-hide=\"!isDoseSpotRegistered\">\n" +
    "                  <span class=\"icomoon icon-icomoon-envelop3\" aria-hidden=\"true\"></span>\n" +
    "                  <div class=\"text\">Refill Requests</div>\n" +
    "                  <span class=\"badge dosespot refill\">{{ refillRequestCount }}</span>\n" +
    "                </button>\n" +
    "              </div>\n" +
    "              <div class=\"btn-group\">\n" +
    "                <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "                  ng-click=\"isDoseSpotRegistered && doseSpotRedirect(refillDoseSpotRedirectUrl)\"\n" +
    "                  ng-disabled=\"!encounterId || !CanUpdateEncounter\"\n" +
    "                  ng-hide=\"!isDoseSpotRegistered\">\n" +
    "                  <span class=\"icomoon icon-icomoon-envelop3\" aria-hidden=\"true\"></span>\n" +
    "                  <div class=\"text\">Refill Transmission Errors</div>\n" +
    "                  <span class=\"badge dosespot transmission-error\">{{ refillTransmissionErrorCount }}</span>\n" +
    "                </button>\n" +
    "              </div>\n" +
    "              <div class=\"btn-group\">\n" +
    "                <script type=\"text/ng-template\" id=\"preferred-pharmacy\">\n" +
    "                  <div>\n" +
    "                    <dl class=\"m-b-0\">\n" +
    "                      <dt>Name</dt>\n" +
    "                      <dd>{{ patient.preferredPharmacyName || \"-\" }}</dt>\n" +
    "                      <dt>Location</dd>\n" +
    "                      <dd>{{ patient.preferredPharmacyLocation || \"-\" }}</dd>\n" +
    "                    </dl>\n" +
    "                  </div>\n" +
    "                </script>\n" +
    "                <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "                  popover-append-to-body=\"true\"\n" +
    "                  uib-popover-template=\"'preferred-pharmacy'\">\n" +
    "                  <span class=\"icomoon icon-icomoon-info\" aria-hidden=\"true\"></span>\n" +
    "                  <div class=\"text\">Preferred Pharmacy</div>\n" +
    "                </button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"panel-body p-a-0\" id=\"prescriptions\">\n" +
    "              <div ui-view=\"prescriptions\"></div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"panel panel-default\" id=\"encounter-detail-treatment\">\n" +
    "            <div class=\"panel-heading\">\n" +
    "              <span class=\"panel-title\">Treatments</span>\n" +
    "              <div class=\"btn-group\">\n" +
    "                <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "                  ng-click=\"isTreatmentsAddNotCollapsed = !isTreatmentsAddNotCollapsed\"\n" +
    "                  ng-disabled=\"!CanUpdateEncounter\">\n" +
    "                  <span class=\"icomoon\" aria-hidden=\"true\" ng-class=\"isTreatmentsAddNotCollapsed ? ' icon-icomoon-minus2' : 'icon-icomoon-plus2'\"></span>\n" +
    "                </button>\n" +
    "              </div>\n" +
    "              <div class=\"btn-group\">\n" +
    "                <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "                  ng-disabled=\"!CanRetrieveTreatmentAgreement\"\n" +
    "                  ui-sref=\"chart-detail-patient-view({encounterid:encounterId, patientid:patientId})\">\n" +
    "                  <span class=\"icomoon icon-icomoon-checkmark\" aria-hidden=\"true\"></span>\n" +
    "                  <div class=\"text\">Obtain Financial Agreement</div>\n" +
    "                </button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"panel-body\" id=\"treatments\">\n" +
    "              <div ui-view=\"treatments\"></div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"panel panel-default\">\n" +
    "            <div class=\"panel-heading\">\n" +
    "              <span class=\"panel-title\">Treatments Agreement</span>\n" +
    "              <div class=\"btn-group\">\n" +
    "                <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "                  ng-disabled=\"!CanRetrieveTreatmentAgreement\"\n" +
    "                  ui-sref=\"chart-detail-patient-view({encounterid:encounterId, patientid:patientId})\">\n" +
    "                  <span class=\"icomoon icon-icomoon-checkmark\" aria-hidden=\"true\"></span>\n" +
    "                  <div class=\"text\">Obtain Financial Agreement</div>\n" +
    "                </button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <fieldset ng-disabled=\"!CanUpdateEncounter\">\n" +
    "              <div class=\"panel-body\">\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <p>By signing this I agree to these treatments, agree to its risks, and to pay for it.</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group row\">\n" +
    "                  <div class=\"col-sm-6\">\n" +
    "                    <div class=\"panel\">\n" +
    "                      <div class=\"panel-title\">\n" +
    "                        <label for=\"encounter-date\">Patient Signature:</label>\n" +
    "                      </div>\n" +
    "                      <div class=\"relative-signature-box panel-default\" ng-class=\"{ 'link-disabled':  !CanUpdateEncounter}\">\n" +
    "                        <canvas angular-sig-pad ng-model=\"encounter.patient_signature\"></canvas>\n" +
    "                        <button type=\"button\" class=\"absolute-top-button-transparent-right-box btn btn-default\"\n" +
    "                          ng-click=\"clearSignature(encounter,'patient_signature')\"\n" +
    "                          ng-disabled=\"metadataReadOnly\">\n" +
    "                          <i class=\"icomoon icon-icomoon-loop3\"></i>\n" +
    "                        </button>\n" +
    "                      </div>\n" +
    "                      <!--label ng-show=\"encounter.patient_signature_date\">{{encounter.patient_signature_date | signedDate}}</label-->\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group col-sm-6\">\n" +
    "                    <div class=\"panel\">\n" +
    "                      <div class=\"panel-title\">\n" +
    "                        <label for=\"encounter-date\">Provider Signature:</label>\n" +
    "                      </div>\n" +
    "                      <div class=\"relative-signature-box panel-default\" ng-class=\"{ 'link-disabled':  !CanUpdateEncounter}\">\n" +
    "                        <canvas angular-sig-pad ng-model=\"encounter.provider_signature\"></canvas>\n" +
    "                        <button type=\"button\" class=\"absolute-top-button-transparent-right-box btn btn-default\"\n" +
    "                          ng-click=\"clearSignature(encounter,'provider_signature')\"\n" +
    "                          ng-disabled=\"metadataReadOnly\">\n" +
    "                          <i class=\"icomoon icon-icomoon-loop3\"></i>\n" +
    "                        </button>\n" +
    "                      </div>\n" +
    "                      <!--label ng-show=\"encounter.provider_signature_date\">{{encounter.patient_signature_date | signedDate}}</label-->\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </fieldset>\n" +
    "          </div>\n" +
    "          <div ui-view=\"consentforms\" id=\"encounter-detail-consent\"></div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <reminders-manage id=\"encounter-detail-followup\"></reminders-manage>\n" +
    "      <div class=\"portlet light porlet-fit m-b-0 p-t-md\">\n" +
    "        <div class=\"portlet-title\">\n" +
    "          <div class=\"actions\">\n" +
    "            <div class=\"btn-group\">\n" +
    "              <a class=\"btn btn-default p-x\" ui-sref=\"^\" ui-sref-opts=\"{reload: true}\">\n" +
    "                <i class=\"icomoon icon-icomoon-arrow-left\"></i>\n" +
    "                Back\n" +
    "              </a>\n" +
    "              <button type=\"submit\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\" ng-disabled=\"!CanUpdateEncounter\">\n" +
    "                <i class=\"icomoon icon-icomoon-circle-up6\"></i>\n" +
    "                Update\n" +
    "              </button>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</form>\n" +
    "<div class=\"popover-jumper-container\" >\n" +
    "  <button \n" +
    "    uib-popover-template=\"'jumper-menu-encounter.html'\"\n" +
    "    popover-placement=\"left\"\n" +
    "  >\n" +
    "    <i class=\"icomoon icon-icomoon-move-down\"></i>\n" +
    "  </button>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"reportForm.html\">\n" +
    "	<div class=\"modal-header\">\n" +
    "		<h3 class=\"modal-title\">Fill Description Form</h3>\n" +
    "	</div>\n" +
    "	<div class=\"modal-body\">\n" +
    "    <div ng-bind-html=\"form_html\" id=\"form_html\">\n" +
    "    </div>\n" +
    "	</div>\n" +
    "	<div class=\"modal-footer\">\n" +
    "		<button ng-disabled=\"myForm.$invalid\" class=\"btn btn-primary\" ng-click=\"ok()\">OK\n" +
    "    </button>\n" +
    "		<button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel\n" +
    "    </button>\n" +
    "	</div>\n" +
    "</script>\n" +
    "<script type=\"text/ng-template\" id=\"jumper-menu-encounter.html\">\n" +
    "<span>\n" +
    "  <ul class=\"\">\n" +
    "    <li\"><a href=\"#top\">Top</a></li>\n" +
    "    <li><a href=\"#patient-image-detail-encounter\">Images</a></li>\n" +
    "    <li><a href=\"#encounter-detail-prescription\">Prescriptions</a></li>\n" +
    "    <li><a href=\"#encounter-detail-treatment\">Treatments</a></li>\n" +
    "    <li><a href=\"#encounter-detail-consent\">Informed Consent Forms</a></li>\n" +
    "    <li><a href=\"#encounter-detail-followup\">Follow-up Reminders</a></li>\n" +
    "  </ul>\n" +
    "</span>\n" +
    "<button type=\"button\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\" ng-click=\"submit()\" ng-disabled=\"!CanUpdateEncounter\">\n" +
    "  <i class=\"icomoon icon-icomoon-circle-up6\"></i>\n" +
    "  Update\n" +
    "</button>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/common/eula.tpl.html","<h3 class=\"page-title center\"> Software License Agreement\n" +
    "  <small></small>\n" +
    "</h3>\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-xs-12\">\n" +
    "    <div class=\"portlet light bordered\">\n" +
    "      <div class=\"portlet body form\">\n" +
    "\n" +
    "      <div class=\"eula-content\">\n" +
    "\n" +
    "<p>\n" +
    "    <strong>THIS License and Services Agreement</strong>\n" +
    "    (this \"<strong>Agreement</strong>\") is made as of the day of your signatures on the Dental EMR Proposal (the \"<strong>Effective Date</strong>\"), between\n" +
    "Dental EMR, Inc., a Delaware corporation (\"<strong>Dental EMR</strong>\", \"<strong>We</strong>\"), and you (\"<strong>Licensee</strong>\", \"    <strong>You</strong>\"). <strong>You and Dental EMR are collectively referred to as the \"Parties.\" </strong><strong></strong>\n" +
    "</p>\n" +
    "<p>\n" +
    "    In consideration of the rights and benefits that they will each receive in connection with this Agreement, the parties, intending to be legally bound,\n" +
    "    agree as follows:\n" +
    "</p>\n" +
    "<p>\n" +
    "    1) <strong>Definitions</strong>: For the purposes of this Agreement, the terms set forth in this section have the meanings assigned to them below. Terms\n" +
    "    not defined below (whether or not capitalized) have the definitions given them in HIPAA, unless the context requires otherwise\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Accounting Log\"</strong>\n" +
    "    means accounting records compiled and maintained by Dental EMR regarding the usage of the Program by the Licensee, which records may include, for example,\n" +
    "    a schedule of the times at which a Program was used by the Licensee and /or the amount of time any given Authorized User used the Program or any portion\n" +
    "    thereof.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Agreement\"</strong>\n" +
    "    means this License Agreement (paper or electronic) or any amendment thereof.&#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"ASP\" or \"Application Service Provider\" or \"Subscription\"</strong>\n" +
    "    means the delivery of Applications over the Internet via a standard set of communications protocols as warranted by this Agreement.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Authorized User\"</strong>\n" +
    "    means you and those members of your Workforce who are individually authorized by you and Dental EMR to have access to Dental EMR Programs and Services to\n" +
    "    assist you in providing treatment and obtaining payment for treatment, and to whom we have assigned a unique identifier for access to the Dental EMR\n" +
    "    Programs and Services.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Confidential Information\"</strong>\n" +
    "    , means any information concerning our business and includes all data, materials, products, technology, computer programs, specifications, manuals,\n" +
    "    business plans, software, trade secrets, workflows, customers, source code, data models, marketing plans, methods of operation, financial information, and\n" +
    "    other information disclosed or submitted, orally or in writing, or through the licensed programs and services or by any other media from one party to\n" +
    "    another pursuant to this Agreement or any other information that is treated or designated by us as confidential or proprietary, or would reasonably be\n" +
    "    viewed as confidential or as having value to our competitors. Confidential Information shall not include information that we make publicly available or\n" +
    "    that becomes known to the general public other than as a result of a breach of an obligation by you. Confidential Information does not include individuals'\n" +
    "    health information.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Dental EMR Information\"</strong>\n" +
    "    means Reference Information and all documents, communications, emails, training materials, online help, user manuals, reports, analysis and other material\n" +
    "    prepared, created or transmitted by Dental EMR to the Licensee.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong> \"Dental EMR Materials\"</strong>\n" +
    "    means all software, Program, Updates and copies of all or portions thereof (including demonstration copies), user Manuals, and other documentation provided\n" +
    "    by, or on behalf of, Dental EMR to the Licensee including Dental EMR programs, subscription, services, and all files, data, and other materials and\n" +
    "    information provided through or as part of this agreement.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Dental EMR Program and Services\"</strong>\n" +
    "    means all parts of the solution delivered other than third party and Sub-licensed Programs. However, \"Programs and Services\" or \"Programs\", \"Software\"\n" +
    "    shall include Dental EMR Programs and or Services and third party programs and or services. Your indemnification, confidential information and intellectual\n" +
    "    property obligations hereunder are for Dental EMR Programs and Services as well as third party programs and services. \"Hosted Applications\" shall also mean\n" +
    "    both Dental EMR and third party programs and services.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"De-identified Information\"</strong>\n" +
    "    means information that has been de-identified in accordance with the provisions of the Privacy Rule, and \"De-Identify\" means make information into\n" +
    "    De-Identified Information.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Equipment\"</strong>\n" +
    "    means the operating system, hardware, software and networks on or through which the Dental EMR programs are used or accessed by the Licensee Software\n" +
    "    Support and Maintenance includes telephone support and product upgrades and content usage for drug database and Drug Interaction checks, ICD and CPT\n" +
    "    upgrades, e-mail and fax support, patch upgrades and any other auxiliary activities that may be conducted to facilitate the use of the Dental EMR Software\n" +
    "    and Services covered under this Agreement.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"HIPAA\"</strong>\n" +
    "    means the administrative simplification provisions of the Health Insurance Portability and Accountability Act of 1996 and the regulations promulgated\n" +
    "    thereunder, including the Privacy Rule and the Security Rule.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"New Product\"</strong>\n" +
    "    means any software program or other products, subscription or services, other than Programs or Updates which Dental EMR provides to its customers. Dental\n" +
    "    EMR may charge a separate fee for any New Product as specified or determined by Dental EMR in accordance with the applicable rates.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Policies and or Procedures\"</strong>\n" +
    "    means our rules, regulations, policies and procedures for access to and use of the Dental EMR Programs and Services including third party programs and\n" +
    "    services, as changed from time to time and as made available or communicated to You, or posted electronically on our Internet website.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Privacy Rule\"</strong>\n" +
    "    means the Standards for Privacy of Individually Identifiable Health Information at 45 CFR part 160 and part 164, subparts A and E.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Security Rule\"</strong>\n" +
    "    means the Security Standards for the Protection of Electronic Protected Health Information at 45 CFR part 160 and part 164, subparts A and C.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Services\"</strong>\n" +
    "    means any and all services that You request and or Dental EMR deliver.&#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Term\"</strong>\n" +
    "    means the initial term and all renewal terms of this Agreement.&#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Full Time Provider\"</strong>\n" +
    "    means any provider that works more than 2 days a week and has a valid National Provider Identifier (NPI), including but not limited to General Dentists,\n" +
    "    Endodontists, Periodontists, Oral Surgeons, Pedodontists, Orthodontists, Prosthodontists, care managers, care coordinators and Podiatrists employed by or\n" +
    "    under contract with Licensee to provide healthcare services. Each Full Time provider may be provided a maximum of five (5) non-provider licenses free of\n" +
    "    cost. Additional non-provider licenses shall be charged at $100/user per month.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Optional Services\"</strong>\n" +
    "    means additional services that Dental EMR may offer to license-holder of Dental EMR Programs from time to time.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Part Time Provider\"</strong>\n" +
    "    means any provider that works 2 days or less per week. Licensee must have a minimum of one (1) Full Time provider in a practice. If the Provider increases\n" +
    "    the number of days worked, Licensee will be required to purchase a full time provider license and pay the increase in license fees. Non-provider licenses\n" +
    "    for Part Time providers shall be charged at $100/user per month. Dental EMR may conduct an audit at any time, and if the provider is found to be working\n" +
    "    more than 2 days per week then the Licensee must purchase the full time license and will incur a 10% penalty on both the license fee, support and\n" +
    "    maintenance. All fees / penalties will be retroactive. If the practice has Part Time providers only, then the first Part Time provider will be considered\n" +
    "    as a Full Time Provider.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Non-Provider License\"</strong>\n" +
    "    means a limited license for any personnel employed by or under contract with Licensee who do not have a National Provider Identifier (NPI), including but\n" +
    "    not limited to office managers, nurses, secretaries, or other administrative staff.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"System\"</strong>\n" +
    "    means the Licensee's computer system in which Programs are installed, including allied Equipment, programs, and related optional software, hardware and or\n" +
    "    auxiliaries.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Updates\"</strong>\n" +
    "    means any changes, enhancements or modifications to a Program, documentation, services, and materials that are provided by Dental EMR to Licensee. The\n" +
    "    determination of whether or not to issue a change, enhancement or modification or to designate any change, enhancement or modification as an Update shall\n" +
    "    be, in each case, made solely by Dental EMR. Updates do not include new Products, materials, services, documents or major enhancements and or early release\n" +
    "    versions of any new or existing products and services.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"User\" or \"End User\"</strong>\n" +
    "    means you and any other user of the Programs and Services authorized by you. \"User Manuals\", means the documentation, and materials that accompany any\n" +
    "    Program and are\n" +
    "</p>\n" +
    "<p>\n" +
    "    published and distributed by or on behalf of Dental EMR to the Licensee from time to time. \"Workforce\" means employees, agents and independent contractors.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Website\"</strong>\n" +
    "    means the website accessible from the URL http://www.DentalEMR.com or other Dental EMR domains.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>\"Your Health Information\"</strong>\n" +
    "    means health information that you or your Workforce or other Users enter into the Dental EMR Programs.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong> \"Your Site\"</strong>\n" +
    "    means the location you provided us upon registration, and such other location or locations as we may approve from time to time.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>2) </strong>\n" +
    "    <strong>License:</strong>\n" +
    "    Dental EMR grants and Licensee accepts a non-exclusive, non-transferable, limited license for the registered users to access and use the functionality of\n" +
    "    Dental EMR Programs and Services during the term, subject to your full compliance with the terms and conditions set forth in this Agreement and with our\n" +
    "    Policies and Procedures. The Licensee shall not permit any other person or entity to access or use the Programs. The Programs shall be used with Equipment\n" +
    "    comparable in operation to the recommended operating system, hardware types and network settings and peripherals as recommended by Dental EMR from time to\n" +
    "    time.\n" +
    "</p>\n" +
    "<p>\n" +
    "    The Licensee acknowledges and agrees that the Programs and other Dental EMR Materials are licensed solely for the internal use of Licensee organization in\n" +
    "    clinical operations and administration in the ordinary course of business in the United States of America. The Licensee may not use the Programs or other\n" +
    "    Dental EMR Materials for any other purposes, such as use to provide data processing services to other health care organizations, integrate with other third\n" +
    "    party solutions or services or embed, include additional services in any circumstances without prior written authorization from Dental EMR and or cause to\n" +
    "    exhibit software, programs, documentation or materials. Additionally, You will not: (a) use the Dental EMR Programs and Services or any portion thereof for\n" +
    "    time-sharing, rental or service bureau purposes including without limitation use methods, applications, techniques, efforts to develop functionally similar\n" +
    "    Software and or workflows/design/functionalities or permit any third party to do any of the foregoing. You agree to not grant access to any Third party for\n" +
    "    any purpose whatsoever without the prior written consent of Dental EMR; (b) make the Programs and Services, in whole or in part, available to any other\n" +
    "    person, entity or business; (c) sell, sublicense, lease, permit, transfer, copy, reverse engineer, decompile or disassemble the Programs and Services, in\n" +
    "    whole or in part, or otherwise attempt to discover the source code to the software used in the Programs; or (d) modify, alter, integrate, combine the\n" +
    "    Programs and Services or associated software with any other software or services not provided or approved by us. You have and will obtain no rights to the\n" +
    "    Programs and Services except for the limited rights to use the Programs and Services expressly granted by this Agreement.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Except as expressly provided herein, each Sub-licensed Program may be used solely with, or as part of, for the purpose of running the Program(s) and for no\n" +
    "    other purpose. In addition to the terms and conditions set forth in herein, the use by the Licensee of each Sublicensed Program shall be governed by the\n" +
    "    additional terms and conditions applicable to such Sub-licensed Program set forth herein. The Programs may only be used by the Licensee in the territory of\n" +
    "    the United States of America, unless otherwise agreed upon in writing by the mutual consent of the parties to this Agreement.\n" +
    "</p>\n" +
    "<p>\n" +
    "    The Programs and Services include certain third party software and services, which may require that you enter into separate subscription or licensing\n" +
    "    agreements with third party vendors. You agree to execute such agreements as may be required for the use of such software or services, and to comply with\n" +
    "    the terms of any license or other agreement held by us, relating to third party products included in the Dental EMR Programs and Services.\n" +
    "</p>\n" +
    "<p>\n" +
    "    The parties may add to this Agreement the license of New Programs, or Programs for installation on additional file servers by execution of additional\n" +
    "    Program Reference Schedule and payment by the Licensee of the Subscription fees and other additional subsequent fees as may be agreed upon by both the\n" +
    "    parties. All such amended or additional schedules shall be executed by the Licensee and delivered to Dental EMR, and shall become effective upon acceptance\n" +
    "    and execution by Dental EMR upon the payment of initial and additional fees.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>3) </strong>\n" +
    "    <strong>Hosting and Access Control:</strong>\n" +
    "    Subject to the terms and conditions of this Agreement, signing up for the ASP version of the Programs, Dental EMR will: (a) make the Hosted Applications\n" +
    "    and allied services available to Licensee via the Internet on an ASP basis; (b) make the Documentation for the Hosted Applications available to Licensee;\n" +
    "    and (c) provide to Licensee a URL, user name, password and other information required to use the Application.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Licensee will not on a unilateral basis withhold, deny, delay or interrupt Dental EMR's access to the hosted Servers and/or application. Licensee shall use\n" +
    "    the Dental EMR Applications for lawful purposes and in compliance with applicable laws. Licensee shall be responsible for all uses of Dental EMR Programs\n" +
    "    by its Users regardless of whether such use is authorized or not by the Licensee or Dental EMR.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Dental EMR Applications are provided for use in conformance with the terms and conditions hereof. If Dental EMR becomes aware of possible violations,\n" +
    "    Dental EMR may initiate an investigation including gathering information from Licensee and examination of material and the data logs.\n" +
    "</p>\n" +
    "<p>\n" +
    "    During the investigation, Dental EMR in its sole and absolute discretion, may suspend access to Dental EMR Programs and/or remove the content or other\n" +
    "    material. If Dental EMR determines, in its sole discretion, that a violation of this Agreement has occurred, it may take responsive action, including\n" +
    "    without limitation, permanent removal of the content on Dental EMR Programs, or any portion thereof, along with issuance of warnings to Licensee and/or\n" +
    "    suspension/termination of this Agreement and application access.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>a.</strong>\n" +
    "    Licensee shall be solely responsible for: (i) procuring, at its expense, the necessary environment at the Licensee's location(s) to use the Hosted\n" +
    "    Applications via the Internet or otherwise, including, without limitation, all computer hardware, software and equipment, Internet access and\n" +
    "    telecommunications services (collectively, the \"Licensee Systems\"); (ii) complying with all laws, rules and regulations related to the Licensee's use of\n" +
    "    its Systems and the licensed Programs and services hereunder; (iii) keeping its user name and password secret and confidential, and, for any communications\n" +
    "    or transactions that are made, using the same; (iv) changing its user name and password if it believes that the same has been stolen or might otherwise be\n" +
    "    misused; (v) maintain recommended information security tools, technologies, fire walls, antivirus, spy wares, etc. and other technical and administrative\n" +
    "    precautions to preserve and protect the protected health information (PHI); (vi) obligations under any third party agreements to which Licensee is a party,\n" +
    "    including, without limitation, any agreement pursuant to which Licensee procures the Licensee Systems or any portion thereof, regardless of whether Dental\n" +
    "    EMR provides Licensee with any assistance in such procurement; (vi) acquiring adequate system know-how in order to correctly use Programs and Services and\n" +
    "    follow Dental EMR and its Licensors' verbal and written guidelines, policies, education material or procedures in regard to the use of Programs and\n" +
    "    Services as well as recommended workflows related thereto.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>b.</strong>\n" +
    "    Permitted Uses: Subject to the terms of this Agreement, we authorize you to access and to use the Programs and Services for treatment and for obtaining\n" +
    "    payment for treatment, and for other purposes expressly authorized in our Policies and Procedures and or other communication from us in writing; provided\n" +
    "    that, except as expressly authorized in our Policies and Procedures, (i) you may access only information pertaining to individuals with whom you have a\n" +
    "    treatment relationship or for whom a provider who has a treatment relationship with has requested a professional consultation from you, or from whom you\n" +
    "    have received authorization to use their health information; and (ii) you may use only the minimum necessary information for payment purposes. You agree\n" +
    "    that you will not access or use the Programs and Services for any other purposes. In particular:\n" +
    "</p>\n" +
    "<p>\n" +
    "    i. You will not reproduce, publish, or distribute content in connection with the programs and services that infringes any third party's trademark,\n" +
    "    copyright, patent, trade secret, publicity, privacy, or other personal or proprietary right; &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    ii. You will comply with all applicable laws, including laws relating to maintenance of privacy, security, and confidentiality of patient and other health\n" +
    "    information and the prohibition on the use of telecommunications facilities and other mediums to transmit illegal, obscene, threatening, libelous,\n" +
    "    harassing, or offensive messages, or otherwise unlawful material; &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    iii. You will not: (a)abuse or misuse the Programs or the Services, including gaining, facilitating or attempting to gain unauthorized access to the\n" +
    "    programs or services; altering or destroying information in the programs except in accordance with accepted practices; (b) use the Programs or Services in\n" +
    "    such a manner that interferes with other Users' use of the System; or (c) use the Programs or the Services in any manner that violates this Agreement, our\n" +
    "    guidelines or those of concerned third parties and or our Licensors and or our Policies and Procedures. You are solely responsible for requesting and\n" +
    "    obtaining the relevant guidelines on your own. &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    iv. You acknowledge and agree that the programs and services, materials, and subscription/access provided hereunder or any other agreement or addendum\n" +
    "    thereof by Dental EMR or its licensors are not intended to be used as diagnostic tools or to provide medical diagnoses or determinations and the Licensee\n" +
    "    and its authorized users accept all the risk and are solely responsible for using due care and exercising their independent professional judgment with\n" +
    "    regard to patient examination, diagnosis, and treatment. &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    v. You will be responsible for ensuring that your authorized users follow proper procedures required by law and by good professional medical and data\n" +
    "    handling practice with regard to the form of patient records, the creation and storage of backup copies of computerized patient records, consents to treat\n" +
    "    or disclose, and use of release of data. You acknowledge that in the event that license fees are not paid within thirty (30) days of when due, without\n" +
    "    limitation of Dental EMR rights to take actions, the licensed programs may automatically convert to read only mode until the delinquent license fees\n" +
    "    together with Dental EMR's standard late payment fees and reconnect charges are paid to Dental EMR. Dental EMR further reserves the right to disable the\n" +
    "    Licensee's read-only access to the Dental EMR products, materials and services in case of continued default of payment within a period of 15 days\n" +
    "    thereafter.\n" +
    "</p>\n" +
    "<p>\n" +
    "    vi. You acknowledge and agree that You are solely responsible for ensuring that each authorized user is aware of the material terms of this agreement, and\n" +
    "    that no person who is not an authorized user be allowed access to the Dental EMR programs, related documents, and training materials etc. Authorized Dental\n" +
    "    EMR resellers are not authorized to execute any agreement on behalf of Dental EMR or otherwise bind or commit Dental EMR in any respect. Any agreement so\n" +
    "    executed on behalf of Dental EMR will be null and void.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>c. Safeguards: </strong>\n" +
    "    <strong></strong>\n" +
    "</p>\n" +
    "<p>\n" +
    "    i. You will be solely responsible to implement and maintain appropriate administrative, physical and technical safeguards to protect information within the\n" +
    "    Programs from unauthorized access, use or alteration or using a User ID assigned to you or a member of your Workforce. Such safeguards shall comply with\n" +
    "    federal, state, and local requirements, including the Privacy Rule and the Security Rule, whether or not you are otherwise subject to HIPAA. You will\n" +
    "    maintain appropriate security with regard to all personnel, systems, and administrative processes used by you or members of your Workforce to transmit,\n" +
    "    store and process electronic health information through the use of the Programs and Services. &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    ii. <u>Compliance</u>: You will immediately notify us of any breach or suspected breach of the security of the Programs and Services of which you become\n" +
    "    aware, or any unauthorized use or disclosure of information within or obtained from the Programs and Services, and you will take such action to mitigate\n" +
    "    the breach or suspected breach as we may direct, and will cooperate with us in investigating and mitigating the breach. &#8232;You will comply with the\n" +
    "    terms of this Agreement, our Policies and Procedures, guidelines, including third party policies and procedures as applicable to you, and all applicable\n" +
    "    laws, rules and regulations. You will be solely responsible for the use of the Programs and Services by you and your Workforce, and shall indemnify us and\n" +
    "    hold us harmless from any claim, cost or liability arising from such use, including reasonable attorneys' fees. &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    iii. <u>User Identification</u>: We authorize you and your Authorized Workforce to use the User IDs assigned to you by us. You acquire no ownership rights\n" +
    "    in any User ID, and User IDs may be revoked or changed at any time in our sole discretion. You will adopt and maintain reasonable and appropriate security\n" +
    "    precautions for User IDs to prevent their disclosure to or use by unauthorized persons. Each member of your Authorized Workforce shall have and use a\n" +
    "    unique identifier. You will use your best efforts to ensure that no member of your Workforce uses a User ID assigned to another person. &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    iv. <u>No Third party Access</u>: Except as required by law, you will not permit any third party (other than your Authorized Workforce) to have access to\n" +
    "    the Programs and or Services without our prior written agreement. You will promptly notify us of any order or demand for compulsory disclosure of health\n" +
    "    information if the disclosure requires access to or use of the Programs and Services. You will cooperate fully with us in connection with any such demand.\n" +
    "    &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    v. <u>Your Workforce</u>: You may permit your authorized Workforce to use the Programs and Services on your behalf, subject to the terms of this Agreement.\n" +
    "    You will obtain a unique User ID from us for each member of your Authorized Workforce; train all members of your Authorized Workforce in the requirements\n" +
    "    of this Agreement and the guidelines and Policies and Procedures relating to their access to and use of the Programs and Services, and ensure that they\n" +
    "    comply with such requirements; take appropriate disciplinary action against any member of your workforce who violates the terms of this Agreement or the\n" +
    "    guidelines, Policies and Procedures; ensure that only you and your Authorized Workforce access the Programs and Services from Your Site; immediately notify\n" +
    "    us of the termination of employment of any member of your Authorized Workforce, or of your withdrawal of authorization for any such person to access the\n" +
    "    Programs and Services. &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    vi. <u>Compliance with Law</u>: You are solely responsible for ensuring that your use of the Programs and Services (including making health information\n" +
    "    available through the Programs and Services) complies with applicable law. You will not undertake or permit any unlawful use of the Programs and Services,\n" +
    "    or take any action that would render the operation or use of the Programs and Services by us or any other User unlawful. We offer no assurance that your\n" +
    "    use of the Programs and Services under the terms of this Agreement will not violate any law or regulation applicable to you. &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    vii. <u>Professional Responsibility</u>: You will be solely responsible for the professional, advisory, analytical and technical services you provide. We\n" +
    "    make no representations concerning the completeness, accuracy, availability or utility of any information in the Programs and Services, or concerning the\n" +
    "    qualifications or competence of individuals who placed it there. We have no liability for the consequences to you or your patients of your use of the\n" +
    "    Programs or Services. &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    viii. <u>Cooperation</u>: You will cooperate with us in the administration of the Programs and Services, including providing reasonable assistance in\n" +
    "    evaluating the Programs and Services collecting and reporting data requested by us for purposes of administering the Programs and Services. &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>4) </strong>\n" +
    "    <strong>Term of License:</strong>\n" +
    "    The term of license for Dental EMR program shall commence from the effective date provided under the Subscription Proposal. Either party can give notice of\n" +
    "    non-renewal, at least 90 days before the expiration of the first term, at its sole or absolute discretion, without cause and without stating any reason\n" +
    "    thereof. If Licensee fails to give notice of non-renewal, Licensee shall be deemed to have agreed to the applicable renewal rate reflected through the\n" +
    "    monthly invoice, for the next fee term.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Licensee may terminate this license on a 60 (sixty) days written notice if Dental EMR materially breaches any provisions of this Agreement, and such breach\n" +
    "    has not been cured after notice of the same within such 60 day period, and not otherwise.\n" +
    "</p>\n" +
    "<p>\n" +
    "    i. <u>Modification</u>: We may change the Programs and Services by providing you not less than thirty (30) days' notice, and the terms under which they are\n" +
    "    provided to you (including terms set forth in this Agreement) at any time.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Upon receipt of such a notice or notification of the change in the terms of the Agreement electronically provided within the Programs or in writing you may\n" +
    "    terminate this Agreement by giving written notice to us on or before the effective date of the change. You agree that your failure to give notice of\n" +
    "    termination prior to the effective date of the change in Programs and Services or acceptance of the change in the terms and conditions of this Agreement by\n" +
    "    pressing 'I Agree' Button in the Dental EMR Programs or receipt of the notice of the change in the terms and not objecting to the same within 2weeks\n" +
    "    constitute acceptance of the change, which shall thereupon become part of this Agreement.\n" +
    "</p>\n" +
    "<p>\n" +
    "    ii. <u>Termination, Suspension or Amendment as a Result of Government Regulation</u>: Notwithstanding anything to the contrary in this Agreement, we have\n" +
    "    the right, on notice to you, immediately to terminate, suspend, or amend this Agreement, without liability: (a) to comply with any order issued or proposed\n" +
    "    to be issued by any governmental agency; (b) to comply with any provision of law, any standard of participation in any reimbursement program, or any\n" +
    "    accreditation standard; or (c) if performance of any term of this Agreement by either Party would cause it to be in violation of law, or would jeopardize\n" +
    "    its tax-exempt status. &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    iii. <u>Judicial or Administrative Procedures</u>: We may terminate this Agreement immediately upon notice to you if: (a) you are named as a defendant in a\n" +
    "    criminal proceeding for a violation of federal or state law; (b) a finding or stipulation that you have violated any standard or requirement of federal or\n" +
    "    state law relating to the privacy or security of health information is made in any administrative or civil proceeding; or (c) you are excluded from\n" +
    "    participation in a federal or state health care program. &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    iv. <u>Insolvency or Bankruptcy</u>: Licensor shall also have the right to immediately terminate the license if the Licensee discontinues business, or\n" +
    "    becomes insolvent, or if any action relating to the bankruptcy or insolvency of the Licensee is instituted. In such an event, Dental EMR shall be nominated\n" +
    "    as the 'first creditor' with regards to the allocation of the Licensee's assets and any recovery thereof. &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>Suspension of Access</strong>\n" +
    "    : We may suspend access to the Programs or the Services by you or any member of your Workforce immediately pending your cure of any breach of this\n" +
    "    Agreement, or in the event we determine in our sole discretion that access to or use of the Programs or Services by you or the member of your Workforce may\n" +
    "    jeopardize the Programs or Services or the confidentiality, privacy, security, integrity or availability of information within the Programs and or\n" +
    "    Services, or that you or the member of your Workforce has violated or may violate this Agreement or our Policies and Procedures, or has jeopardized or may\n" +
    "    jeopardize the rights of any third party, or that any person is or may be making unauthorized use of the Programs and or Services with any User ID assigned\n" +
    "    to you or a member of your Workforce. We may terminate the access of any member of your Authorized Workforce upon termination or change in status of his\n" +
    "    employment with you. Our election to suspend the Services shall not waive or affect our rights to terminate this Agreement as permitted under this\n" +
    "    Agreement.\n" +
    "</p>\n" +
    "<p>\n" +
    "    This Agreement and the rights granted are effective until terminated. Licensee's rights under this Agreement will terminate automatically without notice\n" +
    "    from Dental EMR if Licensee breaches any terms of this Agreement. Sections which by their terms contemplate survival will survive any termination of this\n" +
    "    Agreement.\n" +
    "</p>\n" +
    "<p>\n" +
    "    As explained earlier, Dental EMR reserves the right to update the terms of this Agreement from time to time without notice and acceptance to the updated\n" +
    "    agreement shall be secured either electronically or on paper at the sole discretion of Dental EMR. The latest copy of the Agreement may be downloaded from\n" +
    "    within the Dental EMR Programs at any time or may be requested via fax or email.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Upon the effective date of termination of this Agreement, for any reason, the Licensee shall promptly:\n" +
    "</p>\n" +
    "<p>\n" +
    "    (i) Return and deliver to Dental EMR all the Dental EMR Materials, documents, and manuals; (ii) Discontinue use of Dental EMR Programs, subscription and\n" +
    "    services; destroy copies of programs, materials, documents and manuals on the Licensee's System; (iii) Immediately render all sums and payments for all\n" +
    "    billed and un-billed invoices due and owing to Dental EMR the fees for the balance of the Term upon termination; (iv) In the event of termination for any\n" +
    "    default or breach by the Licensee, pay to Dental EMR all expenses incurred by Dental EMR in the form of damages, additional costs and legal expenses,\n" +
    "    including reasonable attorney and expert fees; (v) Remove all software provided under this Agreement from your computer systems, cease to have access to\n" +
    "    the Programs and or Services, and return to us all hardware, software and documentation provided by or on behalf of us.\n" +
    "</p>\n" +
    "<p>\n" +
    "    The foregoing rights and remedies of Dental EMR shall be cumulative without limiting any other additional rights and remedies available to Dental EMR at\n" +
    "    law, in equity or otherwise. If this Agreement is terminated for any reason, the rights of the Licensee arising under the Agreement shall terminate with\n" +
    "    immediate effect but:\n" +
    "</p>\n" +
    "<p>\n" +
    "    (i) The obligations of the Licensee (other than those set forth in Sections 5 and other applicable sections of this Agreement); (ii) Any liability for\n" +
    "    unpaid license fees as well as any breach by the Licensee of any term or provision of this Agreement arising on or prior to the date of such termination,\n" +
    "    shall survive such termination.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <u>Return of your Practice Data</u>\n" +
    "    : If you decide to leave Dental EMR service, upon your request and at your expense, Dental EMR will make the copy of its database available to you. Unless\n" +
    "    you demand your practices data including patient records within 3 months of termination or expiration of this Agreement by paying the then applicable data\n" +
    "    transfer fees to Dental EMR, Dental EMR will not be liable to maintain such data on its own and shall destroy the data in accordance with the provisions of\n" +
    "    HIPAA. The sole liability and responsibility as to your practice data including patient records rests with you.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <u>Data Access</u>\n" +
    "    : If there are disputes between Dental EMR and you, which have not been resolved through normal notice and cure resolution steps, including payment\n" +
    "    default, we reserve the right to immediately suspend or terminate your access until the default is cured.\n" +
    "</p>\n" +
    "<p>\n" +
    "    DURING THE TERM OF THIS AGREEMENT AS WELL AS AFTER THE EXPIRATION OR TERMINATION HEREOF AND REGARDLESS THE CAUSE OF TERMINATION, YOU AGREE TO INDEMNIFY,\n" +
    "    DEFEND AND HOLD DENTAL EMR HARMLESS FROM AND AGAINST ANY CLAIMS, DAMAGES, COSTS, LOSSES AND EXPENSES THAT YOU OR ANY THIRD PARTY MAY INCUR AS A RESULT OF\n" +
    "    (I) YOUR INABILITY TO CLAIM THE DATA WITHIN 3 MONTHS OF TERMINATION OR EXPIRATION OF THIS AGREEMENT REGARDLESS OF THE CAUSE OF TERMINATION; OR (II)\n" +
    "    SUSPENSION, TERMINATION OR CHANGE OF YOUR ACCESS TO PROGRAMS AND SERVICES.\n" +
    "</p>\n" +
    "<p>\n" +
    "    For purposes of software modifications, improvements and debugging, Dental EMR, its Licensors or agents have the right to enter your database at any time.\n" +
    "    This access to your information will be strictly for the purposes mentioned herein, and in full compliance with HIPAA regulations.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <u>Additional Data Storage Space</u>\n" +
    "    : The basic Dental EMR subscription comes with 5 gigabytes of free space. In case additional Data Storage space is required by you, Dental EMR will provide\n" +
    "    that additional space at an additional charge (Additional Data Storage Fees). Dental EMR reserves the right to implement guidelines concerning Data Storage\n" +
    "    Space and Service, and update those guidelines as needed. Your continued use of the Data Storage Space constitutes your acceptance of the then current\n" +
    "    guidelines.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>5) </strong>\n" +
    "    <strong>License Fees:</strong>\n" +
    "    Beginning on the Effective Date, you will pay directly to Dental EMR the monthly subscription fee in advance as specified in the Dental EMR Proposal. The\n" +
    "    Effective Date of the monthly subscription or yearly maintenance period shall be the day your account is activated through the implementation portal. The\n" +
    "    amount of the License and Subscription Fees and or other Dental EMR service charges may be changed by Dental EMR, from time to time, in accordance with\n" +
    "    Dental EMR's then current general pricing policies for Dental EMR Programs and Services.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Price revisions will be notified through the advance monthly invoices. In case Licensee does not agree to such revisions, Licensee may return the invoice\n" +
    "    marked \"not acceptable\" to initialize the early termination as per the termination procedure given herein.\n" +
    "</p>\n" +
    "<p>\n" +
    "    The fee for subscribing to the Services (\"Subscription Fee\") is set forth above. The Subscription or License Fees shall be determined on the basis of the\n" +
    "    number of Registered Providers authorized to use the Product, each of whom shall be registered (each, a \"Registered User.\") The Products may contain\n" +
    "    embedded controls limiting user log-on to the number of Registered Providers and such counters may interfere with use of the Products beyond the number of\n" +
    "    Registered Providers licensed. Office managers, secretaries and Nurses (not Nurse Practitioners) practicing directly in conjunction with a licensed\n" +
    "    provider do not require a separate license. The amounts payable shall be due and payable on the date specified in this Agreement or if not specified then\n" +
    "    within thirty (30) days of receipt of invoice therefore and payment must be made in U.S. Dollars. Dental EMR will assess Licensee a late payment charge on\n" +
    "    any amount which remains unpaid thirty (30) days after it is due, computed at the rate of one and one-half percent (11&#8260;2%) per month or the highest\n" +
    "    allowable by law, whichever is lower, on the unpaid amount for every month the amount remains unpaid. All payments will be made without setoff,\n" +
    "    counterclaim, recourse or other defense. Nothing mentioned herein will limit any additional rights and remedies available to Dental EMR at law, in equity\n" +
    "    and / or otherwise arising due to the default of payment by the Licensee. Additional third party programs, network access, connectivity solutions,\n" +
    "    subscription services, tools, knowledge bases, data bases and libraries, etc. whether provided separately or within Dental EMR Program will be subject to\n" +
    "    additional charges, and their use shall be subject to the acceptance of their individual terms and conditions by the Licensee which will be communicated to\n" +
    "    Licensee from time to time. A reconnection fee equal to one (1) month's Subscription Fee shall be charged to re- establish connection after termination due\n" +
    "    to non-payment.\n" +
    "</p>\n" +
    "<p>\n" +
    "    If Licensee adds one or more Registered Users to its practice, the software counters shall be adjusted to permit such Registered Users to use the Software,\n" +
    "    upon payment of additional License Fees at the rate specified herein, or the then-current rate, including any Support and Maintenance fees calculated at\n" +
    "    the then-current rate for additional providers, and pro-rated for the applicable portion of the year in which the provider(s) is added. If Licensee loses\n" +
    "    one or more Registered Users, there shall be no change in the\n" +
    "</p>\n" +
    "<p>\n" +
    "    License Fees.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Licensee acknowledges and agrees that there shall be no refunds under this Agreement for any reason, any service delivered or scheduled to be delivered,\n" +
    "    whatsoever, including termination of this Agreement regardless of the cause of such termination. In case Licensee requests early termination for any reason\n" +
    "    whatsoever, Licensee shall be liable for all past due payments along with $6000 per 1st provider; $3500 per each additional full time billing provider,\n" +
    "    $2000 per each part time or non-billing provider. In addition, the Client shall be liable to pay $100 per provider every month for the remaining\n" +
    "    contractual term.\n" +
    "</p>\n" +
    "<p>\n" +
    "    This will cover all internal and third party costs that Dental EMR incurred for the creation and functioning of Licensor's account for the agreed upon term\n" +
    "    hereof, and other out of the pocket expenses related thereto.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Licensee also agrees to pay, at our then current rates, for all additional products, features, or services that Licensee requests from Dental EMR. Licensee\n" +
    "    also agrees to pay, at our then current rates, for all products, features or services that Licensee requests from Dental EMR and that are not included in\n" +
    "    our standard product and services (\"Miscellaneous Charges\"). Dental EMR will notify Licensee of the applicable Miscellaneous Charges before performing\n" +
    "    services or providing features and or products to which a Miscellaneous Charge will apply. The Miscellaneous Charges may change from time to time. Current\n" +
    "    fees and charges may be obtained by calling support@DentalEMR.com\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>6) </strong>\n" +
    "    <strong>Bug fixes and Updates:</strong>\n" +
    "    So long as the subscription for a Program is in effect, subject to the timely payment of Dental EMR fees and dues, the Licensee will be entitled to receive\n" +
    "    bug fixes available to other Dental EMR customers for that program version. Dental EMR may issue Updates/upgrades from time to time and will specify, in\n" +
    "    its sole discretion any costs that may be associated with the updates so issued. Updates, if issued, may apply to selected Programs, modules, features, or\n" +
    "    platforms, as may be specified by Dental EMR from time to time.\n" +
    "</p>\n" +
    "<p>\n" +
    "    In case of payment default, the issuance of updates may be stopped and Dental EMR may specify any other service charges in addition to the clearance of\n" +
    "    outstanding subscription/service charges, financial charges etc., and there may be additional charges for the resumption of services and updates. Major\n" +
    "    product updates requiring migration of key business processes or data elements or templates and/or other configuration will be charged as per the standard\n" +
    "    development rates by Dental EMR. Dental EMR shall retain sole editorial discretion with respect to the licensed programs and services and the information\n" +
    "    contained therein and retain the right to revise, supplement or discontinue the licensed programs and services or any portion thereof or information\n" +
    "    contained therein.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>7) </strong>\n" +
    "    <strong>Support Services:</strong>\n" +
    "    Dental EMR shall provide Licensees with telephone support for the Software during business hours, which are 7:00 a.m. to 8:00 p.m. Eastern Standard Time,\n" +
    "    Monday through Friday, excluding <strong>holidays</strong> (the \"Business Hours\") subject to: (i) timely payment of Dental EMR invoices and support fees,\n" +
    "    and (ii) Licensee's compliance with its obligations under this Agreement. Upgrades, maintenance and phone support is provided for Client hosted\n" +
    "    installations for a 20% charge of the accumulative license fee of all Providers plus the base system cost. Extended support shall be available at the\n" +
    "    request of the Licensee at the rate of $250 per hour. Licensee agrees that all timings and costs, specified herein, may be changed at the discretion of\n" +
    "    Dental EMR without any prior notice to the Licensee.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>8) </strong>\n" +
    "    <strong>Communication:</strong>\n" +
    "    Licensee will obtain, and at all times maintain, a TeamViewer and phone support is provided for Client hosted installations for a 20% charge of the\n" +
    "    accumulative connection and other peripherals/communication platforms, as may be required by Dental EMR, to initiate communications with, and receive\n" +
    "    communications from, Dental EMR. Licensee will arrange for such Programs and or peripherals/communication platforms to connect with Dental EMR system when\n" +
    "    required. Licensee authorizes Dental EMR and its staff to send and receive electronic communications through such communication platforms for the following\n" +
    "    purposes:\n" +
    "</p>\n" +
    "<p>\n" +
    "    (i) To send or receive e-mail communications; (ii) To modify or provide Updates to any software Program; (iii) To maintain the Accounting Log; (iv) To\n" +
    "    perform maintenance or support services; (v) To verify the list of Dental EMR Authorized Users; (vi) To monitor functionality of the Programs; (vii) In\n" +
    "    each case with prior consent of the Licensee, to retrieve Reference Information (as hereinafter defined).\n" +
    "</p>\n" +
    "<p>\n" +
    "    All charges, such as for example, installation, usage, maintenance and outgoing toll charges for the modem telephone line and above mentioned communication\n" +
    "    platforms shall be at Licensee's expense.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>9) </strong>\n" +
    "    <strong>System Monitoring, Compliance, Taxes and other Guidelines:</strong>\n" +
    "    Dental EMR reserves the right to monitor the System electronically from time to time and to access and disclose any information as permitted or required by\n" +
    "    applicable laws or regulation, to operate its System properly, or to protect itself or others. It is not Dental EMR's intention that the Services, System\n" +
    "    or Dental EMR's facilities be used in contravention of the Communications Decency Act of 1996, 47 U.S.C. Section 223, or any other applicable law.\n" +
    "</p>\n" +
    "<p>\n" +
    "    It is Licensee's responsibility to ensure that its usage of Dental EMR at all times remains compliant with all applicable Federal, and State laws, rules\n" +
    "    and regulations. Licensee shall indemnify and defend Dental EMR for any claims, suits, losses or actions against Dental EMR arising from, related to or in\n" +
    "    connection with any violation by Licensee of the Communications Decency Act, and other applicable State, Federal law, rules and regulations.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Licensee agrees to use or disclose any Individually Identifiable Health Information (IIHI) obtained or sent through the licensed programs and services\n" +
    "    including without limitation Surescripts System and or other third party programs, components and services only in a manner consistent with all Applicable\n" +
    "    Law, including HIPAA and including obtaining any consents or authorizations required to be obtained by such Applicable Law, and that all consents and\n" +
    "    authorizations will allow disclosure of all data elements transmitted through the Surescripts System whether or not Licensee intends to utilize such data\n" +
    "    elements.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Licensee will under no circumstances use or allow any use of any data accessed by the Licensee through the Surescripts System other than for the specific\n" +
    "    purposes identified below:\n" +
    "</p>\n" +
    "<p>\n" +
    "    i. Patient visit services may be accessed by the Licensee only in connection with the treatment of a specific patient in a scheduled or walk-in outpatient\n" +
    "    visit or another specific treatment event. Licensee shall not access or attempt to access these services in connection with any inpatient or other acute\n" +
    "    service or in connection with any institutional service. &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    ii. Medication history transaction allows Licensee to request medication history for a specific patient utilizing the National Council for Prescription\n" +
    "    Drug Programs (\"NCPDP\") transaction segment syntax then implemented by Surescripts. &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    Licensee will allow Dental EMR and/or its licensors including without limitation Surescripts to access, inspect and audit records of the Licensee relating\n" +
    "    to the use of the licensed programs and services including Surescripts System, Surescripts Data and data or information provided by Participants\n" +
    "    (Participants means the data sources, pharmacy benefit manager, health benefit payor or administrator, prescribers health care providers or facilities,\n" +
    "    pharmacies, information system vendors, or other entities, each of which has entered into a written agreement with Surescripts, or has the right through\n" +
    "    another entity's written agreement with Surescripts, to access, provide or communicate information through the Surescripts System.)\n" +
    "</p>\n" +
    "<p>\n" +
    "    In the event that Dental EMR grants Licensee permission to modify any of the Dental EMR Programs, then Licensee assumes all liability for such modified\n" +
    "    programs. Licensee hereby acknowledges and agrees that Dental EMR disclaims all warranties, express or implied, regarding any Licensee-modified programs.\n" +
    "</p>\n" +
    "<p>\n" +
    "    All charges and fees shall be exclusive of all federal, state, municipal, or other government excise, sales, use, occupational, or like taxes now in force\n" +
    "    or enacted in the future, and you agree to pay any tax (excluding taxes on our net income) that we may be required to collect or pay now or at any time in\n" +
    "    the future and that are imposed upon the sale or delivery of items and services purchased under this Agreement.\n" +
    "</p>\n" +
    "<p>\n" +
    "    In case of Licensee signing up for any additional feature, product or service with Dental EMR, the terms and conditions of this Agreement shall prevail and\n" +
    "    be applicable, and an additional addendum for the pricing of that additional feature, product or service shall be signed.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Licensee is solely responsible for any charges that Licensee incurs to use the Products and Services, such as telephone and equipment charges, and fees\n" +
    "    charged by third party vendors of products and services.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>10) </strong>\n" +
    "    <strong>Reference Information &amp; Data Migration:</strong>\n" +
    "    Dental EMR from time to time may request (by letter, facsimile, or electronic communication) to retrieve information as specified in the request including\n" +
    "    but not limited to usage patterns, behaviors, trends, error reports, etc. and other information to help improve product and service quality, use the\n" +
    "    information for staff training, etc. The Licensee shall not unreasonably withhold or delay approval of such requests.\n" +
    "</p>\n" +
    "<p>\n" +
    "    You understand and agree that prior to contacting or allowing Dental EMR to perform clinical or billing data migration from your previous systems and or\n" +
    "    databases or to perform any other services on your computer, it is your responsibility to back-up the data, software, information or other files stored on\n" +
    "    your computer disks and/or drives.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Dental EMR will not be held responsible for any problems arising from third party software installed on your computer by our technicians, you, or other\n" +
    "    third party. Dental EMR will not be held liable for lost data due to hardware failure, virus, spyware, corruption or any other situation. If any problems\n" +
    "    with third party software or hardware arise, it is your responsibly to obtain support from the manufacturer of the product at fault.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Keeping in view the limitations of the online data migration process, in addition to the issues and failures which may result from, including without\n" +
    "    limitation, non-availability of technical support staff at your practice's location; incompatibilities in data formats; already-corrupted data, you\n" +
    "    acknowledge and agree that the migration will be at your sole and exclusive risk. You also acknowledge and agree that Dental EMR assumes no liability or\n" +
    "    responsibility for any loss or damage of any kind whatsoever arising from or related to this migration including without limitation to incorrect, missing,\n" +
    "    lost or corrupted data.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Under no circumstances shall Dental EMR be liable to you or any other person for any damages, including without limitation any indirect, incidental,\n" +
    "    special or consequential damages, expenses costs, profits, lost savings or earnings, lost or corrupted data, or other liability arising out of or related\n" +
    "    to the services provided by Dental EMR or out of the installation, de-installation, migration, use of, or inability to use your computer equipment,\n" +
    "    hardware, peripherals, database, or the network as a result of the services provided hereunder.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>11) </strong>\n" +
    "    <strong>Indemnity:</strong>\n" +
    "    Dental EMR, subject to the limitations on its liability set forth in Section 22 and other applicable sections hereof, shall hold harmless and defend\n" +
    "    Licensee against suits based solely on a claim that the use of licensed program by Licensee in accordance with the terms hereof, infringes on any U.S.\n" +
    "    patent, copyright or trademark, and the use of such program in such manner is prohibited by a court of competent jurisdiction, provided that Licensee gives\n" +
    "    Dental EMR prompt written notice of such suit and gives Dental EMR full authority, information and assistance to defend such suit, and permits Dental EMR\n" +
    "    to control the defense thereof.\n" +
    "</p>\n" +
    "<p>\n" +
    "    However, this indemnity will not apply unless Licensee gives Dental EMR prompt notice of such claim or action alleging such infringement and has given\n" +
    "    Dental EMR full opportunity and sole authority to control the response thereto and the defense thereof, including, without limitation, any agreement\n" +
    "    relating to settlement. Dental EMR shall have no obligation to the extent a claim is based upon:\n" +
    "</p>\n" +
    "<p>\n" +
    "    (i) use of any version of program which is altered by, or at the request of Licensee, if infringement would have been avoided by a current, unaltered\n" +
    "    version; or (ii) combination, operation or use of the program with software and/or hardware not delivered by Dental EMR if such infringement could have\n" +
    "    been avoided by not combining, operating or using of the program with such software and/or hardware.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>Remedial Measures:</strong>\n" +
    "    If the licensed program becomes the subject of a claim, or if Dental EMR reasonably believes that use of licensed program may become the subject of a\n" +
    "    claim, then Dental EMR may do, at its own expense and option, at least one of the following:\n" +
    "</p>\n" +
    "<p>\n" +
    "    (i) procure for Licensee the right to continue use of the licensed program at no additional cost to Licensee for such right; (ii) replace the licensed\n" +
    "    program with a non-infringing product; (iii) modify the licensed program so that it becomes non-infringing; or (iv) terminate Licensee's license to such\n" +
    "    program upon written notice to Licensee, whereupon Licensee shall immediately terminate all further use of the affected licensed program.\n" +
    "</p>\n" +
    "<p>\n" +
    "    In the event of termination, Dental EMR shall have no liability to Licensee or any other third party concerning their use of such Dental EMR program except\n" +
    "    to refund to Licensee a pro rata portion of the License Fees, actually paid to Dental EMR, and applicable to the remaining term of the Agreement.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>No other Remedies Regarding Infringements:</strong>\n" +
    "    THE FOREGOING STATES DENTAL EMR'S ENTIRE LIABILITY AND LICENSEE'S SOLE AND EXCLUSIVE REMEDIES WITH RESPECT TO ANY INFRINGEMENT, ALLEGED INFRINGEMENT OR\n" +
    "    MISAPPROPRIATION OF ANY INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY BY THE LICENSED PROGRAM OR ANY PART THEREOF.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Dental EMR's indemnification obligations under this Section 11 shall not apply in the event that any of the losses are covered by Licensee's\n" +
    "    indemnification obligations set forth under Section 19 hereof.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Dental EMR may from time to time, in user documentation or through other communications, provide the Licensee with recommended procedures for dealing with\n" +
    "    confidentiality of patient records, with patient informed consent in regard to use and maintenance of patient clinical records, and with other matters\n" +
    "    relating to patient information. However, Dental EMR makes no representation or warranty, express or implied, with respect to the legal effect of such\n" +
    "    recommendations, and the Licensee acknowledges that the Licensee and Licensee's Authorized Users are responsible for ensuring that those procedures\n" +
    "    required by law and by good professional medical practice are followed with regard to the copies of patient records, the maintenance of backup copies of\n" +
    "    patient records, consent to treatment or disclosure, and use and release of data. Dental EMR shall not be liable under the foregoing indemnity or\n" +
    "    obligations under Section 10 above for claims arising from failure of the Licensee to fulfill such responsibilities and Dental EMR shall be entitled to\n" +
    "    assume that the Licensee has fulfilled such responsibilities.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>12) </strong>\n" +
    "    <strong>Drug Interaction Tool:</strong>\n" +
    "    (For subscriptions that include The Dental EMR Drug Interaction Tool (the \"Drug Interaction Service\"), a service provided to DentalEMR.com by DoseSpot.\n" +
    "    (\"DoseSpot\")) Through your use of the Drug Interaction Service, you agree to the terms of this section of the Terms and Conditions of Use. Dental EMR and\n" +
    "    DoseSpot/Sure Scripts are not responsible for the results of your decisions resulting from the use of the Drug Interaction Service, including, but not\n" +
    "    limited to, your choosing to seek or not to seek professional medical care, or from choosing or not choosing a specific treatment based upon the Drug\n" +
    "    Interaction Service. DoseSpot/SureScripts and Dental EMR do not guarantee the accuracy, timeliness or completeness of the drug information provided through\n" +
    "    the Drug Interaction Service. Dental EMR and DoseSpot/SureScripts do not endorse drugs, diagnose patients or recommend therapy. The Drug Interaction\n" +
    "    Service is an informational resource designed to assist licensed healthcare providers in caring for their patients and provide consumers with drug-specific\n" +
    "    information. The Drug Interaction Service is not a substitute for the care provided by licensed healthcare providers and you are urged to consult with your\n" +
    "    healthcare provider in all instances. The absence of a warning for a given drug or drug combination in no way should be construed to indicate that the drug\n" +
    "    or drug combination is safe, effective or appropriate. Dental EMR and DoseSpot/Surescripts do not assume any responsibility for any aspect of healthcare\n" +
    "    services administered or not administered with the aid of information the Drug Interaction Service.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>13) </strong>\n" +
    "    <strong>Licensee Information:</strong>\n" +
    "    The Licensee warrants that the Authorized Users information provided to Dental EMR is true and complete and the Licensee will promptly inform Dental EMR\n" +
    "    when such information changes thereafter. The Licensee further represents and warrants that each Authorized User is legally bound as Licensee hereunder.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>14) </strong>\n" +
    "    <strong>Licensee Authority:</strong>\n" +
    "    The Licensee represents and warrants that it has obtained each approval, authorization and consent necessary to enter into this Agreement and perform its\n" +
    "    obligations, comply with the terms and conditions, and engage in the actions contemplated by this Agreement. The Licensee warrants that to the best of its\n" +
    "    knowledge no conflict of interest exists or is likely to arise in the performance of its obligations under the Agreement. The Licensee agrees that for the\n" +
    "    term of this agreement and for all succeeding terms, the Licensee shall not enter into any contractual obligations with any Dental EMR competitors and\n" +
    "    shall refrain from direct communication with Dental EMR's competitors regarding Dental EMR Products and Services, including but not limited to its\n" +
    "    features, performance benchmarks and any other information not publicly available.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Individuals' Rights: You shall be solely responsible for affording individuals their rights with respect to Your Health Information, such as the rights of\n" +
    "    access and amendment. You will not undertake to afford an individual any rights with respect to any information in the Programs and Services other than\n" +
    "    Your Health Information.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>15) </strong>\n" +
    "    <strong>Relationship of Dental EMR and the Resellers:</strong>\n" +
    "    The Licensee agrees and acknowledges the relationship of Dental EMR and its authorized independent resellers (the \"Resellers\") is that of an independent\n" +
    "    contractor. The Resellers do not have any right or authority to bind or assume or create any obligation or responsibility, express or implied, for or on\n" +
    "    behalf of Dental EMR, or in Dental EMR's name. Dental EMR and the Resellers are not partners or joint ventures and their relationship is not one of\n" +
    "    employer and Employee, master and servant, franchiser and franchisee, or principal and agent. The Licensee agrees that Dental EMR is not responsible for\n" +
    "    any act, omission, failure or damage relating to work or any other matter performed by, or on behalf of, any Reseller for the Licensee, any Authorized\n" +
    "    User, or any other Person.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>16) </strong>\n" +
    "    <strong>Title:</strong>\n" +
    "    The Licensee agrees that, as between Dental EMR and the Licensee, Dental EMR shall have sole and exclusive ownership of, and all right, title, and interest\n" +
    "    in and to, the Dental EMR Materials, including the Programs and Material, and all modifications and enhancements of the Programs or User Manuals (including\n" +
    "    ownership of all copyrights and other intellectual property rights), subject only to the rights expressly granted to the Licensee under this Agreement.\n" +
    "    This Agreement does not provide the Licensee with title or ownership of any Dental EMR Material, but only a limited right to use the same solely upon the\n" +
    "    terms expressly set forth in this Agreement.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>17) </strong>\n" +
    "    <strong>Use of Information: </strong>\n" +
    "    <strong></strong>\n" +
    "</p>\n" +
    "<p>\n" +
    "    a. The purpose of the Dental EMR Programs and Services is to store Your Health Information and (i) to &#8232;make it available to you and your Authorized\n" +
    "    Workforce; and (ii) to facilitate the sharing of individuals' health information among Users and Patients. &#8232;You may make Your Health Information\n" +
    "    accessible to other Users through the Programs and Services for these purposes. You authorize us, as your business associate, to use and disclose Your\n" +
    "    Health Information as follows, subject to the recipient's agreement to comply with our and our Licensors Policies and Procedures and with applicable laws\n" +
    "    and regulations relating to the use and disclosure of health information, and subject also to the provisions of Section 18(b). &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    b. We may: (i) permit access to Your Health Information to you, Your Authorized patients and your Authorized Workforce; (ii) permit access to Your Health\n" +
    "    Information by health care providers and their business associates for treatment; (iii) disclose or permit access to your Your Health Information to health\n" +
    "    plans, health care clearinghouses, medical groups, independent practice associations and other parties responsible for payment and their business\n" +
    "    associates for the purpose of obtaining payment for services you provide; (iv) De-Identify Your Health Information, and use and disclose De-Identified\n" +
    "    Information as provided by Section 18 (b) (x); (v) create limited data sets from Your Health Information, and disclose them for any purpose for which you\n" +
    "    may disclose a limited data set; and you hereby authorize us to enter into data use agreements on your behalf for the use of limited data sets, in\n" +
    "    accordance with applicable law and regulation; (vi) aggregate your health information with that of other users, and share aggregated information among\n" +
    "    Users; (vii) use Your Health Information for the proper management and administration of the Dental EMR Programs and Services and our business, and to\n" +
    "    carry out our legal responsibilities; we may also disclose Your Health Information for such purposes if the disclosure is required by law, or we obtain\n" +
    "    reasonable assurances from the recipient that it will be held confidentially and used or further disclosed only as required by law or for the purpose for\n" +
    "    which it was disclosed to the recipient, and the recipient notifies us of any instances of which it is aware in which the confidentiality of the\n" +
    "    information has been breached. Without limiting the foregoing, we may permit access to the Programs and Services by our contracted system developers under\n" +
    "    appropriate confidentiality agreements. (viii) use or disclose Your Health Information for other purposes, as from time to time described in our Policies\n" +
    "    and Procedures; provided that we will not make or permit any such use or disclosure that would violate applicable law or regulation if made by you or your\n" +
    "    business associate. Except as provided in subsection 17(b)(iv) and 17(b)(v), and notwithstanding any other provision of this section, we will not use or\n" +
    "    disclose Your Health Information in any manner that would violate the requirements of the Privacy Rule.\n" +
    "</p>\n" +
    "<p>\n" +
    "    c. <u>Responsibility for Misuse by Other Users</u>: You acknowledge that in granting access to the Dental EMR Programs and Services for the purposes set\n" +
    "    forth in section 17(a) &amp; (b), we will rely on the assurances of the recipients of the information as to (i) their identity and credentials, (ii) the\n" +
    "    purposes for which they are accessing the Dental EMR Programs and Services, and (iii) the nature and extent of the information to which they will have\n" +
    "    access. You acknowledge that, while the Dental EMR Programs and Services will contain certain technical safeguards against misuse of the Dental EMR\n" +
    "    Programs and Services, it will rely to a substantial extent on the representations and undertakings of Users. You agree that we will not be responsible for\n" +
    "    any unlawful access to or use of Your Health Information by any User resulting from the User's misrepresentation to us, or breach of the User's user\n" +
    "    agreement or our Policies and Procedures or guidelines including third party guidelines, policies and procedures as applicable on you and your Workforce or\n" +
    "    Authorized Users.\n" +
    "</p>\n" +
    "<p>\n" +
    "    d. <u>Specially Protected Information</u>: We apply the standards of the Privacy Rule in permitting access to the Dental EMR Programs and Services. You\n" +
    "    acknowledge that other federal and state laws, rules and regulations impose additional restrictions on the use and disclosure of certain types of health\n" +
    "    information, or health information pertaining to certain classes of individuals. You agree that you are solely responsible for ensuring that Your Health\n" +
    "    Information may properly be disclosed for the purposes set forth in section 17(a) &amp; (b), subject only to the restrictions of the Privacy Rule. In\n" +
    "    particular, you will: not make available through the Programs and Services any information subject to any restriction on use or disclosure (whether arising\n" +
    "    from your agreement with the individual or under law), other than the general restrictions contained in the Privacy Rule; obtain any necessary consents,\n" +
    "    authorizations or releases from individuals required for making their health information available through the Programs and Services for the purpose set\n" +
    "    forth in section 17(a) &amp; (b); include such statements (if any) in your notice of privacy practices as may be required in connection with your use of\n" +
    "    the Programs and Services; not place in the Programs any information that the you know or have reason to believe is false or materially inaccurate.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>18) </strong>\n" +
    "    <strong>HIPAA; Business Associate Provisions and De-identified Information: </strong>\n" +
    "    <strong></strong>\n" +
    "</p>\n" +
    "<p>\n" +
    "    a. To the extent required by the Health Insurance Portability and Accountability Act of 1996 and regulations related to privacy promulgated there under\n" +
    "    (the \"Privacy Standard\"), and notwithstanding anything to the contrary herein, Dental EMR will maintain the confidentiality of Protected Health Information\n" +
    "    or PHI as defined by the Privacy Standard, Dental EMR will: not use or further disclose PHI other than as permitted or required by this Agreement or as\n" +
    "    required by law (as such term is defined by the Privacy Standard); use appropriate safeguards to prevent use or disclosure of PHI other than as provided\n" +
    "    for by this Agreement; report to Licensee any use or disclosure of PHI not provided for by this Agreement of which Dental EMR become aware; ensure that any\n" +
    "    agent, including a subcontractor to whom Dental EMR provides PHI received from, or created or received by Licensee on behalf of, Licensee agrees in writing\n" +
    "    to the provisions of this Agreement; mitigate, to the extent practicable, the harmful effect of any use or disclosure of PHI not permitted by this\n" +
    "    Agreement; upon expiration or termination of this Agreement, return to Licensee or destroy all PHI received from, or created or received on behalf of\n" +
    "    Licensee (including all copies thereof) then in Dental EMR possession or under its control; or if, return or destruction is not feasible, provide Licensee\n" +
    "    with written notice in which Dental EMR describes why return or destruction is not feasible and agree in writing to extend the protections of this Section\n" +
    "    to the PHI and limit further uses and disclosures to those purposes that make return or destruction infeasible. Dental EMR agrees that this Agreement may\n" +
    "    be amended from time to time if necessary to comply with HIPAA. The requirements of this Section will survive this Agreement.\n" +
    "</p>\n" +
    "<p>\n" +
    "    b. <u>Business Associate Provisions</u>: In maintaining, using and affording access to Your Health Information in accordance with this Agreement, we will:\n" +
    "</p>\n" +
    "<p>\n" +
    "    i. Not use or further disclose the information except as permitted or required by this Agreement or as required by law; &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    ii. Use appropriate safeguards to prevent use or disclosure of the information other than as provided for by this Agreement, including administrative,\n" +
    "    physical, and technical safeguards that reasonably and appropriately protect the confidentiality, integrity, and availability of the information; &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    iii. Report to you any use or disclosure of the information not provided for by this Agreement of which we become aware, or any security incident as a\n" +
    "    result of which we determines that unauthorized access has been obtained to Your Health Information; &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    iv. Ensure that any of our agents or subcontractors to whom we provide Your Health Information for purposes of assisting us in providing the Programs or\n" +
    "    the Services, agrees to the same restrictions and conditions that apply to us with respect to such information, including the obligation to implement\n" +
    "    reasonable and appropriate safeguards to protect it (it being understood that other Users of the System are not our agents or subcontractors); &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    v. Make available protected health information in accordance with &#164; 164.524 of the Privacy Rule; &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    vi. Make available protected health information for amendment and incorporate any amendments to protected health information in accordance with\n" +
    "    &#164;164.526 of the Privacy Rule; &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    vii. Make available the information required to provide an accounting of disclosures in accordance with &#164; 164.528 of the Privacy Rule; &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    viii. Make our internal practices, books, and records relating to the use and disclosure of protected health information received from, or created or\n" +
    "    received by us on your behalf available to the Secretary of the United States Department of Health and Human Services for purposes of determining your\n" +
    "    compliance with the Privacy Rule; and\n" +
    "</p>\n" +
    "<p>\n" +
    "    ix. At termination of this Agreement, if feasible, return or destroy all protected health information received from, or created or received by us on your\n" +
    "    behalf that we still maintain in any form, and retain no copies of such information; or, if such return or destruction is not feasible, extend the\n" +
    "    protections of this Agreement to the information and limit further uses and disclosures to those purposes that make the return or destruction of the\n" +
    "    information infeasible. You acknowledge that it will likely be infeasible to segregate Your Health Information for removal from the System. However, we\n" +
    "    will provide you with an electronic copy of Your Health Information in the format in which it is produced by our standard procedures for copying or\n" +
    "    archiving such information. You acknowledge that you may have to purchase proprietary software in order to access such information.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <u>De-Identified Information</u>\n" +
    "    : You hereby transfer and assign to us all right, title and interest in and to all De-Identified Information that we make from Your Health Information\n" +
    "    pursuant to Section 17(b) (iv) free of any fee or charge whatsoever. You agree that we may use, disclose, market, license and sell such De- Identified\n" +
    "    Information for any purpose without restriction, and that you have no interest in such information, or in the proceeds of any sale, license, or other\n" +
    "    commercialization thereof. You acknowledge that the rights conferred by this section are the principal consideration for the provision of the Services,\n" +
    "    without which we would not enter into this Agreement.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>19) </strong>\n" +
    "    <strong>Indemnity by Licensee:</strong>\n" +
    "    To the fullest extent permitted by law, you will indemnify, defend and hold harmless Dental EMR, its Licensors and other users as well ours and their\n" +
    "    affiliates and their respective directors, officers, employees, agents and representatives from and against any and all Losses, damages (including but not\n" +
    "    limited to compensatory, consequential, special and punitive damages), demands, claims, costs, penalties, injuries, interest, or expenses (including\n" +
    "    without limitation reasonable attorney fees and costs as incurred by counsel of Dental EMR's choice) howsoever caused, sustained by you, Dental EMR or any\n" +
    "    third party, at all levels of litigation or other proceeding at any time, arising out of or relating to (i) the use, non-use or misuse of the Programs and\n" +
    "    or Services or any portion thereof by you or your Workforce; (ii) any act or omission including a tortuous act as well as breach or alleged breach by you\n" +
    "    or your Workforce of any representations, warranties, obligations, responsibilities or agreements contained in this Agreement; (iii) the modification of\n" +
    "    the Programs and Services or any information contained therein, integration, alteration or the combination of all or part of Programs and or Services with\n" +
    "    any other software, program, product or device that is not expressly permitted under this Agreement, by or at the request of the Licensee, the user or the\n" +
    "    Workforce, regardless of whether or not we gave our consent to or performed such combination, integration, alteration or modification; (iv) any personal\n" +
    "    injury or death sustained by an individual, any third party or another, alleged or otherwise; (iv) Licensee's violation of federal, state or local laws,\n" +
    "    rules or regulations; (v) any act or omission (negligent, willful or otherwise) or misconduct by Licensee, its directors, officers, Workforce, employees,\n" +
    "    contractors, or agents; (vi) the actions of any person gaining access to the Programs and Services under a User ID assigned to you or a member of your\n" +
    "    Workforce; (vii) the use or consumption of Programs and or Services or any part thereof not in strict conformance with Dental EMR or its Licensor's\n" +
    "    guidelines, policies, procedures, recommendations, training/education material; (viii) ignoring standard workflows or following a way other than the\n" +
    "    recommended procedure/workflow or functionality built into the Programs or non-use of a standard or recommended workflow, functionality etc.; (ix) any\n" +
    "    errors or inaccuracies contained in the patient data or practice data as delivered by Licensee to Dental EMR; (x) any medical treatment, diagnosis, or\n" +
    "    prescription rendered by Licensee or its agents (including physicians and healthcare professionals); or (xi) the actions of anyone using a User ID,\n" +
    "    password or other unique identifier assigned to you or any member of your Workforce that adversely affects the Programs and or Services or any information\n" +
    "    accessed or any action performed through the Programs and or Services, provided however, you may not enter into any settlement that would admit any\n" +
    "    wrongdoing by or impose any liability on the part of Dental EMR, or impose any obligation on Dental EMR, without Dental EMR's prior written consent.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>20) </strong>\n" +
    "    <strong>DISCLAIMER:</strong>\n" +
    "    IT IS EXPRESSLY AGREED THAT IN NO EVENT SHALL DENTAL EMR, ITS LICENSORS INCLUDING SURESCRIPTS AS WELL AS DISTRIBUTORS BE LIABLE FOR ANY SPECIAL, DIRECT,\n" +
    "    INDIRECT, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS OR REVENUES, LOSS OF USE, OR LOSS OF INFORMATION OR DATA,\n" +
    "    WHETHER A CLAIM FOR ANY SUCH LIABILITY OR DAMAGES IS PREMISED UPON BREACH OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER\n" +
    "    THEORY OF LIABILITY, EVEN IF WE HAVE BEEN APPRISED OF THE POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES OCCURRING. WE DISCLAIM ANY AND ALL LIABILITY FOR\n" +
    "    ERRONEOUS TRANSMISSIONS AND LOSS OF SERVICE RESULTING FROM COMMUNICATION FAILURES BY TELECOMMUNICATION SERVICE PROVIDERS OR THE SYSTEM.\n" +
    "</p>\n" +
    "<p>\n" +
    "    YOU ACKNOWLEDGE AND AGREE THAT THE FEES AND OTHER CHARGES WHICH DENTAL EMR IS CHARGING UNDER THIS AGREEMENT DO NOT INCLUDE ANY CONSIDERATION FOR ASSUMPTION\n" +
    "    BY DENTAL EMR OF THE RISK OF CLIENT'S DIRECT, INDIRECT, CONSEQUENTIAL OR INCIDENTAL DAMAGES OR OF UNLIMITED DIRECT DAMAGES.\n" +
    "</p>\n" +
    "<p>\n" +
    "    LICENSEE AGREES THAT EACH PROGRAM AND EACH OF THE OTHER DENTAL EMR MATERIALS AND SERVICES AND INFORMATION CONTAINED THEREIN OR PROVIDED THEREWITH ARE\n" +
    "    PROVIDED ON \"AS IS\" AND \"AS AVAILABLE\" BASIS ONLY, WITHOUT WARRANTY OF ANY KIND, AND ALL EXPRESS, IMPLIED OR STATUTORY WARRANTIES, CONDITIONS,\n" +
    "    REPRESENTATIONS, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, TIMELINESS,\n" +
    "    COMPLETENESS, ADEQUACY AND NONINFRINGEMENT OR WARRANTY ARISING OUT OF COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE OR TRADE ARE EXCLUDED BY DENTAL\n" +
    "    EMR. DENTAL EMR DOES NOT WARRANT THAT THE PROGRAMS WILL MEET THE REQUIREMENTS OF ANY PERSON AND OPERATE ON AN UNINTERRUPTED OR ERROR-FREE BASIS. LICENSEE\n" +
    "    IS SOLELY RESPONSIBLE FOR ANY AND ALL ACTS OR OMISSIONS TAKEN OR MADE IN RELIANCE ON THE PROGRAMS AND SERVICES OR THE INFORMATION CONTAINED THEREIN,\n" +
    "    INCLUDING INACCURATE OR INCOMPLETE INFORMATION.\n" +
    "</p>\n" +
    "<p>\n" +
    "    WITHOUT LIMITING THE FOREGOING, DENTAL EMR AND ITS LICENSORS DO NOT WARRANT THE QUALITY, ACCURACY, OR SUITABILITY OF INFORMATION PROVIDED THROUGH THE\n" +
    "    DENTAL EMR PROGRAMS FOR ANY PURPOSE. THE LICENSEE AGREES THAT DENTAL EMR HAS MADE NO AGREEMENTS, REPRESENTATIONS OR WARRANTIES OTHER THAN THOSE EXPRESSLY\n" +
    "    SET FORTH IN THIS AGREEMENT, AND THAT NO FUTURE AGREEMENT, REPRESENTATION OR WARRANTY OF DENTAL EMR WITH REGARD TO PROGRAMS OR SERVICES PROVIDED UNDER THIS\n" +
    "    AGREEMENT SHALL BE EFFECTIVE UNLESS EXPRESSLY STATED IN AN AMENDMENT TO THIS AGREEMENT SIGNED BY BOTH PARTIES.\n" +
    "</p>\n" +
    "<p>\n" +
    "    SURESCRIPTS USES AVAILABLE TECHNOLOGY TO MATCH PATIENT IDENTITIES WITH THEIR PRESCRIPTION DRUG BENEFIT AND PRESCRIPTION DRUG RECORDS IN ORDER TO PROVIDE\n" +
    "    PHYSICIANS WITH PATIENTS' PRESCRIPTION DRUG BENEFIT AND MEDICATION HISTORY INFORMATION. BECAUSE PATIENT INFORMATION IS MAINTAINED IN MULTIPLE PLACES, NOT\n" +
    "    ALL OF WHICH ARE ACCESSIBLE TO SURESCRIPTS, AND BECAUSE NOT ALL PATIENT INFORMATION IS KEPT IN A STANDARD FASHION OR IS REGULARLY UPDATED, IT IS POSSIBLE\n" +
    "    THAT FALSE MATCHES MAY OCCUR OR THAT THERE MAY BE ERRORS OR OMISSIONS IN THE PRESCRIPTION DRUG BENEFIT OR MEDICATION HISTORY INFORMATION. THEREFORE, ANY\n" +
    "    TREATING PHYSICIAN OR OTHER HEALTH CARE PROVIDER OR FACILITY SHOULD VERIFY PRESCRIPTION DRUG BENEFIT OR MEDICATION HISTORY INFORMATION WITH EACH PATIENT\n" +
    "    AND/OR THE PATIENT'S REPRESENTATIVES BEFORE SUCH INFORMATION IS RELIED UPON OR UTILIZED IN DIAGNOSING OR TREATING THE PATIENT. SURESCRIPTS IS NOT A HEALTH\n" +
    "    PLAN, HEALTH CARE PROVIDER OR PRESCRIBER.\n" +
    "</p>\n" +
    "<p>\n" +
    "    DENTAL EMR AND ITS LICENSORS INCLUDING SURESCRIPTS DO NOT AND CANNOT INDEPENDENTLY VERIFY OR REVIEW THE INFORMATION TRANSMITTED THROUGH THE LICENSED\n" +
    "    PROGRAMS AND SERVICES INCLUDING SURESCRIPTS SYSTEM FOR ACCURACY OR COMPLETENESS.\n" +
    "</p>\n" +
    "<p>\n" +
    "    DENTAL EMR AND ITS LICENSORS INCLUDING SURESCRIPTS MAKE NO REPRESENTATION OR WARRANTY REGARDING THE AVAILABILITY THROUGH THE SURESCRIPTS SYSTEM AND OTHER\n" +
    "    LICENSED PROGRAMS AND SERVICES OF ANY PARTICULAR DATA SOURCE OR OTHER PARTICIPANT. AT ANY TIME, DATA SOURCES OR OTHER PARTICIPANTS MAY BE ADDED TO OR\n" +
    "    DELETED FROM THE SURESCRIPTS SYSTEM OR MAY LIMIT LICENSEE ACCESS TO THEIR DATA, AND SUCH CHANGES MAY OCCUR WITHOUT PRIOR NOTICE TO DENTAL EMR OR LICENSEE.\n" +
    "</p>\n" +
    "<p>\n" +
    "    THE LICENSEE ACKNOWLEDGES THAT DENTAL EMR: HAS NO CONTROL OF OR RESPONSIBILITY FOR THE LICENSEE'S USE OF THE SERVICE OR CONTENT PROVIDED THEREON; HAS NO\n" +
    "    LIABILITY TO ANY PERSON FOR ANY DATA OR INFORMATION INPUT ON THE SERVICE BY THE LICENSEE TO THE SERVICE.\n" +
    "</p>\n" +
    "<p>\n" +
    "    CARRIER LINES: YOU ACKNOWLEDGE THAT ACCESS TO THE PROGRAMS AND SERVICES WILL BE PROVIDED OVER VARIOUS FACILITIES AND COMMUNICATIONS LINES, AND INFORMATION\n" +
    "    WILL BE TRANSMITTED OVER LOCAL EXCHANGE AND INTERNET BACKBONE CARRIER LINES AND THROUGH ROUTERS, SWITCHES, AND OTHER DEVICES (COLLECTIVELY, \"CARRIER\n" +
    "    LINES\") OWNED, MAINTAINED, AND SERVICED BY THIRD PARTY CARRIERS, UTILITIES, INTERNET SERVICE PROVIDERS, ALL OF WHICH ARE BEYOND OUR CONTROL. WE ASSUME NO\n" +
    "    LIABILITY FOR OR RELATING TO THE INTEGRITY, PRIVACY, SECURITY, CONFIDENTIALITY, OR USE OF ANY INFORMATION WHILE IT IS TRANSMITTED ON THE CARRIER LINES, OR\n" +
    "    ANY DELAY, FAILURE, INTERRUPTION, INTERCEPTION, LOSS, TRANSMISSION, OR CORRUPTION OF ANY DATA OR OTHER INFORMATION ATTRIBUTABLE TO TRANSMISSION ON THE\n" +
    "    CARRIER LINES. USE OF THE CARRIER LINES IS SOLELY AT YOUR RISK AND IS SUBJECT TO ALL APPLICABLE LOCAL, STATE, NATIONAL, AND INTERNATIONAL LAWS.\n" +
    "</p>\n" +
    "<p>\n" +
    "    UNAUTHORIZED ACCESS; LOST OR CORRUPT DATA: WE ARE NOT RESPONSIBLE FOR UNAUTHORIZED ACCESS TO YOUR DATA, FACILITIES OR EQUIPMENT BY INDIVIDUALS OR ENTITIES\n" +
    "    USING THE PROGRAMS AND OR SERVICES OR FOR UNAUTHORIZED ACCESS TO, ALTERATION, THEFT, CORRUPTION, LOSS OR DESTRUCTION OF YOUR DATA FILES, PROGRAMS,\n" +
    "    PROCEDURES, OR INFORMATION THROUGH THE SYSTEM, WHETHER BY ACCIDENT, FRAUDULENT MEANS OR DEVICES, OR ANY OTHER MEANS. YOU ARE SOLELY RESPONSIBLE FOR\n" +
    "    VALIDATING THE ACCURACY OF ALL OUTPUT AND REPORTS, AND FOR PROTECTING YOUR DATA AND PROGRAMS FROM LOSS BY IMPLEMENTING APPROPRIATE SECURITY MEASURES,\n" +
    "    INCLUDING ROUTINE BACKUP PROCEDURES. YOU HEREBY WAIVE ANY DAMAGES OCCASIONED BY LOST OR CORRUPT DATA, INCORRECT REPORTS, OR INCORRECT DATA FILES RESULTING\n" +
    "    FROM PROGRAMMING ERROR, OPERATOR ERROR, EQUIPMENT OR SOFTWARE MALFUNCTION, SECURITY VIOLATIONS, OR THE USE OF THIRD PARTY SOFTWARE. WE ARE NOT RESPONSIBLE\n" +
    "    FOR THE CONTENT OF ANY INFORMATION TRANSMITTED OR RECEIVED THROUGH OUR PROVISION OF THE SERVICES.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>21) </strong>\n" +
    "    <strong>Service Level Guarantee:</strong>\n" +
    "    Dental EMR guarantees to have your subscription access available through internet access 99% of the time in any given month. In the event that your access\n" +
    "    is not available for more than 99%, Dental EMR will credit the following month's service fee as follows. Such credit shall be retroactive and shall be as\n" +
    "    calculated below and as measured 24 hours a day in a calendar month, with the maximum credit not to exceed fifty (50) percent of the monthly subscription\n" +
    "    charge for the affected month.\n" +
    "</p>\n" +
    "<table border=\"1\" cellspacing=\"0\" cellpadding=\"0\">\n" +
    "    <tbody>\n" +
    "        <tr>\n" +
    "            <td width=\"199\">\n" +
    "                <p>\n" +
    "                    Monthly Uptime Percentage\n" +
    "                </p>\n" +
    "            </td>\n" +
    "            <td width=\"50\">\n" +
    "                <p>\n" +
    "                    Credits\n" +
    "                </p>\n" +
    "            </td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "            <td width=\"199\">\n" +
    "                <p>\n" +
    "                    95% to 98.9%\n" +
    "                </p>\n" +
    "            </td>\n" +
    "            <td width=\"50\">\n" +
    "                <p>\n" +
    "                    10%\n" +
    "                </p>\n" +
    "            </td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "            <td width=\"199\">\n" +
    "                <p>\n" +
    "                    90% to 94.9%\n" +
    "                </p>\n" +
    "            </td>\n" +
    "            <td width=\"50\">\n" +
    "                <p>\n" +
    "                    20%\n" +
    "                </p>\n" +
    "            </td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "            <td width=\"199\">\n" +
    "                <p>\n" +
    "                    89.9% or below\n" +
    "                </p>\n" +
    "            </td>\n" +
    "            <td width=\"50\">\n" +
    "                <p>\n" +
    "                    50%\n" +
    "                </p>\n" +
    "            </td>\n" +
    "        </tr>\n" +
    "    </tbody>\n" +
    "</table>\n" +
    "<p>\n" +
    "    In order for you to receive a credit on your account, you must request such credit within seven (7) days after you experienced the down time. You must\n" +
    "    request credit by sending an electronic mail message to <u>support@DentalEMR.com</u>. For security, the body of this message must contain your account\n" +
    "    number, the dates and times of the unavailability, and such other Licensee identification requested by Dental EMR. Credits will usually be applied within\n" +
    "    sixty (60) days of your credit request. Credit to your account shall be your sole and exclusive remedy in the event of outage or service degradation. This\n" +
    "    Service Level guarantees and credits are subject to Force Majeure Clause hereof, as well as availability of Licensee systems and access capability at the\n" +
    "    time of outage. This guarantee applies to Licensees in good financial standing with Dental EMR at the time of a service outage. Dental EMR retains sole\n" +
    "    discretionary power when determining whether or not this guarantee has been met.\n" +
    "</p>\n" +
    "<p>\n" +
    "    This guarantee and all its terms apply to additional services subscribed to by Licensee. For instance: implementation, data entry, DRT (discrete reportable\n" +
    "    transcription), custom development, data migration, training and support, etc. For all these services, the Monthly Uptime will mean the percentage of\n" +
    "    error-free-service delivery guaranteed by Dental EMR hereunder. The service levels credits will apply accordingly.\n" +
    "</p>\n" +
    "<p>\n" +
    "    The issuance of credits to you hereunder shall be your sole and exclusive remedy for any claim and you hereby waive the right of any action, legal or\n" +
    "    otherwise, against Dental EMR by accepting the applicability of the Service Level Credits to any claims you might have relating to the quality of service\n" +
    "    hereunder.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>22) </strong>\n" +
    "    <strong>Limitation of Liabilities</strong>\n" +
    "    : IT IS EXPRESSLY AGREED THAT IN NO EVENT DENTAL EMR OR ANY OF THE DIRECT OR INDIRECT OWNERS OF DNETAL EMR, OR THEIR RESPECTIVE OFFICERS, DIRECTORS,\n" +
    "    STOCKHOLDERS, AGENTS, AND EMPLOYEES, OR ANY LICENSORS OF DENTAL EMR SHALL HAVE ANY LIABILITY WHATSOEVER FOR INSTANCES INCLUDING BUT NOT LIMITED TO ANY LOSS\n" +
    "    OR CORRUPTION OF DATA, ANY INABILITY TO RECORD OR ACCESS DATA, ANY FAILURE TO RESTORE DATA, OR ANY SPECIAL, CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY\n" +
    "    OR PUNITIVE DAMAGES INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS, REVENUES OR GOODWILL, LOSS OF USE, OR LOSS OF INFORMATION OR DATA, OR FROM INCREASED\n" +
    "    EXPENSES OR COSTS, FORESEEABLE OR UNFORESEEABLE, WHETHER A CLAIM FOR ANY SUCH LIABILITY OR DAMAGES IS PREMISED UPON BREACH OF CONTRACT, BREACH OF WARRANTY,\n" +
    "    NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER THEORY OF LIABILITY, EVEN IF DENTAL EMR HAS BEEN APPRISED OF THE POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES\n" +
    "    OCCURRING THAT MAY BE INCURRED OR SUFFERED BY THE LICENSEE OR ANY OTHER PERSON FROM THE USE OR INABILITY TO USE THE PROGRAMS AND SERVICES WHETHER UNDER THE\n" +
    "    LAWS OF CONTRACT, STRICT LIABILITY, TORT OR OTHERWISE, ARISING FROM THOSE OR OTHER CAUSES. DENTAL EMR DISCLAIMS ANY AND ALL LIABILITY FOR ERRONEOUS\n" +
    "    TRANSMISSIONS AND LOSS OF SERVICE RESULTING FROM COMMUNICATION FAILURES BY TELECOMMUNICATION SERVICE PROVIDERS OR THE PROGRAMS.\n" +
    "</p>\n" +
    "<p>\n" +
    "    NOTWITHSTANDING ANYTHING IN THIS AGREEMENT TO THE CONTRARY, OUR AGGREGATE LIABILITY UNDER THIS AGREEMENT, REGARDLESS OF THEORY OF LIABILITY, SHALL BE\n" +
    "    LIMITED TO THE AGGREGATE FEES ACTUALLY PAID BY YOU UNDER THIS AGREEMENT FOR THE SIX (6) MONTH PERIOD PRECEDING THE EVENT FIRST GIVING RISE TO THE CLAIM.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Conditions for Breach: We will not be deemed to be in violation of this Agreement unless you have first have given us written notice specifying the nature\n" +
    "    of the default, and we have failed within thirty (30) days of receipt of the notice either to cure the default or, if cure within such period is not\n" +
    "    practicable, to be diligently proceeding to cure the default.\n" +
    "</p>\n" +
    "<p>\n" +
    "    In the event Licensee fails to comply with the Dental EMR implementation, training and policy guidelines, including but not limited to installation of\n" +
    "    compliant hardware, bandwidth or infrastructure, HIPAA, HITECH, and other federal / state laws, or as a result of inactivity fails to respond to any\n" +
    "    communication for a period of six (6) months after signing up, the Licensee shall be considered dormant and automatically released from this Agreement. Any\n" +
    "    payment deposits, up-front or recurring, shall be forfeited accordingly and Dental EMR will not be held liable for any loss or damage suffered by the\n" +
    "    Licensee. Thereafter, if the Licensee wishes to resume our services, they will have to renew their Agreement with Dental EMR, subject to additional\n" +
    "    charges.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>23) </strong>\n" +
    "    <strong>Data Protection:</strong>\n" +
    "    The Licensee agrees that it will establish procedures for handling and protecting patient data consistent with good data management for important and\n" +
    "    sensitive data, including but not limited to measures such as:\n" +
    "</p>\n" +
    "<p>\n" +
    "    i. Implementation of physical and electronic security measures to prevent unauthorized persons from having access to the Programs;\n" +
    "</p>\n" +
    "<p>\n" +
    "    ii. Use of a continuously active computer virus detection and deletion program on the System, upgraded regularly, together with procedures to ensure that\n" +
    "    all data or files that are loaded into the System are checked for viruses prior to use; &#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    iii. Ensure that all Authorized Users are trained in proper security and data protection procedures, such as alertness to evidence of unauthorized access,\n" +
    "    avoidance of use of modems for Internet access, and avoidance of use of diskettes that have not been checked for viruses. &#8232;&#8232;\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>24) Assignment:</strong>\n" +
    "    The Licensee's rights, duties and obligations under this Agreement may not be, directly or indirectly, transferred, leased, assigned, delegated,\n" +
    "    sublicensed or otherwise conveyed or disposed of, nor may the Licensee undergo a change of ownership or control (in each case, \"a Transfer\") without the\n" +
    "    prior written consent of Dental EMR, which may be withheld in Dental EMR's sole and absolute discretion; provided, however, that Dental EMR shall not\n" +
    "    unreasonably withhold its consent to a Transfer that involves the sale by the Licensee of all of its assets, or a merger of the Licensee into another\n" +
    "    Person, so long as, prior to such Transfer, the transferee: Provides Dental EMR with such information as Dental EMR may reasonably request and such\n" +
    "    information is satisfactory to Dental EMR; Assumes all obligations of the Licensee to Dental EMR under this Agreement or otherwise; and Pays to Dental EMR,\n" +
    "    Dental EMR's re- license fee. No Transfer shall relieve the Licensee of any of its obligations, whether under this Agreement or otherwise. Any purported\n" +
    "    Transfer by the Licensee in violation of this Section 24 shall be null and void. Dental EMR may assign its rights and delegate obligations under this\n" +
    "    Agreement without the consent of the Licensee subject to the foregoing; this Agreement shall be binding upon and inure to the benefit of the parties, their\n" +
    "    successors and permitted assigns.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>25) </strong>\n" +
    "    <strong>Protection of Proprietary Rights &amp; Confidential Information:</strong>\n" +
    "    The Licensee acknowledges that the Dental EMR Information, the Dental EMR Materials and other Related technical and business information and documentation\n" +
    "    (all together the \"Proprietary Information\") provided to the Licensee by or on behalf of Dental EMR or an authorized Dental EMR reseller classify as\n" +
    "    confidential information and are valuable proprietary rights of Dental EMR or its licensors, including ideas, concepts and techniques contained in the\n" +
    "    Programs, reference data, system design, data models, product performance and functionality, planned products and services, marketing and product plans,\n" +
    "    processes, formulas, and methodologies for developing, analyzing and presenting data, and the terms and conditions of this Agreement, as it may be amended\n" +
    "    from time to time, and any other agreements between Dental EMR and the Licensee. The Licensee agrees not to (i) Provide or otherwise make available what's\n" +
    "    outlined above or any Dental EMR Information, Dental EMR Material, any backup copies of Programs if permitted to be made under this Agreement, or other\n" +
    "    documentation of Dental EMR in any form to any person, corporation or entity, (ii) Disclose Proprietary Information to any person, corporation or entity,\n" +
    "    including preparation and provision to any third party, or allowing any third party access to the Programs to prepare any benchmark analysis of the\n" +
    "    performance of the Programs or comparison of the Programs or the Optional Services with the products or services of any other party or (iii) remove or\n" +
    "    obscure any copyright and trademark notices or other proprietary notices relating to licensed software. The Licensee shall exercise the same level of care\n" +
    "    to protect the proprietary nature of Dental EMR Proprietary Information as it exercises to protect its own proprietary and confidential information and\n" +
    "    shall, in addition, take such actions as are required under this Agreement or shall be reasonably specified by Dental EMR in a written notice to the\n" +
    "    Licensee hereafter for such purpose.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Licensee agrees that the Confidential Information is to be considered confidential and proprietary to Dental EMR and Licensee shall hold the same in\n" +
    "    confidence, shall not use the Confidential Information other than for the purposes of its business with Dental EMR, and shall disclose it only to its\n" +
    "    officers, directors, or employees with a specific need to know, who will then be bound to the same effect as the Licensee under the terms of this agreement\n" +
    "    to the extent of (or in reference to) the Confidential Information so disclosed. Licensee will not disclose, publish or otherwise reveal any of the\n" +
    "    Confidential Information received from Dental EMR to any other party whatsoever except with the specific prior written authorization of Dental EMR.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Confidential Information furnished in tangible form shall not be duplicated by Licensee except for purposes of this Agreement. Upon the request of Dental\n" +
    "    EMR, Licensee shall return all Confidential Information received in written or tangible form, including copies, or reproductions or other media containing\n" +
    "    such Confidential Information, within ten (10) days of such request.\n" +
    "</p>\n" +
    "<p>\n" +
    "    At Licensee's option, any documents or other media developed by the Licensee containing Confidential Information may be destroyed by Licensee. If the\n" +
    "    Licensee chooses not to destroy any such documents or other media containing Confidential Information, it must, however, essentially remove any part there\n" +
    "    of containing references to the Confidential Information mentioned therein. Licensee shall provide a written certificate to Dental EMR regarding\n" +
    "    destruction within ten (10) days thereafter.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Nothing contained herein shall be construed as granting or conferring any rights by license or otherwise in any Confidential Information. It is understood\n" +
    "    and agreed that neither party solicits any change in the organization, business practice, service or products of the other party, and that the disclosure\n" +
    "    of Confidential Information shall not be construed as evidencing any intent by a party to purchase any products or services of the other party nor as an\n" +
    "    encouragement to expend funds in development or research efforts. Confidential Information may pertain to prospective or unannounced products. Licensee\n" +
    "    agrees not to use any Confidential Information as a basis upon which to develop or have a third party develop a competing or similar product/service.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Licensee may disclose Confidential Information as required to satisfy any legal requirement of a competent government body provided that, immediately upon\n" +
    "    receiving any such request and to the extent that it may legally do so, Licensee advises Dental EMR the request prior to making such disclosure in order\n" +
    "    that Dental EMR may interpose an objection to such disclosure or take such other action as it deems appropriate to protect the Confidential Information.\n" +
    "    Each party's Confidential Information shall remain the property of that party. Nothing contained in this section shall be construed as obligating a party\n" +
    "    to disclose its Confidential Information to the other party or as granting to or conferring upon a party, expressly or impliedly, any rights or license to\n" +
    "    the Confidential Information of the other party.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Licensee agrees that Dental EMR will suffer irreparable harm if Licensee fails to comply with its obligations set forth in this Section 25 including other\n" +
    "    obligations set forth in this Agreement pertaining to Dental EMR's intellectual property rights (which shall survive the termination or expiration of this\n" +
    "    Agreement, regardless of the cause of termination), and you further agree that monetary damages will be inadequate to compensate us for any such breach.\n" +
    "    Accordingly, you agree that we will, in addition to any other remedies available to us at law or in equity, be entitled to the issuance of injunctive\n" +
    "    relief to enforce the provisions hereof as well as seek specific performance, immediately and without the necessity of posting a bond.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>26) </strong>\n" +
    "    <strong>Scope of Agreement:</strong>\n" +
    "    This Agreement is the exclusive agreement between the Licensee and Dental EMR, with respect to the Programs and Services and the subject matter of this\n" +
    "    Agreement and, as of its Effective Date supersedes all prior and contemporaneous agreements, negotiations, representations and proposals, written or oral,\n" +
    "    related to its subject matter. Dental EMR shall not be bound by or liable to the Licensee for any representation, promise or inducement made by any agent\n" +
    "    of Dental EMR or any other Person, which is not embodied in this Agreement or in another writing signed by Dental EMR. Dental EMR shall have no obligation\n" +
    "    under this Agreement in the event that the representations and warranties of the Licensee set forth in this Agreement are untrue in any material respect.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>27) </strong>\n" +
    "    <strong>Governing Law:</strong>\n" +
    "    This Agreement shall be governed by and construed and enforced in accordance with the laws of the State of Delaware as it applies to a contract made and to\n" +
    "    be performed in such state. The Licensee consents and agrees that the courts of Delaware and the United States District Court for Delaware shall have\n" +
    "    personal jurisdiction over the Licensee, as well as subject matter jurisdiction with respect to any provision of this Agreement, and shall be the exclusive\n" +
    "    forums for any litigation arising out of or relating to this Agreement. The Licensee also agrees to and hereby does waive its rights to a trial by jury and\n" +
    "    agrees to accept service of process by mail.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>28) </strong>\n" +
    "    <strong>Modifications and Waivers:</strong>\n" +
    "    This Agreement may not be modified except by a writing signed by authorized representatives of both parties. A waiver by any party of its rights under this\n" +
    "    Agreement shall not be binding unless contained in a writing signed by an authorized representative of the party waiving its rights. The non- enforcement\n" +
    "    or waiver of any provision on any occasion shall not constitute a waiver of such provision on any other occasions unless expressly so agreed in writing. It\n" +
    "    is agreed that no usage of trade or other regular practice or method of dealing between or among the parties to this Agreement shall be used to modify,\n" +
    "    interpret, supplement, or alter in any manner the terms of this Agreement.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>29) </strong>\n" +
    "    <strong>Dispute Resolution: </strong>\n" +
    "    <strong></strong>\n" +
    "</p>\n" +
    "<p>\n" +
    "    <u>Negotiation</u>\n" +
    "    : Should a dispute arise between the parties as to the interpretation or the legal effects of the Agreement, the parties shall first seek to resolve such\n" +
    "    dispute through negotiations. If such negotiations do not succeed within fifteen (15) working days, or a different period agreed by the parties, each of\n" +
    "    the parties may request that the dispute be brought before an independent expert or submitted for mediation.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <u>Independent Expert</u>\n" +
    "    : The parties shall in connection with the conclusion of the Agreement appoint an independent expert, whose name shall be specified in an Addendum to this\n" +
    "    Agreement, and who shall hold such qualifications as the parties believe to be the most appropriate in the light of the Agreement. If this has not been\n" +
    "    done, the parties may agree on the appointment of an independent expert at the time of a dispute. The parties shall in advance choose either to comply with\n" +
    "    the solution proposed by the expert (binding), or use the solution proposed by the expert as a basis for reaching a solution themselves (advisory). The\n" +
    "    detailed approach to these efforts shall be determined by the independent expert, in consultation with the parties.\n" +
    "</p>\n" +
    "<p>\n" +
    "    If a dispute related to this Agreement has not been resolved after negotiations or by an independent expert, the parties may attempt to resolve the dispute\n" +
    "    through mediation. Mediation may also be used without the prior use of an independent expert, if this is agreed by the parties. The detailed approach to\n" +
    "    mediation shall be determined by the mediator, in consultation with the parties.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <u>Joint Rules for Mediation or Independent Expert</u>\n" +
    "    : The independent expert and/or mediator shall act impartially and independently in the performance of his or her duties. Prior to accepting an assignment,\n" +
    "    the expert/mediator shall notify the parties of any potential circumstances that are likely to give rise to a suspicion of insufficient impartially or\n" +
    "    independence on his or her part. The expert/mediator shall also give the parties such notice during the assignment if the parties have not previously\n" +
    "    received such information, or if the relevant circumstances arise during the assignment.\n" +
    "</p>\n" +
    "<p>\n" +
    "    At the start of mediation, the expert/mediator shall inform the parties of the basis on which his or her remuneration will be calculated. Unless otherwise\n" +
    "    agreed, each party shall pay its own costs and half of the costs of the expert/mediator. The expert/mediator has the right to request the parties to pay a\n" +
    "    sufficient advance to cover the costs and remuneration of the mediator/expert, or to request the parties to provide sufficient security.\n" +
    "</p>\n" +
    "<p>\n" +
    "    The assignment of the independent expert or mediator shall be concluded in one of the following ways: through a proposed solution from the expert that the\n" +
    "    parties have agreed in advance shall be binding; through a written settlement or agreement between the parties, based on the solution proposed by the\n" +
    "    expert/mediator; through the expert/mediator informing the parties that he or she does not deem it appropriate to continue the assignment, or through a\n" +
    "    party informing the expert or the mediator that such party wishes to conclude the assignment.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <u>Litigation or Arbitration</u>\n" +
    "    : If a dispute is not resolved through negotiations, through mediation or by an independent expert, each party may require such dispute to be resolved by\n" +
    "    arbitration and if the resolution is not reached, with final effect before the courts of law in the Delaware City, United States of America.\n" +
    "</p>\n" +
    "<p>\n" +
    "    In the event of any dispute or controversy hereunder (including, without limitation, any dispute involving the existence, validity or breach of this\n" +
    "    Agreement), the parties shall submit same to arbitration privately and confidentially in Delaware, Delaware by one arbitrator mutually agreed (or, if none\n" +
    "    appointed pursuant to the Commercial Arbitration Rules of the American Arbitration Association), subject to the arbitrator executing an appropriate\n" +
    "    confidentiality agreement. The result of any such arbitration shall be binding but shall not be made public unless necessary to confirm same after non-\n" +
    "    compliance by a party. Nothing contained herein shall limit or curtail Dental EMR's right to any remedy or relief under any applicable law in reference to\n" +
    "    any dispute or controversy.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Both parties agree that that no action at law shall be taken by either party previous to an unsuccessful resolution by arbitration. However, if Licensee\n" +
    "    considers litigation as recourse for dispute resolution, Licensee will be solely responsible for all legal fees and expenses incurred by Dental EMR to\n" +
    "    defend or resolve the dispute. This provision shall survive the termination of this agreement, regardless, of the cause of such termination.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>30) </strong>\n" +
    "    <strong>Enforceability/Injunctive Relief:</strong>\n" +
    "    It is understood and agreed by the parties to this Agreement that it is their intention that if a court of competent jurisdiction shall determine that any\n" +
    "    of the terms of this Agreement are invalid or otherwise unenforceable, that such court shall substitute terms, therefore, with such court determines are\n" +
    "    enforceable, so as to result in the enforcement of the original terms to the maximum extent permitted by law.\n" +
    "</p>\n" +
    "<p>\n" +
    "    The Licensee agrees that any non-compliance with the terms of this Agreement, or any unauthorized or improper use of any Dental EMR trademarks or Dental\n" +
    "    EMR Materials will cause irreparable damage to Dental EMR. The Licensee therefore agrees that if the Licensee engages in any one or more of such\n" +
    "    noncompliance, unauthorized use and improper use of Dental EMR trademarks or Dental EMR Materials, during or after the Term of License, Dental EMR shall be\n" +
    "    entitled to both temporary and permanent injunctive relief against the Licensee from any court of competent jurisdiction, in addition to all other remedies\n" +
    "    which Dental EMR may have at law, in equity or otherwise.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>31) </strong>\n" +
    "    <strong>Marketing:</strong>\n" +
    "    The Licensee agrees that during the term of this Agreement Dental EMR may publicly refer to the Licensee, orally and in writing, as a Customer of Dental\n" +
    "    EMR and may also use Licensee's trademark or logo for this purpose. Any other reference to Customer by Dental EMR requires the written consent of Customer.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>32) </strong>\n" +
    "    <strong>Notices:</strong>\n" +
    "    Unless otherwise provided, any notice required or permitted under this Agreement shall be given in writing and shall be deemed effectively given upon\n" +
    "    personal delivery to the party to be notified or on the fifth (5th) day following deposit with the United States Post Office, by registered or certified\n" +
    "    mail (return receipt requested), postage prepaid and addressed to the party to be notified at the address of such party set forth in this Agreement, or at\n" +
    "    such other address as such party may designate by ten (10) days advance written notice to the other party.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>33) </strong>\n" +
    "    <strong>No Third party Beneficiaries:</strong>\n" +
    "    Nothing express or implied in this Agreement is intended to confer, nor shall confer, upon any person or entity other than the parties, their licensors,\n" +
    "    and their respective successors or assigns any rights, remedies, obligations, or liabilities whatsoever.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>34) </strong>\n" +
    "    <strong>Non Solicitation:</strong>\n" +
    "    Each party to this Agreement agrees not to recruit or hire any employee or agent of the other party, either as an employee or consultant, or recruit any\n" +
    "    such person for another company, while such person is employed or retained by the other party and for a period of twelve (12)\n" +
    "</p>\n" +
    "<p>\n" +
    "    months after the employee leaves the employ of the other party, or for a period of twelve (12) months after the termination or expiration of this\n" +
    "    Agreement, whichever period ends at the latest date.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>35) </strong>\n" +
    "    <strong>Advice of Counsel &amp; Authority:</strong>\n" +
    "    Each Party acknowledges: (a) having fully read this Agreement in its entirety; (b) having had full opportunity to study and review this Agreement; (c)\n" +
    "    having been advised that counsel for us has acted solely on our behalf in connection with the negotiation, preparation, and execution of this Agreement;\n" +
    "    (d) having been advised that all parties have the right to consult and should consult independent counsel respecting their rights and duties under this\n" +
    "    Agreement; and (e) having had access to all such information as has been requested.\n" +
    "</p>\n" +
    "<p>\n" +
    "    The individuals, corporations or entities entering into this Agreement represent and warrant that they are competent and capable of entering into a binding\n" +
    "    contract, and that they are authorized to enter into this Agreement on behalf of the Parties.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>36) </strong>\n" +
    "    <strong>Interpretation:</strong>\n" +
    "    Section headings are for reference only, and shall not be construed as substantive parts of this Agreement. Each capitalized term used in this Agreement\n" +
    "    (including any schedule or exhibit of this Agreement) shall have the meaning attributed to it in any part of this Agreement (including any such schedules\n" +
    "    or exhibits).\n" +
    "</p>\n" +
    "<p>\n" +
    "    In the event that Dental EMR is entitled to make any decision or determination, or grant or withhold any consent or approval, pursuant to any term of this\n" +
    "    Agreement, it shall be entitled to do so in its sole and absolute discretion. Each use of the term including, in this Agreement, unless otherwise expressly\n" +
    "    stated in connection with such use, shall mean including without limitation.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>37) </strong>\n" +
    "    <strong>Survival &amp; Period of Claims:</strong>\n" +
    "    Licensee acknowledges and agrees that the covenants and agreements made in this Agreement are made for the benefit of Dental EMR and its Licensors and the\n" +
    "    obligations existing prior to the termination or expiration hereof shall survive the termination or expiration of this Agreement. Other than that all\n" +
    "    indemnification, confidentiality, intellectual property, non-compete, non-solicitation, governing law, dispute resolution, enforceability/injunctive\n" +
    "    relief, Limitation of Liability, Disclaimer and other clauses including those provisions which by their terms contemplate survival shall survive the\n" +
    "    termination or expiration of this Agreement regardless of the cause of such termination. In the event of any breach by Licensee of the terms of this\n" +
    "    Agreement, in addition to other relief to which Dental EMR shall be entitled, Dental EMR shall be entitled to terminate this License.\n" +
    "</p>\n" +
    "<p>\n" +
    "    No action, regardless of form, relating, directly or indirectly to this Agreement or the Programs or Services or other goods or services rendered may be\n" +
    "    brought more than one 6 months after cause of action has arisen, except that an action for nonpayment may be brought within one 1 year after the date of\n" +
    "    the most recent payment. This limitation however, shall not be applicable to Dental EMR intellectual property as well as indemnification rights. If any\n" +
    "    claim or cause of action is not filed within said six (6) month time period, the claim or cause of action shall be forever barred.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>38) </strong>\n" +
    "    <strong>Insurance: </strong>\n" +
    "    You will obtain and maintain such policies of, general liability, errors and omissions, and professional liability insurance with reputable insurance\n" +
    "    companies as is usually carried by persons engaged in your business covering the Term of this Agreement. Such insurance shall be in amounts no less than\n" +
    "    One Million Dollars ($1,000,000) per occurrence and Three Million Dollars ($3,000,000) in the annual aggregate.\n" +
    "</p>\n" +
    "<p>\n" +
    "    Furthermore, you will hold Dental EMR harmless from all claims, demands and suits arising out of the performance of your obligations hereunder or for any\n" +
    "    other damages to you, Dental EMR or any third party that could have been covered by obtaining proper insurance.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>39) </strong>\n" +
    "    <strong>Force Majeure:</strong>\n" +
    "    Neither party shall be liable to the other party for damages or losses, except for payment obligations, on account of failure of performance by the\n" +
    "    defaulting party if the failure is the result of an Act of God (e.g., fire, flood, inclement weather, epidemic, or earthquake), fear, possibility, war or\n" +
    "    act of terrorism, including chemical or biological warfare; labor dispute, lockout, strike, embargo; communication line, hardware or power failures;\n" +
    "    governmental acts, orders, or restrictions; failure of suppliers or third persons; nuclear or other civil or military emergencies; acts of legislative,\n" +
    "    judicial, executive, or administrative authorities; or any other reason where failure to perform is beyond the reasonable control, and is not caused by the\n" +
    "    negligence, intentional conduct or misconduct of the defaulting party, and the defaulting party has exercised all reasonable efforts to avoid or remedy\n" +
    "    such force majeure. The defaulting party must provide written notice of the force majeure event to the remaining parties within two (2) business days of\n" +
    "    such event.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>40) </strong>\n" +
    "    <strong>Misuse of Third Party Product:</strong>\n" +
    "    You agree that You will use Third Party Products only in accordance with the permitted or licensed use of such Third Party Products and You agree to\n" +
    "    defend, indemnify and hold Dental EMR, its affiliates, resellers and licensors as well as their respective employees, officers, or contractors harmless\n" +
    "    from any claim by or on behalf of any third party which is brought against Dental EMR, its affiliates, resellers and licensors as well as their employees,\n" +
    "    officers, or directors arising out of any improper use of any third Party Product or any infringement of any third party's rights with respect to your use,\n" +
    "    copying, modification, distribution, display or other activity relating to any Third Party Product unless such activity is licensed to You under this\n" +
    "    Agreement with respect to the applicable Third Party Product.\n" +
    "</p>\n" +
    "<p>\n" +
    "    <strong>41) </strong>\n" +
    "    <strong>Federal Government:</strong>\n" +
    "    In the event that the Licensee under this Agreement is the United States Government, this clause will apply: The Programs and accompanying materials are\n" +
    "    commercial computer software provided with RESTRICTED RIGHTS. Use, duplication or disclosure by the Government is subject to restrictions as set forth in\n" +
    "    (a) this Agreement pursuant to DFARs 227.7202- 3(a); or (b) the Commercial Computer Software- Restricted Rights clause at FAR 52.227-19 subdivision (c)(l)\n" +
    "    and (2), as applicable. The Programs and accompanying materials are provided with disclosure prohibitions, as provided in this Agreement, notwithstanding\n" +
    "    any copyright notice, and the terms of FAR 52.227-19 subdivision (d) are expressly disclaimed. Contractor/manufacturer is DentalEMR, Inc., 120 Broadway,\n" +
    "    Delaware, DE.\n" +
    "</p>\n" +
    "\n" +
    "</div>\n" +
    "      <div class=\"form-actions\">\n" +
    "        <div class=\"pull-right\">\n" +
    "          <button class=\"btn p-x btn-default\" ng-click=\"decline()\">Decline</button>\n" +
    "          <button class=\"btn p-x btn-primary\" ng-click=\"accept()\">Accept</button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      </div>\n" +
    "</div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "")

$templateCache.put("src/common/faq.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<div ng-hide={{isInWizard}}>\n" +
    "  <div class=\"page-bar\">\n" +
    "    <ul class=\"page-breadcrumb\">\n" +
    "      <li>\n" +
    "        <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "        <a ui-sref=\"charts\">Home</a>\n" +
    "        <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      </li>\n" +
    "      <li>\n" +
    "        <span>FAQ</span>\n" +
    "      </li>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </ul>\n" +
    "  </div>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\" >\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-file-text2 font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">FAQ</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\"></div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <iframe src=\"https://docs.google.com/document/d/e/2PACX-1vSDDpl5bxddHfjO25_cAu3i0ODDdTtrL0L3K0q-CoIR8uEOieyKwWAf7M8zVZvh7_99L13RHPqMNGeS/pub?embedded=true\"\n" +
    "          style=\"width:100%; height:calc(100vh - 340px); overflow:auto;\">\n" +
    "        </iframe>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/flow-complete.tpl.html","<div class=\"row text-center\">\n" +
    "  <div class=\"col-md-12 col-md-offset-0\">\n" +
    "    <h1>{{ page.title }}</h1>\n" +
    "\n" +
    "    <div>\n" +
    "      <p>{{ page.message }}</p>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>")

$templateCache.put("src/common/home.tpl.html","<div class=\"text-center\">\n" +
    "  <h1>User Home Page content</h1>\n" +
    "  <h2>If you are seeing this, you are logged in.</h2>\n" +
    "</div>\n" +
    "<!--\n" +
    "<a href=\"\" ui-sref=\"restricted\">Go to restricted page (will show you Access Denied)</a>\n" +
    "-->\n" +
    "")

$templateCache.put("src/common/howto.tpl.html","\n" +
    "<!-- BEGIN PAGE HEADER-->\n" +
    "<div ng-hide={{isInWizard}}>\n" +
    "  <!--\n" +
    "  <h3 class=\"page-title\"> Imaging Connections\n" +
    "    <small></small>\n" +
    "  </h3>\n" +
    "  -->\n" +
    "  <div class=\"page-bar\">\n" +
    "    <ul class=\"page-breadcrumb\">\n" +
    "      <li>\n" +
    "        <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "        <a ui-sref=\"charts\">Home</a>\n" +
    "        <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      </li>\n" +
    "      <li>\n" +
    "        <span>How-to</span>\n" +
    "      </li>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </ul>\n" +
    "  </div>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-book font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">How-to</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\"></div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "          <input type=\"text\" class=\"form-control\" placeholder=\"Search\" ng-model=\"searchTerm\">\n" +
    "          <br>\n" +
    "          <table id=\"patientsTable\" class=\"table table-striped table-bordered table-advance\">\n" +
    "          <thead>\n" +
    "            <tr>\n" +
    "              <th width=\"5%\">Module\n" +
    "              </th>\n" +
    "              <th width=\"5%\">Type\n" +
    "                </th>\n" +
    "                <th width = \"40%\">Description\n" +
    "              </th>\n" +
    "              <th>Video\n" +
    "              </th>\n" +
    "            </tr>\n" +
    "          </thead>\n" +
    "          <tr ng-repeat=\"video in videos | filter:searchVideos\">\n" +
    "            <td>{{video.subject}}</td>\n" +
    "            <td>{{video.type}}</td>\n" +
    "            <td>{{video.description}}</td>\n" +
    "            <td>\n" +
    "              <video width=\"600\" controls>\n" +
    "                <source src=\"{{video.videoURLMP4 | trustAsResourceUrl}}\" type=\"video/mp4\">\n" +
    "                <!--<source src=\"movie.ogg\" type=\"video/ogg\">-->\n" +
    "                Your browser does not support the video tag.\n" +
    "              </video>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </table>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/insurance-data-provided-by-patient.tpl.html","<div class=\"row\"  >\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-body\">\n" +
    "\n" +
    "        <form\n" +
    "              class=\"form-horzizontal\"\n" +
    "              novalidate>\n" +
    "\n" +
    "          <fieldset ng-disabled=\"true\">\n" +
    "\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"col-md-4\"></div>\n" +
    "              <div class=\"col-md-4\">\n" +
    "                <h3 style=\"text-align: center;\">Insurance data provided by patient</h3>\n" +
    "              </div>\n" +
    "              <div class=\"col-md-4\"></div>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "            <div class=\"form-group row\">\n" +
    "              <div class=\"col-sm-6\">\n" +
    "                <div class=\"panel-heading\">\n" +
    "                  <div class=\"row\">\n" +
    "                    <h4 class=\"panel-title\">Insurance Information</h4>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"panel-body\">\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Type</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <div class=\"btn-group pull-right\">\n" +
    "                        <label class=\"btn btn-default\"\n" +
    "                               ng-model=\"patient.insuranceData.insurance_type\"\n" +
    "                               uib-btn-radio=\"'dental'\" uncheckable>Dental</label>\n" +
    "                        <label class=\"btn btn-default\"\n" +
    "                               ng-model=\"patient.insuranceData.insurance_type\"\n" +
    "                               uib-btn-radio=\"'medical'\" uncheckable>Medical</label>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Payer Name</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             ng-readonly=\"!isAnonymous()\"\n" +
    "                             name=\"primary-insurance-company-name\"\n" +
    "                             ng-model=\"patient.insuranceData.insurance_company_name\"\n" +
    "                             ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "                             placeholder=\"Payer Name\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label> Address</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             ng-readonly=\"!isAnonymous()\"\n" +
    "                             ng-disabled=\"!isAnonymous()\"\n" +
    "                             name=\"primary-insurance-insurance-company-address-street-1\"\n" +
    "                             ng-model=\"patient.insuranceData.insurance_company_address_street_1\"\n" +
    "                             google-places-auto-complete\n" +
    "                             ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "                             zip=\"patient.insuranceData.insurance_company_address_zipcode\"\n" +
    "                             state=\"patient.insuranceData.insurance_company_address_state\"\n" +
    "                             city=\"patient.insuranceData.insurance_company_address_city\"\n" +
    "                             placeholder=\" Address\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Address Cont.</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             ng-readonly=\"!isAnonymous()\"\n" +
    "                             ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "                             name=\"primary-insurance-insurance-company-address-street-2\"\n" +
    "                             ng-model=\"patient.insuranceData.insurance_company_address_street_2\"\n" +
    "                             placeholder=\"Address cont.\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>City, State, Zip</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-3 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             ng-readonly=\"!isAnonymous()\"\n" +
    "                             ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "                             name=\"primary-insurance-insurance-company-address-city\"\n" +
    "                             ng-model=\"patient.insuranceData.insurance_company_address_city\"\n" +
    "                             placeholder=\"City\"/>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"col-sm-2 form-group\">\n" +
    "                      <state-input ng-model=\"patient.insuranceData.insurance_company_address_state\"\n" +
    "                                   ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "                                   ng-readonly=\"!isAnonymous()\"\n" +
    "                                   name=\"primary-insurance-insurance-company-address-state\"\n" +
    "                                   ng-required=\"false\" />\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"col-sm-3 form-group\">\n" +
    "                      <zip-input name=\"primary-insurance-insurance-company-address-zipcode\"\n" +
    "                                 ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "                                 ng-readonly=\"!isAnonymous()\"\n" +
    "                                 ng-model=\"patient.insuranceData.insurance_company_address_zipcode\"\n" +
    "                                 ng-required=\"false\" />\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Payer Phone Number</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <phone-input ng-model=\"patient.insuranceData.insurance_company_phone\"\n" +
    "                                   name=\"primary-insurance-insurance-company-address-phone\"\n" +
    "                                   ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "                                   placeholder=\"Phone Number\"\n" +
    "                                   ng-readonly=\"!isAnonymous()\"\n" +
    "                                   ng-required=\"false\" />\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Plan Name</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "                             name=\"primary-insurance-plan-name\"\n" +
    "                             ng-readonly=\"!isAnonymous()\"\n" +
    "                             ng-model=\"patient.insuranceData.insurance_plan_name\"\n" +
    "                             placeholder=\"Plan name\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Group</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-4 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             ng-readonly=\"!isAnonymous()\"\n" +
    "                             ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "                             name=\"primary-insurance-group-number\"\n" +
    "                             ng-model=\"patient.insuranceData.insurance_group_id\"\n" +
    "                             placeholder=\"Group Number\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Subscriber ID #</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             name=\"primary-insurance-policy-id-number\"\n" +
    "                             ng-model=\"patient.insuranceData.insurance_subscriber_id\"\n" +
    "                             placeholder=\"Subscriber ID #\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Subscriber</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control input\"\n" +
    "                             name=\"primary-insurance-insured-party-firstname\"\n" +
    "                             ng-model=\"patient.insuranceData.insurance_subscriber_first_name\"\n" +
    "                             placeholder=\"First Name\"\n" +
    "                      />\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control input\"\n" +
    "                             name=\"primary-insurance-insured-party-lastname\"\n" +
    "                             ng-model=\"patient.insuranceData.insurance_subscriber_last_name\"\n" +
    "                             placeholder=\"Last Name\"\n" +
    "                      />\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Subscriber's Address</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             name=\"primary-insurance-insuranced-party-address-street-1\"\n" +
    "                             ng-model=\"patient.insuranceData.insurance_subscriber_address_street_1\"\n" +
    "                             google-places-auto-complete\n" +
    "                             zip=\"patient.insuranceData.insurance_subscriber_address_zipcode\"\n" +
    "                             state=\"patient.insuranceData.insurance_subscriber_address_state\"\n" +
    "                             city=\"patient.insuranceData.insurance_subscriber_address_city\"\n" +
    "                             placeholder=\"Subscriber's Address\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Address Cont.</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             name=\"primary-insurance-insuranced-party-address-street-2\"\n" +
    "                             ng-model=\"patient.insuranceData.insurance_subscriber_address_street_2\"\n" +
    "                             placeholder=\"Address cont.\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>City, State, Zip</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-3 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             name=\"primary-insurance-insuranced-party-address-city\"\n" +
    "                             ng-model=\"patient.insuranceData.insurance_subscriber_address_city\"\n" +
    "                             placeholder=\"City\"/>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"col-sm-2 form-group\">\n" +
    "                      <state-input ng-model=\"patient.insuranceData.insurance_subscriber_address_state\"\n" +
    "                                   name=\"primary-insurance-insuranced-party-address-state\"\n" +
    "                                   ng-required=\"false\" />\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"col-sm-3 form-group\">\n" +
    "                      <zip-input name=\"primary-insurance-insuranced-party-address-zipcode\"\n" +
    "                                 ng-model=\"patient.insuranceData.insurance_subscriber_address_zipcode\"\n" +
    "                                 ng-required=\"false\" />\n" +
    "\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Subscriber's relation / Birthdate</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-4 form-group\">\n" +
    "                      <select class=\"form-control\" ng-model=\"patient.insuranceData.insurance_subscriber_party_relationship\">\n" +
    "                        <option value=\"\">None</option>\n" +
    "                        <option value=\"self\">Self</option>\n" +
    "                        <option value=\"spouse\">Spouse</option>\n" +
    "                        <option value=\"dependent\">Dependent</option>\n" +
    "                        <option value=\"other\">Other</option>\n" +
    "                      </select>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <custom-datepicker-popup show-placeholder=\"false\" ng-model=\"patient.insuranceData.insurance_subscriber_birth_date\" input-name=\"'PatientInsuranceSubscriberBirthDateInputDate'\" form-name=\"'updatePatient'\" format=\"'MM/dd/yyyy'\" ng-required=\"false\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Subscriber's Gender</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <div class=\"btn-group pull-right\">\n" +
    "                        <label class=\"btn btn-default\"\n" +
    "                               ng-model=\"patient.insuranceData.insurance_subscriber_gender\"\n" +
    "                               uib-btn-radio=\"'m'\">Male</label>\n" +
    "                        <label class=\"btn btn-default\"\n" +
    "                               ng-model=\"patient.insuranceData.insurance_subscriber_gender\"\n" +
    "                               uib-btn-radio=\"'f'\">Female</label>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Subscriber's Phone / SSN#</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <phone-input ng-model=\"patient.insuranceData.insurance_subscriber_phone\"\n" +
    "                                   name=\"primary-insurance-insuranced-party-phone\"\n" +
    "                                   placeholder=\"Phone\"\n" +
    "                                   ng-required=\"false\" />\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"col-sm-4 form-group\">\n" +
    "                      <ssn-input ng-model=\"patient.insuranceData.insurance_subscriber_ssn\"\n" +
    "                                 name=\"'primary-insurance-insuranced-party-ssn'\"\n" +
    "                                 placeholder=\"'SSN'\"\n" +
    "                                 ng-required=\"false\" />\n" +
    "                      </ssn-input>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Employer/Business</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             ng-model=\"patient.insuranceData.insurance_subscriber_employer_name\"\n" +
    "                             placeholder=\"Name of Employer\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "              </div>\n" +
    "\n" +
    "              <div class=\"col-sm-6\">\n" +
    "                <div class=\"panel-heading\">\n" +
    "                  <h4 class=\"panel-title\">Other Coverage Information</h4>\n" +
    "                </div>\n" +
    "                <div class=\"panel-body\">\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Type</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <div class=\"btn-group pull-right\">\n" +
    "                        <label class=\"btn btn-default\"\n" +
    "                               ng-model=\"patient.otherInsuranceData.insurance_type\"\n" +
    "                               uib-btn-radio=\"'dental'\" uncheckable>Dental</label>\n" +
    "                        <label class=\"btn btn-default\"\n" +
    "                               ng-model=\"patient.otherInsuranceData.insurance_type\"\n" +
    "                               uib-btn-radio=\"'medical'\" uncheckable>Medical</label>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Company Name</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "                             name=\"secondary-insurance-company-name\"\n" +
    "                             ng-model=\"patient.otherInsuranceData.insurance_company_name\"\n" +
    "                             ng-change=\"companyChangeSecondaryInsurance()\"\n" +
    "                             placeholder=\"Company Name\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Company Address</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             name=\"secondary-insurance-insurance-company-address-street-1\"\n" +
    "                             ng-model=\"patient.otherInsuranceData.insurance_company_address_street_1\"\n" +
    "                             google-places-auto-complete\n" +
    "                             ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "                             zip=\"patient.otherInsuranceData.insurance_company_address_zipcode\"\n" +
    "                             state=\"patient.otherInsuranceData.insurance_company_address_state\"\n" +
    "                             city=\"patient.otherInsuranceData.insurance_company_address_city\"\n" +
    "                             placeholder=\"Company Address\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Address Cont.</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "                             name=\"secondary-insurance-insurance-company-address-street-2\"\n" +
    "                             ng-model=\"patient.otherInsuranceData.insurance_company_address_2\"\n" +
    "                             placeholder=\"Address cont.\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>City, State, Zip</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-3 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "                             name=\"secondary-insurance-insurance-company-address-city\"\n" +
    "                             ng-model=\"patient.otherInsuranceData.insurance_company_address_city\"\n" +
    "                             placeholder=\"City\"/>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"col-sm-2 form-group\">\n" +
    "                      <state-input ng-model=\"patient.otherInsuranceData.insurance_company_address_state\"\n" +
    "                                   name=\"secondary-insurance-insurance-company-address-state\"\n" +
    "                                   ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "                                   ng-required=\"false\" />\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"col-sm-3 form-group\">\n" +
    "                      <zip-input name=\"secondary-insurance-insurance-company-address-zipcode\"\n" +
    "                                 ng-model=\"patient.otherInsuranceData.insurance_company_address_zipcode\"\n" +
    "                                 ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "                                 ng-required=\"false\" />\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Payer Phone Number</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <phone-input ng-model=\"patient.otherInsuranceData.insurance_company_phone\"\n" +
    "                                   name=\"secondary-insurance-insurance-company-address-phone\"\n" +
    "                                   ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "                                   placeholder=\"Phone Number\"\n" +
    "                                   ng-required=\"false\" />\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Plan Name</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "                             name=\"secondary-insurance-plan-name\"\n" +
    "                             ng-model=\"patient.otherInsuranceData.insurance_plan_name\"\n" +
    "                             placeholder=\"Plan name\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Group</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-4 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "                             name=\"secondary-insurance-group-number\"\n" +
    "                             ng-model=\"patient.otherInsuranceData.insurance_group_id\"\n" +
    "                             placeholder=\"Group Number\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Subscriber ID #</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             name=\"secondary-insurance-policy-id-number\"\n" +
    "                             ng-model=\"patient.otherInsuranceData.insurance_subscriber_id\"\n" +
    "                             placeholder=\"Subscriber ID #\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Subscriber</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control input\"\n" +
    "                             name=\"secondary-insurance-insured-party-firstname\"\n" +
    "                             ng-model=\"patient.otherInsuranceData.insurance_subscriber_first_name\"\n" +
    "                             placeholder=\"First Name\"\n" +
    "                      />\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control input\"\n" +
    "                             name=\"secondary-insurance-insured-party-lastname\"\n" +
    "                             ng-model=\"patient.otherInsuranceData.insurance_subscriber_last_name\"\n" +
    "                             placeholder=\"Last Name\"\n" +
    "                      />\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Subscriber's Address</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             name=\"secondary-insurance-insuranced-party-address-street-1\"\n" +
    "                             ng-model=\"patient.otherInsuranceData.insurance_subscriber_address_street_1\"\n" +
    "                             google-places-auto-complete\n" +
    "                             zip=\"patient.otherInsuranceData.insurance_subscriber_address_zipcode\"\n" +
    "                             state=\"patient.otherInsuranceData.insurance_subscriber_address_state\"\n" +
    "                             city=\"patient.otherInsuranceData.insurance_subscriber_address_city\"\n" +
    "                             placeholder=\"Subscriber's Address\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Address Cont.</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             name=\"secondary-insurance-insuranced-party-address-street-2\"\n" +
    "                             ng-model=\"patient.otherInsuranceData.insurance_subscriber_address_street_2\"\n" +
    "                             placeholder=\"Address cont.\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>City, State, Zip</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-3 form-group\">\n" +
    "                      <input type=\"text\"\n" +
    "                             class=\"form-control\"\n" +
    "                             name=\"secondary-insurance-insuranced-party-address-city\"\n" +
    "                             ng-model=\"patient.otherInsuranceData.insurance_subscriber_address_city\"\n" +
    "                             placeholder=\"City\"/>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"col-sm-2 form-group\">\n" +
    "                      <state-input ng-model=\"patient.otherInsuranceData.insurance_subscriber_address_state\"\n" +
    "                                   name=\"secondary-insurance-insuranced-party-address-state\"\n" +
    "                                   ng-required=\"false\" />\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"col-sm-3 form-group\">\n" +
    "                      <zip-input name=\"secondary-insurance-insuranced-party-address-zipcode\"\n" +
    "                                 ng-model=\"patient.otherInsuranceData.insurance_subscriber_address_zipcode\"\n" +
    "                                 ng-required=\"false\" />\n" +
    "\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Subscriber's relation / Birthdate</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-4 form-group\">\n" +
    "                      <select class=\"form-control\" ng-model=\"patient.otherInsuranceData.insurance_subscriber_party_relationship\">\n" +
    "                        <option value=\"\">None</option>\n" +
    "                        <option value=\"self\">Self</option>\n" +
    "                        <option value=\"spouse\">Spouse</option>\n" +
    "                        <option value=\"dependent\">Dependent</option>\n" +
    "                        <option value=\"other\">Other</option>\n" +
    "                      </select>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <custom-datepicker-popup show-placeholder=\"false\" ng-model=\"patient.otherInsuranceData.insurance_subscriber_birth_date\" input-name=\"'otherCoverageSubscriberBirthInputDate'\" form-name=\"'updatePatient'\" format=\"'MM/dd/yyyy'\" ng-required=\"false\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Subscriber's Gender</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <div class=\"btn-group pull-right\">\n" +
    "                        <label class=\"btn btn-default\"\n" +
    "                               ng-model=\"patient.otherInsuranceData.insurance_subscriber_gender\"\n" +
    "                               uib-btn-radio=\"'m'\">Male</label>\n" +
    "                        <label class=\"btn btn-default\"\n" +
    "                               ng-model=\"patient.otherInsuranceData.insurance_subscriber_gender\"\n" +
    "                               uib-btn-radio=\"'f'\">Female</label>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                  <div class=\"form-group row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <label>Subscriber's Phone / SSN#</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                      <phone-input ng-model=\"patient.otherInsuranceData.insurance_subscriber_phone\"\n" +
    "                                   name=\"secondary-insurance-insuranced-party-phone\"\n" +
    "                                   placeholder=\"Phone\"\n" +
    "                                   ng-required=\"false\" />\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"col-sm-4 form-group\">\n" +
    "                      <ssn-input ng-model=\"patient.otherInsuranceData.insurance_subscriber_ssn\"\n" +
    "                                 name=\"'secondary-insurance-insuranced-party-ssn'\"\n" +
    "                                 placeholder=\"'SSN'\"\n" +
    "                                 ng-required=\"false\" />\n" +
    "                      </ssn-input>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </fieldset>\n" +
    "\n" +
    "\n" +
    "        </form>\n" +
    "\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/lock-screen.tpl.html","<div class=\"login\">\n" +
    "<div class=\"content content-lockscreen\">\n" +
    "<!-- BEGIN LOGIN FORM -->\n" +
    "<form role=\"form\" ng-submit=\"vm.login()\">\n" +
    "  <h3 class=\"form-title\">Sign in to your account</h3>\n" +
    "  <div class=\"form-group\">\n" +
    "    <!--ie8, ie9 does not support html5 placeholder, so we just show field title for that-->\n" +
    "    <label class=\"control-label\" for=\"login__email\">Email or username</label>\n" +
    "    <div class=\"input-icon\">\n" +
    "      <i class=\"icomoon icon-icomoon-user\"></i>\n" +
    "      <input class=\"form-control\" type=\"text\" autocomplete=\"off\" placeholder=\"Email or username\" name=\"username\" id=\"login__email\" ng-model=\"email\" /> </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label\" for=\"login__password\">Password</label>\n" +
    "    <div class=\"input-icon\">\n" +
    "      <i class=\"icomoon icon-icomoon-lock\"></i>\n" +
    "      <input class=\"form-control\" type=\"password\" autocomplete=\"off\" placeholder=\"Password\" name=\"password\" id=\"login__password\" ng-model=\"password\" /> </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-actions\">\n" +
    "    <button ng-click=\"signin()\" type=\"submit\" class=\"btn btn-primary pull-right\"> Submit </button>\n" +
    "  </div>\n" +
    "\n" +
    "</form>\n" +
    "<!-- END LOGIN FORM -->\n" +
    "</div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/messages.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<h3 class=\"page-title\"> Messages\n" +
    "  <small></small>\n" +
    "</h3>\n" +
    "<div class=\"page-bar\" ng-hide=\"true\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "      <a ui-sref=\"home\">Home</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>Messages</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "\n" +
    "<!--div class=\"inbox\">\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-md-2\">\n" +
    "      <div class=\"inbox-sidebar\">\n" +
    "        <a href=\"javascript:;\" data-title=\"Compose\" class=\"btn red compose-btn btn-block\">\n" +
    "          <i class=\"fa fa-edit\"></i> Compose </a>\n" +
    "        <ul class=\"inbox-nav\">\n" +
    "          <li class=\"active\">\n" +
    "            <a href=\"javascript:;\" data-type=\"inbox\" data-title=\"Inbox\"> Inbox\n" +
    "                                                <span class=\"badge badge-success\">3</span>\n" +
    "                                            </a>\n" +
    "          </li>\n" +
    "          <li>\n" +
    "            <a href=\"javascript:;\" data-type=\"important\" data-title=\"Inbox\"> Important </a>\n" +
    "          </li>\n" +
    "          <li>\n" +
    "            <a href=\"javascript:;\" data-type=\"sent\" data-title=\"Sent\"> Sent </a>\n" +
    "          </li>\n" +
    "          <li>\n" +
    "            <a href=\"javascript:;\" data-type=\"draft\" data-title=\"Draft\"> Draft\n" +
    "                                                <span class=\"badge badge-danger\">8</span>\n" +
    "                                            </a>\n" +
    "          </li>\n" +
    "          <li class=\"divider\"></li>\n" +
    "          <li>\n" +
    "            <a href=\"javascript:;\" class=\"sbold uppercase\" data-title=\"Trash\"> Trash\n" +
    "                                                <span class=\"badge badge-info\">23</span>\n" +
    "                                            </a>\n" +
    "          </li>\n" +
    "        </ul>\n" +
    "        <ul class=\"inbox-contacts\">\n" +
    "          <li class=\"divider margin-bottom-30\"></li>\n" +
    "          <li>\n" +
    "            <a href=\"javascript:;\">\n" +
    "              <img class=\"contact-pic\" src=\"http://www.gravatar.com/avatar/000000000000000000000?d=mm\">\n" +
    "              <span class=\"contact-name\">Lisa Wong</span>\n" +
    "              <span class=\"contact-status bg-red\"></span>\n" +
    "            </a>\n" +
    "          </li>\n" +
    "          <li>\n" +
    "            <a href=\"javascript:;\">\n" +
    "              <img class=\"contact-pic\" src=\"http://www.gravatar.com/avatar/000000000000000000000?d=mm\">\n" +
    "              <span class=\"contact-name\">Nick Strong</span>\n" +
    "              <span class=\"contact-status bg-green\"></span>\n" +
    "            </a>\n" +
    "          </li>\n" +
    "          <li>\n" +
    "            <a href=\"javascript:;\">\n" +
    "              <img class=\"contact-pic\" src=\"http://www.gravatar.com/avatar/000000000000000000000?d=mm\">\n" +
    "              <span class=\"contact-name\">Anna Bold</span>\n" +
    "              <span class=\"contact-status bg-yellow\"></span>\n" +
    "            </a>\n" +
    "          </li>\n" +
    "        </ul>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"col-md-10\">\n" +
    "      <div class=\"inbox-body\">\n" +
    "        <div class=\"inbox-header\">\n" +
    "          <h1 class=\"pull-left\">Inbox</h1>\n" +
    "        </div>\n" +
    "        <div class=\"inbox-content\">\n" +
    "          <table class=\"table table-striped table-advance table-hover\">\n" +
    "            <thead>\n" +
    "              <tr>\n" +
    "                <th colspan=\"3\">\n" +
    "                  <div class=\"checker\">\n" +
    "                    <span>\n" +
    "                      <input type=\"checkbox\" class=\"mail-checkbox mail-group-checkbox\">\n" +
    "                    </span>\n" +
    "                  </div>\n" +
    "                </th>\n" +
    "                <th class=\"pagination-control\" colspan=\"3\">\n" +
    "                  <span class=\"pagination-info\"> 1-30 of 789 </span>\n" +
    "                  <a class=\"btn btn-sm blue btn-outline\">\n" +
    "                    <i class=\"icomoon icon-icomoon-arrow-left2\"></i>\n" +
    "                  </a>\n" +
    "                  <a class=\"btn btn-sm blue btn-outline\">\n" +
    "                    <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "                  </a>\n" +
    "                </th>\n" +
    "              </tr>\n" +
    "            </thead>\n" +
    "            <tbody>\n" +
    "              <tr class=\"unread\" data-messageid=\"1\">\n" +
    "                <td class=\"inbox-small-cells\">\n" +
    "                  <div class=\"checker\">\n" +
    "                    <span>\n" +
    "                      <input type=\"checkbox\" class=\"mail-checkbox\">\n" +
    "                    </span>\n" +
    "                  </div>\n" +
    "                </td>\n" +
    "                <td class=\"inbox-small-cells\">\n" +
    "                  <i class=\"fa fa-star\"></i>\n" +
    "                </td>\n" +
    "                <td class=\"view-message hidden-xs\"> Sample Subject </td>\n" +
    "                <td class=\"view-message \"> New server for datacenter needed </td>\n" +
    "                <td class=\"view-message inbox-small-cells\">\n" +
    "                  <i class=\"fa fa-paperclip\"></i>\n" +
    "                </td>\n" +
    "                <td class=\"view-message text-right\"> 4:30 PM </td>\n" +
    "              </tr>\n" +
    "            </tbody>\n" +
    "          </table>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div-->\n" +
    "")

$templateCache.put("src/common/password-change.tpl.html","<div ng-controller=\"PasswordChangeCtrl\">\n" +
    "  <change-password-page change-password=\"changePassword\" invalid-url=\"invalidUrl\" $state=\"$state\"></change-password-page>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/password-invite-set.tpl.html","<!-- BEGIN SET NEW PASSWORD FORM -->\n" +
    "<form role=\"form\" name=\"passwordForm\" angular-validator\n" +
    "      angular-validator-submit=\"submit(passwordForm)\" novalidate ng-if=\"!invalidUrl\">\n" +
    "    <h3>Set the password to your new account</h3>\n" +
    "    <div class=\"form-group\">\n" +
    "      <label>New</label>\n" +
    "       <div name=\"password\"\n" +
    "            ng-model=\"formModel.password\"\n" +
    "            password-strength-input></div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label>Confirm</label>\n" +
    "      <div class=\"input-icon\">\n" +
    "        <i class=\"icomoon icon-icomoon-lock\"></i>\n" +
    "        <input type=\"password\" ng-disabled=\"!formModel.password\" class=\"form-control\"\n" +
    "              ng-model=\"formModel.confirmPassword\"\n" +
    "              name=\"confirmPassword\"\n" +
    "              validate-on=\"blur\"\n" +
    "              placeholder=\"New password\"\n" +
    "              validator=\"formModel.confirmPassword === formModel.password\"\n" +
    "              invalid-message=\"'Passwords do not match'\"\n" +
    "              required-message=\"'This field is required'\"\n" +
    "              required/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"form-actions\" style=\"margin-bottom: 12px\">\n" +
    "        <button type=\"submit\" ng-disabled=\"!formModel.password\" class=\"btn btn-primary pull-right\">Set Password</button>\n" +
    "    </div>\n" +
    "</form>\n" +
    "<!-- END SET NEW PASSWORD FORM -->\n" +
    "")

$templateCache.put("src/common/password-reset.tpl.html","<forgot-password-page></forgot-password-page>\n" +
    "")

$templateCache.put("src/common/patient-add-basic.partial.tpl.html","  <h3 class=\"form-section\">Patient Information</h3>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label>Prefix</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <div class=\"btn-group pull-right\">\n" +
    "          <label class=\"btn btn-default btn-wide-75\" ng-model=\"patient.title\" uib-btn-radio=\"'mr'\" uncheckable>Mr.</label>\n" +
    "          <label class=\"btn btn-default btn-wide-75\" ng-model=\"patient.title\" uib-btn-radio=\"'mrs'\" uncheckable>Mrs.</label>\n" +
    "          <label class=\"btn btn-default btn-wide-75\" ng-model=\"patient.title\" uib-btn-radio=\"'ms'\" uncheckable>Ms.</label>\n" +
    "          <label class=\"btn btn-default btn-wide-75\" ng-model=\"patient.title\" uib-btn-radio=\"'dr'\" uncheckable>Dr.</label>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label>Sex</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <div class=\"btn-group pull-right\">\n" +
    "            <label class=\"btn btn-default btn-wide\" ng-model=\"patient.gender\" uib-btn-radio=\"'m'\">Male</label>\n" +
    "            <label class=\"btn btn-default btn-wide\" ng-model=\"patient.gender\" uib-btn-radio=\"'f'\">Female</label>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group row\" id=\"patientNameFormGroup\">\n" +
    "      <div class=\"col-sm-6\">\n" +
    "        <label>Patient Name</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-3 form-group\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"firstname\"\n" +
    "               ng-model=\"patient.firstName\"\n" +
    "               placeholder=\"First Name\"\n" +
    "               validate-on=\"blur\"\n" +
    "               ng-minlength=2\n" +
    "               required-message=\"'This field is required'\"\n" +
    "               invalid-message=\"'Minimum length is 2 letters.'\"\n" +
    "               autofocus\n" +
    "               required />\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-1 form-group\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"middlename\"\n" +
    "               size=\"1\"\n" +
    "               maxlength=\"1\"\n" +
    "               ng-minlength=\"0\"\n" +
    "               ng-maxlength=\"1\"\n" +
    "               validate-on=\"blur\"\n" +
    "               ng-pattern=\"/^[A-z]$/\"\n" +
    "               invalid-message=\"'Middle initial is one character'\"\n" +
    "               ng-model=\"patient.middleInitial\"\n" +
    "               placeholder=\"M.I.\" />\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-2 form-group\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"lastname\"\n" +
    "               ng-model=\"patient.lastName\"\n" +
    "               ng-minlength=2\n" +
    "               placeholder=\"Last Name\"\n" +
    "               validate-on=\"blur\"\n" +
    "               required-message=\"'This field is required'\"\n" +
    "               invalid-message=\"'Minimum length is 2 letters.'\"\n" +
    "               required />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-6\">\n" +
    "        <label>Date of Birth</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-6 form-group\">\n" +
    "\n" +
    "        <custom-datepicker-popup show-placeholder=\"false\" ng-model=\"patient.birthDate\" input-name=\"'patientBirtInputDate'\" form-name=\"'addPatient'\" format=\"'MM/dd/yyyy'\" ng-required=\"false\"/>\n" +
    "\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-2\">\n" +
    "        <label>Email</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-4\">\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"col-sm-6\">\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"col-md-5\">\n" +
    "            <input type=\"email\"\n" +
    "                   name=\"email\"\n" +
    "                   placeholder=\"Email\"\n" +
    "                   validate-on=\"blur\"\n" +
    "                   class=\"form-control\"\n" +
    "                   invalid-message=\"'Not a valid email address'\"\n" +
    "                   ng-model=\"patient.email\"\n" +
    "                   ng-change=\"emailChanged()\"\n" +
    "                   ng-pattern=\"/^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$/\"\n" +
    "            />\n" +
    "          </div>\n" +
    "          <div class=\"col-md-7\">\n" +
    "            <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "              <label>\n" +
    "                <input type=\"checkbox\" name=\"patient-add-send-form\" ng-change=\"sendFormChanged()\" ng-model=\"$parent.sendForm\" ng-disabled=\"!patient.email || addPatient.email.$invalid\">\n" +
    "                <span class=\"custom-control-indicator\"></span>\n" +
    "                Send form to patient when added\n" +
    "              </label>\n" +
    "            </div>\n" +
    "            <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "              <label>\n" +
    "                <input type=\"checkbox\" name=\"use_pin\" ng-model=\"$parent.usePin\" ng-disabled=\"!$parent.sendForm\">\n" +
    "                <span class=\"custom-control-indicator\"></span>\n" +
    "                Use a PIN for extra protection (fill mobile phone to sms pin to patient)\n" +
    "              </label>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-6\">\n" +
    "        <label>Phone Number</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-3\">\n" +
    "        <phone-input ng-model=\"patient.homePhone\"\n" +
    "                     name=\"home-phone\"\n" +
    "                     placeholder=\"Home Phone\"\n" +
    "                     ng-required=\"false\" />\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-3\">\n" +
    "        <phone-input ng-model=\"patient.mobilePhone\"\n" +
    "                     name=\"mobile-phone\"\n" +
    "                     placeholder=\"Mobile Phone\"\n" +
    "                     ng-required=\"false\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-2\">\n" +
    "        <label>Mailing Address</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-6 col-sm-offset-6 form-group\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-model=\"patient.patientAddressStreet1\"\n" +
    "               google-places-auto-complete\n" +
    "               zip=\"patient.patientAddressZipCode\"\n" +
    "               state=\"patient.patientAddressState\"\n" +
    "               city=\"patient.patientAddressCity\"\n" +
    "               placeholder=\"Street Address\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-6 col-sm-offset-6 form-group\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-model=\"patient.patientAddressStreet2\"\n" +
    "               placeholder=\"Street Address cont.\"/>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "\n" +
    "      <div class=\"col-sm-6\">\n" +
    "        <label>City, State and Zip</label>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"col-sm-2 form-group\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-model=\"patient.patientAddressCity\"\n" +
    "               placeholder=\"City\"/>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"col-sm-2 form-group\">\n" +
    "        <state-input ng-model=\"patient.patientAddressState\"\n" +
    "                     name=\"patient-address-state\"\n" +
    "                     ng-required=\"false\" />\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"col-sm-2 form-group\">\n" +
    "        <zip-input name=\"patient-address-zip\"\n" +
    "                   ng-model=\"patient.patientAddressZipCode\"\n" +
    "                   ng-required=\"false\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "")

$templateCache.put("src/common/patient-add.tpl.html","<form name=\"addPatient\" role=\"form\" class=\"form-horzizontal\" angular-validator angular-validator-submit=\"submit()\" novalidate>\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Add Patient</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"col-xs-12\">\n" +
    "          <div class=\"form-body\">\n" +
    "            <div ng-include=\"'src/common/patient-add-basic.partial.tpl.html'\"></div>\n" +
    "          </div>\n" +
    "          <div class=\"form-actions\">\n" +
    "          </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <div class=\"pull-right\">\n" +
    "      <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "        <label ng-if=\"showGoToPatientDetail\">\n" +
    "          <input type=\"checkbox\" class=\" custom-control custom-checkbox\" name=\"patient-open-detail\" ng-model=\"$parent.openPatientDetail\" ng-disabled=\"created\">\n" +
    "          <span class=\"custom-control-indicator\"></span>\n" +
    "          Go to patient detail after add\n" +
    "        </label>\n" +
    "      </div>\n" +
    "      <button id=\"addNewPatientBtn\" class=\"btn p-x btn-primary\" type=\"submit\" ng-disabled=\"creating || created\">Add</button>\n" +
    "      <a ng-click=\"close()\" type=\"button\" class=\"btn btn-default pull-right\">Cancel</a>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</form>\n" +
    "")

$templateCache.put("src/common/patient-detail-billing.tpl.html","<div class=\"row\" ng-if=\"!invoiceId\">\n" +
    "	<div class=\"col-md-12\">\n" +
    "		<div class=\"portlet light porlet-fit\">\n" +
    "			<div class=\"portlet-title\">\n" +
    "				<div class=\"caption\">\n" +
    "					<i class=\"icomoon icon-icomoon-calculator3 font-red-sunglo\"></i>\n" +
    "					<span class=\"caption-subject font-red-sunglo bold uppercase\">Treatments not invoiced</span>\n" +
    "				</div>\n" +
    "				<div class=\"actions\">\n" +
    "					<div class=\"btn-group\">\n" +
    "						<button ng-disabled=\"!CanCreateInvoice || !enableNewInvoiceButton\" type=\"button\" class=\"btn p-x btn-primary\"\n" +
    "              ng-click=\"newInvoiceClicked();\">\n" +
    "							New invoice\n" +
    "						</button>\n" +
    "					</div>\n" +
    "				</div>\n" +
    "			</div>\n" +
    "      <div class=\"portlet-body\" ui-view=\"treatments\"></div>\n" +
    "		</div>\n" +
    "	</div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "	<div class=\"col-md-12\">\n" +
    "		<div class=\"portlet light porlet-fit pos-r\">\n" +
    "      <wave-spinner class=\"modal-loader\" ng-if=\"loadingJeds\"></wave-spinner>\n" +
    "			<div class=\"portlet-title\">\n" +
    "				<div class=\"caption\" ng-hide=\"buildStatement\">\n" +
    "					<i class=\"icomoon icon-icomoon-cash3 font-red-sunglo\"></i>\n" +
    "					<span ng-if=\"!invoiceId\" class=\"caption-subject font-red-sunglo bold uppercase\">Patient Account Receivables\n" +
    "            <span ng-if=\"accountBalance\">: Balance = {{accountBalance.totalAmount}} {{practice.currency}}\n" +
    "            </span>\n" +
    "          </span>\n" +
    "					<span ng-if=\"invoiceId\" class=\"caption-subject\">Invoice Account Receivables\n" +
    "            <span ng-if=\"accountBalance\">: Balance = {{accountBalance.totalAmount}} {{practice.currency}}\n" +
    "            </span>\n" +
    "          </span>\n" +
    "				</div>\n" +
    "				<div class=\"caption bold uppercase\" ng-show=\"buildStatement\">\n" +
    "					<span class=\"caption-subject\">Build Statement\n" +
    "            <span ng-if=\"statementTotal\">Total: {{statementTotal}} {{practice.currency}}\n" +
    "            </span>\n" +
    "          </span>\n" +
    "        </div>\n" +
    "				<div class=\"actions\">\n" +
    "\n" +
    "          <div class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "            <label style=\"margin-right:4px;\">Replace payment portions with a single payment total?</label>\n" +
    "          </div>\n" +
    "          <div class=\"checkbox-inline custom-control custom-checkbox m-x-0\">\n" +
    "            <label>\n" +
    "              <input\n" +
    "                type=\"checkbox\"\n" +
    "                ng-model=\"totalPaymentLineitems\"\n" +
    "              >\n" +
    "              <span class=\"custom-control-indicator\"></span>\n" +
    "            </label>\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "          <div class=\"btn-group pull-right\" dropdown>\n" +
    "						<button type=\"button\" class=\"btn p-x btn-primary\" ng-click=\"openAddPaymentModal('Payment / Refund', '', practice.currency, practice.merchant_id, patient.ar_gl_account, practice.cash_gl_account, jeds, false, animationsEnabled)\" ng-disabled=\"!CanUpdateBilling || !enablePaymentButton\" ng-hide=\"buildStatement\">\n" +
    "							Payment/Refund\n" +
    "						</button>\n" +
    "            <button ng-hide=\"buildStatement\" class=\"btn p-x btn-primary\" ng-click=\"triggerBuildStatement()\"  style=\"margin-left: 6px;\">\n" +
    "              Build Statement\n" +
    "            </button>\n" +
    "\n" +
    "\n" +
    "\n" +
    "            <button ng-show=\"buildStatement\" class=\"btn p-x btn-primary\" ng-click=\"printStatement(totalPaymentLineitems, accountBalance.totalAmount, (invoiceId) ? invoice.invoice_number : null);\" ng-disabled=\"!enablePrintStatementButton\">\n" +
    "              <i class=\"icomoon icon-icomoon-printer2\"></i> Print Statement\n" +
    "            </button>\n" +
    "            <button ng-show=\"buildStatement\" class=\"btn p-x btn-primary\" ng-click=\"buildStatement = false; totalPaymentLineitems = false\"  style=\"margin-left: 6px;\">\n" +
    "              Cancel Build Statement\n" +
    "            </button>\n" +
    "					</div>\n" +
    "				</div>\n" +
    "			</div>\n" +
    "			<div class=\"portlet-body\">\n" +
    "\n" +
    "				<div style=\"overflow-x: auto;\">\n" +
    "					<table class=\"table table-bordered table-advance\">\n" +
    "            <thead>\n" +
    "						<tr>\n" +
    "							<th class=\"col-md-1\" ng-if=\"!buildStatement && !totalPaymentLineitems\">Pay</th>\n" +
    "							<th class=\"col-md-1\" ng-if=\"buildStatement\">Include on Statement</th>\n" +
    "              <th class=\"col-md-1\" ng-if=\"!buildStatement && totalPaymentLineitems\"></th>\n" +
    "							<th class=\"col-md-1\">Recorded</th>\n" +
    "              <th class=\"col-md-1\">#</th>\n" +
    "							<th class=\"col-md-3\">Description</th>\n" +
    "							<th class=\"col-md-1\" style=\"text-align:right;\">Amount</th>\n" +
    "							<th class=\"col-md-1\">Type</th>\n" +
    "							<th class=\"col-md-1\"></th>\n" +
    "							<th class=\"col-md-1\"></th>\n" +
    "							<th class=\"col-md-1\">By</th>\n" +
    "							<th class=\"col-md-1\"></th>\n" +
    "						</tr>\n" +
    "            </thead>\n" +
    "						<tr ng-repeat=\"jed in displayJeds | orderBy: 'created': true\">\n" +
    "              <td ng-if=\"!buildStatement\">\n" +
    "                <div class=\"checkbox-inline custom-control custom-checkbox\" ng-if=\"jed.arjed && jed.arjed.type.indexOf('invoicelineitem') !== -1\">\n" +
    "                  <label>\n" +
    "                    <input type=\"checkbox\"\n" +
    "                      ng-change=\"lineItemCheckChanged()\"\n" +
    "                      ng-disabled=\"!CanUpdateBilling\"\n" +
    "                      ng-model=\"jed.includeInPayment\"\n" +
    "                    >\n" +
    "                    <span class=\"custom-control-indicator\"></span>\n" +
    "                  </label>\n" +
    "                </div>\n" +
    "              </td>\n" +
    "              <td ng-if=\"buildStatement\">\n" +
    "                <div class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "                  <label>\n" +
    "                    <input type=\"checkbox\"\n" +
    "                      ng-change=\"lineItemStatementCheckChanged()\"\n" +
    "                      ng-init=\"(invoiceId) ? jed.includeInStatement = true : (isToday(jed.created) ? jed.includeInStatement = true : jed.includeInStatement = false)\"\n" +
    "                      ng-model=\"jed.includeInStatement\">\n" +
    "                    <span class=\"custom-control-indicator\"></span>\n" +
    "                  </label>\n" +
    "                </div>\n" +
    "              </td>\n" +
    "							<td ng-class=\"{active:jed.arjed.type=='insurancediscountrevenueinvoicelineitem',success:jed.arjed.type=='claimerinvoicelineitem' || jed.arjed.type=='claimee',danger:jed.arjed.type=='payment',warning:jed.arjed.type=='writeoff',info:jed.arjed.type=='chargeback'}\">\n" +
    "                {{jed.created | date:\"MM/dd/yyyy\"}}</td>\n" +
    "              <td ng-class=\"{active:jed.arjed.type=='insurancediscountrevenueinvoicelineitem',success:jed.arjed.type=='claimerinvoicelineitem' || jed.arjed.type=='claimee',danger:jed.arjed.type=='payment',warning:jed.arjed.type=='writeoff',info:jed.arjed.type=='chargeback'}\">\n" +
    "								<span ng-if=\"jed.arjed && jed.arjed.type=='payment'\">{{jed.arjed.reference_number}}</span>\n" +
    "								<span ng-if=\"!jed.arjed || jed.arjed.type!=='payment'\">{{jed.id}}</span>\n" +
    "              </td>\n" +
    "\n" +
    "              <td ng-if=\"!totalPaymentLineitems || buildStatement\" ng-class=\"{active:jed.arjed.type=='insurancediscountrevenueinvoicelineitem',success:jed.arjed.type=='claimerinvoicelineitem' || jed.arjed.type=='claimee',danger:jed.arjed.type=='payment',warning:jed.arjed.type=='writeoff',info:jed.arjed.type=='chargeback'}\"  ng-attr-title=\"{{(jed.memo) ? jed.memo:null}}\">\n" +
    "                <span ng-if=\"jed.arjed && jed.arjed.type==='revenueinvoicelineitem' && jed.arjed.treatment && jed.arjed.treatment.is_abandoned\"\n" +
    "                  class=\"icomoon icon-icomoon-warning\"\n" +
    "                  style=\"color:red;font-weight:bold;font-size:large;\"\n" +
    "                  uib-popover-template=\"'abandoned-alert.html'\"\n" +
    "                  popover-placement=\"left\"\n" +
    "                  popover-append-to-body=\"true\"\n" +
    "                ></span>\n" +
    "                {{jed.arjed.payment ? 'Portion of ' + jed.description + ' totaling ' +  jed.arjed.payment.total + ' ' + practice.currency + ' applied to #' + jed.arjed.payment_for : jed.description}}\n" +
    "              </td>\n" +
    "							<td ng-if=\"totalPaymentLineitems && !buildStatement\" ng-class=\"{active:jed.arjed.type=='insurancediscountrevenueinvoicelineitem',success:jed.arjed.type=='claimerinvoicelineitem' || jed.arjed.type=='claimee',danger:jed.arjed.type=='payment',warning:jed.arjed.type=='writeoff',info:jed.arjed.type=='chargeback'}\"  ng-attr-title=\"{{(jed.memo) ? jed.memo:null}}\">\n" +
    "                <span ng-if=\"jed.arjed && jed.arjed.type==='revenueinvoicelineitem' && jed.arjed.treatment && jed.arjed.treatment.is_abandoned\"\n" +
    "                  class=\"icomoon icon-icomoon-warning\"\n" +
    "                  style=\"color:red;font-weight:bold;font-size:large;\"\n" +
    "                  uib-popover-template=\"'abandoned-alert.html'\"\n" +
    "                  popover-placement=\"left\"\n" +
    "                  popover-append-to-body=\"true\"\n" +
    "                ></span>\n" +
    "                {{jed.description}}\n" +
    "              </td>\n" +
    "\n" +
    "              <td ng-if=\"jed.arjed.type!=='payment' || !totalPaymentLineitems || buildStatement\" style=\"text-align:right;\" ng-class=\"{active:jed.arjed.type=='insurancediscountrevenueinvoicelineitem',success:jed.arjed.type=='claimerinvoicelineitem' || jed.arjed.type=='claimee',danger:jed.arjed.type=='payment',warning:jed.arjed.type=='writeoff',info:jed.arjed.type=='chargeback'}\">\n" +
    "                {{jed.amount}}\n" +
    "              </td>\n" +
    "              <td ng-if=\"jed.arjed.type==='payment' && totalPaymentLineitems && !buildStatement\" style=\"text-align:right;\" ng-class=\"{active:jed.arjed.type=='insurancediscountrevenueinvoicelineitem',success:jed.arjed.type=='claimerinvoicelineitem' || jed.arjed.type=='claimee',danger:jed.arjed.type=='payment',warning:jed.arjed.type=='writeoff',info:jed.arjed.type=='chargeback'}\">\n" +
    "                {{jed.arjed.payment.total}}\n" +
    "              </td>\n" +
    "\n" +
    "							<td ng-class=\"{active:jed.arjed.type=='insurancediscountrevenueinvoicelineitem',success:jed.arjed.type=='claimerinvoicelineitem' || jed.arjed.type=='claimee',danger:jed.arjed.type=='payment',warning:jed.arjed.type=='writeoff',info:jed.arjed.type=='chargeback'}\">\n" +
    "								<span ng-if=\"jed.type\">{{jed.type}}</span>\n" +
    "              </td>\n" +
    "              <td>\n" +
    "								<span\n" +
    "                  ng-if=\"jed.arjed && jed.arjed.invoice && (jed.arjed.type.indexOf('revenueinvoicelineitem') !== -1)\"\n" +
    "                >\n" +
    "                  <a\n" +
    "                    ng-class=\"{'bg-warning': jed.arjed.invoiceBalance > 0 && jed.arjed.invoicePastDueDays < 30 && jed.arjed.invoicePastDueDays > 15, 'bg-danger': jed.arjed.invoiceBalance > 0 && jed.arjed.invoicePastDueDays > 30, 'link-disabled': !CanRetrieveInvoice}\"\n" +
    "                    ui-sref=\"invoicedetail({invoiceid: jed.arjed.invoice.id})\"\n" +
    "                  >\n" +
    "                    {{jed.arjed.invoice.invoice_number}}\n" +
    "                  </a>\n" +
    "                  <span\n" +
    "                    class=\"icomoon icon-icomoon-info2\"\n" +
    "                    uib-popover-template=\"'inv-total-remaining-pastdue-payments-writeoffs.html'\"\n" +
    "                  >\n" +
    "                  </span>\n" +
    "                </span>\n" +
    "								<span\n" +
    "                  ng-if=\"jed.arjed && jed.arjed.invoice && (jed.arjed.type === 'claimerinvoicelineitem')\"\n" +
    "                >\n" +
    "                  <a\n" +
    "                    ng-class=\"{'bg-warning': jed.arjed.invoiceBalance > 0 && jed.arjed.invoicePastDueDays < 30 && jed.arjed.invoicePastDueDays > 15, 'bg-danger': jed.arjed.invoiceBalance > 0 && jed.arjed.invoicePastDueDays > 30}\"\n" +
    "                    ui-sref=\"invoicedetail({invoiceid: jed.arjed.invoice.id})\"\n" +
    "                  >\n" +
    "                    {{jed.arjed.invoice.invoice_number}}\n" +
    "                  </a>\n" +
    "                  <span\n" +
    "                    class=\"icomoon icon-icomoon-info2\"\n" +
    "                    uib-popover-template=\"'claimer-total-remaining-pastdue-payments-writeoffs-chargebacks.html'\"\n" +
    "                  >\n" +
    "                  </span>\n" +
    "                </span>\n" +
    "                <span\n" +
    "                  ng-if=\"jed.arjed && jed.arjed.invoice && (jed.arjed.type === 'miscinvoicelineitem')\"\n" +
    "                >\n" +
    "                  <a\n" +
    "                    ng-class=\"{'bg-warning': jed.arjed.invoiceBalance > 0 && jed.arjed.invoicePastDueDays < 30 && jed.arjed.invoicePastDueDays > 15, 'bg-danger': jed.arjed.invoiceBalance > 0 && jed.arjed.invoicePastDueDays > 30}\"\n" +
    "                    ui-sref=\"invoicedetail({invoiceid: jed.arjed.invoice.id})\"\n" +
    "                  >\n" +
    "                    {{jed.arjed.invoice.invoice_number}}\n" +
    "                  </a>\n" +
    "                  <span\n" +
    "                    class=\"icomoon icon-icomoon-info2\"\n" +
    "                    uib-popover-template=\"'inv-total-remaining-pastdue-payments-writeoffs.html'\"\n" +
    "                  >\n" +
    "                  </span>\n" +
    "                </span>\n" +
    "								<span\n" +
    "                  ng-if=\"jed.arjed && jed.arjed.invoice && jed.arjed.type == 'writeoff'\"\n" +
    "                >\n" +
    "                  <a\n" +
    "                    ng-class=\"{'bg-warning': jed.arjed.invoiceBalance > 0 && jed.arjed.invoicePastDueDays < 30 && jed.arjed.invoicePastDueDays > 15, 'bg-danger': jed.arjed.invoiceBalance > 0 && jed.arjed.invoicePastDueDays > 30, 'link-disabled': !CanRetrieveInvoice}\"\n" +
    "                    ui-sref=\"invoicedetail({invoiceid: jed.arjed.invoice.id})\"\n" +
    "                  >\n" +
    "                    {{jed.arjed.invoice.invoice_number}}\n" +
    "                  </a>\n" +
    "                  <span\n" +
    "                    class=\"icomoon icon-icomoon-info2\"\n" +
    "                    uib-popover-template=\"'inv-total-remaining-pastdue-writeofffor.html'\"\n" +
    "                  >\n" +
    "                  </span>\n" +
    "                </span>\n" +
    "						    <span\n" +
    "                  ng-if=\"jed.arjed && jed.arjed.invoice && jed.arjed.type === 'payment'\"\n" +
    "                >\n" +
    "                  <a\n" +
    "                    ng-class=\"{'bg-warning': jed.arjed.invoiceBalance > 0 && jed.arjed.invoicePastDueDays < 30 && jed.arjed.invoicePastDueDays > 15, 'bg-danger': jed.arjed.invoiceBalance > 0 && jed.arjed.invoicePastDueDays > 30, 'link-disabled': !CanRetrieveInvoice}\"\n" +
    "                    ui-sref=\"invoicedetail({invoiceid: jed.arjed.invoice.id})\"\n" +
    "                  >\n" +
    "                    {{jed.arjed.invoice.invoice_number}}\n" +
    "                  </a>\n" +
    "                  <span\n" +
    "                    class=\"icomoon icon-icomoon-info2\"\n" +
    "                    uib-popover-template=\"'inv-total-remaining-pastdue-paymentfor.html'\"\n" +
    "                    ng-if=\"!totalPaymentLineitems || buildStatement\"\n" +
    "                  >\n" +
    "                  </span>\n" +
    "                </span>\n" +
    "						    <span\n" +
    "                  ng-if=\"jed.arjed && jed.arjed.invoice && jed.arjed.type === 'chargeback'\"\n" +
    "                >\n" +
    "                  <a\n" +
    "                    ng-class=\"{'bg-warning': jed.arjed.invoiceBalance > 0 && jed.arjed.invoicePastDueDays < 30 && jed.arjed.invoicePastDueDays > 15, 'bg-danger': jed.arjed.invoiceBalance > 0 && jed.arjed.invoicePastDueDays > 30, 'link-disabled': !CanRetrieveInvoice}\"\n" +
    "                    ui-sref=\"invoicedetail({invoiceid: jed.arjed.invoice.id})\"\n" +
    "                  >\n" +
    "                    {{jed.arjed.invoice.invoice_number}}\n" +
    "                  </a>\n" +
    "                  <span\n" +
    "                    class=\"icomoon icon-icomoon-info2\"\n" +
    "                    uib-popover-template=\"'inv-total-remaining-pastdue-chargebackfor.html'\"\n" +
    "                  >\n" +
    "                  </span>\n" +
    "                </span>\n" +
    "							</td>\n" +
    "							<td>\n" +
    "								<div class=\"btn-group\" uib-dropdown ng-show=\"jed.arjed.treatment.encounters.length > 0\">\n" +
    "									<button ng-disabled=\"!CanRetrieveEncounter\" type=\"button\" class=\"btn btn-default btn-sm dropdown-toggle\" uib-dropdown-toggle>\n" +
    "										Encounters\n" +
    "										<span class=\"caret\"></span>\n" +
    "									</button>\n" +
    "									<ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "										<li ng-repeat=\"e in jed.arjed.treatment.encounters | orderBy: 'encounter_date'\"><a ui-sref=\"chart-detail({encounterid: e.id})\">{{(e.encounter_date | date:'MM-dd-yyyy') + \" - \" + e.title}}</a></li>\n" +
    "									</ul>\n" +
    "								</div>\n" +
    "							</td>\n" +
    "							<td>{{jed.by_username}}</td>\n" +
    "							<td>\n" +
    "								<div class=\"btn-group\" uib-dropdown ng-if=\"jed.arjed.type.indexOf('invoicelineitem') !== -1\" ng-show=\"jed.arjed && jed.arjed.type.indexOf('invoicelineitem') !== -1\">\n" +
    "									<button ng-disabled=\"!CanUpdateBilling && !CanRetrieveInvoice\" type=\"button\" class=\"btn btn-default btn-sm dropdown-toggle\" uib-dropdown-toggle>\n" +
    "										Actions\n" +
    "										<span class=\"caret\"></span>\n" +
    "									</button>\n" +
    "									<ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "										<li ng-show=\"CanUpdateBilling\"><a ng-click=\"openAddJEModal(\n" +
    "                      'Writeoff / Writeoff Reversal',\n" +
    "                      '',\n" +
    "                      practice.currency,\n" +
    "                      patient.ar_gl_account,\n" +
    "                      'writeoff',\n" +
    "                      jed.arjed.treatment,\n" +
    "                      jed.arjed.claim,\n" +
    "                      jed.arjed.invoice,\n" +
    "                      practice.writeoff_gl_account,\n" +
    "                      jed.arjed.remaining,\n" +
    "                      null,\n" +
    "                      jed,\n" +
    "                      animationsEnabled,\n" +
    "                      'Negative amounts represent writeoffs, which will reduce Receivables Balance and incur bad debt expense. Positive amounts represent writeoff reversals, which will increase Receivables Balance and reduce bad debt expense.'\n" +
    "                      )\">Writeoff / Writeoff Reversal</a></li>\n" +
    "                      <li ng-show=\"CanUpdateBilling\"\n" +
    "                        ng-if=\"!jed.arjed.claim && jed.arjed && jed.arjed.type === 'revenueinvoicelineitem' && patient.insurance_plan_info.payer_id\"\n" +
    "                      ><a ng-click=\"openAddARModal(\n" +
    "                        'Add Claim Amount',\n" +
    "                        'POST INVOICE CORRECTION: Insurance claim for treatment ' + jed.description,\n" +
    "                        practice.currency,\n" +
    "                        patient.ar_gl_account,\n" +
    "                        'claimerinvoicelineitem',\n" +
    "                        jed.arjed.treatment,\n" +
    "                        null,\n" +
    "                        jed.arjed.invoice,\n" +
    "                        practice.insurance_ar_gl_account,\n" +
    "                        'claimee',\n" +
    "                        null,\n" +
    "                        null,\n" +
    "                        '0.00',\n" +
    "                        animationsEnabled,\n" +
    "                        'Positive amount will credit (-) patient AR account and create a claim debit (+) in the patient\\'s insurance tab. When claiming in the insurance tab, ensure that a plan is set in the patient profile. This claim will not be considered in estimates for how much non-invoiced line items will reduce maximums.'\n" +
    "                        )\">Add Claim Amount</a></li>\n" +
    "  <!--										<li ng-if=\"!invoiceId\" role=\"separator\" class=\"divider\"></li>\n" +
    "										<li ng-show=\"CanRetrieveInvoice\" ng-if=\"!invoiceId\" ><a ui-sref=\"invoicedetail({invoiceid: jed.arjed.invoice.id})\">View invoice</a></li>\n" +
    "										<li ng-if=\"!invoiceId\" role=\"separator\" class=\"divider\"></li>\n" +
    "										<li ng-show=\"CanRetrieveInvoice\" ng-if=\"!invoiceId\" ><a href='' ng-click='printInvoice(jed.arjed.invoice.id)'>Print invoice</a></li>\n" +
    "-->									</ul>\n" +
    "								</div>\n" +
    "							</td>\n" +
    "						</tr>\n" +
    "					</table>\n" +
    "				</div>\n" +
    "			</div>\n" +
    "		</div>\n" +
    "	</div>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"addPayment.html\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">{{ title }}</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <!-- this is a hack to show the swal backdrop on top of the modal backdrop -->\n" +
    "    <style>\n" +
    "      .sweet-overlay { z-index: 1051; }\n" +
    "    </style>\n" +
    "    <form name='myForm'>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label for=\"jemethod\">Payment Method:</label>\n" +
    "        <select class='form-control' ng-model=\"method\" ng-init=\"method='Patient Payment - Credit Card'\">\n" +
    "          <option>Patient Payment - Credit Card</option>\n" +
    "          <option>Patient Payment - Check</option>\n" +
    "          <option>Patient Payment - Cash</option>\n" +
    "          <option>Patient Payment - Care Credit</option>\n" +
    "        </select>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label for=\"jememo\">Payer and other info:</label>\n" +
    "        <textarea id=\"jememo\" class=\"form-control\" type=\"text\" ng-model=\"payer\"></textarea>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label for=\"jememo\">Memo:</label>\n" +
    "        <textarea id=\"jememo\" class=\"form-control\" type=\"text\" ng-model=\"newJournalEntryInfo.memo\"></textarea>\n" +
    "      </div>\n" +
    "      <div class=\"form-horizontal\">\n" +
    "        <div ng-form=\"innerform\" class=\"form-group has-feedback\" ng-repeat=\"jed in jedsWithArjedToPay | orderBy: 'created': true\" ng-class=\"{'has-error':innerform.amount.$invalid,'has-success':innerform.amount.$valid}\">\n" +
    "          <div class=\"col-md-2\">\n" +
    "            <input class=\"form-control\" type=\"text\" name=\"amount\" required\n" +
    "              ng-disabled=\"isProcessing || result.pg_response_type === 'A' || changeTotalAmount\"\n" +
    "              ng-model-options=\"{debounce:{blur:1,default:500}, updateOn:'default blur'}\"\n" +
    "              ng-model=\"jed.arjed.paymentAmount\"\n" +
    "              ng-pattern=\"AMOUNT_REGEX\"\n" +
    "            />\n" +
    "            <span ng-hide=\"changeTotalAmount\" class=\"icomoon icon-icomoon-checkmark form-control-feedback\" ng-class=\"{'icon-icomoon-checkmark':innerform.amount.$valid, 'icon-icomoon-warning':innerform.amount.$invalid}\"></span>\n" +
    "          </div>\n" +
    "          <div class=\"col-md-10\">\n" +
    "            <p class=\"form-control-static m-b-0\">{{ jed.description }}</p>\n" +
    "            <span class=\"help-block\" ng-if=\"innerform.amount.$invalid\">Amount must be a decimal number to the hundredths (e.g. 6.23).</span>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group has-feedback\" ng-class=\"{'has-success':myForm.amount.$valid, 'has-error':myForm.amount.$invalid}\" ng-show=\"showUnassociatedAmount\">\n" +
    "          <div class=\"col-md-2\">\n" +
    "            <input id=\"jeamt\" class=\"form-control\" name=\"amount\" type=\"text\" required\n" +
    "              ng-disabled=\"isProcessing || result.pg_response_type === 'A'\"\n" +
    "              ng-model-options=\"{debounce: {default:500, blur:1}, updateOn:'default blur'}\"\n" +
    "              ng-model=\"unassociatedAmount\"\n" +
    "              ng-pattern=\"AMOUNT_REGEX\"/>\n" +
    "            <span class=\"icomoon icon-icomoon-checkmark form-control-feedback\" ng-class=\"{'icon-icomoon-checkmark':myForm.amount.$valid, 'icon-icomoon-warning':myForm.amount.$invalid}\"></span>\n" +
    "          </div>\n" +
    "          <div class=\"col-md-10\">\n" +
    "            <p class=\"form-control-static m-b-0\">Amount unassociated with a treatment</p>\n" +
    "            <span class=\"help-block\" ng-if=\"myForm.amount.$invalid\">Amount must be a decimal number to the hundredths (e.g. 6.23).</span>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"alert alert-info\">\n" +
    "        <div ng-if=\"!changeTotalAmount\">\n" +
    "          <strong>Total amount</strong>\n" +
    "          <span ng-if=\"newJournalEntryInfo.amount && newJournalEntryInfo.amount !== 'NaN'\">\n" +
    "            {{ newJournalEntryInfo.amount }} {{ currency }}\n" +
    "          </span>\n" +
    "          <span ng-if=\"!newJournalEntryInfo.amount || newJournalEntryInfo.amount === 'NaN'\">\n" +
    "            INVALID AMOUNT\n" +
    "          </span>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group has-feedback\" ng-class=\"{'has-success':myForm.totalamount.$valid, 'has-error':myForm.totalamount.$invalid}\" ng-if=\"changeTotalAmount\">\n" +
    "          <div>\n" +
    "            <strong>Total amount</strong>\n" +
    "            <input id=\"pmtamt\" name=\"totalamount\" type=\"text\" required\n" +
    "              ng-disabled=\"isProcessing || result.pg_response_type === 'A'\"\n" +
    "              ng-model-options=\"{debounce: {default:500, blur:1}, updateOn:'default blur'}\"\n" +
    "              ng-model=\"newJournalEntryInfo.amount\"\n" +
    "              ng-pattern=\"AMOUNT_REGEX\"\n" +
    "            />\n" +
    "            <span class=\"icomoon icon-icomoon-checkmark form-control-feedback\" ng-class=\"{'icon-icomoon-checkmark':myForm.totalamount.$valid, 'icon-icomoon-warning':myForm.totalamount.$invalid}\"></span>\n" +
    "          </div>\n" +
    "          <div>\n" +
    "            <span class=\"help-block\" ng-if=\"myForm.totalamount.$invalid\">Amount must be a decimal number to the hundredths (e.g. 6.23).</span>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"checkbox-inline custom-control custom-checkbox m-x-0\" >\n" +
    "          <label>\n" +
    "            <input\n" +
    "              type=\"checkbox\"\n" +
    "              ng-model=\"changeTotalAmount\"\n" +
    "              ng-disabled=\"!newJournalEntryInfo.amount || newJournalEntryInfo.amount === 'NaN'\"\n" +
    "            >\n" +
    "            <span class=\"custom-control-indicator\"></span>\n" +
    "          </label>\n" +
    "        </div>\n" +
    "        <div class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "          <label style=\"margin-right:4px;\">Edit total amount to auto-calculate line items?</label>\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"form-group m-b\">\n" +
    "        <angular-forte-handler\n" +
    "          control='control'\n" +
    "          is-connected='isConnected'\n" +
    "          is-processing='isProcessing'\n" +
    "          pg-merchant-id='merchantId'\n" +
    "          pg-total-amount='newJournalEntryInfo.amount'\n" +
    "          result='result'\n" +
    "        />\n" +
    "        <button class=\"btn btn-danger\" type=\"button\"\n" +
    "          ng-disabled=\"!isConnected || isProcessing || myForm.amount.$invalid || result.pg_response_type === 'A'\"\n" +
    "          ng-click=\"control.triggerTransaction(transactionType)\">\n" +
    "          {{ transactionTypeDisplay }}\n" +
    "        </button>\n" +
    "\n" +
    "        <label ng-if=\"result\" ng-class=\"{'font-green-jungle': result.pg_response_type === 'A', 'font-red-sunglo': result.pg_response_type === 'D' || result.pg_response_type === 'E', 'font-blue-steel': !result.pg_response_type}\">\n" +
    "          ({{ result.pg_response_description ? result.pg_response_description : result.event }})\n" +
    "        </label>\n" +
    "\n" +
    "      </div>\n" +
    "      <div class=\"alert alert-warning m-b-0\">\n" +
    "        Payment required in: <strong>{{ currency }}</strong>.\n" +
    "        <br>\n" +
    "        Positive amounts represent payments and credit AR account and debit cash account. Negative amounts represent refunds and debit AR account and credit cash account.\n" +
    "      </div>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-default btn-wide\" ng-click=\"cancel()\" ng-disabled=\"isProcessing || result.pg_response_type === 'A'\">Cancel</button>\n" +
    "    <button class=\"btn btn-primary btn-wide\" ng-click=\"ok()\" ng-disabled=\"isProcessing || myForm.$invalid\">OK</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"addJE.html\">\n" +
    "	<div class=\"modal-header\">\n" +
    "		<h3 class=\"modal-title\">{{title}}</h3>\n" +
    "	</div>\n" +
    "	<div class=\"modal-body\">\n" +
    "		<form name='myForm'>\n" +
    "      <div class=\"form-group\" ng-class=\"{ 'has-success': myForm.description.$valid, 'has-error': myForm.description.$invalid}\">\n" +
    "	      <label for=\"jedesc\">Description:</label>\n" +
    "	      <textarea id=\"jedesc\" name='description' class=\"form-control\" ng-model=\"newJournalEntryInfo.description\" data-required=\"true\"></textarea>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\">\n" +
    "	      <label for=\"jememo\">Memo:</label>\n" +
    "	      <textarea id=\"jememo\" class=\"form-control\" type=\"text\" ng-model=\"newJournalEntryInfo.memo\"></textarea>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"form-group has-feedback\"\n" +
    "        ng-class=\"{\n" +
    "            'has-success':  myForm.amount.$valid,\n" +
    "            'has-error':    myForm.amount.$invalid\n" +
    "        }\"\n" +
    "      >\n" +
    "	      <label for=\"jeamt\">Amount:</label>\n" +
    "        <input id=\"jeamt\" name=\"amount\"\n" +
    "          class=\"form-control\"\n" +
    "          type=\"text\"\n" +
    "          ng-model=\"newJournalEntryInfo.amount\"\n" +
    "          ng-pattern=\"AMOUNT_REGEX\"\n" +
    "          ng-model-options=\"{\n" +
    "              updateOn:'default blur',\n" +
    "              debounce: {\n" +
    "                  default: 500,\n" +
    "                  blur: 1\n" +
    "              },\n" +
    "          }\"\n" +
    "          ng-disabled=\"isProcessing || result.pg_response_type === 'A'\"\n" +
    "          required\n" +
    "        />\n" +
    "        <span class=\"icomoon icon-icomoon-checkmark form-control-feedback\"\n" +
    "            ng-class=\"{\n" +
    "            'icon-icomoon-checkmark':myForm.amount.$valid,\n" +
    "            'icon-icomoon-warning':myForm.amount.$invalid\n" +
    "            }\"\n" +
    "        >\n" +
    "        </span>\n" +
    "        <small class=\"help-block\" ng-if=\"myForm.amount.$invalid\">\n" +
    "          Amount must be a decimal number to the hundredths (e.g. 6.23).\n" +
    "        </small>\n" +
    "			</div>\n" +
    "			<div class=\"form-group\">\n" +
    "				<label>Payment required in: {{currency}}. <br>{{helpText}}</label>\n" +
    "			</div>\n" +
    "		</form>\n" +
    "	</div>\n" +
    "	<div class=\"modal-footer\">\n" +
    "		<button ng-disabled=\"myForm.$invalid\" class=\"btn btn-primary\" ng-click=\"ok()\">OK\n" +
    "    </button>\n" +
    "		<button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel\n" +
    "    </button>\n" +
    "	</div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"inv-total-remaining-pastdue-payments-writeoffs.html\">\n" +
    "  <span>\n" +
    "    <strong>Invoice date:</strong> {{jed.arjed.invoice.invoice_date | date:\"MM/dd/yyyy\"}}<br/>\n" +
    "    <strong>Invoice total:</strong> {{jed.arjed.invoiceTotal}}<br/>\n" +
    "    <strong>Invoice remaining:</strong> {{jed.arjed.invoiceBalance}}<br/>\n" +
    "    <span ng-show=\"jed.arjed.invoiceBalance > 0\"><strong>Invoice past due: </strong>{{jed.arjed.invoicePastDueDays}}  days</br></span>\n" +
    "    <strong>Line item payments total:</strong> {{jed.arjed.paymentsTotal}}. <br/>\n" +
    "    <strong>Line item write-offs total:</strong> {{jed.arjed.writeoffsTotal}} <br/>\n" +
    "  </span>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"claimer-total-remaining-pastdue-payments-writeoffs-chargebacks.html\">\n" +
    "  <span>\n" +
    "    <strong>Invoice date:</strong> {{jed.arjed.invoice.invoice_date | date:\"MM/dd/yyyy\"}}<br/>\n" +
    "    <strong>Invoice total:</strong> {{jed.arjed.invoiceTotal}}<br/>\n" +
    "    <strong>Invoice remaining:</strong> {{jed.arjed.invoiceBalance}}<br/>\n" +
    "    <span ng-show=\"jed.arjed.invoiceBalance > 0\"><strong>Invoice past due: </strong>{{jed.arjed.invoicePastDueDays}}  days</br></span>\n" +
    "    <strong>Line item payments total:</strong> {{jed.arjed.paymentsTotal}}. <br/>\n" +
    "    <strong>Line item write-offs total:</strong> {{jed.arjed.writeoffsTotal}} <br/>\n" +
    "    <strong>Line item chargebacks total:</strong> {{jed.arjed.chargebacksTotal}}\n" +
    "    <span ng-if=\"jed.arjed.claim\">\n" +
    "      <strong><a ui-sref=\"claimdetail({patientid: patientId, claimid: jed.arjed.claim.id})\">{{jed.arjed.claim.claim_number}}: {{jed.arjed.claim.state}}</a></strong>\n" +
    "    </span>\n" +
    "    <span ng-if=\"!jed.arjed.claim\">\n" +
    "      <strong><a ui-sref=\"insurance({patientid: patientId})\">Claim not created</a></strong>\n" +
    "    </span>\n" +
    "  </span>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"inv-total-remaining-pastdue-writeofffor.html\">\n" +
    "  <span>\n" +
    "    <strong>Invoice date:</strong> {{jed.arjed.invoice.invoice_date | date:\"MM/dd/yyyy\"}}<br/>\n" +
    "    <strong>Invoice total:</strong> {{jed.arjed.invoiceTotal}}<br/>\n" +
    "    <strong>Invoice remaining:</strong> {{jed.arjed.invoiceBalance}}<br/>\n" +
    "    <span ng-show=\"jed.arjed.invoiceBalance > 0\"><strong>Invoice past due: </strong>{{jed.arjed.invoicePastDueDays}}  days</br></span>\n" +
    "    <strong>Writeoff for #:</strong> {{jed.arjed.writeoff_for}}\n" +
    "  </span>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"inv-total-remaining-pastdue-paymentfor.html\">\n" +
    "  <span>\n" +
    "    <strong>Invoice date:</strong> {{jed.arjed.invoice.invoice_date | date:\"MM/dd/yyyy\"}}<br/>\n" +
    "    <strong>Invoice total:</strong> {{jed.arjed.invoiceTotal}}<br/>\n" +
    "    <strong>Invoice remaining:</strong> {{jed.arjed.invoiceBalance}}<br/>\n" +
    "    <span ng-show=\"jed.arjed.invoiceBalance > 0\"><strong>Invoice past due: </strong>{{jed.arjed.invoicePastDueDays}}  days</br></span>\n" +
    "    <strong>Payment for #:</strong> {{jed.arjed.payment_for}}\n" +
    "  </span>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"inv-total-remaining-pastdue-chargebackfor.html\">\n" +
    "  <span>\n" +
    "    <strong>Invoice date:</strong> {{jed.arjed.invoice.invoice_date | date:\"MM/dd/yyyy\"}}<br/>\n" +
    "    <strong>Invoice total:</strong> {{jed.arjed.invoiceTotal}}<br/>\n" +
    "    <strong>Invoice remaining:</strong> {{jed.arjed.invoiceBalance}}<br/>\n" +
    "    <span ng-show=\"jed.arjed.invoiceBalance > 0\"><strong>Invoice past due: </strong>{{jed.arjed.invoicePastDueDays}}  days</br></span>\n" +
    "    <strong>Chargeback for #:</strong> {{jed.arjed.chargeback_for}}\n" +
    "  </span>\n" +
    "</script>\n" +
    "<script type=\"text/ng-template\" id=\"abandoned-alert.html\">\n" +
    "  <span>\n" +
    "      This treatment was marked abandoned. While billing and insurance entries associated with this treatment should sum to zero, the system cannot automatically add reversing entries to accomplish this. Please be sure such entries have been manually added and all entries associated with this treatment in billing and insurance each sum to zero.<br/>\n" +
    "  </span>\n" +
    "</script>\n" +
    "\n" +
    "<!-- <iframe\n" +
    "  ng-src=\"{{ pdfurl | trustAsResourceUrl }}\"\n" +
    "  frameborder=\"0\"\n" +
    "  style=\"width:100%; min-height: 1200px;\">\n" +
    "</iframe> -->\n" +
    "")

$templateCache.put("src/common/patient-detail-consent-forms.tpl.html","<div class=\"panel panel-default consent-forms\">\n" +
    "  <div class=\"panel-heading\">\n" +
    "    <span class=\"panel-title\">Informed Consent Forms</span>\n" +
    "    <span class=\"m-l\"\n" +
    "      ng-show=\"needsConsent\"\n" +
    "      popover-title=\"Add a new Consent Form\"\n" +
    "      uib-popover=\"There are treatments that the patient needs to consent\"\n" +
    "      popover-trigger=\"mouseenter\">\n" +
    "      <i class=\"icomoon icon-icomoon-warning2 font-red-thunderbird\"></i>\n" +
    "    </span>\n" +
    "    <span class=\"m-l\"\n" +
    "      ng-show=\"!encounter.treatments.length\"\n" +
    "      popover-title=\"No Treatments\"\n" +
    "      uib-popover=\"You need to add Treatments to the Encounter first to add Consent Forms\"\n" +
    "      popover-trigger=\"mouseenter\">\n" +
    "      <i class=\"icomoon icon-icomoon-warning2 font-red-thunderbird\"></i>\n" +
    "    </span>\n" +
    "    <span class=\"btn btn-default btn-icomoon-pixel-perfect pull-right\" ng-if=\"CanListConsentForm || CanListConsentFormTemplate\" ng-click=\"load(true)\">\n" +
    "      <i class=\"icomoon icon-icomoon-spinner11\"></i>\n" +
    "    </span>\n" +
    "  </div>\n" +
    "  <div class=\"panel-body\" ng-if=\"CanAccessConsentForm\">\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"col-sm-2\" ng-if=\"CanCreateConsentForm\">\n" +
    "        <button type=\"button\" class=\"btn btn-info btn-block\" style=\"margin-bottom:6px\"\n" +
    "          ng-disabled=\"!encounter.treatments.length\"\n" +
    "          ngf-select=\"upload($file)\">\n" +
    "          Upload Form\n" +
    "        </button>\n" +
    "        <div class=\"well drop-box text-center\"\n" +
    "          ng-if=\"encounter.treatments.length\"\n" +
    "          ngf-drag-over-class=\"'dragover'\"\n" +
    "          ngf-drop=\"upload($file)\">\n" +
    "          Drop pdf file here to upload\n" +
    "        </div>\n" +
    "        <div\n" +
    "          ng-if=\"encounter.treatments.length\"\n" +
    "          ngf-no-file-drop=\"\">\n" +
    "          File Drag/Drop is not supported for this browser\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-10\" ng-class=\"{'col-md-12 col-sm-12': !CanCreateConsentForm}\">\n" +
    "        <table class=\"table table-condensed table-responsive\" ng-if=\"CanListConsentFormTemplate\">\n" +
    "          <tr>\n" +
    "            <th class=\"b-t-0\">Consent Form Templates</th>\n" +
    "            <th class=\"b-t-0\">Created by</th>\n" +
    "            <th class=\"b-t-0\">Created on</th>\n" +
    "            <th class=\"b-t-0\"></th>\n" +
    "          </tr>\n" +
    "          <tr ng-repeat=\"consent_form_template in consent_form_templates track by consent_form_template.id\">\n" +
    "            <td>\n" +
    "              <a ng-if=\"CanRetrieveConsentFormTemplate\" ng-click=\"previewTemplateModal($index)\">{{ consent_form_template.name }}</a>\n" +
    "              <span ng-if=\"!CanRetrieveConsentFormTemplate\">{{ consent_form_template.name }}</span>\n" +
    "            </td>\n" +
    "            <td>{{ consent_form_template.created_by }}</td>\n" +
    "            <td>{{ consent_form_template.created_at | date:\"MM-dd-yyyy\" }}</td>\n" +
    "            <td>\n" +
    "              <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x pull-right\"\n" +
    "                ng-click=\"renderModal($index)\"\n" +
    "                ng-disabled=\"!encounter.treatments.length\"\n" +
    "                ng-if=\"CanRetrieveConsentFormTemplate && CanCreateConsentForm\">\n" +
    "                <i class=\"icomoon icon-icomoon-insert-template\"></i>\n" +
    "              </button>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "          <tr ng-hide=\"consent_form_templates.length\">\n" +
    "            <td colspan=\"4\" class=\"p-a-0\">\n" +
    "              <div class=\"well well-sm text-center\">There are no Consent Form Templates</div>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </table>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"col-sm-12\">\n" +
    "        <table class=\"table table-condensed table-responsive\" ng-if=\"CanListConsentForm\">\n" +
    "          <tr>\n" +
    "            <th class=\"b-t-0\">Consent Forms</th>\n" +
    "            <th class=\"b-t-0\">Treatments</th>\n" +
    "            <th class=\"b-t-0\">Created by</th>\n" +
    "            <th class=\"b-t-0\">Created on</th>\n" +
    "            <th class=\"b-t-0\"></th>\n" +
    "          </tr>\n" +
    "          <tr ng-repeat=\"file in upload_queue track by $index\">\n" +
    "            <td>{{ file.name }}</td>\n" +
    "            <td colspan=\"3\">\n" +
    "              <div class=\"progress m-b-0\">\n" +
    "                <div class=\"progress-bar\" role=\"progressbar\" ng-style=\"{ 'width': file.progress + '%' }\">\n" +
    "                  {{ file.progress + ' %' }} of\n" +
    "                  {{ file.size / 1048576 | number:2 }} MB\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </td>\n" +
    "            <td>\n" +
    "              <span class=\"btn btn-link btn-icomoon-pixel-perfect pull-right\">\n" +
    "                <i class=\"icomoon\"\n" +
    "                  ng-class=\"{progress:'icon-icomoon-upload font-blue-sharp', completed:'icon-icomoon-checkmark4 font-green-jungle', 'error':'icon-icomoon-warning2 font-red-thunderbird'}[file.status]\">\n" +
    "                </i>\n" +
    "              </span>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "          <tr ng-repeat=\"consent_form in consent_forms track by consent_form.id\">\n" +
    "            <td>\n" +
    "              <a href=\"\" ng-if=\"CanRetrieveConsentForm\" ng-click=\"previewModal($index)\">{{ consent_form.name }}</a>\n" +
    "              <span ng-if=\"!CanRetrieveConsentForm\">{{ consent_form.name }}</span>\n" +
    "            </td>\n" +
    "            <td>\n" +
    "              <div class=\"label label-default\"\n" +
    "                popover-title=\"Consented Treatments\"\n" +
    "                popover-trigger=\"mouseenter\"\n" +
    "                popover-class=\"no-max-width\"\n" +
    "                uib-popover-template=\"'#ConsentFormTreatmentsPreviewPopover'\"\n" +
    "                >\n" +
    "                {{ consent_form.treatments.length }}\n" +
    "              </div>\n" +
    "            </td>\n" +
    "            <td>{{ consent_form.created_by }}</td>\n" +
    "            <td>{{ consent_form.created_at | date:\"MM-dd-yyyy\" }}</td>\n" +
    "            <td>\n" +
    "              <span class=\"btn btn-default btn-icomoon-pixel-perfect-1x pull-right\"\n" +
    "                ng-click=\"removeModal($index)\"\n" +
    "                ng-if=\"CanDeleteConsentForm\">\n" +
    "                <i class=\"icomoon icon-icomoon-bin\"></i>\n" +
    "              </span>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "          <tr ng-hide=\"upload_queue.length + consent_forms.length\">\n" +
    "            <td colspan=\"5\" class=\"p-x-0 p-y-0\">\n" +
    "              <div class=\"well well-sm text-center\">There are no Consent Forms</div>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </table>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"panel-body well well-sm text-center m-b-0\" ng-if=\"!CanAccessConsentForm\">\n" +
    "    You don't have permission to access the Consent Forms\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#ConsentFormTemplatePreviewModal\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">View Consent Form Template</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-show=\"loading\"></wave-spinner>\n" +
    "    <form class=\"form-horizontal\">\n" +
    "      <fieldset>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-3 control-label\">Name</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <div class=\"form-control-static\">{{ consent_form_template.name }}</div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-3 control-label\">Fields</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <table class=\"table table-condensed\">\n" +
    "              <tr>\n" +
    "                <th>name</th>\n" +
    "                <th>type</th>\n" +
    "                <th>page</th>\n" +
    "              </tr>\n" +
    "              <tr ng-repeat=\"field in consent_form_template.form_template_metadata.fields\">\n" +
    "                <td>{{ field.title }}</td>\n" +
    "                <td>{{ field.type }}</td>\n" +
    "                <td>{{ field.page_num }}</td>\n" +
    "              </tr>\n" +
    "            </table>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <div class=\"col-sm-offset-1 col-sm-10\">\n" +
    "            <embed src=\"{{ form_archive_url }}\" type=\"application/pdf\" style=\"width:100%\"></embed>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </fieldset>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <span class=\"btn btn-success btn-wide pull-left\" ng-click=\"download()\">Download</span>\n" +
    "    <span class=\"btn btn-default btn-wide\" ng-click=\"$dismiss()\">Close</span>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#ConsentFormPreviewModal\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Change Consent Form</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-show=\"loading\"></wave-spinner>\n" +
    "    <form class=\"form-horizontal\">\n" +
    "      <fieldset>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-3 control-label\">Name</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <input type=\"text\" ng-disabled=\"!CanUpdateConsentForm\" class=\"form-control input-md\" name=\"name\" ng-model=\"consent_form.name\">\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-3 control-label\">Treatments</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <table class=\"table table-condensed m-b-0\">\n" +
    "              <tr>\n" +
    "                <th>code</th>\n" +
    "                <th>name</th>\n" +
    "              </tr>\n" +
    "              <tr ng-repeat=\"t in consent_form.treatments track by t.id\">\n" +
    "                <td>{{ t.procedure_code }}</td>\n" +
    "                <td>{{ t.procedure_name }}</td>\n" +
    "              </tr>\n" +
    "            </table>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\" ng-if=\"consent_form.form_template\">\n" +
    "          <label class=\"col-sm-3 control-label\">Template</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <a class=\"form-control-static\"\n" +
    "              ng-click=\"template(consent_form.form_template.id)\">\n" +
    "              {{ consent_form.form_template.name }}\n" +
    "            </a>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\" ng-if=\"has_context\">\n" +
    "          <label class=\"col-sm-3 control-label\">Form Data</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <table class=\"table table-condensed m-b-0\">\n" +
    "              <tr>\n" +
    "                <th>name</th>\n" +
    "                <th>value</th>\n" +
    "              </tr>\n" +
    "              <tr ng-repeat=\"(key, value) in consent_form.form_context track by key\">\n" +
    "                <td>{{ key }}</td>\n" +
    "                <td ng-if=\"!value.startsWith('data:image/png')\">{{ value }}</td>\n" +
    "                <td ng-if=\"value.startsWith('data:image/png')\">\n" +
    "                  <img src=\"{{ value }}\" class=\"img-thumbnail\">\n" +
    "                </td>\n" +
    "              </tr>\n" +
    "            </table>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <div class=\"col-sm-offset-1 col-sm-10\">\n" +
    "            <embed src=\"{{ form_archive_url }}\" type=\"application/pdf\" style=\"width:100%\"></embed>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </fieldset>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <span class=\"btn btn-success btn-wide pull-left\" ng-click=\"download()\">Download</span>\n" +
    "    <span class=\"btn btn-default btn-wide\" ng-click=\"$dismiss()\">Close</span>\n" +
    "    <button type=\"button\" class=\"btn btn-primary btn-wide\"\n" +
    "      ng-click=\"update()\"\n" +
    "      ng-disabled=\"loading\"\n" +
    "      ng-if=\"CanUpdateConsentForm\">\n" +
    "      Update\n" +
    "    </button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#ConsentFormTreatmentsPreviewPopover\">\n" +
    "  <ul class=\"list-unstyled\">\n" +
    "    <li ng-repeat=\"treatment in consent_form.treatments track by treatment.id\" style=\"margin-bottom:3px;\">\n" +
    "      <kbd>{{ treatment.procedure_code }}</b></kbd>\n" +
    "      {{ treatment.procedure_name }}\n" +
    "      <code ng-if=\"treatment.tooth_numbers\">\n" +
    "        Th: {{ treatment.tooth_numbers }}\n" +
    "      </code>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#ConsentFormRemoveModal\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Delete Consent Form</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-show=\"loading\"></wave-spinner>\n" +
    "    Deleting this Consent Form will remove it from the Patient's chart\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <span class=\"btn btn-primary\" ng-disabled=\"loading\" ng-click=\"$dismiss()\">Cancel</span>\n" +
    "    <span class=\"btn btn-danger\" ng-disabled=\"loading\" ng-click=\"remove()\">Delete</span>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#ConsentFormRenderModal\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Create Consent Form</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-show=\"loading\"></wave-spinner>\n" +
    "    <form class=\"form-horizontal\">\n" +
    "      <fieldset ng-disabled=\"loading\">\n" +
    "        <div class=\"form-group m-b\">\n" +
    "          <label class=\"col-sm-4 control-label\">Form Name</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <input type=\"text\" class=\"form-control input-md\"\n" +
    "              name=\"name\"\n" +
    "              ng-model=\"consent_form.name\">\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"p-y\">\n" +
    "          <div class=\"form-group\" ng-repeat=\"field in consent_form_template.form_template_metadata.fields\">\n" +
    "            <label class=\"col-sm-4 control-label\">{{ field.title }}</label>\n" +
    "            <div class=\"col-sm-7\" ng-switch=\"field.type\">\n" +
    "              <div ng-switch-when=\"signature\"\n" +
    "                class=\"relative-signature-box\"\n" +
    "                style=\"height:150px; width:100%; background-color:#fff;\">\n" +
    "                <canvas\n" +
    "                  angular-sig-pad\n" +
    "                  ng-model=\"consent_form.form_context[field.name]\"\n" +
    "                  style=\"cursor:crosshair;\">\n" +
    "                </canvas>\n" +
    "                <span class=\"absolute-top-button-transparent-right-box btn btn-default\"\n" +
    "                  ng-click=\"consent_form.form_context[field.name] = null\">\n" +
    "                  <i class=\"icomoon icon-icomoon-reset\"></i>\n" +
    "                </span>\n" +
    "              </div>\n" +
    "              <div ng-switch-when=\"date\">\n" +
    "                <p class=\"form-control\" style=\"margin-top:0; margin-bottom: 6px;\">\n" +
    "                  {{ consent_form.form_context[field.name] || \"(Not Set)\" }}\n" +
    "                </p>\n" +
    "                <div class=\"form-control-datepicker\"\n" +
    "                  uib-datepicker\n" +
    "                  ng-model=\"_dates[field.name]\"\n" +
    "                  ng-change=\"consent_form.form_context[field.name] = (_dates[field.name] | date: 'MM/dd/yyyy')\">\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <input ng-switch-default\n" +
    "                class=\"form-control input-md\"\n" +
    "                type=\"{{ field.type }}\"\n" +
    "                name=\"{{ field.name }}\"\n" +
    "                ng-model=\"consent_form.form_context[field.name]\">\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </fieldset>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <span class=\"btn btn-default btn-wide\" ng-disabled=\"loading\" ng-click=\"$dismiss()\">Close</span>\n" +
    "    <span class=\"btn btn-primary btn-wide\" ng-disabled=\"loading\" ng-click=\"render()\">Save</span>\n" +
    "  </div>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/common/patient-detail-encounters.tpl.html","<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-reading font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Encounters</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\">\n" +
    "          <div class=\"btn-group pull-right\" uib-dropdown>\n" +
    "            <button type=\"button\" ng-disabled=\"!CanCreateEncounter\" class=\"btn p-x btn-primary dropdown-toggle\" uib-dropdown-toggle>\n" +
    "              Add new encounter\n" +
    "              <i class=\"icomoon icon-icomoon-arrow-down\"></i>\n" +
    "            </button>\n" +
    "            <ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "              <li><a ng-click=\"goToEncounterType('co')\">Consultation</a></li>\n" +
    "              <li><a ng-click=\"goToEncounterType('cp')\">Consultation and Procedure</a></li>\n" +
    "              <li><a ng-click=\"goToEncounterType('pr')\">Procedure</a></li>\n" +
    "              <li><a ng-click=\"goToEncounterType('fu')\">FollowUp</a></li>\n" +
    "            </ul>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <div>\n" +
    "          <!-- <div class=\"pre-scrollable\"> -->\n" +
    "            <input type=\"text\" class=\"form-control m-b\" placeholder=\"Search\" ng-model=\"searchTerm\">\n" +
    "            <table class=\"table table-striped table-bordered table-advance\">\n" +
    "              <thead>\n" +
    "                <tr>\n" +
    "                  <th ng-click=\"orderByClick('encounter_date')\" style=\"cursor:pointer\">\n" +
    "                    Date\n" +
    "                    <span class=\"pull-right\" ng-show=\"orderByProp == 'encounter_date'\">\n" +
    "                      <i ng-hide=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                      <i ng-show=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "                    </span>\n" +
    "                  </th>\n" +
    "                  <th ng-click=\"orderByClick('encounter_type_display')\" style=\"cursor:pointer\">\n" +
    "                    Type\n" +
    "                    <span class=\"pull-right\" ng-show=\"orderByProp == 'encounter_type_display'\">\n" +
    "                      <i ng-hide=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                      <i ng-show=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "                    </span>\n" +
    "                  </th>\n" +
    "                  <th ng-click=\"orderByClick('provider_fullname')\" style=\"cursor:pointer\">\n" +
    "                    Provider\n" +
    "                    <span class=\"pull-right\" ng-show=\"orderByProp == 'provider_fullname'\">\n" +
    "                      <i ng-hide=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                      <i ng-show=\"orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "                    </span>\n" +
    "                  </th>\n" +
    "                  <th>Short Description</th>\n" +
    "                  <th class=\"col-narrow\" style=\"min-width:110px\"></th>\n" +
    "                </tr>\n" +
    "              </thead>\n" +
    "              <tr ng-repeat=\"e in encounters | propsFilter: {filter:searchTerm, props:['encounter_date|date|MM/dd/yyyy', 'encounter_type_display', 'provider_fullname', 'title']} | orderBy: orderByProp: orderByReverse\">\n" +
    "                <td class=\"narrow\" ng-bind-html=\"e.encounter_date | date:'MM/dd/yyyy' | highlight: searchTerm\"></td>\n" +
    "                <td class=\"narrow\" ng-bind-html=\"e.encounter_type_display | highlight: searchTerm\"></td>\n" +
    "                <td class=\"narrow text-nowrap\" ng-bind-html=\"e.provider_fullname | highlight: searchTerm\"></td>\n" +
    "                <td>\n" +
    "                  <div ng-bind-html=\"e.title | highlight: searchTerm\"></div>\n" +
    "                  <div ng-if=\"e.expanded\">\n" +
    "                    <h5 class=\"control-label-bold\">Full Description</h5>\n" +
    "                    <div class=\"preserve-newlines\">{{ e.report }}</div>\n" +
    "                  </div>\n" +
    "                </td>\n" +
    "                <td class=\"btn-container\">\n" +
    "                  <div class=\"btn-group\">\n" +
    "                    <button type=\"button\" class=\"btn btn-icomoon-pixel-perfect-1x m-x-0 font-purple-soft white\" ng-model=\"e.expanded\" uib-btn-checkbox ng-disabled=\"!e.report\">\n" +
    "                      <i class=\"icomoon icon-icomoon-file-eye2\"></i>\n" +
    "                    </button>\n" +
    "                    <a class=\"btn btn-icomoon-pixel-perfect-1x m-x-0 font-blue white\" ng-disabled=\"!CanRetrieveEncounter\" ui-sref=\"chart-detail({encounterid: e.id,isnewencounter: false})\">\n" +
    "                      <i class=\"icomoon icon-icomoon-pencil5\"></i>\n" +
    "                    </a>\n" +
    "                    <button type=\"button\" class=\"btn btn-icomoon-pixel-perfect-1x m-x-0 font-red white\" ng-disabled=\"!CanDeleteEncounter\" ng-click=\"openDelEncounterModal(e.id, e.encounter_date, e.title)\">\n" +
    "                      <i class=\"icomoon icon-icomoon-bin\"></i>\n" +
    "                    </button>\n" +
    "                  </div>\n" +
    "                </td>\n" +
    "              </tr>\n" +
    "            </table>\n" +
    "          <!-- </div> -->\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<reminders-list></reminders-list>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"delEncounter.html\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Delete encounter?</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    Are you sure you want to delete treatment {{encounterDate | date:'MM-dd-yyyy'}} {{encounterTitle}}?\n" +
    "    NOTE that deleting an encounter will delete all images associated with the encounter,\n" +
    "    may leave one or more treatments without an associated encounter,\n" +
    "    and may make temporal sequence of encounters inconsistent or illogical.\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"ok()\">OK</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/common/patient-detail-file-detail.tpl.html","<div id=\"dwtcontrolContainer\">\n" +
    "	<div class=\"portlet light porlet-fit\">\n" +
    "		<div class=\"portlet-title\">\n" +
    "			<div class=\"caption\">\n" +
    "				<i class=\"icomoon icon-icomoon-file-plus2 font-red-sunglo\"></i>\n" +
    "				<span class=\"caption-subject font-red-sunglo bold uppercase\">\n" +
    "					<span>File Detail</span>\n" +
    "				</span>\n" +
    "			</div>\n" +
    "			<div class=\"actions\">\n" +
    "			    <div class=\"btn-group\">\n" +
    "                     <button type=\"button\" class=\"btn btn-default\" back-button>\n" +
    "                        <span class=\"icomoon icon-icomoon-arrow-left\"></span> Back\n" +
    "                     </button>\n" +
    "                     <button type=\"button\" ng-disabled=\"!CanUpdateFile\" class=\"btn p-x btn-primary\" ng-click=\"update()\" style=\"margin-left: 6px;\">\n" +
    "                        <span class=\"icomoon icon-icomoon-upload\"></span> Update\n" +
    "                     </button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "	    <div class=\"portlet-body\">\n" +
    "            <div  class=\"row\">\n" +
    "                <div class=\"col-md-12\">\n" +
    "                    <img class=\"img-responsive\" ng-src=\"{{file.file_asset}}\" onerror=\"this.style.display='none'\"/>\n" +
    "                </div>\n" +
    "                <div class=\"col-md-6\">\n" +
    "                    <div class=\"btn-group\">\n" +
    "                      <a role=\"button\" class=\"btn btn-default\" target=\"_blank\" href=\"{{file.file_asset}}\">\n" +
    "                        <span class=\"icomoon icon-icomoon-download\"> Open {{file.file_name}}</span>\n" +
    "                      </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"form-group col-md-10\">\n" +
    "                <label for=\"description\"  ng-show=\"file.file_type === 'file'\">Description</label>\n" +
    "                <label for=\"description\"  ng-show=\"file.file_type === 'informedconsent'\">Tooth number(s) and other description</label>\n" +
    "                <input type=\"text\" class=\"form-control\" placeholder=\"Description\" ng-model=\"file.description\">\n" +
    "              </div>\n" +
    "              <div class=\"form-group col-md-2\">\n" +
    "                  <label for=\"filetype\">Type of file</label>\n" +
    "                  <select  ng-disabled=\"metadataReadOnly || !CanUpdateFile\" id=\"filetype\" class=\"form-control\" ng-change=\"changedFileType()\" ng-model=\"file.file_type\">\n" +
    "                      <option value=\"file\">File</option>\n" +
    "                      <option value=\"informedconsent\">Informed consent</option>\n" +
    "                  </select>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\" ng-show=\"file.file_type === 'file'\" >\n" +
    "              <div class=\"form-group col-md-12\">\n" +
    "                <label for=\"filemeta\">Information about the file</label>\n" +
    "                <input  ng-disabled=\"metadataReadOnly || !CanUpdateFile\" type=\"text\" id=\"filemeta\" class=\"form-control\" placeholder=\"Information about file...\" ng-model=\"file.file_type_metadata.text\">\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\"  ng-show=\"file.file_type === 'informedconsent'\">\n" +
    "              <div class=\"form-group col-md-4\">\n" +
    "                <label for=\"patientsig\">Patient's signature</label>\n" +
    "                <div class=\"relative-signature-box panel-default\" ng-class=\"{ 'link-disabled':  !CanUpdateFile}\">\n" +
    "                        <!--<canvas ng-signature-pad=\"patientsig\"></canvas>-->\n" +
    "                        <canvas angular-sig-pad ng-model=\"patientsig\"></canvas>\n" +
    "                      <button   ng-disabled=\"metadataReadOnly\" type=\"button\" class=\"absolute-top-button-transparent-right-box btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"patientsig = null\"><i class=\"icomoon icon-icomoon-reset\"></i></button>\n" +
    "                      <p class=\"input-group\">\n" +
    "                        <input  ng-disabled=\"metadataReadOnly\" type=\"text\" class=\"form-control\" datepicker-popup=\"MM/dd/yyyy\" readonly ng-model=\"file.file_type_metadata.patientsigdate\" is-open=\"patientsigdateopened\"  ng-required=\"true\" close-text=\"Close\" />\n" +
    "                      <span class=\"input-group-btn\">\n" +
    "                        <button ng-show=\"!metadataReadOnly\" type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"openpatientsigdate($event)\"><i class=\"icomoon icon-icomoon-calendar\"></i></button>\n" +
    "                      </span>\n" +
    "                      </p>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"form-group col-md-4\">\n" +
    "                <label for=\"doctorsig\">Doctor's signature</label>\n" +
    "               <div class=\"relative-signature-box panel-default\" ng-class=\"{ 'link-disabled':  !CanUpdateFile}\">\n" +
    "                        <!--<canvas ng-signature-pad=\"doctorsig\"></canvas>-->\n" +
    "                        <canvas angular-sig-pad ng-model=\"doctorsig\"></canvas>\n" +
    "                      <button   ng-disabled=\"metadataReadOnly\" type=\"button\" class=\"absolute-top-button-transparent-right-box btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"doctorsig = null\"><i class=\"icomoon icon-icomoon-reset\"></i></button>\n" +
    "                      <p class=\"input-group\">\n" +
    "                        <input ng-disabled=\"metadataReadOnly\" type=\"text\" class=\"form-control\" datepicker-popup=\"MM/dd/yyyy\" readonly ng-model=\"file.file_type_metadata.doctorsigdate\" is-open=\"doctorsigdateopened\"  ng-required=\"true\" close-text=\"Close\" />\n" +
    "                    <span class=\"input-group-btn\">\n" +
    "                      <button ng-show=\"!metadataReadOnly\" type=\"button\" class=\"btn btn-default\" ng-click=\"opendoctorsigdate($event)\"><i class=\"icomoon icon-icomoon-calendar\"></i></button>\n" +
    "                    </span>\n" +
    "                      </p>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    	<div class=\"portlet-title\">\n" +
    "	        <div class=\"actions\">\n" +
    "	            <div class=\"btn-group\">\n" +
    "                     <button type=\"button\" class=\"btn p-x btn-default\" back-button>\n" +
    "                        <span class=\"icomoon icon-icomoon-arrow-left\"></span> Back\n" +
    "                     </button>\n" +
    "                     <button type=\"button\" ng-disabled=\"!CanUpdateFile\" class=\"btn p-x btn-primary\" ng-click=\"update()\" style=\"margin-left: 6px;\">\n" +
    "                        <span class=\"icomoon icon-icomoon-upload\"></span> Update\n" +
    "                     </button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/patient-detail-insurance.tpl.html","<h3 class=\"page-title\" ng-if=\"insuranceContext\"> Insurance\n" +
    "  <small></small>\n" +
    "</h3>\n" +
    "\n" +
    "<div class=\"row\" ng-if=\"patientInsuranceContext\">\n" +
    "	<div class=\"col-md-12\">\n" +
    "		<div class=\"portlet light porlet-fit pos-r\">\n" +
    "      <wave-spinner class=\"modal-loader\" ng-if=\"loadingJeds\"></wave-spinner>\n" +
    "			<div class=\"portlet-title\">\n" +
    "				<div class=\"caption\">\n" +
    "					<i class=\"icomoon icon-icomoon-cash3 font-red-sunglo\"></i>\n" +
    "					<span class=\"caption-subject font-red-sunglo bold uppercase\">Total to be claimed insurance receivables for patient\n" +
    "            <span ng-if=\"noclaimjedstotal\">: Balance = {{noclaimjedstotal}} {{practice.currency}}\n" +
    "          </span>\n" +
    "				</div>\n" +
    "				<div class=\"actions\">\n" +
    "					<div class=\"btn-group\" uib-dropdown>\n" +
    "						<button ng-disabled=\"!CanCreateClaim && !CanRetrieveClaim\" type=\"button\" class=\"btn p-x btn-primary dropdown-toggle\" uib-dropdown-toggle>\n" +
    "							Make claim for treating provider\n" +
    "							<span class=\"caret\"></span>\n" +
    "						</button>\n" +
    "						<ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "							<li ng-repeat=\"uniqueProviderLocation in uniqueProviderLocations\">\n" +
    "                                <a ui-sref=\"newclaimdetail({treatingproviderid: uniqueProviderLocation.treatingProviderId, locationid: uniqueProviderLocation.locationId})\">{{uniqueProviderLocation.providerUsername}} at {{uniqueProviderLocation.locationName}}</a>\n" +
    "                            </li>\n" +
    "						</ul>\n" +
    "					</div>\n" +
    "				</div>\n" +
    "			</div>\n" +
    "			<div class=\"portlet-body\">\n" +
    "\n" +
    "				<div class=\"pre-scrollable\" style=\"min-height:200px\">\n" +
    "					<table class=\"table table-striped table-bordered table-advance\">\n" +
    "            <thead>\n" +
    "              <tr>\n" +
    "                <th class=\"col-md-1\">Date</th>\n" +
    "                <th class=\"col-md-6\">Description</th>\n" +
    "                <th class=\"col-md-1\" style=\"text-align:right;\">AR Amount</th>\n" +
    "                <th class=\"col-md-1\">Type</th>\n" +
    "                <th class=\"col-md-1\"></th>\n" +
    "                <th class=\"col-md-1\">Treating Provider</th>\n" +
    "                <th class=\"col-md-1\"></th>\n" +
    "              </tr>\n" +
    "            </thead>\n" +
    "						<tr ng-repeat=\"jed in noclaimjeds | orderBy: 'created': true\">\n" +
    "							<td ng-if=\"!jed.arjed.invoice\">{{jed.created | date:\"MM/dd/yyyy\"}}</td>\n" +
    "							<td ng-if=\"jed.arjed.invoice\">{{jed.arjed.invoice.invoice_date | date:\"MM/dd/yyyy\"}}</td>\n" +
    "							<td>{{jed.description}}</td>\n" +
    "							<td style=\"text-align:right;\">{{jed.amount | currency : '' : 2}}</td>\n" +
    "							<td>\n" +
    "								<span ng-if=\"jed.arjed.type=='payment'\">Receipt</span>\n" +
    "								<span ng-if=\"jed.arjed.type=='claimee' && jed.arjed.claim\">Claim <a ui-sref=\"claimdetail({patientid: jed.arjed.claim.patient, claimid: jed.arjed.claim.id})\">{{jed.arjed.claim.claim_number}}</a></span>\n" +
    "								<span class='bg-danger' ng-if=\"jed.arjed.type=='claimee' && !jed.arjed.claim\">To be claimed</span>\n" +
    "							</td>\n" +
    "							<td>\n" +
    "								<div class=\"btn-group\" uib-dropdown ng-show=\"jed.arjed.treatment.encounters.length > 0\">\n" +
    "									<button ng-disabled=\"!CanRetrieveEncounter\" type=\"button\" class=\"btn btn-default btn-sm dropdown-toggle\" uib-dropdown-toggle>\n" +
    "										Encounters\n" +
    "										<span class=\"caret\"></span>\n" +
    "									</button>\n" +
    "									<ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "										<li ng-repeat=\"e in jed.arjed.treatment.encounters | orderBy: 'encounter_date'\"><a ui-sref=\"chart-detail({encounterid: e.id})\">{{(e.encounter_date | date:'MM-dd-yyyy') + \" - \" + e.title}}</a></li>\n" +
    "									</ul>\n" +
    "								</div>\n" +
    "							</td>\n" +
    "							<td>{{jed.arjed.treatment.provider.username}}</td>\n" +
    "							<td>\n" +
    "								<div class=\"btn-group\" uib-dropdown on-toggle=\"jedActionToggled(open, jed);\">\n" +
    "									<button ng-disabled=\"!CanUpdateInsurance\" type=\"button\" class=\"btn btn-default btn-sm dropdown-toggle\" uib-dropdown-toggle>\n" +
    "										Actions\n" +
    "										<span class=\"caret\"></span>\n" +
    "									</button>\n" +
    "									<ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "										<li ng-hide=\"!jed.associatedArjed\"><a ng-click=\"forgoUnclaimedLineItem(\n" +
    "                      jed)\">Forgo Claim</a></li>\n" +
    "									</ul>\n" +
    "								</div>\n" +
    "							</td>\n" +
    "						</tr>\n" +
    "					</table>\n" +
    "				</div>\n" +
    "\n" +
    "			</div>\n" +
    "		</div>\n" +
    "	</div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "	<div class=\"col-md-12\">\n" +
    "		<div class=\"portlet light porlet-fit pos-r\">\n" +
    "      <wave-spinner class=\"modal-loader\" ng-if=\"loadingJeds\"></wave-spinner>\n" +
    "			<div class=\"portlet-title\">\n" +
    "				<div class=\"caption\">\n" +
    "					<i class=\"icomoon icon-icomoon-cash3 font-red-sunglo\"></i>\n" +
    "					<span ng-if=\"patientInsuranceContext\" class=\"caption-subject font-red-sunglo bold uppercase\">Total Claimed or Forgone Claims Insurance Receivables for Patient\n" +
    "            <span ng-if=\"jedstotal\" >: Balance = {{jedstotal}} {{practice.currency}}\n" +
    "            </span>\n" +
    "          </span>\n" +
    "					<span ng-if=\"claimContext\" class=\"caption-subject\">Claim Account Receivables\n" +
    "            <span ng-if=\"jedstotal\">: Balance = {{jedstotal}} {{practice.currency}}\n" +
    "            </span>\n" +
    "          </span>\n" +
    "					<span ng-if=\"insuranceContext\" class=\"caption-subject\">Claims: active\n" +
    "          </span>\n" +
    "				</div>\n" +
    "				<div class=\"actions\">\n" +
    "					<div class=\"btn-group pull-right\" dropdown>\n" +
    "						<button\n" +
    "              type=\"button\"\n" +
    "              class=\"btn p-x btn-primary\"\n" +
    "              ng-click=\"openAddPaymentModal('Payment / Refund', '', practice.currency, practice.merchant_id, practice.insurance_ar_gl_account, practice.cash_gl_account, jeds, false, animationsEnabled)\"\n" +
    "              ng-disabled=\"!enablePaymentButton || !CanUpdateInsurance\">\n" +
    "							Payment/Refund\n" +
    "						</button>\n" +
    "					</div>\n" +
    "				</div>\n" +
    "			</div>\n" +
    "			<div class=\"portlet-body\">\n" +
    "        <div style=\"overflow-x: auto;\">\n" +
    "					<table class=\"table table-stripedtable-bottom-spacer table-advance\">\n" +
    "            <thead>\n" +
    "              <tr>\n" +
    "							  <th class=\"col-md-1\">Pay</th>\n" +
    "                <th class=\"col-md-1\">Recorded</th>\n" +
    "                <th class=\"col-md-1\">#</th>\n" +
    "                <th class=\"col-md-3\">Description</th>\n" +
    "                <th class=\"col-md-1\" style=\"text-align:right;\">AR Amount</th>\n" +
    "                <th class=\"col-md-1\">Type</th>\n" +
    "							  <th class=\"col-md-1\"></th>\n" +
    "                <th class=\"col-md-1\"></th>\n" +
    "                <th class=\"col-md-1\">State</th>\n" +
    "                <th class=\"col-md-1\"></th>\n" +
    "              </tr>\n" +
    "            </thead>\n" +
    "            <tbody>\n" +
    "              <tr ng-repeat=\"jed in jeds | orderBy: 'created': true\">\n" +
    "                <td >\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" ng-if=\"jed.arjed && jed.arjed.type.indexOf('claimee') !== -1 && jed.arjed.claim\">\n" +
    "                    <label>\n" +
    "                      <input\n" +
    "                        ng-disabled=\"!CanUpdateInsurance || (\n" +
    "                          jed.arjed.claim.state.indexOf('insurance') === -1 &&\n" +
    "                          jed.arjed.claim.state.indexOf('other_coverage') === -1\n" +
    "                        ) || (\n" +
    "                          jed.arjed.claim.state === 'claim_completed'\n" +
    "                        )\"\n" +
    "                        type=\"checkbox\"\n" +
    "                        ng-model=\"jed.includeInPayment\"\n" +
    "                        ng-change=\"lineItemCheckChanged()\"\n" +
    "                      ></input>\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <span\n" +
    "                    class=\"icomoon icon-icomoon-info2\"\n" +
    "                    ng-if=\"jed.arjed && jed.arjed.type.indexOf('claimee') !== -1 && jed.arjed.claim\"\n" +
    "                    uib-popover-template=\"'payment_state_info.html'\"\n" +
    "                  >\n" +
    "                </td>\n" +
    "                <td ng-class=\"{active:jed.arjed.type=='insurancediscountrevenueinvoicelineitem',success:jed.arjed.type=='claimerinvoicelineitem' || jed.arjed.type=='claimee',danger:jed.arjed.type=='payment',warning:jed.arjed.type=='writeoff',info:jed.arjed.type=='chargeback'}\">\n" +
    "                  {{jed.created | date:\"MM/dd/yyyy\"}}</td>\n" +
    "                <td ng-class=\"{active:jed.arjed.type=='insurancediscountrevenueinvoicelineitem',success:jed.arjed.type=='claimerinvoicelineitem' || jed.arjed.type=='claimee',danger:jed.arjed.type=='payment',warning:jed.arjed.type=='writeoff',info:jed.arjed.type=='chargeback'}\">\n" +
    "								  <span ng-if=\"jed.arjed && jed.arjed.type=='payment'\">{{jed.arjed.reference_number}}</span>\n" +
    "								  <span ng-if=\"!jed.arjed || jed.arjed.type!=='payment'\">{{jed.id}}</span>\n" +
    "                </td>\n" +
    "                <td ng-class=\"{active:jed.arjed.type=='insurancediscountrevenueinvoicelineitem',success:jed.arjed.type=='claimerinvoicelineitem' || jed.arjed.type=='claimee',danger:jed.arjed.type=='payment',warning:jed.arjed.type=='writeoff',info:jed.arjed.type=='chargeback'}\" ng-attr-title=\"{{(jed.memo) ? jed.memo:null}}\">\n" +
    "                  {{jed.arjed.payment ? 'Portion of ' + jed.description + ' applied to #' + jed.arjed.payment_for : jed.description}}</td>\n" +
    "                </td>\n" +
    "                <td style=\"text-align:right;\" ng-class=\"{active:jed.arjed.type=='insurancediscountrevenueinvoicelineitem',success:jed.arjed.type=='claimerinvoicelineitem' || jed.arjed.type=='claimee',danger:jed.arjed.type=='payment',warning:jed.arjed.type=='writeoff',info:jed.arjed.type=='chargeback'}\">\n" +
    "                  {{jed.amount}} {{practice.currency}}</td>\n" +
    "							  <td ng-class=\"{active:jed.arjed.type=='insurancediscountrevenueinvoicelineitem',success:jed.arjed.type=='claimerinvoicelineitem' || jed.arjed.type=='claimee',danger:jed.arjed.type=='payment',warning:jed.arjed.type=='writeoff',info:jed.arjed.type=='chargeback'}\">\n" +
    "                  <span ng-if=\"jed.type\">{{jed.type}}</span>\n" +
    "                </td>\n" +
    "                <td>\n" +
    "								  <span\n" +
    "                    ng-if=\"jed.arjed && jed.arjed.claim && jed.arjed.type === 'claimee'\"\n" +
    "                  >\n" +
    "                    <a\n" +
    "                      ng-class=\"{'bg-warning': jed.arjed.claimBalance > 0 && jed.arjed.claimPastDueDays < 30 && jed.arjed.claimPastDueDays > 15, 'bg-danger': jed.arjed.claimBalance > 0 && jed.arjed.claimPastDueDays > 30, 'link-disabled': !CanRetrieveClaim }\"\n" +
    "                      ui-sref=\"claimdetail({patientid: jed.arjed.claim.patient, claimid: jed.arjed.claim.id})\"\n" +
    "                    >\n" +
    "                      {{jed.arjed.claim.claim_number}}\n" +
    "                    </a>\n" +
    "                    <span\n" +
    "                      class=\"icomoon icon-icomoon-info2\"\n" +
    "                      uib-popover-template=\"'claim-total-remaining-pastdue-payments-writeoffs.html'\"\n" +
    "                    >\n" +
    "                    </span>\n" +
    "                  </span>\n" +
    "								  <span\n" +
    "                    ng-if=\"jed.arjed && jed.arjed.claim && jed.arjed.type !== 'claimee' && jed.arjed.type === 'writeoff'\"\n" +
    "                  >\n" +
    "                    <a\n" +
    "                      ng-class=\"{'bg-warning': jed.arjed.claimBalance > 0 && jed.arjed.claimPastDueDays < 30 && jed.arjed.claimPastDueDays > 15, 'bg-danger': jed.arjed.claimBalance > 0 && jed.arjed.claimPastDueDays > 30, 'link-disabled': !CanRetrieveClaim}\"\n" +
    "                      ui-sref=\"claimdetail({patientid: jed.arjed.claim.patient, claimid: jed.arjed.claim.id})\"\n" +
    "                    >\n" +
    "                      {{jed.arjed.claim.claim_number}}\n" +
    "                    </a>\n" +
    "                    <span\n" +
    "                      class=\"icomoon icon-icomoon-info2\"\n" +
    "                      uib-popover-template=\"'claim-total-remaining-pastdue-writeofffor.html'\"\n" +
    "                    >\n" +
    "                    </span>\n" +
    "                  </span>\n" +
    "								  <span\n" +
    "                    ng-if=\"jed.arjed && jed.arjed.claim && jed.arjed.type === 'payment'\"\n" +
    "                  >\n" +
    "                    <a\n" +
    "                      ng-class=\"{'bg-warning': jed.arjed.claimBalance > 0 && jed.arjed.claimPastDueDays < 30 && jed.arjed.claimPastDueDays > 15, 'bg-danger': jed.arjed.claimBalance > 0 && jed.arjed.claimPastDueDays > 30,'link-disabled': !CanRetrieveClaim}\"\n" +
    "                      ui-sref=\"claimdetail({patientid: jed.arjed.claim.patient, claimid: jed.arjed.claim.id})\"\n" +
    "                    >\n" +
    "                      {{jed.arjed.claim.claim_number}}\n" +
    "                    </a>\n" +
    "                    <span\n" +
    "                      class=\"icomoon icon-icomoon-info2\"\n" +
    "                      uib-popover-template=\"'claim-total-remaining-pastdue-paymentfor.html'\"\n" +
    "                    >\n" +
    "                    </span>\n" +
    "                  </span>\n" +
    "								  <span\n" +
    "                    ng-if=\"jed.arjed && jed.arjed.claim && jed.arjed.type === 'chargeback'\"\n" +
    "                  >\n" +
    "                    <a\n" +
    "                      ng-class=\"{'bg-warning': jed.arjed.claimBalance > 0 && jed.arjed.claimPastDueDays < 30 && jed.arjed.claimPastDueDays > 15, 'bg-danger': jed.arjed.claimBalance > 0 && jed.arjed.claimPastDueDays > 30,'link-disabled': !CanRetrieveClaim}\"\n" +
    "                      ui-sref=\"claimdetail({patientid: jed.arjed.claim.patient, claimid: jed.arjed.claim.id})\"\n" +
    "                    >\n" +
    "                      {{jed.arjed.claim.claim_number}}\n" +
    "                    </a>\n" +
    "                    <span\n" +
    "                      class=\"icomoon icon-icomoon-info2\"\n" +
    "                      uib-popover-template=\"'claim-total-remaining-pastdue-chargebackfor.html'\"\n" +
    "                    >\n" +
    "                    </span>\n" +
    "                  </span>\n" +
    "\n" +
    "                  <span\n" +
    "                    ng-if=\"jed.arjed && !jed.arjed.claim && jed.arjed.type === 'chargeback'\"\n" +
    "                  >\n" +
    "                    Chargeback for Forgone Claim\n" +
    "                    <span\n" +
    "                      class=\"icomoon icon-icomoon-info2\"\n" +
    "                      uib-popover-template=\"'noclaim-chargeback.html'\"\n" +
    "                    >\n" +
    "                    </span>\n" +
    "                  </span>\n" +
    "                  <span\n" +
    "                    ng-if=\"jed.arjed && !jed.arjed.claim && jed.arjed.type === 'claimee'\"\n" +
    "                  >\n" +
    "                    Forgone Claim\n" +
    "                    <span\n" +
    "                      class=\"icomoon icon-icomoon-info2\"\n" +
    "                      uib-popover-template=\"'noclaim-remaining-payments-writeoffs-chargebacks.html'\"\n" +
    "                    >\n" +
    "                  </span>\n" +
    "\n" +
    "                </td>\n" +
    "                <td>\n" +
    "                  <div class=\"btn-group\" uib-dropdown ng-show=\"jed.arjed.treatment.encounters.length > 0\">\n" +
    "                    <button ng-disabled=\"!CanRetrieveEncounter\" type=\"button\" class=\"btn btn-default btn-sm dropdown-toggle\" uib-dropdown-toggle>\n" +
    "                      Encounters\n" +
    "                      <span class=\"caret\"></span>\n" +
    "                    </button>\n" +
    "                    <ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "                      <li ng-repeat=\"e in jed.arjed.treatment.encounters | orderBy: 'encounter_date'\"><a ui-sref=\"chart-detail({patientid:e.patient, encounterid: e.id})\">{{(e.encounter_date | date:'MM-dd-yyyy') + \" - \" + e.title}}</a></li>\n" +
    "                    </ul>\n" +
    "                  </div>\n" +
    "                </td>\n" +
    "                <td>{{jed.arjed.claim.state}}</td>\n" +
    "                <td>\n" +
    "                  <div class=\"btn-group\" uib-dropdown ng-if=\"jed.arjed.type=='claimee'\"  on-toggle=\"jedActionToggled(open, jed);\">\n" +
    "                    <button ng-disabled=\"!CanRetrieveClaim && !CanUpdateInsurance\" type=\"button\" class=\"btn btn-default btn-sm dropdown-toggle\" uib-dropdown-toggle>\n" +
    "                      Actions\n" +
    "                      <span class=\"caret\"></span>\n" +
    "                    </button>\n" +
    "                    <ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "                      <li ng-show=\"CanRetrieveClaim\" ng-if=\"!claimContext\" ng-hide=\"!jed.arjed.claim\"><a ui-sref=\"claimdetail({patientid: jed.arjed.claim.patient, claimid: jed.arjed.claim.id})\">Claim details</a></li>\n" +
    "                      <li ng-if=\"!claimContext\" role=\"separator\" class=\"divider\"></li>\n" +
    "                      <li ng-show=\"CanUpdateInsurance\" ng-hide=\"!jed.associatedArjed\"><a ng-click=\"openAddChargebackModal(\n" +
    "                      'Chargeback to Patient / Chargeback to Insurance',\n" +
    "                      '',\n" +
    "                      practice.currency,\n" +
    "                      practice.insurance_ar_gl_account,\n" +
    "                      jed.arjed.treatment,\n" +
    "                      jed.arjed.claim,\n" +
    "                      jed.arjed.invoice,\n" +
    "                      jed.arjed.remaining,\n" +
    "                      jed,\n" +
    "                      animationsEnabled,\n" +
    "                      'Negative amounts represent chargebacks to patient, which will increase Patient Receivables Balance and reduce Insurance Receivables Balance. Positive amounts represent chargebacks to insurance, which will increase Insurance Receivables Balance and reduce Patient Receivables Balance.'\n" +
    "                      )\">Chargeback to Patient / Chargeback to Insurance</a></li>\n" +
    "                      <li ng-show=\"CanUpdateInsurance\" ><a ng-click=\"openAddJEModal(\n" +
    "                      'Writeoff / Writeoff Reversal',\n" +
    "                      '',\n" +
    "                      practice.currency,\n" +
    "                      practice.insurance_ar_gl_account,\n" +
    "                      'writeoff',\n" +
    "                      jed.arjed.treatment,\n" +
    "                      jed.arjed.claim,\n" +
    "                      jed.arjed.invoice,\n" +
    "                      practice.writeoff_gl_account,\n" +
    "                      jed.arjed.remaining,\n" +
    "                      null,\n" +
    "                      jed,\n" +
    "                      animationsEnabled,\n" +
    "                      'Negative amounts represent writeoffs, which will reduce Receivables Balance and incur bad debt expense. Positive amounts represent writeoff reversals, which will increase Receivables Balance and reduce bad debt expense.'\n" +
    "                      )\">Writeoff / Writeoff Reversal</a></li>\n" +
    "                    </ul>\n" +
    "                  </div>\n" +
    "                </td>\n" +
    "              </tr>\n" +
    "            </tbody>\n" +
    "					</table>\n" +
    "        </div>\n" +
    "			</div>\n" +
    "		</div>\n" +
    "	</div>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"addPayment.html\">\n" +
    "	<div class=\"modal-header\">\n" +
    "		<h3 class=\"modal-title\">{{title}}</h3>\n" +
    "	</div>\n" +
    "	<div class=\"modal-body\">\n" +
    "    <!-- this is a hack to show the swal backdrop on top of the modal backdrop -->\n" +
    "    <style>\n" +
    "      .sweet-overlay { z-index: 1051; }\n" +
    "    </style>\n" +
    "		<form name='myForm'>\n" +
    "			<div class=\"form-group\">\n" +
    "				<label for=\"jemethod\">Payment Method:</label>\n" +
    "        <select class='form-control' ng-model=\"method\" ng-init=\"method='Insurance Payment - Check'\">\n" +
    "          <option>Insurance Payment - Check</option>\n" +
    "          <option>Insurance Payment - Electronic (EFT)</option>\n" +
    "          <option>Insurance Payment - Virtual Credit Card</option>\n" +
    "        </select>\n" +
    "			</div>\n" +
    "			<div class=\"form-group\">\n" +
    "				<label for=\"jememo\">Payer and other info:</label>\n" +
    "				<textarea id=\"jememo\" class=\"form-control\" type=\"text\" ng-model=\"payer\"></textarea>\n" +
    "			</div>\n" +
    "			<div class=\"form-group\">\n" +
    "				<label for=\"jememo\">Memo:</label>\n" +
    "				<textarea id=\"jememo\" class=\"form-control\" type=\"text\" ng-model=\"newJournalEntryInfo.memo\"></textarea>\n" +
    "			</div>\n" +
    "      <div class=\"form-horizontal\">\n" +
    "        <div ng-form=\"innerform\" class=\"form-group has-feedback\" ng-repeat=\"jed in jedsWithArjedToPay | orderBy: 'created': true\" ng-class=\"{'has-error':innerform.amount.$invalid,'has-success':innerform.amount.$valid}\">\n" +
    "          <div class=\"col-md-2\">\n" +
    "            <input class=\"form-control\" type=\"text\" name=\"amount\" required \n" +
    "              ng-disabled=\"isProcessing || result.pg_response_type === 'A' || changeTotalAmount\" \n" +
    "              ng-model-options=\"{debounce:{blur:1,default:500}, updateOn:'default blur'}\" \n" +
    "              ng-model=\"jed.arjed.paymentAmount\" \n" +
    "              ng-pattern=\"AMOUNT_REGEX\" \n" +
    "            />\n" +
    "            <span ng-hide=\"changeTotalAmount\" class=\"icomoon icon-icomoon-checkmark form-control-feedback\" ng-class=\"{'icon-icomoon-checkmark':innerform.amount.$valid, 'icon-icomoon-warning':innerform.amount.$invalid}\"></span>\n" +
    "          </div>\n" +
    "          <div class=\"col-md-10\">\n" +
    "            <p class=\"form-control-static m-b-0\">{{ jed.description }}</p>\n" +
    "            <span class=\"help-block\" ng-if=\"innerform.amount.$invalid\">Amount must be a decimal number to the hundredths (e.g. 6.23).</span>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group has-feedback\" ng-class=\"{'has-success':myForm.amount.$valid, 'has-error':myForm.amount.$invalid}\" ng-show=\"showUnassociatedAmount\">\n" +
    "          <div class=\"col-md-2\">\n" +
    "            <input id=\"jeamt\" class=\"form-control\" name=\"amount\" type=\"text\" required\n" +
    "              ng-disabled=\"isProcessing || result.pg_response_type === 'A'\"\n" +
    "              ng-model-options=\"{debounce: {default:500, blur:1}, updateOn:'default blur'}\"\n" +
    "              ng-model=\"unassociatedAmount\"\n" +
    "              ng-pattern=\"AMOUNT_REGEX\"/>\n" +
    "            <span class=\"icomoon icon-icomoon-checkmark form-control-feedback\" ng-class=\"{'icon-icomoon-checkmark':myForm.amount.$valid, 'icon-icomoon-warning':myForm.amount.$invalid}\"></span>\n" +
    "          </div>\n" +
    "          <div class=\"col-md-10\">\n" +
    "            <p class=\"form-control-static m-b-0\">Amount unassociated with a treatment</p>\n" +
    "            <span class=\"help-block\" ng-if=\"myForm.amount.$invalid\">Amount must be a decimal number to the hundredths (e.g. 6.23).</span>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"alert alert-info\">\n" +
    "        <div ng-if=\"!changeTotalAmount\">\n" +
    "          <strong>Total amount</strong>\n" +
    "          <span ng-if=\"newJournalEntryInfo.amount && newJournalEntryInfo.amount !== 'NaN'\">\n" +
    "            {{ newJournalEntryInfo.amount }} {{ currency }}\n" +
    "          </span>\n" +
    "          <span ng-if=\"!newJournalEntryInfo.amount || newJournalEntryInfo.amount === 'NaN'\">\n" +
    "            INVALID AMOUNT\n" +
    "          </span>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group has-feedback\" ng-class=\"{'has-success':myForm.totalamount.$valid, 'has-error':myForm.totalamount.$invalid}\" ng-if=\"changeTotalAmount\">\n" +
    "          <div>\n" +
    "            <strong>Total amount</strong>\n" +
    "            <input id=\"pmtamt\" name=\"totalamount\" type=\"text\" required\n" +
    "              ng-disabled=\"isProcessing || result.pg_response_type === 'A'\"\n" +
    "              ng-model-options=\"{debounce: {default:500, blur:1}, updateOn:'default blur'}\"\n" +
    "              ng-model=\"newJournalEntryInfo.amount\"\n" +
    "              ng-pattern=\"AMOUNT_REGEX\"\n" +
    "            />\n" +
    "            <span class=\"icomoon icon-icomoon-checkmark form-control-feedback\" ng-class=\"{'icon-icomoon-checkmark':myForm.totalamount.$valid, 'icon-icomoon-warning':myForm.totalamount.$invalid}\"></span>\n" +
    "          </div>\n" +
    "          <div>\n" +
    "            <span class=\"help-block\" ng-if=\"myForm.totalamount.$invalid\">Amount must be a decimal number to the hundredths (e.g. 6.23).</span>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"checkbox-inline custom-control custom-checkbox m-x-0\" >\n" +
    "          <label>\n" +
    "            <input\n" +
    "              type=\"checkbox\"\n" +
    "              ng-model=\"changeTotalAmount\"\n" +
    "              ng-disabled=\"!newJournalEntryInfo.amount || newJournalEntryInfo.amount === 'NaN'\"\n" +
    "            >\n" +
    "            <span class=\"custom-control-indicator\"></span>\n" +
    "          </label>\n" +
    "        </div>\n" +
    "        <div class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "          <label style=\"margin-right:4px;\">Edit total amount to auto-calculate line items?</label>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"form-group m-b\">\n" +
    "        <angular-forte-handler\n" +
    "          control='control'\n" +
    "          is-connected='isConnected'\n" +
    "          is-processing='isProcessing'\n" +
    "          pg-merchant-id='merchantId'\n" +
    "          pg-total-amount='newJournalEntryInfo.amount'\n" +
    "          result='result'\n" +
    "        />\n" +
    "        <button class=\"btn btn-danger\" type=\"button\"\n" +
    "          ng-disabled=\"!isConnected || isProcessing || myForm.amount.$invalid || result.pg_response_type === 'A'\"\n" +
    "          ng-click=\"control.triggerTransaction(transactionType)\">\n" +
    "          {{ transactionTypeDisplay }}\n" +
    "        </button>\n" +
    "        <label ng-if=\"result\" ng-class=\"{'font-green-jungle': result.pg_response_type === 'A', 'font-red-sunglo': result.pg_response_type === 'D' || result.pg_response_type === 'E', 'font-blue-steel': !result.pg_response_type}\">\n" +
    "          ({{ result.pg_response_description ? result.pg_response_description : result.event }})\n" +
    "        </label>\n" +
    "      </div>\n" +
    "      <div class=\"alert alert-warning m-b-0\">\n" +
    "        Payment required in: <strong>{{ currency }}</strong>.\n" +
    "        <br>\n" +
    "        Positive amounts represent payments and credit AR account and debit cash account. Negative amounts represent refunds and debit AR account and credit cash account.\n" +
    "      </div>\n" +
    "		</form>\n" +
    "	</div>\n" +
    "	<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-default btn-wide\" ng-click=\"cancel()\" ng-disabled=\"isProcessing || result.pg_response_type === 'A'\">Cancel</button>\n" +
    "    <button class=\"btn btn-primary btn-wide\" ng-click=\"ok()\" ng-disabled=\"isProcessing || myForm.$invalid\">OK</button>\n" +
    "	</div>\n" +
    "</script>\n" +
    "\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"addJE.html\">\n" +
    "	<div class=\"modal-header\">\n" +
    "		<h3 class=\"modal-title\">{{title}}</h3>\n" +
    "	</div>\n" +
    "	<div class=\"modal-body\">\n" +
    "		<form name='myForm'>\n" +
    "      <div class=\"form-group\" ng-class=\"{ 'has-success': myForm.description.$valid, 'has-error': myForm.description.$invalid}\">\n" +
    "	      <label for=\"jedesc\">Description:</label>\n" +
    "	      <textarea id=\"jedesc\" name='description' class=\"form-control\" ng-model=\"newJournalEntryInfo.description\" data-required=\"true\"></textarea>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\">\n" +
    "	      <label for=\"jememo\">Memo:</label>\n" +
    "	      <textarea id=\"jememo\" class=\"form-control\" type=\"text\" ng-model=\"newJournalEntryInfo.memo\"></textarea>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"form-group has-feedback\"\n" +
    "        ng-class=\"{\n" +
    "            'has-success':  myForm.amount.$valid,\n" +
    "            'has-error':    myForm.amount.$invalid\n" +
    "        }\"\n" +
    "      >\n" +
    "	      <label for=\"jeamt\">Amount:</label>\n" +
    "        <input id=\"jeamt\" name=\"amount\"\n" +
    "          class=\"form-control\"\n" +
    "          type=\"text\"\n" +
    "          ng-model=\"newJournalEntryInfo.amount\"\n" +
    "          ng-pattern=\"AMOUNT_REGEX\"\n" +
    "          ng-model-options=\"{\n" +
    "              updateOn:'default blur',\n" +
    "              debounce: {\n" +
    "                  default: 500,\n" +
    "                  blur: 1\n" +
    "              },\n" +
    "          }\"\n" +
    "          ng-disabled=\"isProcessing || result.pg_response_type === 'A'\"\n" +
    "          required\n" +
    "        />\n" +
    "        <span class=\"icomoon icon-icomoon-checkmark form-control-feedback\"\n" +
    "            ng-class=\"{\n" +
    "            'icon-icomoon-checkmark':myForm.amount.$valid,\n" +
    "            'icon-icomoon-warning':myForm.amount.$invalid\n" +
    "            }\"\n" +
    "        >\n" +
    "        </span>\n" +
    "        <small class=\"help-block\" ng-if=\"myForm.amount.$invalid\">\n" +
    "          Amount must be a decimal number to the hundredths (e.g. 6.23).\n" +
    "        </small>\n" +
    "			</div>\n" +
    "			<div class=\"form-group\">\n" +
    "				<label>Payment required in: {{currency}}. <br>{{helpText}}</label>\n" +
    "			</div>\n" +
    "		</form>\n" +
    "	</div>\n" +
    "	<div class=\"modal-footer\">\n" +
    "		<button ng-disabled=\"myForm.$invalid\" class=\"btn btn-primary\" ng-click=\"ok()\">OK\n" +
    "    </button>\n" +
    "		<button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel\n" +
    "    </button>\n" +
    "	</div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"claim-total-remaining-pastdue-payments-writeoffs.html\">\n" +
    "  <span>\n" +
    "    <strong>Claim date:</strong> {{jed.arjed.claim.claim_date | date:\"MM/dd/yyyy\"}}<br/>\n" +
    "    <strong>Claim total:</strong> {{jed.arjed.claimTotal}}<br/>\n" +
    "    <strong>Claim remaining:</strong> {{jed.arjed.claimBalance}}<br/>\n" +
    "    <span ng-show=\"jed.arjed.claimBalance > 0\"><strong>Claim past due: </strong>{{jed.arjed.claimPastDueDays}} days</br></span>\n" +
    "    <strong>Line item payments total:</strong> {{jed.arjed.paymentsTotal}}. <br/>\n" +
    "    <strong>Line item write-offs total:</strong> {{jed.arjed.writeoffsTotal}} <br/>\n" +
    "    <strong>Line item chargebacks total:</strong> {{jed.arjed.chargebacksTotal}}\n" +
    "  </span>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"claim-total-remaining-pastdue-writeofffor.html\">\n" +
    "  <span>\n" +
    "    <strong>Claim date:</strong> {{jed.arjed.claim.claim_date | date:\"MM/dd/yyyy\"}}<br/>\n" +
    "    <strong>Claim total:</strong> {{jed.arjed.claimTotal}}<br/>\n" +
    "    <strong>Claim remaining:</strong> {{jed.arjed.claimBalance}}<br/>\n" +
    "    <span ng-show=\"jed.arjed.claimBalance > 0\"><strong>Claim past due: </strong>{{jed.arjed.claimPastDueDays}} days</br></span>\n" +
    "    <strong>Writeoff for #:</strong> {{jed.arjed.writeoff_for}}\n" +
    "  </span>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"claim-total-remaining-pastdue-paymentfor.html\">\n" +
    "  <span>\n" +
    "    <strong>Claim date:</strong> {{jed.arjed.claim.claim_date | date:\"MM/dd/yyyy\"}}<br/>\n" +
    "    <strong>Claim total:</strong> {{jed.arjed.claimTotal}}<br/>\n" +
    "    <strong>Claim remaining:</strong> {{jed.arjed.claimBalance}}<br/>\n" +
    "    <span ng-show=\"jed.arjed.claimBalance > 0\"><strong>Claim past due: </strong>{{jed.arjed.claimPastDueDays}} days</br></span>\n" +
    "    <strong>Payment for #:</strong> {{jed.arjed.payment_for}}\n" +
    "  </span>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"claim-total-remaining-pastdue-chargebackfor.html\">\n" +
    "  <span>\n" +
    "    <strong>Claim date:</strong> {{jed.arjed.claim.claim_date | date:\"MM/dd/yyyy\"}}<br/>\n" +
    "    <strong>Claim total:</strong> {{jed.arjed.claimTotal}}<br/>\n" +
    "    <strong>Claim remaining:</strong> {{jed.arjed.claimBalance}}<br/>\n" +
    "    <span ng-show=\"jed.arjed.claimBalance > 0\"><strong>Claim past due: </strong>{{jed.arjed.claimPastDueDays}} days</br></span>\n" +
    "    <strong>Chargeback for #:</strong> {{jed.arjed.chargeback_for}}\n" +
    "  </span>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"noclaim-chargeback.html\">\n" +
    "  <span>\n" +
    "    <strong>Chargeback for #:</strong> {{jed.arjed.chargeback_for}}\n" +
    "  </span>\n" +
    "</script>\n" +
    "<script type=\"text/ng-template\" id=\"noclaim-remaining-payments-writeoffs-chargebacks.html\">\n" +
    "  <span>\n" +
    "    This claimee line item is forgone and won't ever reduce patient maximums.<br/>\n" +
    "    <strong>Line item remaining:</strong> {{jed.arjed.remaining}}<br/>\n" +
    "    <strong>Line item payments total:</strong> {{jed.arjed.paymentsTotal}}. <br/>\n" +
    "    <strong>Line item write-offs total:</strong> {{jed.arjed.writeoffsTotal}} <br/>\n" +
    "    <strong>Line item chargebacks total:</strong> {{jed.arjed.chargebacksTotal}}\n" +
    "  </span>\n" +
    "</script>\n" +
    "<script type=\"text/ng-template\" id=\"payment_state_info.html\">\n" +
    "  <span ng-if=\"jed.arjed.claim.state === 'claim_completed'\"\n" +
    "  >\n" +
    "    Payment cannot be applied to this line item because the claim was completed.<br/>\n" +
    "  </span>\n" +
    "  <span ng-if=\"\n" +
    "    jed.arjed.claim.state.indexOf('insurance') !== -1 ||\n" +
    "    jed.arjed.claim.state.indexOf('other_coverage') !== -1\n" +
    "  \">\n" +
    "    Payments will be applied to <strong>{{(jed.arjed.claim.state.indexOf('insurance') != -1) ? 'insurance': 'other coverage'}}</strong> maximums because the claim state is <strong>{{jed.arjed.claim.state}}</strong>.<br/>\n" +
    "  </span>\n" +
    "  <span ng-if=\"(\n" +
    "      jed.arjed.claim.state.indexOf('insurance') === -1 &&\n" +
    "      jed.arjed.claim.state.indexOf('other_coverage') === -1 &&\n" +
    "      jed.arjed.claim.state !== 'claim_completed'\n" +
    "    )\"\n" +
    "  \">\n" +
    "    Payment cannot be applied to this line item because the claim has not been submitted to insurance yet.\n" +
    "  </span>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/common/patient-detail-photo-detail.tpl.html","<div id=\"canvas-container\">\n" +
    "  <div id=\"dwtcontrolContainer\" ng-hide=\"calibrating\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-file-plus2 font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Image Detail</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\">\n" +
    "          <div class=\"btn-group\">\n" +
    "            <button type=\"button\" class=\"btn btn-default\" back-button>\n" +
    "              <i class=\"icomoon icon-icomoon-arrow-left\"></i> Back\n" +
    "            </button>\n" +
    "            <button type=\"button\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\"\n" +
    "              ng-disabled=\"!CanUpdateImage\"\n" +
    "              ng-click=\"update()\">\n" +
    "              <span class=\"icomoon icon-icomoon-upload\"></span> Update\n" +
    "            </button>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <div class=\"panel panel-default\">\n" +
    "          <div class=\"panel-heading\">\n" +
    "            <h3 class=\"panel-title\">Image Processing Tools</h3>\n" +
    "          </div>\n" +
    "          <div class=\"panel-body\">\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"col-sm-2 form-group\">\n" +
    "                <div class=\"btn-group\">\n" +
    "                  <button type=\"checkbox\" class=\"btn btn-default\"\n" +
    "                    ng-disabled=\"!CanUpdateImage\"\n" +
    "                    ng-class=\"{active: transforms.filters.invert}\"\n" +
    "                    ng-click=\"transforms.filters.invert = !transforms.filters.invert\"\n" +
    "                    ng-model=\"transforms.filters.invert\">\n" +
    "                    Invert\n" +
    "                  </button>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-2\">\n" +
    "                <div class=\"btn-group\">\n" +
    "                  <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" ng-disabled=\"!CanUpdateImage\" ng-click=\"zoomIn()\">\n" +
    "                    <i class=\"icomoon icon-icomoon-plus2\"></i>\n" +
    "                  </button>\n" +
    "                  <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" ng-disabled=\"!CanUpdateImage\" ng-click=\"zoomOut()\">\n" +
    "                    <i class=\"icomoon icon-icomoon-minus2\"></i>\n" +
    "                  </button>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-5 form-group\">\n" +
    "                <!--<button type=\"button\" class=\"btn btn-xs pull-right\"-->\n" +
    "                <!--ng-disabled=\"!transforms.hasActions()\"-->\n" +
    "                <!--ng-click=\"undoAction()\">-->\n" +
    "                <!--<i class=\"fa fa-undo\"></i>-->\n" +
    "                <!--</button>-->\n" +
    "                <button type=\"button\" class=\"btn btn-default\"\n" +
    "                  ng-class=\"{active: action == 'measure'}\"\n" +
    "                  ng-disabled=\"measuresBackup || !CanUpdateImage\"\n" +
    "                  ng-click=\"toggleMeasurement()\">\n" +
    "                  Take Measure\n" +
    "                </button>\n" +
    "                <!--<button type=\"button\" class=\"btn btn-xs pull-right\"-->\n" +
    "                <!--ng-class=\"{active: measuresBackup}\"-->\n" +
    "                <!--ng-disabled=\"!transforms.hasMeasures() && !measuresBackup\"-->\n" +
    "                <!--ng-click=\"toggleMeasures()\">{{ measuresBackup ? \"Show\" : \"Hide\"}} Measures-->\n" +
    "                <!--</button>-->\n" +
    "                <button type=\"button\" class=\"btn btn-default\"\n" +
    "                  ng-disabled=\"!CanUpdateImage\"\n" +
    "                  ng-disabled=\"!transforms.hasMeasures()\"\n" +
    "                  ng-click=\"transforms.clearMeasures()\">\n" +
    "                  Clear Measures\n" +
    "                </button>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-1  form-group \">\n" +
    "                <div class=\"btn-group\">\n" +
    "                  <button type=\"button\" class=\"btn btn-default\"\n" +
    "                    ng-disabled=\"!CanUpdateImage\"\n" +
    "                    ng-click=\"clearTransforms()\">\n" +
    "                    Reset to original\n" +
    "                  </button>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\" ng-if=\"CanUpdateImage\">\n" +
    "              <div class=\"col-md-6\">\n" +
    "                Brightness\n" +
    "                <div ui-slider min=\"0\" max=\"255\" ng-model=\"transforms.filters.brightness\"></div>\n" +
    "              </div>\n" +
    "              <div class=\"col-md-6 \">\n" +
    "                Contrast\n" +
    "                <div ui-slider min=\"0\" max=\"255\" ng-model=\"transforms.filters.contrast\"></div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <br>\n" +
    "            <br>\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"col-sm-3 form-group pull-right\">\n" +
    "                <p class=\"input-group\">\n" +
    "                  <select name=\"calibrationSelect\" id=\"calibrationSelect\" class=\"form-control\"\n" +
    "                    ng-if=\"calibrations.length\"\n" +
    "                    ng-options=\"option.name for option in calibrations track by option.id\"\n" +
    "                    ng-change=\"updateCalibration()\" ng-disabled=\"!CanUpdateImage\"\n" +
    "                    ng-model=\"transforms.calibration\">\n" +
    "                    <option value=\"\">- Select a calibration -</option>\n" +
    "                  </select>\n" +
    "                  <span class=\"input-group-btn\">\n" +
    "                    <button type=\"button\" class=\"btn btn-default\"\n" +
    "                      ng-click=\"calibrating=!calibrating\"\n" +
    "                      ng-disabled=\"!CanUpdateImage\">\n" +
    "                      Calibrate\n" +
    "                    </button>\n" +
    "                  </span>\n" +
    "                </p>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <canvas image-editor image-data=\"transforms\" zoomable=\"true\" action=\"action\" fullscreen=\"false\">\n" +
    "          Your browser does not support image edition.\n" +
    "          <img class=\"img-responsive\" alt=\"{{ photo.file_name }}\" style=\"width:100%\"\n" +
    "            crossorigin=\"anonymous\"\n" +
    "            ng-src=\"{{ photo.image }}\">\n" +
    "        </canvas>\n" +
    "        <div>\n" +
    "          <a role=\"button\" class=\"btn btn-default\" target=\"_blank\" href=\"{{photo.image}}\">\n" +
    "            <span class=\"icomoon icon-icomoon-download\"> </span> Open {{photo.file_name}}\n" +
    "          </a>\n" +
    "        </div>\n" +
    "        <div>\n" +
    "          <div class=\"form-group\">\n" +
    "            <label for=\"description\">Description</label>\n" +
    "            <input type=\"text\" class=\"form-control\" id=\"description\" placeholder=\"Description\"\n" +
    "              ng-disabled=\"!CanUpdateImage\"\n" +
    "              ng-model=\"photo.description\">\n" +
    "          </div>\n" +
    "          <div class=\"form-group\">\n" +
    "            <label for=\"processingInstructions\">Processing instructions</label>\n" +
    "            <input type=\"text\" class=\"form-control\" id=\"processingInstructions\" placeholder=\"Processing instructions\"\n" +
    "              ng-disabled=\"!CanUpdateImage\"\n" +
    "              ng-model=\"photo.processing_instructions\">\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"actions\">\n" +
    "          <div class=\"btn-group \">\n" +
    "            <button type=\"button\" class=\"btn p-x btn-default\" back-button>\n" +
    "              <i class=\"icon-icomoon-arrow-left2\"></i> Back\n" +
    "            </button>\n" +
    "            <button type=\"button\" class=\"btn p-x btn-primary\" ng-click=\"update()\" style=\"margin-left: 6px;\" ng-disabled=\"!CanUpdateImage\">\n" +
    "              <span class=\"icomoon icon-icomoon-upload\"></span> Update\n" +
    "            </button>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <calibration-tool ng-show=\"calibrating\"></calibration-tool>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/patient-detail-photos.tpl.html","\n" +
    "<patient-image-detail-patient\n" +
    "  can-delete=\"CanDeletePatientImage\"\n" +
    "  can-list=\"CanListPatientImage\"\n" +
    "  can-retrieve=\"CanRetrievePatientImage\"\n" +
    "  can-update=\"CanUpdatePatientImage\"\n" +
    "  can-retrieve-encounter=\"CanRetrieveEncounter\"\n" +
    "  patient-id=\"patientId\">\n" +
    "</patient-image-detail-patient>\n" +
    "")

$templateCache.put("src/common/patient-detail-prescriptions.tpl.html","<!-- Prescriptions -->\n" +
    "\n" +
    "<div style=\"overflow-x:auto;\">\n" +
    "  <table class=\"table table-striped table-bordered table-advance table-vcenter m-b-0 b-0\">\n" +
    "    <thead>\n" +
    "      <tr>\n" +
    "        <th>Date</th>\n" +
    "        <th>Drug</th>\n" +
    "        <th>Dosage</th>\n" +
    "        <th>Quantity</th>\n" +
    "        <th>Status</th>\n" +
    "        <th>Instructions</th>\n" +
    "        <th class=\"col-narrow\" style=\"min-width:120px\"></th>\n" +
    "      </tr>\n" +
    "    </thead>\n" +
    "    <tr ng-repeat=\"p in prescriptions | orderBy: 'date'\">\n" +
    "      <td class=\"narrow\">{{ p.date | date:\"MM/dd/yyyy\" }}</td>\n" +
    "      <td class=\"narrow\">{{ p.drug }}</td>\n" +
    "      <td class=\"narrow\">{{ p.dosage }}</td>\n" +
    "      <td class=\"narrow\">{{ p.quantity }}</td>\n" +
    "      <td class=\"narrow\">{{ prescriptionStatusDisplay(p.status) }}</td>\n" +
    "      <td>{{ p.instructions }}</td>\n" +
    "      <td>\n" +
    "        <div class=\"btn-group\">\n" +
    "          <button class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" type=\"button\"\n" +
    "            ng-click=\"openMaintainPrescriptionModal(encounterId, p);\"\n" +
    "            ng-disabled=\"!CanRetrievePrescription\"\n" +
    "            ng-show=\"(mode === 'ENCOUNTER') && !p.prescription_provider_id\">\n" +
    "            <i class=\"icomoon icon-icomoon-pencil5\" aria-hidden=\"true\"></i>\n" +
    "          </button>\n" +
    "          <button class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" type=\"button\"\n" +
    "            ng-click=\"openMaintainPrescriptionModal(encounterId, p, true);\"\n" +
    "            ng-disabled=\"!CanRetrievePrescription\"\n" +
    "            ng-show=\"(mode === 'ENCOUNTER') && p.prescription_provider_id\">\n" +
    "            <i class=\"icomoon icon-icomoon-eye\" aria-hidden=\"true\"></i>\n" +
    "          </button>\n" +
    "          <button class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" type=\"button\"\n" +
    "            ng-click=\"openDelPrescriptionModal(p.id);\"\n" +
    "            ng-disabled=\"!CanDeletePrescription\"\n" +
    "            ng-show=\"(mode === 'ENCOUNTER') && !p.prescription_provider_id\">\n" +
    "            <i class=\"icomoon icon-icomoon-bin\" aria-hidden=\"true\"></i>\n" +
    "          </button>\n" +
    "          <button class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" type=\"button\"\n" +
    "            ng-disabled=\"!CanRetrieveEncounter\"\n" +
    "            ng-show=\"mode !== 'ENCOUNTER' && p.encounter\"\n" +
    "            ui-sref=\"chart-detail({encounterid: p.encounter.id})\">\n" +
    "            <div class=\"text\">Encounter</div>\n" +
    "          </button>\n" +
    "          <button type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\"\n" +
    "            ng-click=\"printPrescription(p.id);\"\n" +
    "            ng-disabled=\"!CanRetrievePrescription\"\n" +
    "            ng-show=\"!p.prescription_provider_id\">\n" +
    "            <i class=\"icomoon icon-icomoon-printer2\" aria-hidden=\"true\"></i>\n" +
    "          </button>\n" +
    "        </div>\n" +
    "      </td>\n" +
    "    </tr>\n" +
    "  </table>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"ePrescriptionPicker.html\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Select Prescription Type</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <form name='myForm'>\n" +
    "      <div class=\"form-group m-b-0\">\n" +
    "        <div class=\"radio-inline custom-control custom-radio\">\n" +
    "          <label>\n" +
    "            <input type=\"radio\" ng-model=\"choice.name\" value=\"eprescription\" ng-disabled=\"ePrescriptionsDisabled\">\n" +
    "            <span class=\"custom-control-indicator\"></span>\n" +
    "            Add ePrescription\n" +
    "          </label>\n" +
    "          <small class=\"help-block text-danger\" ng-if=\"ePrescriptionsDisabled\">\n" +
    "            Only the encounter provider ({{ encounterProvider.name }}) can add ePrescriptions.\n" +
    "          </small>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"form-group m-b-0\">\n" +
    "        <div class=\"radio-inline custom-control custom-radio\">\n" +
    "          <label>\n" +
    "            <input type=\"radio\" ng-model=\"choice.name\" value=\"manual\">\n" +
    "            <span class=\"custom-control-indicator\"></span>\n" +
    "            Add Prescription Manually\n" +
    "          </label>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-wide default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "    <button class=\"btn btn-wide blue\" ng-click=\"ok()\">OK</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"maintainPrescription.html\">\n" +
    "  <fieldset ng-disabled=\"!CanCreatePrescription || !CanUpdatePrescription\">\n" +
    "    <div class=\"modal-header\">\n" +
    "      <h3 class=\"modal-title\" ng-if=\"!isEdit\">Add Prescription</h3>\n" +
    "      <h3 class=\"modal-title\" ng-if=\"isEdit\">Edit Prescription</h3>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body\">\n" +
    "      <form name='myForm'>\n" +
    "         <div class=\"form-group\" ng-class=\"{ 'has-success': myForm.datename.$valid, 'has-error': myForm.datename.$invalid}\">\n" +
    "           <custom-datepicker-popup ng-disabled=\"viewOnly\" show-placeholder=\"false\" ng-model=\"prescription.date\" input-name=\"'datename'\" form-name=\"'myForm'\" format=\"'MM/dd/yyyy'\" ng-required=\"true\"/>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label for=\"patient\">Patient:</label>\n" +
    "          <label id=\"patient\">\n" +
    "            {{ prescription.encounter.patient.first_name }} {{ prescription.encounter.patient.last_name }}, {{ prescription.encounter.patient.patient_address_street_1 }} {{ prescription.encounter.patient.patient_address_street_2 }}, {{ prescription.encounter.patient.patient_address_city }}, {{ prescription.encounter.patient.patient_address_state }} {{ prescription.encounter.patient.patient_address_zip }}\n" +
    "          </label>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label for=\"provider\">Provider:</label>\n" +
    "          <label id=\"provider\">\n" +
    "            <!--add npi, phone, dea, lcense, address -->\n" +
    "            {{ prescription.encounter.provider.first_name }} {{ prescription.encounter.provider.last_name }}\n" +
    "          </label>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label>Drug Template:</label>\n" +
    "          <ui-select ng-model=\"drugTemplate\" autofocus ng-change=\"drugTemplateChange(drugTemplate)\">\n" +
    "            <ui-select-match placeholder=\"Select a Drug Template\">{{ $select.selected.name }}</ui-select-match>\n" +
    "            <ui-select-choices repeat=\"item in drugTemplates | filter: $select.search\">\n" +
    "              {{ item.name }}\n" +
    "            </ui-select-choices>\n" +
    "          </ui-select>\n" +
    "        </div>\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"col-md-6\">\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-success':myForm.drugname.$valid, 'has-error':myForm.drugname.$invalid}\">\n" +
    "              <label for=\"drug\">Drug:</label>\n" +
    "              <input ng-disabled=\"viewOnly\" id=\"drug\" name='drugname' class=\"form-control\" type=\"text\" ng-model=\"prescription.drug\" required></input>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"col-md-6\">\n" +
    "            <div class=\"form-group\" ng-class=\"{ 'has-success': myForm.dosagename.$valid, 'has-error': myForm.dosagename.$invalid}\">\n" +
    "              <label for=\"dosage\">Dosage:</label>\n" +
    "              <input id=\"dosage\" ng-disabled=\"viewOnly\" name='dosagename' class=\"form-control\" type=\"text\" ng-model=\"prescription.dosage\" required></input>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"col-md-6\">\n" +
    "            <div class=\"form-group\" ng-class=\"{ 'has-success': myForm.quantityname.$valid, 'has-error': myForm.quantityname.$invalid}\">\n" +
    "              <label for=\"quantity\">Quantity:</label>\n" +
    "              <input id=\"quantity\" ng-disabled=\"viewOnly\" min=1 max=1000 name='quantityname' class=\"form-control\" type=\"text\" ng-model=\"prescription.quantity\" required></input>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"col-md-6\">\n" +
    "            <div class=\"form-group\" ng-class=\"{ 'has-success': myForm.number_of_refills.$valid, 'has-error': myForm.number_of_refills.$invalid}\">\n" +
    "              <label for=\"refills\">Refills:</label>\n" +
    "              <input id=\"refills\" ng-disabled=\"viewOnly\" min=0 max=100 name='number_of_refills' class=\"form-control\" type=\"number\" ng-model=\"prescription.number_of_refills\" required></input>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label for=\"generics\">\n" +
    "            Allow Generics: <input id=\"generics\" ng-disabled=\"viewOnly\" name='allow_generics' type=\"checkbox\" ng-model=\"prescription.allow_generics\" />\n" +
    "          </label>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label for=\"instructions\">Instructions:</label>\n" +
    "          <textarea id=\"instructions\" ng-disabled=\"viewOnly\" class=\"form-control\" type=\"text\" ng-model=\"prescription.instructions\"></textarea >\n" +
    "        </div>\n" +
    "      </form>\n" +
    "    </div>\n" +
    "  </fieldset>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-wide default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "    <button class=\"btn btn-wide blue\"\n" +
    "      ng-click=\"ok()\"\n" +
    "      ng-disabled=\"myForm.$invalid || !CanCreatePrescription || !CanUpdatePrescription\">\n" +
    "      OK\n" +
    "    </button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"delPrescription.html\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Delete prescription?</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">Are you sure you want to delete this prescription?</div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-wide default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "    <button class=\"btn btn-wide blue\" ng-click=\"ok()\">OK</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/common/patient-detail-profile.tpl.html","<style>\n" +
    "  .overlay {\n" +
    "    filter: blur(0px);\n" +
    "    -webkit-filter: blur(0px);\n" +
    "    margin-right:0px\n" +
    "  }\n" +
    "</style>\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\" ng-class=\"{'overlay':loadingCount}\">\n" +
    "      <wave-spinner class=\"global-loader\" ng-show=\"loadingCount\"></wave-spinner>\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-pencil5 font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Edit Patient Information</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\">\n" +
    "          <div class=\"btn-group\">\n" +
    "            <button ng-hide=\"isAnonymous()\" ui-sref=\"charts\" type=\"button\" class=\"btn p-x btn-default\">\n" +
    "              <i class=\"icomoon icon-icomoon-arrow-left\"></i>\n" +
    "              Back\n" +
    "            </button>\n" +
    "            <button id=\"formToPatientBtn\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\"\n" +
    "              ng-click=\"openSendEmailModal(patient)\"\n" +
    "              ng-disabled=\"!CanUpdatePatient || !patient.id || !patient.email\"\n" +
    "              ng-hide=\"isAnonymous()\">\n" +
    "              <i class=\"icomoon icon-icomoon-envelop\"></i> Send form to patient\n" +
    "            </button>\n" +
    "            <button type=\"button\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\"\n" +
    "              ng-click=\"reset()\"\n" +
    "              ng-disabled=\"!canEditPatientInfo()\"\n" +
    "              ng-hide=\"isAnonymous()\">\n" +
    "              <i class=\"icomoon icon-icomoon-loop3\"></i>  Reload\n" +
    "            </button>\n" +
    "            <button type=\"button\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\"\n" +
    "              ng-click=\"triggerSubmit()\"\n" +
    "              ng-disabled=\"!canEditPatientInfo()\">\n" +
    "              <i class=\"icomoon icon-icomoon-circle-up6\"></i>  Update\n" +
    "            </button>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-title\">\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "\n" +
    "        <div ng-if=\"patient.hmac.valid === false\" class=\"alert alert-danger pos-r\">\n" +
    "          <div ng-if=\"patient.hmac.reasons_for_invalidity.length\">\n" +
    "            <p>\n" +
    "              Dental EMR has detected the following information was altered outside the normal operation of the application.\n" +
    "              Dental EMR is reviewing the cause and will take measures to prevent it in the future.\n" +
    "              Please review the information in this tab, correct any incorrect information noting the fields that were\n" +
    "              detected as being altered, check this verification checkbox, and click update to remove this message.\n" +
    "            </p>\n" +
    "            <table class=\"table\">\n" +
    "              <thead>\n" +
    "                <tr>\n" +
    "                  <th>Reason</th>\n" +
    "                  <th>Property</th>\n" +
    "                  <th>From</th>\n" +
    "                  <th>To</th>\n" +
    "                </tr>\n" +
    "              </thead>\n" +
    "              <tbody>\n" +
    "                <tr ng-repeat=\"reason in patient.hmac.reasons_for_invalidity\">\n" +
    "                  <td>{{ reason[0] }}</td>\n" +
    "                  <td>{{ reason[1].toCamelCase() }}</td>\n" +
    "                  <td>{{ reason[2][0] }}</td>\n" +
    "                  <td>{{ reason[2][1] }}</td>\n" +
    "                </tr>\n" +
    "              </tbody>\n" +
    "            </table>\n" +
    "          </div>\n" +
    "          <div class=\"checkbox-inline custom-control custom-checkbox m-r-0 m-l-0\">\n" +
    "            <label>\n" +
    "              <input type=\"checkbox\" ng-model=\"patient.hmac.validated\">\n" +
    "              <span class=\"custom-control-indicator\"></span>\n" +
    "              I confirm this patient's profile data verified by me and it is okay to remove this warning message.\n" +
    "            </label>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <form ng-if=\"isAuthenticated() || invalidUrl === false\"\n" +
    "              name=\"updatePatient\"\n" +
    "              role=\"form\"\n" +
    "              class=\"form-horzizontal\"\n" +
    "              angular-validator\n" +
    "              angular-validator-submit=\"submit()\"\n" +
    "              novalidate>\n" +
    "\n" +
    "          <fieldset ng-disabled=\"!canEditPatientInfo()\">\n" +
    "\n" +
    "            <!--\n" +
    "            <div class=\"btn-group\">\n" +
    "                <button type=\"submit\" class=\"btn p-x purple-sharp btn-circle\">\n" +
    "                  <span class=\"glyphicon glyphicon-upload pull\">Update</span>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "            -->\n" +
    "            <div class=\"panel panel-default\" ng-if=\"!isAnonymous()\">\n" +
    "              <div class=\"panel-heading\">\n" +
    "                <h4 class=\"panel-title\">Patient Record</h4>\n" +
    "              </div>\n" +
    "              <div class=\"panel-body\">\n" +
    "                <div class=\"row\">\n" +
    "                  <div class=\"col-sm-6 form-horizontal form-groupa\">\n" +
    "                    <div class=\"form-group m-b-0\">\n" +
    "                      <label class=\"control-label col-sm-5\">Archived?</label>\n" +
    "                      <div class=\"col-sm-7\" style=\"padding-top:7px;\">\n" +
    "                        <switch ng-model=\"patient.isArchived\" class=\"small\"></switch>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"panel panel-default\" ng-if=\"!isAnonymous()\">\n" +
    "              <div class=\"panel-heading\">\n" +
    "                <h4 class=\"panel-title\">Patient Portal</h4>\n" +
    "              </div>\n" +
    "              <div class=\"panel-body\">\n" +
    "                <div class=\"row\">\n" +
    "                  <div class=\"col-sm-6 form-horizontal\">\n" +
    "                    <div class=\"form-group\">\n" +
    "                      <label class=\"col-sm-5 control-label\">Enabled?</label>\n" +
    "                      <div class=\"col-sm-7\" style=\"padding-top:7px;\">\n" +
    "                        <switch\n" +
    "                          class=\"small\"\n" +
    "                          ng-disabled=\"!CanUpdatePatient || !patient.id || !patient.email\"\n" +
    "                          ng-model=\"patient.patientportalUser.is_active\">\n" +
    "                        </switch>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"form-group\" ng-if=\"patient.patientportalUser.id\">\n" +
    "                      <label class=\"col-sm-5 control-label\">Username</label>\n" +
    "                      <div class=\"col-sm-7\">\n" +
    "                        <p class=\"form-control\" readonly>{{ patient.patientportalUser.username }}</p>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"form-group\" ng-if=\"patient.patientportalUser.id\">\n" +
    "                      <label class=\"col-sm-5 control-label\"></label>\n" +
    "                      <div class=\"col-sm-7\">\n" +
    "                        <button\n" +
    "                          class=\"btn btn-sm p-x blue\"\n" +
    "                          ng-click=\"sendPatientPortalEmail(patient.id)\" \n" +
    "                          ng-disabled=\"!CanUpdatePatient || !patient.id || !patient.email\"\n" +
    "                          type=\"button\">\n" +
    "                          Send {{ patient.patientportalUser.has_password ? \"Reset password\" : \"Invitation\" }} Email\n" +
    "                        </button>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div ng-include=\"'src/common/patient-form-demographic-information.partial.tpl.html'\"></div>\n" +
    "            <div ng-include=\"'src/common/patient-form-student.partial.tpl.html'\"></div>\n" +
    "            <div ng-if=\"!isAnonymous()\" ng-include=\"'src/common/patient-form-fee-profile.partial.tpl.html'\"></div>\n" +
    "            <div ng-if=\"!isAnonymous()\" ng-include=\"'src/common/patient-form-insurance.partial.tpl.html'\"></div>\n" +
    "            <div ng-include=\"'src/common/patient-form-responsible.partial.tpl.html'\"></div>\n" +
    "            <div ng-include=\"'src/common/patient-form-dental-information.partial.tpl.html'\"></div>\n" +
    "            <div ng-include=\"'src/common/patient-form-medical-history.partial.tpl.html'\"></div>\n" +
    "            <div ng-include=\"'src/common/patient-form-medications-allergies.partial.tpl.html'\"></div>\n" +
    "            <div ng-include=\"'src/common/patient-form-conclusion.partial.tpl.html'\"></div>\n" +
    "            <div ng-if=\"isAnonymous()\" ng-include=\"'src/common/patient-form-insurance.partial.tpl.html'\"></div>\n" +
    "          </fieldset>\n" +
    "          <div class=\"row\">\n" +
    "              <div class=\"btn-group pull-right\" style=\"margin-right: 15px;\">\n" +
    "                 <button class=\"btn p-x btn-primary\"\n" +
    "                    ng-click=\"openSendEmailModal(patient)\"\n" +
    "					ng-disabled=\"!CanUpdatePatient || !patient.id || !patient.email\"\n" +
    "                    ng-hide=\"isAnonymous()\">\n" +
    "                    Send form to patient\n" +
    "                  </button>\n" +
    "                  <button type=\"button\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\" ng-click=\"triggerSubmit()\" ng-disabled=\"!canEditPatientInfo()\">\n" +
    "                    <i class=\"icomoon icon-icomoon-circle-up6\"></i>  Update\n" +
    "                  </button>\n" +
    "              </div>\n" +
    "          </div>\n" +
    "\n" +
    "        </form>\n" +
    "\n" +
    "        <div ng-if=\"invalidUrl === true\">\n" +
    "          <p>The provided URL is invalid, please contact your practice to request another one.</p>\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "<div class=\"popover-jumper-container\" >\n" +
    "  <button \n" +
    "    uib-popover-template=\"'jumper-menu-profile.html'\"\n" +
    "    popover-placement=\"left\"\n" +
    "  >\n" +
    "    <i class=\"icomoon icon-icomoon-move-down\"></i>\n" +
    "  </button>\n" +
    "</div>\n" +
    "<script type=\"text/ng-template\" id=\"checkEligibilityInTreatments.html\">\n" +
    "    <div class=\"modal-header\">\n" +
    "	    <h3 class=\"modal-title\">\n" +
    "            <span ng-if=\"!working\">\n" +
    "            <span ng-if=\"!isForOtherCoverage\">Insurance </span><span ng-if=\"isForOtherCoverage\">Other Coverage </span><span> Eligibility for </span><label>{{patient.first_name}} {{patient.last_name}}</label><span> -- </span><label ng-if=\"!isForOtherCoverage\">{{patient.insurance_company_name}}</label><label ng-if=\"isForOtherCoverage\">{{patient.other_coverage_company_name}}</label>\n" +
    "            </span>\n" +
    "            <label ng-show=\"working\">Retrieving eligibility....</label>\n" +
    "        </h3>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body\">\n" +
    "      <uib-tabset>\n" +
    "        <uib-tab index=\"0\" heading=\"Report\" ng-show=\"!error\">\n" +
    "          <div class=\"\" ng-bind-html=\"html_div\">\n" +
    "          </div>\n" +
    "        </uib-tab>\n" +
    "        <uib-tab index=\"1\" heading=\"Full Data From Payer\" ng-show=\"!error\">\n" +
    "          <div class=\"\" angular-treeviewer full-tree='eligibilityData' open-icon-class=\"'icomoon icon-icomoon-minus2'\" close-icon-class=\"'icomoon icon-icomoon-plus2'\" group-icon-class=\"'icomoon icon-icomoon-file-empty2'\" search-icon-class=\"'\n" +
    "  icomoon icon-icomoon-search2'\" remove-empty-values=\"true\" normalize-words=\"true\">\n" +
    "          </div>\n" +
    "        </uib-tab>\n" +
    "      </uib-tabset>\n" +
    "      <label ng-show=\"error\">{{error}}</label>\n" +
    "    </div>\n" +
    "    <div class=\"modal-footer\">\n" +
    "	    <!--<button ng-disabled=\"myForm.$invalid\"  class=\"btn p-x btn-primary\" ng-click=\"ok()\">OK</button>-->\n" +
    "	    <button class=\"btn p-x btn-default\" ng-click=\"cancel()\">Done</button>\n" +
    "    </div>\n" +
    "</script>\n" +
    "\n" +
    "<script ng-if=\"!isAnonymous()\" type=\"text/ng-template\" id=\"#send-form-to-patient-modal\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Patient's Profile Via Email</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <div class=\"alert alert-danger m-b-0\">\n" +
    "      For security reasons, do not send without <b>PIN</b> if the patient's profile already contains PHI and make sure \n" +
    "      the email address is correct. Set or change <b>email</b> or <b>mobile phone</b> by changing below then \n" +
    "      <b>update</b> before sending.\n" +
    "    </div>\n" +
    "    <form name=\"f\" novalidate>\n" +
    "      <fieldset>\n" +
    "        <div class=\"form-group\">\n" +
    "          <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "            <label>\n" +
    "              <input type=\"checkbox\" name=\"use_pin\" ng-model=\"$ctrl.data.use_pin\">\n" +
    "              <span class=\"custom-control-indicator\"></span>\n" +
    "              Use a PIN for extra protection\n" +
    "            </label>\n" +
    "          </div>\n" +
    "          <span class=\"help-block\">The patient will require to enter this PIN to access the profile</span>\n" +
    "        </div>\n" +
    "      </fieldset>\n" +
    "    </form>\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label\">Email: <b>{{ $ctrl.patient.email }}</b></label>\n" +
    "    </div>\n" +
    "    <div class=\"form-group m-b-0\" ng-if=\"$ctrl.patient.mobilePhone\">\n" +
    "      <label class=\"control-label\">Mobile Number: <b>{{ $ctrl.patient.mobilePhone }}</b></label>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn p-x-md btn-default\" ng-click=\"$dismiss()\">Close</button>\n" +
    "    <button class=\"btn p-x-md btn-primary\" ng-click=\"$ctrl.send()\" ng-disabled=\"f.$invalid\">Send</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#send-form-pin-modal\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">PIN required</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <form name=\"f\" novalidate>\n" +
    "      <div class=\"form-group\" ng-class=\"{'has-error': f.pin.$invalid}\">\n" +
    "        <input class=\"form-control\" name=\"pin\" ng-model=\"$ctrl.pin\" required>\n" +
    "        <span class=\"help-block\" ng-show=\"f.pin.$invalid && f.pin.$dirty\">\n" +
    "          <span ng-if=\"f.pin.$error.required\">This field may not be blank</span>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "      <div class=\"form-group m-b-0\">\n" +
    "        <span class=\"help-block\">\n" +
    "          You should have received the PIN by a phone call or by SMS\n" +
    "        </span>\n" +
    "      </div>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn p-x-md btn-primary\" ng-click=\"$close($ctrl.pin)\" ng-disabled=\"f.$invalid\">Send</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "<script type=\"text/ng-template\" id=\"jumper-menu-profile.html\">\n" +
    "  <span>\n" +
    "    <ul>\n" +
    "      <li ng-if=\"!isAnonymous()\"><a href=\"#top\">Top</a></li>\n" +
    "      <li ng-if=\"!isAnonymous() && patient.hasHealthInsurance\"><a href=\"#patient-form-insurance-primary\">Insurance Info</a></li>\n" +
    "      <li><a href=\"#patient-form-dental-information\">Dental Info</a></li>\n" +
    "      <li><a href=\"#patient-form-medical-history\">Medical History</a></li>\n" +
    "      <li><a href=\"#patient-form-medications-allergies\">Medications</a></li>\n" +
    "      <li><a href=\"#patient-form-conclusion-emergency\">Emergency Contact</a></li>\n" +
    "      <li><a href=\"#patient-form-conclusion-verification\">Verification</a></li>\n" +
    "    </ul>\n" +
    "    <button type=\"button\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\" \n" +
    "      ng-click=\"triggerSubmit()\"\n" +
    "      ng-disabled=\"!canEditPatientInfo() || loadingCount\">\n" +
    "      <i class=\"icomoon icon-icomoon-circle-up6\"></i>  Update\n" +
    "    </button>\n" +
    "  </span>\n" +
    "  </script>\n" +
    "")

$templateCache.put("src/common/patient-detail-reports.tpl.html","")

$templateCache.put("src/common/patient-detail-treatmentplans.tpl.html","<!-- TREATMENT ADD -->\n" +
    "<style>\n" +
    ".fixed-last {\n" +
    "  margin-right:0px;\n" +
    "  width: 90px;\n" +
    "\n" +
    "}\n" +
    "\n" +
    ".fixed-last-add {\n" +
    "  margin-right:0px;\n" +
    "  width: 60px;\n" +
    "\n" +
    "}\n" +
    "\n" +
    ".fixed-beforelast {\n" +
    "  margin-right:90px;\n" +
    "}\n" +
    "\n" +
    ".fixed-treat {\n" +
    "  right: 18px;\n" +
    "\n" +
    "}\n" +
    ".fixed-enc {\n" +
    "  right: 35px;\n" +
    "  \n" +
    "}\n" +
    "\n" +
    ".fixed-bill {\n" +
    "  right: 20px;\n" +
    "  \n" +
    "}\n" +
    "\n" +
    ".fixed-beforelast-width {\n" +
    "  width:80px;\n" +
    "}\n" +
    "\n" +
    ".fixed {\n" +
    "	position: absolute;\n" +
    "\n" +
    "  height: 73px;\n" +
    "  border-width: 1px 0px 0px 1px !important;\n" +
    "  border-left-color: white !important;\n" +
    "  border-right-color: white !important;\n" +
    "}\n" +
    ".fixed-scroll {\n" +
    "  margin-right:170px;\n" +
    "}\n" +
    ".fixed-scroll-add {\n" +
    "  margin-right:60px;\n" +
    "}\n" +
    "\n" +
    "</style>\n" +
    "<div uib-collapse=\"!isTreatmentsAddNotCollapsed\">\n" +
    "  <form name=\"addTreatmentForm\" novalidate>\n" +
    "  <div class=\"form-group\" ng-attr-title=\"{{getDisplayForInsurancePlanTooltip(patient.insurance_plan_info, patient.other_coverage_plan_info)}}\">\n" +
    "    <div class=\"alert alert-danger\"\n" +
    "      ng-show=\"practice.ins_eligibility_provider_id.trim().length > 0 && patient.insurance_plan_info && !patient.insurance_plan_info.payer_does_eligibility\">\n" +
    "      Primary insurance plan payer doesn't support electronic eligibility\n" +
    "    </div>\n" +
    "    <div class=\"alert alert-danger\"\n" +
    "      ng-show=\"practice.ins_eligibility_provider_id.trim().length > 0 && patient.other_coverage_plan_info && !patient.other_coverage_plan_info.payer_does_eligibility\">\n" +
    "      Secondary insurance plan payer doesn't support electronic eligibility\n" +
    "    </div>\n" +
    "    <div class=\"alert alert-danger\"\n" +
    "      ng-show=\"practice.ins_eligibility_provider_id.trim().length == 0\">\n" +
    "      Not signed up for insurance eligibility checking\n" +
    "    </div>\n" +
    "    <button type=\"button\" class=\"btn btn-info\"\n" +
    "      ng-hide=\"practice.ins_eligibility_provider_id.trim().length == 0 || !patient.insurance_plan_info || !patient.insurance_plan_info.payer_does_eligibility || !encounter\"\n" +
    "      ng-click=\"checkEligibility(patientId, false);\">\n" +
    "      Get eligibility report from primary insurance plan payer\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-info\"\n" +
    "      ng-hide=\"practice.ins_eligibility_provider_id.trim().length == 0 || !patient.other_coverage_plan_info || !patient.other_coverage_plan_info.payer_does_eligibility || !encounter\"\n" +
    "      ng-click=\"checkEligibility(patientId, true);\">\n" +
    "      Get eligibility report from secondary insurance plan payer\n" +
    "    </button>\n" +
    "  </div>\n" +
    "  <div class=\"form-group row\" >\n" +
    "    <div style=\"overflow-x: auto;\" class=\"fixed-scroll-add\">\n" +
    "      <table class=\"table table-striped table-bordered table-advance\">\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "              <th class=\"col-md-1\">Date</th>\n" +
    "              <th class=\"col-md-1\">Visit</th>\n" +
    "              <th class=\"col-md-1\">Tooth</th>\n" +
    "              <th class=\"col-md-2\" style=\"min-width:200px;\">Procedure</th>\n" +
    "              <th class=\"col-md-2\">Provider</th>\n" +
    "              <th class=\"col-md-3\">Location</th>\n" +
    "              <th class=\"col-md-1\">\n" +
    "                <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Does this procedure automatically calculate a treatment estimate for you? If unchecked, will allow user to make manual changes to the calculation.\">\n" +
    "                  Calculate?\n" +
    "                </abbr>\n" +
    "              </th>\n" +
    "              <th class=\"col-md-1\" ng-if=\"!practice.no_insurance\">\n" +
    "                <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Does the Primary Insurance currently apply to this procedure estimate?\">\n" +
    "                  Apply Pri Ins?\n" +
    "                </abbr>\n" +
    "              </th>\n" +
    "              <th class=\"col-md-1\" ng-if=\"!practice.no_insurance\">\n" +
    "                <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Does the Primary Insurance currently apply to this procedure estimate?\">\n" +
    "                  Apply Sec Ins?\n" +
    "                </abbr>\n" +
    "              </th>\n" +
    "              <th class=\"col-md-2\" style=\"background-color:#d3ebed;\">\n" +
    "                <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"The amount the patient is currently estimated to pay for the procedure.\">\n" +
    "                  Patient Amount\n" +
    "                </abbr>\n" +
    "              </th>\n" +
    "              <th class=\"col-md-2\" style=\"background-color:#d3ebed;\">\n" +
    "                <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"The Net Revenue amount. This fee could differ from the Revenue Amount if a lesser insurance fee is being considered in the estimation.\">\n" +
    "                  Fee Amount\n" +
    "                </abbr>\n" +
    "              </th>\n" +
    "              <th class=\"col-md-2\" style=\"background-color:#d3ebed;\">\n" +
    "                <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"The Gross Revenue amount determined by the Fee for Service fees assigned to the patient’s profile.\">\n" +
    "                  Revenue Amount\n" +
    "                </abbr>\n" +
    "              </th>\n" +
    "              <th class=\"col-md-1\" ng-if=\"!practice.no_insurance\">\n" +
    "                <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Is the estimate for the procedure currently calculating with primary Assignment of Benefits assigned to the practice? True, means that the primary insurance company will pay the practice on behalf of the patient.\">\n" +
    "                  Assign Pri to Practice?\n" +
    "                </abbr>\n" +
    "              </th>\n" +
    "              <th class=\"col-md-1\" ng-if=\"!practice.no_insurance\">\n" +
    "                <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Is the estimate for the procedure currently calculating with the provider as in-network with the patient’s primary insurance payer? This status will determine the Pri Fee and Pri Amount column values.\">\n" +
    "                  Pri In-network?\n" +
    "                </abbr>\n" +
    "              </th>\n" +
    "              <th class=\"col-md-2\" ng-if=\"!practice.no_insurance\">\n" +
    "                <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"The primary insurance fee considered based on the in or out of network fee schedule.\">\n" +
    "                  Pri Fee\n" +
    "                </abbr>\n" +
    "              </th>\n" +
    "              <th class=\"col-md-2\" ng-if=\"!practice.no_insurance\">\n" +
    "                <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"The amount the primary insurance is currently estimated to pay for the procedure.\">\n" +
    "                  Pri Amount\n" +
    "                </abbr>\n" +
    "              </th>\n" +
    "              <th class=\"col-md-1\" ng-if=\"!practice.no_insurance\" style=\"background-color:#d3ebed;\">\n" +
    "                <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Is the estimate for the procedure currently calculating with secondary Assignment of Benefits assigned to the practice? True, means that the secondary insurance company will pay the practice on behalf of the patient.\">\n" +
    "                  Assign Sec to Practice?\n" +
    "                </abbr>\n" +
    "              </th>\n" +
    "              <th class=\"col-md-1\" ng-if=\"!practice.no_insurance\" style=\"background-color:#d3ebed;\">\n" +
    "                <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Is the estimate for the procedure currently calculating with the provider as in-network with the patient’s secondary insurance payer? This status will determine the Sec Fee and Sec Amount column values.\">\n" +
    "                  Sec In-network?\n" +
    "                </abbr>\n" +
    "              </th>\n" +
    "              <th class=\"col-md-2\" ng-if=\"!practice.no_insurance\" style=\"background-color:#d3ebed;\">\n" +
    "                <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"The secondary insurance fee considered based on the in or out of network fee schedule.\">\n" +
    "                  Sec Fee\n" +
    "                </abbr>\n" +
    "              </th>\n" +
    "              <th class=\"col-md-2\" ng-if=\"!practice.no_insurance\" style=\"background-color:#d3ebed;\">\n" +
    "                <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"The amount the secondary insurance is currently estimated to pay for the procedure.\">\n" +
    "                  Sec Amount\n" +
    "                </abbr>\n" +
    "              </th>\n" +
    "              <th>Status</th>\n" +
    "              <th class=\"fixed fixed-last-add fixed-enc\"\n" +
    "              ></th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "        <tr>\n" +
    "              <td >\n" +
    "                <custom-datepicker-popup style=\"min-width: 145px;\"  show-placeholder=\"false\"\n" +
    "                                         ng-model=\"treatment.treatment_date\" input-name=\"'treatmentDateInput'\"\n" +
    "                                         form-name=\"'addTreatmentForm'\" format=\"'MM/dd/yyyy'\" ng-required=\"true\"/>\n" +
    "              </td>\n" +
    "              <td >\n" +
    "                  <select style=\"min-width: 95px\" class=\"form-control\" ng-model=\"treatment.recommendation_level\">\n" +
    "                    <option>None</option>\n" +
    "                    <option>Primary</option>\n" +
    "                    <option>Secondary</option>\n" +
    "                    <option>Tertiary</option>\n" +
    "                  </select>\n" +
    "              </td>\n" +
    "              <td style=\"min-width: 80px\">\n" +
    "                  <input \n" +
    "                  type=\"text\" \n" +
    "                  class=\"form-control\" \n" +
    "                  name=\"toothNumber\" \n" +
    "                  ng-model=\"treatment.tooth_numbers\" \n" +
    "                  placeholder=\"Tooth number(s)...\" \n" +
    "                  ng-class=\"{'input-error': addTreatmentForm.toothNumber.$invalid}\"\n" +
    "                  rang-max=\"32\" number-range-limit></input>\n" +
    "              </td>\n" +
    "              <td>\n" +
    "\n" +
    "                <ui-select style=\"min-width: 92px; max-width:200px\" on-select=\"onCodeChange($item)\" ng-model=\"treatment.procedure_code\" append-to-body=\"true\" class=\"ui-select-treatments\">\n" +
    "                  <ui-select-match placeholder=\"Select a Procedure\">{{$select.selected.code + '-' + $select.selected.name}}</ui-select-match>\n" +
    "                  <ui-select-choices repeat=\"item.code as item in procedures | filter: $select.search | orderBy:'code'\">\n" +
    "                    {{item.code + '-' + item.name}}\n" +
    "                  </ui-select-choices>\n" +
    "                </ui-select>\n" +
    "\n" +
    "              </td>\n" +
    "              <td>\n" +
    "                  <select style=\"min-width: 92px; max-width:200px\" class=\"form-control\" ng-model=\"treatment.provider\"\n" +
    "                          ng-options= \"p.id as p.first_name + ' ' + p.last_name for p in providers\"\n" +
    "                          ng-change=\"onProviderChange(treatment.provider)\">\n" +
    "                  </select>\n" +
    "              </td>\n" +
    "              <td>\n" +
    "                  <ui-select style=\"min-width: 92px; max-width:200px\" ng-model=\"treatment.location\" ng-required=\"false\" theme=\"selectize\" append-to-body=\"true\" class=\"ui-select-treatments\">\n" +
    "                    <ui-select-match placeholder=\"Location\">\n" +
    "                      {{ $select.selected.name }}\n" +
    "                    </ui-select-match>\n" +
    "                    <ui-select-choices repeat=\"p.id as p in locations | filter:$select.search\">\n" +
    "                      <span ng-bind-html=\"p.name | highlight: $select.search\"></span>\n" +
    "                    </ui-select-choices>\n" +
    "                  </ui-select>\n" +
    "              </td>\n" +
    "              <td >\n" +
    "                  <input\n" +
    "                    type=\"checkbox\"\n" +
    "                    class=\"form-control\"\n" +
    "                    ng-model=\"treatment.calc_amounts\"\n" +
    "                  ></input>\n" +
    "              </td>\n" +
    "              <td ng-if=\"!practice.no_insurance\" >\n" +
    "                  <input\n" +
    "                    type=\"checkbox\"\n" +
    "                    class=\"form-control\"\n" +
    "                    ng-model=\"treatment.insurance_applied\"\n" +
    "                    ng-change=\"!treatment.insurance_applied ? treatment.other_applied = false : null\"\n" +
    "                    ng-disabled=\"!patient.insurance_plan\"\n" +
    "                  ></input>\n" +
    "              </td>\n" +
    "              <td ng-if=\"!practice.no_insurance\" >\n" +
    "                  <input\n" +
    "                    type=\"checkbox\"\n" +
    "                    class=\"form-control\"\n" +
    "                    ng-model=\"treatment.other_applied\"\n" +
    "                    ng-disabled=\"!patient.insurance_plan || !patient.other_coverage_plan || !treatment.insurance_applied\"\n" +
    "                  ></input>\n" +
    "              </td>\n" +
    "              <td style=\"background-color:#d3ebed; min-width: 98px;\">\n" +
    "                  <input\n" +
    "                    type=\"number\"\n" +
    "                    class=\"form-control\"\n" +
    "                    ng-model=\"treatment.amount_patient\"\n" +
    "                    ng-disabled=\"true\"\n" +
    "                    string-to-number\n" +
    "                  ></input>\n" +
    "              </td>\n" +
    "              <td style=\"background-color:#d3ebed; min-width: 98px;\">\n" +
    "                  <input\n" +
    "                    type=\"number\"\n" +
    "                    class=\"form-control\"\n" +
    "                    ng-model=\"treatment.amount_fee\"\n" +
    "                    ng-disabled=\"treatment.calc_amounts\"\n" +
    "                    string-to-number\n" +
    "                  ></input>\n" +
    "              </td>\n" +
    "              <td style=\"background-color:#d3ebed; min-width: 98px;\">\n" +
    "                  <input\n" +
    "                    type=\"number\"\n" +
    "                    class=\"form-control\"\n" +
    "                    ng-model=\"treatment.amount_revenue_to_post\"\n" +
    "                    ng-disabled=\"treatment.calc_amounts || !treatment.insurance_applied\"\n" +
    "                    string-to-number\n" +
    "                  ></input>\n" +
    "              </td>\n" +
    "              <td ng-if=\"!practice.no_insurance\"  >\n" +
    "                  <input\n" +
    "                    type=\"checkbox\"\n" +
    "                    class=\"form-control\"\n" +
    "                    ng-model=\"treatment.insurance_billing_provider_assigned_benefits\"\n" +
    "                    ng-init=\"(treatment.insurance_applied && !treatment.insurance_billing_provider_assigned_benefits) ? treatment.insurance_billing_provider_assigned_benefits = false : null\"\n" +
    "                    ng-disabled=\"treatment.calc_amounts || !patient.insurance_plan || !treatment.insurance_applied\"\n" +
    "                  ></input>\n" +
    "              </td>\n" +
    "              <td ng-if=\"!practice.no_insurance\"  >\n" +
    "                  <input\n" +
    "                    type=\"checkbox\"\n" +
    "                    class=\"form-control\"\n" +
    "                    ng-model=\"treatment.is_insurance_innetwork\"\n" +
    "                    ng-disabled=\"treatment.calc_amounts || !patient.insurance_plan || !treatment.insurance_applied\"\n" +
    "                  ></input>\n" +
    "              </td>\n" +
    "              <td style=\"min-width: 98px;\" ng-if=\"!practice.no_insurance\"  >\n" +
    "                  <input\n" +
    "                    type=\"number\"\n" +
    "                    class=\"form-control\"\n" +
    "                    ng-model=\"treatment.fee_ins\"\n" +
    "                    ng-disabled=\"treatment.calc_amounts || !patient.insurance_plan || !treatment.insurance_applied\"\n" +
    "                    string-to-number\n" +
    "                  ></input>\n" +
    "              </td>\n" +
    "              <td style=\"min-width: 98px;\" ng-if=\"!practice.no_insurance\"  >\n" +
    "                  <input\n" +
    "                    type=\"number\"\n" +
    "                    class=\"form-control\"\n" +
    "                    ng-model=\"treatment.amount_ins\"\n" +
    "                    ng-disabled=\"treatment.calc_amounts || !patient.insurance_plan || !treatment.insurance_applied\"\n" +
    "                    string-to-number\n" +
    "                  ></input>\n" +
    "              </td>\n" +
    "              <td ng-if=\"!practice.no_insurance\" style=\"background-color:#d3ebed;\">\n" +
    "                  <input\n" +
    "                    type=\"checkbox\"\n" +
    "                    class=\"form-control\"\n" +
    "                    ng-model=\"treatment.other_coverage_billing_provider_assigned_benefits\"\n" +
    "                    ng-init=\"(treatment.other_applied && !treatment.other_coverage_billing_provider_assigned_benefits) ? treatment.other_coverage_billing_provider_assigned_benefits = false : null\"\n" +
    "                    ng-disabled=\"treatment.calc_amounts || !treatment.other_applied\"\n" +
    "                  ></input>\n" +
    "              </td>\n" +
    "              <td ng-if=\"!practice.no_insurance\" style=\"background-color:#d3ebed;\">\n" +
    "                  <input\n" +
    "                    type=\"checkbox\"\n" +
    "                    class=\"form-control\"\n" +
    "                    ng-model=\"treatment.is_other_innetwork\"\n" +
    "                    ng-disabled=\"treatment.calc_amounts || !treatment.other_applied\"\n" +
    "                  ></input>\n" +
    "              </td>\n" +
    "              <td style=\"background-color:#d3ebed; min-width: 98px;\" ng-if=\"!practice.no_insurance\"  >\n" +
    "                  <input\n" +
    "                    type=\"number\"\n" +
    "                    class=\"form-control\"\n" +
    "                    ng-model=\"treatment.fee_other\"\n" +
    "                    ng-disabled=\"treatment.calc_amounts || !treatment.other_applied\"\n" +
    "                    string-to-number\n" +
    "                  ></input>\n" +
    "              </td>\n" +
    "              <td style=\"background-color:#d3ebed; min-width: 98px;\" ng-if=\"!practice.no_insurance\"  >\n" +
    "                  <input\n" +
    "                    type=\"number\"\n" +
    "                    class=\"form-control\"\n" +
    "                    ng-model=\"treatment.amount_other\"\n" +
    "                    ng-disabled=\"treatment.calc_amounts || !treatment.other_applied\"\n" +
    "                    string-to-number\n" +
    "                  ></input>\n" +
    "              </td>\n" +
    "              <td>\n" +
    "                  <select style=\"min-width:100px;\" class=\"form-control\" ng-model=\"treatment.status\">\n" +
    "                    <option value=\"notstarted\">Not started</option>\n" +
    "                    <option value=\"doing\">Doing</option>\n" +
    "                    <option value=\"done\">Done</option>\n" +
    "                    <option value=\"wontdo\">Won't do</option>\n" +
    "                  </select>\n" +
    "              </td>\n" +
    "              <td class=\"fixed fixed-last-add fixed-enc\" >\n" +
    "                  <button type=\"button\" class=\"btn btn-primary btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"create();\" ng-show=\"treatment.insurance_applied\">\n" +
    "                    <i class=\"icomoon icon-icomoon-plus2\" aria-hidden=\"true\"></i>\n" +
    "                  </button>\n" +
    "                  <button type=\"button\" class=\"btn btn-primary btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"create();\" ng-hide=\"treatment.insurance_applied\">\n" +
    "                    <i class=\"icomoon icon-icomoon-plus2\" aria-hidden=\"true\"></i>\n" +
    "                  </button>\n" +
    "              </td>\n" +
    "          </tr>\n" +
    "      </table>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  </form>\n" +
    "  <br/>\n" +
    "  <br/>\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "<!-- TREATMENT EDIT AND DELETE -->\n" +
    "\n" +
    "<form novalidate name=\"editTreatmentsForm\">\n" +
    "<div class=\"form-group row\">\n" +
    "  <wave-spinner class=\"global-loader\" ng-if=\"loading\"></wave-spinner>\n" +
    "      <div style=\"overflow-x: auto;\" class=\"fixed-scroll\">\n" +
    "        <table class=\"table table-striped table-bordered table-advance\">\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <th class=\"col-md-1\" ng-if=\"mode === 'BILLING'\">Include in Invoice</th>\n" +
    "            <th class=\"col-md-1\">Date</th>\n" +
    "            <th class=\"col-md-1\">Visit</th>\n" +
    "            <th class=\"col-md-1\">Tooth</th>\n" +
    "            <th class=\"col-md-2\" style=\"min-width:200px;\">Procedure</th>\n" +
    "            <th class=\"col-md-2\">Provider</th>\n" +
    "            <th class=\"col-md-2\">Location</th>\n" +
    "            <th class=\"col-md-1\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Does this procedure automatically calculate a treatment estimate for you? If unchecked, will allow user to make manual changes to the calculation.\">\n" +
    "                Calculate?\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"col-md-1\" ng-if=\"!practice.no_insurance\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Does the Primary Insurance currently apply to this procedure estimate?\">\n" +
    "                Apply Pri Ins?\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"col-md-1\" ng-if=\"!practice.no_insurance\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Does the Secondary Insurance currently apply to this procedure estimate?\">\n" +
    "                Apply Sec Ins?\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"col-md-2\" style=\"background-color:#d3ebed;\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"The amount the patient is currently estimated to pay for the procedure.\">\n" +
    "                Patient Amount\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"col-md-2\" style=\"background-color:#d3ebed;\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"The Net Fee amount. This fee could differ from the Revenue Amount if a lesser insurance fee is being considered in the estimation.\">\n" +
    "                Fee Amount\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"col-md-2\" style=\"background-color:#d3ebed;\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"The Gross Revenue amount determined by the Fee for Service fees assigned to the patient’s profile.\">\n" +
    "                Revenue Amount\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"col-md-1\" ng-if=\"!practice.no_insurance\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Is the estimate for the procedure currently calculating with primary Assignment of Benefits assigned to the practice? True, means that the primary insurance company will pay the practice on behalf of the patient.\">\n" +
    "                Assign Pri to Practice?\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"col-md-1\" ng-if=\"!practice.no_insurance\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Is the estimate for the procedure currently calculating with the provider as in-network with the patient’s primary insurance payer? This status will determine the Pri Fee and Pri Amount column values.\">\n" +
    "                Pri In-network?\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"col-md-2\" ng-if=\"!practice.no_insurance\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"The primary insurance fee considered based on the in or out of network fee schedule.\">\n" +
    "                Pri Fee\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"col-md-2\" ng-if=\"!practice.no_insurance\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"The amount the primary insurance is currently estimated to pay for the procedure.\">\n" +
    "                Pri Amount\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"col-md-1\" ng-if=\"!practice.no_insurance\" style=\"background-color:#d3ebed;\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Is the estimate for the procedure currently calculating with secondary Assignment of Benefits assigned to the practice? True, means that the secondary insurance company will pay the practice on behalf of the patient.\">\n" +
    "                Assign Sec to Practice?\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"col-md-1\" ng-if=\"!practice.no_insurance\" style=\"background-color:#d3ebed;\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Is the estimate for the procedure currently calculating with the provider as in-network with the patient’s secondary insurance payer? This status will determine the Sec Fee and Sec Amount column values.\">\n" +
    "                Sec In-network?\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"col-md-2\" ng-if=\"!practice.no_insurance\" style=\"background-color:#d3ebed;\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"The secondary insurance fee considered based on the in or out of network fee schedule.\">\n" +
    "                Sec Fee\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"col-md-2\" ng-if=\"!practice.no_insurance\" style=\"background-color:#d3ebed;\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"The amount the secondary insurance is currently estimated to pay for the procedure.\">\n" +
    "                Sec Amount\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"col-md-1\">\n" +
    "              <!-- title=\"To show as abandoned 'true' in productivity treatments completed analytics, check invoiced and done treatments as abandoned. While billing and insurance entries associated with this treatment should sum to zero, the system cannot automatically add reversing entries to accomplish this. Please be sure such entries have been manually added and all entries associated with this treatment in billing and insurance each sum to zero.\"> -->\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Does the procedure need to be VOIDED after it has been invoiced to the patient?\">\n" +
    "                Abandoned?\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"fixed fixed-beforelast fixed-beforelast-width\" \n" +
    "              ng-class=\"{'fixed-enc':mode === 'ENCOUNTER', 'fixed-bill':mode ==='BILLING', 'fixed-treat': mode !== 'ENCOUNTER' && mode !== 'BILLING'}\" \n" +
    "            >Status</th>\n" +
    "            <th class=\"fixed fixed-last\"\n" +
    "              ng-class=\"{'fixed-enc':mode === 'ENCOUNTER', 'fixed-bill':mode ==='BILLING', 'fixed-treat': mode !== 'ENCOUNTER' && mode !== 'BILLING'}\" \n" +
    "            >\n" +
    "              <div class=\"btn-group\">\n" +
    "                <button ng-if=\"treatmentsEditingDictCount()>0\" title=\"Save all edited treatments\" type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"updateTreatmentsEditing();\" >\n" +
    "                    <span class=\"icomoon icon-icomoon-download8\" aria-hidden=\"true\"></span>\n" +
    "                </button>\n" +
    "              </div>  \n" +
    "            </th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "\n" +
    "        <tr ng-repeat=\"t in treatments | orderBy: sortParam\" ng-init=\"consented_status = treatmentConsentedStatus(t.id)\">\n" +
    "            <td ng-if=\"mode === 'BILLING'\">\n" +
    "              <div class=\"checkbox-inline custom-control custom-checkbox am-x-0\">\n" +
    "                <label>\n" +
    "                  <input type=\"checkbox\" ng-disabled=\"!t.updated_in_update_noninvoiced_treatments\" ng-model=\"t.includeInInvoice\">\n" +
    "                  <span class=\"custom-control-indicator\"></span>\n" +
    "                </label>\n" +
    "              </div>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\">{{t.treatment_date | date:\"MM/dd/yyyy\"}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\">{{t.recommendation_level}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\">{{t.tooth_numbers}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\">\n" +
    "              <span ng-if=\"t.is_abandoned\"\n" +
    "              class=\"icomoon icon-icomoon-warning\"\n" +
    "              style=\"color:red;font-weight:bold;font-size:large;\"\n" +
    "              uib-popover-template=\"'abandoned-alert.html'\"\n" +
    "              popover-placement=\"left\"\n" +
    "              popover-append-to-body=\"true\"\n" +
    "            ></span> \n" +
    "              {{t.procedure_code}}-{{t.procedure_name}}\n" +
    "              <i class=\"icomoon\"\n" +
    "                ng-class=\"consented_status.class\"\n" +
    "                uib-tooltip=\"{{ consented_status.text }}\">\n" +
    "              </i>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\">{{getProviderDisplay(t.provider)}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\">{{getLocationDisplay(t.location)}}</td>\n" +
    "\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\">{{t.calc_amounts}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" >{{t.insurance_applied}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" >{{t.other_applied}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\" style=\"background-color:#d3ebed; font-weight:bold; text-align:right;\">{{t.amount_patient}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\" style=\"background-color:#d3ebed; text-align:right;\">{{t.amount_fee}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\" style=\"background-color:#d3ebed; text-align:right;\">{{t.amount_revenue_to_post}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" >{{t.insurance_billing_provider_assigned_benefits}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" >{{t.is_insurance_innetwork}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" >{{t.fee_ins}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" style=\"text-align:right;\" >{{t.amount_ins}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" style=\"background-color:#d3ebed; text-align:right;\" >{{t.other_coverage_billing_provider_assigned_benefits}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" style=\"background-color:#d3ebed; text-align:right;\" >{{t.is_other_innetwork}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" style=\"background-color:#d3ebed; text-align:right;\">{{t.fee_other}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" style=\"background-color:#d3ebed; text-align:right;\" >{{t.amount_other}}</td>\n" +
    "            <td ng-hide=\"isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" style=\"text-align:right;\" >{{t.is_abandoned}}</td>\n" +
    "            <td class=\"fixed fixed-beforelast fixed-beforelast-width\" \n" +
    "              ng-hide=\"isInTreatmentsEditingDict(t.id)\"\n" +
    "              ng-class=\"{'fixed-enc':mode === 'ENCOUNTER', 'fixed-bill':mode ==='BILLING', 'fixed-treat': mode !== 'ENCOUNTER' && mode !== 'BILLING'}\" \n" +
    "            >{{getDisplayForStatus(t.status)}}\n" +
    "              <span ng-if=\"t.invoiced\"\n" +
    "                class=\"icomoon icon-icomoon-lock2\"\n" +
    "                uib-popover-template=\"'treatment-info-invoiced.html'\"\n" +
    "                popover-placement=\"left\"\n" +
    "                popover-append-to-body=\"true\"\n" +
    "              ></span>\n" +
    "              <span ng-if=\"!t.invoiced && t.status !== 'wontdo' && t.updated_in_update_noninvoiced_treatments && t.info_from_update_noninvoiced_treatments.trim().length > 0\"\n" +
    "                class=\"icomoon icon-icomoon-info2\"\n" +
    "                uib-popover-template=\"'treatment-info.html'\"\n" +
    "                popover-placement=\"left\"\n" +
    "                popover-append-to-body=\"true\"\n" +
    "              ></span>\n" +
    "              <span ng-if=\"!t.invoiced && t.status !== 'wontdo' && !t.updated_in_update_noninvoiced_treatments && t.info_from_update_noninvoiced_treatments.trim().length > 0\"\n" +
    "                class=\"icomoon icon-icomoon-warning\"\n" +
    "                uib-popover-template=\"'treatment-info-alert.html'\"\n" +
    "                popover-placement=\"left\"\n" +
    "                popover-append-to-body=\"true\"\n" +
    "              ></span>\n" +
    "            </td>\n" +
    "            <td class=\"fixed fixed-last\" \n" +
    "              ng-hide=\"isInTreatmentsEditingDict(t.id)\"\n" +
    "              ng-class=\"{'fixed-enc':mode === 'ENCOUNTER', 'fixed-bill':mode ==='BILLING', 'fixed-treat': mode !== 'ENCOUNTER' && mode !== 'BILLING'}\" \n" +
    "            >\n" +
    "              <div class=\"btn-group\">\n" +
    "                <button\n" +
    "                  ng-disabled=\"!CanUpdateEncounter\"\n" +
    "                  ng-show=\"mode == 'ENCOUNTER'\"\n" +
    "                  type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\"\n" +
    "                  ng-click=\"addToTreatmentsEditing(t);\"\n" +
    "                >\n" +
    "                    <i class=\"icomoon icon-icomoon-pencil5\" aria-hidden=\"true\"></i>\n" +
    "\n" +
    "                </button>\n" +
    "                <button\n" +
    "                  ng-disabled=\"!CanUpdateEncounter ||  t.invoiced\"\n" +
    "                  ng-show=\"mode == 'ENCOUNTER'\"\n" +
    "                  type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\"\n" +
    "                  ng-click=\"openDelTreatmentModal(t);\"\n" +
    "                >\n" +
    "                    <i class=\"icomoon icon-icomoon-bin\" aria-hidden=\"true\"></i>\n" +
    "\n" +
    "                </button>\n" +
    "                <div class=\"btn-group\" uib-dropdown ng-show=\"mode !== 'ENCOUNTER'\">\n" +
    "                    <button ng-disabled=\"!CanRetrieveEncounter\" type=\"button\" class=\"btn btn-default  btn-sm dropdown-toggle\" uib-dropdown-toggle>\n" +
    "                        Enc <span class=\"caret\"></span>\n" +
    "                    </button>\n" +
    "                    <ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "                      <li ng-repeat=\"e in t.encounters | orderBy: 'encounter_date'\"><a  ui-sref=\"chart-detail({encounterid: e.id})\">{{(e.encounter_date | date:'MM-dd-yyyy') + \" - \" + e.title}}</a></li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </td>\n" +
    "\n" +
    "            <td\n" +
    "              style=\"min-width: 155px;\"\n" +
    "              ng-hide=\"!isInTreatmentsEditingDict(t.id)\"\n" +
    "            >\n" +
    "              <custom-datepicker-popup\n" +
    "                style=\"min-width: 145px;\"\n" +
    "                show-placeholder=\"false\"\n" +
    "                ng-model=\"t.treatment_date\"\n" +
    "                input-name=\"'edittreatmentDateInput'\"\n" +
    "                form-name=\"'editTreatmentsForm'\"\n" +
    "                format=\"'MM/dd/yyyy'\"\n" +
    "                ng-required=\"true\"\n" +
    "                ng-disabled=\"t.invoiced\"\n" +
    "              />\n" +
    "            </td>\n" +
    "            <td\n" +
    "              ng-hide=\"!isInTreatmentsEditingDict(t.id)\"\n" +
    "            >\n" +
    "              <select\n" +
    "                style=\"min-width: 95px;\"\n" +
    "                class=\"form-control\"\n" +
    "                ng-model=\"t.recommendation_level\"\n" +
    "                ng-disabled=\"t.invoiced\"\n" +
    "              >\n" +
    "                <option>None</option>\n" +
    "                <option>Primary</option>\n" +
    "                <option>Secondary</option>\n" +
    "                <option>Tertiary</option>\n" +
    "              </select>\n" +
    "            </td>\n" +
    "            <td\n" +
    "              style=\"min-width: 60px\"\n" +
    "              ng-hide=\"!isInTreatmentsEditingDict(t.id)\"\n" +
    "            >\n" +
    "              <input\n" +
    "                type=\"text\"\n" +
    "                class=\"form-control\"\n" +
    "                ng-model=\"t.tooth_numbers\"\n" +
    "                name=\"toothNumber\"\n" +
    "                placeholder=\"Tooth number(s)...\"\n" +
    "                ng-disabled=\"t.invoiced\"\n" +
    "              ></input>\n" +
    "            </td>\n" +
    "            <td\n" +
    "              ng-hide=\"!isInTreatmentsEditingDict(t.id)\"\n" +
    "            >\n" +
    "              <ui-select style=\"min-width: 92px; max-width:200px\" ng-model=\"t.procedure_code\" append-to-body=\"true\" class=\"ui-select-treatments\">\n" +
    "                <ui-select-match placeholder=\"Select a Procedure\">{{$select.selected.code + '-' + $select.selected.name}}</ui-select-match>\n" +
    "                <ui-select-choices repeat=\"item.code as item in procedures | filter: $select.search | orderBy:'code'\">\n" +
    "                  {{item.code + '-' + item.name}}\n" +
    "                </ui-select-choices>\n" +
    "              </ui-select>\n" +
    "\n" +
    "\n" +
    "            </td>\n" +
    "            <td\n" +
    "              ng-hide=\"!isInTreatmentsEditingDict(t.id)\"\n" +
    "            >\n" +
    "              <select\n" +
    "                style=\"min-width: 92px; max-width:200px\"\n" +
    "                class=\"form-control\"\n" +
    "                ng-model=\"t.provider\" ng-options=\"provider.id as provider.first_name +' '+ provider.last_name for provider in providers\"\n" +
    "                ng-disabled=\"t.invoiced\"\n" +
    "              >\n" +
    "              </select>\n" +
    "            </td>\n" +
    "            <td\n" +
    "              ng-hide=\"!isInTreatmentsEditingDict(t.id)\"\n" +
    "            >\n" +
    "              <ui-select style=\"min-width: 92px; max-width:200px\" ng-model=\"t.location\" ng-required=\"false\" theme=\"selectize\" append-to-body=\"true\" class=\"ui-select-treatments\">\n" +
    "                <ui-select-match placeholder=\"Location\">\n" +
    "                  {{ $select.selected.name }}\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices repeat=\"p.id as p in locations | filter:$select.search\">\n" +
    "                  <span ng-bind-html=\"p.name | highlight: $select.search\"></span>\n" +
    "                </ui-select-choices>\n" +
    "              </ui-select>\n" +
    "            </td>\n" +
    "\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" >\n" +
    "                <input\n" +
    "                  type=\"checkbox\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.calc_amounts\"\n" +
    "                  ng-disabled=\"t.invoiced\"\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" >\n" +
    "                <input\n" +
    "                  type=\"checkbox\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.insurance_applied\"\n" +
    "                  ng-change=\"!t.insurance_applied ? t.other_applied = false : null\"\n" +
    "                  ng-disabled=\"(!patient.insurance_plan && t.updated_in_update_noninvoiced_treatments)\n" +
    "                    || t.invoiced\"\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\"  >\n" +
    "                <input\n" +
    "                  type=\"checkbox\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.other_applied\"\n" +
    "                  ng-disabled=\"(!patient.insurance_plan && t.updated_in_update_noninvoiced_treatments)\n" +
    "                    || (!patient.other_coverage_plan && t.updated_in_update_noninvoiced_treatments)\n" +
    "                    || !t.insurance_applied\n" +
    "                    || t.invoiced\"\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" >\n" +
    "                <input\n" +
    "                  style=\"min-width: 98px;\"\n" +
    "                  type=\"number\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.amount_patient\"\n" +
    "                  ng-disabled=\"true\"\n" +
    "                  string-to-number\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" >\n" +
    "                <input\n" +
    "                  style=\"min-width: 98px;\"\n" +
    "                  type=\"number\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.amount_fee\"\n" +
    "                  ng-disabled=\"t.calc_amounts || t.invoiced\"\n" +
    "                  string-to-number\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" >\n" +
    "                <input\n" +
    "                  style=\"min-width: 98px;\"\n" +
    "                  type=\"number\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.amount_revenue_to_post\"\n" +
    "                  ng-disabled=\"t.calc_amounts || t.invoiced || !t.insurance_applied\"\n" +
    "                  string-to-number\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" >\n" +
    "                <input\n" +
    "                  type=\"checkbox\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.insurance_billing_provider_assigned_benefits\"\n" +
    "                  ng-init=\"(t.insurance_applied && !t.insurance_billing_provider_assigned_benefits) ? t.insurance_billing_provider_assigned_benefits = false : null\"\n" +
    "                  ng-disabled=\"t.calc_amounts || !patient.insurance_plan || !t.insurance_applied || t.invoiced\"\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\"  >\n" +
    "                <input\n" +
    "                  type=\"checkbox\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.is_insurance_innetwork\"\n" +
    "                  ng-init=\"(t.insurance_applied && !t.is_insurance_innetwork) ? t.is_insurance_innetwork=false : null\"\n" +
    "                  ng-disabled=\"t.calc_amounts || !patient.insurance_plan || !t.insurance_applied || t.invoiced\"\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" >\n" +
    "                <input\n" +
    "                  style=\"min-width: 98px;\"\n" +
    "                  type=\"number\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.fee_ins\"\n" +
    "                  ng-disabled=\"t.calc_amounts || !patient.insurance_plan || !t.insurance_applied || t.invoiced\"\n" +
    "                  string-to-number\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" >\n" +
    "                <input\n" +
    "                  style=\"min-width: 98px;\"\n" +
    "                  type=\"number\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.amount_ins\"\n" +
    "                  ng-disabled=\"t.calc_amounts || !patient.insurance_plan || !t.insurance_applied || t.invoiced\"\n" +
    "                  string-to-number\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" >\n" +
    "                <input\n" +
    "                  type=\"checkbox\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.other_coverage_billing_provider_assigned_benefits\"\n" +
    "                  ng-init=\"(t.other_applied && !t.other_coverage_billing_provider_assigned_benefits) ? t.other_coverage_billing_provider_assigned_benefits = false : null\"\n" +
    "                  ng-disabled=\"t.calc_amounts || !t.other_applied || t.invoiced\"\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\"  >\n" +
    "                <input\n" +
    "                  type=\"checkbox\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.is_other_innetwork\"\n" +
    "                  ng-disabled=\"t.calc_amounts || !t.other_applied || t.invoiced\"\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" >\n" +
    "                <input\n" +
    "                  style=\"min-width: 98px;\"\n" +
    "                  type=\"number\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.fee_other\"\n" +
    "                  ng-disabled=\"t.calc_amounts || !t.other_applied || t.invoiced\"\n" +
    "                  string-to-number\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" ng-if=\"!practice.no_insurance\" >\n" +
    "                <input\n" +
    "                  style=\"min-width: 98px;\"\n" +
    "                  type=\"number\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.amount_other\"\n" +
    "                  ng-disabled=\"t.calc_amounts || !t.other_applied || t.invoiced\"\n" +
    "                  string-to-number\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td ng-hide=\"!isInTreatmentsEditingDict(t.id)\" >\n" +
    "                <input\n" +
    "                  type=\"checkbox\"\n" +
    "                  class=\"form-control\"\n" +
    "                  ng-model=\"t.is_abandoned\"\n" +
    "                  ng-disabled=\"!t.invoiced || t.status !== 'done'\"\n" +
    "                ></input>\n" +
    "            </td>\n" +
    "            <td class=\"fixed fixed-beforelast fixed-beforelast-width\" \n" +
    "              ng-hide=\"!isInTreatmentsEditingDict(t.id)\"\n" +
    "              ng-class=\"{'fixed-enc':mode === 'ENCOUNTER', 'fixed-bill':mode ==='BILLING', 'fixed-treat': mode !== 'ENCOUNTER' && mode !== 'BILLING'}\" \n" +
    "              style=\"padding-left:0px;\"\n" +
    "            >\n" +
    "                <select class=\"form-control fixed-beforelast-width\" \n" +
    "                  ng-model=\"t.status\" \n" +
    "                  ng-change=\"statusSelectChanged(t)\"\n" +
    "                  ng-disabled=\"t.invoiced && t.status === 'done'\"\n" +
    "                >\n" +
    "                  <option value=\"notstarted\">Not started</option>\n" +
    "                  <option value=\"doing\">Doing</option>\n" +
    "                  <option value=\"done\">Done</option>\n" +
    "                  <option value=\"wontdo\">Won't do</option>\n" +
    "                </select>\n" +
    "            </td>\n" +
    "            <td class=\"fixed fixed-last\"\n" +
    "              ng-hide=\"!isInTreatmentsEditingDict(t.id)\" \n" +
    "              ng-class=\"{'fixed-enc':mode === 'ENCOUNTER', 'fixed-bill':mode ==='BILLING', 'fixed-treat': mode !== 'ENCOUNTER' && mode !== 'BILLING'}\" \n" +
    "            >\n" +
    "                  <div class=\"btn-group\">\n" +
    "                      <button type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"removeFromTreatmentsEditingReturnCopy(treatments, t); \" >\n" +
    "                             <span class=\"icomoon icon-icomoon-minus2\" aria-hidden=\"true\"></span>\n" +
    "                      </button>\n" +
    "                      <button title=\"save this treatment\" type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"update(t);  \" >\n" +
    "                           <span class=\"icomoon icon-icomoon-download8\" aria-hidden=\"true\"></span>\n" +
    "                      </button>\n" +
    "                  </div>\n" +
    "             </td>\n" +
    "          </tr>\n" +
    "      </table>\n" +
    "    </div>\n" +
    "</div>\n" +
    "</form>\n" +
    "<script type=\"text/ng-template\" id=\"delTreatment.html\">\n" +
    "  <div class=\"modal-header\">\n" +
    "	  <h3 class=\"modal-title\">Delete treatment?</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">Are you sure you want to delete treatment for procedure {{procedureCode}}? NOTE that deleting this treatment will delete it from all encounters it is associated with and will remove it from billing.</div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-wide btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "    <button class=\"btn btn-wide btn-primary\" ng-click=\"ok()\">OK</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"checkEligibilityInTreatments.html\">\n" +
    "    <div class=\"modal-header\">\n" +
    "	    <h3 class=\"modal-title\">\n" +
    "            <span ng-if=\"!working\">\n" +
    "            <span ng-if=\"!isForOtherCoverage\">Insurance </span><span ng-if=\"isForOtherCoverage\">Other Coverage </span><span> Eligibility for </span><label>{{patient.first_name}} {{patient.last_name}}</label><span> -- </span><label ng-if=\"!isForOtherCoverage\">{{patient.insurance_company_name}}</label><label ng-if=\"isForOtherCoverage\">{{patient.other_coverage_company_name}}</label>\n" +
    "            </span>\n" +
    "            <label ng-show=\"working\">Retrieving eligibility....</label>\n" +
    "        </h3>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body\">\n" +
    "      <uib-tabset>\n" +
    "        <uib-tab index=\"0\" heading=\"Report\" ng-show=\"!error\">\n" +
    "          <div class=\"\" ng-bind-html=\"html_div\">\n" +
    "          </div>\n" +
    "        </uib-tab>\n" +
    "        <uib-tab index=\"1\" heading=\"Full Data From Payer\" ng-show=\"!error\">\n" +
    "          <div class=\"\" angular-treeviewer full-tree='eligibilityData' open-icon-class=\"'icomoon icon-icomoon-minus2'\" close-icon-class=\"'icomoon icon-icomoon-plus2'\" group-icon-class=\"'icomoon icon-icomoon-file-empty2'\" search-icon-class=\"'\n" +
    "  icomoon icon-icomoon-search2'\" remove-empty-values=\"true\" normalize-words=\"true\">\n" +
    "          </div>\n" +
    "        </uib-tab>\n" +
    "      </uib-tabset>\n" +
    "      <label ng-show=\"error\">{{error}}</label>\n" +
    "    </div>\n" +
    "    <div class=\"modal-footer\">\n" +
    "	    <!--<button ng-disabled=\"myForm.$invalid\"  class=\"btn btn-primary\" ng-click=\"ok()\">OK</button>-->\n" +
    "	    <button class=\"btn btn-default\" ng-click=\"cancel()\">Done</button>\n" +
    "    </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"treatment-info.html\">\n" +
    "  <span>\n" +
    "    {{t.info_from_update_noninvoiced_treatments}}<br/>\n" +
    "  </span>\n" +
    "</script>\n" +
    "<script type=\"text/ng-template\" id=\"treatment-info-alert.html\">\n" +
    "  <span>\n" +
    "    Not calculating treatment fee, maximum,  deductible, or including in calculations for other treatments.<br/>\n" +
    "    {{t.info_from_update_noninvoiced_treatments}}<br/>\n" +
    "  </span>\n" +
    "</script>\n" +
    "<script type=\"text/ng-template\" id=\"treatment-info-invoiced.html\">\n" +
    "  <span>\n" +
    "    This treatment has been invoiced and fees cannot be changed.<br/>\n" +
    "    {{t.info_from_update_noninvoiced_treatments}}<br/>\n" +
    "  </span>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/common/patient-detail.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<h3 class=\"page-title\" style=\"margin-bottom:10px;\">\n" +
    "  <img class=\"img-circle\" style=\"height:64px; width:64px; margin-top:-15px;\"\n" +
    "    ng-style=\"{cursor: patient.mugshot ? 'pointer' : 'initial'}\"\n" +
    "    popover-enable=\"patient.mugshot\"\n" +
    "    popover-class=\"popover-profile-picture\"\n" +
    "    popover-placement=\"bottom-right\"\n" +
    "    popover-trigger=\"click\"\n" +
    "    ng-src=\"{{ patient.mugshot || '/assets/images/default-mugshot.jpg' }}\"\n" +
    "    uib-popover-template=\"'#patient-detail-mugshot-popover'\">\n" +
    "  {{ patient.firstName }} {{ patient.lastName }}\n" +
    "  <small class=\"dis-b\" style=\"margin-left:72px; margin-top:-10px;\">\n" +
    "    <span style=\"font-weight:bolder; margin-right:4px\">{{ ((patient.birthDate) ? ( '(' + getYearsOld(patient.birthDate) + 'y)') : '') }}</span>\n" +
    "    <span style=\"font-weight:bolder; margin-right:4px\">(ID: {{ patient.id }})</span>\n" +
    "    <span style=\"font-weight:bolder; margin-right:4px\">{{ patient.mobilePhone | tel }}</span>\n" +
    "  </small>\n" +
    "</h3>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#patient-detail-mugshot-popover\">\n" +
    "  <div class=\"thumbnail m-b-0\">\n" +
    "    <img ng-src=\"{{ patient.mugshot }}\">\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <ul class=\"nav nav-pills\">\n" +
    "      <li ng-show=\"CanRetrievePatient\" ng-class=\"{active: $state.includes('profile')}\"><a ui-sref=\"profile\" ui-sref-opts=\"{reload: true}\">Profile</a></li>\n" +
    "      <li ng-show=\"CanRetrievePatient\" ng-class=\"{active: $state.includes('patient-interactions')}\"><a ui-sref=\"patient-interactions\" ui-sref-opts=\"{reload: true}\" data-toggle=\"tooltip\" title=\"Non-medical notes and communication with patient\">Interactions</a></li>\n" +
    "      <li ng-show=\"CanListEncounter\" ng-class=\"{active: $state.includes('chartnotes')}\"><a ui-sref=\"chartnotes\" ui-sref-opts=\"{reload: true}\" data-toggle=\"tooltip\" title=\"Medical notes and reminders\">Encounters</a></li>\n" +
    "      <li ng-show=\"CanListTreatment\" ng-class=\"{active: $state.includes('treatmentplans')}\"><a ui-sref=\"treatmentplans\" ui-sref-opts=\"{reload: true}\">Treatments</a></li>\n" +
    "      <li ng-show=\"CanListPatientImage\" ng-class=\"{active: $state.includes('photos')}\">\n" +
    "        <a ui-sref=\"photos\" ui-sref-opts=\"{reload: true}\">Images</a>\n" +
    "      </li>\n" +
    "      <li ng-show=\"CanListPrescription\" ng-class=\"{active: $state.includes('prescriptions')}\"><a ui-sref=\"prescriptions\" ui-sref-opts=\"{reload: true}\">Prescriptions</a></li>\n" +
    "      <li ng-show=\"CanListPatientFile\" ng-class=\"{active: $state.includes('files')}\">\n" +
    "        <a ui-sref=\"files\" ui-sref-opts=\"{reload: true}\">Files</a>\n" +
    "      </li>\n" +
    "      <li ng-hide=\"true\" ng-class=\"{active: $state.includes('detail-reports')}\"><a ui-sref=\"detail-reports\">Reports</a></li>\n" +
    "      <li ng-show=\"CanListBilling\" ng-class=\"{active: $state.includes('billing')}\"><a ui-sref=\"billing\" ui-sref-opts=\"{reload: true}\">Billing</a></li>\n" +
    "      <li ng-show=\"CanListInsurance\" ng-class=\"{active: $state.includes('insurance')}\"><a ui-sref=\"insurance\" ui-sref-opts=\"{reload: true}\">Insurance</a></li>\n" +
    "      <li ng-show=\"CanListReferral\" ng-class=\"{active: $state.includes('patient-referrals.received')}\"><a ui-sref=\"patient-referrals.received\" ui-sref-opts=\"{reload: true}\">Referrals Received</a></li>\n" +
    "    </ul>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div ng-if=\"patient.hmac.valid === false\" class=\"alert alert-warning medical-alert pos-r\" style=\"margin-bottom:10px\">\n" +
    "  <i class=\"icomoon icon-icomoon-warning2 pos-a\" style=\"font-size:48px; bottom:50%\"></i>\n" +
    "  <div style=\"margin-left:64px\" ng-switch=\"patient.hmac.reasons_for_invalidity.length > 0\">\n" +
    "    <h4>Dental EMR detected profile data for this patient may have been altered or corrupted.</h4>\n" +
    "    <p class=\"m-t-0\" style=\"margin-bottom:10px\">\n" +
    "      Please verify information in the profile tab with the patient before relying on it for diagnosis or treatment.\n" +
    "    </p>\n" +
    "    <p ng-switch-when=\"true\" class=\"m-y-0\">\n" +
    "      Dental EMR has automatically detected what was altered and has made that information available in the patient profile tab.\n" +
    "      Please review, correct any incorrect information, check the verification checkbox, and update to remove this message.\n" +
    "    </p>\n" +
    "    <p ng-switch-when=\"false\" class=\"m-y-0\">\n" +
    "      Dental EMR will contact you regarding what was altered, and once you have corrected and verified profile data\n" +
    "      check box in profile and update to remove this error.\n" +
    "    </p>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div ng-if=\"patientAlerts.length\" class=\"alert alert-warning patient-alert pos-r\" style=\"margin-bottom:10px\"\n" +
    "  uib-tooltip=\"Patient Interactions Notes Alerts.  Change in the patient interactions tab.\" tooltip-placement=\"bottom\">\n" +
    "  <i class=\"icomoon icon-icomoon-warning2 pos-a\"></i>\n" +
    "  <button type=\"button\" class=\"btn btn-link btn-icomoon-pixel-perfect-1x btn-close\" ng-click=\"discardAlerts()\">\n" +
    "    <i class=\"icomoon icon-icomoon-cancel-circle\"></i>\n" +
    "  </button>\n" +
    "  <div class=\"content\">\n" +
    "    <p class=\"m-y-0\" ng-repeat=\"alert in patientAlerts\">\n" +
    "      {{ alert.data.note.substring(0, 200) }}\n" +
    "      <span ng-if=\"alert.data.note.length > 200\">...</span>\n" +
    "    </p>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div ui-view=\"profile\" ng-show=\"$state.includes('profile')\"></div>\n" +
    "<div ui-view=\"patient-interactions\" ng-show=\"$state.includes('patient-interactions')\"></div>\n" +
    "<div ui-view=\"chartnotes\" ng-show=\"$state.includes('chartnotes')\"></div>\n" +
    "\n" +
    "<div class=\"row\" ng-show=\"$state.includes('treatmentplans')\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-stethoscope font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Treatments</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\"></div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <div ui-view=\"treatmentplans\"></div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"row\" ng-show=\"$state.includes('photos')\">\n" +
    "  <div class=\"col-md-12\" ui-view=\"photos\" ng-show=\"$state.is('photos')\"></div>\n" +
    "  <div class=\"col-md-12\" ui-view=\"photodetail\" ng-show=\"$state.is('photodetail')\"></div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"row\" ng-show=\"$state.includes('prescriptions')\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-stethoscope font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Prescriptions</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\"></div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <div ui-view=\"prescriptions\"></div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div ui-view=\"files\" ng-show=\"$state.includes('files')\"></div>\n" +
    "\n" +
    "<div class=\"row\" ng-show=\"$state.includes('detail-reports')\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-files-empty font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Reports</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\"></div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <div ui-view=\"detail-reports\"></div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div ui-view=\"billing\" ng-show=\"$state.includes('billing')\"></div>\n" +
    "\n" +
    "<div ui-view=\"insurance\" ng-show=\"$state.includes('insurance')\"></div>\n" +
    "\n" +
    "<div ui-view=\"referrals\" ng-show=\"$state.includes('patient-referrals')\"></div>\n" +
    "")

$templateCache.put("src/common/patient-files.tpl.html","<div class=\"portlet light porlet-fit\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption\">\n" +
    "      <i class=\"icomoon icon-icomoon-files-empty font-red-sunglo\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold uppercase\">Files</span>\n" +
    "    </div>\n" +
    "    <div class=\"actions pull-right\">\n" +
    "      <button type=\"button\" class=\"btn btn-icomoon-pixel-perfect\" ng-click=\"$ctrl.list()\" ng-disabled=\"!$ctrl.canList\">\n" +
    "        <i class=\"icomoon icon-icomoon-spinner11\"></i>\n" +
    "      </button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body\">\n" +
    "    <div class=\"panel\">\n" +
    "      <div class=\"panel-body p-a-0\">\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"col-xs-12 col-sm-4 col-md-3 m-b\" ng-if=\"$ctrl.canCreate\">\n" +
    "            <div class=\"thumbnail thumbnail-patient-archive\">\n" +
    "              <div class=\"archive drop-box\"\n" +
    "                ngf-allow-dir=\"true\"\n" +
    "                ngf-drag-over-class=\"'dragover'\"\n" +
    "                ngf-drop-available=\"dropAvailable\"\n" +
    "                ngf-hide-on-drop-not-available=\"true\"\n" +
    "                ngf-max-size=\"'700MB'\"\n" +
    "                ngf-multiple=\"true\"\n" +
    "                ngf-drop=\"$ctrl.create($files)\">\n" +
    "                <div>Drop Files to Upload</div>\n" +
    "              </div>\n" +
    "              <div class=\"caption text-center p-x-0 p-y-0\">\n" +
    "                <div class=\"btn-group btn-group-justified\" role=\"group\" style=\"margin-top:4px\">\n" +
    "                  <div class=\"btn-group\" role=\"group\" style=\"padding-right:2px\">\n" +
    "                    <button type=\"button\" class=\"btn btn-info\" ng-click=\"$ctrl.scan()\">Acquire</button>\n" +
    "                  </div>\n" +
    "                  <div class=\"btn-group\" role=\"group\">\n" +
    "                    <label class=\"btn btn-file btn-info\">\n" +
    "                      Upload\n" +
    "                      <input type=\"file\"\n" +
    "                        ngf-allow-dir=\"true\"\n" +
    "                        ngf-max-size=\"'700MB'\"\n" +
    "                        ngf-multiple=\"true\"\n" +
    "                        ngf-select=\"$ctrl.create($files)\">\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <!-- <div class=\"btn-group\" role=\"group\" style=\"padding-left:2px\">\n" +
    "                    <button type=\"button\" class=\"btn btn-info\" ng-click=\"$ctrl.uploadStored()\">Upload from Ccache</button>\n" +
    "                  </div> -->\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"col-xs-12 col-sm-4 col-md-3 m-b\" ng-repeat=\"patient_file in $ctrl.patient_files\">\n" +
    "            <div class=\"thumbnail thumbnail-patient-archive thumbnail-link\" ng-click=\"$ctrl.retrieve(patient_file)\">\n" +
    "              <wave-spinner class=\"modal-loader\" ng-show=\"patient_file.$loading\"></wave-spinner>\n" +
    "              <div class=\"archive\" archive-type-preview file=\"patient_file.$file_archive\" file-metadata=\"patient_file.archive_metadata\">\n" +
    "                <div class=\"btn-group\" ng-show=\"patient_file.id\" ng-click=\"$event.preventDefault(); $event.stopPropagation();\">\n" +
    "                  <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.retrieve(patient_file)\" ng-if=\"$ctrl.canRetrieve\">\n" +
    "                    <i class=\"icomoon icon-icomoon-pencil5\"></i>\n" +
    "                  </div>\n" +
    "                  <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.download(patient_file)\" ng-if=\"$ctrl.canRetrieve\">\n" +
    "                    <i class=\"icomoon icon-icomoon-download\"></i>\n" +
    "                  </div>\n" +
    "                  <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.destroy(patient_file)\" ng-if=\"$ctrl.canDelete\">\n" +
    "                    <i class=\"icomoon icon-icomoon-bin\"></i>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <h6 class=\"size\">{{ patient_file.archive_metadata.size | filesize }}</h6>\n" +
    "                <div class=\"progress\" archive-creation-progress oid=\"patient_file.id\" upload-progress=\"patient_file.$uploadProgress_archive\">\n" +
    "                  <div class=\"progress-bar\"></div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"caption text-center\" style=\"height:72px\">\n" +
    "                <b class=\"dis-b wide-nowrap\" uib-tooltip=\"{{ patient_file.name }}\" tooltip-placement=\"top\" tooltip-popup-delay=\"1000\">{{ patient_file.name }}</b>\n" +
    "                <p class=\"text-muted\" style=\"margin: 5px 0;\" ng-if=\"patient_file.id\">\n" +
    "                  <small class=\"dis-b wide-nowrap\">{{ patient_file.created_at | date:\"medium\" }}</small>\n" +
    "                  <small class=\"dis-b wide-nowrap\">{{ patient_file.created_by }}</small>\n" +
    "                </p>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <a id=\"patient-file-dwl\" class=\"hide\" href=\"#\"></a>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#patient-file-destroy\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Are you sure?</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "    This will remove the File from Patient Charts\n" +
    "    <div class=\"alert alert-danger m-b-0\" ng-if=\"$ctrl.protected_objects\" style=\"margin-top:15px\">\n" +
    "      <strong>The following objects depend on the file:</strong>\n" +
    "      <ul class=\"list-unstyled\">\n" +
    "        <li ng-repeat=\"obj in $ctrl.protected_objects\">- {{ obj[0] }}:{{ obj[1] }}</li>\n" +
    "      </ul>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn p-x btn-default\" ng-click=\"$dismiss()\">Close</button>\n" +
    "    <button class=\"btn p-x btn-danger\" ng-click=\"$ctrl.destroy()\">Delete</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#patient-file-retrieve\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Patient File <small>{{ $ctrl.patient_file.archive_name }}</small></h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "    <form>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label>Name</label>\n" +
    "        <input type=\"text\" class=\"form-control\" name=\"name\" ng-model=\"$ctrl.patient_file.name\" ng-readonly=\"!$ctrl.canUpdate\">\n" +
    "        <span class=\"help-block\" ng-if=\"$ctrl.canUpdate\">If left blank, it will be inferred from the filename</span>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label>Description</label>\n" +
    "        <textarea class=\"form-control\" name=\"description\" rows=\"4\" ng-model=\"$ctrl.patient_file.description\" ng-readonly=\"!$ctrl.canUpdate\"></textarea>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\" ng-if=\"$ctrl.patient_file.archive_metadata.content_type\">\n" +
    "        <label>File</label>\n" +
    "        <embed class=\"w-full img-thumbnail\" ng-attr-src=\"{{ $ctrl.patient_file.archive | trustAsResourceUrl }}\" ng-attr-type=\"{{ $ctrl.patient_file.archive_metadata.content_type }}\" />\n" +
    "      </div>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label>Created By</label>\n" +
    "        <p class=\"form-control form-control-static\">{{ $ctrl.patient_file.created_by }}</p>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label>Created At</label>\n" +
    "        <p class=\"form-control form-control-static\">{{ $ctrl.patient_file.created_at | date:\"MM/dd/yyyy h:mma\" }}</p>\n" +
    "      </div>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn p-x-md btn-default\" ng-click=\"$dismiss()\">Close</button>\n" +
    "    <button class=\"btn p-x-md btn-success\" ng-click=\"$ctrl.download()\" ng-disabled=\"$ctrl.loading\">Download</button>\n" +
    "    <button class=\"btn p-x-md btn-primary\" ng-click=\"$ctrl.update()\" ng-disabled=\"$ctrl.loading\" ng-if=\"$ctrl.canUpdate\">Save</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/common/patient-form-conclusion.partial.tpl.html","<!--<h3>Conclusion</h3>-->\n" +
    "<div class=\"panel panel-default\" id=\"patient-form-conclusion-emergency\">\n" +
    "  <div class=\"panel-heading\">\n" +
    "    <h4 class=\"panel-title\">In case of emergency</h4>\n" +
    "  </div>\n" +
    "  <div class=\"panel-body\">\n" +
    "    <div class=\"row\">\n" +
    "\n" +
    "      <div class=\"form-horizontal col-sm-6\">\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"control-label col-sm-5\">Emergency Contact Full Name</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <input type=\"text\"\n" +
    "                   class=\"form-control\"\n" +
    "                   name=\"emergency-contact-name\"\n" +
    "                   ng-model=\"patient.emergencyContactName\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"control-label col-sm-5\">Home Number</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <phone-input ng-model=\"patient.emergencyContactPhone\"\n" +
    "                         name=\"emergency-contact-phone\"\n" +
    "                         ng-required=\"false\" />\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"control-label col-sm-5\">Relation To Patient</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <input type=\"text\"\n" +
    "                   class=\"form-control\"\n" +
    "                   name=\"emergency-contact-relation\"\n" +
    "                   ng-model=\"patient.emergencyContactRelation\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "      <div class=\"form-horizontal col-sm-6\"></div>\n" +
    "\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "<div class=\"panel panel-default\" id=\"patient-form-conclusion-verification\">\n" +
    "  <div class=\"panel-heading\">\n" +
    "    <h4 class=\"panel-title\">Verification</h4>\n" +
    "  </div>\n" +
    "  <div class=\"panel-body\">\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-10\">\n" +
    "        {{signatureText}}\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-6\">\n" +
    "        <div class=\"relative-signature-box panel-default\">\n" +
    "                <canvas angular-sig-pad ng-model=\"patient.consentSignature\"></canvas>\n" +
    "              <button   ng-disabled=\"metadataReadOnly\" type=\"button\" class=\"absolute-top-button-transparent-right-box btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"clearSignature(patient,'consentSignature')\"><i class=\"icomoon icon-icomoon-reset\"></i></button>\n" +
    "            </div>\n" +
    "        <label ng-show=\"patient.consentSignatureDate\">{{patient.consentSignatureDate | signedDate}}</label>\n" +
    "     </div>\n" +
    "<!--\n" +
    "      <div class=\"col-sm-6\">\n" +
    "        <signature-input ng-model=\"patient.consentSignature\"\n" +
    "                         placeholder=\"'Sign Here'\">\n" +
    "        </signature-input>\n" +
    "      </div>\n" +
    "-->\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-10\">\n" +
    "        <h5>{{feeAndPaymentTitle}}</h5>\n" +
    "        <p>{{feeAndPaymentText}}</p>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-6\">\n" +
    "        <div class=\"relative-signature-box panel-default\">\n" +
    "                <canvas angular-sig-pad ng-model=\"patient.feePaymentSignature\"></canvas>\n" +
    "              <button   ng-disabled=\"metadataReadOnly\" type=\"button\" class=\"absolute-top-button-transparent-right-box btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"clearSignature(patient,'feePaymentSignature')\"><i class=\"icomoon icon-icomoon-reset\"></i></button>\n" +
    "            </div>\n" +
    "        <label ng-show=\"patient.feePaymentSignatureDate\">{{patient.feePaymentSignatureDate | signedDate}}</label>\n" +
    "     </div>\n" +
    "    </div>\n" +
    "<!--\n" +
    "      <div class=\"col-sm-6\">\n" +
    "        <signature-input ng-model=\"patient.feePaymentSignature\"\n" +
    "                         placeholder=\"'Sign Here'\">\n" +
    "        </signature-input>\n" +
    "      </div>\n" +
    "-->\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-10\">\n" +
    "        <p>Please remember that insurance is considered a method of reimbursing the patient for fees paid to the doctor and is not a substitute for payment. Some companies pay fixed allowances for certain procedures and others pay a percentage of the charge. It is your responsibility to pay any deductible amount, co-insurance or any other balance not paid for by your insurance company. You will be responsible for all collection costs, attorneys fees, and court costs. This signature on file is my authorization for the release of information necessary to process my claims. </p>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-6\">\n" +
    "        <div class=\"relative-signature-box panel-default\">\n" +
    "              <canvas angular-sig-pad ng-model=\"patient.insuranceSignature\"></canvas>\n" +
    "              <button   ng-disabled=\"metadataReadOnly\" type=\"button\" class=\"absolute-top-button-transparent-right-box btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"clearSignature(patient,'insuranceSignature')\"><i class=\"icomoon icon-icomoon-reset\"></i></button>\n" +
    "        </div>\n" +
    "        <label ng-show=\"patient.insuranceSignatureDate\">{{patient.insuranceSignatureDate | signedDate}}</label>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-6\">\n" +
    "        <p>(Patient / guardian signature)</p>\n" +
    "      </div>\n" +
    "   </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-10\">\n" +
    "      <p>I hereby authorize payment to this doctor named of the benefits otherwise payable to me.</p>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <fieldset>\n" +
    "      <div class=\"form-group row\">\n" +
    "        <div class=\"col-sm-6\">\n" +
    "          <div class=\"relative-signature-box panel-default\">\n" +
    "            <canvas angular-sig-pad ng-model=\"patient.insuranceSubscriberSignature\" ng-class=\"{ 'link-disabled':  disablePrimaryInsurance && isAnonymous()}\"></canvas>\n" +
    "            <button ng-disabled=\"metadataReadOnly || !patient.insuranceBillingProviderAssignedBenefits && isAnonymous()\" type=\"button\" class=\"absolute-top-button-transparent-right-box btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"clearSignature(patient,'insuranceSubscriberSignature')\"><i class=\"icomoon icon-icomoon-reset\"></i></button>\n" +
    "            <div class=\"form-group row\">\n" +
    "              <div class=\"col-sm-12\">\n" +
    "                <p>(Primary insurance subscriber signature)</p>\n" +
    "              </div>\n" +
    "           </div>\n" +
    "            <div class=\"checkbox\" style=\"padding-left: 25px;\">\n" +
    "              <label><input type=\"checkbox\" ng-disabled=\"disablePrimaryInsurance\" ng-change=\"assignBenefitsChange(patient.insuranceBillingProviderAssignedBenefits,'insuranceSubscriberSignature')\" ng-model=\"patient.insuranceBillingProviderAssignedBenefits\">Assign primary insurance benefits to billing practice?</label>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <label class=\"control-label validationMessage\" style=\"color: #e73d4a;\" >Assignment of benefits requires a signature, signature date, and this check box checked.</label>\n" +
    "          <label ng-show=\"patient.insuranceSubscriberSignatureDate\">{{patient.insuranceSubscriberSignatureDate | signedDate}}</label>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </fieldset>\n" +
    "\n" +
    "    <fieldset >\n" +
    "      <div class=\"form-group row\" >\n" +
    "        <div class=\"col-sm-6\">\n" +
    "\n" +
    "\n" +
    "          <div class=\"relative-signature-box panel-default\">\n" +
    "            <canvas ng-class=\"{ 'link-disabled':  disableOtherInsurance && isAnonymous()}\" angular-sig-pad ng-model=\"patient.otherCoverageSubscriberSignature\"></canvas>\n" +
    "            <button ng-disabled=\"metadataReadOnly || !patient.otherCoverageBillingProviderAssignedBenefits && isAnonymous()\" type=\"button\" class=\"absolute-top-button-transparent-right-box btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"clearSignature(patient,'otherCoverageSubscriberSignature')\"><i class=\"icomoon icon-icomoon-reset\"></i></button>\n" +
    "            <div class=\"form-group row\">\n" +
    "              <div class=\"col-sm-12\">\n" +
    "                <p>(Secondary insurance subscriber signature)</p>\n" +
    "              </div>\n" +
    "           </div>\n" +
    "            <div class=\"checkbox\" style=\"padding-left: 25px;\">\n" +
    "              <label><input type=\"checkbox\" ng-disabled=\"disableOtherInsurance\" ng-change=\"assignBenefitsChange(patient.otherCoverageBillingProviderAssignedBenefits,'otherCoverageSubscriberSignature')\" ng-model=\"patient.otherCoverageBillingProviderAssignedBenefits\">Assign secondary insurance benefits to billing practice?</label>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <label class=\"control-label validationMessage\" style=\"color: #e73d4a;\" >Assignment of benefits requires a signature, signature date, and this check box checked.</label>\n" +
    "          <label ng-show=\"patient.otherCoverageSubscriberSignatureDate\">{{patient.otherCoverageSubscriberSignatureDate | signedDate}}</label>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </fieldset>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-10\">\n" +
    "        <p>\n" +
    "          I hereby acknowledge I have read this office’s\n" +
    "          <a ng-show=\"practice.patient_privacy_policy_url || patient.practicePrivacyPolicyUrl\" ng-href=\"{{ practice.patient_privacy_policy_url || patient.practicePrivacyPolicyUrl }}\" target=\"_blank\">Notice of Privacy Practices</a><span ng-hide=\"practice.patient_privacy_policy_url || patient.practicePrivacyPolicyUrl\">Notice of Privacy Practices</span>.\n" +
    "          I have been given the opportunity to ask any questions I may have regarding this Notice</p>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-6\">\n" +
    "       <div class=\"relative-signature-box panel-default\">\n" +
    "          <canvas angular-sig-pad ng-model=\"patient.privacySignature\"></canvas>\n" +
    "          <button   ng-disabled=\"metadataReadOnly\" type=\"button\" class=\"absolute-top-button-transparent-right-box btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"clearSignature(patient,'privacySignature')\"><i class=\"icomoon icon-icomoon-reset\"></i></button>\n" +
    "        </div>\n" +
    "        <label ng-show=\"patient.privacySignatureDate\">{{patient.privacySignatureDate | signedDate}}</label>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-repeat=\"(key, value) in patient.extensions.agreementsSection\">\n" +
    "      <div class=\"form-group row\">\n" +
    "        <div class=\"col-sm-10\">\n" +
    "          <h5 ng-if=\"value.title\">{{value.title}}</h5>\n" +
    "          <p ng-bind-html=\"value.text\">\n" +
    "          </p>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "  \n" +
    "      <div class=\"form-group row\">\n" +
    "        <div class=\"col-sm-6\">\n" +
    "          <div class=\"relative-signature-box panel-default\">\n" +
    "            <canvas angular-sig-pad ng-model=\"value.valueSignature\"></canvas>\n" +
    "            <button type=\"button\" class=\"absolute-top-button-transparent-right-box btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"clearSignature(value,'valueSignature')\"><i class=\"icomoon icon-icomoon-reset\"></i></button>\n" +
    "          </div>\n" +
    "          <label ng-show=\"value.valueSignatureDate\">{{value.valueSignatureDate | signedDate}}</label>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/patient-form-demographic-information.partial.tpl.html","\n" +
    "<div class=\"panel panel-default\">\n" +
    "  <div class=\"panel-heading\">\n" +
    "    <h4 class=\"panel-title\">Patient Demographic Information</h4>\n" +
    "  </div>\n" +
    "  <div class=\"panel-body\">\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"col-sm-6 form-horizontal\">\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Prefix</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <div class=\"btn-group\">\n" +
    "              <label class=\"btn btn-default btn-wide-75\" ng-model=\"patient.title\" uib-btn-radio=\"'mr'\" uncheckable>Mr.</label>\n" +
    "              <label class=\"btn btn-default btn-wide-75\" ng-model=\"patient.title\" uib-btn-radio=\"'mrs'\" uncheckable>Mrs.</label>\n" +
    "              <label class=\"btn btn-default btn-wide-75\" ng-model=\"patient.title\" uib-btn-radio=\"'ms'\" uncheckable>Ms.</label>\n" +
    "              <label class=\"btn btn-default btn-wide-75\" ng-model=\"patient.title\" uib-btn-radio=\"'dr'\" uncheckable>Dr.</label>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Gender</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <div class=\"btn-group\">\n" +
    "              <label class=\"btn btn-default btn-wide\" ng-model=\"patient.gender\" uib-btn-radio=\"'m'\">Male</label>\n" +
    "              <label class=\"btn btn-default btn-wide\" ng-model=\"patient.gender\" uib-btn-radio=\"'f'\">Female</label>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Patient First Name</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <input type=\"text\" class=\"form-control\" name=\"firstname\" autofocus required\n" +
    "              invalid-message=\"'Minimum length is 2 letters.'\"\n" +
    "              ng-minlength=2\n" +
    "              ng-model=\"patient.firstName\"\n" +
    "              required-message=\"'This field is required'\"\n" +
    "              validate-on=\"blur\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Patient Middle Initial</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "          <input type=\"text\" class=\"form-control\" name=\"middlename\" size=\"1\" maxlength=\"1\"\n" +
    "                 invalid-message=\"'Middle initial is one character'\"\n" +
    "                 ng-maxlength=\"1\"\n" +
    "                 ng-minlength=\"0\"\n" +
    "                 ng-model=\"patient.middleInitial\"\n" +
    "                 ng-pattern=\"/^[A-z]$/\"\n" +
    "                 validate-on=\"blur\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Patient Last Name</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <input type=\"text\" class=\"form-control\" name=\"lastname\" required\n" +
    "            invalid-message=\"'Minimum length is 2 letters.'\"\n" +
    "            ng-minlength=2\n" +
    "            ng-model=\"patient.lastName\"\n" +
    "            required-message=\"'This field is required'\"\n" +
    "            validate-on=\"blur\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Preferred Name</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <input type=\"text\" class=\"form-control\" name=\"nickname\" ng-model=\"patient.nickname\" >\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Date of Birth</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <custom-datepicker-popup\n" +
    "              form-name=\"'updatePatient'\"\n" +
    "              format=\"'MM/dd/yyyy'\"\n" +
    "              input-name=\"'patientBirtInputDate'\"\n" +
    "              ng-model=\"patient.birthDate\"\n" +
    "              ng-required=\"false\"\n" +
    "              show-placeholder=\"false\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Social Security Number</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <ssn-input name=\"'patient-socialSecurityNumber'\"\n" +
    "                       ng-model=\"patient.ssn\"\n" +
    "                       ng-required=\"false\"/>\n" +
    "            </ssn-input>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "        <div class=\"form-group\" ng-class=\"{'has-error':updatePatient.height.$invalid}\">\n" +
    "          <label class=\"col-sm-5 control-label\">Height</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <div class=\"input-group\">\n" +
    "              <div class=\"input-group-btn\">\n" +
    "                <div class=\"btn-group\" uib-dropdown>\n" +
    "                  <button type=\"button\" class=\"btn\" style=\"min-width:70px;\" uib-dropdown-toggle>\n" +
    "                    {{ patient.heightMetric == 1 ? \"In\" : patient.heightMetric == 2 ? \"Cm\" : \"\" }}\n" +
    "                    <span class=\"caret\"></span>\n" +
    "                  </button>\n" +
    "                  <ul class=\"dropdown-menu\" uib-dropdown-menu role=\"menu\">\n" +
    "                    <li role=\"menuitem\" ng-class=\"{'active':patient.heightMetric == 1}\">\n" +
    "                      <a ng-click=\"patient.heightMetric = 1\">In</a>\n" +
    "                    </li>\n" +
    "                    <li role=\"menuitem\" ng-class=\"{'active':patient.heightMetric == 2}\">\n" +
    "                      <a ng-click=\"patient.heightMetric = 2\">Cm</a>\n" +
    "                    </li>\n" +
    "                  </ul>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <input type=\"text\" class=\"form-control\" \n" +
    "                angular-validator-quiet\n" +
    "                name=\"height\"\n" +
    "                ng-model=\"patient.height\"\n" +
    "                ng-pattern=\"/^\\d+(\\.\\d{1,2})?$/\"\n" +
    "                validate-on=\"dirty\">\n" +
    "              <span class=\"input-group-addon\" ng-if=\"patient.height|toFeetAndInch:patient.heightMetric\">\n" +
    "                {{ patient.height | toFeetAndInch:patient.heightMetric }}\n" +
    "              </span>\n" +
    "            </div>\n" +
    "            <ng-messages for=\"updatePatient.height.$error\">\n" +
    "              <span class=\"help-block\" ng-message=\"pattern\">\n" +
    "                Must be a number with at most 2 decimal places, e.g 67.50\n" +
    "              </span>\n" +
    "            </ng-messages>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\" ng-class=\"{'has-error':updatePatient.weight.$invalid}\">\n" +
    "          <label class=\"col-sm-5 control-label\">Weight</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <div class=\"input-group\">\n" +
    "              <div class=\"input-group-btn\">\n" +
    "                <div class=\"btn-group\" uib-dropdown>\n" +
    "                  <button type=\"button\" class=\"btn\" style=\"min-width:70px;\" uib-dropdown-toggle>\n" +
    "                    {{ patient.weightMetric == 1 ? \"Lb\" : patient.weightMetric == 2 ? \"Kg\" : \"\" }}\n" +
    "                    <span class=\"caret\"></span>\n" +
    "                  </button>\n" +
    "                  <ul class=\"dropdown-menu\" uib-dropdown-menu role=\"menu\">\n" +
    "                    <li role=\"menuitem\" ng-class=\"{'active':patient.weightMetric == 1}\">\n" +
    "                      <a ng-click=\"patient.weightMetric = 1\">Lb</a>\n" +
    "                    </li>\n" +
    "                    <li role=\"menuitem\" ng-class=\"{'active':patient.weightMetric == 2}\">\n" +
    "                      <a ng-click=\"patient.weightMetric = 2\">Kg</a>\n" +
    "                    </li>\n" +
    "                  </ul>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <input type=\"text\" class=\"form-control\" \n" +
    "                angular-validator-quiet\n" +
    "                name=\"weight\"\n" +
    "                ng-model=\"patient.weight\"\n" +
    "                ng-pattern=\"/^\\d+(\\.\\d{1,2})?$/\"\n" +
    "                validate-on=\"dirty\">\n" +
    "            </div>\n" +
    "            <ng-messages for=\"updatePatient.weight.$error\">\n" +
    "              <span class=\"help-block\" ng-message=\"pattern\">\n" +
    "                Must be a number with at most 2 decimal places, e.g 76.50\n" +
    "              </span>\n" +
    "            </ng-messages>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-6 form-horizontal\">\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Mailing Address</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <input type=\"text\" class=\"form-control\" name=\"patient-address-street-1\"\n" +
    "                   city=\"patient.patientAddressCity\"\n" +
    "                   google-places-auto-complete\n" +
    "                   placeholder=\"\"\n" +
    "                   ng-model=\"patient.patientAddressStreet1\"\n" +
    "                   state=\"patient.patientAddressState\"\n" +
    "                   zip=\"patient.patientAddressZipCode\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Mailing Address cont.</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <input type=\"text\" class=\"form-control\" name=\"patient-address-street-2\"\n" +
    "                   ng-model=\"patient.patientAddressStreet2\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">City</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <input type=\"text\" class=\"form-control\" name=\"patient-address-city\"\n" +
    "                   ng-model=\"patient.patientAddressCity\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">State</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <state-input ng-model=\"patient.patientAddressState\" name=\"patient-address-state\" ng-required=\"false\" />\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Zip</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <zip-input name=\"patient-address-zip\" ng-model=\"patient.patientAddressZipCode\" ng-required=\"false\" />\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Phone Number</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <phone-input ng-model=\"patient.homePhone\" name=\"patient-home-phone\" ng-required=\"false\" />\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Mobile Number</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <phone-input name=\"patient-mobile-phone\"\n" +
    "                         ng-model=\"patient.mobilePhone\"\n" +
    "                         ng-required=\"false\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Use SMS for patient notifications instead of email</label>\n" +
    "          <div class=\"col-sm-7\" style=\"padding-top:8px;\">\n" +
    "            <switch ng-model=\"patient.preferSmsForNotifications\" class=\"small\" disabled=\"!patient.mobilePhone\"></switch>\n" +
    "            <small style=\"vertical-align:super;\">\n" +
    "              Sends patient notifications by sms to mobile number\n" +
    "            </small>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Email</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <input type=\"email\" name=\"email\" class=\"form-control\"\n" +
    "              invalid-message=\"'Not a valid email address'\"\n" +
    "              ng-model=\"patient.email\"\n" +
    "              validate-on=\"blur\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"col-sm-5 control-label\">Profile Picture</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <label class=\"thumbnail pos-r input-file-wrapper cursor-pointer\"\n" +
    "              ng-hide=\"isAnonymous()\"\n" +
    "              ngf-accept=\"'.png,.jpg,.jpeg'\"\n" +
    "              ngf-allow-dir=\"false\"\n" +
    "              ngf-capture=\"'camera'\"\n" +
    "              ngf-drag-over-class=\"'active'\"\n" +
    "              ngf-drop-available=\"dropAvailable\"\n" +
    "              ngf-drop=\"updateMugshot($file)\"\n" +
    "              ngf-max-size=\"'20MB'\"\n" +
    "              ngf-multiple=\"false\"\n" +
    "              ngf-resize=\"{\n" +
    "                type: 'image/jpeg',\n" +
    "                ratio: '1:1',\n" +
    "                centerCrop: true,\n" +
    "                restoreExif: false\n" +
    "              }\">\n" +
    "              <img ng-src=\"{{ patient.mugshot }}\" ng-if=\"patient.mugshot\">\n" +
    "              <h5 class=\"well m-y-0 text-center text-muted\" ng-if=\"!patient.mugshot\">\n" +
    "                Select\n" +
    "                <span ng-show=\"dropAvailable\"> or Drop</span>\n" +
    "                File\n" +
    "              </h5>\n" +
    "              <div class=\"progress progress-mugshot m-b-0\" ng-if=\"patient.$mugshot_progress\">\n" +
    "                <div class=\"progress-bar\" ng-style=\"{width: patient.$mugshot_progress + '%'}\"></div>\n" +
    "              </div>\n" +
    "              <input type=\"file\" ngf-multiple=\"false\" ngf-select=\"updateMugshot($file)\" ngf-accept=\"'.png,.jpg,.jpeg'\">\n" +
    "            </label>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/patient-form-dental-information-problems-1.partial.tpl.html","\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Discomfort, clicking, or popping in jaw</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isInDiscomfort\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Red, swollen, or bleeding gums</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isSwollenGums\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">A removable dental appliance</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isRemovableAppliance\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Blisters / sores in or around the mouth</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isBlistersSores\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Prolonged bleeding from an injury / extraction</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isProlongedBleeding\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Recent infections or sore throat</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isRecentInfections\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">My teeth are sensitive to hot</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isSensitiveToHot\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">My teeth are sensitive to cold</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isSensitiveToCold\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">My teeth are sensitive to sweets</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isSensitiveToSweets\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">My teeth are sensitive to chewing/biting</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isSensitiveToBiting\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Stained teeth</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isStainedTeeth\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Lock jaw</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isLockingJaw\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Bad Breath</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isBadBreath\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "")

$templateCache.put("src/common/patient-form-dental-information-problems-2.partial.tpl.html","\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Toothache</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isToothache\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Burning tongue / lips</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isBurningTongue\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Broken fillings</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isLostBrokenFillings\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Teeth grinding / clenching</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isTeethGrindingClenching\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Broken / chipped tooth</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isBrokenChippedTooth\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Difficulty closing jaw</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isDifficultyClosingJaw\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Difficulty opening jaw</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isDifficultyOpeningJaw\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Loose / shifting teeth</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isLooseShiftingTeeth\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Food caught between teeth</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isFoodCaughtBetweenTeeth\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Swelling in mouth</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isSwellingLumpInMouth\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "  <label class=\"control-label col-sm-5\">Other</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <switch ng-model=\"patient.isOther\" class=\"small\"></switch>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\" ng-show=\"patient.isOther\">\n" +
    "  <label class=\"control-label col-sm-5\">Please Describe</label>\n" +
    "  <div class=\"col-sm-7\">\n" +
    "    <input type=\"text\"\n" +
    "           class=\"form-control\"\n" +
    "           name=\"other-condition\"\n" +
    "           ng-model=\"patient.otherCondition\">\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "")

$templateCache.put("src/common/patient-form-dental-information.partial.tpl.html","<h3 id=\"patient-form-dental-information\">Dental Information</h3>\n" +
    "\n" +
    "  <div class=\"panel panel-default\">\n" +
    "    <div class=\"panel-heading\">\n" +
    "      <h3 class=\"panel-title\">Dental Information</h3>\n" +
    "    </div>\n" +
    "    <div class=\"panel-body\">\n" +
    "\n" +
    "      <div class=\"row\">\n" +
    "\n" +
    "\n" +
    "        <div class=\"form-horizontal col-sm-6\">\n" +
    "\n" +
    "          <div class=\"form-group\">\n" +
    "            <label class=\"control-label col-sm-5\">Reason for today’s visit</label>\n" +
    "            <div class=\"col-sm-7\">\n" +
    "              <input type=\"text\"\n" +
    "                     class=\"form-control\"\n" +
    "                     name=\"reason-for-visit\"\n" +
    "                     ng-model=\"patient.reasonForVisit\"/>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "          <div class=\"form-group\">\n" +
    "            <label class=\"control-label col-sm-5\">Dentists First Name</label>\n" +
    "            <div class=\"col-sm-7\">\n" +
    "              <input type=\"text\"\n" +
    "                     class=\"form-control\"\n" +
    "                     name=\"dentist-first\"\n" +
    "                     ng-model=\"patient.dentistFirstName\"/>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group\">\n" +
    "            <label class=\"control-label col-sm-5\">Dentists Last Name</label>\n" +
    "            <div class=\"col-sm-7\">\n" +
    "              <input type=\"text\"\n" +
    "                     class=\"form-control\"\n" +
    "                     name=\"dentist-last\"\n" +
    "                     ng-model=\"patient.dentistLastName\"/>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-horizontal col-sm-6\">\n" +
    "\n" +
    "          <div class=\"form-group\">\n" +
    "            <label class=\"control-label col-sm-5\">Are you in pain?</label>\n" +
    "            <div class=\"col-sm-7\">\n" +
    "              <switch ng-model=\"patient.isInPain\" class=\"small\"></switch>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group\" ng-show=\"patient.isInPain\">\n" +
    "            <label class=\"control-label col-sm-5\" >How would you rate your pain? (10 being the worst)</label>\n" +
    "            <div class=\"col-sm-7\">\n" +
    "              <div class=\"btn-group btn-group-justified\">\n" +
    "                <label ng-repeat=\"j in getNumber(10)  track by $index\" class=\"btn btn-default p-x-0\"\n" +
    "                       ng-model=\"patient.smileRating\"\n" +
    "                       uib-btn-radio=\"{{$index + 1}}\" style=\"\">{{$index + 1}}</label>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group\">\n" +
    "            <label class=\"control-label col-sm-5\" >For how long?</label>\n" +
    "            <div class=\"col-sm-7\">\n" +
    "              <input type=\"text\"\n" +
    "                     class=\"form-control\"\n" +
    "                     name=\"for-how-long\"\n" +
    "                     ng-model=\"patient.forHowLong\"\n" +
    "                     placeholder=\"For how long?\"/>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group\">\n" +
    "            <label class=\"control-label col-sm-5\" >Last Dental Visit</label>\n" +
    "            <div class=\"col-sm-7\">\n" +
    "              <input type=\"text\"\n" +
    "                     class=\"form-control\"\n" +
    "                     name=\"last-dental-exam\"\n" +
    "                     ng-model=\"patient.lastDentalExam\"/>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group\">\n" +
    "            <label class=\"control-label col-sm-5\" >Last Dental X-Rays</label>\n" +
    "            <div class=\"col-sm-7\">\n" +
    "              <input type=\"text\"\n" +
    "                     class=\"form-control\"\n" +
    "                     name=\"last-dental-xrays\"\n" +
    "                     ng-model=\"patient.lastDentalXrays\"\n" +
    "                     placeholder=\"Last Dental X-Rays\"/>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <div class=\"panel panel-default\">\n" +
    "    <div class=\"panel-heading\">\n" +
    "      <div class=\"row\">\n" +
    "        <div class=\"col-sm-5\">\n" +
    "          <h3 class=\"panel-title\">Dental Information Part 2</h3>\n" +
    "        </div>\n" +
    "        <div class=\"col-sm-7\">\n" +
    "\n" +
    "          <div class=\"btn-group pull-right\">\n" +
    "            <span class=\"btn btn-default\" ng-model=\"patient.markAllDentalInformationPart2\" uib-btn-radio=\"true\">MARK ALL YES</span>\n" +
    "            <span class=\"btn btn-default\" ng-model=\"patient.markAllDentalInformationPart2\" uib-btn-radio=\"false\">MARK ALL NO</span>\n" +
    "          </div>\n" +
    "\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"panel-body\">\n" +
    "Please indicate any of the following problems by toogle on the corresponding question\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "      <div class=\"form-horizontal col-sm-6\" ng-include=\"'src/common/patient-form-dental-information-problems-1.partial.tpl.html'\"></div>\n" +
    "      <div class=\"form-horizontal col-sm-6\" ng-include=\"'src/common/patient-form-dental-information-problems-2.partial.tpl.html'\"></div>\n" +
    "  </div>\n" +
    "\n" +
    "  </div>\n" +
    "")

$templateCache.put("src/common/patient-form-fee-profile.partial.tpl.html","<div class=\"panel panel-default\">\n" +
    "  <div class=\"panel-heading\">\n" +
    "    <h3 class=\"panel-title\">Payment Information</h3>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"panel-body\">\n" +
    "    <div class=\"row\">\n" +
    "    <div class=\"form-horizontal col-sm-6\">\n" +
    "\n" +
    "      <div class=\"form-group\">\n" +
    "        <label class=\"col-sm-5 control-label\">Fee schedule - fee for service</label>\n" +
    "        <div class=\"col-sm-7\">\n" +
    "          <ui-select ng-model=\"patient.feeprofile\">\n" +
    "            <ui-select-match>\n" +
    "              {{ $select.selected.name }}\n" +
    "            </ui-select-match>\n" +
    "            <ui-select-choices repeat=\"fp.id as fp in feeprofiles | filter:{has_insurance_fees:false} | filter:$select.search\" ui-disable-choice=\"fp.is_archived\">\n" +
    "              <span ng-bind-html=\"fp.name | highlight: $select.search\"></span>\n" +
    "            </ui-select-choices>\n" +
    "          </ui-select>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "\n" +
    "      <div class=\"form-group\">\n" +
    "        <label class=\"col-sm-5 control-label\">Payment Type</label>\n" +
    "        <div class=\"col-sm-7\">\n" +
    "          <div class=\"btn-group\">\n" +
    "            <label class=\"btn btn-default\"\n" +
    "                   ng-model=\"patient.paymentType\"\n" +
    "                   uib-btn-radio=\"'cash'\">Cash</label>\n" +
    "            <label class=\"btn btn-default\"\n" +
    "                   ng-model=\"patient.paymentType\"\n" +
    "                   uib-btn-radio=\"'check'\">Check</label>\n" +
    "            <label class=\"btn btn-default\"\n" +
    "                   ng-model=\"patient.paymentType\"\n" +
    "                   uib-btn-radio=\"'credit_card'\">Credit Card</label>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "")

$templateCache.put("src/common/patient-form-insurance-information.partial.tpl.html","<h3>Insurance Information</h3>\n" +
    "\n" +
    "    <div class=\"panel panel-default\">\n" +
    "      <div class=\"panel-heading\">\n" +
    "        <h3 class=\"panel-title\">General Insurance Information</h3>\n" +
    "      </div>\n" +
    "      <div class=\"panel-body\">\n" +
    "\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-sm-2\">\n" +
    "            <label>Employed</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-10\">\n" +
    "            <div class=\"btn-group pull-right\">\n" +
    "              <label class=\"btn btn-default\"\n" +
    "                     ng-model=\"patient.employmentStatus\"\n" +
    "                     uib-btn-radio=\"'full_time'\">Full Time</label>\n" +
    "              <label class=\"btn btn-default\"\n" +
    "                     ng-model=\"patient.employmentStatus\"\n" +
    "                     uib-btn-radio=\"'part_time'\">Part Time</label>\n" +
    "              <label class=\"btn btn-default\"\n" +
    "                     ng-model=\"patient.employmentStatus\"\n" +
    "                     uib-btn-radio=\"'retired'\">Retired</label>\n" +
    "              <label class=\"btn btn-default\"\n" +
    "                     ng-model=\"patient.employmentStatus\"\n" +
    "                     uib-btn-radio=\"'not'\">Not</label>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-sm-12\">\n" +
    "            <yes-no-button label=\"'Do you belong to a PPO or HMO?'\"\n" +
    "                           ng-model=\"patient.isHmoPpo\">\n" +
    "            </yes-no-button>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-sm-2\">\n" +
    "            <label>Marital Status</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-10\">\n" +
    "            <div class=\"btn-group pull-right\">\n" +
    "              <label class=\"btn btn-default\"\n" +
    "                     ng-model=\"patient.maritalStatus\"\n" +
    "                     uib-btn-radio=\"'married'\">Married</label>\n" +
    "              <label class=\"btn btn-default\"\n" +
    "                     ng-model=\"patient.maritalStatus\"\n" +
    "                     uib-btn-radio=\"'divorced'\">Divorced</label>\n" +
    "              <label class=\"btn btn-default\"\n" +
    "                     ng-model=\"patient.maritalStatus\"\n" +
    "                     uib-btn-radio=\"'widow'\">Widow</label>\n" +
    "              <label class=\"btn btn-default\"\n" +
    "                     ng-model=\"patient.maritalStatus\"\n" +
    "                     uib-btn-radio=\"'single'\">Single</label>\n" +
    "              <label class=\"btn btn-default\"\n" +
    "                     ng-model=\"patient.maritalStatus\"\n" +
    "                     uib-btn-radio=\"'separated'\">Legally Separated</label>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "      </div>  <!-- end panel body -->\n" +
    "    </div>  <!-- end panel element -->\n" +
    "")

$templateCache.put("src/common/patient-form-insurance-primary.partial.tpl.html","<div class=\"panel-heading\">\n" +
    "  <h4 id=\"patient-form-insurance-primary\" class=\"panel-title\">Primary Insurance Information</h4>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"panel-body\">\n" +
    "  <div class=\"form-horizontal\">\n" +
    "\n" +
    "    <div class=\"form-group\" ng-if=\"!isAnonymous()\">\n" +
    "      <label class=\"control-label col-sm-2\">Plan</label>\n" +
    "      <div class=\"col-sm-10\">\n" +
    "        <table style=\"table-layout:fixed; width:100%\">\n" +
    "          <tr>\n" +
    "            <td>\n" +
    "              <ui-select ng-model=\"patient.insuranceData.insurance_plan\" ng-change=\"onInsurancePlanChange(patient.id, patient.insuranceData,true,true)\">\n" +
    "                <ui-select-match placeholder=\"Select a Plan\">\n" +
    "                  <span style=\"text-overflow:ellipsis\">\n" +
    "                    {{ $select.selected.name }}\n" +
    "                    -\n" +
    "                    [{{ $select.selected.group_id }}]\n" +
    "                    -\n" +
    "                    <small class=\"text-muted\">{{ $select.selected.payer_name }}</small>\n" +
    "                  </span>\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                  group-by=\"'payer_name'\"\n" +
    "                  repeat=\"plan.id as plan in plans | filter: $select.search | orderBy: ['payer_name', 'name']\"\n" +
    "                  ui-disable-choice=\"plan.id == patient.otherInsuranceData.insurance_plan || plan.is_archived\">\n" +
    "                  <span style=\"text-overflow:ellipsis\">\n" +
    "                    {{ plan.name }}\n" +
    "                    -\n" +
    "                    [{{ plan.group_id }}]\n" +
    "                  </span>\n" +
    "                </ui-select-choices>\n" +
    "              </ui-select>\n" +
    "            </td>\n" +
    "            <td style=\"width:30px;\">\n" +
    "              <button class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" type=\"button\" style=\"height:34px; margin-left:-1px; margin-top: 1px;\" ng-click=\"patient.insuranceData.insurance_plan = null;onInsurancePlanChange(patient.id, patient.insuranceData,false,false);\" ng-disabled=\"patient.otherInsuranceData.insurance_plan\" >\n" +
    "                <i class=\"icomoon icon-icomoon-cross\"></i>\n" +
    "              </button>\n" +
    "            </td>\n" +
    "            <td style=\"width:30px;\">\n" +
    "              <button class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" type=\"button\" style=\"height:34px; margin-left:-2px; margin-top: 1px;\" ng-click=\"openAddPlan(patient.id, patient.insuranceData, feeprofiles, payers)\">\n" +
    "                <i class=\"icomoon icon-icomoon-plus2\"></i>\n" +
    "              </button>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </table>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\" ng-if=\"!isAnonymous()\">\n" +
    "      <div class=\"col-sm-10 col-sm-offset-2\" ng-attr-title=\"{{getDisplayForInsurancePlanTooltip(patient.insurancePlanInfo, patient.otherCoveragePlanInfo)}}\">\n" +
    "        <div class=\"alert alert-danger\"\n" +
    "          ng-show=\"practice.ins_eligibility_provider_id.trim().length > 0 && patient.insurancePlanInfo && !patient.insurancePlanInfo.payer_does_eligibility\">\n" +
    "          Primary insurance plan payer doesn't support electronic eligibility\n" +
    "        </div>\n" +
    "        <div class=\"alert alert-danger\"\n" +
    "          ng-show=\"practice.ins_eligibility_provider_id.trim().length == 0\">\n" +
    "          Not signed up for insurance eligibility checking\n" +
    "        </div>\n" +
    "        <button type=\"button\" class=\"btn btn-info\"\n" +
    "          ng-click=\"checkEligibility(patientId, false);\"\n" +
    "          ng-hide=\"practice.ins_eligibility_provider_id.trim().length == 0 || !patient.insurancePlanInfo || !patient.insurancePlanInfo.payer_does_eligibility\">\n" +
    "          Get eligibility report from primary insurance plan payer\n" +
    "        </button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "\n" +
    "        <label class=\"col-sm-2 control-label\">Type:</label>\n" +
    "        <div class=\"col-sm-10\">\n" +
    "          <label class=\"control-label\" ng-if=\"patient.insuranceData.insurance_type==='dental'\">Dental</label>\n" +
    "          <label class=\"control-label\" ng-if=\"patient.insuranceData.insurance_type==='medical'\">Medical</label>\n" +
    "          <label class=\"control-label\" ng-if=\"patient.insuranceData.insurance_type==='none'\">(Plan not set)</label>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\" ng-hide=\"isAnonymous()\">\n" +
    "        <label class=\"control-label control-label-bold\">Plan Maximums (in-network)\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Annual Maximum</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"primary-insurance-allowance\"\n" +
    "             ng-model=\"patient.insuranceData.max_annual.maximum.amount_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Annual Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-class=\"{ 'negative-currency' : patient.insuranceData.amount_remaining_innetwork < 0}\"\n" +
    "             name=\"primary-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.insuranceData.max_annual.amount_remaining_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Lifetime Maximum</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"primary-insurance-allowance\"\n" +
    "             ng-model=\"patient.insuranceData.max_lifetime.maximum.amount_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Lifetime Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-class=\"{ 'negative-currency' : patient.insuranceData.amount_remaining_innetwork < 0}\"\n" +
    "             name=\"primary-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.insuranceData.max_lifetime.amount_remaining_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-horizontal\">\n" +
    "    <div class=\"form-group\" ng-hide=\"isAnonymous()\">\n" +
    "      <label class=\"control-label control-label-bold\">Plan Maximums (out-network)</label>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Annual Maximum</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"primary-insurance-allowance\"\n" +
    "             ng-model=\"patient.insuranceData.max_annual.maximum.amount_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Annual Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-class=\"{ 'negative-currency' : patient.insuranceData.amount_remaining_outnetwork < 0}\"\n" +
    "             name=\"primary-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.insuranceData.max_annual.amount_remaining_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Lifetime Maximum</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"primary-insurance-allowance\"\n" +
    "             ng-model=\"patient.insuranceData.max_lifetime.maximum.amount_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Lifetime Remaining</label>\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-currency\n" +
    "               ng-class=\"{ 'negative-currency' : patient.insuranceData.amount_remaining_outnetwork < 0}\"\n" +
    "               name=\"primary-insurance-allowance-remaining\"\n" +
    "               ng-model=\"patient.insuranceData.max_lifetime.amount_remaining_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "  <div class=\"form-horizontal\">\n" +
    "    <div class=\"form-group\" ng-hide=\"isAnonymous()\">\n" +
    "      <label class=\"control-label control-label-bold\">Plan Deductibles (in-network)</label>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Preventative Deductible</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"primary-insurance-allowance\"\n" +
    "             ng-model=\"patient.insuranceData.ded_preventative.deductible.amount_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Preventative Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-class=\"{ 'negative-currency' : patient.insuranceData.amount_remaining_innetwork < 0}\"\n" +
    "             name=\"primary-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.insuranceData.ded_preventative.amount_remaining_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Basic Deductible</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"primary-insurance-allowance\"\n" +
    "             ng-model=\"patient.insuranceData.ded_basic.deductible.amount_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Basic Remaining</label>\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-currency\n" +
    "               ng-class=\"{ 'negative-currency' : patient.insuranceData.amount_remaining_innetwork < 0}\"\n" +
    "               name=\"primary-insurance-allowance-remaining\"\n" +
    "               ng-model=\"patient.insuranceData.ded_basic.amount_remaining_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Major Deductible</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"primary-insurance-allowance\"\n" +
    "             ng-model=\"patient.insuranceData.ded_major.deductible.amount_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Major Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-class=\"{ 'negative-currency' : patient.insuranceData.amount_remaining_innetwork < 0}\"\n" +
    "             name=\"primary-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.insuranceData.ded_major.amount_remaining_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Orthodontic Deductible</label>\n" +
    "      <div>\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-currency\n" +
    "               ng-disabled=\"true\"\n" +
    "               name=\"primary-insurance-allowance\"\n" +
    "               ng-model=\"patient.insuranceData.ded_orthodontic.deductible.amount_innetwork\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Orthodontic Remaining</label>\n" +
    "      <div>\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-currency\n" +
    "               ng-class=\"{ 'negative-currency' : patient.insuranceData.amount_remaining_innetwork < 0}\"\n" +
    "               name=\"primary-insurance-allowance-remaining\"\n" +
    "               ng-model=\"patient.insuranceData.ded_orthodontic.amount_remaining_innetwork\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <div class=\"form-horizontal\">\n" +
    "    <div class=\"form-group\" ng-hide=\"isAnonymous()\">\n" +
    "      <label class=\"control-label control-label-bold\" >Plan Deductibles (out-network)</label>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Preventative Deductible</label>\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-currency\n" +
    "               ng-disabled=\"true\"\n" +
    "               name=\"primary-insurance-allowance\"\n" +
    "               ng-model=\"patient.insuranceData.ded_preventative.deductible.amount_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Preventative Remaining</label>\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-currency\n" +
    "               ng-class=\"{ 'negative-currency' : patient.insuranceData.amount_remaining_outnetwork < 0}\"\n" +
    "               name=\"primary-insurance-allowance-remaining\"\n" +
    "               ng-model=\"patient.insuranceData.ded_preventative.amount_remaining_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Basic Deductible</label>\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-currency\n" +
    "               ng-disabled=\"true\"\n" +
    "               name=\"primary-insurance-allowance\"\n" +
    "               ng-model=\"patient.insuranceData.ded_basic.deductible.amount_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Basic Remaining</label>\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-currency\n" +
    "               ng-class=\"{ 'negative-currency' : patient.insuranceData.amount_remaining_outnetwork < 0}\"\n" +
    "               name=\"primary-insurance-allowance-remaining\"\n" +
    "               ng-model=\"patient.insuranceData.ded_basic.amount_remaining_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Major Deductible</label>\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-currency\n" +
    "               ng-disabled=\"true\"\n" +
    "               name=\"primary-insurance-allowance\"\n" +
    "               ng-model=\"patient.insuranceData.ded_major.deductible.amount_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Major Remaining</label>\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-currency\n" +
    "               ng-class=\"{ 'negative-currency' : patient.insuranceData.amount_remaining_outnetwork < 0}\"\n" +
    "               name=\"primary-insurance-allowance-remaining\"\n" +
    "               ng-model=\"patient.insuranceData.ded_major.amount_remaining_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Orthodontic Deductible</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"primary-insurance-allowance\"\n" +
    "             ng-model=\"patient.insuranceData.ded_orthodontic.deductible.amount_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Orthodontic Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-class=\"{ 'negative-currency' : patient.insuranceData.amount_remaining_outnetwork < 0}\"\n" +
    "             name=\"primary-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.insuranceData.ded_orthodontic.amount_remaining_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-horizontal\" style=\"margin-top:15px;\">\n" +
    "\n" +
    "  <div class=\"form-group\" ng-hide=\"isAnonymous()\">\n" +
    "    <label class=\"control-label col-sm-5\" >Plan Benefits Reset Date</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <custom-datepicker-popup show-placeholder=\"false\" ng-model=\"patient.insuranceData.amount_remaining_reset_date\" input-name=\"'PatientinsuranceAllowanceRemainingBirtInputDate'\" form-name=\"'updatePatient'\" format=\"'MM/dd/yyyy'\" ng-required=\"false\"/>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\" >Payer Company Name</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-readonly=\"!isAnonymous()\"\n" +
    "             name=\"primary-insurance-company-name\"\n" +
    "             ng-model=\"patient.insuranceData.insurance_company_name\"\n" +
    "             ng-change=\"planDataChange(patient.insuranceData)\"/>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\" >Payer Address</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-readonly=\"!isAnonymous()\"\n" +
    "             ng-disabled=\"!isAnonymous()\"\n" +
    "             name=\"primary-insurance-insurance-company-address-street-1\"\n" +
    "             ng-model=\"patient.insuranceData.insurance_company_address_street_1\"\n" +
    "             google-places-auto-complete\n" +
    "             ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "             placeholder=\"\"\n" +
    "             zip=\"patient.insuranceData.insurance_company_address_zipcode\"\n" +
    "             state=\"patient.insuranceData.insurance_company_address_state\"\n" +
    "             city=\"patient.insuranceData.insurance_company_address_city\"/>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group row\">\n" +
    "    <label class=\"control-label col-sm-5\" >Address Cont.</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-readonly=\"!isAnonymous()\"\n" +
    "             ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "             name=\"primary-insurance-insurance-company-address-street-2\"\n" +
    "             ng-model=\"patient.insuranceData.insurance_company_address_street_2\"/>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\" >City</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-readonly=\"!isAnonymous()\"\n" +
    "             ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "             name=\"primary-insurance-insurance-company-address-city\"\n" +
    "             ng-model=\"patient.insuranceData.insurance_company_address_city\"/>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >State</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <state-input ng-model=\"patient.insuranceData.insurance_company_address_state\"\n" +
    "                     ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "                     ng-readonly=\"!isAnonymous()\"\n" +
    "                     name=\"primary-insurance-insurance-company-address-state\"\n" +
    "                     ng-required=\"false\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Zip Code</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <zip-input name=\"primary-insurance-insurance-company-address-zipcode\"\n" +
    "                   ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "                   ng-readonly=\"!isAnonymous()\"\n" +
    "                   ng-model=\"patient.insuranceData.insurance_company_address_zipcode\"\n" +
    "                   ng-required=\"false\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\" >Payer Phone Number</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <phone-input ng-model=\"patient.insuranceData.insurance_company_phone\"\n" +
    "                   name=\"primary-insurance-insurance-company-address-phone\"\n" +
    "                   ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "                   ng-readonly=\"!isAnonymous()\"\n" +
    "                   ng-required=\"false\" />\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\" >Plan Name</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "             name=\"primary-insurance-plan-name\"\n" +
    "             ng-readonly=\"!isAnonymous()\"\n" +
    "             ng-model=\"patient.insuranceData.insurance_plan_name\"/>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\" >Group Number</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-readonly=\"!isAnonymous()\"\n" +
    "             ng-change=\"planDataChange(patient.insuranceData)\"\n" +
    "             name=\"primary-insurance-group-number\"\n" +
    "             ng-model=\"patient.insuranceData.insurance_group_id\"/>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\" >Subscriber ID</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             name=\"primary-insurance-policy-id-number\"\n" +
    "             ng-model=\"patient.insuranceData.insurance_subscriber_id\"/>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\" >Patient's Relationship to Subscriber</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <select class=\"form-control\" ng-model=\"patient.insuranceData.insurance_subscriber_party_relationship\" ng-change=\"fillInsuranceDataFromPatient(patient, 'insuranceData')\">\n" +
    "        <option value=\"\"></option>\n" +
    "        <option value=\"self\">Self</option>\n" +
    "        <option value=\"spouse\">Spouse</option>\n" +
    "        <option value=\"dependent\">Dependent</option>\n" +
    "        <option value=\"other\">Other</option>\n" +
    "      </select>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Subscriber First Name</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control input\"\n" +
    "             name=\"primary-insurance-insured-party-firstname\"\n" +
    "             ng-model=\"patient.insuranceData.insurance_subscriber_first_name\"\n" +
    "             />\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Subscriber Last Name</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control input\"\n" +
    "             name=\"primary-insurance-insured-party-lastname\"\n" +
    "             ng-model=\"patient.insuranceData.insurance_subscriber_last_name\"\n" +
    "      />\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "  <div class=\"form-group row\">\n" +
    "    <label class=\"control-label col-sm-5\">Subscriber Address</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             name=\"primary-insurance-insuranced-party-address-street-1\"\n" +
    "             ng-model=\"patient.insuranceData.insurance_subscriber_address_street_1\"\n" +
    "             google-places-auto-complete\n" +
    "             placeholder=\"\"\n" +
    "             zip=\"patient.insuranceData.insurance_subscriber_address_zipcode\"\n" +
    "             state=\"patient.insuranceData.insurance_subscriber_address_state\"\n" +
    "             city=\"patient.insuranceData.insurance_subscriber_address_city\"/>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group row\">\n" +
    "    <label class=\"control-label col-sm-5\" >Address Cont.</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             name=\"primary-insurance-insuranced-party-address-street-2\"\n" +
    "             ng-model=\"patient.insuranceData.insurance_subscriber_address_street_2\"/>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group row\">\n" +
    "    <label class=\"control-label col-sm-5\" >City</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             name=\"primary-insurance-insuranced-party-address-city\"\n" +
    "             ng-model=\"patient.insuranceData.insurance_subscriber_address_city\"/>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\" >State</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <state-input ng-model=\"patient.insuranceData.insurance_subscriber_address_state\"\n" +
    "                   name=\"primary-insurance-insuranced-party-address-state\"\n" +
    "                   ng-required=\"false\" />\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\" >Zip Code</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <zip-input name=\"primary-insurance-insuranced-party-address-zipcode\"\n" +
    "                 ng-model=\"patient.insuranceData.insurance_subscriber_address_zipcode\"\n" +
    "                 ng-required=\"false\" />\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\" >Patient's Subscriber Birthdate</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "        <custom-datepicker-popup show-placeholder=\"false\" ng-model=\"patient.insuranceData.insurance_subscriber_birth_date\" input-name=\"'PatientInsuranceSubscriberBirthDateInputDate'\" form-name=\"'updatePatient'\" format=\"'MM/dd/yyyy'\" ng-required=\"false\"/>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\" >Subscriber Gender</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <div class=\"btn-group\">\n" +
    "        <label class=\"btn btn-default\"\n" +
    "               ng-model=\"patient.insuranceData.insurance_subscriber_gender\"\n" +
    "               uib-btn-radio=\"'m'\">Male</label>\n" +
    "        <label class=\"btn btn-default\"\n" +
    "               ng-model=\"patient.insuranceData.insurance_subscriber_gender\"\n" +
    "               uib-btn-radio=\"'f'\">Female</label>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\" >Subscriber Phone</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <phone-input ng-model=\"patient.insuranceData.insurance_subscriber_phone\"\n" +
    "                   name=\"primary-insurance-insuranced-party-phone\"\n" +
    "                   ng-required=\"false\" />\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\" >\n" +
    "    <label class=\"control-label col-sm-5\" >Subscriber SSN#</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <ssn-input ng-model=\"patient.insuranceData.insurance_subscriber_ssn\"\n" +
    "                 name=\"'primary-insurance-insuranced-party-ssn'\"\n" +
    "                 ng-required=\"false\" >\n" +
    "      </ssn-input>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\" >Subscriber Employer/Business Name</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-model=\"patient.insuranceData.insurance_subscriber_employer_name\"/>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/patient-form-insurance-secondary.partial.tpl.html","<div class=\"panel-heading\">\n" +
    "  <h4 class=\"panel-title\">Secondary Insurance Information</h4>\n" +
    "</div>\n" +
    "<div class=\"panel-body\">\n" +
    "\n" +
    "\n" +
    "  <div class=\"form-horizontal\">\n" +
    "    <div class=\"form-group\" ng-if=\"!isAnonymous()\">\n" +
    "      <label class=\"control-label col-sm-2\">Plan</label>\n" +
    "      <div class=\"col-sm-10\">\n" +
    "        <table style=\"table-layout:fixed; width:100%\">\n" +
    "          <tr>\n" +
    "            <td>\n" +
    "              <ui-select ng-model=\"patient.otherInsuranceData.insurance_plan\" ng-change=\"onInsurancePlanChange(patient.id, patient.otherInsuranceData,true,true)\" ng-disabled=\"!patient.insuranceData.insurance_plan\">\n" +
    "                <ui-select-match placeholder=\"Select a Secondary Plan\">\n" +
    "                  <span style=\"text-overflow:ellipsis\">\n" +
    "                    {{ $select.selected.name }}\n" +
    "                    -\n" +
    "                    [{{ $select.selected.group_id }}]\n" +
    "                    -\n" +
    "                    <small class=\"text-muted\">{{ $select.selected.payer_name }}</small>\n" +
    "                  </span>\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                  group-by=\"'payer_name'\"\n" +
    "                  repeat=\"plan.id as plan in plans | filter: $select.search | orderBy: ['payer_name', 'name']\"\n" +
    "                  ui-disable-choice=\"plan.id == patient.insuranceData.insurance_plan || plan.is_archived\">\n" +
    "                  <span style=\"text-overflow:ellipsis\">\n" +
    "                    {{ plan.name }}\n" +
    "                    -\n" +
    "                    [{{ plan.group_id }}]\n" +
    "                  </span>\n" +
    "                </ui-select-choices>\n" +
    "              </ui-select>\n" +
    "            </td>\n" +
    "            <td style=\"width:30px;\">\n" +
    "              <button class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" type=\"button\" style=\"height:34px; margin-left:-1px; margin-top: 1px;\" ng-click=\"patient.otherInsuranceData.insurance_plan = null;onInsurancePlanChange(patient.id, patient.otherInsuranceData,false,false);\">\n" +
    "                <i class=\"icomoon icon-icomoon-cross\"></i>\n" +
    "              </button>\n" +
    "            </td>\n" +
    "            <td style=\"width:30px;\">\n" +
    "              <button class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" type=\"button\" style=\"height:34px; margin-left:-2px; margin-top: 1px;\" ng-click=\"openAddPlan(patient.id, patient.otherInsuranceData, feeprofiles, payers)\">\n" +
    "                <i class=\"icomoon icon-icomoon-plus2\"></i>\n" +
    "              </button>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </table>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\" ng-if=\"!isAnonymous()\">\n" +
    "      <div class=\"col-sm-10 col-sm-offset-2\"  ng-attr-title=\"{{getDisplayForInsurancePlanTooltip(patient.insurancePlanInfo, patient.otherCoveragePlanInfo)}}\">\n" +
    "        <div class=\"alert alert-danger\" ng-show=\"practice.ins_eligibility_provider_id.trim().length > 0 && patient.otherCoveragePlanInfo && !patient.otherCoveragePlanInfo.payer_does_eligibility\">\n" +
    "          Secondary insurance plan payer doesn't support electronic eligibility\n" +
    "        </div>\n" +
    "        <div class=\"alert alert-danger\" ng-show=\"practice.ins_eligibility_provider_id.trim().length == 0\">\n" +
    "          Not signed up for insurance eligibility checking\n" +
    "        </div>\n" +
    "        <button type=\"button\" class=\"btn btn-info\"\n" +
    "          ng-click=\"checkEligibility(patientId, true);\"\n" +
    "          ng-hide=\"practice.ins_eligibility_provider_id.trim().length == 0 || !patient.otherCoveragePlanInfo || !patient.otherCoveragePlanInfo.payer_does_eligibility\">\n" +
    "          Get eligibility report from secondary insurance plan payer\n" +
    "        </button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-2\">Type:</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <label class=\"control-label\" ng-if=\"patient.otherInsuranceData.insurance_type==='dental'\">Dental</label>\n" +
    "        <label class=\"control-label\" ng-if=\"patient.otherInsuranceData.insurance_type==='medical'\">Medical</label>\n" +
    "        <label class=\"control-label\" ng-if=\"patient.otherInsuranceData.insurance_type==='none'\">(Plan not set)</label>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\" ng-hide=\"isAnonymous()\">\n" +
    "      <label class=\"control-label control-label-bold\">Plan Maximums (in-network)\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Annual Maximum</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"other-insurance-allowance\"\n" +
    "             ng-model=\"patient.otherInsuranceData.max_annual.maximum.amount_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Annual Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             name=\"other-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.otherInsuranceData.max_annual.amount_remaining_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Lifetime Maximum</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"other-insurance-allowance\"\n" +
    "             ng-model=\"patient.otherInsuranceData.max_lifetime.maximum.amount_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Lifetime Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             name=\"other-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.otherInsuranceData.max_lifetime.amount_remaining_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <div class=\"form-horizontal\">\n" +
    "    <div class=\"form-group\" ng-hide=\"isAnonymous()\">\n" +
    "      <label class=\"control-label control-label-bold\">Plan Maximums (out-network)</label>\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Annual Maximum</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"other-insurance-allowance\"\n" +
    "             ng-model=\"patient.otherInsuranceData.max_annual.maximum.amount_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Annual Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             name=\"other-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.otherInsuranceData.max_annual.amount_remaining_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Lifetime</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"other-insurance-allowance\"\n" +
    "             ng-model=\"patient.otherInsuranceData.max_lifetime.maximum.amount_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Remaining</label>\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-currency\n" +
    "               name=\"other-insurance-allowance-remaining\"\n" +
    "               ng-model=\"patient.otherInsuranceData.max_lifetime.amount_remaining_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <div class=\"form-horizontal\">\n" +
    "    <div class=\"form-group\" ng-hide=\"isAnonymous()\">\n" +
    "      <label class=\"control-label control-label-bold\">Plan Deductibles (in-network)</label>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Preventative Deductible</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"other-insurance-allowance\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_preventative.deductible.amount_innetwork\"/>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Preventative Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             name=\"other-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_preventative.amount_remaining_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Basic Deductible</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"other-insurance-allowance\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_basic.deductible.amount_innetwork\"/>\n" +
    "    </div>\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             name=\"other-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_basic.amount_remaining_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Major Deductible</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"other-insurance-allowance\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_major.deductible.amount_innetwork\"/>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "        <label>Major Remaining</label>\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-currency\n" +
    "               name=\"other-insurance-allowance-remaining\"\n" +
    "               ng-model=\"patient.otherInsuranceData.ded_major.amount_remaining_innetwork\"/>\n" +
    "      </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Orthodontic Deductible</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"other-insurance-allowance\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_orthodontic.deductible.amount_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Orthodontic Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             name=\"other-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_orthodontic.amount_remaining_innetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "  <div class=\"form-horizontal\">\n" +
    "    <div class=\"form-group\" ng-hide=\"isAnonymous()\">\n" +
    "      <label class=\"control-label control-label-bold\">Plan Deductibles (out-network)</label>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Preventative Deductible</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"other-insurance-allowance\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_preventative.deductible.amount_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Preventative Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             name=\"other-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_preventative.amount_remaining_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Basic Deductible</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"other-insurance-allowance\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_basic.deductible.amount_outnetwork\"/>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Basic Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             name=\"other-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_basic.amount_remaining_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Major Deductible</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"other-insurance-allowance\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_major.deductible.amount_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Major Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             name=\"other-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_major.amount_remaining_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Orthodontic Deductible</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             ng-disabled=\"true\"\n" +
    "             name=\"other-insurance-allowance\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_orthodontic.deductible.amount_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-sm-6\" ng-hide=\"isAnonymous()\">\n" +
    "      <label>Orthodontic Remaining</label>\n" +
    "      <input type=\"text\"\n" +
    "             class=\"form-control\"\n" +
    "             ng-currency\n" +
    "             name=\"other-insurance-allowance-remaining\"\n" +
    "             ng-model=\"patient.otherInsuranceData.ded_orthodontic.amount_remaining_outnetwork\"/>\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "  <div class=\"form-horizontal\" style=\"margin-top:15px;\">\n" +
    "\n" +
    "    <div class=\"form-group\" ng-hide=\"isAnonymous()\">\n" +
    "      <label class=\"control-label col-sm-5\">Plan Benefits Reset Date</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <custom-datepicker-popup show-placeholder=\"false\" ng-model=\"patient.otherInsuranceData.amount_remaining_reset_date\" input-name=\"'PatientinsuranceAllowanceRemainingBirtInputDate'\" form-name=\"'updatePatient'\" format=\"'MM/dd/yyyy'\" ng-required=\"false\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Payer Company Name</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-readonly=\"!isAnonymous()\"\n" +
    "               ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "               name=\"secondary-insurance-company-name\"\n" +
    "               ng-model=\"patient.otherInsuranceData.insurance_company_name\"\n" +
    "               ng-change=\"companyChangeSecondaryInsurance()\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Payer Address</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-readonly=\"!isAnonymous()\"\n" +
    "               name=\"secondary-insurance-insurance-company-address-street-1\"\n" +
    "               ng-model=\"patient.otherInsuranceData.insurance_company_address_street_1\"\n" +
    "               google-places-auto-complete\n" +
    "               placeholder=\"\"\n" +
    "               ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "               zip=\"patient.otherInsuranceData.insurance_company_address_zipcode\"\n" +
    "               state=\"patient.otherInsuranceData.insurance_company_address_state\"\n" +
    "               city=\"patient.otherInsuranceData.insurance_company_address_city\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Address Cont.</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-readonly=\"!isAnonymous()\"\n" +
    "               ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "               name=\"secondary-insurance-insurance-company-address-street-2\"\n" +
    "               ng-model=\"patient.otherInsuranceData.insurance_company_address_2\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >City</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-readonly=\"!isAnonymous()\"\n" +
    "               ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "               name=\"secondary-insurance-insurance-company-address-city\"\n" +
    "               ng-model=\"patient.otherInsuranceData.insurance_company_address_city\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >State</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <state-input ng-model=\"patient.otherInsuranceData.insurance_company_address_state\"\n" +
    "                     name=\"secondary-insurance-insurance-company-address-state\"\n" +
    "                     ng-readonly=\"!isAnonymous()\"\n" +
    "                     ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "                     ng-required=\"false\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Zip Code</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <zip-input name=\"secondary-insurance-insurance-company-address-zipcode\"\n" +
    "                   ng-model=\"patient.otherInsuranceData.insurance_company_address_zipcode\"\n" +
    "                   ng-readonly=\"!isAnonymous()\"\n" +
    "                   ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "                   ng-required=\"false\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Payer Phone Number</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <phone-input ng-model=\"patient.otherInsuranceData.insurance_company_phone\"\n" +
    "                     name=\"secondary-insurance-insurance-company-address-phone\"\n" +
    "                     ng-readonly=\"!isAnonymous()\"\n" +
    "                     ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "                     ng-required=\"false\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Plan Name</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-readonly=\"!isAnonymous()\"\n" +
    "               ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "               name=\"secondary-insurance-plan-name\"\n" +
    "               ng-model=\"patient.otherInsuranceData.insurance_plan_name\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Group Number</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               ng-readonly=\"!isAnonymous()\"\n" +
    "               ng-change=\"planDataChange(patient.otherInsuranceData)\"\n" +
    "               name=\"secondary-insurance-group-number\"\n" +
    "               ng-model=\"patient.otherInsuranceData.insurance_group_id\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Subscriber ID</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"secondary-insurance-policy-id-number\"\n" +
    "               ng-model=\"patient.otherInsuranceData.insurance_subscriber_id\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Patient's relationship to subscriber</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <select class=\"form-control\" ng-model=\"patient.otherInsuranceData.insurance_subscriber_party_relationship\" ng-change=\"fillInsuranceDataFromPatient(patient, 'otherInsuranceData')\">\n" +
    "          <option value=\"\"></option>\n" +
    "          <option value=\"self\">Self</option>\n" +
    "          <option value=\"spouse\">Spouse</option>\n" +
    "          <option value=\"dependent\">Dependent</option>\n" +
    "          <option value=\"other\">Other</option>\n" +
    "        </select>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Subscriber First Name</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control input\"\n" +
    "               name=\"secondary-insurance-insured-party-firstname\"\n" +
    "               ng-model=\"patient.otherInsuranceData.insurance_subscriber_first_name\"\n" +
    "        />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\" >\n" +
    "      <label class=\"control-label col-sm-5\" >Subscriber Last Name</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control input\"\n" +
    "               name=\"secondary-insurance-insured-party-lastname\"\n" +
    "               ng-model=\"patient.otherInsuranceData.insurance_subscriber_last_name\"\n" +
    "        />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Subscriber Address</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"secondary-insurance-insuranced-party-address-street-1\"\n" +
    "               ng-model=\"patient.otherInsuranceData.insurance_subscriber_address_street_1\"\n" +
    "               google-places-auto-complete\n" +
    "               placeholder=\"\"\n" +
    "               zip=\"patient.otherInsuranceData.insurance_subscriber_address_zipcode\"\n" +
    "               state=\"patient.otherInsuranceData.insurance_subscriber_address_state\"\n" +
    "               city=\"patient.otherInsuranceData.insurance_subscriber_address_city\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Address Cont.</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"secondary-insurance-insuranced-party-address-street-2\"\n" +
    "               ng-model=\"patient.otherInsuranceData.insurance_subscriber_address_street_2\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >City</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"secondary-insurance-insuranced-party-address-city\"\n" +
    "               ng-model=\"patient.otherInsuranceData.insurance_subscriber_address_city\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >State</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <state-input ng-model=\"patient.otherInsuranceData.insurance_subscriber_address_state\"\n" +
    "                     name=\"secondary-insurance-insuranced-party-address-state\"\n" +
    "                     ng-required=\"false\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Zip Code</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <zip-input name=\"secondary-insurance-insuranced-party-address-zipcode\"\n" +
    "                   ng-model=\"patient.otherInsuranceData.insurance_subscriber_address_zipcode\"\n" +
    "                   ng-required=\"false\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Patient's Subscriber Birthdate</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <custom-datepicker-popup show-placeholder=\"false\" ng-model=\"patient.otherInsuranceData.insurance_subscriber_birth_date\" input-name=\"'otherCoverageSubscriberBirthInputDate'\" form-name=\"'updatePatient'\" format=\"'MM/dd/yyyy'\" ng-required=\"false\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Subscriber Gender</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <div class=\"btn-group\">\n" +
    "          <label class=\"btn btn-default\"\n" +
    "                 ng-model=\"patient.otherInsuranceData.insurance_subscriber_gender\"\n" +
    "                 uib-btn-radio=\"'m'\">Male</label>\n" +
    "          <label class=\"btn btn-default\"\n" +
    "                 ng-model=\"patient.otherInsuranceData.insurance_subscriber_gender\"\n" +
    "                 uib-btn-radio=\"'f'\">Female</label>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Subscriber Phone</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <phone-input ng-model=\"patient.otherInsuranceData.insurance_subscriber_phone\"\n" +
    "                     name=\"secondary-insurance-insuranced-party-phone\"\n" +
    "                     ng-required=\"false\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Subscriber SSN#</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <ssn-input ng-model=\"patient.otherInsuranceData.insurance_subscriber_ssn\"\n" +
    "                   name=\"'secondary-insurance-insuranced-party-ssn'\"\n" +
    "                   ng-required=\"false\" >\n" +
    "        </ssn-input>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Subscriber Employer/Business Name</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <input type=\"text\"\n" +
    "              class=\"form-control\"\n" +
    "              ng-model=\"patient.otherInsuranceData.insurance_subscriber_employer_name\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/patient-form-insurance.partial.tpl.html","<div ng-if=\"!isAnonymous() || !patient.insuranceData.insurance_plan\">\n" +
    "<div class=\"panel-body\">\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"form-horizontal col-sm-6\">\n" +
    "\n" +
    "      <div class=\"form-group\">\n" +
    "        <label class=\"control-label col-sm-7 \">Do you have insurance?</label>\n" +
    "        <div class=\"col-sm-5\">\n" +
    "          <switch ng-model=\"patient.hasHealthInsurance\" class=\"small\"></switch>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <h3 class=\"help-block\" style=\"color: #e73d4a;\" ng-if=\"isAnonymous() && patient.hasHealthInsurance\" >\n" +
    "        Please complete as much of the following information as possible \n" +
    "        before clicking update. You may find some of this information on the back of your dental insurance card. \n" +
    "      </h3>\n" +
    "\n" +
    "      <div class=\"form-group\" ng-show=\"patient.hasHealthInsurance\" ng-if=\"!disablePrimaryInsurance\">\n" +
    "        <label class=\"col-sm-7 control-label\">Assign primary insurance benefits to billing provider?</label>\n" +
    "        <div class=\"col-sm-5\">\n" +
    "          <switch ng-model=\"patient.insuranceBillingProviderAssignedBenefits\" class=\"small\"></switch>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\" ng-show=\"patient.hasHealthInsurance\" ng-if=\"!disableOtherInsurance\">\n" +
    "        <label class=\"col-sm-7 control-label\">Assign secondary insurance benefits to billing provider?</label>\n" +
    "        <div class=\"col-sm-5\">\n" +
    "          <switch ng-model=\"patient.otherCoverageBillingProviderAssignedBenefits\" class=\"small\"></switch>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "<div class=\"form-group row\" ng-if=\"!isAnonymous()\" ng-show=\"patient.hasHealthInsurance\">\n" +
    "  <div class=\"col-sm-9\">\n" +
    "    <button type=\"button\" ng-click=\"showDataProvidedByPatient();\" class=\"btn btn-sm\" >View data provided by patient</button>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "<div class=\"form-group row\" ng-if=\"!isAnonymous() && patient.hasHealthInsurance && planSelectionChanged\">\n" +
    "  <div class=\"col-sm-11\">\n" +
    "    <label class=\"text-info\">Update to save patient and initialize maximums and deductibles. Reload to undo pending patient changes including plan change.\n" +
    "    </label>\n" +
    "  </div>\n" +
    "  <div class=\"btn-group\">\n" +
    "    <button type=\"button\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\" ng-click=\"triggerSubmit()\" ng-disabled=\"!canEditPatientInfo()\">\n" +
    "      <i class=\"icomoon icon-icomoon-circle-up6\" aria-hidden=\"true\"></i> Update\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn p-x btn-primary\" style=\"margin-left: 6px;\" ng-click=\"reset()\" ng-disabled=\"!canEditPatientInfo()\">\n" +
    "      <i class=\"icomoon icon-icomoon-circle-reset\" aria-hidden=\"true\"></i> Reload\n" +
    "\n" +
    "    </button>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "<div class=\"row\" ng-show=\"patient.hasHealthInsurance\">\n" +
    "  <div class=\"col-sm-6\">\n" +
    "    <div ng-include=\"'src/common/patient-form-insurance-primary.partial.tpl.html'\"></div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"col-sm-6\" ng-if=\"!isAnonymous() || !patient.otherInsuranceData.insurance_plan\">\n" +
    "    <div ng-include=\"'src/common/patient-form-insurance-secondary.partial.tpl.html'\"></div>\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "</div>\n" +
    "\n" +
    "<div ng-if=\"isAnonymous() && patient.insuranceData.insurance_plan\">\n" +
    "<h3 class=\"help-block\" style=\"color: #e73d4a;\" >\n" +
    "  To update or confirm your insurance information, please contact us by phone or in person.\n" +
    "</h3>\n" +
    "<div>\n" +
    "\n" +
    "")

$templateCache.put("src/common/patient-form-medical-history-conditions-1.partial.tpl.html","<div class=\"form-horizontal\">\n" +
    "\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Rheumatic fever</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isRheumticFever\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">High blood pressure</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isHighBloodPressure\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Low blood pressure</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isLowBloodPressure\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Mitral valve prolapse</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isMitralValveProlapse\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Heart murmur</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isHeartMurmur\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Chest pain/angina</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isChestPainAngina\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Heart attack(s)</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isHeartAttacks\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Irregular heart beat</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isIrregularHeartBeat\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Cardiac pacemaker</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isCardiacPacemaker\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Heart surgery</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isHeartSurgery\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Damaged heart valves</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isDamagedHeartValves\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Pneumonia, Bronchitis or Chronic Cough</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isPneumonia\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Chronic fatigue / night sweats</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isChronicFatigue\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Trouble climbing 1-2 flights of stairs</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isTroubleClimbingStairs\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Anemia</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isAnemia\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Asthma</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isAsthma\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Bleeding Tendency</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isBleedingTendency\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Blood Transfusion</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isBloodTransfusion\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Blood Disorder</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isBloodDisorder\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Bruise easily</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isBruiseEasily\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Eye disease / glaucoma</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isEyeDisease\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Jaundice / Liver disease</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isLiverDisease\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Hepatitis</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isHepatitis\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Gallbladder trouble</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isGallbladderTrouble\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Fainting spells</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isFaintingSpells\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Epilepsy or Seizures</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isConvulsionsEpilepsy\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Stroke</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isStroke\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Thyroid trouble</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isThyroidTrouble\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Diabetes</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isDiabetes\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Low blood sugar</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isLowBloodSugar\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Are you on dialysis?</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isOnDialysis\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "")

$templateCache.put("src/common/patient-form-medical-history-conditions-2.partial.tpl.html","<div class=\"form-horizontal\">\n" +
    "\n" +
    "\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">HIV</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isHiv\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">AIDS</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isAids\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Kidney trouble</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isKidneyTrouble\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Mental health problems</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isMentalHealthProblems\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Problems with immune system (possibly from med. / surg.)</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isImmuneSystemProblems\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Delay in healing</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isDelayInHealing\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Hay fever / Sinus problems</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isSinusProblems\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Snoring</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isSnoring\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Sleep Apnea / CPAP</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isSleepApnea\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Respiratory problems</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isRespiratoryProblems\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Tuberculosis</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isTuberculosis\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Emphysema</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isEmphysema\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Do you smoke?</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isSmoker\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\" ng-show=\"\">\n" +
    "    <label class=\"control-label col-sm-5\">If so, number of cigarettes a day</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <input type=\"number\"\n" +
    "             min=\"0\"\n" +
    "             max=\"9\"\n" +
    "             class=\"form-control\"\n" +
    "             name=\"patient-packs-of-smokes\"\n" +
    "             ng-model=\"patient.packPerDaySmokes\"/>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Do you use chewing tobacco?</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isUsingChewingTobacco\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">A history of drug abuse</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isHasDrugAbuseHistory\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">A history of alcohol abuse</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isHasAlcoholAbuseHistory\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Abnormal bleeding</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isAbnormalBleeding\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Sexually transmitted disease</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isSexuallyTransmittedDisease\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Contagious diseases</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isContagiousDiseases\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Infectious mononucleosis</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isInfectiousMononucleosis\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Swollen Ankles</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isSwollenAnkles\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Arthritis / Joint disease</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isArthritis\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Prosthetic implant</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isProstheticImplant\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Joint replacement</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isJointReplacement\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Osteoporosis / osteopenia</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isOsteoporosis\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Osteonecrosis</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isOsteonecrosis\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Stomach ulcers</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isStomachUlcers\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Tumor or growth</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isTumorGrowth\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Cancer / Radiation / Chemotherapy</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isCancer\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Are you on a diet?</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isOnDiet\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Pregnancy</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isPregnant\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/patient-form-medical-history.partial.tpl.html","<h3 id=\"patient-form-medical-history\">Medical History</h3>\n" +
    "\n" +
    "<div class=\"panel panel-default\">\n" +
    "  <div class=\"panel-heading\">\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <h3 class=\"panel-title\">Medical History</h3>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <div class=\"btn-group pull-right\">\n" +
    "          <span class=\"btn btn-default\" ng-model=\"patient.markAllMedicalHistory\" uib-btn-radio=\"true\">MARK ALL YES</span>\n" +
    "          <span class=\"btn btn-default\" ng-model=\"patient.markAllMedicalHistory\" uib-btn-radio=\"false\">MARK ALL NO</span>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"panel-body\">\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "\n" +
    "      <div class=\"form-horizontal col-sm-6\">\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"control-label col-sm-5\">Doctor First Name</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <input type=\"text\"\n" +
    "                   class=\"form-control\"\n" +
    "                   name=\"physician-first\"\n" +
    "                   ng-model=\"patient.physicianFirstName\"\n" +
    "                   placeholder=\"First name\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"control-label col-sm-5\">Doctor Last Name</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <input type=\"text\"\n" +
    "                   class=\"form-control\"\n" +
    "                   name=\"physician-last\"\n" +
    "                   ng-model=\"patient.physicianLastName\"\n" +
    "                   placeholder=\"Last name\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"control-label col-sm-5\">Are you in good health?</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <switch ng-model=\"patient.isInGoodHealth\" class=\"small\"></switch>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"control-label col-sm-5\">Are you under the care of a physician?</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <switch ng-model=\"patient.isUnderPhysicianCare\" class=\"small\"></switch>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"control-label col-sm-5\">Has a physician or previous dentist recommended that you take antibiotics prior to your dental treatment?</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <switch ng-model=\"patient.isRecommendedAntibiotics\" class=\"small\"></switch>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "\n" +
    "      <div class=\"form-horizontal col-sm-6\">\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"control-label col-sm-5\">Have you had any illness, operation or been hospitalized in the past five years?</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <switch ng-model=\"patient.isHospitalizedPastFiveYears\" class=\"small\"></switch>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"control-label col-sm-5\">Have you ever had general anesthesia?</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <switch ng-model=\"patient.isHadGeneralAnesthesia\" class=\"small\"></switch>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"control-label col-sm-5\">Have you, or a family member, had any unusual or serious reactions to general anesthesia?</label>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "            <switch ng-model=\"patient.isHadReactionAnesthesia\" class=\"small\"></switch>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "<div class=\"form-group row\">\n" +
    "  <div class=\"col-sm-6\">\n" +
    "    <div class=\"panel panel-default\">\n" +
    "      <div class=\"panel-heading\">\n" +
    "        <div class=\"row\">\n" +
    "\n" +
    "          <div class=\"col-sm-5\">\n" +
    "          <h4 class=\"panel-title\">Have you had or do you currently have...</h4>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "\n" +
    "            <div class=\"btn-group pull-right\">\n" +
    "              <span class=\"btn btn-default\" ng-model=\"patient.markAllMedicalHistoryConditions1\" uib-btn-radio=\"true\">MARK ALL YES</span>\n" +
    "              <span class=\"btn btn-default\" ng-model=\"patient.markAllMedicalHistoryConditions1\" uib-btn-radio=\"false\">MARK ALL NO</span>\n" +
    "            </div>\n" +
    "\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"panel-body\"\n" +
    "           ng-include=\"'src/common/patient-form-medical-history-conditions-1.partial.tpl.html'\">\n" +
    "\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"col-sm-6\">\n" +
    "    <div class=\"panel panel-default\">\n" +
    "      <div class=\"panel-heading\">\n" +
    "        <div class=\"row\">\n" +
    "\n" +
    "          <div class=\"col-sm-5\">\n" +
    "            <h4 class=\"panel-title\">Have you had or do you currently have...</h4>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "\n" +
    "            <div class=\"btn-group pull-right\">\n" +
    "              <span class=\"btn btn-default\" ng-model=\"patient.markAllMedicalHistoryConditions2\" uib-btn-radio=\"true\">MARK ALL YES</span>\n" +
    "              <span class=\"btn btn-default\" ng-model=\"patient.markAllMedicalHistoryConditions2\" uib-btn-radio=\"false\">MARK ALL NO</span>\n" +
    "            </div>\n" +
    "\n" +
    "          </div>\n" +
    "\n" +
    "      </div>\n" +
    "        </div>\n" +
    "      <div class=\"panel-body\"\n" +
    "           ng-include=\"'src/common/patient-form-medical-history-conditions-2.partial.tpl.html'\">\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "")

$templateCache.put("src/common/patient-form-medications-allergies-1.partial.tpl.html","<div class=\"form-horizontal\">\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Nerve pills</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isTakingNervePills\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Diet Pills</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <switch ng-model=\"patient.isTakingDietPills\" class=\"small\"></switch>\n" +
    "      </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Tranquilizers</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isTakingTranquilizers\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Muscle relaxers</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isTakingMuscleRelaxers\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Insulin</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isTakingInsulin\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Stimulants</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isTakingStimulants\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Antidepressants</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isTakingAntidepressants\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Blood thinners (Coumadin, Aspirin)</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isTakingBloodThinners\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Are you taking, or have you ever taken, any bone density meds. or bisphosphonates, such as Fosamax, Boniva, Actonel, IV Zometa, Reclast, Xgeva, Prolia, or Aredia within the past 12 years.</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isTakingBoneDensityMeds\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Antibiotic</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isTakingAntibiotic\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Sodium pentothal, Valium, or other tranquilizers</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isTakingValium\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/patient-form-medications-allergies-2.partial.tpl.html","\n" +
    "<div class=\"form-horizontal\">\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5\">Do you have any known allergies?</label>\n" +
    "    <div class=\"col-sm-7\">\n" +
    "      <switch ng-model=\"patient.isHasKnownAllergies\" class=\"small\"></switch>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div ng-show=\"patient.isHasKnownAllergies || settings\">\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Soy</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <switch ng-model=\"patient.isAllergicToSoy\" class=\"small\"></switch>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Sulfa Drugs</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <switch ng-model=\"patient.isAllergicToSulfaDrugs\" class=\"small\"></switch>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Aspirin</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <switch ng-model=\"patient.isAllergicToAspirin\" class=\"small\"></switch>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Eggs/Yolk</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <switch ng-model=\"patient.isAllergicToEggs\" class=\"small\"></switch>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Local anesthetic (numbing medication)</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <switch ng-model=\"patient.isAllergicToLocalAnesthetic\" class=\"small\"></switch>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Codeine or other narcotics</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <switch ng-model=\"patient.isAllergicToCodeine\" class=\"small\"></switch>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Sulfites</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <switch ng-model=\"patient.isAllergicToSulfites\" class=\"small\"></switch>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Amoxicillin</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <switch ng-model=\"patient.isAllergicToAmoxicillin\" class=\"small\"></switch>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\">Latex</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <switch ng-model=\"patient.isAllergicToLatex\" class=\"small\"></switch>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\" ng-hide=\"settings\">\n" +
    "      <label class=\"control-label col-sm-5\">Please list any allergies other than drug allergies</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"patient-allergies\"\n" +
    "               ng-model=\"patient.nonDrugAllergies\"\n" +
    "               placeholder=\"List of known non-drug allergies\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "")

$templateCache.put("src/common/patient-form-medications-allergies.partial.tpl.html","<h3 id=\"patient-form-medications-allergies\">Medications / Allergies</h3>\n" +
    "\n" +
    "<yes-no-button label=\"'Are you taking any kind of medication, drugs or pills?'\"\n" +
    "               ng-model=\"patient.isTakingMedication\">\n" +
    "</yes-no-button>\n" +
    "\n" +
    "<div class=\"form-group row\">\n" +
    "  <div class=\"col-sm-6\">\n" +
    "    <div class=\"panel panel-default\">\n" +
    "      <div class=\"panel-heading\">\n" +
    "        <div class=\"row\">\n" +
    "\n" +
    "          <div class=\"col-sm-5\">\n" +
    "            <h4 class=\"panel-title\">Are you now taking:</h4>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "\n" +
    "            <div class=\"btn-group pull-right\">\n" +
    "              <span class=\"btn btn-default\" ng-model=\"patient.markAllMedicationTaking\" uib-btn-radio=\"true\">MARK ALL YES</span>\n" +
    "              <span class=\"btn btn-default\" ng-model=\"patient.markAllMedicationTaking\" uib-btn-radio=\"false\">MARK ALL NO</span>\n" +
    "            </div>\n" +
    "\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "      <div class=\"panel-body\"\n" +
    "           ng-include=\"'src/common/patient-form-medications-allergies-1.partial.tpl.html'\">\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"col-sm-6\">\n" +
    "    <div class=\"panel panel-default\">\n" +
    "      <div class=\"panel-heading\">\n" +
    "        <div class=\"row\">\n" +
    "\n" +
    "          <div class=\"col-sm-5\">\n" +
    "            <h4 class=\"panel-title\">Are you allergic or had a reaction to:</h4>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-7\">\n" +
    "\n" +
    "            <div class=\"btn-group pull-right\">\n" +
    "              <span class=\"btn btn-default\" ng-model=\"patient.markAllAllergic\" uib-btn-radio=\"true\">MARK ALL YES</span>\n" +
    "              <span class=\"btn btn-default\" ng-model=\"patient.markAllAllergic\" uib-btn-radio=\"false\">MARK ALL NO</span>\n" +
    "            </div>\n" +
    "\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"panel-body\"\n" +
    "           ng-include=\"'src/common/patient-form-medications-allergies-2.partial.tpl.html'\">\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group row\">\n" +
    "  <div class=\"col-sm-12\">\n" +
    "    <div class=\"panel panel-default\">\n" +
    "      <div class=\"panel-heading\">\n" +
    "        <h4 class=\"panel-title\">Preferred Pharmacy</h4>\n" +
    "      </div>\n" +
    "      <div class=\"panel-body\">\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"col-sm-6 form-group\">\n" +
    "            <label>Preferred Pharmacy Name</label>\n" +
    "            <input type=\"text\" class=\"form-control\" name=\"preferred-pharmacy-name\" ng-model=\"patient.preferredPharmacyName\"/>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-6 form-group\">\n" +
    "            <label>Preferred Pharmacy Location</label>\n" +
    "            <input type=\"text\" class=\"form-control\" name=\"preferred-pharmacy-location\" ng-model=\"patient.preferredPharmacyLocation\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group row\">\n" +
    "  <div class=\"col-sm-12\">\n" +
    "    <div class=\"panel panel-default\">\n" +
    "      <div class=\"panel-heading\">\n" +
    "        <h4 class=\"panel-title\">Please list any medications/antibiotics you are taking and/or bring a list of your medications to the appointment</h4>\n" +
    "      </div>\n" +
    "      <div class=\"panel-body\">\n" +
    "        <!-- The following ng-repeat loop using $index to create ngModel names on the fly.\n" +
    "             For each loop, it creates to inputs, which is why $index is multipled by 2 -->\n" +
    "        <div class=\"row\" ng-repeat=\"i in getNumber(5) track by $index\">\n" +
    "\n" +
    "          <div class=\"col-sm-6 form-group\">\n" +
    "            <label>Medication / Antibiotic name</label>\n" +
    "            <input type=\"text\"\n" +
    "                   class=\"form-control\"\n" +
    "                   name=\"patient-taking-medication-{{$index * 2}}\"\n" +
    "                   ng-model=\"patient['takingMedication' + $index * 2]\"/>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"col-sm-6 form-group\">\n" +
    "            <label>Medication / Antibiotic name</label>\n" +
    "            <input type=\"text\"\n" +
    "                   class=\"form-control\"\n" +
    "                   name=\"patient-taking-medication-{{$index * 2 + 1}}\"\n" +
    "                   ng-model=\"patient['takingMedication' + ($index * 2 + 1)]\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "")

$templateCache.put("src/common/patient-form-responsible.partial.tpl.html","\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"form-horizontal col-sm-8\">\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5\" >Who will be responsible for your account?</label>\n" +
    "      <div class=\"btn-group col-sm-7\">\n" +
    "        <label class=\"btn btn-default\"\n" +
    "               ng-model=\"patient.responsibleParty\"\n" +
    "               uib-btn-radio=\"'father'\">Father</label>\n" +
    "        <label class=\"btn btn-default\"\n" +
    "               ng-model=\"patient.responsibleParty\"\n" +
    "               uib-btn-radio=\"'mother'\">Mother</label>\n" +
    "        <label class=\"btn btn-default\"\n" +
    "               ng-model=\"patient.responsibleParty\"\n" +
    "               uib-btn-radio=\"'spouse'\">Spouse</label>\n" +
    "        <label class=\"btn btn-default\"\n" +
    "               ng-model=\"patient.responsibleParty\"\n" +
    "               uib-btn-radio=\"'self'\">Self</label>\n" +
    "        <label class=\"btn btn-default\"\n" +
    "               ng-model=\"patient.responsibleParty\"\n" +
    "               uib-btn-radio=\"'other'\">Other</label>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "<div ng-show=\"patient.responsibleParty !== 'self'\" class=\"panel panel-default\">\n" +
    "      <div class=\"panel-heading\">\n" +
    "        <h4 class=\"panel-title\">Financial Responsibility</h4>\n" +
    "      </div>\n" +
    "      <div class=\"panel-body\">\n" +
    "\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"form-horizontal col-sm-6\">\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">First Name</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control\"\n" +
    "                       name=\"physician-first\"\n" +
    "                       ng-model=\"patient.responsibleFirstName\"/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Last Name</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control\"\n" +
    "                       name=\"physician-last\"\n" +
    "                       ng-model=\"patient.responsibleLastName\"/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">SSN#</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <ssn-input ng-model=\"patient.responsibleSsn\"\n" +
    "                           name=\"'responsible-ssn'\"\n" +
    "                           ng-required=\"false\" />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Birthdate</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <custom-datepicker-popup show-placeholder=\"false\" ng-model=\"patient.responsibleBirthDate\" input-name=\"'responsibleBirthDateInputDate'\" form-name=\"'updatePatient'\" format=\"'MM/dd/yyyy'\" ng-required=\"false\"/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Phone</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <phone-input ng-model=\"patient.responsiblePhone\"\n" +
    "                             name=\"responsible-home-phone\"\n" +
    "                             ng-required=\"false\" />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Drivers License</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control\"\n" +
    "                       name=\"responsible-drivers-license\"\n" +
    "                       ng-model=\"patient.responsibleDriversLicenseNumber\"/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-horizontal col-sm-6\">\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Address</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control\"\n" +
    "                       name=\"patient-responsible-address-street-1\"\n" +
    "                       ng-model=\"patient.responsibleAddressStreet1\"\n" +
    "                       google-places-auto-complete\n" +
    "                       zip=\"patient.responsibleAddressZipCode\"\n" +
    "                       state=\"patient.responsibleAddressState\"\n" +
    "                       placeholder=\"\"\n" +
    "                       city=\"patient.responsibleAddressCity\"/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Address cont.</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control\"\n" +
    "                       name=\"patient-responsible-address-street-2\"\n" +
    "                       ng-model=\"patient.responsibleAddressStreet2\"/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">City</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control\"\n" +
    "                       name=\"patient-responsible-address-city\"\n" +
    "                       ng-model=\"patient.responsibleAddressCity\"/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">State</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <state-input ng-model=\"patient.responsibleAddressState\"\n" +
    "                             name=\"responsible-address-state\"\n" +
    "                             ng-required=\"false\" />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Zip Code</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <zip-input name=\"responsible-address-zip\"\n" +
    "                           ng-model=\"patient.responsibleAddressZipCode\"\n" +
    "                           ng-required=\"false\" />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Employer Name</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control\"\n" +
    "                       ng-model=\"patient.responsibleEmployer\"/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Employer Phone</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <phone-input ng-model=\"patient.responsibleEmployerPhone\"\n" +
    "                             name=\"responsible-employer-phone\"\n" +
    "                             ng-required=\"false\" />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Employer/Business</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control\"\n" +
    "                       ng-model=\"patient.guarantorEmployer\"/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "      </div>  <!-- end panel body -->\n" +
    "    </div>  <!-- end panel element -->\n" +
    "\n" +
    "\n" +
    "<div class=\"panel panel-default\">\n" +
    "\n" +
    "      <div class=\"panel-heading\">\n" +
    "        <h4 class=\"panel-title\">Spouse or other guarantor information (if different from above)</h4>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"panel-body\">\n" +
    "\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"form-horizontal col-sm-6\">\n" +
    "\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">First Name</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control input\"\n" +
    "                       name=\"guarantor-firstname\"\n" +
    "                       ng-model=\"patient.guarantorFirstName\"\n" +
    "                       placeholder=\"First Name\"\n" +
    "                />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                  <label class=\"control-label col-sm-5\">Last Name</label>\n" +
    "                  <div class=\"col-sm-7\">\n" +
    "                    <input type=\"text\"\n" +
    "                           class=\"form-control input\"\n" +
    "                           name=\"guarantor-lastname\"\n" +
    "                           ng-model=\"patient.guarantorLastName\"\n" +
    "                    />\n" +
    "                  </div>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Relationship</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control input\"\n" +
    "                       name=\"guarantor-relationship\"\n" +
    "                       ng-model=\"patient.guarantorRelationship\"\n" +
    "                />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Birthdate</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <custom-datepicker-popup show-placeholder=\"false\" ng-model=\"patient.guarantorBirthDate\" input-name=\"'guarantorBirthDateInputDate'\" form-name=\"'updatePatient'\" format=\"'MM/dd/yyyy'\" ng-required=\"false\"/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Social Security Number</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <ssn-input ng-model=\"patient.guarantorSsn\"\n" +
    "                           name=\"'guarantor-ssn'\"\n" +
    "                           ng-required=\"false\" />\n" +
    "                </ssn-input>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "            </div>\n" +
    "\n" +
    "          <div class=\"form-horizontal col-sm-6\">\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Address</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control\"\n" +
    "                       name=\"guarantor-address-street-1\"\n" +
    "                       ng-model=\"patient.guarantorAddressStreet1\"\n" +
    "                       google-places-auto-complete\n" +
    "                       zip=\"patient.guarantorAddressZipCode\"\n" +
    "                       state=\"patient.guarantorAddressState\"\n" +
    "                       city=\"patient.guarantorAddressCity\"\n" +
    "                       placeholder=\"\"/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Address cont.</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control\"\n" +
    "                       name=\"guarantor-address-street-2\"\n" +
    "                       ng-model=\"patient.guarantorAddressStreet2\"/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">City</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control\"\n" +
    "                       name=\"guarantor-address-city\"\n" +
    "                       ng-model=\"patient.guarantorAddressCity\"/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">State</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <state-input ng-model=\"patient.guarantorAddressState\"\n" +
    "                             name=\"guarantor-address-state\"\n" +
    "                             ng-required=\"false\" />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Zip Code</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <zip-input name=\"guarantor-address-zip\"\n" +
    "                           ng-model=\"patient.guarantorAddressZipCode\"\n" +
    "                           ng-required=\"false\" />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Home Phone</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <phone-input ng-model=\"patient.guarantorHomePhone\"\n" +
    "                             name=\"guarantor-home-phone\"\n" +
    "                             ng-required=\"false\" />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label col-sm-5\">Work Phone</label>\n" +
    "              <div class=\"col-sm-7\">\n" +
    "                <phone-input ng-model=\"patient.guarantorWorkPhone\"\n" +
    "                             name=\"guarantor-work-phone\"\n" +
    "                             ng-required=\"false\" />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "      </div>  <!-- end panel body -->\n" +
    "    </div>  <!-- end panel element -->\n" +
    "\n" +
    "")

$templateCache.put("src/common/patient-form-student.partial.tpl.html","\n" +
    "<div class=\"panel-body\">\n" +
    "<div class=\"row\">\n" +
    "\n" +
    "  <div class=\"form-horizontal col-sm-8\">\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"col-sm-5 control-label\"\">Are you a student?</label>\n" +
    "      <div class=\"col-sm-7\">\n" +
    "        <div class=\"btn-group\">\n" +
    "          <label class=\"btn btn-default\"\n" +
    "                 ng-model=\"patient.studentStatus\"\n" +
    "                 uib-btn-radio=\"'not_student'\">Not a student</label>\n" +
    "          <label class=\"btn btn-default\"\n" +
    "                 ng-model=\"patient.studentStatus\"\n" +
    "                 uib-btn-radio=\"'full_time'\">Full-Time</label>\n" +
    "          <label class=\"btn btn-default\"\n" +
    "                 ng-model=\"patient.studentStatus\"\n" +
    "                 uib-btn-radio=\"'part_time'\">Part-Time</label>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "<!--\n" +
    "<div ng-show=\"patient.studentStatus !== 'notstudent'\" class=\"panel panel-default\">\n" +
    "      <div class=\"panel-heading\">\n" +
    "        <h4 class=\"panel-title\">School Information</h4>\n" +
    "      </div>\n" +
    "      <div class=\"panel-body\">\n" +
    "\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-sm-2\">\n" +
    "            <label>School Name</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-10\">\n" +
    "            <input type=\"text\"\n" +
    "                   class=\"form-control\"\n" +
    "                   ng-model=\"patient.schoolName\"\n" +
    "                   placeholder=\"Name of School\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-sm-2\">\n" +
    "            <label>School Address</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-10 form-group\">\n" +
    "            <input type=\"text\"\n" +
    "                   class=\"form-control\"\n" +
    "                   name=\"school-address-street-1\"\n" +
    "                   ng-model=\"patient.schoolAddressStreet1\"\n" +
    "                   placeholder=\"Street Address\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-sm-2\">\n" +
    "            <label>School Address cont.</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-10 form-group\">\n" +
    "            <input type=\"text\"\n" +
    "                   class=\"form-control\"\n" +
    "                   name=\"school-address-street-1\"\n" +
    "                   ng-model=\"patient.schoolAddressStreet2\"\n" +
    "                   placeholder=\"Apt\"/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-sm-2\">\n" +
    "            <label>City, State, Zip</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-2 form-group\">\n" +
    "            <input type=\"text\"\n" +
    "                   class=\"form-control\"\n" +
    "                   name=\"school-address-city\"\n" +
    "                   ng-model=\"patient.schoolAddressCity\"\n" +
    "                   placeholder=\"City\"/>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"col-sm-2 form-group\">\n" +
    "            <state-input ng-model=\"patient.schoolAddressState\"\n" +
    "                         name=\"school-address-state\"\n" +
    "                         ng-required=\"false\" />\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"col-sm-2 form-group\">\n" +
    "            <zip-input name=\"school-address-zip\"\n" +
    "                       ng-model=\"patient.schoolAddressZipCode\"\n" +
    "                       ng-required=\"false\" />\n" +
    "          </div>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "    </div>\n" +
    "-->\n" +
    "")

$templateCache.put("src/common/patient-image-detail-encounter.tpl.html","\n" +
    "<div class=\"panel panel-default\" id=\"patient-image-detail-encounter\">\n" +
    "  <div class=\"panel-heading\">\n" +
    "    <span class=\"panel-title\">Images</span>\n" +
    "    <div class=\"pull-right\">\n" +
    "      <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.load()\" ng-disabled=\"!$ctrl.canList\">\n" +
    "        <i class=\"icomoon icon-icomoon-spinner11\"></i>\n" +
    "      </button>\n" +
    "      <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "        ng-if=\"$ctrl.demrservice_config.trim().length > 0 && $ctrl.patient\"\n" +
    "        ng-click=\"$ctrl.sendToDentalemrService($ctrl.demrservice_config, $ctrl.patient, $ctrl.encounterId)\"\n" +
    "        title=\"Invoke external program through DemrService.exe\"\n" +
    "      >\n" +
    "        <i class=\"icomoon icon-icomoon-power\"></i>\n" +
    "      </button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"panel-body\">\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"col-xs-12 col-sm-4 col-md-3 m-b\" ng-if=\"$ctrl.canCreate\">\n" +
    "        <div class=\"thumbnail thumbnail-patient-image\">\n" +
    "          <div class=\"image drop-box text-center\" style=\"line-height:200px; font-size:14px;\"\n" +
    "            ngf-accept=\"'.png,.jpg,.jpeg'\"\n" +
    "            ngf-allow-dir=\"true\"\n" +
    "            ngf-drag-over-class=\"'dragover'\"\n" +
    "            ngf-drop-available=\"dropAvailable\"\n" +
    "            ngf-hide-on-drop-not-available=\"true\"\n" +
    "            ngf-max-size=\"'700MB'\"\n" +
    "            ngf-multiple=\"true\"\n" +
    "            ngf-drop=\"$ctrl.create($files)\">\n" +
    "            Drop Images to Upload\n" +
    "          </div>\n" +
    "          <div class=\"caption text-center p-x-0 p-y-0\">\n" +
    "            <div class=\"btn-group btn-group-justified\" style=\"margin-top:4px\">\n" +
    "              <div class=\"btn-group\" role=\"group\" style=\"padding-right:2px\">\n" +
    "                <button type=\"button\" class=\"btn btn-info\" ng-click=\"$ctrl.scan()\">Acquire Images</button>\n" +
    "              </div>\n" +
    "              <div class=\"btn-group\" role=\"group\" style=\"padding-left:2px\">\n" +
    "                <label class=\"btn btn-file btn-info\">\n" +
    "                  Upload\n" +
    "                  <input type=\"file\"\n" +
    "                    ngf-accept=\"'.png,.jpg,.jpeg'\"\n" +
    "                    ngf-allow-dir=\"true\"\n" +
    "                    ngf-max-size=\"'700MB'\"\n" +
    "                    ngf-multiple=\"true\"\n" +
    "                    ngf-select=\"$ctrl.create($files)\">\n" +
    "                </label>\n" +
    "              </div>\n" +
    "              <!-- <div class=\"btn-group\" role=\"group\" style=\"padding-left:2px\">\n" +
    "                <button type=\"button\" class=\"btn btn-info\" ng-click=\"$ctrl.uploadStored()\">Stored</button>\n" +
    "              </div> -->\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"col-xs-12 col-sm-4 col-md-3 m-b\" ng-repeat=\"patient_image in $ctrl.patient_images\">\n" +
    "        <div class=\"thumbnail thumbnail-patient-image\" ng-click=\"$ctrl.edit(patient_image.id)\" style=\"cursor:pointer\">\n" +
    "          <wave-spinner class=\"modal-loader\" ng-show=\"patient_image.$loading\"></wave-spinner>\n" +
    "          <div class=\"image\" archive-thumb-preview url=\"patient_image.image_archive_thumb\">\n" +
    "            <div class=\"btn-group\" stop-click-propagation ng-show=\"patient_image.id\">\n" +
    "              <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.edit(patient_image.id)\" ng-if=\"$ctrl.canRetrieve\">\n" +
    "                <i class=\"icomoon icon-icomoon-pencil5\"></i>\n" +
    "              </div>\n" +
    "              <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.download(patient_image)\" ng-if=\"$ctrl.canRetrieve\">\n" +
    "                <i class=\"icomoon icon-icomoon-download\"></i>\n" +
    "              </div>\n" +
    "              <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.remove(patient_image)\" ng-if=\"$ctrl.canDelete\">\n" +
    "                <i class=\"icomoon icon-icomoon-bin\"></i>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"progress\" archive-creation-progress oid=\"patient_image.id\" archive=\"patient_image.$uploadProgress_image_archive\" thumb=\"patient_image.$uploadProgress_image_archive_thumb\">\n" +
    "              <div class=\"progress-bar\"></div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"caption text-center\" style=\"height:72px\">\n" +
    "            <b class=\"dis-b wide-nowrap\"\n" +
    "              uib-tooltip=\"{{ patient_image.name }}\"\n" +
    "              tooltip-placement=\"top\"\n" +
    "              tooltip-popup-delay=\"1000\">\n" +
    "              {{ patient_image.name }}\n" +
    "            </b>\n" +
    "            <p class=\"text-muted\" style=\"margin: 5px 0;\" ng-if=\"patient_image.id\">\n" +
    "              <small class=\"dis-b wide-nowrap\">{{ patient_image.created_at | date:\"medium\" }}</small>\n" +
    "              <small class=\"dis-b wide-nowrap\">{{ patient_image.created_by }}</small>\n" +
    "            </p>\n" +
    "            <h5 style=\"background-color:red; color:#fff; font-weight:bold; padding:4px 0;\"\n" +
    "              ng-if=\"patient_image.id === null\">\n" +
    "              Image Error\n" +
    "            </h5>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <a id=\"patient-image-dwl\" class=\"hide\" href=\"#\"></a>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/patient-image-detail-patient.tpl.html","\n" +
    "<div class=\"portlet light porlet-fit\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption\">\n" +
    "      <i class=\"icomoon icon-icomoon-images font-red-sunglo\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold uppercase\">Images</span>\n" +
    "    </div>\n" +
    "    <div class=\"actions\">\n" +
    "      <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.load()\" ng-disabled=\"!$ctrl.canList\">\n" +
    "        <i class=\"icomoon icon-icomoon-spinner11\"></i>\n" +
    "      </button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body\">\n" +
    "    <div class=\"row\">\n" +
    "      <a id=\"patient-image-dwl\" class=\"hide\" href=\"#\"></a>\n" +
    "      <div class=\"col-xs-12 col-sm-4 col-md-3 m-b\" ng-repeat=\"patient_image in $ctrl.patient_images\">\n" +
    "        <div class=\"thumbnail thumbnail-patient-image\">\n" +
    "          <wave-spinner class=\"modal-loader\" ng-show=\"patient_image.$loading\"></wave-spinner>\n" +
    "          <a href ng-click=\"$ctrl.edit(patient_image.id)\">\n" +
    "            <div class=\"image\" archive-thumb-preview url=\"patient_image.image_archive_thumb\">\n" +
    "              <div class=\"btn-group\" ng-click=\"$event.preventDefault(); $event.stopPropagation();\" ng-show=\"patient_image.id\">\n" +
    "                <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "                  ng-click=\"$event.stopPropagation()\"\n" +
    "                  ui-sref=\"chart-detail({encounterid: patient_image.encounter})\"\n" +
    "                  ng-if=\"$ctrl.canRetrieveEncounter\">\n" +
    "                  <i class=\"icomoon icon-icomoon-reading\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.edit(patient_image.id);\" ng-if=\"$ctrl.canRetrieve\">\n" +
    "                  <i class=\"icomoon icon-icomoon-pencil5\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.download(patient_image)\" ng-if=\"$ctrl.canRetrieve\">\n" +
    "                  <i class=\"icomoon icon-icomoon-download\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.remove(patient_image)\" ng-if=\"$ctrl.canDelete\">\n" +
    "                  <i class=\"icomoon icon-icomoon-bin\"></i>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </a>\n" +
    "          <div class=\"caption text-center\" style=\"height:72px\">\n" +
    "            <b class=\"dis-b wide-nowrap\"\n" +
    "              uib-tooltip=\"{{ patient_image.name }}\"\n" +
    "              tooltip-placement=\"top\"\n" +
    "              tooltip-popup-delay=\"1000\">\n" +
    "              {{ patient_image.name }}\n" +
    "            </b>\n" +
    "            <p class=\"text-muted\" style=\"margin: 5px 0;\">\n" +
    "              <small class=\"dis-b wide-nowrap\">{{ patient_image.created_at | date:\"medium\" }}</small>\n" +
    "              <small class=\"dis-b wide-nowrap\">{{ patient_image.created_by }}</small>\n" +
    "            </p>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/patient-interactions.tpl.html","<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-database-time font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Interactions</span>\n" +
    "        </div>\n" +
    "        <div class=\"btn-group pull-right\" uib-dropdown>\n" +
    "          <button type=\"button\" class=\"btn p-x btn-primary dropdown-toggle\" uib-dropdown-toggle>\n" +
    "            Actions\n" +
    "            <i class=\"icomoon icon-icomoon-arrow-down\"></i>\n" +
    "          </button>\n" +
    "          <ul class=\"dropdown-menu pull-right\" uib-dropdown-menu>\n" +
    "            <li><a ng-disabled=\"!CanUpdatePatient\" ng-click=\"openNoteModal({id: null, data: {note:'', isAlert:false}})\">Add Note</a></li>\n" +
    "            <li><a ng-if=\"CanCreateReviewRequest\" ng-click=\"requestReview()\">Request Review from Patient</a></li>\n" +
    "          </ul>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-md-4\">\n" +
    "            <select class=\"form-control\" ng-model=\"typeFilter\">\n" +
    "              <option value=\"\" class=\"\">Filter by Type</option>\n" +
    "              <option ng-repeat=\"(key, label) in EVENT_TYPES\" value=\"{{key}}\">{{label}}</option>\n" +
    "            </select>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div>\n" +
    "          <div style=\"overflow-x:auto;\">\n" +
    "            <table class=\"table table-striped table-bordered table-advance\">\n" +
    "              <thead>\n" +
    "              <tr>\n" +
    "                <th class=\"col-md-2\">Date</th>\n" +
    "                <th class=\"col-md-2\">Who</th>\n" +
    "                <th class=\"col-md-2\">Type</th>\n" +
    "                <th class=\"col-md-5\">Information</th>\n" +
    "                <th class=\"col-md-1\"></th>\n" +
    "              </tr>\n" +
    "              </thead>\n" +
    "              <tr ng-repeat=\"event in patientEvents | orderBy: 'created_at': true | filter: {type: typeFilter}\">\n" +
    "                <td>{{event.created_at | date:\"MM/dd/yyyy h:mma\"}}</td>\n" +
    "                <td ng-show=\"event.type != 500 \">{{event.created_by}}</td>\n" +
    "                <td ng-show=\"event.type == 500 \">{{}}</td>\n" +
    "                <td>{{typeTitle(event.type)}}</td>\n" +
    "                <td ng-bind-html=\"formattedInteraction(event) | sanitize\"></td>\n" +
    "                <td class=\"btn-container\">\n" +
    "                  <button type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-show=\"canEdit(event);\" ng-click=\"openNoteModal(event)\">\n" +
    "                    <i class=\"icomoon icon-icomoon-pencil5\" aria-hidden=\"true\"></i>\n" +
    "                  </button>\n" +
    "                  <button type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-show=\"canDelete(event);\" ng-click=\"openDeleteNoteModal(event.id)\">\n" +
    "                    <i class=\"icomoon icon-icomoon-bin\" aria-hidden=\"true\"></i>\n" +
    "                  </button>\n" +
    "                  <button type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\"\n" +
    "                    ng-click=\"openCancelPatientFormModal(event)\"\n" +
    "                    ng-if=\"event.type === EVENT_TYPE_FORM_SENT && event.data.status == 'sent' && event.data.end !== true\"\n" +
    "                    tooltip-placement=\"left\"\n" +
    "                    tooltip-popup-delay=\"1000\"\n" +
    "                    uib-tooltip=\"Cancel\">\n" +
    "                    <i class=\"icomoon icon-icomoon-stack-cancel\"></i>\n" +
    "                  </button>\n" +
    "                  <span ng-show=\"event.data.isAlert\"\n" +
    "                    title=\"Shows in alert when patient accessed\"\n" +
    "                    class=\"icomoon icon-icomoon-warning\"\n" +
    "                  ></span>\n" +
    "                </td>\n" +
    "              </tr>\n" +
    "            </table>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"addNote.html\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 ng-if=\"!event.id\" class=\"modal-title\">Add Note</h3>\n" +
    "    <h3 ng-if=\"event.id\" class=\"modal-title\">Edit Note</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <textarea ng-model=\"event.data.note\" rows=\"7\" autofocus class=\"form-control\" ng-maxlength=\"7000\"></textarea>\n" +
    "    <small>{{(event.data.note.length) ? event.data.note.length : 0}} of 7000 characters maximum</small>\n" +
    "    <br>\n" +
    "    <div class=\"checkbox-inline custom-control custom-checkbox m-x-0\" style=\"margin-top:10px\">\n" +
    "      <label>\n" +
    "        <input type=\"checkbox\" ng-model=\"event.data.isAlert\">\n" +
    "        <span class=\"custom-control-indicator\"></span>\n" +
    "        Show up to first 200 characters in alert when patient is accessed?\n" +
    "      </label>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn p-x-md btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "    <button class=\"btn p-x-md btn-primary\" ng-disabled=\"!event.data.note\" ng-click=\"save()\">Save</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"delNote.html\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Delete this note?</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">Are you sure you want to delete this note</div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"ok()\">OK</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#cancel-patientform-modal\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Cancel patient form</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    Are you sure you want to cancel the form sent to patient?\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-default\" ng-click=\"$dismiss()\">Cancel</button>\n" +
    "    <button class=\"btn btn-primary p-x-md\" ng-click=\"$ctrl.submit()\">OK</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/common/patient-list.tpl.html","<h3 class=\"page-title\">Charts</h3>\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-users4 font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Patients</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\">\n" +
    "          <div class=\"checkbox-inline custom-control custom-checkbox m-r\">\n" +
    "            <label>\n" +
    "              <input type=\"checkbox\" \n" +
    "                ng-change=\"$ctrl.uiGrid.refresh()\"\n" +
    "                ng-disabled=\"$ctrl.loading\"\n" +
    "                ng-model=\"$ctrl.uiGrid.showArchivedPatients\">\n" +
    "              <span class=\"custom-control-indicator\"></span>\n" +
    "              Include archived patients in list?\n" +
    "            </label>\n" +
    "          </div>\n" +
    "          <a class=\"btn default\" target=\"_blank\" href=\"/api/v1/patients/?format=csv\"\n" +
    "            uib-tooltip=\"Download patient list\"\n" +
    "            ng-disabled=\"!$ctrl.canListPatient\">\n" +
    "            Download\n" +
    "          </a>\n" +
    "          <button class=\"btn p-x default\"\n" +
    "            ng-click=\"$ctrl.load(true)\"\n" +
    "            ng-disabled=\"$ctrl.loading\">\n" +
    "            Hard Reload\n" +
    "          </button>\n" +
    "          <button class=\"btn p-x btn-primary\"\n" +
    "            ng-click=\"$ctrl.openPatientCreateModal()\"\n" +
    "            ng-disabled=\"!$ctrl.canCreatePatient\">\n" +
    "            Add New Patient\n" +
    "          </button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      \n" +
    "      <div class=\"portlet-body pos-r\" style=\"min-height:25px;\">\n" +
    "        <wave-spinner class=\"table-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "        <div ng-if=\"$ctrl.uiGridSettings.data\"\n" +
    "          ui-grid=\"$ctrl.uiGridSettings\"\n" +
    "          ui-grid-resize-columns>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"patient-list.tpl.html#patient-delete-modal\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Delete this patient?</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    Are you sure you want to delete the patient record for {{ $ctrl.patient.name }}? \n" +
    "    <p>\n" +
    "      NOTE that deleting this patient will delete any associated appointments.\n" +
    "    </p>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-wide default\" ng-click=\"$dismiss()\">Cancel</button>\n" +
    "    <button class=\"btn btn-wide btn-primary\" ng-click=\"$ctrl.submit()\">OK</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/common/prescription-add.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<h3 class=\"page-title\"> Prescriptions\n" +
    "</h3>\n" +
    "<div class=\"page-bar\" ng-hide=\"true\">\n" +
    "    <ul class=\"page-breadcrumb\">\n" +
    "        <li>\n" +
    "            <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "            <a ui-sref=\"home\">Home</a>\n" +
    "            <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "        </li>\n" +
    "        <li>\n" +
    "            <span>Add Prescriptions</span>\n" +
    "        </li>\n" +
    "    </ul>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-key font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Add Prescriptions</span>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <form class=\"form-inline\">\n" +
    "          <div class=\"form-group col-md-4\">\n" +
    "            <div class=\"dropdown\">\n" +
    "              <button class=\"btn p-x btn-primary dropdown-toggle\" type=\"button\" id=\"drug-name\" data-toggle=\"dropdown\" aria-expanded=\"true\">\n" +
    "                {{selectedDrug.name}}\n" +
    "                <span class=\"caret\"></span>\n" +
    "              </button>\n" +
    "              <ul class=\"dropdown-menu\" role=\"menu\" aria-labelledby=\"drug-name\">\n" +
    "                <li ng-repeat=\"drug in drugList\">\n" +
    "                  <a ng-click=\"setDrug(drug)\">{{drug.name}}</a>\n" +
    "                </li>\n" +
    "              </ul>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group col-md-4\">\n" +
    "            <div class=\"dropdown\">\n" +
    "              <button class=\"btn p-x btn-primary dropdown-toggle\" type=\"button\" id=\"dosage-name\" data-toggle=\"dropdown\" aria-expanded=\"true\">\n" +
    "                {{selectedDosage.name}}\n" +
    "                <span class=\"caret\"></span>\n" +
    "              </button>\n" +
    "              <ul class=\"dropdown-menu\" role=\"menu\" aria-labelledby=\"dosage-name\">\n" +
    "                <li ng-repeat=\"dosage in dosageList\">\n" +
    "                  <a ng-click=\"setDosage(dosage)\">{{dosage.name}}</a>\n" +
    "                </li>\n" +
    "              </ul>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group col-md-4\">\n" +
    "            <div class=\"dropdown\">\n" +
    "              <button class=\"btn btn-primary dropdown-toggle\" type=\"button\" id=\"dispense-name\" data-toggle=\"dropdown\" aria-expanded=\"true\">\n" +
    "                {{selectedDispense.name}}\n" +
    "                <span class=\"caret\"></span>\n" +
    "              </button>\n" +
    "              <ul class=\"dropdown-menu\" role=\"menu\" aria-labelledby=\"dispense-name\">\n" +
    "                <li ng-repeat=\"dispense in dispenseList\">\n" +
    "                  <a ng-click=\"setDispense(dispense)\">{{dispense.name}}</a>\n" +
    "                </li>\n" +
    "              </ul>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </form>\n" +
    "        <!-- TODO(bradleybossard) : Find a better way to vertically space components -->\n" +
    "        <br>\n" +
    "        <br>\n" +
    "\n" +
    "        <div class=\"text-center\">\n" +
    "          <button type=\"button\" class=\"btn btn-primary\" ng-click=\"addPrescription()\">Submit</button>\n" +
    "          <button type=\"button\" class=\"btn btn-danger\" ui-sref=\"^\">Cancel</button>\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/profile.tpl.html","<profile-page save=\"save\" angular-modal=\"me\"></profile-page>")

$templateCache.put("src/common/provider-selection.tpl.html","<div class=\"modal-header\">\n" +
    "  <h3 class=\"modal-title\">Select a Provider</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-md-12\">\n" +
    "       <table class=\"table table-hover\">\n" +
    "          <thead>\n" +
    "            <tr>\n" +
    "              <th width=\"50%\">First Name</th>\n" +
    "              <th width=\"50%\">Last Name</th>\n" +
    "            </tr>\n" +
    "          </thead>\n" +
    "          <tbody>\n" +
    "            <tr ng-repeat=\"provider in ctrl.providers\" ng-click=\"ctrl.onProviderSelect(provider)\">\n" +
    "              <th width=\"50%\">{{provider.first_name}}</th>\n" +
    "              <th width=\"50%\">{{provider.last_name}}</th>\n" +
    "            </tr>\n" +
    "          </tbody>\n" +
    "        </table>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/releases.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<div ng-hide={{isInWizard}}>\n" +
    "  <div class=\"page-bar\">\n" +
    "    <ul class=\"page-breadcrumb\">\n" +
    "      <li>\n" +
    "        <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "        <a ui-sref=\"charts\">Home</a>\n" +
    "        <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      </li>\n" +
    "      <li>\n" +
    "        <span>Releases</span>\n" +
    "      </li>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </ul>\n" +
    "  </div>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\" >\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-hammer font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Releases</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\"></div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <iframe src=\"https://docs.google.com/document/d/e/2PACX-1vTzssWIsA2uvqBjhbPvwNgJit4G1LJTHBD8ktnlQk5dJNW_TKtuwY09FV3T54OK9VvXIFn8WBTCecsA/pub?embedded=true\"\n" +
    "          style=\"width:100%; height:calc(100vh - 340px); overflow:auto;\">\n" +
    "        </iframe>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/reminders-list.tpl.html","<reminder-list-page ctrl=\"$ctrl\"></reminder-list-page>\n" +
    "")

$templateCache.put("src/common/reminders-manage.tpl.html","\n" +
    "<div class=\"portlet light m-b-0\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption\">\n" +
    "      <i class=\"icomoon icon-icomoon-address-book3 font-red-sunglo\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold uppercase\">\n" +
    "        Follow up Reminders\n" +
    "      </span>\n" +
    "    </div>\n" +
    "    <div class=\"actions\" ng-if=\"$ctrl.CanListReminder\">\n" +
    "      <div class=\"checkbox-inline custom-control custom-checkbox m-l-0\">\n" +
    "        <label>Limit to</label>\n" +
    "      </div>\n" +
    "      <div class=\"checkbox-inline custom-control custom-checkbox m-l-0\">\n" +
    "        <label>\n" +
    "          <input type=\"checkbox\" ng-model=\"$ctrl.expired\" ng-change=\"$ctrl.loadReminders()\">\n" +
    "          <span class=\"custom-control-indicator\"></span>\n" +
    "          <b>expired</b> and\n" +
    "        </label>\n" +
    "      </div>\n" +
    "      <div class=\"checkbox-inline custom-control custom-checkbox m-l-0\">\n" +
    "        <label>\n" +
    "          <input type=\"checkbox\" ng-model=\"$ctrl.uncompleted\" ng-change=\"$ctrl.loadReminders()\">\n" +
    "          <span class=\"custom-control-indicator\"></span>\n" +
    "          <b>uncompleted</b>\n" +
    "        </label>\n" +
    "      </div>\n" +
    "      <div class=\"btn blue-dark p-x\" ng-click=\"$ctrl.loadReminders()\" ng-if=\"$ctrl.CanListReminder\">\n" +
    "        Reload\n" +
    "      </div>\n" +
    "      <div class=\"btn blue-dark p-x\" ng-click=\"$ctrl.reminderCreateModal()\" ng-if=\"$ctrl.CanCreateReminder\">\n" +
    "        Create\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body pos-r\" style=\"overflow-x:auto\">\n" +
    "    <wave-spinner class=\"table-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "    <table ng-if=\"$ctrl.reminders.length\" class=\"table table-bordered table-advance table-align-middle m-b-0\">\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th class=\"narrow\">Date</th>\n" +
    "          <th>Reminder</th>\n" +
    "          <th ng-hide=\"$ctrl.uncompleted\">Resolution</th>\n" +
    "          <th ng-hide=\"$ctrl.uncompleted\" class=\"narrow\">Completed on</th>\n" +
    "          <th class=\"col-narrow\" style=\"min-width:110px\"></th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"reminder in $ctrl.reminders track by reminder.id\">\n" +
    "          <td class=\"highlight\">\n" +
    "            <div ng-class=\"reminder.is_completed ? 'success' : reminder.date <= $ctrl.today ? 'danger' : 'info'\"></div>\n" +
    "            <div class=\"m-l\">{{ reminder.date | date:\"MM/dd/yyyy\" }}</div>\n" +
    "          </td>\n" +
    "          <td class=\"preserve-newlines\">{{ reminder.text }}</td>\n" +
    "          <td ng-hide=\"$ctrl.uncompleted\" class=\"preserve-newlines\">{{ reminder.resolution }}</td>\n" +
    "          <td ng-hide=\"$ctrl.uncompleted\" class=\"narrow\">\n" +
    "            <span ng-if=\"reminder.completed_at\">{{ reminder.completed_at | date:'MM/dd/yyyy' }}</span>\n" +
    "          </td>\n" +
    "          <td class=\"btn-container\">\n" +
    "            <div class=\"btn-group\">\n" +
    "              <button type=\"button\" class=\"btn btn-icomoon-pixel-perfect-1x m-x-0 font-green white\"\n" +
    "                ng-click=\"$ctrl.reminderResolveModal(reminder)\"\n" +
    "                ng-disabled=\"reminder.is_completed\"\n" +
    "                ng-if=\"$ctrl.CanUpdateReminder\">\n" +
    "                <i class=\"icomoon icon-icomoon-checkmark4\"></i>\n" +
    "              </button>\n" +
    "              <button type=\"button\" class=\"btn btn-icomoon-pixel-perfect-1x m-x-0 font-blue white\"\n" +
    "                ng-click=\"$ctrl.reminderDetailModal(reminder)\"\n" +
    "                ng-if=\"$ctrl.CanRetrieveReminder\">\n" +
    "                <i class=\"icomoon icon-icomoon-pencil5\"></i>\n" +
    "              </button>\n" +
    "              <button type=\"button\" class=\"btn btn-icomoon-pixel-perfect-1x m-x-0 font-red white\"\n" +
    "                ng-click=\"$ctrl.reminderDeleteModal(reminder)\"\n" +
    "                ng-if=\"$ctrl.CanDeleteReminder\">\n" +
    "                <i class=\"icomoon icon-icomoon-bin\"></i>\n" +
    "              </button>\n" +
    "            </div>\n" +
    "          </td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "    </table>\n" +
    "    <div ng-if=\"!$ctrl.reminders.length\" class=\"well text-center m-b-0\">\n" +
    "      There are no Reminders\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#reminder-create-modal\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Create Follow up Reminder</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-show=\"$mctrl.loading\"></wave-spinner>\n" +
    "    <form name=\"form\">\n" +
    "      <div class=\"form-group\">\n" +
    "        <label>Remind at</label>\n" +
    "        <custom-datepicker-popup\n" +
    "          form-name=\"'form'\"\n" +
    "          format=\"'MM/dd/yyyy'\"\n" +
    "          input-name=\"'date'\"\n" +
    "          ng-model=\"$mctrl.reminder.date\"\n" +
    "          required\n" +
    "          show-placeholder=\"false\">\n" +
    "        </custom-datepicker-popup>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label>Reminder</label>\n" +
    "        <textarea\n" +
    "          class=\"form-control\"\n" +
    "          name=\"text\"\n" +
    "          rows=\"6\"\n" +
    "          ng-model=\"$mctrl.reminder.text\"\n" +
    "          required>\n" +
    "        </textarea>\n" +
    "      </div>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-wide btn-default\"\n" +
    "      ng-click=\"$dismiss()\">\n" +
    "      Close\n" +
    "    </button>\n" +
    "    <button class=\"btn btn-wide btn-primary\"\n" +
    "      ng-click=\"$mctrl.save()\"\n" +
    "      ng-disabled=\"form.$invalid || $mctrl.loading\">\n" +
    "      Submit\n" +
    "    </button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#reminder-delete-modal\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Delete Follow up Reminder?</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-show=\"$mctrl.loading\"></wave-spinner>\n" +
    "    <div>Are you sure you want to continue?</div>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-wide btn-default\"\n" +
    "      ng-click=\"$dismiss()\">\n" +
    "      Close\n" +
    "    </button>\n" +
    "    <button class=\"btn btn-wide btn-danger\"\n" +
    "      ng-click=\"$mctrl.save()\"\n" +
    "      ng-disabled=\"$mctrl.loading\">\n" +
    "      Delete\n" +
    "    </button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#reminder-detail-modal\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\"><span ng-if=\"$mctrl.CanUpdateReminder\">Edit</span> Follow up Reminder</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-show=\"$mctrl.loading\"></wave-spinner>\n" +
    "    <form name=\"form\">\n" +
    "      <fieldset ng-disabled=\"!$mctrl.CanUpdateReminder\">\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"form-group col-sm-6\">\n" +
    "            <label>Creation Date</label>\n" +
    "            <p class=\"form-control form-control-static\" style=\"color:#aaa\">\n" +
    "              {{ $mctrl.reminder.created_at | date:\"MM/dd/yyyy\" }}\n" +
    "            </p>\n" +
    "          </div>\n" +
    "          <div class=\"form-group col-sm-6\">\n" +
    "            <label>Remind at</label>\n" +
    "            <custom-datepicker-popup\n" +
    "              form-name=\"'form'\"\n" +
    "              format=\"'MM/dd/yyyy'\"\n" +
    "              input-name=\"'date'\"\n" +
    "              ng-model=\"$mctrl.reminder.date\"\n" +
    "              required\n" +
    "              show-placeholder=\"false\">\n" +
    "            </custom-datepicker-popup>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label>Reminder</label>\n" +
    "          <textarea class=\"form-control\"\n" +
    "            name=\"text\"\n" +
    "            ng-model=\"$mctrl.reminder.text\"\n" +
    "            required\n" +
    "            rows=\"6\">\n" +
    "          </textarea>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label class=\"dis-b\">\n" +
    "            Resolution\n" +
    "            <a class=\"pull-right\" ng-click=\"$mctrl.insertResolutionHeader()\"><small>Insert header</small></a>\n" +
    "          </label>\n" +
    "          <textarea class=\"form-control\"\n" +
    "            name=\"resolution\"\n" +
    "            ng-model=\"$mctrl.reminder.resolution\"\n" +
    "            ng-required=\"$mctrl.reminder.is_completed\"\n" +
    "            rows=\"8\">\n" +
    "          </textarea>\n" +
    "        </div>\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-sm-8\">\n" +
    "            <label style=\"line-height:30px\">Mark as Completed</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-4\">\n" +
    "            <div class=\"btn-group pull-right\">\n" +
    "              <label class=\"btn btn-sm btn-default btn-wide-50\"\n" +
    "                ng-model=\"$mctrl.reminder.is_completed\"\n" +
    "                uib-btn-radio=\"true\">\n" +
    "                Yes\n" +
    "              </label>\n" +
    "              <label class=\"btn btn-sm btn-default btn-wide-50\"\n" +
    "                ng-model=\"$mctrl.reminder.is_completed\"\n" +
    "                uib-btn-radio=\"false\">\n" +
    "                No\n" +
    "              </label>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </fieldset>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-wide btn-default\"\n" +
    "      ng-click=\"$dismiss()\">\n" +
    "      Close\n" +
    "    </button>\n" +
    "    <button class=\"btn btn-wide btn-primary\"\n" +
    "      ng-click=\"$mctrl.save()\"\n" +
    "      ng-disabled=\"form.$invalid || $mctrl.loading\"\n" +
    "      ng-if=\"$mctrl.CanUpdateReminder\">\n" +
    "      Submit\n" +
    "    </button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#reminder-resolve-modal\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Complete Follow up Reminder</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-show=\"$mctrl.loading\"></wave-spinner>\n" +
    "    <form name=\"form\">\n" +
    "      <div class=\"form-group\">\n" +
    "        <label>Resolution</label>\n" +
    "        <textarea class=\"form-control\" name=\"resolution\" rows=\"20\" ng-model=\"$mctrl.reminder.resolution\" required></textarea>\n" +
    "      </div>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-wide btn-default\" ng-click=\"$dismiss()\">Close</button>\n" +
    "    <button class=\"btn btn-wide btn-primary\" ng-click=\"$mctrl.save()\" ng-disabled=\"form.$invalid || $mctrl.loading\">Submit</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/common/reports.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<h3 class=\"page-title\"> Reports\n" +
    "  <small></small>\n" +
    "</h3>\n" +
    "<div class=\"page-bar\" ng-hide=\"true\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "      <a ui-sref=\"home\">Home</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>Reports</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/restricted.tpl.html","<alert type=\"'danger'\">\n" +
    "  <strong>Access Denied</strong>\n" +
    "  <p>You don't have permission to access this. <a ui-sref=\"home\">Return home.</a></p>\n" +
    "</alert>\n" +
    "")

$templateCache.put("src/common/review-submit.tpl.html","<div class=\"row\" style=\"padding-top: 30px;\" ng-init=\"init()\">\n" +
    "  <div class=\"col-md-6 col-md-offset-3\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase text-center\">Please review our practice</span>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"portlet-body\" ng-if='isValid'>\n" +
    "        <div class=\"thumbnail dis-ib\" ng-if=\"review.practice.picture_url\">\n" +
    "          <img ng-src=\"{{ review.practice.picture_url }}\" style=\"max-height:200px\"></img>\n" +
    "        </div>\n" +
    "        <p>Hello {{review.patient.first_name}} {{review.patient.last_name}},</p>\n" +
    "        <p>If you are willing, please take a moment and rate our service. Your feedback is greatly appreciated.</p>\n" +
    "        <p>Sincerely,</p>\n" +
    "        <p>Your team at {{review.practice.name}}.</p>\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"form-group col-md-6\">\n" +
    "            <div\n" +
    "              class=\"form-control-static m-b\"\n" +
    "              uib-rating\n" +
    "              ng-model=\"review.rating\"\n" +
    "              max=\"5\"\n" +
    "              read-only=\"false\"\n" +
    "            ></div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"form-group col-md-12\">\n" +
    "            <textarea class=\"form-control\" id=\"text\" placeholder=\"Write your review here....\"\n" +
    "              ng-model=\"review.text\"  ng-maxlength=\"4000\">\n" +
    "            </textarea>\n" +
    "            <small ng-hide=\"!review.text\">{{review.text.length}} of 4000 characters maximum</small>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"row\" ng-show=\"review.practice.reviews_terms_of_use_url\">\n" +
    "          <div class=\"form-group col-md-12\">\n" +
    "            <input id=\"agreement\" type=\"checkbox\" ng-model=\"checked\"> By checking here I agree to the <a  target=\"_blank\" ng-href=\"{{review.practice.reviews_terms_of_use_url}}\">terms of use</a>.</input>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"col-md-6 col-md-offset-3\">\n" +
    "            <button class=\"btn btn-primary btn-lg btn-block\" ng-disabled=\"(review.practice.reviews_terms_of_use_url && !checked) || (!review.text && !review.rating)\" ng-click=\"submit()\">Submit Review</button>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/reviews.tpl.html","<reputation-page practice-id=\"practiceId\"></reputation-page>\n" +
    "\n" +
    "")

$templateCache.put("src/common/schedule-appointment-add.tpl.html","<div ng-cloak>\n" +
    "\n" +
    "  <form name=\"appointment\" role=\"form\" class=\"form-horizontal\" novalidate>\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 ng-show=\"isNewAppointment\" class=\"modal-title\">New Appointment</h3>\n" +
    "    <h3 ng-show=\"!isNewAppointment\" class=\"modal-title\">Edit Appointment</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label class=\"control-label\">Patient</label>\n" +
    "        <span ng-if=\"patient_event_alerts.length > 0\"\n" +
    "          title=\"{{getPatientEventAlertText(patient_event_alerts);}}\"\n" +
    "          class=\"icomoon icon-icomoon-warning\"\n" +
    "        ></span>\n" +
    "        <div class=\"pull-right\" ng-if=\"patient.id\" style=\"margin-top:4px;\">\n" +
    "          <a target=\"_blank\" class=\"btn btn-icomoon-pixel-perfect-1x p-x-xs2 white\" ui-sref=\"profile({patientid:patient.id})\" uib-tooltip=\"Go to Profile\">\n" +
    "            <i class=\"icomoon icomoon icon-icomoon-user font-red-sunglo\"></i>\n" +
    "          </a>\n" +
    "          <a target=\"_blank\" class=\"btn btn-icomoon-pixel-perfect-1x p-x-xs2 white\" ui-sref=\"chartnotes({patientid:patient.id})\" uib-tooltip=\"Go to Encounters\">\n" +
    "            <i class=\"icomoon icomoon icon-icomoon-aid-kit font-red-sunglo\"></i>\n" +
    "          </a>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\"  ng-class=\"{ 'has-error' : appointment.patient.$invalid}\">\n" +
    "        <table style=\"table-layout:fixed; width:100%\" >\n" +
    "          <tr>\n" +
    "            <td>\n" +
    "              <ui-select name=\"patient\" ng-model=\"patient.id\" required>\n" +
    "                <ui-select-match placeholder=\"Select or search a patient in the list\">\n" +
    "                  <span class=\"text-overflow\">\n" +
    "                    {{ $select.selected[1] }}\n" +
    "                    <small class=\"text-muted m-l-sm\">{{ $select.selected[6] }}</small>\n" +
    "                    <small class=\"text-muted m-l-sm\" ng-if=\"$select.selected[3]\">({{ getYearsOld($select.selected[3]) }}y)</small>\n" +
    "                    <small class=\"text-muted m-l-sm\">{{ $select.selected[3] }}</small>\n" +
    "                  </span>\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                  repeat=\"pat[0] as pat in ($select.search ? patients : patient.id ? [patients[0]] : []) | propsFilter: {filter:$select.search, props:['1', '6', '3']} | limitTo:250\"\n" +
    "                  ui-disable-choice=\"pat[4] === true\">\n" +
    "                  <span class=\"text-overflow dis-ib w-full\">\n" +
    "                    <span ng-bind-html=\"pat[1] | highlight: $select.search\"></span>\n" +
    "                    <small class=\"m-l-sm\" ng-bind-html=\"pat[6] | highlight: $select.search\"></small>\n" +
    "                    <small class=\"m-l-sm\" ng-if=\"pat[3]\">({{ getYearsOld(pat[3]) }}y)</small>\n" +
    "                    <small class=\"m-l-sm\" ng-bind-html=\"pat[3] | highlight: $select.search\" ng-if=\"pat[3]\"></small>\n" +
    "                  </span>\n" +
    "                </ui-select-choices>\n" +
    "              </ui-select>\n" +
    "            </td>\n" +
    "            <td style=\"width:30px;\">\n" +
    "              <button class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" type=\"button\" style=\"height:34px; margin-left:-1px; margin-top: 1px;\" ng-click=\"openAddPatientModal()\">\n" +
    "                <i class=\"icomoon icon-icomoon-plus2\"></i>\n" +
    "              </button>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </table>\n" +
    "        <span class=\"help-block\" ng-show=\"appointment.patient.$invalid\">Patient selection is required</span>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\" ng-class=\"{ 'has-error' : appointment.provider.$invalid}\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label class=\"control-label\" for=\"provider-list\">Provider</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <select\n" +
    "          ng-model=\"providerId\"\n" +
    "          class=\"form-control\"\n" +
    "          id=\"provider-list\"\n" +
    "          name=\"provider\"\n" +
    "          ng-required=\"true\"\n" +
    "        >\n" +
    "          <option ng-selected=\"{{provider.id == providerId}}\"\n" +
    "                  ng-repeat=\"provider in providers\"\n" +
    "                  ng-value=\"provider.id\">\n" +
    "          {{provider.first_name}} {{provider.last_name}}\n" +
    "          </option>\n" +
    "        </select>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\" ng-class=\"{ 'has-error' : appointment.operatory.$invalid}\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label class=\"control-label\" for=\"location-list\">Operatory</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <select\n" +
    "          class=\"form-control\"\n" +
    "          id=\"location-list\"\n" +
    "          name=\"operatory\"\n" +
    "          ng-model=\"operatoryId\"\n" +
    "          ng-change=\"onOperatoryChange(item)\"\n" +
    "          ng-options=\"item.id as item.name for item in operatories\"\n" +
    "          required\n" +
    "        >\n" +
    "        </select>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label class=\"control-label\">Chief Complaint</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control input\"\n" +
    "               name=\"complaint\"\n" +
    "               ng-model=\"complaint\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label class=\"control-label\" for=\"sel3\">Appt Type</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\"  ng-class=\"{ 'has-error' : !doesApptDefaultExist}\">\n" +
    "        <select ng-model=\"appointmentType\" class=\"form-control\">\n" +
    "          <option value=0></option>\n" +
    "          <option value=\"npv\">New Patient Visit</option>\n" +
    "          <option value=\"ev\">Evaluation</option>\n" +
    "          <option value=\"rc\">Root Canal</option>\n" +
    "          <option value=\"ob\">Obturation</option>\n" +
    "          <option value=\"re\">Remedicate</option>\n" +
    "          <option value=\"rt\">Re-Treatment</option>\n" +
    "          <option value=\"a\">Apicoectomy</option>\n" +
    "          <option value=\"po\">Post-Op</option>\n" +
    "        </select>\n" +
    "        <span class=\"has-error help-block\" ng-show=\"!doesApptDefaultExist\">Appointment Type Selection is required</span>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label class=\"control-label\">Appt Status</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <select ng-init=\"appointmentStatus =  appointmentStatus || 'unconfirmed' \" ng-model=\"appointmentStatus\" class=\"form-control\">\n" +
    "          <option ng-repeat=\"status in appointmentStatuses \" value=\"{{status.value}}\" ng-selected=\"{{status.value == appointmentStatus}}\">{{status.title}}</option>\n" +
    "        </select>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label class=\"control-label\" for=\"sel4\">Start Date</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <custom-datepicker-popup ng-model=\"startDate\" min-date=\"minDate\" input-name=\"'startDateInputDate'\" form-name=\"'appointment'\" ng-required=\"true\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"row\" ng-if=\"hasDiffStart\">\n" +
    "      <div class=\"col-sm-4\"> </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <div class=\"alert alert-warning m-b-0\">\n" +
    "          This is the recurring event start date. Any changes will make changes to all events</small>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label class=\"control-label\" for=\"sel4\">Start Time</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <div uib-timepicker ng-model=\"startTime\" ng-change=\"timeChange()\" hour-step=\"1\" minute-step=\"15\" show-meridian=\"true\"></div>\n" +
    "        <div class=\"alert alert-info m-b-0\" ng-if=\"currentOperatoryTZ\">\n" +
    "          <strong>{{ currentOperatoryTZ }}</strong> time zone <small>(from Operatory Location's)</small>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label class=\"control-label\" for=\"sel4\">Duration</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <select ng-model=\"duration\" ng-change=\"timeChange()\" class=\"form-control\" id=\"sel4\" ng-init=\"duration = duration || 30\">\n" +
    "          <option ng-repeat=\"option in durationOptions\" ng-value=\"option.value\">{{option.label}}</option>\n" +
    "        </select>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label class=\"control-label\" for=\"sel4\">Current Appointment:</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <p class=\"form-control-static\">{{ appointmentString }}</p>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label class=\"control-label\" for=\"sel4\"> Reminder:</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-4\" ng-if=\"reminderStatus !== 'sent' &&  reminderStatus !== 'confirmed' && reminderStatus !== undefined\">\n" +
    "        <p class=\"form-control-static\">{{ reminderStatus }}</p>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-4\" ng-if=\"reminderStatus === 'sent'\">\n" +
    "        <p class=\"form-control-static\">Last Sent on {{reminderSentDate | date:\"MM/dd/yyyy ' ' h:mma\" }}</p>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-4\" ng-if=\"reminderStatus === 'confirmed'\">\n" +
    "        <p class=\"form-control-static\">Confirmed on {{reminderSentDate | date:\"MM/dd/yyyy ' ' h:mma\" }}</p>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-2\" ng-show=\"!isNewAppointment\">\n" +
    "        <button type=\"button\" class=\"btn btn-danger\" ng-disabled=\"!CanUpdateAppointment || !sendReminderEnabled\" ng-click=\"sendReminder()\">Send Reminder</button>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-2\" ng-show=\"isNewAppointment\">\n" +
    "        <label>\n" +
    "          <input\n" +
    "            type=\"checkbox\"\n" +
    "            ng-model=\"sendReminderOnNewApp\"\n" +
    "            ng-disabled=\"!sendReminderEnabled\"\n" +
    "          > Send</label>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-12\">\n" +
    "        <small class=\"text-muted m-l-sm\" ng-if=\"!sendReminderEnabled\">\n" +
    "          Patient not selected or does not have an email address set or mobile phone.\n" +
    "        </small>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-if=\"isNewAppointment\" class=\"modal-footer\">\n" +
    "      <button type=\"submit\" class=\"btn btn-primary\" ng-disabled=\"appointment.$invalid || !CanCreateAppointment || !doesApptDefaultExist\" ng-click=\"createOrUpdate()\">OK</button>\n" +
    "      <button type=\"button\" class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-if=\"!isNewAppointment\" class=\"modal-footer\">\n" +
    "      <button type=\"submit\" class=\"btn btn-primary\" ng-disabled=\"appointment.$invalid || !CanUpdateAppointment\" ng-click=\"createOrUpdate()\">Update</button>\n" +
    "      <button type=\"button\" ng-disabled=\"!CanDeleteAppointment\" class=\"btn btn-danger\" ng-click=\"delete()\">Delete</button>\n" +
    "      <button type=\"button\" class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "    </div>\n" +
    "    </div>\n" +
    "    </form>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/schedule-background-add.tpl.html","<div>\n" +
    "  <form name=\"timeblock\" role=\"form\" class=\"form-horzizontal\" novalidate>\n" +
    "\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 ng-show=\"isNewTimeblock\" class=\"modal-title\">New Background</h3>\n" +
    "    <h3 ng-show=\"!isNewTimeblock\" class=\"modal-title\">Edit Background</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <div class=\"form-group row\"  ng-class=\"{ 'has-error' : timeblock.provider.$invalid}\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label for=\"provider-list\">Provider</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "          <select\n" +
    "            class=\"form-control\"\n" +
    "            id=\"providers-list\"\n" +
    "            ng-model=\"providerId\"\n" +
    "            ng-options=\"provider.id as (provider.first_name + ' ' + provider.last_name) for provider in providers\"\n" +
    "            name=\"provider\"\n" +
    "            ng-required=\"!operatoryId\"\n" +
    "          >\n" +
    "        </select>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\"  ng-class=\"{ 'has-error' : timeblock.operatory.$invalid}\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label for=\"location-list\">Operatory</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <select\n" +
    "          class=\"form-control\"\n" +
    "          id=\"location-list\"\n" +
    "          ng-model=\"operatoryId\"\n" +
    "          ng-change=\"onOperatoryChange(item)\"\n" +
    "          ng-options=\"item.id as item.name for item in operatories\"\n" +
    "          name=operatory\n" +
    "          ng-required=\"!providerId\"\n" +
    "        >\n" +
    "        </select>\n" +
    "        <span class=\"help-block\" ng-show=\"timeblock.provider.$invalid || timeblock.operatory.$invalid\">Provider, operatory, or both are required</span>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label for=\"sel4\">Start Date</label>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <custom-datepicker-popup ng-model=\"startDate\" input-name=\"'timeblockDate'\" form-name=\"'timeblock'\" ng-required=\"true\"/>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"row\" ng-if=\"hasDiffStart\">\n" +
    "      <div class=\"col-sm-4\"> </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <div class=\"alert alert-warning m-b-0\">\n" +
    "          This is the recurring event start date. Any changes will make changes to all events</small>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\" ng-hide=\"startTimeDisable\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label for=\"sel4\">Start Time</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\" >\n" +
    "        <div uib-timepicker ng-model=\"startTime\" ng-change=\"timeChange()\" hour-step=\"1\" minute-step=\"15\" show-meridian=\"true\"></div>\n" +
    "        <div class=\"alert alert-info m-b-0\" ng-if=\"currentOperatoryTZ\">\n" +
    "            <strong>{{ currentOperatoryTZ }}</strong> time zone <small>(from Operatory Location's timezone, or user's timezone if operatory is not selected.)</small>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label for=\"sel4\">Duration</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <select ng-model=\"duration\" class=\"form-control\" id=\"sel4\" ng-change=\"timeChange()\" ng-init=\"duration = duration || 15\">\n" +
    "          <option ng-repeat=\"duration in durationDefaults\" ng-value=\"{{duration.value}}\">{{duration.label}}</option>\n" +
    "        </select>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label class=\"control-label\" for=\"sel4\">Repeat</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <select class=\"form-control\" ng-model=\"repeatType\" ng-options=\"option.label for option in repeatOptions\" ng-change=\"openCustomRepeatModal()\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label for=\"sel4\">Current Timeblock:</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        {{ timeblockString }}\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\" ng-class=\"{ 'has-error' : timeblock.complaint.$invalid}\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label>Reason</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control input\"\n" +
    "               name=\"complaint\"\n" +
    "               ng-model=\"reason\"\n" +
    "               ng-minlength=1\n" +
    "               validate-on=\"blur\"\n" +
    "               placeholder=\"Enter Reason\"\n" +
    "               required />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "  <div ng-if=\"isNewTimeblock\" class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-disabled=\"timeblock.$invalid || !CanCreateTimeBlock\" ng-click=\"create()\">OK</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "  </div>\n" +
    "\n" +
    "  <div ng-if=\"!isNewTimeblock\" class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-disabled=\"timeblock.$invalid || !CanUpdateTimeBlock\" ng-click=\"update()\">Update</button>\n" +
    "    <button class=\"btn btn-danger\" ng-disabled=\"!CanDeleteTimeBlock\" ng-click=\"delete()\">Delete</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "  </div>\n" +
    "\n" +
    "  </form>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/schedule-confirm.partial.tpl.html","<div ng-cloak>\n" +
    "    <form name=\"appointment\" role=\"form\" class=\"form-horizontal\" novalidate>\n" +
    "      <div class=\"modal-header\">\n" +
    "        <h3 class=\"modal-title\">Confirmation</h3>\n" +
    "      </div>\n" +
    "      <div class=\"modal-body\">\n" +
    "        <p>{{ message }}</p>\n" +
    "  \n" +
    "        <div class=\"modal-footer\" >\n" +
    "          <button type=\"submit\" class=\"btn btn-primary\" ng-click=\"result('save')\">Ok</button>\n" +
    "          <button type=\"button\" class=\"btn btn-default\" ng-click=\"result('cancel')\">Cancel</button>\n" +
    "        </div>\n" +
    "  \n" +
    "      </div>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  ")

$templateCache.put("src/common/schedule-multicolumn.partial.tpl.html","<style>\n" +
    "  .calender-btn{\n" +
    "    border: 1px #cbcbcb solid;\n" +
    "    background: #fff;\n" +
    "    border-radius: 5px !important;\n" +
    "  }\n" +
    "  .header-calender-section{\n" +
    "    display: flex;\n" +
    "    justify-content: space-between;\n" +
    "    flex-wrap: wrap;\n" +
    "    margin-left: 3px;\n" +
    "  }\n" +
    "  .schedule-appoinment-datepicker-calender-section{\n" +
    "    min-width:300px;\n" +
    "    display: flex;\n" +
    "  }\n" +
    "  .schedule-appoinment-datepicker{\n" +
    "    margin: 0 1rem;\n" +
    "  }\n" +
    "  .schedule-appoinment-left-btn{\n" +
    "    float: right;\n" +
    "  }\n" +
    "  .schedule-height {\n" +
    "    height: 85vh !important;\n" +
    "  }\n" +
    "  \n" +
    "</style>\n" +
    "<div ng-controller=\"ScheduleMultiColumnController as ctrl\" id=\"resource-view\">\n" +
    "  <wave-spinner class=\"modal-loader\" ng-show=\"ctrl.loading\"></wave-spinner>\n" +
    "  <div ng-if=\"multiColumnView\" class=\"header-calender-section\">\n" +
    "    <div>\n" +
    "      <label class=\"btn gray btn-outline uppercase\">\n" +
    "        {{ ctrl.dt | date:'EEEE' }}\n" +
    "      </label>\n" +
    "    </div>\n" +
    "    <div>\n" +
    "      <div class=\"schedule-appoinment-datepicker-calender-section\">\n" +
    "        <button type=\"button\" ng-click=\"ctrl.decrementDate()\"\n" +
    "          class=\"calender-btn\">\n" +
    "          <span class=\"fc-icon fc-icon-chevron-left\"></span>\n" +
    "        </button>\n" +
    "        <custom-datepicker-popup class=\"schedule-appoinment-datepicker\" show-placeholder=\"false\" ng-model=\"ctrl.dt\" ng-required=\"true\"></custom-datepicker-popup>\n" +
    "        <button type=\"button\" ng-click=\"ctrl.incrementDate()\"\n" +
    "          class=\"calender-btn\">\n" +
    "          <span class=\"fc-icon fc-icon-chevron-right\"></span>\n" +
    "        </button>\n" +
    "        <button type=\"button\" style=\"margin-left: 5px;\" ng-click=\"ctrl.today()\"\n" +
    "        class=\"calender-btn\">\n" +
    "        TODAY\n" +
    "      </button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div style=\"padding-right: 20px;\">\n" +
    "      <button type=\"button\" class=\"calender-btn\" ng-click=\"ctrl.settingsDialog()\">\n" +
    "        <i class=\"icomoon icon-icomoon-cog\"></i> Columns\n" +
    "      </button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"row\" ng-if=\"multiColumnView\">&nbsp</div>\n" +
    "\n" +
    "  <full-calendar \n" +
    "  ng-class=\"{'schedule-height': isFullScreen}\"\n" +
    "  options=\"uiConfig\" events=\"ctrl.events\" resources=\"ctrl.resources\" dates-set=\"ctrl.dateSet()\"\n" +
    "    event-click=\"ctrl.eventClick()\" event-drop=\"ctrl.eventDrop()\" event-mouse-enter=\"ctrl.eventMouseEnter()\"\n" +
    "    event-mouse-leave=\"ctrl.eventMouseLeave()\" event-resize=\"ctrl.eventResize()\" select=\"ctrl.dateClick()\">\n" +
    "  </full-calendar>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/schedule-recurring-add.tpl.html","<div ng-cloak>\n" +
    "  <form name=\"appointment\" role=\"form\" class=\"form-horizontal\" novalidate>\n" +
    "    <div class=\"modal-header\">\n" +
    "      <h3 class=\"modal-title\">Custom recurrence</h3>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body\">\n" +
    "\n" +
    "      <div class=\"container\">\n" +
    "        <div class=\"row\">\n" +
    "          <div style=\"display: inline-block;\" class=\"col-sm\">\n" +
    "            <span>Repeat every</span>\n" +
    "          </div>\n" +
    "          <div style=\"display: inline-block;\" class=\"col-sm\">\n" +
    "            <input class=\"form-control input\" style=\"width: 75px\" type=\"number\"  min=\"1\" ng-model=\"repeatInterval\">\n" +
    "          </div>\n" +
    "          <div style=\"display: inline-block;\" class=\"col-sm\">\n" +
    "            <select class=\"form-control\" ng-model=\"selectedRepeatIntervalUnit\" ng-options=\"option as option.label for option in repeatIntervalUnits\"></select>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      \n" +
    "\n" +
    "      <div style=\"padding-top: 25px\" ng-if=\"selectedRepeatIntervalUnit.value === 2\">\n" +
    "        <label>Repeat on:</label>\n" +
    "        <button class=\"form-control\" ng-repeat=\"dayOfWeek in repeatOnDaysOfWeek\" ng-click=\"dayOfWeek.checked = dayOfWeek.value\">\n" +
    "          <input type=\"checkbox\" ng-model=\"dayOfWeek.checked\" ng-true-value=\"'{{dayOfWeek.value}}'\" ng-false-value=\"''\">\n" +
    "          {{dayOfWeek.label}}\n" +
    "        </button>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"modal-footer\">\n" +
    "        <button type=\"submit\" class=\"btn btn-primary\" ng-click=\"save()\">Save</button>\n" +
    "        <button type=\"button\" class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "  </form>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/schedule-settings.partial.tpl.html","<div ng-cloak>\n" +
    "  <form name=\"appointment\" role=\"form\" class=\"form-horizontal\" novalidate>\n" +
    "    <div class=\"modal-header\">\n" +
    "      <h3 class=\"modal-title\">Column Ordering</h3>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body\">\n" +
    "      <div class=\"row\" style=\"padding: 25px; padding-top: 0;\">\n" +
    "        Drag and Drop to change the ordering of the columns as you wish to view them.\n" +
    "      </div>\n" +
    "      <div dragula=\"'bag-one'\" dragula-model=\"items\">\n" +
    "        <!-- Your draggable elements here -->\n" +
    "        <div class=\"draggable_item\" ng-repeat=\"item in items\">\n" +
    "          <div class=\"row\">\n" +
    "            <div class=\"col-sm-10\">{{item.title}}</div>\n" +
    "            <div class=\"col-sm-2\">\n" +
    "              <span\n" +
    "                tragula-stick-width\n" +
    "                class=\"grabbable\"\n" +
    "                style=\"font-size: 25px; float: right;\"\n" +
    "                ><i\n" +
    "                  class=\"icomoon icon-icomoon-drag-left-right rotate-270deg grabbable\"\n" +
    "                ></i\n" +
    "              ></span>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"modal-footer\">\n" +
    "        <button type=\"submit\" class=\"btn btn-primary\" ng-click=\"result('save')\">\n" +
    "          Save\n" +
    "        </button>\n" +
    "        <button\n" +
    "          type=\"button\"\n" +
    "          class=\"btn btn-default\"\n" +
    "          ng-click=\"result('cancel')\"\n" +
    "        >\n" +
    "          Cancel\n" +
    "        </button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </form>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/schedule-timeblock-add.tpl.html","<div>\n" +
    "  <form name=\"timeblock\" role=\"form\" class=\"form-horzizontal\" novalidate>\n" +
    "\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 ng-show=\"isNewTimeblock\" class=\"modal-title\">New Timeblock</h3>\n" +
    "    <h3 ng-show=\"!isNewTimeblock\" class=\"modal-title\">Edit Timeblock</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <div class=\"form-group row\"  ng-class=\"{ 'has-error' : timeblock.provider.$invalid}\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label for=\"provider-list\">Provider</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "          <select\n" +
    "            class=\"form-control\"\n" +
    "            id=\"providers-list\"\n" +
    "            ng-model=\"providerId\"\n" +
    "            ng-options=\"provider.id as (provider.first_name + ' ' + provider.last_name) for provider in providers\"\n" +
    "            name=\"provider\"\n" +
    "            ng-required=\"!operatoryId\"\n" +
    "          >\n" +
    "        </select>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\"  ng-class=\"{ 'has-error' : timeblock.operatory.$invalid}\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label for=\"location-list\">Operatory</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <select\n" +
    "          class=\"form-control\"\n" +
    "          id=\"location-list\"\n" +
    "          ng-model=\"operatoryId\"\n" +
    "          ng-change=\"onOperatoryChange(item)\"\n" +
    "          ng-options=\"item.id as item.name for item in operatories\"\n" +
    "          name=operatory\n" +
    "          ng-required=\"!providerId\"\n" +
    "        >\n" +
    "        </select>\n" +
    "        <span class=\"help-block\" ng-show=\"timeblock.provider.$invalid || timeblock.operatory.$invalid\">Provider, operatory, or both are required</span>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label for=\"sel4\">Start Date</label>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <custom-datepicker-popup ng-model=\"startDate\" input-name=\"'timeblockDate'\" form-name=\"'timeblock'\" ng-required=\"true\"/>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "    <div class=\"row\" ng-if=\"hasDiffStart\">\n" +
    "      <div class=\"col-sm-4\"> </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <div class=\"alert alert-warning m-b-0\">\n" +
    "          This is the recurring event start date. Any changes will make changes to all events</small>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\" ng-hide=\"startTimeDisable\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label for=\"sel4\">Start Time</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\" >\n" +
    "        <div uib-timepicker ng-model=\"startTime\" ng-change=\"timeChange()\" hour-step=\"1\" minute-step=\"15\" show-meridian=\"true\"></div>\n" +
    "        <div class=\"alert alert-info m-b-0\" ng-if=\"currentOperatoryTZ\">\n" +
    "            <strong>{{ currentOperatoryTZ }}</strong> time zone <small>(from Operatory Location's timezone, or user's timezone if operatory is not selected.)</small>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label for=\"sel4\">Duration</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <select ng-model=\"duration\" class=\"form-control\" id=\"sel4\" ng-change=\"timeChange()\" ng-init=\"duration = duration || 15\">\n" +
    "          <option ng-repeat=\"duration in durationDefaults\" ng-value=\"duration.value\">{{duration.label}}</option>\n" +
    "        </select>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label class=\"control-label\" for=\"sel4\">Repeat</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <select class=\"form-control\" ng-model=\"repeatType\" ng-options=\"option.label for option in repeatOptions\" ng-change=\"openCustomRepeatModal()\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label for=\"sel4\">Current Timeblock:</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        {{ timeblockString }}\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\" ng-class=\"{ 'has-error' : timeblock.complaint.$invalid}\">\n" +
    "      <div class=\"col-sm-4\">\n" +
    "        <label>Reason</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <input type=\"text\"\n" +
    "               class=\"form-control input\"\n" +
    "               name=\"complaint\"\n" +
    "               ng-model=\"reason\"\n" +
    "               ng-minlength=1\n" +
    "               validate-on=\"blur\"\n" +
    "               placeholder=\"Enter Reason\"\n" +
    "               required />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "  <div ng-if=\"isNewTimeblock\" class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-disabled=\"timeblock.$invalid || !CanCreateTimeBlock\" ng-click=\"create()\">OK</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "  </div>\n" +
    "\n" +
    "  <div ng-if=\"!isNewTimeblock\" class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-disabled=\"timeblock.$invalid || !CanUpdateTimeBlock\" ng-click=\"update()\">Update</button>\n" +
    "    <button class=\"btn btn-danger\" ng-disabled=\"!CanDeleteTimeBlock\" ng-click=\"delete()\">Delete</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "  </div>\n" +
    "\n" +
    "  </form>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/schedule.tpl.html","<style>\n" +
    "  .schedule-appoinment-dropdown {\n" +
    "    position: relative;\n" +
    "    display: inline-block;\n" +
    "    margin-right: 10px;\n" +
    "    margin-bottom: 10px;\n" +
    "  }\n" +
    "\n" +
    "  .schedule-appoinment-dropdown-btn {\n" +
    "    background-color: #E1E5ED;\n" +
    "    color: #666;\n" +
    "    border: none;\n" +
    "    border-radius: 4px !important;\n" +
    "    padding: 5px 20px;\n" +
    "    font-size: 14px;\n" +
    "    cursor: pointer;\n" +
    "    min-width: 160px;\n" +
    "    text-align: center;\n" +
    "  }\n" +
    "\n" +
    "  .schedule-appoinment-dropdown-content {\n" +
    "    position: absolute;\n" +
    "    display: none;\n" +
    "    background-color: #fff;\n" +
    "    min-width: 160px;\n" +
    "    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);\n" +
    "    z-index: 999;\n" +
    "  }\n" +
    "\n" +
    "  .schedule-appoinment-dropdown-content .schedule-appoinment-dropdown-option {\n" +
    "    color: #333;\n" +
    "    padding: 12px 16px;\n" +
    "    text-decoration: none;\n" +
    "    display: block;\n" +
    "  }\n" +
    "\n" +
    "  .schedule-appoinment-dropdown-content .schedule-appoinment-dropdown-option:hover {\n" +
    "    background-color: #f6f6f6;\n" +
    "  }\n" +
    "\n" +
    "\n" +
    "  .schedule-appoinment-dropdown-btn:hover {\n" +
    "    background-color: #ddd;\n" +
    "  }\n" +
    "\n" +
    "  .schedule-appoinment-table-head {\n" +
    "    background-color: #fff;\n" +
    "    display: flex;\n" +
    "    justify-content: space-between;\n" +
    "    padding: 0 0.3rem;\n" +
    "    align-items: center;\n" +
    "  }\n" +
    "\n" +
    "  .schedule-appoinment-btn {\n" +
    "    background-color: #796799;\n" +
    "    color: #fff;\n" +
    "    font-size: 14px;\n" +
    "    margin-left: 10px;\n" +
    "    padding: 5px 10px;\n" +
    "    min-width: 100px;\n" +
    "    border: none;\n" +
    "    border-radius: 5px !important;\n" +
    "    transition: all 0.3s ease-in-out;\n" +
    "  }\n" +
    "\n" +
    "  .schedule-appoinment-btn:hover {\n" +
    "    background-color: #A89CBD;\n" +
    "  }\n" +
    "\n" +
    "  .schedule-appoinment-iconbtn {\n" +
    "    border: none;\n" +
    "    padding: 5px;\n" +
    "    min-width: 30px;\n" +
    "    border-radius: 4px !important;\n" +
    "    background: #E1E5ED;\n" +
    "    color: #666;\n" +
    "    margin-left: 5px;\n" +
    "  }\n" +
    "\n" +
    "  .schedule-appoinment-header-btn {\n" +
    "    display: flex;\n" +
    "    align-items: center;\n" +
    "  }\n" +
    "\n" +
    "  .appt-new {\n" +
    "    background-color: #796799;\n" +
    "  }\n" +
    "\n" +
    "  .timeblock-new {\n" +
    "    background-color: #5c9bd1;\n" +
    "  }\n" +
    "\n" +
    "  .background-new {\n" +
    "    background-color: #2ab4c0;\n" +
    "    color: white;\n" +
    "  }\n" +
    "\n" +
    "  .schedule__table__head {\n" +
    "    display: flex;\n" +
    "    justify-content: space-between;\n" +
    "    align-items: center;\n" +
    "    padding-bottom: 10px;\n" +
    "  }\n" +
    "\n" +
    "  /* For Tablet and Small Laptop Screens */\n" +
    "  @media only screen and (max-width: 1040px) {\n" +
    "    .schedule__table__head {\n" +
    "      display: flex;\n" +
    "      flex-wrap: wrap;\n" +
    "    }\n" +
    "\n" +
    "    .schedule__view__filter {\n" +
    "      width: 100%;\n" +
    "      display: flex;\n" +
    "      justify-content: center;\n" +
    "    }\n" +
    "\n" +
    "    .schedule__header_btns {\n" +
    "      width: 100%;\n" +
    "      display: flex;\n" +
    "      align-items: center;\n" +
    "      justify-content: center;\n" +
    "    }\n" +
    "  }\n" +
    "\n" +
    "  /* For Mobile Screen */\n" +
    "  @media only screen and (max-width: 590px) {\n" +
    "    .schedule__table__head {\n" +
    "      display: block;\n" +
    "    }\n" +
    "\n" +
    "    .schedule__view__filter {\n" +
    "      width: 100%;\n" +
    "      display: flex;\n" +
    "      flex-direction: column;\n" +
    "      align-items: center;\n" +
    "    }\n" +
    "\n" +
    "    .schedule__header_btns {\n" +
    "      width: 100%;\n" +
    "      display: flex;\n" +
    "      flex-direction: column;\n" +
    "      align-items: center;\n" +
    "    }\n" +
    "\n" +
    "    .schedule-appoinment-dropdown {\n" +
    "      width: 100%;\n" +
    "      margin-bottom: 10px;\n" +
    "    }\n" +
    "\n" +
    "    .schedule-appoinment-dropdown-btn {\n" +
    "      width: 100%;\n" +
    "    }\n" +
    "\n" +
    "    .schedule-appoinment-dropdown-content {\n" +
    "      width: 100%;\n" +
    "      text-align: center;\n" +
    "    }\n" +
    "\n" +
    "    .schedule-appoinment-btn {\n" +
    "      width: 100%;\n" +
    "      margin-bottom: 10px;\n" +
    "      margin-right: 10px;\n" +
    "      padding: 8px;\n" +
    "    }\n" +
    "    .schedule-appoinment-iconbtn{\n" +
    "      width: 100%;\n" +
    "      margin-bottom: 10px;\n" +
    "      margin-right: 10px;\n" +
    "      padding: 8px;\n" +
    "    }\n" +
    "    .icon-btn-full-screen{\n" +
    "      display: none;\n" +
    "    }\n" +
    "  }\n" +
    "</style>\n" +
    "<ui-view>\n" +
    "  <!-- BEGIN PAGE HEADER-->\n" +
    "  <h3 class=\"page-title\"> Schedule\n" +
    "    <small>appointments</small>\n" +
    "  </h3>\n" +
    "\n" +
    "\n" +
    "  <div class=\"page-bar\" ng-hide=\"true\">\n" +
    "    <ul class=\"page-breadcrumb\">\n" +
    "      <li>\n" +
    "        <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "        <a ui-sref=\"home\">Home</a>\n" +
    "        <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      </li>\n" +
    "      <li>\n" +
    "        <span>Schedule</span>\n" +
    "      </li>\n" +
    "    </ul>\n" +
    "    <div class=\"page-toolbar\">\n" +
    "\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-md-12\">\n" +
    "      <div class=\"portlet light porlet-fit calendar\" ng-class=\"{'portlet-fullscreen': isFullScreen}\">\n" +
    "        <div class='schedule__table__head'>\n" +
    "          <div class=\"schedule__view__filter\">\n" +
    "            <div class=\"schedule-appoinment-dropdown\" uib-dropdown>\n" +
    "              <button class=\"schedule-appoinment-dropdown-btn\" uib-dropdown-toggle>\n" +
    "                <div>View By &nbsp; <i style=\"transform: rotate(90deg);\" class=\"icomoon icon-icomoon-embed\"></i></div>\n" +
    "              </button>\n" +
    "              <div class=\"schedule-appoinment-dropdown-content\" uib-dropdown-menu>\n" +
    "                <label class=\"uppercase schedule-appoinment-dropdown-option\" ng-model=\"multiColumnView\"\n" +
    "                  ng-click=\"toggleMultiColumn(false)\">\n" +
    "                  <i class=\"icomoon icon-icomoon-menu3\"></i>&nbsp;Full\n" +
    "                </label>\n" +
    "                <label class=\"uppercase schedule-appoinment-dropdown-option\" ng-model=\"multiColumnView\"\n" +
    "                  ng-click=\"toggleMultiColumn(true)\">\n" +
    "                  <i class=\"icomoon icon-icomoon-grid\"></i>&nbsp; Columns\n" +
    "                </label>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"schedule-appoinment-dropdown\" uib-dropdown>\n" +
    "              <button class=\"schedule-appoinment-dropdown-btn\" uib-dropdown-toggle>\n" +
    "                Color By &nbsp; <i style=\"transform: rotate(90deg);\" class=\"icomoon icon-icomoon-embed\"></i>\n" +
    "              </button>\n" +
    "              <div class=\"schedule-appoinment-dropdown-content\" uib-dropdown-menu>\n" +
    "                <label class=\"schedule-appoinment-dropdown-option\" ng-model=\"colorBy\" uib-btn-radio=\"'appointment_type'\"\n" +
    "                  ng-change=\"setColorsBy('appointment_type')\">Appt. Type</label>\n" +
    "                <label class=\"schedule-appoinment-dropdown-option\" ng-model=\"colorBy\"\n" +
    "                  uib-btn-radio=\"'appointment_status'\" ng-change=\"setColorsBy('appointment_status')\">Appt.\n" +
    "                  Status</label>\n" +
    "                <label class=\"schedule-appoinment-dropdown-option\" ng-model=\"colorBy\" uib-btn-radio=\"'provider'\"\n" +
    "                  ng-change=\"setColorsBy('provider')\">Provider</label>\n" +
    "                <label class=\"schedule-appoinment-dropdown-option\" ng-model=\"colorBy\" uib-btn-radio=\"'operatory'\"\n" +
    "                  ng-change=\"setColorsBy('operatory')\">Operatory</label>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"schedule-appoinment-dropdown\" ng-if=\"multiColumnView\" uib-dropdown>\n" +
    "              <button class=\"schedule-appoinment-dropdown-btn\" uib-dropdown-toggle>\n" +
    "                Column By &nbsp; <i style=\"transform: rotate(90deg);\" class=\"icomoon icon-icomoon-embed\"></i>\n" +
    "              </button>\n" +
    "              <div class=\"schedule-appoinment-dropdown-content\" uib-dropdown-menu>\n" +
    "                <label class=\"schedule-appoinment-dropdown-option\" ng-model=\"multicolumnByProvider\"\n" +
    "                  uib-btn-radio=\"false\" ng-change=\"setMulticolumnByProvider(false)\">Operatory</label>\n" +
    "                <label class=\"schedule-appoinment-dropdown-option\" ng-model=\"multicolumnByProvider\" uib-btn-radio=\"true\"\n" +
    "                  ng-change=\"setMulticolumnByProvider(true)\">Provider</label>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"schedule__header_btns\">\n" +
    "            <button class=\"schedule-appoinment-iconbtn icon-btn-full-screen\" ng-click=\"setFullScreen()\">\n" +
    "              <i class=\"icomoon icon-icomoon-enlarge\" ng-if=\"!isFullScreen\"></i>\n" +
    "              <i class=\"icomoon icon-icomoon-cross2\" ng-if=\"isFullScreen\"></i>\n" +
    "            </button>\n" +
    "            <button ng-disabled=\"!CanCreateAppointment || isBackgroundEditMode\" id=\"addAppointmentButton\"\n" +
    "              class=\"schedule-appoinment-btn appt-new\"\n" +
    "              ng-click=\"openAddAppointmentModal({ start: currentDate.hour(businessHoursStart).minute(businessMinutesStart) })\">\n" +
    "              + Appointment\n" +
    "            </button>\n" +
    "            <button id=\"addTimeBlockButton\" ng-disabled=\"!CanCreateTimeBlock || isBackgroundEditMode\"\n" +
    "              class=\"schedule-appoinment-btn timeblock-new\"\n" +
    "              ng-click=\"openAddTimeblockModal($event, currentDate.hour(businessHoursStart).minute(businessMinutesStart))\">\n" +
    "              + Timeblock\n" +
    "            </button>\n" +
    "            <button id=\"backgroundBtn\" class=\"schedule-appoinment-btn background-new\"\n" +
    "              ng-click=\"openAddBackGroundModal({ start: currentDate.hour(businessHoursStart).minute(businessMinutesStart) })\">\n" +
    "              + Background\n" +
    "            </button>\n" +
    "            <button ng-if=\"isBackgroundEditMode && multiColumnView\" class=\"schedule-appoinment-iconbtn background-new\"\n" +
    "              ng-click=\"editBckMode()\" tooltip-append-to-body=\"true\" uib-tooltip=\"Stop background mode\">\n" +
    "              <i class=\"icomoon icon-icomoon-stop\"></i>\n" +
    "            </button>\n" +
    "            <button ng-if=\"!isBackgroundEditMode && multiColumnView\" class=\"schedule-appoinment-iconbtn background-new\"\n" +
    "              ng-click=\"editBckMode()\" tooltip-append-to-body=\"true\" uib-tooltip=\"Edit background mode\">\n" +
    "              <i class=\"icomoon icon-icomoon-pencil\"></i>\n" +
    "            </button>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"portlet-body\">\n" +
    "          <div class=\"row\">\n" +
    "            <div class=\"col-md-12 col-sm-12\">\n" +
    "              <div ng-include=\"'src/common/schedule-multicolumn.partial.tpl.html'\"></div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</ui-view>")

$templateCache.put("src/common/settings-account.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<h3 class=\"page-title\"> Accounting\n" +
    "<!--  <small>manage your account settings</small>-->\n" +
    "</h3>\n" +
    "<div class=\"page-bar\" ng-hide=\"true\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "      <a ui-sref=\"home\">Home</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>Accounting</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-calculator3 font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Accounts and transactions. Currency: {{practice.currency}}</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\">\n" +
    "          <div class=\"btn-group\">\n" +
    "            <button ng-if=\"false\" class=\"btn p-x btn-primary\" ng-disabled=\"!CanCreateGLAccount\" ng-click=\"openAddAccountModal(practice.currency)\">New Account</button>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "\n" +
    "        <div class=\"pre-scrollable\" style=\"min-height:200px\">\n" +
    "          <table class=\"table table-striped table-bordered table-advance\">\n" +
    "            <thead>\n" +
    "              <tr>\n" +
    "                <th class=\"col-md-1\">GL Account</th>\n" +
    "                <th class=\"col-md-1\">Type</th>\n" +
    "                <th class=\"col-md-8\">Description</th>\n" +
    "                <!--<td class=\"col-md-1\">Currency</td>-->\n" +
    "                <th class=\"col-md-2\"></th>\n" +
    "              </tr>\n" +
    "            </thead>\n" +
    "            </tr>\n" +
    "            <tr ng-repeat=\"ac in accounts | orderBy: 'gl_account_number'\">\n" +
    "              <td ng-hide=\"editingAccount == ac.id\" ng-attr-title=\"id: {{ac.id}}\">{{ac.gl_account_number}}</td>\n" +
    "              <td ng-hide=\"editingAccount == ac.id\">{{ac.type}}</td>\n" +
    "              <td ng-hide=\"editingAccount == ac.id\">{{ac.description}} {{ac.patient_ar_gl_account}}</td>\n" +
    "              <!--<td ng-hide=\"editingAccount == ac.id\">{{ac.description}} {{ac.patient_ar_gl_account.first_name}} {{ac.patient_ar_gl_account.last_name}}</td>-->\n" +
    "              <!--<td ng-hide=\"editingAccount == ac.id\">{{ac.currency}}</td>-->\n" +
    "              <td ng-hide=\"(editingAccount === ac.id)\">\n" +
    "                <button ng-hide=\"($parent.acselected === ac)\" type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"$parent.acselected = ac; selectAccount(ac.id);\">\n" +
    "                  <i class=\"icomoon icon-icomoon-eye2\" aria-hidden=\"true\"></i>\n" +
    "                </button>\n" +
    "                <button ng-hide=\"($parent.acselected !== ac)\" type=\"button\" class=\"btn btn-primary btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"$parent.acselected = null; selectAccount(null);\">\n" +
    "                  <i class=\"icomoon icon-icomoon-minus2\" aria-hidden=\"true\"></i>\n" +
    "                </button>\n" +
    "                <button ng-hide=\"($parent.acselected === ac)\" ng-disabled=\"!CanUpdateGLAccount\" type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"editingAccount = ac.id\">\n" +
    "                  <i class=\"icomoon icon-icomoon-pencil5\" aria-hidden=\"true\"></i>\n" +
    "                </button>\n" +
    "              </td>\n" +
    "\n" +
    "              <td ng-hide=\"editingAccount != ac.id\">\n" +
    "                <input type=\"text\" class=\"form-control\" ng-model=\"ac.gl_account_number\"></input>\n" +
    "              </td>\n" +
    "              <td ng-hide=\"editingAccount != ac.id\">\n" +
    "                <select class=\"form-control\" ng-model=\"ac.type\">\n" +
    "                  <option value=\"ca\" selected>Asset - cash</option>\n" +
    "                  <option value=\"ar\" selected>Asset - AR</option>\n" +
    "                  <option value=\"as\" selected>Asset - other</option>\n" +
    "                  <option value=\"li\" selected>Liability</option>\n" +
    "                  <option value=\"in\" selected>Income</option>\n" +
    "                  <option value=\"ex\" selected>Expense</option>\n" +
    "                  <option value=\"eq\" selected>Equity</option>\n" +
    "                </select>\n" +
    "              </td>\n" +
    "              <td ng-hide=\"editingAccount != ac.id\">\n" +
    "                <input type=\"text\" class=\"form-control\" ng-model=\"ac.description\"></input>\n" +
    "              </td>\n" +
    "              <!--\n" +
    "    				<td ng-hide=\"editingAccount != ac.id\">\n" +
    "    					<select class=\"form-control\" ng-model=\"ac.currency\">\n" +
    "    					  <option value=\"USD\" selected>USD</option>\n" +
    "    					  <option value=\"EUR\">EUR</option>\n" +
    "    					</select>\n" +
    "    				</td>\n" +
    "    -->\n" +
    "              <td ng-hide=\"editingAccount != ac.id\">\n" +
    "                <button type=\"button\" class=\"btn btn-primary btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"editingAccount = null;updateAccount(null);\">\n" +
    "                  <i class=\"icomoon icon-icomoon-minus2\" aria-hidden=\"true\"></i>\n" +
    "                </button>\n" +
    "                <button type=\"button\" class=\"btn btn-primary btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"editingAccount = null; updateAccount(ac);\">\n" +
    "                  <i class=\"icomoon icon-icomoon-download8\" aria-hidden=\"true\"></i>\n" +
    "                </button>\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "          </table>\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"row\" ng-show=\"acselected\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-book font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Journal Entry Details for GL Account {{acselected.gl_account_number}} {{acselected.description}} {{acselected.patient_ar_gl_account}} -- Current balance = {{accountTotal}} {{accountTotalCurrency}}</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\">\n" +
    "          <div class=\"btn-group\">\n" +
    "            <button type=\"submit\" class=\"btn p-x btn-primary\" ng-disabled=\"!CanUpdateGLAccount\" ng-click=\"openAddJournalEntryModal(acselected, accounts)\">New Journal Entry</button>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <div style=\"overflow-x:auto;\">\n" +
    "          <table class=\"table table-striped table-bordered table-advance\">\n" +
    "            <thead>\n" +
    "              <tr>\n" +
    "                <th class=\"col-md-1\">Id</th>\n" +
    "                <th class=\"col-md-4\">Parent Journal Entry</th>\n" +
    "                <th class=\"col-md-2\">Description</th>\n" +
    "                <th class=\"col-md-2\">Memo</th>\n" +
    "                <th class=\"col-md-1\" style=\"text-align:right;\">Amount</th>\n" +
    "                <th class=\"col-md-1\">When</th>\n" +
    "                <th class=\"col-md-1\">By</th>\n" +
    "              </tr>\n" +
    "            </thead>\n" +
    "            </tr>\n" +
    "            <tr ng-repeat=\"jed in journalEntryDetails | orderBy: 'created':true\">\n" +
    "              <td>{{jed.id}}</td>\n" +
    "              <td>\n" +
    "                <div>\n" +
    "                  Id: {{jed.journalEntry.id}}\n" +
    "                </div>\n" +
    "                <div>\n" +
    "                  Offsetting Journal Entry Details:\n" +
    "                </div>\n" +
    "                <div ng-repeat=\"ojed in jed.otherJournalEntryDetailInfos\">\n" +
    "                  &#8226; GL Account: {{ojed.gl_account_number}}, Id: {{ojed.id}}, Amount: {{ojed.amount | currency : '' : 2 }}\n" +
    "                </div>\n" +
    "              </td>\n" +
    "              <td>{{jed.description}}</td>\n" +
    "              <td>{{jed.memo}}</td>\n" +
    "              <td style=\"text-align:right;\">{{jed.amount | currency : '' : 2 }}</td>\n" +
    "              <td>{{jed.created | date:\"MM/dd/yyyy\"}}</td>\n" +
    "              <td>{{jed.by_username}}</td>\n" +
    "            </tr>\n" +
    "          </table>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"addAccount.html\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Add Account</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <form>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label for=\"accountnumber\">External GL Account Number:</label>\n" +
    "        <input id=\"accountnumber\" class=\"form-control\" type=\"text\" ng-model=\"newAccount.gl_account_number\"></input>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label for=\"type\">Type of account:</label>\n" +
    "        <select id=\"type\" class=\"form-control\" ng-model=\"newAccount.type\">\n" +
    "          <option value=\"ca\" selected>Asset - cash</option>\n" +
    "          <option value=\"ar\" selected>Asset - AR</option>\n" +
    "          <option value=\"as\" selected>Asset - other</option>\n" +
    "          <option value=\"li\" selected>Liability</option>\n" +
    "          <option value=\"in\" selected>Income</option>\n" +
    "          <option value=\"ex\" selected>Expense</option>\n" +
    "          <option value=\"eq\" selected>Equity</option>\n" +
    "        </select>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label for=\"desc\">Description:</label>\n" +
    "        <input id=\"desc\" class=\"form-control\" type=\"text\" ng-model=\"newAccount.description\"></input>\n" +
    "      </div>\n" +
    "      <!--\n" +
    "		<div class=\"form-group\">\n" +
    "			<label for=\"currency\">Currency:</label>\n" +
    "			<p class=\"form-control-static\" id=\"currency\">{{newAccount.currency}}</label>\n" +
    "\n" +
    "			<select id=\"currency\" class=\"form-control\" ng-model=\"newAccount.currency\">\n" +
    "			  <option value=\"USD\" selected>USD</option>\n" +
    "			  <option value=\"EUR\">EUR</option>\n" +
    "			</select>\n" +
    "\n" +
    "		</div>\n" +
    "-->\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"ok()\">OK</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"addJournalEntry.html\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">{{title}}</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <form name='myForm'>\n" +
    "      <div class=\"form-group\" ng-class=\"{ 'has-success': myForm.description.$valid, 'has-error': myForm.description.$invalid}\">\n" +
    "        <label for=\"jedesc\">Description:</label>\n" +
    "        <textarea id=\"jedesc\" name='description' class=\"form-control\" ng-model=\"newJournalEntry.description\" required></textarea>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label for=\"jememo\">Memo:</label>\n" +
    "        <textarea id=\"jememo\" class=\"form-control\" type=\"text\" ng-model=\"newJournalEntry.memo\"></textarea>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label>Account #1:\n" +
    "          <br/>{{account.gl_account_number + ' - ' + account.description}}</label>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\" ng-class=\"{ 'has-success': myForm.toacct.$valid, 'has-error': myForm.toacct.$invalid}\">\n" +
    "        <label for=\"jetoacct\">Account #2:</label>\n" +
    "        <select name=\"toacct\" required class=\"form-control\" ng-model=\"newJournalEntry.gl_account2\" ng-options=\"account.id as account.gl_account_number + ' - ' + account.description for account in accountsWithoutAccountIdAndSameCurrency  | orderBy:'gl_account_number'\">\n" +
    "        </select>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\" ng-class=\"{ 'has-success': myForm.amount.$valid, 'has-error': myForm.amount.$invalid}\">\n" +
    "        <label for \"jeamt\">Amount (positive amount <strong>debits (+) Account #2</strong> and credits (-) Account #1):</label>\n" +
    "        <input id=\"jeamt\" name='amount' class=\"form-control\" type=\"number\" ng-model=\"newJournalEntry.amount\" required></input>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label>Payment required in: {{currency}}</label>\n" +
    "      </div>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button ng-disabled=\"myForm.$invalid\" class=\"btn btn-primary\" ng-click=\"ok()\">OK</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "  </div>\n" +
    "\n" +
    "</script>\n" +
    "")

$templateCache.put("src/common/settings-billing.tpl.html","<accounting-billing-general></accounting-billing-general>")

$templateCache.put("src/common/settings-chart-notes.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<div ng-hide={{isInWizard}}>\n" +
    "<!--\n" +
    "  <h3 class=\"page-title\"> Fee Schedules\n" +
    "    <small></small>\n" +
    "  </h3>\n" +
    "-->\n" +
    "  <div class=\"page-bar\">\n" +
    "    <ul class=\"page-breadcrumb\">\n" +
    "      <li>\n" +
    "        <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "        <a ui-sref=\"charts\">Home</a>\n" +
    "        <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "        <a ui-sref=\"settings\">Settings</a>\n" +
    "        <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      </li>\n" +
    "      <li>\n" +
    "        <span>Accounting and Billing</span>\n" +
    "      </li>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <li>\n" +
    "        <span>Fee Schedules</span>\n" +
    "      </li>\n" +
    "    </ul>\n" +
    "  </div>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "\n" +
    "<div>\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-md-12\">\n" +
    "      <div class=\"portlet light porlet-fit\">\n" +
    "        <div class=\"portlet-title\">\n" +
    "          <div class=\"caption\">\n" +
    "            <i class=\"icomoon icon-icomoon-cash3 font-red-sunglo\"></i>\n" +
    "            <span class=\"caption-subject font-red-sunglo bold uppercase\">Fee Schedules</span>\n" +
    "          </div>\n" +
    "          <div class=\"actions\">\n" +
    "            <div class=\"btn-group pull-right\">\n" +
    "              <button type=\"submit\" ng-hide=\"fpselected != null\" ng-disabled=\"!CanCreateFeeProfile\" class=\"btn p-x btn-primary\" ng-click=\"openAddFeeprofileModal()\">New Fee Schedule</button>\n" +
    "              <button type=\"submit\" ng-hide=\"fpselected == null\" class=\"btn p-x btn-primary\" ng-click=\"openAddFeeprofileModal(feeprofileselected)\">Copy This Fee Schedule</button>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"portlet-body\">\n" +
    "          <div><small>Currency: {{practice.currency}}</small>\n" +
    "          </div>\n" +
    "\n" +
    "          <table class=\"table table-striped table-bordered table-advance\">\n" +
    "            <thead>\n" +
    "            <tr>\n" +
    "              <th  width=\"20%\" ng-click=\"sortType = 'name'; sortReverse = !sortReverse\">Name\n" +
    "                <span ng-show=\"sortType == 'name' && !sortReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></span>\n" +
    "                <span ng-show=\"sortType == 'name' && sortReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></span>\n" +
    "              </th>\n" +
    "              <th width=\"20%\" ng-click=\"sortType = 'payer.name'; sortReverse = !sortReverse\">Payer\n" +
    "                <span ng-show=\"sortType == 'payer.name' && !sortReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></span>\n" +
    "                <span ng-show=\"sortType == 'payer.name' && sortReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></span>\n" +
    "              </th>\n" +
    "              <th width=\"20%\" ng-click=\"sortType = 'created'; sortReverse = !sortReverse\">Created on\n" +
    "                <span ng-show=\"sortType == 'created' && !sortReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></span>\n" +
    "                <span ng-show=\"sortType == 'created' && sortReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></span>\n" +
    "              </th>\n" +
    "              <th width=\"20%\" ng-click=\"sortType = 'updated_by_username'; sortReverse = !sortReverse\">By\n" +
    "                <span ng-show=\"sortType == 'updated_by_username' && !sortReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></span>\n" +
    "                <span ng-show=\"sortType == 'updated_by_username' && sortReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></span>\n" +
    "              </th>\n" +
    "              <th width=\"20%\">Actions</th>\n" +
    "            </tr>\n" +
    "            </thead>\n" +
    "            <tbody>\n" +
    "            <tr ng-repeat=\"fp in feeprofiles | orderBy: orderByFn:sortReverse\" ng-hide=\"$parent.fpselected !== fp.id && $parent.fpselected !== null \" class=\"fee-profile\">\n" +
    "              <td width=\"20%\" ng-hide=\"fp.showUpdate\">{{fp.name}}</td>\n" +
    "              <td width=\"20%\" ng-show=\"fp.showUpdate\" >\n" +
    "                <input class=\"form-control\" ng-model=\"fp.name\" required>\n" +
    "              </td>\n" +
    "              <td width=\"20%\">{{fp.payer.name}} <div ng-show=\"fp.payer.hasOwnProperty('payer_id') && !fp.payer.payer_id\" ><i class=\"icomoon icon-icomoon-warning2\" style=\"color:#F1C40F;\" title=\"payer no longer recognized by clearinghouse\"></i><small>payer no longer recognized by clearinghouse</small></div>\n" +
    "              </td>\n" +
    "              <td width=\"20%\">{{fp.created | date:\"MM/dd/yyyy\"}}</td>\n" +
    "              <td width=\"20%\">{{fp.updated_by_username}}</td>\n" +
    "              <td width=\"20%\">\n" +
    "                <button ng-show=\"$parent.fpselected !== fp.id\" ng-hide=\"fp.showUpdate == true\" type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"selectFeeprofile(fp.id,fp);\">\n" +
    "                  <span class=\"icomoon icon-icomoon-eye\" aria-hidden=\"true\"></span> <!-- Open the detail -->\n" +
    "                </button>\n" +
    "                <button ng-show=\"$parent.fpselected === fp.id\"  type=\"button\" class=\"btn btn-primary btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"selectFeeprofile(null,null);\">\n" +
    "                  <span class=\"icomoon icon-icomoon-minus2\" aria-hidden=\"true\"></span> <!-- Hide the detail  -->\n" +
    "                </button>\n" +
    "\n" +
    "\n" +
    "                <div class=\"btn-group\">\n" +
    "                  <button type=\"button\" ng-disabled=\"fp.is_primary_feeforservice \" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-show=\"fp.showUpdate == true\" ng-click=\"fp.name = fp.bkName; fp.showUpdate = false\" >\n" +
    "                    <span class=\"icomoon icon-icomoon-minus2\" aria-hidden=\"true\"></span> <!-- Edit Minus -->\n" +
    "                  </button>\n" +
    "                  <button ng-show=\"$parent.fpselected !== fp.id && fp.showUpdate != true\" ng-disabled=\"fp.is_primary_feeforservice || !CanUpdateFeeProfile\"  type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"fp.bkName = fp.name; fp.showUpdate = true;\">\n" +
    "                    <span class=\"icomoon icon-icomoon-pencil5\" aria-hidden=\"true\"></span> <!-- Edit Pencil -->\n" +
    "                  </button>\n" +
    "                  <button ng-show=\"$parent.fpselected !== fp.id && fp.showUpdate\" type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"fp.showUpdate = false; updateFeeProfile(fp);\" >\n" +
    "                    <span class=\"icomoon icon-icomoon-download8\" aria-hidden=\"true\"></span> <!-- Save Pencil -->\n" +
    "                  </button>\n" +
    "                </div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"btn-group\">\n" +
    "                  <button  type=\"button\" ng-disabled=\"fp.name === 'Fee for Service' || !CanDeleteFeeProfile\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"openDelFeeprofileModal(fp.id, fp.name);\">\n" +
    "                    <span class=\"icomoon icon-icomoon-bin\" aria-hidden=\"true\"></span>  <!-- Remove Fee -->\n" +
    "                  </button>\n" +
    "                </div>\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "            </tbody>\n" +
    "          </table>\n" +
    "\n" +
    "\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"row\" ng-hide=\"fpselected == null\">\n" +
    "    <div class=\"col-md-12\">\n" +
    "      <div class=\"portlet light porlet-fit\">\n" +
    "        <div class=\"portlet-title\">\n" +
    "          <div class=\"caption\">\n" +
    "            <i class=\"icomoon icon-icomoon-coin-dollar font-red-sunglo\"></i>\n" +
    "            <span class=\"caption-subject font-red-sunglo bold uppercase\">Fees</span>\n" +
    "          </div>\n" +
    "          <div class=\"actions\"></div>\n" +
    "        </div>\n" +
    "        <div class=\"portlet-body\">\n" +
    "           <table class=\"table table-fixedheader table-striped table-condensed\">\n" +
    "             <thead>\n" +
    "            <tr>\n" +
    "              <th width=\"5%\">Code</th>\n" +
    "              <th width=\"50%\" ng-hide=\"feeprofileselected.has_insurance_fees === false\">Procedure</th>\n" +
    "              <th width=\"14%\" ng-hide=\"feeprofileselected.has_insurance_fees === false\">In-Network Fee</th>\n" +
    "              <th width=\"14%\" ng-hide=\"feeprofileselected.has_insurance_fees === false\">Out of Network Fee</th>\n" +
    "              <th width=\"50%\" ng-hide=\"feeprofileselected.has_insurance_fees === true\">Procedure</th>\n" +
    "              <th width=\"10%\" ng-hide=\"feeprofileselected.has_insurance_fees === true\">Patient($)</th>\n" +
    "              <th width=\"9%\">Last updated</th>\n" +
    "              <th width=\"8%\">Actions</th>\n" +
    "            </tr>\n" +
    "             </thead>\n" +
    "             <tbody>\n" +
    "            <tr ng-repeat=\"f in fees | orderBy: 'code'\" ng-init=\"edit = f.id\">\n" +
    "              <td width=\"5%\" ng-hide=\"edit == f.id\">{{f.code}}</td>\n" +
    "              <td width=\"50%\" ng-hide=\"edit == f.id || feeprofileselected.has_insurance_fees === false\">{{f.name}}</td>\n" +
    "              <td width=\"14%\"  ng-hide=\"edit == f.id || feeprofileselected.has_insurance_fees === false\" ng-click=\"edit = f.id\">${{f.amount_innetwork_fee | number:2}}</td>\n" +
    "              <td width=\"14%\"  ng-hide=\"edit == f.id || feeprofileselected.has_insurance_fees === false\" ng-click=\"edit = f.id\">${{f.amount_outnetwork_fee | number:2}}</td>\n" +
    "              <td width=\"50%\" ng-hide=\"edit == f.id || feeprofileselected.has_insurance_fees === true\">{{f.name}}</td>\n" +
    "              <td width=\"10%\"  ng-hide=\"edit == f.id || feeprofileselected.has_insurance_fees === true\"  ng-click=\"edit = f.id\" >${{f.amount_patient | number:2}}</td>\n" +
    "              <td width=\"9%\" ng-hide=\"edit == f.id\">{{f.updated  | date:\"MM/dd/yyyy\"}}</td>\n" +
    "              <td width=\"8%\" ng-hide=\"edit == f.id\">\n" +
    "                <div class=\"btn-group\">\n" +
    "                <button ng-disabled=\"!CanUpdateFeeProfile\" type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"edit = f.id\">\n" +
    "                  <span class=\"icomoon icon-icomoon-pencil5\" aria-hidden=\"true\"></span>\n" +
    "                </button>\n" +
    "                </div>\n" +
    "              </td>\n" +
    "\n" +
    "              <!--  Editable fields start-->\n" +
    "\n" +
    "              <td width=\"5%\" ng-hide=\"edit != f.id\">\n" +
    "                  {{f.code}}\n" +
    "              </td>\n" +
    "              <td width=\"50%\" ng-hide=\"edit != f.id || feeprofileselected.has_insurance_fees === false\" >\n" +
    "                 {{f.name}}\n" +
    "              </td>\n" +
    "              <td width=\"14%\" ng-hide=\"edit != f.id || feeprofileselected.has_insurance_fees === false\" >\n" +
    "                <input type=\"number\" class=\"form-control\" ng-model=\"f.amount_innetwork_fee\" string-to-number required enter-input=\"edit = null; updateFee(f);\"></input>\n" +
    "              </td>\n" +
    "              <td width=\"14%\" ng-hide=\"edit != f.id || feeprofileselected.has_insurance_fees === false\" >\n" +
    "                <input type=\"number\" class=\"form-control\" ng-model=\"f.amount_outnetwork_fee\" string-to-number required enter-input=\"edit = null; updateFee(f);\"></input>\n" +
    "              </td>\n" +
    "              <td width=\"50%\" ng-hide=\"edit != f.id || feeprofileselected.has_insurance_fees === true\" >\n" +
    "                {{f.name}}\n" +
    "              </td>\n" +
    "              <td width=\"10%\" ng-hide=\"edit != f.id || feeprofileselected.has_insurance_fees === true\" >\n" +
    "                <input type=\"number\" class=\"form-control\" ng-model=\"f.amount_patient\" string-to-number required enter-input=\"edit = null; updateFee(f);\"></input>\n" +
    "              </td>\n" +
    "              <td width=\"9%\" ng-hide=\"edit != f.id\">\n" +
    "                  {{f.updated | date:\"MM/dd/yyyy\"}}\n" +
    "              </td>\n" +
    "              <td width=\"8%\" ng-hide=\"edit != f.id\" >\n" +
    "                <div class=\"btn-group\">\n" +
    "                  <button type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"edit = null\" >\n" +
    "                       <span class=\"icomoon icon-icomoon-minus2\" aria-hidden=\"true\"></span>\n" +
    "                  </button>\n" +
    "                  <button type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"edit = null; updateFee(f);\" >\n" +
    "                     <span class=\"icomoon icon-icomoon-download8\" aria-hidden=\"true\"></span>\n" +
    "                  </button>\n" +
    "                </div>\n" +
    "               </td>\n" +
    "              </tr>\n" +
    "             </tbody>\n" +
    "            </table>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <script type=\"text/ng-template\" id=\"delFeeprofile.html\">\n" +
    "    <div class=\"modal-header\">\n" +
    "      <h3 class=\"modal-title\">Delete fee profile?</h3>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body\">Are you sure you want to delete the fee schedule profile for this {{name}} company? <br><br> Deleting this fee schedule profile will also delete all procedure fees associated with it.  All patients associated with this fee schedule profile will have to be reassigned to a new fee schedule. </div>\n" +
    "    <div class=\"modal-footer\">\n" +
    "      <button class=\"btn btn-primary\" ng-click=\"ok()\">OK</button>\n" +
    "      <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "    </div>\n" +
    "  </script>\n" +
    "\n" +
    "  <script type=\"text/ng-template\" id=\"delFee.html\">\n" +
    "    <div class=\"modal-header\">\n" +
    "      <h3 class=\"modal-title\">Delete fee?</h3>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body\">Are you sure you want to delete fee {{name}} from the selected fee profile?</div>\n" +
    "    <div class=\"modal-footer\">\n" +
    "      <button class=\"btn btn-primary\" ng-click=\"ok()\">OK</button>\n" +
    "      <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "    </div>\n" +
    "  </script>\n" +
    "\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/settings-chart-templates.tpl.html","<encounter-template-page></encounter-template-page>")

$templateCache.put("src/common/settings-consent-forms-templates.tpl.html","  <consent-forms-template></consent-forms-template>")

$templateCache.put("src/common/settings-fee-profile-add.tpl.html","<form>\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Add Fee Schedule</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-2\">\n" +
    "        <label>Name</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <input class=\"form-control\" type=\"text\" ng-model=\"newFeeprofileName\" autofocus></input>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\">\n" +
    "      <div class=\"col-sm-2\">\n" +
    "        <label>Insurance</label>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <input type=\"checkbox\" ng-disabled=\"disablePayer\" ng-model=\"hasInsuranceFees\">\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group row\" ng-show=\"hasInsuranceFees\">\n" +
    "      <div class=\"col-sm-2\">\n" +
    "        <label>Payers</label>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"col-sm-8\">\n" +
    "        <ui-select ng-model=\"payer.id\" autofocus ng-required=\"true\">\n" +
    "          <ui-select-match placeholder=\"Select a Payer or Payer Id\">{{$select.selected.name}} ({{$select.selected.payer_id}})</ui-select-match>\n" +
    "          <ui-select-choices repeat=\"item.id as item in payers | filter: $select.search\">\n" +
    "            {{item.name}} ({{ item.payer_id }})<div ng-show=\"item.hasOwnProperty('payer_id') && !item.payer_id\" ><i class=\"icomoon icon-icomoon-warning2\" style=\"color:#F1C40F;\" title=\"payer no longer recognized by clearinghouse\"></i><small>payer no longer recognized by clearinghouse</small></div>\n" +
    "          </ui-select-choices>\n" +
    "        </ui-select>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"ok()\">OK</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "  </div>\n" +
    "</form>\n" +
    "")

$templateCache.put("src/common/settings-imaging.tpl.html","\n" +
    "<div ng-hide={{isInWizard}}>\n" +
    "   <settings-image-page></settings-image-page>\n" +
    "</div>")

$templateCache.put("src/common/settings-import-patients.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<h3 class=\"page-title\"> Import Patients\n" +
    "  <small></small>\n" +
    "</h3>\n" +
    "<div class=\"page-bar\" ng-hide=\"true\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "      <a ui-sref=\"home\">Home</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <a ui-sref=\"settings\">Settings</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>Import Patients</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-database-add font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Import Patients</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\"></div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        Placeholder\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/settings-insurance-payers-in-network-providers.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<div ng-hide={{isInWizard}}>\n" +
    "<!--\n" +
    "  <h3 class=\"page-title\"> Insurance Payers In-Network Providers\n" +
    "  </h3>\n" +
    "-->\n" +
    "  <div class=\"page-bar\">\n" +
    "    <ul class=\"page-breadcrumb\">\n" +
    "      <li>\n" +
    "        <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "        <a ui-sref=\"charts\">Home</a>\n" +
    "        <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "        <a ui-sref=\"settings\">Settings</a>\n" +
    "        <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      </li>\n" +
    "      <li>\n" +
    "        <span>Accounting and Billing</span>\n" +
    "      </li>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <li>\n" +
    "        <span>Associate Providers with Insurance Payers</span>\n" +
    "      </li>\n" +
    "    </ul>\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "\n" +
    "<div>\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-md-12\">\n" +
    "      <div class=\"portlet light porlet-fit\">\n" +
    "\n" +
    "        <div class=\"portlet-title\">\n" +
    "          <div class=\"caption\">\n" +
    "            <i class=\"icomoon icon-icomoon-lock font-red-sunglo\"></i>\n" +
    "            <span class=\"caption-subject font-red-sunglo bold uppercase\">Associate Providers with Insurance Payers</span>\n" +
    "          </div>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"portlet-body\">\n" +
    "\n" +
    "\n" +
    "          <div class=\"row\">\n" +
    "\n" +
    "            <div class=\"col-md-5\">\n" +
    "              <table class=\"table table-fixedheader table-striped table-condensed table-hover\">\n" +
    "                <thead>\n" +
    "                  <th width=\"100%\">Providers</th>\n" +
    "                </thead>\n" +
    "                <tbody>\n" +
    "                <tr ng-repeat=\"provider in insurancePayersInNetworkProviders.providers\"  ng-class=\"{active: provider.selected }\" ng-click=\"insurancePayersInNetworkProviders.providerSelect(provider)\">\n" +
    "                  <td width=\"100%\">{{provider.first_name + \" \" + provider.last_name}}</td>\n" +
    "                </tr>\n" +
    "                </tbody>\n" +
    "              </table>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "            <div class=\"col-md-5 col-md-offset-2\"  ng-if=\"!insurancePayersInNetworkProviders.selectedProvider\"><h3>Select a Provider First</h3></div>\n" +
    "\n" +
    "            <div class=\"col-md-5 col-md-offset-2\" ng-if=\"insurancePayersInNetworkProviders.selectedProvider\">\n" +
    "              <table class=\"table table-fixedheader table-striped table-condensed table-hover\">\n" +
    "                <thead>\n" +
    "                <tr>\n" +
    "                  <th width=\"10%\">&nbsp;</th>\n" +
    "                  <th  width=\"90%\">Payers</th>\n" +
    "                </tr>\n" +
    "                </thead>\n" +
    "                <tbody>\n" +
    "                <tr ng-repeat=\"payer in insurancePayersInNetworkProviders.payers\">\n" +
    "                  <td width=\"10%\">\n" +
    "                    <input ng-disabled=\"!insurancePayersInNetworkProviders.CanUpdateInsurancePayersInNetworkProvider\" type=\"checkbox\" ng-model=\"payer.selected\" ng-click=\"insurancePayersInNetworkProviders.payerSelect(payer)\">\n" +
    "                  </td>\n" +
    "                  <td width=\"90%\">{{payer.name}}</td>\n" +
    "                </tr>\n" +
    "                </tbody>\n" +
    "              </table>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/settings-insurance-plans-add.tpl.html","<form name=\"insurancePlanAdd\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Add Plan</h3>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"modal-body\">\n" +
    "\n" +
    "    <uib-tabset>\n" +
    "      <uib-tab index=\"0\" heading=\"Plan Information\">\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-sm-2\">\n" +
    "            <label>Payers</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-8\">\n" +
    "            <ui-select ui-select-no-animate ng-model=\"insurancePlansAdd.plan.payer\" ng-change=\"insurancePlansAdd.plan.feeprofile = undefined;\" >\n" +
    "              <ui-select-match placeholder=\"Search by Payer or Payer ID using at least 2 characters\">\n" +
    "                {{$select.selected.name}} ({{$select.selected.payer_id}})\n" +
    "              </ui-select-match>\n" +
    "              <ui-select-choices repeat=\"item.id as item in insurancePlansAdd.payers | propsFilter: {filter:$select.search, props:['name', 'payer_id'] }\">\n" +
    "                <span ng-bind-html=\"(item.name + ' (' + item.payer_id + ')') | highlight: $select.search\"></span>\n" +
    "                <div ng-if=\"item.hasOwnProperty('payer_id') && !item.payer_id\">\n" +
    "                  <i class=\"icomoon icon-icomoon-warning2\" style=\"color:#F1C40F;\" title=\"payer no longer recognized by clearinghouse\"></i>\n" +
    "                  <small>payer no longer recognized by clearinghouse</small>\n" +
    "                </div>\n" +
    "              </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <fieldset ng-disabled=\"!insurancePlansAdd.plan.payer\">\n" +
    "          <div class=\"form-group row\" ng-class=\"{ 'has-error' : insurancePlanAdd.groupId.$invalid}\">\n" +
    "            <div class=\"col-sm-2\">\n" +
    "              <label>Group Number</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "              <input type=\"text\" name=\"groupId\" class=\"form-control\" ng-required=\"true\" ng-model=\"insurancePlansAdd.plan.group_id\" placeholder=\"Group Number\" />\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group row\" ng-class=\"{ 'has-error' : insurancePlanAdd.planName.$invalid}\">\n" +
    "            <div class=\"col-sm-2\">\n" +
    "              <label>Plan Name</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "              <input type=\"text\" class=\"form-control\" name=\"planName\" ng-required=\"true\" ng-model=\"insurancePlansAdd.plan.name\" placeholder=\"Plan Name\" />\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group row\">\n" +
    "            <div class=\"col-sm-2\">\n" +
    "              <label>Company (Payer) Address</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "              <input type=\"text\" class=\"form-control\"\n" +
    "                     google-places-auto-complete\n" +
    "                     zip=\"insurancePlansAdd.plan.address_zip_code\"\n" +
    "                     state=\"insurancePlansAdd.plan.address_state\"\n" +
    "                     city=\"insurancePlansAdd.plan.address_city\"\n" +
    "                     placeholder=\"Claims Address\"\n" +
    "                     ng-model=\"insurancePlansAdd.plan.address_street_1\" autofocus />\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group row\">\n" +
    "            <div class=\"col-sm-2\">\n" +
    "              <label>Address Line 2</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "              <input type=\"text\" class=\"form-control\" ng-model=\"insurancePlansAdd.plan.address_street_2\" placeholder=\"Address Line 2\"/>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "          <div class=\"form-group row\">\n" +
    "\n" +
    "            <div class=\"col-sm-2\">\n" +
    "              <label>City, State and Zip</label>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"col-sm-3 form-group\">\n" +
    "              <input type=\"text\"\n" +
    "                     class=\"form-control\"\n" +
    "                     ng-model=\"insurancePlansAdd.plan.address_city\"\n" +
    "                     placeholder=\"City\"/>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"col-sm-2 form-group\" ng-class=\"{ 'has-error' : insurancePlanAdd.stateInput.$invalid}\">\n" +
    "              <state-input ng-model=\"insurancePlansAdd.plan.address_state\"\n" +
    "                           name=\"stateInput\"\n" +
    "                           ng-required=\"false\" ></state-input>\n" +
    "              <label class=\"control-label has-error validationMessage\" ng-show=\"insurancePlanAdd.stateInput.$invalid\">State should be 2 uppercase letters</label>\n" +
    "\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"col-sm-3 form-group\" ng-class=\"{ 'has-error' : insurancePlanAdd.zipInput.$invalid}\">\n" +
    "              <zip-input\n" +
    "                         ng-model=\"insurancePlansAdd.plan.address_zip_code\"\n" +
    "                         name=\"zipInput\"\n" +
    "                         ng-required=\"false\"> </zip-input>\n" +
    "              <label class=\"control-label has-error validationMessage\" ng-show=\"insurancePlanAdd.zipInput.$invalid\">Zip code is 5 digits</label>\n" +
    "            </div>\n" +
    "\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group row\">\n" +
    "            <div class=\"col-sm-2\">\n" +
    "              <label>Phone Number</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-md-3 col-sm-4\" ng-class=\"{ 'has-error' : insurancePlanAdd.phoneInput.$invalid}\">\n" +
    "              <phone-input ng-model=\"insurancePlansAdd.plan.phone\"\n" +
    "                           name=\"phoneInput\"\n" +
    "                           placeholder=\"Phone Number\"\n" +
    "                           ng-required=\"false\" ></phone-input>\n" +
    "              <label class=\"control-label has-error validationMessage\" ng-show=\"insurancePlanAdd.phoneInput.$invalid\">Phone number should match XXX-XXX-XXXX</label>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"col-sm-2\">\n" +
    "              <label>Fax Number</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-md-3 col-sm-4\" ng-class=\"{ 'has-error' : insurancePlanAdd.faxInput.$invalid}\">\n" +
    "              <phone-input ng-model=\"insurancePlansAdd.plan.fax\"\n" +
    "                           placeholder=\"Fax Phone\"\n" +
    "                           name=\"faxInput\"\n" +
    "                           ng-required=\"false\" ></phone-input>\n" +
    "              <label class=\"control-label has-error validationMessage\" ng-show=\"insurancePlanAdd.faxInput.$invalid\">Phone number should match XXX-XXX-XXXX</label>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "          <div class=\"form-group row\">\n" +
    "            <div class=\"col-sm-2\">\n" +
    "              <label>Website</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "              <input type=\"text\" class=\"form-control\" ng-model=\"insurancePlansAdd.plan.website\" placeholder=\"Website\" />\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group row\" ng-class=\"{ 'has-error' : insurancePlanAdd.feeprofile.$invalid}\">\n" +
    "            <div class=\"col-sm-2\">\n" +
    "              <label>Fee Schedule</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "              <p class=\"input-group\">\n" +
    "                <select class=\"form-control\" ng-model=\"insurancePlansAdd.plan.feeprofile\" name=\"feeprofile\" ng-required=\"true\" ng-options=\"feeprofile.id as feeprofile.name disable when feeprofile.is_archived for feeprofile in (insurancePlansAdd.feeprofiles  | filter:{ payer: insurancePlansAdd.plan.payer}:true)\">\n" +
    "                </select>\n" +
    "                <span class=\"input-group-btn\">\n" +
    "                  <button class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" type=\"button\" ng-click=\"insurancePlansAdd.openAddFeeprofileModal()\"><i class=\"icomoon icon-icomoon-plus2\"></i></button>\n" +
    "                </span>\n" +
    "              </p>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group row\">\n" +
    "            <div class=\"col-sm-2\">\n" +
    "              <label>Notes</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "                <textarea ng-model=\"insurancePlansAdd.plan.notes\" class=\"form-control\" rows=\"3\" maxlength=\"3000\" placeholder=\"Optional notes that display in plan tooltips...\" ng-blur=\"reportBlurred()\"></textarea>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "  \n" +
    "        </fieldset>\n" +
    "\n" +
    "      </uib-tab>\n" +
    "      <uib-tab index=\"1\" heading=\"Benefit Information\" ng-if=\"insurancePlansAdd.editPlan\">\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-sm-3\">\n" +
    "            <label>Maximums (In-network)</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-8\">\n" +
    "            <div class=\" form-group row\">\n" +
    "              <div class=\"col-sm-2\">\n" +
    "                <label>Annual</label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-4 has-feedback\"\n" +
    "                ng-class=\"{ 'has-error' : insurancePlanAdd.maximumamountannualinnetwork.$invalid}\"\n" +
    "              >\n" +
    "                <input type=\"text\"\n" +
    "                  class=\"form-control\"\n" +
    "                  name=\"maximumamountannualinnetwork\"\n" +
    "                  ng-model=\"insurancePlansAdd.plan.annual_maximum.amount_innetwork\"\n" +
    "                  ng-pattern=\"insurancePlansAdd.AMOUNT_REGEX\"\n" +
    "                  placeholder=\"Annual\"\n" +
    "                  required/>\n" +
    "                 </span>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\" form-group row\">\n" +
    "              <div class=\"col-sm-2\">\n" +
    "                <label>Lifetime</label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-4 has-feedback\"\n" +
    "                ng-class=\"{ 'has-error' : insurancePlanAdd.maximumamountlifetimeinnetwork.$invalid}\"\n" +
    "              >\n" +
    "                <input type=\"text\"\n" +
    "                  class=\"form-control\"\n" +
    "                  name=\"maximumamountlifetimeinnetwork\"\n" +
    "                  ng-model=\"insurancePlansAdd.plan.lifetime_maximum.amount_innetwork\"\n" +
    "                  ng-pattern=\"insurancePlansAdd.AMOUNT_REGEX\"\n" +
    "                  placeholder=\"Lifetime\"\n" +
    "                  required />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-sm-3\">\n" +
    "            <label>Maximums (Out-network)</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-8\">\n" +
    "            <div class=\" form-group row\">\n" +
    "              <div class=\"col-sm-2\">\n" +
    "                <label>Annual</label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-4 has-feedback\"\n" +
    "                ng-class=\"{ 'has-error' : insurancePlanAdd.maximumamountannualoutnetwork.$invalid}\"\n" +
    "              >\n" +
    "                <input type=\"text\"\n" +
    "                  class=\"form-control\"\n" +
    "                  name=\"maximumamountannualoutnetwork\"\n" +
    "                  ng-model=\"insurancePlansAdd.plan.annual_maximum.amount_outnetwork\"\n" +
    "                  ng-pattern=\"insurancePlansAdd.AMOUNT_REGEX\"\n" +
    "                  placeholder=\"Annual\"\n" +
    "                  required/>\n" +
    "                 </span>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\" form-group row\">\n" +
    "              <div class=\"col-sm-2\">\n" +
    "                <label>Lifetime</label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-4 has-feedback\"\n" +
    "                ng-class=\"{ 'has-error' : insurancePlanAdd.maximumamountlifetimeoutnetwork.$invalid}\"\n" +
    "              >\n" +
    "                <input type=\"text\"\n" +
    "                  class=\"form-control\"\n" +
    "                  name=\"maximumamountlifetimeoutnetwork\"\n" +
    "                  ng-model=\"insurancePlansAdd.plan.lifetime_maximum.amount_outnetwork\"\n" +
    "                  ng-pattern=\"insurancePlansAdd.AMOUNT_REGEX\"\n" +
    "                  placeholder=\"Lifetime\"\n" +
    "                  required />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "              <small class=\"help-block\"><code>0</code> or <code>0.00</code> disables maximum (no maximum).</small>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-sm-3\">\n" +
    "            <label>Deductibles (in-network)</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-8\">\n" +
    "            <div class=\" form-group row\">\n" +
    "              <div class=\"col-sm-2\">\n" +
    "                <label>Preventative</label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-4 has-feedback\"\n" +
    "                ng-class=\"{ 'has-error' : insurancePlanAdd.deductiblepreventativeamountinnetwork.$invalid}\"\n" +
    "              >\n" +
    "                <input type=\"text\"\n" +
    "                  class=\"form-control\"\n" +
    "                  name=\"deductiblepreventativeamountinnetwork\"\n" +
    "                  ng-model=\"insurancePlansAdd.plan.deductiblesNamed['Preventative'].amount_innetwork\"\n" +
    "                  autofocus\n" +
    "                  ng-pattern=\"insurancePlansAdd.AMOUNT_REGEX\"\n" +
    "                  placeholder=\"Preventative\"\n" +
    "                  required />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\" form-group row\">\n" +
    "              <div class=\"col-sm-2\">\n" +
    "                <label>Basic</label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-4 has-feedback\"\n" +
    "                ng-class=\"{ 'has-error' : insurancePlanAdd.deductiblebasicamountinnetwork.$invalid}\"\n" +
    "              >\n" +
    "                <input type=\"text\"\n" +
    "                  class=\"form-control\"\n" +
    "                  name=\"deductiblebasicamountinnetwork\"\n" +
    "                  ng-model=\"insurancePlansAdd.plan.deductiblesNamed['Basic'].amount_innetwork\"\n" +
    "                  ng-pattern=\"insurancePlansAdd.AMOUNT_REGEX\"\n" +
    "                  placeholder=\"Basic\"\n" +
    "                  required/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\" form-group row\">\n" +
    "              <div class=\"col-sm-2\">\n" +
    "                <label>Major</label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-4 has-feedback\"\n" +
    "                ng-class=\"{ 'has-error' : insurancePlanAdd.deductiblemajoramountinnetwork.$invalid}\"\n" +
    "              >\n" +
    "                <input type=\"text\"\n" +
    "                  class=\"form-control\"\n" +
    "                  name=\"deductiblemajoramountinnetwork\"\n" +
    "                  ng-model=\"insurancePlansAdd.plan.deductiblesNamed['Major'].amount_innetwork\"\n" +
    "                  ng-pattern=\"insurancePlansAdd.AMOUNT_REGEX\"\n" +
    "                  placeholder=\"Major\"\n" +
    "                  required/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\" form-group row\">\n" +
    "              <div class=\"col-sm-2\">\n" +
    "                <label>Orthodontic</label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-4 has-feedback\"\n" +
    "                ng-class=\"{ 'has-error' : insurancePlanAdd.deductibleorthodonticamountinnetwork.$invalid}\"\n" +
    "              >\n" +
    "                <input type=\"text\"\n" +
    "                  class=\"form-control\"\n" +
    "                  name=\"deductibleorthodonticamountinnetwork\"\n" +
    "                  ng-model=\"insurancePlansAdd.plan.deductiblesNamed['Orthodontic'].amount_innetwork\"\n" +
    "                  ng-pattern=\"insurancePlansAdd.AMOUNT_REGEX\"\n" +
    "                  placeholder=\"Orthodontic\"\n" +
    "                  required/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group row\">\n" +
    "          <div class=\"col-sm-3\">\n" +
    "            <label>Deductibles (out-network)</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-8\">\n" +
    "            <div class=\" form-group row\">\n" +
    "              <div class=\"col-sm-2\">\n" +
    "                <label>Preventative</label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-4 has-feedback\"\n" +
    "                ng-class=\"{ 'has-error' : insurancePlanAdd.deductiblepreventativeamountoutnetwork.$invalid}\"\n" +
    "              >\n" +
    "                <input type=\"text\"\n" +
    "                  class=\"form-control\"\n" +
    "                  name=\"deductiblepreventativeamountoutnetwork\"\n" +
    "                  ng-model=\"insurancePlansAdd.plan.deductiblesNamed['Preventative'].amount_outnetwork\"\n" +
    "                  autofocus\n" +
    "                  ng-pattern=\"insurancePlansAdd.AMOUNT_REGEX\"\n" +
    "                  placeholder=\"Preventative\"\n" +
    "                  required />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\" form-group row\">\n" +
    "              <div class=\"col-sm-2\">\n" +
    "                <label>Basic</label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-4 has-feedback\"\n" +
    "                ng-class=\"{ 'has-error' : insurancePlanAdd.deductiblebasicamountoutnetwork.$invalid}\"\n" +
    "              >\n" +
    "                <input type=\"text\"\n" +
    "                  class=\"form-control\"\n" +
    "                  name=\"deductiblebasicamountoutnetwork\"\n" +
    "                  ng-model=\"insurancePlansAdd.plan.deductiblesNamed['Basic'].amount_outnetwork\"\n" +
    "                  ng-pattern=\"insurancePlansAdd.AMOUNT_REGEX\"\n" +
    "                  placeholder=\"Basic\"\n" +
    "                  required/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\" form-group row\">\n" +
    "              <div class=\"col-sm-2\">\n" +
    "                <label>Major</label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-4 has-feedback\"\n" +
    "                ng-class=\"{ 'has-error' : insurancePlanAdd.deductiblemajoramountoutnetwork.$invalid}\"\n" +
    "              >\n" +
    "                <input type=\"text\"\n" +
    "                  class=\"form-control\"\n" +
    "                  name=\"deductiblemajoramountoutnetwork\"\n" +
    "                  ng-model=\"insurancePlansAdd.plan.deductiblesNamed['Major'].amount_outnetwork\"\n" +
    "                  ng-pattern=\"insurancePlansAdd.AMOUNT_REGEX\"\n" +
    "                  placeholder=\"Major\"\n" +
    "                  required/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\" form-group row\">\n" +
    "              <div class=\"col-sm-2\">\n" +
    "                <label>Orthodontic</label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-4 has-feedback\"\n" +
    "                ng-class=\"{ 'has-error' : insurancePlanAdd.deductibleorthodonticamountoutnetwork.$invalid}\"\n" +
    "              >\n" +
    "                <input type=\"text\"\n" +
    "                  class=\"form-control\"\n" +
    "                  name=\"deductibleorthodonticamountoutnetwork\"\n" +
    "                  ng-model=\"insurancePlansAdd.plan.deductiblesNamed['Orthodontic'].amount_outnetwork\"\n" +
    "                  ng-pattern=\"insurancePlansAdd.AMOUNT_REGEX\"\n" +
    "                  placeholder=\"Orthodontic\"\n" +
    "                  required/>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\" form-group row\">\n" +
    "              <small class=\"help-block\"\n" +
    "                ng-if=\"\n" +
    "                  insurancePlanAdd.maximumamountannualinnetwork.$invalid || insurancePlanAdd.maximumamountlifetimeinnetwork.$invalid || insurancePlanAdd.deductiblepreventativeamountinnetwork.$invalid || insurancePlanAdd.deductiblebasicamountinnetwork.$invalid || insurancePlanAdd.deductiblemajoramountinnetwork.$invalid || insurancePlanAdd.deductibleorthodonticamountinnetwork.$invalid ||\n" +
    "                  insurancePlanAdd.maximumamountannualoutnetwork.$invalid || insurancePlanAdd.maximumamountlifetimeoutnetwork.$invalid || insurancePlanAdd.deductiblepreventativeamountoutnetwork.$invalid || insurancePlanAdd.deductiblebasicamountoutnetwork.$invalid || insurancePlanAdd.deductiblemajoramountoutnetwork.$invalid || insurancePlanAdd.deductibleorthodonticamountoutnetwork.$invalid\"\n" +
    "              >\n" +
    "                Amount must be a whole number or a decimal number to the hundredths (e.g. 6.23).\n" +
    "              </small>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </uib-tab>\n" +
    "      <uib-tab index=\"2\" heading=\"Coverage Details\" ng-if=\"insurancePlansAdd.editPlan\">\n" +
    "        <table class=\"table table-fixedheader table-striped table-condensed\">\n" +
    "          <thead>\n" +
    "            <tr>\n" +
    "              <th width=\"40%\">Procedure</th>\n" +
    "              <th width=\"10%\">In-Network Coverage (fraction)</th>\n" +
    "              <th width=\"10%\">Out of Network Coverage (fraction)</th>\n" +
    "              <th width=\"25%\">Deductible</th>\n" +
    "              <th width=\"10%\"></th>\n" +
    "            </tr>\n" +
    "          </thead>\n" +
    "          <tbody>\n" +
    "            <tr ng-repeat=\"p in insurancePlansAdd.plan.planProcedures | filter: {is_active: true} | orderBy: 'code'\">\n" +
    "              <td width=\"40%\">\n" +
    "                 {{p.code}}-{{p.name}}\n" +
    "              </td>\n" +
    "              <td width=\"10%\">\n" +
    "                <input type=\"text\" class=\"form-control\" ng-model=\"p.planProcedureAssoc.proportion_innetwork_covered\" ui-percentage-mask=\"0\" min=\"0\" max=\"100\" range-min=\"0\" range-max=\"1\" number-range-limit >\n" +
    "              </td>\n" +
    "              <td width=\"10%\">\n" +
    "                <input type=\"text\" class=\"form-control\" ng-model=\"p.planProcedureAssoc.proportion_outnetwork_covered\" ui-percentage-mask=\"0\" min=\"0\" max=\"100\" range-min=\"0\" range-max=\"1\" number-range-limit  >\n" +
    "              </td>\n" +
    "              <td width=\"25%\">\n" +
    "                <select class=\"form-control\" ng-model=\"p.deductibleId\" ng-options=\"deductible.id as deductible.name for deductible in insurancePlansAdd.plan.deductibles\">\n" +
    "                  <option value=\"\"></option>\n" +
    "                </select>\n" +
    "              </td>\n" +
    "              <td width=\"10%\">\n" +
    "                <button ng-click=\"p.show = !p.show\" ng-if=\"!p.show\" type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\">\n" +
    "                  <i class=\"icomoon icon-icomoon-copy\" uib-tooltip=\"Copy this fee\" aria-hidden=\"true\"></i>\n" +
    "                </button>\n" +
    "                <select ng-if=\"p.show\" ng-model=\"p.copyProcedureSettingThroughProcedureCode\" ng-change=\"copyProcedureSettingThrough(p); p.copyProcedureSettingThroughProcedureCode='';\" class=\"form-control\" ng-options=\"p.code as p.code + ' - ' + p.name for p in insurancePlansAdd.plan.planProcedures | filter: procedureCodeGreaterThan(p.code)  | orderBy: 'code'\">\n" +
    "                  <option value=\"\">--Copy through--</option>\n" +
    "                </select>\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "          </tbody>\n" +
    "        </table>\n" +
    "      </uib-tab>\n" +
    "    </uib-tabset>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"modal-footer\" ng-if=\"!insurancePlansAdd.editPlan\">\n" +
    "    <button class=\"btn p-x btn-primary\" ng-disabled=\"insurancePlanAdd.$invalid || !insurancePlansAdd.CanCreateInsurancePlan\" ng-click=\"insurancePlansAdd.save(insurancePlansAdd.plan)\">Create</button>\n" +
    "    <button class=\"btn p-x btn-default\" ng-click=\"insurancePlansAdd.done(insurancePlansAdd.plan)\">Close</button>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <div class=\"modal-footer\" ng-if=\"insurancePlansAdd.editPlan\">\n" +
    "    <button class=\"btn p-x btn-primary\" ng-disabled=\"insurancePlanAdd.$invalid || !insurancePlansAdd.CanUpdateInsurancePlan || saving\" ng-click=\"insurancePlansAdd.save(insurancePlansAdd.plan)\">Update</button>\n" +
    "    <button class=\"btn p-x btn-default\" ng-disabled=\"saving\" ng-click=\"insurancePlansAdd.done(insurancePlansAdd.plan)\">Close</button>\n" +
    "  </div>\n" +
    "</form>\n" +
    "")

$templateCache.put("src/common/settings-insurance-plans.tpl.html","<insurance-plan></insurance-plan>\n" +
    "\n" +
    "<!-- <div class=\"page-bar\" ng-hide=\"{{ isInWizard }}\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "      <a ui-sref=\"charts\">Home</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <a ui-sref=\"settings\">Settings</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>Accounting and Billing</span>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <span>Insurance Plans</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"portlet light porlet-fit\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption\">\n" +
    "      <i class=\"icomoon icon-icomoon-lock font-red-sunglo\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold uppercase\">Insurance Plans</span>\n" +
    "    </div>\n" +
    "    <div class=\"actions\">\n" +
    "      <div class=\"checkbox-inline custom-control custom-checkbox m-r\">\n" +
    "        <label>\n" +
    "          <input type=\"checkbox\" ng-model=\"insurancePlans.showArchived\" />\n" +
    "          <span class=\"custom-control-indicator\"></span>\n" +
    "          Show Archived?\n" +
    "        </label>\n" +
    "      </div>\n" +
    "      <div class=\"btn-group pull-right\">\n" +
    "        <button type=\"button\" class=\"btn p-x btn-primary\" ng-disabled=\"!insurancePlans.CanRetrieveInsurancePlan || !insurancePlans.CanCreateInsurancePlan\" ng-click=\"insurancePlans.add()\">Create</button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body\">\n" +
    "    <input type=\"text\" class=\"form-control m-b\" placeholder=\"Search\" ng-model=\"insurancePlans.searchTerm\">\n" +
    "    <table class=\"table table-striped table-bordered table-advance\">\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th ng-click=\"insurancePlans.orderByClick('name')\" style=\"cursor:pointer\">\n" +
    "            Plan\n" +
    "            <span class=\"pull-right\" ng-show=\"insurancePlans.orderByProp == 'name'\">\n" +
    "              <i ng-hide=\"insurancePlans.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "              <i ng-show=\"insurancePlans.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "            </span>\n" +
    "          </th>\n" +
    "          <th ng-click=\"insurancePlans.orderByClick('group_id')\" style=\"cursor:pointer\">\n" +
    "            Group Number\n" +
    "            <span class=\"pull-right\" ng-show=\"insurancePlans.orderByProp == 'group_id'\">\n" +
    "              <i ng-hide=\"insurancePlans.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "              <i ng-show=\"insurancePlans.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "            </span>\n" +
    "          </th>\n" +
    "          <th ng-click=\"insurancePlans.orderByClick('payer.name')\" style=\"cursor:pointer\">\n" +
    "            Payer\n" +
    "            <span class=\"pull-right\" ng-show=\"insurancePlans.orderByProp == 'payer.name'\">\n" +
    "              <i ng-hide=\"insurancePlans.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "              <i ng-show=\"insurancePlans.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "            </span>\n" +
    "          </th>\n" +
    "          <th ng-if=\"insurancePlans.showArchived\">\n" +
    "            <i class=\"icomoon icon-icomoon-box\" style=\"color: #3f444a; font-size: 16px; padding-left: 4px; vertical-align: middle\"\n" +
    "              uib-tooltip=\"Is Archived\">\n" +
    "            </i>\n" +
    "          </th>\n" +
    "          <th class=\"col-narrow\" style=\"min-width:81px\"></th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr\n" +
    "          ng-if=\"insurancePlans.isVisible(plan)\"\n" +
    "          ng-repeat=\"\n" +
    "            plan in insurancePlans.plans |\n" +
    "            propsFilter: { filter:insurancePlans.searchTerm, props:['name', 'group_id', 'payer.name'] } |\n" +
    "            orderBy: insurancePlans.orderByProp:insurancePlans.orderByReverse\">\n" +
    "          <td ng-bind-html=\"plan.name | highlight:insurancePlans.searchTerm\" ng-attr-title=\"{{plan.notes}}\"></td>\n" +
    "          <td ng-bind-html=\"plan.group_id | highlight:insurancePlans.searchTerm\"></td>\n" +
    "          <td ng-bind-html=\"plan.payer.name | highlight:insurancePlans.searchTerm\"></td>\n" +
    "          <td class=\"td-switch-small-ct\" ng-if=\"insurancePlans.showArchived\">\n" +
    "            <div class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "              <label>\n" +
    "                <input\n" +
    "                  type=\"checkbox\"\n" +
    "                  ng-change=\"insurancePlans.update(plan, ['is_archived'])\"\n" +
    "                  ng-model=\"plan.is_archived\"\n" +
    "                />\n" +
    "                <span class=\"custom-control-indicator\"></span>\n" +
    "              </label>\n" +
    "            </div>\n" +
    "          </td>\n" +
    "          <td class=\"btn-container\">\n" +
    "            <div class=\"btn-group\">\n" +
    "              <button type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"insurancePlans.edit(plan)\" ng-disabled=\"!insurancePlans.CanRetrieveInsurancePlan\">\n" +
    "                <i class=\"icomoon icon-icomoon-pencil5\" aria-hidden=\"true\"></i>\n" +
    "              </button>\n" +
    "              <button type=\"button\" class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"insurancePlans.delete(plan)\" ng-disabled=\"!insurancePlans.CanDeleteInsurancePlan\">\n" +
    "                <i class=\"icomoon icon-icomoon-bin\" aria-hidden=\"true\"></i>\n" +
    "              </button>\n" +
    "            </div>\n" +
    "          </td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "      <tfoot>\n" +
    "        <tr ng-if=\"!insurancePlans.CanListInsurancePlan\">\n" +
    "          <td class=\"text-center\" colspan=3>You do not have permission to <b>list</b> Insurance Plans</td>\n" +
    "        </tr>\n" +
    "      </tfoot>\n" +
    "    </table>\n" +
    "  </div>\n" +
    "</div> -->\n" +
    "")

$templateCache.put("src/common/settings-locations-add.tpl.html","<form>\n" +
    "<div class=\"modal-header\">\n" +
    "    <h3 ng-show=\"isNewOperatory\" class=\"modal-title\">New Operatory</h3>\n" +
    "    <h3 ng-hide=\"isNewOperatory\" class=\"modal-title\">Edit Operatory</h3>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body\">\n" +
    "\n" +
    "  <div class=\"form-group row\">\n" +
    "    <div class=\"col-sm-4\">\n" +
    "      <label>Name</label>\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-8\">\n" +
    "      <input type=\"text\" class=\"form-control\" ng-model=\"name\" autofocus />\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "<div ng-show=\"isNewOperatory\" class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"createOrUpdate()\">Create</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>\n" +
    "\n" +
    "<div ng-hide=\"isNewOperatory\" class=\"modal-footer\">\n" +
    "  <button class=\"btn btn-primary\" ng-disabled=\"location.$invalid\" ng-click=\"createOrUpdate()\">Update</button>\n" +
    "  <button ng-hide=\"true\" class=\"btn btn-danger\" ng-click=\"delete()\">Delete</button>\n" +
    "  <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>\n" +
    "</form>\n" +
    "")

$templateCache.put("src/common/settings-medical-alerts.tpl.html","<settings-medical-alert></settings-medical-alert>\n" +
    "")

$templateCache.put("src/common/settings-practice-appointment-defaults.tpl.html","<practice-appointment-default-setting></practice-appointment-default-setting>")

$templateCache.put("src/common/settings-practice-details.tpl.html","<location-and-operatories-page multi-location=\"MULTILOCATION_ENABLE\"></location-and-operatories-page>")

$templateCache.put("src/common/settings-practice.tpl.html"," <practice-general-settings save-function=\"save\" update-practice-picture=\"updatePracticePicture\" choice=\"practice_schema.actions.PUT.type.choices\" practice=\"practice\"></practice-general-settings>\n" +
    "")

$templateCache.put("src/common/settings-prescriptions-templates-add.tpl.html","<fieldset>\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\" ng-if=\"!isEdit\">Add Prescription Template</h3>\n" +
    "    <h3 class=\"modal-title\" ng-if=\"isEdit\">Edit Prescription Template</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <form name='myForm'>\n" +
    "\n" +
    "      <div class=\"form-group\" ng-class=\"{ 'has-success': myForm.templatename.$valid, 'has-error': myForm.templatename.$invalid}\">\n" +
    "        <label>Template Name:</label>\n" +
    "        <input name='templatename' class=\"form-control\" type=\"text\" ng-model=\"template.name\">\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"row\">\n" +
    "\n" +
    "        <div class=\"col-md-6\">\n" +
    "          <div class=\"form-group\" ng-class=\"{ 'has-success': myForm.drugname.$valid, 'has-error': myForm.drugname.$invalid}\">\n" +
    "            <label for=\"drug\">Drug:</label>\n" +
    "            <input  ng-disabled=\"viewOnly\" id=\"drug\" name='drugname' class=\"form-control\" type=\"text\" ng-model=\"template.drug\" required></input>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-md-6\">\n" +
    "          <div class=\"form-group\" ng-class=\"{ 'has-success': myForm.dosagename.$valid, 'has-error': myForm.dosagename.$invalid}\">\n" +
    "            <label for=\"dosage\">Dosage:</label>\n" +
    "            <input id=\"dosage\"  ng-disabled=\"viewOnly\" name='dosagename' class=\"form-control\" type=\"text\" ng-model=\"template.dosage\" required></input>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "\n" +
    "\n" +
    "      <div class=\"row\">\n" +
    "        <div class=\"col-md-6\">\n" +
    "          <div class=\"form-group\" ng-class=\"{ 'has-success': myForm.quantityname.$valid, 'has-error': myForm.quantityname.$invalid}\">\n" +
    "            <label for=\"quantity\">Quantity:</label>\n" +
    "            <input id=\"quantity\"  ng-disabled=\"viewOnly\" min=1 max=1000 name='quantityname' class=\"form-control\" type=\"text\" ng-model=\"template.quantity\" required></input>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-md-6\">\n" +
    "          <div class=\"form-group\" ng-class=\"{ 'has-success': myForm.number_of_refills.$valid, 'has-error': myForm.number_of_refills.$invalid}\">\n" +
    "            <label for=\"refills\">Refills:</label>\n" +
    "            <input id=\"refills\" ng-disabled=\"viewOnly\" min=0 max=100 name='number_of_refills' class=\"form-control\" type=\"number\" ng-model=\"template.number_of_refills\" required></input>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"form-group\">\n" +
    "        <label for=\"instructions\">Instructions:</label>\n" +
    "        <textarea id=\"instructions\"  ng-disabled=\"viewOnly\" class=\"form-control\" type=\"text\" ng-model=\"template.instructions\"></textarea >\n" +
    "      </div>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "</fieldset>\n" +
    "\n" +
    "\n" +
    "\n" +
    "\n" +
    "<div class=\"modal-footer\" ng-if=\"template.id\">\n" +
    "  <button class=\"btn btn-primary\" ng-disabled=\"myForm.$invalid || !CanUpdatePrescriptionTemplate\" ng-click=\"save(template)\">Update</button>\n" +
    "  <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\" ng-if=\"!template.id\">\n" +
    "  <button class=\"btn btn-primary\" ng-disabled=\"myForm.$invalid || !CanCreatePrescriptionTemplate\" ng-click=\"save(template)\">Create</button>\n" +
    "  <button class=\"btn btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/settings-prescriptions-templates.tpl.html","<div ng-hide=\"{{isInWizard}}\">\n" +
    "  <prescriptions></prescriptions>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/settings-procedures.tpl.html","<account-billing-procedures></account-billing-procedures>")

$templateCache.put("src/common/settings-reports-templates.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<h3 class=\"page-title\"> Reports Templates\n" +
    "  <small></small>\n" +
    "</h3>\n" +
    "<div class=\"page-bar\" ng-hide=\"true\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "      <a ui-sref=\"home\">Home</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <a ui-sref=\"settings\">Settings</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>Reports Templates</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-stack font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Reports Templates</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\"></div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        Placeholder\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/settings-schedule.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<h3 class=\"page-title\"> Schedule Settings\n" +
    "  <small></small>\n" +
    "</h3>\n" +
    "<div class=\"page-bar\" ng-hide=\"true\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "      <a ui-sref=\"home\">Home</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <a ui-sref=\"settings\">Settings</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>Schedule Settings</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-calendar font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Schedule Settings</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\"></div>\n" +
    "      </div>\n" +
    "      <div class=\"portlet-body\">\n" +
    "        Placeholder\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/settings-security-questions.tpl.html","<!-- BEGIN PAGE HEADER-->\n" +
    "<div  ng-hide={{isInWizard}}>\n" +
    "  <!--\n" +
    "  <h3 class=\"page-title\"> Security Questions\n" +
    "    <small></small>\n" +
    "  </h3>\n" +
    "  -->\n" +
    "  <div class=\"page-bar\">\n" +
    "    <ul class=\"page-breadcrumb\">\n" +
    "      <li>\n" +
    "        <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "        <a ui-sref=\"charts\">Home</a>\n" +
    "        <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "        <a ui-sref=\"settings\">Settings</a>\n" +
    "        <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      </li>\n" +
    "      <li>\n" +
    "        <span>User Account</span>\n" +
    "      </li>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <li>\n" +
    "        <span>Security Questions</span>\n" +
    "      </li>\n" +
    "    </ul>\n" +
    "  </div>\n" +
    "</div>\n" +
    "<!-- END PAGE HEADER-->\n" +
    "\n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-lock font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">User Account - Security Questions Settings</span>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"portlet-body\">\n" +
    "        <form role=\"form\" name=\"securityForm\"\n" +
    "              angular-validator angular-validator-submit=\"submit(securityForm)\" novalidate>\n" +
    "\n" +
    "          <div class=\"form-group row\" ng-repeat=\"key in keys\">\n" +
    "            <div class=\"col-sm-12\">\n" +
    "              <label>Security Question #{{ $index + 1 }}</label>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"col-sm-12\" >\n" +
    "              <select class=\"form-control\"\n" +
    "                      name=\"{{'question' + key}}\"\n" +
    "                      ng-options=\"question for question in getSecurityQuestions(key)\"\n" +
    "                      ng-model=\"account['security_question_' + key]\"\n" +
    "                      required>\n" +
    "                <option value=\"\">Select a question</option>\n" +
    "              </select>\n" +
    "            </div>\n" +
    "            <!--<div class=\"col-sm-2\">-->\n" +
    "              <!--<label>Security Answer #{{ $index }}</label>-->\n" +
    "            <!--</div>-->\n" +
    "            <div class=\"col-sm-12\" style=\"margin-top:15px;\">\n" +
    "              <input type=\"text\" class=\"form-control\"\n" +
    "                     name=\"{{'answer' + key}}\"\n" +
    "                     placeholder=\"Type your answer\"\n" +
    "                     ng-model=\"account['security_answer_' + key]\"\n" +
    "                     required/>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <button type=\"submit\" class=\"btn p-x btn-primary\">Save</button>\n" +
    "        </form>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/settings-text-templates-edit.tpl.html","<div class=\"modal-header\">\n" +
    "  <h3 class=\"modal-title\" ng-if=\"!isEdit\">{{template.type}}</h3>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body\">\n" +
    "\n" +
    "  <form>\n" +
    "    <div ng-if=\"template.has_params\">\n" +
    "      Params <br></br>\n" +
    "      <div ng-repeat=\"param in template.valid_params\" class=\"chip\">\n" +
    "        {{param}}\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <br></br>\n" +
    "\n" +
    "    <div class=\"form-group\" ng-if=\"template.has_title\">\n" +
    "      <label>Title</label>\n" +
    "      <input type=\"text\" class=\"form-control\" ng-model=\"template.title\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "      <label>Content</label>\n" +
    "      <textarea class=\"form-control\" rows=\"5\" ng-model=\"template.content\"></textarea>\n" +
    "    </div>\n" +
    "  </form>\n" +
    "\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "  <button class=\"btn btn-default\" style=\"float: left\" ng-click=\"cancel()\">Cancel</button>\n" +
    "  <button class=\"btn btn-default\" type=\"button\" ng-disabled=\"!CanUpdateMessageTemplate\" ng-click=\"reset()\">Reset to Defaults</button>\n" +
    "  <button style=\"float: right\" class=\"btn btn-primary\" ng-disabled=\"!CanUpdateMessageTemplate\" type=\"button\" ng-click=\"update(template)\">Update\n" +
    "  </button>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/settings-text-templates.tpl.html","<div ng-hide=\"{{isInWizard}}\">\n" +
    "  <messages></messages>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/settings-two-factor-setup-app.tpl.html","<div class=\"row\">\n" +
    "  <div class=\"col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3\">\n" +
    "    <h1>\n" +
    "      {{ pageTitle }}\n" +
    "    </h1>\n" +
    "\n" +
    "    <p>To start using a token generator:</p>\n" +
    "\n" +
    "    <form role=\"form\" name=\"tokenForm\"\n" +
    "          angular-validator angular-validator-submit=\"submit(tokenForm)\" novalidate>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label>\n" +
    "          1. To start using a token generator, please use your smartphone to scan the QR code below.\n" +
    "          <br>\n" +
    "          For example, <a href=\"https://freeotp.github.io/\" target=\"_blank\">FreeOTP</a> or Google Authenticator.\n" +
    "        </label>\n" +
    "\n" +
    "        <div class=\"text-center\">\n" +
    "          <div class=\"loading\">\n" +
    "            <label>Loading your QR Code...</label>\n" +
    "          </div>\n" +
    "          <img width=\"200px\" height=\"auto\"\n" +
    "            ng-src=\"/api/v1/two-factor/qrcode/?nocache={{ nocache }}\"\n" +
    "            onload=\"angular.element('.loading').hide();\">\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"form-group\">\n" +
    "        <label for=\"id_token\">2. Enter the token generated by the app</label>\n" +
    "        <input class=\"form-control\" id=\"id_token\"\n" +
    "               max=\"999999\" min=\"0\"\n" +
    "               ng-change=\"serverError = false\"\n" +
    "               name=\"token\" type=\"number\"\n" +
    "               ng-model=\"device.token\"\n" +
    "               validate-on=\"blur\"\n" +
    "               validator=\"serverError !== true\"\n" +
    "               required-message=\"'This field is required'\"\n" +
    "               invalid-message=\"'Invalid token, please correct it and try again'\"\n" +
    "               required>\n" +
    "      </div>\n" +
    "\n" +
    "      <div>\n" +
    "        <a ui-sref=\"^\" type=\"button\" class=\"btn btn-default\">Back</a>\n" +
    "        <button type=\"submit\" class=\"btn btn-primary\">Submit</button>\n" +
    "        <a ui-sref=\"settings-two-factor\" type=\"button\"\n" +
    "           class=\"btn btn-default pull-right\">Cancel</a>\n" +
    "      </div>\n" +
    "\n" +
    "    </form>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/settings-two-factor-setup-backup-tokens.tpl.html","<settings-backup-tokens-page></settings-backup-tokens-page>\n" +
    "\n" +
    "")

$templateCache.put("src/common/settings-two-factor-setup-method.tpl.html","<div class=\"row\">\n" +
    "  <div class=\"col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3\">\n" +
    "    <h1>Enable Two-Factor Authentication</h1>\n" +
    "\n" +
    "    <label>Please select which authentication method you would\n" +
    "      like to use.</label>\n" +
    "\n" +
    "    <form name=\"twoFactorMethod\">\n" +
    "        <div class=\"form-group\">\n" +
    "          <div ng-repeat=\"method in methods\" class=\"radio\">\n" +
    "            <label>\n" +
    "              <input type=\"radio\" id=\"id_two-factor-method-{{ $index }}\" name=\"two-factor-method\"\n" +
    "                     ng-model=\"nextState.method\" ng-value=\"method\">\n" +
    "              {{ method.text }}\n" +
    "            </label>\n" +
    "          </div>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "      <div>\n" +
    "        <a ui-sref=\"^\" type=\"button\" class=\"btn btn-default\">Back</a>\n" +
    "        <button ng-click=\"goToForm()\" type=\"submit\" class=\"btn btn-primary\">Next</button>\n" +
    "        <a ui-sref=\"settings-two-factor\" type=\"button\" class=\"btn btn-default pull-right\">Cancel</a>\n" +
    "      </div>\n" +
    "    </form>\n" +
    "\n" +
    "  </div>\n" +
    "</div>")

$templateCache.put("src/common/settings-two-factor-setup-phone-verify.tpl.html","<div class=\"row\">\n" +
    "  <div class=\"col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3\">\n" +
    "    <h1>{{ pageTitle }}</h1>\n" +
    "\n" +
    "    <p>{{ action }}, please enter the provided token.</p>\n" +
    "\n" +
    "    <form name=\"tokenForm\"\n" +
    "          angular-validator angular-validator-submit=\"submit(tokenForm)\" novalidate>\n" +
    "\n" +
    "      <div class=\"form-group\">\n" +
    "        <label for=\"id_token\">Token</label>\n" +
    "        <input class=\"form-control\" id=\"id_token\"\n" +
    "               max=\"999999\" min=\"0\"\n" +
    "               ng-change=\"serverError = false\"\n" +
    "               name=\"token\" type=\"number\"\n" +
    "               ng-model=\"device.token\"\n" +
    "               validate-on=\"blur\"\n" +
    "               validator=\"serverError !== true\"\n" +
    "               required-message=\"'This field is required'\"\n" +
    "               invalid-message=\"'Invalid token, please correct it and try again'\"\n" +
    "               required>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"form-group\">\n" +
    "        <a ui-sref=\"^\" type=\"button\" class=\"btn btn-default\">Back</a>\n" +
    "        <button type=\"submit\" class=\"btn btn-primary\">Submit</button>\n" +
    "        <a ui-sref=\"settings-two-factor\" type=\"button\" class=\"btn btn-default pull-right\">Cancel</a>\n" +
    "      </div>\n" +
    "\n" +
    "    </form>\n" +
    "\n" +
    "  </div>\n" +
    "</div>")

$templateCache.put("src/common/settings-two-factor-setup-phone.tpl.html","<div class=\"row\">\n" +
    "  <div class=\"col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3\">\n" +
    "    <h1>{{ pageTitle }}</h1>\n" +
    "\n" +
    "    <p>Please enter the phone number you wish to use. This number will be validated in the next step.</p>\n" +
    "\n" +
    "    <form role=\"form\" name=\"phoneForm\"\n" +
    "          angular-validator angular-validator-submit=\"submit(phoneForm)\" novalidate>\n" +
    "\n" +
    "      <div class=\"form-group\">\n" +
    "        <label for=\"id_phone\">Phone Number</label>\n" +
    "        <input id=\"id_phone\"\n" +
    "               type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"phone\"\n" +
    "               validate-on=\"blur\"\n" +
    "               ng-change=\"clearServerError()\"\n" +
    "               validator=\"serverError === null\"\n" +
    "               invalid-message=\"serverError\"\n" +
    "               ng-model=\"phone.number\"\n" +
    "               required/>\n" +
    "\n" +
    "        <!--<phone-input ng-model=\"phone.number\"-->\n" +
    "                     <!--name=\"phone\"-->\n" +
    "                     <!--ng-required=\"true\"></phone-input>-->\n" +
    "      </div>\n" +
    "\n" +
    "      <label ng-if=\"methods.length\">Please select which authentication method you would\n" +
    "        like to use.</label>\n" +
    "\n" +
    "      <div class=\"form-group\">\n" +
    "        <div ng-repeat=\"method in methods\" class=\"radio\">\n" +
    "          <label>\n" +
    "            <input type=\"radio\" id=\"id_two-factor-method-{{ $index }}\"\n" +
    "                   name=\"two-factor-method\"\n" +
    "                   ng-click=\"clearServerError()\"\n" +
    "                   ng-model=\"phone.method\" ng-value=\"method.method\">\n" +
    "            {{ method.text }}\n" +
    "          </label>\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"form-group\">\n" +
    "        <a ui-sref=\"^\" type=\"button\" class=\"btn btn-default\">Back</a>\n" +
    "        <button type=\"submit\" class=\"btn btn-primary\">Next</button>\n" +
    "        <a ui-sref=\"settings-two-factor\" type=\"button\" class=\"btn btn-default pull-right\">Cancel</a>\n" +
    "      </div>\n" +
    "\n" +
    "    </form>\n" +
    "\n" +
    "  </div>\n" +
    "</div>")

$templateCache.put("src/common/settings-two-factor-setup.tpl.html","<two-factor-setup-page state=\"state\"></two-factor-setup-page>")

$templateCache.put("src/common/settings-two-factor.tpl.html","<two-factor-main></two-factor-main>\n" +
    "")

$templateCache.put("src/common/settings-users-activity.tpl.html","<setting-practice-user-activity></setting-practice-user-activity>")

$templateCache.put("src/common/settings-users-add.tpl.html","    <div class=\"modal-header\">\n" +
    "        <h3 class=\"modal-title\" ng-show=\"edit !== true\">Add New User</h3>\n" +
    "        <h3 class=\"modal-title\" ng-show=\"edit === true\">{{ customUserEditTitle || 'Edit User'}}</h3>\n" +
    "    </div>\n" +
    "    <form name=\"addUser\" role=\"form\" class=\"form-horzizontal\" ng-cloak ng-show=\"user\">\n" +
    "      <div class=\"modal-body\">\n" +
    "\n" +
    "            <div class=\"form-group row\" ng-class=\"{ 'has-error' : addUser.firstName.$invalid}\">\n" +
    "              <div class=\"col-sm-4\">\n" +
    "                <label>First Name</label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-8\">\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control\"\n" +
    "                       name=\"firstName\"\n" +
    "                       ng-model=\"user.first_name\"\n" +
    "                       placeholder=\"First Name\"\n" +
    "                       ng-minlength=2\n" +
    "                       ng-required=\"true\"\n" +
    "                />\n" +
    "                <span class=\"help-block\" ng-show=\"addUser.firstName.$invalid && addUser.firstName.$dirty\">Minimum length is 2 letters</span>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "        <div class=\"form-group row\" ng-class=\"{ 'has-error' : addUser.lastName.$invalid}\">\n" +
    "          <div class=\"col-sm-4\">\n" +
    "            <label>Last Name</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-8\">\n" +
    "            <input type=\"text\"\n" +
    "                   class=\"form-control\"\n" +
    "                   ng-model=\"user.last_name\"\n" +
    "                   placeholder=\"Last Name\"\n" +
    "                   name=\"lastName\"\n" +
    "                   ng-required=\"true\"\n" +
    "            />\n" +
    "            <span class=\"help-block\" ng-show=\"addUser.lastName.$invalid && addUser.lastName.$dirty\">Last Name is Required</span>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group row\" ng-class=\"{ 'has-error' : addUser.userEmail.$invalid}\">\n" +
    "          <div class=\"col-sm-4\">\n" +
    "            <label>Email</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-8\">\n" +
    "            <input type=\"email\"\n" +
    "                   class=\"form-control\"\n" +
    "                   ng-model=\"user.email\"\n" +
    "                   name=\"userEmail\"\n" +
    "                   placeholder=\"Email\"\n" +
    "                   ng-required=\"true\"\n" +
    "            />\n" +
    "            <span class=\"help-block\" ng-show=\"addUser.userEmail.$invalid && addUser.userEmail.$dirty\">Must be a valid email address</span>\n" +
    "            <small class=\"help-block\">User will use this value to sign in.</small>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group row\" ng-hide=\"isOnProfile\">\n" +
    "          <div class=\"col-sm-4\">\n" +
    "            <label for=\"sel4\">Roles</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-8\">\n" +
    "            <select ng-model=\"user.roles\" class=\"form-control\" id=\"sel4\" multiple>\n" +
    "              <option ng-repeat=\"(staffRole, staffRoleName) in staffRoleToNameMap\" value=\"{{staffRole}}\">{{staffRoleName}}</option>\n" +
    "            </select>\n" +
    "            <small class=\"help-block\">Select one or more (ctrl-click) roles.</small>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group row\" ng-hide=\"isOnProfile\" ng-if=\"MultiLocationEnable\">\n" +
    "          <div class=\"col-sm-4\">\n" +
    "            <label>Default Location</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-8\">\n" +
    "            <ui-select ng-model=\"user.location_default\" ng-required=\"false\" theme=\"selectize\">\n" +
    "              <ui-select-match>\n" +
    "                {{ $select.selected.name }}\n" +
    "              </ui-select-match>\n" +
    "              <ui-select-choices repeat=\"p.id as p in locations | filter:$select.search\">\n" +
    "                <span ng-bind-html=\"p.name | highlight: $select.search\"></span>\n" +
    "              </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group row\" ng-hide=\"isOnProfile\">\n" +
    "          <div class=\"col-sm-4\">\n" +
    "            <label>User Disabled</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-8\">\n" +
    "            <input type=\"checkbox\" class=\"form-control\" ng-model=\"user.is_active\" ng-true-value=\"false\" ng-false-value=\"true\" />\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group row\" ng-class=\"{ 'has-error' : addUser.userphone.$invalid}\">\n" +
    "          <div class=\"col-sm-4\">\n" +
    "            <label>Phone</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-8\">\n" +
    "            <phone-input ng-model=\"user.phone\"\n" +
    "                         name=\"userphone\"\n" +
    "                         placeholder=\"ex. 702-867-5309\"\n" +
    "                         ng-required=\"true\"\n" +
    "            />\n" +
    "            <span class=\"help-block\" ng-show=\"addUser.userphone.$invalid && addUser.userphone.$dirty\">Phone number should match XXX-XXX-XXXX</span>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"provider\" ng-show=\"userIsProvider(user)\">\n" +
    "\n" +
    "          <div class=\"form-group row\" ng-class=\"{ 'has-error' : addUser.npi.$invalid}\">\n" +
    "            <div class=\"col-sm-4\">\n" +
    "              <label>NPI</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "              <input type=\"text\"\n" +
    "                     class=\"form-control\"\n" +
    "                     ng-model=\"user.npi\"\n" +
    "                     placeholder=\"NPI\"\n" +
    "                     name=\"npi\"\n" +
    "                     ng-minlength=\"10\"\n" +
    "                     maxlength=\"10\"\n" +
    "                     ng-required=\"userIsProvider(user)\"\n" +
    "              />\n" +
    "              <span class=\"help-block\" ng-show=\"addUser.npi.$invalid && addUser.npi.$dirty\">Minimum length is 10 chars</span>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group row\" ng-class=\"{ 'has-error' : addUser.dea.$invalid}\">\n" +
    "            <div class=\"col-sm-4\">\n" +
    "              <label>DEA #</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "              <input type=\"text\"\n" +
    "                     class=\"form-control\"\n" +
    "                     ng-model=\"user.dea\"\n" +
    "                     placeholder=\"DEA\"\n" +
    "                     name=\"dea\"\n" +
    "                     ng-minlength=\"9\"\n" +
    "                     maxlength=\"9\"\n" +
    "                     ng-required=\"userIsProvider(user)\"\n" +
    "              />\n" +
    "              <span class=\"help-block\" ng-show=\"addUser.dea.$invalid && addUser.dea.$dirty\">Minimum length is 9 chars</span>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "          <div style=\"border-width: 1px;border-style: solid;padding: 5px;margin-bottom: 5px;\">\n" +
    "            <div class=\"form-group row\" ng-if=\"MultiLocationEnable\">\n" +
    "              <div class=\"col-sm-4\">\n" +
    "                <label>License Number for <b>{{locations.selected_license_location.name}}</b> </label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-8\">\n" +
    "                <input type=\"text\" class=\"form-control\" ng-model=\"locations.selected_license_location.license_number\" placeholder=\"License number for Location\" />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group row\" ng-if=\"MultiLocationEnable\">\n" +
    "              <div class=\"col-sm-4\">\n" +
    "                <label>Location</label>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-8\">\n" +
    "                <ui-select ng-model=\"locations.selected_license_location\" ng-required=\"false\" theme=\"selectize\">\n" +
    "                  <ui-select-match>\n" +
    "                    {{ $select.selected.name }}\n" +
    "                  </ui-select-match>\n" +
    "                  <ui-select-choices repeat=\"p in account_locations | filter:$select.search\">\n" +
    "                    <span ng-bind-html=\"p.name | highlight: $select.search\"></span>\n" +
    "                  </ui-select-choices>\n" +
    "                </ui-select>\n" +
    "              </div>\n" +
    "\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "          <!--div class=\"form-group row\" ng-class=\"{ 'has-error' : addUser.licenseNumber.$invalid}\">\n" +
    "            <div class=\"col-sm-4\">\n" +
    "              <label>License number</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "              <input type=\"text\"\n" +
    "                     class=\"form-control\"\n" +
    "                     ng-model=\"user.license_number\"\n" +
    "                     placeholder=\"License number\"\n" +
    "                     name=\"licenseNumber\"\n" +
    "                     ng-required=\"userIsProvider(user)\"\n" +
    "              />\n" +
    "              <span class=\"help-block\" ng-show=\"addUser.licenseNumber.$invalid && addUser.licenseNumber.$dirty\">License number is required</span>\n" +
    "            </div>\n" +
    "          </div-->\n" +
    "\n" +
    "\n" +
    "        <div class=\"form-group row\" ng-class=\"{ 'has-error' : addUser.birthDateInputDate.$invalid}\">\n" +
    "          <div class=\"col-sm-4\">\n" +
    "            <label>Date of Birth</label>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-8 form-group\">\n" +
    "            <custom-datepicker-popup ng-model=\"user.birth_date\" input-name=\"'birthDateInputDate'\" form-name=\"'addUser'\" ng-required=\"userIsProvider(user)\"/>\n" +
    "            <span class=\"help-block\" ng-show=\"addUser.birthDateInputDate.$invalid && addUser.birthDateInputDate.$dirty\">Date of Birth is required</span>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "          <div class=\"form-group row\" ng-class=\"{ 'has-error' : addUser.taxIdSsn.$invalid}\">\n" +
    "            <div class=\"col-sm-4\">\n" +
    "              <label>Tax ID/SSN</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "              <input type=\"text\"\n" +
    "                     class=\"form-control\"\n" +
    "                     ng-model=\"user.tax_id_or_ssn\"\n" +
    "                     placeholder=\"Tax ID/SSN\"\n" +
    "                     name=\"taxIdSsn\"\n" +
    "                     ng-required=\"userIsProvider(user)\"\n" +
    "              />\n" +
    "              <span class=\"help-block\" ng-show=\"addUser.taxIdSsn.$invalid && addUser.taxIdSsn.$dirty\">Tax ID/SSN is required</span>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group row\">\n" +
    "            <div class=\"col-sm-4\">\n" +
    "              <label>Additional ID</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "              <input type=\"text\" class=\"form-control\" ng-model=\"user.additional_id\" placeholder=\"Additional ID\" />\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group row\">\n" +
    "            <div class=\"col-sm-12\">\n" +
    "              <p>In the event that I am a 'treating provider' on a claim where patient assignment of benefits is to the practice, I agree the treatments claimed on my behalf as the 'treating provider' are true and accurate to the best of my knowledge.</p>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"form-group row\">\n" +
    "            <div class=\"col-sm-4\">\n" +
    "              <label>Signature</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "              <div class=\"relative-signature-box panel-default\">\n" +
    "                <canvas id=\"userSignatureCanvas\" name=\"userSignatureCanvas\" angular-sig-pad ng-model=\"user.signature\"></canvas>\n" +
    "                <button type=\"button\" class=\"absolute-top-button-transparent-right-box btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"clearSignature(user,'signature')\"><i class=\"icomoon icon-icomoon-reset\"></i></button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group row\">\n" +
    "            <div class=\"col-sm-4\">\n" +
    "              <label for=\"sel5\">Specialty</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "              <select class=\"form-control\" ng-model=\"user.specialty\" id=\"sel5\">\n" +
    "                <option value=\"122300000X\">Dentist</option>\n" +
    "                <option value=\"1223G0001X\">General Practice</option>\n" +
    "                <option value=\"1223D0001X\">Dental Public Health</option>\n" +
    "                <option value=\"1223E0200X\">Endodontics</option>\n" +
    "                <option value=\"1223X0400X\">Orthodontics</option>\n" +
    "                <option value=\"1223P0221X\">Pediatric Dentistry</option>\n" +
    "                <option value=\"1223P0300X\">Periodontics</option>\n" +
    "                <option value=\"1223P0700X\">Prosthodontics</option>\n" +
    "                <option value=\"1223P0106X\">Oral & Maxillofacial Pathology</option>\n" +
    "                <option value=\"1223D0008X\">Oral & Maxillofacial Radiology</option>\n" +
    "                <option value=\"1223S0112X\">Oral & Maxillofacial Surgery</option>\n" +
    "              </select>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "          <div class=\"form-group row\">\n" +
    "            <div class=\"col-sm-4\">\n" +
    "            <label>Enable DoseSpot</label>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-8\">\n" +
    "            <input type=\"checkbox\" class=\"form-control\" ng-model=\"user.is_dosespot_elected\" />\n" +
    "            </div>\n" +
    "          </div>\n" +
    "\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"modal-footer\" ng-if=\"edit === true\">\n" +
    "          <button class=\"btn btn-default\" ng-disabled=\"addUser.$invalid || !CanUpdateUser\" type=\"submit\" ng-click=\"updateUser()\">Update</button>\n" +
    "          <button class=\"btn btn-primary\" ng-click=\"cancel()\">Cancel</button>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"modal-footer\" ng-if=\"edit !== true\">\n" +
    "        <button class=\"btn btn-wide btn-default\" ng-click=\"cancel()\">Cancel</button>\n" +
    "        <button class=\"btn btn-wide btn-primary\"  ng-disabled=\"addUser.$invalid || !CanCreateUser\" type=\"submit\" ng-click=\"createUser()\">Create</button>\n" +
    "      </div>\n" +
    "\n" +
    "    </form>\n" +
    "")

$templateCache.put("src/common/settings-users.tpl.html","<setting-practice-user></setting-practice-user>\n" +
    "<!-- \n" +
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-12\">\n" +
    "    <div class=\"portlet light porlet-fit\">\n" +
    "      <div class=\"portlet-title\">\n" +
    "        <div class=\"caption\">\n" +
    "          <i class=\"icomoon icon-icomoon-users font-red-sunglo\"></i>\n" +
    "          <span class=\"caption-subject font-red-sunglo bold uppercase\">Practice Users</span>\n" +
    "        </div>\n" +
    "        <div class=\"actions\">\n" +
    "          <div class=\"checkbox-inline custom-control custom-checkbox m-r\">\n" +
    "            <label>\n" +
    "              <input type=\"checkbox\"  ng-disabled=\"$ctrl.loading\" ng-model=\"viewAllUsers\" class=\"ng-pristine ng-untouched ng-valid ng-empty\">\n" +
    "              <span class=\"custom-control-indicator\"></span>\n" +
    "              Include Inactive users in list?\n" +
    "            </label>\n" +
    "          </div>\n" +
    "          <div class=\"btn-group\">\n" +
    "            <button class=\"btn p-x btn-primary\" type=\"button\"\n" +
    "              ng-disabled=\"!CanCreateUser\"\n" +
    "              ng-click=\"openAddEditModal()\">\n" +
    "              Add New User\n" +
    "            </button>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"portlet-body\">\n" +
    "          <table class=\"table table-striped table-bordered table-advance\">\n" +
    "            <thead>\n" +
    "              <tr>\n" +
    "                <th>First Name</th>\n" +
    "                <th>Last Name</th>\n" +
    "                <th>Email</th>\n" +
    "                <th class=\"text-center\">Roles</th>\n" +
    "                <th class=\"text-center\">Status</th>\n" +
    "                <th></th>\n" +
    "                <th style=\"width:30px\"></th>\n" +
    "              </tr>\n" +
    "            </thead>\n" +
    "            <tr ng-if=\"user.is_active || viewAllUsers\" ng-repeat=\"user in users\" class=\"settings-users\">\n" +
    "              <td>{{ user.first_name }}</td>\n" +
    "              <td>{{ user.last_name }}</td>\n" +
    "              <td>{{ user.email }}</td>\n" +
    "              <td class=\"text-center\">\n" +
    "                <abbr title=\"\" uib-tooltip-template=\"'#tooltip-user-roles'\" tooltip-append-to-body=\"true\">\n" +
    "                  {{ user.roles.length }}\n" +
    "                </abbr>\n" +
    "              </td>\n" +
    "              <td class=\"text-center\">\n" +
    "                {{ user.is_active ? \"Active\" : \"Inactive\" }}\n" +
    "              </td>\n" +
    "              <td>\n" +
    "                <div class=\"btn-group\">\n" +
    "                  <button type=\"button\" ng-disabled=\"!CanUpdateUser\" class=\"btn btn-default btn-sm\" ng-click=\"sendAccountInvitationEmail(user)\">\n" +
    "                    <i class=\"icomoon icon-icomoon-bell3\" aria-hidden=\"true\"></i> Send invitation\n" +
    "                  </button>\n" +
    "                  <button type=\"button\" ng-disabled=\"!CanUpdateUser\" class=\"btn btn-default btn-sm\" ng-click=\"sendAccountResetPasswordEmail(user)\">\n" +
    "                    <i class=\"icomoon icon-icomoon-reset\" aria-hidden=\"true\"></i> Send password reset\n" +
    "                  </button>\n" +
    "                  <button ng-disabled=\"!CanListUserActivity\" ng-hide=\"isInWizard\" class=\"btn btn-default btn-sm\" ui-sref=\"settings-user-activity({userId:user.id})\">\n" +
    "                    <i class=\"icomoon icon-icomoon-eye\" aria-hidden=\"true\"></i> Activity\n" +
    "                  </button>\n" +
    "                </div>\n" +
    "              </td>\n" +
    "              <td>\n" +
    "                <button class=\"btn btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-disabled=\"!CanRetrieveUser\" ng-click=\"openAddEditModal(user.id)\">\n" +
    "                  <i class=\"icomoon icon-icomoon-pencil5\" aria-hidden=\"true\"></i>\n" +
    "                </button>\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "          </table>\n" +
    "          <script type=\"text/ng-template\" id=\"#tooltip-user-roles\">\n" +
    "            <ul class=\"m-b-0\">\n" +
    "              <li ng-repeat=\"role in user.roles\">{{ role }}</li>\n" +
    "            </ul>\n" +
    "          </script>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div> -->\n" +
    "")

$templateCache.put("src/common/settings.tpl.html","\n" +
    "<settings-page two-factor-enabled=\"twoFactorEnabled\" button-callback=\"downloadDesignatedRecordSet\" nav-callback=\"navigationCallback\" is-loading=\"loading\"></settings-page>")

$templateCache.put("src/common/signin-verify.tpl.html","<!-- BEGIN VERIFY FORM -->\n" +
    "<form name=\"tokenForm\" angular-validator angular-validator-submit=\"submit(tokenForm)\" novalidate>\n" +
    "\n" +
    "  <h3 class=\"form-title\">Two-factor authentication</h3>\n" +
    "\n" +
    "  <p>{{ indicationText }}&nbsp;</p>\n" +
    "\n" +
    "  <div class=\"form-group\">\n" +
    "    <input id=\"id_token\" class=\"form-control\" name=\"token\" type=\"text\"\n" +
    "      invalid-message=\"'Invalid token, please correct it and try again'\"\n" +
    "      ng-change=\"serverError = false\"\n" +
    "      ng-model=\"device.token\"\n" +
    "      required\n" +
    "      required-message=\"'This field is required'\"\n" +
    "      validate-on=\"blur\"\n" +
    "      validator=\"serverError !== true\">\n" +
    "  </div>\n" +
    "  <div class=\"form-group\">\n" +
    "    <div class=\"checkbox-inline custom-control custom-checkbox m-r-0 m-l-0\">\n" +
    "      <label>\n" +
    "        <input type=\"checkbox\" ng-model=\"device.remember_device\">\n" +
    "        <span class=\"custom-control-indicator\"></span>\n" +
    "        <small>Don't ask again on this browser for 30 days</small>\n" +
    "      </label>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-actions\">\n" +
    "    <a ui-sref=\"^\" type=\"button\" class=\"btn btn-default btn-wide\">Back</a>\n" +
    "    <button type=\"submit\" class=\"btn btn-primary btn-wide\">Verify</button>\n" +
    "  </div>\n" +
    "</form>\n" +
    "\n" +
    "<!-- END VERIFY FORM -->\n" +
    "<div ng-if=\"phones.length || (primary.isPhone && apps.length)\">\n" +
    "  <h3 class=\"form-title\">Or use one of your backup methods:</h3>\n" +
    "\n" +
    "  <ul>\n" +
    "    <li ng-if=\"apps.length\">\n" +
    "      <label>\n" +
    "        Token generator app is enabled.\n" +
    "      </label>\n" +
    "    </li>\n" +
    "\n" +
    "    <li ng-if=\"phones.length\">\n" +
    "      <label>Phones:</label>\n" +
    "      <div>\n" +
    "        <button class=\"btn btn-xs btn-primary\"\n" +
    "                ng-repeat=\"device in phones\"\n" +
    "                ng-click=\"generateChallenge(device)\">\n" +
    "          {{ device.method_display }} to {{ device.number | hidePhone }}\n" +
    "        </button>\n" +
    "      </div>\n" +
    "    </li>\n" +
    "\n" +
    "    <li ng-if=\"backupTokens.length\">\n" +
    "      <label>There are remaining backup tokens.</label>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/signin.tpl.html","<signin-page signin=\"signin\"></signin-page>\n" +
    "")

$templateCache.put("src/common/signout.tpl.html","<div class=\"text-center\">\n" +
    "  <h1>You have now been signed out of Dental EMR.</h1>\n" +
    "  <h2>Have a nice day!</h2>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/support.tpl.html","<support-page></support-page>")

$templateCache.put("src/common/task-select.tpl.html","<style>\n" +
    "  .sidebar__nav_item{\n" +
    "    padding: 16px 10px;\n" +
    "    position: relative;\n" +
    "    display: flex;\n" +
    "    align-items: center;\n" +
    "    margin: 5px 0;\n" +
    "  }\n" +
    "  .sidebar__nav_item:hover{\n" +
    "    background: #1e272c;\n" +
    "    border-top-left-radius: 20px !important;\n" +
    "    border-bottom-left-radius: 20px !important;\n" +
    "  }\n" +
    "  .sidebar__nav_link{\n" +
    "    color: #fff;\n" +
    "    display: flex;\n" +
    "    align-items: center;\n" +
    "  }\n" +
    "  .sidebar__nav_link:hover{\n" +
    "    color: #fff;\n" +
    "    text-decoration: none;\n" +
    "  }\n" +
    "  .sidebar__icon{\n" +
    "    padding-right: 5px;\n" +
    "    color: #fff !important;\n" +
    "    font-size: 25px !important;\n" +
    "  }\n" +
    "  .sidebar__badge{\n" +
    "    left: 0px;\n" +
    "    position: absolute;\n" +
    "    top: 0;\n" +
    "    background: #337ab7;\n" +
    "    border-radius: 25px !important;\n" +
    "    padding: 4px;\n" +
    "    font-size: 9px;\n" +
    "  }\n" +
    "</style>\n" +
    "\n" +
    "<sidebar-component \n" +
    "  style=\"display: block; padding-top: 1rem; height: 100vh;\"\n" +
    "  $state=\"$state\" \n" +
    "  $transitions=\"$transitions\"\n" +
    "  ></sidebar-component>")

$templateCache.put("src/common/third-party-licenses.tpl.html","<third-party-licenses-page></third-party-licenses-page>\n" +
    "")

$templateCache.put("src/common/treatment-add.tpl.html","<div class=\"text-center\">\n" +
    "  <h3>Treatments</h3>\n" +
    "</div>\n" +
    "<br>\n" +
    "\n" +
    "<!--\n" +
    "Date\n" +
    "1\"/2\"\n" +
    "Status\n" +
    "Tooth Number\n" +
    "Procedure\n" +
    "Fee\n" +
    "Co-pay\n" +
    "Provider\n" +
    "Location\n" +
    "-->\n" +
    "\n" +
    "<form class=\"form-inline\">\n" +
    "  <div class=\"form-group col-md-4\">\n" +
    "    <div class=\"dropdown\">\n" +
    "      <button class=\"btn btn-default dropdown-toggle\" type=\"button\" id=\"procedure\" data-toggle=\"dropdown\" aria-expanded=\"true\">\n" +
    "        {{selectedProcedure.name}} \n" +
    "        <span class=\"caret\"></span>\n" +
    "      </button>\n" +
    "      <ul class=\"dropdown-menu\" role=\"menu\" aria-labelledby=\"drug-name\">\n" +
    "        <li ng-repeat=\"procedure in procedureList\">\n" +
    "          <a ng-click=\"setProcedure(procedure)\">{{procedure.name}}</a>\n" +
    "        </li>\n" +
    "      </ul>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "<!--\n" +
    "  <div class=\"form-group col-md-4\">\n" +
    "    <div class=\"dropdown\">\n" +
    "      <button class=\"btn btn-default dropdown-toggle\" type=\"button\" id=\"dosage-name\" data-toggle=\"dropdown\" aria-expanded=\"true\">\n" +
    "        {{selectedDosage.name}} \n" +
    "        <span class=\"caret\"></span>\n" +
    "      </button>\n" +
    "      <ul class=\"dropdown-menu\" role=\"menu\" aria-labelledby=\"dosage-name\">\n" +
    "        <li ng-repeat=\"dosage in dosageList\">\n" +
    "          <a ng-click=\"setDosage(dosage)\">{{dosage.name}}</a>\n" +
    "        </li>\n" +
    "      </ul>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group col-md-4\">\n" +
    "    <div class=\"dropdown\">\n" +
    "      <button class=\"btn btn-default dropdown-toggle\" type=\"button\" id=\"dispense-name\" data-toggle=\"dropdown\" aria-expanded=\"true\">\n" +
    "        {{selectedDispense.name}} \n" +
    "        <span class=\"caret\"></span>\n" +
    "      </button>\n" +
    "      <ul class=\"dropdown-menu\" role=\"menu\" aria-labelledby=\"dispense-name\">\n" +
    "        <li ng-repeat=\"dispense in dispenseList\">\n" +
    "          <a ng-click=\"setDispense(dispense)\">{{dispense.name}}</a>\n" +
    "        </li>\n" +
    "      </ul>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "-->\n" +
    "</form>\n" +
    "<!-- TODO(bradleybossard) : Find a better way to vertically space components -->\n" +
    "<br>\n" +
    "<br>\n" +
    "\n" +
    "<div class=\"text-center\">\n" +
    "  <button type=\"button\" class=\"btn btn-primary\" ng-click=\"addTreatment()\">Submit</button>\n" +
    "  <button type=\"button\" class=\"btn btn-default\" ui-sref=\"^\">Cancel</button>\n" +
    "</div>\n" +
    "\n" +
    "")

$templateCache.put("src/common/wizard.tpl.html","<wizard on-finish=\"finishWizard()\">\n" +
    "  <wz-step title=\"Starting\" class=\"text-center\">\n" +
    "    <h1>Welcome to Dental EMR!</h1>\n" +
    "    <p>Before we can get started, we'll need you to complete a couple of setup steps first...</p>\n" +
    "    <input type=\"submit\" class=\"btn p-x btn-primary\" wz-next=\"selectFirstFeeProfile()\" value=\"Next\" />\n" +
    "  </wz-step>\n" +
    "  <wz-step title=\"Fee Schedules\" class=\"text-center\" canexit=\"canExitFeeProfile\">\n" +
    "    <h1>Configure Fee Schedules</h1>\n" +
    "    <br>A fee schedule is a list of procedures with associated fees. In addition to setting your cash fees in the default 'Fee for Service' fee schedule, we recommend adding fee schedules for the insurance payers you accept at your office.  <br /><br />For each fee schedule, click on the “eyeball” icon to its right to assign appropriate fees for each procedure.</p>\n" +
    "    <div ui-view=\"feeprofiles\" class=\"text-align-initial\"></div>\n" +
    "    <input type=\"submit\" class=\"btn p-x btn-primary\" wz-next value=\"Next\" />\n" +
    "  </wz-step>\n" +
    "  <wz-step title=\"Insurance Plans\" class=\"text-center\">\n" +
    "    <h1>Add Insurance Plans</h1>\n" +
    "    <p></p>\n" +
    "    <div ui-view=\"insurancePlans\" class=\"text-align-initial\"></div>\n" +
    "    <input type=\"submit\" class=\"btn p-x btn-primary\" wz-next=\"userFirstCompleteYourInfo()\" value=\"Next\" />\n" +
    "  </wz-step>\n" +
    "  <wz-step title=\"Configure Users\" class=\"text-center\" canexit=\"canExitAddUsers\">\n" +
    "    <h1>Configure Users</h1>\n" +
    "    <p>Are there any other members or employees of your practice?  Let's add them now.</p>\n" +
    "\n" +
    "    <div ui-view=\"users\" class=\"text-align-initial\"></div>\n" +
    "    <input type=\"submit\" class=\"btn btn-primary\" wz-next value=\"Next\" />\n" +
    "  </wz-step>\n" +
    "  <wz-step title=\"Add Operatories\" class=\"text-center\" canexit=\"canExitAddLocations\">\n" +
    "    <h1>Add Operatories</h1>\n" +
    "    <p>Do you have multiple operatories or chairs in your practice?  Create them here.  They are used for scheduling appointments</p>\n" +
    "    <div ui-view=\"practicedetails\" class=\"text-align-initial\"></div>\n" +
    "    <input type=\"submit\" class=\"btn btn-primary\" wz-next value=\"Next\" />\n" +
    "  </wz-step>\n" +
    "  <wz-step title=\"Add Consent Form Templates\" class=\"text-center\">\n" +
    "    <h1>Add Consent Form Templates</h1>\n" +
    "    <p>Do you use informed consent forms in your practice?  Let's add them now.</p>\n" +
    "    <div ui-view=\"consentformtemplates\" class=\"text-align-initial\"></div>\n" +
    "    <input type=\"submit\" class=\"btn btn-primary\" wz-next value=\"Next\" />\n" +
    "  </wz-step>\n" +
    "  <wz-step title=\"Congratulations\" class=\"text-center\">\n" +
    "    <h1>Congratulations!</h1>\n" +
    "    <p>You have successfully set up Dental EMR!  Now it's time to add some patients to the system.</p>\n" +
    "    <input type=\"submit\" class=\"btn btn-primary\" wz-finish value=\"Finish\" />\n" +
    "  </wz-step>\n" +
    "</wizard>\n" +
    "")

$templateCache.put("src/common/components/broadcast-navbar-dropdown.tpl.html","  <form name=\"form\" id=\"#broadcast-send-modal\" novalidate>\n" +
    "    <div class=\"modal-header\">\n" +
    "      <h3 class=\"modal-title\">Send broadcasts to all practice users</h3>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body\">\n" +
    "      <div class=\"form-group m-b-0\">\n" +
    "        <textarea class=\"form-control\" name=\"message\" rows=\"13\"\n" +
    "          ng-model=\"$ctrl.message\" required ng-maxlength=\"1024\">\n" +
    "        </textarea>\n" +
    "        <span class=\"help-block\" ng-show=\"form.message.$valid || form.message.$pristine\">\n" +
    "          This field has {{ form.message.$viewValue.length || 0 }}/1024 characters\n" +
    "        </span>\n" +
    "        <span class=\"help-block\" ng-show=\"form.message.$invalid && form.message.$dirty\">\n" +
    "          <span ng-if=\"form.message.$error.required\">This field may not be blank</span>\n" +
    "          <span ng-if=\"form.message.$error.maxlength\">\n" +
    "            This field must have at most 1024 characters (it has {{ form.message.$viewValue.length }})\n" +
    "          </span>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"modal-footer\">\n" +
    "      <button class=\"btn btn-wide btn-default\" ng-click=\"$dismiss()\">Close</button>\n" +
    "      <button class=\"btn btn-wide green\" ng-click=\"$ctrl.send(0)\" ng-disabled=\"form.$invalid\"><small>Send</small> Green</button>\n" +
    "      <button class=\"btn btn-wide yellow-lemon\" ng-click=\"$ctrl.send(1)\" ng-disabled=\"form.$invalid\"><small>Send</small> Yellow</button>\n" +
    "      <button class=\"btn btn-wide red\" ng-click=\"$ctrl.send(2)\" ng-disabled=\"form.$invalid\"><small>Send</small> Red</button>\n" +
    "    </div>\n" +
    "  </form>")

$templateCache.put("src/common/components/broadcast-navbar-history-model.tpl.html","<form name=\"form\" novalidate>\n" +
    "    <div class=\"modal-header\">\n" +
    "        <h3 class=\"modal-title\">\n" +
    "            Broadcasted Messages\n" +
    "            <small class=\"text-info\" ng-if=\"$ctrl.broadcasts.length\">({{ $ctrl.broadcasts.length }})</small>\n" +
    "        </h3>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body\" style=\"max-height:calc(100vh - 300px); overflow:auto;\">\n" +
    "        <div class=\"table-scrollable\">\n" +
    "            <table class=\"table table-advance\">\n" +
    "                <thead>\n" +
    "                    <tr>\n" +
    "                        <th class=\"narrow\">#</th>\n" +
    "                        <th class=\"narrow\">Date</th>\n" +
    "                        <th class=\"narrow\">Time</th>\n" +
    "                        <th class=\"narrow\">Sender</th>\n" +
    "                        <th>Message</th>\n" +
    "                    </tr>\n" +
    "                </thead>\n" +
    "                <tbody>\n" +
    "                    <tr ng-repeat=\"m in $ctrl.broadcasts\" ng-class=\"{0:'green', 1:'yellow', 2:'red'}[m.params.level]\">\n" +
    "                        <td class=\"narrow\">\n" +
    "                            {{ $index + 1 }}\n" +
    "                        </td>\n" +
    "                        <td class=\"narrow\">\n" +
    "                            <div>{{ m.params.datetime | date:'MM/dd/yyyy' }}</div>\n" +
    "                        </td>\n" +
    "                        <td class=\"narrow\">\n" +
    "                            <div>{{ m.params.datetime | date:'hh:mm:ss a' }}</div>\n" +
    "                        </td>\n" +
    "                        <td class=\"narrow\" ng-switch=\"m.params.user_id === $ctrl.userId\">\n" +
    "                            <div ng-switch-when=\"true\">You</div>\n" +
    "                            <div ng-switch-default>{{ m.params.user_first_name }} {{ m.params.user_last_name }}</div>\n" +
    "                        </td>\n" +
    "                        <td class=\"message\">\n" +
    "                            <div>{{ m.params.message }}</div>\n" +
    "                        </td>\n" +
    "                    </tr>\n" +
    "                    <tr ng-if=\"!$ctrl.broadcasts.length\">\n" +
    "                        <td colspan=\"5\">\n" +
    "                            <div class=\"well well-sm text-center m-b-0\">There are no Messages</div>\n" +
    "                        </td>\n" +
    "                    </tr>\n" +
    "                </tbody>\n" +
    "            </table>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"modal-footer\">\n" +
    "        <button class=\"btn btn-wide btn-default\" ng-click=\"$dismiss()\">Cancel</button>\n" +
    "    </div>\n" +
    "</form>")

$templateCache.put("src/common/components/idle-navbar-dropdown.tpl.html","\n" +
    "<li class=\"dropdown-extended\" uib-dropdown>\n" +
    "  <a class=\"dropdown-toggle\" uib-dropdown-toggle ng-disabled=\"$ctrl.disabled\">\n" +
    "    <i class=\"icomoon icomoon-pixel-perfect-1x-with-fixed-width icon-icomoon-clock3\" style=\"vertical-align:top\"></i>\n" +
    "    <span class=\"badge badge-default\">\n" +
    "      {{ $ctrl.idle | idleDuration }}\n" +
    "      <span ng-hide=\"$ctrl.multiplierWasStored\"> *</span>\n" +
    "    </span>\n" +
    "  </a>\n" +
    "  <ul class=\"dropdown-menu\" uib-dropdown-menu>\n" +
    "    <li class=\"external\">\n" +
    "      <h3>Set idle timeout</h3>\n" +
    "    </li>\n" +
    "    <li ng-class=\"{'active disabled': $ctrl.idle === $ctrl.defaultIdle * 2}\">\n" +
    "      <a ng-click=\"$ctrl.setMultiplier(2)\">\n" +
    "        <i class=\"icomoon icon-icomoon-clock3\"></i>\n" +
    "        30 minutes\n" +
    "      </a>\n" +
    "    </li>\n" +
    "    <li ng-class=\"{'active disabled': $ctrl.idle === $ctrl.defaultIdle * 4}\">\n" +
    "      <a ng-click=\"$ctrl.setMultiplier(4)\">\n" +
    "        <i class=\"icomoon icon-icomoon-clock3\"></i>\n" +
    "        1 hour\n" +
    "      </a>\n" +
    "    </li>\n" +
    "    <li ng-class=\"{'active disabled': $ctrl.idle === $ctrl.defaultIdle * 6}\">\n" +
    "      <a ng-click=\"$ctrl.setMultiplier(6)\">\n" +
    "        <i class=\"icomoon icon-icomoon-clock3\"></i>\n" +
    "        1 hour and 30 minutes\n" +
    "      </a>\n" +
    "    </li>\n" +
    "    <li ng-class=\"{'active disabled': $ctrl.idle === $ctrl.defaultIdle * 8}\">\n" +
    "      <a ng-click=\"$ctrl.setMultiplier(8)\">\n" +
    "        <i class=\"icomoon icon-icomoon-clock3\"></i>\n" +
    "        2 hours\n" +
    "      </a>\n" +
    "    </li>\n" +
    "    <li ng-if=\"$ctrl.idle !== $ctrl.defaultIdle\" class=\"divider\"></li>\n" +
    "    <li ng-if=\"$ctrl.idle !== $ctrl.defaultIdle\">\n" +
    "      <a ng-click=\"$ctrl.resetToDefault()\">\n" +
    "        <i class=\"icomoon icon-icomoon-broom\"></i>\n" +
    "        Reset to default (15 minutes)\n" +
    "      </a>\n" +
    "    </li>\n" +
    "    <li ng-if=\"$ctrl.idle !== $ctrl.defaultIdle\" class=\"divider\"></li>\n" +
    "    <li ng-if=\"$ctrl.idle !== $ctrl.defaultIdle\" class=\"dropdown-header\" ng-switch=\"$ctrl.storeMultiplier\" style=\"font-size:13px;\">\n" +
    "      <span ng-switch-when=\"true\">Remembering setting in this browser</span>\n" +
    "      <span ng-switch-default>* Using setting once then back to default</span>\n" +
    "    </li>\n" +
    "    <li ng-if=\"$ctrl.idle !== $ctrl.defaultIdle\">\n" +
    "      <a ng-click=\"$ctrl.storeMultiplierToggle()\" ng-switch=\"$ctrl.storeMultiplier\">\n" +
    "        <i class=\"icomoon icon-icomoon-floppy-disk\"></i>\n" +
    "        <span ng-switch-when=\"true\">Use setting once then back to default</span>\n" +
    "        <span ng-switch-default>Remember setting in this browser</span>\n" +
    "      </a>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</li>\n" +
    "")

$templateCache.put("src/common/layouts/main-layout.tpl.html","<style>\n" +
    "  .nav {\n" +
    "    z-index: 1\n" +
    "  }\n" +
    "\n" +
    "  .hard-reload-button {\n" +
    "    background: none;\n" +
    "    color: #BFCDDB;\n" +
    "    outline: none;\n" +
    "    border: none;\n" +
    "    padding: 28px 16px 19px !important;\n" +
    "    transition: all 2s ease-out;\n" +
    "    height: 68px;\n" +
    "    display: inline-block;\n" +
    "  }\n" +
    "\n" +
    "  .rotateSpinner {\n" +
    "    animation: rotateSpinner 800ms linear infinite;\n" +
    "  }\n" +
    "\n" +
    "  @keyframes rotateSpinner {\n" +
    "    to {\n" +
    "      transform: rotate(360deg);\n" +
    "    }\n" +
    "  }\n" +
    "\n" +
    "  .nav-img-logo {\n" +
    "    display: flex !important;\n" +
    "    justify-content: center;\n" +
    "    align-items: center;\n" +
    "  }\n" +
    "\n" +
    "  #img-logo {\n" +
    "    width: 100%;\n" +
    "  }\n" +
    "\n" +
    "  /* Styles for smartphones */\n" +
    "  @media only screen and (max-width: 1336px) {\n" +
    "    #img-logo {\n" +
    "      width: 25%;\n" +
    "    }\n" +
    "\n" +
    "    .nav-img-logo {\n" +
    "      display: flex !important;\n" +
    "      justify-content: start;\n" +
    "      align-items: center;\n" +
    "    }\n" +
    "  }\n" +
    "</style>\n" +
    "\n" +
    "<div class=\"page-sidebar-closed-hide-logo page-container-bg-solid page-header-fixed page-sidebar-fixed\"\n" +
    "  style=\"min-height: 100%; height: 100%;\">\n" +
    "  <div class=\"sidebar__mobile__menu\" mobile-menu></div>\n" +
    "  <div ng-controller=\"NavbarCtrl\">\n" +
    "    <div ng-controller=\"broadcastNavbarDropdown\">\n" +
    "      <div ng-controller=\"idleNavbarCtrl\">\n" +
    "        <top-navbar-component\n" +
    "        is-authenticated = \"isAuthenticated\"\n" +
    "        profile-dropdown-toggle=\"isScreenLock || isLoginDropdownLock\"\n" +
    "        broadcast-ctrl=\"broadcastCtrl\"\n" +
    "        idle-ctrl=\"idleCtrl\"\n" +
    "        avatar-hash=\"avatarHash\"\n" +
    "        username=\"username\"\n" +
    "        logo-click=\"logoclick\"\n" +
    "        $state=\"$state\"\n" +
    "        signout=\"signout\"\n" +
    "        is-small-width=\"isSmallWidth\"\n" +
    "        current-url=\"$state.current.url\"\n" +
    "        ></top-navbar-component> \n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"clearfix\"></div>\n" +
    "  <div class=\"page-container\">\n" +
    "    <div class=\"page-sidebar-wrapper\">\n" +
    "      <div class=\"page-sidebar navbar-collapse collapse\">\n" +
    "        <ul style=\"padding-left: 5px;\" className='sidebar__menu__main page-header-fixed'>\n" +
    "          <span ui-view=\"task-select\"></span>\n" +
    "        </ul>\n" +
    "      </div>\n" +
    "      <div class=\"page-content-wrapper\">\n" +
    "        <div class=\"page-content\" id=\"top\">\n" +
    "          <div ui-view=\"content\">\n" +
    "            <unsupported-browser></unsupported-browser>\n" +
    "            <div ui-view=\"task-view\"></div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <footer-component></footer-component>\n" +
    "  </div>\n" +
    "\n" +
    "  <script type=\"text/ng-template\" id=\"whatsNewModal.html\">\n" +
    "    <div class=\"modal-body\" style=\"align-items: center;\n" +
    "    display: flex;\n" +
    "    flex-direction: column;\n" +
    "    max-width: 400px;\n" +
    "    justify-content: center;\n" +
    "    margin: auto;\n" +
    "    text-align: center;\">\n" +
    "      <img src=\"assets/images/what-new.png\" style=\"width:150px; height:150px\">\n" +
    "      <h3>See what's changed.</h3>\n" +
    "      <h6>June 24th, 2024</h6>\n" +
    "      <p>A new update has been released, to explore these new workflows enhancements, simply click the link below for a guided list.</p>\n" +
    "      <a href=\"https://dentalemr.knowledgeowl.com/help/software-release-june-2024\">June 2024 Release</a>\n" +
    "    </div>\n" +
    "    <div class=\"modal-footer\">\n" +
    "      <button class=\"btn btn-default\" ng-click=\"$ctrl.close()\">Close</button>\n" +
    "    </div>\n" +
    "  </script>\n" +
    "\n" +
    "  <script type=\"text/ng-template\" id=\"addSupportTicket.html\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">What can we help you with?</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <form name='myForm'>\n" +
    "      <div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label for=\"description\"></label>\n" +
    "          <textarea id=\"description\" class=\"form-control\" type=\"text\"\n" +
    "            ng-model=\"ticket.description\" ng-maxlength=\"4000\">\n" +
    "          </textarea >\n" +
    "          <small>{{ ticket.description.length }} of 4000 characters maximum</small>\n" +
    "          <button class=\"btn pull-right btn-default btn-sm btn-icomoon-pixel-perfect-1x\" ng-click=\"clear()\">\n" +
    "            <i class=\"icomoon icon-icomoon-reset\"></i>\n" +
    "          </button>\n" +
    "          <small class=\"help-block\">\n" +
    "            Please be as specific as possible, tell us how you'd like to be contacted and when the best times are to contact you.\n" +
    "          </small>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </form>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"ok()\">Send to us</button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"cancel()\">Hide</button>\n" +
    "  </div>\n" +
    "</script>")

$templateCache.put("src/common/layouts/signin-layout.tpl.html","<style>\n" +
    "  html {\n" +
    "    margin: 0;\n" +
    "    padding: 0;\n" +
    "    background: url(/assets/images/backgrounds/loginbg-tooth.jpg) no-repeat center center fixed;\n" +
    "    -webkit-background-size: cover;\n" +
    "    -moz-background-size: cover;\n" +
    "    -o-background-size: cover;\n" +
    "    background-size: cover;\n" +
    "  }\n" +
    "  body {\n" +
    "    background: transparent;\n" +
    "  }\n" +
    "  a:hover { text-decoration: none; }\n" +
    "</style>\n" +
    "<div class=\"login\">\n" +
    "  <div class=\"logo\">\n" +
    "    <a href=\"index.html\">\n" +
    "      <img src=\"/assets/images/logo.png\" width=\"300\">\n" +
    "    </a>\n" +
    "  </div>\n" +
    "  <div ui-view=\"content\" class=\"content\"></div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/directives/angular-treeviewer.tpl.html","<script type=\"text/ng-template\" id=\"node_renderer.html\">\n" +
    "    <span ng-if=\"setControls(value)\" >\n" +
    "        <span ng-click=\"toggleControls(value)\" ng-if=\"showLabel(key)\" >\n" +
    "            <span ng-class=\"value._iconClass\">\n" +
    "            </span> \n" +
    "            <label class=\"key\" ng-if=\"isString(key)\">{{key}}</label>\n" +
    "            <label class=\"delimiter\" ng-if=\"isString(key) && isPrimitive(value)\">: </label>\n" +
    "            <label class=\"value\" ng-if=\"isPrimitive(value)\">{{value}}</label>\n" +
    "            <label ng-if=\"isObject(value)\">&nbsp;</label>\n" +
    "        </span>\n" +
    "        <div ng-if=\"value._childrenVisible\">\n" +
    "            <div ng-repeat=\"(key, value) in value\" ng-include=\"'node_renderer.html'\" ng-class=\"getRepeatClass(value, false)\">\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </span>\n" +
    "</script>\n" +
    "<!-- <div>\n" +
    "    <input class=\"search-box\"  ng-model=\"search\" id=\"inputIcon\" type=\"text\" /> \n" +
    "    <i ng-class=\"searchIconClass\"></i>\n" +
    "</div> -->\n" +
    "<!-- <strong  ng-show=\"search.length > 0\" class=\"search-count\">Matching results: {{ (displayTree|filter:search).length }}</strong> -->\n" +
    "<!-- <div ng-repeat=\"(key, value) in displayTree | filter:search\" ng-include=\"'node_renderer.html'\" ng-class=\"getRepeatClass(value, true)\">\n" +
    "</div> -->\n" +
    "<div ng-repeat=\"(key, value) in displayTree\" ng-include=\"'node_renderer.html'\" ng-class=\"getRepeatClass(value, true)\">\n" +
    "</div>")

$templateCache.put("src/common/directives/date-input.tpl.html","<div>\n" +
    "  <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"{{name}}\"\n" +
    "               validate-on=\"blur\"\n" +
    "               size=\"14\"\n" +
    "               maxlength=\"14\"\n" +
    "               ui-options=\"{clearOnBlur: false}\"\n" +
    "               ng-model=\"ngModel\"\n" +
    "               ui-mask=\"{{uiMaskFormat}}\"\n" +
    "               ng-pattern=\"/^\\d{8}$/\"\n" +
    "               placeholder=\"{{placeholder}}\"\n" +
    "               ui-mask-placeholder\n" +
    "               required-message=\"'This field is required'\"\n" +
    "               invalid-message=\"'Invalid date format'\"\n" +
    "               ng-required=\"ngRequired\"/>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/directives/datepicker.tpl.html","<div class=\"input-group datepicker\" ng-class=\"{ 'has-error': isInvalid}\">\n" +
    "  <input type=\"text\" placeholder=\"{{ placeholder || 'Select required date' }}\" class=\"form-control\"\n" +
    "         ng-model=\"datePickerModel\"\n" +
    "         ng-show=\"ngShow\"\n" +
    "         uib-datepicker-popup=\"{{'MM/dd/yyyy'}}\"\n" +
    "         is-open=\"dpOpened\"\n" +
    "         ui-mask=\"99/99/9999\"\n" +
    "         ui-mask-placeholder\n" +
    "         model-view-value=\"true\"\n" +
    "         ng-change=\"onChange($event)\"\n" +
    "         ng-keyup=\"onKeyUp($event)\"\n" +
    "         ng-mouseleave=\"onBlur($event)\"\n" +
    "         close-text=\"Close\"\n" +
    "         ng-blur=\"onBlur($event)\"\n" +
    "         name=\"{{ inputName || 'inputdatepicker'}}\"\n" +
    "         required-message=\"'This field is required'\"\n" +
    "         invalid-message=\"'Invalid date'\"\n" +
    "         validate-on=\"blur\"\n" +
    "         min-date=\"minDate\"\n" +
    "         max-date=\"maxDate\"\n" +
    "         ng-disabled=\"ngDisabled\"\n" +
    "         ng-required=\"ngRequired\"\n" +
    "         ng-readonly=\"ngReadonly\"\n" +
    "  />\n" +
    "\n" +
    "\n" +
    "   <span class=\"input-group-btn\" style=\"vertical-align: top;\" ng-show=\"ngShow\">\n" +
    "     <button type=\"button\" ng-disabled=\"ngDisabled\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"open()\"><i class=\"icomoon icon-icomoon-calendar\"></i></button>\n" +
    "   </span>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/directives/mobile-menu.tpl.html","<nav class=\"nav\" id=\"mobile-nav\">\n" +
    "  <div class=\"mobile-logo\">\n" +
    "    <img id=\"img-logo\" ng-click=\"logoclick()\" class=\"dis-b\" src=\"../../../assets/images/logo.png\" alt=\"DentalEMR\">\n" +
    "  </div>\n" +
    "  <ul>\n" +
    "    <span ui-view=\"task-select\"></span>\n" +
    "  </ul>\n" +
    "</nav>")

$templateCache.put("src/common/directives/password-strength-input.tpl.html","<div class=\"label-indicator-absolute label-indicator-password-strength input-icon\">\n" +
    "  <i class=\"icomoon icon-icomoon-lock\"></i>\n" +
    "  <input type=\"password\"\n" +
    "        class=\"form-control input\"\n" +
    "        ng-model=\"ngModel\"\n" +
    "        name=\"{{name}}\"\n" +
    "        placeholder=\"New password\"\n" +
    "        autocomplete=\"off\"\n" +
    "        ng-pattern=\"/^.*(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\\d\\s:]).*$/\"\n" +
    "        ng-minlength=\"8\"\n" +
    "        ng-required=\"true\">\n" +
    "  <span class=\"label password-indicator-label-absolute\"></span>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/directives/phone-input-new.tpl.html","<div>\n" +
    "  <input type=\"text\"\n" +
    "         class=\"form-control\"\n" +
    "         size=\"12\"\n" +
    "         maxlength=\"12\"\n" +
    "         name=\"{{name}}\"\n" +
    "         ui-options=\"{clearOnBlur: false}\"\n" +
    "         ui-mask=\"999-999-9999\"\n" +
    "         ng-pattern=\"/^\\d{10}$/\"\n" +
    "         pattern-model-overwrite\n" +
    "         placeholder=\"{{placeholder}}\"\n" +
    "         ng-model=\"ngModel\"\n" +
    "         ui-mask-placeholder\n" +
    "         ng-required=\"ngRequired\"/>\n" +
    "\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/directives/phone-input.tpl.html","<div>\n" +
    "  <input type=\"text\"\n" +
    "         class=\"form-control\"\n" +
    "         size=\"12\"\n" +
    "         maxlength=\"12\"\n" +
    "         name=\"{{name}}\"\n" +
    "         validate-on=\"{{validateOn || 'blur' }}\"\n" +
    "         ui-options=\"{clearOnBlur: false}\"\n" +
    "         ui-mask=\"999-999-9999\"\n" +
    "         ng-pattern=\"/^\\d{10}$/\"\n" +
    "         pattern-model-overwrite\n" +
    "         placeholder=\"{{placeholder}}\"\n" +
    "         ng-model=\"ngModel\"\n" +
    "         ui-mask-placeholder\n" +
    "         invalid-message=\"'Phone number should match XXX-XXX-XXXX'\"\n" +
    "         ng-readonly=\"ngReadonly\"\n" +
    "         ng-required=\"ngRequired\"/>\n" +
    "\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/directives/signature-input.tpl.html","<div>\n" +
    "  <input type=\"text\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"{{name}}\"\n" +
    "               ng-model=\"ngModel\"\n" +
    "               placeholder=\"{{placeholder}}\"\n" +
    "               ui-mask-placeholder\n" +
    "               required-message=\"'This field is required'\"\n" +
    "               ng-required=\"ngRequired\"\n" +
    "               />\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/directives/ssn-input.tpl.html","<div>\n" +
    "\n" +
    "<input type=\"text\"\n" +
    "       class=\"form-control\"\n" +
    "       name=\"{{name}}\"\n" +
    "       size=\"14\"\n" +
    "       maxlength=\"14\"\n" +
    "       validate-on=\"blur\"\n" +
    "       ng-model=\"ngModel\"\n" +
    "       ui-options=\"{clearOnBlur: false}\" \n" +
    "       ui-mask=\"999-99-9999\"\n" +
    "       ng-pattern=\"/^\\d{9}$/\"\n" +
    "       pattern-model-overwrite\n" +
    "       placeholder=\"{{placeholder}}\"\n" +
    "       ui-mask-placeholder\n" +
    "       invalid-message=\"'Social Security Number should be of the form XXX-XX-XXXX'\"\n" +
    "       required-message=\"'This field is required'\"\n" +
    "       ng-required=\"ngRequired\"/>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "")

$templateCache.put("src/common/directives/state-input.tpl.html","<div>\n" +
    "  <input type=\"text\"\n" +
    "         class=\"form-control\"\n" +
    "         size=\"2\"\n" +
    "         maxlength=\"2\"\n" +
    "         name=\"{{name}}\"\n" +
    "         validate-on=\"blur\"\n" +
    "         ui-options=\"{clearOnBlur: false}\"\n" +
    "         ui-mask=\"AA\"\n" +
    "         ng-pattern=\"/^[A-Z]{2}$/\"\n" +
    "         ng-model=\"ngModel\"\n" +
    "         ui-mask-placeholder\n" +
    "         invalid-message=\"'State should be 2 letters'\"\n" +
    "         ng-required=\"ngRequired\"\n" +
    "         ng-readonly=\"ngReadonly\"\n" +
    "  />\n" +
    "\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/directives/yes-no-button.tpl.html","<div class=\"form-group row\">\n" +
    "  <div class=\"col-sm-8\">\n" +
    "    <label>{{label}}</label>\n" +
    "  </div>\n" +
    "  <div class=\"col-sm-4\">\n" +
    "    <div class=\"btn-group pull-right\">\n" +
    "      <label class=\"btn btn-default\" ng-model=\"ngModel\" uib-btn-radio=\"true\">{{ yesLabel || 'Yes'}}</label>\n" +
    "      <label class=\"btn btn-default\" ng-model=\"ngModel\" uib-btn-radio=\"false\">{{ noLabel || 'No'}}</label>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/directives/zip-input.tpl.html","<div>\n" +
    "\n" +
    "  <input type=\"text\"\n" +
    "         class=\"form-control\"\n" +
    "         size=\"5\"\n" +
    "         maxlength=\"5\"\n" +
    "         name=\"{{name}}\"\n" +
    "         validate-on=\"blur\"\n" +
    "         ui-options=\"{clearOnBlur: false}\"\n" +
    "         ui-mask=\"99999\"\n" +
    "         ng-pattern=\"/^\\d{5}$/\"\n" +
    "         ng-model=\"ngModel\"\n" +
    "         ui-mask-placeholder\n" +
    "         invalid-message=\"'Zip code is 5 digits'\"\n" +
    "         ng-required=\"ngRequired\"\n" +
    "         ng-readonly=\"ngReadonly\"\n" +
    "  />\n" +
    "\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/directives/calibration/calibration.tpl.html","<div>\n" +
    "  <h3>Calibration Profile Settings</h3>\n" +
    "\n" +
    "  <div>\n" +
    "    <label>Step #1: Plot two points on the image below to draw a line</label>\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"input-group col-sm-4 pull-right\">\n" +
    "          <select name=\"calibrationSelect\" id=\"calibrationSelect2\"\n" +
    "                  data-ng-options=\"option.name for option in calibrations track by option.id\"\n" +
    "                  data-ng-model=\"selectedCalibration\" class=\"form-control\">\n" +
    "            <option value=\"\">- Select a calibration -</option>\n" +
    "          </select>\n" +
    "          <span class=\"input-group-btn\">\n" +
    "              <button type=\"button\" class=\"btn btn-default\"\n" +
    "                      data-ng-click=\"removeCalibration(selectedCalibration)\"\n" +
    "                      data-ng-disabled=\"!calibrations.length || (selectedCalibration && !selectedCalibration.id)\">\n" +
    "                <span class=\"glyphicon glyphicon-remove\"></span>\n" +
    "              </button>\n" +
    "          </span>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"clearfix\"></div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div id=\"canvas-calibration-container\"></div>\n" +
    "\n" +
    "  <form class=\"form\" name=\"calibrationForm\">\n" +
    "    <div class=\"form-group\">\n" +
    "      <label for=\"measure\">Step #2: What is this estimated distance?</label>\n" +
    "      <input id=\"measure\" type=\"number\" name=\"measure\" data-ng-model=\"measure\" class=\"form-control\"\n" +
    "             data-ng-keypress=\"onlyNumber($event)\" placeholder=\"Measure in mm\" min=\"1\"\n" +
    "             data-ng-disabled=\"!showMeasure\">\n" +
    "      <div>\n" +
    "        <span data-ng-show=\"calibrationForm.measure.$error.min\" class=\"validation-error\">A number greater than 0 is required</span>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"form-group\">\n" +
    "      <label for=\"name\">Step #3: What is the name of this calibration profile?</label>\n" +
    "      <input id=\"name\" type=\"text\" name=\"name\" data-ng-model=\"name\" class=\"form-control\"\n" +
    "             placeholder=\"Calibration name\" data-ng-disabled=\"!showMeasure\">\n" +
    "    </div>\n" +
    "\n" +
    "    <button type=\"button\" class=\"btn btn-circle default\" data-ng-click=\"cancel()\">\n" +
    "      <span class=\"glyphicon glyphicon-triangle-left\">Back</span>\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn p-x btn-primary\" data-ng-click=\"calibrate()\"\n" +
    "            data-ng-disabled=\"!(isValidMeasure() && name)\"  style=\"margin-left: 6px;\">\n" +
    "      <span>Calibrate</span>\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-circle\" data-ng-click=\"clear()\"  style=\"margin-left: 6px;\">\n" +
    "      <span class=\"glyphicon glyphicon-erase\">Clear</span>\n" +
    "    </button>\n" +
    "  </form>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/patient-files-scan-modal.html","<form name=\"form\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">\n" +
    "      Acquire Documents\n" +
    "      <div class=\"pull-right\">\n" +
    "        <small class=\"text-primary\" ng-if=\"$ctrl.loading === 'init'\">Initializing...</small>\n" +
    "        <small class=\"btn-group\" ng-if=\"$ctrl.loading !== 'init'\">\n" +
    "          <ui-select theme=\"selectize\" class=\"pull-left text-left\" style=\"height:32px; width:300px; margin:0 -1px;\"\n" +
    "            ng-model=\"$ctrl.settings.sources.latest\"\n" +
    "            on-select=\"$ctrl.saveSettings()\">\n" +
    "            <ui-select-match>{{ $select.selected.name }}</ui-select-match>\n" +
    "            <ui-select-choices repeat=\"source in $ctrl.settings.sources.all | filter:{'name':$select.search}\">\n" +
    "              <span ng-bind-html=\"source.name | highlight: $select.search\"></span>\n" +
    "            </ui-select-choices>\n" +
    "          </ui-select>\n" +
    "          <button class=\"btn btn-default\" ng-click=\"$ctrl.loadSources(true)\" ng-disabled=\"$ctrl.loading\" style=\"font-size:16px; padding:6px 8px 3px;\">\n" +
    "            <i class=\"icomoon icon-icomoon-loop\"></i>\n" +
    "          </button>\n" +
    "        </small>\n" +
    "      </div>\n" +
    "    </h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\" style=\"min-height:51px\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-show=\"$ctrl.loading !== false\"></wave-spinner>\n" +
    "    <div class=\"well well-sm m-b-0\" ng-if=\"!$ctrl.images.length\">\n" +
    "      <ol class=\"m-b-0\">\n" +
    "        <li>Select the <b>Capture Device</b></li>\n" +
    "        <li>Click <b>Capture</b></li>\n" +
    "        <li>Set a name for the captured document</li>\n" +
    "        <li>Click <b>Save</b></li>\n" +
    "      </ol>\n" +
    "    </div>\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"col-sm-10\">\n" +
    "        <div ng-repeat=\"pi in $ctrl.images track by $index\" ng-show=\"$index === $ctrl.currentImageIdx\">\n" +
    "          <wave-spinner class=\"modal-loader\" ng-show=\"pi.$loading\"></wave-spinner>\n" +
    "          <div class=\"form-horizontal\" ng-show=\"!pi.$error\">\n" +
    "            <div class=\"form-group form-group-sm\" ng-class=\"{'has-error': form['name_{{ $index }}'].$invalid}\">\n" +
    "              <label class=\"col-sm-2 control-label\">Name</label>\n" +
    "              <div class=\"col-sm-8\" ng-class=\"{'col-sm-10': form['name_{{ $index }}'].$valid}\">\n" +
    "                <input class=\"form-control\" type=\"text\" ng-model=\"pi.name\" name=\"name_{{ $index }}\" required>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-2\" ng-show=\"form['name_{{ $index }}'].$error.required\">\n" +
    "                <small class=\"help-block\" style=\"font-weight:bold\">Required</small>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <!-- <div class=\"form-group form-group-sm\">\n" +
    "              <label class=\"col-sm-2 control-label\">Description</label>\n" +
    "              <div class=\"col-sm-10\">\n" +
    "                <textarea class=\"form-control\" rows=\"2\" ng-model=\"pi.description\"></textarea>\n" +
    "              </div>\n" +
    "            </div> -->\n" +
    "          </div>\n" +
    "          <div class=\"pos-r img-thumbnail\" ng-class=\"{'dis-b':pi.$error}\">\n" +
    "            <img class=\"img-responsive center-block\" ng-src=\"{{ pi.img }}\" ng-show=\"!pi.$error\">\n" +
    "            <div class=\"well m-b-0\" ng-show=\"pi.$error\">\n" +
    "              <h4>{{ pi.$error.msg }} <small>Error Code: {{ pi.$error.code }}</small></h4>\n" +
    "            </div>\n" +
    "            <div class=\"btn-group\" style=\"position:absolute; right:4px; top:4px;\">\n" +
    "              <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.rotate($index, 'Left')\" ng-show=\"!pi.$error\">\n" +
    "                <i class=\"icomoon icon-icomoon-rotate-ccw\"></i>\n" +
    "              </div>\n" +
    "              <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.rotate($index, 'Right')\" ng-show=\"!pi.$error\">\n" +
    "                <i class=\"icomoon icon-icomoon-rotate-cw\"></i>\n" +
    "              </div>\n" +
    "              <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.load($index)\" ng-show=\"pi.$error\">\n" +
    "                <i class=\"icomoon icon-icomoon-loop\"></i>\n" +
    "              </div>\n" +
    "              <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.remove($index)\">\n" +
    "                <i class=\"icomoon icon-icomoon-bin\"></i>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"hidden\"><div id=\"dwt-container-patient-files\"></div></div>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-2 thumbnail-scan-container\">\n" +
    "        <a href=\"#\" class=\"thumbnail pos-r\"\n" +
    "          ng-repeat=\"pi in $ctrl.images track by $index\"\n" +
    "          ng-click=\"$ctrl.currentImageIdx = $index\"\n" +
    "          ng-class=\"{'active': $ctrl.currentImageIdx == $index, 'invalid': form['name_{{ $index }}'].$invalid}\">\n" +
    "          <i class=\"icomoon icon-icomoon-warning2\" ng-show=\"form['name_{{ $index }}'].$invalid\" tooltip-append-to-body=\"true\" tooltip-class=\"tooltip-inside-modal\" uib-tooltip=\"Click to fix errors\"></i>\n" +
    "          <img class=\"img-responsive center-block\" ng-src=\"{{ pi.img }}\">\n" +
    "        </a>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button type=\"button\" class=\"btn btn-wide btn-default\"\n" +
    "      ng-click=\"$dismiss()\">\n" +
    "      Cancel\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-wide btn-info\"\n" +
    "      ng-click=\"$ctrl.scan()\"\n" +
    "      ng-disabled=\"$ctrl.loading || !$ctrl.settings.sources.all.length\">\n" +
    "      Capture\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-wide btn-primary\"\n" +
    "      ng-click=\"$close($ctrl.images)\"\n" +
    "      ng-disabled=\"$ctrl.loading || !$ctrl.images.length || form.$invalid\">\n" +
    "      Save\n" +
    "    </button>\n" +
    "  </div>\n" +
    "</form>\n" +
    "")

$templateCache.put("src/common/patient-image-delete-modal.html","<div class=\"modal-header\">\n" +
    "  <h3 class=\"modal-title\">Delete Patient Image?</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "  <wave-spinner class=\"modal-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "  Deleting this image will remove it from the Patient Chart\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "  <button class=\"btn p-x-md btn-default\" ng-click=\"$dismiss()\">Cancel</button>\n" +
    "  <button class=\"btn p-x-md btn-danger\" type=\"submit\" ng-click=\"$ctrl.remove()\" ng-disabled=\"$ctrl.loading\">Delete</button>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/patient-image-editor-edit-modal.html","\n" +
    "<div class=\"modal-header text-center\" ng-if=\"$ctrl.canUpdate\">\n" +
    "  <div class=\"btn-group\" ng-class=\"{active: $ctrl.keymode.name === 'scale'}\">\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading || $ctrl.filterHasValue('scale', 0.05)\"\n" +
    "      ng-click=\"$ctrl.sLess()\">\n" +
    "      <i class=\"icomoon icon-icomoon-zoom-out\"></i>\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading || $ctrl.filterHasDefaultValue('scale', 1)\"\n" +
    "      ng-click=\"$ctrl.sReset()\">\n" +
    "      <i class=\"icomoon icon-icomoon-search\"></i>\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading || $ctrl.filterHasValue('scale', 2)\"\n" +
    "      ng-click=\"$ctrl.sMore()\">\n" +
    "      <i class=\"icomoon icon-icomoon-zoom-in\"></i>\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading || $ctrl.filterHasValue('scale', $ctrl.image.image_archive_metadata.filters.fitScale)\"\n" +
    "      ng-click=\"$ctrl.sFit()\">\n" +
    "      <i class=\"icomoon icon-icomoon-shrink6\"></i>\n" +
    "    </button>\n" +
    "  </div>\n" +
    "  <div class=\"btn-group\" ng-class=\"{active: $ctrl.keymode.name === 'angle'}\">\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading || $ctrl.filterHasValue('angle', 0)\"\n" +
    "      ng-click=\"$ctrl.aLess()\">\n" +
    "      <i class=\"icomoon icon-icomoon-rotate-ccw\"></i>\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading || $ctrl.filterHasValue('angle', 270)\"\n" +
    "      ng-click=\"$ctrl.aMore()\">\n" +
    "      <i class=\"icomoon icon-icomoon-rotate-cw\"></i>\n" +
    "    </button>\n" +
    "  </div>\n" +
    "  <div class=\"btn-group\" ng-class=\"{active: $ctrl.keymode.name === 'brightness'}\">\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading || $ctrl.filterHasValue('brightness', -1)\"\n" +
    "      ng-click=\"$ctrl.bLess()\">\n" +
    "      <i class=\"icomoon icon-icomoon-minus-circle\"></i>\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading || $ctrl.filterHasDefaultValue('brightness', 0)\"\n" +
    "      ng-click=\"$ctrl.bReset()\">\n" +
    "      <i class=\"icomoon icon-icomoon-brightness-high\"></i>\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading || $ctrl.filterHasValue('brightness', 1)\"\n" +
    "      ng-click=\"$ctrl.bMore()\">\n" +
    "      <i class=\"icomoon icon-icomoon-plus-circle\"></i>\n" +
    "    </button>\n" +
    "  </div>\n" +
    "  <div class=\"btn-group\" ng-class=\"{active: $ctrl.keymode.name === 'contrast'}\">\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading || $ctrl.filterHasValue('contrast', -100)\"\n" +
    "      ng-click=\"$ctrl.cLess()\">\n" +
    "      <i class=\"icomoon icon-icomoon-minus-circle\"></i>\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading || $ctrl.filterHasDefaultValue('contrast', 0)\"\n" +
    "      ng-click=\"$ctrl.cReset()\">\n" +
    "      <i class=\"icomoon icon-icomoon-contrast\"></i>\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading || $ctrl.filterHasValue('contrast', 100)\"\n" +
    "      ng-click=\"$ctrl.cMore()\">\n" +
    "      <i class=\"icomoon icon-icomoon-plus-circle\"></i>\n" +
    "    </button>\n" +
    "  </div>\n" +
    "  <div class=\"btn-group\">\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-class=\"{'btn-success': $ctrl.filterHasValue('invert', true)}\"\n" +
    "      ng-disabled=\"$ctrl.loading\"\n" +
    "      ng-click=\"$ctrl.invert()\">\n" +
    "      <i class=\"icomoon icon-icomoon-droplet-contrast\"></i>\n" +
    "    </button>\n" +
    "  </div>\n" +
    "  <div class=\"btn-group\">\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-class=\"{'btn-info': $ctrl.mode.name === 'drag'}\"\n" +
    "      ng-disabled=\"$ctrl.loading\"\n" +
    "      ng-click=\"$ctrl.toggleMode('drag')\">\n" +
    "      <i class=\"icomoon icon-icomoon-move\"></i>\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading\"\n" +
    "      ng-click=\"$ctrl.center()\">\n" +
    "      <i class=\"icomoon icon-icomoon-broom\"></i>\n" +
    "    </button>\n" +
    "  </div>\n" +
    "  <div class=\"btn-group\">\n" +
    "    <button type=\"button\" class=\"btn btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-class=\"$ctrl.mode.name === 'sharpness' ? 'btn-info' : 'btn-default'\"\n" +
    "      ng-disabled=\"$ctrl.loading\"\n" +
    "      ng-click=\"$ctrl.toggleMode('sharpness')\">\n" +
    "      <i class=\"icomoon icon-icomoon-equalizer\"></i>\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading || $ctrl.mode.name === 'sharpness'\"\n" +
    "      ng-click=\"$ctrl.sharpnessReset()\">\n" +
    "      <i class=\"icomoon icon-icomoon-broom\"></i>\n" +
    "    </button>\n" +
    "  </div>\n" +
    "  <div class=\"btn-group\">\n" +
    "    <div class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading\"\n" +
    "      ng-click=\"$ctrl.clear()\">\n" +
    "      <i class=\"icomoon icon-icomoon-clear-formatting\"></i>\n" +
    "    </div>\n" +
    "    <div class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-class=\"{'btn-info': $ctrl.mode.name === 'filters'}\"\n" +
    "      ng-disabled=\"$ctrl.loading\"\n" +
    "      ng-click=\"$ctrl.toggleMode('filters')\">\n" +
    "      <i class=\"icomoon icon-icomoon-eye2\"></i>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"btn-group\">\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-class=\"{'btn-info': $ctrl.mode.name === 'measure'}\"\n" +
    "      ng-disabled=\"$ctrl.loading || !$ctrl.calibrations.length\"\n" +
    "      ng-click=\"$ctrl.toggleMode('measure')\">\n" +
    "      <i class=\"icomoon icon-icomoon-rulers\"></i>\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-disabled=\"$ctrl.loading\"\n" +
    "      ng-click=\"$ctrl.clearMeasurements()\">\n" +
    "      <i class=\"icomoon icon-icomoon-broom\"></i>\n" +
    "    </button>\n" +
    "  </div>\n" +
    "  <div class=\"btn-group\">\n" +
    "    <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-class=\"{'btn-info': $ctrl.mode.name === 'form'}\"\n" +
    "      ng-disabled=\"$ctrl.loading\"\n" +
    "      ng-click=\"$ctrl.toggleMode('form')\">\n" +
    "      <i class=\"icomoon icon-icomoon-file-text\"></i>\n" +
    "    </button>\n" +
    "  </div>\n" +
    "  <div class=\"btn-group\">\n" +
    "    <div class=\"btn btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "      ng-class=\"{'btn-info': $ctrl.mode.name === 'help'}\"\n" +
    "      ng-disabled=\"$ctrl.loading\"\n" +
    "      ng-click=\"$ctrl.toggleMode('help')\">\n" +
    "      <i class=\"icomoon icon-icomoon-question2\"></i>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body\">\n" +
    "  <wave-spinner class=\"modal-loader\" ng-show=\"$ctrl.loading\" style=\"background-color:transparent;\"></wave-spinner>\n" +
    "  <div style=\"min-height: 100px; background-color:black;\" ng-style=\"{'height': $ctrl.containerHeight($ctrl.loading)}\"\n" +
    "    register=\"$ctrl.registerPatientImageEditorViewport(api)\"\n" +
    "    patient-image-editor-viewport\n" +
    "    archive=\"$ctrl.image.image_archive\"\n" +
    "    filters=\"$ctrl.image.image_archive_metadata.filters\"\n" +
    "    measurements=\"$ctrl.image.image_archive_metadata.measurements\"\n" +
    "    caliper=\"$ctrl.caliper\"\n" +
    "    loading=\"$ctrl.loading\"\n" +
    "    mode=\"$ctrl.mode\">\n" +
    "    <div class=\"alert alert-warning m-b-0 p-y text-center\" ng-if=\"$ctrl.loading === null\">\n" +
    "      <i class=\"icomoon icon-icomoon-warning2\" style=\"font-size:32px;line-height:32px\"></i>\n" +
    "      <h5>Error loading image</h5>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div ng-switch=\"$ctrl.mode.name\">\n" +
    "    <div class=\"modal-popover\" ng-switch-when=\"calibration\" style=\"width:350px\">\n" +
    "      <form name=\"calibrationForm\">\n" +
    "        <div class=\"btn-icomoon-pixel-perfect-1x pull-right\" style=\"margin-top:-4px; background-color: #eee;\" patient-image-editor-grabbable-popover>\n" +
    "          <i class=\"icomoon icon-icomoon-move\"></i>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label>Plot two points on the image below to draw a line</label>\n" +
    "          <div class=\"row\">\n" +
    "            <div class=\"col-xs-6\" style=\"height:40px;width:70px\">\n" +
    "              <pulse-spinner class=\"m-y-0\"\n" +
    "                ng-if=\"$ctrl.mode.calibration.points[0] === undefined || $ctrl.mode.calibration.points[1] === undefined\">\n" +
    "              </pulse-spinner>\n" +
    "              <div class=\"text-center\" style=\"font-size:28px; left:0; position:absolute; right:0; top:0;\"\n" +
    "                ng-class=\"{'text-info': $ctrl.mode.calibration.points[0] !== undefined || $ctrl.mode.calibration.points[1] !== undefined}\">\n" +
    "                1\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"col-xs-6 p-x-0\" style=\"height:40px;width:70px\">\n" +
    "              <pulse-spinner class=\"m-y-0\"\n" +
    "                ng-if=\"$ctrl.mode.calibration.points[2] === undefined || $ctrl.mode.calibration.points[3] === undefined\">\n" +
    "              </pulse-spinner>\n" +
    "              <div class=\"text-center\" style=\"font-size:28px; left:0; position:absolute; right:0; top:0;\"\n" +
    "                ng-class=\"{'text-info': $ctrl.mode.calibration.points[2] !== undefined || $ctrl.mode.calibration.points[3] !== undefined}\">\n" +
    "                2\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\" style=\"margin-top:4px;\">\n" +
    "          <label>Calibration Name</label>\n" +
    "          <input type=\"text\" class=\"form-control\" required ng-model=\"$ctrl.mode.calibration.name\">\n" +
    "          <small class=\"help-block\">What is the name of this calibration profile?</small>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label>Estimated Distance</label>\n" +
    "          <input type=\"number\" class=\"form-control\" ng-model=\"$ctrl.mode.calibration.mm\">\n" +
    "          <small class=\"help-block\">What is this estimated distance in <b>milimeters</b>?</small>\n" +
    "        </div>\n" +
    "        <input type=\"hidden\" required ng-model=\"$ctrl.mode.calibration.points[0]\">\n" +
    "        <input type=\"hidden\" required ng-model=\"$ctrl.mode.calibration.points[1]\">\n" +
    "        <input type=\"hidden\" required ng-model=\"$ctrl.mode.calibration.points[2]\">\n" +
    "        <input type=\"hidden\" required ng-model=\"$ctrl.mode.calibration.points[3]\">\n" +
    "        <hr style=\"margin:20px -15px\">\n" +
    "        <div class=\"form-group\">\n" +
    "          <div class=\"btn btn-default btn-sm btn-wide pull-left\"\n" +
    "            ng-click=\"$ctrl.clearModeProps()\">\n" +
    "            Reset\n" +
    "          </div>\n" +
    "          <button type=\"button\" class=\"btn btn-primary btn-sm btn-wide pull-right\"\n" +
    "            ng-disabled=\"calibrationForm.$invalid\"\n" +
    "            ng-click=\"$ctrl.createCalibration($ctrl.mode.calibration)\">\n" +
    "            Save\n" +
    "          </button>\n" +
    "          <div class=\"btn btn-default btn-sm btn-wide pull-right m-r-sm\"\n" +
    "            ng-click=\"$ctrl.toggleMode('calibration')\">\n" +
    "            Close\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </form>\n" +
    "    </div>\n" +
    "    <div class=\"modal-popover\" ng-switch-when=\"form\" style=\"width:250px\">\n" +
    "      <form>\n" +
    "        <div class=\"btn-icomoon-pixel-perfect-1x pull-right\" style=\"margin-top:-4px; background-color: #eee;\" patient-image-editor-grabbable-popover>\n" +
    "          <i class=\"icomoon icon-icomoon-move\"></i>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label>Name</label>\n" +
    "          <input type=\"text\" class=\"form-control\" ng-model=\"$ctrl.image.name\">\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label>Description</label>\n" +
    "          <textarea class=\"form-control\" rows=\"6\" ng-model=\"$ctrl.image.description\"></textarea>\n" +
    "        </div>\n" +
    "      </form>\n" +
    "    </div>\n" +
    "    <div class=\"modal-popover\" ng-switch-when=\"filters\" style=\"width:250px\">\n" +
    "      <div class=\"btn-icomoon-pixel-perfect-1x pull-right\" style=\"margin-top:-4px; background-color: #eee;\" patient-image-editor-grabbable-popover>\n" +
    "        <i class=\"icomoon icon-icomoon-move\"></i>\n" +
    "      </div>\n" +
    "      <table class=\"table table-borderless table-center m-b-0\">\n" +
    "        <tr>\n" +
    "          <th colspan=\"2\">Transformation</th>\n" +
    "          <th>Value</th>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"btn-icomoon-pixel-perfect-1x\"><i class=\"icomoon icon-icomoon-search\"></i></td>\n" +
    "          <td>Zoom</td>\n" +
    "          <td>{{ $ctrl.image.image_archive_metadata.filters.scale || 1 }}</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"btn-icomoon-pixel-perfect-1x\"><i class=\"icomoon icon-icomoon-angle\"></i></td>\n" +
    "          <td>Angle</td>\n" +
    "          <td>{{ $ctrl.image.image_archive_metadata.filters.angle || 0 }}&deg;</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"btn-icomoon-pixel-perfect-1x\"><i class=\"icomoon icon-icomoon-brightness-high\"></i></td>\n" +
    "          <td>Brightness</td>\n" +
    "          <td>{{ $ctrl.image.image_archive_metadata.filters.brightness || 0 }}</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"btn-icomoon-pixel-perfect-1x\"><i class=\"icomoon icon-icomoon-contrast\"></i></td>\n" +
    "          <td>Contrast</td>\n" +
    "          <td>{{ $ctrl.image.image_archive_metadata.filters.contrast || 0 }}</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"btn-icomoon-pixel-perfect-1x\"><i class=\"icomoon icon-icomoon-droplet-contrast\"></i></td>\n" +
    "          <td>Invert</td>\n" +
    "          <td>{{ $ctrl.image.image_archive_metadata.filters.invert || false }}</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"btn-icomoon-pixel-perfect-1x\"><i class=\"icomoon icon-icomoon-move\"></i></td>\n" +
    "          <td>Translation</td>\n" +
    "          <td>\n" +
    "            {{ $ctrl.image.image_archive_metadata.filters.dx || 0 }},\n" +
    "            {{ $ctrl.image.image_archive_metadata.filters.dy || 0 }}\n" +
    "          </td>\n" +
    "        </tr>\n" +
    "      </table>\n" +
    "    </div>\n" +
    "    <div class=\"modal-popover\" ng-switch-when=\"help\" style=\"width:250px\">\n" +
    "      <div class=\"btn-icomoon-pixel-perfect-1x pull-right\" style=\"margin-top:-4px; background-color: #eee;\" patient-image-editor-grabbable-popover>\n" +
    "        <i class=\"icomoon icon-icomoon-move\"></i>\n" +
    "      </div>\n" +
    "      <table class=\"table table-borderless m-b-0\">\n" +
    "        <tr>\n" +
    "          <td class=\"text-center\"><i class=\"icomoon icon-icomoon-search\"></i></td>\n" +
    "          <td>Zoom Normal</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"text-center\"><i class=\"icomoon icon-icomoon-rotate-ccw\"></i></td>\n" +
    "          <td>Rotate Left</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"text-center\"><i class=\"icomoon icon-icomoon-rotate-cw\"></i></td>\n" +
    "          <td>Rotate Right</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"text-center\"><i class=\"icomoon icon-icomoon-brightness-high\"></i></td>\n" +
    "          <td>Brightness</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"text-center\"><i class=\"icomoon icon-icomoon-contrast\"></i></td>\n" +
    "          <td>Contrast</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"text-center\"><i class=\"icomoon icon-icomoon-droplet-contrast\"></i></td>\n" +
    "          <td>Invert Colors</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"text-center\"><i class=\"icomoon icon-icomoon-move\"></i></td>\n" +
    "          <td>Translate</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"text-center\"><i class=\"icomoon icon-icomoon-enlarge3\"></i></td>\n" +
    "          <td>Center</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"text-center\"><i class=\"icomoon icon-icomoon-clear-formatting\"></i></td>\n" +
    "          <td>Reset to Original</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"text-center\"><i class=\"icomoon icon-icomoon-rulers\"></i></td>\n" +
    "          <td>Take Measures</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"text-center\"><i class=\"icomoon icon-icomoon-broom\"></i></td>\n" +
    "          <td>Clear Measures</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"text-center\"><i class=\"icomoon icon-icomoon-design\"></i></td>\n" +
    "          <td>Calibrate</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"text-center\"><i class=\"icomoon icon-icomoon-bin\"></i></td>\n" +
    "          <td>Delete Calibration</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "          <td class=\"text-center\"><i class=\"icomoon icon-icomoon-file-text\"></i></td>\n" +
    "          <td>Form</td>\n" +
    "        </tr>\n" +
    "      </table>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"modal-popover\" ng-switch-when=\"saveas\" style=\"width:350px\">\n" +
    "      <wave-spinner class=\"modal-loader\" ng-show=\"$ctrl.mode.loading\" style=\"background-color:transparent;\"></wave-spinner>\n" +
    "      <form>\n" +
    "        <div class=\"btn-icomoon-pixel-perfect-1x pull-right\" style=\"margin-top:-4px; background-color: #eee;\" patient-image-editor-grabbable-popover>\n" +
    "          <i class=\"icomoon icon-icomoon-move\"></i>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label>Name</label>\n" +
    "          <input type=\"text\" class=\"form-control\" ng-model=\"$ctrl.mode.image.name\">\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <img class=\"img-thumbnail w-full\" ng-src=\"{{ $ctrl.mode.image.preview }}\">\n" +
    "        </div>\n" +
    "        <div class=\"modal-footer\">\n" +
    "          <button type=\"button\" class=\"btn default btn-sm btn-wide\"\n" +
    "            ng-disabled=\"$ctrl.mode.loading\"\n" +
    "            ng-click=\"$ctrl.toggleMode('saveas')\">\n" +
    "            Close\n" +
    "          </button>\n" +
    "          <button type=\"button\" class=\"btn btn-primary btn-sm btn-wide\"\n" +
    "            ng-disabled=\"$ctrl.mode.loading || !$ctrl.mode.image.name\"\n" +
    "            ng-click=\"$ctrl.saveas($ctrl.mode.image.name)\">\n" +
    "            Save\n" +
    "          </button>\n" +
    "        </div>\n" +
    "      </form>\n" +
    "      <style>\n" +
    "        .modal-popover .modal-footer { padding-bottom: 0; padding-left: 0; padding-right: 0; }\n" +
    "      </style>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"modal-popover\" ng-switch-when=\"sharpness\" style=\"width:350px\">\n" +
    "      <form>\n" +
    "        <div class=\"btn-icomoon-pixel-perfect-1x pull-right\" style=\"margin-top:-4px; background-color: #eee;\" patient-image-editor-grabbable-popover>\n" +
    "          <i class=\"icomoon icon-icomoon-move\"></i>\n" +
    "        </div>\n" +
    "        <h4 class=\"text-center\">Sharpness</h4>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label>Strength</label>\n" +
    "          <div ui-slider min=\"0\" max=\"20\" step=\"0.01\" tip use-decimals ng-model=\"$ctrl.image.image_archive_metadata.filters.sharpness.strength\"></div>\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "          <label>Mix</label>\n" +
    "          <div ui-slider min=\"0\" max=\"0.5\" step=\"0.01\" tip use-decimals ng-model=\"$ctrl.image.image_archive_metadata.filters.sharpness.mix\"></div>\n" +
    "        </div>\n" +
    "        <style>\n" +
    "          .ui-slider { margin-left: 10px; margin-right: 10px; }\n" +
    "          .ui-slider .ui-slider-handle:focus, .ui-slider .ui-slider-handle:hover { text-decoration: none; }\n" +
    "          .ui-slider .ui-slider-handle { text-align: center; width: 2em; }\n" +
    "          .ui-slider .ui-slider-handle .ui-slider-tip { font-size: 11px; }\n" +
    "        </style>\n" +
    "      </form>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "  <div class=\"btn-group pull-left\">\n" +
    "    <div class=\"btn-group\">\n" +
    "      <button class=\"btn btn-default\" style=\"font-size:16px !important; padding:5px 12px 4px !important;\"\n" +
    "        ng-class=\"{'btn-info': $ctrl.mode.name === 'calibration'}\"\n" +
    "        ng-disabled=\"$ctrl.loading\"\n" +
    "        ng-click=\"$ctrl.toggleMode('calibration')\">\n" +
    "        <i class=\"icomoon icon-icomoon-design\"></i>\n" +
    "      </button>\n" +
    "      <ui-select ng-model=\"$ctrl.caliper\" on-select=\"$ctrl.onSelectCaliper($model)\" theme=\"selectize\" class=\"pull-left text-left\" style=\"height:32px; width:250px; margin:0 -1px;\">\n" +
    "        <ui-select-match placeholder=\"Select Calibration\">\n" +
    "          {{ $select.selected.name }}\n" +
    "        </ui-select-match>\n" +
    "        <ui-select-choices repeat=\"c in $ctrl.calibrations | filter:$select.search\" position=\"up\">\n" +
    "          <span ng-bind-html=\"c.name | highlight: $select.search\"></span>\n" +
    "        </ui-select-choices>\n" +
    "      </ui-select>\n" +
    "      <button class=\"btn btn-default\" style=\"font-size:16px !important; padding:5px 12px 4px !important;\"\n" +
    "        ng-disabled=\"$ctrl.loading || !$ctrl.caliper\"\n" +
    "        ng-click=\"$ctrl.deleteCalibration($ctrl.caliper.id)\">\n" +
    "        <i class=\"icomoon icon-icomoon-bin\"></i>\n" +
    "      </button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <button type=\"button\" class=\"btn btn-wide btn-default\" ng-click=\"$ctrl.$dismiss()\">Close</button>\n" +
    "  <button type=\"button\" class=\"btn btn-wide btn-info\" ng-click=\"$ctrl.toggleMode('saveas')\" ng-disabled=\"!$ctrl.apiPatientImageEditorViewport\">Save as new image</button>\n" +
    "  <button type=\"button\" class=\"btn btn-wide btn-success\" ng-click=\"$ctrl.download()\">Download</button>\n" +
    "  <button type=\"button\" class=\"btn btn-wide btn-primary\"\n" +
    "    ng-if=\"$ctrl.canUpdate\"\n" +
    "    ng-disabled=\"$ctrl.loading\"\n" +
    "    ng-click=\"$ctrl.update()\">\n" +
    "    Save\n" +
    "  </button>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/common/patient-image-editor-scan-modal.html","<form name=\"form\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">\n" +
    "      Acquire Images\n" +
    "      <div class=\"pull-right\">\n" +
    "        <small class=\"text-primary\" ng-if=\"$ctrl.loading === 'init'\">Initializing...</small>\n" +
    "        <small class=\"btn-group\" ng-if=\"$ctrl.loading !== 'init'\">\n" +
    "          <ui-select theme=\"selectize\" class=\"pull-left text-left\" style=\"height:32px; width:300px; margin:0 -1px;\"\n" +
    "            ng-model=\"$ctrl.settings.sources.latest\"\n" +
    "            on-select=\"$ctrl.saveSettings()\">\n" +
    "            <ui-select-match>{{ $select.selected.name }}</ui-select-match>\n" +
    "            <ui-select-choices repeat=\"source in $ctrl.settings.sources.all | filter:{'name':$select.search}\">\n" +
    "              <span ng-bind-html=\"source.name | highlight: $select.search\"></span>\n" +
    "            </ui-select-choices>\n" +
    "          </ui-select>\n" +
    "          <button class=\"btn btn-default\" ng-click=\"$ctrl.loadSources(true)\" ng-disabled=\"$ctrl.loading\" style=\"font-size:16px; padding:6px 8px 3px;\">\n" +
    "            <i class=\"icomoon icon-icomoon-loop\"></i>\n" +
    "          </button>\n" +
    "        </small>\n" +
    "      </div>\n" +
    "    </h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\" style=\"min-height:51px\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-show=\"$ctrl.loading !== false\"></wave-spinner>\n" +
    "    <div class=\"well well-sm m-b-0\" ng-if=\"!$ctrl.images.length\">\n" +
    "      <ol class=\"m-b-0\">\n" +
    "        <li>Select the <b>Capture Device</b></li>\n" +
    "        <li>Click <b>Capture</b></li>\n" +
    "        <li>Set a name for the captured image</li>\n" +
    "        <li>Click <b>Save</b></li>\n" +
    "      </ol>\n" +
    "    </div>\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"col-sm-10\">\n" +
    "        <div ng-repeat=\"pi in $ctrl.images track by $index\" ng-show=\"$index === $ctrl.currentImageIdx\">\n" +
    "          <wave-spinner class=\"modal-loader\" ng-show=\"pi.$loading\"></wave-spinner>\n" +
    "          <div class=\"form-horizontal\" ng-show=\"!pi.$error\">\n" +
    "            <div class=\"form-group form-group-sm\" ng-class=\"{'has-error': form['name_{{ $index }}'].$invalid}\">\n" +
    "              <label class=\"col-sm-2 control-label\">Name</label>\n" +
    "              <div class=\"col-sm-8\" ng-class=\"{'col-sm-10': form['name_{{ $index }}'].$valid}\">\n" +
    "                <input class=\"form-control\" type=\"text\" ng-model=\"pi.name\" name=\"name_{{ $index }}\" required>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-2\" ng-show=\"form['name_{{ $index }}'].$error.required\">\n" +
    "                <small class=\"help-block\" style=\"font-weight:bold\">Required</small>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group form-group-sm\">\n" +
    "              <label class=\"col-sm-2 control-label\">Description</label>\n" +
    "              <div class=\"col-sm-10\">\n" +
    "                <textarea class=\"form-control\" rows=\"2\" ng-model=\"pi.description\"></textarea>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"pos-r img-thumbnail\" ng-class=\"{'dis-b':pi.$error}\">\n" +
    "            <img class=\"img-responsive center-block\" ng-src=\"{{ pi.image_archive }}\" ng-show=\"!pi.$error\">\n" +
    "            <div class=\"well m-b-0\" ng-show=\"pi.$error\">\n" +
    "              <h4>{{ pi.$error.msg }} <small>Error Code: {{ pi.$error.code }}</small></h4>\n" +
    "            </div>\n" +
    "            <div class=\"btn-group\" style=\"position:absolute; right:4px; top:4px;\">\n" +
    "              <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.rotate($index, 'Left')\" ng-show=\"!pi.$error\">\n" +
    "                <i class=\"icomoon icon-icomoon-rotate-ccw\"></i>\n" +
    "              </div>\n" +
    "              <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.rotate($index, 'Right')\" ng-show=\"!pi.$error\">\n" +
    "                <i class=\"icomoon icon-icomoon-rotate-cw\"></i>\n" +
    "              </div>\n" +
    "              <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.load($index)\" ng-show=\"pi.$error\">\n" +
    "                <i class=\"icomoon icon-icomoon-loop\"></i>\n" +
    "              </div>\n" +
    "              <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.remove($index)\">\n" +
    "                <i class=\"icomoon icon-icomoon-bin\"></i>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <div class=\"hidden\"><div id=\"dwt-container-encounter-patient-image\"></div></div>\n" +
    "      </div>\n" +
    "      <div class=\"col-sm-2 thumbnail-scan-container\">\n" +
    "        <a href=\"#\" class=\"thumbnail pos-r\"\n" +
    "          ng-repeat=\"pi in $ctrl.images track by $index\"\n" +
    "          ng-click=\"$ctrl.currentImageIdx = $index\"\n" +
    "          ng-class=\"{'active': $ctrl.currentImageIdx == $index, 'invalid': form['name_{{ $index }}'].$invalid}\">\n" +
    "          <i class=\"icomoon icon-icomoon-warning2\" ng-show=\"form['name_{{ $index }}'].$invalid\" tooltip-append-to-body=\"true\" tooltip-class=\"tooltip-inside-modal\" uib-tooltip=\"Click to fix errors\"></i>\n" +
    "          <img class=\"img-responsive center-block\" ng-src=\"{{ pi.image_archive_thumb }}\">\n" +
    "        </a>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button type=\"button\" class=\"btn btn-wide btn-default\"\n" +
    "      ng-click=\"$dismiss()\">\n" +
    "      Cancel\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-wide btn-info\"\n" +
    "      ng-click=\"$ctrl.scan()\"\n" +
    "      ng-disabled=\"$ctrl.loading || !$ctrl.settings.sources.all.length\">\n" +
    "      Capture\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-wide btn-primary\"\n" +
    "      ng-click=\"$close($ctrl.images)\"\n" +
    "      ng-disabled=\"$ctrl.loading || !$ctrl.images.length || form.$invalid\">\n" +
    "      Save\n" +
    "    </button>\n" +
    "  </div>\n" +
    "</form>\n" +
    "")

$templateCache.put("src/common/patient-image-send-to-dentalemrservice.html","<div class=\"modal-header\">\n" +
    "  <h3 class=\"modal-title\">DemrService Communicator</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "  <wave-spinner class=\"modal-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "  <div ng-repeat=\"(key, value) in demrservice_config\">\n" +
    "    <div ng-if=\"value.type==='invoker'\">\n" +
    "      <button \n" +
    "        class=\"btn p-x-md btn-danger\" \n" +
    "        style=\"width: 100%;\"\n" +
    "        type=\"submit\" \n" +
    "        ng-click=\"$ctrl.invokeBinary(value.binaryPath, value.interpolatedBinaryArgs)\" \n" +
    "        ng-disabled=\"$ctrl.loading || !$ctrl.started\"\n" +
    "        ng-attr-title=\"{{value.buttonTip}}\"\n" +
    "      >{{value.buttonText}}</button>\n" +
    "      <br><br>\n" +
    "\n" +
    "    </div>\n" +
    "    <div ng-if=\"value.type==='retriever'\">\n" +
    "        <button \n" +
    "          class=\"btn p-x-md btn-danger\" \n" +
    "          style=\"width: 100%;\"\n" +
    "          type=\"submit\" \n" +
    "          ng-click=\"$ctrl.retrieveFileAndPost(value.fullPath, value.nameIsDir, value.del)\" \n" +
    "          ng-disabled=\"$ctrl.loading || !$ctrl.started\"\n" +
    "          ng-attr-title=\"{{value.buttonTip}}\"\n" +
    "        >{{value.buttonText}}</button>\n" +
    "        <input i type=\"text\" ng-attr-title=\"Full path on local computer to retrieve.\" ng-model=\"value.fullPath\" style=\"width: 100%;\"></input>\n" +
    "        <br><br>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  <div>\n" +
    "    <textarea readonly rows=\"6\" cols=\"67\">{{$ctrl.statusMessage}}</textarea>\n" +
    "  </div>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "  <button class=\"btn p-x-md btn-default\" ng-click=\"$ctrl.dismiss()\">Cancel</button>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/app/components/global-loading.html","<style>\n" +
    "  .wave-spinner > div {\n" +
    "    background-color: #7f96ac;\n" +
    "  }\n" +
    "</style>\n" +
    "<div style=\"width: 50px;\">\n" +
    "  <wave-spinner ng-if=\"$ctrl.isVisible\"></wave-spinner>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/app/components/global-search.html","<form class=\"search-form search-form-expanded\" style=\"background: transparent;\" ng-if=\"$ctrl.isVisible\">\n" +
    "  <div class=\"input-group\">\n" +
    "    <input type=\"text\" class=\"form-control\" placeholder=\"Search...\"\n" +
    "      ng-model=\"$ctrl.search\"\n" +
    "      ng-model-options=\"{debounce:500}\">\n" +
    "  </div>\n" +
    "</form>\n" +
    "")

$templateCache.put("src/referrals/components/destroy-modal.html","<div class=\"modal-body\">\n" +
    "  <wave-spinner class=\"modal-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "  <div ng-if=\"$ctrl.hasProps($ctrl.obj.protected)\">\n" +
    "    <div class=\"alert alert-warning m-b-0\">\n" +
    "      <h4>Not Permitted!</h4>\n" +
    "      <p>It's attached to <a ng-click=\"isDetailsExpanded = !isDetailsExpanded\">existing data</a>.</p>\n" +
    "    </div>\n" +
    "    <div class=\"table-responsive m-t\" uib-collapse=\"!isDetailsExpanded\">\n" +
    "      <table class=\"table table-bordered table-advance m-b-0\">\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <th>Resource</th>\n" +
    "            <th>Ids</th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "          <tr ng-repeat=\"(model, ids) in $ctrl.obj.protected track by $index\">\n" +
    "            <td class=\"narrow\">{{ model }}</td>\n" +
    "            <td>{{ ids }}</td>\n" +
    "          </tr>\n" +
    "        </tbody>\n" +
    "      </table>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div ng-if=\"!$ctrl.hasProps($ctrl.obj.protected)\">\n" +
    "    <div class=\"alert alert-danger m-b-0\">\n" +
    "      <h4>Are you sure!</h4>\n" +
    "      <p>This action can not be undone.</p>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "  <button class=\"btn btn-default btn-wide apull-left\" type=\"button\"\n" +
    "    ng-click=\"$dismiss()\">\n" +
    "    Dismiss\n" +
    "  </button>\n" +
    "  <button class=\"btn btn-danger btn-wide\" type=\"button\"\n" +
    "    ng-click=\"$ctrl.submit()\"\n" +
    "    ng-disabled=\"$ctrl.loading || !$ctrl.canDelete\">\n" +
    "    Submit\n" +
    "  </button>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/referrals/components/referral-list.html","<!-- <ui-view>\n" +
    "  <div class=\"portlet light porlet-fit\">\n" +
    "    <div class=\"portlet-title\">\n" +
    "      <div class=\"caption\">\n" +
    "        <i class=\"icomoon icon-icomoon-users2 font-red-sunglo\"></i>\n" +
    "        <span class=\"caption-subject font-red-sunglo bold uppercase\">{{ $ctrl.meta.role }}</span>\n" +
    "      </div>\n" +
    "      <div class=\"actions\">\n" +
    "        <div class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "          <label>\n" +
    "            <input type=\"checkbox\" \n" +
    "              ng-change=\"$ctrl.refresh()\"\n" +
    "              ng-disabled=\"$ctrl.loading\"\n" +
    "              ng-model=\"$ctrl.showCompletedReferrals\">\n" +
    "            <span class=\"custom-control-indicator\"></span>\n" +
    "            Include completed referrals in list?\n" +
    "          </label>\n" +
    "        </div>\n" +
    "        <div class=\"btn-group m-x\">\n" +
    "          <div class=\"radio-inline custom-control custom-radio\">\n" +
    "            <label>\n" +
    "              <input type=\"radio\" ng-change=\"$ctrl.load()\" ng-model=\"$ctrl.period\" value=\"1-day\">\n" +
    "              <span class=\"custom-control-indicator\"></span>\n" +
    "              day\n" +
    "            </label>\n" +
    "          </div>\n" +
    "          <div class=\"radio-inline custom-control custom-radio\">\n" +
    "            <label>\n" +
    "              <input type=\"radio\" ng-change=\"$ctrl.load()\" ng-model=\"$ctrl.period\" value=\"1-week\">\n" +
    "              <span class=\"custom-control-indicator\"></span>\n" +
    "              week\n" +
    "            </label>\n" +
    "          </div>\n" +
    "          <div class=\"radio-inline custom-control custom-radio\">\n" +
    "            <label>\n" +
    "              <input type=\"radio\" ng-change=\"$ctrl.load()\" ng-model=\"$ctrl.period\" value=\"1-month\">\n" +
    "              <span class=\"custom-control-indicator\"></span>\n" +
    "              month\n" +
    "            </label>\n" +
    "          </div>\n" +
    "          <div class=\"radio-inline custom-control custom-radio\">\n" +
    "            <label>\n" +
    "              <input type=\"radio\" ng-change=\"$ctrl.load()\" ng-model=\"$ctrl.period\" value=\"3-month\">\n" +
    "              <span class=\"custom-control-indicator\"></span>\n" +
    "              3 mo\n" +
    "            </label>\n" +
    "          </div>\n" +
    "          <div class=\"radio-inline custom-control custom-radio\">\n" +
    "            <label>\n" +
    "              <input type=\"radio\" ng-change=\"$ctrl.load()\" ng-model=\"$ctrl.period\" value=\"6-month\">\n" +
    "              <span class=\"custom-control-indicator\"></span>\n" +
    "              6 mo\n" +
    "            </label>\n" +
    "          </div>\n" +
    "          <div class=\"radio-inline custom-control custom-radio\">\n" +
    "            <label>\n" +
    "              <input type=\"radio\" ng-change=\"$ctrl.load()\" ng-model=\"$ctrl.period\" value=\"\">\n" +
    "              <span class=\"custom-control-indicator\"></span>\n" +
    "              all\n" +
    "            </label>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "        <button class=\"btn p-x default\"\n" +
    "          ng-click=\"$ctrl.load()\"\n" +
    "          ng-disabled=\"$ctrl.loading\">\n" +
    "          Reload\n" +
    "        </button>\n" +
    "        <button class=\"btn p-x btn-primary\"\n" +
    "          ng-disabled=\"!$ctrl.canCreateReferral\"\n" +
    "          ui-sref=\"{{ $ctrl.meta.ns }}.{{ $ctrl.meta.role }}.detail({id:'new'})\">\n" +
    "          Create\n" +
    "        </button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"portlet-body pos-r\" style=\"min-height:25px;\">\n" +
    "      <wave-spinner class=\"table-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "      <input type=\"text\" class=\"form-control m-b\" placeholder=\"Search\" \n" +
    "        ng-change=$ctrl.refresh()\n" +
    "        ng-model=\"$ctrl.search\"\n" +
    "        ng-model-options=\"{debounce: 600}\">\n" +
    "      <div ng-if=\"$ctrl.uiGrid.data\"\n" +
    "        ui-grid=\"$ctrl.uiGrid\" \n" +
    "        ui-grid-resize-columns>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</ui-view> -->\n" +
    "<referral-recieved-page practice-id=\"practiceId\" meta=\"$ctrl.meta\"></referral-recieved-page>")

$templateCache.put("src/referrals/components/referral-practice-detail.html","\n" +
    "<div class=\"page-bar\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "      <a ui-sref=\"charts\">Home</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <a ui-sref=\"settings\">Settings</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <a ui-sref=\"settings-referrals.practice-list\">External Practices</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span ng-if=\"$ctrl.obj.id\">Edit</span>\n" +
    "      <span ng-if=\"!$ctrl.obj.id\">Create</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "\n" +
    "<form name=\"form\" autocomplete=\"off\">\n" +
    "  <div class=\"portlet light\">\n" +
    "    <div class=\"portlet-title\">\n" +
    "      <div class=\"caption\">\n" +
    "        <i class=\"icomoon icon-icomoon-store font-red-sunglo\"></i>\n" +
    "        <span class=\"caption-subject font-red-sunglo bold uppercase\">\n" +
    "          <span>External Practice</span>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "      <div class=\"actions\">\n" +
    "        <a class=\"btn btn-default btn-wide\"\n" +
    "          ui-sref=\"^.practice-list\">\n" +
    "          Back\n" +
    "        </a>\n" +
    "        <button class=\"btn btn-danger btn-wide\" type=\"button\"\n" +
    "          ng-click=\"$ctrl.destroy()\"\n" +
    "          ng-disabled=\"$ctrl.loading || !$ctrl.canDelete\">\n" +
    "          Delete\n" +
    "        </button>\n" +
    "        <button class=\"btn default btn-wide\" type=\"button\"\n" +
    "          ng-click=\"$ctrl.retrieve(true)\"\n" +
    "          ng-disabled=\"$ctrl.loading || !$ctrl.canRetrieve\">\n" +
    "          Reload\n" +
    "        </button>\n" +
    "        <button class=\"btn btn-primary btn-wide\" type=\"button\"\n" +
    "          ng-click=\"$ctrl.save()\"\n" +
    "          ng-disabled=\"$ctrl.loading || !$ctrl.canSave || form.$invalid\">\n" +
    "          Save\n" +
    "        </button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"portlet-body pos-r\">\n" +
    "      <wave-spinner class=\"table-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "      <fieldset ng-disabled=\"!$ctrl.canSave\" ng-if=\"$ctrl.obj !== null\">\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"col-sm-4\">\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-error': form.name.$invalid}\">\n" +
    "              <label class=\"control-label\">\n" +
    "                Name *\n" +
    "                <span ng-if=\"form.name.$invalid\">\n" +
    "                  (<span ng-repeat=\"(error, _) in form.name.$error\">{{ error }}</span>)\n" +
    "                </span>\n" +
    "              </label>\n" +
    "              <input class=\"form-control\" name=\"name\" autocomplete=\"off\" ng-model=\"$ctrl.obj.name\" required>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label\">Email</label>\n" +
    "              <input class=\"form-control\" name=\"email\" autocomplete=\"off\" ng-model=\"$ctrl.obj.email\">\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label\">Type</label>\n" +
    "              <ui-select ng-model=\"$ctrl.obj.type\" theme=\"selectize\" ng-disabled=\"!$ctrl.canSave\">\n" +
    "                <ui-select-match placeholder=\"\">\n" +
    "                  {{ $select.selected.display }}\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices repeat=\"type.value as type in $ctrl.metadata.schema.type.choices | filter:$select.search\">\n" +
    "                  <span ng-bind-html=\"type.display | highlight: $select.search\"></span>\n" +
    "                </ui-select-choices>\n" +
    "              </ui-select>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"col-sm-6\">\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"control-label\">Phone</label>\n" +
    "                  <phone-input name=\"phone\" autocomplete=\"off\" ng-model=\"$ctrl.obj.phone\" />\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-6\">\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"control-label\">Fax</label>\n" +
    "                  <phone-input name=\"phone\" autocomplete=\"off\" ng-model=\"$ctrl.obj.fax\" />\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-4\">\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label\">Address Line 1</label>\n" +
    "              <input class=\"form-control\" name=\"patient_address_street_1\" autocomplete=\"off\" ng-model=\"$ctrl.obj.street_1\" google-places-auto-complete zip=\"$ctrl.obj.postal_code\" state=\"$ctrl.obj.state\" city=\"$ctrl.obj.city\">\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label\">Address Line 2</label>\n" +
    "              <input class=\"form-control\" name=\"street_2\" autocomplete=\"off\" ng-model=\"$ctrl.obj.street_2\">\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label\">City</label>\n" +
    "              <input class=\"form-control\" name=\"city\" autocomplete=\"off\" ng-model=\"$ctrl.obj.city\">\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"col-sm-6\">\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"control-label\">State</label>\n" +
    "                  <input class=\"form-control\" name=\"state\" autocomplete=\"off\" ng-model=\"$ctrl.obj.state\">\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-6\">\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"control-label\">ZIP / Postal Code</label>\n" +
    "                  <input class=\"form-control\" name=\"postal_code\" autocomplete=\"off\" ng-model=\"$ctrl.obj.postal_code\">\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-4\">\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label\">Description</label>\n" +
    "              <textarea class=\"form-control\" autocomplete=\"off\" ng-model=\"$ctrl.obj.description\" style=\"height:256px\"></textarea>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </fieldset>\n" +
    "      <pre class=\"alert alert-danger m-b-0\" ng-if=\"$ctrl.error !== null\">{{ $ctrl.error | json }}</pre>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</form>\n" +
    "\n" +
    "<referral-practice-membership\n" +
    "  ng-if=\"$ctrl.obj.id\"\n" +
    "  obj-id=\"$ctrl.obj.id\">\n" +
    "</referral-practice-membership>\n" +
    "")

$templateCache.put("src/referrals/components/referral-practice-list.html","\n" +
    "<div class=\"page-bar\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "      <a ui-sref=\"charts\">Home</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <a ui-sref=\"settings\">Settings</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>External Practices</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"portlet light porlet-fit\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption\">\n" +
    "      <i class=\"icomoon icon-icomoon-store font-red-sunglo\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold uppercase\">External Practices</span>\n" +
    "    </div>\n" +
    "    <div class=\"actions\">\n" +
    "      <button class=\"btn btn-wide default\" type=\"button\" ng-click=\"$ctrl.load()\">\n" +
    "        Reload\n" +
    "      </button>\n" +
    "      <a class=\"btn btn-primary btn-wide\" ui-sref=\"^.practice-detail({id:'new'})\" ng-if=\"$ctrl.canCreate\">\n" +
    "        Create\n" +
    "      </a>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body pos-r\">\n" +
    "    <wave-spinner class=\"table-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "    <div class=\"table-responsive\">\n" +
    "      <table class=\"table table-striped table-bordered table-advance\">\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <th class=\"cursor-pointer\" ng-click=\"$ctrl.orderByChanged('name')\">\n" +
    "              <span class=\"pull-right\" ng-show=\"$ctrl.orderByIs('name')\">\n" +
    "                <i ng-hide=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                <i ng-show=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "              </span>\n" +
    "              Name\n" +
    "            </th>\n" +
    "            <th class=\"cursor-pointer\" ng-click=\"$ctrl.orderByChanged('type')\">\n" +
    "              <span class=\"pull-right\" ng-show=\"$ctrl.orderByIs('type')\">\n" +
    "                <i ng-hide=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                <i ng-show=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "              </span>\n" +
    "              Type\n" +
    "            </th>\n" +
    "            <th class=\"cursor-pointer narrow\" ng-click=\"$ctrl.orderByChanged('referrals_count')\">\n" +
    "              <span class=\"pull-right\" ng-show=\"$ctrl.orderByIs('referrals_count')\">\n" +
    "                <i ng-hide=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                <i ng-show=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "              </span>\n" +
    "              <span ng-class=\"{'m-r': $ctrl.orderByIs('referrals_count')}\">Referrals</span>\n" +
    "            </th>\n" +
    "            <th class=\"cursor-pointer narrow\" ng-click=\"$ctrl.orderByChanged('referralusers_count')\">\n" +
    "              <span class=\"pull-right\" ng-show=\"$ctrl.orderByIs('referralusers_count')\">\n" +
    "                <i ng-hide=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                <i ng-show=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "              </span>\n" +
    "              <span ng-show=\"$ctrl.orderByIs('referralusers_count')\"\n" +
    "                ng-class=\"{'m-r': $ctrl.orderByIs('referralusers_count')}\">\n" +
    "                Ext Users\n" +
    "              </span>\n" +
    "              <abbr ng-hide=\"$ctrl.orderByIs('referralusers_count')\"\n" +
    "                title\n" +
    "                tooltip-append-to-body=\"true\"\n" +
    "                uib-tooltip=\"External Users who can login\">\n" +
    "                Ext Users\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"narrow\"></th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "          <tr ng-repeat=\"obj in $ctrl.objs | orderBy: $ctrl.orderBy:$ctrl.orderByReverse\">\n" +
    "            <td>{{ obj.name }}</td>\n" +
    "            <td class=\"narrow text-center\">{{ obj.type }}</td>\n" +
    "            <td class=\"narrow text-center\">{{ obj.referrals_count }}</td>\n" +
    "            <td class=\"narrow text-center\">{{ obj.referralusers_count }}</td>\n" +
    "            <td class=\"btn-container-pixel-perfect-1x\">\n" +
    "              <a class=\"btn-icomoon-pixel-perfect-1x pull-right\" ng-if=\"$ctrl.canRetrieve\" ui-sref=\"^.practice-detail({id:obj.id})\">\n" +
    "                <span class=\"icomoon\" ng-class=\"$ctrl.canUpdate ? 'icon-icomoon-pencil5' : 'icon-icomoon-eye2'\"></span>\n" +
    "              </a>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </tbody>\n" +
    "        <tfoot>\n" +
    "          <tr ng-if=\"$ctrl.objs === null\">\n" +
    "            <td colspan=5>\n" +
    "              <pre>{{ $ctrl.error | json }}</pre>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </tfoot>\n" +
    "      </table>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/referrals/components/referral-practice-membership.html","\n" +
    "<div class=\"portlet light\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption\">\n" +
    "      <i class=\"icomoon icon-icomoon-users font-red-sunglo\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold uppercase\">\n" +
    "        <span>External Users Membership</span>\n" +
    "      </span>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body pos-r\">\n" +
    "    <wave-spinner class=\"table-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "    <div class=\"table-responsive\">\n" +
    "      <table class=\"table table-bordered table-advance\">\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <th></th>\n" +
    "            <th style=\"vertical-align:middle;\">Name</th>\n" +
    "            <th style=\"text-align:center; vertical-align:middle;\">Specialty</th>\n" +
    "            <th style=\"vertical-align:middle;\">Email</th>\n" +
    "            <th style=\"text-align:center; vertical-align:middle; width:100px;\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Can login to external practice\">\n" +
    "                Can Login\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th style=\"text-align:center; vertical-align:middle; width:100px;\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\"\n" +
    "                uib-tooltip=\"Can view referrals made by other external practice users\">\n" +
    "                Can View others\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th style=\"text-align:center; vertical-align:middle; width:100px;\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\"\n" +
    "                uib-tooltip=\"For Example, a receptionist can submit referral on behalf of doctors in their office.\">\n" +
    "                Can Submit referrals for others\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"narrow\"></th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "          <tr ng-repeat=\"obj in $ctrl.objs track by $index\" ng-class=\"{'info': $ctrl.isDirty(obj), 'text-muted': !obj.id}\">\n" +
    "            <td class=\"narrow text-right\">{{ obj.referral_user_id }}</td>\n" +
    "            <td class=\"text-nowrap\">\n" +
    "              <span uib-tooltip=\"{{ obj.referral_user_last_name + ', ' + obj.referral_user_first_name }}\"\n" +
    "                tooltip-append-to-body=\"true\" tooltip-popup-delay=\"1000\">\n" +
    "                {{ (obj.referral_user_last_name + \", \" + obj.referral_user_first_name) | truncate:35 }}\n" +
    "              </span>\n" +
    "            </td>\n" +
    "            <td class=\"narrow text-center\">\n" +
    "              {{ $ctrl.specialties ? $ctrl.specialties[obj.referral_user_specialty] : obj.referral_user_specialty }}\n" +
    "            </td>\n" +
    "            <td class=\"narrow\">{{ obj.referral_user_email }}</td>\n" +
    "            <td class=\"td-switch-small-ct\">\n" +
    "              <div class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "                <label>\n" +
    "                  <input type=\"checkbox\"\n" +
    "                    ng-model=\"obj.can_access\"\n" +
    "                    ng-disabled=\"!$ctrl.canSave(obj)\">\n" +
    "                  <span class=\"custom-control-indicator\"></span>\n" +
    "                </label>\n" +
    "              </div>\n" +
    "            </td>\n" +
    "            <td class=\"td-switch-small-ct\">\n" +
    "              <div class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "                <label>\n" +
    "                  <input type=\"checkbox\"\n" +
    "                    ng-model=\"obj.can_access_all\"\n" +
    "                    ng-disabled=\"!$ctrl.canSave(obj) || !obj.can_access\">\n" +
    "                  <span class=\"custom-control-indicator\"></span>\n" +
    "                </label>\n" +
    "              </div>\n" +
    "            </td>\n" +
    "            <td class=\"td-switch-small-ct\">\n" +
    "              <div class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "                <label>\n" +
    "                  <input type=\"checkbox\"\n" +
    "                    ng-model=\"obj.can_create_referrals_on_behalf_of_others\"\n" +
    "                    ng-disabled=\"!$ctrl.canSave(obj) || !obj.can_access || !obj.can_access_all\">\n" +
    "                  <span class=\"custom-control-indicator\"></span>\n" +
    "                </label>\n" +
    "              </div>\n" +
    "            </td>\n" +
    "            <td class=\"btn-container-pixel-perfect-1x\">\n" +
    "              <a class=\"btn-icomoon-pixel-perfect-1x pull-right\"\n" +
    "                ng-if=\"$ctrl.canRetrieveReferralUser\"\n" +
    "                ui-sref=\"^.user-detail({id:obj.referral_user_id})\">\n" +
    "                <span class=\"icomoon\" ng-class=\"$ctrl.canUpdateReferralUser ? 'icon-icomoon-pencil5' : 'icon-icomoon-eye2'\"></span>\n" +
    "              </a>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </tbody>\n" +
    "        <tfoot ng-if=\"$ctrl.errors\" class=\"bg-danger\">\n" +
    "          <tr ng-repeat=\"error in $ctrl.errors track by $index\">\n" +
    "            <td colspan=6>\n" +
    "              <div ng-bind-html=\"error\"></div>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </tfoot>\n" +
    "      </table>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/referrals/components/referral-user-detail.html","\n" +
    "<div class=\"page-bar\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "      <a ui-sref=\"charts\">Home</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <a ui-sref=\"settings\">Settings</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <a ui-sref=\"settings-referrals.user-list\">External Users</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span ng-if=\"$ctrl.obj.id\">Edit</span>\n" +
    "      <span ng-if=\"!$ctrl.obj.id\">Create</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "\n" +
    "<form name=\"form\" autocomplete=\"off\">\n" +
    "  <div class=\"portlet light\">\n" +
    "    <div class=\"portlet-title\">\n" +
    "      <div class=\"caption\">\n" +
    "        <i class=\"icomoon icon-icomoon-user font-red-sunglo\"></i>\n" +
    "        <span class=\"caption-subject font-red-sunglo bold uppercase\">\n" +
    "          <span>External User</span>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "      <div class=\"actions\">\n" +
    "        <a class=\"btn btn-default btn-wide\"\n" +
    "          ui-sref=\"^.user-list\">\n" +
    "          Back\n" +
    "        </a>\n" +
    "        <button class=\"btn btn-danger btn-wide\" type=\"button\"\n" +
    "          ng-click=\"$ctrl.destroy()\"\n" +
    "          ng-disabled=\"$ctrl.loading || !$ctrl.canDelete\">\n" +
    "          Delete\n" +
    "        </button>\n" +
    "        <button class=\"btn default btn-wide\" type=\"button\"\n" +
    "          ng-click=\"$ctrl.retrieve(true)\"\n" +
    "          ng-disabled=\"$ctrl.loading || !$ctrl.canRetrieve\">\n" +
    "          Reload\n" +
    "        </button>\n" +
    "        <button class=\"btn btn-primary btn-wide\" type=\"button\"\n" +
    "          ng-click=\"$ctrl.save()\"\n" +
    "          ng-disabled=\"$ctrl.loading || !$ctrl.canSave || form.$invalid\">\n" +
    "          Save\n" +
    "        </button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"portlet-body pos-r\">\n" +
    "      <wave-spinner class=\"table-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "      <fieldset ng-disabled=\"!$ctrl.canSave\" ng-if=\"$ctrl.obj !== null\">\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"col-sm-4\">\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-error': form.first_name.$invalid}\">\n" +
    "              <label class=\"control-label\">\n" +
    "                First Name *\n" +
    "                <span ng-if=\"form.first_name.$invalid\">\n" +
    "                  (<span ng-repeat=\"(error, _) in form.first_name.$error\">{{ error }}</span>)\n" +
    "                </span>\n" +
    "              </label>\n" +
    "              <input class=\"form-control\" name=\"first_name\" autocomplete=\"off\" ng-model=\"$ctrl.obj.first_name\" required>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-error': form.last_name.$invalid}\">\n" +
    "              <label class=\"control-label\">\n" +
    "                Last Name *\n" +
    "                <span ng-if=\"form.last_name.$invalid\">\n" +
    "                  (<span ng-repeat=\"(error, _) in form.last_name.$error\">{{ error }}</span>)\n" +
    "                </span>\n" +
    "              </label>\n" +
    "              <input class=\"form-control\" name=\"last_name\" autocomplete=\"off\" ng-model=\"$ctrl.obj.last_name\" required>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-error': form.email.$invalid}\">\n" +
    "              <label class=\"control-label\">\n" +
    "                Email Name *\n" +
    "                <span ng-if=\"form.email.$invalid\">\n" +
    "                  (<span ng-repeat=\"(error, _) in form.email.$error\">{{ error }}</span>)\n" +
    "                </span>\n" +
    "              </label>\n" +
    "              <input class=\"form-control\" name=\"email\" autocomplete=\"off\" ng-model=\"$ctrl.obj.email\" required>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-error': form.phone.$invalid}\">\n" +
    "              <label class=\"control-label\">\n" +
    "                Phone *\n" +
    "                <span ng-if=\"form.phone.$invalid\">\n" +
    "                  (<span ng-repeat=\"(error, _) in form.phone.$error\">{{ error }}</span>)\n" +
    "                </span>\n" +
    "              </label>\n" +
    "              <phone-input name=\"phone\" autocomplete=\"off\" ng-model=\"$ctrl.obj.phone\" ng-required=\"true\"/>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-4\">\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label\">Specialty (if applicable)</label>\n" +
    "              <ui-select ng-model=\"$ctrl.obj.specialty\" theme=\"selectize\" ng-disabled=\"!$ctrl.canSave\">\n" +
    "                <ui-select-match placeholder=\"\">\n" +
    "                  {{ $select.selected.display }}\n" +
    "                  <button type=\"button\" class=\"btn btn-link btn-icomoon-pixel-perfect-1x btn-ui-select-clear\"\n" +
    "                    ng-click=\"$select.clear($event) ; $select.search=null\">\n" +
    "                    <i class=\"icomoon icon-icomoon-cross3\"></i>\n" +
    "                  </button>\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices repeat=\"specialty.value as specialty in $ctrl.metadata.schema.specialty.choices | filter:$select.search\">\n" +
    "                  <span ng-bind-html=\"specialty.display | highlight: $select.search\"></span>\n" +
    "                  <small class=\"pull-right\" ng-bind-html=\"specialty.value | highlight: $select.search\"></small>\n" +
    "                </ui-select-choices>\n" +
    "              </ui-select>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label\">\n" +
    "                Provider NPI (if applicable)\n" +
    "              </label>\n" +
    "              <input class=\"form-control\" name=\"npi\" autocomplete=\"off\" ng-model=\"$ctrl.obj.npi\">\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-4\">\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label\">Notes</label>\n" +
    "              <textarea class=\"form-control\" name=\"description\" autocomplete=\"off\" ng-model=\"$ctrl.obj.description\" style=\"height:256px\"></textarea>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </fieldset>\n" +
    "      <pre class=\"alert alert-danger m-b-0\" ng-if=\"$ctrl.error !== null\">{{ $ctrl.error | json }}</pre>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</form>\n" +
    "\n" +
    "<referral-user-membership\n" +
    "  ng-if=\"$ctrl.obj.id\"\n" +
    "  obj-id=\"$ctrl.obj.id\">\n" +
    "</referral-user-membership>\n" +
    "")

$templateCache.put("src/referrals/components/referral-user-list.html","<div class=\"page-bar\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "      <a ui-sref=\"charts\">Home</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <a ui-sref=\"settings\">Settings</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>External Users</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"portlet light porlet-fit\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption\">\n" +
    "      <i class=\"icomoon icon-icomoon-users font-red-sunglo\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold uppercase\">External Users</span>\n" +
    "    </div>\n" +
    "    <div class=\"actions\">\n" +
    "      <button class=\"btn btn-wide default\" type=\"button\" ng-click=\"$ctrl.load()\">\n" +
    "        Reload\n" +
    "      </button>\n" +
    "      <a class=\"btn btn-primary btn-wide\" ui-sref=\"^.user-detail({id:'new'})\" ng-if=\"$ctrl.canCreate\">\n" +
    "        Create\n" +
    "      </a>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body pos-r\">\n" +
    "    <wave-spinner class=\"table-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "    <div class=\"table-responsive\">\n" +
    "      <table class=\"table table-striped table-bordered table-advance\">\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <th class=\"cursor-pointer\" ng-click=\"$ctrl.orderByChanged(['last_name', 'first_name'])\">\n" +
    "              <span class=\"pull-right\" ng-show=\"$ctrl.orderByIs(['last_name', 'first_name'])\">\n" +
    "                <i ng-hide=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                <i ng-show=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "              </span>\n" +
    "              Name\n" +
    "            </th>\n" +
    "            <th class=\"cursor-pointer narrow\" ng-click=\"$ctrl.orderByChanged('email')\">\n" +
    "              <span class=\"pull-right\" ng-show=\"$ctrl.orderByIs('email')\">\n" +
    "                <i ng-hide=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                <i ng-show=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "              </span>\n" +
    "              Email\n" +
    "            </th>\n" +
    "            <th class=\"cursor-pointer narrow\" ng-click=\"$ctrl.orderByChanged('specialty')\">\n" +
    "              <span class=\"pull-right\" ng-show=\"$ctrl.orderByIs('specialty')\">\n" +
    "                <i ng-hide=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                <i ng-show=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "              </span>\n" +
    "              <span ng-class=\"{'m-r': $ctrl.orderByIs('specialty')}\">Specialty</span>\n" +
    "            </th>\n" +
    "            <th class=\"cursor-pointer narrow\" ng-click=\"$ctrl.orderByChanged('referrals_count')\">\n" +
    "              <span class=\"pull-right\" ng-show=\"$ctrl.orderByIs('referrals_count')\">\n" +
    "                <i ng-hide=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                <i ng-show=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "              </span>\n" +
    "              <span ng-class=\"{'m-r': $ctrl.orderByIs('referrals_count')}\">Referrals</span>\n" +
    "            </th>\n" +
    "            <th class=\"cursor-pointer narrow\" ng-click=\"$ctrl.orderByChanged('referralpractices_count')\">\n" +
    "              <span class=\"pull-right\" ng-show=\"$ctrl.orderByIs('referralpractices_count')\">\n" +
    "                <i ng-hide=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                <i ng-show=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "              </span>\n" +
    "              <span ng-show=\"$ctrl.orderByIs('referralpractices_count')\"\n" +
    "                ng-class=\"{'m-r': $ctrl.orderByIs('referralpractices_count')}\">\n" +
    "                Ext Practices\n" +
    "              </span>\n" +
    "              <abbr ng-hide=\"$ctrl.orderByIs('referralpractices_count')\"\n" +
    "                title\n" +
    "                tooltip-append-to-body=\"true\"\n" +
    "                uib-tooltip=\"External Practices where user can login\">\n" +
    "                Ext Practices\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"narrow\"></th>\n" +
    "            <th class=\"narrow\"></th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "          <tr ng-repeat=\"obj in $ctrl.objs | orderBy: $ctrl.orderBy:$ctrl.orderByReverse\">\n" +
    "            <td class=\"text-nowrap\">\n" +
    "              <span uib-tooltip=\"{{ obj.last_name + ', ' + obj.first_name }}\" tooltip-append-to-body=\"true\" tooltip-popup-delay=\"1000\">\n" +
    "                {{ (obj.last_name + \", \" + obj.first_name) | truncate:35 }}\n" +
    "              </span>\n" +
    "            </td>\n" +
    "            <td class=\"narrow\">{{ obj.email }}</td>\n" +
    "            <td class=\"narrow text-center\">{{ obj.specialty }}</td>\n" +
    "            <td class=\"narrow text-center\">{{ obj.referrals_count }}</td>\n" +
    "            <td class=\"narrow text-center\">{{ obj.referralpractices_count }}</td>\n" +
    "            <td>\n" +
    "              <div class=\"btn-group\" style=\"display:flex; justify-content:center;\">\n" +
    "                <button type=\"button\" class=\"btn btn-xs btn-default\" ng-if=\"obj.has_usable_password\"\n" +
    "                  ng-click=\"$ctrl.sendEmail(obj, 'reset-password')\">\n" +
    "                  <span class=\"icomoon icon-icomoon-reset\"></span>\n" +
    "                  Reset Password\n" +
    "                </button>\n" +
    "                <button type=\"button\" class=\"btn btn-xs btn-info\" ng-if=\"!obj.has_usable_password\"\n" +
    "                  ng-click=\"$ctrl.sendEmail(obj, 'invite')\">\n" +
    "                  <span class=\"icomoon icon-icomoon-reset\"></span>\n" +
    "                  Send Invitation\n" +
    "                </button>\n" +
    "              </div>\n" +
    "            </td>\n" +
    "            <td class=\"btn-container-pixel-perfect-1x\">\n" +
    "              <a class=\"btn-icomoon-pixel-perfect-1x pull-right\" ng-if=\"$ctrl.canRetrieve\"\n" +
    "                ui-sref=\"^.user-detail({id:obj.id})\">\n" +
    "                <span class=\"icomoon\" ng-class=\"$ctrl.canUpdate ? 'icon-icomoon-pencil5' : 'icon-icomoon-eye2'\"></span>\n" +
    "              </a>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </tbody>\n" +
    "        <tfoot>\n" +
    "          <tr ng-if=\"$ctrl.objs === null\">\n" +
    "            <td colspan=7>\n" +
    "              <pre>{{ $ctrl.error | json }}</pre>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </tfoot>\n" +
    "      </table>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/referrals/components/referral-user-membership.html","\n" +
    "<div class=\"portlet light\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption\">\n" +
    "      <i class=\"icomoon icon-icomoon-users font-red-sunglo\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold uppercase\">\n" +
    "        <span>External Practices Membership</span>\n" +
    "      </span>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body pos-r\">\n" +
    "    <wave-spinner class=\"table-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "    <div class=\"table-responsive\">\n" +
    "      <table class=\"table table-bordered table-advance\">\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <th></th>\n" +
    "            <th style=\"vertical-align:middle;\">Name</th>\n" +
    "            <th style=\"text-align:center; vertical-align:middle;\">Type</th>\n" +
    "            <th style=\"vertical-align:middle;\">Email</th>\n" +
    "            <th style=\"text-align:center; vertical-align:middle; width:100px;\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\" uib-tooltip=\"Can login to external practice\">\n" +
    "                Can Login\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th style=\"text-align:center; vertical-align:middle; width:100px;\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\"\n" +
    "                uib-tooltip=\"Can view referrals made by other external practice users\">\n" +
    "                Can View others\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th style=\"text-align:center; vertical-align:middle; width:100px;\">\n" +
    "              <abbr title tooltip-append-to-body=\"true\"\n" +
    "                uib-tooltip=\"For Example, a receptionist can submit referral on behalf of doctors in their office.\">\n" +
    "                Can Submit referrals for others\n" +
    "              </abbr>\n" +
    "            </th>\n" +
    "            <th class=\"narrow\"></th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "          <tr ng-repeat=\"obj in $ctrl.objs track by $index\" ng-class=\"{'info': $ctrl.isDirty(obj), 'text-muted': !obj.id}\">\n" +
    "            <td class=\"narrow text-right\">{{ obj.referral_practice_id }}</td>\n" +
    "            <!-- <td>{{ obj.referral_practice_name }}</td> -->\n" +
    "\n" +
    "            <td class=\"text-nowrap\">\n" +
    "              <span uib-tooltip=\"{{ obj.referral_practice_name }}\"\n" +
    "                tooltip-append-to-body=\"true\" tooltip-popup-delay=\"1000\">\n" +
    "                {{ obj.referral_practice_name | truncate:35 }}\n" +
    "              </span>\n" +
    "            </td>\n" +
    "\n" +
    "\n" +
    "            <td class=\"narrow text-center\">\n" +
    "              {{ $ctrl.types ? $ctrl.types[obj.referral_practice_type] : obj.referral_practice_type }}\n" +
    "            </td>\n" +
    "            <td class=\"narrow\">{{ obj.referral_practice_email }}</td>\n" +
    "            <td class=\"td-switch-small-ct\">\n" +
    "              <div class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "                <label>\n" +
    "                  <input type=\"checkbox\"\n" +
    "                    ng-model=\"obj.can_access\"\n" +
    "                    ng-disabled=\"!$ctrl.canSave(obj)\">\n" +
    "                  <span class=\"custom-control-indicator\"></span>\n" +
    "                </label>\n" +
    "              </div>\n" +
    "            </td>\n" +
    "            <td class=\"td-switch-small-ct\">\n" +
    "              <div class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "                <label>\n" +
    "                  <input type=\"checkbox\"\n" +
    "                    ng-model=\"obj.can_access_all\"\n" +
    "                    ng-disabled=\"!$ctrl.canSave(obj) || !obj.can_access\">\n" +
    "                  <span class=\"custom-control-indicator\"></span>\n" +
    "                </label>\n" +
    "              </div>\n" +
    "            </td>\n" +
    "            <td class=\"td-switch-small-ct\">\n" +
    "              <div class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "                <label>\n" +
    "                  <input type=\"checkbox\"\n" +
    "                    ng-model=\"obj.can_create_referrals_on_behalf_of_others\"\n" +
    "                    ng-disabled=\"!$ctrl.canSave(obj) || !obj.can_access || !obj.can_access_all\">\n" +
    "                  <span class=\"custom-control-indicator\"></span>\n" +
    "                </label>\n" +
    "              </div>\n" +
    "            </td>\n" +
    "            <td class=\"btn-container-pixel-perfect-1x\">\n" +
    "              <a class=\"btn-icomoon-pixel-perfect-1x pull-right\"\n" +
    "                ng-if=\"$ctrl.canRetrieveReferralPractice\"\n" +
    "                ui-sref=\"^.practice-detail({id:obj.referral_practice_id})\">\n" +
    "                <span class=\"icomoon\" ng-class=\"$ctrl.canUpdateReferralPractice ? 'icon-icomoon-pencil5' : 'icon-icomoon-eye2'\"></span>\n" +
    "              </a>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </tbody>\n" +
    "        <tfoot ng-if=\"$ctrl.errors\" class=\"bg-danger\">\n" +
    "          <tr ng-repeat=\"error in $ctrl.errors track by $index\">\n" +
    "            <td colspan=6>\n" +
    "              <div ng-bind-html=\"error\"></div>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </tfoot>\n" +
    "      </table>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/referrals/components/referral.html","\n" +
    "<div class=\"portlet light porlet-fit pos-r\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption cursor-pointer\" ng-click=\"$ctrl.$onInit()\">\n" +
    "      <i class=\"icomoon icon-icomoon-bubbles9 font-red-sunglo\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold\">\n" +
    "        <span ng-if=\"$ctrl.meta.role === 'received'\">\n" +
    "          Referral\n" +
    "          <span ng-if=\"$ctrl.practice\"> from {{ $ctrl.practice.name }} - </span>\n" +
    "          <span ng-if=\"$ctrl.user\">{{ $ctrl.user.first_name }} {{ $ctrl.user.last_name }} </span>\n" +
    "          <span ng-if=\"$ctrl.patient\">for patient {{ $ctrl.patient.first_name }} {{ $ctrl.patient.last_name }} </span>\n" +
    "        </span>\n" +
    "      </span>\n" +
    "    </div>\n" +
    "    <div class=\"actions\">\n" +
    "      <a class=\"btn btn-default p-x-md\" ui-sref=\"^\" ui-sref-opts=\"{reload: true}\">Back</a>\n" +
    "      <button type=\"button\" class=\"btn btn-danger p-x-md\" ng-click=\"$ctrl.destroy($ctrl.referral)\" ng-if=\"$ctrl.CanDeleteReferral && $ctrl.referral.reports.length === 0\">Delete Referral</button>\n" +
    "      <button class=\"btn btn-primary p-x-md\" ng-click=\"$ctrl.save($ctrl.referral)\" ng-disabled=\"rform.$invalid\" ng-if=\"$ctrl.CanSaveReferral\">Save</button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body pos-r\">\n" +
    "    <div class=\"tabbable-custom\">\n" +
    "      <ul class=\"nav nav-tabs\">\n" +
    "        <li class=\"active\"><a class=\"p-x-md\">General</a></li>\n" +
    "        <li ng-if=\"$ctrl.CanListReport && $ctrl.referral.id && $ctrl.referral.patient\">\n" +
    "          <a class=\"p-x-md\" ui-sref=\"{{ $ctrl.meta.ns }}.{{ $ctrl.meta.role }}.detail.reports\">Reports</a>\n" +
    "        </li>\n" +
    "      </ul>\n" +
    "      <div class=\"tab-content\">\n" +
    "        <div class=\"tab-pane active\">\n" +
    "          <form name=\"rform\" class=\"pos-r m-x\" novalidate>\n" +
    "            <circle-spinner class=\"global-loader\" ng-show=\"$ctrl.loading\"></circle-spinner>\n" +
    "            <div class=\"row m-t\">\n" +
    "              <div class=\"form-horizontal col-sm-12 p-x-0\">\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-error': rform.ext_user.$invalid}\">\n" +
    "                  <label class=\"col-sm-2 control-label text-nowrap\">Referrer <span ng-show=\"rform.ext_user.$error.required\">*</span></label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <ui-select name=\"ext_user\" ng-disabled=\"$ctrl.referral.created_by.content_type === 'referrals.referraluser'\" ng-model=\"$ctrl.referral.ext_user\" ng-required=\"true\" on-select=\"$ctrl.onSelectUser($model)\">\n" +
    "                      <ui-select-match>\n" +
    "                        <table>\n" +
    "                          <tr>\n" +
    "                            <td class=\"w-full wide-in-one-line\" uib-tooltip=\"{{ $select.selected.last_name }}, {{ $select.selected.first_name }}\" tooltip-append-to-body=\"true\">\n" +
    "                              {{ $select.selected.last_name }}, {{ $select.selected.first_name }}\n" +
    "                            </td>\n" +
    "                            <td style=\"padding-left:6px\"><small>{{ $select.selected.email }}</small></td>\n" +
    "                            <td style=\"padding-left:6px\">{{ $select.selected.phone | tel }}</td>\n" +
    "                            <td style=\"padding-left:6px\">\n" +
    "                              <button type=\"button\" class=\"btn btn-link btn-icomoon-pixel-perfect-1x\" ng-click=\"$select.clear($event) ; $select.search=null\" style=\"margin-top: -2px;\">\n" +
    "                                <i class=\"icomoon icon-icomoon-cross3\"></i>\n" +
    "                              </button>\n" +
    "                            </td>\n" +
    "                          </tr>\n" +
    "                        </table>\n" +
    "                      </ui-select-match>\n" +
    "                      <ui-select-choices repeat=\"u.id as u in $ctrl.referral_users | filter:$select.search\">\n" +
    "                        <table>\n" +
    "                          <tr>\n" +
    "                            <td class=\"w-full\" ng-bind-html=\"(u.last_name + ', ' + u.first_name) | highlight: $select.search\"></td>\n" +
    "                            <td style=\"padding-left:6px\">\n" +
    "                              <small ng-bind-html=\"u.email | highlight: $select.search\"></small>\n" +
    "                            </td>\n" +
    "                            <td ng-bind-html=\"u.phone | tel | highlight: $select.search\" style=\"padding-left:6px\"></td>\n" +
    "                          </tr>\n" +
    "                        </table>\n" +
    "                      </ui-select-choices>\n" +
    "                    </ui-select>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"form-horizontal col-sm-12 p-x-0\">\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-error': rform.ext_practice.$invalid}\">\n" +
    "                  <label class=\"col-sm-2 control-label text-nowrap\">On behalf of Practice <span ng-show=\"rform.ext_practice.$error.required\">*</span></label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <ui-select name=\"ext_practice\" ng-disabled=\"$ctrl.referral.created_by.content_type === 'referrals.referraluser'\" ng-model=\"$ctrl.referral.ext_practice\" ng-required=\"true\" on-select=\"$ctrl.onSelectPractice($model)\">\n" +
    "                      <ui-select-match>\n" +
    "                        <table>\n" +
    "                          <tr>\n" +
    "                            <td class=\"w-full wide-in-one-line\" uib-tooltip=\"{{ $select.selected.name }}\" tooltip-append-to-body=\"true\">\n" +
    "                              {{ $select.selected.name }}\n" +
    "                            </td>\n" +
    "                            <td style=\"padding-left:6px\"><small>{{ $select.selected.email }}</small></td>\n" +
    "                            <td style=\"padding-left:6px\">{{ $select.selected.phone | tel }}</td>\n" +
    "                            <td style=\"padding-left:6px\">\n" +
    "                              <button type=\"button\" class=\"btn btn-link btn-icomoon-pixel-perfect-1x\" ng-click=\"$select.clear($event) ; $select.search=null\" style=\"margin-top: -2px;\">\n" +
    "                                <i class=\"icomoon icon-icomoon-cross3\"></i>\n" +
    "                              </button>\n" +
    "                            </td>\n" +
    "                          </tr>\n" +
    "                        </table>\n" +
    "                      </ui-select-match>\n" +
    "                      <ui-select-choices repeat=\"referral_practice.id as referral_practice in $ctrl.referral_practices | filter:$select.search\">\n" +
    "                        <table>\n" +
    "                          <tr>\n" +
    "                            <td class=\"w-full\" ng-bind-html=\"referral_practice.name | highlight: $select.search\"></td>\n" +
    "                            <td style=\"padding-left:6px\">\n" +
    "                              <small ng-bind-html=\"referral_practice.email | highlight: $select.search\"></small>\n" +
    "                            </td>\n" +
    "                            <td ng-bind-html=\"referral_practice.phone | tel | highlight: $select.search\" style=\"padding-left:6px\"></td>\n" +
    "                          </tr>\n" +
    "                        </table>\n" +
    "                      </ui-select-choices>\n" +
    "                    </ui-select>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"form-horizontal col-sm-12 p-x-0\">\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-error': rform.referral_patient.$invalid}\" ng-if=\"$ctrl.CanListPatient && $ctrl.meta.ns !== 'patient-referrals'\">\n" +
    "                  <label class=\"col-sm-2 control-label text-nowrap\">Patient <span ng-show=\"rform.referral_patient.$error.required\">*</span></label>\n" +
    "                  <div class=\"col-sm-10\">\n" +
    "                    <table style=\"table-layout:fixed; width:100%\">\n" +
    "                      <tr>\n" +
    "                        <td>\n" +
    "                          <ui-select name=\"referral_patient\" ng-disabled=\"$ctrl.referral.reports.length > 0\" ng-model=\"$ctrl.referral.patient\" ng-required=\"false\" on-select=\"$ctrl.onSelectPatient($model, rform)\">\n" +
    "                            <ui-select-match placeholder=\"Select or search a patient in the list\">\n" +
    "                              <span class=\"text-overflow\">\n" +
    "                                {{ $select.selected[1] }}\n" +
    "                                <small class=\"text-muted m-l-sm\">{{ $select.selected[6] }}</small>\n" +
    "                                <small class=\"text-muted m-l-sm\">{{ $select.selected[3] }}</small>\n" +
    "                              </span>\n" +
    "                            </ui-select-match>\n" +
    "                            <ui-select-choices \n" +
    "                              repeat=\"patient[0] as patient in ($select.search ? $ctrl.patients : $ctrl.referral.patient ? [$ctrl.patients[0]] : []) | propsFilter: {filter:$select.search, props:['1', '6', '3']} | limitTo:250\"\n" +
    "                              ui-disable-choice=\"patient[4] === true\">\n" +
    "                              <span class=\"text-overflow dis-ib w-full\">\n" +
    "                                <span ng-bind-html=\"patient[1] | highlight: $select.search\"></span>\n" +
    "                                <small class=\"m-l-sm\" ng-bind-html=\"patient[6] | highlight: $select.search\"></small>\n" +
    "                                <small class=\"m-l-sm\" ng-bind-html=\"patient[3] | highlight: $select.search\" ng-if=\"patient[3]\"></small>\n" +
    "                              </span>\n" +
    "                            </ui-select-choices>\n" +
    "                          </ui-select>\n" +
    "                        </td>\n" +
    "                        <td style=\"width:30px;\" ng-if=\"$ctrl.referral.patient\">\n" +
    "                          <button class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" type=\"button\" style=\"height:34px; margin-left:-1px; margin-top: 1px;\"\n" +
    "                            ng-click=\"$ctrl.referral.patient = null\"\n" +
    "                            ng-disabled=\"$ctrl.referral.reports.length > 0\">\n" +
    "                            <i class=\"icomoon icon-icomoon-cross\"></i>\n" +
    "                          </button>\n" +
    "                        </td>\n" +
    "                        <td style=\"width:30px;\" ng-if=\"$ctrl.referral.patient\">\n" +
    "                          <a class=\"btn btn-default btn-icomoon-pixel-perfect-1x\" style=\"height:34px; line-height:30px; margin-left:-2px; margin-top: 1px;\" ui-sref=\"profile({patientid: $ctrl.referral.patient})\" target=\"_blank\">\n" +
    "                            <i class=\"icomoon icon-icomoon-user\"></i>\n" +
    "                          </a>\n" +
    "                        </td>\n" +
    "                      </tr>\n" +
    "                    </table>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"form-horizontal col-sm-6 p-x-0\">\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-error': rform.date.$invalid}\">\n" +
    "                  <label class=\"col-sm-4 control-label\">Referral Date <span ng-show=\"rform.date.$error.required\">*</span></label>\n" +
    "                  <div class=\"col-sm-8\">\n" +
    "                    <custom-datepicker-popup ng-if=\"$ctrl.referral.created_by.content_type !== 'referrals.referraluser'\" form-name=\"'f'\" format=\"'MM/dd/yyyy'\" input-name=\"'_date'\" name=\"date\" ng-model=\"$ctrl.referral.date\" ng-required=\"true\" show-placeholder=\"false\"></custom-datepicker-popup>\n" +
    "                    <p ng-if=\"$ctrl.referral.created_by.content_type === 'referrals.referraluser'\" class=\"form-control\">{{ $ctrl.referral.date | date:'MM/dd/yyyy' }}</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"form-horizontal col-sm-6 p-l-0\">\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"col-sm-4 control-label\">Is Completed</label>\n" +
    "                  <div class=\"col-sm-8\">\n" +
    "                    <div class=\"form-control-static p-b-0\">\n" +
    "                      <switch ng-model=\"$ctrl.referral.is_completed\" class=\"small\"></switch>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"form-horizontal col-sm-6 p-l-0 p-r-0\">\n" +
    "                <div class=\"form-group panel-heading\">\n" +
    "                  <h4 class=\"panel-title col-sm-offset-4 col-sm-8\">Submitted Patient</h4>\n" +
    "                </div>\n" +
    "                <fieldset class=\"as-read-only\" ng-disabled=\"$ctrl.referral.created_by.content_type === 'referrals.referraluser'\">\n" +
    "                  <div class=\"form-group\" ng-class=\"{'has-error': rform.ext_patient_first_name.$invalid}\">\n" +
    "                    <label class=\"col-sm-4 control-label\">First Name</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" name=\"ext_patient_first_name\" ng-change=\"$ctrl.onPropChange($ctrl.referral.ext_patient, 'first_name')\" ng-minlength=\"2\" ng-model=\"$ctrl.referral.ext_patient.first_name\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\" ng-class=\"{'has-error': rform.ext_patient_last_name.$invalid}\">\n" +
    "                    <label class=\"col-sm-4 control-label\">Last Name</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" name=\"ext_patient_last_name\" ng-change=\"$ctrl.onPropChange($ctrl.referral.ext_patient, 'last_name')\" ng-minlength=\"2\" ng-model=\"$ctrl.referral.ext_patient.last_name\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\" ng-class=\"{'has-error': rform.ext_patient_home_phone.$invalid}\">\n" +
    "                    <label class=\"col-sm-4 control-label\">Home Phone</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <phone-input ng-model=\"$ctrl.referral.ext_patient.home_phone\" name=\"ext_patient_home_phone\"></phone-input>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\" ng-class=\"{'has-error': rform.ext_patient_mobile_phone.$invalid}\">\n" +
    "                    <label class=\"col-sm-4 control-label\">Mobile Phone</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <phone-input ng-model=\"$ctrl.referral.ext_patient.mobile_phone\" name=\"ext_patient_mobile_phone\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">Email</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" ng-model=\"$ctrl.referral.ext_patient.email\" name=\"ext_patient_email\" ng-change=\"$ctrl.onPropChange($ctrl.referral.ext_patient, 'email')\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\" ng-class=\"{'has-error': rform.ext_patient_birth_date.$invalid}\">\n" +
    "                    <label class=\"col-sm-4 control-label\">Date of Birth</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <custom-datepicker-popup input-name=\"'ext_patient_birth_date'\" show-placeholder=\"false\"\n" +
    "                        ng-if=\"$ctrl.referral.created_by.content_type !== 'referrals.referraluser'\"\n" +
    "                        ng-model=\"$ctrl.referral.ext_patient.birth_date\"\n" +
    "                        ng-change=\"$ctrl.onPropChange($ctrl.referral.ext_patient, 'birth_date')\"\n" +
    "                        ivalue=\"''\"></custom-datepicker-popup>\n" +
    "                      <p class=\"form-control\" ng-if=\"$ctrl.referral.created_by.content_type === 'referrals.referraluser'\">\n" +
    "                        {{ $ctrl.referral.ext_patient.birth_date | date:'MM/dd/yyyy' }}\n" +
    "                      </p>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">Address Line 1</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" name=\"ext_patient_address_street_1\" google-places-auto-complete\n" +
    "                        ng-change=\"$ctrl.onPropChange($ctrl.referral.ext_patient, 'patient_address_street_1')\"\n" +
    "                        ng-model=\"$ctrl.referral.ext_patient.patient_address_street_1\"\n" +
    "                        city=\"$ctrl.referral.ext_patient.patient_address_city\"\n" +
    "                        state=\"$ctrl.referral.ext_patient.patient_address_state\"\n" +
    "                        zip=\"$ctrl.referral.ext_patient.patient_address_zip_code\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">Address Line 2</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" name=\"ext_patient_address_street_2\"\n" +
    "                        ng-change=\"$ctrl.onPropChange($ctrl.referral.ext_patient, 'patient_address_street_2')\"\n" +
    "                        ng-model=\"$ctrl.referral.ext_patient.patient_address_street_2\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">City</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" name=\"ext_patient_address_city\"\n" +
    "                        ng-change=\"$ctrl.onPropChange($ctrl.referral.ext_patient, 'patient_address_city')\"\n" +
    "                        ng-model=\"$ctrl.referral.ext_patient.patient_address_city\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\" ng-class=\"{'has-error': rform.ext_patient_address_state.$invalid}\">\n" +
    "                    <label class=\"col-sm-4 control-label\">State</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" name=\"ext_patient_address_state\" ui-mask-placeholder\n" +
    "                        ng-change=\"$ctrl.onPropChange($ctrl.referral.ext_patient, 'patient_address_state')\"\n" +
    "                        ng-model=\"$ctrl.referral.ext_patient.patient_address_state\"\n" +
    "                        ng-pattern=\"/^[A-Z]{2}$/\"\n" +
    "                        ui-mask=\"AA\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\" ng-class=\"{'has-error': rform.ext_patient_address_zip_code.$invalid}\">\n" +
    "                    <label class=\"col-sm-4 control-label\">ZIP / Postal Code</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" name=\"ext_patient_address_zip_code\" ui-mask-placeholder\n" +
    "                        ng-change=\"$ctrl.onPropChange($ctrl.referral.ext_patient, 'patient_address_zip_code')\"\n" +
    "                        ng-model=\"$ctrl.referral.ext_patient.patient_address_zip_code\"\n" +
    "                        ng-pattern=\"/^\\d{5}$/\"\n" +
    "                        ui-mask=\"99999\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </fieldset>\n" +
    "                <div class=\"form-group\">\n" +
    "                  <div class=\"col-sm-offset-4 col-sm-8\">\n" +
    "                    <button type=\"button\" class=\"btn btn-info p-x-md btn-block\"\n" +
    "                      ng-click=\"$ctrl.createPatient()\"\n" +
    "                      ng-if=\"$ctrl.CanCreatePatient\"\n" +
    "                      ng-disabled=\"\n" +
    "                        $ctrl.meta.ns === 'patient-referrals' ||\n" +
    "                        !$ctrl.referral.ext_patient ||\n" +
    "                        $ctrl.referral.ext_patient.created ||\n" +
    "                        rform.ext_patient_first_name.$invalid ||\n" +
    "                        rform.ext_patient_last_name.$invalid ||\n" +
    "                        rform.ext_patient_home_phone.$invalid ||\n" +
    "                        rform.ext_patient_mobile_phone.$invalid ||\n" +
    "                        rform.ext_patient_birth_date.$invalid ||\n" +
    "                        rform.ext_patient_address_state.$invalid ||\n" +
    "                        rform.ext_patient_address_zip_code.$invalid\n" +
    "                      \">\n" +
    "                      Create Patient from Submitted Info\n" +
    "                    </button>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"form-horizontal col-sm-6 p-l-0 p-r-0\">\n" +
    "                <div class=\"form-group panel-heading\">\n" +
    "                  <h4 class=\"panel-title col-sm-offset-4 col-sm-8\">Patient</h4>\n" +
    "                </div>\n" +
    "                <fieldset class=\"as-read-only\" ng-disabled=\"$ctrl.referral.patient === undefined || $ctrl.referral.patient === null || $ctrl.meta.ns === 'patient-referrals'\">\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">First Name</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" ng-model=\"$ctrl.patient.first_name\" name=\"patient_first_name\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">Last Name</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" ng-model=\"$ctrl.patient.last_name\" name=\"patient_last_name\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">Home Phone</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <phone-input ng-model=\"$ctrl.patient.home_phone\" name=\"patient_home_phone\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">Mobile Phone</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <phone-input ng-model=\"$ctrl.patient.mobile_phone\" name=\"patient_mobile_phone\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">Email</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" ng-model=\"$ctrl.patient.email\" name=\"patient_email\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">Date of Birth</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <custom-datepicker-popup input-name=\"'patient_birth_date'\" show-placeholder=\"false\" ng-model=\"$ctrl.patient.birth_date\"></custom-datepicker-popup>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">Address Line 1</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" google-places-auto-complete name=\"patient_address_street_1\"\n" +
    "                        ng-model=\"$ctrl.patient.patient_address_street_1\"\n" +
    "                        city=\"$ctrl.patient.patient_address_city\"\n" +
    "                        state=\"$ctrl.patient.patient_address_state\"\n" +
    "                        zip=\"$ctrl.patient.patient_address_zip_code\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">Address Line 2</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" name=\"patient_address_street_2\" ng-model=\"$ctrl.patient.patient_address_street_2\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">City</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" name=\"patient_address_city\" ng-model=\"$ctrl.patient.patient_address_city\">\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">State</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" name=\"patient_address_state\" ui-mask-placeholder\n" +
    "                        ng-model=\"$ctrl.patient.patient_address_state\"\n" +
    "                        ng-pattern=\"/^[A-Z]{2}$/\"\n" +
    "                        ui-mask=\"AA\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"col-sm-4 control-label\">ZIP / Postal Code</label>\n" +
    "                    <div class=\"col-sm-8\">\n" +
    "                      <input class=\"form-control\" name=\"patient_address_zip_code\" ui-mask-placeholder\n" +
    "                        ng-model=\"$ctrl.referral.ext_patient.patient_address_zip_code\"\n" +
    "                        ng-pattern=\"/^\\d{5}$/\"\n" +
    "                        ui-mask=\"99999\"/>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </fieldset>\n" +
    "                <div class=\"form-group\" ng-if=\"$ctrl.CanUpdatePatient\">\n" +
    "                  <div class=\"col-sm-offset-4 col-sm-8\">\n" +
    "                    <button type=\"button\" class=\"btn btn-info p-x-md btn-block\"\n" +
    "                      ng-click=\"$ctrl.updatePatient()\"\n" +
    "                      ng-if=\"$ctrl.CanUpdatePatient\"\n" +
    "                      ng-disabled=\"\n" +
    "                        $ctrl.meta.ns === 'patient-referrals' ||\n" +
    "                        $ctrl.referral.patient === undefined ||\n" +
    "                        $ctrl.referral.patient === null\n" +
    "                      \">\n" +
    "                      Update Patient\n" +
    "                    </button>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"panel-heading\" style=\"margin-left:-15px; margin-right:-15px\">\n" +
    "                <h4 class=\"panel-title text-center\">Referred for Consideration, for Consultation and/or Endodontic Treatment</h4>\n" +
    "              </div>\n" +
    "              <fieldset class=\"as-read-only\" ng-disabled=\"$ctrl.referral.created_by.content_type === 'referrals.referraluser'\">\n" +
    "                <div class=\"col-sm-6\">\n" +
    "                  <h5>Referred for the Following</h5>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"consultation_and_diagnosis\" ng-model=\"$ctrl.referral.referred_for.consultation_and_diagnosis\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Consultation & Diagnosis\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"root_canal_treatment\" ng-model=\"$ctrl.referral.referred_for.root_canal_treatment\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Root Canal Treatment\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"re_treatment\" ng-model=\"$ctrl.referral.referred_for.re_treatment\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Re-Treatment\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"leave_post_space\" ng-model=\"$ctrl.referral.referred_for.leave_post_space\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Leave Post Space\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"apicoectomy_retrograde\" ng-model=\"$ctrl.referral.referred_for.apicoectomy_retrograde\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Apicoectomy / Retrograde\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"apicoectomy_retrofilling\" ng-model=\"$ctrl.referral.referred_for.apicoectomy_retrofilling\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Apicoectomy / Retrofilling\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"pulp_exposure\" ng-model=\"$ctrl.referral.referred_for.pulp_exposure\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Pulp Exposure\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"remove_post\" ng-model=\"$ctrl.referral.referred_for.remove_post\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Remove Post\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"buildup\" ng-model=\"$ctrl.referral.referred_for.buildup\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Buildup\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"post_and_crown_buildup\" ng-model=\"$ctrl.referral.referred_for.post_and_crown_buildup\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Post and Crown Buildup\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"tooth_is_open_for_drainage\" ng-model=\"$ctrl.referral.referred_for.tooth_is_open_for_drainage\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Tooth is Open for Drainage\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"endodontic_microsurgery\" ng-model=\"$ctrl.referral.referred_for.endodontic_microsurgery\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Endodontic Microsurgery\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"col-sm-6\">\n" +
    "                  <h5>Other Information</h5>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"please_send_additional_referral_pads\" ng-model=\"$ctrl.referral.referred_for.please_send_additional_referral_pads\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Please send additional referral pads\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"please_call_patient_to_arrange_appt\" ng-model=\"$ctrl.referral.referred_for.please_call_patient_to_arrange_appt\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Please call patient to arrange appt\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "                      <label>\n" +
    "                        <input type=\"checkbox\" name=\"patient_will_call_you_to_arrange_appt\" ng-model=\"$ctrl.referral.referred_for.patient_will_call_you_to_arrange_appt\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Patient will call you to arrange appt\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label>Crown / Bridge is Cemented</label>\n" +
    "                    <div>\n" +
    "                      <div class=\"radio-inline custom-control custom-radio\">\n" +
    "                        <label>\n" +
    "                          <input type=\"radio\" name=\"crown_or_bridge_is_cemented\" ng-model=\"$ctrl.referral.referred_for.crown_or_bridge_is_cemented\" ng-value=\"true\">\n" +
    "                          <span class=\"custom-control-indicator\"></span>\n" +
    "                          Yes\n" +
    "                        </label>\n" +
    "                      </div>\n" +
    "                      <div class=\"radio-inline custom-control custom-radio\">\n" +
    "                        <label>\n" +
    "                          <input type=\"radio\" name=\"crown_or_bridge_is_cemented\" ng-model=\"$ctrl.referral.referred_for.crown_or_bridge_is_cemented\" ng-value=\"false\">\n" +
    "                          <span class=\"custom-control-indicator\"></span>\n" +
    "                          No\n" +
    "                        </label>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label>Please Call Me</label>\n" +
    "                    <div>\n" +
    "                      <div class=\"radio-inline custom-control custom-radio\">\n" +
    "                        <label>\n" +
    "                          <input type=\"radio\" name=\"please_call_me\" ng-model=\"$ctrl.referral.referred_for.please_call_me\" ng-value=\"true\">\n" +
    "                          <span class=\"custom-control-indicator\"></span>\n" +
    "                          Yes\n" +
    "                        </label>\n" +
    "                      </div>\n" +
    "                      <div class=\"radio-inline custom-control custom-radio\">\n" +
    "                        <label>\n" +
    "                          <input type=\"radio\" name=\"please_call_me\" ng-model=\"$ctrl.referral.referred_for.please_call_me\" ng-value=\"false\">\n" +
    "                          <span class=\"custom-control-indicator\"></span>\n" +
    "                          No\n" +
    "                        </label>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <h5 class=\"m-t-md\">Images</h5>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <div class=\"radio custom-control custom-radio\">\n" +
    "                      <label>\n" +
    "                        <input type=\"radio\" name=\"xray\" ng-model=\"$ctrl.referral.referred_for.xray\" ng-value=\"'attached'\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Attached to Referral\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                    <div class=\"radio custom-control custom-radio\">\n" +
    "                      <label>\n" +
    "                        <input type=\"radio\" name=\"xray\" ng-model=\"$ctrl.referral.referred_for.xray\" ng-value=\"'given_to_patient'\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Given to Patient\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                    <div class=\"radio custom-control custom-radio\">\n" +
    "                      <label>\n" +
    "                        <input type=\"radio\" name=\"xray\" ng-model=\"$ctrl.referral.referred_for.xray\" ng-value=\"'please_take'\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        Please Take\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                    <div class=\"radio custom-control custom-radio\">\n" +
    "                      <label>\n" +
    "                        <input type=\"radio\" name=\"xray\" ng-model=\"$ctrl.referral.referred_for.xray\" ng-value=\"'no'\">\n" +
    "                        <span class=\"custom-control-indicator\"></span>\n" +
    "                        No X-Ray\n" +
    "                      </label>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </fieldset>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"panel-heading\" style=\"margin-left:-15px; margin-right:-15px\">\n" +
    "                <h4 class=\"panel-title text-center\">Please mark teeth or area to be treated</h4>\n" +
    "              </div>\n" +
    "              <fieldset class=\"as-read-only\" ng-disabled=\"$ctrl.referral.created_by.content_type === 'referrals.referraluser'\" style=\"overflow-x:auto\">\n" +
    "                <div class=\"tooth-picker m-y\">\n" +
    "                  <img class=\"thumbnail m-b-0\" src=\"assets/tooth-picker/tooth-picker.png\">\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:66px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[01]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[01]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:93px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[02]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[02]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:133px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[03]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[03]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:169px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[04]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[04]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:197px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[05]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[05]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:228px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[06]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[06]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:260px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[07]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[07]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:292px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[08]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[08]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:327px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[09]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[09]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:358px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[10]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[10]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:389px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[11]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[11]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:421px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[12]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[12]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:451px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[13]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[13]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:485px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[14]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[14]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:525px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[15]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[15]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:95px;left:560px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[16]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[16]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:56px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[32]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[32]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:93px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[31]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[31]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:133px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[30]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[30]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:169px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[29]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[29]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:197px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[28]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[28]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:228px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[27]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[27]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:260px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[26]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[26]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:292px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[25]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[25]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:327px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[24]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[24]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:358px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[23]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[23]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:389px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[22]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[22]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:421px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[21]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[21]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:451px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[20]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[20]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:485px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[19]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[19]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:525px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[18]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[18]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"checkbox-inline custom-control custom-checkbox\" style=\"top:137px;left:560px;\">\n" +
    "                    <label>\n" +
    "                      <input type=\"checkbox\" name=\"tooths[17]\" ng-model=\"$ctrl.referral.referred_for.tooth_numbers[17]\">\n" +
    "                      <span class=\"custom-control-indicator\"></span>\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </fieldset>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"panel-heading\" style=\"margin-left:-15px; margin-right:-15px\">\n" +
    "                <h4 class=\"panel-title text-center\">Remarks or Special Instructions</h4>\n" +
    "              </div>\n" +
    "              <div class=\"panel-body p-x-0\">\n" +
    "                <fieldset class=\"as-read-only\" ng-disabled=\"$ctrl.referral.created_by.content_type === 'referrals.referraluser'\">\n" +
    "                  <textarea class=\"form-control\" rows=\"8\" name=\"description\"\n" +
    "                    ng-model=\"$ctrl.referral.referred_for.description\">\n" +
    "                  </textarea>\n" +
    "                </fieldset>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\" ng-if=\"$ctrl.referral.images.length\">\n" +
    "              <div class=\"panel-heading\" style=\"margin-left:-15px; margin-right:-15px\">\n" +
    "                <h4 class=\"panel-title text-center\">Attached Images</h4>\n" +
    "              </div>\n" +
    "              <div class=\"panel-body p-x-0 p-b-0\">\n" +
    "                <div class=\"row\">\n" +
    "                  <div class=\"col-xs-12 col-sm-4 col-md-3 m-b\" ng-repeat=\"patient_image in $ctrl.referral.images\">\n" +
    "                    <div class=\"thumbnail thumbnail-patient-image\">\n" +
    "                      <a href ng-click=\"$ctrl.onViewImage(patient_image)\">\n" +
    "                        <div class=\"image\" archive-thumb-preview url=\"patient_image.archive_thumb\">\n" +
    "                          <div class=\"btn-group\">\n" +
    "                            <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" stop-click-propagation ng-click=\"$ctrl.onViewImage(patient_image)\">\n" +
    "                              <i class=\"icomoon icon-icomoon-eye2\"></i>\n" +
    "                            </div>\n" +
    "                            <div class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" stop-click-propagation ng-click=\"$ctrl.onDownloadImage(patient_image)\">\n" +
    "                              <i class=\"icomoon icon-icomoon-download\"></i>\n" +
    "                            </div>\n" +
    "                          </div>\n" +
    "                        </div>\n" +
    "                      </a>\n" +
    "                      <div class=\"caption text-center\" style=\"height:37px\">\n" +
    "                        <b class=\"dis-b wide-nowrap\">{{ patient_image.name }}</b>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "              <hr class=\"m-t-0\">\n" +
    "              <div class=\"pull-right\">\n" +
    "                <a class=\"btn btn-default p-x-md\" ui-sref=\"^\" ui-sref-opts=\"{reload: true}\">Back</a>\n" +
    "                <button type=\"button\" class=\"btn btn-danger p-x-md\" ng-click=\"$ctrl.destroy($ctrl.referral)\" ng-if=\"$ctrl.CanDeleteReferral && $ctrl.referral.reports.length === 0\">Delete Referral</button>\n" +
    "                <button class=\"btn btn-primary p-x-md\" ng-click=\"$ctrl.save($ctrl.referral)\" ng-disabled=\"rform.$invalid\" ng-if=\"$ctrl.CanSaveReferral\">Save</button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </form>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#patient-change-unlock\">\n" +
    "  <div class=\"modal-body text-center\">\n" +
    "    <div class=\"alert alert-block alert-warning m-b-0\">\n" +
    "      <h4 class=\"alert-heading\">Warning!</h4>\n" +
    "      <p>If you change the patient, all referral reports for this referral will be deleted.</p>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer p-t-0 b-t-0\">\n" +
    "    <button class=\"btn btn-wide default\" ng-click=\"$dismiss()\">Cancel</button>\n" +
    "    <button class=\"btn btn-wide blue\" ng-click=\"$close()\">Continue</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#referral-image-view\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">{{ $ctrl.image.name }}</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body text-center\">\n" +
    "    <img class=\"img-thumbnail\" ng-src=\"{{ $ctrl.image.archive_thumb }}\">\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer p-t-0 b-t-0\">\n" +
    "    <button class=\"btn btn-success btn-wide pull-left\" ng-click=\"$ctrl.onDownloadImage()\">Download</button>\n" +
    "    <button class=\"btn btn-primary btn-wide\" ng-click=\"$close()\">Close</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#referral-destroy\">\n" +
    "  <div class=\"modal-body text-center\">\n" +
    "    <div class=\"alert alert-block alert-danger m-b-0\">\n" +
    "      <h4 class=\"alert-heading\">Danger!</h4>\n" +
    "      <p>Deleting this Referral will remove all related Reports.</p>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer p-t-0 b-t-0\">\n" +
    "    <button class=\"btn btn-default p-x-md\" ng-click=\"$dismiss()\">Cancel</button>\n" +
    "    <button class=\"btn btn-primary p-x-md\" ng-click=\"$close()\">Continue</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/referrals/components/referrals.html","<ui-view>\n" +
    "  <div class=\"portlet light porlet-fit\">\n" +
    "    <div class=\"portlet-title\">\n" +
    "      <div class=\"caption cursor-pointer\" ng-click=\"$ctrl.$onInit()\">\n" +
    "        <i class=\"icomoon icon-icomoon-users2 font-red-sunglo\"></i>\n" +
    "        <span class=\"caption-subject font-red-sunglo bold uppercase\">{{ $ctrl.meta.role }}</span>\n" +
    "      </div>\n" +
    "      <div class=\"actions\">\n" +
    "        <div class=\"checkbox-inline custom-control custom-checkbox p-l-0\">\n" +
    "          <label style=\"margin-right:4px;\">Limit to</label>\n" +
    "        </div>\n" +
    "        <div class=\"checkbox-inline custom-control custom-checkbox m-r-0 m-l-0\">\n" +
    "          <label>\n" +
    "            <input type=\"checkbox\" ng-model=\"$ctrl.uncompleted\" ng-change=\"$ctrl.loadReferrals()\">\n" +
    "            <span class=\"custom-control-indicator\"></span>\n" +
    "            <strong>uncompleted</strong>\n" +
    "            and\n" +
    "          </label>\n" +
    "        </div>\n" +
    "        <div class=\"btn-group\" style=\"margin-bottom:10px; margin-right:10px; margin-top:10px;\">\n" +
    "          <span class=\"btn btn-default m-r-0\" ng-model=\"$ctrl.period\" ng-change=\"$ctrl.loadReferrals()\" uib-btn-radio=\"[1,'day']\">day</span>\n" +
    "          <span class=\"btn btn-default m-r-0\" ng-model=\"$ctrl.period\" ng-change=\"$ctrl.loadReferrals()\" uib-btn-radio=\"[1,'week']\">week</span>\n" +
    "          <span class=\"btn btn-default m-r-0\" ng-model=\"$ctrl.period\" ng-change=\"$ctrl.loadReferrals()\" uib-btn-radio=\"[1,'month']\">month</span>\n" +
    "          <span class=\"btn btn-default m-r-0\" ng-model=\"$ctrl.period\" ng-change=\"$ctrl.loadReferrals()\" uib-btn-radio=\"[3,'month']\">3 mo.</span>\n" +
    "          <span class=\"btn btn-default m-r-0\" ng-model=\"$ctrl.period\" ng-change=\"$ctrl.loadReferrals()\" uib-btn-radio=\"[6,'month']\">6 mo.</span>\n" +
    "          <span class=\"btn btn-default m-r-0\" ng-model=\"$ctrl.period\" ng-change=\"$ctrl.loadReferrals()\" uib-btn-radio=\"null\">all</span>\n" +
    "        </div>\n" +
    "        <button type=\"button\" class=\"btn btn-primary p-x\" ng-if=\"$ctrl.CanCreateReferral\" ui-sref=\"{{ $ctrl.meta.ns }}.{{ $ctrl.meta.role }}.detail({id:'new'})\">Create</button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"portlet-body pos-r\">\n" +
    "      <circle-spinner class=\"global-loader\" ng-show=\"$ctrl.loading\"></circle-spinner>\n" +
    "      <input type=\"text\" class=\"form-control m-b\" placeholder=\"Search\" ng-model=\"$ctrl.searchTerm\">\n" +
    "      <div class=\"table-responsive\">\n" +
    "        <table class=\"table table-striped table-bordered table-advance\">\n" +
    "          <thead>\n" +
    "            <tr>\n" +
    "              <th class=\"col-narrow\" ng-click=\"$ctrl.orderByClick('date')\" style=\"cursor:pointer ; min-width:105px\">\n" +
    "                Date\n" +
    "                <span class=\"pull-right\" ng-show=\"$ctrl.orderByProp == 'date'\">\n" +
    "                  <i ng-hide=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                  <i ng-show=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "                </span>\n" +
    "              </th>\n" +
    "              <th class=\"col-narrow\" ng-click=\"$ctrl.orderByClick('ext_practice')\" style=\"cursor:pointer\">\n" +
    "                <span class=\"pull-right\" ng-show=\"$ctrl.orderByProp == 'ext_practice'\">\n" +
    "                  <i ng-hide=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                  <i ng-show=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "                </span>\n" +
    "                Referring Practice\n" +
    "              </th>\n" +
    "              <th class=\"col-narrow\" ng-click=\"$ctrl.orderByClick('ext_user')\" style=\"cursor:pointer\">\n" +
    "                <span class=\"pull-right\" ng-show=\"$ctrl.orderByProp == 'ext_user'\">\n" +
    "                  <i ng-hide=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                  <i ng-show=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "                </span>\n" +
    "                Referrer\n" +
    "              </th>\n" +
    "              <th class=\"col-narrow\" ng-click=\"$ctrl.orderByClick('patient_name')\" ng-if=\"$ctrl.meta.ns !== 'patient-referrals'\" style=\"cursor:pointer\">\n" +
    "                <span class=\"pull-right\" ng-show=\"$ctrl.orderByProp == 'patient_name'\">\n" +
    "                  <i ng-hide=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-down2\"></i>\n" +
    "                  <i ng-show=\"$ctrl.orderByReverse\" class=\"icomoon icon-icomoon-arrow-up2\"></i>\n" +
    "                </span>\n" +
    "                Patient Name\n" +
    "              </th>\n" +
    "              <th>Description</th>\n" +
    "              <th class=\"col-narrow\" style=\"min-width:81px\"></th>\n" +
    "            </tr>\n" +
    "          </thead>\n" +
    "          <tbody>\n" +
    "            <tr ng-repeat=\"r in $ctrl.referrals | propsFilter: {filter:$ctrl.searchTerm, props:['date|date|MM/dd/yyyy', 'ext_practice', 'ext_practice_phone', 'ext_user', 'ext_user_phone', 'patient_name', 'description']} | orderBy: $ctrl.orderByProp:$ctrl.orderByReverse\" ng-class=\"{'text-info':r.is_completed}\">\n" +
    "              <td class=\"highlight\">\n" +
    "                <div ng-class=\"r.is_completed ? 'info': 'danger'\"></div>\n" +
    "                <div class=\"m-l\">\n" +
    "                  <span ng-bind-html=\"r.date | date:'MM/dd/yyyy' | highlight: $ctrl.searchTerm\"></span>\n" +
    "                </div>\n" +
    "              </td>\n" +
    "              <td class=\"narrow\">\n" +
    "                <table>\n" +
    "                  <tr>\n" +
    "                    <td class=\"w-full wide-in-one-line referrals-col-ellipsis\" uib-tooltip=\"{{ r.ext_practice }}\" tooltip-append-to-body=\"true\">\n" +
    "                      <a ui-sref=\"settings-referrals.practice-detail({id:r.ext_practice_id})\" target=\"_blank\" ng-bind-html=\"r.ext_practice | highlight: $ctrl.searchTerm\"></a>\n" +
    "                    </td>\n" +
    "                    <td class=\"p-l\">\n" +
    "                      <small class=\"text-muted\" ng-bind-html=\"r.ext_practice_phone | tel | highlight: $ctrl.searchTerm\"></small>\n" +
    "                    </td>\n" +
    "                  </tr>\n" +
    "                </table>\n" +
    "              </td>\n" +
    "              <td class=\"narrow\">\n" +
    "                <table>\n" +
    "                  <tr>\n" +
    "                    <td class=\"w-full wide-in-one-line referrals-col-ellipsis\" uib-tooltip=\"{{ r.ext_user }}\" tooltip-append-to-body=\"true\">\n" +
    "                      <a ui-sref=\"settings-referrals.user-detail({id:r.ext_user_id})\" target=\"_blank\" ng-bind-html=\"r.ext_user | highlight: $ctrl.searchTerm\"></a>\n" +
    "                    </td>\n" +
    "                    <td class=\"p-l\">\n" +
    "                      <small class=\"text-muted\" ng-bind-html=\"r.ext_user_phone | tel | highlight: $ctrl.searchTerm\"></small>\n" +
    "                    </td>\n" +
    "                  </tr>\n" +
    "                </table>\n" +
    "              </td>\n" +
    "              <td ng-if=\"$ctrl.meta.ns !== 'patient-referrals'\" class=\"narrow\">\n" +
    "                <a ui-sref=\"profile({patientid: r.patient_id})\" target=\"_blank\" ng-bind-html=\"r.patient_name | highlight: $ctrl.searchTerm\"></a>\n" +
    "              </td>\n" +
    "              <td class=\"wide-in-one-line\">\n" +
    "                <small uib-tooltip=\"{{ r.description }}\" tooltip-append-to-body=\"true\" ng-bind-html=\"r.description | highlight: $ctrl.searchTerm\"></small>\n" +
    "              </td>\n" +
    "              <td class=\"btn-container\">\n" +
    "                <div class=\"btn-group\">\n" +
    "                  <a class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "                    ui-sref=\"{{ $ctrl.meta.ns }}.{{ $ctrl.meta.role }}.detail({id:r.id})\">\n" +
    "                    <span class=\"icomoon\" ng-class=\"$ctrl.CanUpdateReferral ? 'icon-icomoon-pencil5' : 'icon-icomoon-eye2'\"></span>\n" +
    "                  </a>\n" +
    "                  <a class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\"\n" +
    "                    ng-if=\"$ctrl.CanListReport && r.reports_count > 0\"\n" +
    "                    ui-sref=\"{{ $ctrl.meta.ns }}.{{ $ctrl.meta.role }}.detail.reports({id:r.id})\">\n" +
    "                    <span class=\"icomoon icon-icomoon-file-spreadsheet2\"></span>\n" +
    "                  </a>\n" +
    "                </div>\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "            <tr ng-if=\"$ctrl.referrals && !$ctrl.referrals.length\">\n" +
    "              <td colspan=\"6\">\n" +
    "                <div class=\"well well-sm text-center m-b-0\">\n" +
    "                  No referrals\n" +
    "                </div>\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "          </tbody>\n" +
    "        </table>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</ui-view>\n" +
    "")

$templateCache.put("src/referrals/components/report-pdf.html","\n" +
    "<div class=\"portlet light porlet-fit\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption cursor-pointer\" ng-click=\"$ctrl.$onInit()\">\n" +
    "      <i class=\"icomoon icon-icomoon-bubbles9 font-red-sunglo\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold\">\n" +
    "        <span>\n" +
    "          Referral Report\n" +
    "          <span ng-if=\"$ctrl.report.snapshot.referral.ext_practice\">\n" +
    "            for\n" +
    "            {{ $ctrl.report.snapshot.referral.ext_practice.name }} -\n" +
    "          </span>\n" +
    "          <span ng-if=\"$ctrl.report.snapshot.referral.ext_user\">\n" +
    "            {{ $ctrl.report.snapshot.referral.ext_user.first_name }}\n" +
    "            {{ $ctrl.report.snapshot.referral.ext_user.last_name }}\n" +
    "          </span>\n" +
    "          <span ng-if=\"$ctrl.report.snapshot.referral.patient\">\n" +
    "            for patient\n" +
    "            {{ $ctrl.report.snapshot.referral.patient.first_name }}\n" +
    "            {{ $ctrl.report.snapshot.referral.patient.last_name }}\n" +
    "          </span>\n" +
    "        </span>\n" +
    "      </span>\n" +
    "    </div>\n" +
    "    <div class=\"actions\">\n" +
    "      <a class=\"btn btn-default p-x-md\" ui-sref=\"^\" ui-sref-opts=\"{reload: true}\">Back</a>\n" +
    "      <button type=\"button\" class=\"btn btn-primary p-x-md\" ng-click=\"$ctrl.pdf.download()\" ng-disabled=\"!$ctrl.pdf\">Download</button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body pos-r\">\n" +
    "    <wave-spinner class=\"m-y dis-b\" ng-show=\"!$ctrl.url\"></wave-spinner>\n" +
    "    <object ng-show=\"$ctrl.url\" ng-attr-data=\"{{ $ctrl.url }}\" class=\"pdf-viewer\" type=\"application/pdf\">\n" +
    "      <div class=\"alert alert-danger text-center m-b-0\">\n" +
    "        This browser does not support PDF. Use the Download button to view it.\n" +
    "      </div>\n" +
    "    </object>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/referrals/components/report.html","<div class=\"portlet light porlet-fit\" ng-form=\"rform\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption\">\n" +
    "      <i class=\"icomoon icon-icomoon-bubbles9 font-red-sunglo\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold\">\n" +
    "        <span ng-if=\"$ctrl.meta.role === 'received'\">\n" +
    "          <span ng-if=\"$ctrl.report.id\">Edit</span>\n" +
    "          <span ng-if=\"!$ctrl.report.id\">Create</span>\n" +
    "          Referral Report\n" +
    "          <span ng-if=\"$ctrl.report.snapshot.referral.referral_practice || $ctrl.report.snapshot.referral.ext_practice\">\n" +
    "            for\n" +
    "            {{ $ctrl.report.snapshot.referral.referral_practice.name || $ctrl.report.snapshot.referral.ext_practice.name }} -\n" +
    "          </span>\n" +
    "          <span ng-if=\"$ctrl.report.snapshot.referral.ext_user\">\n" +
    "            {{ $ctrl.report.snapshot.referral.ext_user.first_name }}\n" +
    "            {{ $ctrl.report.snapshot.referral.ext_user.last_name }}\n" +
    "          </span>\n" +
    "          <span ng-if=\"$ctrl.report.snapshot.referral.patient\">\n" +
    "            for patient\n" +
    "            {{ $ctrl.report.snapshot.referral.patient.first_name }}\n" +
    "            {{ $ctrl.report.snapshot.referral.patient.last_name }}\n" +
    "          </span>\n" +
    "        </span>\n" +
    "      </span>\n" +
    "    </div>\n" +
    "    <div class=\"actions btns-xs-block\">\n" +
    "      <a class=\"btn btn-default p-x-md btn-xs-block\" ui-sref=\"^\" ui-sref-opts=\"{reload: true}\" ng-if=\"rform.$pristine\">\n" +
    "        Back\n" +
    "      </a>\n" +
    "      <button type=\"button\" class=\"btn btn-default p-x-md btn-xs-block\" ng-click=\"$ctrl.onBack()\" ng-if=\"rform.$dirty\">\n" +
    "        Back\n" +
    "      </button>\n" +
    "      <button type=\"button\" class=\"btn btn-primary p-x-md btn-xs-block\"\n" +
    "        ng-click=\"$ctrl.onSaveAndSendEmailToReferrer()\"\n" +
    "        ng-disabled=\"rform.$invalid\"\n" +
    "        ng-if=\"$ctrl.CanChangeReport\">\n" +
    "        <span ng-if=\"$ctrl.report.sent_at\">Publish Report to Referrer</span>\n" +
    "        <span ng-if=\"!$ctrl.report.sent_at\">Save and Publish Report to Referrer</span>\n" +
    "      </button>\n" +
    "      <button type=\"button\" class=\"btn btn-primary p-x-md btn-xs-block\"\n" +
    "        ui-sref=\".pdf\"\n" +
    "        ng-disabled=\"rform.$invalid\"\n" +
    "        ng-show=\"$ctrl.report.id\">\n" +
    "        Get PDF\n" +
    "      </button>\n" +
    "      <button type=\"button\" class=\"btn btn-primary p-x-md btn-xs-block\" ng-click=\"$ctrl.onSave()\" ng-disabled=\"rform.$invalid || $ctrl.report.sent_at\" ng-if=\"$ctrl.CanChangeReport\">Save</button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body pos-r\">\n" +
    "    <div class=\"tabbable-custom\">\n" +
    "      <ul class=\"nav nav-tabs\">\n" +
    "        <li><a class=\"p-x-md\" ui-sref=\"{{ $ctrl.meta.ns }}.{{ $ctrl.meta.role }}.detail\" ui-sref-opts=\"{reload: true}\">General</a></li>\n" +
    "        <li class=\"active\">\n" +
    "          <a class=\"p-x-md\">Reports</a>\n" +
    "        </li>\n" +
    "      </ul>\n" +
    "      <div class=\"tab-content\">\n" +
    "        <div class=\"tab-pane pos-r active\" ng-switch=\"$ctrl.CanChangeReport && !$ctrl.report.sent_at\">\n" +
    "          <circle-spinner class=\"global-loader\" ng-show=\"$ctrl.loading\"></circle-spinner>\n" +
    "          <div ng-switch-when=\"true\">\n" +
    "            <div class=\"row m-b\">\n" +
    "              <div class=\"col-sm-8 form-horizontal\">\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"col-sm-3 control-label\">First Name</label>\n" +
    "                  <div class=\"col-sm-9\">\n" +
    "                    <!--\n" +
    "                    <input ng-if=\"!$ctrl.report.sent_at\" class=\"form-control\" ng-model=\"$ctrl.report.snapshot.referral.patient.first_name\" type=\"text\" name=\"patient-first-name\" required>\n" +
    "                    <p ng-if=\"$ctrl.report.sent_at\" class=\"form-control m-b-0\">{{ $ctrl.report.snapshot.referral.patient.first_name }}</p>\n" +
    "                    -->\n" +
    "                    <p class=\"form-control m-b-0\">{{ $ctrl.report.snapshot.referral.patient.first_name }}</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"col-sm-3 control-label\">Last Name</label>\n" +
    "                  <div class=\"col-sm-9\">\n" +
    "                    <!--\n" +
    "                    <input ng-if=\"!$ctrl.report.sent_at\" class=\"form-control\" ng-model=\"$ctrl.report.snapshot.referral.patient.last_name\" type=\"text\" name=\"patient-last-name\" required>\n" +
    "                    <p ng-if=\"$ctrl.report.sent_at\" class=\"form-control m-b-0\">{{ $ctrl.report.snapshot.referral.patient.last_name }}</p>\n" +
    "                    -->\n" +
    "                    <p class=\"form-control m-b-0\">{{ $ctrl.report.snapshot.referral.patient.last_name }}</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"col-sm-3 control-label\">Date of Birth</label>\n" +
    "                  <div class=\"col-sm-9\">\n" +
    "                    <!--\n" +
    "                    <custom-datepicker-popup\n" +
    "                      ng-if=\"!$ctrl.report.sent_at\"\n" +
    "                      ng-model=\"$ctrl.report.snapshot.referral.patient.birth_date\"\n" +
    "                      input-name=\"'patient-birth-date'\"\n" +
    "                      form-name=\"'myOuterForm'\"\n" +
    "                      format=\"'MM/dd/yyyy'\"\n" +
    "                      show-placeholder=false\n" +
    "                      ng-required=\"true\">\n" +
    "                    </custom-datepicker-popup>\n" +
    "                    <p ng-if=\"$ctrl.report.sent_at\" class=\"form-control m-b-0\">{{ $ctrl.report.snapshot.referral.patient.birth_date | date:\"MM/dd/yyyy\" }}</p>\n" +
    "                    -->\n" +
    "                    <p class=\"form-control m-b-0\">{{ $ctrl.report.snapshot.referral.patient.birth_date | date:\"MM/dd/yyyy\" }}</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"col-sm-3 control-label\">Home Phone</label>\n" +
    "                  <div class=\"col-sm-9\">\n" +
    "                    <!--\n" +
    "                    <phone-input ng-if=\"!$ctrl.report.sent_at\" ng-model=\"$ctrl.report.snapshot.referral.patient.home_phone\" name=\"patient_home_phone\"></phone-input>\n" +
    "                    <p ng-if=\"$ctrl.report.sent_at\" class=\"form-control m-b-0\">{{ $ctrl.report.snapshot.referral.patient.home_phone | tel }}</p>\n" +
    "                    -->\n" +
    "                    <p class=\"form-control m-b-0\">{{ $ctrl.report.snapshot.referral.patient.home_phone | tel }}</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"col-sm-3 control-label\">Mobile Phone</label>\n" +
    "                  <div class=\"col-sm-9\">\n" +
    "                    <!--\n" +
    "                    <phone-input ng-if=\"!$ctrl.report.sent_at\" ng-model=\"$ctrl.report.snapshot.referral.patient.mobile_phone\" name=\"patient_mobile_phone\"></phone-input>\n" +
    "                    <p ng-if=\"$ctrl.report.sent_at\" class=\"form-control m-b-0\">{{ $ctrl.report.snapshot.referral.patient.mobile_phone | tel }}</p>\n" +
    "                    -->\n" +
    "                    <p class=\"form-control m-b-0\">{{ $ctrl.report.snapshot.referral.patient.mobile_phone | tel }}</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-4 form-horizontal\">\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"col-sm-7 control-label\">Published to Referrer</label>\n" +
    "                  <div class=\"col-sm-5\">\n" +
    "                    <p class=\"form-control-static text-info\">\n" +
    "                      <b class=\"label p-x\" ng-class=\"$ctrl.report.sent_at ? 'label-success' : 'label-danger'\">\n" +
    "                        {{ $ctrl.report.sent_at ? \"Yes\" : \"No\" }}\n" +
    "                      </b>\n" +
    "                    </p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\" ng-if=\"$ctrl.report.updated_at\">\n" +
    "                  <label class=\"col-sm-7 control-label\">Last Update</label>\n" +
    "                  <div class=\"col-sm-5\">\n" +
    "                    <p class=\"form-control-static\">{{ $ctrl.report.updated_at | date:\"MM/dd/yyyy\" }}</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"col-sm-7 control-label\">Read at</label>\n" +
    "                  <div class=\"col-sm-5\">\n" +
    "                    <p class=\"form-control-static\">\n" +
    "                      <b class=\"label label-danger p-x\" ng-hide=\"$ctrl.report.read_at\">Not read</b>\n" +
    "                      <span ng-show=\"$ctrl.report.read_at\">{{ $ctrl.report.read_at | date:\"MM/dd/yyyy hh:mm:ss a\" }}</span>\n" +
    "                    </p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"col-sm-12\">\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label>External User Notes</label>\n" +
    "                    <p class=\"form-control preserve-wrap\" style=\"overflow: auto;\" ng-style=\"{'height': $ctrl.report.updated_at ? '58px' : '107px'}\">{{ $ctrl.ext_user.description }}</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <hr>\n" +
    "            <div class=\"form-horizontal\">\n" +
    "              <div class=\"form-group\">\n" +
    "                <label class=\"col-sm-2 control-label\">Template</label>\n" +
    "                <div class=\"col-sm-10\">\n" +
    "                  <div class=\"input-group\">\n" +
    "                    <ui-select ng-model=\"$ctrl.template\" theme=\"selectize\" on-select=\"$ctrl.onTemplateSelect($model)\">\n" +
    "                      <ui-select-match>\n" +
    "                        {{ $select.selected.name }}\n" +
    "                        ({{ $select.selected.id }})\n" +
    "                        -\n" +
    "                        <small class=\"text-muted\">{{ $select.selected.provider_first_name }} {{ $select.selected.provider_last_name }}</small>\n" +
    "                        <button type=\"button\" class=\"btn btn-link btn-icomoon-pixel-perfect-1x btn-ui-select-clear\" ng-click=\"$select.clear($event) ; $select.search=null\">\n" +
    "                          <i class=\"icomoon icon-icomoon-cross3\"></i>\n" +
    "                        </button>\n" +
    "                      </ui-select-match>\n" +
    "                      <ui-select-choices repeat=\"opt in $ctrl.templates | filter:$select.search\">\n" +
    "                        {{ opt.name }}\n" +
    "                        ({{ opt.id }})\n" +
    "                        -\n" +
    "                        <small class=\"text-muted\">{{ opt.provider_first_name }} {{ opt.provider_last_name }}</small>\n" +
    "                      </ui-select-choices>\n" +
    "                    </ui-select>\n" +
    "                    <div class=\"input-group-btn\">\n" +
    "                      <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x p-x-xs2\" ng-disabled=\"!$ctrl.template || $ctrl.disableTemplateEditDelete\" ng-click=\"$ctrl.onTemplateDestroy($ctrl.template.id)\">\n" +
    "                        <i class=\"icomoon icon-icomoon-bin\"></i>\n" +
    "                      </button>\n" +
    "                      <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x p-x-xs2\" ng-disabled=\"!$ctrl.template || $ctrl.disableTemplateEditDelete\" ng-click=\"$ctrl.onTemplateUpdate($ctrl.template, $ctrl.report)\">\n" +
    "                        <i class=\"icomoon icon-icomoon-floppy-disk\"></i>\n" +
    "                      </button>\n" +
    "                      <button type=\"button\" class=\"btn btn-default btn-icomoon-pixel-perfect-1x p-x-xs2\" ng-click=\"$ctrl.onTemplateCreate($ctrl.report)\">\n" +
    "                        <i class=\"icomoon icon-icomoon-file-plus2\"></i>\n" +
    "                      </button>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"form-group\" ng-class=\"{'has-error': rform.summary.$invalid}\">\n" +
    "                <label class=\"col-sm-2 control-label text-nowrap\">Regarding</label>\n" +
    "                <div class=\"col-sm-10\">\n" +
    "                  <input class=\"form-control\" ng-model=\"$ctrl.report.summary\" type=\"text\" name=\"summary\">\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"form-group\" ng-class=\"{'has-error': rform.description.$invalid}\">\n" +
    "                <label class=\"col-sm-2 control-label text-nowrap\">(Body)</label>\n" +
    "                <div class=\"col-sm-10\">\n" +
    "                  <textarea class=\"form-control\" ng-model=\"$ctrl.report.description\" rows=\"6\" name=\"description\"></textarea>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"form-group\">\n" +
    "                <label class=\"col-sm-2 control-label text-nowrap\">(Closing)</label>\n" +
    "                <div class=\"col-sm-10\">\n" +
    "                  <textarea class=\"form-control\" ng-model=\"$ctrl.report.close_message\" rows=\"6\" name=\"close_message\"></textarea>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"form-group\">\n" +
    "                <label class=\"col-sm-2 control-label\">Signing Provider</label>\n" +
    "                <div class=\"col-sm-10\">\n" +
    "                  <ui-select ng-model=\"$ctrl.report.signing_provider\" theme=\"selectize\" name=\"provider\">\n" +
    "                    <ui-select-match>\n" +
    "                      {{ $select.selected.first_name }} {{ $select.selected.last_name }}\n" +
    "                      <button type=\"button\" class=\"btn btn-link btn-icomoon-pixel-perfect-1x btn-ui-select-clear\" ng-click=\"$select.clear($event) ; $select.search=null\">\n" +
    "                        <i class=\"icomoon icon-icomoon-cross3\"></i>\n" +
    "                      </button>\n" +
    "                    </ui-select-match>\n" +
    "                    <ui-select-choices repeat=\"{id:provider.id} as provider in $ctrl.providers | filter:{is_active: true} | filter:$select.search\">\n" +
    "                      <span ng-bind-html=\"(provider.first_name + ' ' + provider.last_name) | highlight: $select.search\"></span>\n" +
    "                    </ui-select-choices>\n" +
    "                  </ui-select>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <uib-tabset class=\"tabbable-custom m-t-md\">\n" +
    "              <uib-tab class=\"tab-wide-170\" heading=\"Included Treatments\" active=\"$ctrl.activeTabs[0]\">\n" +
    "                <div class=\"text-success text-center m-b\">Drag and drop to re-order treatments.</div>\n" +
    "                <div class=\"table-responsive\">\n" +
    "                  <table class=\"table table-striped table-bordered table-advance m-b-0\">\n" +
    "                    <thead>\n" +
    "                      <tr>\n" +
    "                        <th>Date</th>\n" +
    "                        <th>Provider</th>\n" +
    "                        <th>Visit</th>\n" +
    "                        <th>Tooth</th>\n" +
    "                        <th>Code</th>\n" +
    "                        <th>Procedure</th>\n" +
    "                        <th style=\"width:59px;\"></th>\n" +
    "                      </tr>\n" +
    "                    </thead>\n" +
    "                    <tbody tragula=\"'report-t'\" dragula-model=\"$ctrl.report.report_treatments\">\n" +
    "                      <tr ng-repeat=\"rt in (filteredReportTreatments = ($ctrl.report.report_treatments | filter:$ctrl.filterRemoved))\">\n" +
    "                        <td tragula-stick-width update=\"$ctrl.activeTabs[0]\">{{ rt.treatment.treatment_date | date:'MM/dd/yyyy' }}</td>\n" +
    "                        <td tragula-stick-width update=\"$ctrl.activeTabs[0]\">{{ rt.treatment.provider }}</td>\n" +
    "                        <td tragula-stick-width update=\"$ctrl.activeTabs[0]\">{{ rt.treatment.recommendation_level }}</td>\n" +
    "                        <td tragula-stick-width update=\"$ctrl.activeTabs[0]\">{{ rt.treatment.tooth_numbers || '&nbsp;' }}</td>\n" +
    "                        <td tragula-stick-width update=\"$ctrl.activeTabs[0]\">{{ rt.treatment.procedure_code }}</td>\n" +
    "                        <td tragula-stick-width update=\"$ctrl.activeTabs[0]\">{{ rt.treatment.procedure_name }}</td>\n" +
    "                        <td tragula-stick-width update=\"$ctrl.activeTabs[0]\" class=\"grabbable\" style=\"font-size:25px; padding:0 0 0 14px;\"><i class=\"icomoon icon-icomoon-drag-left-right rotate-270deg grabbable\"></i></td>\n" +
    "                      </tr>\n" +
    "                    </tbody>\n" +
    "                    <tfoot>\n" +
    "                      <tr ng-if=\"!filteredReportTreatments.length && $ctrl.treatments\"><td class=\"text-center\" colspan=7>There are no Treatments included in the Report yet. You can select some on the <a href=\"#\" ng-click=\"$ctrl.activeTabs[1]=true\">Change Treatments</a> tab.</td></tr>\n" +
    "                      <tr ng-if=\"!$ctrl.treatments\"><td class=\"text-center\" colspan=7>There are no <b>completed</b> Treatments for this Patient yet.</td></tr>\n" +
    "                    </tfoot>\n" +
    "                  </table>\n" +
    "                </div>\n" +
    "              </uib-tab>\n" +
    "              <uib-tab class=\"tab-wide-170\" heading=\"Change Treatments\" active=\"$ctrl.activeTabs[1]\">\n" +
    "                <div class=\"table-responsive\">\n" +
    "                  <table class=\"table table-striped table-bordered table-advance m-b-0\">\n" +
    "                    <thead>\n" +
    "                      <tr>\n" +
    "                        <th>Date</th>\n" +
    "                        <th>Provider</th>\n" +
    "                        <th>Visit</th>\n" +
    "                        <th>Tooth</th>\n" +
    "                        <th>Code</th>\n" +
    "                        <th>Procedure</th>\n" +
    "                        <th></th>\n" +
    "                      </tr>\n" +
    "                    </thead>\n" +
    "                    <tbody>\n" +
    "                      <tr ng-repeat=\"treatment in $ctrl.treatments track by treatment.id\" ng-class=\"{'selected':treatment.selected}\">\n" +
    "                        <td>{{ treatment.treatment_date | date:'MM/dd/yyyy' }}</td>\n" +
    "                        <td>{{ treatment.provider }}</td>\n" +
    "                        <td>{{ treatment.recommendation_level }}</td>\n" +
    "                        <td>{{ treatment.tooth_numbers || '&nbsp;' }}</td>\n" +
    "                        <td>{{ treatment.procedure_code }}</td>\n" +
    "                        <td>{{ treatment.procedure_name }}</td>\n" +
    "                        <td class=\"td-switch-small-ct\"><switch ng-model=\"treatment.selected\" ng-change=\"$ctrl.onTreatmentChange(rform, treatment)\" class=\"small\"></switch></td>\n" +
    "                      </tr>\n" +
    "                    </tbody>\n" +
    "                    <tfoot>\n" +
    "                      <tr ng-if=\"$ctrl.treatments.length\"><td class=\"text-success text-center\" colspan=\"7\">Treatments will be ordered as they are checked. But you can re-order later on the <a href=\"#\" ng-click=\"$ctrl.activeTabs[0]=true\">Report Treatments</a> tab.</td></tr>\n" +
    "                      <tr ng-if=\"!$ctrl.treatments.length\"><td class=\"text-center\" colspan=7>There are no completed Treatments for this user yet.</td></tr>\n" +
    "                    </tfoot>\n" +
    "                  </table>\n" +
    "                </div>\n" +
    "              </uib-tab>\n" +
    "            </uib-tabset>\n" +
    "            <uib-tabset class=\"tabbable-custom m-t-md\">\n" +
    "              <uib-tab class=\"tab-wide-170\" heading=\"Included Images\" active=\"$ctrl.activeTabs[2]\">\n" +
    "                <div class=\"text-center text-success m-b\">Drag and drop to re-order images</div>\n" +
    "                <div class=\"row\" dragula=\"'report-i'\" dragula-model=\"$ctrl.report.report_images\" style=\"margin-top:10px; margin-bottom:-10px;\">\n" +
    "                  <div ng-repeat=\"ri in $ctrl.report.report_images | filter:$ctrl.filterRemoved\" class=\"col-xs-12 col-sm-3\" style=\"margin-bottom:10px\">\n" +
    "                    <div class=\"thumbnail thumbnail-patient-image m-b-0\" style=\"box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1)\">\n" +
    "                      <wave-spinner class=\"modal-loader\" ng-show=\"ri.$loading\"></wave-spinner>\n" +
    "                      <div class=\"image grabbable\" archive-thumb-preview url=\"ri.patient_image.image_archive_thumb_url\">\n" +
    "                        <div class=\"btn-group\" ng-show=\"ri.id\">\n" +
    "                          <a class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" target=\"_blank\" tabindex=\"-1\" ng-if=\"$ctrl.CanRetrieveEncounter\" ui-sref=\"chart-detail({patientid:$ctrl.report.snapshot.referral.patient.id, encounterid: ri.patient_image.encounter})\"><i class=\"icomoon icon-icomoon-reading\"></i></a>\n" +
    "                          <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" ng-if=\"$ctrl.CanRetrievePatientImage\" ng-click=\"$ctrl.onImageView(ri.patient_image.id, ri.name)\"><i class=\"icomoon icon-icomoon-eye2\"></i></div>\n" +
    "                          <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" ng-if=\"$ctrl.CanRetrievePatientImage\" ng-click=\"$ctrl.onImageDownload(ri, ri.patient_image.id, ri.name)\"><i class=\"icomoon icon-icomoon-download\"></i></div>\n" +
    "                        </div>\n" +
    "                      </div>\n" +
    "                      <div class=\"caption text-center\">\n" +
    "                        <input class=\"form-control\" ng-model=\"ri.name\" type=\"text\">\n" +
    "                        <div>\n" +
    "                          <small class=\"dis-b wide-nowrap\">{{ ri.patient_image.created_at | date:'MM/dd/yyyy hh:mm a' }}</small>\n" +
    "                          <small class=\"dis-b wide-nowrap\">{{ ri.patient_image.created_by }}</small>\n" +
    "                        </div>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </uib-tab>\n" +
    "              <uib-tab class=\"tab-wide-170\" heading=\"Change Images\" active=\"$ctrl.activeTabs[3]\" select=\"$ctrl.loadReportImages()\">\n" +
    "                <div class=\"text-center text-success m-b\">Images will be ordered as they are checked</div>\n" +
    "                <div class=\"row\" style=\"margin-top:10px; margin-bottom:-10px\">\n" +
    "                  <div ng-repeat=\"patient_image in $ctrl.images track by patient_image.id\" class=\"col-xs-12 col-sm-4 col-md-3\" style=\"margin-bottom:10px\">\n" +
    "                    <div class=\"thumbnail thumbnail-patient-image\" ng-class=\"{'selected': patient_image.selected}\" style=\"box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1)\">\n" +
    "                      <wave-spinner class=\"modal-loader\" ng-show=\"patient_image.$loading\"></wave-spinner>\n" +
    "                      <div class=\"image\" archive-thumb-preview url=\"patient_image.image_archive_thumb_url\">\n" +
    "                        <div class=\"btn-group\" ng-show=\"patient_image.id\">\n" +
    "                          <a class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" target=\"_blank\" ng-if=\"$ctrl.CanRetrieveEncounter\" ui-sref=\"chart-detail({patientid:$ctrl.report.snapshot.referral.patient.id, encounterid: patient_image.encounter})\"><i class=\"icomoon icon-icomoon-reading\"></i></a>\n" +
    "                          <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" ng-if=\"$ctrl.CanRetrievePatientImage\" ng-click=\"$ctrl.onImageView(patient_image.id, patient_image.name)\"><i class=\"icomoon icon-icomoon-eye2\"></i></div>\n" +
    "                          <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" ng-if=\"$ctrl.CanRetrievePatientImage\" ng-click=\"$ctrl.onImageDownload(patient_image, patient_image.id, patient_image.name)\"><i class=\"icomoon icon-icomoon-download\"></i></div>\n" +
    "                          <div class=\"image-switch-ct\">\n" +
    "                            <switch ng-model=\"patient_image.selected\" ng-change=\"$ctrl.onImageChange(rform, patient_image)\" class=\"small\"></switch>\n" +
    "                          </div>\n" +
    "                        </div>\n" +
    "                      </div>\n" +
    "                      <div class=\"caption text-center\" ng-style=\"{'color': patient_image.selected ? '#64bd63' : '#666'}\">\n" +
    "                        <b class=\"dis-b wide-nowrap\">{{ patient_image.name }}</b>\n" +
    "                        <div>\n" +
    "                          <small class=\"dis-b wide-nowrap\">{{ patient_image.created_at | date:'MM/dd/yyyy hh:mm a' }}</small>\n" +
    "                          <small class=\"dis-b wide-nowrap\">{{ patient_image.created_by }}</small>\n" +
    "                        </div>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </uib-tab>\n" +
    "            </uib-tabset>\n" +
    "            <uib-tabset class=\"tabbable-custom m-t-md\">\n" +
    "              <uib-tab class=\"tab-wide-170\" heading=\"Included Files\" active=\"$ctrl.activeTabs[4]\">\n" +
    "                <div class=\"text-center text-success m-b\">Drag and drop to re-order files</div>\n" +
    "                <div class=\"row\" dragula=\"'report-f'\" dragula-model=\"$ctrl.report.report_files\" style=\"margin-top:10px; margin-bottom:-10px;\">\n" +
    "                  <div ng-repeat=\"rf in $ctrl.report.report_files | filter:$ctrl.filterRemoved\" class=\"col-xs-12 col-sm-3\" style=\"margin-bottom:10px\">\n" +
    "                    <div class=\"thumbnail thumbnail-patient-archive\" style=\"box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1)\">\n" +
    "                      <wave-spinner class=\"modal-loader\" ng-show=\"rf.$loading\"></wave-spinner>\n" +
    "                      <div class=\"archive\" archive-type-preview file=\"rf.patient_file.$file_archive\" file-metadata=\"rf.patient_file.archive_metadata\">\n" +
    "                        <div class=\"btn-group\" ng-show=\"rf.patient_file.id\">\n" +
    "                          <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" ng-if=\"$ctrl.CanRetrievePatientFile\" ng-click=\"$ctrl.onFileView(rf.patient_file, rf.name)\"><i class=\"icomoon icon-icomoon-eye2\"></i></div>\n" +
    "                          <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" ng-if=\"$ctrl.CanRetrievePatientFile\" ng-click=\"$ctrl.onFileDownload(rf.patient_file, rf.name)\"><i class=\"icomoon icon-icomoon-download\"></i></div>\n" +
    "                        </div>\n" +
    "                        <h6 class=\"size\">{{ rf.patient_file.archive_metadata.size | filesize }}</h6>\n" +
    "                      </div>\n" +
    "                      <div class=\"caption text-center\">\n" +
    "                        <input class=\"form-control\" ng-model=\"rf.name\" type=\"text\">\n" +
    "                        <div>\n" +
    "                          <small class=\"dis-b wide-nowrap\">{{ rf.patient_file.created_at | date:'MM/dd/yyyy hh:mm a' }}</small>\n" +
    "                          <small class=\"dis-b wide-nowrap\">{{ rf.patient_file.created_by }}</small>\n" +
    "                        </div>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </uib-tab>\n" +
    "              <uib-tab class=\"tab-wide-170\" heading=\"Change Files\" active=\"$ctrl.activeTabs[5]\">\n" +
    "                <div class=\"text-center text-success m-b\">Files will be ordered as they are checked</div>\n" +
    "                <div class=\"row\" style=\"margin-top:10px; margin-bottom:-10px\">\n" +
    "                  <div ng-repeat=\"patient_file in $ctrl.files track by patient_file.id\" class=\"col-xs-12 col-sm-4 col-md-3\" style=\"margin-bottom:10px\">\n" +
    "                    <div class=\"thumbnail thumbnail-patient-archive\" ng-class=\"{'selected': patient_file.selected}\">\n" +
    "                      <wave-spinner class=\"modal-loader\" ng-show=\"patient_file.$loading\"></wave-spinner>\n" +
    "                      <div class=\"archive\" archive-type-preview file=\"patient_file.$file_archive\" file-metadata=\"patient_file.archive_metadata\">\n" +
    "                        <div class=\"btn-group\" ng-show=\"patient_file.id\" ng-click=\"$event.preventDefault(); $event.stopPropagation();\">\n" +
    "                          <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" ng-if=\"$ctrl.CanRetrievePatientFile\" ng-click=\"$ctrl.onFileView(patient_file)\"><i class=\"icomoon icon-icomoon-eye2\"></i></div>\n" +
    "                          <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" ng-if=\"$ctrl.CanRetrievePatientFile\" ng-click=\"$ctrl.onFileDownload(patient_file)\"><i class=\"icomoon icon-icomoon-download\"></i></div>\n" +
    "                          <div class=\"image-switch-ct\">\n" +
    "                            <switch ng-model=\"patient_file.selected\" ng-change=\"$ctrl.onFileChange(rform, patient_file)\" class=\"small\"></switch>\n" +
    "                          </div>\n" +
    "                        </div>\n" +
    "                        <h6 class=\"size\">{{ patient_file.archive_metadata.size | filesize }}</h6>\n" +
    "                        <div class=\"progress\" archive-creation-progress oid=\"patient_file.id\" upload-progress=\"patient_file.$uploadProgress_archive\">\n" +
    "                          <div class=\"progress-bar\"></div>\n" +
    "                        </div>\n" +
    "                      </div>\n" +
    "                      <div class=\"caption text-center\" style=\"height:72px\">\n" +
    "                        <b class=\"dis-b wide-nowrap\" uib-tooltip=\"{{ patient_file.name }}\" tooltip-placement=\"top\" tooltip-popup-delay=\"1000\">{{ patient_file.name }}</b>\n" +
    "                        <p class=\"text-muted\" style=\"margin: 5px 0;\" ng-if=\"patient_file.id\">\n" +
    "                          <small class=\"dis-b wide-nowrap\">{{ patient_file.created_at | date:\"medium\" }}</small>\n" +
    "                          <small class=\"dis-b wide-nowrap\">{{ patient_file.created_by }}</small>\n" +
    "                        </p>\n" +
    "                      </div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </uib-tab>\n" +
    "            </uib-tabset>\n" +
    "            <hr class=\"m-t\">\n" +
    "            <div class=\"form-actions clearfix\">\n" +
    "              <div class=\"btn-set pull-right\">\n" +
    "                <a class=\"btn btn-default p-x-md\" ui-sref=\"^\" ui-sref-opts=\"{reload: true}\" ng-if=\"rform.$pristine\">\n" +
    "                  Back\n" +
    "                </a>\n" +
    "                <button type=\"button\" class=\"btn btn-default p-x-md\" ng-click=\"$ctrl.onBack()\" ng-if=\"rform.$dirty\">\n" +
    "                  Back\n" +
    "                </button>\n" +
    "                <button type=\"button\" class=\"btn btn-primary p-x-md\" ng-click=\"$ctrl.onSave()\" ng-disabled=\"rform.$invalid\">Save</button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div ng-switch-when=\"false\">\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"col-sm-6 form-horizontal\">\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"col-sm-4 control-label\">First Name</label>\n" +
    "                  <div class=\"col-sm-8\">\n" +
    "                    <p class=\"form-control m-b-0\">{{ $ctrl.report.snapshot.referral.patient.first_name }}</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"col-sm-4 control-label\">Last Name</label>\n" +
    "                  <div class=\"col-sm-8\">\n" +
    "                    <p class=\"form-control m-b-0\">{{ $ctrl.report.snapshot.referral.patient.last_name }}</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"col-sm-4 control-label\">Date of Birth</label>\n" +
    "                  <div class=\"col-sm-8\">\n" +
    "                    <p class=\"form-control m-b-0\">{{ $ctrl.report.snapshot.referral.patient.birth_date | date:\"MM/dd/yyyy\" }}</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"col-sm-4 control-label\">Mobile Phone</label>\n" +
    "                  <div class=\"col-sm-8\">\n" +
    "                    <p class=\"form-control m-b-0\">{{ $ctrl.report.snapshot.referral.patient.mobile_phone | tel }}</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"col-sm-6 form-horizontal\">\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"col-sm-5 control-label\"><b>Last Update</b></label>\n" +
    "                  <div class=\"col-sm-7\">\n" +
    "                    <p class=\"form-control m-b-0\">{{ $ctrl.report.updated_at | date:\"MM/dd/yyyy\" }}</p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                  <label class=\"col-sm-5 control-label\"><b>Published to Referrer</b></label>\n" +
    "                  <div class=\"col-sm-7\">\n" +
    "                    <p class=\"form-control-static text-info\">\n" +
    "                      <b class=\"label p-x\" ng-class=\"$ctrl.report.sent_at ? 'label-success' : 'label-danger'\">\n" +
    "                        {{ $ctrl.report.sent_at ? \"Yes\" : \"No\" }}\n" +
    "                      </b>\n" +
    "                    </p>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <hr>\n" +
    "            <div class=\"form-horizontal m-t-md\">\n" +
    "              <div class=\"form-group\">\n" +
    "                <label class=\"col-sm-2 control-label text-nowrap\">Regarding</label>\n" +
    "                <div class=\"col-sm-8\">\n" +
    "                  <p class=\"form-control\">{{ $ctrl.report.summary }}</p>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"form-group\">\n" +
    "                <label class=\"col-sm-2 control-label text-nowrap\">(Body)</label>\n" +
    "                <div class=\"col-sm-8\">\n" +
    "                  <p class=\"form-control preserve-wrap\">{{ $ctrl.report.description }}</p>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"form-group\">\n" +
    "                <label class=\"col-sm-2 control-label text-nowrap\">(Closing)</label>\n" +
    "                <div class=\"col-sm-8\">\n" +
    "                  <p class=\"form-control preserve-wrap\">{{ $ctrl.report.close_message }}</p>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "              <div class=\"form-group\">\n" +
    "                <label class=\"col-sm-2 control-label\">Signing Provider</label>\n" +
    "                <div class=\"col-sm-8\">\n" +
    "                  <img ng-attr-src=\"{{ $ctrl.report.signing_provider.signature }}\" alt=\"\">\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"table-responsive\">\n" +
    "              <table class=\"table table-striped table-bordered table-advance m-t-md\">\n" +
    "                <thead>\n" +
    "                  <tr>\n" +
    "                    <th>Date</th>\n" +
    "                    <th>Provider</th>\n" +
    "                    <th>Visit</th>\n" +
    "                    <th>Tooth</th>\n" +
    "                    <th>Code</th>\n" +
    "                    <th>Procedure</th>\n" +
    "                  </tr>\n" +
    "                </thead>\n" +
    "                <tbody tragula=\"'report-t'\" dragula-model=\"$ctrl.report.report_treatments\">\n" +
    "                  <tr ng-repeat=\"rt in (filteredReportTreatments = ($ctrl.report.report_treatments | filter:$ctrl.filterRemoved))\">\n" +
    "                    <td class=\"narrow\" update=\"$ctrl.activeTabs[0]\">{{ rt.treatment.treatment_date | date:'MM/dd/yyyy' }}</td>\n" +
    "                    <td class=\"narrow\" update=\"$ctrl.activeTabs[0]\">{{ rt.treatment.provider }}</td>\n" +
    "                    <td class=\"narrow\" update=\"$ctrl.activeTabs[0]\">{{ rt.treatment.recommendation_level }}</td>\n" +
    "                    <td class=\"narrow\" update=\"$ctrl.activeTabs[0]\">{{ rt.treatment.tooth_numbers || '&nbsp;' }}</td>\n" +
    "                    <td class=\"narrow\" update=\"$ctrl.activeTabs[0]\">{{ rt.treatment.procedure_code }}</td>\n" +
    "                    <td update=\"$ctrl.activeTabs[0]\">{{ rt.treatment.procedure_name }}</td>\n" +
    "                  </tr>\n" +
    "                </tbody>\n" +
    "                <tfoot>\n" +
    "                  <tr ng-if=\"!$ctrl.report.report_treatments\">\n" +
    "                    <td class=\"text-center\" colspan=6>There are no <b>completed</b> Treatments assigned to this Report.</td>\n" +
    "                  </tr>\n" +
    "                </tfoot>\n" +
    "              </table>\n" +
    "            </div>\n" +
    "            <div class=\"row m-t-md\" style=\"margin-top:15px; margin-bottom:-10px;\">\n" +
    "              <div ng-repeat=\"ri in $ctrl.report.report_images\" class=\"col-xs-12 col-sm-3\" style=\"margin-bottom:10px\">\n" +
    "                <div class=\"thumbnail thumbnail-patient-image m-b-0\" style=\"box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1)\">\n" +
    "                  <wave-spinner class=\"modal-loader\" ng-show=\"ri.$loading\"></wave-spinner>\n" +
    "                  <div class=\"image\" archive-thumb-preview url=\"ri.patient_image.image_archive_thumb_url\">\n" +
    "                    <div class=\"btn-group\" ng-show=\"ri.id\">\n" +
    "                      <a class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" target=\"_blank\" tabindex=\"-1\" ng-if=\"$ctrl.CanRetrieveEncounter\" ui-sref=\"chart-detail({patientid:$ctrl.report.snapshot.referral.patient.id, encounterid: ri.patient_image.encounter})\"><i class=\"icomoon icon-icomoon-reading\"></i></a>\n" +
    "                      <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" ng-if=\"$ctrl.CanRetrievePatientImage\" ng-click=\"$ctrl.onImageView(ri.patient_image.id, ri.name)\"><i class=\"icomoon icon-icomoon-eye2\"></i></div>\n" +
    "                      <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" ng-if=\"$ctrl.CanRetrievePatientImage\" ng-click=\"$ctrl.onImageDownload(ri, ri.patient_image.id, ri.name)\"><i class=\"icomoon icon-icomoon-download\"></i></div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"caption text-center\">\n" +
    "                    <b class=\"dis-b wide-nowrap\">{{ ri.name }}</b>\n" +
    "                    <div>\n" +
    "                      <small class=\"dis-b wide-nowrap\">{{ ri.patient_image.created_at | date:'MM/dd/yyyy hh:mm a' }}</small>\n" +
    "                      <small class=\"dis-b wide-nowrap\">{{ ri.patient_image.created_by }}</small>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"row m-t-md\" style=\"margin-top:15px; margin-bottom:-10px;\">\n" +
    "              <div ng-repeat=\"rf in $ctrl.report.report_files | filter:$ctrl.filterRemoved\" class=\"col-xs-12 col-sm-3\" style=\"margin-bottom:10px\">\n" +
    "                <div class=\"thumbnail thumbnail-patient-archive\" style=\"box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1)\">\n" +
    "                  <wave-spinner class=\"modal-loader\" ng-show=\"rf.$loading\"></wave-spinner>\n" +
    "                  <div class=\"archive\" archive-type-preview file=\"rf.patient_file.$file_archive\" file-metadata=\"rf.patient_file.archive_metadata\">\n" +
    "                    <div class=\"btn-group\" ng-show=\"rf.patient_file.id\">\n" +
    "                      <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" ng-if=\"$ctrl.CanRetrievePatientFile\" ng-click=\"$ctrl.onFileView(rf.patient_file, rf.name)\"><i class=\"icomoon icon-icomoon-eye2\"></i></div>\n" +
    "                      <div class=\"btn btn-xs white btn-icomoon-pixel-perfect-1x\" ng-if=\"$ctrl.CanRetrievePatientFile\" ng-click=\"$ctrl.onFileDownload(rf.patient_file, rf.name)\"><i class=\"icomoon icon-icomoon-download\"></i></div>\n" +
    "                    </div>\n" +
    "                    <h6 class=\"size\">{{ rf.patient_file.archive_metadata.size | filesize }}</h6>\n" +
    "                  </div>\n" +
    "                  <div class=\"caption text-center\">\n" +
    "                    <b class=\"dis-b wide-nowrap\">{{ rf.name }}</b>\n" +
    "                    <div>\n" +
    "                      <small class=\"dis-b wide-nowrap\">{{ rf.patient_file.created_at | date:'MM/dd/yyyy hh:mm a' }}</small>\n" +
    "                      <small class=\"dis-b wide-nowrap\">{{ rf.patient_file.created_by }}</small>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <hr class=\"am-t\">\n" +
    "            <div class=\"form-actions clearfix\">\n" +
    "              <div class=\"btn-set pull-right\">\n" +
    "                <a class=\"btn btn-default p-x-md\" ui-sref=\"^\" ui-sref-opts=\"{reload: true}\">Back</a>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <a id=\"patient-image-dwl\" class=\"hide\" href=\"\"></a>\n" +
    "          <a class=\"hide\" ng-attr-id=\"report-archive-{{ $ctrl.uniqId }}-dwl\"></a>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#referrals-detail-report-detail-view\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">{{ $ctrl.name }}</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body text-center\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-show=\"$ctrl.$loading\"></wave-spinner>\n" +
    "    <img ng-src=\"{{ $ctrl.thumb }}\" class=\"img-thumbnail\">\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-success btn-wide pull-left\" ng-click=\"$ctrl.onImageDownload()\">Download</button>\n" +
    "    <button class=\"btn btn-default btn-wide\" ng-click=\"$dismiss()\">Close</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#referrals-detail-report-detail-create-template\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Create Template From Report</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <form name=\"template\">\n" +
    "      <div class=\"form-group\">\n" +
    "        <label>Name</label>\n" +
    "        <input type=\"text\" class=\"form-control\" ng-model=\"$ctrl.template.name\" required>\n" +
    "      </div>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-default btn-wide pull-left\" ng-click=\"$dismiss()\">Cancel</button>\n" +
    "    <button class=\"btn btn-primary btn-wide\"\n" +
    "      ng-disabled=\"template.$invalid\"\n" +
    "      ng-click=\"$ctrl.create()\">\n" +
    "      Create\n" +
    "    </button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#referrals-detail-report-detail-delete-template\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">Are you sure?</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    This will remove the Report Template from your user account\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-default btn-wide pull-left\" ng-click=\"$dismiss()\">Cancel</button>\n" +
    "    <button class=\"btn btn-danger btn-wide\" ng-click=\"$ctrl.delete()\">Delete</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#report-archive-open\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title\">{{ $ctrl.name || $ctrl.obj.name }}</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <wave-spinner class=\"modal-loader\" ng-show=\"$ctrl.loading\"></wave-spinner>\n" +
    "    <embed class=\"w-full img-thumbnail\" ng-attr-src=\"{{ $ctrl.obj.archive | trustAsResourceUrl }}\" ng-attr-type=\"{{ $ctrl.obj.archive_metadata.content_type }}\" ng-if=\"$ctrl.obj.archive_metadata.content_type\" style=\"min-height:480px\"/>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn p-x btn-success pull-left\" ng-click=\"$ctrl.download()\" ng-disabled=\"$ctrl.loading\">Download</button>\n" +
    "    <button class=\"btn p-x-md btn-default\" ng-click=\"$dismiss()\">Close</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#referrals-detail-report-detail-publish\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title text-center\">Are you sure you want to publish this report?</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    After you publish this report you will no longer be able to change it. You will, however, still be able to create\n" +
    "    and publish new reports for this referral until you mark the referral completed.\n" +
    "    <form name=\"f\">\n" +
    "      <fieldset>\n" +
    "        <div class=\"form-group m-b-0 m-t\" ng-if=\"$ctrl.formData.noTreatments != null\">\n" +
    "          <div class=\"alert alert-danger m-b-0 text-center\">\n" +
    "            No treatments have been associated with this report and as a result, treatment revenue will <b>not</b> be\n" +
    "            associated with this referrer.\n" +
    "          </div>\n" +
    "          <div class=\"checkbox custom-control custom-checkbox m-b-0\">\n" +
    "            <label>\n" +
    "              <input type=\"checkbox\" name=\"no-treatments\" required\n" +
    "                ng-model=\"$ctrl.formData.noTreatments\"\n" +
    "                ng-model-options=\"{allowInvalid: true}\">\n" +
    "              <span class=\"custom-control-indicator\"></span>\n" +
    "              Please check to publish anyway\n" +
    "            </label>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </fieldset>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-default btn-wide pull-left\" ng-click=\"$dismiss()\">No</button>\n" +
    "    <button class=\"btn btn-primary btn-wide\" ng-click=\"$close()\" ng-disabled=\"f.$invalid\">Yes</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"#referrals-detail-report-detail-back-navigation\">\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3 class=\"modal-title text-center\">Are you sure you want to continue?</h3>\n" +
    "  </div>\n" +
    "  <div class=\"modal-body\">\n" +
    "    Leaving this report may cause you to lose changes. Are you sure you want to leave?\n" +
    "    <form name=\"f\">\n" +
    "      <fieldset>\n" +
    "        <div class=\"form-group m-b-0 m-t\">\n" +
    "          <div class=\"checkbox custom-control custom-checkbox m-b-0\">\n" +
    "            <label>\n" +
    "              <input type=\"checkbox\" name=\"no-ask-again\" ng-model=\"$ctrl.formData.noAskAgain\">\n" +
    "              <span class=\"custom-control-indicator\"></span>\n" +
    "              Don't ask me this again in this browser.\n" +
    "            </label>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </fieldset>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-default btn-wide pull-left\" ng-click=\"$dismiss()\">No</button>\n" +
    "    <button class=\"btn btn-primary btn-wide\" ng-click=\"$ctrl.close()\">Yes</button>\n" +
    "  </div>\n" +
    "</script>\n" +
    "")

$templateCache.put("src/referrals/components/reports.html","\n" +
    "<div class=\"portlet light porlet-fit\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption cursor-pointer\" ng-click=\"$ctrl.$onInit()\">\n" +
    "      <i class=\"icomoon icon-icomoon-bubbles9 font-red-sunglo\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold\">\n" +
    "        <span ng-if=\"$ctrl.meta.role === 'received'\">\n" +
    "          Referral\n" +
    "          <span ng-if=\"$ctrl.referral.ext_practice\"> from {{ $ctrl.referral.ext_practice.name }} - </span>\n" +
    "          <span ng-if=\"$ctrl.referral.ext_user\">{{ $ctrl.referral.ext_user.first_name }} {{ $ctrl.referral.ext_user.last_name }} </span>\n" +
    "          <span ng-if=\"$ctrl.referral.patient\">for patient {{ $ctrl.referral.patient.first_name }} {{ $ctrl.referral.patient.last_name }} </span>\n" +
    "        </span>\n" +
    "      </span>\n" +
    "    </div>\n" +
    "    <div class=\"actions\">\n" +
    "      <a class=\"btn btn-default p-x-md\" ui-sref=\"^\" ui-sref-opts=\"{reload: true}\">Back</a>\n" +
    "      <a class=\"btn p-x-md btn-primary\" ui-sref=\".detail({reportId:'new'})\" ng-if=\"$ctrl.CanCreateReport\">Create</a>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body pos-r\">\n" +
    "    <div class=\"tabbable-custom\">\n" +
    "      <ul class=\"nav nav-tabs\">\n" +
    "        <li><a class=\"p-x-md\" ui-sref=\"{{ $ctrl.meta.ns }}.{{ $ctrl.meta.role }}.detail\" ui-sref-opts=\"{reload: true}\">General</a></li>\n" +
    "        <li class=\"active\">\n" +
    "          <a ng-click=\"$ctrl.$onInit()\" class=\"p-x-md cursor-pointer\">Reports</a>\n" +
    "        </li>\n" +
    "      </ul>\n" +
    "      <div class=\"tab-content\">\n" +
    "        <div class=\"tab-pane active\">\n" +
    "          <form name=\"rform\" novalidate class=\"pos-r\">\n" +
    "            <circle-spinner class=\"global-loader\" ng-show=\"$ctrl.loading\"></circle-spinner>\n" +
    "            <div class=\"table-responsive\">\n" +
    "              <table class=\"table table-striped table-bordered table-advance\">\n" +
    "                <thead>\n" +
    "                  <tr>\n" +
    "                    <th class=\"narrow\">Published</th>\n" +
    "                    <th class=\"narrow\">Read Date</th>\n" +
    "                    <th class=\"narrow\">Treatments</th>\n" +
    "                    <th class=\"narrow\">Images</th>\n" +
    "                    <th class=\"narrow\">Files</th>\n" +
    "                    <th>Regarding</th>\n" +
    "                    <th class=\"w-xs text-nowrap\"></th>\n" +
    "                    <th style=\"width:30px\"></th>\n" +
    "                  </tr>\n" +
    "                </thead>\n" +
    "                <tbody>\n" +
    "                  <tr ng-repeat=\"r in $ctrl.reports track by r.id\">\n" +
    "                    <td class=\"highlight text-nowrap\">\n" +
    "                      <div ng-class=\"r.sent_at ? 'info': 'danger'\"></div>\n" +
    "                      <div class=\"m-l\">{{ r.sent_at | date:'MM/dd/yyyy' }}</div>\n" +
    "                    </td>\n" +
    "                    <td class=\"text-nowrap\">\n" +
    "                      {{ r.read_at | date:\"MM/dd/yyyy hh:mm:ss a\" }}\n" +
    "                    </td>\n" +
    "                    <td class=\"text-center\">{{ r.report_treatments.length || \"\" }}</td>\n" +
    "                    <td class=\"text-center\">{{ r.report_images.length || \"\" }}</td>\n" +
    "                    <td class=\"text-center\">{{ r.report_files.length || \"\" }}</td>\n" +
    "                    <td>{{ r.summary }}</td>\n" +
    "                    <td class=\"btn-container\">\n" +
    "                      <button type=\"button\" class=\"btn btn-default btn-xs btn-icomoon-pixel-perfect-1x\" ng-click=\"$ctrl.publishToReferrer(r)\" ng-if=\"$ctrl.CanCreateReport\">\n" +
    "                        <span class=\"icomoon icon-icomoon-bullhorn\"></span>\n" +
    "                        <span class=\"text\">Send Report to Referrer</span>\n" +
    "                      </button>\n" +
    "                    </td>\n" +
    "                    <td class=\"btn-container\">\n" +
    "                      <a class=\"btn btn-xs btn-default btn-icomoon-pixel-perfect-1x\" ng-if=\"$ctrl.CanRetrieveReport\" ui-sref=\".detail({reportId:r.id})\">\n" +
    "                        <span class=\"icomoon\" ng-class=\"$ctrl.CanUpdateReport ? 'icon-icomoon-pencil5' : 'icon-icomoon-eye2'\"></span>\n" +
    "                      </a>\n" +
    "                    </td>\n" +
    "                  </tr>\n" +
    "                </tbody>\n" +
    "              </table>\n" +
    "            </div>\n" +
    "          </form>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/kiosk/components/kiosk-users.html","<kiosks-users-page></kiosks-users-page>")

$templateCache.put("src/kiosk/components/kiosks.html","<kiosks-page kiosks-filters=\"kiosksFilters\"></kiosks-page>\n" +
    "\n" +
    "\n" +
    "")

$templateCache.put("src/common/components/browser-unsupported.html","\n" +
    "<div class=\"unsupported-browser\" ng-if=\"!$ctrl.isSupported\">\n" +
    "  <div class=\"page-header\" uib-collapse=\"$ctrl.unsupportedBrowserUnderstood\">\n" +
    "    <h3>Browser not Supported! <small>We support the latest versions of Chrome, Firefox, and Edge.</small></h3>\n" +
    "    <div class=\"checkbox custom-control custom-checkbox\">\n" +
    "      <label>\n" +
    "        <input type=\"checkbox\" ng-model=\"$ctrl.unsupportedBrowserUnderstood\" ng-model-options=\"{debounce:{default:300}}\">\n" +
    "        <span class=\"custom-control-indicator\"></span>\n" +
    "        <small>I understand I am using an unsupported browser and by doing so may be exposing Dental EMR to security vulnerabilities and functionality that may not work as designed.</small>\n" +
    "      </label>\n" +
    "    </div>\n" +
    "    <h5 ng-if=\"$ctrl.family\">We have detected that you are using {{ $ctrl.family }}.</h5>\n" +
    "  </div>\n" +
    "  <div class=\"collapsed-expander\" ng-if=\"$ctrl.unsupportedBrowserUnderstood\" ng-click=\"$ctrl.unsupportedBrowserUnderstood=false;\"></div>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/app/services/modal/feeprofile-destroy.html","<div class=\"modal-header\">\n" +
    "  <h3 class=\"modal-title\">Delete this Fee Profile?</h3>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body\">\n" +
    "  <p class=\"m-t-0\">\n" +
    "    Are you sure you want to delete the fee schedule profile for this company: <b>{{ $ctrl.feeprofile.name }}</b>?\n" +
    "  </p>\n" +
    "  <p class=\"m-b-0\">\n" +
    "    Deleting this fee schedule profile will also delete all procedure fees associated with it.\n" +
    "    <br>\n" +
    "    All patients associated with this fee schedule profile will have to be reassigned to a new fee schedule.\n" +
    "  </p>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "  <button class=\"btn btn-wide default\" ng-click=\"$dismiss()\">Cancel</button>\n" +
    "  <button class=\"btn btn-wide btn-primary\" ng-click=\"$ctrl.submit()\">OK</button>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/app/services/modal/feeprofile-duplicate.html","<div class=\"modal-header\">\n" +
    "  <h3 class=\"modal-title\">Duplicate this Fee Schedule?</h3>\n" +
    "</div>\n" +
    "\n" +
    "<form class=\"form-horizontal\" autocomplete=\"off\" name=\"form\" novalidate>\n" +
    "  <div class=\"modal-body\">\n" +
    "    <div class=\"form-group has-feedback\" ng-class=\"{'has-error': form.name.$invalid}\">\n" +
    "      <label class=\"col-sm-3 control-label\">\n" +
    "        Name\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-9\">\n" +
    "        <input class=\"form-control\"\n" +
    "          autocomplete=\"off\"\n" +
    "          name=\"name\"\n" +
    "          ng-model=\"$ctrl.duplicated.name\"\n" +
    "          required/>\n" +
    "        <span ng-repeat=\"(error, _) in form.name.$error\" ng-switch=\"error\">\n" +
    "          <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "            ng-switch-when=\"required\"\n" +
    "            style=\"font-size: 16px; pointer-events: all;\"\n" +
    "            tooltip-append-to-body=\"true\"\n" +
    "            tooltip-class=\"tooltip-inside-modal\"\n" +
    "            uib-tooltip=\"This field is required\">\n" +
    "          </span>\n" +
    "          <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "            ng-switch-default\n" +
    "            style=\"font-size: 16px; pointer-events: all;\"\n" +
    "            tooltip-append-to-body=\"true\"\n" +
    "            tooltip-class=\"tooltip-inside-modal\"\n" +
    "            uib-tooltip=\"{{ error }}\">\n" +
    "          </span>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"form-group\">\n" +
    "      <label class=\"col-sm-3 control-label\">\n" +
    "        Description\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-9\">\n" +
    "        <textarea class=\"form-control\" style=\"height: 68px\"\n" +
    "          autocomplete=\"off\"\n" +
    "          name=\"description\"\n" +
    "          ng-model=\"$ctrl.duplicated.description\">\n" +
    "        </textarea>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"form-group has-feedback form-group-ui-select\"\n" +
    "      ng-class=\"{'has-error': form.payer.$invalid}\"\n" +
    "      ng-if=\"$ctrl.feeprofile.has_insurance_fees\">\n" +
    "      <label class=\"col-sm-3 control-label\">\n" +
    "        Payer\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-9\">\n" +
    "        <ui-select\n" +
    "          name=\"payer\"\n" +
    "          ng-model=\"$ctrl.duplicated.payer\"\n" +
    "          ng-required=\"true\">\n" +
    "          <ui-select-match placeholder=\"Search by Name or Payer ID\">\n" +
    "            <span class=\"text-overflow dis-ib w-full\">\n" +
    "              <span>{{ $select.selected[1] }}</span>\n" +
    "              <small class=\"text-muted m-l-sm\">{{ $select.selected[2] }}</small>\n" +
    "            </span>\n" +
    "          </ui-select-match>\n" +
    "          <ui-select-choices style=\"min-width: 460px\" repeat=\"\n" +
    "            choice[0] as choice in $ctrl.payers | \n" +
    "            propsFilter: {filter:$select.search, props:['1', '2']} | \n" +
    "            limitTo:250 \n" +
    "            track by choice[0]\">\n" +
    "            <span class=\"text-overflow dis-ib w-full\">\n" +
    "              <span>{{ choice[1] }}</span>\n" +
    "              <small class=\"text-muted m-l-sm\">{{ choice[2] }}</small>\n" +
    "            </span>\n" +
    "          </ui-select-choices>\n" +
    "        </ui-select>\n" +
    "        <span ng-repeat=\"(error, _) in form.payer.$error\" ng-switch=\"error\">\n" +
    "          <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "            ng-switch-when=\"required\"\n" +
    "            style=\"font-size: 16px; pointer-events: all;\"\n" +
    "            tooltip-append-to-body=\"true\"\n" +
    "            tooltip-class=\"tooltip-inside-modal\"\n" +
    "            uib-tooltip=\"This field is required\">\n" +
    "          </span>\n" +
    "          <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "            ng-switch-default\n" +
    "            style=\"font-size: 16px; pointer-events: all;\"\n" +
    "            tooltip-append-to-body=\"true\"\n" +
    "            tooltip-class=\"tooltip-inside-modal\"\n" +
    "            uib-tooltip=\"{{ error }}\">\n" +
    "          </span>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"form-group m-b-0\">\n" +
    "      <label class=\"col-sm-3 control-label\">Archived</label>\n" +
    "      <div class=\"col-sm-9\">\n" +
    "        <div style=\"padding-top: 5px\">\n" +
    "          <switch class=\"small\"\n" +
    "            name=\"is_archived\"\n" +
    "            ng-model=\"$ctrl.duplicated.is_archived\">\n" +
    "          </switch>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-wide default\" ng-click=\"$dismiss()\">Cancel</button>\n" +
    "    <button type=\"button\" class=\"btn btn-wide btn-primary\"\n" +
    "      ng-click=\"$ctrl.submit()\"\n" +
    "      ng-disabled=\"form.$invalid\">\n" +
    "      Submit\n" +
    "    </button>\n" +
    "  </div>\n" +
    "</form>\n" +
    "\n" +
    "<!-- <pre style=\"border: 0; margin: 0;\">{{ $ctrl.feeprofile | json }}</pre> -->\n" +
    "<!-- <pre style=\"border: 0; margin: 0;\">{{ $ctrl.duplicated | json }}</pre> -->\n" +
    "")

$templateCache.put("src/app/services/modal/supercode-destroy.html","<div class=\"modal-header\">\n" +
    "  <h3 class=\"modal-title\">Delete this Supercode?</h3>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-body\">\n" +
    "  <div class=\"well well-sm m-b-0 p-x-0 p-y-0\">\n" +
    "    <div class=\"form-horizontal\">\n" +
    "      <div class=\"form-group m-b-0\">\n" +
    "        <label class=\"col-sm-2 control-label control-label-bold\">Code</label>\n" +
    "        <div class=\"col-sm-10\">\n" +
    "          <p class=\"form-control-static\">\n" +
    "            {{ $ctrl.supercode.code }}\n" +
    "          </p>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"form-group m-b-0\">\n" +
    "        <label class=\"col-sm-2 control-label control-label-bold\">Name</label>\n" +
    "        <div class=\"col-sm-10\">\n" +
    "          <p class=\"form-control-static _m-y-0\">\n" +
    "            {{ $ctrl.supercode.name }}\n" +
    "          </p>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"form-group m-b-0\" ng-if=\"$ctrl.supercode.created\">\n" +
    "        <label class=\"col-sm-2 control-label control-label-bold\">Created</label>\n" +
    "        <div class=\"col-sm-10\">\n" +
    "          <p class=\"form-control-static\">\n" +
    "            At\n" +
    "            {{ $ctrl.supercode.created.date | date:\"MM/dd/yyyy\" }}\n" +
    "            by\n" +
    "            {{ $ctrl.supercode.created.user }}\n" +
    "          </p>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"form-group m-b-0\" ng-if=\"$ctrl.supercode.updated\">\n" +
    "        <label class=\"col-sm-2 control-label control-label-bold\">Updated</label>\n" +
    "        <div class=\"col-sm-10\">\n" +
    "          <p class=\"form-control-static\">\n" +
    "            At\n" +
    "            {{ $ctrl.supercode.updated.date | date:\"MM/dd/yyyy\" }}\n" +
    "            by\n" +
    "            {{ $ctrl.supercode.updated.user }}\n" +
    "          </p>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"form-group m-b-0\">\n" +
    "        <label class=\"col-sm-2 control-label control-label-bold\">Archived</label>\n" +
    "        <div class=\"col-sm-10\">\n" +
    "          <p class=\"form-control-static\">\n" +
    "            {{ $ctrl.supercode.is_active ? 'No' : 'Yes' }}\n" +
    "          </p>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"table-responsive\" style=\"margin-top:15px; max-height: 220px;\" ng-if=\"$ctrl.supercode.procedures.length\">\n" +
    "    <table class=\"table table-advance table-advance-form table-condensed m-b-0\">\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th class=\"text-center\" style=\"width: 75px\">Code</th>\n" +
    "          <th>Name</th>\n" +
    "          <th class=\"text-center narrow\">Active</th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"procedure in $ctrl.supercode.procedures track by procedure.id\">\n" +
    "          <td class=\"text-center\">\n" +
    "            {{ procedure.code }}\n" +
    "          </td>\n" +
    "          <td class=\"wide-in-one-line\">\n" +
    "            {{ procedure.name }}\n" +
    "          </td>\n" +
    "          <td class=\"narrow text-center\">\n" +
    "            <i class=\"text-muted icomoon\" style=\"font-size: 16px; vertical-align: middle\"\n" +
    "              ng-class=\"procedure.is_active ? 'icon-icomoon-checkmark-circle' : ''\">\n" +
    "            </i>\n" +
    "          </td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "    </table>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"modal-footer\">\n" +
    "  <button class=\"btn btn-wide default\" ng-click=\"$dismiss()\">Cancel</button>\n" +
    "  <button class=\"btn btn-wide btn-primary\" ng-click=\"$ctrl.submit()\">OK</button>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/referrals/components/referrals-detail-event-detail/referrals-detail-event-detail.html","<div class=\"modal-header\">\n" +
    "  <h3 class=\"modal-title\">Event #{{ $ctrl.event.id }}</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\" style=\"max-height: calc(100vh - 200px);overflow-y: auto;\">\n" +
    "  <form name=\"form\" class=\"form-horizontal\">\n" +
    "    <fieldset>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label class=\"col-md-3 control-label\">Date and Time</label>\n" +
    "        <div class=\"col-md-6\">\n" +
    "          <div class=\"form-control-static\">\n" +
    "            {{ $ctrl.event.created_at | date:'MM/dd/yyyy - hh:mm:ss a' }}\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"form-group\">\n" +
    "        <label class=\"col-md-3 control-label\">Action</label>\n" +
    "        <div class=\"col-md-6\">\n" +
    "          <div class=\"form-control-static\" ng-bind=\"$ctrl.event.action\"></div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <pre>{{ $ctrl.event | json }}</pre>\n" +
    "    </fieldset>\n" +
    "  </form>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "  <button class=\"btn btn-wide btn-primary\" ng-click=\"$dismiss()\">Close</button>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/referrals/components/referrals-detail-event/referrals-detail-event.html","<div class=\"portlet-body\">\n" +
    "  <table class=\"table table-striped table-bordered table-advance\">\n" +
    "    <tr>\n" +
    "      <th class=\"w-xs text-nowrap\">Date and Time</th>\n" +
    "      <th>Action</th>\n" +
    "      <th style=\"width:30px\"></th>\n" +
    "    </tr>\n" +
    "    <tr ng-repeat=\"event in $ctrl.events\">\n" +
    "      <td ng-bind=\"event.created_at | date:'MM/dd/yyyy hh:mm:ss a'\" class=\"w-xs text-nowrap\"></td>\n" +
    "      <td ng-bind=\"event.action\"></td>\n" +
    "      <td>\n" +
    "        <a class=\"btn-icomoon-pixel-perfect-1x pull-right\"\n" +
    "          ui-sref=\"referrals.received.detail.event.detail({eventId:event.id})\">\n" +
    "          <span class=\"icomoon icon-icomoon-pencil5\"></span>\n" +
    "        </a>\n" +
    "      </td>\n" +
    "    </tr>\n" +
    "  </table>\n" +
    "</div>\n" +
    "")

$templateCache.put("src/app/routes/settings/accounting-and-billing/feeprofile-detail.html","<div class=\"page-bar\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li>\n" +
    "      <i class=\"icomoon icon-icomoon-home6\"></i>\n" +
    "      <a ui-sref=\"charts\">Home</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "      <a ui-sref=\"settings\">Settings</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>Accounting and Billing</span>\n" +
    "    </li>\n" +
    "    <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    <li>\n" +
    "      <a ui-sref=\"^.feeprofile-list\">Fee Schedules</a>\n" +
    "      <i class=\"icomoon icon-icomoon-arrow-right2\"></i>\n" +
    "    </li>\n" +
    "    <li>\n" +
    "      <span>{{ $ctrl.feeprofile.id ? \"Edit\" : \"Create\" }}</span>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "\n" +
    "<form autocomplete=\"off\" name=\"form\" novalidate>\n" +
    "  <div class=\"portlet light\">\n" +
    "    <div class=\"portlet-title\">\n" +
    "      <div class=\"caption\">\n" +
    "        <i class=\"icomoon icon-icomoon-cash3\"></i>\n" +
    "        <span class=\"caption-subject font-red-sunglo bold uppercase\">\n" +
    "          <span>Fee Schedule</span>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "      <div class=\"actions\">\n" +
    "        <a class=\"btn btn-default btn-wide\" ui-sref=\"^.feeprofile-list\">\n" +
    "          Back\n" +
    "        </a>\n" +
    "        <button type=\"button\" class=\"btn default btn-wide\"\n" +
    "          ng-click=\"$ctrl.retrieve()\"\n" +
    "          ng-disabled=\"!$ctrl.canRetrieve\"\n" +
    "          ng-if=\"$ctrl.feeprofile.id\">\n" +
    "          Reload\n" +
    "        </button>\n" +
    "        <button type=\"button\" class=\"btn btn-danger btn-wide\"\n" +
    "          ng-click=\"$ctrl.destroy()\"\n" +
    "          ng-disabled=\"!$ctrl.canDelete || $ctrl.feeprofile.name === 'Fee for Service'\"\n" +
    "          ng-if=\"$ctrl.feeprofile.id\">\n" +
    "          Delete\n" +
    "        </button>\n" +
    "        <button type=\"button\" class=\"btn btn-info btn-wide\"\n" +
    "          ng-click=\"$ctrl.duplicate()\"\n" +
    "          ng-disabled=\"!$ctrl.canSave\"\n" +
    "          ng-if=\"$ctrl.feeprofile.id\">\n" +
    "          Duplicate\n" +
    "        </button>\n" +
    "        <button type=\"button\" class=\"btn btn-primary btn-wide\"\n" +
    "          ng-click=\"$ctrl.feeprofile.id ? $ctrl.update() : $ctrl.create()\"\n" +
    "          ng-disabled=\"!$ctrl.canSave || form.$invalid\">\n" +
    "          Save\n" +
    "        </button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"portlet-body\">\n" +
    "      <fieldset ng-if=\"$ctrl.feeprofile\" ng-disabled=\"!$ctrl.canSave\">\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"col-sm-6\">\n" +
    "            <div class=\"form-group has-feedback\" ng-class=\"{'has-error': $ctrl.$retrieved && form.name.$invalid}\">\n" +
    "              <label class=\"control-label\">\n" +
    "                Name\n" +
    "              </label>\n" +
    "              <input class=\"form-control\"\n" +
    "                autocomplete=\"off\"\n" +
    "                name=\"name\"\n" +
    "                ng-model=\"$ctrl.feeprofile.name\"\n" +
    "                ng-readonly=\"$ctrl.feeprofile.name === 'Fee for Service'\"\n" +
    "                required />\n" +
    "              <span class=\"form-control-feedback\"\n" +
    "                ng-if=\"$ctrl.$retrieved\"\n" +
    "                ng-repeat=\"(error, _) in form.name.$error\"\n" +
    "                ng-switch=\"error\">\n" +
    "                <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "                  ng-switch-when=\"required\"\n" +
    "                  tooltip-append-to-body=\"true\"\n" +
    "                  uib-tooltip=\"This field is required\">\n" +
    "                </span>\n" +
    "                <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "                  ng-switch-default\n" +
    "                  tooltip-append-to-body=\"true\"\n" +
    "                  uib-tooltip=\"{{ error }}\">\n" +
    "                </span>\n" +
    "              </span>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label\">\n" +
    "                Description\n" +
    "              </label>\n" +
    "              <textarea class=\"form-control\" style=\"height: 105px\"\n" +
    "                autocomplete=\"off\"\n" +
    "                name=\"description\"\n" +
    "                ng-model=\"$ctrl.feeprofile.description\">\n" +
    "              </textarea>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-6\">\n" +
    "            <div class=\"form-group\" ng-if=\"!$ctrl.feeprofile.id || $ctrl.feeprofile.has_insurance_fees\">\n" +
    "              <label class=\"control-label\">\n" +
    "                Has Insurance Fees\n" +
    "              </label>\n" +
    "              <div style=\"padding-top: 5px\">\n" +
    "                <switch class=\"small\"\n" +
    "                  name=\"has_insurance_fees\"\n" +
    "                  disabled=\"$ctrl.feeprofile.id\"\n" +
    "                  ng-model=\"$ctrl.feeprofile.has_insurance_fees\">\n" +
    "                </switch>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group has-feedback form-group-ui-select\"\n" +
    "              ng-class=\"{'has-error': $ctrl.$retrieved && form.payer.$invalid}\"\n" +
    "              ng-if=\"!$ctrl.feeprofile.id || $ctrl.feeprofile.has_insurance_fees\">\n" +
    "              <label class=\"control-label\">\n" +
    "                Payer\n" +
    "              </label>\n" +
    "              <ui-select\n" +
    "                name=\"payer\"\n" +
    "                ng-model=\"$ctrl.feeprofile.payer\"\n" +
    "                ng-disabled=\"$ctrl.feeprofile.id || !$ctrl.feeprofile.has_insurance_fees\"\n" +
    "                ng-required=\"$ctrl.feeprofile.has_insurance_fees\">\n" +
    "                <ui-select-match placeholder=\"Search by Name or Payer ID\">\n" +
    "                  <span class=\"text-overflow dis-ib w-full\">\n" +
    "                    <span>{{ $select.selected[1] }}</span>\n" +
    "                    <small class=\"text-muted m-l-sm\">{{ $select.selected[2] }}</small>\n" +
    "                  </span>\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices repeat=\"\n" +
    "                  choice[0] as choice in $ctrl.options.payers | \n" +
    "                  propsFilter: {filter:$select.search, props:['1', '2']} | \n" +
    "                  limitTo:250 \n" +
    "                  track by choice[0]\">\n" +
    "                  <span class=\"text-overflow dis-ib w-full\">\n" +
    "                    <span>{{ choice[1] }}</span>\n" +
    "                    <small class=\"text-muted m-l-sm\">{{ choice[2] }}</small>\n" +
    "                  </span>\n" +
    "                </ui-select-choices>\n" +
    "              </ui-select>\n" +
    "              <span class=\"form-control-feedback\"\n" +
    "                ng-if=\"$ctrl.$retrieved\"\n" +
    "                ng-repeat=\"(error, _) in form.payer.$error\"\n" +
    "                ng-switch=\"error\">\n" +
    "                <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "                  ng-switch-when=\"required\"\n" +
    "                  tooltip-append-to-body=\"true\"\n" +
    "                  tooltip-placement=\"top-right\"\n" +
    "                  uib-tooltip=\"This field is required\">\n" +
    "                </span>\n" +
    "                <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "                  ng-switch-default\n" +
    "                  tooltip-append-to-body=\"true\"\n" +
    "                  tooltip-placement=\"top-right\"\n" +
    "                  uib-tooltip=\"{{ error }}\">\n" +
    "                </span>\n" +
    "              </span>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label\">\n" +
    "                Archived\n" +
    "              </label>\n" +
    "              <div style=\"padding-top: 5px\">\n" +
    "                <switch class=\"small\"\n" +
    "                  disabled=\"$ctrl.feeprofile.name === 'Fee for Service'\"\n" +
    "                  name=\"is_archived\"\n" +
    "                  ng-model=\"$ctrl.feeprofile.is_archived\">\n" +
    "                </switch>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </fieldset>\n" +
    "    </div>\n" +
    "    <div class=\"portlet-title\" ng-if=\"$ctrl.feeprofile.fees\">\n" +
    "      <div class=\"caption\">\n" +
    "        <i class=\"icomoon icon-icomoon-cash4\"></i>\n" +
    "        <span class=\"caption-subject font-red-sunglo bold uppercase\">\n" +
    "          <span>Fees</span>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "      <div class=\"actions\">\n" +
    "        <!-- <div class=\"checkbox-inline custom-control custom-checkbox m-r\">\n" +
    "          <label>\n" +
    "            <input type=\"checkbox\" ng-model=\"$ctrl.showFeesWithoutAmount\" />\n" +
    "            <span class=\"custom-control-indicator\"></span>\n" +
    "            Show Fees without amounts?\n" +
    "          </label>\n" +
    "        </div> -->\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"portlet-body\" ng-if=\"$ctrl.feeprofile.fees\">\n" +
    "      <div class=\"table-responsive\">\n" +
    "        <table class=\"table table-advance table-advance-form m-b-0\">\n" +
    "          <thead ng-if=\"$ctrl.feeprofile.has_insurance_fees === false\">\n" +
    "            <tr>\n" +
    "              <th class=\"text-center\" style=\"width: 70px\">Code</th>\n" +
    "              <th class=\"\">Procedure</th>\n" +
    "              <th class=\"text-center\" style=\"width: 130px\">Patient</th>\n" +
    "              <th class=\"text-center narrow\">Updated</th>\n" +
    "            </tr>\n" +
    "          </thead>\n" +
    "          <tbody ng-if=\"$ctrl.feeprofile.has_insurance_fees === false\">\n" +
    "            <tr ng-if=\"$ctrl.isFeeVisible(fee)\"\n" +
    "              ng-init=\"f1 = 'fee_' + fee.id + '_amounts_patient';\"\n" +
    "              ng-repeat=\"fee in $ctrl.feeprofile.fees track by fee.id\">\n" +
    "              <td class=\"highlight narrow text-center\">\n" +
    "                <div ng-class=\"{\n" +
    "                  'primary': fee.amounts.patient !== fee.$amounts.patient,\n" +
    "                  'danger': form[f1].$invalid}\">\n" +
    "                </div>\n" +
    "                <div class=\"m-l\">\n" +
    "                  {{ fee.procedure.code }}\n" +
    "                </div>\n" +
    "              </td>\n" +
    "              <td class=\"wide-in-one-line\">\n" +
    "                {{ fee.procedure.name }}\n" +
    "              </td>\n" +
    "              <td style=\"padding: 3px\">\n" +
    "                <div class=\"form-group m-b-0 has-feedback\" ng-class=\"{'has-error': form[f1].$invalid}\">\n" +
    "                  <input class=\"form-control text-right\" style=\"height: 30px\"\n" +
    "                    name=\"{{ f1 }}\"\n" +
    "                    ng-model=\"fee.amounts.patient\"\n" +
    "                    ng-pattern=\"$ctrl.DECIMAL_NUMBER\"\n" +
    "                    ng-style=\"fee.amounts.patient !== '0.00' ? {color: '#666'} : {}\"\n" +
    "                    required />\n" +
    "                  <span class=\"form-control-feedback\" ng-repeat=\"(error, _) in form[f1].$error\" ng-switch=\"error\">\n" +
    "                    <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "                      ng-switch-when=\"pattern\"\n" +
    "                      tooltip-append-to-body=\"true\"\n" +
    "                      uib-tooltip=\"Must be a decimal number to the hundredths.\">\n" +
    "                    </span>\n" +
    "                    <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "                      ng-switch-when=\"required\"\n" +
    "                      tooltip-append-to-body=\"true\"\n" +
    "                      uib-tooltip=\"Must be a decimal number to the hundredths.\">\n" +
    "                    </span>\n" +
    "                    <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "                      ng-switch-default\n" +
    "                      tooltip-append-to-body=\"true\"\n" +
    "                      uib-tooltip=\"{{ error }}\">\n" +
    "                    </span>\n" +
    "                  </span>\n" +
    "                </div>\n" +
    "              </td>\n" +
    "              <td class=\"narrow\">\n" +
    "                {{ fee.updated.date | date:\"MM/dd/yyyy\" }}\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "          </tbody>\n" +
    "          <thead ng-if=\"$ctrl.feeprofile.has_insurance_fees === true\">\n" +
    "            <tr>\n" +
    "              <th class=\"text-center\" style=\"width: 70px\">Code</th>\n" +
    "              <th class=\"\">Procedure</th>\n" +
    "              <th class=\"text-center\" style=\"width: 130px\">In Network</th>\n" +
    "              <th class=\"text-center\" style=\"width: 130px\">Out of Network</th>\n" +
    "              <th class=\"text-center narrow\">Updated</th>\n" +
    "            </tr>\n" +
    "          </thead>\n" +
    "          <tbody ng-if=\"$ctrl.feeprofile.has_insurance_fees === true\">\n" +
    "            <tr ng-if=\"$ctrl.isFeeVisible(fee)\"\n" +
    "              ng-init=\"f1 = 'fee_' + fee.id + '_amounts_innetwork'; f2 = 'fee_' + fee.id + '_amounts_outnetwork';\"\n" +
    "              ng-repeat=\"fee in $ctrl.feeprofile.fees track by fee.id\">\n" +
    "              <td class=\"highlight narrow text-center\">\n" +
    "                <div ng-class=\"{\n" +
    "                  'primary': fee.amounts.innetwork !== fee.$amounts.innetwork || fee.amounts.outnetwork !== fee.$amounts.outnetwork,\n" +
    "                  'danger': form[f1].$invalid || form[f2].$invalid}\">\n" +
    "                </div>\n" +
    "                <div class=\"m-l\">{{ fee.procedure.code }}</div>\n" +
    "              </td>\n" +
    "              <td class=\"wide-in-one-line\">\n" +
    "                {{ fee.procedure.name }}\n" +
    "              </td>\n" +
    "              <td style=\"padding: 3px\">\n" +
    "                <div class=\"form-group m-b-0 has-feedback\" ng-class=\"{'has-error': form[f1].$invalid}\">\n" +
    "                  <input class=\"form-control text-right\" style=\"height: 30px\"\n" +
    "                    name=\"{{ f1 }}\"\n" +
    "                    ng-model=\"fee.amounts.innetwork\"\n" +
    "                    ng-pattern=\"$ctrl.DECIMAL_NUMBER\"\n" +
    "                    ng-style=\"fee.amounts.innetwork !== '0.00' ? {color: '#666'} : {}\"\n" +
    "                    required />\n" +
    "                  <span class=\"form-control-feedback\" ng-repeat=\"(error, _) in form[f1].$error\" ng-switch=\"error\">\n" +
    "                    <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "                      ng-switch-when=\"pattern\"\n" +
    "                      tooltip-append-to-body=\"true\"\n" +
    "                      uib-tooltip=\"Must be a decimal number to the hundredths.\">\n" +
    "                    </span>\n" +
    "                    <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "                      ng-switch-when=\"required\"\n" +
    "                      tooltip-append-to-body=\"true\"\n" +
    "                      uib-tooltip=\"Must be a decimal number to the hundredths.\">\n" +
    "                    </span>\n" +
    "                    <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "                      ng-switch-default\n" +
    "                      tooltip-append-to-body=\"true\"\n" +
    "                      uib-tooltip=\"{{ error }}\">\n" +
    "                    </span>\n" +
    "                  </span>\n" +
    "                </div>\n" +
    "              </td>\n" +
    "              <td style=\"padding: 3px\">\n" +
    "                <div class=\"form-group m-b-0 has-feedback\" ng-class=\"{'has-error': form[f2].$invalid}\">\n" +
    "                  <input class=\"form-control text-right\" style=\"height: 30px\"\n" +
    "                    name=\"{{ f2 }}\"\n" +
    "                    ng-model=\"fee.amounts.outnetwork\"\n" +
    "                    ng-pattern=\"$ctrl.DECIMAL_NUMBER\"\n" +
    "                    ng-style=\"fee.amounts.outnetwork !== '0.00' ? {color: '#666'} : {}\"\n" +
    "                    required />\n" +
    "                  <span class=\"form-control-feedback\" ng-repeat=\"(error, _) in form[f2].$error\" ng-switch=\"error\">\n" +
    "                    <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "                      ng-switch-when=\"pattern\"\n" +
    "                      tooltip-append-to-body=\"true\"\n" +
    "                      uib-tooltip=\"Must be a decimal number to the hundredths.\">\n" +
    "                    </span>\n" +
    "                    <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "                      ng-switch-when=\"required\"\n" +
    "                      tooltip-append-to-body=\"true\"\n" +
    "                      uib-tooltip=\"Must be a decimal number to the hundredths.\">\n" +
    "                    </span>\n" +
    "                    <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\"\n" +
    "                      ng-switch-default\n" +
    "                      tooltip-append-to-body=\"true\"\n" +
    "                      uib-tooltip=\"{{ error }}\">\n" +
    "                    </span>\n" +
    "                  </span>\n" +
    "                </div>\n" +
    "              </td>\n" +
    "              <td class=\"narrow\">\n" +
    "                {{ fee.updated.date | date:\"MM/dd/yyyy\" }}\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "          </tbody>\n" +
    "        </table>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</form>\n" +
    "")

$templateCache.put("src/app/routes/settings/accounting-and-billing/feeprofile-list.html","<account-billing-fee-schedules></account-billing-fee-schedules>")

$templateCache.put("src/app/routes/settings/accounting-and-billing/supercode-detail.html","<div class=\"page-bar\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li><i class=\"icomoon icon-icomoon-home6\"></i></li>\n" +
    "    <li><a ui-sref=\"charts\">Home</a></li>\n" +
    "    <li><i class=\"icomoon icon-icomoon-arrow-right2\"></i></li>\n" +
    "    <li><a ui-sref=\"settings\">Settings</a></li>\n" +
    "    <li><i class=\"icomoon icon-icomoon-arrow-right2\"></i></li>\n" +
    "    <li><span>Accounting and Billing</span></li>\n" +
    "    <li><i class=\"icomoon icon-icomoon-arrow-right2\"></i></li>\n" +
    "    <li><a ui-sref=\"settings-accounting-and-billing.supercode-list\">Supercodes</a></li>\n" +
    "    <li><i class=\"icomoon icon-icomoon-arrow-right2\"></i></li>\n" +
    "    <li><span>{{ $ctrl.supercode.id ? \"Edit\" : \"Create\" }}</span></li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "\n" +
    "<form autocomplete=\"off\" name=\"form\" novalidate>\n" +
    "  <div class=\"portlet light\">\n" +
    "    <div class=\"portlet-title\">\n" +
    "      <div class=\"caption\">\n" +
    "        <i class=\"icomoon icon-icomoon-pill\"></i>\n" +
    "        <span class=\"caption-subject font-red-sunglo bold uppercase\">\n" +
    "          <span>Supercode</span>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "      <div class=\"actions\">\n" +
    "        <a class=\"btn btn-default btn-wide\" ui-sref=\"^.supercode-list\">\n" +
    "          Back\n" +
    "        </a>\n" +
    "        <button type=\"button\" class=\"btn default btn-wide\" ng-click=\"$ctrl.retrieve()\" ng-disabled=\"!$ctrl.canRetrieve\">\n" +
    "          Reload\n" +
    "        </button>\n" +
    "        <button type=\"button\" class=\"btn btn-danger btn-wide\" ng-click=\"$ctrl.destroy()\" ng-disabled=\"!$ctrl.canDelete\"\n" +
    "          ng-if=\"$ctrl.supercode.id\">\n" +
    "          Delete\n" +
    "        </button>\n" +
    "        <button type=\"button\" class=\"btn btn-primary btn-wide\"\n" +
    "          ng-click=\"$ctrl.supercode.id ? $ctrl.update() : $ctrl.create()\" ng-disabled=\"!$ctrl.canSave || form.$invalid\">\n" +
    "          Save\n" +
    "        </button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"portlet-body\">\n" +
    "      <fieldset ng-if=\"$ctrl.supercode\" ng-disabled=\"!$ctrl.canSave\">\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"col-sm-6\">\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-feedback has-error': $ctrl.$retrieved && form.code.$invalid}\">\n" +
    "              <label class=\"control-label\">\n" +
    "                Code\n" +
    "              </label>\n" +
    "              <input class=\"form-control\" autocomplete=\"off\" name=\"code\" ng-model=\"$ctrl.supercode.code\" required />\n" +
    "              <span class=\"form-control-feedback\" ng-if=\"$ctrl.$retrieved\" ng-repeat=\"(error, _) in form.code.$error\"\n" +
    "                ng-switch=\"error\">\n" +
    "                <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\" ng-switch-when=\"required\"\n" +
    "                  tooltip-append-to-body=\"true\" uib-tooltip=\"This field is required\">\n" +
    "                </span>\n" +
    "                <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\" ng-switch-default\n" +
    "                  tooltip-append-to-body=\"true\" uib-tooltip=\"{{ error }}\">\n" +
    "                </span>\n" +
    "              </span>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-feedback has-error': $ctrl.$retrieved && form.name.$invalid}\">\n" +
    "              <label class=\"control-label\">\n" +
    "                Name\n" +
    "              </label>\n" +
    "              <input class=\"form-control\" autocomplete=\"off\" name=\"name\" ng-model=\"$ctrl.supercode.name\" required />\n" +
    "              <span class=\"form-control-feedback\" ng-if=\"$ctrl.$retrieved\" ng-repeat=\"(error, _) in form.name.$error\"\n" +
    "                ng-switch=\"error\">\n" +
    "                <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\" ng-switch-when=\"required\"\n" +
    "                  tooltip-append-to-body=\"true\" uib-tooltip=\"This field is required\">\n" +
    "                </span>\n" +
    "                <span class=\"icomoon icon-icomoon-warning2 form-control-feedback\" ng-switch-default\n" +
    "                  tooltip-append-to-body=\"true\" uib-tooltip=\"{{ error }}\">\n" +
    "                </span>\n" +
    "              </span>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"col-sm-6\">\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label\">\n" +
    "                Description\n" +
    "              </label>\n" +
    "              <textarea class=\"form-control\" style=\"height: 109px\" autocomplete=\"off\" name=\"description\"\n" +
    "                ng-model=\"$ctrl.supercode.description\">\n" +
    "              </textarea>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"control-label\">\n" +
    "                Archived\n" +
    "              </label>\n" +
    "              <div style=\"padding-top: 5px\">\n" +
    "                <switch class=\"small\" name=\"archived\" ng-model=\"$ctrl.supercode.is_archived\">\n" +
    "                </switch>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </fieldset>\n" +
    "    </div>\n" +
    "    <div class=\"portlet-title\">\n" +
    "      <div class=\"caption\">\n" +
    "        <i class=\"icomoon icon-icomoon-pill\"></i>\n" +
    "        <span class=\"caption-subject font-red-sunglo bold uppercase\">\n" +
    "          <span>Procedures</span>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "      <div class=\"actions\">\n" +
    "        <div class=\"checkbox-inline custom-control custom-checkbox m-r\" ng-if=\"$ctrl.supercode.id\">\n" +
    "          <label>\n" +
    "            <input type=\"checkbox\" ng-model=\"$ctrl.showOnlySelected\" />\n" +
    "            <span class=\"custom-control-indicator\"></span>\n" +
    "            Show only Selected?\n" +
    "          </label>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"portlet-body\" ng-if=\"$ctrl.supercode.procedures\">\n" +
    "      <div class=\"table-responsive\">\n" +
    "        <table class=\"table table-advance table-advance-form table-condensed table-hover\">\n" +
    "          <thead>\n" +
    "            <tr>\n" +
    "              <th class=\"text-center\" style=\"width: 75px\">Code</th>\n" +
    "              <th>Name</th>\n" +
    "              <th class=\"narrow\">Active</th>\n" +
    "              <th>Selected</th>\n" +
    "            </tr>\n" +
    "          </thead>\n" +
    "          <tbody>\n" +
    "            <tr ng-repeat=\"procedure in $ctrl.supercode.procedures track by procedure.id\"\n" +
    "              ng-if=\"$ctrl.isVisible(procedure)\">\n" +
    "              <td class=\"text-center\">\n" +
    "                {{ procedure.code }}\n" +
    "              </td>\n" +
    "              <td class=\"wide-in-one-line\">\n" +
    "                {{ procedure.name }}\n" +
    "              </td>\n" +
    "              <td class=\"text-center\">\n" +
    "                <i class=\"icomoon\" style=\"font-size: 16px; vertical-align: middle\"\n" +
    "                  ng-class=\"{'icon-icomoon-checkmark-circle': procedure.is_active}\">\n" +
    "                </i>\n" +
    "              </td>\n" +
    "              <td class=\"td-switch-small-ct\"\n" +
    "                ng-style=\"{'background-color': {error: '#ed6b75', success:'#26c281'}[procedure.$updateSelected]}\">\n" +
    "                <div class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "                  <label>\n" +
    "                    <input type=\"checkbox\" ng-model=\"procedure.selected\" />\n" +
    "                    <span class=\"custom-control-indicator\"\n" +
    "                      ng-style=\"{'border-color': procedure.$updateSelected ? '#fff' : ''}\">\n" +
    "                    </span>\n" +
    "                  </label>\n" +
    "                </div>\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "          </tbody>\n" +
    "        </table>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</form>\n" +
    "")

$templateCache.put("src/app/routes/settings/accounting-and-billing/supercode-list.html","<div class=\"page-bar\">\n" +
    "  <ul class=\"page-breadcrumb\">\n" +
    "    <li><i class=\"icomoon icon-icomoon-home6\"></i></li>\n" +
    "    <li><a ui-sref=\"charts\">Home</a></li>\n" +
    "    <li><i class=\"icomoon icon-icomoon-arrow-right2\"></i></li>\n" +
    "    <li><a ui-sref=\"settings\">Settings</a></li>\n" +
    "    <li><i class=\"icomoon icon-icomoon-arrow-right2\"></i></li>\n" +
    "    <li><span>Accounting and Billing</span></li>\n" +
    "    <li><i class=\"icomoon icon-icomoon-arrow-right2\"></i></li>\n" +
    "    <li><span>Supercodes</span></li>\n" +
    "  </ul>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"portlet light porlet-fit\">\n" +
    "  <div class=\"portlet-title\">\n" +
    "    <div class=\"caption\">\n" +
    "      <i class=\"icomoon icon-icomoon-pill\"></i>\n" +
    "      <span class=\"caption-subject font-red-sunglo bold uppercase\">Supercodes</span>\n" +
    "    </div>\n" +
    "    <div class=\"actions\">\n" +
    "      <div class=\"checkbox-inline custom-control custom-checkbox m-r\">\n" +
    "        <label>\n" +
    "          <input type=\"checkbox\" ng-model=\"$ctrl.showArchived\" />\n" +
    "          <span class=\"custom-control-indicator\"></span>\n" +
    "          Show Archived?\n" +
    "        </label>\n" +
    "      </div>\n" +
    "      <button class=\"btn p-x default\" ng-click=\"$ctrl.list()\" ng-disabled=\"!$ctrl.canListSuperCode\">\n" +
    "        Reload\n" +
    "      </button>\n" +
    "      <a class=\"btn p-x btn-primary\" ui-sref=\"^.supercode-detail({ id:'new' })\" ng-disabled=\"!$ctrl.canCreateSuperCode\">\n" +
    "        New Supercode\n" +
    "      </a>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"portlet-body\">\n" +
    "    <div class=\"table-responsive\">\n" +
    "      <table class=\"table table-advance table-bordered\">\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <th class=\"text-center\" style=\"width: 75px\">Code</th>\n" +
    "            <th>Name</th>\n" +
    "            <th class=\"text-center\" style=\"width: 99px\">Created</th>\n" +
    "            <th class=\"text-center\" style=\"width: 99px\">Updated</th>\n" +
    "            <th class=\"text-center\" style=\"width: 43px\" ng-if=\"$ctrl.showArchived\">\n" +
    "              <i class=\"icomoon icon-icomoon-box\" style=\"font-size: 16px; vertical-align: middle\"\n" +
    "                uib-tooltip=\"Is Archived\">\n" +
    "              </i>\n" +
    "            </th>\n" +
    "            <th style=\"width: 99px\"></th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "          <tr ng-if=\"$ctrl.isVisible(supercode)\" ng-repeat-start=\"supercode in $ctrl.supercodes track by supercode.id\">\n" +
    "            <td class=\"text-center\">\n" +
    "              {{ supercode.code }}\n" +
    "            </td>\n" +
    "            <td class=\"wide-in-one-line\">\n" +
    "              {{ supercode.name }}\n" +
    "            </td>\n" +
    "            <td class=\"text-center\">\n" +
    "              <a tabindex=\"0\" tooltip-trigger=\"'click'\" uib-tooltip=\"{{ supercode.created.user }}\">\n" +
    "                {{ supercode.created.date | date:\"MM/dd/yyyy\" }}\n" +
    "              </a>\n" +
    "            </td>\n" +
    "            <td class=\"text-center\">\n" +
    "              <a tabindex=\"0\" tooltip-trigger=\"'click'\" uib-tooltip=\"{{ supercode.updated.user }}\">\n" +
    "                {{ supercode.updated.date | date:\"MM/dd/yyyy\" }}\n" +
    "              </a>\n" +
    "            </td>\n" +
    "            <td class=\"td-switch-small-ct\" ng-if=\"$ctrl.showArchived\" ng-switch=\"supercode.$updateIsActive\"\n" +
    "              ng-style=\"{'background-color': {error: '#ed6b75', success:'#26c281'}[supercode.$updateIsActive]}\">\n" +
    "              <div ng-switch-when=\"in-progress\" style=\"margin-top: 4px;\">\n" +
    "                <i class=\"icomoon icon-icomoon-spinner10\"></i>\n" +
    "              </div>\n" +
    "              <div ng-switch-default class=\"checkbox-inline custom-control custom-checkbox\">\n" +
    "                <label>\n" +
    "                  <input type=\"checkbox\" ng-change=\"$ctrl.update(supercode, ['is_active'])\"\n" +
    "                    ng-model=\"supercode.is_active\" ng-true-value=\"false\" ng-false-value=\"true\" />\n" +
    "                  <span class=\"custom-control-indicator\"\n" +
    "                    ng-style=\"{'border-color': supercode.$updateIsActive ? '#fff' : ''}\">\n" +
    "                  </span>\n" +
    "                </label>\n" +
    "              </div>\n" +
    "            </td>\n" +
    "            <td class=\"btn-container-pixel-perfect-1x\" style=\"background-color: #ffffff\">\n" +
    "              <div class=\"btn-group\" style=\"display: flex; justify-content: space-evenly\">\n" +
    "                <button type=\"button\" class=\"btn btn-icomoon-pixel-perfect-1x white\"\n" +
    "                  ng-disabled=\"!$ctrl.canRetrieveSuperCode\" ng-click=\"$ctrl.retrieve(supercode, ['procedures'])\">\n" +
    "                  <i class=\"icomoon icon-icomoon-pill\" style=\"width: 17px\"\n" +
    "                    ng-style=\"{transform: supercode.$showProcedures ? 'rotate(90deg)' : null}\">\n" +
    "                  </i>\n" +
    "                </button>\n" +
    "                <a class=\"btn btn-icomoon-pixel-perfect-1x white font-blue\" ng-disabled=\"!$ctrl.canUpdateSuperCode\"\n" +
    "                  ui-sref=\"^.supercode-detail({ id:supercode.id })\">\n" +
    "                  <i class=\"icomoon icon-icomoon-pencil5\"></i>\n" +
    "                </a>\n" +
    "                <button type=\"button\" class=\"btn btn-icomoon-pixel-perfect-1x white font-red\"\n" +
    "                  ng-disabled=\"!$ctrl.canDeleteSuperCode\" ng-click=\"$ctrl.destroy(supercode)\">\n" +
    "                  <i class=\"icomoon icon-icomoon-bin\"></i>\n" +
    "                </button>\n" +
    "              </div>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "          <tr ng-repeat-end ng-if=\"supercode.$showProcedures\">\n" +
    "            <td class=\"p-x-0 p-y-0\" colspan=\"{{ $ctrl.showArchived ? 6 : 5 }}\">\n" +
    "              <table class=\"table table-advance table-condensed table-expandable m-b-0\" ng-if=\"supercode.procedures\">\n" +
    "                <thead>\n" +
    "                  <tr>\n" +
    "                    <th class=\"text-center\" style=\"width: 75px\">Code</th>\n" +
    "                    <th>Name</th>\n" +
    "                    <th class=\"narrow\">Active</th>\n" +
    "                  </tr>\n" +
    "                </thead>\n" +
    "                <tbody>\n" +
    "                  <tr class=\"text-muted\" ng-repeat=\"procedure in supercode.procedures track by procedure.id\">\n" +
    "                    <td class=\"text-center\">\n" +
    "                      {{ procedure.code }}\n" +
    "                    </td>\n" +
    "                    <td class=\"wide-in-one-line\">\n" +
    "                      {{ procedure.name }}\n" +
    "                    </td>\n" +
    "                    <td class=\"text-center\">\n" +
    "                      <i class=\"icomoon\" style=\"font-size: 16px; vertical-align: middle\"\n" +
    "                        ng-class=\"{'icon-icomoon-checkmark-circle': procedure.is_active}\">\n" +
    "                      </i>\n" +
    "                    </td>\n" +
    "                  </tr>\n" +
    "                  <tr ng-if=\"!supercode.procedures.length\">\n" +
    "                    <td colspan=\"3\"></td>\n" +
    "                  </tr>\n" +
    "                </tbody>\n" +
    "              </table>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </tbody>\n" +
    "      </table>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")
}]);
})();
(function() {
  "use strict"

  angular.module("referrals.services", [
    "common.services.pdfmake",
  ])

  angular.module("referrals.components", [])

  angular.module("referrals", [
    "uiSwitch",
    angularDragula(angular),
    "referrals.services",
    "referrals.components",
  ])

  .component("referrals", {
    template: "<ui-view></ui-view>",
    bindings: {
    },
  })

  .filter("tel", function() {
    return function(value) {
      if (value) {
        return value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
      }
    }
  })

  .filter("truncate", function() {
    return function(value, length, end) {
      if (value) {
        if (isNaN(length)) {
          length = 15
        }
        if (end === undefined) {
          end = "..."
        }
        if (value.length <= length || value.length - end.length <= length) {
          return value
        } else {
          return String(value).substring(0, length - end.length) + end
        }
      }
    }
  })

  .filter("yaml", function() {
    return function(value) {
      if (value) {
        return jsyaml.dump(value, {lineWidth:-1})
      }
    }
  })

  .config(routes)

  var AuthorizationsResolver = {
    CanCreatePatient:            function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanCreatePatient") },
    CanCreateReferral:           function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanCreateReferral") },
    CanCreateReferralPractice:   function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanCreateReferralPractice") },
    CanCreateReferralUser:       function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanCreateReferralUser") },
    CanCreateReferralUserPracticeMembership: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanCreateReferralUserPracticeMembership") },
    CanCreateReport:             function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanCreateReport") },
    CanCreateReportTemplate:     function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanCreateReportTemplate") },
    CanDeleteReferral:           function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanDeleteReferral") },
    CanDeleteReferralPractice:   function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanDeleteReferralPractice") },
    CanDeleteReferralUser:       function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanDeleteReferralUser") },
    CanDeleteReport:             function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanDeleteReport") },
    CanListPatient:              function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanListPatient") },
    CanListPatientImage:         function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanListPatientImage") },
    CanListReferralPractice:     function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanListReferralPractice") },
    CanListReferralUser:         function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanListReferralUser") },
    CanListReport:               function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanListReport") },
    CanListReportTemplate:       function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanListReportTemplate") },
    CanListTreatment:            function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanListTreatment") },
    CanRetrieveEncounter:        function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanRetrieveEncounter") },
    CanRetrievePatientImage:     function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanRetrievePatientImage") },
    CanRetrieveReferral:         function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanRetrieveReferral") },
    CanRetrieveReferralPractice: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanRetrieveReferralPractice") },
    CanRetrieveReferralUser:     function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanRetrieveReferralUser") },
    CanRetrieveReport:           function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanRetrieveReport") },
    CanRetrieveReportTemplate:   function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanRetrieveReportTemplate") },
    CanUpdatePatient:            function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanUpdatePatient") },
    CanUpdateReferral:           function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanUpdateReferral") },
    CanUpdateReferralPractice:   function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanUpdateReferralPractice") },
    CanUpdateReferralUser:       function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanUpdateReferralUser") },
    CanUpdateReferralUserPracticeMembership: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanUpdateReferralUserPracticeMembership") },
    CanUpdateReport:             function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanUpdateReport") },
  }

  Object.entries(AuthorizationsResolver).forEach(function(elem){
    AuthorizationsResolver[elem[0][0].toLowerCase() + elem[0].slice(1)] = elem[1]
  })

  function routes($stateProvider, $urlRouterProvider) {

    $urlRouterProvider
      .when("/home/referrals/", "/home/referrals/received/")

    $stateProvider
      .state("practice-referrals", {
        url: "/referrals/",
        parent: "home",
        abstract: true,
        views: {
          "task-view@home": {
            component: "referrals",
          }
        },
        resolve : angular.extend({}, AuthorizationsResolver, {
          meta: function() {
            return {
              ns: "practice-referrals"
            }
          },
          referralsFilters: function(PrincipalService) {
            return {
              practice: PrincipalService.getPracticeId(),
            }
          },
        })
      })
      .state("practice-referrals.received-prev", {
        url: "received-prev/",
        component: "referralsList",
        resolve: {
          meta: function(meta) {
            return angular.extend({}, meta, {
              role: "received"
            })
          },
        },
      })
      .state("practice-referrals.received", {
        url: "received/",
        component: "referralList",
        resolve: {
          meta: function(meta) {
            return angular.extend({}, meta, {
              role: "received"
            })
          },
        },
      })
      .state("practice-referrals.received.detail", {
        url: "{id}/",
        component: "referralsDetail",
        resolve: {
          meta: function(meta, $transition$) {
            return angular.extend({}, meta, {
              action: $transition$.params().id === "new" ? "create" : "update",
            })
          },
        },
        params: {
          patient_id: null,
          ext_patient: null,
        },
      })
      .state("practice-referrals.received.detail.reports", {
        url: "reports/",
        views: {
          "@practice-referrals": {
            component: "reportsList",
          }
        },
        resolve: {
          reportFilters: function($transition$) {
            return {
              referral: +$transition$.params().id
            }
          },
        }
      })
      .state("practice-referrals.received.detail.reports.detail", {
        url: "{reportId}/",
        views: {
          "@practice-referrals": {
            component: "report",
          }
        },
        params: {
          initial: null,
        },
        resolve: {
        }
      })
      .state("practice-referrals.received.detail.reports.detail.pdf", {
        url: "pdf/",
        views: {
          "@practice-referrals": {
            component: "reportPDF",
          }
        },
      })
      .state("practice-referrals.received.detail.event", {
        component: "referralsDetailEvent",
        url: "history/",
        resolve: {
        }
      })
      .state("practice-referrals.received.detail.event.detail", {
        url:       "{eventId:int}/",
        onEnter: function(referralsDetailEventDetail, event) {
          referralsDetailEventDetail.openModal(event)
        },
        resolve: {
        }
      })

      .state("patient-referrals", {
        url: "/referrals/",
        parent: "patientdetail",
        abstract: true,
        views: {
          "referrals@patientdetail": {
            component: "referrals",
          }
        },
        resolve : angular.extend({}, AuthorizationsResolver, {
          meta: function() {
            return {
              ns: "patient-referrals"
            }
          },
          referralsFilters: function($transition$) {
            return {
              patient: $transition$.params().patientid,
            }
          },
        })
      })
      .state("patient-referrals.received-prev", {
        url: "received-prev/",
        component: "referralsList",
        resolve: {
          meta: function(meta) {
            return angular.extend({}, meta, {
              role: "received"
            })
          },
        },
      })
      .state("patient-referrals.received", {
        url: "received/",
        component: "referralList",
        resolve: {
          meta: function(meta) {
            return angular.extend({}, meta, {
              role: "received"
            })
          },
        },
      })
      .state("patient-referrals.received.detail", {
        url: "{id}/",
        component: "referralsDetail",
        resolve: {
          meta: function(meta, $transition$) {
            return angular.extend({}, meta, {
              action: $transition$.params().id === "new" ? "create" : "update",
            })
          },
        },
        params: {
          patient_id: null,
          ext_patient: null,
        },
      })
      .state("patient-referrals.received.detail.reports", {
        url: "reports/",
        views: {
          "@patient-referrals": {
            component: "reportsList",
          }
        },
        resolve: {
          reportFilters: function($transition$) {
            return {
              referral: +$transition$.params().id
            }
          },
        }
      })
      .state("patient-referrals.received.detail.reports.detail", {
        url: "{reportId}/",
        views: {
          "@patient-referrals": {
            component: "report",
          }
        },
        params: {
          initial: null,
        },
        resolve: {
        }
      })
      .state("patient-referrals.received.detail.reports.detail.pdf", {
        url: "pdf/",
        views: {
          "@patient-referrals": {
            component: "reportPDF",
          }
        },
      })
      .state("patient-referrals.received.detail.event", {
        component: "referralsDetailEvent",
        url:       "history/",
        resolve: {
        }
      })
      .state("patient-referrals.received.detail.event.detail", {
        url:       "{eventId:int}/",
        onEnter: function(referralsDetailEventDetail, event) {
          referralsDetailEventDetail.openModal(event)
        },
        resolve: {
        }
      })

      .state("settings-referrals", {
        parent: "settings",
        url: "/referrals/",
        views: {
          "content@home": {
            template: "<ui-view></ui-view>",
          }
        },
        resolve: angular.extend({}, AuthorizationsResolver, {
          practice: function(PrincipalService) { return PrincipalService.getPracticeId() },
        })
      })
      .state("settings-referrals.practice-list", {
        url: "practices/",
        component: "referralsExternalPracticeList",
      })
      .state("settings-referrals.practice-detail", {
        url: "practices/{id}/",
        component: "referralsExternalPracticeDetail",
      })
      .state("settings-referrals.user-list", {
        url: "users/",
        component: "referralsExternalUserList",
      })
      .state("settings-referrals.user-detail", {
        url: "users/{id}/",
        component: "referralsExternalUserDetail",
      })

  }

})();

(function() {
  "use strict"

  angular.module("referrals.components")

  .component("referralsExternalPracticeDetail", {
    bindings: { $transition$: "<" },
    controller: Controller,
    templateUrl: "src/referrals/components/referral-practice-detail.html",
  })

  function Controller(
    $rootScope,
    $scope,
    $state,
    DestroyModal,
    ReferralPracticesService
  ) {

    var $ctrl = this

    $ctrl.error = null
    $ctrl.loading = 0
    $ctrl.metadata = {}
    $ctrl.practice = $rootScope.resolved.practice

    $ctrl.$onInit = function() {
      if ($ctrl.$transition$.params().id !== "new") {
        $ctrl.canDelete = $rootScope.resolved.CanDeleteReferralPractice
        $ctrl.canRetrieve = $rootScope.resolved.CanRetrieveReferralPractice
        $ctrl.canSave = $rootScope.resolved.CanUpdateReferralPractice
        $ctrl.obj = {id: $ctrl.$transition$.params().id}
      } else {
        $ctrl.canDelete = false
        $ctrl.canRetrieve = false
        $ctrl.canSave = $rootScope.resolved.CanCreateReferralPractice
        $ctrl.obj = {}
      }

      $ctrl.loading++
      ReferralPracticesService.metadata($ctrl.practice, $ctrl.obj.id).then(
        function(response) {
          $ctrl.error = null
          $ctrl.metadata = response.data
          $ctrl.retrieve()
        },
        function(response) {
          $ctrl.error = response
          $ctrl.metadata = null
        }
      ).finally(
        function() {
          $ctrl.loading--
        }
      )
    }

    $ctrl.retrieve = function(broadcast) {
      if ($ctrl.obj && $ctrl.obj.id) {
        $ctrl.loading++
        ReferralPracticesService.retrieve($ctrl.practice, $ctrl.obj.id).then(
          function(response) {
            $ctrl.error = null
            $ctrl.obj = response.data
          },
          function(response) {
            $ctrl.error = response
            $ctrl.obj = null
          }
        ).finally(
          function() {
            $ctrl.loading--
          }
        )
        if (broadcast) {
          $scope.$broadcast("referral-practice.retrieve")
        }
      }
    }

    $ctrl.save = function() {
      $ctrl.loading++
      ReferralPracticesService[$ctrl.obj.id ? "update" : "create"]($ctrl.practice, $ctrl.obj).then(
        function(response) {
          $ctrl.error = null
          if ($ctrl.obj.id) {
            angular.extend($ctrl.obj, response.data)
          } else {
            $state.go("^.practice-detail", {id:response.data.id})
          }
        },
        function(response) {
          $ctrl.error = response.data
        }
      ).finally(
        function() {
          $ctrl.loading--
        }
      )
      $scope.$broadcast("referral-practice.save")
    }

    $ctrl.destroy = function() {
      DestroyModal.open("ReferralPracticesService", $ctrl.practice, $ctrl.obj.id).then(
        function() {
          $state.go("^.practice-list")
        }
      )
    }

  }

})();

(function() {
  "use strict"

  angular.module("referrals.components")

  .component("referralsExternalPracticeList", {
    controller: Controller,
    templateUrl: "src/referrals/components/referral-practice-list.html",
  })

  function Controller(
    $rootScope,
    ReferralPracticesService
  ) {

    var $ctrl = this

    $ctrl.canCreate = $rootScope.resolved.CanCreateReferralPractice
    $ctrl.canRetrieve = $rootScope.resolved.CanRetrieveReferralPractice
    $ctrl.canUpdate = $rootScope.resolved.CanUpdateReferralPractice
    $ctrl.error = null
    $ctrl.loading = false
    $ctrl.objs = []
    $ctrl.orderBy = "name"
    $ctrl.orderByReverse = false

    $ctrl.$onInit = function() {
      $ctrl.load()
    }

    $ctrl.load = function() {
      $ctrl.loading = true
      ReferralPracticesService.list($rootScope.resolved.practice).then(
        function (response) {
          $ctrl.error = null
          $ctrl.objs = response.data
        },
        function (response) {
          $ctrl.error = response
          $ctrl.objs = null
        }
      ).finally(
        function() {
          $ctrl.loading = false
        }
      )
    }

    $ctrl.orderByChanged = function(prop) {
      if (angular.equals(prop, $ctrl.orderBy)) {
        $ctrl.orderByReverse = !$ctrl.orderByReverse
      }
      else {
        $ctrl.orderBy = prop
      }
    }

    $ctrl.orderByIs = function(prop) {
      return angular.equals(prop, $ctrl.orderBy)
    }
  }

})();

(function() {
  "use strict"

  angular.module("referrals.components")

  .component("referralsExternalUserDetail", {
    bindings: { $transition$: "<" },
    controller: Controller,
    templateUrl: "src/referrals/components/referral-user-detail.html",
  })

  function Controller(
    $rootScope,
    $scope,
    $state,
    DestroyModal,
    ReferralUsersService
  ) {

    var $ctrl = this

    $ctrl.error = null
    $ctrl.loading = 0
    $ctrl.metadata = {}
    $ctrl.practice = $rootScope.resolved.practice

    $ctrl.$onInit = function() {
      if ($ctrl.$transition$.params().id !== "new") {
        $ctrl.canDelete = $rootScope.resolved.CanDeleteReferralUser
        $ctrl.canRetrieve = $rootScope.resolved.CanRetrieveReferralUser
        $ctrl.canSave = $rootScope.resolved.CanUpdateReferralUser
        $ctrl.obj = {id: $ctrl.$transition$.params().id}
      } else {
        $ctrl.canDelete = false
        $ctrl.canRetrieve = false
        $ctrl.canSave = $rootScope.resolved.CanCreateReferralUser
        $ctrl.obj = {}
      }

      $ctrl.loading++
      ReferralUsersService.metadata($ctrl.practice, $ctrl.obj.id).then(
        function(response) {
          $ctrl.error = null
          $ctrl.metadata = response.data
          $ctrl.retrieve()
        },
        function(response) {
          $ctrl.error = response
          $ctrl.metadata = null
        }
      ).finally(
        function() {
          $ctrl.loading--
        }
      )
    }

    $ctrl.retrieve = function(broadcast) {
      if ($ctrl.obj && $ctrl.obj.id) {
        $ctrl.loading++
        ReferralUsersService.retrieve($ctrl.practice, $ctrl.obj.id).then(
          function(response) {
            $ctrl.error = null
            $ctrl.obj = response.data
          },
          function(response) {
            $ctrl.error = response
            $ctrl.obj = null
          }
        ).finally(
          function() {
            $ctrl.loading--
          }
        )
        if (broadcast) {
          $scope.$broadcast("referral-user.retrieve")
        }
      }
    }

    $ctrl.save = function() {
      $ctrl.loading++
      ReferralUsersService[$ctrl.obj.id ? "update" : "create"]($ctrl.practice, $ctrl.obj).then(
        function(response) {
          $ctrl.error = null
          if ($ctrl.obj.id) {
            angular.extend($ctrl.obj, response.data)
          } else {
            $state.go("^.user-detail", {id:response.data.id})
          }
        },
        function(response) {
          $ctrl.error = response.data
        }
      ).finally(
        function() {
          $ctrl.loading--
        }
      )
      $scope.$broadcast("referral-user.save")
    }

    $ctrl.destroy = function() {
      DestroyModal.open("ReferralUsersService", $ctrl.practice, $ctrl.obj.id).then(
        function() {
          $state.go("^.user-list")
        }
      )
    }

  }

})();

(function() {
  "use strict"

  angular.module("referrals.components")

  .component("referralsExternalUserList", {
    controller: Controller,
    templateUrl: "src/referrals/components/referral-user-list.html",
  })

  function Controller(
    $rootScope,
    NotificationService,
    ReferralUsersService
  ) {

    var $ctrl = this

    $ctrl.canCreate = $rootScope.resolved.CanCreateReferralUser
    $ctrl.canRetrieve = $rootScope.resolved.CanRetrieveReferralUser
    $ctrl.canUpdate = $rootScope.resolved.CanUpdateReferralUser
    $ctrl.error = null
    $ctrl.loading = false
    $ctrl.objs = []
    $ctrl.orderBy = ["last_name", "first_name"]
    $ctrl.orderByReverse = false

    $ctrl.$onInit = function() {
      $ctrl.load()
    }

    $ctrl.load = function() {
      $ctrl.loading = true
      ReferralUsersService.list($rootScope.resolved.practice).then(
        function (response) {
          $ctrl.error = null
          $ctrl.objs = response.data
        },
        function (response) {
          $ctrl.error = response
          $ctrl.objs = null
        }
      ).finally(
        function() {
          $ctrl.loading = false
        }
      )
    }

    $ctrl.orderByChanged = function(prop) {
      if (angular.equals(prop, $ctrl.orderBy)) {
        $ctrl.orderByReverse = !$ctrl.orderByReverse
      }
      else {
        $ctrl.orderBy = prop
      }
    }

    $ctrl.orderByIs = function(prop) {
      return angular.equals(prop, $ctrl.orderBy)
    }

    $ctrl.sendEmail = function(obj, kind) {
      $ctrl.loading = true
      ReferralUsersService.sendEmail($rootScope.resolved.practice, obj.id, kind).then(
        function() {
          NotificationService.success("Email sent")
        },
        function(response) {
          NotificationService.error("Email error", response)
        }
      ).finally(
        function() {
          $ctrl.loading = false
        }
      )
    }
  }

})();

(function () {
  "use strict"

  angular.module("referrals.components")

    .service("DestroyModal", function ($uibModal) {

      this.open = function (ServiceName, practice, id) {
        function Controller($injector) {
          var $ctrl = this

          $ctrl.Service = $injector.get(ServiceName)
          $ctrl.canDelete = false
          $ctrl.error = null
          $ctrl.loading = 0
          $ctrl.obj = {}

          $ctrl.$onInit = function () {
            $ctrl.loading++
            $ctrl.Service.dependants(practice, id).then(
              function (response) {
                $ctrl.obj = response.data
                $ctrl.canDelete = !$ctrl.hasProps($ctrl.obj.protected)
                $ctrl.error = null
                console.groupCollapsed(ServiceName)
                console.debug("[dependants]", JSON.stringify($ctrl.obj, null, 2))
                console.groupEnd()
              },
              function (response) {
                $ctrl.error = response
                $ctrl.obj = null
              }
            ).finally(
              function () {
                $ctrl.loading--
              }
            )
          }

          $ctrl.submit = function () {
            $ctrl.loading++
            $ctrl.Service.destroy(practice, id).then(
              function () {
                $ctrl.$close()
              },
              function (response) {
                $ctrl.error = response
              }
            ).finally(
              function () {
                $ctrl.loading--
              }
            )
          }

          $ctrl.hasProps = function (obj) {
            if (angular.isObject(obj)) {
              return Object.keys(obj).length !== 0
            }
            return false
          }
        }

        return $uibModal.open({
          bindToController: true,
          controllerAs: "$ctrl",
          templateUrl: "src/referrals/components/destroy-modal.html",
          controller: Controller,
        }).result
      }

    })

})();

(function() {
  "use strict"

  angular.module("referrals.components")

  .component("referralPracticeMembership", {
    bindings: {
      objId: "=",
    },
    controller: Controller,
    templateUrl: "src/referrals/components/referral-practice-membership.html",
  })

  function Controller(
    $rootScope,
    $scope,
    NotificationService,
    ReferralUserPracticeMembershipService,
    ReferralUsersService
  ) {

    var $ctrl = this

    $ctrl.canRetrieveReferralUser = $rootScope.resolved.CanRetrieveReferralUser
    $ctrl.canCreate = $rootScope.resolved.CanCreateReferralUserPracticeMembership
    $ctrl.canUpdate = $rootScope.resolved.CanUpdateReferralUserPracticeMembership
    $ctrl.canUpdateReferralUser = $rootScope.resolved.CanUpdateReferralUser

    $ctrl.errors = null
    $ctrl.loading = 0
    $ctrl.objs = []
    $ctrl.practice = $rootScope.resolved.practice
    $ctrl.specialties = {}

    $ctrl.$onInit = function() {
      $ctrl.loading++
      ReferralUsersService.metadata($ctrl.practice, $ctrl.objId).then(
        function(response) {
          $ctrl.error = null
          $ctrl.specialties = response.data.schema.specialty.choices.reduce(
            function(memo, item) {
              memo[item.value] = item.display
              return memo
            },
            {}
          )
          $ctrl.load()
        },
        function(response) {
          $ctrl.error = response
          $ctrl.specialties = null
        }
      ).finally(
        function() {
          $ctrl.loading--
        }
      )
    }

    $ctrl.canSave = function(obj) {
      if (obj.id) {
        return $ctrl.canUpdate
      }
      return $ctrl.canCreate
    }

    $ctrl.isDirty = function(obj) {
      return (
        Boolean(obj.can_access) !== Boolean(obj.$can_access)
        ||
        Boolean(obj.can_access_all) !== Boolean(obj.$can_access_all)
        ||
        Boolean(obj.can_create_referrals_on_behalf_of_others) !== Boolean(obj.$can_create_referrals_on_behalf_of_others)
      )
    }

    $ctrl.load = function() {
      $ctrl.loading += 1
      ReferralUserPracticeMembershipService.forPractice($ctrl.practice, $ctrl.objId).then(
        function(response) {
          $ctrl.errors = null
          $ctrl.objs = response.data.map(hydrate)
        },
        function(response) {
          $ctrl.errors = [NotificationService.parse(response)]
          $ctrl.objs = null
        }
      ).finally(
        function() {
          $ctrl.loading -= 1
        }
      )
    }

    $ctrl.save = function() {
      $ctrl.errors = null
      $ctrl.objs.forEach(function(obj) {
        if ($ctrl.isDirty(obj) && $ctrl.canSave(obj)) {
          $ctrl.loading += 1
          ReferralUserPracticeMembershipService[obj.id ? "update" : "create"]($ctrl.practice, {
            id: obj.id,
            referral_user: obj.referral_user_id,
            referral_practice: $ctrl.objId,
            can_access: obj.can_access || false,
            can_access_all: obj.can_access_all || false,
            can_create_referrals_on_behalf_of_others: obj.can_create_referrals_on_behalf_of_others || false,
          }).then(
            function(response) {
              angular.extend(obj, hydrate(response.data))
            },
            function(response) {
              if ($ctrl.errors === null) {
                $ctrl.errors = [NotificationService.parse(response)]
              } else {
                $ctrl.errors = $ctrl.errors.concat(NotificationService.parse(response))
              }
            }
          ).finally(
            function() {
              $ctrl.loading -= 1
            }
          )
        }
      })
    }

    $scope.$on("referral-practice.retrieve", function() {
      $ctrl.load()
    });

    $scope.$on("referral-practice.save", function() {
      $ctrl.save()
    });

    function hydrate(obj) {
      obj.$can_access = obj.can_access
      obj.$can_access_all = obj.can_access_all
      obj.$can_create_referrals_on_behalf_of_others = obj.can_create_referrals_on_behalf_of_others
      return obj
    }
  }

})();

(function() {
  "use strict"

  angular.module("referrals.components")

  .component("referralUserMembership", {
    bindings: {
      objId: "=",
    },
    controller: Controller,
    templateUrl: "src/referrals/components/referral-user-membership.html",
  })

  function Controller(
    $rootScope,
    $scope,
    NotificationService,
    ReferralUserPracticeMembershipService,
    ReferralPracticesService
  ) {

    var $ctrl = this

    $ctrl.canRetrieveReferralPractice = $rootScope.resolved.CanRetrieveReferralPractice
    $ctrl.canCreate = $rootScope.resolved.CanCreateReferralUserPracticeMembership
    $ctrl.canUpdate = $rootScope.resolved.CanUpdateReferralUserPracticeMembership
    $ctrl.canUpdateReferralPractice = $rootScope.resolved.CanUpdateReferralPractice

    $ctrl.errors = null
    $ctrl.loading = 0
    $ctrl.objs = []
    $ctrl.practice = $rootScope.resolved.practice
    $ctrl.types = {}

    $ctrl.$onInit = function() {
      $ctrl.loading++
      ReferralPracticesService.metadata($ctrl.practice, $ctrl.objId).then(
        function(response) {
          $ctrl.error = null
          $ctrl.types = response.data.schema.type.choices.reduce(
            function(memo, item) {
              memo[item.value] = item.display
              return memo
            },
            {}
          )
          $ctrl.load()
        },
        function(response) {
          $ctrl.error = response
          $ctrl.types = null
        }
      ).finally(
        function() {
          $ctrl.loading--
        }
      )
    }

    $ctrl.canSave = function(obj) {
      if (obj.id) {
        return $ctrl.canUpdate
      }
      return $ctrl.canCreate
    }

    $ctrl.isDirty = function(obj) {
      return (
        Boolean(obj.can_access) !== Boolean(obj.$can_access)
        ||
        Boolean(obj.can_access_all) !== Boolean(obj.$can_access_all)
        ||
        Boolean(obj.can_create_referrals_on_behalf_of_others) !== Boolean(obj.$can_create_referrals_on_behalf_of_others)
      )
    }

    $ctrl.load = function() {
      $ctrl.loading += 1
      ReferralUserPracticeMembershipService.forUser($ctrl.practice, $ctrl.objId).then(
        function(response) {
          $ctrl.errors = null
          $ctrl.objs = response.data.map(hydrate)
        },
        function(response) {
          $ctrl.errors = [NotificationService.parse(response)]
          $ctrl.objs = null
        }
      ).finally(
        function() {
          $ctrl.loading -= 1
        }
      )
    }

    $ctrl.save = function() {
      $ctrl.errors = null
      $ctrl.objs.forEach(function(obj) {
        if ($ctrl.isDirty(obj) && $ctrl.canSave(obj)) {
          $ctrl.loading += 1
          ReferralUserPracticeMembershipService[obj.id ? "update" : "create"]($ctrl.practice, {
            id: obj.id,
            referral_user: $ctrl.objId,
            referral_practice: obj.referral_practice_id,
            can_access: obj.can_access || false,
            can_access_all: obj.can_access_all || false,
            can_create_referrals_on_behalf_of_others: obj.can_create_referrals_on_behalf_of_others || false,
          }).then(
            function(response) {
              angular.extend(obj, hydrate(response.data))
            },
            function(response) {
              if ($ctrl.errors === null) {
                $ctrl.errors = [NotificationService.parse(response)]
              } else {
                $ctrl.errors = $ctrl.errors.concat(NotificationService.parse(response))
              }
            }
          ).finally(
            function() {
              $ctrl.loading -= 1
            }
          )
        }
      })
    }

    $scope.$on("referral-user.retrieve", function() {
      $ctrl.load()
    });

    $scope.$on("referral-user.save", function() {
      $ctrl.save()
    });

    function hydrate(obj) {
      obj.$can_access = obj.can_access
      obj.$can_access_all = obj.can_access_all
      obj.$can_create_referrals_on_behalf_of_others = obj.can_create_referrals_on_behalf_of_others
      return obj
    }
  }

})();

(function () {
  "use strict"

  angular.module("referrals.services")

    .service("ReferralUserPracticeMembershipService", function ($http) {

      this.create = function (practice, data) {
        return $http.post("/api/v3/practice/" + practice + "/~referrals/referral-user-practice-membership", data)
      }

      this.update = function (practice, data) {
        return $http.put("/api/v3/practice/" + practice + "/~referrals/referral-user-practice-membership/" + data.id, data)
      }

      this.forPractice = function (practice, referral_practice) {
        return $http.get("/api/v3/practice/" + practice + "/~referrals/referral-user-practice-membership/referral-practice/" + referral_practice)
      }

      this.forUser = function (practice, referral_user) {
        return $http.get("/api/v3/practice/" + practice + "/~referrals/referral-user-practice-membership/referral-user/" + referral_user)
      }

    })

})();

(function() {
"use strict"

  angular.module("referrals.components")

  .component("report", {
    bindings: { $transition$: "<" },
    controller: Controller,
    templateUrl: "src/referrals/components/report.html",
  })

  .directive("tragula", Tragula)

  .directive("tragulaStickWidth", TragulaStickWidth)

  function Controller(
    $document,
    $localStorage,
    $q,
    $rootScope,
    $scope,
    $state,
    $uibModal,
    AuthenticationService,
    NotificationService,
    PatientFileService,
    PatientImageService,
    PrincipalService,
    ReferralsService,
    ReportService,
    ReportFileService,
    ReportTemplateService,
    dragulaService
  ) {

    var $ctrl = this

    $ctrl.CanCreateReport = $rootScope.resolved.CanCreateReport
    $ctrl.CanCreateReportTemplate = $rootScope.resolved.CanCreateReportTemplate
    $ctrl.CanDeleteReportTemplate = $rootScope.resolved.CanDeleteReportTemplate
    $ctrl.CanListPatientImage = $rootScope.resolved.CanListPatientImage
    $ctrl.CanListReportTemplate = $rootScope.resolved.CanListReportTemplate
    $ctrl.CanListTreatment = $rootScope.resolved.CanListTreatment
    $ctrl.CanRetrieveEncounter = $rootScope.resolved.CanRetrieveEncounter
    $ctrl.CanRetrievePatientFile = $rootScope.resolved.CanRetrievePatientFile
    $ctrl.CanRetrievePatientImage = $rootScope.resolved.CanRetrievePatientImage
    $ctrl.CanRetrieveReportTemplate = $rootScope.resolved.CanRetrieveReportTemplate
    $ctrl.CanUpdateReport = $rootScope.resolved.CanUpdateReport
    $ctrl.activeTabs = [true, false, true, false, true, false]
    $ctrl.meta = $rootScope.resolved.meta
    $ctrl.ReportFileService = ReportFileService
    $ctrl.uniqId = Date.now().toString(36)

    $ctrl.$onInit = function() {
      $ctrl.reportId = $ctrl.$transition$.params().reportId !== "new" ? +$ctrl.$transition$.params().reportId : null
      $ctrl.CanChangeReport = $ctrl.reportId ? $ctrl.CanUpdateReport : $ctrl.CanCreateReport
      $ctrl.reportArchiveParamsCreate = $ctrl.reportId ? {report: $ctrl.reportId} : null
      $ctrl.loading = true
      var requests$ = []
      if ($ctrl.$transition$.params().initial !== null) {
        requests$.push($ctrl.$transition$.params().initial.templates)
        requests$.push($ctrl.$transition$.params().initial.providers)
        requests$.push($ctrl.$transition$.params().initial.report)
        requests$.push($ctrl.$transition$.params().initial.patientFilters)
        requests$.push($ctrl.$transition$.params().initial.treatments)
        requests$.push($ctrl.$transition$.params().initial.images)
        requests$.push($ctrl.$transition$.params().initial.files)
      }
      else {
        // templates
        requests$.push(
          $ctrl.CanListReportTemplate ?
          ReportTemplateService.list({provider__practice:PrincipalService.getPracticeId()}).then(
            function(response) {
              return response.data
            },
            function(response) {
              NotificationService.error("Error Loading Templates", response)
              return []
            }
          )
          : []
        )
        // providers
        requests$.push(
          AuthenticationService.getProviderAndAssociatesAccounts(PrincipalService.getPracticeId()).then(
            function(response) {
              return response.data
            },
            function(response) {
              NotificationService.error("Error Loading Providers", response)
              return []
            }
          )
        )
        // report
        var report$
        if ($ctrl.reportId) {
          report$ = ReportService.retrieve($ctrl.reportId).then(
            function(response) {
              return response.data
            },
            function(response) {
              NotificationService.error("Error Loading Report", response)
              return {
                report_files: [],
                report_images: [],
                report_treatments: [],
                snapshot: {referral: {}},
              }
            }
          )
        }
        else {
          report$ = ReferralsService.snapshot($ctrl.$transition$.params().id).then(
            function(response) {
              return {
                report_files: [],
                report_images: [],
                report_treatments: [],
                snapshot: {referral: response.data},
              }
            },
            function(response) {
              NotificationService.error("Error Loading Referral Snapshot", response)
              return {
                report_files: [],
                report_images: [],
                report_treatments: [],
                snapshot: {referral: {}},
              }
            }
          )
        }
        requests$.push(report$)
        // ext_user
        requests$.push(
          ReferralsService.retrieve_related($ctrl.$transition$.params().id).then(
            function(response) {
              return response.data.ext_user
            },
            function(response) {
              NotificationService.error("Error Loading Additional Info", response)
              return {}
            }
          )
        )
        // patientFilters
        var patientFilters$
        if ($ctrl.reportId !== null) {
          patientFilters$ = {"report": $ctrl.reportId}
        }
        else {
          patientFilters$ = report$.then(
            function(report) {
              return {"referral": report.snapshot.referral.id}
            }
          )
        }
        requests$.push(patientFilters$)
        // treatments
        requests$.push(
          $q.when(patientFilters$).then(
            function(patientFilters) {
              return ReportService.treatments(patientFilters).then(
                function(response) {
                  return response.data
                },
                function(response) {
                  NotificationService.error("Error Loading Treatments", response)
                  return []
                }
              )
            }
          )
        )
        // images
        requests$.push(null)
        // files
        requests$.push(
          $q.when(patientFilters$).then(
            function(patientFilters) {
              return ReportService.files(patientFilters).then(
                function(response) {
                  return response.data
                },
                function(response) {
                  NotificationService.error("Error Loading Files", response)
                  return []
                }
              )
            }
          )
        )
      }
      $q.all(requests$).spread(
        function(templates, providers, report, ext_user, patientFilters, treatments, images, files) {
          $ctrl.templates = templates
          $ctrl.providers = providers
          $ctrl.report = report
          $ctrl.ext_user = ext_user
          $ctrl.patientFilters = patientFilters
          $ctrl.treatments = treatments
          $ctrl.images = images
          $ctrl.files = files
          $ctrl.loading = false
        },
        function() {
          $ctrl.loading = false
        }
      )

      dragulaService.options($scope, "report-i", {
        moves: function(el, source, handle) {
          return angular.element(handle).hasClass("grabbable")
        },
      })
      $scope.$on("report-t.over", function() {
        $document.find("body").addClass("grabbing")
      })
      $scope.$on("report-t.out", function() {
        $document.find("body").removeClass("grabbing")
      })
      $scope.$on("report-i.over", function() {
        $document.find("body").addClass("grabbing")
      })
      $scope.$on("report-i.out", function() {
        $document.find("body").removeClass("grabbing")
      })
      $scope.$on("report-f.over", function() {
        $document.find("body").addClass("grabbing")
      })
      $scope.$on("report-f.out", function() {
        $document.find("body").removeClass("grabbing")
      })
    }

    $ctrl.filterRemoved = function(value) {
      return !value.hasOwnProperty("removed")
    }

    $ctrl.loadReportImages = function() {
      if (!$ctrl.images) {
        ReportService.images($ctrl.patientFilters).then(
          function(response) {
            $ctrl.images = response.data
          },
          function(response) {
            NotificationService.error("Error Loading Images", response)
            $ctrl.images = []
          }
        )
      }
    }

    $ctrl.onBack = function() {
      var noAskAgain = $localStorage["referrals-detail-report-detail-back-navigation--noAskAgain"]
      if (noAskAgain) {
        return $state.go("^", {}, {reload: true})
      }
      $uibModal.open({
        bindToController: true,
        controller: function($scope) {
          var $mctrl = this
          $mctrl.formData = {
            noAskAgain: false,
          }
          $mctrl.close = function() {
            if ($mctrl.formData.noAskAgain) {
              $localStorage["referrals-detail-report-detail-back-navigation--noAskAgain"] = true
            }
            $scope.$close()
          }
        },
        controllerAs: "$ctrl",
        templateUrl: "#referrals-detail-report-detail-back-navigation",
      }).result.then(
        function() {
          $state.go("^", {}, {reload: true})
        }
      )
    }

    $ctrl.onFileChange = function(form, obj) {
      form.$setDirty()
      var idx = $ctrl.report.report_files.findIndex(function(rf) { return rf.patient_file.id === obj.id })
      if (obj.selected) {
        if (idx !== -1) {
          delete $ctrl.report.report_files[idx].removed
        }
        else {
          $ctrl.report.report_files.push({
            name: obj.name,
            patient_file: _.omit(obj, "selected"),
          })
        }
      }
      else {
        if ($ctrl.report.report_files[idx].hasOwnProperty("id")) {
          $ctrl.report.report_files[idx].removed = true
        }
        else {
          $ctrl.report.report_files.splice(idx, 1)
        }
      }
    }

    $ctrl.onFileDownload = function(obj, name) {
      obj.$loading = true
      PatientFileService.download(obj).then(
        function(blob) {
          var link = angular.element("#report-archive-" + $ctrl.uniqId + "-dwl")
          link.attr("href", URL.createObjectURL(blob))
          link.attr("download", (name || obj.name) + "." + mime.getExtension(blob.type))
          link[0].click()
          delete obj.$loading
        },
        function(response) {
          NotificationService.error("Error Downloading Patient File", response)
          delete obj.$loading
        }
      )
    }

    $ctrl.onFileView = function(obj, name) {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        templateUrl: "#report-archive-open",
        resolve: {
          obj: function() {
            return PatientFileService.retrieve(obj).then(
              function(response) {
                return response.data
              },
              function(response) {
                NotificationService.error("Error Retrieving Patient File", response)
              }
            )
          }
        },
        controller: function($scope, obj) {
          var $mctrl = this
          $mctrl.obj = obj
          $mctrl.name = name
          $mctrl.download = function() {
            $mctrl.loading = true
            PatientFileService.download(obj).then(
              function(blob) {
                var link = angular.element("#report-archive-" + $ctrl.uniqId + "-dwl")
                link.attr("href", URL.createObjectURL(blob))
                link.attr("download", (name || obj.name) + "." + mime.getExtension(blob.type))
                link[0].click()
                $mctrl.loading = false
              },
              function(response) {
                NotificationService.error("Error Downloading Patient File", response)
                $mctrl.loading = false
              }
            )
          }
        }
      }).result.then(
        function(response) {
          angular.extend(obj, response.data)
        }
      )
    }

    $ctrl.onImageChange = function(form, patient_image) {
      form.$setDirty()
      var idx = $ctrl.report.report_images.findIndex(function(ri) {
        return ri.patient_image.id === patient_image.id
      })
      if (patient_image.selected) {
        if (idx !== -1) {
          delete $ctrl.report.report_images[idx].removed
        }
        else {
          $ctrl.report.report_images.push({
            name: patient_image.name,
            patient_image: _.omit(patient_image, "selected"),
          })
        }
      }
      else {
        if ($ctrl.report.report_images[idx].hasOwnProperty("id")) {
          $ctrl.report.report_images[idx].removed = true
        }
        else {
          $ctrl.report.report_images.splice(idx, 1)
        }
      }
    }

    $ctrl.onImageDownload = function(ct, id, name) {
      ct.$loading = true
      PatientImageService.download(id).then(
        function(blob) {
          var link = angular.element("#patient-image-dwl")
          if (link.attr("href").startsWith("blob")) {
            URL.revokeObjectURL(link.attr("href"))
          }
          link.attr("href", URL.createObjectURL(blob))
          link.attr("download", name)
          link[0].click()
          delete ct.$loading
        },
        function(response) {
          NotificationService.error("Error Downloading Patient Image", response)
          delete ct.$loading
        }
      )
    }

    $ctrl.onImageView = function(id, name) {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        size: "lg",
        templateUrl: "#referrals-detail-report-detail-view",
        controller: function() {
          var $mctrl = this
          $mctrl.name = name
          PatientImageService.retrieve(id).then(
            function(response) {
              $mctrl.thumb = response.data.image_archive_thumb
            },
            function(response) {
              NotificationService.error("Error Retrieving Patient Image", response)
            }
          )
          $mctrl.onImageDownload = function() {
            $ctrl.onImageDownload($mctrl, id, name)
          }
        }
      })
    }

    $ctrl.onSave = function() {
      $ctrl.loading = true
      return save($ctrl.report).then(
        function(response) {
          $ctrl.loading = false
          $ctrl.activeTabs = [true, false, true, false, true, false]
          angular.extend($ctrl.report, response.data)
          if ($ctrl.reportId) {
            NotificationService.success("Successfully Saved Report")
          }
          else {
            $state.go("^.detail", {reportId: $ctrl.report.id, initial: {
              templates: $ctrl.treatments,
              providers: $ctrl.providers,
              report: $ctrl.report,
              patientFilters: $ctrl.patientFilters,
              treatments: $ctrl.treatments,
              images: $ctrl.images,
              files: $ctrl.files,
            }})
            NotificationService.success("Successfully Created Report")
          }
        },
        function(response) {
          $ctrl.loading = false
          NotificationService.error("Error Saving Report", response)
        }
      )
    }

    $ctrl.onSaveAndSendEmailToReferrer = function() {
      if ($ctrl.report.sent_at) {
        $ctrl.loading = true
        ReportService.publishToReferrer(PrincipalService.getPracticeId(), $ctrl.report.id).then(
          function(response) {
            $ctrl.loading = false
            $ctrl.report.sent_at = response.data.sent_at
            NotificationService.success("Email Sent to " + response.data.email)
          },
          function(response) {
            $ctrl.loading = false
            NotificationService.error("Error Sending Email", response)
          }
        )
      }
      else {
        $uibModal.open({
          bindToController: true,
          controller: function(noTreatments) {
            var $mctrl = this
            $mctrl.formData = {
              noTreatments: noTreatments ? false : null
            }
          },
          controllerAs: "$ctrl",
          resolve: {
            noTreatments: function() {
              return $ctrl.report.report_treatments.length === 0
            }
          },
          templateUrl: "#referrals-detail-report-detail-publish",
        }).result.then(
          function() {
            $ctrl.loading = true
            save($ctrl.report).then(
              function(response) {
                $ctrl.activeTabs = [true, false, true, false, true, false]
                NotificationService.success("Successfully Saved Report")
                angular.extend($ctrl.report, response.data)
                ReportService.publishToReferrer(PrincipalService.getPracticeId(), $ctrl.report.id).then(
                  function(response2) {
                    $ctrl.loading = false
                    $ctrl.report.sent_at = response2.data.sent_at
                    if (!$ctrl.reportId) {
                      $state.go("^.detail", {reportId: $ctrl.report.id, initial: {
                        templates: $ctrl.treatments,
                        providers: $ctrl.providers,
                        report: $ctrl.report,
                        treatments: $ctrl.treatments,
                        images: $ctrl.images,
                        files: $ctrl.files,
                      }})
                    }
                    NotificationService.success("Email Sent to " + response2.data.email)
                  },
                  function(response2) {
                    $ctrl.loading = false
                    if (!$ctrl.reportId) {
                      $state.go("^.detail", {reportId: $ctrl.report.id, initial: {
                        templates: $ctrl.treatments,
                        providers: $ctrl.providers,
                        report: $ctrl.report,
                        treatments: $ctrl.treatments,
                        images: $ctrl.images,
                        files: $ctrl.files,
                      }})
                    }
                    NotificationService.error("Error Sending Email", response2)
                  }
                )
              },
              function(response) {
                $ctrl.loading = false
                NotificationService.error("Error Saving Report", response)
              }
            )
          }
        )
      }
    }

    $ctrl.onTemplateCreate = function(report) {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        size: "md",
        templateUrl: "#referrals-detail-report-detail-create-template",
        controller: function($scope, PrincipalService) {
          var $mctrl = this
          $mctrl.template = {
            provider: PrincipalService.getAccountId(),
            data: {
              summary: report.summary,
              description: report.description,
              close_message: report.close_message,
            }
          }
          $mctrl.create = function() {
            ReportTemplateService.create($mctrl.template).then(
              function(response) {
                $scope.$close(response.data)
              },
              function(response) {
                NotificationService.error("Error Creating Report Template", response)
              }
            )
          }
        }
      }).result.then(
        function(template) {
          $ctrl.template = template
          $ctrl.templates.push(template)
          $ctrl.onTemplateSelect(template)
        }
      )
    }

    $ctrl.onTemplateDestroy = function(id) {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        size: "md",
        templateUrl: "#referrals-detail-report-detail-delete-template",
        controller: function($scope) {
          var $mctrl = this
          $mctrl.delete = function() {
            ReportTemplateService.destroy(id).then(
              function() {
                $scope.$close(id)
              },
              function(response) {
                NotificationService.error("Error Deleting Template", response)
              }
            )
          }
        }
      }).result.then(
        function(id) {
          for (var i = 0; i < $ctrl.templates.length; i++) {
            if ($ctrl.templates[i].id === id) {
              $ctrl.templates.splice(i,1)
              break
            }
          }
          $ctrl.template = null
        }
      )
    }

    $ctrl.onTemplateSelect = function(value) {
      if (value && value.data) {
        angular.extend($ctrl.report, value.data)
      }
      $ctrl.disableTemplateEditDelete = (value && value.provider && (value.provider !== PrincipalService.getAccountId())) ? true : false;
    }

    $ctrl.onTemplateUpdate = function(template, report) {
      $ctrl.loading = true
      var data = _.pick(report, "summary", "description", "close_message")
      ReportTemplateService.partial_update(template.id, {data: data}).then(
        function(response) {
          $ctrl.loading = false
          angular.extend(template, response.data)
        },
        function(response) {
          $ctrl.loading = false
          NotificationService.error("Error Updating Report Template", response)
        }
      )
    }

    $ctrl.onTreatmentChange = function(form, treatment) {
      form.$setDirty()
      var idx = $ctrl.report.report_treatments.findIndex(function(rt) {
        return rt.treatment.id === treatment.id
      })
      if (treatment.selected) {
        if (idx !== -1) {
          delete $ctrl.report.report_treatments[idx].removed
        }
        else {
          $ctrl.report.report_treatments.push({
            treatment: _.omit(treatment, "selected"),
          })
        }
      }
      else {
        if ($ctrl.report.report_treatments[idx].hasOwnProperty("id")) {
          $ctrl.report.report_treatments[idx].removed = true
        }
        else {
          $ctrl.report.report_treatments.splice(idx, 1)
        }
      }
    }

    function save(report) {
      var deferred = $q.defer();
      var action = "partial_update"
      var report_ = angular.extend({}, report)
      if (!report_.id) {
        action = "create"
        angular.extend(report_, {referral: report.snapshot.referral.id})
      }
      delete report_.snapshot
      ReportService[action](report_).then(
        function(response) {
          deferred.resolve(response)
        },
        function(response) {
          deferred.reject(response)
        }
      )
      return deferred.promise
    }

  }

  function Tragula($window, dragulaService) {
    return {
      restrict: "A",
      scope: {
        dragulaScope: "=",
        dragulaModel: "="
      },
      link: function(scope, elem, attrs) {
        var dragulaScope = scope.dragulaScope || scope.$parent
        var container = elem[0]
        var name = scope.$eval(attrs.tragula)
        var drake
        var bag = dragulaService.find(dragulaScope, name)
        if (bag) {
          drake = bag.drake
          drake.containers.push(container)
        }
        else {
          drake = $window.dragula({
            containers: [container],
            mirrorContainer: container,
            moves: function(el, source, handle) {
              return angular.element(handle).hasClass("grabbable")
            },
          })
          dragulaService.add(dragulaScope, name, drake)
        }
        scope.$watch('dragulaModel', function (newValue, oldValue) {
          if (!newValue) {
            return
          }
          if (drake.models) {
            var modelIndex = oldValue ? drake.models.indexOf(oldValue) : -1
            if (modelIndex >= 0) {
              drake.models.splice(modelIndex, 1, newValue)
            }
            else {
              drake.models.push(newValue)
            }
          }
          else {
            drake.models = [newValue]
          }
          dragulaService.handleModels(dragulaScope, drake)
        })
      }
    }
  }

  function TragulaStickWidth() {
    return {
      restrict: "A",
      scope: {
        update: '=',
      },
      controller: function($scope, $element, $timeout) {
        $scope.$watch('update', function(value) {
          if (value) {
            $timeout(function(){
              $element.css("width", $element.css("width"))
            })
          }
        }, true)
      },
    }
  }

})();

(function() {
"use strict"

  angular.module("referrals.components")

  .component("reportPDF", {
    bindings: { $transition$: "<" },
    controller: Controller,
    templateUrl: "src/referrals/components/report-pdf.html",
  })

  function Controller(
    NotificationService,
    PDFMakeService,
    ReportService
  ) {

    var $ctrl = this

    $ctrl.pdf = null
    $ctrl.report = null
    $ctrl.url = null

    $ctrl.$onInit = function() {
      ReportService.retrieve($ctrl.$transition$.params().reportId).then(
        function(response) {
          $ctrl.report = response.data
          $ctrl.pdf = PDFMakeService.getInstanceForReport()
          $ctrl.pdf.build($ctrl.report).then(
            function() {
              $ctrl.pdf.getUrl().then(
                function(url) {
                  $ctrl.url = url
                }
              )
            }
          )
        },
        function(response) {
          NotificationService.error("Error Loading Report", response)
        }
      )
    }
  }

})();

(function() {
  "use strict"

  angular.module("referrals.components")

  .component("reportsList", {
    bindings: { $transition$: "<" },
    controller: Controller,
    templateUrl: "src/referrals/components/reports.html",
  })

  function Controller(
    $rootScope,
    NotificationService,
    PrincipalService,
    ReferralsService,
    ReportService
  ) {

    var $ctrl = this

    $ctrl.CanCreateReport = $rootScope.resolved.CanCreateReport
    $ctrl.CanRetrieveReport = $rootScope.resolved.CanRetrieveReport
    $ctrl.CanUpdateReport = $rootScope.resolved.CanUpdateReport
    $ctrl.meta = $rootScope.resolved.meta

    $ctrl.loading = false
    $ctrl.reports = []

    $ctrl.$onInit = function() {
      $ctrl.loading = true
      ReportService.list({referral:$ctrl.$transition$.params().id}).then(
        function(response) {
          $ctrl.loading = false
          $ctrl.reports = response.data
        },
        function(response) {
          $ctrl.loading = false
          NotificationService.error("Error Loding Reports", response)
        }
      )
      ReferralsService.retrieve_related($ctrl.$transition$.params().id).then(
        function(response) {
          $ctrl.loading = false
          $ctrl.referral = response.data
        },
        function(response) {
          $ctrl.loading = false
          NotificationService.error("Error Loading Referrals", response)
        }
      )
    }

    $ctrl.publishToReferrer = function(report) {
      $ctrl.loading = true
      ReportService.publishToReferrer(PrincipalService.getPracticeId(), report.id).then(
        function(response) {
          $ctrl.loading = false
          report.sent_at = response.data.sent_at
          NotificationService.success("Sent to " + response.data.email)
        },
        function(response) {
          $ctrl.loading = false
          NotificationService.error("Error Sending Email", response)
        }
      )
    }

  }

})();

(function() {
  "use strict"

  angular.module("referrals.components")

  .component("referralsDetail", {
    bindings: { $transition$: "<" },
    controller: Controller,
    templateUrl: "src/referrals/components/referral.html",
  })

  function Controller(
    $document,
    $filter,
    $q,
    $rootScope,
    $state,
    $uibModal,
    NotificationService,
    PatientService,
    PatientsService,
    PrincipalService,
    ReferralsService
  ) {

    var $ctrl = this

    $ctrl.CanCreatePatient = $rootScope.resolved.CanCreatePatient
    $ctrl.CanCreateReferral = $rootScope.resolved.CanCreateReferral
    $ctrl.CanDeleteReferral = $rootScope.resolved.CanDeleteReferral
    $ctrl.CanDeleteReport = $rootScope.resolved.CanDeleteReport
    $ctrl.CanListPatient = $rootScope.resolved.CanListPatient
    $ctrl.CanListReport = $rootScope.resolved.CanListReport
    $ctrl.CanUpdatePatient = $rootScope.resolved.CanUpdatePatient

    $ctrl.meta = $rootScope.resolved.meta

    $ctrl.loading = false
    $ctrl.patient = null
    $ctrl.patients = []
    $ctrl.practice = {}
    $ctrl.practices = []

    $ctrl.user = null
    $ctrl.users = []

    $ctrl.$onInit = function() {
      $ctrl.CanSaveReferral = $ctrl.$transition$.params().id !== "new" ? $rootScope.resolved.CanUpdateReferral : $rootScope.resolved.CanCreateReferral
      $ctrl.referral = $ctrl.$transition$.params().patientid ? {patient: $ctrl.$transition$.params().patientid} : {}
      $ctrl.referral.date = moment().format("YYYY-MM-DD")

      $ctrl.loading = true
      $q.all([
        PatientService.v3.list(),
        ReferralsService.practices($rootScope.resolved.referralsFilters),
        ReferralsService.users($rootScope.resolved.referralsFilters)
      ]).spread(
        function(patients, practices, users) {
          $ctrl.patients = $filter("orderBy")(patients.data, ["1", "2", "3"])
          $ctrl.practices = practices.data
          $ctrl.users = users.data
          if ($ctrl.$transition$.params().id !== "new") {
            ReferralsService.retrieve($ctrl.$transition$.params().id).then(
              function(response) {
                $ctrl.loading = false
                $ctrl.referral = response.data
                $ctrl.onSelectPractice($ctrl.referral.ext_practice)
                $ctrl.onSelectUser($ctrl.referral.ext_user)
                $ctrl.onSelectPatient($ctrl.referral.patient)
                if ($ctrl.$transition$.params().patient_id) {
                  $ctrl.referral.patient = $ctrl.$transition$.params().patient_id
                  if ($ctrl.$transition$.params().ext_patient) {
                    angular.extend($ctrl.referral.ext_patient, $ctrl.$transition$.params().ext_patient)
                    $ctrl.referral.ext_patient.created = $ctrl.$transition$.params().patient_id
                  }
                  ReferralsService.partial_update($ctrl.referral).then(
                    function(response2) {
                      angular.extend($ctrl.referral, response2.data)
                      $ctrl.onSelectPatient($ctrl.referral.patient)
                      NotificationService.success("Successfully Saved Referral with Patient")
                    },
                    function(response) {
                      NotificationService.error("Error Saving Referral", response)
                    }
                  )
                }
                if ($ctrl.referral.patient) {
                  var idx = $ctrl.patients.findIndex(function(p) { return p[0] === $ctrl.referral.patient })
                  if (idx !== -1) {
                    $ctrl.patients.unshift($ctrl.patients.splice(idx, 1)[0])
                  }
                }
              },
              function(response) {
                $ctrl.loading = false
                if (response.status === 404) {
                  $state.go("^", {}, {reload:true})
                }
                NotificationService.error("Error Loading Referral", response)
              }
            )
          }
          else {
            $ctrl.loading = false
            if ($ctrl.$transition$.params().patient_id) {
              $ctrl.referral.patient = $ctrl.$transition$.params().patient_id
              if ($ctrl.$transition$.params().ext_patient) {
                $ctrl.referral.ext_patient = $ctrl.$transition$.params().ext_patient
                $ctrl.referral.ext_patient.created = $ctrl.$transition$.params().patient_id
              }
              NotificationService.success("Loaded Patient into Referral, but not saved")
            }
            $ctrl.onSelectPractice($ctrl.referral.ext_practice)
            $ctrl.onSelectUser($ctrl.referral.ext_user)
            $ctrl.onSelectPatient($ctrl.referral.patient)
          }
        },
        function(response) {
          $ctrl.loading = false
          NotificationService.error("Error Loading Referral Data", response)
        }
      )
    }

    $ctrl.createPatient = function() {
      var modalInstance = $uibModal.open({
        animation: $ctrl.animationsEnabled,
        size:'lg',
        templateUrl: 'src/common/patient-add.tpl.html',
        controller: 'PatientAddCtrl',
        resolve: {
          openPatientDetail: null,
          showGoToPatientDetail: function () {
            return false; //this tells the patientadd dialog to disable the 'Go to patient detail after add' checkbox.
          },
          patientInfo: function () {
            var ext_patient = Object.entries($ctrl.referral.ext_patient).filter(function(e) {
              return e[1] !== null
            }).reduce(function (accum, e) {
              accum[e[0]] = e[1]
              return accum
            }, {})
            return PatientsService.allSnakecaseToCamelcase(ext_patient);
          }
        }
      });

      modalInstance.result.then(function (patient) {
        $ctrl.referral.patient = patient.id;
        $ctrl.referral.ext_patient.created = patient.id;
        $ctrl.patients.push(patient);
        $ctrl.onSelectPatient($ctrl.referral.patient);
      }, function () {
      });
    };

    $ctrl.onDownloadImage = function(image) {
      ReferralsService.imagePresignedUrl(this.referral.id, {images: [image.id]}).then(
        function(response) {
          angular.forEach(response.data.urls, function(url) {
            var link = angular.element("<a/>")
            link.attr("href", url)
            link.attr("target", "_blank")
            link.attr("download", "")
            $document[0].body.appendChild(link[0])
            link[0].click()
            $document[0].body.removeChild(link[0])
          })
        },
        function(error) {
          console.log("error", error)
        }
      )
    }

    $ctrl.onPropChange = function(obj, prop) {
      if (obj[prop] === "") {
        delete obj[prop]
      }
    }

    $ctrl.onViewImage = function(image) {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        templateUrl: "#referral-image-view",
        controller: function() {
          var $mctrl = this
          $mctrl.image = image
          $mctrl.onDownloadImage = function() {
            ReferralsService.imagePresignedUrl($ctrl.referral.id, {images: [image.id]}).then(
              function(response) {
                angular.forEach(response.data.urls, function(url) {
                  var link = angular.element("<a/>")
                  link.attr("href", url)
                  link.attr("target", "_blank")
                  link.attr("download", "")
                  $document[0].body.appendChild(link[0])
                  link[0].click()
                  $document[0].body.removeChild(link[0])
                })
              },
              function(error) {
                console.log("error", error)
              }
            )
          }
        }
      })
    }

    $ctrl.onSelectPatient = function(value, rform) {
      if (value) {
        $ctrl.patient = _.findWhere($ctrl.patients, {id: value})
      }
      else {
        $ctrl.patient = null
      }
      if (rform) {
        if (rform.$valid && $ctrl.referral.id) {
          $ctrl.save($ctrl.referral)
        }
      }
    }

    $ctrl.onSelectPractice = function(value) {
      if (value) {
        $ctrl.practice = _.findWhere($ctrl.practices, {id: value})
        $ctrl.referral_users = $ctrl.practice.referral_users
      }
      else {
        $ctrl.practice = null
        $ctrl.referral_users = $ctrl.users
      }
    }

    $ctrl.onSelectUser = function(value) {
      if (value) {
        $ctrl.user = _.findWhere($ctrl.users, {id: value})
        $ctrl.referral_practices = $ctrl.user.referral_practices
      }
      else {
        $ctrl.user = null
        $ctrl.referral_practices = $ctrl.practices
      }
    }

    $ctrl.updatePatient = function() {
      PatientService.update($ctrl.patient).then(
        function(response) {
          angular.extend($ctrl.patient, response.data)
          NotificationService.success("Successfully Updated Patient")
        },
        function(response) {
          NotificationService.error("Error Updating Patient", response)
        }
      )
    }

    $ctrl.save = function(referral) {
      if ($ctrl.CanSaveReferral) {
        referral = angular.extend({}, referral, {})
        if (!referral.id) {
          angular.extend(referral, {
            practice: PrincipalService.getPracticeId(),
          })
        }
        ReferralsService[referral.id ? "partial_update" : "create"](referral).then(
          function(response) {
            if (referral.id) {
              angular.extend(referral, response.data)
            }
            else {
              $state.go("^.detail", {id:response.data.id}, {reload:true})
            }
            NotificationService.success("Successfully Saved Referral")
          },
          function(response) {
            NotificationService.error("Error Saving Referral", response)
          }
        )
      }
    }

    $ctrl.destroy = function(referral) {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        size: "md",
        templateUrl: "#referral-destroy",
      }).result.then(
        function() {
          ReferralsService.destroy(referral.id).then(
            function() {
              $state.go("^", {}, {reload:true})
              NotificationService.success("Successfully Deleted Referral")
            },
            function(response) {
              NotificationService.error("Error Deleting Referral", response)
            }
          )
        }
      )
    }

  }

})();

(function() {
  "use strict"

  angular.module("referrals.components")

  .component("referralsList", {
    controller: Controller,
    templateUrl: "src/referrals/components/referrals.html",
  })

  function Controller(
    $rootScope,
    NotificationService,
    PatientService,
    ReferralsService
  ) {

    var $ctrl = this

    $ctrl.CanCreateReferral = $rootScope.resolved.CanCreateReferral
    $ctrl.CanListReport = $rootScope.resolved.CanListReport
    $ctrl.CanUpdateReferral = $rootScope.resolved.CanUpdateReferral
    $ctrl.meta = $rootScope.resolved.meta
    $ctrl.loading = false
    $ctrl.patientsMap = null
    $ctrl.period = [3, "month"]
    $ctrl.uncompleted = true
    $ctrl.referrals = []
    $ctrl.searchTerm = ""
    $ctrl.orderByProp = "date"
    $ctrl.orderByReverse = true

    Object.defineProperties($ctrl, {
      filters: {
        get: function() {
          return angular.extend(
            {},
            $rootScope.resolved.referralsFilters,
            {
              date__range: $ctrl.period ?
                [
                  moment().startOf($ctrl.period[1]).subtract($ctrl.period[0] - 1, $ctrl.period[1]).format("YYYY-MM-DD"),
                  moment().endOf($ctrl.period[1]).format("YYYY-MM-DD")
                ].join(",") : undefined,
              is_completed: $ctrl.uncompleted ? false : undefined,
            }
          )
        }
      },
    })

    $ctrl.$onInit = function() {
      $ctrl.loadReferrals()
      PatientService.v3.list().then(
        function(response) {
          $ctrl.patientsMap = response.data.reduce(
            function(memo, obj) {
              memo.set(obj[0], obj[1])
              return memo
            },
            new Map()
          )
          if ($ctrl.referrals) {
            $ctrl.referrals.forEach(function(r) {
              if (r.patient_id) {
                r.patient_name = $ctrl.patientsMap.get(r.patient_id)
              }
            })
          }
        },
        function(response) {
          NotificationService.error("Error Loading Patients Name", response)
        }
      )
    }

    $ctrl.loadReferrals = function() {
      $ctrl.loading = true
      ReferralsService[$ctrl.meta.role]($ctrl.filters).then(
        function(response) {
          $ctrl.loading = false
          $ctrl.referrals = response.data
          if ($ctrl.patientsMap) {
            $ctrl.referrals.forEach(function(r) {
              if (r.patient_id) {
                r.patient_name = $ctrl.patientsMap.get(r.patient_id)
              }
            })
          }
        },
        function(response) {
          $ctrl.loading = false
          NotificationService.error("Error Loading Referrals", response)
        }
      )
    }

    $ctrl.onPeriodChange = function(reload) {
      if ($ctrl.period) {
        var prevPeriod = $ctrl.period[0] - 1
        var typePeriod = $ctrl.period[1]
        $ctrl.filters.date__range = [
          moment().startOf(typePeriod).subtract(prevPeriod, typePeriod).format("YYYY-MM-DD"),
          moment().endOf(typePeriod).format("YYYY-MM-DD")
        ].join(",")
      }
      else {
        delete $ctrl.filters.date__range
      }
      if (reload) {
        $ctrl.$onInit()
      }
    }

    $ctrl.onUncompletedChange = function(reload) {
      if ($ctrl.uncompleted) {
        $ctrl.filters.is_completed = false
      }
      else {
        delete $ctrl.filters.is_completed
      }
      if (reload) {
        $ctrl.$onInit()
      }
    }

    $ctrl.orderByClick = function(prop) {
      if (prop ===  $ctrl.orderByProp) {
        $ctrl.orderByReverse = !$ctrl.orderByReverse
      }
      else {
        $ctrl.orderByProp = prop
      }
    }

    $ctrl.onPeriodChange()
    $ctrl.onUncompletedChange()
  }

})();

(function () {
  "use strict"

  angular
    .module("referrals.components")

    .component("referralList", {
      bindings: {
        canCreateReferral: "<",
        canListReport: "<",
        canRetrieveReferral: "<",
        canUpdateReferral: "<",
        meta: "<",
        referralsFilters: "<",
      },
      controller: Controller,
      templateUrl: "src/referrals/components/referral-list.html",
    })

  const dateSorting = function (aDate, bDate) {
    let a = new Date(moment(aDate, "MM-DD-YYYY").format("YYYY-MM-DD"));
    //here MM-DD-YYYY is the current format in which the dates are returned 
    let b = new Date(moment(bDate, "MM-DD-YYYY").format("YYYY-MM-DD"));
    
    if (a < b) {
        return -1;
    }
    else if (a > b) {
        return 1;
    }
    else {
        return 0;
    }
  }

  function Controller(NotificationService, ReferralService, uiGridConstants) {
    var $ctrl = this

    $ctrl.$onInit = function () {
      $ctrl.curStateName = $ctrl.meta.ns + "." + $ctrl.meta.role
      $ctrl.loading = false
      $ctrl.period = "3-month"
      $ctrl.search = ""
      $ctrl.showCompletedReferrals = false
      $ctrl.singlePatient = $ctrl.referralsFilters.patient !== undefined
      $ctrl.uiGrid = {
        api: null,
        columnDefs: [
          {
            enableSorting: true,
            name: "date",
            sort: { direction: uiGridConstants.DESC, priority: 0 },
            displayName: "Date",
            field: "1",
            cellClass: "text-center",
            width: 100,
            sortingAlgorithm: (aDate, bDate) => { return dateSorting(aDate, bDate) },
          },
          {
            name: "referring-practice",
            displayName: "Referring Practice",
            field: "4",
            cellTemplate: [
              '<div class="ui-grid-cell-contents">',
              '  <a class="dis-ib" target="_blank"',
              '    ui-sref="settings-referrals.practice-detail({id:row.entity[3]})">',
              "    {{ row.entity[4] }}",
              "  </a>",
              '  <small class="pull-right text-muted">{{ row.entity[5] }}</small>',
              "</div>",
            ].join(""),
          },
          {
            name: "referrer",
            displayName: "Referrer",
            field: "7",
            cellTemplate: [
              '<div class="ui-grid-cell-contents">',
              '  <a class="dis-ib" target="_blank"',
              '    ui-sref="settings-referrals.user-detail({id:row.entity[6]})">',
              "    {{ row.entity[7] }}",
              "  </a>",
              '  <small class="pull-right text-muted">{{ row.entity[8] }}</small>',
              "</div>",
            ].join(""),
          },
          $ctrl.singlePatient
            ? null
            : {
                name: "patient",
                displayName: "Patient",
                field: "10",
                cellTemplate: [
                  '<div class="ui-grid-cell-contents">',
                  '  <a class="dis-ib" target="_blank"',
                  '    ui-sref="profile({patientid: row.entity[9]})">',
                  "    {{ row.entity[10] }}",
                  "  </a>",
                  "</div>",
                ].join(""),
              },
          {
            name: "description",
            displayName: "Description",
            field: "11",
          },
          {
            name: "is-completed",
            displayName: "Is Completed",
            field: "2",
            cellTemplate: [
              '<div class="ui-grid-cell-contents">',
              '  <div class="checkbox-inline custom-control custom-checkbox">',
              "    <label>",
              '      <input type="checkbox" style="bottom:0;"',
              '        ng-change="grid.appScope.$ctrl.setIsCompleted(row.entity)"',
              '        ng-model="row.entity[2]">',
              '      <span class="custom-control-indicator"></span>',
              "    </label>",
              "  </div>",
              "</div>",
            ].join(""),
            cellClass: "text-center",
            enableColumnMenu: false,
            visible: $ctrl.showCompletedReferrals,
            width: 130,
          },
          {
            name: "actions",
            displayName: "",
            cellTemplate: [
              '<div class="ui-grid-cell-contents">',
              '  <div class="btn-group">',
              $ctrl.canUpdateReferral || $ctrl.canRetrieveReferral
                ? [
                    '<a class="btn btn-icomoon-pixel-perfect-1x font-blue m-r-sm"',
                    '  ui-sref="{{ grid.appScope.$ctrl.curStateName }}.detail({id:row.entity[0]})">',
                    '  <i class="icomoon icon-icomoon-pencil5"></i>',
                    "</a>",
                  ].join("")
                : "",
              $ctrl.canListReport
                ? [
                    '<a class="btn btn-icomoon-pixel-perfect-1x font-blue" ng-if="row.entity[12]"',
                    '  ui-sref="{{ grid.appScope.$ctrl.curStateName }}.detail.reports({id:row.entity[0]})">',
                    '  <i class="icomoon icon-icomoon-file-spreadsheet2"></i>',
                    "</a>",
                  ].join("")
                : "",
              "  </div>",
              "</div>",
            ].join(""),
            enableColumnMenu: false,
            enableColumnResizing: false,
            visible: $ctrl.canListReport || $ctrl.canRetrieveReferral || $ctrl.canUpdateReferral,
            width: 80,
          },
        ].filter(function (item) {
          return item !== null
        }),
        data: null,
        minRowsToShow: 20,
        onRegisterApi: function (api) {
          $ctrl.uiGrid.api = api
          $ctrl.uiGrid.api.grid.registerRowsProcessor(function (rows) {
            $ctrl.loading = true
            var search = $ctrl.search ? new RegExp($ctrl.search.replace(/[\\^$*+?.()|[\]{}]/g, "\\$&"), "i") : null
            rows.forEach(function (row) {
              if ($ctrl.showCompletedReferrals === false && row.entity[2] === true) {
                row.visible = false
              } else {
                row.visible = true
              }
              if (
                row.visible &&
                search &&
                !(
                  (row.entity[1] && row.entity[1].match(search)) ||
                  (row.entity[4] && row.entity[4].match(search)) ||
                  (row.entity[5] && row.entity[5].match(search)) ||
                  (row.entity[7] && row.entity[7].match(search)) ||
                  (row.entity[8] && row.entity[8].match(search)) ||
                  (row.entity[11] && row.entity[11].match(search)) ||
                  (!$ctrl.singlePatient && row.entity[10] && row.entity[10].match(search))
                )
              ) {
                row.visible = false
              }
            })
            $ctrl.loading = false
            return rows
          }, 99)
        },
        showGridFooter: true,
      }
      $ctrl.load()
    }

    $ctrl.load = function () {
      $ctrl.loading = true
      var params = angular.extend({}, $ctrl.referralsFilters)
      if ($ctrl.period) {
        var period = $ctrl.period.split("-")
        params.date__range = [
          moment()
            .startOf(period[1])
            .subtract(period[0] - 1, period[1])
            .format("YYYY-MM-DD"),
          moment().endOf(period[1]).format("YYYY-MM-DD"),
        ].join(",")
      }
      delete params.practice //TODO: remove when referralsFilters don't include practice
      ReferralService.v3.list(params).then(
        function (response) {
          $ctrl.uiGrid.data = response.data
          $ctrl.loading = false
        },
        function (response) {
          $ctrl.uiGrid.data = null
          $ctrl.loading = false
          NotificationService.error("Error Loading Referrals", response)
        }
      )
    }

    $ctrl.refresh = function () {
      if ($ctrl.uiGrid.api) {
        var isCompleted = $ctrl.uiGrid.api.grid.getColumn("is-completed")
        if ($ctrl.showCompletedReferrals) {
          isCompleted.showColumn()
        } else {
          isCompleted.hideColumn()
        }
        $ctrl.uiGrid.api.grid.queueGridRefresh()
      }
    }

    $ctrl.setIsCompleted = function (row) {
      ReferralService.v3.update(row[0], { is_completed: row[2] }).then(
        function () {
          NotificationService.clear()
          NotificationService.success("Saved")
        },
        function (error) {
          NotificationService.error("Error saving referral", error)
        }
      )
    }
  }
})();

(function() {
  "use strict"

  angular.module("referrals.services")

  .service("EventsService", function($http) {

    this.retrieve = function(params) {
      return $http.get("/api/v2/~referrals/events/" + params.id + "/")
    }

  })

})();

(function () {
  "use strict"

  angular.module("referrals.services")

    .service("ReferralPracticesService", function ($http) {

      this.list = function (practice) {
        return $http.get("/api/v3/practice/" + practice + "/~referrals/referral-practice")
      }

      this.create = function (practice, data) {
        return $http.post("/api/v3/practice/" + practice + "/~referrals/referral-practice", data)
      }

      this.retrieve = function (practice, id) {
        return $http.get("/api/v3/practice/" + practice + "/~referrals/referral-practice/" + id)
      }

      this.update = function (practice, data) {
        return $http.put("/api/v3/practice/" + practice + "/~referrals/referral-practice/" + data.id, data)
      }

      this.destroy = function (practice, id) {
        return $http.delete("/api/v3/practice/" + practice + "/~referrals/referral-practice/" + id)
      }

      this.dependants = function (practice, id) {
        return $http.get("/api/v3/practice/" + practice + "/~referrals/referral-practice/" + id + "/dependants")
      }

      this.metadata = function (practice, id) {
        return $http({
          method: "OPTIONS",
          url: "/api/v3/practice/" + practice + "/~referrals/referral-practice" + (id ? "/" + id : ""),
        })
      }
    })

})();

(function () {
  "use strict"

  angular.module("referrals.services")

    .service("ReferralUsersService", function ($http) {

      this.list = function (practice) {
        return $http.get("/api/v3/practice/" + practice + "/~referrals/referral-user")
      }

      this.create = function (practice, data) {
        return $http.post("/api/v3/practice/" + practice + "/~referrals/referral-user", data)
      }

      this.retrieve = function (practice, id) {
        return $http.get("/api/v3/practice/" + practice + "/~referrals/referral-user/" + id)
      }

      this.update = function (practice, data) {
        return $http.put("/api/v3/practice/" + practice + "/~referrals/referral-user/" + data.id, data)
      }

      this.destroy = function (practice, id) {
        return $http.delete("/api/v3/practice/" + practice + "/~referrals/referral-user/" + id)
      }

      this.dependants = function (practice, id) {
        return $http.get("/api/v3/practice/" + practice + "/~referrals/referral-user/" + id + "/dependants")
      }

      this.metadata = function (practice, id) {
        return $http({
          method: "OPTIONS",
          url: "/api/v3/practice/" + practice + "/~referrals/referral-user" + (id ? "/" + id : ""),
        })
      }

      this.sendEmail = function (practice, id, kind) {
        return $http.get("/api/v3/practice/" + practice + "/~referrals/referral-user/" + id + "/send-email/" + kind)
      }
    })

})();

(function () {
  "use strict"

  angular
    .module("referrals.services")

    .service("ReferralService", function ReferralService($http, $q, PrincipalService) {
      this.v3 = {
        list: function (params) {
          return $http.get("/api/v3/practice/" + PrincipalService.getPracticeId() + "/~referrals/referral", {
            params: params,
          })
        },
        update: function (id, data) {
          return $http.put("/api/v3/practice/" + PrincipalService.getPracticeId() + "/~referrals/referral/" + id, data)
        },
      }
    })
})();

(function() {
"use strict"

  angular.module("referrals.services")

  .service("ReferralsService", function($http, PrincipalService) {

    this.create = function(params) {
      return $http.post("/api/v2/~referrals/referrals/", params)
    }

    this.destroy = function(id) {
      return $http.delete("/api/v2/~referrals/referrals/" + id + "/")
    }

    this.partial_update = function(params) {
      return $http.patch("/api/v2/~referrals/referrals/" + params.id + "/", params)
    }

    this.patients = function(params) {
      // TODO: remove
      if (!params) {
        params = {}
      }
      if (!params.practice) {
        params.practice = PrincipalService.getPracticeId()
      }
      return $http.get("/api/v2/~referrals/referrals/patients/", {params:params})
    }

    this.practices = function(params) {
      return $http.get("/api/v2/~referrals/referrals/practices/", {params:params})
    }

    this.received = function(params) {
      return $http.get("/api/v2/~referrals/referrals/received/", {params:params})
    }

    this.reports = function(params) {
      return $http.get("/api/v2/~referrals/referrals/" + params.id + "/reports/", params)
    }

    this.retrieve = function(id) {
      return $http.get("/api/v2/~referrals/referrals/" + id + "/")
    }

    this.retrieve_related = function(id) {
      return $http.get("/api/v2/~referrals/referrals/" + id + "/retrieve-related/")
    }

    this.stats = function(params) {
      return $http.get("/api/v2/~referrals/referrals/stats/", {params:params})
    }

    this.users = function(params) {
      return $http.get("/api/v2/~referrals/referrals/users/", {params:params})
    }

    this.snapshot = function(id) {
      return $http.get("/api/v2/~referrals/referrals/" + id + "/snapshot/")
    }

    this.imagePresignedUrl = function(id, params) {
      return $http.post("/api/v2/~referrals/referrals/" + id + "/image-presigned-url/", params)
    }

  })

})();

(function() {
  "use strict"

  angular.module("referrals.services")

  .service("ReportTemplateService", function($http) {

    this.list = function(params) {
      return $http.get("/api/v2/~referrals/report-templates/", {params:params})
    }

    this.create = function(params) {
      return $http.post("/api/v2/~referrals/report-templates/", params)
    }

    this.destroy = function(id) {
      return $http.delete("/api/v2/~referrals/report-templates/" + id + "/")
    }

    this.partial_update = function(id, params) {
      return $http.patch("/api/v2/~referrals/report-templates/" + id + "/", params)
    }

  })

})();

(function() {
  "use strict"

  angular.module("referrals.services")

  .service("ReportService", function($http) {

    this.list = function(params) {
      return $http.get("/api/v2/~referrals/reports/", {params:params})
    }

    this.retrieve = function(id) {
      return $http.get("/api/v2/~referrals/reports/" + id + "/")
    }

    this.partial_update = function(params) {
      return $http.patch("/api/v2/~referrals/reports/" + params.id + "/", params)
    }

    this.create = function(params) {
      return $http.post("/api/v2/~referrals/reports/", params)
    }

    this.images = function(params) {
      return $http.get("/api/v2/~referrals/reports/images/", {params:params})
    }

    this.treatments = function(params) {
      return $http.get("/api/v2/~referrals/reports/treatments/", {params:params})
    }

    this.files = function(params) {
      return $http.get("/api/v2/~referrals/reports/files/", {params:params})
    }

    // v3
    this.publishToReferrer = function(practice, id) {
      return $http.post("/api/v3/practice/" + practice + "/~referrals/report/" + id + "/publish-to-referrer")
    }
  })

})();

(function() {
  "use strict"

  angular.module("referrals.services")

  .service("ReportFileService", function($http, $q, ArchivesService, Upload) {

    var $svc = this

    $svc.create = function(params, files) {
      var files_upload_req = files.map(function(file) {
        return {
          archive: file.name
        }
      })
      return $http.post("/api/v2/~referrals/report-files/upload/", files_upload_req, {params:params}).then(
        function(response) {
          return files.map(function(file, idx) {
            var obj = {}
            obj.$archive = file
            response.data[idx].archive.data.file = file
            Upload.upload(response.data[idx].archive)
            .then(
              function() {
                obj.$uploadProgress_archive = 100
              },
              function(response) {
                ArchivesService.setError(obj, "$upload_archive", response)
              },
              function(evt) {
                obj.$uploadProgress_archive = Math.min(100, parseInt(100.0 * evt.loaded / evt.total))
              }
            )
            .then(
              function() {
                var data = angular.extend({}, params, {
                  archive: response.data[idx].archive.name
                })
                return $http.post("/api/v2/~referrals/report-files/", data).then(
                  function(response) {
                    return angular.extend(obj, response.data)
                  },
                  function(response) {
                    ArchivesService.setError(obj, "$create", response)
                  }
                )
              }
            )
            .then(
              function(obj) {
                delete obj.$archive
                delete obj.$uploadProgress_archive
                delete obj.report
                return obj
              }
            )
            return obj
          })
        }
      )
    }

    $svc.destroy = function(id) {
      return $http.delete("/api/v2/~referrals/report-files/" + id + "/")
    }

    $svc.download = function(id) {
      return $http.get("/api/v2/~referrals/report-files/" + id + "/download/").then(
        function(response) {
          return Upload.urlToBlob(response.data.archive)
        }
      )
    }

    $svc.partial_update = function(params) {
      return $http.patch("/api/v2/~referrals/report-files/" + params.id + "/", params)
    }

    $svc.retrieve = function(id) {
      return $http.get("/api/v2/~referrals/report-files/" + id + "/")
    }

  })

})();

(function() {
'use strict';

angular.module('referrals.components')

.service('referralsDetailEventDetail', function($uibModal, $state, $rootScope) {

  this.openModal = function() {

    $uibModal.open({
      templateUrl: 'src/referrals/components/referrals-detail-event-detail/referrals-detail-event-detail.html',
      controller: function() {
        this.event = $rootScope.resolved.event;
      },
      controllerAs: '$ctrl',
      size: 'lg',
    }).result.finally(function() { $state.go('^'); });

  };

});

})();

(function() {
'use strict';

angular.module('referrals.components')

.component('referralsDetailEvent', {
  controller: Controller,
  templateUrl: 'src/referrals/components/referrals-detail-event/referrals-detail-event.html',
  bindings: {
  }
});

function Controller($rootScope) {

  var $ctrl = this;

  $ctrl.$onInit = function() {
    $ctrl.events = $rootScope.resolved.events;
  };

}

})();

(function() {
"use strict"

  angular.module("kiosk", [
    "kiosk.components",
    "kiosk.services",
    "rx-web-sockets",
  ])

  .config(function($stateProvider) {

    var AuthorizationsResolver = {
      CanCreateKioskUser:   function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanCreateKioskUser") },
      CanDeleteKioskUser:   function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanDeleteKioskUser") },
      CanListKioskUser:     function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanListKioskUser") },
      CanRetrieveKioskUser: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanRetrieveKioskUser") },
      CanUpdateKioskUser:   function(AuthorizationService) { return AuthorizationService.hasFrontendPermission("CanUpdateKioskUser") },
    }

    $stateProvider
    .state("kiosk", {
      url: "/kiosks/",
      parent: "home",
      abstract: true,
      resolve : angular.extend({}, AuthorizationsResolver, {
        kiosksFilters: function(PrincipalService) {
          return {
            user__practice: PrincipalService.getPracticeId(),
          }
        },
      }),
      views: {
        "task-view@home": {
          template: "<ui-view></ui-view>",
        }
      }
    })
    .state("kiosk.list", {
      url: "",
      component: "kiosks",
    })
    .state("kiosk-users", {
      url: "/kiosk-users/",
      parent: "settings",
      abstract: true,
      resolve : angular.extend({}, AuthorizationsResolver, {
        usersFilters: function(PrincipalService) {
          return {
            practice: PrincipalService.getPracticeId(),
          }
        },
      }),
      views: {
        "content@home": {
          template: "<ui-view></ui-view>",
        }
      }
    })
    .state("kiosk-users.list", {
      url: "",
      component: "kioskUsers",
    })
  })

  angular.module("kiosk.components", [])
  angular.module("kiosk.services", [])

})();

(function(exports) {
  'use strict';

  var Logging = {};
  Logging[Logging.None    = 0]          = "None";
  Logging[Logging.RxWsWs  = 2]          = "RxWsWs";
  Logging[Logging.RxWsRx  = 4]          = "RxWsRx";
  Logging[Logging.RxWsAPI = 8]          = "RxWsAPI";
  Logging[Logging.RxWsSrv = 16]         = "RxWsSrv";
  Logging[Logging.All     = 2147483647] = "All";
  exports.Logging = Logging;

  var MessageStatus = {};
  MessageStatus[MessageStatus.STATUS_200_OK                      = 200] = "STATUS_200_OK";
  MessageStatus[MessageStatus.STATUS_401_UNAUTHORIZED            = 401] = "STATUS_401_UNAUTHORIZED";
  MessageStatus[MessageStatus.STATUS_404_NOT_FOUND               = 404] = "STATUS_404_NOT_FOUND";
  MessageStatus[MessageStatus.STATUS_407_AUTHENTICATION_REQUIRED = 407] = "STATUS_407_AUTHENTICATION_REQUIRED";
  MessageStatus[MessageStatus.STATUS_412_PRECONDITION_FAILED     = 412] = "STATUS_412_PRECONDITION_FAILED";
  exports.MessageStatus = MessageStatus;

  exports.ResponseSubject = (function(_super) {
    function ResponseSubject() {
      var _this = _super.call(this) || this;
      return _this;
    }
    __extends(ResponseSubject, _super);
    return ResponseSubject;
  }(Rx.Subject));

  exports.RequestSubject = (function(_super) {
    function RequestSubject() {
      var _this = _super.call(this) || this;
      return _this;
    }
    __extends(RequestSubject, _super);
    return RequestSubject;
  }(Rx.Subject));

  exports.RxWebSocket = (function() {
    function RxWebSocket(url, helo, logging, maxConnectAttempts) {
      if (logging === undefined) {
        logging = Logging.RxWsAPI;
      }
      if (maxConnectAttempts === undefined) {
        maxConnectAttempts = 10;
      }
      var _this = this;
      this.url = url;
      this.helo = helo;
      this.logging = logging;
      this.maxConnectAttempts = maxConnectAttempts;
      this.heloCompleted = false;
      this.curConnectAttempts = 0;
      this.connect();
      this.requestQueue = [];
      this.requestSubject = new exports.RequestSubject();
      this.requestSubject.subscribe(function(msg) {
        if (_this.log(Logging.RxWsRx)) {
          console.group("[" + Logging[Logging.RxWsRx] + "] RxWebSocket.requestSubject.observer.next (send|queue)");
        }
        if (_this.socket) {
          var encoded_msg = JSON.stringify(msg);
          if (_this.socket.readyState === WebSocket.OPEN) {
            if (_this.log(Logging.RxWsRx)) {
              console.log("sending:", encoded_msg);
            }
            _this.socket.send(encoded_msg);
          }
          else {
            if (_this.log(Logging.RxWsRx)) {
              console.log("queueing:", encoded_msg);
            }
            _this.requestQueue.push(msg);
          }
        }
        else {
          _this.connect();
        }
        if (_this.log(Logging.RxWsRx)) {
          console.groupEnd();
        }
      });
      this.responseSubject = new exports.ResponseSubject();
      this.responseSubject.subscribe(function(msg) {
        if (_this.log(Logging.RxWsRx)) {
          console.group("[" + Logging[Logging.RxWsRx] + "] RxWebSocket.responseSubject.observer.next (log)");
          console.log(JSON.stringify(msg));
          console.groupEnd();
        }
      });
    }

    RxWebSocket.prototype.connect = function() {
      if (this.log(Logging.RxWsWs)) {
        console.group("[" + Logging[Logging.RxWsWs] + "] RxWebSocket.connect (init and bind events)");
        console.log(this.url);
        console.groupEnd();
      }
      var socket = new WebSocket(this.url);
      socket.onopen = this.onOpen.bind(this);
      socket.onmessage = this.onMessage.bind(this);
      socket.onerror = this.onError.bind(this);
      socket.onclose = this.onClose.bind(this);
      this.socket = socket;
    };

    RxWebSocket.prototype.log = function(level) {
      return !!(this.logging & level);
    };

    RxWebSocket.prototype.onClose = function(closeEvent) {
      var _this = this;
      if (this.log(Logging.RxWsWs)) {
        console.group("[" + Logging[Logging.RxWsWs] + "] RxWebSocket.onClose (try to reconnect with backoff)");
        console.log(closeEvent);
      }
      if (closeEvent.wasClean) {
        if (this.log(Logging.RxWsWs)) {
          console.log("Not retrying - clean close");
        }
        this.socket = null;
        this.curConnectAttempts = 0;
      }
      else {
        this.curConnectAttempts += 1;
        if (this.log(Logging.RxWsWs)) {
          console.log("Retrying - [retry:" + this.curConnectAttempts + "] [delay:" + this.curConnectAttempts * 100 + "ms]");
        }
        if (this.curConnectAttempts < this.maxConnectAttempts) {
          setTimeout(function() {
            _this.connect();
          }, this.curConnectAttempts * 100);
        }
      }
      if (this.log(Logging.RxWsWs)) {
        console.groupEnd();
      }
    };

    RxWebSocket.prototype.onError = function(event) {
      if (this.log(Logging.RxWsWs)) {
        console.group("[" + Logging[Logging.RxWsWs] + "] RxWebSocket.onError (TODO)");
        console.log(event);
        console.groupEnd();
      }
    };

    RxWebSocket.prototype.onMessage = function(messageEvent) {
      if (this.log(Logging.RxWsWs)) {
        console.group("[" + Logging[Logging.RxWsWs] + "] RxWebSocket.onMessage (response.next)");
        console.log(messageEvent.data);
        console.groupEnd();
      }
      var msg = JSON.parse(messageEvent.data);
      if (msg && msg.payload && msg.payload.cmd === "helo") {
        this.curConnectAttempts = 0;
        this.helo.onResponse(msg);
        this.heloCompleted = true;
      }
      else {
        this.responseSubject.next(msg);
      }
    };

    RxWebSocket.prototype.onOpen = function() {
      this.heloCompleted = false;
      var helo = this.helo.doRequest();
      if (this.log(Logging.RxWsRx)) {
        console.group("[" + Logging[Logging.RxWsRx] + "] RxWebSocket.onOpen (requestSubject.next: helo and queue)");
        console.log("helo: ", JSON.stringify(helo));
      }
      this.requestSubject.next(helo);
      if (this.requestQueue.length) {
        if (this.log(Logging.RxWsRx)) {
          console.log("requestQueue: ", JSON.stringify(this.requestQueue));
        }
        for (var i = this.requestQueue.length - 1; i >= 0; i--) {
          this.requestSubject.next(this.requestQueue.shift());
        }
      }
      if (this.log(Logging.RxWsRx)) {
        console.groupEnd();
      }
    };

    RxWebSocket.prototype.send = function(request, log) {
      if (log === undefined) {
        log = true;
      }
      if (log && this.log(Logging.RxWsAPI)) {
        console.group("[" + Logging[Logging.RxWsAPI] + "] RxWebSocket.send (request.next)");
        console.log(JSON.stringify(request));
        console.groupEnd();
      }
      this.requestSubject.next(request);
    };

    Object.defineProperty(RxWebSocket.prototype, "incomming", {
      get: function() {
        return this.responseSubject.asObservable();
      },
      enumerable: true,
      configurable: true
    });

    return RxWebSocket;
  }());

})(window);

(function() {
'use strict';

  angular.module('rx-web-sockets', [
  ])

  .service('WebSocketSubjectService', function(
    $state,
    PrincipalService
  ) {

    this.client = new Rx.BehaviorSubject(store.namespace("client").getAll())

    this.client.skip(1).subscribe(function(value) {
      if (value) {
        store.namespace("client").setAll(value)
      }
      store.session.namespace("client").setAll(value)
    })

    this.sockets = {}

    var _this = this;

    var webSocketsConfig = [
      {
        url: (window.location.protocol === "https:" ? "wss" : "ws") + "://" + window.location.host + "/ws",
        helo: {
          doRequest: function () {
            return {
              stream: "kiosk-manager",
              payload: {
                cmd: "helo",
                request: {
                  target: "server",
                  client: _this.client.getValue(),
                }
              }
            };
          },
          onResponse: function (msg) {
            var stream = msg.stream;
            var payload = msg.payload;
            if (stream === "kiosk-manager") {
              var status = payload.status;
              var response = payload.response;
              if (status === MessageStatus.STATUS_200_OK) {
                _this.client.next(response.client);
              }
              else if (status === MessageStatus.STATUS_407_AUTHENTICATION_REQUIRED) {
                PrincipalService.authenticate(null);
                $state.go('signout');
              }
              else {
                console.group("[RxWsSrv] RxWebSocket.helo.response [ERROR]");
                console.log(JSON.stringify(msg));
                console.groupEnd();
              }
            }
          }
        }
      }
    ];

    webSocketsConfig.forEach(function (cfg, idx) {
      var pathname = new URL(cfg.url).pathname;
      _this.sockets[pathname] = new RxWebSocket(cfg.url, cfg.helo);
      if (idx === 0) {
        _this.default = pathname;
      }
    });

    this.socket = function(socket) {
      return this.sockets[socket || this.default]
    }
  });

})();

(function() {
"use strict"

  angular.module("kiosk.components")

  .component("kiosks", {
    controller: Controller,
    templateUrl: "src/kiosk/components/kiosks.html",
    bindings: {
    }
  }).directive("directiveWhenScrolled", function() {
    return function(scope, elm) {
      scope.limit = 10;
      scope.$applyAsync(function() { scope.limit = 10 });
      var childElem = elm.children().first()
      var raw = childElem[0];

      childElem.bind('scroll', function() {
        console.log(raw.scrollTop + raw.offsetHeight, raw.scrollHeight)
        if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
          scope.$apply(function () { scope.limit += 10 });
        }
      });
    };
  });

  function Controller(
    $rootScope,
    $scope,
    $state,
    $uibModal,
    KiosksService,
    NotificationService,
    PrincipalService,
    WebSocketSubjectService
  ) {

    var $ctrl = this

    $ctrl.$onInit = function() {
      $ctrl.kiosk_users = []
      $ctrl.webSocketSubject = WebSocketSubjectService.socket()
      $ctrl.webSocketSubject.incomming.subscribe(
        function(msg) {
          console.group("kioskList.$onInit - webSocketSubject.subscribe")
          console.log(JSON.stringify(msg))
          console.groupEnd()
          var stream = msg.stream
          var payload = msg.payload
          if (stream === "kiosk-manager") {
            // var cmd = payload.cmd
            var status = payload.status
            // var response = payload.response
            if (status === MessageStatus.STATUS_200_OK) {
              $scope.$applyAsync(function() {
              })
            }
            else if (status === MessageStatus.STATUS_401_UNAUTHORIZED) {}
            else if (status === MessageStatus.STATUS_404_NOT_FOUND) {}
            else if (status === MessageStatus.STATUS_407_AUTHENTICATION_REQUIRED) {
              PrincipalService.authenticate(null)
              $state.go("signout")
            }
            else if (status === MessageStatus.STATUS_412_PRECONDITION_FAILED) {}
          }
        },
        function(error) {
          console.log("kiosk stream error", error)
        },
        function() {
          console.log("completed")
        }
      )
      $ctrl.CanListKiosk = $rootScope.resolved.CanListKiosk
      $ctrl.CanDeleteKiosk = true || $rootScope.resolved.CanDeleteKiosk
      $ctrl.filters = angular.extend({}, $rootScope.resolved.kiosksFilters)
      $scope.kiosksFilters = angular.extend({}, $rootScope.resolved.kiosksFilters)
      $ctrl.kiosks = []
      $ctrl.loadKiosks($ctrl.filters)
    }

    $ctrl.loadKiosks = function(filters) {
      if ($ctrl.CanListKiosk) {
        KiosksService.list(filters).then(
          function(response) {
            $ctrl.kiosks = response.data
          },
          function(response) {
            NotificationService.error("Error Loading Kiosks", response)
          }
        )
      }
    }

    $ctrl.assignKioskModal = function(kiosk) {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        size: "md",
        templateUrl: "#assignKioskModal",
        controller: function(metadata) {
          var $mctrl = this
          $mctrl.loading = false
          $mctrl.patients = metadata.schema.patient.choices
          $mctrl.patient = kiosk.patient
          $mctrl.hasPatient = !!kiosk.patient.id
          $mctrl.kiosk = kiosk
          if (kiosk.patient.pin && kiosk.patient.pin.number) {
            $mctrl.pin = kiosk.patient.pinkiosk.patient.pin.number
          }
          else {
            $mctrl.pin = Math.floor(Math.random() * 8889) + 1111
          }
          $mctrl.notAssigned = function(patient) {
            // TODO improve performance
            return !$ctrl.kiosk_users.some(function(_user) {
              return _user.kiosks.some(function(_kiosk) {
                return _kiosk.patient.id === patient.id
              })
            })
          }
          $mctrl.assign = function() {
            $mctrl.loading = true
            var kiosk_data = angular.extend(
              {id: kiosk.id},
              {
                patient: angular.extend(
                  {},
                  $mctrl.patient,
                  {
                    pin: {
                      number: $mctrl.pin
                    }
                  }
                )
              }
            )
            KiosksService.update(kiosk_data).then(
              function(response) {
                $mctrl.loading = false
                $mctrl.$close(response.data)
              },
              function(response) {
                $mctrl.loading = false
                NotificationService.error("Error assigning Kiosk to Patient", response)
              }
            )
          }
        },
        resolve: {
          metadata: function(KiosksService, NotificationService) {
            return KiosksService.metadata(kiosk.id).then(
              function(response) {
                return response.data
              },
              function(response) {
                NotificationService.error("Error loading Kiosk info", response)
                return {
                  schema: {
                    patient: []
                  }
                }
              }
            )
          },
        },
      }).result.then(
        function(_kiosk) {
          angular.extend(kiosk, _kiosk)
        }
      )
    }

    $ctrl.clearKioskModal = function(kiosk) {
      if (!kiosk.reply_channel) {
        KiosksService.update(angular.extend({}, {id:kiosk.id}, {patient:{}})).then(
          function(response) {
            angular.extend(kiosk, response.data)
          },
          function(response) {
            NotificationService.error("Error clearing Patient from Kiosk", response)
          }
        )
      }
      else {
        $uibModal.open({
          bindToController: true,
          controllerAs: "$ctrl",
          size: "md",
          templateUrl: "#clearKioskModal",
          controller: function() {
            var $mctrl = this
            $mctrl.loading = false
            $mctrl.clear = function() {
              $mctrl.loading = true
              KiosksService.update(angular.extend({}, {id:kiosk.id}, {patient:{}})).then(
                function(response) {
                  $mctrl.loading = false
                  $mctrl.$close(response.data)
                },
                function(response) {
                  $mctrl.loading = false
                  NotificationService.error("Error clearing Patient from Kiosk", response)
                }
              )
            }
          },
        }).result.then(
          function(_kiosk) {
            angular.extend(kiosk, _kiosk)
          }
        )
      }
    }

    $ctrl.removeKioskModal = function(kiosk) {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        size: "md",
        templateUrl: "#removeKioskModal",
        controller: function() {
          var $mctrl = this
          $mctrl.loading = false
          $mctrl.kiosk = kiosk
          $mctrl.errors = {}
          $mctrl.submit = function(params) {
            $mctrl.loading = true
            KiosksService.remove(params).then(
              function() {
                $mctrl.loading = false
                $mctrl.errors = {}
                $mctrl.$close()
              },
              function(response) {
                $mctrl.loading = false
                $mctrl.errors = response.data
              }
            )
          }
        },
      }).result.then(
        function() {
          for (var i = 0; i < $ctrl.kiosks.length; i++) {
            if ($ctrl.kiosks[i].id === kiosk.id) {
              $ctrl.kiosks.splice(i, 1)
              break
            }
          }
        }
      )
    }

  }

})();

(function() {
"use strict"

  angular.module("kiosk.components")

  .component("kioskUsers", {
    controller: Controller,
    templateUrl: "src/kiosk/components/kiosk-users.html",
  })

  function Controller(
    $rootScope,
    $uibModal,
    KioskUsersService,
    NotificationService,
    PrincipalService
  ) {

    var $ctrl = this

    $ctrl.$onInit = function() {
      $ctrl.CanCreateKioskUser = $rootScope.resolved.CanCreateKioskUser
      $ctrl.CanDeleteKioskUser = $rootScope.resolved.CanDeleteKioskUser
      $ctrl.CanListKioskUser = $rootScope.resolved.CanListKioskUser
      $ctrl.CanUpdateKioskUser = $rootScope.resolved.CanUpdateKioskUser
      $ctrl.filters = angular.extend({}, $rootScope.resolved.usersFilters)
      $ctrl.kioskUsers = []
      $ctrl.loadUsers($ctrl.filters)
    }

    $ctrl.loadUsers = function(filters) {
      if ($ctrl.CanListKioskUser) {
        KioskUsersService.list(filters).then(
          function (response) {
            $ctrl.kioskUsers = response.data
          },
          function (response) {
            NotificationService.error("Error Loading Kiosk Users", response)
          }
        )
      }
    }

    $ctrl.logoutKioskUserModal = function(kioskUser) {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        size: "md",
        templateUrl: "#logoutKioskUserModal",
        controller: function() {
          var $mctrl = this
          $mctrl.loading = false
          $mctrl.logout = function() {
            $mctrl.loading = true
            KioskUsersService.logout({id:kioskUser.id}).then(
              function() {
                $mctrl.loading = false
                $mctrl.$close()
              },
              function(response) {
                $mctrl.loading = false
                NotificationService.error("Error logging out User", response)
              }
            )
          }
        },
      })
    }

    $ctrl.createKioskUserModal = function() {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        size: "md",
        templateUrl: "#createKioskUserModal",
        controller: function() {
          var $mctrl = this
          $mctrl.usernameSuffix = "@practice-" + PrincipalService.getPracticeId()
          $mctrl.loading = false
          $mctrl.errors = {}
          $mctrl.submit = function(params) {
            $mctrl.loading = true
            KioskUsersService.create(angular.extend({}, params, {
              username: params.username + $mctrl.usernameSuffix,
              practice: PrincipalService.getPracticeId(),
            })).then(
              function(response) {
                $mctrl.loading = false
                $mctrl.errors = {}
                $mctrl.$close(response.data)
              },
              function(response) {
                $mctrl.loading = false
                $mctrl.errors = response.data
              }
            )
          }
        },
      }).result.then(
        function(kioskUser) {
          $ctrl.kioskUsers.push(kioskUser)
        }
      )
    }

    $ctrl.removeKioskUserModal = function(kioskUser) {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        size: "md",
        templateUrl: "#removeKioskUserModal",
        controller: function() {
          var $mctrl = this
          $mctrl.loading = false
          $mctrl.kioskUser = kioskUser
          $mctrl.errors = {}
          $mctrl.submit = function(params) {
            $mctrl.loading = true
            KioskUsersService.remove(params).then(
              function() {
                $mctrl.loading = false
                $mctrl.errors = {}
                $mctrl.$close()
              },
              function(response) {
                $mctrl.loading = false
                $mctrl.errors = response.data
              }
            )
          }
        },
      }).result.then(
        function() {
          for (var i = 0; i < $ctrl.kioskUsers.length; i++) {
            if ($ctrl.kioskUsers[i].id === kioskUser.id) {
              $ctrl.kioskUsers.splice(i, 1)
              break
            }
          }
        }
      )
    }

    $ctrl.setPasswordKioskUserModal = function(kioskUser) {
      $uibModal.open({
        bindToController: true,
        controllerAs: "$ctrl",
        size: "md",
        templateUrl: "#setPasswordKioskUserModal",
        controller: function() {
          var $mctrl = this
          $mctrl.loading = false
          $mctrl.kioskUser = kioskUser
          $mctrl.errors = {}
          $mctrl.submit = function(params) {
            $mctrl.loading = true
            KioskUsersService.setPassword(angular.extend({}, params, {id:kioskUser.id})).then(
              function() {
                $mctrl.loading = false
                $mctrl.errors = {}
                $mctrl.$close()
              },
              function(response) {
                $mctrl.loading = false
                $mctrl.errors = response.data
              }
            )
          }
        },
      })
    }


  }

})();

(function() {
"use strict"

  angular.module("kiosk.services")

  .service("KioskUsersService", function($http) {

    this.list = function(params) {
      return $http.get("/api/v2/~kiosk/kiosk-users/", {params:params})
    }

    this.logout = function(params) {
      return $http.post("/api/v2/~kiosk/kiosk-users/logout/", params)
    }

    this.create = function(params) {
      return $http.post("/api/v2/~kiosk/kiosk-users/", params)
    }

    this.remove = function(params) {
      return $http.delete("/api/v2/~kiosk/kiosk-users/" + params.id + "/")
    }

    this.setPassword = function(params) {
      return $http.put("/api/v2/~kiosk/kiosk-users/" + params.id + "/set-password/", params)
    }

  })

})();

(function() {
"use strict"

  angular.module("kiosk.services")

  .service("KiosksService", function($http) {

    this.list = function(params) {
      return $http.get("/api/v2/~kiosk/kiosks/", {params:params})
    }

    this.metadata = function(id) {
      return $http({
        method: "OPTIONS",
        url: "/api/v3/~kiosk/kiosk" + (id ? "/" + id : ""),
      })
    }

    this.remove = function(params) {
      return $http.delete("/api/v2/~kiosk/kiosks/" + params.id + "/")
    }

    this.update = function(params) {
      return $http.patch("/api/v2/~kiosk/kiosks/" + params.id + "/", params)
    }

  })

})();

(function() {
  'use strict';

  angular.element(document).ready(function() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('sw.js', { scope: '/' })
        .then(function(registration) {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch(function(error) {
          console.error('Service Worker registration failed:', error);
        });
    }
    angular.bootstrap(document, ['app']);
  });

  var MultiLocationEnable = true;

  var defaultIdle = 900;

  var defaultIdleMultiplier = 1;

  function success(response) {
    return response.data;
  }

  function errorObj(reason) {
    console.debug('ERROR', reason);
    return {};
  }

  function errorCol(reason) {
    console.debug('ERROR', reason);
    return [];
  }

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  window.titleCase = titleCase;

  function config(
    $httpProvider,
    $localStorageProvider,
    $locationProvider,
    $logProvider,
    $provide,
    $stateProvider,
    $uibModalProvider,
    $urlRouterProvider,
    IdleProvider,
    KeepaliveProvider,
    toastrConfig
  ) {

    $logProvider.debugEnabled(true);
    $httpProvider.interceptors.push('httpInterceptor');

    //Remove the () on negative numbers
    $provide.decorator('$locale', ['$delegate', function($delegate) {
      if($delegate.id === 'en-us') {
        $delegate.NUMBER_FORMATS.PATTERNS[1].negPre = '-\u00A4';
        $delegate.NUMBER_FORMATS.PATTERNS[1].negSuf = '';
      }
      return $delegate;
    }]);

    $uibModalProvider.options.backdrop = false;

    $locationProvider.html5Mode({enabled:true, requireBase:false}).hashPrefix('!');

    var idleMultiplier = $localStorageProvider.get("idleMultiplier")
    if (!idleMultiplier) {
      $localStorageProvider.set("idleMultiplier", defaultIdleMultiplier)
      idleMultiplier = defaultIdleMultiplier
    }

    // The idle timeout duration in seconds. After this amount of time passes without the user performing an action
    // that triggers one of the watched DOM events, the user is considered idle
    IdleProvider.idle(defaultIdle * idleMultiplier - 60)

    // The amount of time the user has to respond (in seconds) before they have been considered timed out.
    IdleProvider.timeout(30)

    // When true, the Keepalive service is automatically stopped and started as needed.
    IdleProvider.keepalive(false)

    // URL to a simple GET request to ping the server, TODO: change for an specialized endpoint
    KeepaliveProvider.http("/api/heartbeat/")

    // Specifies how often the Keepalive event is triggered and the HTTP request is issued
    KeepaliveProvider.interval(defaultIdle - 60)

    angular.extend(toastrConfig, {
      autoDismiss: false,
      allowHtml: true,
      timeOut:5000,
      extendedTimeOut:null,
    });

    var AuthorizationsResolver = Object.keys(window.permissionsMap)
      .map(function (value) {
        return value.slice(3)
      })
      .reduce(function (values, val) {
        values["can" + val] = function (AuthorizationService) {
          return AuthorizationService.hasFrontendPermission("Can" + val)
        }
        values["Can" + val] = values["can" + val]
        return values
      }, {})

    var FeaturesResolver = {
      MultiLocationEnable: function() { return MultiLocationEnable },
    }

    $stateProvider
      .state('site', {
        abstract: true,
        resolve: {
          authorize: ['AuthorizationService',
            function(AuthorizationService) {
              return AuthorizationService.authorize();
            }
          ]
        }
      })
      .state('signin-layout', {
        parent: 'site',
        abstract: true,
        views: {
          '@': {
            templateUrl: 'src/common/layouts/signin-layout.tpl.html',
          }
        }
      })
      .state('home', {
        parent: 'site',
        url: '/home',
        data: {
          roles: ['User']
        },
        resolve : angular.extend({}, AuthorizationsResolver, FeaturesResolver, {
          apiMeta: function($http) {
            return $http.get("/api/meta/").then(
              function(response) {
                return response.data
              },
              function() { return {} }
            )
          },
          referralsStatsFilters: function(PrincipalService) {
            return {
              practice: PrincipalService.getPracticeId(),
              is_completed: false
            }
          },
        }),
        views: {
          '@': {
            templateUrl: 'src/common/layouts/main-layout.tpl.html',
            controller: function ($scope, $transition$) {
              $scope.$transition$ = $transition$
            }
          },
          'task-select@home': {
            controller: 'TaskSelectCtrl',
            templateUrl: 'src/common/task-select.tpl.html'
          }
        }
      })
      .state('splash', {
        parent: 'signin-layout',
        url: '/',
        data: {
          roles: []
        },
        views: {
          'content@signin-layout': {
            templateUrl: 'src/common/signin.tpl.html',
            controller: 'SignInCtrl'
          }
        }
      })
      .state('insurance-data-provided-by-patient-base',{
        parent: 'site',
        abstract: true,
        data: {roles: ['User']},
        views: {
          '@': {
            template: '<ui-view/>'
          }
        }

      })
      .state('insurance-data-provided-by-patient',{
        parent: 'insurance-data-provided-by-patient-base',
        url:'/insurance-data-provided-by-patient/:patientId',
        data: {roles: ['User']},
        views:{
          '@insurance-data-provided-by-patient-base' : {
            controller: 'InsuranceDataProvidedByPatientCtrl',
            templateUrl: 'src/common/insurance-data-provided-by-patient.tpl.html'
          }
        }

      })
      .state('wizard-base', {
        parent: 'home',
        abstract:true,
        data: {
          roles: ['User']
        },
        views: {
          'task-view@home': {
            controller: 'WizardCtrl',
            templateUrl: 'src/common/wizard.tpl.html'
          }
        }
      })
      .state('wizard', {
        parent: 'wizard-base',
        url: '/wizard',
        data: {
          roles: ['User']
        },
        resolve:{
          CanCreateFeeProfile: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanCreateFeeProfile');
          },
          CanDeleteFeeProfile: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanDeleteFeeProfile');
          },
          CanUpdateFeeProfile: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateFeeProfile');
          },
          CanRetrieveInsurancePlan: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrieveInsurancePlan');
          },
          CanCreateInsurancePlan: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanCreateInsurancePlan');
          },
          CanDeleteInsurancePlan: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanDeleteInsurancePlan');
          },
          CanUpdateInsurancePlan: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateInsurancePlan');
          },
          CanRetrieveUser: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrieveUser');
          },
          CanUpdateUser: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateUser');
          },
          CanListInsurancePlan: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListInsurancePlan');
          },
          CanListUserActivity: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListUserActivity');
          },
          CanCreateUser: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanCreateUser');
          },
          CanUpdateLocationOperatory: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateLocationOperatory');
          },
          CanCreateConsentFormTemplate: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanCreateConsentFormTemplate');
          },
          CanDeleteConsentFormTemplate: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanDeleteConsentFormTemplate');
          },
          CanListConsentFormTemplate: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListConsentFormTemplate');
          },
          CanRetrieveConsentFormTemplate: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrieveConsentFormTemplate');
          },
          CanUpdateConsentFormTemplate: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateConsentFormTemplate');
          },
          Locations: function(LocationsService){
            return LocationsService.all().then(success,errorObj);
          },
          MultiLocationEnable:function(){
            return MultiLocationEnable;
          }
        },
        views: {
          'feeprofiles': {
            templateUrl: 'src/common/settings-chart-notes.tpl.html',
            controller: 'ChartNotesSettingsCtrl'
          },
          'insurancePlans': {
            templateUrl: 'src/common/settings-insurance-plans.tpl.html',
            controller: 'InsurancePlansSettingsCtrl as insurancePlans'
          },
          'users': {
            templateUrl: 'src/common/settings-users.tpl.html',
            controller: 'UsersSettingsCtrl'
          },
          'practicedetails': {
            templateUrl: 'src/common/settings-practice-details.tpl.html',
            controller: 'PracticeDetailsSettingsCtrl'
          },
          'consentformtemplates': {
            templateUrl: 'src/common/settings-consent-forms-templates.tpl.html',
            controller: 'ConsentFormsSettingsCtrl'
          }
        }
      })
      .state('eula', {
        parent: 'home',
        url: '/eula',
        data: {
          roles: ['User']
        },
        views: {
          'task-view@home': {
            controller: 'EulaCtrl',
            templateUrl: 'src/common/eula.tpl.html'
          }
        }
      })
      .state('thirdpartylicences', {
        parent: 'home',
        url: '/thirdpartylicences',
        data: {
          roles: ['User']
        },
        views: {
          'task-view@home': {
            controller: 'ThirdPartyLicencesCtrl as ctrl',
            templateUrl: 'src/common/third-party-licenses.tpl.html'
          }
        }
      })
      .state('schedule', {
        parent: 'home',
        url: '/schedule/:mode',
        data: {
          roles: ['User']
        },
        params: {
          mode: {
            value: 'multicolumn'
          }
        },
        resolve: {
          PracticeSettings: function(AuthenticationService,PrincipalService){
            return AuthenticationService.getPractice(PrincipalService.getPracticeId()).then(success,errorObj);
          },
        },
        views: {
          'task-view@home': {
            controller: 'ScheduleCtrl',
            templateUrl: 'src/common/schedule.tpl.html'
          }
        }
      })
      .state('charts-prev', {
        parent: 'home',
        url: '/charts-prev',
        params: {
          featureStep: null
        },
        data: {
          roles: ['User']
        },
        resolve:{
          CanCreatePatient: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanCreatePatient');
          },
          CanDeletePatient: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanDeletePatient');
          },
          CanRetrievePatient: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrievePatient');
          },
          CanListEncounter: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanListEncounter');
          },
          CanListTreatment: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanListTreatment');
          },
          CanListImage: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanListImage');
          },
          CanListPrescription: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanListPrescription');
          },
          CanListFile: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanListFile');
          },
          CanListBilling: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanListBilling');
          },
          CanListInsurance: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanListInsurance');
          },

        },
        views: {
          'task-view@home': {
            controller: 'ChartsCtrl',
            templateUrl: 'src/common/charts.tpl.html'
          }
        }
      })
      .state("charts", {
        parent: "home",
        url: "/charts",
        data: {
          roles: ["User"]
        },
        resolve: {
        },
        views: {
          "task-view@home": {
            component: "patientList",
          }
        }
      })
      .state('patientadd', {
        parent: 'charts',
        url: '/patientadd',
        data: {
          roles: ['User']
        },
        views: {
          'task-view@home': {
            controller: 'PatientAddCtrl',
            templateUrl: 'src/common/patient-add.tpl.html'
          }
        },
        params: {
          patient: null,
          returnTo: null,
        }
      })
      .state('patientdetail', {
        parent: 'charts',
        url: '/patientdetail/:patientid',
        data: {
          roles: ['User'],
        },
        resolve: {
          CanRetrievePatient: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrievePatient');
          },
          CanListEncounter: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanListEncounter');
          },
          CanListTreatment: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanListTreatment');
          },
          CanListPatientImage: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListPatientImage');
          },
          CanListPrescription: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanListPrescription');
          },
          CanListPatientFile: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListPatientFile');
          },
          CanListBilling: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanListBilling');
          },
          CanListInsurance: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanListInsurance');
          },
          CanListReferral: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanListReferral');
          },
        },
        views: {
          'task-view@home': {
            controller: 'PatientDetailCtrl',
            templateUrl: 'src/common/patient-detail.tpl.html'
          }
        }
      })
      .state('anonymous', {
        parent: 'site',
        abstract: true,
        data: {
          roles: []
        },
        views: {
          '@': {
            template: '<ui-view/>'
          }
        }
      })
      .state('patient-update-profile', {
        parent: 'anonymous',
        url: '/patientdetail/fill/:token',
        views: {
          '@anonymous': {
            controller: 'AnonymousPatientDetailCtrl',
            templateUrl: 'src/common/patient-detail-profile.tpl.html'
          }
        }
      })
      .state('appointment-reminder', {
        parent: 'anonymous',
        url: '/appointment/reminder/:token',
        views: {
          '@anonymous': {
            controller: 'AppointmentReminderCtrl',
            templateUrl: 'src/common/appointment-reminder.tpl.html'
          }
        }
      })
      .state('review-submit', {
        parent: 'anonymous',
        url: '/review/submit/:token',
        views: {
          '@anonymous': {
            controller: 'ReviewSubmitCtrl',
            templateUrl: 'src/common/review-submit.tpl.html'
          }
        }
      })
      .state('flow-complete', {
        parent: 'anonymous',
        params: {
          title: '',
          message: ''
        },
        views: {
          '': {
            controller: 'FlowCompleteCtrl',
            templateUrl: 'src/common/flow-complete.tpl.html'
          }
        }
      })
      .state('profile', {
        parent: 'patientdetail',
        url: '/profile',
        data: {
          roles: ['User']
        },
        resolve:{
          CanUpdatePatient: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdatePatient');
          },
          CanCreateInsurancePlan: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanCreateInsurancePlan');
          },
          CanUpdateInsurancePlan: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdateInsurancePlan');
          }
        },
        views: {
          'profile@patientdetail': {
            controller: 'PatientDetailProfileCtrl',
            templateUrl: 'src/common/patient-detail-profile.tpl.html'
          }
        }
      })
      .state('patient-interactions', {
        parent: 'patientdetail',
        url: '/patient-interactions',
        data: {
          roles: ['User']
        },
        resolve:{
          CanUpdatePatient: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdatePatient');
          },
          CanCreateReviewRequest: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanCreateReviewRequest');
          }
        },
        views: {
          'patient-interactions@patientdetail': {
            controller: 'PatientInteractionsCtrl',
            templateUrl: 'src/common/patient-interactions.tpl.html'
          }
        }
      })
      .state('chartnotes', {
        parent: 'patientdetail',
        url: '/chartnotes',
        data: {
          roles: ['User']
        },
        params:{
          isnewencounter: false
        },
        resolve:{
          CanAccessConsentForm: function(AuthorizationService) { return AuthorizationService.hasAnyFrontendPermissions(['CanCreateConsentForm', 'CanDeleteConsentForm', 'CanListConsentForm', 'CanRetrieveConsentForm', 'CanUpdateConsentForm']); },
          CanCreateConsentForm: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanCreateConsentForm'); },
          CanCreateEncounter: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanCreateEncounter'); },
          CanCreatePatientImage: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanCreatePatientImage'); },
          CanCreateReminder: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanCreateReminder'); },
          CanDeleteConsentForm: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanDeleteConsentForm'); },
          CanDeleteEncounter: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanDeleteEncounter'); },
          CanDeletePatientImage: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanDeletePatientImage'); },
          CanDeleteReminder: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanDeleteReminder'); },
          CanListConsentForm: function (AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanListConsentForm'); },
          CanListConsentFormTemplate: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanListConsentFormTemplate'); },
          CanListPatientImage: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanListPatientImage'); },
          CanListReminder: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanListReminder'); },
          CanRetrieveConsentForm: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanRetrieveConsentForm'); },
          CanRetrieveConsentFormTemplate: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanRetrieveConsentFormTemplate'); },
          CanRetrieveEncounter: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanRetrieveEncounter'); },
          CanRetrievePatientImage: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanRetrievePatientImage'); },
          CanRetrievePrescription: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanRetrievePrescription'); },
          CanRetrieveReminder: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanRetrieveReminder'); },
          CanRetrieveTreatmentAgreement: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanRetrieveTreatmentAgreement'); },
          CanUpdateConsentForm: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanUpdateConsentForm'); },
          CanUpdateEncounter: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanUpdateEncounter'); },
          CanUpdatePatientImage: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanUpdatePatientImage'); },
          CanUpdateReminder: function(AuthorizationService) { return AuthorizationService.hasFrontendPermission('CanUpdateReminder'); },
          remindersList: function($transition$) {
            return {
              filters: {
                encounter__patient_id: +$transition$.params().patientid,
              },
              showPatientName: false,
            }
          },
        },
        views: {
          'chartnotes@patientdetail': {
            controller: 'PatientDetailEncounters',
            templateUrl: 'src/common/patient-detail-encounters.tpl.html'
          }
        }
      })
      .state('chart-detail-base', {
        parent: 'chartnotes',
        url: '/chart-detail/:encounterid',
        abstract:true,
        data: {
          roles: ['User']
        },
        resolve:{
          MultiLocationEnable:function(){
            return MultiLocationEnable;
          }
        },
        views: {
          'chartnotes@patientdetail': {
            controller: 'EncounterDetailCtrl',
            templateUrl: 'src/common/encounter-detail.tpl.html'
          }
        }
      })
      .state('chart-detail', {
        parent: 'chart-detail-base',
        url: '',
        data: {
          roles: ['User']
        },
        resolve: {
          remindersManage: function($transition$) {
            return {
              filters: {
                encounter_id: +$transition$.params().encounterid,
              },
            }
          },
        },
        views: {
          'prescriptions': {
            controller: 'PatientDetailPrescriptionsCtrl',
            templateUrl: 'src/common/patient-detail-prescriptions.tpl.html'
          },
          'treatments': {
            controller: 'PatientDetailTreatmentPlansCtrl',
            templateUrl: 'src/common/patient-detail-treatmentplans.tpl.html'
          },
          'consentforms': {
            controller: 'PatientDetailConsentformsCtrl',
            templateUrl: 'src/common/patient-detail-consent-forms.tpl.html'
          }
        }
      })
      .state('chart-detail-patient-view', {
        parent: 'chartnotes',
        url: '/chart-detail-patient-view/:encounterid',
        data: {
          roles: ['User']
        },
        resolve:{
          CanUpdateTreatmentAgreement: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdateTreatmentAgreement');
          },
        },
        views: {
          'chartnotes@patientdetail': {
            controller: 'EncounterDetailPatientViewCtrl',
            templateUrl: 'src/common/encounter-detail-patient-view.tpl.html'
          }
        },
        onEnter: function(CommunicationsService) {
          CommunicationsService.mute(true);
        },
        onExit: function(CommunicationsService) {
          CommunicationsService.mute(false);
        }
      })
      .state('chart-detail-new', {
        parent: 'chartnotes',
        url: '/chart-detail-new/:type',
        data: {
          roles: ['User']
        },
        resolve:{
          MultiLocationEnable:function(){
            return MultiLocationEnable;
          }
        },
        params:{providerId: null},
        views: {
          'chartnotes@patientdetail': {
            controller: 'EncounterDetailCtrl',
            templateUrl: 'src/common/encounter-detail.tpl.html'
          }
        }
      })
      .state('encounter-add', {
        parent: 'chartnotes',
        url: '/encounter-add',
        data: {
          roles: ['User']
        },
        views: {
          'chartnotes@patientdetail': {
            controller: 'EncounterAddCtrl',
            templateUrl: 'src/common/encounter-add.tpl.html'
          }
        }
      })
      .state('prescription-add', {
        parent: 'chart-detail',
        url: '/prescription-add',
        data: {
          roles: ['User']
        },
        views: {
          'chartnotes@patientdetail': {
            controller: 'PrescriptionAddCtrl',
            templateUrl: 'src/common/prescription-add.tpl.html'
          }
        }
      })
      .state('treatment-add', {
        parent: 'encounter-add',
        url: '/treatment-add',
        data: {
          roles: ['User']
        },
        views: {
          'chartnotes@patientdetail': {
            controller: 'TreatmentAddCtrl',
            templateUrl: 'src/common/treatment-add.tpl.html'
          }
        }
      })
      .state('treatmentplans', {
        parent: 'patientdetail',
        url: '/treatmentplans',
        data: {
          roles: ['User']
        },
        resolve:{
          CanUpdateEncounter: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdateEncounter');
          },
          CanRetrieveEncounter: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrieveEncounter');
          }
        },
        views: {
          'treatmentplans@patientdetail': {
            controller: 'PatientDetailTreatmentPlansCtrl',
            templateUrl: 'src/common/patient-detail-treatmentplans.tpl.html'
          }
        }
      })
      .state('photos', {
        parent: 'patientdetail',
        url: '/photos',
        data: {
          roles: ['User']
        },
        views: {
          'photos@patientdetail': {
            controller: 'PatientDetailPhotosCtrl',
            templateUrl: 'src/common/patient-detail-photos.tpl.html'
          }
        }
      })
      .state('photodetail', {
        parent: 'photos',
        url: '/:photoid',
        data: {
          roles: ['User']
        },
        resolve:{
          CanUpdateImage: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdateImage');
          }
        },
        views: {
          'photodetail@patientdetail': {
            controller: 'PatientDetailPhotoDetailCtrl',
            templateUrl: 'src/common/patient-detail-photo-detail.tpl.html'
          }
        }
      })
      .state('prescriptions', {
        parent: 'patientdetail',
        url: '/prescriptions',
        data: {
          roles: ['User']
        },
        resolve:{
          CanUpdateEncounter: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdateEncounter');
          },
          CanRetrieveEncounter: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrieveEncounter');
          },
          CanRetrievePrescription: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrievePrescription');
          }
        },
        views: {
          'prescriptions@patientdetail': {
            controller: 'PatientDetailPrescriptionsCtrl',
            templateUrl: 'src/common/patient-detail-prescriptions.tpl.html'
          }
        }
      })
      .state("files", {
        parent: "patientdetail",
        url: "/files",
        data: {
          roles: ["User"]
        },
        views: {
          "files@patientdetail": {
            component: "patientFiles",
          }
        }
      })
      // TODO: jjlorenzo remove, deprecated
      .state('filedetail', {
        parent: 'files',
        url: '/:fileid',
        data: {
          roles: ['User']
        },
        resolve:{
          CanUpdateFile: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdateFile');
          }
        },
        views: {
          'files@patientdetail': {
            controller: 'PatientDetailFileDetailCtrl',
            templateUrl: 'src/common/patient-detail-file-detail.tpl.html'
          }
        }
      })
      .state('detail-reports', {
        parent: 'patientdetail',
        url: '/reports',
        data: {
          roles: ['User']
        },
        views: {
          'detail-reports@patientdetail': {
            //controller: 'PatientDetailCtrl',
            templateUrl: 'src/common/patient-detail-reports.tpl.html'
          }
        }
      })
      .state('billing-base', {
        parent: 'patientdetail',
        url: '/billing',
        abstract:true,
        data: {
          roles: ['User']
        },
        resolve:{
          CanUpdateBilling: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdateBilling');
          },
          CanRetrieveInvoice: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrieveInvoice');
          },
          CanCreateInvoice: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanCreateInvoice');
          },
          CanRetrieveEncounter: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrieveEncounter');
          }
        },
        views: {
          'billing@patientdetail': {
            controller: 'PatientDetailBillingCtrl',
            templateUrl: 'src/common/patient-detail-billing.tpl.html'
          }
        }
      })
      .state('billing', {
        parent: 'billing-base',
        url: '',
        data: {
          roles: ['User']
        },
        resolve:{
          CanRetrieveEncounter: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrieveEncounter');
          },
          CanUpdateEncounter: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdateEncounter');
          }
        },
        views: {
          'treatments':{
            controller: 'PatientDetailTreatmentPlansCtrl',
            templateUrl: 'src/common/patient-detail-treatmentplans.tpl.html'
          }
        }
      })
      .state('invoicedetail-base', {
        parent: 'billing',
        url: '/invoices/:invoiceid',
        abstract:true,
        data: {
          roles: ['User']
        },
        params:{
          treatmentsToInvoice: null
        },
        resolve:{
          CanCreateInvoice: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanCreateInvoice');
          },
          CanRetrieveEncounter: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrieveEncounter');
          }
        },
        views: {
          'billing@patientdetail': {
            controller: 'BillingInvoicesDetailCtrl',
            templateUrl: 'src/common/billing-invoices-detail.tpl.html'
          }
        }
      })
      .state('invoicedetail', {
        parent: 'invoicedetail-base',
        url: '',
        data: {
          roles: ['User']
        },
        resolve:{
          CanUpdateBilling: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdateBilling');
          },
          CanRetrieveInvoice: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrieveInvoice');
          },
          CanCreateInvoice: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanCreateInvoice');
          },
          CanRetrieveEncounter: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrieveEncounter');
          }
        },
        views: {
          'billing': {
            controller: 'PatientDetailBillingCtrl',
            templateUrl: 'src/common/patient-detail-billing.tpl.html'
          }
        }
      })
      .state('newclaimdetail-base', {
        parent: 'insurance',
        abstract:true,
        url: '/claims/new/treatingprovider/:treatingproviderid/location/:locationid',
        data: {
          roles: ['User']
        },
        resolve:{
          CanCreateClaim: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanCreateClaim');
          },
          CanUpdateClaim: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdateClaim');
          }
        },
        views: {
          'insurance@patientdetail': {
            controller: 'BillingClaimsDetailCtrl',
            templateUrl: 'src/common/billing-claims-detail.tpl.html'
          }
        }
      })
      .state('newclaimdetail', {
        parent: 'newclaimdetail-base',
        url: '',
        data: {
          roles: ['User']
        },
        views: {
          'insurance': {
            controller: 'PatientDetailInsuranceCtrl',
            templateUrl: 'src/common/patient-detail-insurance.tpl.html'
          }
        }
      })
      .state('claimdetail-base', {
        parent: 'insurance',
        abstract:true,
        url: '/claims/:claimid',
        data: {
          roles: ['User']
        },
        resolve:{
          CanCreateClaim: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanCreateClaim');
          },
          CanUpdateClaim: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdateClaim');
          }
        },
        views: {
          'insurance@patientdetail': {
            controller: 'BillingClaimsDetailCtrl',
            templateUrl: 'src/common/billing-claims-detail.tpl.html'
          }
        }
      })
      .state('claimdetail', {
        parent: 'claimdetail-base',
        url: '',
        data: {
          roles: ['User']
        },
        views: {
          'insurance': {
            controller: 'PatientDetailInsuranceCtrl',
            templateUrl: 'src/common/patient-detail-insurance.tpl.html'
          }
        }
      })
      .state('insurance', {
        parent: 'patientdetail',
        url: '/insurance',
        data: {
          roles: ['User']
        },
        resolve:{
          CanUpdateInsurance: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdateInsurance');
          },
          CanCreateClaim: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanCreateClaim');
          },
          CanRetrieveClaim: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrieveClaim');
          },
          CanRetrieveEncounter: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrieveEncounter');
          }
        },
        views: {
          'insurance@patientdetail': {
            controller: 'PatientDetailInsuranceCtrl',
            templateUrl: 'src/common/patient-detail-insurance.tpl.html'
          }
        }
      })
      .state('insurance-all', {
        parent: 'settings',
        url: '/insurance',
        data: {
          roles: ['User']
        },
        resolve:{
          CanUpdateInsurance: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanUpdateInsurance');
          },
          CanCreateClaim: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanCreateClaim');
          },
          CanRetrieveClaim: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrieveClaim');
          },
          CanRetrieveEncounter: function(AuthorizationService){
            return AuthorizationService.hasFrontendPermission('CanRetrieveEncounter');
          }
        },
        views: {
          'task-view@home': {
            templateUrl: 'src/common/patient-detail-insurance.tpl.html',
            controller: 'PatientDetailInsuranceCtrl'
          }
        }

      })
      .state('messages', {
        parent: 'home',
        url: '/messages',
        data: {
          roles: ['User']
        },
        views: {
          'task-view@home': {
            controller: 'MessagesCtrl',
            templateUrl: 'src/common/messages.tpl.html'
          }
        }
      })
      .state('reminders', {
        parent:    'home',
        url:       '/reminders/',
        resolve: {
          remindersList: function(PrincipalService) {
            return {
              filters: {
                encounter__patient__practice_id: PrincipalService.getPracticeId()
              },
              showPatientName: true,
            }
          },
        },
        views: {
          'task-view@home': {
            component: 'remindersList',
          }
        },
      })
      .state('reports', {
        parent: 'home',
        url: '/reports',
        data: {
          roles: ['User']
        },
        views: {
          'task-view@home': {
            controller: 'ReportsCtrl',
            templateUrl: 'src/common/reports.tpl.html'
          }
        }
      })
      .state('analytics', {
        parent: 'home',
        url: '/analytics',
        data: {
          roles: ['User']
        },
        views: {
          'task-view@home': {
            controller: 'AnalyticsCtrl',
            templateUrl: 'src/common/analytics.tpl.html'
          }
        }
      })
      .state('analytics-overview', {
        parent: 'analytics',
        url: '/overview',
        data: {
          roles: ['User']
        },
        views: {
          'analytics-overview@analytics': {
            controller: 'AnalyticsOverviewCtrl',
            templateUrl: 'src/common/analytics-overview.tpl.html'
          }
        }
      })
      .state('analytics-productivity', {
        parent: 'analytics',
        url: '/productivity',
        data: {
          roles: ['User']
        },
        views: {
          'analytics-productivity@analytics': {
            controller: 'AnalyticsProductivityCtrl',
            templateUrl: 'src/common/analytics-productivity.tpl.html'
          }
        }
      })
      .state('analytics-productivity-treatments-detail', {
        parent: 'analytics-productivity',
        url: '/treatments/completed',
        data: {
          roles: ['User']
        },
        views: {
          'analytics-productivity@analytics': {
            controller: 'AnalyticsProductivityTreatmentsDetailCtrl',
            templateUrl: 'src/common/analytics-productivity-treatments-detail.tpl.html'
          }
        }
      })
      .state('analytics-patients', {
        parent: 'analytics',
        url: '/patientsandreceivables',
        data: {
          roles: ['User']
        },
        views: {
          'analytics-patients@analytics': {
            controller: 'AnalyticsPatientsCtrl',
            templateUrl: 'src/common/analytics-patients.tpl.html'
          }
        }
      })
      .state('analytics-patients-ar-current', {
        parent: 'analytics-patients',
        url: '/ar/patientaging',
        data: {
          roles: ['User']
        },
        views: {
          'analytics-patients@analytics': {
            controller: 'AnalyticsPatientsArCurrentCtrl',
            templateUrl: 'src/common/analytics-patients-ar-current.tpl.html'
          }
        }
      })
      .state("analytics-patients-aging", {
        parent: "analytics-patients",
        url: "/ar/patients-aging",
        data: {
          roles: ["User"]
        },
        views: {
          "analytics-patients@analytics": {
            component: "analyticsPatientsAging",
          }
        }
      })
      .state('analytics-patients-payments-reconciliation', {
        parent: 'analytics-patients',
        url: '/ar/paymentsreconciliation',
        data: {
          roles: ['User']
        },
        views: {
          'analytics-patients@analytics': {
            controller: 'AnalyticsPatientsPaymentsReconciliation',
            templateUrl: 'src/common/analytics-patients-payments-reconciliation.tpl.html'
          }
        }
      })
      .state('analytics-active-claims', {
        parent: 'analytics-patients',
        url: '/ar/claimsactive',
        data: {
          roles: ['User']
        },
        views: {
          'analytics-patients@analytics': {
            controller: 'AnalyticsActiveClaimsCtrl',
            templateUrl: 'src/common/analytics-active-claims.tpl.html'
          }
        }
      })
      .state('analytics-active-claims-next', {
        parent: 'analytics-patients',
        url: '/ar/active-claims',
        data: {
          roles: ['User']
        },
        views: {
          'analytics-patients@analytics': {
            component: "analyticsActiveClaims",
          }
        }
      })
      .state('analytics-services', {
        parent: 'analytics',
        url: '/services',
        data: {
          roles: ['User']
        },
        views: {
          'analytics-services@analytics': {
            controller: 'AnalyticsServicesCtrl',
            templateUrl: 'src/common/analytics-services.tpl.html'
          }
        }
      })
      .state('signin', {
        parent: 'signin-layout',
        url: '/signin',
        data: {
          roles: []
        },
        views: {
          'content@signin-layout': {
            templateUrl: 'src/common/signin.tpl.html',
            controller: 'SignInCtrl'
          }
        }
      })
      .state('signin-verify', {
        parent: 'signin',
        url: '/verify',
        params: {'verificationToken': null},
        data: {
          roles: []
        },
        views: {
          'content@signin-layout': {
            templateUrl: 'src/common/signin-verify.tpl.html',
            controller: 'SignInVerifyCtrl'
          }
        }
      })
      .state('signout', {
        parent: 'signin-layout',
        url: '/signout',
        data: {
          roles: []
        },
        views: {
          'content@signin-layout': {
            templateUrl: 'src/common/signin.tpl.html',
            controller: 'SignInCtrl'
          }
        }
      })
      .state('password', {
        parent: 'signin-layout',
        url: '/password',
        abstract: true,
        data: {
          roles: []
        },
        views: {
          'content@signin-layout': {
            template: '<ui-view/>'
          }
        }
      })
      .state('password-reset', {
        parent: 'password',
        url: '/reset',
        views: {
          'content@signin-layout': {
            templateUrl: 'src/common/password-reset.tpl.html',
            controller: 'PasswordResetCtrl'
          }
        }
      })
      .state('password-reset-confirm', {
        parent: 'password',
        url: '/reset/confirm/{uid}/{token}',
        views: {
          '': {
            templateUrl: 'src/common/password-change.tpl.html',
            controller: 'PasswordResetConfirmCtrl'
          }
        }
      })
      .state('password-invite-set', {
        parent: 'password',
        url: '/invite/set/{uid}/{token}',
        views: {
          '': {
            templateUrl: 'src/common/password-invite-set.tpl.html',
            controller: 'PasswordResetConfirmCtrl'
          }
        }
      })
      .state('password-change', {
        parent: 'password',
        url: '/change',
        data: {
          roles: ['User']
        },
        views: {
          '': {
            templateUrl: 'src/common/password-change.tpl.html',
            controller: 'PasswordChangeCtrl'
          }
        }
      })
      .state('myprofile', {
        parent: 'home',
        url: '/profile',
        data: {
          roles: ['User']
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/profile.tpl.html',
            controller: 'ProfileCtrl'
          }
        }
      })
      .state('reviews', {
        parent: 'home',
        url: '/reviews',
        data: {
          roles: ['User']
        },
        resolve: {
          CanListReview: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListReview');
          },
          CanRetrieveReview: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrieveReview');
          },
          CanUpdateReview: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateReview');
          }
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/reviews.tpl.html',
            controller: 'ReviewsCtrl'
          }
        }
      })
      .state('support', {
        parent: 'home',
        url: '/support',
        data: {
          roles: ['User']
        },
        resolve: {
          CanCreateSupportPracticeTickets: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanCreateSupportPracticeTickets');
          },
          CanCreateSupportUserTickets: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanCreateSupportUserTickets');
          },
          CanListSupportPracticeTickets: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListSupportPracticeTickets');
          },
          CanListSupportUserTickets: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListSupportUserTickets');
          },
          CanRetrieveSupportPracticeTickets: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrieveSupportPracticeTickets');
          },
          CanRetrieveSupportUserTickets: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrieveSupportUserTickets');
          },
          CanUpdateSupportUserTickets: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateSupportUserTickets');
          }
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/support.tpl.html',
            controller: 'SupportCtrl'
          }
        }
      })
      .state('settings', {
        parent: 'home',
        url: '/settings',
        data: {
          roles: ['User']
        },
        resolve: {
          CanRetrievePracticeSetting: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrievePracticeSetting');
          },
          CanListUser: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListUser');
          },
          CanListPrescriptionTemplate: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListPrescriptionTemplate');
          },
          CanListFeeProfile: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListFeeProfile');
          },
          CanListInsurancePlan: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListInsurancePlan');
          },
          CanListMedicalAlert: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListMedicalAlert');
          },
          CanListMessageTemplate: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListMessageTemplate');
          },
          CanListInsurancePayersInNetworkProvider: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListInsurancePayersInNetworkProvider');
          },
          CanListEncounterTemplate: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListEncounterTemplate');
          },
          CanRetrieveBillingSetting: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrieveBillingSetting');
          },
          CanRetrieveLocationOperatory: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrieveLocationOperatory');
          },
          CanAccessConsentFormTemplate: function(AuthorizationService) {
            return (
              AuthorizationService.hasFrontendPermission('CanCreateConsentFormTemplate') ||
              AuthorizationService.hasFrontendPermission('CanDeleteConsentFormTemplate') ||
              AuthorizationService.hasFrontendPermission('CanListConsentFormTemplate') ||
              AuthorizationService.hasFrontendPermission('CanRetrieveConsentFormTemplate') ||
              AuthorizationService.hasFrontendPermission('CanUpdateConsentFormTemplate')
            );
          },
          CanAccessReferralPractice: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission("CanListReferralPractice") && (
              AuthorizationService.hasFrontendPermission("CanCreateReferralPractice") ||
              AuthorizationService.hasFrontendPermission("CanDeleteReferralPractice") ||
              AuthorizationService.hasFrontendPermission("CanRetrieveReferralPractice") ||
              AuthorizationService.hasFrontendPermission("CanUpdateReferralPractice")
            )
          },
          CanAccessReferralUser: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission("CanListReferralUser") && (
              AuthorizationService.hasFrontendPermission("CanCreateReferralUser") ||
              AuthorizationService.hasFrontendPermission("CanDeleteReferralUser") ||
              AuthorizationService.hasFrontendPermission("CanRetrieveReferralUser") ||
              AuthorizationService.hasFrontendPermission("CanUpdateReferralUser")
            )
          },
          CanRetrievePracticeData: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrievePracticeData');
          },
          CanListProcedure: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListProcedure');
          }
        },
        views: {
          'task-view@home': {
            templateUrl: 'src/common/settings.tpl.html',
            controller: 'SettingsCtrl'
          }
        }
      })
      .state("settings-accounting-and-billing", {
        parent: "settings",
        url: "/accounting-and-billing/",
        data: {
          roles: ["User"],
        },
        views: {
          "content@home": {
            template: "<ui-view></ui-view>",
          }
        },
      })
      .state("settings-accounting-and-billing.feeprofile-list", {
        url: "feeprofiles/",
        component: "routeSettingsAccountingAndBillingFeeprofileList",
        data: {
          search: false,
        },
      })
      .state("settings-accounting-and-billing.feeprofile-detail", {
        url: "feeprofiles/{id}/",
        component: "routeSettingsAccountingAndBillingFeeprofileDetail",
        data: {
          search: function($transition$) {
            return false && $transition$.params().id !== "new"
          },
        },
        resolve: {
          id: function($transition$) {
            return $transition$.params().id
          },
        },
      })
      .state("settings-accounting-and-billing.supercode-list", {
        url: "supercode",
        component: "routeSettingsAccountingAndBillingSuperCodeList",
        data: {
          search: false,
        },
      })
      .state("settings-accounting-and-billing.supercode-detail", {
        url: "supercode/{id}",
        component: "routeSettingsAccountingAndBillingSuperCodeDetail",
        data: {
          search: function($transition$) {
            return false && $transition$.params().id !== "new"
          },
        },
        resolve: {
          id: function($transition$) {
            return $transition$.params().id
          },
        },
      })
      .state("settings-accounting-and-billing-insurance-plan", {
        parent: "settings",
        url: "/accounting-and-billing/insurance-plan",
        data: {
          roles: ["User"],
        },
        views: {
          "content@home": {
            component: "settingsAccountingAndBillingInsurancePlan"
          }
        }
      })
      .state('settings-account', {
        parent: 'settings',
        url: '/account-settings',
        data: {
          roles: ['User']
        },
        resolve: {
          CanCreateGLAccount: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanCreateGLAccount');
          },
          CanUpdateGLAccount: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateGLAccount');
          }
        },
        views: {
          'task-view@home': {
            templateUrl: 'src/common/settings-account.tpl.html',
            controller: 'AccountSettingsCtrl'
          }
        }
      })
      .state('settings-billing', {
        parent: 'settings',
        url: '/billing-settings',
        data: {
          roles: ['User']
        },
        resolve: {
          CanUpdateBillingSetting: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateBillingSetting');
          }
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-billing.tpl.html',
            controller: 'BillingSettingsCtrl'
          }
        }
      })
      .state("settings-user-activity", {
        parent: "settings",
        url: "/users-activity/{userId:int}/",
        data: {
          roles: ["User"]
        },
        resolve: {
          user: function (AuthenticationService, $transition$) {
            return AuthenticationService.getUserAccount($transition$.params().userId).then(success, errorObj);
          },
          params: function ($transition$) {
            return {
              user_id: $transition$.params().userId,
              user_class: 'authentication.Account',
              page_size: 50,
            };
          },
          events_meta: function (PracticeService, params) {
            return PracticeService.system_events_meta(params).then(success, errorObj);
          },
          events: function (PracticeService, params) {
            return PracticeService.system_events(params).then(success, errorCol);
          },
          CanListUserActivity: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListUserActivity');
          }
        },
        views: {
          'content@home': {
            component: "settingsUsersActivity",
          }
        }
      })
      .state('settings-chart-templates', {
        parent: 'settings',
        url: '/chart-template-settings',
        data: {
          roles: ['User']
        },
        resolve: {
          CanDeleteEncounterTemplate: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanDeleteEncounterTemplate');
          }
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-chart-templates.tpl.html',
            controller: 'ChartTemplatesSettingsCtrl'
          }
        }
      })
      .state('settings-consent-forms-templates', {
        parent: 'settings',
        url: '/consent-forms-templates',
        data: {
          roles: ['User']
        },
        resolve: {
          CanCreateConsentFormTemplate: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanCreateConsentFormTemplate');
          },
          CanDeleteConsentFormTemplate: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanDeleteConsentFormTemplate');
          },
          CanListConsentFormTemplate: function (AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListConsentFormTemplate');
          },
          CanRetrieveConsentFormTemplate: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrieveConsentFormTemplate');
          },
          CanUpdateConsentFormTemplate: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateConsentFormTemplate');
          },
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-consent-forms-templates.tpl.html',
            controller: 'ConsentFormsSettingsCtrl'
          }
        }
      })
      .state('settings-chart-notes', {
        parent: 'settings',
        url: '/chart-notes-settings',
        data: {
          roles: ['User']
        },
        resolve: {
          CanCreateFeeProfile: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanCreateFeeProfile');
          },
          CanDeleteFeeProfile: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanDeleteFeeProfile');
          },
          CanUpdateFeeProfile: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateFeeProfile');
          }
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-chart-notes.tpl.html',
            controller: 'ChartNotesSettingsCtrl'
          }
        }
      })
      .state('settings-imaging', {
        parent: 'settings',
        url: '/imaging-settings',
        data: {
          roles: ['User']
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-imaging.tpl.html',
            controller: 'ImagingSettingsCtrl'
          }
        }
      })
      .state('settings-practice', {
        parent: 'settings',
        url: '/practice-settings',
        data: {
          roles: ['User']
        },
        resolve:{
          CanUpdatePracticeSetting: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdatePracticeSetting');
          }

        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-practice.tpl.html',
            controller: 'PracticeSettings'
          }
        }
      })
      .state('settings-prescriptions-templates', {
        parent: 'settings',
        url: '/prescriptions-templates-settings',
        data: {
          roles: ['User']
        },
        resolve:{
          CanDeletePrescriptionTemplate: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanDeletePrescriptionTemplate');
          },
          CanUpdatePrescriptionTemplate: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdatePrescriptionTemplate');
          },
          CanCreatePrescriptionTemplate: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanCreatePrescriptionTemplate');
          },
          CanRetrievePrescriptionTemplate: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrievePrescriptionTemplate');
          }
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-prescriptions-templates.tpl.html',
            controller: 'PrescriptionsTemplatesSettingsCtrl'
          }
        }
      })
      .state('settings-text-templates', {
        parent: 'settings',
        url: '/settings-text-templates',
        data: {
          roles: ['User']
        },
        resolve: {
          CanRetrieveMessageTemplate: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrieveMessageTemplate');
          },
          CanUpdateMessageTemplate: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateMessageTemplate');
          }
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-text-templates.tpl.html',
            controller: 'TextTemplatesSettingsCtrl'
          }
        }
      })
      .state('settings-text-template-edit', {
        parent: 'settings',
        url: '/settings-text-template-edit',
        params: {
          templateId: null,
        },
        data: {
          roles: ['User']
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-text-templates-edit.tpl.html',
            controller: 'TextTemplateEditSettingsCtrl'
          }
        }
      })
      .state('settings-insurance-plans', {
        parent: 'settings',
        url: '/settings-insurance-plans',
        data: {
          roles: ['User']
        },
        resolve:{
          CanRetrieveInsurancePlan: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrieveInsurancePlan');
          },
          CanCreateInsurancePlan: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanCreateInsurancePlan');
          },
          CanDeleteInsurancePlan: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanDeleteInsurancePlan');
          },
          CanUpdateInsurancePlan: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateInsurancePlan');
          }
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-insurance-plans.tpl.html',
            controller: 'InsurancePlansSettingsCtrl as insurancePlans'
          }
        }
      })
      .state('settings-insurance-payers-in-network-providers', {
        parent: 'settings',
        url: '/settings-insurance-payers-in-network-providers',
        data: {
          roles: ['User']
        },
        resolve:{
          CanUpdateInsurancePayersInNetworkProvider: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateInsurancePayersInNetworkProvider');
          }
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-insurance-payers-in-network-providers.tpl.html',
            controller: 'InsurancePayersInNetworkProvidersSettingsCtrl as insurancePayersInNetworkProviders'
          }
        }
      })
      .state('settings-procedures', {
        parent: 'settings',
        url: '/settings-procedures',
        data: {
          roles: ['User']
        },
        resolve:{
          CanListProcedure: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListProcedure');
          },
          CanUpdateProcedure: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateProcedure');
          }
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-procedures.tpl.html',
            controller: 'ProceduresSettingsCtrl'
          }
        }
      })
      .state('settings-practice-details', {
        parent: 'settings',
        url: '/practice-details-settings',
        data: {
          roles: ['User']
        },
        resolve: {
          CanUpdateLocationOperatory: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateLocationOperatory');
          },
          Locations: function(LocationsService){
            return LocationsService.all().then(success,errorObj);
          },
          MultiLocationEnable:function(){
            return MultiLocationEnable;
          }
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-practice-details.tpl.html',
            controller: 'PracticeDetailsSettingsCtrl'
          }
        }
      })
      .state('settings-practice-appointment-defaults', {
        parent: 'settings',
        url: '/settings-practice-appointment-defaults',
        data: {
          roles: ['User']
        },
        resolve: {
          CanUpdateLocationOperatory: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateLocationOperatory');
          },
          Locations: function(LocationsService){
            return LocationsService.all().then(success,errorObj);
          },
          MultiLocationEnable:function(){
            return MultiLocationEnable;
          }
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-practice-appointment-defaults.tpl.html',
            controller: 'PracticeAppointmentDefaultsCtrl'
          }
        }
      })
      .state('settings-schedule', {
        parent: 'settings',
        url: '/schedule-settings',
        data: {
          roles: ['User']
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-schedule.tpl.html',
            controller: 'ScheduleSettingsCtrl'
          }
        }
      })
      .state('settings-import-patients', {
        parent: 'settings',
        url: '/import-patients-settings',
        data: {
          roles: ['User']
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-import-patients.tpl.html',
            controller: 'ImportPatientsSettingsCtrl'
          }
        }
      })
      .state('settings-users', {
        parent: 'settings',
        url: '/users-settings',
        data: {
          roles: ['User']
        },
        resolve: {
          CanRetrieveUser: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanRetrieveUser');
          },
          CanUpdateUser: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateUser');
          },
          CanCreateUser: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanCreateUser');
          },
          CanListUserActivity: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanListUserActivity');
          },
          MultiLocationEnable:function(){
            return MultiLocationEnable;
          }
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-users.tpl.html',
            controller: 'UsersSettingsCtrl'
          }
        }
      })
      .state('settings-reports-templates', {
        parent: 'settings',
        url: '/reports-templates-settings',
        data: {
          roles: ['User']
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-reports-templates.tpl.html',
            controller: 'ReportsTemplatesSettingsCtrl'
          }
        }
      })
      .state('settings-two-factor', {
        parent: 'settings',
        url: '/two-factor',
        data: {
          roles: ['User']
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-two-factor.tpl.html',
            controller: 'TwoFactorCtrl'
          }
        }
      })
      .state('settings-two-factor-setup', {
        parent: 'settings-two-factor',
        url: '/setup',
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-two-factor-setup.tpl.html',
            controller: 'TwoFactorSetupCtrl'
          }
        }
      })
      .state('settings-two-factor-setup-method', {
        parent: 'settings-two-factor-setup',
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-two-factor-setup-method.tpl.html',
            controller: 'TwoFactorSetupMethodCtrl'
          }
        }
      })
      .state('settings-two-factor-setup-app', {
        parent: 'settings-two-factor-setup-method',
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-two-factor-setup-app.tpl.html',
            controller: 'TwoFactorSetupAppCtrl'
          }
        }
      })
      .state('settings-two-factor-setup-phone', {
        parent: 'settings-two-factor-setup-method',
        params: {method: null},
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-two-factor-setup-phone.tpl.html',
            controller: 'TwoFactorSetupPhoneCtrl'
          }
        }
      })
      .state('settings-two-factor-setup-phone-verify', {
        parent: 'settings-two-factor-setup-phone',
        params: {device: null},
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-two-factor-setup-phone-verify.tpl.html',
            controller: 'TwoFactorSetupPhoneVerifyCtrl'
          }
        }
      })
      .state('settings-two-factor-setup-backup-phone', {
        parent: 'settings-two-factor',
        url: '/backup/phone',
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-two-factor-setup-phone.tpl.html',
            controller: 'TwoFactorSetupPhoneCtrl'
          }
        }
      })
      .state('settings-two-factor-setup-backup-phone-verify', {
        parent: 'settings-two-factor-setup-backup-phone',
        url: '/verify',
        params: {device: null},
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-two-factor-setup-phone-verify.tpl.html',
            controller: 'TwoFactorSetupPhoneVerifyCtrl'
          }
        }
      })
      .state('settings-two-factor-setup-backup-app', {
        parent: 'settings-two-factor',
        url: '/backup/app',
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-two-factor-setup-app.tpl.html',
            controller: 'TwoFactorSetupAppCtrl'
          }
        }
      })
      .state('settings-two-factor-setup-backup-tokens', {
        parent: 'settings-two-factor',
        params: {backupTokens: null},
        url: '/backup/tokens',
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-two-factor-setup-backup-tokens.tpl.html',
            controller: 'TwoFactorSetupBackupTokensCtrl'
          }
        }
      })
      .state('settings-security-questions', {
        parent: 'settings',
        url: '/security/questions',
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-security-questions.tpl.html',
            controller: 'SecurityQuestionsCtrl'
          }
        }
      })
      .state('settings-medical-alerts', {
        parent: 'settings',
        url: '/medical-alerts',
        data: {
          roles: ['User']
        },
        resolve: {
          CanUpdateMedicalAlert: function(AuthorizationService) {
            return AuthorizationService.hasFrontendPermission('CanUpdateMedicalAlert');
          }
        },
        views: {
          'content@home': {
            templateUrl: 'src/common/settings-medical-alerts.tpl.html',
            controller: 'MedicalAlertsSettingsCtrl'
          }
        }
      })
      .state('howto', {
        parent: 'home',
        url: '/howto',
        data: {
          roles: ['User']
        },
        views: {
          'task-view@home': {
            templateUrl: 'src/common/howto.tpl.html',
            controller: 'HowtoCtrl'
          }
        }
      })
      .state('faq', {
        parent: 'home',
        url: '/faq',
        data: {
          roles: ['User']
        },
        views: {
          'task-view@home': {
            templateUrl: 'src/common/faq.tpl.html',
            controller: 'FaqCtrl'
          }
        }
      })
      .state('releases', {
        parent: 'home',
        url: '/releases',
        data: {
          roles: ['User']
        },
        views: {
          'task-view@home': {
            templateUrl: 'src/common/releases.tpl.html',
            controller: 'ReleasesCtrl'
          }
        }
      })
      .state('restricted', {
        parent: 'signin-layout',
        url: '/restricted',
        data: {
          roles: ['Admin']
        },
        views: {
          'content@signin-layout': {
            templateUrl: 'src/common/restricted.tpl.html'
          }
        }
      })
      .state('accessdenied', {
        parent: 'signin-layout',
        url: '/denied',
        data: {
          roles: []
        },
        views: {
          'content@signin-layout': {
            templateUrl: 'src/common/denied.tpl.html'
          }
        }
      });

    $urlRouterProvider.otherwise('/');

  }

  function run(
    $http,
    $localStorage,
    $location,
    $sessionStorage,
    $rootScope,
    $state,
    $transitions,
    $uibModalStack,
    AuthorizationService,
    DomainService,
    Idle,
    Keepalive,
    NotificationService,
    PrincipalService,
    SessionStorageService,
    WebsocketService
  ) {

    try {
      if (environment.RAVEN_CONFIG) {
        var raven = Raven.config(environment.RAVEN_CONFIG.dsn, environment.RAVEN_CONFIG.config)
        raven.addPlugin(Raven.Plugins.Angular)
        raven.addPlugin(Raven.Plugins.Console)
        raven.install()
      }
    }
    catch (e) {
      console.error(e)
    }

    if (PrincipalService.isAuthenticated() ) {
      WebsocketService.connect('/ws/client');
    }

    $http.defaults.xsrfHeaderName = 'X-CSRFToken';
    $http.defaults.xsrfCookieName = 'csrftoken';

    $rootScope.isAnonymous = PrincipalService.isAnonymous;
    $rootScope.isAuthenticated = PrincipalService.isAuthenticated;
    $rootScope.swalOpen = false;
    $rootScope.title = 'Dental EMR';
    localStorage.removeItem("inLockScreen")

    // if the user is authenticated for the initial state, start the watch
    PrincipalService.identity().then(
      function(identity) {
        if (identity) {
          Idle.watch()
          Keepalive.start()
        }
      }
    )

    $rootScope.getdefaultIdle = function() {
      return defaultIdle
    }

    $rootScope.getIdle = function() {
      return Idle.getIdle() + 60
    }

    $rootScope.multiplyIdle = function(idleMultiplier, save) {
      var idle = defaultIdle * idleMultiplier
      Idle.setIdle(idle - 60)
      if (save) {
        $localStorage.idleMultiplier = idleMultiplier
      }
      return idle
    }

    $transitions.onStart({}, function(trans) {
      var from = trans.$from();
      var to = trans.$to();
      $rootScope.toState = to;
      $rootScope.toStateParams = trans.params();
      if (PrincipalService.isIdentityResolved()) {
        AuthorizationService.authorize()
      }
      NotificationService.clear()
      if (from.url) {
        // keep track of the last state
        SessionStorageService.lastURLState = from.url
      }
      if (to.url) {
        SessionStorageService.toURLState = to.url
      }
    });

    $transitions.onSuccess({}, function(trans) {
      var to = trans.$to();
      var tokenObj = {};
      var injector = trans.injector();
      getResolvables(to, tokenObj, injector);
      $rootScope.resolved = tokenObj;
    })

    function getResolvables(state, tokenObj, injector) {
      state.resolvables.forEach(function(x) {
        tokenObj[x.token] = injector.get(x.token);
      });
      if (state.parent) {
        getResolvables(state.parent, tokenObj, injector);
      }
    }

    // $rootScope.$on("IdleStart", function() {
    //   console.log("IdleStart")
    // })

    $rootScope.$on("IdleWarn", function(event, countdown) {
      // console.log("IdleWarn", event, countdown)
      // Respond to idle service events
      $("h3.idle-timer-countdown").text(countdown)
      // When the screen is locked we don't need to go to the idle
      // TODO jjlorenzo: replace localStorage for $localStorage
      if (localStorage.getItem("inLockScreen") !== "true") {
        $uibModalStack.dismissAll()
        if (PrincipalService.isAuthenticated() && !$rootScope.swalOpen) {
          // Store the current path in local storage so we can go back there if they login again
          $sessionStorage.redirectOnLogin = {"name": $rootScope.toState.name, "params": $rootScope.toStateParams}
          swal(
            {
              title: "Are you still here?",
              html: 'As a security measure you will be automatically logged out in <h3 class="idle-timer-countdown">' + countdown + '</h3> seconds.',
              type: "warning",
              timer: 1000 * countdown,
              confirmButtonText: "Wait, I'm still here!",
              confirmButtonColor: "#43A047"
            },
            function() {
              $rootScope.swalOpen = false
            }
          )
          $rootScope.swalOpen = true;
        }
      }
    })

    $rootScope.$on("IdleTimeout", function() {
      // User has not responded to the "Are you still here?" warning
      // When the screen is locked we don't need to go to the idle
      if (localStorage.getItem("inLockScreen") !== "true") {
        // Restart the idle service
        Idle.unwatch()
        Idle.setIdle(defaultIdle * $localStorage.idleMultiplier - 60) // after unwatch so Idle.running = false
        Keepalive.stop()
        $rootScope.swalOpen = false
        // Reset page title back to default
        $rootScope.title = "Dental EMR"
        PrincipalService.authenticate(null)
        $http.post("/api/v1/auth/logout/").finally(
          function() {
            $state.go("signin")
          }
        )
      }
    })

    // $rootScope.$on("IdleEnd", function() {
    //   console.log("IdleEnd")
    // })

    // $rootScope.$on("Keepalive", function() {
    //   console.log("Keepalive")
    // })

    $rootScope.$on("signinchange", function() {
      var identity = PrincipalService.getIdentity();
      if (identity !== "") {
        // After we've received the user identity info,
        // Raven.setUserContext({
        //   email: identity.email,
        //   id: identity.id,
        // })
        // if(SessionStorageService.toURLState !== '/eula' && PrincipalService.isEulaAccepted() !== true) {
        //   $location.path('/home/eula');
        //   return;
        // }
        if (SessionStorageService.toURLState !== "/wizard" && PrincipalService.getWizardCompleted() !== true) {
          $location.path("/home/wizard")
        }
      }
    })

    $rootScope.$on("startIdleWatch", function() {
      // emited after login
      // Idle.setIdle(defaultIdle * $localStorage.idleMultiplier - 60)
      Idle.watch()
      Keepalive.start()
    });

  }

  angular.module('app', [
    'ngSanitize',
    'ngAnimate',
    'ngMessages',
    'ui.select',
    'ui.router',
    'ngCookies',
    'ui.bootstrap',
    'ui.grid',
    'ui.grid.selection',
    'ui.grid.resizeColumns',
    'ui.grid.exporter',
    'ui.grid.grouping',
    'ui.grid.autoResize',
    'ui.scroll',
    'ui.scroll.grid',
    'ui.mask',
    'ui.slider',
    'ui.utils.masks',
    'dentalemr-react.components',
    'dentalemr-react.services',
    'toastr',
    'angularValidator',
    'angularSigPad',
    'ngIdle',
    'mgo-angular-wizard',
    'ngFileUpload',
    'common.directives.angularTreeviewer',
    // 'common.services.scheduleClass',
    // 'common.services.operatory',
    'angularForteHandler',
    'chart.js',
    'angular-spinkit',
    'ngStorage',
    'ngFilesizeFilter',
    // 'ngRaven',
    'common.directives.pattern-model-overwrite',
    'common.directives.uiSelect',
    'common.components',
    'common.decorators.$q',
    'common.directives.backbutton',
    'common.directives.imageEditing',
    'common.directives.yes-no-button',
    'common.directives.date-input',
    'common.directives.signature-input',
    'common.directives.ssn-input',
    'common.directives.phone-input',
    'common.directives.phone-input-new',
    'common.directives.state-input',
    'common.directives.zip-input',
    'common.directives.calibrationTool',
    'common.directives.password-strength-input',
    'common.directives.stringtonumber',
    'common.directives.enter-input',
    'common.directives.ng-currency',
    'common.directives.datepicker',
    'common.filters.height',
    'common.filters.hide-phone',
    'common.filters.props-filter',
    'common.directives.convert-to-number',
    'common.directives.number-range-limit',
    'common.directives.stop-click-propagation',
    'common.directives.angularjsFullCalendar',
    'common.navbar',
    'common.task-select',
    'common.schedule',
    'common.schedule-recurring-add',
    'common.schedule-fullcalendar',
    'common.schedule-multicolumn',
    'common.schedule-appointment-add',
    'common.schedule-timeblock-add',
    'common.schedule-background-add',
    'common.schedule-confirm-ctrl',
    'common.schedule-settings-ctrl',
    'common.messages',
    'common.reminders',
    'common.reports',
    'common.appointment-reminder',
    'common.analytics',
    'common.analytics-overview',
    'common.analytics-productivity',
    'common.analytics-productivity-treatments-detail',
    'common.analytics-patients',
    'common.analytics-patients-ar-current',
    'common.analytics-patients-payments-reconciliation',
    'common.analytics-services',
    'common.analytics-active-claims',
    'common.billing-invoices-detail',
    'common.billing-claims-detail',
    'common.profile',
    'common.settings',
    'common.settings-account',
    'common.settings-billing',
    'common.settings-chart-templates',
    'common.settings-consent-forms-templates',
    'common.settings-chart-notes',
    'common.settings-imaging',
    'common.settings-practice',
    'common.settings-practice-details',
    'common.settings-practice-appointment-defaults',
    'common.settings-schedule',
    'common.settings-import-patients',
    'common.settings-users',
    'common.settings-users-add',
    'common.settings-locations-add',
    'common.settings-reports-templates',
    'common.settings-two-factor',
    'common.settings-two-factor-setup',
    'common.settings-medical-alerts',
    'common.settings-prescriptions-templates',
    'common.settings-text-templates',
    'common.settings-insurance-plans',
    'common.settings-insurance-payers-in-network-providers',
    'common.settings-procedures',
    'common.settings-users-activity',
    'common.howto',
    'common.faq',
    'common.releases',
    'common.patient-add',
    'common.patient-add-payment-information',
    'common.patient-list',
    'common.charts',
    'common.signin',
    'common.signout',
    'common.flow-complete',
    'common.anonymous.patient-detail',
    'common.review-submit',
    'common.reviews',
    'common.support',
    'common.password-reset',
    'common.password-change',
    'common.patient-detail',
    'common.patient-detail-consentforms',
    'common.patient-detail-photos',
    'common.patient-image-detail',
    'common.patient-image-editor',
    'common.patient-files',
    'common.patient-detail-file-detail',
    'common.patient-detail-treatments',
    'common.patient-detail-encounters',
    'common.patient-detail-profile',
    'common.patient-detail-photo-detail',
    'common.encounter-add',
    'common.encounter-detail',
    'common.patient-detail-profile',
    'common.encounter-detail-patient-view',
    'common.patient-detail-billing',
    'common.patient-detail-insurance',
    'common.prescription-add',
    'common.patient-detail-prescriptions',
    'common.patient-interactions',
    'common.insurance-data-provided-by-patient',
    'common.treatment-add',
    'common.uploader', // deprecated
    'common.lockscreen',
    'common.eula',
    'common.thirdparty',
    'common.wizard',
    'common.provider-selection',
    'common.services.accounts',
    'common.services.analytics',
    'common.services.authorization',
    'common.services.billing',
    'common.services.authentication',
    'common.services.schedule',
    'common.services.schedulecommon',
    'common.services.timeblocks',
    'common.services.locations',
    'common.services.insurance',
    'common.services.principal',
    'common.services.patients',
    'common.services.encounters',
    'common.services.encounter-prescriptions',
    'common.services.encounter-treatments',
    'common.services.uploads', // deprecated
    'common.services.multifactorauthentication',
    'common.services.prescriptions',
    'common.services.treatments',
    'common.services.procedures',
    'common.services.templates',
    'common.services.image',
    'common.services.canvas',
    'common.services.reminders',
    'common.services.consentforms',
    'common.services.consentforms-template',
    'common.services.patient',
    'common.services.patientfiles',
    'common.services.patientimages',
    'common.services.calibrations',
    'common.services.password',
    'common.services.two-factor',
    'common.services.storage',
    'common.services.local-storage',
    'common.services.notification',
    'common.services.medical-alerts',
    'common.services.text-templates',
    'common.services.practice-service',
    'common.settings.security-questions',
    'common.services.patient-interaction-service',
    'common.services.text-formatters',
    'common.services.domain',
    'common.services.thirdpartylicenses',
    'common.services.sensorcalibrations',
    'common.services.reviews',
    'common.services.support',
    'common.services.websocket',
    'common.services.communications',
    'common.archives',
    'common.interceptors.http',
    'common.directives.google-places-autocomplete',
    'common.filters.sanitize',
    'common.filters.trustAsResourceUrl',
    'common.filters.yearsOld',
    'common.filters.signed-date',
    'templates',
    'referrals',
    'kiosk',
    'app.components',
    'app.routes',
    'app.services',
  ])
  .config(config)
  .run(run);

})();

(function() {
  "use strict"

  angular.module("app.components", [
  ])

})();

(function() {
  "use strict"

  angular.module("app.routes", [
  ])

})();

(function() {
  "use strict"

  angular.module("app.services", [
  ])

})();

(function () {
  "use strict"

  angular.module("app.components").component("globalLoading", {
    templateUrl: "src/app/components/global-loading.html",
    controller: function (AppServicesApi) {
      var $ctrl = this

      $ctrl.$onInit = function () {
        Object.defineProperty($ctrl, "isVisible", {
          get: function () {
            return AppServicesApi.inProgress > 0
          },
        })
      }
    }
  })
})();

(function () {
  "use strict"

  angular.module("app.components").component("globalSearch", {
      templateUrl: "src/app/components/global-search.html",
      bindings: {
        transition: "<",
      },
      controller: function ($scope, $transitions) {
        var $ctrl = this

        $ctrl.$onInit = function () {
          $ctrl.search = ""
          $ctrl.searchUnregisterListener = $scope.$watch("$ctrl.search", function (value) {
            $scope.$emit("search", value)
          })
          $ctrl.isVisible = isVisible($ctrl.transition)
          $ctrl.isVisibleUnregisterListener = $transitions.onBefore({}, function (transition) {
            $ctrl.isVisible = isVisible(transition)
          })
        }

        $ctrl.$onDestroy = function () {
          $ctrl.searchUnregisterListener()
          $ctrl.isVisibleUnregisterListener()
        }

        function isVisible(transition) {
          var value = transition.$to().data.search
          if (value !== undefined) {
            return angular.isFunction(value) ? value(transition) : value
          }
          return false
        }
      }
    })
})();

(function () {
  "use strict"

  angular.module("app.routes").component("routeSettingsAccountingAndBillingFeeprofileDetail", {
    templateUrl: "src/app/routes/settings/accounting-and-billing/feeprofile-detail.html",
    bindings: {
      canCreateFeeProfile: "<",
      canRetrieveFeeProfile: "<",
      canUpdateFeeProfile: "<",
      canDeleteFeeProfile: "<",
      id: "<",
    },
    controller: function (
      //
      $q,
      $rootScope,
      $scope,
      $state,
      AppServicesApi,
      AppServicesModal,
      NotificationService
    ) {
      var $ctrl = this

      $ctrl.$onInit = function () {
        $ctrl.DECIMAL_NUMBER = /^-?\d+(\.\d{1,2})?$/
        if ($ctrl.id === "new") {
          $ctrl.canDelete = false
          $ctrl.canSave = $ctrl.canCreateFeeProfile
          $ctrl.canRetrieve = false
          $ctrl.feeprofile = {}
          $ctrl.feeprofileHasInsuranceFeesUnregisterListener = $scope.$watch(
            "$ctrl.feeprofile.has_insurance_fees",
            function (new_value, old_val) {
              if (old_val === true && new_value === false && $ctrl.feeprofile.payer) {
                delete $ctrl.feeprofile.payer
              }
            }
          )
        } else {
          $ctrl.canDelete = $ctrl.canDeleteFeeProfile
          $ctrl.canSave = $ctrl.canUpdateFeeProfile
          $ctrl.canRetrieve = $ctrl.canRetrieveFeeProfile
          $ctrl.feeprofile = { id: $ctrl.id }
          $ctrl.feeprofileHasInsuranceFeesUnregisterListener = angular.noop
        }
        $ctrl.$retrieved = false
        $ctrl.options = null
        $ctrl.searchRegExp = null
        $ctrl.showFeesWithoutAmount = true
        $ctrl.searchRegExpUnregisterListener = $rootScope.$on("search", function (_, search) {
          if (search) {
            $ctrl.searchRegExp = new RegExp(search.replace(/[\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
          } else {
            $ctrl.searchRegExp = null
          }
        })
        AppServicesApi.feeprofile_options($ctrl.feeprofile.id).then(
          function (response) {
            $ctrl.options = response.data
            if ($ctrl.feeprofile.id) {
              $ctrl.retrieve()
            }
          },
          function () {
            $ctrl.options = {
              payers: [],
            }
          }
        )
      }

      $ctrl.$onDestroy = function () {
        $ctrl.feeprofileHasInsuranceFeesUnregisterListener()
        $ctrl.searchRegExpUnregisterListener()
      }

      $ctrl.create = function () {
        var props = ["name", "description", "is_archived", "has_insurance_fees", "payer"]
        var data = props.reduce(function (data, prop) {
          data[prop] = $ctrl.feeprofile[prop]
          return data
        }, {})
        AppServicesApi.feeprofile_create(data).then(
          function (response) {
            $state.go("^.feeprofile-detail", { id: response.data.id })
          },
          function (response) {
            NotificationService.error("Error Creating FeeSchedule", response)
          }
        )
      }

      $ctrl.retrieve = function () {
        $ctrl.$retrieved = false
        $q.all([
          AppServicesApi.feeprofile_retrieve($ctrl.feeprofile.id).then(
            function (response) {
              return response.data
            },
            function (response) {
              NotificationService.error("Error retrieving FeeSchedule", response)
              throw "self"
            }
          ),
          AppServicesApi.feeprofile_fees($ctrl.feeprofile.id).then(
            function (response) {
              return response.data.map(function (fee) {
                fee.$amounts = angular.copy(fee.amounts)
                return fee
              })
            },
            function (response) {
              NotificationService.error("Error retrieving FeeSchedule associated Fees", response)
              throw "self-fees"
            }
          ),
        ])
          .then(function (response) {
            $ctrl.feeprofile = response[0]
            $ctrl.feeprofile.fees = response[1]
          })
          .finally(function () {
            $ctrl.$retrieved = true
          })
      }

      $ctrl.update = function () {
        var feeprofile = ["name", "description", "is_archived"].reduce(function (feeprofile, prop) {
          feeprofile[prop] = $ctrl.feeprofile[prop]
          return feeprofile
        }, {})
        var fees = getModifiedFees()
        $q.all([
          AppServicesApi.feeprofile_update($ctrl.feeprofile.id, feeprofile).then(
            function (response) {
              return response.data
            },
            function (response) {
              NotificationService.error("Error updating FeeSchedule", response)
              throw "self"
            }
          ),
          AppServicesApi.feeprofile_fees_update($ctrl.feeprofile.id, fees).then(
            function (response) {
              return response.data
            },
            function (response) {
              NotificationService.error("Error updating FeeSchedule associated Fees", response)
              throw "self-fees"
            }
          ),
        ]).then(function (response) {
          angular.extend($ctrl.feeprofile, response[0])
          response[1].forEach(function (newfee) {
            var idx = $ctrl.feeprofile.fees.findIndex(function (curfee) {
              return curfee.id === newfee.id
            })
            if (idx !== -1) {
              angular.extend($ctrl.feeprofile.fees[idx], newfee, { $amounts: angular.copy(newfee.amounts) })
            }
          })
          NotificationService.success("Saved")
        })
      }

      $ctrl.destroy = function () {
        AppServicesModal.feeprofile_destroy($ctrl.feeprofile).then(
          function () {
            $state.go("^.feeprofile-list")
          },
          function (response) {
            if (response) {
              NotificationService.error("Error Deleting FeeSchedule", response)
            }
          }
        )
      }

      $ctrl.duplicate = function () {
        AppServicesModal.feeprofile_duplicate($ctrl.feeprofile).then(
          function (response) {
            $state.go("^.feeprofile-detail", { id: response.data.id })
          },
          function (response) {
            if (response && response !== "escape key press") {
              NotificationService.error("Error duplicating FeeSchedule", response)
            }
          }
        )
      }

      $ctrl.isFeeVisible = function (fee) {
        var byAmount = $ctrl.showFeesWithoutAmount
          ? true
          : $ctrl.feeprofile.has_insurance_fees
          ? fee.amounts.innetwork !== "0.00" || fee.amounts.outnetwork !== "0.00"
          : fee.amounts.patient !== "0.00"
        var bySearch = $ctrl.searchRegExp
          ? $ctrl.searchRegExp.test(fee.procedure.code) || $ctrl.searchRegExp.test(fee.procedure.name)
          : true
        return byAmount && bySearch
      }

      function getModifiedFees() {
        if ($ctrl.feeprofile.has_insurance_fees) {
          return $ctrl.feeprofile.fees.reduce(function (fees, fee) {
            if (
              fee.amounts.innetwork !== fee.$amounts.innetwork ||
              fee.amounts.outnetwork !== fee.$amounts.outnetwork
            ) {
              fees.push({
                id: fee.id,
                amounts: {
                  innetwork: fee.amounts.innetwork,
                  outnetwork: fee.amounts.outnetwork,
                },
              })
            }
            return fees
          }, [])
        }
        return $ctrl.feeprofile.fees.reduce(function (fees, fee) {
          if (fee.amounts.patient !== fee.$amounts.patient) {
            fees.push({
              id: fee.id,
              amounts: {
                patient: fee.amounts.patient,
              },
            })
          }
          return fees
        }, [])
      }
    },
  })
})();

(function () {
  "use strict"

  angular.module("app.routes").component("routeSettingsAccountingAndBillingFeeprofileList", {
    templateUrl: "src/app/routes/settings/accounting-and-billing/feeprofile-list.html",
    bindings: {
      canListFeeProfile: "<",
      canCreateFeeProfile: "<",
      canRetrieveFeeProfile: "<",
      canUpdateFeeProfile: "<",
      canDeleteFeeProfile: "<",
    },
    controller: function (
      //
      $rootScope,
      AppServicesApi,
      AppServicesModal,
      NotificationService
    ) {
      var $ctrl = this

      $ctrl.$onInit = function () {
        $ctrl.feeprofiles = []
        $ctrl.searchRegExp = null
        $ctrl.showArchived = false
        $ctrl.searchRegExpUnregisterListener = $rootScope.$on("search", function (_, search) {
          if (search) {
            $ctrl.searchRegExp = new RegExp(search.replace(/[\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
          } else {
            $ctrl.searchRegExp = null
          }
        })
        $ctrl.list()
      }

      $ctrl.$onDestroy = function () {
        $ctrl.searchRegExpUnregisterListener()
      }

      $ctrl.list = function () {
        AppServicesApi.feeprofile_list().then(
          function (response) {
            $ctrl.feeprofiles = response.data
          },
          function (response) {
            $ctrl.feeprofiles = null
            NotificationService.error("Error loading FeeSchedules", response)
          }
        )
      }

      $ctrl.retrieve = function (feeprofile, props) {
        if (props.includes("fees") && feeprofile.fees === undefined) {
          AppServicesApi.feeprofile_fees(feeprofile.id).then(
            function (response) {
              feeprofile.fees = response.data.filter(function (fee) {
                if (feeprofile.has_insurance_fees) {
                  return fee.amounts.innetwork !== "0.00" || fee.amounts.outnetwork !== "0.00"
                } else {
                  return fee.amounts.patient !== "0.00"
                }
              })
            },
            function (response) {
              feeprofile.fees = null
              NotificationService.error("Error loading FeeSchedule associated Fees", response)
            }
          )
        }
      }

      $ctrl.update = function (feeprofile, props) {
        var data = props.reduce(function (data, prop) {
          data[prop] = feeprofile[prop]
          return data
        }, {})
        AppServicesApi.feeprofile_update(feeprofile.id, data).then(
          function (response) {
            angular.extend(feeprofile, response.data)
            NotificationService.clear()
            NotificationService.success("Saved")
          },
          function (response) {
            NotificationService.error("Error updating FeeSchedule", response)
          }
        )
      }

      $ctrl.destroy = function (feeprofile) {
        AppServicesModal.feeprofile_destroy(feeprofile).then(
          function (id) {
            var idx = $ctrl.feeprofiles.findIndex(function (feeprofile) {
              return feeprofile.id === id
            })
            if (idx !== -1) {
              $ctrl.feeprofiles.splice(idx, 1)
            }
          },
          function (response) {
            if (response) {
              NotificationService.error("Error Deleting FeeSchedule", response)
            }
          }
        )
      }

      $ctrl.isVisible = function (feeprofile) {
        var byArchived = $ctrl.showArchived ? true : !feeprofile.is_archived
        var bySearch = $ctrl.searchRegExp
          ? $ctrl.searchRegExp.test(feeprofile.name) || $ctrl.searchRegExp.test(feeprofile.payer.name)
          : true
        return byArchived && bySearch
      }
    }
  })
})();

(function () {
  "use strict"

  angular.module("app.routes").component("routeSettingsAccountingAndBillingSuperCodeList", {
    templateUrl: "src/app/routes/settings/accounting-and-billing/supercode-list.html",
    bindings: {
      canListSuperCode: "<",
      canCreateSuperCode: "<",
      canRetrieveSuperCode: "<",
      canUpdateSuperCode: "<",
      canDeleteSuperCode: "<",
    },
    controller: function (
      //
      $rootScope,
      $timeout,
      AppServicesApi,
      AppServicesModal,
      NotificationService
    ) {
      var $ctrl = this

      $ctrl.$onInit = function () {
        $ctrl.supercodes = []
        $ctrl.searchRegExp = null
        $ctrl.showArchived = false
        $ctrl.searchRegExpUnregisterListener = $rootScope.$on("search", function (_, search) {
          if (search) {
            $ctrl.searchRegExp = new RegExp(search.replace(/[\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
          } else {
            $ctrl.searchRegExp = null
          }
        })
        $ctrl.list()
      }

      $ctrl.$onDestroy = function () {
        $ctrl.searchRegExpUnregisterListener()
      }

      $ctrl.list = function () {
        AppServicesApi.supercode_list().then(
          function (response) {
            $ctrl.supercodes = response.data
            /* for-debug-starts */
            // $ctrl.destroy($ctrl.supercodes[2])
            // $ctrl.retrieve($ctrl.supercodes[0], ["procedures"])
            // $ctrl.retrieve($ctrl.supercodes[1], ["procedures"])
            // $ctrl.supercodes[0].$updateIsActive = "success"
            // $ctrl.supercodes[1].$updateIsActive = "error"
            /* for-debug-finish */
          },
          function (response) {
            $ctrl.supercodes = null
            NotificationService.error("Error loading Supercodes", response)
          }
        )
      }

      $ctrl.retrieve = function (supercode, props) {
        if (props.includes("procedures")) {
          if (supercode.procedures) {
            supercode.$showProcedures = !supercode.$showProcedures
          } else {
            AppServicesApi.supercode_procedures(supercode.id, { selected: "" }).then(
              function (response) {
                supercode.procedures = response.data
                supercode.$showProcedures = !supercode.$showProcedures
              },
              function (response) {
                supercode.procedures = null
                NotificationService.error("Error retrieving Procedures", response)
              }
            )
          }
        }
      }

      $ctrl.update = function (supercode, props) {
        var datum = props.reduce(function (datum, prop) {
          datum[prop] = supercode[prop]
          return datum
        }, {})
        if (props.includes("is_active")) {
          supercode.$updateIsActive = "in-progress"
        }
        AppServicesApi.supercode_update(supercode.id, datum).then(
          function (response) {
            angular.extend(supercode, response.data)
            if (props.includes("is_active")) {
              supercode.$updateIsActive = "success"
            }
          },
          function (response) {
            if (props.includes("is_active")) {
              supercode.$updateIsActive = "error"
            }
            NotificationService.error("Error updating Supercode", response)
          }
        ).finally(
          function () {
            $timeout(function () {
              delete supercode.$updateIsActive
            }, 500)
          }
        )
      }

      $ctrl.destroy = function (supercode) {
        AppServicesModal.supercode_destroy(supercode).then(
          function (id) {
            var idx = $ctrl.supercodes.findIndex(function (supercode) {
              return supercode.id === id
            })
            if (idx !== -1) {
              $ctrl.supercodes.splice(idx, 1)
            }
          },
          function (response) {
            if (response && response !== "escape key press") {
              NotificationService.error("Error deleting Supercode", response)
            }
          }
        )
      }

      $ctrl.isVisible = function (supercode) {
        if ($ctrl.showArchived ? true : supercode.is_active) {
          if ($ctrl.searchRegExp) {
            return $ctrl.searchRegExp.test(supercode.code) || $ctrl.searchRegExp.test(supercode.name)
          }
          return true
        }
      }
    },
  })
})();

(function () {
  "use strict"

  angular.module("app.routes").component("routeSettingsAccountingAndBillingSuperCodeDetail", {
    templateUrl: "src/app/routes/settings/accounting-and-billing/supercode-detail.html",
    bindings: {
      canCreateSuperCode: "<",
      canRetrieveSuperCode: "<",
      canUpdateSuperCode: "<",
      canDeleteSuperCode: "<",
      id: "<",
    },
    controller: function (
      //
      $q,
      $rootScope,
      $scope,
      $state,
      $timeout,
      AppServicesApi,
      AppServicesModal,
      NotificationService
    ) {
      var $ctrl = this

      $ctrl.$onInit = function () {
        if ($ctrl.id === "new") {
          $ctrl.canDelete = false
          $ctrl.canRetrieve = $ctrl.canRetrieveSuperCode
          $ctrl.canSave = $ctrl.canCreateSuperCode
          $ctrl.supercode = {}
        } else {
          $ctrl.canDelete = $ctrl.canDeleteSuperCode
          $ctrl.canRetrieve = $ctrl.canRetrieveSuperCode
          $ctrl.canSave = $ctrl.canUpdateSuperCode
          $ctrl.supercode = { id: $ctrl.id }
        }
        // $ctrl.$retrieved = false
        $ctrl.searchRegExp = null
        $ctrl.showOnlySelected = false
        $ctrl.searchRegExpUnregisterListener = $rootScope.$on("search", function (_, search) {
          if (search) {
            $ctrl.searchRegExp = new RegExp(search.replace(/[\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
          } else {
            $ctrl.searchRegExp = null
          }
        })
        // if ($ctrl.supercode.id) { $ctrl.retrieve() } else { $ctrl.$retrieved = true }
        $ctrl.retrieve()
      }

      $ctrl.$onDestroy = function () {
        $ctrl.searchRegExpUnregisterListener()
      }

      $ctrl.create = function () {
        var supercode = ["code", "name", "description", "is_active", "procedures"].reduce(function (supercode, prop) {
          if (prop === "procedures") {
            supercode[prop] = getModifiedProcedures().map(function (procedure) { return procedure.id })
          } else {
            supercode[prop] = $ctrl.supercode[prop]
          }
          return supercode
        }, {})
        AppServicesApi.supercode_create(supercode).then(
          function (response) {
            $state.go("^.supercode-detail", { id: response.data.id })
          },
          function (response) {
            NotificationService.error("Error creating Supercode", response)
          }
        )
      }

      $ctrl.retrieve = function () {
        $ctrl.$retrieved = false
        $q.all([
          AppServicesApi.supercode_retrieve($ctrl.supercode.id).then(
            function (response) {
              return response.data
            },
            function (response) {
              NotificationService.error("Error retrieving Supercode", response)
              throw "self"
            }
          ),
          AppServicesApi.supercode_procedures($ctrl.supercode.id).then(
            function (response) {
              return response.data.map(function (procedure) {
                procedure.$selected = procedure.selected
                return procedure
              })
            },
            function (response) {
              NotificationService.error("Error retrieving Procedures", response)
              throw "self-procedures"
            }
          ),
        ]).then(
          function (responses) {
            $ctrl.supercode = responses[0]
            Object.defineProperty($ctrl.supercode, "is_archived", {
              get: function () {
                return !$ctrl.supercode.is_active
              },
              set: function (value) {
                $ctrl.supercode.is_active = !value
              },
            })
            $ctrl.supercode.procedures = responses[1]
            $ctrl.$retrieved = true
          },
          function (response) {
            console.error(response)
            $ctrl.$retrieved = true
          }
        )
      }

      $ctrl.update = function () {
        var supercode = ["code", "name", "description", "is_active"].reduce(function (supercode, prop) {
          supercode[prop] = $ctrl.supercode[prop]
          return supercode
        }, {})
        var procedures = getModifiedProcedures()
        $q.all([
          AppServicesApi.supercode_update($ctrl.supercode.id, supercode).then(
            function (response) {
              return response.data
            },
            function (response) {
              NotificationService.error("Error updating Supercode", response)
              throw "self"
            }
          ),
          AppServicesApi.supercode_procedures_update($ctrl.supercode.id, procedures).then(
            function (response) {
              return response.data
            },
            function (response) {
              NotificationService.error("Error updating Supercode associated Procedures", response)
              throw "self-procedures"
            }
          ),
        ]).then(function (responses) {
          angular.extend($ctrl.supercode, responses[0])
          var procedures_idxs = []
          responses[1].forEach(function (procedure) {
            var idx = $ctrl.supercode.procedures.findIndex(function (value) {
              return value.id === procedure.id
            })
            if (idx !== -1) {
              procedures_idxs.push(idx)
              angular.extend($ctrl.supercode.procedures[idx], procedure, {
                $selected: procedure.selected,
                $updateSelected: $ctrl.supercode.procedures[idx].selected === procedure.selected ? "success" : "error",
              })
            }
          })
          NotificationService.success("Saved")
          $timeout(function () {
            procedures_idxs.forEach(function (idx) {
              delete $ctrl.supercode.procedures[idx].$updateSelected
            })
          }, 500)
        })
      }

      $ctrl.destroy = function () {
        AppServicesModal.supercode_destroy($ctrl.supercode).then(
          function () {
            $state.go("^.supercode-list")
          },
          function (response) {
            if (response && response !== "escape key press") {
              NotificationService.error("Error deleting Supercode", response)
            }
          }
        )
      }

      $ctrl.isVisible = function (procedure) {
        var bySelected = $ctrl.showOnlySelected ? procedure.selected : true
        var bySearch = $ctrl.searchRegExp
          ? $ctrl.searchRegExp.test(procedure.code) || $ctrl.searchRegExp.test(procedure.name)
          : true
        return bySelected && bySearch
      }

      function getModifiedProcedures() {
        return $ctrl.supercode.procedures.reduce(function (procedures, procedure) {
          if (procedure.selected !== procedure.$selected) {
            procedures.push({
              id: procedure.id,
              selected: procedure.selected,
            })
          }
          return procedures
        }, [])
      }
    },
  })
})();

(function () {
  "use strict"

  angular.module("app.services").service("AppServicesModal", function ($uibModal, AppServicesApi) {
    var options = {
      animation: false,
      bindToController: true,
      controllerAs: "$ctrl",
    }

    this.feeprofile_destroy = function (feeprofile) {
      return $uibModal.open(
        angular.extend({}, options, {
          templateUrl: "src/app/services/modal/feeprofile-destroy.html",
          controller: function ($scope) {
            var $ctrl = this

            $ctrl.$onInit = function () {
              $ctrl.feeprofile = angular.extend({}, feeprofile)
            }

            $ctrl.submit = function () {
              AppServicesApi.feeprofile_destroy($ctrl.feeprofile.id).then(
                function () {
                  $scope.$close($ctrl.feeprofile.id)
                },
                function (response) {
                  $scope.$dismiss(response)
                }
              )
            }
          },
        })
      ).result
    }

    this.feeprofile_duplicate = function (feeprofile) {
      return $uibModal.open(
        angular.extend({}, options, {
          templateUrl: "src/app/services/modal/feeprofile-duplicate.html",
          resolve: {
            options: function (AppServicesApi) {
              if (feeprofile.has_insurance_fees) {
                return AppServicesApi.feeprofile_options().then(
                  function (response) {
                    return response.data
                  },
                  function () {
                    return { payers: [] }
                  }
                )
              }
              return { payers: [] }
            },
          },
          controller: function ($scope, options) {
            var $ctrl = this

            $ctrl.$onInit = function () {
              $ctrl.duplicated = {
                is_archived: feeprofile.is_archived ? true : undefined,
              }
              $ctrl.feeprofile = feeprofile
              $ctrl.payers = options.payers
            }

            $ctrl.submit = function () {
              AppServicesApi.feeprofile_duplicate(feeprofile.id, $ctrl.duplicated).then(
                function (response) {
                  $scope.$close(response)
                },
                function (response) {
                  $scope.$dismiss(response)
                }
              )
            }
          },
        })
      ).result
    }

    this.supercode_destroy = function (supercode) {
      return $uibModal.open(
        angular.extend({}, options, {
          templateUrl: "src/app/services/modal/supercode-destroy.html",
          resolve: {
            procedures: function (AppServicesApi) {
              if (supercode.procedures) {
                return supercode.procedures.filter(function (procedure) {
                  return procedure.selected
                })
              }
              return AppServicesApi.supercode_procedures(supercode.id, { selected: "" }).then(
                function (response) {
                  return response.data
                },
                function (response) {
                  console.error(response)
                  return null
                }
              )
            }
          },
          controller: function ($scope, procedures) {
            var $ctrl = this
            $ctrl.$onInit = function () {
              $ctrl.supercode = angular.extend({}, supercode, { procedures: procedures })
            }
            $ctrl.submit = function () {
              AppServicesApi.supercode_destroy($ctrl.supercode.id).then(
                function () {
                  $scope.$close($ctrl.supercode.id)
                },
                function (response) {
                  $scope.$dismiss(response)
                }
              )
            }
          },
        })
      ).result
    }

  })
})();

(function () {
  "use strict"

  // - [L]ist
  // - [C]reate
  // - [R]etrieve
  // - [U]pdate
  // - [D]estroy
  // - [O]ptions
  // - [L]ist-routes
  // - [D]etail-routes

  angular.module("app.services").service("AppServicesApi", function ($http, $q, PrincipalService) {
    var inProgress = 0

    Object.defineProperty(this, "inProgress", {
      get: function () {
        return inProgress
      },
    })

    function v3() {
      return "/api/v3/practice/" + PrincipalService.getPracticeId()
    }

    // FeeProfile //////////////////////////////////////////////////////////////////////////////////////////////////////

    this.feeprofile_list = function () {
      inProgress += 1
      return $http.get(v3() + "/~treatments/feeprofile").finally(function () {
        inProgress -= 1
      })
    }
    this.feeprofile_create = function (datum) {
      inProgress += 1
      return $http.post(v3() + "/~treatments/feeprofile", datum).finally(function () {
        inProgress -= 1
      })
    }
    this.feeprofile_retrieve = function (id) {
      inProgress += 1
      return $http.get(v3() + "/~treatments/feeprofile/" + id).finally(function () {
        inProgress -= 1
      })
    }
    this.feeprofile_update = function (id, datum) {
      inProgress += 1
      return $http.put(v3() + "/~treatments/feeprofile/" + id, datum).finally(function () {
        inProgress -= 1
      })
    }
    this.feeprofile_destroy = function (id) {
      inProgress += 1
      return $http.delete(v3() + "/~treatments/feeprofile/" + id).finally(function () {
        inProgress -= 1
      })
    }
    this.feeprofile_options = function (id) {
      inProgress += 1
      return $http({ method: "OPTIONS", url: v3() + "/~treatments/feeprofile" + (id ? "/" + id : "") }).finally(
        function () {
          inProgress -= 1
        }
      )
    }
    this.feeprofile_duplicate = function (id, datum) {
      inProgress += 1
      return $http.post(v3() + "/~treatments/feeprofile/" + id + "/duplicate", datum).finally(function () {
        inProgress -= 1
      })
    }
    this.feeprofile_fees = function (id) {
      inProgress += 1
      return $http.get(v3() + "/~treatments/feeprofile/" + id + "/fees").finally(function () {
        inProgress -= 1
      })
    }
    this.feeprofile_fees_update = function (id, datum) {
      inProgress += 1
      return $http.patch(v3() + "/~treatments/feeprofile/" + id + "/fees-update", datum).finally(function () {
        inProgress -= 1
      })
    }
    this.feeprofile_for_patient = function (patient_id) {
      inProgress += 1
      return $http.get(v3() + "/~treatments/feeprofile/for-patient/" + patient_id).finally(function () {
        inProgress -= 1
      })
    }

    // InsurancePlan ///////////////////////////////////////////////////////////////////////////////////////////////////

    this.insuranceplan_update = function (id, datum) {
      inProgress += 1
      return $http.put(v3() + "/~insurance/plan/" + id, datum).finally(function () {
        inProgress -= 1
      })
    }

    // SuperCode ///////////////////////////////////////////////////////////////////////////////////////////////////////

    this.supercode_list = function () {
      inProgress += 1
      return $http.get(v3() + "/~treatments/supercode").finally(function () {
        inProgress -= 1
      })
    }
    this.supercode_create = function (datum) {
      inProgress += 1
      return $http.post(v3() + "/~treatments/supercode", datum).finally(function () {
        inProgress -= 1
      })
    }
    this.supercode_retrieve = function (id) {
      if (id === undefined) {
        return $q.when({
          data: {
            is_active: true,
          }
        })
      }
      inProgress += 1
      return $http.get(v3() + "/~treatments/supercode/" + id).finally(function () {
        inProgress -= 1
      })
    }
    this.supercode_update = function (id, datum) {
      inProgress += 1
      return $http.put(v3() + "/~treatments/supercode/" + id, datum).finally(function () {
        inProgress -= 1
      })
    }
    this.supercode_destroy = function (id) {
      inProgress += 1
      return $http.delete(v3() + "/~treatments/supercode/" + id).finally(function () {
        inProgress -= 1
      })
    }
    this.supercode_procedures = function (id, params) {
      inProgress += 1
      return $http.get(v3() + "/~treatments/supercode/" + (id === undefined ? "procedures" : id + "/procedures"), {
        params: params,
      }).finally(
        function () {
          inProgress -= 1
        }
      )
    }
    this.supercode_procedures_update = function (id, datum) {
      inProgress += 1
      return $http.patch(v3() + "/~treatments/supercode/" + id + "/procedures-update", datum).finally(function () {
        inProgress -= 1
      })
    }
  })
})();

(function () {
    'use strict';

    var LICENSE_KEY = '0529514855-fcs-1680833387'

    var supportCallbackEvents = [
        'eventClick',
        'eventDrop',
        'eventResize',
        'dateClick',
        'select',
        'eventContent',
        'datesSet',
        'eventDidMount',
        'eventMouseEnter',
        'eventMouseLeave',
    ];

    var supportedProperties = [
        'events',
        'options',
        'resources',
        'view',
        'resourceOrder',
    ]

    var defaultProperties = {
        'resourceOrder': 'order',
    }

    var ignoredProperties = [
        'options',
    ]

    var getScope = function () {
        var localScope = {};
        supportedProperties.forEach(function (property) {
            localScope[property] = '=';
        })

        supportCallbackEvents.forEach(function (event) {
            localScope[event] = '&?';
        });
        return localScope;
    }

    var getFunc = function (fn) {
        return function (args) {
            return fn()(args)
        }
    }

    var updateCalendarObject = function (scope) {
        supportedProperties.forEach(function (property) {
            if (ignoredProperties[property]) {
                return;
            }
            if (scope[property]) {
                scope.calendar[property] = scope[property];
            } else if (defaultProperties[property]) {
                scope.calendar[property] = defaultProperties[property];
            }
        });

        supportCallbackEvents.forEach(function (property) {
            if (ignoredProperties[property]) {
                return;
            }
            if (scope[property]) {
                scope.calendar[property] = getFunc(scope[property]);
            } else if (defaultProperties[property]) {
                scope.calendar[property] = defaultProperties[property];
            }
        })

        scope.calendar.schedulerLicenseKey = LICENSE_KEY
    }

    angular.module('common.directives.angularjsFullCalendar', []).directive('fullCalendar', function () {
        return {
            restrict: 'E',
            scope: getScope(),
            link: function (scope, element) {
                // Import the FullCalendar plugins
                var FullCalendar = window.FullCalendar;

                scope.options.calendar.initialView = 'resourceTimeGridDay';

                // Initialize the FullCalendar plugin
                var calendarEl = element[0];
                if (scope.options) {
                    scope.calendar = Object.assign({}, scope.options.calendar, { selectable: true })
                }

                updateCalendarObject(scope);

                var calendar = new FullCalendar.Calendar(calendarEl, scope.calendar);

                // Update the FullCalendar plugin when the events or options change
                scope.$watchGroup(['events', 'resources', 'view'], function (newValues, oldValues) {
                    if (!newValues) {
                        return;
                    }

                    if (newValues[0]) {
                        if (calendar.getEventSources().length === 0) {
                            calendar.addEventSource(newValues[0]);
                        } else {
                            calendar.getEventSources()[0].remove();
                            calendar.addEventSource(newValues[0]);
                        }
                    }

                    if(newValues[1] !== oldValues[1]) {
                        calendar.setOption('resources', newValues[1]);
                        calendar.changeView('resourceTimeGridDay')
                    }

                    if (newValues[2]) {
                        calendar.changeView(newValues[2])
                    }

                });

                scope.$on('nextClick', function () { calendar.next() });
                scope.$on('prevClick', function () { calendar.prev() });
                scope.$on('goToDateClick', function (_event, args) { calendar.gotoDate(args) });
                scope.$on('todayClick', function () { calendar.today() });
                // Destroy the FullCalendar plugin when the directive is destroyed
                scope.$on('$destroy', function () {
                    calendar.destroy();
                });

                scope.$on('editBackgroundMode', function (_event, isEditMode) {
                    var events = calendar.getEvents();
                    events.forEach(function (event) {
                        if (isEditMode) {
                            if (event.display === 'background') {
                                event.setProp('display', 'auto');
                                event.setProp('backgroundColor', 'rgba(183, 183, 183, 0.85)')
                            } else {
                                event.setProp('display', 'none');
                            }
                        } else {
                            if (event.display === 'auto') {
                                event.setProp('display', 'background');
                                event.setProp('backgroundColor', 'rgba(0, 0, 0, 0.8)')
                            } else {
                                event.setProp('display', 'auto');
                            }
                        }

                    })

                })

                scope.$on('colorEventBy', function (_event, field_name) {
                    // var events = calendar.getEvents();
                    var eventSource = calendar.getEventSources()[0];
                    var rawCal = calendar.getEventSources()[0].internalEventSource._raw

                    function snakeToCamel(str) {
                        return str.replace(
                            /(?!^)_(.)/g,
                            (_, char) => char.toUpperCase()
                        );
                    }

                    rawCal.forEach(function (event) {
                        var bgColor = '';
                        if (event.extendedProps.type === 'appointment') {
                            bgColor = AppointmentGetColor(event.extendedProps[snakeToCamel(field_name)], field_name);
                            if (bgColor) {
                                event.backgroundColor = bgColor;
                            }
                        } else if (event.extendedProps.type === 'timeblock') {
                            bgColor = TimeBlockGetColor(event.extendedProps[snakeToCamel(field_name)]);
                            if (bgColor) {
                                event.backgroundColor = bgColor;
                            }
                        }
                    })
                        
                    // events.forEach(function (event) {
                    //     var bgColor = '';
                    //     if (event.extendedProps.type === 'appointment') {
                    //         bgColor = AppointmentGetColor(event.extendedProps[snakeToCamel(field_name)], field_name);
                    //         if (bgColor) {
                    //             event.setProp('backgroundColor', bgColor);
                    //         }
                    //     } else if (event.extendedProps.type === 'timeblock') {
                    //         bgColor = TimeBlockGetColor(event.extendedProps[snakeToCamel(field_name)]);
                    //         if (bgColor) {
                    //             event.setProp('backgroundColor', bgColor);
                    //         }
                    //     }

                    // })
                    eventSource.remove();
                    calendar.addEventSource(rawCal);
                })

                scope.$on('fullView', function(_event, showFullView) {
                    if(showFullView) {
                    calendar.changeView('timeGridDay');
                    calendar.setOption('headerToolbar', {
                            left: 'timeGridDay,timeGridWeek,dayGridMonth,prev,next',
                            center: 'title',
                            right: 'today'
                    })
                    calendar.setOption('navLinks', false);
                    calendar.today();
                } else {
                    calendar.changeView('resourceTimeGridDay');
                    calendar.setOption('headerToolbar', false)  
                }
                })

                function AppointmentGetColor(field_value, field_name) {
                    var opacity = '1.0';
                    // var field_name = 'appointment_type'

                    if (field_name === 'appointment_type') {
                        var typeColorMap = {
                            'npv': 'rgba(162, 194, 101, ' + opacity + ')',
                            'ev': 'rgba(153, 189, 255, ' + opacity + ')',
                            'rc': 'rgba(144, 213, 213, ' + opacity + ')',
                            'ob': 'rgba(255, 187, 77, ' + opacity + ')',
                            're': 'rgba(198, 168, 244, ' + opacity + ')',
                            'rt': 'rgba(255, 165, 125, ' + opacity + ')',
                            'a': 'rgba(103, 152, 130, ' + opacity + ')',
                            'po': 'rgba(100, 103, 140, ' + opacity + ')'
                        };
                        if (typeColorMap.hasOwnProperty(field_value)) {
                            return typeColorMap[field_value];
                        }
                    } else if (field_name === 'appointment_status') {
                        var statusColorMap = {
                            'unconfirmed': 'rgba(144, 213, 213,  ' + opacity + ')',
                            'contact_attempted': 'rgba(153, 189, 255,  ' + opacity + ')',
                            'confirmed': 'rgba(198, 168, 244,  ' + opacity + ')',
                            'late': 'rgba(255, 187, 77,   ' + opacity + ')',
                            'paperwork': 'rgba(255, 165, 125, ' + opacity + ')',
                            'checked_in': 'rgba(162, 194, 101,  ' + opacity + ')',
                            'seated': 'rgba(103, 152, 130,  ' + opacity + ')',
                            'checked_out': 'rgba(100, 103, 140,  ' + opacity + ')',
                            'completed': 'rgba(181, 181, 181,  ' + opacity + ')',
                            'no_show': 'rgba(255, 105, 102,  ' + opacity + ')',
                            'canceled': 'rgba(213, 214, 212,  ' + opacity + ')'
                        };
                        if (statusColorMap.hasOwnProperty(field_value)) {
                            return statusColorMap[field_value];
                        }
                    }

                    if (typeof field_value === 'string' || field_value instanceof String) {
                        var stringFieldValue = field_value;
                        field_value = 0;
                        for (var i = 0; i < stringFieldValue.length; i++) {
                            field_value += stringFieldValue.charCodeAt(i);
                        }
                    }

                    var colors = [
                        'rgba(144, 213, 213, ' + opacity + ')',
                        'rgba(255, 105, 102, ' + opacity + ')',
                        'rgba(162, 194, 101, ' + opacity + ')',
                        'rgba(153, 189, 255, ' + opacity + ')',
                        'rgba(255, 187, 77, ' + opacity + ')',
                        'rgba(198, 168, 244, ' + opacity + ')',
                        'rgba(255, 165, 125, ' + opacity + ')',
                        'rgba(126, 146, 206, ' + opacity + ')',
                        'rgba(213, 144, 144, ' + opacity + ')',
                        'rgba(103, 152, 130, ' + opacity + ')',
                        'rgba(213, 196, 144, ' + opacity + ')',
                        'rgba(152, 103, 140, ' + opacity + ')'
                    ];

                    field_value = field_value % colors.length;
                    return colors[field_value];

                }

                function TimeBlockGetColor(index) {
                    if (!index || isNaN(index)) {
                        return 'rgba(183, 183, 183, 0.85)';
                    }

                    /*
                    var colors = [
                      'rgba(255,0,0, 0.5)',
                      'rgba(0, 0, 255, 0.5)',
                      'rgba(0, 255,0, 0.5)',
                      'rgba(255,164,0, 0.5)',
                      'rgba(0, 255, 255, 0.5)',
                      'rgba(255,0,255 0.5)'
                    ];
                    */
                    var opacity = '0.4';

                    var colors = [
                        'rgba(144, 213, 213, ' + opacity + ')',
                        'rgba(255, 105, 102, ' + opacity + ')',
                        'rgba(162, 194, 101, ' + opacity + ')',
                        'rgba(153, 189, 255, ' + opacity + ')',
                        'rgba(255, 187, 77, ' + opacity + ')',
                        'rgba(198, 168, 244, ' + opacity + ')',
                        'rgba(255, 165, 125, ' + opacity + ')',
                        'rgba(126, 146, 206, ' + opacity + ')',
                        'rgba(213, 144, 144, ' + opacity + ')',
                        'rgba(103, 152, 130, ' + opacity + ')',
                        'rgba(213, 196, 144, ' + opacity + ')',
                        'rgba(152, 103, 140, ' + opacity + ')'
                    ];

                    index = index % colors.length;
                    return colors[index];
                }

                // Render the FullCalendar plugin
                calendar.render();
            }
        };
    })
})();
